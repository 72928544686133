export const styleSheet = theme => ({
    container: { flex: 1, maxWidth: 290 },
    searchInput: {
        width: '100%',
        marginTop: 0,
        border: 'none',
        backgroundColor: theme.colors.gray03,
        height: '30px',
        borderRadius: '30px',
        padding: '0 15px 0 28px',
        '::placeholder': {
            fontFamily: theme.fonts.fontFamily,
            color: theme.colors.gray04
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            width: '100%',
            fontSize: '16px',
            '::placeholder': {
                textTransform: 'unset',
                letterSpacing: 0,
                fontSize: '16px',
                lineHeight: '30px'
            }
        }
    },
    searchIcon: {
        position: 'absolute',
        top: 2,
        bottom: 0,
        left: 9,
        display: 'flex',
        alignItems: 'center',
        svg: {
            height: '14px',
            width: '14px'
        }
    },
    searchFilter: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ':focus-within': {
            '.searchIcon svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    minimumText: {
        marginLeft: theme.margins.margin03
    },
    searchResultsNav: {
        position: 'absolute',
        bottom: 0,
        right: 10,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        '> svg': {
            height: '12px',
            width: '12px'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            right: '8px'
        }
    },
    numResults: {
        fontSize: theme.fonts.fontSizes.size01,
        paddingRight: 6,
        borderRight: `1px solid ${theme.colors.gray02}`
    },
    resultsButtonEnabled: {
        cursor: 'pointer'
    },
    resultsButtonDisabled: {
        cursor: 'not-allowed'
    },
    prevResult: {
        margin: '0 10px',
        svg: {
            marginTop: '2px',
            transform: 'rotate(270deg)'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            margin: '0',
            padding: '0 10px'
        }
    },
    nextResult: {
        svg: {
            marginTop: '2px',
            transform: 'rotate(90deg)'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            margin: '0',
            padding: '0 10px'
        }
    },
    clearResults: {
        height: 14,
        marginLeft: '10px',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        },
        svg: {
            height: 14,
            width: 14
        }
    },
    noResults: {
        position: 'absolute',
        bottom: 0,
        left: '165px',
        top: '2px',
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.gray06,
        fontSize: theme.fonts.fontSizes.size00
    }
});
