export const styleSheet = theme => ({
    container: {
        display: 'flex',
        padding: '0 3px',
        zIndex: 2,
        position: 'relative',
        backgroundColor: theme.colors.gray01,
        justifyContent: 'flex-start'
    },
    checkbox: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.colors.black01,
        alignItems: 'center',
        height: 22,
        padding: '0px 5px 0 4px',
        borderRadius: 6,
        backgroundColor: theme.colors.gray10,
        margin: '0 2px 4px',
        ':hover': {
            p: {
                color: theme.colors.black01
            }
        },
        p: {
            color: theme.colors.gray06,
            letterSpacing: 'normal',
            marginLeft: 4,
            textTransform: 'unset'
        }
    }
});
