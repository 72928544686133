import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { get } from 'utils';
import XDate from 'xdate';
import { styleSheet } from './stylesheet';

class EventMobilePlayer extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string,
        callType: PropTypes.string,
        canListen: PropTypes.bool.isRequired,
        togglePlayEvent: PropTypes.func.isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        loadingAudio: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        callDate: null,
        callType: '',
        equity: null,
        loadingAudio: false,
        passedStyles: {}
    };

    render() {
        const {
            callType,
            callDate,
            canListen,
            equity,
            loadingAudio,
            mediaPlayer,
            passedStyles,
            theme,
            togglePlayEvent,
            styles
        } = this.props;
        const formattedDate = callDate ? new XDate(callDate).toString('MMM dd, yyyy') : '';

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {canListen && (
                    <Div styles={styles.audioControl} onClick={loadingAudio ? undefined : togglePlayEvent}>
                        <Icon
                            className={mediaPlayer.listening ? 'pauseIcon' : 'playIcon'}
                            color={theme.colors.white01}
                            type={mediaPlayer.listening ? 'pause' : 'play'}
                        />
                    </Div>
                )}
                <Div styles={styles.event}>
                    <Div styles={styles.eventHeader}>
                        <Text size={3} styles={styles.ticker} weight="medium">
                            {get(equity, 'localTicker', '')}
                        </Text>
                        <Text size={1} capitalize styles={styles.callType}>
                            {callType ? callType.replace(/_/g, ' ') : 'Loading...'}
                        </Text>
                    </Div>
                    <Text styles={styles.date}>{formattedDate}</Text>
                </Div>
                {canListen && (
                    <Div styles={styles.listenNow} onClick={loadingAudio ? undefined : togglePlayEvent}>
                        <Text size={3}>
                            {mediaPlayer.listening ? 'Pause Audio' : loadingAudio ? 'Loading...' : 'Listen Now'}
                        </Text>
                    </Div>
                )}
            </Div>
        );
    }
}

export const EventMobilePlayerUI = compose(withStyleSheet(styleSheet))(EventMobilePlayer);
