import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import filterBy from 'lodash/filter';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { get } from 'utils';
import { AutocompleteUI } from './ui';

function defaultSort(option) {
    return get(option, 'label');
}

function sortOptions(options, sort) {
    return sortBy(options, sort).map(g => {
        return {
            label: g.label,
            metadata: g.metadata,
            value: g.value,
            options: g.options
                ? sortBy(
                      get(g, 'options', []).map(o => ({ ...o, parent: g })),
                      sort
                  )
                : undefined
        };
    });
}

function defaultFilter(searchTerm, option) {
    // If it has options.length > 0 keep it since it's a header and some of its sub options match,
    // otherwise, filter out anything that doesn't directly match
    return get(option, 'options.length') || option.label.toLowerCase().includes((searchTerm || '').toLowerCase());
}

function defaultOptionsFiltering(options, searchTerm, filter) {
    const predicate = filter.bind(this, searchTerm);
    const filtered = filterBy(
        options.map(g => (g.options ? { ...g, options: filterBy(g.options, predicate) } : g)),
        predicate
    );
    return flatten(filtered.map(o => (o.options ? [o, ...o.options] : o)));
}

export class Autocomplete extends PureComponent {
    static displayName = 'AutocompleteContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        changeOnEnter: PropTypes.bool,
        clearOnBlur: PropTypes.bool,
        clearOnSelect: PropTypes.bool,
        disabled: PropTypes.bool,
        error: PropTypes.string,
        filter: PropTypes.func,
        filterOptions: PropTypes.bool,
        getTagLabel: PropTypes.func,
        getTagStyle: PropTypes.func,
        hideTooltipOnScroll: PropTypes.bool,
        icon: PropTypes.string,
        id: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        keepInViewport: PropTypes.bool,
        label: PropTypes.string,
        loading: PropTypes.bool,
        menuStyles: PropTypes.objectOf(PropTypes.any),
        multi: PropTypes.bool,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onSearch: PropTypes.func,
        OptionComponent: PropTypes.elementType,
        options: PropTypes.arrayOf(PropTypes.any),
        placeholder: PropTypes.string,
        resultsPlaceholder: PropTypes.string,
        sort: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        strictOptions: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        wrapTags: PropTypes.bool
    };

    static defaultProps = {
        autoFocus: false,
        changeOnEnter: true,
        clearOnBlur: true,
        clearOnSelect: false,
        disabled: false,
        error: undefined,
        getTagLabel: undefined,
        getTagStyle: undefined,
        hideTooltipOnScroll: undefined,
        initialSearchTerm: null,
        filter: defaultFilter,
        filterOptions: undefined,
        icon: undefined,
        id: undefined,
        keepInViewport: undefined,
        label: undefined,
        loading: false,
        menuStyles: {},
        multi: false,
        name: 'autocomplete',
        onBlur: undefined,
        onChange: null,
        onFocus: undefined,
        onSearch: null,
        OptionComponent: undefined,
        options: [],
        placeholder: undefined,
        resultsPlaceholder: undefined,
        sort: defaultSort,
        styles: {},
        strictOptions: true,
        useTags: false,
        value: [],
        wrapTags: true
    };

    constructor(props) {
        super(props);
        const { initialSearchTerm, multi, strictOptions, value } = props;

        this.clearValue = this.clearValue.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleOptionNode = this.handleOptionNode.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onHighlight = this.onHighlight.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onTagChange = this.onTagChange.bind(this);
        this.onTooltipHide = this.onTooltipHide.bind(this);
        this.onTooltipShow = this.onTooltipShow.bind(this);

        this.defaultOptionsFiltering = memoize(defaultOptionsFiltering);
        this.sortOptions = memoize(sortOptions);

        this.inputRef = React.createRef();
        this.tooltipRef = React.createRef();
        this.optionNodes = [];

        let searchTerm = initialSearchTerm;
        if (!multi && strictOptions && this.options.length) {
            const selectedOption = this.options.find(o => o.value === value);
            searchTerm = get(selectedOption, 'label', null);
        }

        this.filterUpdated = false;
        this.state = {
            focused: false,
            searchTerm,
            value: Array.isArray(value) ? value : [value].filter(v => v),
            highlightedIndex: 0
        };
    }

    componentDidUpdate({ initialSearchTerm: prevInitialTerm, name: prevName, options: prevOptions, value: prevValue }) {
        const { searchTerm, value: stateValue } = this.state;
        const { initialSearchTerm, multi, name, options, strictOptions, value } = this.props;
        // This should return false for null, undefined, '', {}, []
        const hasValue = typeof value === 'object' ? !!Object.keys(value ?? {}).length : !!value;
        let newState;
        if (prevName !== name) {
            newState = {
                searchTerm: '',
                value: Array.isArray(value) ? value : [value].filter(v => v),
                highlightedIndex: 0
            };
        } else if ((prevValue !== value && stateValue !== value) || (hasValue && options !== prevOptions)) {
            newState = { value: Array.isArray(value) ? value : [value].filter(v => v) };
            const selectedOption = this.options.find(o => o.value === value);
            if (!multi && selectedOption) {
                newState.searchTerm = get(selectedOption, 'label', null);
            } else if (strictOptions && !selectedOption) {
                newState.searchTerm = '';
            } else if (!strictOptions && !selectedOption && prevInitialTerm !== initialSearchTerm) {
                // Not strict, no selected option found, and new search term
                newState.searchTerm = initialSearchTerm;
            }
        }
        if (!searchTerm && !prevInitialTerm && initialSearchTerm) {
            newState = {
                ...(newState || {}),
                searchTerm: initialSearchTerm
            };
        }
        if (newState) {
            this.setState(newState);
        }
    }

    onSelect({ event, option, keepTooltipOpen }) {
        const { multi, name, onChange, onSearch, useTags, clearOnSelect } = this.props;
        const { value } = this.state;
        const { value: selected, parent, options } = option;
        event.stopPropagation();
        event.preventDefault();

        let newValue = selected;
        const reselected = value.some(v => isEqual(selected, v));
        if (multi) {
            if (reselected) {
                // If we are in tagged mode and the user selects a value that's already been selected,
                // we don't want to do anything. Checked if the current value is already selected
                if (useTags) {
                    newValue = value;
                } else {
                    // We're removing this selection. If it's a group, we've already removed all the child
                    // options so just unselect the group. If it's a child option, just unselect the child
                    // because the parent group can't also be selected (we'd have selected the group instead).
                    newValue = value.filter(v => v !== selected);
                }
            } else if (value.includes(get(parent, 'value'))) {
                // If we are unselected and the parent of this option is currently selected,
                // it means the entire group is selected. We want to remove the group and select
                // all of it's child options except this one.
                newValue = [
                    ...value.filter(v => v !== get(parent, 'value')),
                    ...get(parent, 'options', [])
                        .map(o => o.value)
                        .filter(v => v !== selected)
                ];
            } else {
                const groupSelected =
                    parent && parent.options.every(o => o.value === option.value || value.includes(o.value));
                if (groupSelected) {
                    // We are adding a new value to the selection. If this results in the entire
                    // group now be selected, we'll add the group instead.
                    newValue = [
                        ...value.filter(
                            v =>
                                !get(parent, 'options', [])
                                    .map(o => o.value)
                                    .includes(v)
                        ),
                        parent.value
                    ];
                } else if (!parent) {
                    // If we're selecting a group, remove the child options and only select the group.
                    newValue = [...value.filter(v => !(options || []).map(o => o.value).includes(v)), selected];
                } else {
                    // All other checks were false, just simply select this value
                    newValue = [...value, selected];
                }
            }
        }

        this.setState(
            {
                value: Array.isArray(newValue) ? newValue : [newValue],
                searchTerm: multi
                    ? ''
                    : get(
                          this.options.find(o => o.value === newValue),
                          'label',
                          null
                      )
            },
            () => {
                if (!keepTooltipOpen && (!multi || useTags)) {
                    this.inputRef.current.blur();
                }

                if (clearOnSelect) {
                    this.inputRef.current.blur();
                    this.setState({ value: [], searchTerm: '' }, () => {
                        if (onSearch) {
                            onSearch({ value: '' });
                        }
                    });
                }

                // Let the parent know the input search value has cleared
                if (onSearch && useTags) {
                    onSearch({ value: '' });
                }

                if (onChange) {
                    onChange({
                        name,
                        value: newValue,
                        event,
                        parent
                    });
                }
            }
        );
    }

    onSearch({ event, value: searchTerm }) {
        const { multi, name, onSearch, onChange } = this.props;

        this.filterUpdated = true;
        this.setState(
            ({ value }) => ({
                searchTerm,
                value: multi ? value : [],
                highlightedIndex: 0
            }),
            () => {
                if (onSearch) {
                    onSearch({ name, value: searchTerm, event });
                }

                // We're searching, so clear the value
                if (!multi && onChange) {
                    onChange({ name, value: null, event });
                }
            }
        );
    }

    onBlur(event) {
        const { value } = this.state;
        const { clearOnBlur, onSearch, name, onBlur } = this.props;

        if (clearOnBlur) {
            this.filterUpdated = false;
            this.setState({ highlightedIndex: 0, focused: false });
            if (!value || !value.length) {
                this.setState(
                    {
                        searchTerm: ''
                    },
                    () => {
                        if (onSearch) {
                            onSearch({ event, value: '', name });
                        }
                    }
                );
            }
        }

        if (onBlur) onBlur();
    }

    onFocus() {
        const { onFocus } = this.props;
        this.setState({ focused: true });
        if (onFocus) onFocus();
    }

    onHighlight(highlightedIndex) {
        this.setState({
            highlightedIndex
        });
    }

    onTagChange({ event, value }) {
        const { name, onChange } = this.props;
        if (value) {
            this.setState({ value }, () => {
                if (onChange) {
                    onChange({
                        name,
                        value,
                        event
                    });
                }
            });
        }
    }

    onTooltipShow() {
        const { value } = this.state;

        window.addEventListener('keydown', this.handleKeyDown);
        if (value) {
            const nodeIndex = this.options.findIndex(o => o.value === value);
            const optionNode = this.optionNodes[nodeIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }
        }
    }

    onTooltipHide() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    clearValue({ event }) {
        const { name, onSearch, onChange } = this.props;
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            searchTerm: '',
            value: []
        });

        if (onSearch) {
            onSearch({ name, value: '', event });
        }

        if (onChange) {
            onChange({ name, value: null, event });
        }
    }

    handleKeyDown(e) {
        const { changeOnEnter, multi, useTags } = this.props;
        const { highlightedIndex } = this.state;
        const keyCode = get(e, 'code');
        const maxIndex = this.options.length - 1;

        if (keyCode === 'ArrowDown' || keyCode === 'ArrowUp' || keyCode === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }

        if (keyCode === 'ArrowDown' && highlightedIndex < maxIndex) {
            const nextIndex = highlightedIndex + 1;
            const optionNode = this.optionNodes[nextIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }
            this.onHighlight(nextIndex);
        }

        if (keyCode === 'ArrowUp' && highlightedIndex > 0) {
            const nextIndex = highlightedIndex - 1;
            const optionNode = this.optionNodes[nextIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }

            this.onHighlight(nextIndex);
        }

        if (keyCode === 'Enter') {
            const option = get(this.options, `[${highlightedIndex}]`, null);
            if (option && !get(option, 'disabled', false)) {
                if (changeOnEnter) {
                    this.onSelect({ event: e, option, keepTooltipOpen: useTags });
                }
                if (!useTags && !multi && this.tooltipRef.current) {
                    this.tooltipRef.current.hideTooltip();
                }
            }
        }

        if (keyCode === 'Escape') {
            const { searchTerm } = this.state;
            if (this.filterUpdated && searchTerm) {
                this.clearValue({ event: e });
            } else if (this.inputRef.current) {
                this.inputRef.current.blur();
            }
        }
    }

    handleOptionNode(node, optionIndex) {
        if (typeof node === 'object') {
            this.optionNodes[optionIndex] = node;
        }
    }

    get options() {
        const { filter, onSearch, options, sort, filterOptions } = this.props;
        const { searchTerm } = this.state || {};

        // If explicitly told to filter, then we filter.
        // If the filterOptions prop was unspecified then:
        //    - If no onSearch handler was passed, then we
        //      filter since the parent definitely isn't.
        //    - If onSearch was passed, then the parent is probably
        //      doing the filtering. If the parent isn't, it will need
        //      to pass `filterOptions={true}`.
        if (filterOptions || (filterOptions === undefined && !onSearch)) {
            return this.defaultOptionsFiltering(
                this.sortOptions(options, sort),
                this.filterUpdated ? searchTerm : '',
                filter
            );
        }

        return options;
    }

    render() {
        const { focused, highlightedIndex, searchTerm, value } = this.state;
        const {
            autoFocus,
            changeOnEnter,
            disabled,
            error,
            getTagLabel,
            getTagStyle,
            hideTooltipOnScroll,
            icon,
            id,
            keepInViewport,
            label,
            loading,
            menuStyles,
            multi,
            name,
            OptionComponent,
            placeholder,
            resultsPlaceholder,
            styles,
            strictOptions,
            useTags,
            wrapTags
        } = this.props;
        let inputValue = searchTerm;
        if (!useTags && multi && strictOptions && !focused && value.length) {
            inputValue =
                value.length === 1
                    ? get(
                          this.options.find(o => o.value === value[0]),
                          'label'
                      )
                    : `${value.length} selected`;
        }
        return (
            <AutocompleteUI
                autoFocus={autoFocus}
                changeOnEnter={changeOnEnter}
                disabled={disabled}
                error={error}
                getTagLabel={getTagLabel}
                getTagStyle={getTagStyle}
                handleInputRef={this.inputRef}
                handleOptionNode={this.handleOptionNode}
                handleTooltipRef={this.tooltipRef}
                hideTooltipOnScroll={hideTooltipOnScroll}
                highlightedIndex={highlightedIndex}
                icon={icon}
                id={id}
                inputValue={inputValue}
                keepInViewport={keepInViewport}
                label={label}
                loading={loading}
                menuStyles={menuStyles}
                multi={multi}
                name={name}
                onBlur={this.onBlur}
                onClear={this.clearValue}
                onFocus={this.onFocus}
                onHighlight={this.onHighlight}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                onTagChange={this.onTagChange}
                onTooltipHide={this.onTooltipHide}
                onTooltipShow={this.onTooltipShow}
                OptionComponent={OptionComponent}
                options={this.options}
                placeholder={placeholder}
                resultsPlaceholder={resultsPlaceholder}
                styles={styles}
                useTags={useTags}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const AutocompleteContainer = compose()(Autocomplete);
