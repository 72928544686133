export const styleSheet = theme => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '350px'
    },
    emailInput: {
        marginTop: `calc(${theme.margins.margin03} - 2px)`
    },
    passwordInput: {
        width: '100%',
        marginTop: `calc(${theme.margins.margin03} - 4px)`
    },
    resetContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        [`@media(max-width: ${theme.breakpoints.external.tablet})`]: {
            marginBottom: theme.margins.margin02
        },
        a: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.colors.linkColorSecondary,
            textDecoration: 'none',
            marginTop: theme.margins.margin00,
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    textReset: {
        paddingLeft: 8,
        color: theme.colors.linkColorSecondary,
        ':hover': {
            textDecoration: 'underline'
        }
    },
    notice: {
        marginTop: theme.margins.margin02
    },
    formButtons: {
        marginTop: theme.margins.margin02,
        display: 'flex',
        flexDirection: 'column',
        a: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 6,
            width: '100%',
            height: 40,
            marginTop: theme.margins.margin00,
            border: `1px solid ${theme.colors.gray03}`,
            textDecoration: 'none',
            color: theme.colors.gray06,
            ':hover': {
                border: `1px solid ${theme.colors.gray03}`,
                backgroundColor: theme.colors.gray03,
                color: theme.colors.black01
            },
            ':active': {
                border: `1px solid ${theme.colors.gray01}`,
                backgroundColor: theme.colors.gray01,
                color: theme.colors.black01
            }
        }
    },
    submitBtn: {
        div: {
            display: 'flex'
        }
    }
});
