export const styleSheet = theme => ({
    container: {
        paddingTop: 8,
        borderRadius: 8,
        backgroundColor: theme.colors.gray08,
        boxShadow: `0 2px 8px 0 ${theme.colors.gray02}`
    },
    input: {
        margin: '0 8px',
        input: {
            borderRadius: 6,
            height: '40px',
            fontSize: theme.fonts.fontSizes.size03,
            margin: 0,
            width: '100%',
            ':hover': {
                boxShadow: 'unset',
                ':not(:focus)': {
                    boxShadow: 'unset'
                }
            },
            ':focus': {
                boxShadow: 'unset'
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                fontSize: theme.fonts.fontSizes.size04
            }
        }
    },
    textArea: {
        margin: '0 8px',
        label: {
            color: theme.colors.white01,
            fontWeight: theme.fonts.fontWeights.medium,
            marginBottom: 6
        },
        textarea: {
            background: theme.colors.white01,
            border: 'unset',
            borderRadius: 6,
            fontSize: theme.fonts.fontSizes.size03,
            height: '40px',
            margin: 0,
            padding: '8px 10px',
            width: '100%',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                fontSize: theme.fonts.fontSizes.size04
            },
            ':focus': {
                boxShadow: 'unset'
            }
        }
    },
    error: {
        borderRadius: 3,
        color: theme.colors.red06,
        fontSize: theme.fonts.fontSizes.size00,
        letterSpacing: '1px',
        margin: '10px 8px 8px 8px'
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
        borderRadius: '0 0 6px 6px',
        backgroundColor: theme.colors.gray11,
        padding: '8px 8px'
    },
    button: {
        border: 'unset',
        boxShadow: 'unset',
        height: 30,
        p: {
            letterSpacing: 1
        },
        svg: {
            marginLeft: theme.margins.margin00
        }
    },
    buttonCancel: {
        marginRight: 8,
        p: {
            color: theme.colors.gray04
        },
        ':active': {
            p: {
                color: theme.colors.white01
            }
        },
        ':hover': {
            p: {
                color: theme.colors.white02
            }
        }
    },
    buttonSubmit: {
        ...theme.content.blueButton,
        borderRadius: 6,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: {
            color: theme.colors.white01
        }
    },
    buttonDisabled: {
        cursor: 'not-allowed',
        opacity: 0.7
    }
});
