import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withEventDetails } from 'graphql/audioCalls';
import { generateTabId, get } from 'utils';
import { constants } from 'consts';
import { EventDetailsUI } from './ui';

export class EventDetails extends PureComponent {
    static displayName = 'EventDetailsContainer';

    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        createTabPath: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        id: PropTypes.string,
        tabType: PropTypes.string
    };

    static defaultProps = {
        attachments: [],
        id: null,
        tabType: null
    };

    constructor(props) {
        super(props);

        this.openAttachment = this.openAttachment.bind(this);
    }

    openAttachment(idx) {
        const { attachments, createTabPath, history, id, tabType } = this.props;
        let newTabId = generateTabId({ tabType, id: `${id}`, page: 'pdf', pageId: `${idx}` });
        const att = attachments[idx];
        const eventTabType =
            get(att, 'title') === 'Slides' ? constants.TAB_TYPES.pdfSlides : constants.TAB_TYPES.pdfPresentation;
        newTabId = generateTabId({ id, tabType: eventTabType });
        const tabPath = createTabPath(newTabId);
        history.push(tabPath);
    }

    render() {
        const { ...rest } = this.props;
        // PUT OTHER PROPS AFTER {...rest}
        return <EventDetailsUI {...rest} openAttachment={this.openAttachment} />;
    }
}

export const EventDetailsContainer = compose(
    withUrlContext(['createTabPath', 'audioCallId', 'tabType', 'history']),
    withEventDetails({ fetchPolicy: 'cache-only' }),
    withProps(({ audioCall }) => ({
        ...audioCall,
        conference: get(audioCall, 'eventGroups[0]')
    }))
)(EventDetails);
