import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Span } from 'components/Span';
import { styleSheet } from './stylesheet';

class Emoji extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        symbol: PropTypes.string.isRequired
    };

    static defaultProps = {
        label: null,
        passedStyles: {}
    };

    render() {
        const { label, passedStyles, styles, symbol } = this.props;
        return (
            <Span
                aria-label={label}
                aria-hidden={!!label}
                className="emoji"
                role="img"
                styles={{ ...styles.container, ...passedStyles }}
            >
                {symbol}
            </Span>
        );
    }
}

export const EmojiUI = compose(withStyleSheet(styleSheet))(Emoji);
