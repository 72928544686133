import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vimeo';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { styleSheet } from './stylesheet';

class VimeoPlayer extends PureComponent {
    static propTypes = {
        autoplay: PropTypes.bool.isRequired,
        onClickPreview: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        playerRef: PropTypes.objectOf(PropTypes.any),
        showControls: PropTypes.bool.isRequired,
        showPreview: PropTypes.bool.isRequired,
        url: PropTypes.string.isRequired
    };

    static defaultProps = {
        onClickPreview: undefined,
        passedStyles: {},
        playerRef: undefined
    };

    render() {
        const { autoplay, onClickPreview, passedStyles, playerRef, showControls, showPreview, url } = this.props;
        return (
            <ReactPlayer
                className="vimeo-player"
                controls={showControls}
                height="100%"
                light={showPreview}
                onClickPreview={onClickPreview}
                playing={autoplay}
                ref={playerRef}
                style={passedStyles}
                width="100%"
                url={url}
            />
        );
    }
}

export const VimeoPlayerUI = compose(withStyleSheet(styleSheet))(VimeoPlayer);
