import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { get } from 'utils';
import { graphql } from 'graphql/utils';
import { ruleFragment, streamFragment } from 'graphql/fragments/streams';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withCloneStreamData($streamId: ID) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        rules {
                            ...rule
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data }) => ({
                    stream: get(data, 'streams[0]')
                }),
                skip: ({ streamId }) => !streamId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                mutation CloneStream($input: StreamInput!) {
                    createStream(input: $input) {
                        success
                        stream {
                            ...stream
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        createStream: input =>
                            mutate({
                                variables: { input },
                                update: (proxy, response) => {
                                    const query = gql`
                                        query withCloneStreamDashData($dashboardIds: [ID]!) {
                                            dashboards(filter: { dashboardIds: $dashboardIds }) {
                                                id
                                                streams {
                                                    ...stream
                                                }
                                            }
                                        }
                                        ${streamFragment}
                                    `;
                                    try {
                                        const { dashboards } = proxy.readQuery({
                                            query,
                                            variables: {
                                                dashboardIds: input.dashboardIds
                                            }
                                        });
                                        const dashboard = get(dashboards, '[0]');
                                        const stream = get(response, 'data.createStream.stream');
                                        if (dashboard && stream) {
                                            const streams = get(dashboard, 'streams', []);
                                            proxy.writeQuery({
                                                query,
                                                data: {
                                                    dashboards: [
                                                        {
                                                            ...dashboard,
                                                            streams: [...streams, stream]
                                                        }
                                                    ]
                                                }
                                            });
                                        }
                                    } catch {
                                        // The stream's dashboard isn't in the cache yet, so do nothing
                                    }
                                }
                            })
                                .then(() => {
                                    setStatusBanner('Saved Search cloned successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error cloning search: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
