import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import deepClone from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { anyChanged, get } from 'utils';
import { withData } from './data';
import { CustomStreamFormUI } from './ui';

const CUSTOM_SOURCE_TYPES = [
    { label: 'Google Sheet', value: 'gsheet' },
    { label: 'CSV', value: 'csv' },
    { label: 'API', value: 'api' },
    { label: 'External', value: 'external' }
];

const CUSTOM_TEMPLATE_TYPES = [
    { label: 'Card', value: 'basic_card' },
    { label: 'Row', value: 'basic_row' }
];

const getTemplateOptions = sampleRecords => {
    return !sampleRecords
        ? []
        : Object.keys(sampleRecords).map(name => ({
              value: name,
              label: name,
              sampleValues: sampleRecords[name]
          }));
};

const getDataCollectionOptions = dataCollections => {
    return (dataCollections || []).map(d => ({
        label: d.name,
        value: d.id
    }));
};

export class CustomStreamForm extends PureComponent {
    static displayName = 'CustomStreamFormContainer';

    static propTypes = {
        dataCollection: PropTypes.shape({
            processingStatus: PropTypes.string,
            processingStatusMessage: PropTypes.string
        }),
        dataCollections: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                collectionType: PropTypes.string,
                name: PropTypes.string
            })
        ),
        dataCollectionId: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        reprocessDataCollection: PropTypes.func.isRequired,
        sampleRecords: PropTypes.objectOf(PropTypes.any),
        setCollection: PropTypes.func.isRequired,
        setTemplate: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        template: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        dataCollectionId: null,
        dataCollection: null,
        dataCollections: [],
        sampleRecords: null,
        styles: undefined,
        streamId: null,
        template: null
    };

    constructor(props) {
        super(props);
        this.checkFormDisabled = this.checkFormDisabled.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getTemplateOptions = memoize(getTemplateOptions);
        this.getDataCollectionOptions = memoize(getDataCollectionOptions);

        this.state = {
            dataCollectionType: 'gsheet',
            templateType: 'basic_card'
        };
    }

    componentDidMount() {
        const { onChange, setCollection, setTemplate, streamId } = this.props;
        const { dataCollectionType, templateType } = this.state;

        this.checkFormDisabled();
        if (streamId === 'new') {
            setCollection('temp', dataCollectionType, {}).then(({ dataCollectionId }) => {
                onChange({ name: 'dataCollectionId', value: dataCollectionId });
            });
            setTemplate('temp', templateType, {}).then(({ templateId }) => {
                onChange({ name: 'streamMatchTemplateId', value: templateId });
            });
        } else {
            this.updateConfigs();
        }
    }

    componentDidUpdate(prevProps) {
        this.checkFormDisabled();
        if (
            anyChanged(
                ['dataCollectionId', 'dataCollectionLoading', 'streamMatchTemplateId', 'templateLoading'],
                prevProps,
                this.props
            )
        ) {
            this.updateConfigs();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    checkFormDisabled() {
        const { dataCollection, onChange } = this.props;
        const { dataCollectionType, templateType } = this.state;
        const isDisabled =
            get(dataCollection, 'processingStatus') !== 'complete' || !dataCollectionType || !templateType;
        if (isDisabled !== this.isDisabled) {
            onChange({ name: 'isDisabled', value: isDisabled });
            this.isDisabled = isDisabled;
        }
    }

    updateConfigs() {
        const { dataCollection, template } = this.props;
        if (dataCollection) {
            this.setState({
                dataCollectionType: get(dataCollection, 'collectionType'),
                [get(dataCollection, 'collectionType')]: omit(get(dataCollection, 'configuration'), ['__typename'])
            });
        }
        if (template) {
            this.setState({
                templateType: get(template, 'templateType'),
                [get(template, 'templateType')]: omit(get(template, 'configuration'), ['__typename'])
            });
        }
    }

    onChange({ name, value }) {
        const { onChange, setCollection, setTemplate } = this.props;
        this.setState(
            prevState => {
                const updatedState = set(deepClone(prevState), name, value);
                // Copy the template config to the new one
                if (name === 'templateType' && ['basic_card', 'basic_row'].includes(value)) {
                    set(updatedState, value, get(prevState, get(prevState, 'templateType')));
                }
                return updatedState;
            },
            () => {
                const { dataCollectionType, templateType } = this.state;
                if (
                    (name === 'dataCollectionType' && value !== 'external') ||
                    ['csv', 'gsheet', 'api'].some(t => name.startsWith(t))
                ) {
                    const config = get(this.state, dataCollectionType);
                    setCollection(get(config, 'url', 'temp'), dataCollectionType, {
                        [`${dataCollectionType}Configuration`]: config
                    }).then(({ dataCollectionId }) => {
                        onChange({ name: 'dataCollectionId', value: dataCollectionId });
                    });
                }

                if (name === 'templateType' || ['basic_card', 'basic_row'].some(t => name.startsWith(t))) {
                    setTemplate('temp', templateType, get(this.state, templateType)).then(({ templateId }) => {
                        onChange({ name: 'streamMatchTemplateId', value: templateId });
                    });
                }

                if (name === 'dataCollectionId') {
                    onChange({ name, value });
                }
            }
        );
    }

    render() {
        const {
            dataCollection,
            dataCollections,
            dataCollectionId,
            reprocessDataCollection,
            sampleRecords,
            styles
        } = this.props;
        const { dataCollectionType, templateType } = this.state;
        return (
            <CustomStreamFormUI
                dataCollectionType={dataCollectionType}
                dataCollectionTypes={CUSTOM_SOURCE_TYPES}
                dataCollectionId={dataCollectionId}
                templateOptions={this.getTemplateOptions(sampleRecords)}
                templateType={templateType}
                templateTypes={CUSTOM_TEMPLATE_TYPES}
                dataCollectionConfig={get(this.state, dataCollectionType)}
                dataCollectionOptions={this.getDataCollectionOptions(dataCollections)}
                templateConfig={get(this.state, templateType)}
                onChange={this.onChange}
                reprocessDataCollection={reprocessDataCollection}
                sampleLoading={
                    dataCollectionId && !['complete', 'error'].includes(get(dataCollection, 'processingStatus'))
                }
                sampleStatus={get(dataCollection, 'processingStatus')}
                sampleError={get(dataCollection, 'processingStatusMessage')}
                styles={styles}
            />
        );
    }
}

export const CustomStreamFormContainer = compose(withData())(CustomStreamForm);
