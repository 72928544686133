import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { Graphic } from 'components/Graphic';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { VideoPlayer } from 'components/VideoPlayer';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS, VIDEO_TYPES } from 'consts';
import { getHighlightColor } from 'utils';
import { NewsActionMenu } from '../NewsActionMenu';
import { styleSheet } from './stylesheet';

class NewsHighlights extends PureComponent {
    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        filterKey: PropTypes.string.isRequired,
        hasHighlights: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        manageShareSettings: PropTypes.func.isRequired,
        matches: PropTypes.arrayOf(PropTypes.any).isRequired,
        onSelectHighlight: PropTypes.func.isRequired,
        openExport: PropTypes.func.isRequired,
        openVideoModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        setFilter: PropTypes.func.isRequired,
        setSort: PropTypes.func.isRequired,
        setVisibility: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        sortKey: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        userId: PropTypes.string,
        videoPlayerRef: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {},
        userId: undefined,
        videoPlayerRef: undefined
    };

    constructor(props) {
        super(props);

        this.renderSettings = this.renderSettings.bind(this);
    }

    renderHeader() {
        const { styles, theme, filterKey, sortKey, setFilter, setSort, matches, openExport } = this.props;
        return (
            <Div styles={styles.header}>
                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                    <Dropdown
                        name="filterHighlights"
                        size={1}
                        cancelClassName="tab-tooltip"
                        disableHoverOpen
                        styles={styles.filterDropdown}
                        onChange={setFilter}
                        options={[
                            { value: 'all', label: 'All Highlights' },
                            { value: 'team', label: 'Team Highlights' },
                            { value: 'me', label: 'My Highlights' }
                        ]}
                        value={filterKey}
                    />
                </WithPermission>
                <Dropdown
                    name="sortHighlights"
                    size={1}
                    cancelClassName="tab-tooltip"
                    disableHoverOpen
                    styles={styles.sortDropdown}
                    onChange={setSort}
                    options={[
                        { value: 'chron', label: 'Article Order' },
                        { value: 'newest', label: 'Newest' }
                    ]}
                    value={sortKey}
                />
                <Div styles={styles.spacer} />
                <Hint
                    xOffset={-70}
                    text={matches && matches.length > 0 ? 'Export Highlights to PDF' : 'No Highlights to Export'}
                    styles={matches && matches.length > 0 ? styles.settingsButton : styles.settingsButtonDisabled}
                    onClick={matches && matches.length > 0 ? openExport : undefined}
                    name="open-content-pdf-highlight-exporter"
                >
                    <Icon type="pdf" color={theme.colors.gray04} />
                </Hint>
                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                    <Tooltip
                        xOffset={0}
                        yOffset={-2}
                        isEnabled
                        useElementOffsetRight
                        useElementOffsetTop
                        growLeft
                        content={this.renderSettings}
                        persistOnMouseExit
                        useOutsideClickHandler
                    >
                        {({ showTooltip }) => (
                            <Hint
                                xOffset={-50}
                                text="Share Settings"
                                styles={styles.settingsButton}
                                onClick={showTooltip}
                            >
                                <Icon type="gear02" color={theme.colors.gray04} />
                            </Hint>
                        )}
                    </Tooltip>
                </WithPermission>
            </Div>
        );
    }

    renderSettings() {
        const { styles, theme, manageShareSettings, shareBookmarks } = this.props;
        const shareValue = shareBookmarks ? 'share' : 'private';
        return (
            <Div styles={styles.settings} className="tab-tooltip">
                <Div styles={styles.settingsHeader}>
                    <Text size={3} weight="medium">
                        Your Highlights
                    </Text>
                    <Text size={1} styles={styles.headerSubtext}>
                        Applied to this article only
                    </Text>
                </Div>
                <MultiSelect styles={styles.options} selected={[shareValue]} required onChange={manageShareSettings}>
                    <Option styles={styles.option} type="radio" value="share">
                        <Icon type="eye" color={theme.colors.gray04} />
                        <Div styles={styles.optionText}>
                            <Text styles={styles.optionTitle} size={1} weight="medium">
                                Share with team
                            </Text>
                            <Text size={1}>
                                Your team can see your highlights on <strong>this article</strong>.
                            </Text>
                        </Div>
                    </Option>
                    <Option styles={styles.option} type="radio" value="private">
                        <Icon type="eyeStrike" color={theme.colors.gray04} />
                        <Div styles={styles.optionText}>
                            <Text styles={styles.optionTitle} size={1} weight="medium">
                                Keep private
                            </Text>
                            <Text size={1}>
                                Only you can see your highlights on <strong> this article</strong>.
                            </Text>
                        </Div>
                    </Option>
                </MultiSelect>
            </Div>
        );
    }

    renderHighlights() {
        const {
            containerRef,
            filterKey,
            matches,
            onSelectHighlight,
            setVisibility,
            sortKey,
            styles,
            theme,
            userId
        } = this.props;

        return matches.map(h => {
            const { created, creatorName, highlight, highlightColor, id, note, shared, tags, userId: creatorId } = h;
            const highlightBorder = getHighlightColor(highlightColor)?.dark;
            const highlightStyles = {
                ...styles.highlightedText,
                backgroundColor: getHighlightColor(highlightColor)?.color,
                borderBottom: `1px solid ${highlightBorder}`
            };

            return (
                <Div styles={styles.highlight} key={id}>
                    {userId === creatorId && (
                        <NewsActionMenu
                            containerRef={containerRef}
                            bookmarkId={id}
                            clickOpen
                            highlightsFilterKey={filterKey}
                            highlightsSortKey={sortKey}
                            styles={styles.actionMenu}
                        />
                    )}
                    <Div styles={highlightStyles}>
                        <Div styles={styles.highlightText} onClick={() => onSelectHighlight(id)}>
                            <RawHTML className="highlightText" html={highlight} />
                        </Div>
                    </Div>
                    <Div styles={styles.note}>
                        <Div styles={styles.noteHeader}>
                            <Text size={3} weight="medium">
                                {creatorName}
                            </Text>
                            <Text size={1} styles={styles.noteCreated}>
                                {new XDate(created).toString('h:mm TT MMM dS, yyyy')}
                            </Text>
                            {userId === creatorId && (
                                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                                    <Hint
                                        text={shared ? 'Visible to your team' : 'Hidden from your team'}
                                        styles={styles.sharedIndicator}
                                        growLeft
                                        fromLeft
                                        yOffset={-19}
                                        xOffset={-6}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setVisibility({ bookmarkId: id, shared: !shared });
                                        }}
                                    >
                                        <Icon type={shared ? 'eye' : 'eyeStrike'} color={theme.colors.gray04} />
                                    </Hint>
                                </WithPermission>
                            )}
                        </Div>
                        {note && (
                            <Text size={3} styles={styles.noteText}>
                                {note}
                            </Text>
                        )}
                        {tags && tags.length > 0 && (
                            <Div styles={styles.tags}>
                                {tags.map(tag => (
                                    <Div
                                        styles={{
                                            ...styles.tag,
                                            border: `1px solid ${getHighlightColor(highlightColor)?.dark}`,
                                            color: getHighlightColor(highlightColor)?.dark02,
                                            backgroundColor: getHighlightColor(highlightColor)?.color
                                        }}
                                        key={tag}
                                    >
                                        <Text size={0}>{tag}</Text>
                                    </Div>
                                ))}
                            </Div>
                        )}
                    </Div>
                </Div>
            );
        });
    }

    renderEmptyMessage() {
        const { hasHighlights, openVideoModal, styles, theme, videoPlayerRef } = this.props;
        return (
            <Div styles={styles.emptyMessage}>
                <Icon style={styles.emptyIcon} styles={styles.emptyIcon} type="highlight" color={theme.colors.gray04} />
                <Text size={3}>No matching highlights for this article.</Text>
                <Text size={1}>Highlight text to create a highlight.</Text>
                <Graphic styles={styles.emptyGraphic} type="backdrop" color={theme.colors.gray04} opacity={0.1} />
                {!hasHighlights && (
                    <Fragment>
                        <Text size={1} styles={styles.videoDescription}>
                            Click the video below to learn about
                            <br />
                            Aiera&apos;s Highlights feature
                        </Text>
                        <VideoPlayer
                            onClickPreview={openVideoModal}
                            playerRef={videoPlayerRef}
                            showControls={false}
                            showPreview
                            styles={styles.videoPlayer}
                            videoType={VIDEO_TYPES.workspaceHighlights}
                        />
                    </Fragment>
                )}
            </Div>
        );
    }

    render() {
        const { matches, passedStyles, styles, loading } = this.props;

        if (loading && (!matches || matches.length === 0)) {
            return (
                <Div styles={styles.loader}>
                    <LoaderLogo height={60} />
                </Div>
            );
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {this.renderHeader()}
                <Div styles={styles.scrollContainer}>
                    {matches && matches.length > 0 ? this.renderHighlights() : this.renderEmptyMessage()}
                </Div>
            </Div>
        );
    }
}

export const NewsHighlightsUI = compose(withStyleSheet(styleSheet))(NewsHighlights);
