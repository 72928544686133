export const styleSheet = theme => ({
    h1: {
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size06,
        fontWeight: theme.fonts.fontWeights.medium,
        lineHeight: theme.fonts.lineHeights.size06
    },
    h2: {
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size05,
        fontWeight: theme.fonts.fontWeights.medium
    },
    h3: {
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size04,
        fontWeight: theme.fonts.fontWeights.light
    }
});
