import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FiltersOldUI } from './ui';

function ensureLastFilterEmpty(filters) {
    if (filters && filters.length) {
        const { type, operator, value } = filters.slice(-1)[0] || {};
        if (type && operator && ![undefined, null].includes(value)) {
            return [...filters, null];
        }
        return filters;
    }
    return [null];
}

export class FiltersOld extends PureComponent {
    static displayName = 'FiltersOldContainer';

    static propTypes = {
        filterType: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        label: undefined,
        onChange: null,
        styles: {},
        value: null
    };

    constructor(props) {
        super(props);

        this.addFilter = this.addFilter.bind(this);
        this.checkValue = this.checkValue.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.changeFilter = this.changeFilter.bind(this);

        this.state = {
            value: ensureLastFilterEmpty()
        };
    }

    componentDidMount() {
        this.checkValue();
    }

    componentDidUpdate({ value: prevValue }) {
        const { value } = this.props;
        if (value !== prevValue) {
            this.checkValue();
        }
    }

    checkValue() {
        // We need to track the value in state and in props
        // in order to properly handle extra filters in the array
        // that are no completely filled out. The parent can now
        // filter nulls out (needed to send to server) and we'll
        // keep them here in state so that the user can fill them in.
        //
        // If the values in the props change and we can't find them all
        // in the current state value, then we need to update the state.
        let { value } = this.props;
        value = value || [];
        const { value: stateValue } = this.state;
        if (!value.every(v => stateValue.includes(v)) || !stateValue.every(v => value.includes(v))) {
            this.setState({
                value: ensureLastFilterEmpty(value)
            });
        }
    }

    addFilter() {
        const { name, onChange } = this.props;
        this.setState(
            ({ value }) => ({ value: ensureLastFilterEmpty([...(value || []), null]) }),
            () => {
                const { value } = this.state;
                if (onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    removeFilter(index) {
        const { name, onChange } = this.props;
        let filterValue = {};
        this.setState(
            ({ value }) => {
                const newValue = value.slice();
                filterValue = value[index];
                newValue.splice(index, 1);
                return { value: newValue };
            },
            () => {
                const { value } = this.state;
                // Only trigger an onChange if the filter we removed had a full value
                if (filterValue && filterValue.type && filterValue.operator && filterValue.value && onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    changeFilter(index, { value: filterValue }) {
        const { name, onChange } = this.props;
        this.setState(
            ({ value }) => {
                const newValue = value.slice();
                newValue[index] = filterValue;
                return { value: ensureLastFilterEmpty(newValue) };
            },
            () => {
                const { value } = this.state;
                if (onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    render() {
        const { filterType, label, name, styles } = this.props;
        const { value } = this.state;
        return (
            <FiltersOldUI
                name={name}
                label={label}
                styles={styles}
                filters={value}
                filterType={filterType}
                addFilter={this.addFilter}
                removeFilter={this.removeFilter}
                changeFilter={this.changeFilter}
            />
        );
    }
}

export const FiltersOldContainer = compose()(FiltersOld);
