import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import regular from 'styles/fonts/Rubik-Regular.ttf';
import medium from 'styles/fonts/Rubik-Medium.ttf';
import bold from 'styles/fonts/Rubik-Bold.ttf';
import serif from 'styles/fonts/CharisSIL-B.ttf';
import { toDateTimeString, get } from 'utils';
import { withEventDetails } from 'graphql/audioCalls';
import { generatePdfDocument } from './pdf';
import { generateWordDocument } from './doc';
import { AskAieraEventQuestionsUI } from './ui';
import { withData } from './data';

export class AskAieraEventQuestions extends PureComponent {
    static displayName = 'AskAieraEventQuestionsContainer';

    static propTypes = {
        eventDate: PropTypes.string,
        title: PropTypes.string,
        eventId: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(PropTypes.any),
        loading: PropTypes.bool.isRequired,
        onSelectAnswer: PropTypes.func
    };

    static defaultProps = {
        onSelectAnswer: undefined,
        questions: [],
        eventDate: undefined,
        title: 'Transcript Title N/A'
    };

    constructor(props) {
        super(props);

        this.loadFonts = this.loadFonts.bind(this);
        this.onDownloadPdf = this.onDownloadPdf.bind(this);
        this.onDownloadWord = this.onDownloadWord.bind(this);

        this.state = {
            loadingPdf: false,
            loadingWord: false
        };
    }

    componentDidMount() {
        this.loadFonts();
    }

    loadFonts() {
        import('@react-pdf/renderer').then(({ Font }) => {
            let attempts = 0;
            return new Promise((resolve, reject) => {
                let hasSans = false;
                let hasSerif = false;
                Font.register({
                    family: 'Rubik',
                    format: 'truetype',
                    fonts: [{ src: regular }, { src: medium, fontWeight: 500 }, { src: bold, fontWeight: 700 }]
                });

                Font.register({
                    family: 'CharisSIL',
                    format: 'truetype',
                    src: serif,
                    fontWeight: 700
                });
                Font.load({ fontFamily: 'CharisSIL' }).then(() => {
                    hasSerif = true;
                });
                Font.load({ fontFamily: 'Rubik' }).then(() => {
                    hasSans = true;
                });

                const fontsLoaded = () => {
                    this.fontLoader = setTimeout(() => {
                        attempts += 1;
                        if (attempts < 5) {
                            if (hasSans && hasSerif) {
                                resolve();
                            } else {
                                fontsLoaded();
                            }
                        } else {
                            reject();
                        }
                    }, 1000);
                };

                fontsLoaded();
            });
        });
    }

    onDownloadPdf(styles) {
        const { title, eventDate, questions } = this.props;
        const answeredQuestions = questions.filter(({ answer }) => answer && answer.length > 0);
        if (answeredQuestions && answeredQuestions.length > 0) {
            this.setState({ loadingPdf: true }, () => {
                generatePdfDocument({
                    onComplete: () => this.setState({ loadingPdf: false }),
                    styles,
                    eventDate: toDateTimeString(eventDate),
                    questions: answeredQuestions,
                    title
                });
            });
        }
    }

    onDownloadWord() {
        const { title, eventDate, questions } = this.props;
        const answeredQuestions = questions.filter(({ answer }) => answer && answer.length > 0);
        if (answeredQuestions && answeredQuestions.length > 0) {
            this.setState({ loadingWord: true }, () => {
                generateWordDocument({
                    onComplete: () => this.setState({ loadingWord: false }),
                    eventDate: toDateTimeString(eventDate),
                    questions: answeredQuestions,
                    title
                });
            });
        }
    }

    render() {
        const { questions, eventId, loading, onSelectAnswer } = this.props;
        const { loadingPdf, loadingWord } = this.state;
        return (
            <AskAieraEventQuestionsUI
                onSelectAnswer={onSelectAnswer}
                eventId={eventId}
                questions={questions}
                loadingPdf={loadingPdf}
                loadingWord={loadingWord}
                loading={loading}
                onDownloadPdf={this.onDownloadPdf}
                onDownloadWord={this.onDownloadWord}
            />
        );
    }
}

export const AskAieraEventQuestionsContainer = compose(
    withEventDetails({
        fetchPolicy: 'cache-only'
    }),
    withPropsOnChange(['audioCall', 'eventDate', 'title'], ({ audioCall, title, eventDate }) => ({
        title: get(audioCall, 'title', title),
        eventDate: get(audioCall, 'callDate', eventDate)
    })),
    withData()
)(AskAieraEventQuestions);
