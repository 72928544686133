import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class StreamRow extends PureComponent {
    static propTypes = {
        containerStyles: PropTypes.objectOf(PropTypes.any).isRequired,
        name: PropTypes.string,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        sentiment: PropTypes.string,
        showHighlight: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        name: undefined,
        onClick: null,
        passedStyles: {},
        sentiment: null
    };

    render() {
        const {
            styles,
            passedStyles,
            containerStyles,
            name,
            onClick,
            sentiment,
            showHighlight,
            children,
            theme
        } = this.props;
        const highlightStyles = showHighlight ? styles.highlight : {};
        const cardStyles = styles.primaryCard;
        const mobileCardStyles = styles.primaryCardMobile;
        let sentimentStyles = styles.sentiment;
        if (sentiment === 'neg') sentimentStyles = { ...sentimentStyles, ...styles.sentimentNeg };
        if (sentiment === 'pos') sentimentStyles = { ...sentimentStyles, ...styles.sentimentPos };

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Div styles={{ ...styles.container, ...containerStyles }} data-tname={name}>
                        <Div
                            styles={{ ...(m ? mobileCardStyles : cardStyles), ...highlightStyles, ...passedStyles }}
                            onClick={onClick}
                        >
                            {sentiment && <Div styles={sentimentStyles} />}
                            {children}
                        </Div>
                    </Div>
                )}
            </MediaQuery>
        );
    }
}

export const StreamRowUI = compose(withStyleSheet(styleSheet))(StreamRow);
