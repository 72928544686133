export const styleSheet = theme => ({
    container: {
        paddingTop: 'env(safe-area-inset-top)',
        display: 'flex',
        flex: 1,
        height: '100%',
        overflow: 'hidden'
    },
    sideBar: {
        display: 'flex',
        width: '170px',
        flexShrink: 0
    },
    wideScreenContent: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.white02,
        borderLeft: `1px solid ${theme.colors.gray05}`,
        color: theme.colors.gray05,
        letterSpacing: '1px'
    },
    contentWrapper: {
        backgroundColor: theme.colors.gray12,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    activeContent: {
        minHeight: 0,
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        [`@media(min-width: ${theme.breakpoints.internal.mobileEdge})`]: {
            borderRadius: '6px 0 0 6px'
        }
    },
    decorationWrapper: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '1000px',
        [`@media(min-width: 1570px)`]: {
            width: '1000px',
            minWidth: '1000px',
            flexShrink: 0
        }
    },
    subContentWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    pageContainer: {
        position: 'relative',
        display: 'flex',
        overflowY: 'auto',
        flexDirection: 'column',
        flexGrow: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            WebkitOverflowScrolling: 'touch',
            overflowY: 'scroll'
        }
    },
    pageContainerLocked: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%'
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0
    },
    pageNoFooter: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 0,
        height: '100%'
    },
    streaming: {
        maxWidth: '450px',
        width: '100%',
        background: '#281e2f',
        color: 'rgba(255, 255, 255, 0.5)',
        padding: theme.margins.margin03,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '15px',
        fontWeight: 100,
        boxShadow: 'inset 20px 0 40px 0 rgba(0, 0, 0, 0.1)'
    },
    mobileStatusBarBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 'env(safe-area-inset-top)',
        backgroundColor: theme.colors.gray03,
        zIndex: 8
    }
});
