import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { CardEquities } from 'components/CardEquities';
import { CardLockButton } from 'components/CardLockButton';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { toDateString, toDateTimeString, get, generateTabURL } from 'utils';
import { styleSheet } from './stylesheet';

class FilingCard extends PureComponent {
    static propTypes = {
        arrivalDate: PropTypes.string,
        bookmarkId: PropTypes.string,
        dashboardType: PropTypes.string.isRequired,
        displayMode: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
        formName: PropTypes.string,
        formNumber: PropTypes.string,
        formShortName: PropTypes.string,
        highlights: PropTypes.string,
        id: PropTypes.string,
        isAmendment: PropTypes.bool,
        matchId: PropTypes.string.isRequired,
        officialUrl: PropTypes.string,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.object),
        pathname: PropTypes.string.isRequired,
        primaryEquity: PropTypes.objectOf(PropTypes.any),
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        to: PropTypes.string
    };

    static defaultProps = {
        arrivalDate: undefined,
        bookmarkId: undefined,
        events: [],
        formName: null,
        formNumber: null,
        formShortName: null,
        highlights: null,
        id: undefined,
        isAmendment: false,
        officialUrl: null,
        onClick: null,
        passedStyles: {},
        primaryEquity: {},
        score: undefined,
        streamId: undefined,
        to: null
    };

    renderEvents(restricted) {
        const { events, pathname, styles, theme } = this.props;
        return events && events.length
            ? events.map(event => (
                  <InternalLink
                      key={get(event, 'id')}
                      to={restricted ? undefined : generateTabURL({ pathname, eventId: get(event, 'id') })}
                      styles={{ ...styles.eventsContainer, ...(restricted ? styles.eventsContainerRestricted : {}) }}
                  >
                      <Icon type="calendar" color={theme.colors.gray04} />
                      <Text styles={styles.eventTitle}>{get(event, 'title')}</Text>
                      <Text styles={styles.eventDate}>{toDateString(get(event, 'callDate'))}</Text>
                  </InternalLink>
              ))
            : null;
    }

    render() {
        const {
            arrivalDate,
            bookmarkId,
            displayMode,
            dashboardType,
            formName,
            formNumber,
            formShortName,
            highlights,
            id,
            isAmendment,
            matchId,
            officialUrl,
            onClick,
            passedStyles,
            primaryEquity,
            score,
            streamId,
            styles,
            to
        } = this.props;
        const isCompact = displayMode === STREAM_DISPLAY_MODES.compact;
        const isComfortable = displayMode === STREAM_DISPLAY_MODES.comfortable;
        const containerStyles = isCompact ? styles.compact : styles.container;
        const equityName = get(primaryEquity, 'commonName');
        const monitorTitle = equityName ? `${equityName} ${formShortName}` : formShortName;
        const title = dashboardType === 'equity' ? formShortName : monitorTitle;
        const footerStyles = isCompact ? styles.footerCompact : styles.footer;
        const cardStyles = isCompact ? styles.cardContentCompact : styles.cardContent;

        return (
            <WithPermission permissions={[PERMISSIONS.unlockedEquityFilings]}>
                {({ restricted, isLoading }) => (
                    <Card
                        containerStyles={isCompact ? styles.containerCompact : undefined}
                        externalTo={restricted || isLoading ? undefined : officialUrl}
                        name="filing-card"
                        onClick={isLoading ? undefined : onClick}
                        styles={{
                            ...containerStyles,
                            ...passedStyles,
                            ...(restricted || isLoading ? styles.containerLocked : {})
                        }}
                        to={restricted || isLoading ? undefined : to}
                    >
                        {({ isHovering }) => (
                            <Fragment>
                                <Div styles={cardStyles}>
                                    {isHovering && false && (
                                        <StreamActionMenu
                                            bookmarkId={bookmarkId}
                                            highlight={highlights || formName}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="filing"
                                            url={to}
                                        />
                                    )}
                                    {dashboardType !== 'equity' && !isComfortable && !isCompact && (
                                        <CardEquities equities={[primaryEquity]} />
                                    )}
                                    <CardHeader
                                        styles={styles.cardHeader}
                                        title={title}
                                        subtext={isAmendment ? 'Amendment' : undefined}
                                    />
                                    {displayMode === STREAM_DISPLAY_MODES.default && highlights && (
                                        <Div styles={styles.content}>
                                            <RawHTML html={highlights} />
                                        </Div>
                                    )}
                                </Div>
                                <Div styles={footerStyles}>
                                    <Text size={3}>
                                        {`Filed ${toDateTimeString(arrivalDate, false, false, 'MMM d, yyyy')}`}
                                    </Text>
                                    {restricted || isLoading ? (
                                        <CardLockButton upgradeModalId="filings" />
                                    ) : (
                                        <Div styles={styles.form}>
                                            <Text weight="medium">{`Form ${formNumber}`}</Text>
                                        </Div>
                                    )}
                                </Div>
                                {displayMode === STREAM_DISPLAY_MODES.default && this.renderEvents(restricted)}
                            </Fragment>
                        )}
                    </Card>
                )}
            </WithPermission>
        );
    }
}

export const FilingCardUI = compose(withStyleSheet(styleSheet))(FilingCard);
