import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { AskAieraFormUI } from './ui';
import { withData } from './data';

export class AskAieraForm extends PureComponent {
    static displayName = 'AskAieraFormContainer';

    static propTypes = {
        eventId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        askQuestion: PropTypes.func.isRequired,
        questionBank: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        eventId: undefined,
        questionBank: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onChangeQuestion = this.onChangeQuestion.bind(this);
        this.onAskQuestion = this.onAskQuestion.bind(this);

        this.textareaRef = createRef();

        this.state = {
            question: ''
        };
    }

    onChangeQuestion({ value }, dropdown) {
        this.setState({ question: value }, () => {
            if (dropdown && this.textareaRef.current) {
                this.textareaRef.current.focus();
            }
        });
    }

    onAskQuestion() {
        const { askQuestion, eventId } = this.props;
        const { question } = this.state;
        if (question.length > 0) {
            askQuestion(question).then(() => {
                this.setState({ question: '' });
                if (window.heap) {
                    window.heap.track('AskAiera Submit', {
                        question,
                        eventId
                    });
                }
            });
        }
    }

    render() {
        const { styles, questionBank } = this.props;
        const { question } = this.state;
        return (
            <AskAieraFormUI
                question={question}
                styles={styles}
                onAskQuestion={this.onAskQuestion}
                onChangeQuestion={this.onChangeQuestion}
                questionBank={questionBank}
                textareaRef={this.textareaRef}
            />
        );
    }
}

export const AskAieraFormContainer = compose(withData())(AskAieraForm);
