import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NotFoundUI } from './ui';

export class NotFound extends PureComponent {
    static displayName = 'NotFoundContainer';

    static propTypes = {
        backButtonStyles: PropTypes.objectOf(PropTypes.any),
        showBack: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        backButtonStyles: undefined,
        showBack: true,
        styles: undefined
    };

    render() {
        const { backButtonStyles, showBack, styles } = this.props;
        return <NotFoundUI backButtonStyles={backButtonStyles} showBack={showBack} styles={styles} />;
    }
}

export const NotFoundContainer = compose()(NotFound);
