import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { colors } from 'styles/colors';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { LoaderLogo } from 'components/LoaderLogo';

import { styleSheet } from './stylesheet';

class ActionButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        isHovering: PropTypes.bool.isRequired,
        loaderColor: PropTypes.string,
        loading: PropTypes.bool,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tabIndex: PropTypes.number,
        to: PropTypes.string
    };

    static defaultProps = {
        disabled: false,
        loaderColor: colors.orange02,
        loading: false,
        name: undefined,
        onClick: undefined,
        onMouseEnter: undefined,
        onMouseLeave: undefined,
        passedStyles: {},
        size: 1,
        to: null,
        tabIndex: undefined
    };

    render() {
        const {
            styles,
            passedStyles,
            to,
            isHovering,
            name,
            onClick,
            onMouseEnter,
            onMouseLeave,
            children,
            loading,
            loaderColor,
            disabled,
            tabIndex,
            size
        } = this.props;
        let content = children;
        let buttonStyles = {
            ...styles.container,
            ...(disabled ? styles.disabled : {}),
            ...(size === 2 ? styles.containerSize02 : {}),
            ...passedStyles
        };

        if (to) {
            buttonStyles = {
                a: {
                    ...buttonStyles
                }
            };
            content = <Link to={to}>{children}</Link>;
        }

        if (loading) {
            content = <LoaderLogo color={loaderColor} height={size === 2 ? 40 : 30} type={2} />;
        }

        return (
            <Div
                tabIndex={tabIndex}
                data-tname={name}
                styles={buttonStyles}
                onClick={disabled || loading ? undefined : onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {typeof content === 'function' ? content({ isHovering }) : content}
            </Div>
        );
    }
}

export const ActionButtonUI = compose(withStyleSheet(styleSheet))(ActionButton);
