import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import XDate from 'xdate';
import { Link, generatePath } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Hint } from 'components/Hint';
import { Checkbox } from 'components/Checkbox';
import { WithPermission } from 'components/WithPermission';
import { toDurationString, toDateTimeString, get, generateTabURL } from 'utils';
import { PERMISSIONS } from 'consts';
import { routes } from 'routes';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

const RATE_MAP = ['1.00x', '1.10x', '1.25x', '1.50x', '1.75x', '2.00x', '2.50x'];

class Player extends PureComponent {
    static propTypes = {
        dragTime: PropTypes.number,
        isPublic: PropTypes.bool,
        loadingAudio: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        onDragPlayerKnob: PropTypes.func.isRequired,
        openUpgradeModal: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        processingAudio: PropTypes.bool.isRequired,
        seekBack: PropTypes.func.isRequired,
        seekForward: PropTypes.func.isRequired,
        seekStart: PropTypes.func.isRequired,
        seekToClick: PropTypes.func.isRequired,
        showEventInfo: PropTypes.bool,
        showVolume: PropTypes.bool.isRequired,
        startAudio: PropTypes.func.isRequired,
        stopAudio: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        timelineRef: PropTypes.objectOf(PropTypes.any).isRequired,
        timelineOnly: PropTypes.bool.isRequired,
        toggleAutoPlay: PropTypes.func.isRequired,
        togglePlaybackRate: PropTypes.func.isRequired,
        viewingNoEvent: PropTypes.bool,
        willAutoPlay: PropTypes.bool.isRequired
    };

    static defaultProps = {
        dragTime: null,
        isPublic: false,
        loadingAudio: false,
        mediaPlayer: null,
        showEventInfo: false,
        viewingNoEvent: false
    };

    renderAudioPlayer() {
        const {
            styles,
            isPublic,
            loadingAudio,
            mediaPlayer,
            openUpgradeModal,
            processingAudio,
            startAudio,
            theme,
            seekBack,
            seekForward,
            seekStart,
            stopAudio,
            togglePlaybackRate
        } = this.props;
        const seekableStyles = mediaPlayer.seekable ? styles.seek : { ...styles.seek, ...styles.disabled };
        const isAtEnd = mediaPlayer.duration - mediaPlayer.currentTime < 5;
        const catchingUp = isAtEnd && parseFloat(mediaPlayer.playbackRate) > 1;
        const playButton = (
            <Div
                data-tname="play-event-audio-audio-bar"
                styles={styles.audioControl}
                onClick={mediaPlayer.listening ? stopAudio : !processingAudio && !loadingAudio ? startAudio : undefined}
            >
                <Icon
                    type={mediaPlayer.listening ? 'pause' : processingAudio ? 'hourglass' : 'play'}
                    color={theme.colors.white01}
                />
                <MediaQuery minWidth={601}>
                    <Text weight="medium" size={1}>
                        {mediaPlayer.listening
                            ? 'Pause Audio'
                            : mediaPlayer.status === 'paused'
                            ? 'Resume Audio'
                            : processingAudio
                            ? 'Processing...'
                            : loadingAudio
                            ? 'Loading...'
                            : 'Listen Now'}
                    </Text>
                </MediaQuery>
            </Div>
        );

        const playbackTooltip = ({ hideTooltip }) => {
            const setPlayback = index => {
                hideTooltip();
                togglePlaybackRate(index);
            };

            return (
                <Div className="tab-tooltip" styles={styles.playbackTooltip}>
                    <Text styles={styles.playbackTooltipHeader} size={0} weight="medium" uppercase>
                        Playback Rate
                    </Text>
                    <Div styles={styles.playbackRates}>
                        {RATE_MAP.map((rate, index) => (
                            <Text styles={styles.playbackTooltipOption} onClick={() => setPlayback(index)}>
                                {rate}
                            </Text>
                        ))}
                    </Div>
                </Div>
            );
        };

        return mediaPlayer.canListen ? (
            <Fragment>
                {mediaPlayer.isLive ? (
                    <WithPermission permissions={PERMISSIONS.unlockedLiveEvents}>
                        {({ restricted, isLoading }) =>
                            (restricted && !isPublic) || isLoading ? (
                                <Div
                                    data-tname="play-event-audio-audio-bar"
                                    styles={styles.audioControl}
                                    onClick={isLoading ? undefined : openUpgradeModal}
                                >
                                    <Icon className="lockIcon" type="lock02" color={theme.colors.white01} />
                                    <Icon
                                        className="playIcon"
                                        color={theme.colors.white01}
                                        type={mediaPlayer.listening ? 'pause' : 'play'}
                                    />
                                    <MediaQuery minWidth={601}>
                                        <Text weight="medium" size={1}>
                                            {mediaPlayer.listening
                                                ? 'Pause Audio'
                                                : mediaPlayer.status === 'paused'
                                                ? 'Resume Audio'
                                                : 'Listen Now'}
                                        </Text>
                                    </MediaQuery>
                                </Div>
                            ) : (
                                playButton
                            )
                        }
                    </WithPermission>
                ) : (
                    playButton
                )}
                <Tooltip
                    hideOnScroll={false}
                    isEnabled
                    useElementOffsetTop
                    useElementOffsetLeft
                    persistOnMouseExit
                    yOffset={-300}
                    xOffset={0}
                    blockBackground
                    content={playbackTooltip}
                >
                    {({ showTooltip }) => (
                        <Hint
                            yOffset={-62}
                            xOffset={-30}
                            text={catchingUp ? 'Catching up to live audio' : 'Change Playback Rate'}
                            styles={styles.speed}
                            onClick={catchingUp ? undefined : showTooltip}
                        >
                            <Text size={1} weight="medium">
                                {`${parseFloat(mediaPlayer.playbackRate).toFixed(2)}x`}
                            </Text>
                        </Hint>
                    )}
                </Tooltip>
                <Hint
                    yOffset={-62}
                    xOffset={-40}
                    text="Start from beginning"
                    styles={{ ...seekableStyles, ...styles.seekStart }}
                    onClick={seekStart}
                >
                    <Icon type="startOver" color={theme.colors.gray04} />
                </Hint>
                <Hint yOffset={-62} xOffset={-40} text="Back 15 Seconds" styles={seekableStyles} onClick={seekBack}>
                    <Icon type="back15" color={theme.colors.gray04} />
                </Hint>
                <Hint
                    yOffset={-62}
                    xOffset={-50}
                    text="Forward 15 Seconds"
                    styles={seekableStyles}
                    onClick={seekForward}
                >
                    <Icon type="forward15" color={theme.colors.gray04} />
                </Hint>
                {/*
                    <Hint yOffset={-54} xOffset={54} growLeft text="Highlight last 10 seconds" styles={styles.highlight}>
                        <Icon type="highlighter" color={theme.colors.gray04} />
                    </Hint>
                */}
            </Fragment>
        ) : null;
    }

    renderTimeline() {
        const { mediaPlayer, dragTime, styles, timelineRef, onDragPlayerKnob, seekToClick } = this.props;
        const { currentTime } = mediaPlayer;
        const { duration } = mediaPlayer;
        const completion = parseInt((currentTime * 100) / duration, 10).toFixed(2);
        const progressBarStyle = {
            ...styles.progressBar,
            width: `calc(${completion}% + 2px)`
        };

        const timelineStyles = { ...styles.timelineWrapper };

        if (mediaPlayer.canListen) {
            progressBarStyle.borderRadius = 4;
        } else {
            progressBarStyle.width = 0;
            timelineStyles.cursor = 'default';
        }

        const isAtEnd = mediaPlayer.duration - mediaPlayer.currentTime < 5;

        return (
            <Div styles={styles.player}>
                <Div
                    onClick={mediaPlayer.seekable ? () => mediaPlayer.seek(0) : null}
                    styles={mediaPlayer.seekable && styles.cursor}
                >
                    <Text size={0} styles={styles.startTime}>
                        {toDurationString(dragTime || mediaPlayer.currentTime)}
                    </Text>
                </Div>
                <Div onMouseDown={seekToClick} styles={timelineStyles}>
                    <Div ref={timelineRef} className="player-timeline" styles={styles.timeline}>
                        <Div styles={progressBarStyle} />
                        {mediaPlayer.seekable ? (
                            <Div
                                onMouseDown={onDragPlayerKnob}
                                className="knob"
                                styles={{ ...styles.knob, left: `${completion}%` }}
                            />
                        ) : null}
                    </Div>
                </Div>
                <Div
                    onClick={mediaPlayer.canListen && !isAtEnd ? mediaPlayer.goLive : null}
                    styles={[styles.flex, mediaPlayer.canListen && !isAtEnd && styles.cursor]}
                >
                    {mediaPlayer.canListen && mediaPlayer.isLive && (
                        <Div className="go-live-button" data-tname={isAtEnd ? 'player-is-live' : 'player-go-live'}>
                            <Div onClick={mediaPlayer.goLive} styles={isAtEnd ? styles.live : styles.goLive}>
                                <Text weight="medium" uppercase size={1} styles={styles.liveText}>
                                    {!isAtEnd && 'Go '}Live
                                </Text>
                            </Div>
                        </Div>
                    )}
                    <Div>
                        <Text size={0} styles={styles.endTime}>
                            {toDurationString(mediaPlayer.duration)}
                        </Text>
                    </Div>
                </Div>
            </Div>
        );
    }

    renderEvent() {
        const { styles, mediaPlayer, pathname } = this.props;
        const title = get(mediaPlayer, 'metaData.title');
        const ticker = get(mediaPlayer, 'metaData.ticker');
        const equityId = get(mediaPlayer, 'metaData.equityId');
        const exchange = get(mediaPlayer, 'metaData.exchange');
        const callDate = get(mediaPlayer, 'metaData.callDate');
        const tabId = get(mediaPlayer, 'metaData.tabId');
        const tickerPath = equityId ? generatePath(routes.equity, { equityId }) : undefined;

        return (
            <Div styles={styles.trackInfo}>
                {title && (
                    <Link
                        className="trackTitle"
                        to={generateTabURL({
                            pathname,
                            tabId
                        })}
                    >
                        {title}
                    </Link>
                )}
                <Div styles={styles.trackDetails}>
                    {tickerPath && (
                        <Link className="ticker" to={tickerPath}>
                            {ticker}
                        </Link>
                    )}
                    {exchange && (
                        <Text styles={styles.exchange} size={0} uppercase>
                            {exchange}
                        </Text>
                    )}
                    <Text
                        size={0}
                        styles={{ ...styles.trackDate, marginLeft: tickerPath || exchange ? 6 : 0 }}
                        uppercase
                    >
                        {toDateTimeString(callDate)}
                    </Text>
                </Div>
            </Div>
        );
    }

    renderAutoPlay() {
        const { styles, mediaPlayer, toggleAutoPlay, willAutoPlay } = this.props;
        const callDate = get(mediaPlayer, 'metaData.callDate');

        if (!mediaPlayer.canListen && callDate && new XDate().diffSeconds(new XDate(callDate)) > 0) {
            return (
                <Hint
                    yOffset={-74}
                    onClick={toggleAutoPlay}
                    text="Auto-play live events when they start"
                    styles={styles.autoPlayButton}
                >
                    <Checkbox
                        onToggle={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleAutoPlay();
                        }}
                        checked={willAutoPlay}
                        size={3}
                    />
                    <Text size={1}>Auto Play</Text>
                </Hint>
            );
        }

        return null;
    }

    renderUnavailable() {
        const { styles, theme, mediaPlayer } = this.props;
        const callDate = get(mediaPlayer, 'metaData.callDate');
        const show =
            callDate &&
            new XDate().diffMinutes(new XDate(callDate)) < -1 &&
            !mediaPlayer.canListen &&
            !mediaPlayer.canPlay;

        return show ? (
            <Hint styles={styles.unavailable} yOffset={-72} text="No audio available">
                <Icon type="unavailable" color={theme.colors.gray04} />
            </Hint>
        ) : null;
    }

    renderRemove() {
        const { viewingNoEvent, mediaPlayer, styles, theme } = this.props;

        return viewingNoEvent && mediaPlayer.status === 'paused' ? (
            <Div styles={styles.removeEvent} onClick={mediaPlayer.stop}>
                <Icon type="circleX" color={theme.colors.gray04} />
            </Div>
        ) : null;
    }

    renderVolume() {
        const { mediaPlayer, styles, theme, showVolume } = this.props;
        const volume = get(mediaPlayer, 'audio.audio.volume');
        if (typeof volume !== 'number' || !showVolume) {
            return null;
        }
        return (
            <Tooltip
                isEnabled
                useElementOffsetBottom
                useElementOffsetRight
                growUp
                growLeft
                useOutsideClickHandler
                persistOnMouseExit
                blockBackground
                xOffset={62}
                yOffset={-94}
                content={
                    <Div styles={styles.volumeTooltip} className="audio-playbar-tools">
                        <input
                            tabIndex={0}
                            defaultValue={volume}
                            onChange={e => mediaPlayer.setVolume(parseFloat(e.target.value))}
                            type="range"
                            id="volume"
                            name="volume"
                            min={0}
                            max={1}
                            step={0.01}
                        />
                    </Div>
                }
            >
                {({ showTooltip }) => (
                    <Div onClick={showTooltip} styles={styles.volumeButton}>
                        <Icon
                            type={volume === 0 ? 'speakerOff' : volume < 0.5 ? 'speakerLow' : 'speaker02'}
                            color={theme.colors.gray04}
                        />
                    </Div>
                )}
            </Tooltip>
        );
    }

    render() {
        const { showEventInfo, timelineOnly } = this.props;
        return (
            <Fragment>
                {showEventInfo && !timelineOnly && this.renderEvent()}
                {this.renderUnavailable()}
                {!timelineOnly && this.renderAudioPlayer()}
                {this.renderAutoPlay()}
                {this.renderTimeline()}
                {this.renderVolume()}
                {this.renderRemove()}
            </Fragment>
        );
    }
}

export const PlayerUI = compose(withStyleSheet(styleSheet))(Player);
