import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Span } from 'components/Span';
import { Img } from 'components/Img';
import { TimeAgo } from 'components/TimeAgo';
import { RawHTML } from 'components/RawHTML';
import { styleSheet } from './stylesheet';

class CardHeader extends PureComponent {
    static propTypes = {
        context: PropTypes.string,
        iconUrl: PropTypes.string,
        iconText: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        secondaryContext: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        truncateTitle: PropTypes.bool.isRequired
    };

    static defaultProps = {
        context: null,
        iconUrl: undefined,
        iconText: undefined,
        passedStyles: {},
        secondaryContext: null,
        subtext: null,
        subtitle: null,
        title: null
    };

    render() {
        const {
            context,
            passedStyles,
            secondaryContext,
            styles,
            subtext,
            subtitle,
            theme,
            title,
            truncateTitle,
            iconUrl,
            iconText
        } = this.props;

        const iconStyles = iconUrl
            ? styles.iconElement
            : { ...styles.iconElement, backgroundColor: theme.colors.gray10 };

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.content}>
                    {context && (
                        <Text styles={styles.line01} weight="medium" uppercase>
                            {context}
                            {secondaryContext && <Span styles={styles.line01b}>{secondaryContext}</Span>}
                        </Text>
                    )}
                    {title && (
                        <Div className="title" styles={truncateTitle ? styles.line02Truncated : styles.line02}>
                            <RawHTML html={title} />
                        </Div>
                    )}
                    {subtitle && (
                        <Text size={1} className="subtitle" styles={styles.line03} title={subtitle}>
                            {subtitle}
                        </Text>
                    )}
                    {subtext && (
                        <Text size={0} className="subtext" styles={styles.line04}>
                            {typeof subtext.getMonth === 'function' ? <TimeAgo date={subtext} /> : subtext}
                        </Text>
                    )}
                </Div>
                {(iconUrl || iconText) && (
                    <Div styles={iconStyles}>
                        {iconUrl ? (
                            <Img src={iconUrl} />
                        ) : (
                            <Text uppercase size={2}>
                                {iconText}
                            </Text>
                        )}
                    </Div>
                )}
            </Div>
        );
    }
}

export const CardHeaderUI = compose(withStyleSheet(styleSheet))(CardHeader);
