export const styleSheet = theme => {
    const container = {
        backgroundColor: theme.colors.green03,
        borderRadius: 6,
        boxShadow: 'unset',
        flexShrink: 0,
        svg: {
            marginBottom: 2
        },
        p: {
            color: theme.colors.white01,
            marginLeft: 6
        },
        ':hover': {
            backgroundColor: theme.colors.green06
        },
        ':active': {
            backgroundColor: theme.colors.green07
        }
    };
    return {
        addOptions: {
            boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
            background: theme.colors.white01,
            borderRadius: 6,
            width: 220,
            overflow: 'hidden'
        },
        addOption: {
            padding: '10px 12px',
            cursor: 'pointer',
            ':last-child': {
                borderTop: `1px solid ${theme.colors.gray01}`
            },
            ':hover': { background: theme.colors.blue08, color: theme.colors.white01 }
        },
        addOptionDescription: {
            lineHeight: '1.2em',
            marginTop: 4
        },
        container: {
            ...container
        },
        containerAdded: {
            ...container,
            svg: {
                marginBottom: 0
            }
        },
        containerLocked: {
            ...container,
            backgroundColor: theme.colors.yellow05,
            p: {
                color: theme.colors.black01,
                marginLeft: 6
            },
            ':hover': {
                backgroundColor: theme.colors.yellow04
            },
            ':active': {
                backgroundColor: theme.colors.yellow08
            }
        }
    };
};
