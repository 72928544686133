import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DateInputUI } from './ui';

export class DateInput extends PureComponent {
    static displayName = 'DateInputContainer';

    static propTypes = {
        formatString: PropTypes.string,
        minDate: PropTypes.instanceOf(Date),
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.instanceOf(Date)
    };

    static defaultProps = {
        formatString: 'M/d/yyyy',
        minDate: undefined,
        onChange: undefined,
        placeholder: undefined,
        styles: undefined,
        value: undefined
    };

    constructor(props) {
        super(props);

        this.hideCalendar = this.hideCalendar.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.state = {
            isCalendarVisible: false
        };
    }

    hideCalendar() {
        this.setState({ isCalendarVisible: false });
    }

    onChange({ value }) {
        const { name, onChange } = this.props;
        this.setState({ isCalendarVisible: false }, () => {
            if (onChange) {
                onChange({ name, value });
            }
        });
    }

    onFocus() {
        this.setState({ isCalendarVisible: true });
    }

    render() {
        const { isCalendarVisible } = this.state;
        const { formatString, minDate, name, placeholder, styles, value } = this.props;
        return (
            <DateInputUI
                formatString={formatString}
                hideCalendar={this.hideCalendar}
                isCalendarVisible={isCalendarVisible}
                minDate={minDate}
                name={name}
                onChange={this.onChange}
                onFocus={this.onFocus}
                placeholder={placeholder}
                styles={styles}
                value={value}
            />
        );
    }
}

export const DateInputContainer = compose()(DateInput);
