import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { GSheetCard } from 'components/GSheetCard';
import { Stream } from 'components/Stream';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    gsheet: GSheetCard
};

class GSheetStream extends PureComponent {
    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        passedStyles: {},
        streamId: null,
        subtitle: undefined
    };

    render() {
        const {
            dashboardId,
            loadMoreMatches,
            loading,
            matches,
            passedStyles,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;
        return (
            <Stream
                {...streamProps}
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                offset={matches.length}
                streamId={streamId}
                styles={{ ...styles.container, ...passedStyles }}
                subtitle={subtitle}
            >
                {matches.map(m => {
                    const CardComponent = COMPONENT_MAP[m.type];
                    return <CardComponent key={`gsheet-card-${m.id}`} {...m} />;
                })}
            </Stream>
        );
    }
}

export const GSheetStreamUI = compose(withStyleSheet(styleSheet))(GSheetStream);
