import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Checkbox } from 'components/Checkbox';
import { CopyLink } from 'components/CopyLink';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { ExternalLink } from 'components/ExternalLink';
import { Heading } from 'components/Heading';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { Notice } from 'components/Notice';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const GOOGLE_LINK = 'https://support.google.com/calendar/answer/37100';
const MAC_OS_LINK = 'https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac';
const MICROSOFT_LINK =
    'https://support.office.com/en-us/article/Import-or-subscribe-to-a-calendar-in-Outlook-com-' +
    'cff1429c-5af6-41ec-a5b4-74f2c278e98c';

class WatchlistIcalModal extends PureComponent {
    static propTypes = {
        dateOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        eventTypeOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        loading: PropTypes.bool.isRequired,
        onChangeDate: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
        onToggleEventType: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedDate: PropTypes.string.isRequired,
        selectedEventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        showCheckboxes: PropTypes.bool.isRequired,
        showForm: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleCheckboxes: PropTypes.func.isRequired,
        url: PropTypes.string,
        watchlist: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {},
        url: null,
        watchlist: null
    };

    renderContent() {
        const { loading, showForm, styles, url, theme, watchlist } = this.props;

        if (loading) {
            return <LoaderLogo styles={styles.loading} />;
        }

        if (showForm) {
            return this.renderForm();
        }

        if (url) {
            const name = get(watchlist, 'name', '');
            return (
                <MediaQuery maxWidth={theme.breakpoints.internal.mobileEdge}>
                    {m => (
                        <Div styles={styles.content}>
                            <Notice type="info" styles={styles.notice}>
                                It may take up to 12-24 hours for events to appear in your calendar
                            </Notice>
                            <Div styles={styles.contentRow}>
                                <Div styles={styles.contentAuto}>
                                    <Heading styles={styles.heading} size={2}>
                                        Automatically add event feed
                                        <br />
                                        to your calendar
                                    </Heading>
                                    <Text size={3} span styles={styles.description}>
                                        Click&nbsp;
                                        <Span>
                                            <ExternalLink href={url}>here</ExternalLink>
                                        </Span>
                                        &nbsp;or the subscribe button (below) to open a webcal feed of events for
                                        companies in your{' '}
                                        <Text span weight="bold" size={3}>
                                            {name}
                                        </Text>
                                        &nbsp;watchlist.
                                        <br />
                                        <br />
                                        The feed will open your operating system&apos;s default calendar application, or
                                        a new browser tab.
                                    </Text>
                                    <Div styles={styles.button}>
                                        <ExternalLink href={url} styles={styles.button}>
                                            Subscribe Now
                                            <Icon type="plus" color={theme.colors.white01} />
                                        </ExternalLink>
                                    </Div>
                                </Div>
                                {!m && (
                                    <Div styles={styles.contentManual}>
                                        <Heading styles={styles.heading} size={2}>
                                            Manually add event feed
                                            <br />
                                            to your calendar
                                        </Heading>
                                        <Text size={3} styles={styles.description}>
                                            Click the webcal link below to copy it to your clipboard, and follow the
                                            instructions for your calendar client to add events from your&nbsp;
                                            <Text span weight="bold" size={3}>
                                                {name}
                                            </Text>{' '}
                                            watchlist.
                                            <br />
                                        </Text>
                                        <CopyLink url={url} styles={styles.copyLink} />
                                        <Div styles={styles.moreInfo}>
                                            <Text size={3} styles={styles.moreInfoText}>
                                                Calendar Client Instructions:
                                            </Text>
                                            <ul>
                                                <li>
                                                    <ExternalLink href={GOOGLE_LINK}>Google Calendar</ExternalLink>
                                                </li>
                                                <li>
                                                    <ExternalLink href={MICROSOFT_LINK}>Microsoft Outlook</ExternalLink>
                                                </li>
                                                <li>
                                                    <ExternalLink href={MAC_OS_LINK}>macOS Calendar</ExternalLink>
                                                </li>
                                            </ul>
                                        </Div>
                                    </Div>
                                )}
                            </Div>
                        </Div>
                    )}
                </MediaQuery>
            );
        }

        return (
            <Text size={3} styles={styles.description}>
                Please contact your Aiera account manager about subscribing to your watchlist&apos;s events calendar.
            </Text>
        );
    }

    renderForm() {
        const {
            dateOptions,
            eventTypeOptions,
            onChangeDate,
            onContinue,
            onToggleEventType,
            selectedDate,
            selectedEventTypes,
            showCheckboxes,
            styles,
            theme,
            toggleCheckboxes
        } = this.props;
        const numSelected = selectedEventTypes.length;
        const asterisk = numSelected === 0 ? '*' : '';
        return (
            <Div styles={styles.form}>
                <Dropdown
                    disableHoverOpen
                    label="How far into the future do you want to see events? ¹"
                    name="selectedRange"
                    onChange={onChangeDate}
                    options={dateOptions}
                    styles={styles.dateRangeDropdown}
                    value={selectedDate}
                />
                <Text size={1} styles={styles.footnoteDagger}>
                    ¹ Historical events are backfilled two weeks by default
                </Text>
                <Div
                    styles={showCheckboxes ? styles.toggleCheckboxes : styles.toggleCheckboxesClosed}
                    onClick={toggleCheckboxes}
                >
                    <Icon type="chevron02" color={theme.colors.gray06} />
                    <Text size={1}>
                        {showCheckboxes
                            ? `Hide selected event types (${numSelected})${asterisk}`
                            : `Show selected event types (${numSelected})${asterisk}`}
                    </Text>
                </Div>
                {showCheckboxes && (
                    <Div styles={styles.checkboxContainer}>
                        {eventTypeOptions.map(option => {
                            const hintText = (
                                <Text size={2}>
                                    Event information for Earnings Releases
                                    <br />
                                    may not be accurate, and is subject to change.
                                </Text>
                            );
                            let Wrapper = Div;
                            let wrapperProps = {
                                onClick: () => onToggleEventType(option.value),
                                styles: styles.checkboxRow
                            };
                            if (option.value === 'earnings_release') {
                                Wrapper = Hint;
                                wrapperProps = {
                                    growUp: true,
                                    onClick: () => onToggleEventType('earnings_release'),
                                    slideIn: true,
                                    styles: styles.checkboxRow,
                                    text: hintText,
                                    xOffset: 20,
                                    yOffset: -40
                                };
                            }
                            return (
                                <Wrapper {...wrapperProps}>
                                    <Checkbox
                                        checked={selectedEventTypes.includes(option.value)}
                                        size={2}
                                        styles={styles.checkbox}
                                    />
                                    <Text size={3}>{option.label}</Text>
                                </Wrapper>
                            );
                        })}
                    </Div>
                )}
                <Div styles={styles.formFooter}>
                    {numSelected === 0 && (
                        <Text size={1} styles={styles.formFooterNote}>
                            * When no types are selected, everything except
                            <br />
                            &nbsp;&nbsp;&nbsp;Earnings Releases will be included by default
                        </Text>
                    )}
                    <ActionButton onClick={onContinue} size={1} styles={styles.actionButton}>
                        <Text size={3}>Continue</Text>
                    </ActionButton>
                </Div>
            </Div>
        );
    }

    render() {
        const { onClose, passedStyles, styles } = this.props;
        return (
            <Modal isOpen onModalClose={onClose} styles={styles.modal} title="Subscribe to watchlist events">
                <Div styles={{ ...styles.container, ...passedStyles }}>{this.renderContent()}</Div>
            </Modal>
        );
    }
}

export const WatchlistIcalModalUI = compose(withStyleSheet(styleSheet))(WatchlistIcalModal);
