import gql from 'graphql-tag';
import { compose } from 'recompose';
import { withMemo } from 'hoc/utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges
} from 'hoc/streams';
import { eventGroupMatchFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        withMemo({ formatMatches: formatMatchesByType, mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query withEventGroupStream(
                    $streamId: ID
                    $size: Int = 20
                    $offset: Int = 0
                    $filter: StreamMatchFilter
                ) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        rules {
                            ...rule
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...eventGroupMatch
                            }
                        }
                    }
                }
                ${eventGroupMatchFragment}
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { fetchMore, loading, matches, refetch, rules, stream, subtitle } = getDefaultStreamProps(
                        data,
                        formatMatches
                    );
                    return { fetchMore, loading, matches, refetch, rules, stream, subtitle };
                },
                skip: ({ streamId, streamProps }) => !get(streamProps, 'wasVisible', false) || !streamId,
                options: ({ dashDateRange, dashSearchTerm, dashSources, dashEquityScope, mapFilters, streamId }) => {
                    return getDefaultStreamOptions({
                        dashDateRange,
                        dashSearchTerm,
                        dashSources,
                        dashEquityScope,
                        mapFilters,
                        streamId,
                        streamType: 'event_groups'
                    });
                }
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging()
    );
