import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Countdown, { zeroPad } from 'react-countdown';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Span } from 'components/Span';
import { styleSheet } from './stylesheet';

class EventCountdown extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string.isRequired,
        hideLabels: PropTypes.bool.isRequired,
        labelStyles: PropTypes.objectOf(PropTypes.any),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showDays: PropTypes.bool.isRequired,
        showSeconds: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        labelStyles: {},
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, labelStyles, callDate, hideLabels, showDays, showSeconds } = this.props;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) return null;
            return (
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Span>
                        {showDays || days > 0 ? `${zeroPad(days)}:` : ''}
                        {zeroPad(hours)}:{zeroPad(minutes)}
                        {showSeconds ? `:${zeroPad(seconds)}` : ''}
                    </Span>
                    {!hideLabels && (
                        <Div styles={{ ...styles.labels, ...labelStyles }}>
                            {showDays && <Span>days</Span>}
                            <Span>hours</Span>
                            <Span>mins</Span>
                            <Span>secs</Span>
                        </Div>
                    )}
                </Div>
            );
        };

        return <Countdown date={callDate} renderer={renderer} />;
    }
}

export const EventCountdownUI = compose(withStyleSheet(styleSheet))(EventCountdown);
