export const styleSheet = theme => {
    const resultTd = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: 14,
        paddingTop: 5,
        paddingBottom: 5,
        lineHeight: '1em',
        paddingRight: theme.margins.margin01,
        ':first-of-type': {
            width: 44,
            paddingLeft: theme.margins.margin00,
            paddingRight: theme.margins.margin00
        },
        ':last-of-type': {
            paddingRight: 14,
            textAlign: 'right'
        }
    };
    return {
        companyIcon: {
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            img: {
                maxHeight: 24
            }
        },
        companyInitials: {
            backgroundColor: theme.colors.gray03,
            color: theme.colors.gray06,
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        exchange: {
            marginLeft: 4,
            color: theme.colors.gray04,
            letterSpacing: 1
        },
        loadButtonRow: {
            td: {
                padding: 10
            }
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            padding: '12px 0',
            div: {
                margin: 'auto'
            }
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        loadMoreButtonHighlighted: {
            borderRadius: 6,
            justifyContent: 'center',
            boxShadow: `0 0 0 1px ${theme.colors.blue08}`,
            p: {
                color: theme.colors.blue08
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        price: {
            display: 'flex',
            alignItems: 'center'
        },
        priceChange: {
            marginRight: 4
        },
        priceChangePercent: {
            display: 'flex',
            span: {
                fontSize: theme.fonts.fontSizes.size00
            }
        },
        priceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'column',
            svg: {
                marginLeft: 3
            }
        },
        quoteHint: {
            alignItems: 'center',
            display: 'flex',
            lineHeight: '1.3em',
            span: {
                ':first-of-type': {
                    textAlign: 'right'
                },
                ':last-of-type': {
                    color: theme.colors.gray04,
                    marginLeft: 5,
                    textAlign: 'left'
                }
            }
        },
        result: {
            td: {
                ...resultTd
            },
            ':nth-of-type(odd)': {
                td: {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        resultHighlighted: {
            td: {
                ...resultTd,
                backgroundColor: theme.colors.blue08,
                color: theme.colors.white01,
                'svg g': {
                    fill: theme.colors.white01
                },
                span: {
                    color: theme.colors.white01
                },
                p: {
                    color: theme.colors.white01
                },
                '.companyInitials': {
                    color: theme.colors.gray06
                }
            }
        },
        results: {
            width: '100%'
        },
        ticker: {
            color: theme.colors.gray06,
            letterSpacing: 1
        }
    };
};
