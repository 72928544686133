import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { Span } from 'components/Span';
import { Hint } from 'components/Hint';
import { withStyleSheet } from 'hoc/styles';
import { toDateString } from 'utils';
import { styleSheet } from './stylesheet';

class FilingHeader extends PureComponent {
    static propTypes = {
        arrivalDate: PropTypes.string,
        company: PropTypes.string,
        exchange: PropTypes.string,
        formNumber: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        onTickerClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        periodEndDate: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        arrivalDate: null,
        company: null,
        exchange: null,
        formNumber: null,
        loading: false,
        passedStyles: {},
        periodEndDate: null,
        ticker: null,
        title: null
    };

    renderCompany() {
        const { styles, theme, onTickerClick, ticker, exchange, company: name } = this.props;

        if (!ticker) {
            return null;
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Hint
                        text="View Company"
                        styles={styles.company}
                        onClick={onTickerClick}
                        xOffset={-105}
                        yOffset={-41}
                    >
                        {!m && (
                            <Div styles={styles.companySummary}>
                                {name && (
                                    <Text size={3} className="companyName">
                                        {name}
                                    </Text>
                                )}
                                {ticker && (
                                    <Text size={0} styles={styles.companyTicker} uppercase>
                                        {ticker}
                                        <Span styles={styles.exchange}>{exchange}</Span>
                                    </Text>
                                )}
                            </Div>
                        )}
                        {ticker && <Icon type="company" color={theme.colors.gray04} />}
                    </Hint>
                )}
            </MediaQuery>
        );
    }

    render() {
        const {
            styles,
            passedStyles,
            history,
            theme,
            title,
            loading,
            company: name,
            periodEndDate,
            formNumber,
            arrivalDate
        } = this.props;
        const pageTitle = title || 'Loading...';

        if (loading && !name) {
            return (
                <MediaQuery maxWidth={theme.breakpoints.internal.tablet}>
                    {m => (
                        <Div styles={{ ...styles.container, ...passedStyles }}>
                            {m && (
                                <Div styles={styles.backIcon} onClick={() => history.goBack()}>
                                    <Icon type="chevron" color={theme.colors.gray04} />
                                </Div>
                            )}
                            <Div styles={styles.loadingPlaceholders}>
                                <Div styles={styles.loadingMediumText} />
                                <Div styles={styles.columns}>
                                    <Div styles={styles.loadingSmallText} />
                                    <Div styles={styles.loadingSmallText} />
                                </Div>
                            </Div>
                        </Div>
                    )}
                </MediaQuery>
            );
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobileEdge}>
                {m => (
                    <React.Fragment>
                        <Div styles={{ ...styles.container, ...passedStyles }} className="print-hide">
                            {m && (
                                <Div styles={styles.backIcon} onClick={() => history.goBack()}>
                                    <Icon type="chevron" color={theme.colors.gray04} />
                                </Div>
                            )}
                            {!m && this.renderCompany()}
                            <Div styles={styles.headerInfo}>
                                <Div styles={styles.headerTitle}>
                                    {name && (
                                        <Text styles={styles.headerTitlePlaceholder} size={3} weight="medium">
                                            {name}
                                        </Text>
                                    )}
                                    <Div styles={styles.headerTitleTextWrapper}>
                                        {name && (
                                            <Text styles={styles.headerTitleText} size={3} weight="medium">
                                                {name}
                                            </Text>
                                        )}
                                    </Div>
                                </Div>
                                <Text styles={styles.headerSmallText}>
                                    {pageTitle && (
                                        <Text span styles={styles.endDate} size={0} uppercase>
                                            {pageTitle}
                                        </Text>
                                    )}
                                    {periodEndDate && (
                                        <Text span styles={styles.filedDate} size={0} uppercase>
                                            Period ending {toDateString(periodEndDate, true)}
                                        </Text>
                                    )}
                                </Text>
                            </Div>
                            <Div styles={styles.formNumber}>
                                <Text size={3} weight="medium">
                                    {formNumber}
                                </Text>
                                {!m && arrivalDate && (
                                    <Text span styles={styles.filedDate} size={0} uppercase>
                                        Filed {toDateString(arrivalDate, true)}
                                    </Text>
                                )}
                            </Div>
                            {m && this.renderCompany()}
                        </Div>
                    </React.Fragment>
                )}
            </MediaQuery>
        );
    }
}

export const FilingHeaderUI = compose(withStyleSheet(styleSheet))(FilingHeader);
