export const routes = {
    root: '/home',
    home: '/home',
    account: '/account',
    billing: '/account/billing',
    dev: '/account/dev',
    ical: '/account/ical',
    manageTeam: '/account/manage-team',
    support: '/account/support',
    alerts: '/alerts',
    alert: '/alerts/:alertId',
    signOut: '/auth/logout',
    newPassword: '/auth/new-password',
    resetPassword: '/auth/reset-password',
    resetPasswordCode: '/auth/reset-password/:code',
    setPassword: '/auth/set-password',
    calendar: '/calendar',
    companies: '/companies',
    company: '/companies/:companyId',
    companyAlerts: '/companies/:companyId/alerts',
    companyAlertsSettings: '/companies/:companyId/alerts/settings',
    companyAskAiera: '/companies/:companyId/ask-aiera',
    companyCalendar: '/companies/:companyId/calendar',
    companyActivity: '/companies/:companyId/activity',
    companyActivityCorporateActivity: '/companies/:companyId/activity/corporate-activity',
    companyActivityFilings: '/companies/:companyId/activity/filings',
    companyActivityFilingsOther: '/companies/:companyId/activity/filings/other',
    companyActivityFilingsOwnership: '/companies/:companyId/activity/filings/ownership',
    companyActivityFilingsProspectus: '/companies/:companyId/activity/filings/prospectusreg',
    companyActivityFilingsProxies: '/companies/:companyId/activity/filings/proxies',
    companyActivityFilingsNews: '/companies/:companyId/activity/filings/news',
    companyActivityFilingsFinancials: '/companies/:companyId/activity/filings/financials',
    companyActivityAttachments: '/companies/:companyId/activity/attachment',
    companyActivityDocuments: '/companies/:companyId/activity/document',
    companyActivityNews: '/companies/:companyId/activity/news',
    companyActivityPeople: '/companies/:companyId/activity/people',
    companyActivityPrivate: '/companies/:companyId/activity/private',
    companyActivityPrivateTranscripts: '/companies/:companyId/activity/private/transcripts',
    companyActivityPrivateDocuments: '/companies/:companyId/activity/private/documents',
    companyActivityPrivateNews: '/companies/:companyId/activity/private/news',
    companyActivityPrivateResearch: '/companies/:companyId/activity/private/research',
    companyActivitySnapshot: '/companies/:companyId/activity/snapshot',
    companyActivityTranscripts: '/companies/:companyId/activity/transcripts',
    companyActivityTranscriptsExternal: '/companies/:companyId/activity/transcripts/external',
    companyFinancials: '/companies/:companyId/financials',
    companyFinancialsIncome: '/companies/:companyId/financials/income',
    companyFinancialsCashFlow: '/companies/:companyId/financials/cash-flow',
    companyFinancialsBalanceSheet: '/companies/:companyId/financials/balance-sheet',
    companyFinancialsValuation: '/companies/:companyId/financials/valuation',
    companyHighlights: '/companies/:companyId/highlights',
    companyPage: '/companies/:companyId/:page?/:subPage?/:subSubPage?',
    companySearch: '/companies/:companyId/search/:searchTerm?',
    companyTopics: '/companies/:companyId/topics',
    companySWOTAnalysis: '/companies/:companyId/swot',
    conference: '/conference/:eventGroupId',
    dashboard: '/dashboard/:dashboardId?',
    dashboardHome: '/dashboard/events',
    documents: '/documents',
    equity: '/equity/:equityId(\\d+)',
    equityDashboard: '/equity/:dashboardId([a-zA-Z0-9]{32})',
    equities: '/equities', // DEPRECATED
    framedEvent: '/framed/event/:audioCallId',
    mobile: '/mobile',
    mobileAlerts: '/mobile/alerts',
    mobileCompanies: '/mobile/companies',
    mobileDashboards: '/mobile/dashboards',
    mobileEvents: '/mobile/events',
    mobileHome: '/mobile/events',
    mobileMenu: '/mobile/menu',
    mobilePushPermission: '/mobile/push-permission',
    notFound: '/not-found',
    playground: '/playground',
    playgroundApolloCancel: '/playground/apollocancel',
    playgroundSearch: '/playground/search',
    popoutEvent: '/popout/:type(event)/:audioCallId',
    popoutFiling: '/popout/:type(filing)/:filingId',
    popoutNews: '/popout/:type(news)/:newsId',
    popoutStreetAccount: '/popout/:type(streetaccount)/:streetAccountId',
    popoutSpotlight: '/popout/:type(spotlight)/:spotlightId',
    research: '/research',
    search: '/search',
    watchlistRoot: '/watchlist',
    watchlist: '/watchlist/:watchlistId?',
    workspace: '/workspace'
};
export const externalRoutes = {
    agent: '/agent',
    signIn: '/auth',
    sso: '/auth/sso/:org',
    confirmEmail: '/auth/confirm-email',
    mobileWelcome: '/mobile/welcome',
    publicAskAiera: '/ask-aiera',
    publicEvent: '/p/:ticker/event/:mm/:dd/:yyyy/:shareId',
    publicEventMinimal: '/p/evtmin/:shareId',
    publicEmbedEvent: '/p/event/embed/:shareId',
    publicPrivateEvent: '/p/event/shared/:shareId',
    pricing: '/pricing',
    register: '/register/:code?',
    invitedUser: '/register/invited/:code',
    verifyEmail: '/register/verify-email/:code',
    transcriptEditor: '/transcript-editor',
    transcriptEditorEvent: '/transcript-editor/e/:eventId',
    transcriptEditorItem: '/transcript-editor/i/:itemId'
};
