import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { GraphicUI } from './ui';

export class Graphic extends PureComponent {
    static displayName = 'GraphicContainer';

    render() {
        const { ...rest } = this.props;
        return <GraphicUI {...rest} />;
    }
}

export const GraphicContainer = compose()(Graphic);
