import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { withUser } from 'graphql/user';
import { get } from 'utils';
import { UserMenuUI } from './ui';

export class UserMenu extends PureComponent {
    static displayName = 'UserMenuContainer';

    static propTypes = {
        billingSource: PropTypes.string,
        closed: PropTypes.bool,
        organizationAdmin: PropTypes.bool.isRequired,
        organizationName: PropTypes.string,
        userName: PropTypes.string.isRequired
    };

    static defaultProps = {
        billingSource: null,
        closed: false,
        organizationName: null
    };

    render() {
        const { billingSource, closed, organizationAdmin, organizationName, userName } = this.props;
        return (
            <UserMenuUI
                billingSource={billingSource}
                closed={closed}
                organizationAdmin={organizationAdmin}
                organizationName={organizationName}
                userName={userName}
            />
        );
    }
}

export const UserMenuContainer = compose(
    withUser({ variables: { withOrganization: true } }),
    withProps(({ user }) => ({
        billingSource: get(user, 'organization.billingSource'),
        organizationAdmin: get(user, 'organizationAdmin', false),
        organizationName: get(user, 'organization.name', 'No organization'),
        userName: get(user, 'name', 'Account')
    }))
)(UserMenu);
