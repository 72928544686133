import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withData } from './data';
import { EquityFinancialsModalUI } from './ui';

const CATEGORIES = ['KPIs', 'Income Statement', 'Cash Flow Statement', 'Balance Sheet'];

export class EquityFinancialsModal extends PureComponent {
    static displayName = 'EquityFinancialsModalContainer';

    static propTypes = {
        currentTab: PropTypes.string.isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        financials: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        onSelectTab: PropTypes.func.isRequired,
        quarters: PropTypes.arrayOf(PropTypes.string),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        equity: undefined,
        financials: undefined,
        loading: false,
        quarters: undefined,
        styles: undefined
    };

    render() {
        const { currentTab, equity, financials, loading, onClose, onSelectTab, quarters, styles } = this.props;
        return (
            <EquityFinancialsModalUI
                categories={CATEGORIES}
                currentTab={currentTab}
                equity={equity}
                financials={financials}
                loading={loading}
                onClose={onClose}
                onSelectTab={onSelectTab}
                quarters={quarters}
                styles={styles}
            />
        );
    }
}

export const EquityFinancialsModalContainer = compose(
    withStateHandlers(
        () => ({
            currentTab: 'KPIs'
        }),
        {
            onSelectTab: () => currentTab => ({ currentTab })
        }
    ),
    withData()
)(EquityFinancialsModal);
