import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import flatten from 'lodash/flatten';
import { statusBannerFire } from 'actions/statusBanner';
import { dashboardFragment, dashboardSectionFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation FollowMonitor($dashboardId: ID!, $followed: Boolean!) {
                    starDashboard(dashboardId: $dashboardId, starred: $followed) {
                        success
                        dashboard {
                            ...dashboard
                        }
                        query {
                            recommendedDashboards: dashboards(filter: { recommended: true, size: 5 }) {
                                ...dashboard
                                creatingUser {
                                    id
                                    username
                                }
                            }
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { dashboardGallerySearch = '', setStatusBanner } = ownProps;
                    return {
                        followMonitor: (dashboardId, followed) =>
                            mutate({
                                variables: { dashboardId, followed },
                                update: (
                                    proxy,
                                    {
                                        data: {
                                            starDashboard: { dashboard }
                                        }
                                    }
                                ) => {
                                    try {
                                        const query = gql`
                                            query withFollowDashboardButtonData {
                                                galleryDashboards(filter: { search: ${dashboardGallerySearch} }) {
                                                    tag
                                                    dashboards {
                                                        ...dashboard
                                                    }
                                                }
                                            }
                                            ${dashboardFragment}
                                        `;
                                        const { galleryDashboards } = proxy.readQuery({ query });

                                        // Since following an equity dashboard clones it on the server
                                        // the dashboard that is returned is not in the cache yet.
                                        // So, we need to replace the old gallery dashboard with the cloned new one
                                        if (galleryDashboards && galleryDashboards.length) {
                                            let equityDashboards = [];
                                            const userDashboards = [];

                                            galleryDashboards.forEach(d => {
                                                const { tag, dashboards } = d;
                                                if (tag === 'Equities') {
                                                    equityDashboards = [...equityDashboards, ...dashboards];
                                                } else {
                                                    userDashboards.push(d);
                                                }
                                            });

                                            // Find the index of the original dashboard
                                            equityDashboards = flatten(equityDashboards);
                                            const originalDashIdx = equityDashboards.findIndex(
                                                d => d.id === dashboardId
                                            );
                                            // Replace the original with the newly cloned dashboard
                                            equityDashboards = [
                                                ...equityDashboards.slice(0, originalDashIdx),
                                                dashboard,
                                                ...equityDashboards.slice(originalDashIdx + 1)
                                            ];

                                            proxy.writeQuery({
                                                query,
                                                data: {
                                                    galleryDashboards: [
                                                        ...userDashboards,
                                                        {
                                                            tag: 'Equities',
                                                            dashboards: equityDashboards,
                                                            __typename: 'Dashboard'
                                                        }
                                                    ]
                                                }
                                            });
                                        }
                                    } catch {
                                        // Gallery dashboards aren't in the cache yet, so do nothing
                                    }
                                }
                            }).catch(error => {
                                setStatusBanner(`Error adding monitor: ${error}`, 'error', 'circleX');
                            })
                    };
                }
            }
        )
    );
