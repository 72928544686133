import { generatePath } from 'react-router-dom';
import { TYPES } from 'consts/filters';
import { routes } from 'routes';
import { generateTabURL, get } from 'utils';

const FILTERS_TO_RULES = {
    [TYPES.equity]: 'equity_id',
    [TYPES.index]: 'index_id',
    [TYPES.sector]: 'gics_sector_id',
    [TYPES.subSector]: 'gics_sub_sector_id',
    [TYPES.watchlist]: 'watchlist_id'
};
const RULES_TO_FILTERS = Object.fromEntries(Object.entries(FILTERS_TO_RULES).map(i => i.reverse()));
/*
 *
 * Get Filters
 *
 */

function getFilterValue({ ruleType, value }) {
    if (ruleType === 'gics_sector_id') {
        return { gicsSectorId: value };
    }
    if (ruleType === 'gics_sub_sector_id') {
        return { gicsSubSectorId: value };
    }
    return value;
}

function getFilterLabel({ ruleType, equity, index, sector, subSector, watchlist }) {
    if (ruleType === 'equity_id') {
        const localTicker = get(equity, 'localTicker');
        const exchange = get(equity, 'exchange.shortName');
        let label = localTicker;
        if (exchange) {
            label = `${localTicker}:${exchange}`;
        }
        return label;
    }
    if (ruleType === 'index_id') {
        return get(index, 'displayName');
    }
    if (['gics_sector_id', 'gics_sub_sector_id'].includes(ruleType)) {
        return get(sector, 'name', get(subSector, 'name'));
    }
    if (ruleType === 'watchlist_id') {
        return get(watchlist, 'name');
    }
    return null;
}

export function mapAlertRulesToFilters(rules) {
    if (rules) {
        return rules.map(rule => ({
            label: getFilterLabel(rule),
            type: RULES_TO_FILTERS[rule.ruleType],
            value: getFilterValue(rule)
        }));
    }

    return [];
}

/*
 *
 * Get Rules
 *
 */

function getRuleValue({ type, value }) {
    if (type === TYPES.sector) {
        const { gicsSectorId, gicsSubSectorId } = value;
        return gicsSectorId || gicsSubSectorId;
    }
    if (type === TYPES.subSector) {
        const { gicsSubSectorId } = value;
        return gicsSubSectorId;
    }
    return value;
}

function getRuleType({ type, value }) {
    if (type === TYPES.sector) {
        const { gicsSectorId } = value;
        return FILTERS_TO_RULES[gicsSectorId ? TYPES.sector : TYPES.subSector];
    }

    return FILTERS_TO_RULES[type] || 'unsupported';
}

export function mapAlertFiltersToRules(equityScope) {
    return equityScope.map(({ type, value }) => ({
        ruleType: getRuleType({ type, value }),
        condition: 'is_equal',
        value: getRuleValue({ type, value })
    }));
}

/*
 *
 * General utility functions
 *
 */

export function generateAlertURL({
    contentId,
    dashboardId,
    dashboardType,
    equityId,
    eventId,
    notificationType,
    streamId,
    transcriptId
}) {
    if (eventId) {
        const pathname =
            dashboardType === 'equity'
                ? generatePath(routes.equity, { equityId })
                : generatePath(routes.dashboard, { dashboardId: dashboardId || 'events' });
        return generateTabURL({
            eventId,
            match: !!streamId,
            pathname,
            streamId,
            itemId: transcriptId
        });
    }
    if (contentId) {
        const pathname =
            dashboardType === 'equity'
                ? generatePath(routes.equity, { equityId })
                : generatePath(routes.dashboard, { dashboardId: dashboardId || 'events' });
        const options = {
            match: !!streamId,
            pathname,
            streamId
        };
        if (notificationType === 'spotlight_match') {
            return generateTabURL({ ...options, spotlightId: contentId });
        }
        return generateTabURL({ ...options, newsId: contentId });
    }
    if (dashboardId) {
        if (dashboardType === 'equity') {
            return generatePath(routes.equity, { equityId });
        }
        return generatePath(routes.dashboard, { dashboardId });
    }
    return null;
}
