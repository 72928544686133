import { types } from 'actions/alertToast';

const { ALERT_TOAST_HIDE, ALERT_TOAST_SHOW } = types;

const initialState = {
    visible: false,
    type: null
};

export function alertToastReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === ALERT_TOAST_SHOW) {
        return {
            visible: true,
            type: payload.type
        };
    }

    if (type === ALERT_TOAST_HIDE) {
        return {
            visible: false,
            type: null
        };
    }

    return state;
}
