import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId, generateTabId } from 'utils';
import { withData } from './data';
import { PrivateRecordingGateUI } from './ui';

export class PrivateRecordingGate extends PureComponent {
    static displayName = 'PrivateRecordingGateContainer';

    static propTypes = {
        callDate: PropTypes.string,
        currentState: PropTypes.objectOf(PropTypes.any),
        disconnectPrivateRecording: PropTypes.func.isRequired,
        eventId: PropTypes.string,
        hasTranscript: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isOwner: PropTypes.bool,
        logsLoading: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        privateRecordingId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        callDate: undefined,
        currentState: undefined,
        eventId: undefined,
        hasTranscript: false,
        isOwner: false,
        logsLoading: true,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.disconnect = this.disconnect.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.toggleLogs = this.toggleLogs.bind(this);

        this.state = {
            disconnected: false,
            disconnecting: false,
            showLogs: false
        };
    }

    disconnect() {
        this.setState({ disconnecting: true }, () => {
            const { disconnectPrivateRecording, privateRecordingId } = this.props;
            disconnectPrivateRecording(privateRecordingId).finally(() => {
                this.setState({
                    disconnected: true,
                    disconnecting: false
                });
            });
        });
    }

    onEdit() {
        const { eventId, history, pathname } = this.props;
        history.push(
            generateModalId({
                pathname,
                id: eventId,
                type: 'privateRecording',
                tabId: generateTabId({ audioCallId: eventId })
            })
        );
    }

    toggleLogs() {
        this.setState(({ showLogs }) => ({ showLogs: !showLogs }));
    }

    render() {
        const { disconnected, disconnecting, showLogs } = this.state;
        const { callDate, currentState, eventId, hasTranscript, isOwner, logsLoading, styles } = this.props;
        return (
            <PrivateRecordingGateUI
                callDate={callDate}
                currentState={currentState}
                disconnect={this.disconnect}
                disconnected={disconnected}
                disconnecting={disconnecting}
                eventId={eventId}
                hasTranscript={hasTranscript}
                isOwner={isOwner}
                logsLoading={logsLoading}
                onEdit={this.onEdit}
                showLogs={showLogs}
                styles={styles}
                toggleLogs={this.toggleLogs}
            />
        );
    }
}

export const PrivateRecordingGateContainer = compose(
    withUrlContext(['history', 'pathname']),
    withData()
)(PrivateRecordingGate);
