import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import XDate from 'xdate';
import { CONTENT_TYPES, TAB_TYPES } from 'consts';
import { FILING_FORM_FILTERS, OPERATORS, TYPES } from 'consts/filters';
import { contentMatchFragment, transcriptMatchFragment } from 'graphql/fragments/streams';
import { get, generateInitials, getNativePrice, generateTabId, generateTabURL, getCompanyQuotes } from 'utils';

const FILTERS_TO_RULES = {
    [TYPES.collapse]: 'collapse',
    [TYPES.company]: 'company_id',
    [TYPES.content]: 'content_id',
    [TYPES.country]: 'country_code',
    [TYPES.date]: 'date',
    [TYPES.domain]: 'domain',
    [TYPES.equity]: 'equity_id',
    [TYPES.event]: 'event_id',
    [TYPES.eventDate]: 'date',
    [TYPES.eventGroup]: 'event_group_id',
    [TYPES.eventTonalSentiment]: 'tonal_sentiment',
    [TYPES.eventStatus]: 'status',
    // Only valid on event stream, where searchTerm is what's used
    [TYPES.eventTitle]: 'search_term',
    // Only applies to event
    [TYPES.eventType]: 'event_type',
    [TYPES.exchangeCountry]: 'exchange_country_code',
    [TYPES.filingForm]: 'filing_form',
    [TYPES.filingFormCategory]: 'filing_form_category',
    [TYPES.index]: 'index_id',
    [TYPES.isArchived]: 'is_archived',
    [TYPES.isRead]: 'is_read',
    [TYPES.isStarred]: 'is_starred',
    [TYPES.mcap]: 'marketcap',
    [TYPES.mentioningCompany]: 'mentioning_company_id',
    [TYPES.newsSource]: 'news_source',
    [TYPES.newsSourceTag]: 'news_source_tag',
    [TYPES.offeringType]: 'offering_type',
    [TYPES.pe]: 'pricetoearnings',
    [TYPES.person]: 'person_id',
    [TYPES.publishDate]: 'date',
    [TYPES.publishedTranscriptSource]: 'published_transcript_source',
    [TYPES.recordDate]: 'date',
    [TYPES.revenue]: 'totalrevenue',
    [TYPES.scope]: 'scope',
    [TYPES.searchTerm]: 'search_term',
    [TYPES.source]: 'source',
    [TYPES.sourceCategory]: 'stream_source_category',
    [TYPES.spotlightType]: 'spotlight_type',
    [TYPES.tag]: 'tag',
    [TYPES.transcriptStatus]: 'transcript_status',
    [TYPES.type]: 'type',
    [TYPES.valuation]: 'valuation',
    [TYPES.watchlist]: 'watchlist_id'
};

const OPERATORS_TO_CONDITIONS = {
    [OPERATORS.between]: 'is_between',
    [OPERATORS.greaterThanOrEqual]: 'is_greater_than_or_equal_to',
    [OPERATORS.greaterThan]: 'is_greater_than',
    [OPERATORS.isNot]: 'is_not_equal',
    [OPERATORS.is]: 'is_equal',
    [OPERATORS.lessThanOrEqual]: 'is_less_than_or_equal_to',
    [OPERATORS.lessThan]: 'is_less_than',
    [OPERATORS.withinDays]: 'is_within',
    [OPERATORS.withinMonths]: 'is_within',
    [OPERATORS.withinWeeks]: 'is_within'
};

const GROUPED_RULE_TYPES = {
    events: ['source', 'type', 'event_type'],
    default: ['search_term', 'source', 'type', 'event_type', 'spotlight_type', 'filing_form-is_equal', 'tag']
};

function getRuleCondition({ operator }) {
    return OPERATORS_TO_CONDITIONS[operator];
}

function getRuleType({ type, value }) {
    if (type === TYPES.sector) {
        const { gicsSectorId } = value;
        return gicsSectorId ? 'gics_sector_id' : 'gics_sub_sector_id';
    }

    return FILTERS_TO_RULES[type] || 'unsupported';
}

function getRuleValue({ type, operator, value }) {
    if (operator === OPERATORS.withinDays) {
        return { function: 'timedelta', kwargs: { delta: `${value}d` } };
    }
    if (operator === OPERATORS.withinWeeks) {
        return { function: 'timedelta', kwargs: { delta: `${value}w` } };
    }
    if (operator === OPERATORS.withinMonths) {
        return { function: 'timedelta', kwargs: { delta: `${value}m` } };
    }
    if ([TYPES.date, TYPES.eventDate, TYPES.publishDate, TYPES.recordDate].includes(type)) {
        if ([OPERATORS.is, OPERATORS.isNot].includes(operator)) {
            return { function: value };
        }
        if (operator === OPERATORS.between) {
            return value.map(v => new XDate(v).toString('MM/dd/yyyy'));
        }
        return new XDate(value).toString('MM/dd/yyyy');
    }

    if (type === TYPES.sector) {
        const { gicsSectorId, gicsSubSectorId } = value;
        return gicsSectorId || gicsSubSectorId;
    }
    return value;
}

export function mapFilterToRule(filter) {
    switch (filter.type) {
        case TYPES.searchTerm:
        case TYPES.tag:
            return filter.value.map(value => ({
                ruleType: getRuleType({ ...filter, value }),
                condition: getRuleCondition({ ...filter, value }),
                value: getRuleValue({ ...filter, value })
            }));
        case TYPES.eventType:
            return flatten(Object.values(filter.value)).map(value => ({
                ruleType: getRuleType({ ...filter, value }),
                condition: getRuleCondition({ ...filter, value }),
                value: getRuleValue({ ...filter, value })
            }));
        case TYPES.source:
            return flatten(Object.values(filter.value)).map(value => ({
                ruleType: getRuleType({ ...filter, value }),
                condition: getRuleCondition({ ...filter, value }),
                value: getRuleValue({ ...filter, value })
            }));
        default:
            return {
                ruleType: getRuleType(filter),
                condition: getRuleCondition(filter),
                value: getRuleValue(filter)
            };
    }
}

export function mapFiltersToRules(filters) {
    return flatten(
        (filters || [])
            .filter(f => f && f.type && f.operator && f.value !== undefined && f.value !== null)
            .map(mapFilterToRule)
    );
}

export function getBookmarkFilters(filterKey) {
    const rules = [];
    if (filterKey === 'all') {
        rules.push({ type: TYPES.scope, operator: OPERATORS.is, value: 'organization' });
    } else if (filterKey === 'team') {
        rules.push(
            { type: TYPES.scope, operator: OPERATORS.is, value: 'organization' },
            { type: TYPES.scope, operator: OPERATORS.isNot, value: 'user' }
        );
    }
    return rules;
}

const RULES_TO_FILTERS = Object.fromEntries(Object.entries(FILTERS_TO_RULES).map(i => i.reverse()));
RULES_TO_FILTERS.gics_sector_id = TYPES.sector;
RULES_TO_FILTERS.gics_sub_sector_id = TYPES.sector;
RULES_TO_FILTERS.date = TYPES.date;
RULES_TO_FILTERS.type = TYPES.type;
RULES_TO_FILTERS.event_type = TYPES.type;

const CONDITIONS_TO_OPERATORS = Object.fromEntries(Object.entries(OPERATORS_TO_CONDITIONS).map(i => i.reverse()));

function getFilterType({ ruleType }, streamType) {
    const filterType = RULES_TO_FILTERS[ruleType];
    if (streamType === 'content') {
        return ruleType === FILTERS_TO_RULES.eventType ? TYPES.eventType : filterType;
    }

    if (streamType === 'events') {
        return filterType === TYPES.date
            ? TYPES.eventDate
            : filterType === TYPES.type
            ? TYPES.eventType
            : filterType === TYPES.searchTerm
            ? TYPES.eventTitle
            : filterType;
    }

    if (streamType === 'custom_data') {
        return filterType === TYPES.date ? TYPES.recordDate : filterType;
    }

    return filterType;
}

function getFilterOperator({ condition, value }) {
    if (['is_within', 'is_within_past'].includes(condition)) {
        return { d: OPERATORS.withinDays, w: OPERATORS.withinWeeks, m: OPERATORS.withinMonths }[
            get(value, 'kwargs.delta', '').slice(-1)[0]
        ];
    }
    return CONDITIONS_TO_OPERATORS[condition];
}

function getFilterValue({ ruleType, condition, value }) {
    if (ruleType === 'gics_sector_id') {
        return { gicsSectorId: value };
    }
    if (ruleType === 'gics_sub_sector_id') {
        return { gicsSubSectorId: value };
    }
    if (['is_within', 'is_within_past'].includes(condition)) {
        return get(value, 'kwargs.delta', '').slice(0, -1);
    }
    if (ruleType === 'date' && ['is_equal', 'is_not_equal'].includes(condition)) {
        return get(value, 'function');
    }
    return value;
}

function getFilterLabel({
    ruleType,
    country,
    equity,
    sector,
    watchlist,
    newsSource,
    newsSourceTag,
    eventGroup,
    filingForm,
    index,
    person
}) {
    if (ruleType === 'country_code') {
        return get(country, 'shortName');
    }
    if (ruleType === 'equity_id') {
        const localTicker = get(equity, 'localTicker');
        const exchange = get(equity, 'exchange.shortName');
        let label = localTicker;
        if (exchange) {
            label = `${localTicker}:${exchange}`;
        }
        return label;
    }
    if (['gics_sector_id', 'gics_sub_sector_id'].includes(ruleType)) {
        return get(sector, 'name');
    }
    if (ruleType === 'watchlist_id') {
        return get(watchlist, 'name');
    }
    if (ruleType === 'news_source') {
        return get(newsSource, 'displayName');
    }
    if (ruleType === 'news_source_tag') {
        return get(newsSourceTag, 'name');
    }
    if (ruleType === 'event_group_id') {
        return get(eventGroup, 'title');
    }
    if (ruleType === 'filing_form') {
        return get(filingForm, 'formNumber');
    }
    if (ruleType === 'index_id') {
        return get(index, 'displayName');
    }
    if (ruleType === 'person_id') {
        return get(person, 'name');
    }
    return null;
}

function mapRuleToFilter(rule, streamType) {
    return {
        type: getFilterType(rule, streamType),
        operator: getFilterOperator(rule),
        value: getFilterValue(rule),
        label: getFilterLabel(rule)
    };
}

function groupRules(rules, streamType) {
    const groupedRuleTypes = GROUPED_RULE_TYPES[streamType] || GROUPED_RULE_TYPES.default;
    return flatten(
        Object.values(
            rules.reduce((group, rule) => {
                const ret = { ...group };
                const key = `${rule.ruleType}-${rule.condition}`;
                // For grouped rule types, combine the values for matching operators
                if (groupedRuleTypes.includes(rule.ruleType) || groupedRuleTypes.includes(key)) {
                    // Source and event type filter values are expected to be objects
                    if (['source', 'type', 'event_type'].includes(rule.ruleType)) {
                        const type = RULES_TO_FILTERS[rule.ruleType];
                        ret[key] = ret[key]
                            ? {
                                  ...rule,
                                  value: { [type]: [...ret[key].value[type], rule.value] }
                              }
                            : {
                                  ...rule,
                                  value: { [type]: [rule.value] }
                              };
                    } else {
                        ret[key] = ret[key]
                            ? { ...rule, value: [...ret[key].value, rule.value] }
                            : { ...rule, value: [rule.value] };
                    }
                } else {
                    ret.all = ret.all ? [...ret.all, rule] : [rule];
                }
                return ret;
            }, {})
        )
    );
}

export function mapRulesToFilters(rules, streamType) {
    return groupRules(rules || [], streamType).map(r => mapRuleToFilter(r, streamType));
}

export function mapDashFiltersToRules({ bookmarkTags, searchTerm, dateRange, filters, sources, equityScope, scopes }) {
    let bookmarkTagRules = [];
    let searchTermRules = [];
    let dateRangeRules = [];
    let filterRules = [];
    let sourceRules = [];
    let equityScopeRules = [];
    let scopeRules = [];

    if (bookmarkTags) {
        bookmarkTagRules = mapFiltersToRules([{ type: TYPES.tag, operator: OPERATORS.is, value: bookmarkTags }]);
    }

    if (searchTerm) {
        searchTermRules = mapFiltersToRules([{ type: TYPES.searchTerm, operator: OPERATORS.is, value: [searchTerm] }]);
    }

    if (dateRange && dateRange.length) {
        dateRangeRules = mapFiltersToRules([{ type: TYPES.date, operator: OPERATORS.between, value: dateRange }]);
    }

    if (filters && filters.length) {
        filterRules = mapFiltersToRules(filters);
    }

    if (sources) {
        sourceRules = mapFiltersToRules(
            flatten(
                Object.entries(sources).map(([type, values]) =>
                    values.map(value => ({
                        type,
                        operator: OPERATORS.is,
                        value
                    }))
                )
            )
        );
    }

    if (equityScope && equityScope.length) {
        equityScopeRules = flatten(
            equityScope.map(({ type = TYPES.sector, value, operator }) =>
                mapFiltersToRules([{ type, operator: operator || OPERATORS.is, value }])
            )
        );
    }

    if (scopes) {
        scopeRules = flatten(scopes.map(({ operator, type, value }) => mapFiltersToRules([{ operator, type, value }])));
    }

    return flatten([
        bookmarkTagRules,
        searchTermRules,
        dateRangeRules,
        filterRules,
        sourceRules,
        equityScopeRules,
        scopeRules
    ]).filter(r => r);
}

export function mapDashRulesToFilters(rules) {
    const filters = mapRulesToFilters(rules);
    const bookmarkTags = [];
    const dateFilters = [];
    const equityScope = [];
    const scopes = [];
    const searchTermFilters = [];
    const sourceFilters = [];
    const otherFilters = [];
    filters.forEach(f => {
        if (f.type === TYPES.date) dateFilters.push(f);
        if ([TYPES.equity, TYPES.index, TYPES.sector, TYPES.watchlist].includes(f.type)) equityScope.push(f);
        if (f.type === TYPES.scope) scopes.push(f);
        if (f.type === TYPES.searchTerm) searchTermFilters.push(f);
        if ([TYPES.sourceCategory, TYPES.eventType, TYPES.domain].includes(f.type)) sourceFilters.push(f);
        if (f.type === TYPES.tag) bookmarkTags.push(f);
        if (
            ![
                TYPES.date,
                TYPES.domain,
                TYPES.equity,
                TYPES.eventType,
                TYPES.index,
                TYPES.sector,
                TYPES.scope,
                TYPES.searchTerm,
                TYPES.sourceCategory,
                TYPES.tag,
                TYPES.watchlist
            ].includes(f.type)
        ) {
            otherFilters.push(f);
        }
    });
    const searchTerm = get(searchTermFilters, '[0].value[0]');
    const dateRange = get(dateFilters, '[0].value');
    const sources = Object.fromEntries(
        Object.entries(groupBy(sourceFilters, ({ type }) => type)).map(([type, group]) => [
            type,
            group.map(({ value }) => value)
        ])
    );
    return {
        bookmarkTags,
        dateRange,
        equityScope,
        filters: otherFilters,
        scopes,
        searchTerm,
        sources
    };
}

export const mapFiltersToBookmarkTags = filters => {
    return get(
        (filters || []).find(({ operator, type }) => operator === OPERATORS.is && type === TYPES.tag),
        'value',
        []
    );
};

export const mapContentTypeToFilter = value => {
    return {
        type: TYPES.type,
        operator: OPERATORS.is,
        value
    };
};

export const mapContentTypesToFilters = types => {
    if (types && types.length) {
        return types.map(mapContentTypeToFilter);
    }
    return [];
};

export const mapFilingFormToFilter = (operator, value) => {
    return {
        type: TYPES.filingForm,
        operator,
        value
    };
};

// Format for the dash sources filter
export const mapEarningsOnlyToFilters = earningsOnly => {
    if (earningsOnly) {
        return {
            [TYPES.eventType]: [['earnings']]
        };
    }
    return {};
};

export const mapRulesToEarningsOnly = rules => {
    return !!(rules || []).find(
        r => r.condition === 'is_equal' && r.ruleType === 'event_type' && r.value === 'earnings'
    );
};

/**
 * If all forms are checked, it’s everything so we don’t need any rules
 * If some combination of the forms (but not all) are checked AND NOT "Other", just add `is_equal` rules for those
 * If only "Other" is checked, add `is_not_equal` rules for the rest of the forms
 * If "Other" + some combination of forms are checked (but not all), add `is_not_equal` rules for any unchecked forms
 */
export const mapFilingFormsToFilters = filingForms => {
    const filters = [];
    const filingFormKeys = Object.keys(FILING_FORM_FILTERS);
    if (filingForms && filingForms.length && filingForms.length !== filingFormKeys.length) {
        const hasOther = filingForms.includes('Other');
        filingFormKeys.forEach(key => {
            const formId = FILING_FORM_FILTERS[key];
            const checked = filingForms.includes(key);
            if (formId && hasOther && !checked) {
                filters.push(mapFilingFormToFilter(OPERATORS.isNot, Number(formId)));
            }
            if (formId && !hasOther && checked) {
                filters.push(mapFilingFormToFilter(OPERATORS.is, Number(formId)));
            }
        });
    }
    return filters;
};

export const mapScopesToFilters = scopes => {
    return (scopes || []).map(value => ({
        type: TYPES.scope,
        operator: OPERATORS.is,
        value
    }));
};

// The default scope is everything (user + team) so no filters means return both
export const mapFiltersToScopes = filters => {
    const scopes = [];
    if (!get(filters, 'length')) {
        return ['team', 'user'];
    }
    (filters || []).forEach(f => {
        if (f.operator === OPERATORS.is && f.value === 'team') scopes.push('team');
        if (f.operator === OPERATORS.is && f.value === 'user') scopes.push('user');
    });
    return scopes;
};

export const mapScopesToSelectedType = scopes => {
    let orgFilter = false;
    let userFilter = false;
    (scopes || []).forEach(f => {
        if (f.operator === OPERATORS.is && f.value === 'organization') orgFilter = true;
        if (f.operator === OPERATORS.isNot && f.value === 'user') userFilter = true;
    });
    if (!get(scopes, 'length')) return 'my';
    if (orgFilter && userFilter) return 'team';
    if (orgFilter && !userFilter) return 'all';
    return null;
};

function formatTranscriptMatch(match) {
    const bookmark = get(match, 'bookmark');
    const bookmarkId = ['archived', 'deleted'].includes(get(bookmark, 'status')) ? null : get(bookmark, 'id');

    return {
        bookmarkId,
        eventType: get(match, 'transcript.event.callType'),
        callDate: get(match, 'transcript.event.callDate'),
        company: get(match, 'transcript.event.equity.commonName'),
        contentType: 'transcript',
        deleted: false,
        equityIcon: get(match, 'transcript.event.equity.icon'),
        equityInitials: generateInitials(get(match, 'transcript.event.equity.commonName')),
        eventId: get(match, 'transcript.event.eventId'),
        exchangeName: get(match, 'transcript.event.equity.exchange.shortName'),
        grouped: get(match, 'collapsed', []).map(formatTranscriptMatch),
        hasPublishedTranscript: get(match, 'transcript.event.hasPublishedTranscript'),
        highlightColor: bookmarkId ? get(bookmark, 'highlightColor') : undefined,
        id: get(match, 'id'),
        isArchived: get(match, 'transcript.event.userSettings.archived'),
        isRead: get(match, 'transcript.event.userSettings.isRead'),
        isStarred: get(match, 'transcript.event.userSettings.starred'),
        itemId: get(match, 'transcript.itemId'),
        matchId: get(match, 'id'),
        pressUrl: get(match, 'transcript.event.pressUrl'),
        score: get(match, 'userSettings.score'),
        sentiment: get(match, 'transcript.sentiment.primarySentiment', 'neu'),
        slidesUrl: get(match, 'transcript.event.slidesUrl'),
        speaker: get(match, 'transcript.speaker'),
        startMs: get(match, 'transcript.startMs'),
        startTimestamp: get(match, 'transcript.startTimestamp', get(match, 'transcript.event.callDate')),
        tags: get(match, 'transcript.event.tags', []),
        ticker: get(match, 'transcript.event.equity.localTicker'),
        title: get(match, 'transcript.event.subTitle', get(match, 'transcript.event.title')),
        transcript: get(match, 'highlights', []).join('\n\n') || get(match, 'transcript.transcript'),
        transcriptRaw: get(match, 'transcript.transcript'),
        transcriptTime: get(match, 'transcript.startTimestamp')
            ? new XDate(get(match, 'transcript.startTimestamp'))
            : null,
        type: 'transcript',
        userTags: get(match, 'transcript.event.userSettings.tags', [])
    };
}

function formatRollupMatch(match) {
    return {
        deleted: false,
        type: 'rollup',
        id: get(match, 'id'),
        total: get(match, 'total'),
        streamName: get(match, 'stream.name'),
        streamId: get(match, 'stream.id'),
        dashboardName: get(match, 'dashboard.name', get(match, 'stream.dashboards[0].name')),
        dashboardId: get(match, 'dashboard.id', get(match, 'stream.dashboards[0].id')),
        dashboardStreams: get(match, 'collapsed', []).map(formatRollupMatch)
    };
}

function formatGSheetMatch(match) {
    const matchProps = {
        type: 'gsheet',
        id: get(match, 'id')
    };
    get(match, 'fields', []).forEach(({ name, value }) => {
        matchProps[name] = value;
    });

    return matchProps;
}

function formatGenericSpotlight(content) {
    return {
        eventDate: get(content, 'eventDate')
    };
}

function formatGuidance(content) {
    return {
        spotlightSubtype: get(content, 'guidanceTrend'),
        guidanceTrend: get(content, 'guidanceTrend'),
        ...formatGenericSpotlight(content)
    };
}

function formatFiling(content) {
    return {
        formNumber: get(content, 'filing.form.formNumber'),
        formShortName: get(content, 'filing.form.formNameShort'),
        formName: get(content, 'filing.form.formName'),
        isAmendment: get(content, 'filing.isAmendment'),
        periodEndDate: get(content, 'filing.periodEndDate'),
        arrivalDate: get(content, 'filing.arrivalDate'),
        officialUrl: get(content, 'filing.officialUrl')
    };
}

export function formatCalendarEvent(event) {
    const { callDate, eventId, callType, hasTonalSentiment, hasUnknownTime, title } = event || {};
    const eventDate = new XDate(callDate);
    return {
        callDate,
        date: eventDate.toString('Mdyy'),
        hasTonalSentiment,
        hasUnknownTime,
        id: eventId,
        time: eventDate.toString('h:mm TT'),
        title,
        type: callType
    };
}

export function formatCorporateAction(content) {
    return {
        actionSubtype: get(content, 'actionSubtype'),
        actionType: get(content, 'actionType'),
        eventId: get(content, 'corporateActionEventId'),
        source: 'Aiera'
    };
}

const contentFormatMap = {
    PartnershipSpotlightContent: formatGenericSpotlight,
    AssetPurchaseSpotlightContent: formatGenericSpotlight,
    BuybackSpotlightContent: formatGenericSpotlight,
    SalesMetricSpotlightContent: formatGenericSpotlight,
    MAndASpotlightContent: formatGenericSpotlight,
    SpinOffSpotlightContent: formatGenericSpotlight,
    IPOSpotlightContent: formatGenericSpotlight,
    GuidanceSpotlightContent: formatGuidance,
    FilingContent: formatFiling,
    CorporateActionContent: formatCorporateAction
};

function formatSubcontent(match) {
    const formatter = contentFormatMap[get(match, 'content.__typename')];
    return {
        displayType: get(match, 'content.displayType'),
        ...(formatter ? formatter(get(match, 'content')) : {})
    };
}

export function formatBookmarkTargetProps(target) {
    const type = get(target, '__typename');
    let props = {
        date: new XDate(get(target, 'publishedDate')),
        equity: get(target, 'primaryEquity'),
        title: get(target, 'title')
    };
    switch (type) {
        case 'FilingContent':
            props = {
                ...props,
                date: new XDate(get(target, 'filing.releaseDate')),
                title: get(target, 'title')
            };
            break;
        case 'NewsContent':
            props = {
                ...props,
                source: get(target, 'newsSource.name')
            };
            break;
        case 'ScheduledAudioCall':
            props = {
                ...props,
                date: new XDate(get(target, 'callDate')),
                hasUnknownTime: get(target, 'hasUnknownTime')
            };
            break;
        case 'ScheduledAudioCallEvent':
            props = {
                ...props,
                date: new XDate(get(target, 'event.callDate')),
                equity: get(target, 'event.equity'),
                eventItemId: get(target, 'itemId'),
                hasUnknownTime: get(target, 'event.hasUnknownTime'),
                speakerName: get(target, 'speaker.name'),
                speakerTitle: get(target, 'speaker.title'),
                speakerAffiliation: get(target, 'speaker.affiliation'),
                startMs: get(target, 'startMs'),
                startTimestamp: get(target, 'startTimestamp', get(target, 'event.callDate')),
                title: get(target, 'event.title')
            };
            break;
        default:
            break;
    }
    return props;
}

function formatBookmarkMatch(match) {
    const bookmark = get(match, 'bookmark', {});
    const collapsed = get(match, 'collapsed', []);
    const { target, user } = bookmark;
    return {
        bookmarkUrlType: get(bookmark, 'bookmarkUrlType'),
        collapsed: collapsed.map(formatBookmarkMatch),
        created: new XDate(get(bookmark, 'created', new Date())),
        creatorName: `${get(user, 'firstName', get(user, 'email', ''))} ${get(user, 'lastName', '')}`,
        creatorId: get(user, 'id'),
        deleted: ['archived', 'deleted'].includes(get(bookmark, 'status')),
        highlight: get(bookmark, 'highlight'),
        highlightColor: get(bookmark, 'highlightColor'),
        id: get(bookmark, 'id'),
        matchId: get(match, 'id'),
        note: get(bookmark, 'note'),
        shared: get(bookmark, 'shared'),
        tags: get(bookmark, 'tags'),
        target,
        targetId: get(bookmark, 'targetId'),
        targetStreamId: get(bookmark, 'targetStreamId'),
        targetType: get(bookmark, 'targetType'),
        type: 'bookmark',
        userId: get(user, 'id'),
        ...formatBookmarkTargetProps(target)
    };
}

function formatCompanyMatch(match) {
    const instruments = get(match, 'company.instruments', []);
    const { primaryQuote, quotes } = getCompanyQuotes(instruments);
    const technicals = get(primaryQuote, 'technicals');
    return {
        averageDailyVolume: get(technicals, 'averageDailyVolume'),
        companyId: get(match, 'company.id'),
        companyName: get(match, 'company.commonName'),
        currency: get(primaryQuote, 'currency'),
        exchangeName: get(primaryQuote, 'exchange.shortName'),
        iconUrl: get(match, 'company.iconUrl'),
        id: get(match, 'id'),
        initials: generateInitials(get(match, 'company.commonName')),
        instruments,
        latestOhlc: get(primaryQuote, 'latestOhlc'),
        newToday: get(match, 'company.statistics.newToday'),
        prevClose: get(primaryQuote, 'prevClose'),
        pricetoearnings: get(technicals, 'pricetoearnings'),
        primaryQuote,
        quotes,
        ticker: get(primaryQuote, 'localTicker'),
        type: 'company',
        volume: get(technicals, 'volume')
    };
}

function formatContentMatch(match) {
    const bookmark = get(match, 'bookmark');
    const bookmarkId = ['archived', 'deleted'].includes(get(bookmark, 'status')) ? null : get(bookmark, 'id');
    return {
        additionalHighlights: get(match, 'highlights', []).slice(1),
        attachmentType: get(match, 'content.attachmentType'),
        authors: get(match, 'content.authors', []),
        body: get(match, 'content.body'),
        bookmarkId,
        categories: get(match, 'content.categories'),
        company: get(match, 'content.primaryEquity.commonName'),
        contentId: get(match, 'content.id'),
        contentType: get(match, 'content.contentType'),
        createdDate: get(match, 'content.createdDate'),
        deleted: false,
        docType: get(match, 'content.docType'),
        documentFormat: get(match, 'content.documentFormat'),
        equities: get(match, 'content.equities', []),
        equityIcon: get(match, 'content.primaryEquity.icon'),
        equityInitials: generateInitials(get(match, 'content.primaryEquity.commonName')),
        events: get(match, 'content.events', []),
        eventId: get(match, 'content.eventId'),
        exchangeName: get(match, 'content.primaryEquity.exchange.shortName'),
        grouped: get(match, 'collapsed', []).map(formatContentMatch),
        highlightColor: bookmarkId ? get(bookmark, 'highlightColor') : undefined,
        highlightTitle: get(match, 'highlightTitle'),
        highlights: get(match, 'highlights[0]'),
        highlightsMatches: get(match, 'highlightsMatches', []),
        id: get(match, 'content.id'),
        isArchived: get(match, 'content.userSettings.archived', false),
        isRead: get(match, 'content.userSettings.isRead', false),
        isStarred: get(match, 'content.userSettings.starred', false),
        matchId: get(match, 'id'),
        newsSource: get(match, 'content.newsSource.name'),
        numPages: get(match, 'content.numPages'),
        originalFileName: get(match, 'content.originalFileName'),
        pdfUrl: get(match, 'content.pdfUrl'),
        primaryEquity: get(match, 'content.primaryEquity'),
        publishedDate: get(match, 'content.publishedDate'),
        publishers: get(match, 'content.publishers', []),
        score: get(match, 'userSettings.score'),
        source: get(match, 'content.source'),
        synopsis: get(match, 'content.synopsis'),
        tags: get(match, 'content.tags', []),
        ticker: get(match, 'content.primaryEquity.localTicker'),
        title: get(match, 'content.title'),
        type: get(match, 'content.contentType'),
        uploadingUserId: get(match, 'content.uploadingUserId'),
        uploadingUserEmail: get(match, 'content.user.email'),
        url: get(match, 'content.url'),
        userTags: get(match, 'content.userSettings.tags', []),
        ...formatSubcontent(match)
    };
}

function formatEventMatch(match) {
    const firstName = get(match, 'event.createdByUser.firstName');
    const lastName = get(match, 'event.createdByUser.lastName');
    const username = get(match, 'event.createdByUser.username');
    const noLiveAccess = get(match, 'event.noLiveAccess');
    const currency = get(match, 'event.equity.currency');
    const priceMovementPercent = get(match, 'event.priceHighlight.movementPercent')
        ? get(match, 'event.priceHighlight.movementPercent') * 100
        : undefined;
    const summaries = get(match, 'event.summarizations', []).filter(({ modelType }) => modelType === 'zeroshot');
    const eventType = get(match, 'event.callType');
    const everything = summaries.find(({ summaryType }) => summaryType === 'everything');
    const pres = summaries.find(({ summaryType }) => summaryType === 'presentation');
    const qa = summaries.find(({ summaryType }) => summaryType === 'q_and_a');
    const summary = everything || pres || qa;
    const summaryTitle = get(summary, 'title', '');
    const summaryText = get(summary, 'summary', []);
    const eventTitle = get(match, 'event.title');
    let creator = username;
    let price = get(match, 'event.priceHighlight.endOrLatestPrice');
    if (price) {
        price = getNativePrice({ price, currency });
    }
    if (firstName && lastName) {
        creator = `${firstName} ${lastName.slice(0, 1)}.`;
    }
    if (!get(match, 'event')) {
        return null;
    }
    return {
        alertEnabled: get(match, 'event.emailNotificationsEnabled'),
        audioCallId: get(match, 'event.eventId'),
        company: get(match, 'event.equity.commonName'),
        conference: get(match, 'event.eventGroups[0]'),
        creator,
        date: new XDate(get(match, 'event.callDate', new XDate())),
        deleted: !!get(match, 'event.deleted'),
        equityIcon: get(match, 'event.equity.icon'),
        equityInitials: generateInitials(get(match, 'event.equity.commonName')),
        eventId: get(match, 'event.eventId'),
        eventType,
        exchangeName: get(match, 'event.equity.exchange.shortName'),
        hasTonalSentiment: get(match, 'event.hasTonalSentiment'),
        hasTranscript: get(match, 'event.hasTranscript'),
        hasUnknownTime: get(match, 'event.hasUnknownTime', false),
        id: get(match, 'id'),
        isLive: get(match, 'event.isLive'),
        noLiveAccess,
        organizationName: get(match, 'event.createdByUser.organization.name'),
        overThreshold: get(match, 'event.priceHighlight.overThreshold'),
        price,
        priceMovementAbsolute: get(match, 'event.priceHighlight.movementAbsolute'),
        priceMovementPercent,
        score: get(match, 'userSettings.score'),
        summaryText,
        summaryTitle,
        ticker: get(match, 'event.equity.localTicker'),
        title: eventType === 'custom' ? eventTitle : get(match, 'event.subTitle', eventTitle),
        type: 'event'
    };
}

function formatEventGroupMatch(match) {
    return {
        type: 'event_group',
        id: get(match, 'id'),
        eventGroupId: get(match, 'eventGroup.id'),
        eventGroupTitle: get(match, 'eventGroup.title'),
        isPromoted: get(match, 'eventGroup.promoted'),
        startDate: get(match, 'eventGroup.start'),
        endDate: get(match, 'eventGroup.end'),
        hostEquityId: get(match, 'eventGroup.hostEquity.id'),
        hostEquityLocalTicker: get(match, 'eventGroup.hostEquity.localTicker'),
        hostEquityName: get(match, 'eventGroup.hostEquity.commonName'),
        hostEquityExchangeName: get(match, 'eventGroup.hostEquity.exchange.shortName'),
        equities: get(match, 'eventGroup.equities'),
        numEvents: get(match, 'eventGroup.numEvents', 0)
    };
}

function formatEquityMatch(match) {
    return {
        type: 'equity',
        id: get(match, 'id'),
        equityId: get(match, 'id'),
        company: get(match, 'equity.commonName'),
        deleted: false,
        equityIcon: get(match, 'equity.icon'),
        equityInitials: generateInitials(get(match, 'equity.commonName')),
        exchangeName: get(match, 'equity.exchange.shortName'),
        last: get(match, 'equity.last'),
        lastClose: get(match, 'equity.lastClose'),
        ticker: get(match, 'equity.localTicker'),
        sector: get(match, 'equity.sector.name'),
        marketCap: get(match, 'equity.marketcap'),
        nextEarnings: get(match, 'equity.nextEarnings'),
        pe: get(
            get(match, 'equity.valuation', []).find(v => v.metric === 'p_gaap_eps'),
            'nextTwelveMonths',
            0
        )
    };
}

function formatCustomDataMatch(match) {
    return {
        type: 'custom',
        company: get(match, 'record.equity.commonName'),
        id: get(match, 'id'),
        deleted: false,
        equityId: get(match, 'record.equityId'),
        ticker: get(match, 'record.equity.localTicker'),
        record: get(match, 'record'),
        dataRecordId: get(match, 'record.dataRecordId'),
        grouped: get(match, 'collapsed', []).map(formatCustomDataMatch)
    };
}

function formatDashboardMatch(match) {
    return {
        type: 'dashboard',
        name: get(match, 'dashboard.name'),
        recommended: get(match, 'dashboard.recommended', false),
        ownedByCurrentUser: get(match, 'dashboard.ownedByCurrentUser', false),
        numLiveEvents: get(match, 'dashboard.numLiveEvents', 0),
        streams: get(match, 'dashboard.streams', []),
        streamCount: get(match, 'dashboard.streams', []).length,
        dashboardType: get(match, 'dashboard.dashboardType'),
        dashboardId: get(match, 'dashboard.dashboardId'),
        equityId: get(match, 'dashboard.equityId'),
        id: get(match, 'id')
    };
}

function formatNotificationMatch(match) {
    return {
        content: get(match, 'notification.content'),
        created: get(match, 'notification.created'),
        dashboard: get(match, 'notification.dashboard'),
        deleted: get(match, 'notification.deleted'),
        equity: get(match, 'notification.equity'),
        event: get(match, 'notification.event'),
        id: get(match, 'notification.id'),
        isRead: get(match, 'notification.isRead'),
        message: get(match, 'notification.message'),
        notificationType: get(match, 'notification.notificationType'),
        stream: get(match, 'notification.stream'),
        transcriptId: get(match, 'notification.transcriptId'),
        type: 'notification'
    };
}

export function formatMatchesByType(matches) {
    return matches
        .map(m =>
            ({
                BookmarkStreamMatch: formatBookmarkMatch,
                CompanyStreamMatch: formatCompanyMatch,
                ContentStreamMatch: formatContentMatch,
                CustomDataStreamMatch: formatCustomDataMatch,
                DashboardRollupStreamMatch: formatRollupMatch,
                DashboardStreamMatch: formatDashboardMatch,
                EquityStreamMatch: formatEquityMatch,
                EventGroupStreamMatch: formatEventGroupMatch,
                EventStreamMatch: formatEventMatch,
                GSheetStreamMatch: formatGSheetMatch,
                NotificationStreamMatch: formatNotificationMatch,
                StreamRollupStreamMatch: formatRollupMatch,
                TranscriptStreamMatch: formatTranscriptMatch
            }[get(m, '__typename')](m))
        )
        .filter(m => m && !m.deleted);
}

export function generateStreamDescription({ rules }) {
    const filteredRules = rules.filter(
        ({ ruleType }) => ruleType !== TYPES.type && ruleType !== FILTERS_TO_RULES.eventType
    );
    return [
        ...flatten(
            mapRulesToFilters(filteredRules).map(({ type, label, value }) => {
                if ([TYPES.eventDate, TYPES.eventType, TYPES.source].includes(type)) {
                    return flatten(Object.values(value));
                }
                return label || value;
            })
        )
    ].join(', ');
}

const STREAM_TYPE_MAP = {
    attachment: 'content',
    corporate_action: 'content',
    document: 'content',
    filings: 'content',
    news: 'content',
    research: 'content',
    spotlight: 'content'
};

export function mapStreamType(streamType) {
    return STREAM_TYPE_MAP[streamType] || streamType;
}

export function getTypeFromStream(stream, defaultStreamType) {
    const streamType = get(stream, 'streamType', defaultStreamType);
    const rules = get(stream, 'rules', []);
    const spotlight = !!rules.find(r => r.ruleType === 'type' && r.value === 'spotlight');
    const filings = !!rules.find(r => r.ruleType === 'type' && r.value === 'filing');
    const transcript = !!rules.find(r => r.ruleType === 'type' && r.value === 'transcript');
    const attachment = !!rules.find(r => r.ruleType === 'type' && r.value === 'attachment');
    const news = !attachment && !transcript && !!rules.find(r => r.ruleType === 'type' && r.value === 'news');
    const document = !!rules.find(r => r.ruleType === 'type' && r.value === 'document');
    const research = !!rules.find(r => r.ruleType === 'type' && r.value === 'research');
    const corporateAction = !!rules.find(r => r.ruleType === 'type' && r.value === 'corporate_action');
    return spotlight
        ? 'spotlight'
        : filings
        ? 'filings'
        : news
        ? 'news'
        : document
        ? 'document'
        : research
        ? 'research'
        : corporateAction
        ? 'corporate_action'
        : streamType;
}

export function generateBookmarkUrl(bookmark, pathname = '') {
    const { bookmarkUrlType, target, targetId, targetStreamId: streamId } = bookmark;
    let itemId;
    let url;
    // Use bookmarkUrlType when set, otherwise generate the url based on target type
    if (bookmarkUrlType && targetId) {
        switch (bookmarkUrlType) {
            case 'event':
                url = generateTabURL({ eventId: targetId, pathname });
                break;
            case 'event_press_release':
                url = generateTabURL({
                    tabId: generateTabId({ id: targetId, tabType: TAB_TYPES.pdfPresentation }),
                    pathname
                });
                break;
            case 'event_slides':
                url = generateTabURL({
                    tabId: generateTabId({ id: targetId, tabType: TAB_TYPES.pdfSlides }),
                    pathname
                });
                break;
            case 'content_document':
            case 'document':
                url = generateTabURL({ documentId: targetId, pathname, match: !!streamId, streamId });
                break;
            case 'content_filing':
            case 'filing':
                url = generateTabURL({ filingId: targetId, pathname, match: !!streamId, streamId });
                break;
            case 'content_news':
            case 'news':
                url = generateTabURL({ newsId: targetId, pathname, match: !!streamId, streamId });
                break;
            case 'content_research':
            case 'research':
                url = generateTabURL({ researchId: targetId, pathname, match: !!streamId, streamId });
                break;
            case 'transcript':
                itemId = get(target, 'itemId');
                url = generateTabURL({
                    eventId: get(target, 'event.id'),
                    itemId,
                    pathname,
                    match: !!streamId,
                    page: 'text',
                    pageId: itemId,
                    streamId
                });
                break;
            default:
                break;
        }
    }
    if (!url) {
        const targetType = get(target, '__typename');
        if (CONTENT_TYPES.includes(targetType)) {
            const { id: contentId, contentType } = target;
            if (contentType === 'filing') {
                url = generateTabURL({ filingId: contentId, pathname, match: !!streamId, streamId });
            }
            if (contentType === 'news') {
                url = generateTabURL({ newsId: contentId, pathname, match: !!streamId, streamId });
            }
            if (contentType === 'research') {
                url = generateTabURL({ researchId: contentId, pathname, match: !!streamId, streamId });
            }
            if (contentType === 'spotlight') {
                url = generateTabURL({ spotlightId: contentId, pathname, match: !!streamId, streamId });
            }
            if (contentType === 'streetaccount') {
                url = generateTabURL({ streetAccountId: contentId, pathname, match: !!streamId, streamId });
            }
        }
        if (targetType === 'ScheduledAudioCall') {
            url = generateTabURL({ eventId: get(target, 'id'), pathname });
        }
        if (targetType === 'ScheduledAudioCallEvent') {
            itemId = get(target, 'itemId');
            url = generateTabURL({
                eventId: get(target, 'event.id'),
                itemId,
                pathname,
                match: !!streamId,
                page: 'text',
                pageId: itemId,
                streamId
            });
        }
    }
    return url;
}

export function normalizeBookmarks(bookmarks, pathname = '') {
    return (bookmarks || []).map(bookmark => {
        return {
            ...bookmark,
            url: generateBookmarkUrl(bookmark, pathname)
        };
    });
}

export function updateStreamMatchFragmentBookmark({ apolloClient, bookmark, matchId, targetType }) {
    let id;
    let fragment;
    let typename;
    if (['content', 'filing'].includes(targetType)) {
        id = `ContentStreamMatch:${matchId}`;
        fragment = gql`
            ${contentMatchFragment}
        `;
        typename = 'ContentStreamMatch';
    }
    if (targetType === 'transcript') {
        id = `TranscriptStreamMatch:${matchId}`;
        fragment = gql`
            ${transcriptMatchFragment}
        `;
        typename = 'TranscriptStreamMatch';
    }
    if (id && fragment && typename) {
        try {
            /**
             * Apollo requires that data passed into writeFragment includes the same
             * fields used in the fragment.
             */
            const match = apolloClient.readFragment({ id, fragment });
            if (match) {
                apolloClient.writeFragment({
                    id,
                    fragment,
                    data: {
                        ...match,
                        bookmark,
                        __typename: typename
                    }
                });
            }
        } catch {
            // Catch exceptions raised from updating the fragment in the cache directly
        }
    }
}
