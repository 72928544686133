export const styleSheet = theme => {
    const primaryCard = {
        backgroundColor: theme.colors.white01,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 13px'
    };
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            margin: 0,
            width: '100%',
            ':last-of-type': {
                borderBottom: `1px solid ${theme.colors.gray05}`
            },
            ':nth-of-type(odd)': {
                '> div': {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        highlight: {
            ...theme.content.newFadeAnimation
        },
        primaryCard: {
            ...primaryCard
        },
        primaryCardMobile: {
            ...primaryCard
        },
        sentiment: {
            position: 'absolute',
            left: 0,
            top: 10,
            backgroundColor: theme.colors.gray05,
            borderRadius: '0 3px 3px 0',
            height: '45px',
            width: '3px'
        },
        sentimentNeg: {
            backgroundColor: theme.colors.red01
        },
        sentimentPos: {
            backgroundColor: theme.colors.green01
        },
        spacer: {
            flex: 1
        }
    };
};
