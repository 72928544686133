import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withMobileUnreadCount {
                    notificationsStream {
                        id
                        unreadCount
                    }
                }
            `,
            {
                props: ({ data }) => {
                    return {
                        unreadCount: get(data, 'notificationsStream.unreadCount', 0)
                    };
                },
                options: {
                    fetchPolicy: 'cache-and-network'
                }
            }
        )
    );
