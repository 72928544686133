import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withWatchlistsData = () =>
    compose(
        graphql(
            gql`
                query withEquityScopeAutocompleteWatchlists {
                    currentUser {
                        id
                        watchlists {
                            id
                            name
                            watchlistId
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    watchlists: get(data, 'currentUser.watchlists', []),
                    watchlistsLoading: get(data, 'loading')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
