import gql from 'graphql-tag';
import { mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';

export const getIndicesQuery = alias => gql`
    query ${alias || 'GetIndices'} {
        indices: marketIndices {
            id
            shortName
            displayName
        }
    }
`;

export const withIndices = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withIndices';
    return graphql(getIndicesQuery(alias), {
        props: ({ data }) => ({
            indicesError: data.error,
            indicesLoading: data.loading,
            indices: data.indices
        }),
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};
