import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Td } from 'components/Td';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class ModuleTd extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.number
    };

    static defaultProps = {
        passedStyles: {},
        size: 3
    };

    render() {
        const { styles, passedStyles, children, size, ...rest } = this.props;
        return (
            <Td styles={{ ...styles.container, ...passedStyles }} {...rest}>
                {children && typeof children === 'string' ? <Text size={size}>{children}</Text> : children}
            </Td>
        );
    }
}

export const ModuleTdUI = compose(withStyleSheet(styleSheet))(ModuleTd);
