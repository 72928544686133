import Mixpanel from 'mixpanel-browser';
import { config } from 'configuration';

const { MIXPANEL_ENABLED, MIXPANEL_TOKEN } = config;

let initialized = false;
let trackingEvents = false;
export const isTracking = () => trackingEvents;

async function init() {
    initialized = true;

    if (MIXPANEL_ENABLED) {
        await Mixpanel.init(MIXPANEL_TOKEN);
    }
}

async function trackReduxEvents(userId, $email, name) {
    if (!trackingEvents && initialized && MIXPANEL_ENABLED) {
        trackingEvents = true;
        await Mixpanel.identify(userId);
        await Mixpanel.people.set({ $email, $name: name || $email });
    }
}

async function trackAction(action, sessionId, userId) {
    const { type, payload, track } = action;
    if (MIXPANEL_ENABLED) {
        if (type && type.includes('FAILURE')) {
            await Mixpanel.track(type, {
                distinct_id: userId,
                userId,
                sessionId,
                ...payload
            });
        } else if (track) {
            await Mixpanel.track(type, {
                distinct_id: userId,
                userId,
                sessionId,
                ...payload
            });
        }
    }
}

export const aieraReporting = ({ getState }) => next => action => {
    const {
        User: { sessionId, userId, email, name }
    } = getState();

    if (!initialized) {
        init();
    }

    if (userId && email && !trackingEvents) {
        trackReduxEvents(userId, email, name);
    }

    if (initialized && trackingEvents && !action.noTrack && MIXPANEL_ENABLED && action && action.type) {
        trackAction(action, sessionId, userId);
    }

    if (!userId || !email) {
        trackingEvents = false;
    }

    // Back to our regularly scheduled programming
    next(action);
};
