import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SpotlightFilterUI } from './ui';

export class SpotlightFilter extends PureComponent {
    static displayName = 'SpotlightFilterContainer';

    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        onChange: undefined,
        name: 'spotlightFilter',
        value: null
    };

    render() {
        const { name, value, onChange } = this.props;
        return <SpotlightFilterUI value={value} onChange={onChange} name={name} />;
    }
}

export const SpotlightFilterContainer = compose()(SpotlightFilter);
