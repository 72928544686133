import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get, getCompanyQuotes, mtNewsNormalize } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withNews($newsId: ID!, $streamId: ID, $includeHighlights: Boolean = false) {
                    content(filter: { contentIds: [$newsId] }) {
                        __typename
                        id
                        title
                        bodyWithHighlights: body(highlight: { streamId: $streamId }) @include(if: $includeHighlights)
                        body @skip(if: $includeHighlights)
                        contentType
                        publishedDate
                        primaryCompany {
                            id
                            commonName
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        companies {
                            id
                            commonName
                            iconUrl
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        categories {
                            id
                            shortName
                            displayName
                        }
                        userSettings {
                            id
                            shareBookmarks
                            isRead
                            archived
                            isRead
                            starred
                            tags
                        }
                        tags {
                            tag
                            users {
                                id
                                username
                            }
                        }
                        ... on NewsContent {
                            url
                            redirectUrl
                            canShowFullText
                            newsSource {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const content = get(data, 'content[0]');
                    const primaryCompany = get(content, 'primaryCompany');
                    const instruments = get(primaryCompany, 'instruments', []);
                    const { primaryQuote } = getCompanyQuotes(instruments);
                    const body = mtNewsNormalize(get(content, 'bodyWithHighlights', get(data, 'content[0].body', '')));

                    return {
                        body,
                        categories: get(content, 'categories', []),
                        companies: get(content, 'companies'),
                        company: get(primaryCompany, 'commonName'),
                        companyId: get(primaryCompany, 'id'),
                        contentType: get(content, 'contentType'),
                        exchange: get(primaryQuote, 'exchange.shortName'),
                        isArchived: get(content, 'userSettings.archived', false),
                        isRead: get(content, 'userSettings.isRead', false),
                        isStarred: get(content, 'userSettings.starred', false),
                        newsError: !!get(data, 'error'),
                        newsLoading: get(data, 'loading'),
                        newsSource: get(content, 'newsSource.name'),
                        publishedDate: get(content, 'publishedDate'),
                        shareBookmarks: get(content, 'userSettings.shareBookmarks', false),
                        tags: get(content, 'tags', []),
                        ticker: get(primaryQuote, 'localTicker'),
                        title: get(content, 'title'),
                        url: get(content, 'canShowFullText', true) ? get(content, 'url') : get(content, 'redirectUrl'),
                        userTags: get(content, 'userSettings.tags', [])
                    };
                },
                options: ({ newsId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    returnPreviousData: true,
                    variables: {
                        newsId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
