import { keyframes } from '@emotion/react';

// eslint-disable-next-line
const wordFader = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01,
        a: {
            textDecoration: 'inherit',
            color: 'inherit'
        },
        '.copyLiveText': {
            display: 'none'
        },
        ':hover': {
            '.copyLiveText': {
                display: 'block'
            }
        }
    },
    copy: {
        ':hover': {
            color: theme.colors.blue08
        }
    },
    innerContainer: {
        position: 'relative',
        padding: `8px ${theme.margins.margin00}`,
        margin: `0 5px`,
        borderRadius: 4,
        ':hover': {
            backgroundColor: theme.colors.gray05
        }
    },
    innerContainerCard: {
        position: 'relative',
        padding: `8px ${theme.margins.margin00}`,
        borderRadius: 4,
        ':hover': {
            backgroundColor: theme.colors.gray10
        }
    },
    header: {
        color: theme.colors.gray06,
        marginBottom: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    timestamp: {
        marginRight: 4
    },
    transcript: {
        fontSize: theme.fonts.fontSizes.size03,
        lineHeight: '1.3em',
        letterSpacing: 0.1
    },
    partialTranscript: {
        lineHeight: '1.3em',
        letterSpacing: 0.1
        // Remove for now since it's using a lot of GPU. Can add back
        // if we find a way to do this without using so much
        // opacity: 0,
        // animation: `${wordFader} 0.7s forwards`
    },
    annotations: {
        ...theme.content.annotated
    },
    searchResult: {
        padding: 0,
        overflow: 'hidden'
    }
});
