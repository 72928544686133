import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Text } from 'components/Text';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

class AddMonitorButton extends PureComponent {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        onAddMonitor: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        isStarred: PropTypes.bool.isRequired,
        restricted: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { restricted, passedStyles, styles, onAddMonitor, loading, theme, isStarred } = this.props;
        let containerStyle = styles.container;
        let iconType = 'newDashboard';
        if (restricted) {
            containerStyle = styles.containerLocked;
            iconType = 'lock02';
        }
        return (
            <Tooltip
                isEnabled
                useOutsideClickHandler
                useElementOffsetBottom
                useElementOffsetRight
                growLeft
                xOffset={0}
                yOffset={6}
                content={({ hideTooltip }) => (
                    <Div styles={styles.addOptions}>
                        <Div
                            onClick={() => {
                                onAddMonitor();
                                hideTooltip();
                            }}
                            styles={styles.addOption}
                        >
                            <Text weight="medium" size={3}>
                                Copy Monitor
                            </Text>
                            <Text styles={styles.addOptionDescription}>
                                Create your own local copy of this monitor, that you can modify.
                            </Text>
                        </Div>
                        <Div
                            onClick={() => {
                                onAddMonitor({ shouldFollow: true });
                                hideTooltip();
                            }}
                            styles={styles.addOption}
                        >
                            <Text weight="medium" size={3}>
                                {isStarred ? 'Unfollow Monitor' : 'Follow Monitor'}
                            </Text>
                            <Text styles={styles.addOptionDescription}>
                                {isStarred
                                    ? 'Remove this monitor from your sidebar.'
                                    : 'Add this monitor to your sidebar, and follow any changes made to it.'}
                            </Text>
                        </Div>
                    </Div>
                )}
            >
                {({ showTooltip }) => (
                    <ActionButton
                        onClick={showTooltip}
                        loading={loading}
                        styles={{ ...containerStyle, ...passedStyles }}
                        loaderColor={theme.colors.white01}
                        name={restricted ? 'add-monitor-locked' : 'add-monitor'}
                    >
                        <Icon type={iconType} color={restricted ? theme.colors.black01 : theme.colors.white01} />
                        <Text size={1}>Add Monitor</Text>
                        <Icon
                            styles={{ transform: 'rotate(90deg)', marginLeft: 8, marginTop: 2 }}
                            type="chevron02"
                            color={theme.colors.white01}
                        />
                    </ActionButton>
                )}
            </Tooltip>
        );
    }
}

export const AddMonitorButtonUI = compose(withStyleSheet(styleSheet))(AddMonitorButton);
