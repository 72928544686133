export const styleSheet = theme => {
    const upgradeButton = {
        ...theme.content.blueButton,
        backgroundColor: theme.colors.yellow07,
        boxShadow: 'unset',
        color: theme.colors.black01,
        marginLeft: 15,
        ':hover': {
            backgroundColor: theme.colors.yellow04
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    };
    return {
        container: {},
        content: {
            lineHeight: '1.3em',
            marginTop: theme.margins.margin01,
            padding: '0 35px'
        },
        footer: {
            ...theme.content.modalButtonsContainer,
            justifyContent: 'flex-end'
        },
        buttonsContainer: {
            alignItems: 'center',
            display: 'flex',
            flex: 1
        },
        cancelButton: {
            ...theme.content.cancelButton
        },
        upgradeButton: {
            ...upgradeButton
        },
        upgradeButtonDisabled: {
            ...upgradeButton,
            cursor: 'not-allowed',
            opacity: 0.7
        },
        backButton: {
            padding: 0,
            boxShadow: 'unset',
            p: {
                color: theme.colors.gray06
            },
            ':hover': {
                boxShadow: 'unset',
                p: {
                    color: theme.colors.black01
                }
            },
            ':active': {
                backgroundColor: 'unset'
            },
            svg: {
                marginRight: theme.margins.margin00,
                transform: 'rotate(180deg)'
            }
        },
        manageUpgrade: {
            ...theme.content.textNote,
            margin: `${theme.margins.margin01} 35px 0`
        },
        billingDetails: {
            marginTop: theme.margins.margin00,
            lineHeight: '1.3em',
            opacity: 0.5
        },
        billingError: {
            ...theme.content.textNote,
            backgroundColor: theme.colors.red05,
            borderLeft: `2px solid ${theme.colors.red01}`,
            margin: `${theme.margins.margin01} 35px 0`
        },
        billingErrorText: {
            color: theme.colors.red01,
            ':not(:first-of-type)': {
                paddingTop: theme.margins.margin00
            }
        },
        availableFunctionality: {
            marginTop: 6,
            lineHeight: '1.3em'
        },
        priceChange: {
            marginTop: 12,
            lineHeight: '1.3em'
        },
        upgradeRequest: {
            ...theme.content.textNote,
            margin: `${theme.margins.margin01} 35px 0`,
            li: {
                display: 'inline-block',
                marginTop: 6
            }
        },
        upgradeMultiselect: {
            flexWrap: 'unset',
            marginTop: theme.margins.margin00,
            '> div': {
                borderBottom: 'unset',
                borderRight: `1px solid ${theme.colors.gray03}`,
                ':first-of-type': {
                    borderRadius: '6px 0 0 6px'
                },
                ':last-of-type': {
                    borderRight: 'unset',
                    borderRadius: '0 6px 6px 0'
                }
            }
        },
        upgradeOption: {
            display: 'flex',
            flexDirection: 'column'
        },
        optionLabel: {
            marginBottom: 4
        },
        optionDescription: {
            lineHeight: '1.3em',
            color: theme.colors.gray06
        },
        adminsHeader: {
            marginTop: theme.margins.margin00
        },
        administratorRequest: {
            lineHeight: '1.3em'
        },
        tags: {
            display: 'flex',
            margin: `${theme.margins.margin00} 35px`
        },
        tag: {
            backgroundColor: theme.colors.gray03,
            borderRadius: 6,
            display: 'flex',
            marginRight: theme.margins.margin00,
            padding: '4px 8px',
            p: {
                ':first-of-type': {
                    marginRight: 6
                }
            }
        },
        spacer: {
            flex: 1
        },
        bold: {
            fontWeight: theme.fonts.fontWeights.medium
        },
        salesEmail: {
            display: 'block',
            marginTop: 3
        }
    };
};
