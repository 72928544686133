export const styleSheet = theme => ({
    container: {},
    bar1container: {
        height: '4px',
        width: '7px',
        backgroundColor: theme.colors.gray05,
        margin: '12px 1.6px 0px 0px',
        float: 'left'
    },
    bar2container: {
        height: '10px',
        width: '7px',
        backgroundColor: theme.colors.gray05,
        margin: '6px 1.6px 0px 0px',
        float: 'left'
    },
    bar3container: {
        height: '16px',
        width: '7px',
        backgroundColor: theme.colors.gray05,
        float: 'left'
    }
});
