export const styleSheet = theme => ({
    container: {},
    dropdown: {
        margin: '8px 8px 0'
    },
    tooltip: {
        maxWidth: 290,
        opacity: 1
    },
    button: {
        svg: {
            marginLeft: theme.margins.margin00
        }
    }
});
