import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { compose } from 'recompose';
import { get, safeRegExp } from 'utils';
import { FilingSidebarUI } from './ui';

export class FilingSidebar extends PureComponent {
    static displayName = 'FilingSidebarContainer';

    static propTypes = {
        body: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        selectBlock: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        body: undefined,
        loading: false,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.highlightNodes = this.highlightNodes.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.scrollToSearchResult = this.scrollToSearchResult.bind(this);

        this.state = {
            searchTerm: '',
            searchedTerm: '',
            searchResults: []
        };
    }

    // Split the text and insert styled spans when there are matches for the searchTerm
    highlightNodes() {
        const { searchedTerm, searchResults } = this.state;

        const regex = safeRegExp(searchedTerm);
        searchResults.forEach(({ node, parentNode }) => {
            // The nodes are textNodes, we are highlighting
            // the text in the node, and then wrapping it
            // all in a span.
            //
            // We then replace the textNode
            // in the parent, with this new span. This
            // makes it simpler to remove in a clean way.
            const parts = node.nodeValue.split(regex);
            const el = document.createElement('span');
            el.className = 'termWrapper';

            const highlighted = parts.map(part => {
                if (part.match(regex)) {
                    return `<span class="searchTerm">${part}</span>`;
                }
                return part;
            });

            el.innerHTML = highlighted.join('');
            parentNode.replaceChild(el, node);
        });
    }

    handleKeywordSearch() {
        const { body, containerRef } = this.props;
        const { searchTerm } = this.state;
        const searchResults = [];

        // Remove current marked up nodes
        const resetNodes = new Promise(resolve => {
            const nodes = containerRef.current.querySelectorAll('.termWrapper');
            nodes.forEach(node => {
                const newEl = document.createTextNode(node.innerText);
                const pn = node.parentNode;
                pn.replaceChild(newEl, node); // replace span with text node
                pn.normalize(); // merges text nodes
            });
            resolve();
        });

        resetNodes.then(() => {
            if (searchTerm.length > 0 && body) {
                const regex = safeRegExp(searchTerm);
                const walk = document.createTreeWalker(
                    containerRef.current.querySelector('.raw-text'),
                    NodeFilter.SHOW_TEXT,
                    null,
                    false
                );
                // Get all matching text nodes
                let n = walk.nextNode();
                while (n) {
                    if (n.textContent.match(regex) && n.parentNode && n.parentNode.style?.display !== 'none') {
                        searchResults.push({
                            node: n,
                            text: n.textContent,
                            parentNode: n.parentNode
                        });
                    }
                    n = walk.nextNode();
                }
                this.setState(
                    {
                        searchResults,
                        searchedTerm: searchTerm
                    },
                    this.highlightNodes
                );
            } else {
                // Clear results
                this.setState({
                    searchResults,
                    searchedTerm: searchTerm
                });
            }
        });
    }

    onKeyDown(e) {
        const { searchTerm } = this.state;
        const isEnter = get(e, 'nativeEvent.keyCode') === 13;
        if (isEnter && searchTerm && searchTerm.length > 1) this.handleKeywordSearch();
    }

    onSearch({ value }) {
        this.setState({
            searchTerm: value
        });
    }

    scrollToSearchResult(index) {
        const { selectBlock } = this.props;
        const { searchResults } = this.state;
        const node = get(searchResults[index], 'parentNode');
        if (node) {
            selectBlock(node);
        }
    }

    render() {
        const { loading, styles } = this.props;
        const { searchTerm, searchedTerm, searchResults } = this.state;

        return (
            <FilingSidebarUI
                loading={loading}
                executeSearch={this.handleKeywordSearch}
                onKeyDown={this.onKeyDown}
                onSearch={this.onSearch}
                scrollToSearchResult={this.scrollToSearchResult}
                searchResults={searchResults}
                searchedTerm={searchedTerm}
                searchTerm={searchTerm}
                styles={styles}
            />
        );
    }
}

export const FilingSidebarContainer = compose()(FilingSidebar);
