export const styleSheet = theme => ({
    containerCompact: {
        borderRadius: 'unset',
        width: 'unset',
        margin: 'unset'
    },
    actionMenu: {
        ...theme.content.cardActionMenu
    },
    compact: {
        borderRadius: 'unset',
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        padding: '7px 13px 10px'
    },
    compactHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '6px 0 -6px'
    },
    compactTime: {
        color: theme.colors.gray04
    },
    compactType: {
        paddingTop: 4
    },
    content: {
        ...theme.content.annotated,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        margin: '12px -5px -3px',
        padding: '3px 5px',
        borderRadius: 3.5
    },
    groupedContent: {
        ...theme.content.annotated,
        borderLeft: `1px solid ${theme.colors.gray02}`,
        borderRight: `1px solid ${theme.colors.gray02}`,
        backgroundColor: theme.colors.white02,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        padding: '8px 13px',
        ':hover': {
            backgroundColor: theme.colors.blue07,
            cursor: 'pointer'
        },
        ':last-of-type': {
            borderBottom: `1px solid ${theme.colors.gray02}`,
            borderRadius: '0 0 6px 6px',
            paddingBottom: '10px'
        }
    },
    groupedContentDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    groupedContentTime: {
        color: theme.colors.gray06
    },
    groupedContentIndex: {
        color: theme.colors.gray06
    },
    groupedContentDate: {
        color: theme.colors.gray06,
        marginRight: '3px'
    },
    spacer: {
        flex: 1
    },
    speaker: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
        marginBottom: -12,
        svg: {
            marginRight: 4
        }
    }
});
