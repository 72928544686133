export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.statusBanner,
        borderBottom: 'unset',
        borderRadius: 6,
        bottom: 20,
        color: theme.colors.white01,
        cursor: 'pointer',
        height: 90,
        maxWidth: 300,
        position: 'absolute',
        right: 20,
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        zIndex: 16, // one above tooltips
        ':hover': {
            backgroundColor: theme.colors.statusBanner
        },
        'p:last-of-type': {
            color: theme.colors.gray04,
            fontSize: theme.fonts.fontSizes.size01
        }
    },
    loading: {
        height: '100%',
        margin: '1px auto 0 auto'
    },
    notificationsCount: {
        color: theme.colors.gray04
    }
});
