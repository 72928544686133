export const styleSheet = theme => ({
    container: {
        position: 'relative',
        // backgroundColor: theme.colors.white01,
        // border: `1px solid ${theme.colors.gray03}`,
        borderRadius: '2px',
        margin: theme.margins.margin03,
        padding: theme.margins.margin04,
        maxWidth: '700px',
        width: '100%',
        alignSelf: 'center',
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            margin: 0,
            padding: 0
        }
    },
    headingNoIcon: {
        marginBottom: '10px'
    },
    heading: {
        marginBottom: '15px'
    }
});
