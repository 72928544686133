import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { LoaderLogo } from 'components/LoaderLogo';

import { EventMentions } from '../EventMentions';
import { styleSheet } from './stylesheet';

class EventLocalMonitors extends PureComponent {
    static propTypes = {
        loadingNewTerm: PropTypes.bool.isRequired,
        newTerm: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onKeyPress: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onTermSelect: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedNode: PropTypes.string,
        streamMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        newTerm: null,
        selectedNode: null,
        passedStyles: {}
    };

    render() {
        const {
            loadingNewTerm,
            newTerm,
            onChange,
            onKeyPress,
            onRemove,
            onSubmit,
            onTermSelect,
            passedStyles,
            selectedNode,
            streamMatches,
            styles,
            theme
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <EventMentions
                    styles={styles.mentions}
                    onTermSelect={onTermSelect}
                    selectedNode={selectedNode}
                    mentionsTitle="Local Monitors"
                    onRemove={onRemove}
                    streamMatches={streamMatches}
                />
                <Div styles={styles.trackTerm}>
                    <TextInput
                        name="eventLocalMonitorName"
                        placeholder="Keyword Mention"
                        onChange={onChange}
                        styles={styles.input}
                        value={newTerm}
                        onKeyDown={onKeyPress}
                    />
                    <Div styles={styles.btn} onClick={onSubmit}>
                        {loadingNewTerm ? (
                            <LoaderLogo type={2} height={24} color={theme.colors.white01} />
                        ) : (
                            <Text size={1}>Monitor</Text>
                        )}
                    </Div>
                </Div>
            </Div>
        );
    }
}

export const EventLocalMonitorsUI = compose(withStyleSheet(styleSheet))(EventLocalMonitors);
