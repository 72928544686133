export const styleSheet = theme => {
    const optionDescription = {
        maxWidth: 148,
        marginTop: 6,
        color: theme.colors.gray06,
        lineHeight: '1.3em'
    };
    return {
        container: {
            position: 'relative',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.1)',
            transition: 'all 0.2s ease-in-out',
            borderRadius: 6.5,
            minWidth: 600,
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                minWidth: 'unset'
            }
        },
        closeModal: {
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            display: 'flex',
            alignItems: 'center',
            ':hover': {
                transform: 'scale(1.1)'
            }
        },
        content: {
            borderRadius: 6.5,
            backgroundColor: theme.colors.white01
        },
        copySearchTerms: {
            alignItems: 'center',
            color: theme.colors.gray06,
            display: 'flex',
            height: 30,
            justifyContent: 'flex-end',
            marginBottom: -30,
            p: {
                cursor: 'pointer',
                ':hover': {
                    color: theme.colors.gray08
                }
            }
        },
        searchTermLimitError: {
            alignItems: 'center',
            display: 'flex',
            height: 30,
            justifyContent: 'flex-end',
            marginBottom: -30,
            'p:first-of-type': {
                color: theme.colors.red01
            },
            'p:last-of-type': {
                color: theme.colors.gray06,
                cursor: 'pointer',
                ':hover': {
                    color: theme.colors.gray08
                }
            }
        },
        button: {
            backgroundColor: theme.colors.white01,
            svg: {
                marginRight: 7,
                marginBottom: 1
            }
        },
        buttonSave: {
            ...theme.content.blueButton,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        buttonDisabled: {
            cursor: 'not-allowed',
            opacity: 0.6
        },
        cancelButton: {
            ...theme.content.cancelButton
        },
        backButton: {
            padding: 0,
            boxShadow: 'unset',
            p: {
                color: theme.colors.gray06
            },
            ':hover': {
                boxShadow: 'unset',
                p: {
                    color: theme.colors.black01
                }
            },
            ':active': {
                backgroundColor: 'unset'
            },
            svg: {
                marginRight: theme.margins.margin00,
                transform: 'rotate(180deg)'
            }
        },
        filterModeCheckbox: {
            marginTop: theme.margins.margin02,
            p: {
                color: 'inherit',
                fontSize: theme.fonts.fontSizes.size03,
                letterSpacing: 'normal',
                textTransform: 'unset'
            },
            '.rowBox': {
                marginRight: theme.margins.margin00
            },
            '.rowBoxChecked': {
                marginRight: theme.margins.margin00
            }
        },
        modalHeader: {
            padding: '30px 35px 0'
        },
        modalTitleInput: {
            marginRight: theme.margins.margin02,
            width: '100%'
        },
        removeTagIcon: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginLeft: 2,
            svg: {
                opacity: 0.8,
                height: 8
            }
        },
        modalButtons: {
            ...theme.content.modalButtonsContainer,
            '> div:first-of-type': {
                marginRight: 12
            },
            '> div:last-of-type': {
                marginLeft: 12
            }
        },
        titleClose: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            h1: {
                maxWidth: 550,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        },
        equityScope: {
            maxWidth: 608,
            marginTop: theme.margins.margin02
        },
        filters: {
            marginTop: theme.margins.margin02,
            marginRight: -15
        },
        spacer: {
            flex: 1
        },
        columns: {
            display: 'flex'
        },
        shareLink: {
            marginTop: theme.margins.margin02
        },
        colorMenu: {},
        streamOption: {
            '.optionLockedContainer': {
                marginRight: -4
            }
        },
        streamOptionSelected: {
            '> div': {
                p: {
                    ':first-of-type': {
                        fontWeight: theme.fonts.fontWeights.medium
                    }
                }
            },
            '.optionLockedContainer': {
                marginRight: -4
            }
        },
        optionSelected: {
            borderRight: `1px solid ${theme.colors.gray03}`,
            borderBottom: 'unset',
            ':first-of-type': {
                borderRadius: '6px 0 0 6px'
            },
            ':last-of-type': {
                borderRadius: '0 6px 6px 0',
                borderRight: 'unset'
            },
            '> div': {
                p: {
                    ':first-of-type': {
                        fontWeight: theme.fonts.fontWeights.medium
                    }
                }
            }
        },
        option: {
            borderRight: `1px solid ${theme.colors.gray03}`,
            borderBottom: 'unset',
            ':first-of-type': {
                borderRadius: '6px 0 0 6px'
            },
            ':last-of-type': {
                borderRadius: '0 6px 6px 0',
                borderRight: 'unset'
            }
        },
        streamOptionDescription: {
            marginTop: 6,
            color: theme.colors.gray06,
            lineHeight: '1.3em'
        },
        optionBookmarkContainer: {
            height: '100%'
        },
        optionDescription: {
            ...optionDescription
        },
        optionDescriptionBookmark: {
            ...optionDescription,
            maxWidth: 140
        },
        selectDisplayType: {
            marginTop: theme.margins.margin02
        },
        selectContentType: {
            marginTop: theme.margins.margin02,
            maxWidth: 608,
            '> div': {
                flexWrap: 'unset'
            }
        },
        selectType: {
            padding: '0 35px',
            marginTop: theme.margins.margin02
        },
        displayTypeSelector: {
            flexWrap: 'unset',
            flexDirection: 'column'
        },
        label: {
            letterSpacing: 1,
            color: theme.colors.gray06,
            marginBottom: 6
        },
        streamConfiguration: {
            padding: '0 35px',
            marginTop: theme.margins.margin02
        },
        streamPreview: {
            position: 'relative',
            width: 290,
            borderRadius: 6.5,
            overflow: 'hidden',
            marginLeft: theme.margins.margin00,
            '> div': {
                height: '100%'
            }
        },
        newStreamPage: {
            maxWidth: 600,
            width: '100%'
        },
        cloneDropdown: {
            margin: '8px 8px 0'
        },
        titleColorRow: {
            marginTop: theme.margins.margin02,
            display: 'flex',
            maxWidth: 608
        },
        dashboardDropdown: {
            marginTop: theme.margins.margin02,
            width: '50%'
        },
        streamTypesDropdown: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 35px 0 20px',
            width: '50%'
        },
        streamTypeLabelContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '6px'
        },
        streamTypeLabel: {
            letterSpacing: '1px',
            color: theme.colors.gray06,
            fontSize: theme.fonts.fontSizes.size00
        },
        streamTypeDropdown: {},
        tooltipClone: {
            maxWidth: 290,
            opacity: 1
        },
        preview: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        searchInstructions: {
            marginTop: 6,
            color: theme.colors.gray04,
            letterSpacing: 1,
            textAlign: 'right'
        },
        taggedInput: {
            maxWidth: 608,
            marginTop: theme.margins.margin02
        },
        gSheetInfo: {
            marginTop: theme.margins.margin02
        },
        gSheetEmail: {
            backgroundColor: theme.colors.yellow06,
            boxShadow: 'inset 0 -1px 0 0 rgb(255, 234, 155)',
            padding: '4px 2px',
            display: 'inline-block',
            margin: '4px 0'
        },
        gSheetLink: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: '1px solid rgba(215, 237, 247, 0.57)',
            lineHeight: '1em',
            textAlign: 'center',
            paddingLeft: theme.margins.margin01,
            marginLeft: theme.margins.margin01,
            p: {
                marginTop: 6
            }
        },
        gSheetInstruction: {
            '.noticeContent': {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                span: {
                    display: 'block',
                    backgroundColor: theme.colors.yellow01
                }
            }
        },
        gSheetSourceInput: {
            marginTop: theme.margins.margin02,
            width: '100%'
        },
        gSheetForm: {
            width: 608,
            marginTop: theme.margins.margin02
        },
        spotlightForm: {
            marginTop: theme.margins.margin02,
            maxWidth: 608
        }
    };
};
