import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { EmojiUI } from './ui';

/**
 *
 * The label prop is used to pass an aria-label value to the Span wrapping the emoji
 * This is used by visually-impaired users, allowing them to "hear" the label
 * So, use words to describe the intent of the Emoji (e.g. "upcoming event")
 *
 */

export class Emoji extends PureComponent {
    static displayName = 'EmojiContainer';

    static propTypes = {
        label: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        symbol: PropTypes.string.isRequired
    };

    static defaultProps = {
        label: undefined,
        styles: undefined
    };

    render() {
        const { label, styles, symbol } = this.props;
        return <EmojiUI label={label} styles={styles} symbol={symbol} />;
    }
}

export const EmojiContainer = compose()(Emoji);
