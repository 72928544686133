export const styleSheet = theme => ({
    container: {
        position: 'absolute',
        backgroundColor: theme.colors.white01,
        border: `1px solid ${theme.colors.gray02}`,
        borderRadius: `0 0 2px 2px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `0 ${theme.margins.margin00} ${theme.margins.margin00}`,
        margin: '32px 0 0 -1px',
        width: '100%',
        maxWidth: '164px',
        zIndex: 1,
        a: {
            color: theme.colors.black01,
            fontSize: theme.fonts.fontSizes.size01,
            textDecoration: 'none',
            width: 'fit-content',
            ':not(first-of-type)': {
                marginTop: theme.margins.margin00
            },
            ':hover': {
                textDecoration: 'underline'
            }
        }
    }
});
