import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import { withStyleSheet } from 'hoc/styles';
import { get, generateTabURL, toDateString, getHighlightColor } from 'utils';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { CardLockButton } from 'components/CardLockButton';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { styleSheet } from './stylesheet';

class SpotlightCard extends PureComponent {
    static propTypes = {
        additionalHighlights: PropTypes.arrayOf(PropTypes.any).isRequired,
        bookmarkId: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        displayType: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        eventDate: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        spotlightSubtype: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        to: PropTypes.string
    };

    static defaultProps = {
        bookmarkId: undefined,
        displayType: null,
        equities: [],
        events: [],
        grouped: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        passedStyles: {},
        score: undefined,
        spotlightSubtype: null,
        streamId: undefined,
        to: null
    };

    renderGroupedRows() {
        const { styles, grouped, onClick } = this.props;

        if (!grouped.length) {
            return null;
        }

        return grouped.map((g, index) => {
            const { company, displayType, mediaId, eventDate, publishedDate, title } = g;
            return (
                <Div key={mediaId} styles={styles.groupedContent} onClick={() => onClick(mediaId)}>
                    <Div styles={styles.groupedContentDetails}>
                        <Text size={0} styles={styles.textDarkerGray}>
                            {toDateString(eventDate, true)}
                            <br />
                            Updated <TimeAgo date={publishedDate} />
                        </Text>

                        <Text size={0} styles={styles.textDarkerGray}>
                            &nbsp; • &nbsp;
                            {displayType}
                        </Text>
                        <Div styles={styles.spacer} />
                        <Text size={0} styles={styles.groupedContentIndex}>{`${index + 1} of ${grouped.length}`}</Text>
                    </Div>
                    <Text size={3} weight="medium">
                        {company}
                    </Text>
                    <Text size={1} styles={styles.title}>
                        {title}
                    </Text>
                </Div>
            );
        });
    }

    renderAdditionalHighlights() {
        const { styles, additionalHighlights, to, onClick } = this.props;

        if (!additionalHighlights.length) {
            return null;
        }

        return additionalHighlights.map((h, index) => {
            const key = `spotlight-highlight-${index}-${h.slice(10)}`;
            return (
                <InternalLink key={key} styles={styles.groupedContent} to={to} onClick={onClick}>
                    <RawHTML html={h} />
                </InternalLink>
            );
        });
    }

    renderEvents(restricted) {
        const { events, pathname, styles, theme } = this.props;
        return events && events.length
            ? events.map(event => (
                  <InternalLink
                      key={get(event, 'id')}
                      to={restricted ? undefined : generateTabURL({ pathname, eventId: get(event, 'id') })}
                      styles={{ ...styles.eventsContainer, ...(restricted ? styles.eventsContainerRestricted : {}) }}
                  >
                      <Icon type="calendar" color={theme.colors.gray04} />
                      <Text styles={styles.eventTitle}>{get(event, 'title')}</Text>
                      <Text styles={styles.eventDate}>{toDateString(get(event, 'callDate'))}</Text>
                  </InternalLink>
              ))
            : null;
    }

    renderSpotlightType() {
        const { displayType, spotlightSubtype, styles } = this.props;
        return (
            <Text span styles={styles.spotlightTag} size={0}>
                {displayType}
                {spotlightSubtype && (
                    <Text span styles={styles.spotlightSubTag} size={0}>
                        {spotlightSubtype}
                    </Text>
                )}
            </Text>
        );
    }

    render() {
        const {
            additionalHighlights,
            bookmarkId,
            displayMode,
            eventDate,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            onClick,
            passedStyles,
            publishedDate,
            score,
            streamId,
            styles,
            title,
            equities,
            to
        } = this.props;
        if (STREAM_DISPLAY_MODES.compact === displayMode) {
            return (
                <WithPermission permissions={[PERMISSIONS.unlockedSpotlightContent]}>
                    {({ restricted, isLoading }) => (
                        <Card
                            containerStyles={{
                                ...styles.containerCompact,
                                ...(restricted || isLoading ? styles.containerRestricted : {})
                            }}
                            name="spotlight-card-compact"
                            onClick={isLoading ? undefined : onClick}
                            styles={{ ...styles.compact, ...passedStyles }}
                            to={restricted || isLoading ? undefined : to}
                        >
                            {({ isHovering }) => (
                                <Fragment>
                                    {isHovering && (
                                        <StreamActionMenu
                                            bookmarkId={bookmarkId}
                                            highlight={highlights}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="content"
                                            url={to}
                                        />
                                    )}
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subTextCompact}>
                                        <Div styles={styles.spotlightContainer}>
                                            {this.renderSpotlightType()}
                                            <Text span size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                            </Text>
                                        </Div>
                                        <Text size={1} styles={styles.date}>
                                            {toDateString(eventDate, true)}
                                            <br />
                                            Updated <TimeAgo date={publishedDate} />
                                        </Text>
                                    </Div>

                                    <WithPermission permissions={[PERMISSIONS.featureStreamsSpotlight]}>
                                        {(restricted || isLoading) && <CardLockButton upgradeModalId="spotlight" />}
                                    </WithPermission>
                                </Fragment>
                            )}
                        </Card>
                    )}
                </WithPermission>
            );
        }
        const containerStyles =
            displayMode === STREAM_DISPLAY_MODES.comfortable ? styles.containerComfortable : styles.container;
        const contentStyles = {
            ...styles.content,
            backgroundColor: getHighlightColor(highlightColor)?.color
        };

        return (
            <WithPermission permissions={[PERMISSIONS.unlockedSpotlightContent]}>
                {({ restricted, isLoading }) => (
                    <Card
                        name={`spotlight-card-${
                            displayMode === STREAM_DISPLAY_MODES.default ? 'default' : 'comfortable'
                        }`}
                        styles={{
                            ...containerStyles,
                            ...(restricted || isLoading ? styles.containerRestricted : {}),
                            ...passedStyles
                        }}
                        subContentButtonText={
                            additionalHighlights && additionalHighlights.length > 0
                                ? `${pluralize('additional match', additionalHighlights.length, true)}`
                                : ''
                        }
                        subContentRows={
                            displayMode === STREAM_DISPLAY_MODES.default ? this.renderAdditionalHighlights() : undefined
                        }
                        subContentTitle={title}
                    >
                        {({ isHovering }) => (
                            <Fragment>
                                {isHovering && (
                                    <StreamActionMenu
                                        bookmarkId={bookmarkId}
                                        highlight={highlights}
                                        matchId={matchId}
                                        score={score}
                                        streamId={streamId}
                                        styles={styles.actionMenu}
                                        targetId={id}
                                        targetType="content"
                                        url={to}
                                    />
                                )}
                                <InternalLink
                                    styles={styles.cardLink}
                                    to={restricted || isLoading ? undefined : to}
                                    onClick={isLoading ? undefined : onClick}
                                >
                                    <CardEquities equities={equities} />
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subText}>
                                        <Div styles={styles.spotlightContainer}>
                                            {this.renderSpotlightType()}
                                            <Text span size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                            </Text>
                                        </Div>
                                        <Text size={1} styles={styles.date}>
                                            {toDateString(eventDate, true)}
                                            <br />
                                            Updated <TimeAgo date={publishedDate} />
                                        </Text>
                                    </Div>
                                    {displayMode === STREAM_DISPLAY_MODES.default && (
                                        <Div styles={contentStyles}>
                                            <RawHTML html={highlights} />
                                        </Div>
                                    )}
                                    <WithPermission permissions={[PERMISSIONS.featureStreamsSpotlight]}>
                                        {(restricted || isLoading) && (
                                            <CardLockButton styles={styles.lockIcon} upgradeModalId="spotlight" />
                                        )}
                                    </WithPermission>
                                </InternalLink>
                                {displayMode === STREAM_DISPLAY_MODES.default && this.renderEvents(restricted)}
                            </Fragment>
                        )}
                    </Card>
                )}
            </WithPermission>
        );
    }
}

export const SpotlightCardUI = compose(withStyleSheet(styleSheet))(SpotlightCard);
