import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Table } from 'components/Table';

import { styleSheet } from './stylesheet';

class BoxTable extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        tableStyles: PropTypes.objectOf(PropTypes.any),
        lockScroll: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        tableStyles: {},
        lockScroll: false
    };

    render() {
        const { styles, passedStyles, children, lockScroll, tableStyles } = this.props;
        const tableStyle = lockScroll ? styles.tableWrapperScrollLock : styles.tableWrapper;
        return (
            <Div styles={{ ...tableStyle, ...passedStyles }}>
                <Table styles={{ ...styles.container, ...tableStyles }}>{children}</Table>
            </Div>
        );
    }
}

export const BoxTableUI = compose(withStyleSheet(styleSheet))(BoxTable);
