import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ResizeObserver from 'resize-observer-polyfill';
import { get, getViewportOffset } from 'utils';
import { TooltipUI } from './ui';

export class Tooltip extends PureComponent {
    static displayName = 'TooltipContainer';

    static propTypes = {
        beforeHide: PropTypes.func,
        blockBackground: PropTypes.bool,
        cancelClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        delay: PropTypes.number,
        growLeft: PropTypes.bool,
        growUp: PropTypes.bool,
        hideOnScroll: PropTypes.bool,
        isEnabled: PropTypes.bool,
        keepInViewport: PropTypes.bool,
        onHide: PropTypes.func,
        onShow: PropTypes.func,
        slideIn: PropTypes.bool,
        useElementOffsetBottom: PropTypes.bool,
        useElementOffsetLeft: PropTypes.bool,
        useElementOffsetRight: PropTypes.bool,
        useElementOffsetTop: PropTypes.bool,
        useMaxHoverWidth: PropTypes.bool,
        useMinHoverWidth: PropTypes.bool,
        useOutsideClickHandler: PropTypes.bool,
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        beforeHide: null,
        blockBackground: false,
        cancelClassName: null,
        delay: 0,
        growLeft: false,
        growUp: false,
        hideOnScroll: true,
        isEnabled: false,
        keepInViewport: false,
        onHide: null,
        onShow: null,
        slideIn: false,
        useElementOffsetBottom: false,
        useElementOffsetLeft: false,
        useElementOffsetRight: false,
        useElementOffsetTop: false,
        useMaxHoverWidth: false,
        useMinHoverWidth: false,
        useOutsideClickHandler: false,
        // Default offset to make the mouse appear over the
        // tooltip so that it will disappear on mouse out. Otherwise
        // you have to mouse over and then out to close it.
        xOffset: -30,
        yOffset: -30
    };

    constructor(props) {
        super(props);

        this.tooltipRoot = document.getElementById('tooltip-root');
        this.el = document.createElement('div');

        this.checkPlacement = this.checkPlacement.bind(this);
        this.repositionTooltip = this.repositionTooltip.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.hideOnScroll = this.hideOnScroll.bind(this);
        this.generateTooltipPosition = this.generateTooltipPosition.bind(this);

        this.tooltipRef = React.createRef();

        if (window.IntersectionObserver) {
            this.windowIntersectionObserver = new IntersectionObserver(this.checkPlacement);
        }

        this.tooltipResizeObserver = new ResizeObserver(this.checkPlacement);
        this.targetResizeObserver = new ResizeObserver(this.repositionTooltip);

        this.state = {
            showTooltip: false,
            // Start way off the screen
            top: -500,
            left: -500,
            hoverElementWidth: null
        };
    }

    componentDidMount() {
        // Reposition tooltip if window is resized
        window.addEventListener('resize', this.repositionTooltip);
        if (this.tooltipRoot) {
            this.tooltipRoot.appendChild(this.el);
        } else {
            this.tooltipRoot = document.getElementById('tooltip-root');
            this.tooltipRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.repositionTooltip);
        this.hideTooltip();
        if (this.tooltipRoot) {
            this.tooltipRoot.removeChild(this.el);
        }
    }

    /**
     * If growLeft is true, the xOffset will be placed from the right
     * If growUp is true, the yOffset will be placed from the bottom
     * By default, xOffset is placed from the left and yOffset is placed from the right
     */
    generateTooltipPosition(e, target) {
        const {
            growLeft,
            growUp,
            useElementOffsetBottom,
            useElementOffsetLeft,
            useElementOffsetRight,
            useElementOffsetTop,
            xOffset: xOffsetProp,
            yOffset: yOffsetProp
        } = this.props;
        const hoverElementWidth = get(target, 'offsetWidth', null);
        let yOffset = get(e, 'clientY', get(e, 'pageY'));
        let xOffset = get(e, 'clientX', get(e, 'pageX'));

        if (useElementOffsetTop) {
            yOffset = target?.getBoundingClientRect().top;
            if (!useElementOffsetLeft) {
                xOffset = 0;
            }
        }
        if (useElementOffsetBottom) {
            yOffset = target?.getBoundingClientRect().bottom;
            if (!useElementOffsetLeft) {
                xOffset = 0;
            }
        }
        if (useElementOffsetLeft) {
            xOffset = target?.getBoundingClientRect().left;
            if (!useElementOffsetBottom && !useElementOffsetTop) {
                yOffset = 0;
            }
        }
        if (useElementOffsetRight) {
            xOffset = target?.getBoundingClientRect().right;
            if (!useElementOffsetBottom && !useElementOffsetTop) {
                yOffset = 0;
            }
        }

        // Determine top/right/bottom/left positioning based on growLeft/growUp props
        let bottom;
        let right;
        let left = xOffset + xOffsetProp > 0 ? xOffset + xOffsetProp : xOffset;
        let top = yOffset + yOffsetProp > 0 ? yOffset + yOffsetProp : yOffset;
        if (growLeft) {
            left = undefined;
            right = window.innerWidth - xOffset - xOffsetProp;
        }
        if (growUp) {
            top = undefined;
            bottom = window.innerHeight - yOffset - yOffsetProp;
        }

        return {
            hoverElementWidth,
            bottom,
            left,
            right,
            top
        };
    }

    showTooltip(e) {
        if (e && e.persist) e.persist();
        const {
            delay,
            onShow,
            useElementOffsetBottom,
            useElementOffsetLeft,
            useElementOffsetRight,
            useElementOffsetTop,
            hideOnScroll
        } = this.props;
        const target = get(e, 'currentTarget', get(e, 'relatedTarget'));
        const modal = document.getElementById('modal-container');
        const isAnchored =
            useElementOffsetBottom || useElementOffsetLeft || useElementOffsetRight || useElementOffsetTop;

        // Hide tooltip if a modal or page scrolls
        if (hideOnScroll) {
            document.addEventListener('scroll', this.hideOnScroll, true);
        }
        if (modal) {
            modal.addEventListener('scroll', this.hideTooltip);
        }

        const showTooltip = () => {
            this.setState(
                {
                    showTooltip: true,
                    bottom: undefined,
                    right: undefined,
                    ...this.generateTooltipPosition(e, target)
                },
                () => {
                    // Make sure tooltip wasn't already hidden, which can happen in concurrent mode
                    const { showTooltip: stillShowTooltip } = this.state;
                    if (stillShowTooltip) {
                        // This will call the checkPlacement function any time the tooltip
                        // crosses the viewport boundary
                        if (this.windowIntersectionObserver) {
                            this.windowIntersectionObserver.observe(this.tooltipRef.current);
                        }

                        // This could be for a tooltip where its anchored to an
                        // expanding target, like a tagged input, where more
                        // tags expand the height and push down the tooltip
                        if (isAnchored) this.targetResizeObserver.observe(target);

                        // If the tooltip resizes (like in the filter tooltip
                        // menu) we should check placement incase it is off
                        // screen
                        this.tooltipResizeObserver.observe(this.tooltipRef.current);

                        if (onShow) {
                            onShow({ hideTooltip: this.hideTooltip, showTooltip });
                        }
                    }
                }
            );
        };

        if (this.tooltipTimer) clearTimeout(this.tooltipTimer);
        if (delay) {
            this.tooltipTimer = setTimeout(showTooltip, delay);
        } else {
            showTooltip();
        }
    }

    checkPlacement() {
        const { keepInViewport } = this.props;
        let { top, left, bottom, right } = this.state;
        const viewportOffset = getViewportOffset(this.tooltipRef.current);
        if (keepInViewport) {
            // If we're trying to keep in viewport and it goes past the bottom,
            // unset the top and just pin it to the bottom
            if (viewportOffset.bottom < 0) {
                top = undefined;
                bottom = 0;
            }

            if (viewportOffset.bottom === 0 && viewportOffset.top < 0) {
                top = 0;
            }
            // If it goes past teh right edge, unset the left and pin the right
            // instead.
            if (viewportOffset.right < 0) {
                left = undefined;
                right = 0;
            }

            this.setState({
                top,
                left,
                bottom,
                right
            });
        } else if (viewportOffset.bottom < 0) {
            // If we don't care about keeping the whole thing in the viewport,
            // make sure we set the bottom (and keep the current top) so
            // that the container will scroll and all the content is accessible.
            // This is the mode used for inputs and other things that are less
            // tooltip-like and would be weird if they moved to stay in view.
            bottom = 0;
            this.setState({ bottom });
        }
    }

    repositionTooltip(e) {
        const { showTooltip } = this.state;
        if (showTooltip) {
            const target = get(e, '[0].target');
            this.setState(this.generateTooltipPosition(e, target));
        }
    }

    hideOnScroll(e) {
        const { target } = e || {};

        // Hide if scrolling
        // isn't happening within tooltip
        if (this.tooltipRef !== target && !this.tooltipRef?.current?.contains(target)) {
            // Only hide if its not scrolling an autocomplete
            if (!target.className.includes('autocompleteTooltip')) {
                this.hideTooltip();
            }
        }
    }

    hideTooltip(event) {
        const { relatedTarget } = event || {};
        const { onHide, beforeHide } = this.props;
        const modal = document.getElementById('modal-container');
        let shouldHide = true;

        // Hide tooltip if a modal or page scrolls
        document.removeEventListener('scroll', this.hideOnScroll, true);
        if (modal) {
            modal.removeEventListener('scroll', this.hideTooltip);
        }

        if (this.tooltipTimer) clearTimeout(this.tooltipTimer);

        // Disconnect interaction observers
        if (this.windowIntersectionObserver) {
            this.windowIntersectionObserver.disconnect();
        }

        // Disconnect resize observers
        this.targetResizeObserver.disconnect();
        this.tooltipResizeObserver.disconnect();

        /**
         * Don't hide tooltip when hovering over or clicking itself
         * Hide the tooltip when relatedTarget is the browser's window object (e.g. hovering over developer console)
         * This prevents a crash since passing window to the contains function crashes the client
         */
        if (
            relatedTarget !== window &&
            (this.tooltipRef === relatedTarget || this.tooltipRef?.current?.contains(relatedTarget))
        ) {
            shouldHide = false;
        }

        // Enable preventing hide tooltip
        if (beforeHide) {
            shouldHide = beforeHide(event);
        }

        if (shouldHide) {
            this.setState({ showTooltip: false, top: -500, left: -500 }, () => {
                if (onHide) {
                    onHide();
                }
            });
        }
    }

    render() {
        const {
            blockBackground,
            isEnabled,
            slideIn,
            useMaxHoverWidth,
            useMinHoverWidth,
            useOutsideClickHandler,
            ...rest
        } = this.props;
        const { showTooltip, hoverElementWidth, top, left, bottom, right } = this.state;
        return (
            <TooltipUI
                {...rest}
                blockBackground={blockBackground}
                bottom={bottom}
                hideTooltip={this.hideTooltip}
                hoverElementWidth={hoverElementWidth}
                isEnabled={isEnabled}
                isVisible={showTooltip}
                left={left}
                right={right}
                showTooltip={this.showTooltip}
                slideIn={slideIn}
                tooltipContainer={this.el}
                tooltipRef={this.tooltipRef}
                top={top}
                useMaxHoverWidth={useMaxHoverWidth}
                useMinHoverWidth={useMinHoverWidth}
                useOutsideClickHandler={useOutsideClickHandler}
            />
        );
    }
}

export const TooltipContainer = compose()(Tooltip);
