import { alertToastReducer } from './alertToast';
import { appReducer } from './app';
import { highlightReducer } from './highlight';
import { popoverPDFReducer } from './popoverPDF';
import { publicAskAiera } from './publicAskAiera';
import { searchReducer } from './search';
import { statusBannerReducer } from './statusBanner';
import { tabsReducer } from './tabs';
import { transcriptEditorReducer } from './transcriptEditor';
import { userReducer } from './user';
import { zendeskReducer } from './zendesk';

export const reducers = {
    AlertToast: alertToastReducer,
    App: appReducer,
    Highlight: highlightReducer,
    PopoverPDF: popoverPDFReducer,
    PublicAskAiera: publicAskAiera,
    Search: searchReducer,
    StatusBanner: statusBannerReducer,
    Tabs: tabsReducer,
    TranscriptEditor: transcriptEditorReducer,
    User: userReducer,
    Zendesk: zendeskReducer
};
