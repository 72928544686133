import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { CheckboxUI } from './ui';

export class Checkbox extends PureComponent {
    static displayName = 'CheckboxContainer';

    static propTypes = {
        checked: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onToggle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        checked: false,
        name: undefined,
        onChange: null,
        onToggle: null,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { checked, name, onChange, onToggle } = this.props;
        if (onChange) {
            onChange({ event, name, value: !checked });
        }
        if (onToggle) {
            onToggle(event);
        }
    }

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <CheckboxUI {...rest} onChange={this.onChange} />;
    }
}

export const CheckboxContainer = compose()(Checkbox);
