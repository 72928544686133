export const styleSheet = theme => ({
    container: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
            '.checkbox': {
                boxShadow: `0 0 0 1px ${theme.colors.gray02}`
            },
            '.rowBox': {
                border: `1px solid ${theme.colors.gray04}`
            },
            '.rowBoxChecked': {
                border: `1px solid rgba(0, 0, 0 ,0.2)`
            }
        }
    },
    disabled: {
        cursor: 'not-allowed',
        opacity: 0.6
    },
    rowBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        marginRight: theme.margins.margin01,
        backgroundColor: '#FFF',
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: '2px'
    },
    rowBoxChecked: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        marginRight: theme.margins.margin01,
        backgroundColor: theme.colors.orange01,
        border: `1px solid ${theme.colors.orange01}`,
        borderRadius: '2px',
        svg: {
            width: '11px',
            g: {
                fill: theme.colors.white01
            }
        }
    },
    rowBox03: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 14,
        height: 14,
        backgroundColor: theme.colors.white01,
        boxShadow: `inset 0 0 0 1px rgba(0, 0, 0, 0.1)`,
        borderRadius: 4
    },
    rowBoxChecked03: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 14,
        height: 14,
        backgroundColor: theme.colors.orange01,
        borderRadius: 4,
        svg: {
            g: {
                fill: theme.colors.white01
            }
        }
    },
    rowBox04: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
        backgroundColor: theme.colors.white01,
        border: '1px solid #C7C9D3',
        borderRadius: 2,
        svg: {
            height: 8,
            width: 10
        },
        ':hover': {
            border: `1px solid ${theme.colors.gray04}`,
            svg: {
                height: 8,
                width: 9,
                g: {
                    fill: theme.colors.gray05
                }
            }
        }
    },
    rowBoxChecked04: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
        backgroundColor: theme.colors.blue08,
        border: `1px solid ${theme.colors.blue08}`,
        borderRadius: 2,
        svg: {
            height: 8,
            width: 9,
            g: {
                fill: theme.colors.white01
            }
        },
        ':hover': {
            backgroundColor: theme.colors.blue10
        },
        ':active': {
            backgroundColor: theme.colors.blue11
        }
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        marginRight: theme.margins.margin01,
        backgroundColor: '#FFF',
        borderRadius: '2px'
    },
    label: {
        letterSpacing: '1px',
        color: theme.colors.contextPaneText,
        lineHeight: '1.3em'
    }
});
