export const styleSheet = theme => ({
    container: {},
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '4px 6px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backdropFilter: 'blur(6px)',
        borderRadius: 4,
        color: theme.colors.white01
    },
    tooltipText: {
        lineHeight: '1.2em'
    },
    tooltipTextDescription: {
        lineHeight: '1.2em',
        opacity: 0.8
    }
});
