export const styleSheet = theme => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    percentComplete: {
        zIndex: 2
    },
    progressBar: {
        backgroundColor: theme.colors.blue08,
        borderRadius: '6px 0 0 6px',
        bottom: 0,
        boxShadow: `0 0 0 1px ${theme.colors.blue08}`,
        left: 0,
        opacity: 0.6,
        position: 'absolute',
        top: 0,
        transition: 'width 0.2s ease-in-out',
        zIndex: 1
    },
    progressBarContainer: {
        backgroundColor: theme.colors.white02,
        borderRadius: 6,
        boxShadow: `0 0 0 1px ${theme.colors.gray03}`,
        marginTop: 10,
        padding: '10px 10px 10px 14px',
        position: 'relative',
        width: '100%',
        ':hover': {
            backgroundColor: theme.colors.gray03,
            boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
            '.progressBar': {
                opacity: 1
            }
        }
    },
    status: {
        color: theme.colors.gray04,
        marginTop: 10
    }
});
