export const styleSheet = theme => {
    return {
        container: {
            backgroundColor: theme.colors.white01,
            zIndex: 7, // Needs to be higher than pricing, so the popup menu overlaps pricing
            position: 'relative'
        },
        table: {
            'tr:first-of-type': {
                th: {
                    borderTop: 'unset'
                }
            },
            'tr:last-of-type': {
                td: {
                    borderBottom: 'unset'
                }
            }
        },
        tableHeaderRowShadow: {
            boxShadow: '0 1px 4px 0 rgba(66,65,82,0.06)',
            th: {
                height: 22,
                position: 'sticky',
                top: 22,
                zIndex: 3,
                backgroundColor: theme.colors.white01,
                ':last-of-type': {
                    paddingRight: theme.margins.margin01
                }
            }
        },
        tableHeaderColumnLabel: {
            textAlign: 'right',
            boxShadow: `inset 0 -1px 0 0 ${theme.colors.gray09}`,
            color: theme.colors.gray06,
            letterSpacing: '1px',
            padding: `0 ${theme.margins.margin01} 7px 0`,
            whiteSpace: 'nowrap'
        },
        tableColumnLabelCellBorderLeftBottom: {
            boxShadow: `inset 1px 0 0 0 ${theme.colors.gray03}, inset 0 -1px 0 0 ${theme.colors.gray09}`
        },
        tableHeaderGroupLabel: {
            textAlign: 'center',
            borderTop: `1px solid ${theme.colors.gray09}`,
            whiteSpace: 'nowrap',
            color: theme.colors.gray08,
            letterSpacing: '1px',
            padding: '8px 0 0',
            height: '22px'
        },
        tableCellBorderLeft: {
            boxShadow: `inset 1px 0 0 0 ${theme.colors.gray03}`
        },
        tableCellBorderLeftBottom: {
            boxShadow: `inset 1px 0 0 0 ${theme.colors.gray03}, inset 0 -1px 0 0 ${theme.colors.gray03}`
        },
        tableCellBorderRight: {
            boxShadow: `inset -1px 0 0 0 ${theme.colors.gray03}`
        },
        tableCellPaddingRight: {
            paddingRight: theme.margins.margin02
        },
        tableCellPaddingLeft: {
            paddingLeft: '15px'
        },
        tableHeaderTitle: {
            textAlign: 'left',
            boxShadow: `inset -1px 0 0 0 ${theme.colors.gray03}, inset 0 -1px 0 0 ${theme.colors.gray09}`,
            borderTop: `1px solid ${theme.colors.gray09}`,
            padding: `11px 0 12px ${theme.margins.margin01}`,
            whiteSpace: 'nowrap',
            zIndex: 3
        },
        headerTitleWrapper: {
            position: 'relative',
            color: theme.colors.black01,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            paddingRight: '15px'
        },
        tableWrapper: {
            width: '100%',
            table: {
                width: '100%',
                borderCollapse: 'collapse'
            },
            th: {
                verticalAlign: 'middle'
            },
            td: {
                textAlign: 'left'
            }
        },
        tableHeaderRow: {
            th: {
                position: 'sticky',
                top: 0,
                zIndex: 3,
                backgroundColor: theme.colors.white01,
                ':first-of-type': {
                    paddingLeft: theme.margins.margin01
                }
            }
        },
        tableRowExpanded: {
            backgroundColor: theme.colors.blue09,
            td: {
                position: 'sticky',
                top: 43,
                zIndex: 2,
                backgroundColor: theme.colors.blue09
            }
        },
        tableRow: {
            td: {
                zIndex: 1,
                position: 'relative'
            },
            ':hover': {
                td: {
                    backgroundColor: theme.colors.yellow01
                }
            }
        },
        tableRowNoBottomBorder: {
            td: {
                boxShadow: 'none',
                ':first-of-type': {
                    boxShadow: `inset -1px 0 0 0 ${theme.colors.gray03}`
                }
            },
            '.finanicalDivider': {
                boxShadow: `inset 1px 0 0 0 ${theme.colors.gray03}`
            }
        },
        tableRowTitle: {
            width: '235px',
            boxShadow: `inset -1px 0 0 0 ${theme.colors.gray03}, inset 0 -1px 0 0 ${theme.colors.gray03}`,
            verticalAlign: 'middle',
            position: 'relative',
            paddingLeft: theme.margins.margin01,
            paddingTop: '8px',
            paddingBottom: '9px',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                width: '225px'
            },
            '.dropDownButton': {
                visibility: 'hidden',
                [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                    visibility: 'visible'
                }
            },
            '.lockedFinancial': {
                visibility: 'hidden',
                [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                    visibility: 'visible'
                }
            },
            '.removeMonitorButton': {
                visibility: 'hidden',
                [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                    visibility: 'visible'
                }
            },
            ':hover': {
                '.removeMonitorButton': {
                    visibility: 'visible'
                },
                '.dropDownButton': {
                    visibility: 'visible'
                },
                '.lockedFinancial': {
                    visibility: 'visible'
                }
            }
        },
        dropDownButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            background: theme.colors.white03,
            borderRadius: '6px',
            marginRight: '14px',
            cursor: 'pointer',
            flexShrink: 0,
            ':hover': {
                backgroundColor: theme.colors.gray01,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        removeMonitorButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            background: theme.colors.white03,
            borderRadius: '6px',
            marginRight: '14px',
            cursor: 'pointer',
            flexShrink: 0,
            svg: {
                width: 12
            },
            ':hover': {
                backgroundColor: theme.colors.gray01,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        lockedFinancial: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            background: 'transparent',
            marginRight: '14px',
            flexShrink: 0,
            svg: {
                height: '12px'
            }
        },
        rowSubtext: {
            marginTop: '2px',
            letterSpacing: '1px',
            color: theme.colors.gray04
        },
        rowTitleText: {
            textTransform: 'capitalize',
            whiteSpace: 'normal',
            lineHeight: '1.1em'
        },
        rowTitleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        tableRowValue: {
            verticalAlign: 'middle',
            boxShadow: `inset 0 -1px 0 0 ${theme.colors.gray03}`,
            padding: `8px ${theme.margins.margin01} 9px 0`,
            p: {
                textAlign: 'right'
            }
        },
        tableNewMonitorRow: {
            verticalAlign: 'middle',
            borderBottom: `1px solid ${theme.colors.gray03}`,
            padding: '8px',
            backgroundColor: theme.colors.yellow03,
            p: {
                textAlign: 'right'
            }
        },
        titlePlus: {
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            p: {
                marginLeft: theme.margins.margin00
            },
            ':hover': {
                color: theme.colors.blue08,
                p: {
                    color: theme.colors.blue08
                },
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        summaryList: {
            padding: 0,
            borderBottom: 'unset',
            margin: 0
        },
        plusButton: {
            marginLeft: theme.margins.margin01
        },
        loadingCell: {
            borderBottom: `1px solid ${theme.colors.gray03}`
        },
        loadingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px'
        },
        noMatchesCell: {
            position: 'relative',
            zIndex: 2,
            backgroundColor: theme.colors.white01,
            borderBottom: `1px solid ${theme.colors.gray03}`
        },
        toggleHiddenMonitorsCell: {
            borderBottom: `1px solid ${theme.colors.gray03}`
        },
        toggleHiddenMonitors: {
            lineHeight: '30px',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: theme.colors.white01,
            letterSpacing: '1px',
            color: theme.colors.gray04,
            ':hover': {
                color: theme.colors.gray08
            }
        },
        noMonitors: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
            p: {
                color: theme.colors.gray04,
                marginRight: '14px'
            }
        },
        popUp: {
            minWidth: '200px'
        }
    };
};
