const STATUS_BANNER_FIRE = 'STATUS_BANNER_FIRE';
const STATUS_BANNER_HIDE = 'STATUS_BANNER_HIDE';

export const types = {
    STATUS_BANNER_FIRE,
    STATUS_BANNER_HIDE
};

/**
 *
 * These actions show and hide the blue/red status
 * banner you see from time to time. Generally
 * this is used in onSuccess and onFailure calls of
 * other actions.
 *
 */

export function statusBannerFire(text, type, icon, duration) {
    return {
        type: STATUS_BANNER_FIRE,
        payload: {
            text,
            type,
            icon,
            duration
        }
    };
}

export function statusBannerHide() {
    return {
        type: STATUS_BANNER_HIDE
    };
}
