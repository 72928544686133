import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { styleSheet } from './stylesheet';

class DatePicker extends PureComponent {
    static propTypes = {
        maxDetail: PropTypes.string.isRequired,
        minDate: PropTypes.objectOf(PropTypes.any),
        minDetail: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        range: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.instanceOf(Date),
        visible: PropTypes.bool
    };

    static defaultProps = {
        minDate: undefined,
        onChange: undefined,
        passedStyles: {},
        range: false,
        value: undefined,
        visible: false
    };

    render() {
        const {
            maxDetail,
            minDate,
            minDetail,
            name,
            onChange,
            passedStyles,
            range,
            styles,
            value,
            visible
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {visible && (
                    <Div className="calendar" data-tname={name} styles={styles.calendar}>
                        <Calendar
                            maxDetail={maxDetail}
                            minDate={minDate}
                            minDetail={minDetail}
                            onChange={onChange}
                            selectRange={range}
                            value={value}
                        />
                    </Div>
                )}
            </Div>
        );
    }
}

export const DatePickerUI = compose(withStyleSheet(styleSheet))(DatePicker);
