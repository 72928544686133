export const styleSheet = theme => ({
    container: {
        ...theme.content.box,
        display: 'flex',
        flexWrap: 'wrap',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexWrap: 'unset',
            flexDirection: 'column'
        }
    },
    disabled: {
        div: {
            cursor: 'not-allowed'
        }
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        ...theme.content.formLabel
    }
});
