export const styleSheet = theme => ({
    container: {
        position: 'relative',
        ':hover': {
            'input:not(:focus)': {
                backgroundColor: theme.colors.white02,
                boxShadow: `0 0 0 1px ${theme.colors.gray01}`
            }
        },
        // Explicitly set the arrow color, since for some reason it's only visible locally
        '.PhoneInputCountrySelectArrow': {
            '--PhoneInputCountrySelectArrow-color': theme.colors.black01
        }
    },
    error: {
        ...theme.content.formLabel,
        color: theme.colors.inputError,
        fontSize: theme.fonts.fontSizes.size01,
        marginLeft: 'auto',
        textTransform: 'none'
    },
    input: {
        backgroundColor: theme.colors.white03,
        border: 'none',
        borderRadius: '6px',
        display: 'block',
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size04,
        height: '40px',
        marginLeft: 5,
        paddingLeft: 12,
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        '::placeholder': {
            color: theme.colors.gray04
        },
        ':focus': {
            backgroundColor: theme.colors.white01,
            boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 50px ${theme.colors.input} inset`
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            WebkitAppearance: 'none'
        }
    },
    inputError: {
        boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`,
        '::placeholder': {
            color: theme.colors.inputError
        },
        ':focus': {
            boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`
        }
    },
    inputWithText: {
        backgroundColor: theme.colors.white01,
        boxShadow: `0 0 0 1px ${theme.colors.gray01}`
    },
    label: {
        ...theme.content.formLabel
    },
    labelContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        right: 0,
        top: -20
    },
    localStorageHint: {
        color: theme.colors.linkHover,
        marginLeft: 45,
        paddingTop: 8,
        ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    subContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    }
});
