export const styleSheet = theme => {
    const container = {
        a: {
            display: 'flex',
            justifyContent: 'space-between',
            textDecoration: 'none',
            color: theme.colors.gray08,
            '.subtext': {
                color: theme.colors.orange01
            }
        }
    };
    const containerHover = {
        backgroundColor: theme.colors.blue09,
        a: {
            textDecoration: 'none',
            color: theme.colors.black01,
            p: {
                color: theme.colors.black01
            },
            '.subtext': {
                color: theme.colors.orange01
            },
            '.title': {
                textDecoration: 'underline',
                color: theme.colors.blue08
            }
        }
    };
    return {
        containerCompact: {
            borderRadius: 'unset',
            width: 'unset',
            margin: 'unset',
            padding: 0
        },
        actionMenu: {
            ...theme.content.cardActionMenu
        },
        compact: {
            ...container,
            borderRadius: 'unset',
            border: 'unset',
            borderBottom: `1px solid ${theme.colors.gray01}`,
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            ':hover': {
                ...containerHover
            }
        },
        container: {
            ...container,
            padding: 0,
            ':hover': {
                ...containerHover
            }
        },
        containerLocked: {
            ...container,
            padding: 0,
            ':hover': {
                ...containerHover,
                backgroundColor: theme.colors.white01
            }
        },
        cardContent: {
            padding: '10px 13px'
        },
        cardContentCompact: {
            padding: '10px 13px 0'
        },
        content: {
            ...theme.content.annotated,
            fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
            color: theme.colors.gray08,
            lineHeight: '1.3em',
            marginTop: 15
        },
        cardHeader: {
            flex: 1
        },
        eventsContainer: {
            backgroundColor: theme.colors.gray03,
            borderRadius: 6,
            margin: '0 5px 5px',
            a: {
                color: theme.colors.gray08,
                textDecoration: 'none',
                height: 28,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 8px',
                svg: {
                    height: 12,
                    flexShrink: 0
                }
            },
            ':hover': {
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        eventsContainerRestricted: {
            ':hover': {
                backgroundColor: theme.colors.gray03
            }
        },
        eventTitle: {
            marginLeft: 6,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        eventDate: {
            marginLeft: theme.margins.margin00,
            whiteSpace: 'nowrap'
        },
        lockIcon: {
            marginBottom: -10
        },
        footerCompact: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 5px',
            height: 34,
            padding: '0 10px 0 8px',
            alignItems: 'center',
            p: {
                fontSize: 14
            },
            span: {
                color: theme.colors.gray04,
                marginLeft: 6
            }
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 5px',
            borderTop: `1px solid ${theme.colors.gray03}`,
            height: 34,
            padding: '0 10px 0 8px',
            alignItems: 'center',
            p: {
                fontSize: 14
            },
            span: {
                color: theme.colors.gray04,
                marginLeft: 6
            }
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.colors.orange02
        }
    };
};
