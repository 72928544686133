import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { PopupSearchMenuUI } from './ui';

export class PopupSearchMenu extends PureComponent {
    static displayName = 'PopupSearchMenuContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        initialSearchText: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.any),
        onClose: PropTypes.func.isRequired,
        onRemove: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        onSelectOption: PropTypes.func.isRequired,
        searchPlaceholder: PropTypes.string
    };

    static defaultProps = {
        styles: {},
        initialSearchText: undefined,
        options: [],
        onRemove: undefined,
        searchPlaceholder: undefined
    };

    constructor(props) {
        super(props);

        this.handleRemove = this.handleRemove.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            loadingOption: null,
            loadingRemove: false
        };
    }

    handleSearch({ value }) {
        const { onSearch } = this.props;

        if (onSearch) {
            onSearch(value);
        }
    }

    handleSelect(option) {
        const { onSelectOption } = this.props;

        if (onSelectOption) {
            this.setState(
                {
                    loadingOption: option.key
                },
                () => {
                    onSelectOption(option);
                }
            );
        }
    }

    handleRemove() {
        const { onRemove } = this.props;
        if (onRemove) {
            this.setState(
                {
                    loadingRemove: true
                },
                onRemove
            );
        }
    }

    render() {
        const { onClose, initialSearchText, options, onRemove, styles, searchPlaceholder } = this.props;
        const { loadingOption, loadingRemove } = this.state;
        return (
            <PopupSearchMenuUI
                loadingOption={loadingOption}
                loadingRemove={loadingRemove}
                styles={styles}
                onClose={onClose}
                onSearch={this.handleSearch}
                onSelectOption={this.handleSelect}
                initialSearchText={initialSearchText}
                options={options}
                onRemove={onRemove && this.handleRemove}
                searchPlaceholder={searchPlaceholder}
            />
        );
    }
}

export const PopupSearchMenuContainer = compose()(PopupSearchMenu);
