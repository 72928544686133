import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';

function normalizeTags(tags = []) {
    const options = (tags || []).map(tag => ({
        value: tag.toLowerCase().trim(),
        label: tag
    }));

    return {
        options
    };
}

export const withData = () =>
    compose(
        graphql(
            gql`
                query withTags {
                    currentUser {
                        id
                        userId
                        organization {
                            id
                            organizationId
                            tags
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    tags: get(data, 'currentUser.organization.tags', [])
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withMemo({ normalizeTags }),
        withProps(({ normalizeTags: normalize, tags }) => ({
            ...normalize(tags)
        }))
    );
