import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose, withStateHandlers } from 'recompose';
import { DOWNLOAD_MATCH_LIMIT, withData } from './data';
import { ExportEventsModalUI } from './ui';

export class ExportEventsModal extends PureComponent {
    static displayName = 'ExportEventsModalContainer';

    static propTypes = {
        dashboardId: PropTypes.string,
        dashDateRule: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
        dashLoading: PropTypes.bool,
        dashName: PropTypes.string,
        dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
        hasDashDateRule: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        setDateRange: PropTypes.func.isRequired,
        streamId: PropTypes.string,
        streamName: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        totalMatches: PropTypes.number.isRequired
    };

    static defaultProps = {
        dashboardId: undefined,
        dashDateRule: undefined,
        dashLoading: false,
        dashName: undefined,
        loading: false,
        streamId: undefined,
        streamName: undefined,
        styles: undefined
    };

    componentDidMount() {
        const { dashDateRule, setDateRange } = this.props;
        if (dashDateRule) {
            setDateRange({ value: dashDateRule });
        }
    }

    componentDidUpdate({ dashDateRule: prevDashDateRule }) {
        const { dashDateRule, dashLoading, loading, setDateRange } = this.props;
        if (!dashLoading && !loading && !prevDashDateRule && dashDateRule) {
            setDateRange({ value: dashDateRule });
        }
    }

    render() {
        const {
            dashboardId,
            dashLoading,
            dashName,
            dateRange,
            hasDashDateRule,
            loading,
            onClose,
            setDateRange,
            streamId,
            streamName,
            styles,
            totalMatches
        } = this.props;
        return (
            <ExportEventsModalUI
                dashboardId={dashboardId}
                dashName={dashName}
                dateRange={dateRange}
                downloadLimit={DOWNLOAD_MATCH_LIMIT}
                hasDashDateRule={hasDashDateRule}
                loading={dashLoading || loading}
                onClose={onClose}
                setDateRange={setDateRange}
                streamId={streamId}
                streamName={streamName}
                styles={styles}
                totalMatches={totalMatches}
            />
        );
    }
}

export const ExportEventsModalContainer = compose(
    withStateHandlers(
        {
            dateRange: [new Date(), new XDate().addWeeks(2).toDate()]
        },
        {
            setDateRange: () => ({ value }) => ({ dateRange: value })
        }
    ),
    withData()
)(ExportEventsModal);
