import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { EventDialerUI } from './ui';

export class EventDialer extends PureComponent {
    static displayName = 'EventDialerContainer';

    static propTypes = {
        audioCall: PropTypes.objectOf(PropTypes.any),
        focusMode: PropTypes.bool.isRequired,
        listenOnly: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        toggleDialPad: PropTypes.func
    };

    static defaultProps = {
        audioCall: null,
        listenOnly: false,
        mediaPlayer: null,
        toggleDialPad: () => {}
    };

    constructor(props) {
        super(props);

        this.onTone = this.onTone.bind(this);
        this.onCall = this.onCall.bind(this);
        this.onHangUp = this.onHangUp.bind(this);
    }

    onTone(tone) {
        const { mediaPlayer } = this.props;
        if (mediaPlayer) {
            mediaPlayer.tone(tone);
        }
    }

    onCall({ tones }) {
        const { listenOnly, mediaPlayer } = this.props;
        if (mediaPlayer) {
            if (listenOnly) {
                mediaPlayer.listen({ dial: tones.join('') });
            } else {
                mediaPlayer.record({ dial: tones.join('') });
            }
        }
    }

    onHangUp() {
        const { mediaPlayer } = this.props;
        if (mediaPlayer) {
            mediaPlayer.stop();
        }
    }

    render() {
        const { audioCall, focusMode, toggleDialPad, mediaPlayer } = this.props;
        const inProgress = get(audioCall, 'isLive');
        return mediaPlayer && mediaPlayer.canRecord && (!inProgress || mediaPlayer.dialed) ? (
            <EventDialerUI
                focusMode={focusMode}
                toggleDialPad={toggleDialPad}
                onTone={this.onTone}
                onCall={this.onCall}
                onHangUp={this.onHangUp}
                number={get(audioCall, 'conferenceNumber')}
                pin={get(audioCall, 'conferencePin')}
                connected={['muted', 'recording'].includes(mediaPlayer.status)}
            />
        ) : null;
    }
}

export const EventDialerContainer = compose(withEventMediaPlayer({ fetchPolicy: 'cache-only' }))(EventDialer);
