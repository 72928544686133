import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import uuid from 'uuid/v4';
import { compose } from 'recompose';
import { generatePath, matchPath, withRouter } from 'react-router-dom';
import { routes } from 'routes';
import { get } from 'utils';
import { DEFAULT_WATCHLIST_FILTER, TYPES } from 'consts/filters';
import { withReporting } from 'provider/reporting';
import { withData } from './data';
import { WatchlistFormUI } from './ui';

/**
 * We removed the min values from all mcap buttons (except Large)
 * because using both min and max creates two separate rules,
 * which the server handles as "OR" instead of "AND",
 * so the mcap range doesn't actually work as intended.
 */
const marketCapButtons = [
    {
        label: 'Micro',
        value: { max: 500000000 }
    },
    {
        label: 'Small',
        value: { max: 2000000000 }
    },
    {
        label: 'Mid',
        value: { max: 20000000000 }
    },
    {
        label: 'Large',
        value: { min: 20000000000 }
    }
];

function hasAdvancedFilters(filters) {
    let hasFilters = false;
    if (filters[TYPES.mcap] || filters[TYPES.sector]) {
        hasFilters = true;
    }
    if (filters.filterList) {
        hasFilters = filters.filterList.some(({ value }) => !!value);
    }
    return hasFilters;
}

function hasLoadingState(cloning, deleting, submitting) {
    return cloning || deleting || submitting;
}

// Disable if it's the primary watchlist and no equities have been added
function isCloseDisabled(filters, hasRules, watchlist) {
    return get(watchlist, 'watchlistType') === 'primary_watchlist' && !Object.keys(filters).length && !hasRules;
}

// Disable submit button if name is empty or if the primary watchlist has 0 equities selected
function isSubmitDisabled(filters, getRulesFromFilters, hasChanges, watchlist) {
    const hasNoRules = getRulesFromFilters(filters).length === 0;
    return hasNoRules || !hasChanges || !get(watchlist, 'name');
}

export class WatchlistForm extends PureComponent {
    static displayName = 'WatchlistFormContainer';

    static propTypes = {
        createWatchlist: PropTypes.func,
        deleteWatchlist: PropTypes.func,
        filters: PropTypes.objectOf(PropTypes.any),
        getRulesFromFilters: PropTypes.func.isRequired,
        hasRules: PropTypes.bool,
        hiddenEquities: PropTypes.arrayOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        isOpen: PropTypes.bool,
        isOnboarding: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        updateWatchlist: PropTypes.func.isRequired,
        watchlist: PropTypes.objectOf(PropTypes.any),
        watchlistId: PropTypes.string
    };

    static defaultProps = {
        createWatchlist: null,
        deleteWatchlist: null,
        filters: undefined,
        hasRules: false,
        hiddenEquities: [],
        isOpen: false,
        isOnboarding: false,
        loading: false,
        styles: undefined,
        watchlist: null,
        watchlistId: null
    };

    constructor(props) {
        super(props);

        this.onAddSelectedIdentifiers = this.onAddSelectedIdentifiers.bind(this);
        this.onBulkIdentifiersReset = this.onBulkIdentifiersReset.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeSelectedIdentifiers = this.onChangeSelectedIdentifiers.bind(this);
        this.onClone = this.onClone.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onRemoveAllEquities = this.onRemoveAllEquities.bind(this);
        this.onRemoveEquity = this.onRemoveEquity.bind(this);
        this.onRestore = this.onRestore.bind(this);
        this.onReviewIdentifiers = this.onReviewIdentifiers.bind(this);
        this.onSelectEquity = this.onSelectEquity.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onToggleAdvanced = this.onToggleAdvanced.bind(this);
        this.onUndoRemoveEquity = this.onUndoRemoveEquity.bind(this);

        this.bulkIdentifiersRef = createRef();
        this.hasAdvancedFilters = memoize(hasAdvancedFilters);
        this.hasLoadingState = memoize(hasLoadingState);
        this.isCloseDisabled = memoize(isCloseDisabled);
        this.isSubmitDisabled = memoize(isSubmitDisabled);
        this.uuid = uuid();

        const filters = props.filters || {};

        this.state = {
            cloneName: '',
            cloning: false,
            deleting: false,
            filters,
            hasChanges: false,
            hiddenEquities: props.hiddenEquities,
            reviewingIdentifiers: false,
            selectedIdentifiers: [],
            selectedPreviewEquities: [],
            showAdvanced: this.hasAdvancedFilters(filters),
            submitting: false,
            watchlist: props.watchlist
        };
    }

    componentDidUpdate({
        filters: prevFilters,
        watchlistId: prevWatchlistId,
        watchlist: prevWatchlist,
        hiddenEquities: prevHiddenEquities
    }) {
        const { filters, watchlistId, watchlist, hiddenEquities } = this.props;
        if (prevWatchlistId !== watchlistId || (!prevWatchlist && watchlist)) {
            this.setState({ watchlist });
        }

        if (prevWatchlistId !== watchlistId || (!prevFilters && filters)) {
            this.setState({
                filters,
                showAdvanced: this.hasAdvancedFilters(filters)
            });
        }

        if (prevHiddenEquities.length !== hiddenEquities.length) {
            this.setState({ hiddenEquities });
        }
    }

    onAddSelectedIdentifiers() {
        const { selectedIdentifiers } = this.state;
        if (selectedIdentifiers.length) {
            this.setState(
                ({ filters }) => {
                    // Combine the selected identifiers with existing equity filters
                    const includedEquities = new Set(get(filters, TYPES.equity, []).concat(selectedIdentifiers));
                    // Remove anything from the blacklist that is now in selectedIdentifiers
                    const equityBlacklist = get(filters, 'equityBlacklist', []).filter(
                        equityId => !includedEquities.has(equityId)
                    );
                    return {
                        filters: {
                            ...filters,
                            [TYPES.equity]: includedEquities.size ? [...includedEquities] : null,
                            equityBlacklist
                        },
                        hasChanges: true,
                        reviewingIdentifiers: false,
                        selectedIdentifiers: []
                    };
                },
                () => {
                    // Reset the BulkFollowIdentifiers component to clear the input
                    if (this.bulkIdentifiersRef.current) {
                        this.bulkIdentifiersRef.current.reset();
                    }
                }
            );
        }
    }

    onBulkIdentifiersReset() {
        this.setState({
            reviewingIdentifiers: false,
            selectedIdentifiers: []
        });
    }

    onCancel() {
        const { filters, hasChanges, selectedPreviewEquities, watchlist } = this.state;
        const { hasRules, onClose } = this.props;
        let confirm = true;
        // eslint-disable-next-line no-alert
        if (hasChanges || get(selectedPreviewEquities, 'length', 0) > 0) {
            confirm = window.confirm('You have unsaved changes. Are you sure you want to cancel?');
        }
        if (confirm && !this.isCloseDisabled(filters, hasRules, watchlist)) onClose();
    }

    onChange({ name: inputName, value }) {
        const name = inputName.replace(`${this.uuid}:`, '');
        if ([TYPES.mcap, TYPES.sector, TYPES.equity, 'filterList'].includes(name)) {
            this.setState(({ filters }) => {
                // Make sure we remove anything from the blacklist that is now in the equity list
                if (name === TYPES.equity) {
                    if (!value) return null;
                    const equityFilters = get(filters, TYPES.equity);
                    const includedEquities = new Set(equityFilters);
                    includedEquities.add(value);
                    const equityBlacklist = get(filters, 'equityBlacklist', []).filter(
                        equityId => !includedEquities.has(equityId)
                    );
                    return {
                        filters: {
                            ...filters,
                            [TYPES.equity]: includedEquities.size ? [...includedEquities] : null,
                            equityBlacklist
                        },
                        hasChanges: true
                    };
                }

                return {
                    filters: {
                        ...filters,
                        [name]: value
                    },
                    hasChanges: true
                };
            });
        } else if (name === 'cloneName') {
            this.setState({
                cloneName: value
            });
        } else {
            this.setState(({ watchlist }) => ({
                hasChanges: true,
                watchlist: {
                    ...watchlist,
                    [name]: value
                }
            }));
        }
    }

    onChangeSelectedIdentifiers(selectedIdentifiers) {
        this.setState({ selectedIdentifiers });
    }

    onClone() {
        const { createWatchlist, filters, history, onClose } = this.props;
        this.setState({ cloning: true }, () => {
            const { cloneName } = this.state;
            createWatchlist({ name: cloneName, filters })
                .then(({ id }) => {
                    this.setState({ cloning: false }, () => {
                        onClose();
                        history.push(generatePath(routes.watchlist, { watchlistId: id }));
                    });
                })
                .catch(() => {
                    this.setState({ cloning: false });
                });
        });
    }

    onRemove() {
        const { deleteWatchlist, history, onClose } = this.props;
        // eslint-disable-next-line no-alert
        const confirm = window.confirm('Are you sure you want to remove this watchlist?');
        if (confirm) {
            this.setState({ deleting: true }, () => {
                deleteWatchlist()
                    .then(() => {
                        this.setState({ deleting: false }, () => {
                            onClose();
                            history.push(generatePath(routes.watchlist));
                        });
                    })
                    .catch(() => {
                        this.setState({ deleting: false });
                    });
            });
        }
    }

    onRemoveAllEquities() {
        this.setState({
            filters: {},
            hasChanges: true
        });
    }

    onRemoveEquity(equityId, equity) {
        this.setState(({ filters, hiddenEquities, selectedPreviewEquities }) => {
            // If the equity was explicitly added, just remove it from the list.
            // Otherwise, it's there from some other rule match so we want to
            // add an explicit rule to exclude.
            const isArray = Array.isArray(equityId);
            const includedEquities = new Set(filters[TYPES.equity]);
            const excludedEquities = new Set(filters.equityBlacklist);
            let newHiddenEquities = hiddenEquities;
            if (isArray) {
                equityId.forEach(id => {
                    if (includedEquities.has(id)) {
                        includedEquities.delete(id);
                    } else {
                        excludedEquities.add(id);
                        if (Array.isArray(equity)) {
                            const hiddenEquity = equity.find(({ id: eid }) => eid === id);
                            if (hiddenEquity) {
                                newHiddenEquities = [...newHiddenEquities, hiddenEquity];
                            }
                        }
                    }
                });
            } else if (includedEquities.has(equityId)) {
                includedEquities.delete(equityId);
            } else {
                excludedEquities.add(equityId);
                newHiddenEquities = equity
                    ? [...hiddenEquities, ...(Array.isArray(equity) ? equity : [equity])]
                    : hiddenEquities;
            }

            return {
                filters: {
                    ...filters,
                    [TYPES.equity]: includedEquities.size ? [...includedEquities] : null,
                    equityBlacklist: excludedEquities.size ? [...excludedEquities] : null
                },
                hiddenEquities: newHiddenEquities,
                hasChanges: true,
                selectedPreviewEquities: selectedPreviewEquities.filter(
                    eId => !(isArray ? equityId : [equityId]).includes(eId)
                )
            };
        });
    }

    onRestore() {
        if (
            window.confirm('Are you sure you want to restore your watchlist to the S&P 500? Any changes will be lost.')
        ) {
            this.setState({
                filters: DEFAULT_WATCHLIST_FILTER,
                hasChanges: true,
                hiddenEquities: [],
                selectedPreviewEquities: []
            });
        }
    }

    onReviewIdentifiers() {
        this.setState({
            hasChanges: true,
            reviewingIdentifiers: true
        });
    }

    onSelectEquity({ value }) {
        this.setState({ selectedPreviewEquities: value });
    }

    onSubmit() {
        const { createWatchlist, history, location, onClose, reporter, updateWatchlist, watchlistId } = this.props;
        const { filters, watchlist } = this.state;
        this.setState({ submitting: true }, () => {
            if (watchlistId === 'new') {
                createWatchlist({ name: get(watchlist, 'name', '').trim(), filters })
                    .then(({ id }) => {
                        this.setState(
                            {
                                hasChanges: false,
                                submitting: false
                            },
                            () => {
                                // Redirect to the new watchlist's tab if currently on the watchlist page
                                if (matchPath(location.pathname, { path: routes.watchlist })) {
                                    history.push(generatePath(routes.watchlist, { watchlistId: id }));
                                } else {
                                    onClose();
                                }
                                if (window.Appcues) {
                                    window.Appcues.track('Created Watchlist');
                                }

                                // Track Creating Watchlist
                                reporter.track(reporter.actions.submit, reporter.objects.watchlistCreate, {
                                    component: 'WatchlistForm',
                                    watchlistId
                                });
                            }
                        );
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            } else {
                updateWatchlist({ name: get(watchlist, 'name', '').trim(), filters })
                    .then(() => {
                        this.setState(
                            {
                                hasChanges: false,
                                submitting: false
                            },
                            () => {
                                onClose();
                            }
                        );
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            }
        });
    }

    onToggleAdvanced() {
        const { showAdvanced } = this.state;
        this.setState({ showAdvanced: !showAdvanced });
    }

    onUndoRemoveEquity(equityId) {
        const { filters, hiddenEquities } = this.state;
        const excludedEquities = new Set(filters.equityBlacklist);
        const removedIds = Array.isArray(equityId) ? equityId : [equityId];
        removedIds.forEach(id => {
            if (excludedEquities.has(id)) {
                excludedEquities.delete(id);
            }
        });
        const newHiddenEquities = hiddenEquities.filter(({ id }) => excludedEquities.has(id));
        this.setState({
            filters: { ...filters, equityBlacklist: excludedEquities.size ? [...excludedEquities] : null },
            hasChanges: true,
            hiddenEquities: newHiddenEquities
        });
    }

    render() {
        const {
            cloneName,
            cloning,
            deleting,
            filters,
            hasChanges,
            hiddenEquities,
            reviewingIdentifiers,
            selectedIdentifiers,
            selectedPreviewEquities,
            showAdvanced,
            submitting,
            watchlist
        } = this.state;
        const { getRulesFromFilters, hasRules, isOpen, isOnboarding, loading, styles, watchlistId } = this.props;
        const isLoading = this.hasLoadingState(cloning, deleting, submitting);
        const isNew = watchlistId === 'new';
        const cancelDisabled = isLoading || this.isCloseDisabled(filters, hasRules, watchlist);
        const submitDisabled = isLoading || this.isSubmitDisabled(filters, getRulesFromFilters, hasChanges, watchlist);
        return (
            <WatchlistFormUI
                bulkIdentifiersRef={this.bulkIdentifiersRef}
                cloneName={cloneName}
                cloning={cloning}
                deleting={deleting}
                filters={filters}
                hiddenEquities={hiddenEquities}
                isCancelDisabled={cancelDisabled}
                isModalOpen={isOpen}
                isNew={isNew}
                isOnboarding={isOnboarding}
                isSubmitDisabled={submitDisabled}
                loading={loading}
                locked={get(watchlist, 'status') === 'locked'}
                marketCapButtons={marketCapButtons}
                name={get(watchlist, 'name')}
                onAddSelectedIdentifiers={this.onAddSelectedIdentifiers}
                onBulkIdentifiersReset={this.onBulkIdentifiersReset}
                onCancel={this.onCancel}
                onChange={this.onChange}
                onChangeSelectedIdentifiers={this.onChangeSelectedIdentifiers}
                onClone={this.onClone}
                onRemove={this.onRemove}
                onRemoveAllEquities={this.onRemoveAllEquities}
                onRemoveEquity={this.onRemoveEquity}
                onRestore={this.onRestore}
                onReviewIdentifiers={this.onReviewIdentifiers}
                onSelectEquity={this.onSelectEquity}
                onSubmit={this.onSubmit}
                onUndoRemoveEquity={this.onUndoRemoveEquity}
                previewRules={getRulesFromFilters(filters)}
                reviewingIdentifiers={reviewingIdentifiers}
                selectedIdentifiers={selectedIdentifiers}
                selectedPreviewEquities={selectedPreviewEquities}
                showAdvanced={showAdvanced}
                showWarning={
                    get(watchlist, 'watchlistType') === 'primary_watchlist' && get(watchlist, 'equityCount') === 0
                }
                styles={styles}
                submitting={submitting}
                toggleAdvanced={this.onToggleAdvanced}
                title={isNew ? 'Create Watchlist' : 'Edit Watchlist'}
                uuid={this.uuid}
            />
        );
    }
}

export const WatchlistFormContainer = compose(withRouter, withData(), withReporting())(WatchlistForm);
