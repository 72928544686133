import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { PERMISSIONS, STREAM_MENU_COLORS } from 'consts';
import { OPERATORS, TYPES } from 'consts/filters';
import { withCreateDashboard } from 'graphql/dashboards';
import { withCreateStream } from 'graphql/streams';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { mapContentTypeToFilter, mapFiltersToRules } from 'utils/streams';
import { withUser } from 'graphql/user';
import { hasPermission } from 'utils';
import { CreateDashFromTermTooltipUI } from './ui';

const STREAM_CONTENT_TYPES = ['transcript', 'attachment', 'filing', 'document', 'news'];

export class CreateDashFromTermTooltip extends PureComponent {
    static displayName = 'CreateDashFromTermTooltipContainer';

    static propTypes = {
        createDashboard: PropTypes.func.isRequired,
        createStream: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        user: PropTypes.objectOf(PropTypes.any),
        onSubmit: PropTypes.func,
        placeholder: PropTypes.string,
        searchTerm: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        user: undefined,
        onSubmit: null,
        placeholder: undefined,
        searchTerm: '',
        styles: undefined,
        xOffset: undefined,
        yOffset: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false,
            value: props.searchTerm
        };
    }

    componentDidUpdate({ searchTerm: prevSearchTerm }) {
        const { searchTerm } = this.props;
        if (prevSearchTerm !== searchTerm) {
            this.setState({ value: searchTerm });
        }
    }

    onChange({ value }) {
        this.setState({ value });
    }

    onSubmit() {
        const { value } = this.state;
        const { createDashboard, createStream, history, onSubmit, searchTerm, user } = this.props;
        const searchTermFilter = {
            type: TYPES.searchTerm,
            operator: OPERATORS.is,
            value: [searchTerm]
        };
        const contentTypes = [...STREAM_CONTENT_TYPES];
        const streamInputs = [];
        // Create a stream for each supported content type
        if (hasPermission(user, PERMISSIONS.featureStreamsResearch)) {
            contentTypes.push('research');
        }
        contentTypes.forEach((contentType, idx) => {
            streamInputs.push({
                name: `${searchTerm} ${
                    contentType === 'spotlight'
                        ? 'corporate activity'
                        : contentType === 'attachment'
                        ? 'event docs'
                        : contentType
                }`,
                rules: mapFiltersToRules([searchTermFilter, mapContentTypeToFilter(contentType)]),
                streamType: 'content',
                uxPreferences: {
                    color: STREAM_MENU_COLORS[idx + 1]
                }
            });
        });
        this.setState({ loading: true }, () => {
            createDashboard({ name: value })
                .then(({ id }) => {
                    if (streamInputs.length) {
                        return Promise.all(
                            streamInputs.map(i => createStream({ ...i, dashboardIds: [id], showBanner: false }))
                        ).then(() => id);
                    }
                    return id;
                })
                .then(id => {
                    this.setState({ loading: false }, () => {
                        if (onSubmit) onSubmit();
                        history.push(generatePath(routes.dashboard, { dashboardId: id }));
                    });
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        });
    }

    render() {
        const { loading, value } = this.state;
        const { children, placeholder, styles, xOffset, yOffset } = this.props;
        return (
            <CreateDashFromTermTooltipUI
                isSubmitDisabled={!value.length}
                loading={loading}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                placeholder={placeholder}
                styles={styles}
                value={value}
                xOffset={xOffset}
                yOffset={yOffset}
            >
                {children}
            </CreateDashFromTermTooltipUI>
        );
    }
}

export const CreateDashFromTermTooltipContainer = compose(
    withUser({ fetchPolicy: 'cache-only' }),
    withUrlContext(['history']),
    withCreateDashboard(),
    withCreateStream({ batchKey: 'createDashFromTermTooltip' })
)(CreateDashFromTermTooltip);
