import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { get } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { BackButton } from 'components/BackButton';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Heading } from 'components/Heading';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class CustomDataRecord extends PureComponent {
    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        getTemplateField: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        record: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        equity: null,
        loading: false,
        minimized: false,
        passedStyles: {},
        record: null
    };

    renderCompany() {
        const { equity, styles } = this.props;

        if (!equity) {
            return null;
        }

        const content = (
            <Fragment>
                <Text styles={styles.ticker} size={1} uppercase weight="medium">
                    {`${get(equity, 'localTicker')}:${get(equity, 'exchange.shortName')}`}
                </Text>
                <Text size={1} styles={styles.company}>
                    {get(equity, 'commonName')}
                </Text>
            </Fragment>
        );

        return (
            <Div styles={styles.companyInfo} title="View equity">
                <InternalLink
                    to={generatePath(routes.equity, { equityId: get(equity, 'equityId') })}
                    styles={styles.equityLink}
                >
                    {content}
                </InternalLink>
            </Div>
        );
    }

    renderFullText() {
        const { getTemplateField, styles } = this.props;
        const title = getTemplateField('title');
        const subtitle = getTemplateField('subtitle');
        const topRight = getTemplateField('topRight');
        const bottomRight = getTemplateField('bottomRight');
        const externalUrl = getTemplateField('url');
        const body = getTemplateField('fullPageBody');
        const titleContent = (
            <Heading size={1} styles={styles.fullTextHeading}>
                {title || 'No document title'}
            </Heading>
        );
        return (
            <Div styles={styles.fullTextContainer} className="enable-quick-context">
                {this.renderCompany()}
                <Div styles={styles.headingRow}>
                    {externalUrl ? (
                        <ExternalLink styles={styles.headerLink} href={externalUrl}>
                            {titleContent}
                        </ExternalLink>
                    ) : (
                        titleContent
                    )}
                    {topRight && (
                        <Text size={3} styles={styles.topRight}>
                            {topRight}
                        </Text>
                    )}
                </Div>
                <Div styles={styles.headingRow}>
                    {subtitle && (
                        <Text size={2} styles={styles.subtitle}>
                            {subtitle}
                        </Text>
                    )}
                    {bottomRight && (
                        <Text size={1} styles={styles.bottomRight}>
                            {bottomRight}
                        </Text>
                    )}
                </Div>
                <Div styles={styles.documentContent}>{body && <RawHTML html={body} />}</Div>
            </Div>
        );
    }

    render() {
        const { containerRef, loading, minimized, passedStyles, record, styles, theme } = this.props;

        if (minimized) {
            return null;
        }

        if (loading && !record) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => m && <BackButton back styles={styles.backButton} />}
                    </MediaQuery>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        return (
            <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                {this.renderFullText()}
            </Div>
        );
    }
}

export const CustomDataRecordUI = compose(withStyleSheet(styleSheet))(CustomDataRecord);
