import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { DashboardDropdown } from 'components/DashboardDropdown';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

class CreateStreamFromTermTooltip extends PureComponent {
    static propTypes = {
        contentTypeOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        isSubmitDisabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSelectContentType: PropTypes.func.isRequired,
        onSelectDashboard: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedContentType: PropTypes.string,
        selectedDashboard: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.string,
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        passedStyles: {},
        selectedContentType: null,
        selectedDashboard: null,
        value: '',
        xOffset: 0,
        yOffset: -30
    };

    renderForm(hideTooltip) {
        const {
            contentTypeOptions,
            isSubmitDisabled,
            loading,
            onChange,
            onSelectContentType,
            onSelectDashboard,
            onSubmit,
            selectedContentType,
            selectedDashboard,
            styles,
            value
        } = this.props;
        return (
            <Fragment>
                <TextInput
                    autoFocus
                    label="Name"
                    name="search"
                    onChange={onChange}
                    placeholder="Enter saved search name..."
                    styles={styles.textInput}
                    value={value}
                />
                <Dropdown
                    disableHoverOpen
                    label="Select content type"
                    name="saveAsButtonContentDropdown"
                    onChange={onSelectContentType}
                    options={contentTypeOptions}
                    size={2}
                    styles={styles.dropdown}
                    value={selectedContentType}
                />
                <DashboardDropdown
                    disableHoverOpen
                    label="Select dashboard"
                    name="saveAsButtonDashDropdown"
                    onChange={onSelectDashboard}
                    size={2}
                    styles={styles.dropdown}
                    value={selectedDashboard}
                />
                <Div styles={styles.buttonsContainer}>
                    <ActionButton styles={{ ...styles.button, ...styles.buttonCancel }} onClick={hideTooltip} uppercase>
                        <Text>Cancel</Text>
                    </ActionButton>
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSubmit,
                            ...(isSubmitDisabled ? styles.buttonDisabled : {})
                        }}
                        disabled={isSubmitDisabled}
                        loading={loading}
                        onClick={onSubmit}
                    >
                        <Text size={1} uppercase>
                            Save Search
                        </Text>
                    </ActionButton>
                </Div>
            </Fragment>
        );
    }

    render() {
        const { children, passedStyles, styles, xOffset, yOffset } = this.props;
        return (
            <Tooltip
                content={({ hideTooltip }) => this.renderForm(hideTooltip)}
                isEnabled
                styles={{ ...styles.container, ...passedStyles }}
                useElementOffsetBottom
                useElementOffsetLeft
                useOutsideClickHandler
                hideOnScroll={false}
                xOffset={xOffset}
                yOffset={yOffset}
            >
                {children}
            </Tooltip>
        );
    }
}

export const CreateStreamFromTermTooltipUI = compose(withStyleSheet(styleSheet))(CreateStreamFromTermTooltip);
