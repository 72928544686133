import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class SearchCommandLegend extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Text styles={styles.label} size={0} uppercase>
                    advanced usage
                </Text>
                <Div styles={styles.row}>
                    <Text size={1}>To signify &quot;AND&quot;</Text>
                    <Div styles={styles.spacer} />
                    <Text span weight="medium" size={1}>
                        foo
                    </Text>
                    <Text span weight="medium" size={1}>
                        AND
                    </Text>
                    <Text span weight="medium" size={1}>
                        bar
                    </Text>
                    ,
                    <Text span weight="medium" size={1}>
                        foo
                    </Text>
                    <Text span weight="medium" size={1}>
                        +
                    </Text>
                    <Text span weight="medium" size={1}>
                        bar
                    </Text>
                </Div>
                <Div styles={styles.row}>
                    <Text size={1}>To signify &quot;OR&quot;</Text>
                    <Div styles={styles.spacer} />
                    <Text span weight="medium" size={1}>
                        foo
                    </Text>
                    <Text span weight="medium" size={1}>
                        OR
                    </Text>
                    <Text span weight="medium" size={1}>
                        bar
                    </Text>
                    ,
                    <Text span weight="medium" size={1}>
                        foo
                    </Text>
                    <Text span weight="medium" size={1}>
                        |
                    </Text>
                    <Text span weight="medium" size={1}>
                        bar
                    </Text>
                </Div>
                <Div styles={styles.row}>
                    <Text size={1}>To negate a term</Text>
                    <Div styles={styles.spacer} />
                    <Text span weight="medium" size={1}>
                        -word
                    </Text>
                </Div>
                <Div styles={styles.row}>
                    <Text size={1}>To search by phrase</Text>
                    <Div styles={styles.spacer} />
                    <Text span weight="medium" size={1}>
                        &quot;my example&quot;
                    </Text>
                </Div>
                <Div styles={styles.row}>
                    <Text size={1}>To search by prefix</Text>
                    <Div styles={styles.spacer} />
                    <Text span weight="medium" size={1}>
                        word*
                    </Text>
                </Div>
            </Div>
        );
    }
}

export const SearchCommandLegendUI = compose(withStyleSheet(styleSheet))(SearchCommandLegend);
