import { compose } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { withGetUser } from 'graphql/user';
import { config } from 'configuration';

export const withData = () =>
    compose(
        withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
        graphql(
            gql`
                query withUploadedDocument($documentId: ID!) {
                    content(filter: { contentIds: [$documentId] }) {
                        __typename
                        id
                        title
                        contentType
                        createdDate
                        ... on DocumentContent {
                            pdfUrl
                        }
                    }
                }
            `,
            {
                props: ({ data, ownProps }) => {
                    const content = get(data, 'content[0]');
                    const id = get(content, 'id');
                    const endpoint = get(config, 'API_ENDPOINT');
                    const apiKey = get(ownProps, 'user.apiKey');
                    const pdfUrl = `${endpoint}/content/${id}/pdf?api_key=${apiKey}`;
                    return {
                        pdfUrl,
                        contentType: get(content, 'contentType'),
                        documentError: !!get(data, 'error'),
                        documentLoading: get(data, 'loading'),
                        publishedDate: get(content, 'createdDate'),
                        title: get(content, 'title')
                    };
                },
                options: ({ documentId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    returnPreviousData: true,
                    variables: {
                        documentId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
