export const styleSheet = theme => {
    return {
        container: {
            padding: '0 35px 30px'
        },
        externalLink: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginLeft: 4,
            textOverflow: 'ellipsis'
        },
        headerEmbed: { display: 'flex', justifyContent: 'space-between', marginTop: 30 },
        headerLink: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
        buttonDownload: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            p: {
                marginLeft: 4
            },
            ':hover': {
                textDecoration: 'underline',
                color: theme.colors.blue08,
                g: {
                    fill: theme.colors.blue08
                }
            }
        },
        buttonShareLink: {
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            cursor: 'pointer',
            ':hover': { color: theme.colors.blue08, g: { fill: theme.colors.blue08 } }
        },
        modal: {
            width: '100%',
            maxWidth: 700
        },
        shareLinkWrapper: {
            display: 'flex',
            margin: '6px 0',
            padding: '10px 12px',
            fontSize: theme.fonts.fontSizes.size01,
            border: `1px solid ${theme.colors.gray05}`,
            alignItems: 'center',
            borderRadius: '6px'
        },
        textarea: {
            resize: 'none',
            borderRadius: '6px',
            border: `1px solid ${theme.colors.gray05}`,
            padding: '6px 10px',
            height: 116,
            width: '100%',
            margin: '6px 0',
            overflow: 'hidden',
            fontSize: theme.fonts.fontSizes.size00
        },
        transcrippetWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px 0 0'
        }
    };
};
