import pick from 'lodash/pick';
import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { TYPES } from 'consts/filters';
import { watchlistFragment, watchlistEquityFragment } from 'graphql/fragments/watchlists';
import { graphql } from 'graphql/utils';
import { withDeleteWatchlist } from 'graphql/watchlists';
import { withMemo } from 'hoc/utils';
import { routes } from 'routes';
import { get } from 'utils';
import { matchPath } from 'react-router-dom';
import { userFragment } from 'graphql/fragments/user';

// Generic utils that will move after all rule/filter mappings are ready

function groupRules(rules) {
    const grouped = groupBy(rules, ({ ruleType }) => ruleType);
    Object.keys(grouped).forEach(key => {
        grouped[key] = groupBy(grouped[key], ({ condition }) => condition);
    });
    return grouped;
}

function getMinMaxFilter(type, groupedRules) {
    const min = get(groupedRules, 'is_greater_than_or_equal_to.[0].value');
    const max = get(groupedRules, 'is_less_than_or_equal_to.[0].value');
    return min !== undefined || max !== undefined ? { type, value: { min, max } } : null;
}

function getSimpleFilter(type, groupedRules) {
    const value = get(groupedRules, 'is_equal');
    return value ? { type, value } : null;
}

function getMarketCapFilter(groupedRules) {
    return getMinMaxFilter(TYPES.mcap, groupedRules.marketcap);
}

function getPGaapEpsFilter(groupedRules) {
    return getMinMaxFilter(TYPES.pGaapEps, groupedRules.p_gaap_eps);
}

function getEvSalesFilter(groupedRules) {
    return getMinMaxFilter(TYPES.evSales, groupedRules.ev_sales);
}

function getEvEbitdaFilter(groupedRules) {
    return getMinMaxFilter(TYPES.evEbitda, groupedRules.ev_ebitda);
}

function getEvFcfFilter(groupedRules) {
    return getMinMaxFilter(TYPES.evFcf, groupedRules.ev_fcf);
}

function getEquityFilter(groupedRules) {
    return { type: TYPES.equity, value: get(groupedRules, 'equity_id.is_equal', []).map(({ value }) => value) };
}

function getEquityBlacklistFilter(groupedRules) {
    return { type: TYPES.equity, value: get(groupedRules, 'equity_id.is_not_equal', []).map(({ value }) => value) };
}

function getSectorFilter(groupedRules) {
    const multiValue = [
        ...get(groupedRules, 'gics_sector_id.is_equal', []).map(({ value }) => ({ gicsSectorId: value })),
        ...get(groupedRules, 'gics_sub_sector_id.is_equal', []).map(({ value }) => ({ gicsSubSectorId: value }))
    ];
    return {
        type: TYPES.sector,
        value: multiValue.length ? multiValue : null
    };
}

function getCountryFilter(groupedRules) {
    const multiValue = get(groupedRules, 'country_code.is_equal', []).map(({ value }) => value);
    return {
        type: TYPES.country,
        value: multiValue.length ? multiValue : null
    };
}

function getExchangeCountryFilter(groupedRules) {
    const multiValue = get(groupedRules, 'exchange_country_code.is_equal', []).map(({ value }) => value);
    return {
        type: TYPES.exchangeCountry,
        value: multiValue.length ? multiValue : null
    };
}

function getIndexFilter(groupedRules) {
    const multiValue = get(groupedRules, 'index_id.is_equal', []).map(({ value }) => value);
    return {
        type: TYPES.index,
        value: multiValue.length ? multiValue : null
    };
}

function getCompanyTypeFilter(groupedRules) {
    return { type: TYPES.companyType, value: get(groupedRules, 'company_type.is_equal', []).map(({ value }) => value) };
}

function getOfferingTypeFilter(groupedRules) {
    return getSimpleFilter(TYPES.offeringType, groupedRules.offering_type);
}

function groupFilters(filters) {
    return groupBy(filters, ({ type }) => type);
}

function getMinMaxRule(ruleType, value) {
    const min = get(value, 'min');
    const max = get(value, 'max');
    return [
        min
            ? {
                  ruleType,
                  condition: 'is_greater_than_or_equal_to',
                  value: min
              }
            : null,
        max
            ? {
                  ruleType,
                  condition: 'is_less_than_or_equal_to',
                  value: max
              }
            : null
    ].filter(r => r);
}

function getSimpleRule(ruleType, value) {
    return [
        value
            ? {
                  ruleType,
                  condition: 'is_equal',
                  value
              }
            : null
    ].filter(r => r);
}

function getMarketCapRules(value) {
    return getMinMaxRule('marketcap', value);
}

function getPGaapEpsRules(value) {
    return getMinMaxRule('p_gaap_eps', value);
}

function getEvSalesRules(value) {
    return getMinMaxRule('ev_sales', value);
}

function getEvEbitdaRules(value) {
    return getMinMaxRule('ev_ebitda', value);
}

function getEvFcfRules(value) {
    return getMinMaxRule('ev_fcf', value);
}

function getEquityRules(value) {
    return (value || []).map(equityId => ({ ruleType: 'equity_id', condition: 'is_equal', value: equityId }));
}

function getEquityBlacklistRules(value) {
    return (value || []).map(equityId => ({ ruleType: 'equity_id', condition: 'is_not_equal', value: equityId }));
}

function getSectorRules(value) {
    return (value || [])
        .map(({ gicsSectorId, gicsSubSectorId }) =>
            gicsSectorId || gicsSubSectorId
                ? {
                      ruleType: gicsSectorId ? 'gics_sector_id' : 'gics_sub_sector_id',
                      condition: 'is_equal',
                      value: gicsSectorId || gicsSubSectorId
                  }
                : null
        )
        .filter(r => r);
}

function getCountryRules(value) {
    return (value || [])
        .map(countryCode =>
            countryCode
                ? {
                      ruleType: 'country_code',
                      condition: 'is_equal',
                      value: countryCode
                  }
                : null
        )
        .filter(r => r);
}

function getExchangeCountryRules(value) {
    return (value || [])
        .map(countryCode =>
            countryCode
                ? {
                      ruleType: 'exchange_country_code',
                      condition: 'is_equal',
                      value: countryCode
                  }
                : null
        )
        .filter(r => r);
}

function getIndexRules(value) {
    return (value || [])
        .map(indexId =>
            indexId
                ? {
                      ruleType: 'index_id',
                      condition: 'is_equal',
                      value: indexId
                  }
                : null
        )
        .filter(r => r);
}

function getCompanyTypeRules(groupedRules) {
    const value = Array.isArray(groupedRules) ? groupedRules : [];
    return value
        .map(companyType =>
            companyType
                ? {
                      ruleType: 'company_type',
                      condition: 'is_equal',
                      value: companyType
                  }
                : null
        )
        .filter(r => r);
}

function getOfferingTypeRules(value) {
    return getSimpleRule(TYPES.offeringType, value);
}

// End of util functions

// Mappers that are specific to the watchlist form

function getFiltersFromRules(rules) {
    const groupedRules = groupRules(rules);
    return {
        [TYPES.mcap]: get(getMarketCapFilter(groupedRules), 'value'),
        [TYPES.equity]: get(getEquityFilter(groupedRules), 'value'),
        equityBlacklist: get(getEquityBlacklistFilter(groupedRules), 'value'),
        [TYPES.sector]: get(getSectorFilter(groupedRules), 'value'),
        filterList: [
            getCountryFilter(groupedRules),
            getExchangeCountryFilter(groupedRules),
            getIndexFilter(groupedRules),
            getOfferingTypeFilter(groupedRules),
            getPGaapEpsFilter(groupedRules),
            getEvSalesFilter(groupedRules),
            getEvEbitdaFilter(groupedRules),
            getEvFcfFilter(groupedRules),
            getCompanyTypeFilter(groupedRules)
        ].filter(r => r)
    };
}

export function getRulesFromFilters(filters) {
    if (!filters) return [];

    const { filterList } = filters;
    const grouped = groupFilters(filterList);
    return [
        ...getEquityRules(filters[TYPES.equity]),
        ...getEquityBlacklistRules(filters.equityBlacklist),
        ...getSectorRules(filters[TYPES.sector]),
        ...getMarketCapRules(filters[TYPES.mcap]),
        ...getCountryRules(get(grouped, `${TYPES.country}[0].value`)),
        ...getExchangeCountryRules(get(grouped, `${TYPES.exchangeCountry}[0].value`)),
        ...getIndexRules(get(grouped, `${TYPES.index}[0].value`)),
        ...getOfferingTypeRules(get(grouped, `${TYPES.offeringType}[0].value`)),
        ...getPGaapEpsRules(get(grouped, `${TYPES.pGaapEps}[0].value`)),
        ...getEvSalesRules(get(grouped, `${TYPES.evSales}[0].value`)),
        ...getEvEbitdaRules(get(grouped, `${TYPES.evEbitda}[0].value`)),
        ...getEvFcfRules(get(grouped, `${TYPES.evFcf}[0].value`)),
        ...getCompanyTypeRules(get(grouped, `${TYPES.companyType}[0].value`))
    ].map(r => pick(r, ['ruleType', 'condition', 'value']));
}

export function getHiddenEquitiesFromRules(rules) {
    const hiddenRules = rules.filter(
        ({ ruleType, condition }) => ruleType === 'equity_id' && condition === 'is_not_equal'
    );
    return hiddenRules.map(({ targetEquity }) => targetEquity);
}

export const withUpdateWatchlist = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation UpdateWatchlist($watchlistId: ID!, $name: String!, $rules: [WatchlistRuleInput]!) {
                    updateWatchlist(input: { watchlistId: $watchlistId, name: $name, rules: $rules }) {
                        watchlist {
                            ...watchlist
                            equities(size: 100, fromIndex: 0, sortKey: name, sortDirection: asc) {
                                ...watchlistEquity
                            }
                        }
                    }
                }
                ${watchlistFragment}
                ${watchlistEquityFragment}
            `,
            {
                props: ({ mutate, ownProps: { currentUserId, watchlistId, setStatusBanner } }) => ({
                    updateWatchlist: ({ name, filters, id, userId }) => {
                        const rules = getRulesFromFilters(filters);
                        const uId = userId || currentUserId;
                        return mutate({
                            variables: {
                                watchlistId: id || watchlistId,
                                name,
                                rules
                            },
                            update: (proxy, response) => {
                                /**
                                 * If updating the primary watchlist,
                                 * update numFollowedEquities for the current user's fragment in the cache.
                                 * numFollowedEquities is just the number of equities in the user's primary watchlist.
                                 * We're doing this because we're forcing all users to have at least 1 equity
                                 * in their primary watchlist (see /app/AuthGate/container.js#L70).
                                 * So, update the user fragment in the cache to make sure we don't show the edit
                                 * watchlist form when the page changes.
                                 */
                                const watchlist = get(response, 'data.updateWatchlist.watchlist');
                                if (get(watchlist, 'watchlistType') === 'primary_watchlist') {
                                    const fragmentId = `User:${uId}`;
                                    const item = proxy.readFragment({
                                        id: fragmentId,
                                        fragment: userFragment
                                    });
                                    proxy.writeFragment({
                                        id: fragmentId,
                                        fragment: userFragment,
                                        data: {
                                            ...item,
                                            numFollowedEquities: get(watchlist, 'equityCount', 0)
                                        }
                                    });
                                }
                            }
                        })
                            .then(() => {
                                setStatusBanner('Watchlist updated successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error updating watchlist: ${error}`, 'error', 'circleX');
                                throw error;
                            });
                    }
                })
            }
        )
    );

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withMemo({ getFiltersFromRules, getRulesFromFilters, getHiddenEquitiesFromRules }),
        graphql(
            gql`
                query withWatchlistForm($watchlistId: ID!) {
                    currentUser {
                        id
                        watchlists(ids: [$watchlistId]) {
                            ...watchlist
                        }
                    }
                }
                ${watchlistFragment}
            `,
            {
                props: ({
                    data,
                    ownProps: { getFiltersFromRules: getFilters, getHiddenEquitiesFromRules: getHiddenEquities }
                }) => {
                    const watchlist = get(data, 'currentUser.watchlists[0]');
                    const rules = get(watchlist, 'rules', []);
                    const hiddenEquities = getHiddenEquities(rules);
                    return {
                        currentUserId: get(data, 'currentUser.id'),
                        filters: rules.length ? getFilters(rules) : undefined,
                        hasRules: rules.length > 0,
                        hiddenEquities,
                        loading: get(data, 'loading'),
                        watchlist
                    };
                },
                skip: ({ watchlistId }) => !watchlistId || watchlistId === 'new',
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                mutation CreateWatchlist($name: String!, $rules: [WatchlistRuleInput]!) {
                    createWatchlist(input: { name: $name, rules: $rules }) {
                        watchlist {
                            ...watchlist
                        }
                    }
                }
                ${watchlistFragment}
            `,
            {
                props: ({ mutate, ownProps: { location, setStatusBanner } }) => ({
                    createWatchlist: ({ name, filters }) => {
                        const rules = getRulesFromFilters(filters);
                        return mutate({
                            variables: {
                                name,
                                rules
                            },
                            update: (
                                proxy,
                                {
                                    data: {
                                        createWatchlist: { watchlist }
                                    }
                                }
                            ) => {
                                // Only use the watchlist fragment when on the watchlist page
                                const query = matchPath(location.pathname, { path: routes.watchlist })
                                    ? gql`
                                          query withCreateWatchlist {
                                              currentUser {
                                                  id
                                                  watchlists {
                                                      ...watchlist
                                                  }
                                              }
                                          }
                                          ${watchlistFragment}
                                      `
                                    : gql`
                                          query withCreateWatchlist {
                                              currentUser {
                                                  id
                                                  watchlists {
                                                      id
                                                      watchlistId
                                                      name
                                                  }
                                              }
                                          }
                                      `;
                                const { currentUser } = proxy.readQuery({ query });
                                proxy.writeQuery({
                                    query,
                                    data: {
                                        currentUser: {
                                            ...currentUser,
                                            watchlists: [...currentUser.watchlists, watchlist]
                                        }
                                    }
                                });
                            }
                        })
                            .then(
                                ({
                                    data: {
                                        createWatchlist: { watchlist }
                                    }
                                }) => {
                                    setStatusBanner('Watchlist created successfully!');
                                    return watchlist;
                                }
                            )
                            .catch(error => {
                                setStatusBanner(`Error creating watchlist: ${error}`, 'error', 'circleX');
                                throw error;
                            });
                    }
                })
            }
        ),
        withUpdateWatchlist(),
        withDeleteWatchlist()
    );
