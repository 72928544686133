import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ENVIRONMENTS } from 'consts';
import { withUser } from 'graphql/user';
import { hasPermission } from 'utils';

export class WithPermission extends PureComponent {
    static displayName = 'WithPermissionContainer';

    static propTypes = {
        env: PropTypes.oneOf(ENVIRONMENTS),
        permissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        restrictedComponent: PropTypes.elementType,
        user: PropTypes.shape({ permissions: PropTypes.arrayOf(PropTypes.string) }),
        userLoading: PropTypes.bool
    };

    static defaultProps = {
        env: 'production',
        permissions: [],
        restrictedComponent: null,
        user: null,
        userLoading: false
    };

    render() {
        const { env, user, userLoading, permissions, children, restrictedComponent: RestrictedComponent } = this.props;
        const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
        // If we dont have one or more of the required perms, restrict.
        // If perms is an empty array, some returns false, meaning we
        // assume it's unrestricted.
        const restricted = permissionsArray.some(p => !hasPermission(user, p, env));
        if (typeof children === 'function') {
            return children({ restricted, isLoading: userLoading });
        }
        if (restricted) {
            if (
                RestrictedComponent &&
                RestrictedComponent.prototype &&
                RestrictedComponent.prototype.isReactComponent
            ) {
                return <RestrictedComponent />;
            }
            return RestrictedComponent;
        }
        return children;
    }
}

export const WithPermissionContainer = compose(withUser())(WithPermission);
