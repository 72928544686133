import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class EventPriceAlert extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        price: PropTypes.string,
        priceMovementAbsolute: PropTypes.number,
        priceMovementPercent: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        dismissPriceAlert: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        price: undefined,
        priceMovementAbsolute: undefined,
        priceMovementPercent: undefined
    };

    render() {
        const {
            passedStyles,
            styles,
            theme,
            price,
            priceMovementAbsolute,
            priceMovementPercent,
            dismissPriceAlert
        } = this.props;
        const isPositive = priceMovementAbsolute && priceMovementAbsolute > 0;
        return (
            <Div
                styles={{
                    ...styles.container,
                    backgroundColor: isPositive ? theme.colors.green09 : theme.colors.red05,
                    ...passedStyles
                }}
                onClick={dismissPriceAlert}
            >
                <Icon type="bell" color={isPositive ? theme.colors.green03 : theme.colors.red01} />
                <Text size={1} uppercase styles={styles.label}>
                    Price reaction
                </Text>
                <Div styles={styles.spacer} />
                {price && (
                    <Div styles={styles.price}>
                        <Text size={1}>
                            {price}{' '}
                            <Text
                                size={1}
                                span
                                styles={{ color: isPositive ? theme.colors.green03 : theme.colors.red01 }}
                            >
                                ({priceMovementAbsolute.toFixed(2)}) {priceMovementPercent.toFixed(2)}%
                            </Text>
                        </Text>
                        <Icon
                            type={isPositive ? 'triangleUp' : 'triangleDown'}
                            color={isPositive ? theme.colors.green03 : theme.colors.red01}
                        />
                    </Div>
                )}
                <Icon type="circleX" color={theme.colors.black01} />
            </Div>
        );
    }
}

export const EventPriceAlertUI = compose(withStyleSheet(styleSheet))(EventPriceAlert);
