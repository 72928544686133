import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { GSheetStreamUI } from './ui';

export class GSheetStream extends PureComponent {
    static displayName = 'GSheetStreamContainer';

    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        streamId: null,
        styles: undefined,
        subtitle: undefined
    };

    render() {
        const { dashboardId, loadMoreMatches, loading, matches, streamId, streamProps, styles, subtitle } = this.props;
        return (
            <GSheetStreamUI
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
            />
        );
    }
}

export const GSheetStreamContainer = compose(withData())(GSheetStream);
