export const styleSheet = theme => {
    const toggleBtn = {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        borderTop: `1px solid ${theme.colors.gray01}`,
        padding: '0 13px'
    };
    const primaryCard = {
        border: `1px solid ${theme.colors.gray09}`,
        backgroundColor: theme.colors.white01,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        padding: '10px 13px',
        position: 'relative'
    };
    const primaryCardSubContent = {
        border: `1px solid ${theme.colors.gray09}`,
        backgroundColor: theme.colors.white01,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px 6px 0 0',
        borderBottom: 'unset',
        padding: '10px 13px',
        position: 'relative'
    };
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '6px',
            width: 'calc(100% - 6px)',
            margin: '0 3px 3px',
            position: 'relative',
            '> a': {
                color: 'inherit',
                textDecoration: 'inherit'
            }
        },
        highlight: {
            ...theme.content.newFadeAnimation
        },
        pointer: {
            ':hover': {
                backgroundColor: theme.colors.blue09,
                cursor: 'pointer'
            }
        },
        primaryCard: {
            ...primaryCard
        },
        primaryCardMobile: {
            ...primaryCard
        },
        primaryCardSubContent: {
            ...primaryCardSubContent
        },
        primaryCardSubContentMobile: {
            ...primaryCardSubContent
        },
        subContentTitle: {
            overflow: 'hidden',
            flex: 1,
            marginLeft: 4,
            'p:first-of-type': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineHeight: '1.2em',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
            }
        },
        subContentTitleText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: '3px',
            marginRight: 10,
            color: theme.colors.gray06
        },
        subContentButtonText: {
            flex: 1
        },
        sentiment: {
            position: 'absolute',
            left: 0,
            top: 10,
            backgroundColor: theme.colors.gray05,
            borderRadius: '0 3px 3px 0',
            height: '45px',
            width: '3px'
        },
        sentimentNeg: {
            backgroundColor: theme.colors.red01
        },
        sentimentPos: {
            backgroundColor: theme.colors.green01
        },
        toggleSubContentBtn: {
            ...toggleBtn,
            position: 'relative',
            borderRadius: '0 0 6px 6px',
            borderLeft: `1px solid ${theme.colors.gray09}`,
            borderRight: `1px solid ${theme.colors.gray09}`,
            borderBottom: `1px solid ${theme.colors.gray09}`,
            borderTop: `1px solid ${theme.colors.gray03}`,
            backgroundColor: theme.colors.white01,
            color: theme.colors.black01,
            svg: {
                transition: 'all 0.2s ease-in-out'
            },
            ':hover': {
                backgroundColor: theme.colors.white03
            },
            ':active': {
                backgroundColor: theme.colors.gray03
            }
        },
        toggleSubContentBtnOpen: {
            ...toggleBtn,
            position: 'sticky',
            zIndex: 1,
            top: 0,
            backgroundColor: theme.colors.white03,
            borderLeft: `1px solid ${theme.colors.gray09}`,
            borderRight: `1px solid ${theme.colors.gray09}`,
            svg: {
                transition: 'all 0.2s ease-in-out',
                transform: 'rotate(90deg)'
            },
            ':hover': {
                backgroundColor: theme.colors.gray03
            },
            ':active': {
                backgroundColor: theme.colors.gray01
            }
        },
        extraCardBorder: {
            height: '4px',
            borderRadius: '0 0 6px 6px',
            borderBottom: `1px solid ${theme.colors.gray09}`,
            backgroundColor: theme.colors.white02,
            ':first-of-type': {
                margin: '0 5px',
                opacity: 0.8
            },
            ':nth-of-type(2)': {
                margin: '0 10px',
                opacity: 0.6
            },
            ':nth-of-type(3)': {
                margin: '0 15px',
                opacity: 0.3
            }
        },
        spacer: {
            flex: 1
        },
        chevron: {
            flexShrink: 0,
            marginRight: 8
        }
    };
};
