const APP_SET_LOGIN_ROUTE = 'APP_SET_LOGIN_ROUTE';
const APP_SET_DEBUG_MODE = 'APP_SET_DEBUG_MODE';

export const types = {
    APP_SET_LOGIN_ROUTE,
    APP_SET_DEBUG_MODE
};

/*
 *
 * These actions are for setting and
 * unsetting the route the user will
 * be redirected to on login. The
 * route should be cleared on login as well.
 *
 */

export function appSetLoginRoute(loginRoute) {
    return {
        type: APP_SET_LOGIN_ROUTE,
        payload: {
            loginRoute
        }
    };
}

export function appSetDebugMode(debugMode) {
    return {
        type: APP_SET_DEBUG_MODE,
        payload: {
            debugMode
        }
    };
}
