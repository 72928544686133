import React from 'react';
import PropTypes from 'prop-types';
import { AieraMobileBridge } from 'utils/mobileBridge';

export const mobileBridge = new AieraMobileBridge();
export function configureMobileBridge() {
    return mobileBridge;
}

const MobileBridgeContext = React.createContext();
export const MobileBridgeProvider = ({ children, bridge }) => (
    <MobileBridgeContext.Provider value={bridge}>{React.Children.only(children)}</MobileBridgeContext.Provider>
);
MobileBridgeProvider.propTypes = { bridge: PropTypes.objectOf(PropTypes.any).isRequired };

export const withMobileBridge = () => BaseComponent => props => (
    <MobileBridgeContext.Consumer>
        {bridge => <BaseComponent {...props} bridge={bridge} />}
    </MobileBridgeContext.Consumer>
);
