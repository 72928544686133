import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ActionMenuUI } from './ui';

export class ActionMenu extends PureComponent {
    static displayName = 'ActionMenuContainer';

    static propTypes = {
        className: PropTypes.string,
        hideTooltip: PropTypes.func,
        hoverOpen: PropTypes.bool,
        menuStyles: PropTypes.objectOf(PropTypes.any),
        onHide: PropTypes.func,
        onShow: PropTypes.func,
        openByDefault: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        className: undefined,
        hideTooltip: undefined,
        hoverOpen: false,
        menuStyles: {},
        onHide: undefined,
        onShow: undefined,
        openByDefault: false,
        options: [],
        styles: undefined
    };

    render() {
        const {
            className,
            hideTooltip,
            hoverOpen,
            menuStyles,
            onHide,
            onShow,
            openByDefault,
            options,
            styles
        } = this.props;
        return (
            <ActionMenuUI
                className={className}
                hideTooltip={hideTooltip}
                hoverOpen={hoverOpen}
                menuStyles={menuStyles}
                onHide={onHide}
                onShow={onShow}
                openByDefault={openByDefault}
                options={options}
                styles={styles}
            />
        );
    }
}

export const ActionMenuContainer = compose()(ActionMenu);
