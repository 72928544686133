import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class StatusBanner extends Component {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        hideStatusBanner: PropTypes.func.isRequired,
        visible: PropTypes.bool.isRequired,
        text: PropTypes.string,
        type: PropTypes.string,
        icon: PropTypes.string
    };

    static defaultProps = {
        text: 'Success',
        type: 'success',
        icon: 'circleCheck'
    };

    render() {
        const { styles, theme, text, icon, visible, type, hideStatusBanner } = this.props;
        let containerStyles = type === 'success' ? styles.container : { ...styles.container, ...styles.containerError };
        let iconColor = theme.colors.white01;
        if (visible) containerStyles = { ...containerStyles, ...styles.visible };

        if (type === 'success') {
            iconColor = theme.colors.green04;
        }

        if (type === 'warning') {
            iconColor = theme.colors.yellow01;
        }

        if (type === 'error') {
            iconColor = theme.colors.red01;
        }

        return (
            <Div styles={styles.absolute} className="print-hide">
                <Div styles={containerStyles} onClick={hideStatusBanner}>
                    <Text size={1} uppercase styles={styles.text}>
                        {text}
                    </Text>
                    {icon && <Icon type={icon} color={iconColor} />}
                </Div>
            </Div>
        );
    }
}

export const StatusBannerUI = compose(withStyleSheet(styleSheet))(StatusBanner);
