export const styleSheet = theme => ({
    container: {
        position: 'relative',
        zIndex: 1,
        boxShadow: '0 1px 3px 0 rgba(38, 39, 53, 0.16)',
        backgroundColor: theme.colors.gray01
    },
    rangeTotal: {
        position: 'absolute',
        top: 10,
        left: 10
    }
});
