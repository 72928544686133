export const styleSheet = theme => ({
    container: {
        display: 'flex'
    },
    filterType: {
        minWidth: 200,
        marginRight: theme.margins.margin00
    },
    operator: {
        minWidth: 200,
        marginRight: theme.margins.margin00
    },
    searchTermInput: {
        input: {
            padding: '0 12px',
            borderRadius: 6
        }
    }
});
