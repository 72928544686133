export const styleSheet = theme => ({
    container: {
        width: 480
    },
    content: {
        a: {
            ':hover': {
                textDecoration: 'none'
            }
        },
        height: 280,
        margin: '20px 35px 10px'
    },
    downloadBtn: {
        ...theme.content.blueButton,
        borderRadius: 6,
        boxShadow: 'unset',
        color: theme.colors.white01,
        justifyContent: 'center',
        marginTop: 20,
        textDecoration: 'none'
    },
    downloadInfo: {
        tr: {
            borderBottom: `1px solid ${theme.colors.gray03}`,
            td: {
                padding: '16px 0',
                ':first-of-type': {
                    fontWeight: theme.fonts.fontWeights.medium,
                    paddingRight: 20
                }
            },
            ':last-of-type': {
                borderBottom: 'unset'
            }
        },
        width: '100%'
    },
    editRow: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    infoRow: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    loading: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 310,
        justifyContent: 'center',
        p: {
            textAlign: 'center',
            lineHeight: '1.3em',
            marginBottom: 20
        },
        width: 480
    },
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 180
    }
});
