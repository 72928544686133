import gql from 'graphql-tag';
import { compose, lifecycle, withProps } from 'recompose';
import { get } from 'utils';
import { withRealtime } from 'provider/realtime';
import { graphql } from 'graphql/utils';
import {
    dataCollectionFragment,
    dataCollectionRecordFragment,
    streamMatchTemplateFragment
} from 'graphql/fragments/streams';
import { withMemo } from 'hoc/utils';

const normalizeSamples = samples => {
    if (!samples) return null;

    const sampleMap = {};
    samples.forEach(sample => {
        const records = get(sample, 'record', []);
        records.forEach(record => {
            const name = get(record, 'name');
            const value = get(record, 'value');
            if (sampleMap[name]) {
                sampleMap[name].push(value);
            } else {
                sampleMap[name] = [value];
            }
        });
    });
    return sampleMap;
};

export const withData = () =>
    compose(
        withMemo({ normalizeSamples }),
        graphql(
            gql`
                query withDataCollection($dataCollectionId: ID!) {
                    dataCollections(filter: { dataCollectionIds: [$dataCollectionId] }) {
                        ...dataCollection
                        sample {
                            ...dataCollectionRecord
                        }
                    }
                }
                ${dataCollectionFragment}
                ${dataCollectionRecordFragment}
            `,
            {
                props: ({ data, ownProps: { normalizeSamples: normalizeSampleData } }) => {
                    const dataCollection = get(data, 'dataCollections[0]');
                    return {
                        dataCollection,
                        dataCollectionLoading: data.loading,
                        refreshDataCollection: data.refetch,
                        sampleRecords: normalizeSampleData(get(dataCollection, 'sample'))
                    };
                },
                skip: ({ dataCollectionId }) => !dataCollectionId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.trySubscribe = dataCollectionId => {
                    if (this.subscription && this.subscribedId !== dataCollectionId) {
                        this.subscription.unsubscribe();
                        this.subscription = null;
                        this.subscribedId = null;
                        const { refreshDataCollection } = this.props;
                        if (dataCollectionId) {
                            refreshDataCollection();
                        }
                    }
                    if (!this.subscription && dataCollectionId) {
                        const { realtime } = this.props;
                        this.subscription = realtime.subscribe(`data_collection_${dataCollectionId}`, 'updated', () => {
                            const { refreshDataCollection } = this.props;
                            refreshDataCollection();
                        });
                        this.subscribedId = dataCollectionId;
                    }
                };
            },
            componentDidUpdate() {
                const { dataCollectionId } = this.props;
                this.trySubscribe(dataCollectionId);
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                    this.subscribedId = null;
                }
            }
        }),
        graphql(
            gql`
                query withExternalDataCollections {
                    dataCollections(filter: { collectionType: external }) {
                        ...dataCollection
                        sample {
                            ...dataCollectionRecord
                        }
                    }
                }
                ${dataCollectionFragment}
                ${dataCollectionRecordFragment}
            `,
            {
                props: ({ data }) => {
                    return {
                        dataCollections: get(data, 'dataCollections', [])
                    };
                },
                options: {
                    fetchPolicy: 'cache-and-network'
                }
            }
        ),
        graphql(
            gql`
                mutation withCreateDataCollection(
                    $name: String!
                    $type: DataCollectionType
                    $gsheetConfiguration: GSheetConfigurationInput
                    $csvConfiguration: CsvConfigurationInput
                    $apiConfiguration: ApiConfigurationInput
                ) {
                    createDataCollection(
                        input: {
                            name: $name
                            collectionType: $type
                            gsheetConfiguration: $gsheetConfiguration
                            csvConfiguration: $csvConfiguration
                            apiConfiguration: $apiConfiguration
                        }
                    ) {
                        success
                        dataCollection {
                            ...dataCollection
                        }
                    }
                }
                ${dataCollectionFragment}
            `,
            {
                props: ({ mutate }) => ({
                    createDataCollection: (name, type, config) =>
                        mutate({ variables: { name, type, ...config } }).then(({ data }) =>
                            get(data, 'createDataCollection.dataCollection')
                        )
                }),
                options: {
                    context: {
                        debounceKey: 'createDataCollection',
                        debounceTimeout: 500
                    }
                }
            }
        ),
        graphql(
            gql`
                mutation withUpdateDataCollection(
                    $dataCollectionId: ID!
                    $name: String!
                    $type: DataCollectionType
                    $gsheetConfiguration: GSheetConfigurationInput
                    $csvConfiguration: CsvConfigurationInput
                    $apiConfiguration: ApiConfigurationInput
                ) {
                    updateDataCollection(
                        input: {
                            dataCollectionId: $dataCollectionId
                            name: $name
                            collectionType: $type
                            gsheetConfiguration: $gsheetConfiguration
                            csvConfiguration: $csvConfiguration
                            apiConfiguration: $apiConfiguration
                        }
                    ) {
                        success
                        dataCollection {
                            ...dataCollection
                        }
                    }
                }
                ${dataCollectionFragment}
            `,
            {
                props: ({ mutate }) => ({
                    updateDataCollection: (dataCollectionId, name, type, config) =>
                        mutate({ variables: { dataCollectionId, name, type, ...config } }).then(({ data }) =>
                            get(data, 'updateDataCollection.dataCollection')
                        )
                }),
                options: {
                    context: {
                        debounceKey: 'updateDataCollection',
                        debounceTimeout: 500
                    }
                }
            }
        ),

        graphql(
            gql`
                query withStreamMatchTemplate($streamMatchTemplateId: ID!) {
                    streamMatchTemplates(filter: { templateIds: [$streamMatchTemplateId] }) {
                        ...streamMatchTemplate
                    }
                }
                ${streamMatchTemplateFragment}
            `,
            {
                props: ({ data }) => {
                    const template = get(data, 'streamMatchTemplates[0]');
                    return {
                        template,
                        templateLoading: data.loading,
                        refreshTemplate: data.refetch
                    };
                },
                skip: ({ streamMatchTemplateId }) => !streamMatchTemplateId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                mutation withCreateStreamMatchTemplate(
                    $name: String!
                    $type: StreamMatchTemplateType
                    $config: BasicTemplateConfigurationInput
                ) {
                    createStreamMatchTemplate(
                        input: { name: $name, templateType: $type, basicConfiguration: $config }
                    ) {
                        success
                        template {
                            ...streamMatchTemplate
                        }
                    }
                }
                ${streamMatchTemplateFragment}
            `,
            {
                props: ({ mutate }) => ({
                    createTemplate: (name, type, config) =>
                        mutate({ variables: { name, type, config } }).then(({ data }) =>
                            get(data, 'createStreamMatchTemplate.template')
                        )
                }),
                options: {
                    context: {
                        debounceKey: 'createStreamMatchTemplate',
                        debounceTimeout: 500
                    }
                }
            }
        ),
        graphql(
            gql`
                mutation withUpdateStreamMatchTemplate(
                    $streamMatchTemplateId: ID!
                    $name: String!
                    $type: StreamMatchTemplateType
                    $config: BasicTemplateConfigurationInput
                ) {
                    updateStreamMatchTemplate(
                        input: {
                            templateId: $streamMatchTemplateId
                            name: $name
                            templateType: $type
                            basicConfiguration: $config
                        }
                    ) {
                        success
                        template {
                            ...streamMatchTemplate
                        }
                    }
                }
                ${streamMatchTemplateFragment}
            `,
            {
                props: ({ mutate }) => ({
                    updateTemplate: (streamMatchTemplateId, name, type, config) =>
                        mutate({ variables: { streamMatchTemplateId, name, type, config } }).then(({ data }) =>
                            get(data, 'updateStreamMatchTemplate.template')
                        )
                }),
                options: {
                    context: {
                        debounceKey: 'updateStreamMatchTemplate',
                        debounceTimeout: 500
                    }
                }
            }
        ),
        withProps(
            ({
                createDataCollection,
                updateDataCollection,
                dataCollectionId,
                createTemplate,
                updateTemplate,
                streamMatchTemplateId
            }) => ({
                setCollection: (name, type, config) =>
                    dataCollectionId
                        ? updateDataCollection(dataCollectionId, name, type, config)
                        : createDataCollection(name, type, config),
                setTemplate: (name, type, config) =>
                    streamMatchTemplateId
                        ? updateTemplate(streamMatchTemplateId, name, type, config)
                        : createTemplate(name, type, config)
            })
        ),
        graphql(
            gql`
                mutation processDataCollection($dataCollectionId: ID!) {
                    processDataCollection(dataCollectionId: $dataCollectionId) {
                        dataCollection {
                            ...dataCollection
                        }
                    }
                }
                ${dataCollectionFragment}
            `,
            {
                props: ({ mutate }) => ({
                    reprocessDataCollection: () => mutate()
                })
            }
        )
    );
