import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { ModalsUI } from './ui';

export class Modals extends PureComponent {
    static displayName = 'ModalsContainer';

    static propTypes = {
        dashboardGalleryId: PropTypes.string,
        dashboardId: PropTypes.string,
        dashboardStatsId: PropTypes.string,
        editBookmarkId: PropTypes.string,
        editDashboardId: PropTypes.string,
        editPrivateRecordingId: PropTypes.string,
        editStreamId: PropTypes.string,
        editWatchlistId: PropTypes.string,
        equityFinancialsId: PropTypes.string,
        exportEventsId: PropTypes.string,
        exportHighlights: PropTypes.string,
        exportMatchesId: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        sectionId: PropTypes.string,
        sharedStreamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        submitEventDetailsId: PropTypes.string,
        tabs: PropTypes.arrayOf(PropTypes.any),
        transcrippetId: PropTypes.string,
        upgradeType: PropTypes.string,
        videoType: PropTypes.string,
        watchlistIcalId: PropTypes.string
    };

    static defaultProps = {
        dashboardGalleryId: undefined,
        dashboardId: undefined,
        dashboardStatsId: undefined,
        editBookmarkId: undefined,
        editDashboardId: undefined,
        editPrivateRecordingId: undefined,
        editStreamId: undefined,
        editWatchlistId: undefined,
        equityFinancialsId: undefined,
        exportEventsId: undefined,
        exportHighlights: undefined,
        exportMatchesId: undefined,
        sectionId: undefined,
        sharedStreamId: undefined,
        styles: undefined,
        submitEventDetailsId: undefined,
        tabs: undefined,
        transcrippetId: undefined,
        upgradeType: undefined,
        videoType: undefined,
        watchlistIcalId: undefined
    };

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    // Remove the modal ids from query params
    onClose() {
        const { history, tabs } = this.props;
        history.push({ search: qs.stringify({ tabs }, { encode: false }) });
    }

    render() {
        const {
            dashboardGalleryId,
            dashboardId,
            dashboardStatsId,
            editBookmarkId,
            editDashboardId,
            editPrivateRecordingId,
            editStreamId,
            editWatchlistId,
            equityFinancialsId,
            exportEventsId,
            exportHighlights,
            exportMatchesId,
            sectionId,
            sharedStreamId,
            styles,
            submitEventDetailsId,
            transcrippetId,
            upgradeType,
            videoType,
            watchlistIcalId
        } = this.props;
        return (
            <ModalsUI
                dashboardGalleryId={dashboardGalleryId}
                dashboardId={dashboardId}
                dashboardStatsId={dashboardStatsId}
                editBookmarkId={editBookmarkId}
                editDashboardId={editDashboardId}
                editPrivateRecordingId={editPrivateRecordingId}
                editStreamId={editStreamId}
                editWatchlistId={editWatchlistId}
                equityFinancialsId={equityFinancialsId}
                exportEventsId={exportEventsId}
                exportHighlights={exportHighlights}
                exportMatchesId={exportMatchesId}
                onClose={this.onClose}
                sectionId={sectionId}
                sharedStreamId={sharedStreamId}
                styles={styles}
                submitEventDetailsId={submitEventDetailsId}
                transcrippetId={transcrippetId}
                upgradeType={upgradeType}
                videoType={videoType}
                watchlistIcalId={watchlistIcalId}
            />
        );
    }
}

export const ModalsContainer = compose(
    withUrlContext([
        'dashboardGalleryId',
        'dashboardId',
        'dashboardStatsId',
        'editBookmarkId',
        'editDashboardId',
        'editPrivateRecordingId',
        'editStreamId',
        'editWatchlistId',
        'equityFinancialsId',
        'exportEventsId',
        'exportHighlights',
        'exportMatchesId',
        'history',
        'sectionId',
        'sharedStreamId',
        'submitEventDetailsId',
        'tabs',
        'transcrippetId',
        'upgradeType',
        'videoType',
        'watchlistIcalId'
    ])
)(Modals);
