import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { ExternalLinkUI } from './ui';

function formatUrl(url) {
    return !url || url.includes('mailto') || url.includes('webcal') || /^https?:\/\//.test(url) ? url : `http://${url}`;
}

export class ExternalLink extends PureComponent {
    static displayName = 'ExternalLinkContainer';

    static propTypes = {
        href: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.formatUrl = memoize(formatUrl);
    }

    render() {
        const { href, ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ExternalLinkUI href={this.formatUrl(href)} {...rest} />;
    }
}

export const ExternalLinkContainer = compose()(ExternalLink);
