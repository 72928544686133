import { compose } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { withGetUser } from 'graphql/user';
import { config } from 'configuration';

export const withData = () =>
    compose(
        withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
        graphql(
            gql`
                query withResearch($researchId: ID!, $streamId: ID, $includeHighlights: Boolean = false) {
                    content(filter: { contentIds: [$researchId] }) {
                        __typename
                        id
                        title
                        bodyWithHighlights: body(highlight: { streamId: $streamId }) @include(if: $includeHighlights)
                        body @skip(if: $includeHighlights)
                        contentType
                        publishedDate
                        primaryCompany {
                            id
                            commonName
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        companies {
                            id
                            commonName
                            iconUrl
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        categories {
                            id
                            shortName
                            displayName
                        }
                        userSettings {
                            id
                            shareBookmarks
                            isRead
                            archived
                            isRead
                            starred
                            tags
                        }
                        tags {
                            tag
                            users {
                                id
                                username
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data, ownProps }) => {
                    const content = get(data, 'content[0]');
                    const id = get(ownProps, 'researchId');
                    const apiKey = get(ownProps, 'user.apiKey');
                    const endpoint = get(config, 'RESEARCH_ENDPOINT');
                    const pdfUrl = `${endpoint}/content/${id}/pdf?api_key=${apiKey}`;
                    return {
                        pdfUrl,
                        contentType: get(content, 'contentType'),
                        isArchived: get(content, 'userSettings.archived', false),
                        isRead: get(content, 'userSettings.isRead', false),
                        isStarred: get(content, 'userSettings.starred', false),
                        researchError: !!get(data, 'error'),
                        researchLoading: get(data, 'loading'),
                        publishedDate: get(content, 'publishedDate'),
                        tags: get(content, 'tags', []),
                        title: get(content, 'title'),
                        userTags: get(content, 'userSettings.tags', [])
                    };
                },
                options: ({ researchId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    returnPreviousData: true,
                    variables: {
                        researchId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
