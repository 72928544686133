import memoize from 'lodash/memoize';
import { get, formatFinancial } from 'utils';

export const normalizeFinancials = memoize((financials, kpis) => {
    let financialKpis = [...kpis];
    const financialRows = financials.map(f => {
        const highlights = get(f, 'searchResult.matches', []).reverse();
        const chartLabels = [];
        const chartActuals = [];
        const chartEstimates = [];
        const columnData = [];
        const title = get(f, 'kpiTitle', 'N/A');
        const precision = title === title.includes('EPS') ? 1 : 2;
        const format = get(f, 'kpiFormat', '');
        const userFinancialKpiId = get(f, 'userFinancialKpiId', null);
        const lastActual = get(f, 'latestQuarter.actual', 0);
        const lastEstimate = get(f, 'latestQuarter.estimate', 0);
        columnData[0] = formatFinancial(format, get(f, 'latestQuarter.actual', 'N/A'), precision);
        columnData[1] = formatFinancial(format, get(f, 'latestQuarter.estimate', 'N/A'), precision);
        columnData[2] = formatFinancial('percentage', get(f, 'latestQuarter.beatMiss', 'N/A'), 1);
        columnData[3] = formatFinancial('percentage', get(f, 'latestQuarter.actualYoy', 'N/A'), 1);
        columnData[4] = formatFinancial('percentage', get(f, 'latestQuarter.estimateYoy', 'N/A'), 1);

        // Display instructions from Conor
        // for beatMiss column
        if (format === 'percentage') {
            columnData[2] = `${((lastActual - lastEstimate) * 10000).toFixed(0)}bps`;
            columnData[3] = 'N/A';
            columnData[4] = 'N/A';
        } else if (lastActual < 0 || lastEstimate < 0) {
            columnData[2] = 'N/A';
        }

        financialKpis = financialKpis.filter(k => k.key !== get(f, 'kpiTerm', ''));
        get(f, 'quarters', []).forEach((quarter, i) => {
            const label = `${get(quarter, 'quarter')}Q`;
            const actual = get(quarter, 'actual', 'N/A');
            const estimate = get(quarter, 'estimate', 'N/A');
            chartLabels[i] = label;
            chartActuals[i] = actual;
            chartEstimates[i] = estimate;

            if (typeof actual === 'number' && typeof estimate === 'number') {
                if (actual > estimate) {
                    chartActuals[i] = { y: actual, color: '#5AC87A' };
                } else {
                    chartActuals[i] = { y: actual, color: '#C85A73' };
                }
            }
        });

        return {
            userFinancialKpiId: get(f, 'userFinancialKpiId'),
            title: get(f, 'kpiTitle', 'N/A'),
            locked: !userFinancialKpiId,
            highlights,
            columnData,
            chartLabels,
            chartActuals,
            chartEstimates
        };
    });
    return {
        financials: financialRows,
        financialKpis
    };
});
