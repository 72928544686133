export const styleSheet = theme => ({
    container: {
        width: '100%',
        tr: {
            td: {
                backgroundColor: theme.colors.white01
            },
            ':nth-of-type(odd)': {
                td: {
                    backgroundColor: theme.colors.gray10,
                    '.curves': {
                        backgroundColor: theme.colors.gray10
                    }
                }
            }
        }
    },
    tableWrapperScrollLock: {
        height: '100%',
        overflowY: 'hidden'
    },
    tableWrapper: {
        height: '100%',
        overflowY: 'auto'
    }
});
