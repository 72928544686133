import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import XDate from 'xdate';
import { theme } from 'styles';
import { get } from 'utils';
import { mapDashFiltersToRules, mapContentTypesToFilters } from 'utils/streams';
import { graphql } from 'graphql/utils';
import { streamFragment, ruleFragment } from 'graphql/fragments/streams';
import { withMemo } from 'hoc/utils';

function normalizeChartData(streams = []) {
    const normalizedStreams = streams.map(stream => {
        const name = get(stream, 'name');
        const color = get(stream, 'uxPreferences.color', theme.colors.blue08);
        const data = get(stream, 'matches.totalPerDay', []).map(({ x, y }) => ({
            x: new XDate(x).getTime(),
            y
        }));

        return {
            name,
            color,
            data
        };
    });

    return { data: normalizedStreams };
}

export const withData = () =>
    compose(
        withMemo({ mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query withContentStreamChart($streamIds: [ID], $filter: StreamMatchFilter) {
                    streams(filter: { streamIds: $streamIds }) {
                        ...stream
                        rules {
                            ...rule
                        }
                        matches(size: 0, filter: $filter) {
                            total
                            totalPerDay {
                                x: date
                                y: total
                            }
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data, ownProps }) => {
                    const streams = get(data, 'streams', []);
                    const loading = get(data, 'loading');
                    const dateRange = get(ownProps, 'dashDateRange');
                    let rangeTotal = null;
                    if (dateRange) {
                        rangeTotal = get(streams, '[0].matches.total');
                    }
                    return {
                        isEmpty: !loading && streams.length === 0,
                        loading,
                        rangeTotal,
                        streams
                    };
                },
                skip: ({ streamId, wasVisible, contentTypes }) =>
                    !wasVisible || !streamId || !contentTypes || contentTypes.length === 0,
                options: ({ streamId, dashSearchTerm, dashDateRange, dashEquityScope, contentTypes, mapFilters }) => {
                    const rules = [];
                    if (dashDateRange) {
                        rules.push(
                            ...mapFilters({
                                searchTerm: dashSearchTerm,
                                dateRange: dashDateRange,
                                equityScope: dashEquityScope,
                                filters: mapContentTypesToFilters(contentTypes)
                            })
                        );
                    } else {
                        rules.push(
                            {
                                ruleType: 'date',
                                condition: 'is_greater_than_or_equal_to',
                                value: new XDate().addMonths(-1).toString('yyyy-MM-dd')
                            },
                            ...mapFilters({
                                searchTerm: dashSearchTerm,
                                equityScope: dashEquityScope,
                                filters: mapContentTypesToFilters(contentTypes)
                            })
                        );
                    }
                    return {
                        fetchPolicy: 'cache-first',
                        returnPreviousData: true,
                        context: {
                            debounceKey: `contentStreamChart-${streamId}`,
                            debounceTimeout: 300
                        },
                        variables: {
                            streamIds: [streamId],
                            filter: {
                                rules
                            }
                        }
                    };
                }
            }
        ),
        withMemo({ normalizeChartData }),
        withPropsOnChange(['normalizeChartData', 'streams'], ({ normalizeChartData: chartData, streams }) =>
            chartData(streams)
        )
    );
