export const styleSheet = theme => ({
    container: {
        ...theme.content.box,
        boxShadow: '0 1px 10px 5px rgba(66,65,82,0.06)',
        borderRadius: 4,
        padding: `${theme.margins.margin01} ${theme.margins.margin02}`,
        margin: '0 auto',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        zIndex: 9,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            bottom: theme.margins.margin00,
            padding: theme.margins.margin00
        }
    },
    close: {
        cursor: 'pointer',
        textAlign: 'right',
        marginBottom: theme.margins.margin00,
        svg: {
            height: 14,
            width: 14
        }
    },
    dialpad: {
        margin: '0 auto'
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    number: {
        marginRight: theme.margins.margin00
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    center: {
        textAlign: 'center'
    },
    link: {
        cursor: 'pointer',
        color: theme.colors.blue01,
        ':hover': {
            textDecoration: 'underline'
        }
    }
});
