import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { privateRecordingFragment } from 'graphql/fragments/privateRecordings';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';

// Format equities for EquityAutocomplete
function normalizeEquities(equities) {
    return (equities || []).map(equity => ({
        exchange: get(equity, 'exchange.shortName', ''),
        label: get(equity, 'localTicker'),
        value: get(equity, 'id')
    }));
}

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withMemo({ normalize: normalizeEquities }),
        graphql(
            gql`
                query withPrivateRecordingForm($privateRecordingId: ID) {
                    privateRecordings(filter: { privateRecordingIds: [$privateRecordingId] }) {
                        ...privateRecording
                        equities {
                            id
                            localTicker
                            exchange {
                                id
                                shortName
                            }
                        }
                        eventGroups {
                            id
                            title
                        }
                    }
                }
                ${privateRecordingFragment}
            `,
            {
                props: ({ data }) => {
                    const privateRecording = get(data, 'privateRecordings[0]');
                    return {
                        equities: get(privateRecording, 'equities'),
                        loading: get(data, 'loading'),
                        privateRecording
                    };
                },
                options: {
                    fetchPolicy: 'cache-first'
                },
                skip: ({ privateRecordingId }) => !privateRecordingId || privateRecordingId === 'new'
            }
        ),
        graphql(
            gql`
                mutation CreatePrivateRecording(
                    $audioUrl: String
                    $connectAccessId: String
                    $connectCallerId: String
                    $connectionType: PRConnectionType!
                    $connectOffsetSeconds: Int = 0
                    $connectPhoneNumber: String
                    $connectPin: String
                    $connectUrl: String
                    $equityIds: [ID]
                    $eventGroupIds: [ID]
                    $externalAudioStreamUrl: String
                    $fileUploadUuid: String
                    $localeCode: String
                    $onCompleteEmailCreator: Boolean = false
                    $onConnectDialNumber: String
                    $onFailure: PROnFailure
                    $onFailureDialNumber: String
                    $onFailureInstructions: String
                    $onFailureSmsNumber: String
                    $scheduledFor: DateTime
                    $smsAlertBeforeCall: Boolean = true
                    $title: String!
                    $tags: [String]
                ) {
                    createPrivateRecording(
                        input: {
                            audioUrl: $audioUrl
                            connectAccessId: $connectAccessId
                            connectCallerId: $connectCallerId
                            connectionType: $connectionType
                            connectOffsetSeconds: $connectOffsetSeconds
                            connectPhoneNumber: $connectPhoneNumber
                            connectPin: $connectPin
                            connectUrl: $connectUrl
                            equityIds: $equityIds
                            eventGroupIds: $eventGroupIds
                            externalAudioStreamUrl: $externalAudioStreamUrl
                            fileUploadUuid: $fileUploadUuid
                            localeCode: $localeCode
                            onCompleteEmailCreator: $onCompleteEmailCreator
                            onConnectDialNumber: $onConnectDialNumber
                            onFailure: $onFailure
                            onFailureDialNumber: $onFailureDialNumber
                            onFailureInstructions: $onFailureInstructions
                            onFailureSmsNumber: $onFailureSmsNumber
                            scheduledFor: $scheduledFor
                            smsAlertBeforeCall: $smsAlertBeforeCall
                            title: $title
                            tags: $tags
                        }
                    ) {
                        privateRecording {
                            ...privateRecording
                            equities {
                                id
                                localTicker
                                exchange {
                                    id
                                    shortName
                                }
                            }
                            eventGroups {
                                id
                                title
                            }
                        }
                    }
                }
                ${privateRecordingFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    createPrivateRecording: ({
                        audioUrl,
                        connectAccessId,
                        connectCallerId,
                        connectionType,
                        connectOffsetSeconds,
                        connectPhoneNumber,
                        connectPin,
                        connectUrl,
                        equityIds,
                        eventGroupIds,
                        externalAudioStreamUrl,
                        fileUploadUuid,
                        localeCode,
                        onCompleteEmailCreator,
                        onConnectDialNumber,
                        onFailure,
                        onFailureDialNumber,
                        onFailureInstructions,
                        onFailureSmsNumber,
                        scheduledFor,
                        smsAlertBeforeCall,
                        title,
                        tags
                    }) =>
                        mutate({
                            variables: {
                                audioUrl,
                                connectAccessId,
                                connectCallerId,
                                connectionType,
                                connectOffsetSeconds,
                                connectPhoneNumber,
                                connectPin,
                                connectUrl,
                                equityIds,
                                eventGroupIds,
                                externalAudioStreamUrl,
                                fileUploadUuid,
                                localeCode,
                                onCompleteEmailCreator,
                                onConnectDialNumber,
                                onFailure,
                                onFailureDialNumber,
                                onFailureInstructions,
                                onFailureSmsNumber,
                                scheduledFor,
                                smsAlertBeforeCall,
                                title,
                                tags
                            }
                        })
                            .then(response => {
                                setStatusBanner('Recording created successfully!');
                                return get(response, 'data.createPrivateRecording.privateRecording');
                            })
                            .catch(error => {
                                setStatusBanner(`Error creating Recording: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        ),
        graphql(
            gql`
                mutation UpdatePrivateRecording(
                    $audioUrl: String
                    $connectAccessId: String
                    $connectCallerId: String
                    $connectionType: PRConnectionType!
                    $connectOffsetSeconds: Int = 0
                    $connectPhoneNumber: String
                    $connectPin: String
                    $connectUrl: String
                    $equityIds: [ID]
                    $eventGroupIds: [ID]
                    $externalAudioStreamUrl: String
                    $fileUploadUuid: String
                    $localeCode: String
                    $onCompleteEmailCreator: Boolean = false
                    $onConnectDialNumber: String
                    $onFailure: PROnFailure
                    $onFailureDialNumber: String
                    $onFailureInstructions: String
                    $onFailureSmsNumber: String
                    $privateRecordingId: ID!
                    $scheduledFor: DateTime
                    $smsAlertBeforeCall: Boolean = true
                    $title: String!
                    $tags: [String]
                    $retry: Boolean = false
                ) {
                    updatePrivateRecording(
                        input: {
                            audioUrl: $audioUrl
                            connectAccessId: $connectAccessId
                            connectCallerId: $connectCallerId
                            connectionType: $connectionType
                            connectOffsetSeconds: $connectOffsetSeconds
                            connectPhoneNumber: $connectPhoneNumber
                            connectPin: $connectPin
                            connectUrl: $connectUrl
                            equityIds: $equityIds
                            eventGroupIds: $eventGroupIds
                            externalAudioStreamUrl: $externalAudioStreamUrl
                            fileUploadUuid: $fileUploadUuid
                            localeCode: $localeCode
                            onCompleteEmailCreator: $onCompleteEmailCreator
                            onConnectDialNumber: $onConnectDialNumber
                            onFailure: $onFailure
                            onFailureDialNumber: $onFailureDialNumber
                            onFailureInstructions: $onFailureInstructions
                            onFailureSmsNumber: $onFailureSmsNumber
                            privateRecordingId: $privateRecordingId
                            scheduledFor: $scheduledFor
                            smsAlertBeforeCall: $smsAlertBeforeCall
                            title: $title
                            tags: $tags
                        }
                        retry: $retry
                    ) {
                        privateRecording {
                            ...privateRecording
                            equities {
                                id
                                localTicker
                                exchange {
                                    id
                                    shortName
                                }
                            }
                            eventGroups {
                                id
                                title
                            }
                        }
                    }
                }
                ${privateRecordingFragment}
            `,
            {
                props: ({ mutate, ownProps: { privateRecordingId, setStatusBanner } }) => ({
                    updatePrivateRecording: (
                        {
                            audioUrl,
                            connectAccessId,
                            connectCallerId,
                            connectionType,
                            connectOffsetSeconds,
                            connectPhoneNumber,
                            connectPin,
                            connectUrl,
                            equityIds,
                            eventGroupIds,
                            externalAudioStreamUrl,
                            fileUploadUuid,
                            localeCode,
                            onCompleteEmailCreator,
                            onConnectDialNumber,
                            onFailure,
                            onFailureDialNumber,
                            onFailureInstructions,
                            onFailureSmsNumber,
                            scheduledFor,
                            smsAlertBeforeCall,
                            title,
                            tags
                        },
                        retry = false
                    ) =>
                        mutate({
                            variables: {
                                audioUrl,
                                connectAccessId,
                                connectCallerId,
                                connectionType,
                                connectOffsetSeconds,
                                connectPhoneNumber,
                                connectPin,
                                connectUrl,
                                equityIds,
                                eventGroupIds,
                                externalAudioStreamUrl,
                                fileUploadUuid,
                                localeCode,
                                onCompleteEmailCreator,
                                onConnectDialNumber,
                                onFailure,
                                onFailureDialNumber,
                                onFailureInstructions,
                                onFailureSmsNumber,
                                privateRecordingId,
                                scheduledFor,
                                smsAlertBeforeCall,
                                title,
                                tags,
                                retry
                            }
                        })
                            .then(() => {
                                setStatusBanner('Recording updated successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error updating Recording: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        ),
        graphql(
            gql`
                mutation DeletePrivateRecording($privateRecordingId: ID!) {
                    deletePrivateRecording(privateRecordingId: $privateRecordingId) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    deletePrivateRecording: privateRecordingId =>
                        mutate({
                            variables: { privateRecordingId }
                        })
                            .then(() => {
                                setStatusBanner('Recording deleted successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error deleting Recording: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        ),
        withPropsOnChange(['equities'], ({ equities, normalize }) => ({
            equities: normalize(equities)
        }))
    );
