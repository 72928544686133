import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { ButtonForm } from 'components/ButtonForm';
import { Div } from 'components/Div';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Textarea } from 'components/Textarea';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class SubmitEventDetailsForm extends PureComponent {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any),
        onChange: PropTypes.func,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        submitDisabled: PropTypes.bool,
        submitted: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        form: null,
        onChange: null,
        onClose: null,
        onSubmit: null,
        passedStyles: {},
        submitDisabled: false,
        submitted: false
    };

    renderSubmitted() {
        const { styles } = this.props;
        return (
            <Div styles={styles.thanks}>
                <Text styles={styles.thanksTitle} size={6} weight="medium">
                    Thank you!
                </Text>
                <Text styles={styles.thanksText} size={3}>
                    Thank you for submitting the event details. Our team is reviewing and will update this event once
                    the details are confirmed.
                </Text>
            </Div>
        );
    }

    renderForm() {
        const { form, onChange, onSubmit, submitDisabled, styles } = this.props;
        return (
            <Fragment>
                <TextInput
                    autoFocus
                    label="Webcast URL"
                    name="webcastUrl"
                    onChange={onChange}
                    styles={styles.input}
                    value={get(form, 'webcastUrl', '')}
                />
                <TextInput
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={onChange}
                    styles={styles.input}
                    value={get(form, 'phoneNumber', '')}
                />
                <TextInput
                    label="PIN/Access Code"
                    name="pin"
                    onChange={onChange}
                    styles={styles.input}
                    value={get(form, 'pin', '')}
                />
                <Textarea
                    label="Other details"
                    name="text"
                    onChange={onChange}
                    styles={styles.input}
                    value={get(form, 'text', '')}
                />
                <Text styles={styles.legal} size={2}>
                    By submitting you acknowledge that the event transcript and audio will become available to everyone
                    on the Aiera platform.
                </Text>
                <ButtonForm styles={styles.submitButton} disabled={submitDisabled} icon="event" onClick={onSubmit}>
                    Submit
                </ButtonForm>
            </Fragment>
        );
    }

    render() {
        const { submitted, onClose, passedStyles, styles } = this.props;
        return (
            <Modal isOpen onModalClose={onClose} title="Submit Connection Details">
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    {submitted ? this.renderSubmitted() : this.renderForm()}
                </Div>
            </Modal>
        );
    }
}

export const SubmitEventDetailsFormUI = compose(withStyleSheet(styleSheet))(SubmitEventDetailsForm);
