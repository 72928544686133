import gql from 'graphql-tag';
import { compose, lifecycle } from 'recompose';
import { get } from 'utils';
import { withMemo } from 'hoc/utils';
import {
    formatMatchesByType,
    mapDashFiltersToRules,
    mapRulesToFilters,
    mapContentTypesToFilters,
    mapFilingFormsToFilters,
    mapFiltersToRules
} from 'utils/streams';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges
} from 'hoc/streams';
import { contentMatchFragment, ruleFragment, streamFragment, transcriptMatchFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { FILING_FORM_FILTERS, TYPES, OPERATORS } from 'consts/filters';
import { withRealtime } from 'provider/realtime';
import { withGetUser } from 'graphql/user';

function hasFilingFormFilter(filters) {
    return !!(filters || []).find(f => f && f.type === TYPES.filingForm && f.operator === OPERATORS.is);
}

function isOnlyFilingsType(rules) {
    let hasFiling = false;
    const types = [];
    (rules || []).forEach(r => {
        if (r.condition === 'is_equal' && r.ruleType === TYPES.type) {
            types.push(r);
        }
        if (r.value === 'filing') hasFiling = true;
    });
    return types.length === 1 && hasFiling;
}

function mapFiltersToContentTypes(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.type && f.operator === OPERATORS.is),
        'value.type',
        []
    );
}

function mapFormIdToNumber(formId) {
    return Object.keys(FILING_FORM_FILTERS).find(k => FILING_FORM_FILTERS[k] === formId);
}

function mapFiltersToFilingForms(filters) {
    const filingFilters = (filters || []).filter(f => f && f.type === TYPES.filingForm);
    const filingForms = Object.keys(FILING_FORM_FILTERS);
    if (!filingFilters.length) return filingForms;
    const checked = [];
    const unchecked = [];
    filingFilters.forEach(f => {
        if (f.operator === OPERATORS.is) {
            f.value.forEach(formId => {
                const formNumber = mapFormIdToNumber(String(formId));
                if (formNumber) checked.push(formNumber);
            });
        }
        if (f.operator === OPERATORS.isNot) {
            const formNumber = mapFormIdToNumber(String(f.value));
            if (formNumber) unchecked.push(formNumber);
        }
    });
    return checked.length ? checked : filingForms.map(form => (!unchecked.includes(form) ? form : null)).filter(f => f);
}

export const withData = () =>
    compose(
        withMemo({
            formatMatches: formatMatchesByType,
            hasFilingFilters: hasFilingFormFilter,
            hasOnlyFilings: isOnlyFilingsType,
            mapFilters: mapDashFiltersToRules
        }),
        graphql(
            gql`
                mutation SetStreamLens($input: SetStreamRulesInput!) {
                    setStreamLens(input: $input) {
                        stream {
                            ...stream
                            lens {
                                rules {
                                    ...rule
                                }
                            }
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate }) => ({
                    setStreamLens: ({ streamId, contentTypes, filingForms }) => {
                        let rules = [];
                        if (contentTypes) {
                            rules = mapFiltersToRules(mapContentTypesToFilters(contentTypes));
                        }
                        if (filingForms) {
                            rules = mapFiltersToRules(mapFilingFormsToFilters(filingForms));
                        }
                        return mutate({
                            variables: {
                                input: { streamId, rules }
                            }
                        });
                    }
                })
            }
        ),
        graphql(
            gql`
                query withContentStream($streamId: ID, $size: Int = 20, $offset: Int = 0, $filter: StreamMatchFilter) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        lens {
                            rules {
                                ...rule
                            }
                        }
                        rules {
                            ...rule
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...contentMatch
                                ...transcriptMatch
                            }
                        }
                    }
                }
                ${contentMatchFragment}
                ${ruleFragment}
                ${streamFragment}
                ${transcriptMatchFragment}
            `,
            {
                props: ({
                    data,
                    ownProps: {
                        formatMatches,
                        filingFormsSet,
                        filtersSet,
                        hasFilingFilters,
                        hasOnlyFilings,
                        inStateContentTypes,
                        inStateFilingForms
                    }
                }) => {
                    const {
                        fetchMore,
                        loading,
                        matches,
                        refetch,
                        rules,
                        stream,
                        subtitle,
                        lens
                    } = getDefaultStreamProps(data, formatMatches);
                    const filters = mapRulesToFilters(get(lens, 'length') ? lens : rules);
                    const contentTypes = filtersSet ? inStateContentTypes : mapFiltersToContentTypes(filters);
                    const isOnlyFilings = hasOnlyFilings(rules);
                    const filingForms =
                        !filingFormsSet && isOnlyFilings ? mapFiltersToFilingForms(filters) : inStateFilingForms;
                    const streamContentTypes = mapFiltersToContentTypes(mapRulesToFilters(rules));
                    const showTypeSelector =
                        filtersSet ||
                        (streamContentTypes.includes('transcript') &&
                            (streamContentTypes.includes('attachment') || streamContentTypes.includes('news')));
                    const hasFilters =
                        mapRulesToFilters(rules).filter(({ type }) => type !== TYPES.searchTerm && type !== TYPES.type)
                            .length > 0;
                    const hasFilingFilter = hasFilingFilters(mapRulesToFilters(rules));
                    const searchTerms = (rules || [])
                        .filter(({ ruleType, condition }) => ruleType === 'search_term' && condition === 'is_equal')
                        .map(({ value }) => value);
                    return {
                        contentTypes,
                        fetchMore,
                        filingForms,
                        hasFilingFilter,
                        hasFilters,
                        isOnlyFilings,
                        loading,
                        matches,
                        refetch,
                        rules,
                        searchTerms,
                        showFilingStreamToolbar: isOnlyFilings && !hasFilingFilter,
                        showTypeSelector,
                        stream,
                        streamContentTypes,
                        subtitle
                    };
                },
                skip: ({ streamId, streamProps }) => !get(streamProps, 'wasVisible', false) || !streamId,
                options: ({
                    dashDateRange,
                    dashFilters,
                    dashSearchTerm,
                    dashSources,
                    dashEquityScope,
                    mapFilters,
                    streamId,
                    inStateContentTypes,
                    inStateFilingForms,
                    filingFormsSet,
                    filtersSet
                }) => {
                    let lenses;
                    if (filtersSet) lenses = mapContentTypesToFilters(inStateContentTypes);
                    if (filingFormsSet) lenses = mapFilingFormsToFilters(inStateFilingForms);
                    return getDefaultStreamOptions({
                        applyLens: true,
                        dashDateRange,
                        dashFilters,
                        dashSearchTerm,
                        dashSources,
                        dashEquityScope,
                        lenses,
                        mapFilters,
                        streamId,
                        streamType: 'content'
                    });
                }
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging(),
        withGetUser({ fetchPolicy: 'cache-only' }),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.trySubscribe = () => {
                    if (this.subscriptions) {
                        this.subscriptions.forEach(sub => sub.unsubscribe());
                        this.subscriptions = null;
                    }
                    if (!this.subscriptions) {
                        const { realtime, streamContentTypes = [], user } = this.props;
                        const userId = get(user, 'id');
                        if (streamContentTypes.includes('document') && userId) {
                            this.subscriptions = [
                                realtime.subscribe(`file_upload_changes_${userId}`, 'created', () => {
                                    const refetch = get(this, 'props.refetch');
                                    if (refetch) {
                                        refetch();
                                    }
                                }),
                                realtime.subscribe(`file_upload_changes_${userId}`, 'deleted', () => {
                                    const refetch = get(this, 'props.refetch');
                                    if (refetch) {
                                        refetch();
                                    }
                                })
                            ];
                        }
                    }
                };
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(sub => sub.unsubscribe());
                    this.subscriptions = null;
                }
            }
        })
    );
