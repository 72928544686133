import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { TextInput } from 'components/TextInput';
import { Text } from 'components/Text';
import { LoaderLogo } from 'components/LoaderLogo';
import { RawHTML } from 'components/RawHTML';
import { ActionButton } from 'components/ActionButton';
import { safeRegExp } from 'utils';
import { styleSheet } from './stylesheet';

class FilingSidebar extends PureComponent {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        executeSearch: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        scrollToSearchResult: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.any),
        searchedTerm: PropTypes.string,
        searchTerm: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {},
        searchResults: [],
        searchedTerm: '',
        searchTerm: ''
    };

    // Split the text and insert styled spans when there are matches for the searchTerm
    getHighlightedText(result) {
        const { searchedTerm, theme } = this.props;
        let highlighted = [result];

        if (result) {
            const parts = result.split(safeRegExp(searchedTerm));
            highlighted = parts.map(part => {
                if (part.toLowerCase().includes(searchedTerm.toLowerCase())) {
                    return `<span style="${`background-color: ${theme.colors.blue08};color: ${theme.colors.white01}`}">${part}</span>`;
                }
                return part;
            });
        }

        return highlighted.join('');
    }

    renderHeader() {
        const { onKeyDown, executeSearch, onSearch, searchTerm, styles } = this.props;

        return (
            <Div styles={styles.header}>
                <TextInput
                    name="articleSearch"
                    onKeyDown={onKeyDown}
                    onChange={onSearch}
                    value={searchTerm}
                    styles={styles.searchBox}
                    icon="search03"
                    placeholder="Search filing..."
                    clearable
                    onClear={executeSearch}
                />
                <ActionButton styles={styles.searchButton} onClick={executeSearch}>
                    <Text size={1}>Search</Text>
                </ActionButton>
            </Div>
        );
    }

    renderSearchResults() {
        const { searchResults, searchedTerm, styles, scrollToSearchResult } = this.props;

        if (searchResults && searchResults.length > 0) {
            return searchResults.map(({ text }, index) => {
                const key = `result-${text.slice(0, 5)}-${index}`;
                return (
                    <Div styles={styles.searchResult} key={key} onClick={() => scrollToSearchResult(index)}>
                        <Text size={0} styles={styles.searchResultCount}>
                            {`${index + 1} of ${searchResults.length}`}
                        </Text>
                        <Div styles={styles.searchResultText}>
                            <RawHTML html={this.getHighlightedText(text)} />
                        </Div>
                    </Div>
                );
            });
        }

        if (!!searchedTerm && searchedTerm.length > 0 && (!searchResults || searchResults.length === 0)) {
            return (
                <Div styles={styles.empty}>
                    <Text size={3} styles={styles.emptyText}>
                        {`No results for ${searchedTerm}`}
                    </Text>
                </Div>
            );
        }

        return null;
    }

    render() {
        const { loading, passedStyles, searchResults, styles } = this.props;
        const hasNoMatches = !searchResults || searchResults.length === 0;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} className="print-hide">
                {this.renderHeader()}
                {loading && hasNoMatches ? (
                    <Div styles={styles.loader}>
                        <LoaderLogo height={60} />
                    </Div>
                ) : (
                    <Div styles={styles.scrollContainer}>{this.renderSearchResults()}</Div>
                )}
            </Div>
        );
    }
}

export const FilingSidebarUI = compose(withStyleSheet(styleSheet))(FilingSidebar);
