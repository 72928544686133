import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { compose } from 'recompose';
import { abbrevNum, get } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Modal } from 'components/Modal';
import { styleSheet } from './stylesheet';

const helpText = `"Call" measures change since the start of the call.

"Since close" measures change since most recent closing price.`;

class EventPriceChart extends PureComponent {
    static propTypes = {
        callEndTime: PropTypes.string,
        callStartTime: PropTypes.string,
        chartRef: PropTypes.objectOf(PropTypes.any),
        closeChartModal: PropTypes.func.isRequired,
        endPrice: PropTypes.number,
        equity: PropTypes.objectOf(PropTypes.any),
        equityHref: PropTypes.string,
        eventType: PropTypes.string,
        isChartModalOpen: PropTypes.bool.isRequired,
        lastClose: PropTypes.number,
        onTimeSelect: PropTypes.func,
        openChartModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        priceData: PropTypes.arrayOf(PropTypes.any),
        realtimePrices: PropTypes.arrayOf(PropTypes.object),
        renderChartHeader: PropTypes.func,
        selectedTime: PropTypes.string,
        showChart: PropTypes.bool,
        startPrice: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string,
        volumeData: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        callEndTime: null,
        callStartTime: null,
        chartRef: null,
        endPrice: null,
        equity: null,
        equityHref: null,
        eventType: null,
        lastClose: null,
        onTimeSelect: null,
        passedStyles: {},
        priceData: null,
        realtimePrices: null,
        renderChartHeader: null,
        selectedTime: null,
        showChart: true,
        startPrice: null,
        theme: {},
        title: undefined,
        volumeData: null
    };

    chartOptions(forModal = false) {
        const {
            callEndTime,
            callStartTime,
            closeChartModal,
            isChartModalOpen,
            onTimeSelect,
            openChartModal,
            priceData,
            realtimePrices,
            selectedTime,
            startPrice,
            theme,
            volumeData
        } = this.props;
        const realtimePricesCount = get(realtimePrices, 'length', 0);
        // Short circuit if we don't have any realtime prices
        if (realtimePricesCount === 0) return null;
        // Get prices and volume changes, and set line and gradient colors based on price direction during event
        const currentChangeAmount = get(realtimePrices, `[${realtimePricesCount - 1}].changeAmount`, 0);
        const prices = [];
        const volumeChanges = [];
        let priceDirection;

        realtimePrices.forEach(({ price, volumeChangeFromLast }) => {
            prices.push(price);
            volumeChanges.push(volumeChangeFromLast);
        });

        if (currentChangeAmount > 0) {
            priceDirection = 'up';
        } else if (currentChangeAmount < 0) {
            priceDirection = 'down';
        }

        let eventPriceColor = theme.colors.gray04;
        let eventPriceStops = [
            [0, 'rgba(179, 175, 175, 0.2)'],
            [0.9, 'rgba(179, 175, 175, 0)']
        ];
        if (priceDirection === 'up') {
            eventPriceColor = '#3BBA35'; // green02
            eventPriceStops = [
                [0, 'rgba(69, 243, 59, 0.2)'],
                [0.9, 'rgba(69, 243, 59, 0)']
            ];
        }
        if (priceDirection === 'down') {
            eventPriceColor = '#C9251E'; // red01
            eventPriceStops = [
                [0, 'rgba(243, 59, 59, 0.2)'],
                [0.9, 'rgba(243, 59, 59, 0)']
            ];
        }

        const toggleModal = () => {
            if (isChartModalOpen) {
                closeChartModal();
            } else {
                openChartModal();
            }
        };

        return {
            title: {
                text: null
            },
            time: {
                useUTC: false,
                timezoneOffset: new XDate().getTimezoneOffset()
            },
            exporting: { enabled: false },
            legend: {
                enabled: false
            },
            chart: {
                className: 'eventPricingChart',
                height: forModal ? undefined : 104,
                spacing: [0, 0, 0, 0],
                events: {
                    click(event) {
                        if (onTimeSelect) {
                            onTimeSelect(new XDate(event.xAxis[0].value));
                        } else {
                            toggleModal();
                        }
                    }
                }
            },
            xAxis: [
                {
                    dateTimeLabelFormats: {
                        hour: '%l:%M%p'
                    },
                    plotLines: [
                        {
                            color: theme.colors.gray04,
                            width: 1,
                            dashStyle: 'dash',
                            value: new XDate(callStartTime).getTime()
                        },
                        ...(selectedTime
                            ? [
                                  {
                                      color: theme.colors.blue08,
                                      width: 3,
                                      dashStyle: 'shortDash',
                                      value: new XDate(selectedTime).getTime(),
                                      zIndex: 4
                                  }
                              ]
                            : []),
                        ...(callEndTime
                            ? [
                                  {
                                      color: theme.colors.gray04,
                                      width: 1,
                                      dashStyle: 'dash',
                                      value: new XDate(callEndTime).getTime()
                                  }
                              ]
                            : [])
                    ],
                    type: 'datetime',
                    offset: 15,
                    lineColor: 'rgba(218, 218, 232, 0.0)',
                    tickColor: 'rgba(218, 218, 232, 0.7)',
                    showLastLabel: false,
                    labels: {
                        y: 3,
                        style: {
                            color: theme.colors.gray08
                        }
                    }
                }
            ],
            yAxis: [
                {
                    visible: true,
                    gridLineWidth: 0,
                    lineWidth: 0,
                    labels: {
                        enabled: true,
                        x: -10,
                        y: -10
                    },
                    plotLines: [
                        {
                            color: theme.colors.gray08,
                            width: 1,
                            dashStyle: 'dash',
                            value: startPrice
                        }
                    ],
                    maxPadding: 0,
                    minPadding: 0,
                    endOnTick: true,
                    height: forModal ? undefined : 104,
                    top: 0,
                    max: Math.max(...prices),
                    min: Math.min(...prices)
                },
                {
                    title: { text: null },
                    gridLineWidth: 0,
                    lineWidth: 0,
                    labels: {
                        enabled: false
                    },
                    height: forModal ? undefined : 104,
                    top: 0,
                    max: Math.max(...volumeChanges),
                    min: Math.min(...volumeChanges)
                }
            ],
            tooltip: {
                followTouchMove: false,
                backgroundColor: theme.colors.white01,
                borderColor: theme.colors.gray01,
                borderRadius: 4,
                borderWidth: 1,
                valueDecimals: 2,
                shadow: false,
                shared: true,
                crosshairs: true,
                followPointer: true,
                split: false,
                headerFormat: `<div style="font-weight: 400;text-align: right;color:${theme.colors.gray04}">{point.key}</div><br/><br/>`,
                style: {
                    fontFamily: theme.fonts.fontFamily,
                    fontSize: theme.fonts.fontSizes.size01,
                    letterSpacing: '1px'
                },
                pointFormatter: function format() {
                    let changeColor = 'inherit';
                    if (this.changeAmount > 0) {
                        changeColor = theme.colors.green01;
                    } else if (this.changeAmount < 0) {
                        changeColor = theme.colors.red01;
                    }

                    let changeStr = 'N/A';
                    if (this.x >= new XDate(callStartTime).getTime()) {
                        changeStr = `
                        ${this.changeAmount >= 0 ? '+' : ''}${this.changeAmount.toFixed(2)}
                        (${Math.abs(this.changePercent * 100).toFixed(2)}%)`;
                    }
                    return `
                        Price: <span style="font-weight: bold">
                            $${this.y.toFixed(2)}
                        </span><br/>
                        Change: <span style="font-weight: bold; color: ${changeColor}">
                            ${changeStr}                            
                        </span><br/>
                        Volume: <span style="font-weight: bold;">
                            ${abbrevNum(this.volume)}                            
                        </span>
                        ${
                            realtimePricesCount === 1
                                ? `
                                    <br />
                                    <span style="font-size: 9px;">*Showing latest available price</span>`
                                : ''
                        }
                    `;
                }
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            plotOptions: {
                fillOpacity: 0.1,
                area: {
                    threshold: null,
                    zoneAxis: 'x',
                    zones: [
                        {
                            value: callStartTime ? new XDate(callStartTime).getTime() : null,
                            color: theme.colors.gray04,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    x2: 0,
                                    y1: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, 'rgba(64, 67, 72, 0.07)'],
                                    [0.9, 'rgba(64, 67, 72, 0)']
                                ]
                            },
                            shadow: {
                                color: 'rgba(240, 240, 247, 1)',
                                offsetX: -1,
                                offsetY: -2,
                                width: 6
                            }
                        },
                        {
                            value: callEndTime ? new XDate(callEndTime).getTime() : null,
                            color: eventPriceColor,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    x2: 0,
                                    y1: 0,
                                    y2: 1
                                },
                                stops: eventPriceStops
                            },
                            shadow: {
                                color: 'rgba(240, 240, 247, 1)',
                                offsetX: -1,
                                offsetY: -2,
                                width: 6
                            }
                        },
                        {
                            value: new XDate().getTime(),
                            color: theme.colors.gray04,
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    x2: 0,
                                    y1: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, 'rgba(64, 67, 72, 0.07)'],
                                    [0.9, 'rgba(64, 67, 72, 0)']
                                ]
                            },
                            shadow: {
                                color: 'rgba(240, 240, 247, 1)',
                                offsetX: -1,
                                offsetY: -2,
                                width: 6
                            }
                        }
                    ]
                },
                series: {
                    turboThreshold: 3000,
                    dataGrouping: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click() {
                                if (onTimeSelect) {
                                    onTimeSelect(new XDate(this.x));
                                } else {
                                    toggleModal();
                                }
                            }
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'area',
                    name: 'Price',
                    marker: { enabled: false },
                    data: priceData
                },
                {
                    enableMouseTracking: false,
                    type: 'column',
                    name: 'Volume',
                    color: 'rgba(0, 122, 255, 0.5)',
                    marker: { enabled: false },
                    data: volumeData,
                    grouping: true,
                    maxPointWidth: 3,
                    minPointWidth: 3,
                    groupPadding: 0.2,
                    pointPadding: 0,
                    yAxis: 1
                }
            ]
        };
    }

    renderEquityHeader() {
        const {
            endPrice,
            equity,
            equityHref,
            lastClose,
            openChartModal,
            realtimePrices,
            renderChartHeader,
            startPrice
        } = this.props;

        if (!renderChartHeader) {
            return null;
        }

        const name = get(equity, 'commonName', 'Company');
        const ticker = get(equity, 'localTicker', 'ticker');
        const exchange = get(equity, 'exchange.shortName', 'exchange');
        const currency = get(equity, 'currency');

        const realtime = realtimePrices && realtimePrices.length > 0 ? realtimePrices[realtimePrices.length - 1] : null;
        let latestPrice = null;

        if (endPrice) {
            latestPrice = endPrice;
        } else if (realtime) {
            latestPrice = realtime.price;
        }

        let sinceCloseChange;
        let sinceCloseChangePercent;
        if (latestPrice && lastClose) {
            sinceCloseChange = latestPrice - lastClose;
            sinceCloseChangePercent = (sinceCloseChange / lastClose) * 100;
        }

        let callChange;
        let callChangePercent;
        if (latestPrice && startPrice) {
            callChange = latestPrice - startPrice;
            callChangePercent = (callChange / startPrice) * 100;
        }

        return renderChartHeader({
            currency,
            sinceCloseChange,
            sinceCloseChangePercent,
            equityHref,
            callChange,
            callChangePercent,
            exchange,
            helpText,
            latestPrice,
            name,
            openChartModal,
            realtimePrices,
            ticker
        });
    }

    render() {
        const {
            chartRef,
            closeChartModal,
            eventType,
            title,
            isChartModalOpen,
            realtimePrices,
            showChart,
            styles,
            passedStyles
        } = this.props;

        return (
            <Div styles={[styles.container, passedStyles]} className="print-hide">
                {this.renderEquityHeader()}
                {eventType !== 'custom' && showChart && realtimePrices && realtimePrices.length > 0 ? (
                    <Fragment>
                        <Div styles={styles.relativeContainer}>
                            <Div style={styles.absoluteContainer}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType="stockChart"
                                    options={this.chartOptions()}
                                    ref={chartRef}
                                />
                            </Div>
                        </Div>
                        <Modal
                            isOpen={isChartModalOpen}
                            onModalClose={closeChartModal}
                            styles={styles.chartModal}
                            title={title ? `${title} Price Movement` : 'Price Movement'}
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType="stockChart"
                                options={this.chartOptions(true)}
                            />
                        </Modal>
                    </Fragment>
                ) : null}
            </Div>
        );
    }
}

export const EventPriceChartUI = compose(withStyleSheet(styleSheet))(EventPriceChart);
