import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class EventAlertToggle extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleAlert: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { enabled, passedStyles, styles, theme, toggleAlert } = this.props;
        return (
            <Hint
                name="event-alert-toggle"
                onClick={toggleAlert}
                styles={{ ...(enabled ? styles.containerEnabled : styles.container), ...passedStyles }}
                text={enabled ? 'Disable Email Alert' : 'Email When Recording Starts'}
                xOffset={enabled ? -44 : -60}
            >
                <Icon
                    type={enabled ? 'bellRinging' : 'bellDisabled'}
                    color={enabled ? theme.colors.blue08 : theme.colors.gray04}
                />
            </Hint>
        );
    }
}

export const EventAlertToggleUI = compose(withStyleSheet(styleSheet))(EventAlertToggle);
