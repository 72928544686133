export const styleSheet = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 2
    },
    content: {
        display: 'flex',
        flexDirection: 'column'
    },
    line01: {
        color: theme.colors.blue15,
        fontSize: theme.fonts.fontSizes.size00,
        display: 'flex',
        alignSelf: 'flex-start',
        alignItems: 'center',
        marginBottom: 6,
        letterSpacing: 1
    },
    line01b: {
        opacity: 0.5,
        fontWeight: 400,
        marginLeft: 2
    },
    line02: {
        lineHeight: '1.2em',
        fontWeight: theme.fonts.fontWeights.medium,
        fontSize: theme.fonts.fontSizes.size03,
        textWrap: 'balance'
    },
    line02Truncated: {
        fontWeight: theme.fonts.fontWeights.medium,
        fontSize: theme.fonts.fontSizes.size03,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-all'
    },
    line03: {
        marginTop: '3px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineHeight: '1.2em',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    line04: {
        color: theme.colors.gray04,
        paddingTop: '4px'
    },
    iconElement: {
        flexShrink: 0,
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: theme.margins.margin00,
        marginTop: 4,
        p: {
            paddingLeft: 1,
            color: theme.colors.gray04,
            letterSpacing: 1
        },
        img: {
            minHeight: '65%',
            maxHeight: '100%'
        }
    }
});
