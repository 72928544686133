/* eslint-disable no-console */
import EventEmitter from 'events';

export class AieraMobileBridge extends EventEmitter {
    // This tells the mobile app that we're loaded and can
    // start receiving events.
    ready() {
        this.emit('ready');
    }

    enabled() {
        return !!window.ReactNativeWebView;
    }

    // Instead of a local EventEmitter, this emit() call emits the
    // event to the mobile app using postMessage.
    emit(name, ...args) {
        if (this.enabled()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ name, args, ns: 'aieraMobile' }));
        }
    }

    // After receiving an event from the mobile app, emit the event
    // locally to listeners.
    localEmit(name, args) {
        super.emit(name, ...args);
    }

    // Handle any postMessage events on the window, check to see if
    // they came from the mobile app, and if so emit the event.
    // Uses `ns: 'aieraMobile'` to filter out messages that don't
    // come from the mobile app.
    handler = event => {
        let name;
        let args;
        let ns;
        try {
            // If the message didn't come from mobile, it's probably not
            // a JSON string so we catch and we'll skip emitting anything.
            ({ name, args, ns } = JSON.parse(event.data));
        } catch {} // eslint-disable-line no-empty

        if (ns === 'aieraMobile') {
            this.localEmit(name, args);
        }
    };

    captureLogs() {
        // Debugging the web code from the mobile app can be difficult,
        // so use this to send all logging statements over the bridge
        // so that they can be logged in the mobile app for easier debugging.
        ['log', 'info', 'warn', 'error'].forEach(type => {
            const original = console[type];
            console[type] = (...args) => {
                original(...args);
                this.emit('log', type, ...args);
            };
        });
    }
}
