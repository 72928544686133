export const styleSheet = theme => ({
    actionBlock: {
        marginTop: theme.margins.margin01,
        [`@media(max-width:${theme.breakpoints.internal.mobile})`]: {
            display: 'none'
        }
    },
    container: {
        backgroundColor: theme.colors.white01,
        zIndex: 8,
        position: 'relative',
        borderBottom: `2px solid ${theme.colors.gray03}`
    },
    containerMinimal: {
        backgroundColor: theme.colors.white01,
        zIndex: 8,
        position: 'relative',
        borderBottom: `1px solid ${theme.colors.gray01}`
    },
    buttonsContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.margins.margin00,
        [`@media(max-width:${theme.breakpoints.internal.mobileEdge})`]: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: 0
        }
    },
    buttonDisconnect: {
        border: `1px solid ${theme.colors.red01}`,
        borderRadius: 6,
        boxShadow: 'unset',
        color: theme.colors.red01,
        display: 'inline-flex',
        height: 30,
        marginRight: theme.margins.margin00,
        ':active': {
            backgroundColor: theme.colors.red09,
            color: theme.colors.white01
        },
        ':hover': {
            backgroundColor: theme.colors.red01,
            color: theme.colors.white01
        },
        [`@media(max-width:${theme.breakpoints.internal.mobileEdge})`]: {
            marginBottom: theme.margins.margin01,
            marginRight: 0
        }
    },
    buttonEdit: {
        border: `1px solid ${theme.colors.orange02}`,
        borderRadius: 6,
        boxShadow: 'unset',
        color: theme.colors.orange02,
        display: 'inline-flex',
        height: 30,
        ':active': {
            backgroundColor: theme.colors.orange05,
            color: theme.colors.white01
        },
        ':hover': {
            backgroundColor: theme.colors.orange02,
            color: theme.colors.white01
        }
    },
    header: {
        backgroundColor: theme.colors.white01,
        zIndex: 6,
        position: 'relative',
        padding: '16px 20px 0 20px',
        textTransform: 'capitalize',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexShrink: 0,
        justifyContent: 'space-between',
        marginBottom: theme.margins.margin01
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        [`@media(max-width:${theme.breakpoints.internal.mobileEdge})`]: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    eventTitle: {
        lineHeight: '1.2em',
        textTransform: 'capitalize',
        marginBottom: '4px',
        textWrap: 'balance'
    },
    name: {
        marginBottom: '2px'
    },
    eventStatusLive: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: theme.colors.orange01,
        letterSpacing: '1px'
    },
    eventStatus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: theme.colors.gray04,
        letterSpacing: '1px'
    },
    liveDot: {
        height: '8px',
        width: '8px',
        borderRadius: '4px',
        marginRight: '8px',
        backgroundColor: theme.colors.orange01
    },
    headingCallDate: {
        color: theme.colors.gray06,
        marginLeft: theme.margins.margin00
    },
    loadHeader: {
        padding: `18px ${theme.margins.margin01}`,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'flex-start'
    },
    loadName: {
        height: '15px',
        width: '100px',
        backgroundColor: theme.colors.gray03,
        borderRadius: '6px',
        ...theme.content.flickerAnimation
    },
    loadEventTitle: {
        height: '25px',
        margin: '0 0 8px',
        width: '250px',
        backgroundColor: theme.colors.gray03,
        borderRadius: '6px',
        ...theme.content.flickerAnimation
    },
    printer: {
        position: 'absolute',
        right: 10,
        top: 8,
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    recordingControls: {
        borderLeft: `1px solid ${theme.colors.gray01}`,
        height: '100%',
        marginLeft: 'auto',
        padding: 15,
        [`@media(max-width:${theme.breakpoints.internal.mobileEdge})`]: {
            alignSelf: 'flex-start',
            border: 0,
            height: 'auto',
            marginLeft: 0,
            padding: '0 20px'
        }
    },
    recordingControlsHeader: {
        color: theme.colors.gray06,
        letterSpacing: '2px'
    },
    scriptsAsia: {
        margin: `0 ${theme.margins.margin01} ${theme.margins.margin01}`,
        height: 30
    }
});
