import React, { PureComponent } from 'react';
import { compose } from 'recompose';

export class EmptyEventsSVG extends PureComponent {
    static displayName = 'EmptyEventsSVGContainer';

    render() {
        return (
            <svg width="450px" height="409px" viewBox="0 0 450 409">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="aeira-01" fillRule="nonzero">
                        <g id="Group" transform="translate(51.000000, 40.000000)">
                            <g opacity="0.11" transform="translate(20.000000, 69.000000)" fill="#C2C5CE" id="Path">
                                <g>
                                    <path d="M240.8,196.8 C246.2,182.6 258,171 272.4,166 C278.5,163.9 284.9,162.8 290.9,160.5 C296.9,158.1 302.6,154 304.7,147.9 C308.1,137.8 300.4,127.3 291.7,121.2 C283,115.1 272.6,110.8 266.5,102.1 C258.1,90.2 260.1,74 255.1,60.3 C250,46.3 236.8,35.5 222.1,33.3 C214.5,32.1 206.7,33.1 199,32.9 C174.1,32.2 151.2,19.5 130.9,5.2 C126.6,2.2 121.4,-1.1 116.5,0.8 C110,3.3 109.9,12.3 106.8,18.6 C102.5,27.4 91.9,31 82.3,32.2 C72.6,33.4 62.4,33.2 53.8,37.9 C50.8,39.5 47.9,41.9 47.1,45.3 C46.1,49.3 48.5,53.4 49,57.5 C50.1,67.2 40.7,74.9 31.4,78.1 C22.1,81.3 11.9,82.1 3.9,87.7 C2.5,88.7 1,90 0.8,91.8 C0.6,93.8 1.9,95.5 3.3,97 C11.2,105.8 20.1,113.6 29.8,120.3 C33.6,122.9 37.8,125.6 39.6,129.9 C41.6,134.6 40.1,140 38.7,144.9 C35.2,156.9 31.6,168.9 28.1,180.9 L43.3,197.6 L240.8,196.8 Z" />
                                </g>
                            </g>
                            <g transform="translate(102.000000, 62.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="32.2" cy="31.1" r="30" />
                                <g opacity="0.13" transform="translate(26.000000, 1.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M5.8,0.1 C3.9,0.1 2.1,0.3 0.3,0.6 C14.2,3.2 24.8,15.4 24.8,30.1 C24.8,44.8 14.2,57 0.3,59.6 C2.1,59.9 3.9,60.1 5.8,60.1 C22.4,60.1 35.8,46.7 35.8,30.1 C35.8,13.5 22.3,0.1 5.8,0.1 Z" />
                                    </g>
                                </g>
                                <path
                                    d="M31.8,62.1 C14.7,62.1 0.8,48.2 0.8,31.1 C0.8,14 14.7,0.1 31.8,0.1 C48.9,0.1 62.8,14 62.8,31.1 C62.8,48.2 48.8,62.1 31.8,62.1 Z M31.8,2.1 C15.8,2.1 2.8,15.1 2.8,31.1 C2.8,47.1 15.8,60.1 31.8,60.1 C47.8,60.1 60.8,47.1 60.8,31.1 C60.8,15.1 47.7,2.1 31.8,2.1 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g opacity="0.51" transform="translate(43.000000, 217.000000)" fill="#C2C5CE" id="Path">
                                <g>
                                    <path d="M15,43 C15,43 -1.4,27.5 1,24 C1.5,23.2 5,16 12,21 C12,21 17,14.8 22,19 C27,23.2 37,20 37,20 L49,20 C49,20 63.8,20.8 64,20 C64.2,19.2 70.8,7 78,9 C78,9 79.8,-3.2 89,1 C89,1 99.8,-2.2 99,6 C99,6 108,3.2 107,11 C107,11 112.8,11.5 113,16 C113,16 113.8,12.5 119,13 C119,13 119.2,4 125,4 C130.8,4 131,9 131,9 C131,9 135.8,5 140,8 C144.2,11 142,17 142,17 C142,17 145,17 145,19 L147,17 C147,17 147.8,10.8 153,11 C153,11 156.8,11.2 157,15 C157,15 159.8,12 163,14 C166.2,16 165,20 165,20 C165,20 169.5,22.5 168,26 C166.5,29.5 182,30 182,30 C182,30 184.8,10.8 196,10 C207.2,9.2 219,12 219,12 L240,15 L244,19 L237,32 L148,40 L15,43 Z" />
                                </g>
                            </g>
                            <path
                                d="M63.1,266.4 L264.7,266 C264.7,266 277.3,252.1 280.1,247.9 C280.1,247.9 250.4,235 227.7,238.2 C227.7,238.2 213.6,247.1 198.5,244.4 C183.4,241.7 150,232.2 138.3,244.6 C138.3,244.6 78.9,234.4 57.7,260.4 L63.1,266.4 Z"
                                id="Path"
                                fill="#FFFFFF"
                            />
                            <g opacity="0.13" transform="translate(57.000000, 237.000000)" fill="#C2C5CE" id="Path">
                                <g>
                                    <path d="M6.1,29.4 L207.7,29 C207.7,29 220.3,15.1 223.1,10.9 C223.1,10.9 193.4,-2 170.7,1.2 C170.7,1.2 156.6,10.1 141.5,7.4 C126.4,4.7 93,-4.8 81.3,7.6 C81.3,7.6 21.9,-2.6 0.7,23.4 L6.1,29.4 Z" />
                                </g>
                            </g>
                            <g opacity="0.51" transform="translate(44.000000, 217.000000)" fill="#C2C5CE" id="Path">
                                <g transform="translate(78.000000, 0.000000)">
                                    <path d="M0,9 C0,9 13.5,5 13,12 C13,12 26.2,11 24,22 L31,21 L34,15 C34,15 31.5,11 28,12 C28,12 28.2,4.2 20,5 C20,5 19.5,-1.8 12,1 C12,1 0,-3.8 0,9 Z" />
                                </g>
                                <g transform="translate(131.000000, 7.000000)">
                                    <path d="M0,4 C0,4 5.5,10.8 1,16 L13,19 C13,19 15.8,13.5 10,11 C10,11 12,3.5 8,1 C4,-1.5 0.2,2.5 0,4 Z" />
                                </g>
                                <g transform="translate(156.000000, 12.000000)">
                                    <path d="M0,3 C0,3 5.2,9 3,14 L9,17 L10,15 C10,15 12,10.8 8,9 C8,9 9.8,1.8 5,1 C0.2,0.2 0,3 0,3 Z" />
                                </g>
                                <g transform="translate(0.000000, 18.000000)">
                                    <path d="M12,4 C12,4 8.8,15.2 15,22 L14,26 C14,26 2.2,12.5 0,6 C0,6 4.2,-4.5 12,4 Z" />
                                </g>
                            </g>
                            <g transform="translate(65.000000, 136.000000)" id="Path">
                                <g transform="translate(2.000000, 1.000000)">
                                    <path
                                        d="M18.8,5.3 C17.5,5.3 16.4,5.7 15.4,6.5 C14.5,3.1 11.5,0.7 7.9,0.7 C3.6,0.7 0.1,4.2 0.1,8.5 C0.1,12.8 3.6,16.3 7.9,16.3 L18.8,16.3 C21.8,16.3 24.3,13.9 24.3,10.8 C24.2,7.7 21.8,5.3 18.8,5.3 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(5.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M10,5.4 C8.8,2.6 6.1,0.6 2.8,0.6 C2,0.6 1.2,0.7 0.5,1 C3.1,1.8 5,3.9 5.7,6.5 C6.6,5.8 7.8,5.3 9.1,5.3 C9.4,5.3 9.7,5.3 10,5.4 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(9.000000, 5.000000)">
                                            <g>
                                                <path d="M4.8,0.3 C4,0.3 3.2,0.5 2.5,0.8 C4.3,1.7 5.6,3.5 5.6,5.7 C5.6,8.7 3.2,11.2 0.1,11.2 L4.8,11.2 C7.8,11.2 10.3,8.8 10.3,5.7 C10.2,2.7 7.8,0.3 4.8,0.3 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M20.8,18.2 L9.9,18.2 C9.4,18.2 8.8,18.2 8.3,18.1 C7.8,18 7.4,17.5 7.5,16.9 C7.6,16.4 8.1,16 8.7,16.1 C9.1,16.2 9.5,16.2 9.9,16.2 L20.8,16.2 C23.3,16.2 25.3,14.2 25.3,11.7 C25.3,9.3 23.3,7.3 20.8,7.3 C19.8,7.3 18.8,7.6 18,8.3 L16.8,9.2 L16.4,7.7 C15.6,4.7 12.9,2.6 9.8,2.6 C6.1,2.6 3,5.7 3,9.4 C3,11.4 3.9,13.3 5.4,14.6 C5.8,15 5.9,15.6 5.5,16 C5.2,16.4 4.5,16.5 4.1,16.1 C2.1,14.4 0.9,12 0.9,9.3 C0.9,4.5 4.8,0.5 9.7,0.5 C13.2,0.5 16.4,2.6 17.8,5.8 C18.7,5.4 19.6,5.1 20.6,5.1 C24.2,5.1 27.1,8 27.1,11.5 C27.2,15.3 24.3,18.2 20.8,18.2 Z" />
                                    </g>
                                </g>
                            </g>
                            <path
                                d="M286.6,267.1 L50.5,267.1 C49.9,267.1 49.5,266.7 49.5,266.1 C49.5,265.5 49.9,265.1 50.5,265.1 L286.6,265.1 C287.2,265.1 287.6,265.5 287.6,266.1 C287.6,266.7 287.2,267.1 286.6,267.1 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(150.000000, 98.000000)">
                                <g transform="translate(1.000000, 1.000000)" id="Path">
                                    <path
                                        d="M26,6.7 C24.3,6.7 22.7,7.3 21.4,8.3 C20.2,3.7 16.1,0.4 11.2,0.4 C5.4,0.4 0.6,5.1 0.6,11 C0.6,16.8 5.3,21.6 11.2,21.6 L26,21.6 C30.1,21.6 33.4,18.3 33.4,14.2 C33.4,10.1 30.1,6.7 26,6.7 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(8.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M12.9,6.9 C11.3,3.1 7.5,0.4 3.2,0.4 C2.1,0.4 1,0.6 0,0.9 C3.5,2 6.2,4.8 7.1,8.4 C8.4,7.4 9.9,6.8 11.7,6.8 C12.1,6.7 12.5,6.8 12.9,6.9 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(11.000000, 6.000000)">
                                            <g>
                                                <path d="M7,0.7 C5.9,0.7 4.8,1 3.8,1.4 C6.3,2.6 8,5.1 8,8.1 C8,12.2 4.7,15.5 0.6,15.5 L7,15.5 C11.1,15.5 14.4,12.2 14.4,8.1 C14.4,4.1 11.1,0.7 7,0.7 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE" id="Shape">
                                    <g>
                                        <path d="M27,23.6 L12.2,23.6 C9.9,23.6 7.7,22.9 5.8,21.7 C3,19.8 1.1,16.9 0.7,13.6 L0.7,13.5 L0.7,13.5 C0.7,13.3 0.8,13 0.9,12.8 C1.1,12.6 1.3,12.4 1.6,12.4 C2.1,12.3 2.6,12.7 2.7,13.3 C3.1,16 4.6,18.5 6.9,20 C6.9,20 6.9,20 6.9,20 C8.5,21 10.3,21.6 12.1,21.6 L27,21.6 C30.5,21.6 33.4,18.7 33.4,15.2 C33.4,15.1 33.4,15 33.4,14.9 C33.4,14.6 33.5,14.4 33.7,14.2 C33.9,14 34.1,13.9 34.4,13.9 C34.9,13.9 35.4,14.3 35.5,14.9 C35.5,15 35.5,15.2 35.5,15.3 C35.4,19.8 31.6,23.6 27,23.6 Z M33.2,12.1 C32.9,12.1 32.5,11.9 32.4,11.6 C31.2,9.8 29.2,8.7 27.1,8.7 C25.6,8.7 24.3,9.2 23.1,10.1 L21.9,11.1 L21.5,9.6 C20.4,5.4 16.6,2.4 12.2,2.4 C7.8,2.4 3.9,5.4 2.9,9.7 C2.8,10.2 2.2,10.6 1.7,10.4 C1.4,10.3 1.2,10.2 1.1,10 C1,9.8 0.9,9.5 1,9.2 C2.3,4 6.9,0.4 12.3,0.4 C14.7,0.4 17,1.1 18.9,2.5 C20.8,3.8 22.2,5.6 23.1,7.8 C24.3,7.1 25.7,6.8 27.1,6.8 C29.9,6.8 32.5,8.2 34.1,10.5 L34.1,10.5 C34.4,11 34.3,11.6 33.8,11.9 C33.6,12 33.4,12.1 33.2,12.1 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(208.000000, 69.000000)">
                                <path
                                    d="M6.3,180.8 C6.1,180.8 5.9,180.7 5.8,180.6 C5.3,180.3 5.2,179.7 5.5,179.2 C50.2,110.4 59.7,38.1 59.8,37.4 C59.9,36.9 60.4,36.5 60.9,36.5 C61.4,36.6 61.8,37.1 61.8,37.6 C61.7,38.3 52.2,111 7.2,180.2 C6.9,180.6 6.6,180.8 6.3,180.8 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M130.4,78.5 C130.1,78.5 129.8,78.4 129.6,78.1 C103.8,45.9 63.7,50.9 63.3,50.9 C62.8,51 62.2,50.6 62.2,50 C62.1,49.5 62.5,48.9 63.1,48.9 C63.5,48.8 104.7,43.7 131.2,76.8 C131.5,77.2 131.5,77.9 131,78.2 C130.9,78.5 130.7,78.5 130.4,78.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M101.8,94.5 C101.4,94.5 101,94.2 100.9,93.8 C95,76.2 84.7,67.6 77,63.4 C68.7,58.9 61.6,58.5 61.6,58.4 C61,58.4 60.6,57.9 60.7,57.4 C60.7,56.8 61.2,56.4 61.7,56.4 C62,56.4 91.2,58.2 102.8,93.2 C103,93.7 102.7,94.3 102.2,94.5 C102,94.5 101.9,94.5 101.8,94.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M54.7,46.1 C54.4,46.1 54,45.9 53.9,45.6 C53.7,45.2 29.3,7.4 0.9,2.5 C0.4,2.4 0,1.9 0.1,1.3 C0.2,0.7 0.7,0.4 1.3,0.5 C16.6,3.1 30.6,14.9 39.7,24.4 C49.4,34.6 55.7,44.4 55.7,44.4 C56,44.9 55.9,45.5 55.4,45.8 C55.1,46 54.9,46.1 54.7,46.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M51.1,52.4 C50.9,52.4 50.6,52.3 50.5,52.2 C50.3,52 31.9,36.9 10.1,43.9 C9.6,44.1 9,43.8 8.8,43.2 C8.6,42.7 8.9,42.1 9.5,41.9 C32.3,34.7 51.6,50.5 51.7,50.6 C52.1,51 52.2,51.6 51.8,52 C51.7,52.3 51.4,52.4 51.1,52.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M50.3,46.9 C50.2,46.9 50,46.9 49.9,46.8 C49.7,46.6 49.7,46.3 49.9,46.1 L52.9,43.1 C53.1,42.9 53.4,42.9 53.6,43.1 C53.8,43.3 53.8,43.6 53.6,43.8 L50.6,46.8 C50.6,46.9 50.5,46.9 50.3,46.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M47.7,45.4 C47.6,45.4 47.5,45.4 47.4,45.3 C47.2,45.1 47.1,44.8 47.3,44.6 L50.7,40.2 C50.9,40 51.2,39.9 51.4,40.1 C51.6,40.3 51.7,40.6 51.5,40.8 L48.1,45.2 C48,45.3 47.9,45.4 47.7,45.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M43.5,43 C43.4,43 43.3,43 43.2,42.9 C43,42.7 42.9,42.4 43.1,42.2 L47.7,36 C47.9,35.8 48.2,35.7 48.4,35.9 C48.6,36.1 48.7,36.4 48.5,36.6 L43.9,42.8 C43.9,42.9 43.7,43 43.5,43 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M39,40.2 C38.9,40.2 38.8,40.2 38.7,40.1 C38.5,39.9 38.4,39.6 38.6,39.4 L44.4,31.8 C44.6,31.6 44.9,31.5 45.1,31.7 C45.3,31.9 45.4,32.2 45.2,32.4 L39.4,40 C39.3,40.1 39.1,40.2 39,40.2 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M34.4,38.2 C34.3,38.2 34.2,38.2 34.1,38.1 C33.9,37.9 33.8,37.6 34,37.4 L40.8,27.8 C41,27.6 41.3,27.5 41.5,27.7 C41.7,27.9 41.8,28.2 41.6,28.4 L34.8,38 C34.7,38.1 34.5,38.2 34.4,38.2 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M29.4,36.4 C29.3,36.4 29.2,36.4 29.1,36.3 C28.9,36.1 28.8,35.8 29,35.6 L37.2,23.8 C37.4,23.6 37.7,23.5 37.9,23.7 C38.1,23.9 38.2,24.2 38,24.4 L29.8,36.2 C29.7,36.3 29.5,36.4 29.4,36.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M24.6,35.4 C24.5,35.4 24.4,35.4 24.3,35.3 C24.1,35.1 24,34.8 24.2,34.6 L33.6,20.6 C33.8,20.4 34.1,20.3 34.3,20.5 C34.5,20.7 34.6,21 34.4,21.2 L25,35.2 C24.9,35.3 24.7,35.4 24.6,35.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M19.8,34.8 C19.7,34.8 19.6,34.8 19.5,34.7 C19.3,34.5 19.2,34.2 19.4,34 L30.4,17.6 C30.6,17.4 30.9,17.3 31.1,17.5 C31.3,17.7 31.4,18 31.2,18.2 L20.2,34.6 C20.1,34.7 20,34.8 19.8,34.8 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M18.4,30.4 C18.3,30.4 18.2,30.4 18.1,30.3 C17.9,30.1 17.8,29.8 18,29.6 L27.6,15.2 C27.8,15 28.1,14.9 28.3,15.1 C28.5,15.3 28.6,15.6 28.4,15.8 L18.8,30.2 C18.7,30.3 18.6,30.4 18.4,30.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M16.6,24.8 C16.5,24.8 16.4,24.8 16.3,24.7 C16.1,24.5 16,24.2 16.2,24 L24.6,12.4 C24.8,12.2 25.1,12.1 25.3,12.3 C25.5,12.5 25.6,12.8 25.4,13 L17,24.6 C16.9,24.7 16.8,24.8 16.6,24.8 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M14.8,20.4 C14.7,20.4 14.6,20.4 14.5,20.3 C14.3,20.1 14.2,19.8 14.4,19.6 L20.8,10 C21,9.8 21.3,9.7 21.5,9.9 C21.7,10.1 21.8,10.4 21.6,10.6 L15.2,20.2 C15.1,20.3 15,20.4 14.8,20.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M11.6,16 C11.5,16 11.4,16 11.3,15.9 C11.1,15.8 11,15.5 11.1,15.2 L15.9,7 C16,6.8 16.3,6.7 16.6,6.8 C16.8,6.9 16.9,7.2 16.8,7.5 L12,15.7 C11.9,15.9 11.8,16 11.6,16 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M8.4,12 C8.3,12 8.2,12 8.2,11.9 C8,11.8 7.9,11.5 8,11.2 L11.4,4.4 C11.5,4.2 11.8,4.1 12.1,4.2 C12.3,4.3 12.4,4.6 12.3,4.9 L8.9,11.7 C8.8,11.9 8.6,12 8.4,12 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M6.2,7.8 C6.1,7.8 6,7.8 5.9,7.7 C5.7,7.6 5.6,7.3 5.7,7 L7.9,3.2 C8,3 8.3,2.9 8.6,3 C8.8,3.1 8.9,3.4 8.8,3.7 L6.6,7.5 C6.6,7.7 6.4,7.8 6.2,7.8 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M2.6,6.2 C2.5,6.2 2.5,6.2 2.4,6.2 C2.1,6.1 2,5.8 2.1,5.6 L3.3,2.6 C3.4,2.3 3.7,2.2 3.9,2.3 C4.2,2.4 4.3,2.7 4.2,2.9 L3,5.9 C3,6.1 2.8,6.2 2.6,6.2 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M47.1,55.1 C47,55.1 46.9,55.1 46.8,55 C46.6,54.8 46.5,54.5 46.7,54.3 L49.1,50.7 C49.3,50.5 49.6,50.4 49.8,50.6 C50,50.8 50.1,51.1 49.9,51.3 L47.5,54.9 C47.5,55.1 47.3,55.1 47.1,55.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M43.3,55.3 C43.2,55.3 43.1,55.3 43,55.2 C42.8,55.1 42.7,54.8 42.8,54.5 L46.4,48.5 C46.5,48.3 46.9,48.2 47.1,48.3 C47.3,48.4 47.4,48.7 47.3,49 L43.7,55 C43.7,55.2 43.5,55.3 43.3,55.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M39.4,55.1 C39.3,55.1 39.3,55.1 39.2,55.1 C38.9,55 38.8,54.7 38.9,54.4 L42.3,46.2 C42.4,45.9 42.7,45.8 43,45.9 C43.3,46 43.4,46.3 43.3,46.6 L39.9,54.8 C39.7,55 39.6,55.1 39.4,55.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M34.8,55.3 C34.7,55.3 34.7,55.3 34.6,55.3 C34.3,55.2 34.2,54.9 34.3,54.7 L38.1,44.3 C38.2,44 38.5,43.9 38.7,44 C39,44.1 39.1,44.4 39,44.6 L35.2,55 C35.2,55.2 35,55.3 34.8,55.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M30.2,54.7 C30.2,54.7 30.1,54.7 30.1,54.7 C29.8,54.6 29.7,54.4 29.7,54.1 L32.7,42.5 C32.8,42.2 33,42.1 33.3,42.1 C33.6,42.2 33.7,42.4 33.7,42.7 L30.7,54.3 C30.6,54.6 30.4,54.7 30.2,54.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M25.4,54.5 C25.4,54.5 25.3,54.5 25.3,54.5 C25,54.4 24.9,54.2 24.9,53.9 L27.7,41.3 C27.8,41 28,40.9 28.3,40.9 C28.6,41 28.7,41.2 28.7,41.5 L25.9,54.1 C25.8,54.4 25.6,54.5 25.4,54.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M20.6,53.9 C20.6,53.9 20.5,53.9 20.5,53.9 C20.2,53.8 20.1,53.6 20.1,53.3 L22.9,40.9 C23,40.6 23.2,40.5 23.5,40.5 C23.8,40.6 23.9,40.8 23.9,41.1 L21.1,53.5 C21,53.8 20.8,53.9 20.6,53.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M17.6,47.9 C17.6,47.9 17.5,47.9 17.6,47.9 C17.2,47.8 17.1,47.6 17.1,47.3 L18.3,41.1 C18.4,40.8 18.6,40.6 18.9,40.7 C19.2,40.8 19.3,41 19.3,41.3 L18.1,47.5 C18,47.8 17.8,47.9 17.6,47.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M13.8,45.5 C13.8,45.5 13.7,45.5 13.8,45.5 C13.4,45.4 13.3,45.2 13.3,44.9 L13.9,41.7 C14,41.4 14.2,41.2 14.5,41.3 C14.8,41.4 15,41.6 14.9,41.9 L14.3,45.1 C14.3,45.4 14,45.5 13.8,45.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M61.3,63.1 C61.3,63.1 61.2,63.1 61.1,63.1 C60.8,63 60.7,62.7 60.8,62.5 L62.2,58.1 C62.3,57.8 62.6,57.7 62.8,57.8 C63.1,57.9 63.2,58.2 63.1,58.4 L61.7,62.8 C61.7,63 61.5,63.1 61.3,63.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M64.3,65.5 C64.2,65.5 64.2,65.5 64.1,65.5 C63.8,65.4 63.7,65.1 63.8,64.9 L66.2,58.3 C66.3,58 66.6,57.9 66.8,58 C67.1,58.1 67.2,58.4 67.1,58.6 L64.7,65.2 C64.7,65.4 64.5,65.5 64.3,65.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M68.1,67.9 C68,67.9 68,67.9 67.9,67.9 C67.6,67.8 67.5,67.5 67.6,67.3 L70.4,59.7 C70.5,59.4 70.8,59.3 71,59.4 C71.3,59.5 71.4,59.8 71.3,60 L68.5,67.6 C68.5,67.8 68.3,67.9 68.1,67.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M71.5,70.5 C71.4,70.5 71.4,70.5 71.3,70.5 C71,70.4 70.9,70.1 71,69.8 L74.4,61.6 C74.5,61.3 74.8,61.2 75.1,61.3 C75.4,61.4 75.5,61.7 75.4,62 L72,70.2 C71.9,70.4 71.7,70.5 71.5,70.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M73.9,73.3 C73.8,73.3 73.7,73.3 73.7,73.2 C73.5,73.1 73.4,72.8 73.5,72.5 L78.7,63.5 C78.8,63.3 79.1,63.2 79.4,63.3 C79.6,63.4 79.7,63.7 79.6,64 L74.4,73 C74.2,73.2 74.1,73.3 73.9,73.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M77.9,75.3 C77.8,75.3 77.7,75.3 77.7,75.2 C77.5,75.1 77.4,74.8 77.5,74.5 L82.3,65.9 C82.4,65.7 82.7,65.6 83,65.7 C83.3,65.8 83.3,66.1 83.2,66.4 L78.4,75 C78.2,75.2 78.1,75.3 77.9,75.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M81.7,76.9 C81.6,76.9 81.5,76.9 81.4,76.8 C81.2,76.6 81.1,76.3 81.3,76.1 L85.9,69.1 C86.1,68.9 86.4,68.8 86.6,69 C86.8,69.2 86.9,69.5 86.7,69.7 L82.1,76.7 C82,76.8 81.8,76.9 81.7,76.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M85.5,78.1 C85.4,78.1 85.3,78.1 85.2,78 C85,77.8 84.9,77.5 85.1,77.3 L88.9,72.5 C89.1,72.3 89.4,72.2 89.6,72.4 C89.8,72.6 89.9,72.9 89.7,73.1 L85.9,77.9 C85.8,78 85.6,78.1 85.5,78.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M89.1,79.7 C89,79.7 88.8,79.6 88.7,79.5 C88.5,79.3 88.5,79 88.7,78.8 L91.9,75.8 C92.1,75.6 92.4,75.6 92.6,75.8 C92.8,76 92.8,76.3 92.6,76.5 L89.4,79.5 C89.3,79.6 89.2,79.7 89.1,79.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M92.2,82.3 C92.1,82.3 91.9,82.2 91.8,82.1 C91.6,81.9 91.6,81.6 91.8,81.4 L94.4,79 C94.6,78.8 94.9,78.8 95.1,79 C95.3,79.2 95.3,79.5 95.1,79.7 L92.5,82.1 C92.5,82.2 92.4,82.3 92.2,82.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M94.6,85.1 C94.4,85.1 94.3,85 94.2,84.9 C94,84.7 94.1,84.4 94.3,84.2 L96.7,82.4 C96.9,82.2 97.2,82.3 97.4,82.5 C97.6,82.7 97.5,83 97.3,83.2 L94.9,85 C94.9,85 94.7,85.1 94.6,85.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M96.2,87.9 C96,87.9 95.9,87.8 95.8,87.7 C95.6,87.5 95.7,87.2 95.9,87 L97.7,85.8 C97.9,85.6 98.2,85.7 98.4,85.9 C98.6,86.1 98.5,86.4 98.3,86.6 L96.5,87.8 C96.4,87.8 96.3,87.9 96.2,87.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M97.4,91.1 C97.2,91.1 97,91 97,90.8 C96.9,90.6 97,90.3 97.2,90.1 L99.2,89.1 C99.4,89 99.7,89.1 99.9,89.3 C100,89.5 99.9,89.8 99.7,90 L97.7,91 C97.6,91 97.5,91.1 97.4,91.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M64.9,55.3 C64.9,55.3 64.9,55.3 64.9,55.3 C64.6,55.3 64.4,55 64.4,54.7 L65,50.1 C65,49.8 65.3,49.6 65.6,49.7 C65.9,49.7 66.1,50 66,50.3 L65.4,54.9 C65.4,55.1 65.2,55.3 64.9,55.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M68.1,56.3 C68.1,56.3 68,56.3 68.1,56.3 C67.7,56.2 67.6,56 67.6,55.7 L68.8,49.7 C68.9,49.4 69.1,49.3 69.4,49.3 C69.7,49.4 69.8,49.6 69.8,49.9 L68.6,55.9 C68.5,56.2 68.3,56.3 68.1,56.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M71.7,57.1 C71.7,57.1 71.6,57.1 71.6,57.1 C71.3,57 71.2,56.8 71.2,56.5 L72.6,49.9 C72.7,49.6 72.9,49.5 73.2,49.5 C73.5,49.6 73.6,49.8 73.6,50.1 L72.2,56.7 C72.1,57 71.9,57.1 71.7,57.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M75.3,58.7 C75.3,58.7 75.2,58.7 75.2,58.7 C74.9,58.6 74.8,58.4 74.8,58.1 L76.8,50.3 C76.9,50 77.1,49.9 77.4,49.9 C77.7,50 77.8,50.2 77.8,50.5 L75.8,58.3 C75.7,58.6 75.5,58.7 75.3,58.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M79.5,60.5 C79.4,60.5 79.4,60.5 79.3,60.5 C79,60.4 78.9,60.1 79,59.9 L82,50.7 C82.1,50.4 82.4,50.3 82.6,50.4 C82.9,50.5 83,50.8 82.9,51 L79.9,60.2 C79.9,60.4 79.7,60.5 79.5,60.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M83.5,61.9 C83.4,61.9 83.4,61.9 83.3,61.9 C83,61.8 82.9,61.5 83.1,61.2 L87.5,51.6 C87.6,51.3 87.9,51.2 88.2,51.4 C88.5,51.5 88.6,51.8 88.4,52.1 L84,61.7 C83.8,61.8 83.7,61.9 83.5,61.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M88.1,64.1 C88,64.1 87.9,64.1 87.8,64 C87.6,63.9 87.5,63.6 87.6,63.3 L93.4,53.3 C93.5,53.1 93.8,53 94.1,53.1 C94.3,53.2 94.4,53.5 94.3,53.8 L88.5,63.8 C88.4,64 88.2,64.1 88.1,64.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M93.6,66.7 C93.5,66.7 93.5,66.7 93.4,66.7 C93.1,66.6 93,66.3 93.1,66 L97.9,55 C98,54.7 98.3,54.6 98.6,54.7 C98.9,54.8 99,55.1 98.9,55.4 L94.1,66.4 C94,66.6 93.8,66.7 93.6,66.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M97.8,70.1 C97.7,70.1 97.6,70.1 97.6,70 C97.4,69.9 97.3,69.6 97.4,69.3 L104.2,56.7 C104.3,56.5 104.6,56.4 104.9,56.5 C105.1,56.6 105.2,56.9 105.1,57.2 L98.3,69.8 C98.2,70 98,70.1 97.8,70.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M102.8,72.9 C102.7,72.9 102.6,72.9 102.6,72.8 C102.4,72.7 102.3,72.4 102.4,72.1 L109,60 C109.1,59.8 109.4,59.7 109.7,59.8 C109.9,59.9 110,60.2 109.9,60.5 L103.3,72.7 C103.2,72.8 103,72.9 102.8,72.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M107.8,73.3 C107.7,73.3 107.6,73.3 107.6,73.2 C107.4,73.1 107.3,72.8 107.4,72.5 L113.4,62.1 C113.5,61.9 113.8,61.8 114.1,61.9 C114.3,62 114.4,62.3 114.3,62.6 L108.3,73 C108.2,73.2 108,73.3 107.8,73.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M112.8,73.3 C112.7,73.3 112.6,73.3 112.5,73.2 C112.3,73.1 112.2,72.7 112.3,72.5 L116.9,65.3 C117,65.1 117.4,65 117.6,65.1 C117.8,65.2 117.9,65.6 117.8,65.8 L113.2,73 C113.1,73.2 113,73.3 112.8,73.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M117.4,74.7 C117.3,74.7 117.2,74.7 117.1,74.6 C116.9,74.4 116.8,74.1 117,73.9 L120.8,68.3 C121,68.1 121.3,68 121.5,68.2 C121.7,68.4 121.8,68.7 121.6,68.9 L117.8,74.5 C117.7,74.6 117.6,74.7 117.4,74.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M122,76.1 C121.9,76.1 121.8,76.1 121.7,76 C121.5,75.8 121.4,75.5 121.6,75.3 L124.4,71.3 C124.6,71.1 124.9,71 125.1,71.2 C125.3,71.4 125.4,71.7 125.2,71.9 L122.4,75.9 C122.3,76 122.1,76.1 122,76.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M125.4,78.1 C125.3,78.1 125.1,78.1 125,78 C124.8,77.8 124.8,77.5 125,77.3 L127.4,74.9 C127.6,74.7 127.9,74.7 128.1,74.9 C128.3,75.1 128.3,75.4 128.1,75.6 L125.7,78 C125.6,78 125.5,78.1 125.4,78.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <g transform="translate(51.000000, 59.000000)">
                                    <circle id="Oval" fill="#FFFFFF" cx="5.1" cy="5.6" r="4" />
                                    <path
                                        d="M5.1,2.6 C6.8,2.6 8.1,4 8.1,5.6 C8.1,7.3 6.7,8.6 5.1,8.6 C3.5,8.6 2.1,7.2 2.1,5.6 C2.1,3.9 3.4,2.6 5.1,2.6 L5.1,2.6 Z M5.1,0.6 C2.3,0.6 0.1,2.9 0.1,5.6 C0.1,8.3 2.4,10.6 5.1,10.6 C7.8,10.6 10.1,8.3 10.1,5.6 C10.1,2.9 7.9,0.6 5.1,0.6 L5.1,0.6 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                                <g transform="translate(45.000000, 54.000000)">
                                    <circle id="Oval" fill="#FFFFFF" cx="5" cy="5.4" r="4" />
                                    <path
                                        d="M5,2.3 C6.7,2.3 8,3.7 8,5.3 C8,7 6.6,8.3 5,8.3 C3.4,8.3 2,6.9 2,5.3 C2,3.7 3.4,2.3 5,2.3 L5,2.3 Z M5,0.3 C2.2,0.3 0,2.6 0,5.3 C0,8 2.3,10.3 5,10.3 C7.7,10.3 10,8 10,5.3 C10,2.6 7.8,0.3 5,0.3 L5,0.3 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                                <g transform="translate(43.000000, 63.000000)">
                                    <circle id="Oval" fill="#FFFFFF" cx="5.9" cy="5.7" r="4" />
                                    <path
                                        d="M5.9,2.7 C7.6,2.7 8.9,4.1 8.9,5.7 C8.9,7.3 7.5,8.7 5.9,8.7 C4.3,8.7 2.9,7.3 2.9,5.7 C2.9,4.1 4.3,2.7 5.9,2.7 L5.9,2.7 Z M5.9,0.7 C3.1,0.7 0.9,3 0.9,5.7 C0.9,8.4 3.2,10.7 5.9,10.7 C8.6,10.7 10.9,8.4 10.9,5.7 C10.9,3 8.7,0.7 5.9,0.7 L5.9,0.7 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                            </g>
                            <g transform="translate(1.000000, 56.000000)">
                                <path
                                    d="M109.1,6.2 C108.9,6.2 108.8,6.1 108.7,6 L107.3,3.6 C107.2,3.4 107.2,3.1 107.5,2.9 C107.7,2.8 108,2.8 108.2,3.1 L109.6,5.5 C109.7,5.7 109.7,6 109.4,6.2 C109.3,6.2 109.2,6.2 109.1,6.2 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M34.7,76.9 C34.6,76.9 34.5,76.9 34.4,76.8 L32.2,75.2 C32,75 31.9,74.7 32.1,74.5 C32.3,74.3 32.6,74.2 32.8,74.4 L35,76 C35.2,76.2 35.3,76.5 35.1,76.7 C35,76.8 34.8,76.9 34.7,76.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M33.9,62.3 C33.7,62.3 33.4,62.1 33.4,61.9 L31.6,49.7 C31.6,49.4 31.7,49.2 32,49.1 C32.3,49.1 32.5,49.2 32.6,49.5 L34.4,61.7 C34.4,62 34.2,62.2 33.9,62.3 C33.9,62.3 33.9,62.3 33.9,62.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M29.5,60.3 C29.3,60.3 29.1,60.1 29,59.9 L27.4,50.7 C27.4,50.4 27.5,50.2 27.8,50.1 C28.1,50.1 28.3,50.2 28.4,50.5 L30,59.7 C30,60 29.8,60.2 29.5,60.3 C29.5,60.3 29.5,60.3 29.5,60.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M25.5,60.7 C25.3,60.7 25.1,60.5 25,60.3 L23,51.7 C22.9,51.4 23.1,51.2 23.4,51.1 C23.7,51 23.9,51.2 24,51.5 L26,60.1 C26.1,60.4 25.9,60.6 25.6,60.7 C25.6,60.7 25.5,60.7 25.5,60.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M20.3,59.9 C20.1,59.9 19.9,59.8 19.8,59.5 L18.2,53.3 C18.1,53 18.3,52.8 18.6,52.7 C18.9,52.6 19.1,52.8 19.2,53.1 L20.8,59.3 C20.9,59.6 20.7,59.8 20.4,59.9 C20.4,59.9 20.3,59.9 20.3,59.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M15.5,60.5 C15.3,60.5 15.1,60.3 15,60.1 L14,55.7 C13.9,55.4 14.1,55.2 14.4,55.1 C14.7,55 14.9,55.2 15,55.5 L16,59.9 C16.1,60.2 15.9,60.4 15.6,60.5 C15.6,60.5 15.5,60.5 15.5,60.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M10.9,61.1 C10.7,61.1 10.5,61 10.4,60.8 L9.4,57.8 C9.3,57.5 9.5,57.3 9.7,57.2 C10,57.1 10.2,57.3 10.3,57.5 L11.3,60.5 C11.4,60.8 11.2,61 11,61.1 C11,61.1 11,61.1 10.9,61.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M5.5,62.5 C5.4,62.5 5.2,62.4 5.1,62.3 L3.9,60.9 C3.7,60.7 3.7,60.4 4,60.2 C4.2,60 4.5,60 4.7,60.3 L5.9,61.7 C6.1,61.9 6.1,62.2 5.8,62.4 C5.8,62.5 5.6,62.5 5.5,62.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M99.1,43.7 C98.9,43.7 98.7,43.6 98.6,43.3 L96.6,36.9 C96.5,36.6 96.7,36.4 96.9,36.3 C97.2,36.2 97.4,36.4 97.5,36.6 L99.5,43 C99.6,43.3 99.4,43.5 99.2,43.6 C99.2,43.7 99.2,43.7 99.1,43.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M100.9,41.3 C100.7,41.3 100.4,41.1 100.4,40.9 L99.8,36.1 C99.8,35.8 100,35.6 100.2,35.5 C100.5,35.5 100.7,35.7 100.8,35.9 L101.4,40.7 C101.5,41 101.3,41.3 100.9,41.3 C101,41.3 101,41.3 100.9,41.3 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M103.7,40.5 C103.4,40.5 103.2,40.3 103.2,40 L103.2,36 C103.2,35.7 103.4,35.5 103.7,35.5 C104,35.5 104.2,35.7 104.2,36 L104.2,40 C104.2,40.3 104,40.5 103.7,40.5 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M106.5,38.7 C106.5,38.7 106.5,38.7 106.5,38.7 C106.2,38.7 106,38.4 106,38.2 L106.2,36.2 C106.2,35.9 106.5,35.7 106.7,35.8 C107,35.8 107.2,36.1 107.1,36.3 L106.9,38.3 C107,38.5 106.8,38.7 106.5,38.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M98.3,20.6 C98.1,20.6 98,20.5 97.9,20.4 L93.1,14 C92.9,13.8 93,13.5 93.2,13.3 C93.4,13.1 93.7,13.2 93.9,13.4 L98.7,19.8 C98.9,20 98.8,20.3 98.6,20.5 C98.6,20.5 98.5,20.6 98.3,20.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M100.7,16 C100.5,16 100.4,15.9 100.3,15.8 L96.7,10.4 C96.5,10.2 96.6,9.9 96.8,9.7 C97,9.5 97.3,9.6 97.5,9.8 L101.1,15.2 C101.3,15.4 101.2,15.7 101,15.9 C100.9,16 100.8,16 100.7,16 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M102.9,11.4 C102.7,11.4 102.6,11.3 102.5,11.2 L100.3,7.8 C100.2,7.6 100.2,7.3 100.4,7.1 C100.6,7 100.9,7 101.1,7.2 L103.3,10.6 C103.4,10.8 103.4,11.1 103.2,11.3 C103.1,11.4 103,11.4 102.9,11.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M105.5,8.6 C105.3,8.6 105.2,8.5 105.1,8.4 L103.5,5.8 C103.4,5.6 103.4,5.3 103.7,5.1 C103.9,5 104.2,5 104.4,5.3 L106,7.9 C106.1,8.1 106.1,8.4 105.8,8.6 C105.7,8.6 105.6,8.6 105.5,8.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <g>
                                    <path
                                        d="M129.3,196 C128.9,196 128.6,195.8 128.4,195.4 C103.3,141.3 88.1,98.9 79.7,72.9 C70.6,44.7 67.4,29 67.3,28.9 C67.2,28.4 67.5,27.8 68.1,27.7 C68.6,27.6 69.2,27.9 69.3,28.5 C69.4,29.1 82.4,91.3 130.3,194.6 C130.5,195.1 130.3,195.7 129.8,195.9 C129.6,195.9 129.5,196 129.3,196 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M1.1,64.1 C0.8,64.1 0.5,64 0.3,63.8 C-0.1,63.4 -2.84217094e-14,62.8 0.4,62.4 C11.7,52.6 29,48.5 41.5,46.7 C55,44.8 66,45.3 66.1,45.3 C66.7,45.3 67.1,45.8 67.1,46.3 C67.1,46.9 66.6,47.3 66.1,47.3 C65.7,47.3 22.7,45.6 1.8,63.9 C1.5,64 1.3,64.1 1.1,64.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M30.3,79 C30.2,79 30.1,79 30,78.9 C29.5,78.7 29.2,78.1 29.4,77.6 C32.6,68.8 40.4,61.1 51.9,55.4 C60.5,51.1 68.1,49.5 68.4,49.4 C68.9,49.3 69.5,49.6 69.6,50.2 C69.7,50.7 69.4,51.3 68.8,51.4 C68.5,51.5 38.7,58.1 31.3,78.3 C31,78.8 30.7,79 30.3,79 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M75.4,38.8 C75.3,38.8 75.1,38.8 75,38.7 C74.5,38.5 74.2,37.9 74.4,37.4 C74.5,37.1 85.3,11 110.8,1 C111.3,0.8 111.9,1.1 112.1,1.6 C112.3,2.1 112,2.7 111.5,2.9 C86.9,12.5 76.4,37.9 76.3,38.1 C76.2,38.6 75.8,38.8 75.4,38.8 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M78.2,45.4 C77.9,45.4 77.6,45.2 77.4,45 C77.1,44.5 77.2,43.9 77.7,43.6 C78.4,43.1 94.7,32.4 108.7,35.2 C109.2,35.3 109.6,35.8 109.5,36.4 C109.4,36.9 108.9,37.3 108.3,37.2 C95.1,34.6 78.9,45.2 78.8,45.3 C78.5,45.4 78.4,45.4 78.2,45.4 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M69,54.1 C68.8,54.1 68.6,54 68.6,53.8 L67.2,51 C67.1,50.8 67.2,50.5 67.4,50.3 C67.6,50.2 67.9,50.3 68.1,50.5 L69.5,53.3 C69.6,53.5 69.5,53.8 69.3,54 C69.2,54.1 69.1,54.1 69,54.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M66.8,57.1 C66.6,57.1 66.4,57 66.3,56.8 L64.1,52 C64,51.7 64.1,51.5 64.3,51.3 C64.5,51.2 64.8,51.3 65,51.5 L67.2,56.3 C67.3,56.6 67.2,56.8 67,57 C67,57.1 66.9,57.1 66.8,57.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M63.6,59.9 C63.4,59.9 63.2,59.8 63.1,59.6 L60.1,52.8 C60,52.5 60.1,52.3 60.4,52.1 C60.7,52 60.9,52.1 61.1,52.4 L64.1,59.2 C64.2,59.5 64.1,59.7 63.8,59.9 C63.8,59.9 63.7,59.9 63.6,59.9 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M60.4,62.5 C60.2,62.5 60,62.4 59.9,62.2 L56.7,54.4 C56.6,54.1 56.7,53.9 57,53.7 C57.3,53.6 57.5,53.7 57.7,54 L60.9,61.8 C61,62.1 60.9,62.3 60.6,62.5 C60.6,62.5 60.5,62.5 60.4,62.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M58.2,66.9 C58,66.9 57.8,66.8 57.7,66.6 L53.1,56 C53,55.7 53.1,55.5 53.4,55.3 C53.7,55.2 53.9,55.3 54.1,55.6 L58.7,66.2 C58.8,66.5 58.7,66.7 58.4,66.9 C58.4,66.9 58.3,66.9 58.2,66.9 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M54.2,67.5 C54,67.5 53.8,67.4 53.8,67.2 L49.2,58 C49.1,57.8 49.2,57.5 49.4,57.3 C49.6,57.2 49.9,57.3 50.1,57.5 L54.7,66.7 C54.8,66.9 54.7,67.2 54.5,67.4 C54.4,67.5 54.3,67.5 54.2,67.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M50.8,68.1 C50.6,68.1 50.5,68 50.4,67.8 L45.8,59.8 C45.7,59.6 45.7,59.3 46,59.1 C46.2,59 46.5,59 46.7,59.3 L51.3,67.3 C51.4,67.5 51.4,67.8 51.1,68 C51,68.1 50.9,68.1 50.8,68.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M46.5,69.3 C46.3,69.3 46.2,69.2 46.1,69.1 L42.1,62.9 C41.9,62.7 42,62.4 42.2,62.2 C42.4,62.1 42.7,62.1 42.9,62.3 L46.9,68.5 C47.1,68.7 47,69 46.8,69.2 C46.6,69.3 46.5,69.3 46.5,69.3 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M41.7,70.7 C41.5,70.7 41.4,70.6 41.3,70.5 L38.3,65.9 C38.1,65.7 38.2,65.4 38.4,65.2 C38.6,65 38.9,65.1 39.1,65.3 L42.1,69.9 C42.3,70.1 42.2,70.4 42,70.6 C41.8,70.7 41.8,70.7 41.7,70.7 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M37.7,73.5 C37.6,73.5 37.5,73.5 37.4,73.4 L34.6,70.8 C34.4,70.6 34.4,70.3 34.6,70.1 C34.8,69.9 35.1,69.9 35.3,70.1 L38.1,72.7 C38.3,72.9 38.3,73.2 38.1,73.4 C37.9,73.4 37.8,73.5 37.7,73.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M64.4,49.1 C64.2,49.1 64,48.9 63.9,48.7 L63.5,46.7 C63.4,46.4 63.6,46.2 63.9,46.1 C64.2,46 64.4,46.2 64.5,46.5 L64.9,48.5 C65,48.8 64.8,49.1 64.4,49.1 C64.5,49.1 64.4,49.1 64.4,49.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M61,50.1 C60.7,50.1 60.5,49.9 60.5,49.6 L60.3,46.2 C60.3,45.9 60.5,45.7 60.8,45.7 C61.1,45.7 61.3,45.9 61.3,46.2 L61.5,49.6 C61.5,49.9 61.3,50.1 61,50.1 C61,50.1 61,50.1 61,50.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M57.8,51.1 C57.5,51.1 57.3,50.9 57.3,50.6 L56.9,46 C56.9,45.7 57.1,45.5 57.4,45.5 C57.7,45.5 57.9,45.7 57.9,46 L58.3,50.6 C58.4,50.8 58.1,51.1 57.8,51.1 C57.9,51.1 57.8,51.1 57.8,51.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M54,52.5 C53.7,52.5 53.5,52.3 53.5,52 L52.9,46 C52.9,45.7 53.1,45.5 53.3,45.5 C53.6,45.5 53.8,45.7 53.8,45.9 L54.4,51.9 C54.6,52.2 54.4,52.5 54,52.5 C54.1,52.5 54.1,52.5 54,52.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M50.2,54.1 C50,54.1 49.7,53.9 49.7,53.7 L48.9,47.1 C48.9,46.8 49.1,46.6 49.3,46.5 C49.6,46.5 49.8,46.7 49.9,46.9 L50.7,53.5 C50.8,53.8 50.6,54.1 50.2,54.1 C50.3,54.1 50.3,54.1 50.2,54.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M46.4,56.1 C46.2,56.1 45.9,55.9 45.9,55.7 L44.7,47.5 C44.7,47.2 44.8,47 45.1,46.9 C45.4,46.9 45.6,47 45.7,47.3 L46.9,55.5 C47,55.8 46.8,56.1 46.4,56.1 C46.5,56.1 46.5,56.1 46.4,56.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M42.3,58.5 C42.1,58.5 41.8,58.3 41.8,58.1 L40.4,47.7 C40.4,47.4 40.6,47.2 40.8,47.1 C41.1,47.1 41.3,47.3 41.4,47.5 L42.8,57.9 C42.8,58.2 42.6,58.5 42.3,58.5 C42.3,58.5 42.3,58.5 42.3,58.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M38.7,60.7 C38.5,60.7 38.2,60.5 38.2,60.3 L36.6,48.7 C36.6,48.4 36.8,48.2 37,48.1 C37.3,48.1 37.5,48.3 37.6,48.5 L39.2,60.1 C39.2,60.4 39,60.7 38.7,60.7 C38.7,60.7 38.7,60.7 38.7,60.7 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M82.6,48.7 C82.5,48.7 82.3,48.6 82.2,48.5 L78.8,44.5 C78.6,44.3 78.6,44 78.9,43.8 C79.1,43.6 79.4,43.6 79.6,43.9 L83,47.9 C83.2,48.1 83.2,48.4 82.9,48.6 C82.8,48.7 82.7,48.7 82.6,48.7 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M86,48.1 C85.8,48.1 85.6,48 85.6,47.8 L82.6,42.2 C82.5,42 82.6,41.7 82.8,41.5 C83,41.4 83.3,41.5 83.5,41.7 L86.5,47.3 C86.6,47.5 86.5,47.8 86.3,48 C86.1,48.1 86.1,48.1 86,48.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M89,47.1 C88.8,47.1 88.6,47 88.5,46.8 L86.1,40.4 C86,40.1 86.1,39.9 86.4,39.8 C86.7,39.7 86.9,39.8 87,40.1 L89.4,46.5 C89.5,46.8 89.4,47 89.1,47.1 C89.1,47.1 89,47.1 89,47.1 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M93,47.3 C92.8,47.3 92.6,47.2 92.5,47 L89.7,38.6 C89.6,38.3 89.8,38.1 90,38 C90.3,37.9 90.5,38.1 90.6,38.3 L93.4,46.7 C93.5,47 93.3,47.2 93.1,47.3 C93.1,47.3 93,47.3 93,47.3 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M95.6,45.5 C95.4,45.5 95.2,45.4 95.1,45.1 L92.9,37.5 C92.8,37.2 93,37 93.2,36.9 C93.5,36.8 93.7,37 93.8,37.2 L96,44.8 C96.1,45.1 95.9,45.3 95.7,45.4 C95.6,45.5 95.6,45.5 95.6,45.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M78.4,39.9 C78.3,39.9 78.2,39.9 78.1,39.8 L75.9,37.8 C75.7,37.6 75.7,37.3 75.9,37.1 C76.1,36.9 76.4,36.9 76.6,37.1 L78.8,39.1 C79,39.3 79,39.6 78.8,39.8 C78.7,39.9 78.5,39.9 78.4,39.9 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M81.8,38.7 C81.7,38.7 81.5,38.6 81.4,38.5 L77.2,34.1 C77,33.9 77,33.6 77.2,33.4 C77.4,33.2 77.7,33.2 77.9,33.4 L82.1,37.8 C82.3,38 82.3,38.3 82.1,38.5 C82,38.7 81.9,38.7 81.8,38.7 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M85.6,37.5 C85.5,37.5 85.3,37.4 85.2,37.3 L78.8,30.5 C78.6,30.3 78.6,30 78.8,29.8 C79,29.6 79.3,29.6 79.5,29.8 L85.9,36.6 C86.1,36.8 86.1,37.1 85.9,37.3 C85.8,37.5 85.7,37.5 85.6,37.5 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M89,35.3 C88.9,35.3 88.7,35.2 88.6,35.1 L81.6,26.7 C81.4,26.5 81.5,26.2 81.7,26 C81.9,25.8 82.2,25.9 82.4,26.1 L89.4,34.5 C89.6,34.7 89.5,35 89.3,35.2 C89.2,35.3 89.1,35.3 89,35.3 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M91.6,31.4 C91.5,31.4 91.3,31.3 91.2,31.2 L83.6,23.4 C83.4,23.2 83.4,22.9 83.6,22.7 C83.8,22.5 84.1,22.5 84.3,22.7 L91.9,30.5 C92.1,30.7 92.1,31 91.9,31.2 C91.8,31.3 91.7,31.4 91.6,31.4 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M93,27.8 C92.9,27.8 92.7,27.7 92.6,27.6 L86.6,20.2 C86.4,20 86.5,19.7 86.7,19.5 C86.9,19.3 87.2,19.4 87.4,19.6 L93.4,27 C93.6,27.2 93.5,27.5 93.3,27.7 C93.2,27.7 93.1,27.8 93,27.8 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <path
                                        d="M95.6,24.2 C95.4,24.2 95.3,24.1 95.2,24 L90,17 C89.8,16.8 89.9,16.5 90.1,16.3 C90.3,16.1 90.6,16.2 90.8,16.4 L96,23.4 C96.2,23.6 96.1,23.9 95.9,24.1 C95.8,24.1 95.7,24.2 95.6,24.2 Z"
                                        id="Path"
                                        fill="#C2C5CE"
                                    />
                                    <g transform="translate(65.000000, 55.000000)">
                                        <circle id="Oval" fill="#FFFFFF" cx="5.9" cy="5.7" r="4" />
                                        <path
                                            d="M5.9,2.7 C7.6,2.7 8.9,4.1 8.9,5.7 C8.9,7.4 7.5,8.7 5.9,8.7 C4.3,8.7 2.9,7.3 2.9,5.7 C2.8,4.1 4.2,2.7 5.9,2.7 L5.9,2.7 Z M5.9,0.7 C3.1,0.7 0.9,3 0.9,5.7 C0.9,8.5 3.2,10.7 5.9,10.7 C8.6,10.7 10.9,8.4 10.9,5.7 C10.9,3 8.6,0.7 5.9,0.7 L5.9,0.7 Z"
                                            id="Shape"
                                            fill="#C2C5CE"
                                        />
                                    </g>
                                    <g transform="translate(76.000000, 50.000000)">
                                        <circle id="Oval" fill="#FFFFFF" cx="5" cy="5.2" r="4" />
                                        <path
                                            d="M5,2.2 C6.7,2.2 8,3.6 8,5.2 C8,6.9 6.6,8.2 5,8.2 C3.4,8.2 2,6.8 2,5.2 C1.9,3.5 3.3,2.2 5,2.2 L5,2.2 Z M5,0.2 C2.2,0.2 0,2.5 0,5.2 C0,7.9 2.3,10.2 5,10.2 C7.8,10.2 10,7.9 10,5.2 C10,2.5 7.7,0.2 5,0.2 L5,0.2 Z"
                                            id="Shape"
                                            fill="#C2C5CE"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(75.000000, 116.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="5.5" cy="5.1" r="4" />
                                <path
                                    d="M5.5,2.1 C7.2,2.1 8.5,3.5 8.5,5.1 C8.5,6.8 7.1,8.1 5.5,8.1 C3.9,8.1 2.5,6.7 2.5,5.1 C2.4,3.4 3.8,2.1 5.5,2.1 L5.5,2.1 Z M5.5,0.1 C2.7,0.1 0.5,2.4 0.5,5.1 C0.5,7.9 2.8,10.1 5.5,10.1 C8.3,10.1 10.5,7.8 10.5,5.1 C10.5,2.3 8.2,0.1 5.5,0.1 L5.5,0.1 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(296.000000, 91.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M18.9,5.4 C17.6,5.4 16.5,5.8 15.5,6.6 C14.6,3.2 11.6,0.8 8,0.8 C3.7,0.8 0.2,4.3 0.2,8.6 C0.2,12.9 3.7,16.4 8,16.4 L18.9,16.4 C21.9,16.4 24.4,14 24.4,10.9 C24.3,7.8 21.9,5.4 18.9,5.4 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(5.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M10.1,5.5 C8.9,2.7 6.2,0.7 2.9,0.7 C2.1,0.7 1.3,0.8 0.6,1.1 C3.2,1.9 5.1,4 5.8,6.6 C6.7,5.9 7.9,5.4 9.2,5.4 C9.5,5.4 9.8,5.4 10.1,5.5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(9.000000, 5.000000)">
                                            <g>
                                                <path d="M4.9,0.4 C4.1,0.4 3.3,0.6 2.6,0.9 C4.4,1.8 5.7,3.6 5.7,5.8 C5.7,8.8 3.3,11.3 0.2,11.3 L4.9,11.3 C7.9,11.3 10.4,8.9 10.4,5.8 C10.3,2.8 7.9,0.4 4.9,0.4 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M19.9,18.3 L9,18.3 C8.5,18.3 7.9,18.3 7.4,18.2 C7.1,18.2 6.9,18 6.8,17.8 C6.6,17.6 6.6,17.3 6.6,17.1 C6.7,16.6 7.2,16.2 7.8,16.3 C8.2,16.4 8.6,16.4 9,16.4 L19.9,16.4 C22.4,16.4 24.4,14.4 24.4,11.9 C24.4,9.4 22.4,7.4 19.9,7.4 C18.9,7.4 17.9,7.7 17.1,8.4 L15.9,9.4 L15.5,7.9 C14.7,4.9 12,2.8 8.9,2.8 C5.2,2.8 2.1,5.9 2.1,9.6 C2.1,11.6 3,13.5 4.6,14.8 C5,15.1 5.1,15.8 4.7,16.2 C4.4,16.6 3.7,16.7 3.3,16.3 C1.3,14.6 0.1,12.2 0.1,9.5 C0.1,4.6 4.1,0.7 8.9,0.7 C12.4,0.7 15.6,2.8 17,6 C17.9,5.6 18.8,5.3 19.8,5.3 C23.4,5.3 26.3,8.2 26.3,11.8 C26.3,15.4 23.4,18.3 19.9,18.3 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(137.000000, 39.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M19,4.9 C17.7,4.9 16.6,5.3 15.6,6.1 C14.7,2.7 11.7,0.3 8.1,0.3 C3.8,0.3 0.3,3.8 0.3,8.1 C0.3,12.4 3.8,15.9 8.1,15.9 L19,15.9 C22,15.9 24.5,13.5 24.5,10.4 C24.4,7.4 22,4.9 19,4.9 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(5.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M10.2,5 C9,2.2 6.3,0.2 3,0.2 C2.2,0.2 1.4,0.3 0.7,0.6 C3.3,1.4 5.2,3.5 5.9,6.1 C6.8,5.4 8,4.9 9.3,4.9 C9.6,4.9 9.9,5 10.2,5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(9.000000, 4.000000)">
                                            <g>
                                                <path d="M5,0.9 C4.2,0.9 3.4,1.1 2.7,1.4 C4.5,2.3 5.8,4.1 5.8,6.3 C5.8,9.3 3.4,11.8 0.3,11.8 L5,11.8 C8,11.8 10.5,9.4 10.5,6.3 C10.4,3.4 8,0.9 5,0.9 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M20,17.8 L9.1,17.8 C8.6,17.8 8,17.8 7.5,17.7 C7,17.6 6.6,17.1 6.7,16.5 C6.8,16 7.3,15.6 7.9,15.7 C8.3,15.8 8.7,15.8 9.1,15.8 L20,15.8 C22.5,15.8 24.5,13.8 24.5,11.3 C24.5,8.9 22.5,6.9 20,6.8 C19,6.8 18,7.1 17.2,7.8 L16,8.8 L15.6,7.3 C14.8,4.3 12.1,2.2 9,2.2 C5.3,2.2 2.2,5.3 2.2,9 C2.2,11 3.1,12.9 4.7,14.2 C5.1,14.6 5.2,15.2 4.8,15.6 C4.5,16 3.8,16.1 3.4,15.7 C1.4,14 0.2,11.6 0.2,8.9 C0.2,4.1 4.2,0.1 9,0.1 C12.5,0.1 15.7,2.2 17.1,5.4 C18,5 18.9,4.7 19.9,4.7 C23.5,4.7 26.4,7.6 26.4,11.2 C26.4,14.8 23.5,17.8 20,17.8 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(255.000000, 57.000000)">
                                <g transform="translate(1.000000, 1.000000)" id="Path">
                                    <path
                                        d="M25.6,6.6 C23.9,6.6 22.3,7.2 21,8.2 C19.8,3.6 15.7,0.3 10.8,0.3 C5,0.3 0.2,5 0.2,10.9 C0.2,16.7 4.9,21.5 10.8,21.5 L25.6,21.5 C29.7,21.5 33,18.2 33,14.1 C33,9.9 29.7,6.6 25.6,6.6 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(7.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M13.5,6.7 C11.9,2.9 8.1,0.2 3.8,0.2 C2.7,0.2 1.6,0.4 0.6,0.7 C4.1,1.8 6.8,4.6 7.7,8.2 C9,7.2 10.5,6.6 12.3,6.6 C12.7,6.6 13.1,6.6 13.5,6.7 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(12.000000, 6.000000)">
                                            <g>
                                                <path d="M6.6,0.6 C5.5,0.6 4.4,0.9 3.4,1.3 C5.9,2.5 7.6,5 7.6,8 C7.6,12.1 4.3,15.4 0.2,15.4 L6.6,15.4 C10.7,15.4 14,12.1 14,8 C14,3.9 10.7,0.6 6.6,0.6 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE" id="Shape">
                                    <g>
                                        <path d="M26.6,23.4 L11.8,23.4 C9.5,23.4 7.3,22.7 5.4,21.5 C2.6,19.7 0.8,16.7 0.3,13.4 C0.3,13.1 0.3,12.9 0.5,12.7 C0.7,12.5 0.9,12.4 1.2,12.3 C1.7,12.2 2.2,12.6 2.3,13.2 C2.7,15.9 4.2,18.4 6.5,19.9 C8.1,20.9 9.9,21.5 11.8,21.5 L26.6,21.5 C30.1,21.5 33,18.6 33,15.1 C33,15 33,14.9 33,14.8 C33,14.3 33.4,13.8 34,13.8 C34.5,13.8 35,14.2 35,14.7 C35,14.8 35,15 35,15.1 C35,19.6 31.2,23.4 26.6,23.4 Z M32.8,11.9 C32.5,11.9 32.2,11.7 32,11.4 C30.8,9.6 28.8,8.5 26.6,8.5 C25.2,8.5 23.8,9 22.6,9.9 L21.4,10.8 L21,9.3 C19.9,5.1 16.1,2.1 11.7,2.1 C7.3,2.1 3.4,5.1 2.4,9.4 C2.3,9.9 1.7,10.3 1.2,10.1 C0.9,10 0.7,9.9 0.6,9.6 C0.5,9.4 0.4,9.1 0.5,8.8 C1.8,3.6 6.4,0 11.8,0 C14.2,0 16.5,0.7 18.4,2.1 C20.3,3.4 21.7,5.3 22.6,7.4 C23.8,6.7 25.2,6.4 26.6,6.4 C29.4,6.4 32.1,7.8 33.6,10.2 C33.9,10.7 33.8,11.3 33.3,11.6 C33.1,11.8 33,11.9 32.8,11.9 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(201.000000, 23.000000)">
                                <g transform="translate(1.000000, 1.000000)" id="Path">
                                    <path
                                        d="M26,6.9 C24.3,6.9 22.7,7.5 21.4,8.5 C20.2,3.9 16.1,0.6 11.2,0.6 C5.4,0.6 0.6,5.3 0.6,11.2 C0.6,17 5.3,21.8 11.2,21.8 L26,21.8 C30.1,21.8 33.4,18.5 33.4,14.4 C33.4,10.2 30.1,6.9 26,6.9 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(8.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M12.9,7 C11.3,3.2 7.5,0.5 3.2,0.5 C2.1,0.5 1,0.7 0,1 C3.5,2.1 6.2,4.9 7.1,8.5 C8.4,7.5 9.9,6.9 11.7,6.9 C12.1,6.9 12.5,6.9 12.9,7 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(11.000000, 6.000000)">
                                            <g>
                                                <path d="M7,0.9 C5.9,0.9 4.8,1.2 3.8,1.6 C6.3,2.8 8,5.3 8,8.3 C8,12.4 4.7,15.7 0.6,15.7 L7,15.7 C11.1,15.7 14.4,12.4 14.4,8.3 C14.4,4.2 11.1,0.9 7,0.9 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE" id="Shape">
                                    <g>
                                        <path d="M27,23.7 L12.2,23.7 C9.9,23.7 7.7,23 5.8,21.8 C3,20 1.2,17 0.7,13.7 C0.6,13.2 1,12.7 1.5,12.6 C2,12.5 2.5,12.9 2.6,13.5 C3,16.2 4.5,18.7 6.8,20.2 C8.4,21.2 10.2,21.8 12.1,21.8 L27,21.8 C30.5,21.8 33.4,18.9 33.4,15.4 C33.4,15.3 33.4,15.2 33.4,15.1 C33.4,14.8 33.5,14.6 33.7,14.4 C33.9,14.2 34.1,14.1 34.4,14.1 C34.9,14.1 35.4,14.5 35.4,15.1 C35.4,15.1 35.4,15.2 35.4,15.2 C35.4,15.3 35.4,15.4 35.4,15.4 C35.4,19.9 31.6,23.7 27,23.7 Z M33.2,12.2 C32.9,12.2 32.5,12 32.4,11.8 C31.2,10 29.2,8.9 27.1,8.9 C25.6,8.9 24.3,9.4 23.1,10.3 L21.9,11.2 L21.5,9.7 C20.4,5.5 16.6,2.5 12.2,2.5 C7.8,2.5 3.9,5.5 2.9,9.8 C2.8,10.3 2.2,10.7 1.7,10.5 C1.2,10.4 0.8,9.8 1,9.3 C2.3,4.1 6.9,0.5 12.3,0.5 C14.7,0.5 17,1.2 18.9,2.6 C20.8,3.9 22.2,5.8 23.1,7.9 C24.3,7.2 25.7,6.9 27.1,6.9 C29.9,6.9 32.6,8.3 34.1,10.7 C34.4,11.2 34.3,11.8 33.8,12.1 C33.5,12.1 33.3,12.2 33.2,12.2 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(151.000000, 0.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M19.1,5.1 C17.8,5.1 16.7,5.5 15.7,6.3 C14.8,2.9 11.8,0.5 8.2,0.5 C3.9,0.5 0.4,4 0.4,8.3 C0.4,12.6 3.9,16.1 8.2,16.1 L19.1,16.1 C22.1,16.1 24.6,13.7 24.6,10.6 C24.6,7.5 22.1,5.1 19.1,5.1 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(5.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M10.4,5.1 C9.2,2.3 6.5,0.3 3.2,0.3 C2.4,0.3 1.6,0.4 0.9,0.7 C3.5,1.5 5.4,3.6 6.1,6.2 C7,5.5 8.2,5 9.5,5 C9.8,5.1 10.1,5.1 10.4,5.1 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(9.000000, 5.000000)">
                                            <g>
                                                <path d="M5.1,0.1 C4.3,0.1 3.5,0.3 2.8,0.6 C4.6,1.5 5.9,3.3 5.9,5.5 C5.9,8.5 3.5,11 0.4,11 L5.1,11 C8.1,11 10.6,8.6 10.6,5.5 C10.6,2.5 8.1,0.1 5.1,0.1 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M16.8,18 C16.8,18 16.8,18 16.8,18 L9.2,18 C8.7,18 8.1,18 7.6,17.9 C7.1,17.8 6.7,17.3 6.8,16.7 C6.9,16.2 7.4,15.8 8,15.9 C8.4,16 8.8,16 9.2,16 L20.1,16 C22.6,16 24.5,14 24.6,11.5 C24.6,9.1 22.6,7.1 20.1,7.1 C19.1,7.1 18.1,7.4 17.3,8 L16.1,9 L15.7,7.5 C14.9,4.5 12.2,2.4 9.1,2.4 C5.4,2.4 2.3,5.5 2.3,9.2 C2.3,11.2 3.2,13.1 4.8,14.4 C5.2,14.8 5.3,15.4 4.9,15.8 C4.6,16.2 3.9,16.3 3.5,15.9 C1.5,14.2 0.3,11.8 0.3,9.1 C0.3,4.2 4.2,0.3 9.1,0.3 C12.6,0.3 15.8,2.4 17.2,5.6 C18.1,5.2 19,4.9 20,4.9 C23.5,4.9 26.5,7.8 26.5,11.3 C26.5,14.8 23.6,17.8 20,17.8 L16.8,17.8 L16.8,18 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(224.000000, 23.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M19.4,4.9 C18.1,4.9 17,5.3 16,6.1 C15.1,2.7 12.1,0.3 8.5,0.3 C4.2,0.3 0.7,3.8 0.7,8.1 C0.7,12.4 4.2,15.9 8.5,15.9 L19.4,15.9 C22.4,15.9 24.9,13.5 24.9,10.4 C24.8,7.3 22.4,4.9 19.4,4.9 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(6.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M9.6,5 C8.4,2.2 5.7,0.2 2.4,0.2 C1.6,0.2 0.8,0.3 0.1,0.6 C2.7,1.4 4.6,3.5 5.3,6.1 C6.2,5.4 7.4,4.9 8.7,4.9 C9,4.9 9.3,4.9 9.6,5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(8.000000, 4.000000)">
                                            <g>
                                                <path d="M5.4,0.9 C4.6,0.9 3.8,1.1 3.1,1.4 C4.9,2.3 6.2,4.1 6.2,6.3 C6.2,9.3 3.8,11.8 0.7,11.8 L5.4,11.8 C8.4,11.8 10.9,9.4 10.9,6.3 C10.8,3.3 8.4,0.9 5.4,0.9 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M20.4,17.8 L9.5,17.8 C9,17.8 8.4,17.8 7.9,17.7 C7.6,17.7 7.4,17.5 7.3,17.3 C7.1,17.1 7.1,16.8 7.1,16.6 C7.2,16.1 7.7,15.7 8.3,15.8 C8.7,15.9 9.1,15.9 9.5,15.9 L17,15.9 C17,15.9 17,15.9 17.1,15.9 L20.4,15.9 C22.9,15.9 24.9,13.9 24.9,11.4 C24.9,8.9 22.9,6.9 20.4,6.9 C19.4,6.9 18.4,7.2 17.6,7.9 L16.4,8.9 L16,7.4 C15.2,4.4 12.5,2.3 9.4,2.3 C5.7,2.3 2.6,5.3 2.6,9.1 C2.6,11.1 3.5,13 5.1,14.3 C5.3,14.5 5.4,14.7 5.5,15 C5.5,15.3 5.4,15.5 5.3,15.7 C5,16.1 4.3,16.2 3.9,15.8 C1.9,14.1 0.7,11.7 0.7,9 C0.7,4.2 4.7,0.2 9.5,0.2 C13,0.2 16.2,2.3 17.6,5.5 C18.5,5.1 19.5,4.8 20.4,4.8 C24,4.8 26.9,7.7 26.9,11.3 C26.9,14.9 23.9,17.8 20.4,17.8 Z" />
                                    </g>
                                </g>
                            </g>
                            <path
                                d="M151,252.4 C150.4,252.4 150,252 150,251.4 L150,246.4 C150,245.8 150.4,245.4 151,245.4 C151.6,245.4 152,245.8 152,246.4 L152,251.4 C152,252 151.6,252.4 151,252.4 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M119.1,256.8 C118.5,256.8 118.1,256.4 118.1,255.8 L118.1,251.5 C118.1,250.9 118.5,250.5 119.1,250.5 C119.7,250.5 120.1,250.9 120.1,251.5 L120.1,255.8 C120.1,256.4 119.7,256.8 119.1,256.8 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(229.000000, 239.000000)">
                                <path
                                    d="M7.9,15.7 C7.3,15.7 6.9,15.3 6.9,14.7 L6.9,7.7 C6.9,7.1 7.3,6.7 7.9,6.7 C8.5,6.7 8.9,7.1 8.9,7.7 L8.9,14.7 C8.9,15.3 8.4,15.7 7.9,15.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M1,15.7 C0.4,15.7 0,15.3 0,14.7 L0,9.7 C0,9.1 0.4,8.7 1,8.7 C1.6,8.7 2,9.1 2,9.7 L2,14.7 C2,15.3 1.6,15.7 1,15.7 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <g transform="translate(3.000000, 0.000000)">
                                    <circle id="Oval" fill="#FFFFFF" cx="4.9" cy="4.4" r="3" />
                                    <path
                                        d="M4.9,8.4 C2.7,8.4 0.9,6.6 0.9,4.4 C0.9,2.2 2.7,0.4 4.9,0.4 C7.1,0.4 8.9,2.2 8.9,4.4 C8.9,6.6 7.1,8.4 4.9,8.4 Z M4.9,2.4 C3.8,2.4 2.9,3.3 2.9,4.4 C2.9,5.5 3.8,6.4 4.9,6.4 C6,6.4 6.9,5.5 6.9,4.4 C6.9,3.3 6,2.4 4.9,2.4 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                            </g>
                            <path
                                d="M164,310 C88.7,310 26.5,251.2 22.3,176.1 C22.3,175.5 22.7,175.1 23.2,175 C23.7,175 24.2,175.4 24.3,175.9 C28.4,249.9 89.8,307.9 164,307.9 C200.6,307.9 235.2,293.9 261.4,268.4 C287.6,243 302.7,209 303.8,172.6 C303.8,171.1 303.9,169.5 303.9,168 C303.9,167.4 304.3,167 304.9,167 C305.5,167 305.9,167.4 305.9,168 C305.9,169.6 305.9,171.1 305.8,172.7 C304.6,209.6 289.3,244.1 262.7,269.9 C236.2,295.7 201.1,310 164,310 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M114.7,273 L91.6,273 C91,273 90.6,272.6 90.6,272 C90.6,271.4 91,271 91.6,271 L114.7,271 C115.3,271 115.7,271.4 115.7,272 C115.7,272.6 115.2,273 114.7,273 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M138.2,276 L126,276 C125.4,276 125,275.6 125,275 C125,274.4 125.4,274 126,274 L138.2,274 C138.8,274 139.2,274.4 139.2,275 C139.2,275.6 138.8,276 138.2,276 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M129.3,283 L114.6,283 C114,283 113.6,282.6 113.6,282 C113.6,281.4 114,281 114.6,281 L129.3,281 C129.9,281 130.3,281.4 130.3,282 C130.3,282.6 129.9,283 129.3,283 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M148,295 L138.7,295 C138.1,295 137.7,294.6 137.7,294 C137.7,293.4 138.1,293 138.7,293 L148,293 C148.6,293 149,293.4 149,294 C149,294.6 148.6,295 148,295 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M201.5,283 L177.3,283 C176.7,283 176.3,282.6 176.3,282 C176.3,281.4 176.7,281 177.3,281 L201.5,281 C202.1,281 202.5,281.4 202.5,282 C202.5,282.6 202.1,283 201.5,283 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M237.2,274 L221.3,274 C220.7,274 220.3,273.6 220.3,273 C220.3,272.4 220.7,272 221.3,272 L237.2,272 C237.8,272 238.2,272.4 238.2,273 C238.2,273.6 237.7,274 237.2,274 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M173.3,273 L159,273 C158.4,273 158,272.6 158,272 C158,271.4 158.4,271 159,271 L173.3,271 C173.9,271 174.3,271.4 174.3,272 C174.3,272.6 173.9,273 173.3,273 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M166.2,283 L148,283 C147.4,283 147,282.6 147,282 C147,281.4 147.4,281 148,281 L166.2,281 C166.8,281 167.2,281.4 167.2,282 C167.2,282.6 166.7,283 166.2,283 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M183.3,293 L168.5,293 C167.9,293 167.5,292.6 167.5,292 C167.5,291.4 167.9,291 168.5,291 L183.3,291 C183.9,291 184.3,291.4 184.3,292 C184.3,292.6 183.9,293 183.3,293 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M220.3,288 L208.2,288 C207.6,288 207.2,287.6 207.2,287 C207.2,286.4 207.6,286 208.2,286 L220.3,286 C220.9,286 221.3,286.4 221.3,287 C221.3,287.6 220.8,288 220.3,288 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M224.1,281 L214.2,281 C213.6,281 213.2,280.6 213.2,280 C213.2,279.4 213.6,279 214.2,279 L224.1,279 C224.7,279 225.1,279.4 225.1,280 C225.1,280.6 224.7,281 224.1,281 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M57.6,261.5 C57.4,261.5 57.1,261.4 56.9,261.3 C56.5,260.9 56.4,260.3 56.8,259.9 C57.7,258.9 79.1,235.3 137.7,243.7 C139.8,241.3 150.4,232.3 183.5,240.9 C203.5,246.1 211.3,243.1 218.9,240.2 C225.2,237.8 231.7,235.3 244.5,237.3 C272.6,241.8 280.2,247.1 280.5,247.3 C280.9,247.6 281.1,248.2 280.7,248.7 C280.4,249.1 279.8,249.3 279.3,248.9 C279.2,248.9 271.6,243.7 244.1,239.3 C231.8,237.3 225.8,239.6 219.5,242.1 C211.6,245.1 203.5,248.3 182.8,242.9 C147.5,233.7 138.8,245.3 138.7,245.4 L138.3,245.9 L137.7,245.8 C107.5,241.4 87.4,245.8 75.9,250.3 C63.5,255.2 58.1,261.2 58,261.2 C58.2,261.4 57.9,261.5 57.6,261.5 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(110.000000, 216.000000)" fill="#C2C5CE" id="Path">
                                <path d="M1.8,21.2 C1.4,21.2 1,21 0.9,20.6 C0.5,19.5 0.2,18.4 0.2,17.3 C0.2,12.6 4,8.8 8.7,8.8 C9.8,8.8 10.9,9 11.9,9.4 C12.4,9.6 12.7,10.2 12.4,10.7 C12.2,11.2 11.6,11.5 11.1,11.2 C10.3,10.9 9.5,10.7 8.6,10.7 C5,10.7 2.1,13.6 2.1,17.2 C2.1,18.1 2.3,18.9 2.6,19.7 C2.8,20.2 2.6,20.8 2.1,21 C2.1,21.2 2,21.2 1.8,21.2 Z" />
                                <path d="M11.6,11.3 C11.2,11.3 10.7,11 10.6,10.5 C10.4,9.8 10.3,9.1 10.3,8.5 C10.3,4 14,0.3 18.5,0.3 C20.6,0.3 22.6,1.1 24.1,2.5 C24.5,2.9 24.5,3.4 24.2,3.9 C23.6,4.6 23.3,5.5 23.3,6.3 C23.3,6.9 22.9,7.3 22.3,7.3 C21.7,7.3 21.3,6.9 21.3,6.3 C21.3,5.3 21.6,4.3 22,3.4 C21,2.7 19.7,2.3 18.4,2.3 C15,2.3 12.2,5.1 12.2,8.5 C12.2,9 12.3,9.5 12.4,10 C12.5,10.5 12.2,11.1 11.7,11.2 C11.8,11.3 11.7,11.3 11.6,11.3 Z" />
                                <path d="M30.7,8.7 C30.4,8.7 30.1,8.6 29.9,8.3 C29.5,7.9 29.6,7.2 30,6.9 C30.4,6.6 30.9,6.3 31.3,6 C31.2,3.9 29.4,2.3 27.3,2.3 C26.1,2.3 24.9,2.9 24.2,3.8 C23.9,4.2 23.2,4.3 22.8,4 C22.4,3.7 22.3,3 22.6,2.6 C23.8,1.1 25.5,0.3 27.3,0.3 C30.6,0.3 33.3,3 33.3,6.3 C33.3,6.4 33.3,6.6 33.3,6.7 C33.3,7.1 33,7.5 32.6,7.6 C32.1,7.8 31.6,8.1 31.2,8.4 C31.2,8.6 30.9,8.7 30.7,8.7 Z" />
                                <path d="M22.5,7.3 C21.9,7.3 21.5,6.9 21.5,6.3 C21.5,5 21.9,3.7 22.8,2.6 C23.1,2.2 23.8,2.1 24.2,2.4 C24.6,2.7 24.7,3.4 24.4,3.8 C23.8,4.5 23.5,5.4 23.5,6.2 C23.5,6.8 23,7.3 22.5,7.3 Z" />
                                <path d="M40.4,13.4 C40.3,13.4 40.3,13.4 40.4,13.4 C39.8,13.4 39.4,12.9 39.4,12.4 L39.4,12.1 C39.4,9.4 37.2,7.2 34.5,7.2 C33.9,7.2 33.3,7.3 32.8,7.5 C32.3,7.7 31.7,7.4 31.5,6.9 C31.3,6.4 31.6,5.8 32.1,5.6 C32.9,5.3 33.7,5.1 34.5,5.1 C38.3,5.1 41.4,8.2 41.4,12 C41.4,12.1 41.4,12.2 41.4,12.3 C41.3,13 40.9,13.4 40.4,13.4 Z" />
                                <path d="M45,19.4 C44.9,19.4 44.7,19.4 44.6,19.3 C44.1,19.1 43.9,18.5 44.1,18 C44.3,17.6 44.4,17.2 44.4,16.7 C44.4,14.9 42.9,13.4 41.1,13.4 C41,13.4 40.8,13.4 40.7,13.4 L40.4,11.4 C40.7,11.4 40.9,11.3 41.2,11.3 C44.1,11.3 46.5,13.7 46.5,16.6 C46.5,17.3 46.4,18 46.1,18.7 C45.7,19.2 45.3,19.4 45,19.4 Z" />
                            </g>
                            <path
                                d="M188.7,243.7 C188.5,243.7 188.4,243.7 188.2,243.6 C187.7,243.3 187.5,242.7 187.8,242.2 C188.2,241.5 188.4,240.7 188.4,239.8 C188.4,237.4 186.7,235.4 184.4,235 C184.1,234.9 183.8,234.7 183.6,234.4 C183.5,234.1 183.5,233.7 183.7,233.4 C184.4,232.5 184.7,231.5 184.7,230.4 C184.7,227.6 182.4,225.3 179.6,225.3 C177.8,225.3 176.2,226.2 175.2,227.8 C175,228.2 174.5,228.4 174.1,228.2 C173.7,228.1 173.4,227.7 173.4,227.3 C173.3,224.6 171.1,222.4 168.4,222.4 C165.6,222.4 163.4,224.7 163.4,227.4 C163.4,228.1 163.5,228.8 163.8,229.4 C163.9,229.7 163.9,230 163.7,230.3 C163.5,230.6 163.3,230.8 163,230.8 C160.1,231.2 155.2,234 155.2,236.4 C155.2,237 154.8,237.4 154.2,237.4 C153.6,237.4 153.2,237 153.2,236.4 C153.2,232.9 158.1,230 161.5,229.1 C161.4,228.6 161.3,228 161.3,227.5 C161.3,223.6 164.5,220.5 168.3,220.5 C171.3,220.5 173.9,222.4 174.9,225.1 C176.2,224 177.8,223.4 179.5,223.4 C183.4,223.4 186.6,226.6 186.6,230.5 C186.6,231.6 186.4,232.6 185.9,233.5 C188.5,234.5 190.2,237 190.2,239.9 C190.2,241.1 189.9,242.2 189.3,243.2 C189.4,243.5 189.1,243.7 188.7,243.7 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M210.6,244.9 C210.4,244.9 210.3,244.9 210.1,244.8 C209.6,244.5 209.4,243.9 209.7,243.4 C210,242.9 210.1,242.3 210.1,241.7 C210.1,240 208.9,238.6 207.2,238.3 C206.9,238.2 206.6,238 206.4,237.7 C206.3,237.4 206.3,237 206.5,236.7 C207,236.1 207.2,235.3 207.2,234.5 C207.2,232.5 205.6,230.8 203.5,230.8 C202.2,230.8 201,231.5 200.4,232.6 C200.2,233 199.7,233.2 199.3,233 C198.9,232.9 198.6,232.5 198.6,232.1 C198.5,230.2 197,228.6 195,228.6 C193,228.6 191.4,230.2 191.4,232.2 C191.4,232.7 191.5,233.2 191.7,233.6 C191.8,233.9 191.8,234.2 191.6,234.5 C191.4,234.8 191.2,235 190.9,235 C190,235.1 189.2,235.6 188.6,236.2 C188.2,236.6 187.6,236.6 187.2,236.3 C186.8,235.9 186.8,235.3 187.1,234.9 C187.8,234.2 188.6,233.6 189.5,233.3 C189.4,232.9 189.4,232.6 189.4,232.2 C189.4,229.1 191.9,226.6 195,226.6 C197.3,226.6 199.3,228 200.1,230 C201.1,229.3 202.3,228.8 203.5,228.8 C206.6,228.8 209.2,231.3 209.2,234.5 C209.2,235.3 209.1,236 208.8,236.7 C210.7,237.6 212,239.5 212,241.7 C212,242.6 211.8,243.5 211.3,244.3 C211.3,244.7 211,244.9 210.6,244.9 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(262.000000, 227.000000)" fill="#C2C5CE" id="Path">
                                <path d="M3.1,9 C3,9 2.8,9 2.7,8.9 C2.1,8.7 1.6,8.6 1,8.6 C0.4,8.6 5.68434189e-14,8.2 5.68434189e-14,7.6 C5.68434189e-14,7 0.4,6.6 1,6.6 C1.9,6.6 2.7,6.8 3.5,7.1 C4,7.3 4.3,7.9 4,8.4 C3.9,8.7 3.5,9 3.1,9 Z" />
                                <path d="M3.1,9 C2.7,9 2.2,8.7 2.1,8.2 C2,7.7 1.9,7.1 1.9,6.6 C1.9,3.1 4.8,0.2 8.3,0.2 C9.9,0.2 11.5,0.8 12.7,1.9 C13.1,2.3 13.1,2.8 12.8,3.3 C12.4,3.8 12.2,4.4 12.2,5 C12.2,5.6 11.8,6 11.2,6 C10.6,6 10.2,5.6 10.2,5 C10.2,4.3 10.4,3.6 10.7,2.9 C10,2.5 9.2,2.2 8.4,2.2 C6,2.2 4,4.2 4,6.6 C4,7 4,7.3 4.1,7.7 C4.2,8.2 3.9,8.8 3.4,8.9 C3.3,9 3.2,9 3.1,9 Z" />
                                <path d="M17.4,7 C17.1,7 16.8,6.9 16.6,6.6 C16.2,6.2 16.3,5.5 16.7,5.2 C17,5 17.3,4.8 17.6,4.6 C17.4,3.2 16.3,2.2 14.9,2.2 C14.1,2.2 13.3,2.6 12.8,3.3 C12.5,3.7 11.8,3.8 11.4,3.5 C11,3.2 10.9,2.5 11.2,2.1 C12.1,0.9 13.5,0.3 14.9,0.3 C17.5,0.3 19.6,2.4 19.6,5 C19.6,5.1 19.6,5.2 19.6,5.3 C19.6,5.7 19.3,6.1 19,6.2 C18.6,6.3 18.3,6.5 18,6.8 C17.9,6.9 17.6,7 17.4,7 Z" />
                                <path d="M11.2,6 C10.6,6 10.2,5.6 10.2,5 C10.2,3.9 10.6,2.9 11.2,2.1 C11.5,1.7 12.2,1.6 12.6,1.9 C13,2.2 13.1,2.9 12.8,3.3 C12.4,3.8 12.2,4.4 12.2,5 C12.2,5.5 11.8,6 11.2,6 Z" />
                                <path d="M24.6,10.5 C24.6,10.5 24.6,10.5 24.6,10.5 C24,10.5 23.6,10 23.6,9.5 C23.6,9.5 23.6,9.4 23.6,9.4 C23.6,7.4 22.1,5.9 20.2,5.9 C19.8,5.9 19.4,6 19,6.1 C18.5,6.3 17.9,6 17.7,5.5 C17.5,5 17.8,4.4 18.3,4.2 C18.9,4 19.6,3.8 20.2,3.8 C23.2,3.8 25.6,6.2 25.6,9.2 C25.6,9.3 25.6,9.3 25.6,9.4 C25.6,10.1 25.1,10.5 24.6,10.5 Z" />
                            </g>
                            <path
                                d="M227.7,239.3 C227.6,239.3 227.6,239.3 227.5,239.3 C227,239.2 226.6,238.6 226.7,238.1 C226.8,237.8 228.4,230.5 233.6,227.5 C236.1,226.1 238.9,225.8 242,226.8 C242.5,227 242.8,227.5 242.7,228.1 C242.5,228.6 242,228.9 241.4,228.8 C238.9,228 236.6,228.2 234.6,229.3 C230.2,231.9 228.6,238.6 228.6,238.6 C228.5,239 228.1,239.3 227.7,239.3 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M66.5,254.7 C65.9,254.7 65.5,254.3 65.5,253.7 C65.5,253.1 65.9,252.7 66.5,252.7 C66.9,252.7 67.2,252.7 67.6,252.6 C70,252.1 71.8,249.9 71.8,247.5 C71.8,244.9 69.8,242.6 67.2,242.3 C66.9,242.3 66.6,242.1 66.5,241.8 C66.3,241.5 66.3,241.2 66.4,240.9 C66.7,240.3 66.8,239.6 66.8,238.9 C66.8,236.1 64.5,233.9 61.8,233.9 C59.1,233.9 56.9,236 56.8,238.8 C56.8,239.2 56.5,239.6 56.1,239.7 C55.7,239.8 55.2,239.6 55,239.3 C54.1,237.8 52.4,236.8 50.6,236.8 C47.8,236.8 45.5,239.1 45.5,241.9 C45.5,242.5 45.1,242.9 44.5,242.9 C43.9,242.9 43.5,242.5 43.5,241.9 C43.5,238 46.7,234.8 50.6,234.8 C52.3,234.8 54,235.4 55.2,236.5 C56.2,233.8 58.8,231.9 61.8,231.9 C65.7,231.9 68.8,235.1 68.8,238.9 C68.8,239.5 68.7,240 68.6,240.5 C71.6,241.4 73.8,244.2 73.8,247.4 C73.8,250.8 71.4,253.8 68,254.5 C67.4,254.6 67,254.7 66.5,254.7 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(84.000000, 208.000000)">
                                <path
                                    d="M28.4,18.8 C28.4,18.8 32.3,45.2 7.6,49.2 C7.6,49.2 -10.5,26 14.9,1.9 C14.9,1.9 21.1,8.7 18.7,26.5 C18.8,26.4 26.4,18.2 28.4,18.8 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                                <g opacity="0.3" transform="translate(1.000000, 1.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M13.9,0.8 C13.9,0.8 2.5,22.4 6.6,48.1 C6.6,48.1 -10.7,24.4 13.9,0.8 Z" />
                                    </g>
                                </g>
                                <g opacity="0.3" transform="translate(7.000000, 18.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M21.4,0.8 C21.4,0.8 16.7,19.9 0.6,31.2 C0.6,31.1 24,30 21.4,0.8 Z" />
                                    </g>
                                </g>
                                <g fill="#C2C5CE">
                                    <path
                                        d="M7.6,50.1 C7.3,50.1 7.1,50 6.9,49.8 C6.7,49.6 6.6,49.2 6.6,48.9 C6.8,48 10.6,27.8 27.8,17.4 C28.1,17.2 28.4,17.2 28.7,17.4 C29,17.5 29.2,17.8 29.3,18.2 C29.4,18.7 31.3,31.6 24.3,41 C20.6,46 15,49.1 7.7,50.1 C7.7,50.1 7.6,50.1 7.6,50.1 Z M27.5,20 C14.7,28.5 10.1,43.3 8.9,47.9 C14.9,46.8 19.5,44.1 22.7,39.8 C27.8,32.9 27.7,23.7 27.5,20 Z"
                                        id="Shape"
                                    />
                                    <path
                                        d="M7.8,49.5 L7.3,48.6 C16.2,43.3 23.9,28.8 24,28.6 L24.9,29.1 C24.6,29.8 17,44.1 7.8,49.5 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M6.7,49.6 C-10.9,21.4 14,1.1 14.3,0.9 C14.7,0.6 15.3,0.6 15.7,1 C22.3,8.8 19.9,25.7 19.8,26.4 L17.8,26.1 C17.8,25.9 20.1,10.5 14.8,3 C10.8,6.6 -6.6,24.2 8.5,48.4 L6.7,49.6 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M7.1,49.2 C6.3,38.1 12.1,16.1 12.3,15.2 L13.3,15.5 C13.2,15.7 7.4,38.3 8.1,49.2 L7.1,49.2 Z"
                                        id="Path"
                                    />
                                </g>
                            </g>
                            <g transform="translate(40.000000, 195.000000)">
                                <path
                                    d="M43.1,9.2 C43.1,9.2 34.7,19.1 41.7,26.8 C48.7,34.5 51.1,41 45.5,45.7 C39.9,50.4 26.7,67.9 26.7,67.9 C26.7,67.9 22,53.8 22.5,47 C22.5,47 16.2,33.6 7.6,27.1 C-0.9,20.6 1.2,4.5 5.2,1.4 C5.2,1.4 17.9,-2.2 25.8,21.9 C25.9,21.9 27.5,7.3 43.1,9.2 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                                <g opacity="0.3" transform="translate(26.000000, 9.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M17.1,0.2 C17.1,0.2 2.7,9.5 7.7,23.3 C7.7,23.3 10,35.6 0.6,58.9 C0.6,58.9 12.7,42.3 16,40 C19.4,37.7 26.9,30.5 17.5,19.6 C17.5,19.6 12.5,17.1 12.7,12.8 C13.1,8.6 15.3,1.4 17.1,0.2 Z" />
                                    </g>
                                </g>
                                <g opacity="0.3" transform="translate(1.000000, 1.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M4.3,0.4 C4.3,0.4 11.3,27.3 25.9,37.4 C25.9,37.4 26.3,40.5 21.5,45 C21.5,45 15.7,34.5 10.4,29.9 C5,25.3 -4.7,17.1 4.3,0.4 Z" />
                                    </g>
                                </g>
                                <g fill="#C2C5CE">
                                    <path
                                        d="M26.7,68.9 C26.7,68.9 26.6,68.9 26.6,68.9 C26.2,68.9 25.9,68.6 25.8,68.2 C24.5,64.6 18.5,46.7 23.7,43.2 C27.7,40.5 26,36.6 25.9,36.4 C25.9,36.3 25.8,36.2 25.8,36.2 C23.6,24.8 24.8,16.7 29.5,12.1 C35,6.6 43.1,8.1 43.5,8.2 C43.8,8.3 44.1,8.5 44.2,8.8 C44.3,9.1 44.3,9.5 44.1,9.7 C39.7,16.5 39.5,20.6 40.1,22.8 C40.7,25 42.3,25.8 42.3,25.9 C42.4,25.9 42.5,26 42.5,26.1 C42.8,26.4 49.9,32.8 49.8,39.6 C49.8,42.3 48.6,44.7 46.3,46.6 C42.8,49.5 38.8,53.9 34.3,59.6 C30.5,64.5 27.7,68.6 27.7,68.6 C27.3,68.8 27,68.9 26.7,68.9 Z M27.6,35.7 C28.5,37.7 29,42 24.7,44.9 C22,46.7 24.3,57.6 27,65.7 C28.2,64 30.2,61.3 32.6,58.2 C37.2,52.4 41.3,48 44.9,44.9 C46.8,43.3 47.7,41.5 47.7,39.4 C47.8,33.9 41.9,28.3 41.2,27.5 C40.6,27.2 38.8,26 38.1,23.3 C37.1,19.8 38.3,15.3 41.5,9.9 C39.1,9.8 34.3,10 30.8,13.4 C26.6,17.7 25.6,25.1 27.6,35.7 Z"
                                        id="Shape"
                                    />
                                    <path
                                        d="M27.3,66.1 L26.4,65.7 C26.5,65.5 36.6,40.8 32.9,32.1 C29,22.9 36.9,14.3 36.9,14.2 L37.6,14.9 C37.5,15 30.1,23.1 33.7,31.7 C37.7,40.9 27.8,65.1 27.3,66.1 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M21.6,47.3 C21.6,47.2 18.5,38.4 7,27.8 C-5.4,16.5 4.4,1 4.5,0.8 C4.6,0.6 4.9,0.4 5.1,0.4 C11.3,-0.8 17,2.6 21.7,10.2 C25.1,15.8 26.8,21.6 26.9,21.8 L25,22.3 C24.9,22.1 18.6,0.3 6,2.2 C4.6,4.5 -1.8,16.9 8.4,26.3 C20.3,37.2 23.4,46.2 23.5,46.6 L21.6,47.3 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M26.5,38.8 C16.1,25.1 9.9,10.3 9.8,10.2 L10.7,9.8 C10.8,9.9 16.9,24.6 27.3,38.2 L26.5,38.8 Z"
                                        id="Path"
                                    />
                                </g>
                            </g>
                            <g transform="translate(237.000000, 195.000000)">
                                <path
                                    d="M4,13.4 C4,13.4 -7.5,37.4 14.9,48.5 C14.9,48.5 39.1,31.6 21.8,1.1 C21.8,1.1 13.9,5.8 10.9,23.5 C10.9,23.5 6,13.4 4,13.4 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                                <g opacity="0.3" transform="translate(14.000000, 1.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M7.8,0.1 C7.8,0.1 12.4,24.1 0.9,47.5 C0.9,47.5 24.4,29.9 7.8,0.1 Z" />
                                    </g>
                                </g>
                                <g opacity="0.3" transform="translate(0.000000, 13.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M4,0.4 C4,0.4 2.9,20.1 14.9,35.5 C14.9,35.5 -7.1,27.6 4,0.4 Z" />
                                    </g>
                                </g>
                                <g fill="#C2C5CE">
                                    <path
                                        d="M14.9,49.5 C14.8,49.5 14.6,49.5 14.5,49.4 C7.9,46.3 3.4,41.7 1.4,35.8 C-2.5,24.7 3.1,13 3.3,12.5 C3.4,12.2 3.7,12 4.1,11.9 C4.4,11.9 4.8,12 5,12.2 C18.4,27.1 16.1,47.6 16,48.5 C16,48.8 15.8,49.1 15.5,49.3 C15.2,49.5 15.1,49.5 14.9,49.5 Z M4.5,14.8 C3.2,18.2 0.4,27 3.3,35.1 C5.1,40.1 8.7,44.1 14.1,46.9 C14.3,42.2 14.2,26.8 4.5,14.8 Z"
                                        id="Shape"
                                    />
                                    <path
                                        d="M14.5,48.8 C7.3,40.9 4.3,25 4.2,24.3 L5.2,24.1 C5.2,24.3 8.3,40.4 15.3,48.1 L14.5,48.8 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M15.6,49.3 L14.3,47.8 C35.8,29.1 24.4,7.2 21.6,2.6 C14.4,8.2 12,23.6 12,23.8 L10,23.5 C10.1,22.8 12.7,5.9 21.4,0.4 C21.9,0.1 22.5,0.2 22.8,0.7 C22.8,0.8 40.6,27.5 15.6,49.3 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M15.4,48.7 L14.5,48.3 C18.5,38 19.5,14.8 19.5,14.6 L20.5,14.6 C20.4,15.6 19.4,38.3 15.4,48.7 Z"
                                        id="Path"
                                    />
                                </g>
                            </g>
                            <g transform="translate(260.000000, 196.000000)">
                                <path
                                    d="M28.2,18.6 C28.2,18.6 32.1,45 7.4,49 C7.4,49 -10.7,25.8 14.7,1.7 C14.7,1.7 20.9,8.5 18.5,26.3 C18.6,26.3 26.2,18.1 28.2,18.6 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                                <g opacity="0.3" transform="translate(1.000000, 1.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M13.7,0.6 C13.7,0.6 2.3,22.2 6.4,47.900314 C6.4,48 -10.9,24.2 13.7,0.6 Z" />
                                    </g>
                                </g>
                                <g opacity="0.3" transform="translate(7.000000, 18.000000)" fill="#C2C5CE" id="Path">
                                    <g>
                                        <path d="M21.2,0.6 C21.2,0.6 16.5,19.7 0.4,31 C0.4,31 23.8,29.9 21.2,0.6 Z" />
                                    </g>
                                </g>
                                <g fill="#C2C5CE">
                                    <path
                                        d="M7.4,50 C7.1,50 6.9,49.9 6.7,49.7 C6.5,49.5 6.4,49.1 6.4,48.8 C6.6,47.9 10.4,27.7 27.6,17.3 C27.9,17.1 28.2,17.1 28.5,17.3 C28.8,17.4 29,17.7 29.1,18.1 C29.2,18.6 31.1,31.5 24.1,40.9 C20.4,45.9 14.8,49 7.5,50 C7.5,49.9 7.4,50 7.4,50 Z M27.3,19.9 C14.5,28.4 9.9,43.2 8.7,47.8 C14.7,46.7 19.3,44 22.5,39.7 C27.6,32.7 27.5,23.5 27.3,19.9 Z"
                                        id="Shape"
                                    />
                                    <path
                                        d="M7.6,49.4 L7.1,48.5 C16,43.2 23.7,28.7 23.8,28.5 L24.7,29 C24.4,29.6 16.8,43.9 7.6,49.4 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M6.5,49.5 C-11.1,21.3 13.8,1 14.1,0.8 C14.5,0.5 15.1,0.5 15.5,0.9 C22.1,8.7 19.7,25.6 19.6,26.3 L17.6,26 C17.6,25.8 19.9,10.4 14.6,2.9 C10.6,6.5 -6.8,24.1 8.3,48.3 L6.5,49.5 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M6.9,49 C6.1,37.9 11.9,15.9 12.1,15 L13.1,15.3 C13,15.5 7.2,38.1 7.9,49 L6.9,49 Z"
                                        id="Path"
                                    />
                                </g>
                            </g>
                            <path
                                d="M83,259.3 C82.4,259.3 82,258.9 82,258.3 L82,253.3 C82,252.7 82.4,252.3 83,252.3 C83.6,252.3 84,252.7 84,253.3 L84,258.3 C84,258.9 83.6,259.3 83,259.3 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M156,251.5 C155.4,251.5 155,251.1 155,250.5 L155,248 C155,247.4 155.4,247 156,247 C156.6,247 157,247.4 157,248 L157,250.5 C157,251 156.6,251.5 156,251.5 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(25.000000, 59.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M19.5,4.9 C18.2,4.9 17.1,5.3 16.1,6.1 C15.2,2.7 12.2,0.3 8.6,0.3 C4.3,0.3 0.8,3.8 0.8,8.1 C0.8,12.4 4.3,15.9 8.6,15.9 L19.5,15.9 C22.5,15.9 25,13.5 25,10.4 C24.9,7.3 22.5,4.9 19.5,4.9 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(6.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M9.7,5 C8.5,2.2 5.8,0.2 2.5,0.2 C1.7,0.2 0.9,0.3 0.2,0.6 C2.8,1.4 4.7,3.5 5.4,6.1 C6.3,5.4 7.5,4.9 8.8,4.9 C9.1,4.9 9.4,4.9 9.7,5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(8.000000, 4.000000)">
                                            <g>
                                                <path d="M5.5,0.9 C4.7,0.9 3.9,1.1 3.2,1.4 C5,2.3 6.3,4.1 6.3,6.3 C6.3,9.3 3.9,11.8 0.8,11.8 L5.5,11.8 C8.5,11.8 11,9.4 11,6.3 C10.9,3.3 8.5,0.9 5.5,0.9 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M20.5,17.8 L9.6,17.8 C9.1,17.8 8.5,17.7 8,17.6 C7.7,17.6 7.5,17.4 7.4,17.2 C7.3,17 7.2,16.7 7.2,16.4 C7.3,15.9 7.8,15.5 8.4,15.6 C8.8,15.7 9.2,15.7 9.6,15.7 L17.1,15.7 C17.1,15.7 17.1,15.7 17.2,15.7 L20.5,15.7 C23,15.7 24.9,13.7 25,11.2 C25,8.8 23,6.8 20.5,6.7 C19.5,6.7 18.5,7 17.7,7.7 L16.5,8.7 L16.1,7.2 C15.3,4.2 12.6,2.1 9.5,2.1 C5.7,2.1 2.7,5.2 2.7,8.9 C2.7,10.9 3.6,12.8 5.2,14.1 C5.6,14.5 5.7,15.1 5.3,15.5 C5,15.9 4.3,16 3.9,15.6 C1.9,13.9 0.7,11.5 0.7,8.8 C0.7,3.9 4.6,-2.84217094e-14 9.5,-2.84217094e-14 C13,-2.84217094e-14 16.2,2.1 17.6,5.3 C18.5,4.9 19.4,4.6 20.4,4.6 C24,4.6 26.9,7.5 26.9,11.1 C27,14.9 24,17.8 20.5,17.8 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(85.000000, 26.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M14.7,3.9 C13.8,3.9 12.9,4.2 12.2,4.8 C11.6,2.3 9.3,0.5 6.6,0.5 C3.4,0.5 0.9,3.1 0.9,6.2 C0.9,9.4 3.5,11.9 6.6,11.9 L14.6,11.9 C16.8,11.9 18.6,10.1 18.6,7.9 C18.7,5.7 16.9,3.9 14.7,3.9 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(5.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M7,4 C6.1,1.9 4.1,0.5 1.7,0.5 C1.1,0.5 0.5,0.6 0,0.8 C1.9,1.4 3.3,2.9 3.8,4.8 C4.5,4.3 5.3,3.9 6.3,3.9 C6.5,3.9 6.7,4 7,4 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(6.000000, 3.000000)">
                                            <g>
                                                <path d="M3.7,0.9 C3.1,0.9 2.5,1.1 2,1.3 C3.4,1.9 4.3,3.3 4.3,4.9 C4.3,7.1 2.5,8.9 0.3,8.9 L3.7,8.9 C5.9,8.9 7.7,7.1 7.7,4.9 C7.7,2.7 5.9,0.9 3.7,0.9 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M12.4,14 L7.7,14 C4,14 0.9,11 0.9,7.2 C0.9,3.5 3.9,0.5 7.7,0.5 C10.3,0.5 12.7,2 13.8,4.3 C15.6,3.5 17.7,3.9 19.1,5.2 C20.1,6.1 20.7,7.5 20.7,8.9 C20.7,11.4 18.9,13.5 16.5,13.9 C15.9,14 15.4,13.6 15.3,13.1 C15.2,12.6 15.6,12.1 16.1,12 C17.6,11.8 18.6,10.5 18.6,9 C18.6,8.1 18.2,7.3 17.6,6.8 C16.5,5.8 14.8,5.8 13.7,6.7 L12.5,7.7 L12.1,6.2 C11.6,4.1 9.7,2.6 7.5,2.6 C4.9,2.6 2.8,4.7 2.7,7.3 C2.7,9.9 4.8,12 7.5,12.1 L12.2,12.1 C12.7,12.1 13.2,12.6 13.2,13.1 C13.4,13.5 12.9,14 12.4,14 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(74.000000, 240.000000)" fill="#C2C5CE" id="Oval">
                                <circle cx="190.5" cy="11.5" r="1" />
                                <circle cx="181.5" cy="13.5" r="1" />
                                <circle cx="135.5" cy="11.5" r="1" />
                                <circle cx="137.5" cy="20.5" r="1" />
                                <circle cx="117.5" cy="17.5" r="1" />
                                <circle cx="107.5" cy="12.5" r="1" />
                                <circle cx="165.5" cy="20.5" r="1" />
                                <circle cx="101.5" cy="16.5" r="1" />
                                <circle cx="94.5" cy="15.5" r="1" />
                                <circle cx="93.5" cy="3.5" r="1" />
                                <circle cx="112.5" cy="19.5" r="1" />
                                <circle cx="114.5" cy="11.5" r="1" />
                                <circle cx="109.5" cy="9.5" r="1" />
                                <circle cx="90.5" cy="20.5" r="1" />
                                <circle cx="80.5" cy="15.5" r="1" />
                                <circle cx="73.5" cy="19.5" r="1" />
                                <circle cx="67.5" cy="17.5" r="1" />
                                <circle cx="66.5" cy="6.5" r="1" />
                                <circle cx="85.5" cy="22.5" r="1" />
                                <circle cx="87.5" cy="14.5" r="1" />
                                <circle cx="91.5" cy="12.5" r="1" />
                                <circle cx="176.5" cy="18.5" r="1" />
                                <circle cx="166.5" cy="15.5" r="1" />
                                <circle cx="157.5" cy="18.5" r="1" />
                                <circle cx="150.5" cy="16.5" r="1" />
                                <circle cx="152.5" cy="4.5" r="1" />
                                <circle cx="171.5" cy="20.5" r="1" />
                                <circle cx="173.5" cy="12.5" r="1" />
                                <circle cx="168.5" cy="10.5" r="1" />
                                <circle cx="60.5" cy="20.5" r="1" />
                                <circle cx="49.5" cy="14.5" r="1" />
                                <circle cx="41.5" cy="20.5" r="1" />
                                <circle cx="34.5" cy="17.5" r="1" />
                                <circle cx="38.5" cy="7.5" r="1" />
                                <circle cx="52.5" cy="21.5" r="1" />
                                <circle cx="57.5" cy="14.5" r="1" />
                                <circle cx="52.5" cy="12.5" r="1" />
                                <circle cx="28.5" cy="20.5" r="1" />
                                <circle cx="3.5" cy="22.5" r="1" />
                                <circle cx="13.5" cy="23.5" r="1" />
                                <circle cx="0.5" cy="17.5" r="1" />
                                <circle cx="4.5" cy="15.5" r="1" />
                                <circle cx="20.5" cy="21.5" r="1" />
                                <circle cx="10.5" cy="21.5" r="1" />
                                <circle cx="5.5" cy="19.5" r="1" />
                                <circle cx="83.5" cy="2.5" r="1" />
                                <circle cx="73.5" cy="8.5" r="1" />
                                <circle cx="70.5" cy="5.5" r="1" />
                                <circle cx="66.5" cy="14.5" r="1" />
                                <circle cx="88.5" cy="9.5" r="1" />
                                <circle cx="90.5" cy="1.5" r="1" />
                                <circle cx="82.5" cy="19.5" r="1" />
                                <circle cx="149.5" cy="19.5" r="1" />
                                <circle cx="139.5" cy="14.5" r="1" />
                                <circle cx="133.5" cy="18.5" r="1" />
                                <circle cx="126.5" cy="17.5" r="1" />
                                <circle cx="125.5" cy="10.5" r="1" />
                                <circle cx="144.5" cy="21.5" r="1" />
                                <circle cx="146.5" cy="13.5" r="1" />
                                <circle cx="141.5" cy="11.5" r="1" />
                                <circle cx="121.5" cy="21.5" r="1" />
                                <circle cx="109.5" cy="18.5" r="1" />
                                <circle cx="103.5" cy="22.5" r="1" />
                                <circle cx="96.5" cy="21.5" r="1" />
                                <circle cx="95.5" cy="9.5" r="1" />
                                <circle cx="114.5" cy="25.5" r="1" />
                                <circle cx="120.5" cy="17.5" r="1" />
                                <circle cx="111.5" cy="15.5" r="1" />
                                <circle cx="194.5" cy="15.5" r="1" />
                                <circle cx="181.5" cy="10.5" r="1" />
                                <circle cx="178.5" cy="15.5" r="1" />
                                <circle cx="171.5" cy="14.5" r="1" />
                                <circle cx="170.5" cy="3.5" r="1" />
                                <circle cx="186.5" cy="17.5" r="1" />
                                <circle cx="191.5" cy="8.5" r="1" />
                                <circle cx="186.5" cy="7.5" r="1" />
                                <circle cx="105.5" cy="8.5" r="1" />
                                <circle cx="96.5" cy="3.5" r="1" />
                                <circle cx="90.5" cy="7.5" r="1" />
                                <circle cx="183.5" cy="20.5" r="1" />
                                <circle cx="100.5" cy="10.5" r="1" />
                                <circle cx="103.5" cy="2.5" r="1" />
                                <circle cx="51.5" cy="15.5" r="1" />
                                <circle cx="41.5" cy="10.5" r="1" />
                                <circle cx="35.5" cy="14.5" r="1" />
                                <circle cx="17.5" cy="19.5" r="1" />
                                <circle cx="25.5" cy="23.5" r="1" />
                                <circle cx="47.5" cy="19.5" r="1" />
                                <circle cx="48.5" cy="9.5" r="1" />
                                <circle cx="43.5" cy="7.5" r="1" />
                                <circle cx="141.5" cy="16.5" r="1" />
                                <circle cx="131.5" cy="11.5" r="1" />
                                <circle cx="125.5" cy="12.5" r="1" />
                                <circle cx="118.5" cy="14.5" r="1" />
                                <circle cx="119.5" cy="8.5" r="1" />
                                <circle cx="133.5" cy="15.5" r="1" />
                                <circle cx="138.5" cy="7.5" r="1" />
                                <circle cx="130.5" cy="8.5" r="1" />
                                <circle cx="101.5" cy="5.5" r="1" />
                                <circle cx="91.5" cy="0.5" r="1" />
                                <circle cx="85.5" cy="4.5" r="1" />
                                <circle cx="78.5" cy="3.5" r="1" />
                                <circle cx="77.5" cy="21.5" r="1" />
                                <circle cx="96.5" cy="7.5" r="1" />
                                <circle cx="103.5" cy="14.5" r="1" />
                                <circle cx="98.5" cy="12.5" r="1" />
                            </g>
                            <path
                                d="M274,269 C273.4,269 273,268.6 273,268 L273,262 C273,261.4 273.4,261 274,261 C274.6,261 275,261.4 275,262 L275,268 C275,268.6 274.6,269 274,269 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M280,269 C279.4,269 279,268.6 279,268 L279,257 C279,256.4 279.4,256 280,256 C280.6,256 281,256.4 281,257 L281,268 C281,268.6 280.6,269 280,269 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M283,268 C282.4,268 282,267.6 282,267 L282,263 C282,262.4 282.4,262 283,262 C283.6,262 284,262.4 284,263 L284,267 C284,267.6 283.6,268 283,268 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M55,269 C54.4,269 54,268.6 54,268 L54,262 C54,261.4 54.4,261 55,261 C55.6,261 56,261.4 56,262 L56,268 C56,268.6 55.6,269 55,269 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M52,269 C51.4,269 51,268.6 51,268 L51,264 C51,263.4 51.4,263 52,263 C52.6,263 53,263.4 53,264 L53,268 C53,268.6 52.6,269 52,269 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M108,180 C108,180 127.7,189 142.4,201 C157.1,213 172.6,221.4 204,205.8 C204,205.8 221.5,194.1 226.2,188.4 C230.9,182.7 245.7,174.2 250.8,174.4 C250.8,174.4 233.5,179.4 226.5,184.1 C219.5,188.8 186.8,198.4 168,196.1 C149.2,193.8 128.8,186.4 128.8,186.4 L108,180 Z"
                                id="Path"
                                fill="#FFFFFF"
                            />
                            <g opacity="0.3" transform="translate(117.000000, 182.000000)" fill="#C2C5CE" id="Path">
                                <g>
                                    <path d="M2.9,3.5 L20.9,12.3 C20.9,12.3 33.9,21.2 47.6,21.8 C61.3,22.5 82.1,18.6 85.4,17 C88.7,15.3 107,3.5 107,3.5 C107,3.5 79.8,12.7 71.6,13.5 C63.4,14.3 44.8,15 32.8,11.1 C20.8,7.2 0.1,0.7 0.1,0.7 L2.9,3.5 Z" />
                                </g>
                            </g>
                            <g transform="translate(98.000000, 173.000000)" fill="#C2C5CE" id="Path">
                                <path d="M76.6,42.9 C66.7,42.9 57.4,39.5 48.8,32.7 C16.9,7.4 0.9,5.1 0.7,5.1 L0.9,3.1 C1.6,3.2 17.5,5.4 50,31.1 C71.8,48.4 97.8,42.8 127.4,14.6 C139,3.5 152.1,0.4 152.7,0.3 L153.1,2.2 C153,2.2 139.9,5.3 128.8,16 C121.6,22.8 110.6,32.6 97.1,38.3 C90.1,41.4 83.2,42.9 76.6,42.9 Z" />
                                <path d="M75.7,24.6 C67.7,24.6 60.3,23.6 53.7,21.5 C25.9,12.9 6.6,6.7 6.4,6.6 L7,4.7 C7.2,4.8 26.5,10.9 54.3,19.6 C82.4,28.3 118.9,16.1 136.5,5.7 C136.7,5.6 141.3,3.1 152.8,0.3 L153.3,2.2 C142.1,4.9 137.6,7.4 137.5,7.4 C126.9,13.7 112.9,18.9 99.3,21.9 C91,23.8 83.1,24.6 75.7,24.6 Z" />
                                <path d="M72.7,32.1 C63.5,32.1 54.7,30.7 48.5,27.2 C32.3,18.1 20.8,13.1 20.7,13.1 L21.5,11.3 C21.6,11.3 33.2,16.4 49.5,25.5 C65.4,34.4 101,29 111.9,20.8 C124.1,11.6 144.4,3.4 144.6,3.3 L145.4,5.2 C145.2,5.3 125.2,13.5 113.1,22.5 C105.8,27.9 88.6,32.1 72.7,32.1 Z" />
                            </g>
                            <path
                                d="M254.4,177.7 C254.3,177.7 254.2,177.7 254.1,177.6 L248.7,172.6 C248.5,172.4 248.5,172.1 248.7,171.9 C248.9,171.7 249.2,171.7 249.4,171.9 L254.8,176.9 C255,177.1 255,177.4 254.8,177.6 C254.7,177.7 254.5,177.7 254.4,177.7 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <path
                                d="M96.8,180.3 C96.7,180.3 96.5,180.3 96.5,180.2 C96.3,180 96.3,179.7 96.5,179.5 L101.8,174.1 C102,173.9 102.3,173.9 102.5,174.1 C102.7,174.3 102.7,174.6 102.5,174.8 L97.2,180.2 C97,180.3 96.9,180.3 96.8,180.3 Z"
                                id="Path"
                                fill="#C2C5CE"
                            />
                            <g transform="translate(0.000000, 131.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M19.2,5.4 C17.9,5.4 16.8,5.8 15.8,6.6 C14.9,3.2 11.9,0.8 8.3,0.8 C4,0.8 0.5,4.3 0.5,8.6 C0.5,12.9 4,16.4 8.3,16.4 L19.2,16.4 C22.2,16.4 24.7,14 24.7,10.9 C24.7,7.8 22.2,5.4 19.2,5.4 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(6.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M9.5,5.5 C8.3,2.7 5.6,0.7 2.3,0.7 C1.5,0.7 0.7,0.8 0,1.1 C2.6,1.9 4.5,4 5.2,6.6 C6.1,5.9 7.3,5.4 8.6,5.4 C8.9,5.4 9.2,5.4 9.5,5.5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(8.000000, 5.000000)">
                                            <g>
                                                <path d="M5.2,0.4 C4.4,0.4 3.6,0.6 2.9,0.9 C4.7,1.8 6,3.6 6,5.8 C6,8.8 3.6,11.3 0.5,11.3 L5.2,11.3 C8.2,11.3 10.7,8.9 10.7,5.8 C10.7,2.8 8.2,0.4 5.2,0.4 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M16.9,18.3 C16.9,18.3 16.9,18.3 16.9,18.3 L9.3,18.3 C8.8,18.3 8.2,18.2 7.7,18.2 C7.1,18.1 6.8,17.6 6.9,17 C7,16.4 7.5,16.1 8.1,16.2 C8.5,16.3 8.9,16.3 9.3,16.3 L20.2,16.3 C22.7,16.3 24.7,14.3 24.7,11.8 C24.7,9.3 22.7,7.3 20.2,7.3 C19.2,7.3 18.2,7.6 17.4,8.3 L16.2,9.3 L15.8,7.8 C15,4.8 12.3,2.7 9.2,2.7 C5.5,2.7 2.4,5.8 2.4,9.5 C2.4,11.5 3.3,13.4 4.9,14.7 C5.3,15.1 5.4,15.7 5,16.1 C4.7,16.5 4,16.6 3.6,16.2 C1.6,14.5 0.4,12.1 0.4,9.4 C0.4,4.5 4.4,0.6 9.2,0.6 C12.7,0.6 15.9,2.7 17.3,5.9 C18.2,5.5 19.2,5.2 20.1,5.2 C23.7,5.2 26.6,8.1 26.6,11.7 C26.6,15.3 23.7,18.2 20.1,18.2 L16.9,18.2 L16.9,18.3 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(269.000000, 150.000000)" id="Path">
                                <g transform="translate(1.000000, 1.000000)">
                                    <path
                                        d="M13,3.5 C12.1,3.5 11.4,3.8 10.7,4.3 C10.1,2 8.1,0.4 5.6,0.4 C2.7,0.4 0.3,2.8 0.3,5.7 C0.3,8.6 2.7,11 5.6,11 L13,11 C15,11 16.7,9.4 16.7,7.3 C16.6,5.1 15,3.5 13,3.5 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(4.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M6.4,3.5 C5.6,1.6 3.7,0.3 1.6,0.3 C1,0.3 0.5,0.4 -5.68434189e-14,0.5 C1.7,1 3.1,2.4 3.5,4.2 C4.1,3.7 4.9,3.4 5.8,3.4 C6,3.5 6.2,3.5 6.4,3.5 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(5.000000, 3.000000)">
                                            <g>
                                                <path d="M4,0.5 C3.4,0.5 2.9,0.6 2.4,0.9 C3.6,1.5 4.5,2.8 4.5,4.2 C4.5,6.2 2.9,7.9 0.8,7.9 L4,7.9 C6,7.9 7.7,6.3 7.7,4.2 C7.6,2.1 6,0.5 4,0.5 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M13.7,12.8 L6.6,12.8 C3.1,12.8 0.4,10 0.4,6.5 C0.4,3.1 3.2,0.3 6.6,0.3 C9,0.3 11.1,1.6 12.2,3.8 C12.8,3.6 13.3,3.5 14,3.5 C16.6,3.5 18.7,5.6 18.7,8.2 C18.7,9.1 18.5,9.9 18,10.6 L18,10.6 C17.7,11.1 17.1,11.2 16.6,10.9 C16.1,10.6 16,10 16.3,9.5 C16.6,9.1 16.7,8.6 16.7,8.1 C16.7,6.6 15.5,5.4 14,5.4 C13.4,5.4 12.8,5.6 12.3,6 L11.1,7 L10.7,5.5 C10.2,3.6 8.5,2.3 6.6,2.3 C4.3,2.3 2.4,4.2 2.4,6.5 C2.4,8.8 4.3,10.7 6.6,10.8 L13.7,10.8 C14.2,10.8 14.7,11.2 14.7,11.8 C14.7,12.4 14.3,12.8 13.7,12.8 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(192.000000, 113.000000)" id="Path">
                                <g transform="translate(1.000000, 2.000000)">
                                    <path
                                        d="M14.5,3.7 C13.5,3.7 12.6,4 11.9,4.6 C11.2,2 8.9,0.1 6.1,0.1 C2.8,0.1 0.1,2.8 0.1,6.1 C0.1,9.4 2.8,12.1 6.1,12.1 L14.5,12.1 C16.8,12.1 18.7,10.2 18.7,7.9 C18.8,5.6 16.9,3.7 14.5,3.7 Z"
                                        fill="#FFFFFF"
                                    />
                                    <g opacity="0.2" transform="translate(4.000000, 0.000000)" fill="#C2C5CE">
                                        <g opacity="0.13">
                                            <g>
                                                <path d="M7.6,3.7 C6.7,1.5 4.6,0 2.1,0 C1.5,0 0.9,0.1 0.3,0.3 C2.3,0.9 3.8,2.5 4.3,4.5 C5,3.9 5.9,3.6 6.9,3.6 C7.2,3.7 7.4,3.7 7.6,3.7 Z" />
                                            </g>
                                        </g>
                                        <g opacity="0.13" transform="translate(6.000000, 3.000000)">
                                            <g>
                                                <path d="M4.5,0.7 C3.9,0.7 3.2,0.9 2.7,1.1 C4.1,1.8 5.1,3.2 5.1,4.9 C5.1,7.2 3.2,9.1 0.9,9.1 L4.5,9.1 C6.8,9.1 8.7,7.2 8.7,4.9 C8.8,2.6 6.9,0.7 4.5,0.7 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g opacity="0.53" fill="#C2C5CE">
                                    <g>
                                        <path d="M15.5,15.1 L14.3,15.1 C13.8,15.1 13.3,14.6 13.3,14.1 C13.3,13.5 13.8,13.1 14.3,13.1 L15.5,13.1 C16.4,13.1 17.2,12.8 17.8,12.2 C18.4,11.6 18.8,10.8 18.8,9.9 C18.8,9.6 18.8,9.3 18.7,9 C18.7,9 18.7,9 18.7,8.9 C18.3,7.6 17,6.6 15.6,6.6 C14.9,6.6 14.2,6.8 13.6,7.3 L12.4,8.2 L12,6.7 C11.4,4.5 9.4,2.9 7.1,2.9 C4.3,2.9 2.1,5.2 2.1,7.9 C2.1,10.7 4.4,12.9 7.1,12.9 L10.4,12.9 C10.9,12.9 11.4,13.3 11.4,13.9 C11.4,14.4 10.9,14.9 10.4,14.9 L7.1,14.9 C3.2,14.9 0.1,11.8 0.1,7.9 C0.1,4 3.3,0.9 7.1,0.9 C9.8,0.9 12.3,2.5 13.5,5 C14.2,4.7 14.9,4.6 15.6,4.6 C17.9,4.6 20,6.2 20.6,8.4 C20.7,8.9 20.8,9.4 20.8,9.8 C20.8,12.8 18.4,15.1 15.5,15.1 Z" />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(135.000000, 139.000000)" fill="#C2C5CE" id="Path">
                                <path d="M9,5.8 C8.4,5.8 8,5.4 8,4.8 C8,3.2 6.7,2 5.2,2 C3.7,2 2.4,3.3 2.4,4.8 C2.4,5.4 2,5.8 1.4,5.8 C0.8,5.8 0.4,5.4 0.4,4.8 C0.4,2.1 2.6,0 5.2,0 C7.9,0 10,2.2 10,4.8 C10,5.4 9.6,5.8 9,5.8 Z" />
                                <path d="M16.7,5.8 C16.1,5.8 15.7,5.4 15.7,4.8 C15.7,3.2 14.4,2 12.9,2 C11.4,2 10.1,3.3 10.1,4.8 C10.1,5.4 9.7,5.8 9.1,5.8 C8.5,5.8 8.1,5.4 8.1,4.8 C8.1,2.1 10.3,0 12.9,0 C15.6,0 17.7,2.2 17.7,4.8 C17.7,5.4 17.2,5.8 16.7,5.8 Z" />
                            </g>
                            <g transform="translate(161.000000, 146.000000)" fill="#C2C5CE" id="Path">
                                <path d="M7,5.1 C6.4,5.1 6,4.7 6,4.1 C6,3.1 5.2,2.2 4.1,2.2 C3,2.2 2.2,3 2.2,4.1 C2.2,4.7 1.8,5.1 1.2,5.1 C0.6,5.1 0.2,4.7 0.2,4.1 C0.2,2 1.9,0.2 4.1,0.2 C6.2,0.2 8,1.9 8,4.1 C8,4.6 7.6,5.1 7,5.1 Z" />
                                <path d="M12.8,5.1 C12.2,5.1 11.8,4.7 11.8,4.1 C11.8,3.1 11,2.2 9.9,2.2 C8.8,2.2 8,3 8,4.1 C8,4.7 7.6,5.1 7,5.1 C6.4,5.1 6,4.7 6,4.1 C6,2 7.7,0.2 9.9,0.2 C12,0.2 13.8,1.9 13.8,4.1 C13.8,4.6 13.3,5.1 12.8,5.1 Z" />
                            </g>
                        </g>
                        <g id="Group">
                            <g transform="translate(20.000000, 317.000000)">
                                <g transform="translate(23.000000, 14.000000)">
                                    <path
                                        d="M11.1,19.7 C11,19.7 10.9,19.6 10.8,19.5 C9.2,13.5 8,12.2 1.9,10.6 C1.8,10.6 1.7,10.5 1.7,10.3 C1.7,10.1 1.8,10.1 1.9,10 C7.9,8.4 9.2,7.2 10.8,1.1 C10.8,1 10.9,1 10.9,0.9 C10.9,0.9 11,0.9 11,0.8 L11.2,0.8 C11.2,0.8 11.3,0.8 11.3,0.9 C11.3,0.9 11.3,1 11.4,1 C13,7.1 14.3,8.3 20.3,9.9 C20.4,9.9 20.4,10 20.5,10 C20.5,10 20.5,10.1 20.6,10.1 L20.6,10.3 C20.6,10.3 20.6,10.4 20.5,10.4 C20.5,10.4 20.4,10.4 20.4,10.5 C14.3,12.1 13.1,13.4 11.5,19.4 C11.3,19.6 11.2,19.7 11.1,19.7 Z"
                                        id="Path"
                                        fill="#FFFFFF"
                                    />
                                    <path
                                        d="M11.1,3.9 C12.4,7.6 13.9,9.1 17.6,10.4 C13.9,11.7 12.4,13.2 11.1,16.9 C9.8,13.2 8.3,11.7 4.6,10.4 C8.3,9.1 9.8,7.6 11.1,3.9 L11.1,3.9 Z M11.1,0 C11.1,0 11.1,0 11.1,0 C11,0 10.8,0 10.7,0.1 C10.5,0.2 10.4,0.2 10.2,0.4 C10.1,0.5 10,0.6 9.9,0.8 C9.9,0.9 9.8,1 9.8,1 C8.3,6.7 7.3,7.7 1.6,9.2 C1,9.3 0.7,9.8 0.7,10.4 C0.7,11 1.1,11.5 1.6,11.6 C7.3,13.1 8.3,14.1 9.8,19.8 C9.9,20.4 10.4,20.7 11,20.7 C11.6,20.7 12.1,20.3 12.2,19.8 C13.7,14.1 14.7,13.1 20.4,11.6 C20.5,11.6 20.6,11.6 20.6,11.5 C20.8,11.4 20.9,11.3 21,11.2 C21.1,11.1 21.2,10.9 21.3,10.8 C21.3,10.7 21.4,10.5 21.4,10.4 C21.4,10.3 21.4,10.1 21.3,10 C21.2,9.8 21.2,9.7 21,9.6 C20.9,9.5 20.8,9.4 20.6,9.3 C20.5,9.3 20.4,9.2 20.4,9.2 C14.7,7.7 13.7,6.7 12.2,1 C12.2,0.9 12.2,0.8 12.1,0.8 C12,0.6 11.9,0.5 11.8,0.4 C11.7,0.3 11.5,0.2 11.4,0.2 C11.4,0 11.2,0 11.1,0 L11.1,0 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                                <path
                                    d="M34.1,47.9 C33.5,47.9 33.1,47.5 33.1,46.9 L33.1,45.4 C33.1,44.8 33.5,44.4 34.1,44.4 C34.7,44.4 35.1,44.8 35.1,45.4 L35.1,46.9 C35.1,47.4 34.6,47.9 34.1,47.9 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M34.1,4.4 C33.5,4.4 33.1,4 33.1,3.4 L33.1,1.9 C33.1,1.3 33.5,0.9 34.1,0.9 C34.7,0.9 35.1,1.3 35.1,1.9 L35.1,3.4 C35.1,3.9 34.6,4.4 34.1,4.4 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M34.1,66.6 C33.5,66.6 33.1,66.2 33.1,65.6 L33.1,54.1 C33.1,53.5 33.5,53.1 34.1,53.1 C34.7,53.1 35.1,53.5 35.1,54.1 L35.1,65.6 C35.1,66.2 34.6,66.6 34.1,66.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M60.8,25.6 L59.3,25.6 C58.7,25.6 58.3,25.2 58.3,24.6 C58.3,24 58.7,23.6 59.3,23.6 L60.8,23.6 C61.4,23.6 61.8,24 61.8,24.6 C61.8,25.2 61.4,25.6 60.8,25.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M12.5,25.6 L1,25.6 C0.4,25.6 0,25.2 0,24.6 C0,24 0.4,23.6 1,23.6 L12.5,23.6 C13.1,23.6 13.5,24 13.5,24.6 C13.5,25.2 13.1,25.6 12.5,25.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(397.000000, 104.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(442.000000, 123.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g opacity="0.3" transform="translate(421.000000, 144.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g transform="translate(12.000000, 124.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(69.000000, 62.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g opacity="0.3" transform="translate(39.000000, 115.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g opacity="0.3" transform="translate(0.000000, 177.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g opacity="0.3" transform="translate(389.000000, 242.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="410" cy="275.6" r="2" />
                            <g opacity="0.3" transform="translate(376.000000, 332.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g transform="translate(16.000000, 262.000000)" id="Path">
                                <path d="M1,1.6 C2.7,1.6 4,2.9 4,4.6 C4,6.3 2.7,7.6 1,7.6" fill="#FFFFFF" />
                                <path
                                    d="M1,8.6 C0.4,8.6 0,8.2 0,7.6 C0,7 0.4,6.6 1,6.6 C2.1,6.6 3,5.7 3,4.6 C3,3.5 2.1,2.6 1,2.6 C0.4,2.6 0,2.2 0,1.6 C0,1 0.4,0.6 1,0.6 C3.2,0.6 5,2.4 5,4.6 C5,6.8 3.2,8.6 1,8.6 Z"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(367.000000, 292.000000)" id="Path">
                                <path d="M4,7.6 C2.3,7.6 1,6.3 1,4.6 C1,2.9 2.3,1.6 4,1.6" fill="#FFFFFF" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C4.6,0.6 5,1 5,1.6 C5,2.2 4.6,2.6 4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C4.6,6.6 5,7 5,7.6 C5,8.2 4.6,8.6 4,8.6 Z"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(128.000000, 372.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="97" cy="384.6" r="2" />
                            <g transform="translate(203.000000, 389.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g opacity="0.3" transform="translate(241.000000, 399.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g transform="translate(326.000000, 367.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="275" cy="365.6" r="2" />
                            <g transform="translate(123.000000, 33.000000)" id="Path">
                                <path d="M1,1.6 C2.7,1.6 4,2.9 4,4.6 C4,6.3 2.7,7.6 1,7.6" fill="#FFFFFF" />
                                <path
                                    d="M1,8.6 C0.4,8.6 0,8.2 0,7.6 C0,7 0.4,6.6 1,6.6 C2.1,6.6 3,5.7 3,4.6 C3,3.5 2.1,2.6 1,2.6 C0.4,2.6 0,2.2 0,1.6 C0,1 0.4,0.6 1,0.6 C3.2,0.6 5,2.4 5,4.6 C5,6.8 3.2,8.6 1,8.6 Z"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="59" cy="26.6" r="2" />
                            <g transform="translate(263.000000, 17.000000)" id="Path">
                                <path d="M4,7.6 C2.3,7.6 1,6.3 1,4.6 C1,2.9 2.3,1.6 4,1.6" fill="#FFFFFF" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C4.6,0.6 5,1 5,1.6 C5,2.2 4.6,2.6 4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C4.6,6.6 5,7 5,7.6 C5,8.2 4.6,8.6 4,8.6 Z"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="30" cy="187.6" r="2" />
                            <g opacity="0.3" transform="translate(26.000000, 297.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g opacity="0.3" transform="translate(166.000000, 404.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="285" cy="394.6" r="2" />
                            <circle id="Oval" fill="#C2C5CE" cx="405" cy="181.6" r="2" />
                            <circle id="Oval" fill="#C2C5CE" cx="405" cy="71.6" r="2" />
                            <g opacity="0.3" transform="translate(293.000000, 23.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <circle id="Oval" fill="#C2C5CE" cx="146" cy="2.6" r="2" />
                            <g opacity="0.3" transform="translate(238.000000, 35.000000)" fill="#C2C5CE" id="Oval">
                                <g>
                                    <circle cx="2" cy="2.6" r="2" />
                                </g>
                            </g>
                            <g transform="translate(435.000000, 187.000000)">
                                <circle id="Oval" fill="#FFFFFF" cx="4" cy="4.6" r="3" />
                                <path
                                    d="M4,8.6 C1.8,8.6 0,6.8 0,4.6 C0,2.4 1.8,0.6 4,0.6 C6.2,0.6 8,2.4 8,4.6 C8,6.8 6.2,8.6 4,8.6 Z M4,2.6 C2.9,2.6 2,3.5 2,4.6 C2,5.7 2.9,6.6 4,6.6 C5.1,6.6 6,5.7 6,4.6 C6,3.5 5.1,2.6 4,2.6 Z"
                                    id="Shape"
                                    fill="#C2C5CE"
                                />
                            </g>
                            <g transform="translate(328.000000, 4.000000)">
                                <g transform="translate(17.000000, 35.000000)">
                                    <path
                                        d="M10.8,20.5 C10.7,20.5 10.7,20.5 10.8,20.5 C10.7,20.5 10.6,20.5 10.6,20.4 C10.6,20.4 10.6,20.4 10.5,20.3 C8.9,14.2 7.6,13 1.6,11.4 C1.5,11.4 1.5,11.3 1.4,11.3 C1.4,11.3 1.4,11.2 1.3,11.2 L1.3,11 C1.3,11 1.3,10.9 1.4,10.9 C1.4,10.9 1.4,10.9 1.5,10.8 C7.6,9.2 8.8,7.9 10.4,1.9 C10.4,1.8 10.5,1.7 10.7,1.7 C10.8,1.7 10.9,1.8 11,1.9 C12.6,7.9 13.8,9.2 19.9,10.8 C20,10.8 20.1,10.9 20.1,11.1 C20.1,11.2 20,11.3 19.9,11.4 C13.9,13 12.6,14.2 11,20.3 C11,20.4 10.9,20.4 10.9,20.5 C10.9,20.5 10.9,20.5 10.8,20.5 L10.8,20.5 Z"
                                        id="Path"
                                        fill="#FFFFFF"
                                    />
                                    <path
                                        d="M10.7,4.7 C12,8.4 13.5,9.9 17.2,11.2 C13.5,12.5 12,14 10.7,17.7 C9.4,14 7.9,12.5 4.2,11.2 C8,9.9 9.5,8.4 10.7,4.7 L10.7,4.7 Z M10.8,0.8 C10.2,0.8 9.7,1.2 9.6,1.7 C8.1,7.4 7.1,8.4 1.4,9.9 C1.3,9.9 1.2,9.9 1.2,10 C1,10.1 0.9,10.2 0.8,10.3 C0.7,10.4 0.6,10.6 0.5,10.7 C0.5,10.8 0.4,11 0.4,11.1 C0.4,11.2 0.4,11.4 0.5,11.5 C0.6,11.7 0.6,11.8 0.8,11.9 C0.9,12 1,12.1 1.2,12.2 C1.3,12.2 1.4,12.3 1.4,12.3 C7.1,13.8 8.1,14.8 9.6,20.5 C9.6,20.6 9.6,20.7 9.7,20.7 C9.8,20.9 9.9,21 10,21.1 C10.1,21.2 10.3,21.3 10.4,21.3 C10.5,21.3 10.7,21.4 10.8,21.4 L10.8,21.4 C10.9,21.4 11.1,21.4 11.2,21.3 C11.4,21.2 11.5,21.2 11.7,21 C11.8,20.9 11.9,20.8 12,20.6 C12,20.5 12.1,20.4 12.1,20.4 C13.6,14.7 14.6,13.7 20.3,12.2 C20.9,12.1 21.2,11.6 21.2,11 C21.2,10.4 20.8,9.9 20.3,9.8 C14.6,8.3 13.6,7.3 12.1,1.6 C11.8,1.2 11.3,0.8 10.8,0.8 L10.8,0.8 Z"
                                        id="Shape"
                                        fill="#C2C5CE"
                                    />
                                </g>
                                <path
                                    d="M27.8,26.1 C27.2,26.1 26.8,25.7 26.8,25.1 L26.8,1.6 C26.8,1 27.2,0.6 27.8,0.6 C28.4,0.6 28.8,1 28.8,1.6 L28.8,25.1 C28.8,25.7 28.3,26.1 27.8,26.1 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M27.8,79.6 C27.2,79.6 26.8,79.2 26.8,78.6 L26.8,67.1 C26.8,66.5 27.2,66.1 27.8,66.1 C28.4,66.1 28.8,66.5 28.8,67.1 L28.8,78.6 C28.8,79.2 28.3,79.6 27.8,79.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M2.5,46.6 L1,46.6 C0.4,46.6 0,46.2 0,45.6 C0,45 0.4,44.6 1,44.6 L2.5,44.6 C3.1,44.6 3.5,45 3.5,45.6 C3.5,46.2 3.1,46.6 2.5,46.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                                <path
                                    d="M60.8,46.6 L49.3,46.6 C48.7,46.6 48.3,46.2 48.3,45.6 C48.3,45 48.7,44.6 49.3,44.6 L60.8,44.6 C61.4,44.6 61.8,45 61.8,45.6 C61.8,46.2 61.4,46.6 60.8,46.6 Z"
                                    id="Path"
                                    fill="#C2C5CE"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export const EmptyEventsSVGContainer = compose()(EmptyEventsSVG);
