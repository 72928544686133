export const styleSheet = theme => ({
    container: {
        border: `1px solid ${theme.colors.gray05}`,
        borderRadius: 10,
        boxShadow: `0.5px 0.5px 2px 0 ${theme.colors.gray05}`,
        p: {
            color: theme.colors.gray02,
            fontSize: '6px',
            letterSpacing: 1,
            textTransform: 'uppercase'
        },
        padding: '2px 2px 1px 3px'
    }
});
