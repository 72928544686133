import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { toDateTimeString } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { BackButton } from 'components/BackButton';
import { ContentCompanies } from 'components/ContentCompanies';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Heading } from 'components/Heading';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { ContentActionBlock } from 'components/ContentActionBlock';
import { NewsActionMenu } from './NewsActionMenu';
import { NewsSidebar } from './NewsSidebar';
import { styleSheet } from './stylesheet';

class News extends PureComponent {
    static propTypes = {
        body: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        companies: PropTypes.arrayOf(PropTypes.any).isRequired,
        company: PropTypes.string,
        companyId: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        contentType: PropTypes.string.isRequired,
        exchange: PropTypes.string,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isArchived: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        loadingDetails: PropTypes.bool.isRequired,
        matchCount: PropTypes.number.isRequired,
        minimized: PropTypes.bool,
        newsId: PropTypes.string,
        newsSource: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.string,
        shareBookmarks: PropTypes.bool.isRequired,
        selectBlock: PropTypes.func.isRequired,
        selectHighlightId: PropTypes.func.isRequired,
        selectedHighlightId: PropTypes.string,
        selectSidebarTab: PropTypes.func.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        setToolbarTitle: PropTypes.func,
        sidebarTab: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tags: PropTypes.arrayOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        body: undefined,
        company: undefined,
        companyId: undefined,
        exchange: undefined,
        minimized: false,
        newsId: null,
        newsSource: undefined,
        passedStyles: {},
        publishedDate: undefined,
        selectedHighlightId: undefined,
        setToolbarTitle: null,
        ticker: undefined,
        url: undefined
    };

    componentDidUpdate() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    renderCategories() {
        const { categories, styles } = this.props;

        const mappedCategories = categories.map(({ displayName, categoryId }) => (
            <Div key={`sa-page-cat-${categoryId}`} styles={styles.category}>
                <Text size={0} uppercase>
                    {displayName}
                </Text>
            </Div>
        ));

        if (categories && categories.length) {
            return (
                <Div styles={styles.categoryContainer}>
                    <Text styles={styles.catLabel} size={0} uppercase>
                        News categories
                    </Text>
                    <Div styles={styles.categories}>{mappedCategories}</Div>
                </Div>
            );
        }

        return null;
    }

    renderCompany() {
        const { company, companyId, styles, ticker, exchange } = this.props;

        if (!company && !ticker) {
            return null;
        }

        const content = (
            <Fragment>
                <Text styles={styles.ticker} size={1} uppercase weight="medium">
                    {`${ticker}:${exchange}`}
                </Text>
                <Text size={1} styles={styles.company}>
                    {company}
                </Text>
            </Fragment>
        );

        if (companyId) {
            return (
                <Div styles={styles.companyInfo} title="View company">
                    <InternalLink to={generatePath(routes.company, { companyId })} styles={styles.companyLink}>
                        {content}
                    </InternalLink>
                </Div>
            );
        }

        return (
            <Div styles={styles.companyInfo} title="View company">
                {content}
            </Div>
        );
    }

    renderFullText() {
        const {
            body,
            containerRef,
            contentType,
            companies,
            highlightsFilterKey,
            highlightsSortKey,
            isArchived,
            isRead,
            isStarred,
            newsSource,
            newsId,
            publishedDate,
            selectHighlightId,
            selectedHighlightId,
            styles,
            tags,
            title,
            url,
            userTags
        } = this.props;
        let publishDate = toDateTimeString(publishedDate, true);
        const highlightMenu = (
            <NewsActionMenu
                body={body}
                bookmarkId={selectedHighlightId}
                containerRef={containerRef}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                openByDefault
                styles={styles.actionMenu}
                useSelection
            />
        );

        if (newsSource || url) {
            publishDate = ` • ${publishDate}`;
        }
        if (body) {
            return (
                <Div styles={styles.fullTextContainer} className="enable-quick-context">
                    {companies.length > 1 ? (
                        <ContentCompanies styles={styles.companies} companies={companies} />
                    ) : (
                        this.renderCompany()
                    )}
                    <Heading size={1} styles={styles.fullTextHeading}>
                        {title || 'No document title'}
                    </Heading>
                    <Div styles={styles.sourceDate}>
                        {url && newsSource && (
                            <ExternalLink styles={styles.link} href={url}>
                                {newsSource}
                            </ExternalLink>
                        )}
                        {!url && newsSource && (
                            <Text size={1} styles={styles.source}>
                                {newsSource}
                            </Text>
                        )}
                        <Text size={1} styles={styles.publishDate}>
                            {publishDate}
                        </Text>
                    </Div>
                    <ContentActionBlock
                        contentType={contentType}
                        isArchived={isArchived}
                        isRead={isRead}
                        isStarred={isStarred}
                        tags={tags}
                        userTags={userTags}
                        targetId={newsId}
                        targetType="content"
                        styles={styles.actionBlock}
                    />
                    <Tooltip
                        isEnabled
                        xOffset={-30}
                        content={highlightMenu}
                        keepInViewport
                        hideOnScroll={false}
                        blockBackground
                        persistOnMouseExit
                    >
                        {({ showTooltip }) => (
                            <Div
                                className="article"
                                styles={styles.documentContent}
                                onMouseDown={selectHighlightId}
                                onMouseUp={e => {
                                    if (window.getSelection()?.toString()) {
                                        showTooltip(e);
                                    } else if (selectedHighlightId) {
                                        showTooltip(e);
                                    }
                                }}
                            >
                                <RawHTML className="raw-text" html={body} />
                            </Div>
                        )}
                    </Tooltip>
                    {this.renderCategories()}
                </Div>
            );
        }

        return null;
    }

    renderContent() {
        const { loadingDetails, body, styles, theme, passedStyles, containerRef } = this.props;

        if (loadingDetails && !body) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => m && <BackButton back styles={styles.backButton} />}
                    </MediaQuery>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        return (
            <Div styles={styles.content} ref={containerRef}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                {this.renderFullText()}
                <Div styles={styles.spacer} />
            </Div>
        );
    }

    render() {
        const {
            body,
            containerRef,
            highlightsFilterKey,
            highlightsSortKey,
            matchCount,
            minimized,
            onSearch,
            passedStyles,
            shareBookmarks,
            selectBlock,
            selectSidebarTab,
            setHighlightsFilter,
            setHighlightsSort,
            sidebarTab,
            styles
        } = this.props;

        if (minimized) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <NewsSidebar
                    body={body}
                    containerRef={containerRef}
                    highlightsFilterKey={highlightsFilterKey}
                    highlightsSortKey={highlightsSortKey}
                    onSearch={onSearch}
                    matchCount={matchCount}
                    shareBookmarks={shareBookmarks}
                    selectBlock={selectBlock}
                    selectedTab={sidebarTab}
                    selectTab={selectSidebarTab}
                    setHighlightsFilter={setHighlightsFilter}
                    setHighlightsSort={setHighlightsSort}
                />
                {this.renderContent()}
            </Div>
        );
    }
}

export const NewsUI = compose(withStyleSheet(styleSheet))(News);
