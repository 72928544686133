export const styleSheet = theme => {
    const option = {
        display: 'flex',
        flexShrink: 0,
        padding: '10px 12px',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: `inset 0 -1px 0 0 ${theme.colors.gray03}`,
        backgroundColor: theme.colors.white01,
        p: {
            lineHeight: '1.3em',
            fontSize: 12
        },
        ':last-of-type': {
            boxShadow: 'unset'
        }
    };

    return {
        container: {
            position: 'relative',
            input: {
                borderRadius: 6
            }
        },
        autocompleteMenu: {
            backgroundColor: theme.colors.white01,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderRadius: 6,
            boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
            maxHeight: 180,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch'
        },
        optionSelected: {
            ...option,
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01,
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        option: {
            ...option
        },
        optionHeader: {
            position: 'sticky',
            top: 0,
            p: {
                fontWeight: theme.fonts.fontWeights.medium
            }
        },
        optionNested: {},
        menuPlaceholder: {
            color: theme.colors.gray04,
            padding: theme.margins.margin01,
            p: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }
        },
        loader: {
            margin: `${theme.margins.margin01} auto`
        },
        spacer: {
            flex: 1
        },
        nestedIcon: {
            marginRight: 8
        }
    };
};
