/* eslint-disable max-classes-per-file */
const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

const fMap = {
    '1': [697, 1209],
    '2': [697, 1336],
    '3': [697, 1477],
    '4': [770, 1209],
    '5': [770, 1336],
    '6': [770, 1477],
    '7': [852, 1209],
    '8': [852, 1336],
    '9': [852, 1477],
    '*': [941, 1209],
    '0': [941, 1336],
    '#': [941, 1477]
};

export class DTMF {
    constructor(context) {
        this.context = context || null;
        this.status = 0;
    }

    setup(f1, f2) {
        if (!this.context && AudioContext) {
            this.context = new AudioContext();
        } else if (!this.context) return;

        this.osc1 = this.context.createOscillator();
        this.osc2 = this.context.createOscillator();
        this.osc1.frequency.value = f1;
        this.osc2.frequency.value = f2;

        this.gainNode = this.context.createGain();
        this.gainNode.gain.value = 0.25;

        this.filter = this.context.createBiquadFilter();
        this.filter.type = 'lowpass';
        this.filter.frequency.setValueAtTime(8000, this.context.currentTime);

        this.osc1.connect(this.gainNode);
        this.osc2.connect(this.gainNode);

        this.gainNode.connect(this.filter);
        this.filter.connect(this.context.destination);
    }

    start(tone, play = true) {
        if (play) {
            this.setup(...fMap[tone]);
            this.osc1.start(0);
            this.osc2.start(0);
        }
        this.tone = tone;
    }

    stop() {
        this.osc1?.stop(0);
        this.osc2?.stop(0);
        this.tone = null;
        this.cleanup();
    }

    cleanup() {
        this.context?.close();
        this.context = null;
    }
}
