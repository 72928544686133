import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { get } from 'utils';
import { withData } from './data';
import { CloneStreamTooltipUI } from './ui';

export class CloneStreamTooltip extends PureComponent {
    static displayName = 'CloneStreamTooltipContainer';

    static propTypes = {
        createStream: PropTypes.func.isRequired,
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        dashboardId: PropTypes.string.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        onSubmit: PropTypes.func,
        placeholder: PropTypes.string,
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        submitButtonText: PropTypes.string
    };

    static defaultProps = {
        currentDashboard: undefined,
        onSubmit: null,
        placeholder: 'Enter new search title...',
        stream: null,
        styles: undefined,
        submitButtonText: 'Clone search'
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSelectDashboard = this.handleSelectDashboard.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isEnabled: true,
            loading: false,
            selectedDashboard: props.dashboardId,
            value: get(props, 'stream.name', '')
        };
    }

    componentDidUpdate(prevProps) {
        const { stream: prevStream } = prevProps;
        const { stream } = this.props;
        if (prevStream !== stream) {
            this.setState({
                value: get(stream, 'name', '')
            });
        }
    }

    handleChange({ value }) {
        this.setState({ value });
    }

    handleKeyDown({ key }) {
        if (key === 'Enter') {
            this.handleSubmit();
        }
    }

    handleSelectDashboard({ value }) {
        this.setState({ selectedDashboard: value });
    }

    handleSubmit() {
        const { selectedDashboard, value: name } = this.state;
        const { createStream, dashboardId, history, onSubmit, stream } = this.props;

        if (stream && selectedDashboard) {
            const { rules, streamType, uxPreferences, dataCollectionId, streamMatchTemplateId } = stream;
            // Mapping over the rules is necessary to remove unsupported props, like __typename
            const input = {
                dashboardIds: [selectedDashboard],
                name,
                rules: (rules || []).map(({ ruleType, condition, value }) => ({ ruleType, condition, value })),
                streamType,
                uxPreferences,
                dataCollectionId,
                streamMatchTemplateId
            };

            this.setState({ loading: true }, () => {
                createStream(input).finally(() => {
                    this.setState(
                        {
                            isEnabled: false,
                            loading: false
                        },
                        () => {
                            if (onSubmit) onSubmit();
                            // Redirect to cloned stream's dashboard if different than current dashboard
                            if (dashboardId !== selectedDashboard) {
                                history.push(generatePath(routes.dashboard, { dashboardId: selectedDashboard }));
                            }
                        }
                    );
                });
            });
        }
    }

    render() {
        const { isEnabled, loading, selectedDashboard, value } = this.state;
        const { children, currentDashboard, placeholder, stream, styles, submitButtonText } = this.props;
        return (
            <CloneStreamTooltipUI
                currentDashboard={currentDashboard}
                isEnabled={isEnabled}
                loading={loading}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onSelectDashboard={this.handleSelectDashboard}
                onSubmit={this.handleSubmit}
                placeholder={placeholder}
                selectedDashboard={selectedDashboard}
                stream={stream}
                styles={styles}
                submitButtonText={submitButtonText}
                value={value}
            >
                {children}
            </CloneStreamTooltipUI>
        );
    }
}

export const CloneStreamTooltipContainer = compose(withUrlContext(['history']), withData())(CloneStreamTooltip);
