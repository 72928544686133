import { BackButton } from 'components/BackButton';
import { Div } from 'components/Div';
import { Heading } from 'components/Heading';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { generateTabURL, toDateTimeString, prettyLineBreak, prettyEventType } from 'utils';
import { Icon } from 'components/Icon';
import { Notice } from 'components/Notice';
import { ActionButton } from 'components/ActionButton';
import { styleSheet } from './stylesheet';

class Report extends PureComponent {
    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool.isRequired,
        loadingPdf: PropTypes.bool.isRequired,
        loadingWord: PropTypes.bool.isRequired,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        onDownloadPdf: PropTypes.func.isRequired,
        onDownloadWord: PropTypes.func.isRequired,
        blocks: PropTypes.arrayOf(PropTypes.any).isRequired,
        showSummary: PropTypes.bool.isRequired,
        topSummary: PropTypes.arrayOf(PropTypes.any).isRequired,
        toggleSummary: PropTypes.func.isRequired
    };

    static defaultProps = {
        minimized: false,
        passedStyles: {},
        created: undefined,
        setToolbarTitle: null
    };

    componentDidUpdate() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    renderFullText() {
        const {
            styles,
            title,
            blocks,
            created,
            pathname,
            topSummary,
            showSummary,
            toggleSummary,
            onDownloadPdf,
            onDownloadWord,
            loadingPdf,
            loadingWord
        } = this.props;

        if (blocks) {
            return (
                <Div styles={styles.fullTextContainer}>
                    <Notice type="info" styles={styles.reportNotice}>
                        <Text size={3}>
                            Reports are generated using artifical intelligence, and may not be perfect.
                            <br />
                            Please use at your own discretion.
                        </Text>
                    </Notice>
                    <Heading size={1} styles={styles.fullTextHeading}>
                        {title || 'No document title'}
                    </Heading>
                    <Div styles={styles.reportDetails}>
                        <Div styles={styles.typeDate}>
                            <Text size={1} styles={{ lineHeight: '1.3em' }}>
                                Report Generated on
                                <br />
                                {toDateTimeString(created, true)}
                            </Text>
                        </Div>
                        <Div styles={styles.downloadOptions}>
                            <ActionButton styles={styles.downloadOption} onClick={() => onDownloadPdf(styles)}>
                                <Icon type="download" color="black" />
                                <Text styles={styles.download} size={1}>
                                    {loadingPdf ? 'Downloading PDF...' : 'Download PDF'}
                                </Text>
                            </ActionButton>
                            <ActionButton styles={styles.downloadOption} onClick={() => onDownloadWord(styles)}>
                                <Icon type="download" color="black" />
                                <Text styles={styles.download} size={1}>
                                    {loadingWord ? 'Downloading Word...' : 'Download Word Doc'}
                                </Text>
                            </ActionButton>
                        </Div>
                    </Div>
                    {topSummary && topSummary.length > 0 && (
                        <Div styles={styles.reportSummary}>
                            <Div styles={styles.toggleSummary} onClick={toggleSummary}>
                                <Icon
                                    type="chevron02"
                                    color="black"
                                    styles={{
                                        transform: showSummary ? 'rotate(90deg)' : undefined
                                    }}
                                />
                                <Text weight="medium" size={3} styles={styles.toggleSummaryLabel}>
                                    {showSummary ? 'Hide' : 'Show'} Report Summary
                                </Text>
                                <Text
                                    size={3}
                                    styles={{
                                        ...styles.toggleSummaryPreview,
                                        opacity: showSummary ? 0 : 100
                                    }}
                                >
                                    {topSummary[0]}
                                </Text>
                            </Div>
                            {showSummary &&
                                topSummary.map(smry => (
                                    <Text key={smry.slice(0, 10)} size={3} styles={styles.reportSummaryText}>
                                        {smry}
                                    </Text>
                                ))}
                        </Div>
                    )}
                    <Div className="report" styles={styles.reportBlocks}>
                        {blocks.map(
                            ({
                                content,
                                eventId,
                                eventDate,
                                localTicker,
                                eventType,
                                quarter,
                                year,
                                title: blockTitle
                            }) => (
                                <Div key={eventId} styles={styles.reportBlock}>
                                    <Div styles={styles.reportBlockHeader}>
                                        <Link to={generateTabURL({ eventId, pathname })}>
                                            <Text size={3} weight="bold">
                                                {localTicker}, {prettyEventType(eventType)}
                                                {quarter && `, Q${quarter} ${year}`}
                                            </Text>
                                        </Link>
                                        <Text weight="bold" size={5} styles={styles.reportBlockTitle}>
                                            {blockTitle.length > 120
                                                ? blockTitle
                                                : prettyLineBreak(blockTitle, 0.6, 68)}
                                        </Text>
                                        <Text styles={styles.eventDate} size={1}>
                                            {eventDate}
                                        </Text>
                                    </Div>
                                    {content.map(txt => (
                                        <RawHTML key={txt.slice(0, 20)} className="raw-text" html={txt} />
                                    ))}
                                </Div>
                            )
                        )}
                    </Div>
                </Div>
            );
        }

        return null;
    }

    renderContent() {
        const { loading, blocks, styles, theme, passedStyles, containerRef } = this.props;

        if (loading && (!blocks || blocks.length === 0)) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => m && <BackButton back styles={styles.backButton} />}
                    </MediaQuery>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        return (
            <Div styles={styles.content} ref={containerRef}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                {this.renderFullText()}
            </Div>
        );
    }

    render() {
        const { minimized, passedStyles, styles } = this.props;

        if (minimized) {
            return null;
        }

        return <Div styles={{ ...styles.container, ...passedStyles }}>{this.renderContent()}</Div>;
    }
}

export const ReportUI = compose(withStyleSheet(styleSheet))(Report);
