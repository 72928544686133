import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { withCountries } from 'graphql/countries';
import { CountryAutocompleteUI } from './ui';

const formatOptions = countries =>
    countries.map(({ shortName, countryCode }) => ({ label: shortName, value: countryCode }));

export class CountryAutocomplete extends PureComponent {
    static displayName = 'CountryAutocompleteContainer';

    static propTypes = {
        countries: PropTypes.arrayOf(PropTypes.any),
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
    };

    static defaultProps = {
        countries: [],
        initialSearchTerm: null,
        label: undefined,
        loading: false,
        multi: false,
        name: 'countryAutocomplete',
        onChange: null,
        placeholder: 'Filter by Country',
        styles: {},
        useTags: false,
        value: null
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.formatOptions = memoize(formatOptions);
    }

    onChange({ event, value }) {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange({ event, name, value });
        }
    }

    render() {
        const {
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            placeholder,
            styles,
            countries,
            useTags,
            value
        } = this.props;
        return (
            <CountryAutocompleteUI
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                options={this.formatOptions(countries)}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const CountryAutocompleteContainer = compose(
    withCountries(),
    withProps(({ countriesLoading }) => ({ loading: countriesLoading }))
)(CountryAutocomplete);
