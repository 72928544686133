const ALERT_TOAST_SHOW = 'ALERT_TOAST_SHOW';
const ALERT_TOAST_HIDE = 'ALERT_TOAST_HIDE';

export const types = {
    ALERT_TOAST_HIDE,
    ALERT_TOAST_SHOW
};

/**
 *
 * These actions show and hide the alert toast.
 * The alert toast is used to inform the user of destructive
 * actions, or refresh the page. Or announce new features etc.
 * It lives in the InternalLayout file.
 *
 */

export function alertToastShow(type) {
    return {
        type: ALERT_TOAST_SHOW,
        payload: {
            type
        }
    };
}

export function alertToastHide() {
    return {
        type: ALERT_TOAST_HIDE
    };
}
