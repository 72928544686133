export const styleSheet = theme => ({
    container: {
        td: {
            ':first-of-type': {
                paddingLeft: '23px'
            },
            ':last-of-type': {
                paddingRight: '23px'
            }
        },
        th: {
            ':first-of-type': {
                paddingLeft: theme.margins.margin01
            },
            ':last-of-type': {
                paddingRight: theme.margins.margin01
            }
        }
    }
});
