export const styleSheet = theme => ({
    container: {
        paddingTop: 'env(safe-area-inset-top)',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        backgroundColor: theme.colors.white01
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: `${theme.margins.margin03} 10px 0`,
        overflowY: 'auto',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            position: 'relative',
            padding: theme.margins.margin03,
            paddingBottom: 0
        }
    },
    back: {
        marginLeft: theme.margins.margin02,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            marginLeft: 'unset'
        }
    },
    wrapper: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    brandBox: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.colors.gray12,
        position: 'relative'
    },
    logoMobile: {
        position: 'absolute',
        right: 40
    }
});
