import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { LoaderLogo } from 'components/LoaderLogo';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Th } from 'components/Th';
import { Tr } from 'components/Tr';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'events';

class EventResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        setPreview: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    componentDidUpdate(prevProps) {
        const { highlightedResult: prevHighlightedResult } = prevProps;
        const { highlightedResult, setPreview, results } = this.props;
        const { type, index } = highlightedResult;
        const { type: prevType, index: prevIndex } = prevHighlightedResult;

        if (type === RESULT_TYPE && (type !== prevType || index !== prevIndex)) {
            const result = results[index];
            if (result) {
                clearTimeout(this.previewTimeout);
                this.previewTimeout = setTimeout(() => {
                    setPreview(this.generatePreview(result));
                }, 50);
            }
        }
    }

    generatePreview(event) {
        const { company, equityIcon, equityInitials, ticker, title, date, isLive, hasTranscript } = event;
        const { styles, onClickResult } = this.props;
        return (
            <Div styles={styles.preview} onClick={onClickResult}>
                <Div styles={styles.previewBlock}>
                    <CardHeader
                        styles={styles.previewHeader}
                        context={ticker}
                        iconUrl={equityIcon}
                        iconText={equityInitials}
                        title={company}
                    />
                    <Div styles={styles.previewSubtitle}>
                        <Text size={3}>{title}</Text>
                    </Div>
                </Div>
                {date && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            Event date
                        </Text>
                        <Text size={3}>{new XDate(date).toString('MMM d, yyyy, h:ssTT')}</Text>
                    </Div>
                )}
                <Div styles={styles.previewBlock}>
                    <Text size={0} styles={styles.previewLabel} uppercase>
                        event status
                    </Text>
                    {isLive && <Text size={3}>Live</Text>}
                    {!isLive && hasTranscript && <Text size={3}>Has Transcript</Text>}
                    {!isLive && !hasTranscript && <Text size={3}>No Transcript</Text>}
                </Div>
            </Div>
        );
    }

    render() {
        const {
            styles,
            enabled,
            results,
            loading,
            loadingMoreResults,
            loadMoreResults,
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) {
            return null;
        }

        return (
            <Table styles={styles.results}>
                <Tr styles={styles.resultHeaders}>
                    <Th colSpan={2}>Event</Th>
                    <Th>Date</Th>
                </Tr>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results.map((event, index) => {
                        const { equityIcon, equityInitials, date, company, ticker, id, isLive, title } = event;
                        const dateString = date.toString('MM/dd/yyyy');
                        const timeString = date.toString('h:mmTT');
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        return (
                            <Tr
                                elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                key={`event-${id}-${ticker}`}
                                styles={resultStyles}
                                onClick={onClickResult}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                            >
                                <Td>
                                    {equityIcon ? (
                                        <Div styles={styles.companyIcon}>
                                            <Img src={equityIcon} />
                                        </Div>
                                    ) : (
                                        <Div styles={styles.equityInitials}>
                                            <Text uppercase className="equityInitials" size={0}>
                                                {equityInitials}
                                            </Text>
                                        </Div>
                                    )}
                                </Td>
                                <Td>
                                    {`${company} (${ticker})`}
                                    <Text size={1} styles={styles.eventTitle}>
                                        {title}
                                    </Text>
                                </Td>
                                <Td styles={styles.eventDateTime}>
                                    {dateString}
                                    {isLive ? (
                                        <Text size={0} styles={styles.liveStatus} uppercase>
                                            {`live from ${timeString}`}
                                        </Text>
                                    ) : (
                                        <Text size={1} styles={styles.eventTime}>
                                            {timeString}
                                        </Text>
                                    )}
                                </Td>
                            </Tr>
                        );
                    })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr>
                        <Td colSpan={4}>
                            <ActionButton onClick={() => loadMoreResults('event')} styles={styles.loadMoreButton}>
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const EventResultsUI = compose(withStyleSheet(styleSheet))(EventResults);
