import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { StreamRow } from 'components/StreamRow';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { RawHTML } from 'components/RawHTML';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class GSheetCard extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        body: PropTypes.string,
        bottomLeft: PropTypes.string,
        bottomRight: PropTypes.string,
        topLeft: PropTypes.string,
        topRight: PropTypes.string,
        ticker: PropTypes.string,
        template: PropTypes.string,
        showSubContent: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        body: undefined,
        bottomLeft: undefined,
        bottomRight: undefined,
        topRight: undefined,
        topLeft: undefined,
        ticker: undefined,
        template: undefined,
        passedStyles: {}
    };

    generateStyle(defaultStyle, value) {
        const { styles } = this.props;

        if (value && value.includes('+')) {
            return { ...defaultStyle, ...styles.greenText };
        }
        if (value && value.includes('-')) {
            return { ...defaultStyle, ...styles.redText };
        }

        return defaultStyle;
    }

    renderRowSubContent() {
        const { styles, showSubContent, body } = this.props;
        if (!showSubContent) {
            return null;
        }

        return (
            <Div styles={styles.rowSubContent}>
                <Text size={1} styles={styles.rowSubContentBody}>
                    {body}
                </Text>
            </Div>
        );
    }

    renderRowContent() {
        const { styles, theme, topLeft, bottomLeft, topRight, bottomRight, body, showSubContent } = this.props;
        return (
            <Div styles={styles.rowContent}>
                <Div styles={styles.rowInfo}>
                    {body ? (
                        <Fragment>
                            <Icon type={showSubContent ? 'boxMinus' : 'boxPlus'} color={theme.colors.gray04} />
                            <Div styles={styles.expandableTitleWrapper}>
                                <Text className="contentTitle" styles={styles.rowTitle} size={3}>
                                    {topLeft}
                                </Text>
                                <Text uppercase styles={styles.rowSubtitle} size={0}>
                                    {bottomLeft}
                                </Text>
                            </Div>
                        </Fragment>
                    ) : (
                        <Div styles={styles.rowTitleWrapper}>
                            <Text className="contentTitle" styles={styles.rowTitle} size={3}>
                                {topLeft}
                            </Text>
                            {bottomLeft && (
                                <Text uppercase styles={styles.rowSubtitle} size={0}>
                                    {bottomLeft}
                                </Text>
                            )}
                        </Div>
                    )}
                </Div>
                <Div styles={styles.rowRightSide}>
                    <Text size={3} styles={this.generateStyle({}, topRight)}>
                        {topRight}
                    </Text>
                    <Text size={1} styles={this.generateStyle({}, bottomRight)}>
                        {bottomRight}
                    </Text>
                </Div>
            </Div>
        );
    }

    render() {
        const {
            styles,
            passedStyles,
            body,
            topLeft,
            topRight,
            bottomLeft,
            bottomRight,
            ticker,
            template,
            onClick
        } = this.props;
        if (template === 'template1') {
            return (
                <Card styles={{ ...styles.container, ...passedStyles }} name="gsheet-card">
                    <Div styles={styles.topContent}>
                        <CardHeader context={ticker} title={topLeft} subtitle={bottomLeft} />
                        <Div styles={styles.rightText}>
                            {topRight && (
                                <Text size={3} styles={this.generateStyle(styles.rightText01, topRight)}>
                                    {topRight}
                                </Text>
                            )}
                            {bottomRight && (
                                <Text size={1} styles={this.generateStyle(styles.rightText02, bottomRight)}>
                                    {bottomRight}
                                </Text>
                            )}
                        </Div>
                    </Div>
                    {body && (
                        <Div styles={styles.content}>
                            <RawHTML html={body} />
                        </Div>
                    )}
                </Card>
            );
        }

        if (template === 'template2') {
            return (
                <StreamRow
                    styles={{ cursor: body ? 'pointer' : undefined, ...styles.rowContainer, ...passedStyles }}
                    onClick={body ? onClick : undefined}
                    name="gsheet-row"
                >
                    {this.renderRowContent()}
                    {this.renderRowSubContent()}
                </StreamRow>
            );
        }
        return null;
    }
}

export const GSheetCardUI = compose(withStyleSheet(styleSheet))(GSheetCard);
