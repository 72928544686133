import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { DatePicker } from 'components/DatePicker';
import { Div } from 'components/Div';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class DateInput extends PureComponent {
    static propTypes = {
        formatString: PropTypes.string.isRequired,
        hideCalendar: PropTypes.func.isRequired,
        isCalendarVisible: PropTypes.bool.isRequired,
        minDate: PropTypes.instanceOf(Date),
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.instanceOf(Date)
    };

    static defaultProps = {
        minDate: undefined,
        passedStyles: {},
        placeholder: undefined,
        value: undefined
    };

    render() {
        const {
            formatString,
            hideCalendar,
            isCalendarVisible,
            minDate,
            name,
            onChange,
            onFocus,
            passedStyles,
            placeholder,
            styles,
            value
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <OutsideClickHandler onClick={hideCalendar} cancelClassName="calendar">
                    <TextInput
                        autoComplete="off"
                        name={name}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        value={value ? new XDate(value).toString(formatString) : value}
                    />
                </OutsideClickHandler>
                {isCalendarVisible && (
                    <DatePicker
                        minDate={minDate}
                        name={name}
                        onChange={onChange}
                        styles={styles.datePicker}
                        value={value}
                    />
                )}
            </Div>
        );
    }
}

export const DateInputUI = compose(withStyleSheet(styleSheet))(DateInput);
