import gql from 'graphql-tag';

export const equitySearchFragment = gql`
    fragment equitySearch on EquitySearchResult {
        total
        hits {
            id
            equity {
                equityId
                id
                commonDescription
                commonName
                description
                name
                localTicker
                exchange {
                    id
                    shortName
                }
                company {
                    id
                }
            }
        }
    }
`;
