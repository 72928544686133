export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.gray12,
        height: '100%',
        width: 40,
        paddingTop: 6,
        ':hover': {
            '.closedMenu': {
                display: 'block'
            }
        }
    },
    newBtn: {
        ...theme.content.blueButton,
        borderRadius: 6,
        padding: '0 0 2px 2px',
        height: 26,
        width: 26,
        margin: '4px 0',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    closedBtn: {
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 34,
        width: 40,
        transition: 'all 0.1s ease-in-out',
        svg: {
            height: 14,
            width: 14
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.white01
            }
        }
    },
    closedBtnLink: {
        a: {
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            height: 34,
            width: 40,
            transition: 'all 0.1s ease-in-out',
            svg: {
                height: 14,
                width: 14
            },
            ':hover': {
                'svg g': {
                    fill: theme.colors.white01
                }
            }
        },
        '.selected': {
            'svg g': {
                fill: theme.colors.orange02
            }
        }
    },
    labelTooltip: {
        ...theme.content.tooltipTextSmall
    },
    hoverMenuBtn: {
        backgroundColor: theme.colors.gray13,
        position: 'absolute',
        top: 1,
        left: -40,
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 34,
        width: 40,
        svg: {
            height: 14,
            width: 14
        }
    },
    hoverMenu: {
        minWidth: 200,
        boxShadow: '4px 0 8px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: '0 6.5px 6.5px 6.5px',
        backgroundColor: theme.colors.gray13,
        padding: 0,
        marginBottom: 46
    },
    menuScrollContainer: {
        height: '100%',
        overflowY: 'auto',
        paddingBottom: 6
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 12,
        marginTop: 14,
        height: 24,
        ':first-of-type': {
            marginTop: 8
        },
        p: {
            letterSpacing: 2,
            color: theme.colors.orange02
        }
    },
    dividerLine: {
        flex: 1,
        height: 1,
        backgroundColor: theme.colors.gray03,
        opacity: 0.1,
        margin: '0 14px 1px'
    },
    dashboardMenuOption: {
        a: {
            height: 26,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.colors.white01,
            padding: '0 12px',
            ':hover': {
                backgroundColor: theme.colors.gray11
            }
        }
    },
    openPane: {
        svg: {
            transform: 'rotate(180deg)'
        }
    },
    spacer: {
        flex: 1
    }
});
