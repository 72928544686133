import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { TYPES } from 'consts/filters';
import { StreamFilter } from 'components/StreamFilter';
import { styleSheet } from './stylesheet';

class CorporateActionFilter extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        passedStyles: {},
        value: null
    };

    render() {
        const { name, onChange, passedStyles, styles, value } = this.props;
        return (
            <StreamFilter
                name={name}
                onChange={onChange}
                styles={{ ...styles.container, ...passedStyles }}
                types={[
                    TYPES.mcap,
                    TYPES.country,
                    TYPES.exchangeCountry,
                    TYPES.offeringType,
                    TYPES.equity,
                    TYPES.sector
                ]}
                value={value}
            />
        );
    }
}

export const CorporateActionFilterUI = compose(withStyleSheet(styleSheet))(CorporateActionFilter);
