export const styleSheet = theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white01
    },
    containerLoading: {
        backgroundColor: theme.colors.white01,
        paddingBottom: theme.margins.margin03,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    headingRow: {
        display: 'flex',
        alignItems: 'center'
    },
    headerLink: {
        color: theme.colors.black01
    },
    fullTextHeading: {
        flex: 1,
        lineHeight: '1.2em',
        fontFamily: theme.fonts.fontFamilyContent,
        fontWeight: theme.fonts.fontWeights.bold,
        marginBottom: theme.margins.margin00
    },
    subtitle: {
        flex: 1,
        marginTop: 8
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    topRight: {
        textAlign: 'right',
        lineHeight: '1.2em'
    },
    bottomRight: {
        textAlign: 'right',
        lineHeight: '1.2em',
        marginTop: 8,
        color: theme.colors.gray06
    },
    documentContent: {
        marginTop: 24,
        h1: {
            fontWeight: theme.fonts.fontWeights.medium,
            fontSize: theme.fonts.fontSizes.size05
        },
        ul: {
            display: 'block',
            listStyleType: 'disc',
            marginTop: '1em',
            marginBottom: '1em',
            paddingLeft: theme.margins.margin02
        },
        li: {
            display: 'list-item'
        },
        strong: {
            fontWeight: theme.fonts.fontWeights.medium
        },
        p: {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        },
        '> *': {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        }
    },
    fullTextContainer: {
        padding: `28px ${theme.margins.margin02} 8px`,
        wordBreak: 'break-word',
        '> *': {
            overflowWrap: 'break-word'
        },
        '> span': {
            display: 'block',
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        ...theme.content.annotated,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: theme.margins.margin01,
            paddingBottom: 0
        }
    },
    loading: {
        padding: theme.margins.margin03,
        color: theme.colors.gray02
    },
    loadingBlock: {
        height: '24px',
        marginBottom: '10px',
        backgroundColor: theme.colors.gray03
    },
    loadingBlocks: {
        width: '80%',
        paddingTop: theme.margins.margin01,
        '> div:last-child': {
            marginRight: theme.margins.margin03
        }
    },
    ticker: {
        color: theme.colors.orange04,
        letterSpacing: '1px'
    },
    sourceDate: {
        marginTop: 3,
        display: 'flex',
        alignItems: 'baseline'
    },
    publishDate: {
        color: theme.colors.gray06
    },
    companyInfo: {
        lineHeight: '12px',
        a: {
            display: 'inline-flex'
        }
    },
    equityLink: {
        backgroundColor: theme.colors.white01,
        a: {
            textDecoration: 'none',
            color: theme.colors.black01
        },
        ':hover': {
            a: {
                textDecoration: 'none'
            }
        }
    },
    company: {
        color: theme.colors.gray06,
        marginLeft: 8,
        marginBottom: 18
    },
    spacer: {
        flex: 1
    },
    spotlightTag: {
        padding: '3px 4px',
        borderRadius: 2.5,
        overflow: 'hidden',
        backgroundColor: theme.colors.gray08,
        color: theme.colors.gray10
    },
    spotlightSubTag: {
        marginLeft: 4,
        marginRight: -4,
        padding: '3px 4px',
        borderLeft: 'solid 1px white',
        borderTopRightRadius: 2.5,
        borderBottomRightRadius: 2.5,
        backgroundColor: theme.colors.gray06,
        color: theme.colors.gray10
    },
    eventsContainer: {
        padding: theme.margins.margin02,
        backgroundColor: theme.colors.white01
    },
    eventsTitle: {
        fontSize: theme.fonts.fontSizes.size05,
        marginBottom: theme.margins.margin00
    },
    event: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white01,
        padding: '1px 0px'
    }
});
