import React from 'react';
import styled from '@emotion/styled';
import { mapStylesProp } from 'utils/styles';

const Table = styled('table')(mapStylesProp);

export const TableUI = ({ children, ...rest }) => (
    <Table {...rest}>
        <tbody>{children}</tbody>
    </Table>
);
