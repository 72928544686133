import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import { NoRouteMatch } from 'pages/NoRouteMatch';

import { WithPermission } from 'components/WithPermission';

export class SecureRoute extends PureComponent {
    static displayName = 'SecureRouteContainer';

    static propTypes = {
        permissions: PropTypes.arrayOf(PropTypes.string),
        restrictedComponent: PropTypes.elementType
    };

    static defaultProps = {
        permissions: [],
        restrictedComponent: NoRouteMatch
    };

    render() {
        const { permissions, restrictedComponent: RestrictedComponent, ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <WithPermission restrictedComponent={RestrictedComponent} permissions={permissions}>
                <Route {...rest} />
            </WithPermission>
        );
    }
}

export const SecureRouteContainer = compose()(SecureRoute);
