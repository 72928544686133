// don't use getUrlParams from utils because we dont want
// this module to depend on anything internal, or we'll easily
// run into dependency cycles
import qs from 'qs';
import memoize from 'memoize-one';

const getUrlConfig = memoize(() => {
    const params = qs.parse(window.location.search.replace(/^\?/, ''));
    return params.aieraConfig ? JSON.parse(params.aieraConfig) : {};
});

function getEnv(key, defaultVal) {
    const env = { ...process.env, ...window.env };
    const value = getUrlConfig()[key] ?? env[`REACT_APP_${key}`] ?? env[`STORYBOOK_${key}`] ?? env[key] ?? defaultVal;
    if (value === 'false') return false;
    if (value === 'true') return true;
    return value;
}

function actingAsUser() {
    const { search } = window.location;
    const { loginAs } = qs.parse(search.includes('?') ? search.slice(1) : search);
    return loginAs || window.sessionStorage.asAieraUser;
}

const shared = {
    AIERA_ENV: getEnv('AIERA_ENV', 'development'),
    API_VERSION: '1.2.0',
    AMP_DOMAIN: 'hls.media.aiera.com',
    AMP_BASE_URL: 'https://storage.media.aiera.com',
    BUILD_COMMIT: getEnv('BUILD_COMMIT'),
    BUILD_DATE: getEnv('BUILD_DATE,'),
    BUGSNAG_API: '75f178d04a8f5b731fc66abf09ed6af4',
    BUGSNAG_VERSION: getEnv('BUGSNAG_VERSION'),
    CDN: 'https://public.aiera.com',
    DEFAULT_ELASTICSEARCH_PASSWORD: 'aoogu3g;V7mwBL',
    DEFAULT_ELASTICSEARCH_USER: 'aiera-dashboard',
    GITHUB_BASE: 'https://github.com/aiera-inc/aiera-desktop',
    LOGROCKET_API: '60jt48/aiera',
    MIXPANEL_TOKEN: '2ef62a684ab83af07391eb49ad4cf581',
    NODE_ENV: getEnv('NODE_ENV', 'development'),
    SHOW_DEBUG_INFO: getEnv('SHOW_DEBUG_INFO', localStorage.getItem('aieraDebug') === 'true'),
    STREAMS_AUTH_TOKEN: '45t690jgerokfg',
    VERSION: getEnv('VERSION'),
    ZENDESK_KEY: '1522bd02-c563-4547-b466-bd8a3586dea7'
};

// only "test" events are in the dev bucket, everything else is in prod
export const AMP_BASE_URL_DEV = 'https://storage-dev.media.aiera.com';
export const AMP_BASE_URL_PROD = 'https://storage.media.aiera.com';

const LOCAL_API_ENDPOINT = getEnv('LOCAL_API_ENDPOINT', 'http://localhost:5000/api');
const LOCAL_GRAPHQL_ENDPOINT = `${LOCAL_API_ENDPOINT}/graphql`;
const DEV_API_ENDPOINT = 'https://graphql-dev.aiera.com/api';
const DEV_GRAPHQL_ENDPOINT = `${DEV_API_ENDPOINT}/graphql`;
const PROD_API_ENDPOINT = 'https://graphql.aiera.com/api';
const PROD_GRAPHQL_ENDPOINT = `${PROD_API_ENDPOINT}/graphql`;

const DEV_RESEARCH_ENDPOINT = 'https://research-dev.aiera.com/api';
const PROD_RESEARCH_ENDPOINT = 'https://research.aiera.com/api';

const DEV_STREAMS_ENDPOINT = 'https://streams-v2-dev.aiera.com/api/twilio/voice';
const PROD_STREAMS_ENDPOINT = 'https://streams-v2.aiera.com/api/twilio/voice';

const DASHBOARD_ENDPOINT_LOCAL = 'http://localhost:3000/';
const DASHBOARD_ENDPOINT_DEV = 'http://dashboard-dev.aiera.com/';
const DASHBOARD_ENDPOINT_STAGING = 'https://dashboard-staging.aiera.com/';
export const DASHBOARD_ENDPOINT_BETA = 'https://beta.aiera.com/';
export const DASHBOARD_ENDPOINT_PROD = 'https://dashboard.aiera.com/';

// const DEV_PUSHER_KEY = '15c8c584d2162bdb7b6c';
// Using prod key for now since notifications only come in from prod server
const DEV_PUSHER_KEY = '72fcbf901d3ea077b359';
const DEV_PUSHER_CLUSTER = 'mt1';
const PROD_PUSHER_KEY = '72fcbf901d3ea077b359';
const PROD_PUSHER_CLUSTER = 'mt1';

// Stripe+
const DEV_STRIPE_BILLING_SOURCE = 'stripe_test';
const DEV_STRIPE_PUBLISHABLE_KEY =
    'pk_test_51GsYxGBqhCDUoszBBIgD8WAFCA18T9flGFHUQdTkXnPAJPmZREpx5M9E5P794PTzhPBfOBOjMWMYzAkReQnMX6pV00QeJNfDhU';
const PROD_STRIPE_BILLING_SOURCE = 'stripe_live';
const PROD_STRIPE_PUBLISHABLE_KEY = 'pk_live_APZBbrZNcv77HuLgyOnyvFoD00Daww6pfC';

const AieraConfig = {
    local: {
        ...shared,
        API_ENDPOINT: LOCAL_API_ENDPOINT,
        APOLLO_DEBUG: true,
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_LOCAL,
        GRAPHQL_ENDPOINT: LOCAL_GRAPHQL_ENDPOINT,
        PUSHER_CLUSTER: DEV_PUSHER_CLUSTER,
        PUSHER_KEY: DEV_PUSHER_KEY,
        RESEARCH_ENDPOINT: DEV_RESEARCH_ENDPOINT,
        STREAMS_ENDPOINT: DEV_STREAMS_ENDPOINT,
        STRIPE_BILLING_SOURCE: DEV_STRIPE_BILLING_SOURCE,
        STRIPE_PUBLISHABLE_KEY: DEV_STRIPE_PUBLISHABLE_KEY
    },
    development: {
        ...shared,
        API_ENDPOINT: DEV_API_ENDPOINT,
        APOLLO_DEBUG: true,
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_DEV,
        GRAPHQL_ENDPOINT: DEV_GRAPHQL_ENDPOINT,
        PUSHER_CLUSTER: DEV_PUSHER_CLUSTER,
        PUSHER_KEY: DEV_PUSHER_KEY,
        RESEARCH_ENDPOINT: DEV_RESEARCH_ENDPOINT,
        STREAMS_ENDPOINT: DEV_STREAMS_ENDPOINT,
        STRIPE_BILLING_SOURCE: DEV_STRIPE_BILLING_SOURCE,
        STRIPE_PUBLISHABLE_KEY: DEV_STRIPE_PUBLISHABLE_KEY
    },
    staging: {
        ...shared,
        API_ENDPOINT: PROD_API_ENDPOINT,
        APOLLO_DEBUG: true,
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_STAGING,
        GRAPHQL_ENDPOINT: PROD_GRAPHQL_ENDPOINT,
        PUSHER_CLUSTER: PROD_PUSHER_CLUSTER,
        PUSHER_KEY: PROD_PUSHER_KEY,
        RESEARCH_ENDPOINT: PROD_RESEARCH_ENDPOINT,
        STREAMS_ENDPOINT: PROD_STREAMS_ENDPOINT,
        STRIPE_BILLING_SOURCE: DEV_STRIPE_BILLING_SOURCE,
        STRIPE_PUBLISHABLE_KEY: DEV_STRIPE_PUBLISHABLE_KEY
    },
    beta: {
        ...shared,
        API_ENDPOINT: PROD_API_ENDPOINT,
        APOLLO_DEBUG: false,
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_BETA,
        GRAPHQL_ENDPOINT: PROD_GRAPHQL_ENDPOINT,
        PUSHER_CLUSTER: PROD_PUSHER_CLUSTER,
        PUSHER_KEY: PROD_PUSHER_KEY,
        RESEARCH_ENDPOINT: PROD_RESEARCH_ENDPOINT,
        STREAMS_ENDPOINT: PROD_STREAMS_ENDPOINT,
        STRIPE_BILLING_SOURCE: PROD_STRIPE_BILLING_SOURCE,
        STRIPE_PUBLISHABLE_KEY: PROD_STRIPE_PUBLISHABLE_KEY
    },
    production: {
        ...shared,
        API_ENDPOINT: PROD_API_ENDPOINT,
        APOLLO_DEBUG: false,
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_PROD,
        GRAPHQL_ENDPOINT: PROD_GRAPHQL_ENDPOINT,
        PUSHER_CLUSTER: PROD_PUSHER_CLUSTER,
        PUSHER_KEY: PROD_PUSHER_KEY,
        RESEARCH_ENDPOINT: PROD_RESEARCH_ENDPOINT,
        STREAMS_ENDPOINT: PROD_STREAMS_ENDPOINT,
        STRIPE_BILLING_SOURCE: PROD_STRIPE_BILLING_SOURCE,
        STRIPE_PUBLISHABLE_KEY: PROD_STRIPE_PUBLISHABLE_KEY
    }
};

const NodeConfig = {
    development: {
        DASHBOARD_ENDPOINT: DASHBOARD_ENDPOINT_LOCAL,
        BUGSNAG_ENABLED: getEnv('BUGSNAG_ENABLED', false),
        MIXPANEL_ENABLED: getEnv('MIXPANEL_ENABLED', false),
        HEAP_ENABLED: getEnv('HEAP_ENABLED', false),
        LOGROCKET_ENABLED: getEnv('LOGROCKET_ENABLED', false),
        APPCUES_ENABLED: getEnv('APPCUES_ENABLED', false)
    },
    test: {
        SHOW_REDUX_LOGGER: getEnv('SHOW_REDUX_LOGGER', true),
        BUGSNAG_ENABLED: getEnv('BUGSNAG_ENABLED', false),
        MIXPANEL_ENABLED: getEnv('MIXPANEL_ENABLED', false),
        HEAP_ENABLED: getEnv('HEAP_ENABLED', false),
        LOGROCKET_ENABLED: getEnv('LOGROCKET_ENABLED', false),
        APPCUES_ENABLED: getEnv('APPCUES_ENABLED', false)
    },
    production: {
        SHOW_REDUX_LOGGER: getEnv('SHOW_REDUX_LOGGER', false),
        BUGSNAG_ENABLED: getEnv('BUGSNAG_ENABLED', true),
        MIXPANEL_ENABLED: getEnv('MIXPANEL_ENABLED', true) && !actingAsUser(),
        HEAP_ENABLED: getEnv('HEAP_ENABLED', true) && !actingAsUser(),
        LOGROCKET_ENABLED: getEnv('LOGROCKET_ENABLED', false) && !actingAsUser(),
        APPCUES_ENABLED: getEnv('APPCUES_ENABLED', true) && !actingAsUser()
    }
};

export const config = {
    ...AieraConfig[getEnv('AIERA_ENV', 'development')],
    ...NodeConfig[getEnv('NODE_ENV', 'development')],
    actingAsUser
};
