import React, { PureComponent, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { EventCard } from 'components/EventCard';
import { Stream } from 'components/Stream';
import { DateDivider } from 'components/DateDivider';
import { EventStreamToolbar } from 'components/EventStreamToolbar';
import { STREAM_DISPLAY_MODES } from 'consts';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    event: EventCard
};

class EventStream extends PureComponent {
    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        EmptyComponent: PropTypes.objectOf(PropTypes.any),
        hasEarningsOnly: PropTypes.bool.isRequired,
        hasTranscript: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        matches: PropTypes.arrayOf(PropTypes.object),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showHasTranscriptToggle: PropTypes.bool.isRequired,
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleEarningsOnly: PropTypes.func.isRequired,
        toggleHasTranscript: PropTypes.func.isRequired
    };

    static defaultProps = {
        EmptyComponent: undefined,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        passedStyles: {},
        streamId: null,
        subtitle: undefined
    };

    constructor(props) {
        super(props);

        this.streamRef = createRef();

        this.renderTypeSelector = this.renderTypeSelector.bind(this);
    }

    renderTypeSelector() {
        const {
            hasEarningsOnly,
            hasTranscript,
            showHasTranscriptToggle,
            streamId,
            toggleEarningsOnly,
            toggleHasTranscript
        } = this.props;
        return (
            <EventStreamToolbar
                hasEarningsOnly={hasEarningsOnly}
                hasTranscript={hasTranscript}
                showHasTranscriptToggle={showHasTranscriptToggle}
                streamId={streamId}
                toggleEarningsOnly={toggleEarningsOnly}
                toggleHasTranscript={toggleHasTranscript}
            />
        );
    }

    render() {
        const {
            children,
            dashboardId,
            EmptyComponent,
            loadMoreMatches,
            loading,
            matches,
            passedStyles,
            streamDisplayMode,
            streamId,
            streamProps,
            subtitle
        } = this.props;
        const streamChildren = [];
        let lastDate;
        const onScrollToEnd = get(this.streamRef, 'current.onScrollToEnd');

        // passing undefined children through
        // will prevent the stream's "empty state"
        // from rendering
        if (children) {
            streamChildren.push(children);
        }

        if (matches && matches.length > 0) {
            matches.forEach((m, idx) => {
                const CardComponent = COMPONENT_MAP[m.type];
                const cardDate = m.date ? new XDate(m.date) : false;
                if (
                    streamDisplayMode === STREAM_DISPLAY_MODES.default &&
                    cardDate &&
                    idx !== 0 &&
                    (cardDate.diffHours(lastDate).toFixed(0) > 24 || !lastDate)
                ) {
                    lastDate = cardDate;
                    streamChildren.push(
                        <Fragment key={`media-card-${m.id}`}>
                            <DateDivider date={cardDate} onScrollToTop={onScrollToEnd} />
                            <CardComponent displayMode={streamDisplayMode} {...m} />
                        </Fragment>
                    );
                } else {
                    streamChildren.push(
                        <CardComponent displayMode={streamDisplayMode} key={`media-card-${m.id}`} {...m} />
                    );
                }
            });
        }
        return (
            <Stream
                {...streamProps}
                scrollButtonDisabled
                streamRef={this.streamRef}
                dashboardId={dashboardId}
                EmptyComponent={EmptyComponent}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                offset={matches.length}
                streamId={streamId}
                styles={passedStyles}
                subtitle={subtitle}
                renderResultsHeader={this.renderTypeSelector}
            >
                {streamChildren}
            </Stream>
        );
    }
}

export const EventStreamUI = compose(withStyleSheet(styleSheet))(EventStream);
