import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                mutation submitEventConnectionDetails(
                    $eventId: ID!
                    $webcastUrl: String
                    $dialInPhoneNumber: String
                    $dialInPin: String
                    $issueText: String!
                ) {
                    raiseEventIssue(
                        input: {
                            issueText: $issueText
                            eventId: $eventId
                            issueCategory: connection_info
                            webcastUrl: $webcastUrl
                            dialInPhoneNumber: $dialInPhoneNumber
                            dialInPin: $dialInPin
                        }
                    ) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { eventId } }) => ({
                    submitDetails: ({ text, webcastUrl, phoneNumber, pin }) => {
                        return mutate({
                            variables: {
                                eventId,
                                webcastUrl,
                                dialInPhoneNumber: phoneNumber,
                                dialInPin: pin,
                                issueText: text || ''
                            }
                        });
                    }
                })
            }
        )
    );
