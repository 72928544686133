import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { DefaultPlayer } from './DefaultPlayer';
import { VimeoPlayer } from './VimeoPlayer';
import { styleSheet } from './stylesheet';

const PLAYER_COMPONENT_MAP = {
    vimeo: VimeoPlayer
};

class VideoPlayer extends PureComponent {
    static propTypes = {
        autoplay: PropTypes.bool.isRequired,
        onClickPreview: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        player: PropTypes.string,
        playerRef: PropTypes.objectOf(PropTypes.any),
        showControls: PropTypes.bool.isRequired,
        showPreview: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        url: PropTypes.string.isRequired
    };

    static defaultProps = {
        onClickPreview: undefined,
        passedStyles: {},
        player: undefined,
        playerRef: undefined
    };

    render() {
        const {
            autoplay,
            onClickPreview,
            passedStyles,
            player,
            playerRef,
            showControls,
            showPreview,
            styles,
            url
        } = this.props;
        const PlayerComponent = PLAYER_COMPONENT_MAP[player] || DefaultPlayer;
        return (
            <PlayerComponent
                autoplay={autoplay}
                onClickPreview={onClickPreview}
                playerRef={playerRef}
                showControls={showControls}
                showPreview={showPreview}
                styles={{ ...styles.container, ...passedStyles }}
                url={url}
            />
        );
    }
}

export const VideoPlayerUI = compose(withStyleSheet(styleSheet))(VideoPlayer);
