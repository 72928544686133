import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Card } from 'components/Card';
import { styleSheet } from './stylesheet';

class LiveTranscriptRow extends PureComponent {
    static propTypes = {
        dashSearchTerm: PropTypes.string,
        eventHref: PropTypes.string,
        partialTranscript: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showAnnotations: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        transcript: PropTypes.string,
        transcriptTime: PropTypes.instanceOf(XDate),
        copied: PropTypes.bool.isRequired,
        onCopy: PropTypes.func.isRequired
    };

    static defaultProps = {
        dashSearchTerm: undefined,
        eventHref: null,
        partialTranscript: null,
        passedStyles: {},
        transcriptTime: null,
        transcript: ''
    };

    renderPartial() {
        const { partialTranscript, showAnnotations, styles } = this.props;
        const transcriptStyles = showAnnotations
            ? [styles.annotations, styles.partialTranscript]
            : styles.partialTranscript;
        return (
            <Text size={3} span>
                <Text
                    key="livePartialTranscriptText" // eslint-disable-line react/no-array-index-key
                    span
                    size={3}
                    styles={transcriptStyles}
                >
                    {partialTranscript}
                </Text>
            </Text>
        );
    }

    render() {
        const {
            dashSearchTerm,
            eventHref,
            partialTranscript,
            passedStyles,
            showAnnotations,
            styles,
            transcriptTime,
            transcript,
            onCopy,
            copied
        } = this.props;
        const transcriptStyles = showAnnotations ? [styles.annotations, styles.transcript] : styles.transcript;
        const content = (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Link to={eventHref}>
                    <Div styles={dashSearchTerm ? styles.innerContainerCard : styles.innerContainer}>
                        <Div className="live-transcript-time" styles={styles.header}>
                            {transcriptTime && (
                                <Text size={0} styles={styles.timestamp}>
                                    {transcriptTime.toString('h:mm:ssTT')}
                                </Text>
                            )}
                            <Text onClick={onCopy} className="copyLiveText" styles={styles.copy} size={0}>
                                {copied ? 'Copied to clipboard!' : 'Click here to copy'}
                            </Text>
                        </Div>
                        <Div styles={transcriptStyles}>
                            {partialTranscript ? this.renderPartial() : <RawHTML html={transcript} />}
                        </Div>
                    </Div>
                </Link>
            </Div>
        );

        if (dashSearchTerm) {
            return <Card styles={styles.searchResult}>{content}</Card>;
        }

        return content;
    }
}

export const LiveTranscriptRowUI = compose(withStyleSheet(styleSheet))(LiveTranscriptRow);
