export const styleSheet = (theme, { hoverStyles }) => {
    const container = {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        scrollSnapAlign: 'start',
        overflow: 'hidden',
        paddingRight: 4,
        paddingBottom: 8,
        borderRadius: 0,
        boxShadow: 'unset',
        ':hover': {
            ...hoverStyles
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingLeft: 8,
            paddingRight: 0,
            minWidth: 'calc(100vw - 50px)',
            width: 'calc(100vw - 50px)'
        }
    };

    const scrollBtnHidden = {
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
        position: 'absolute',
        borderRadius: 30,
        height: 30,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.gray08,
        color: theme.colors.white01,
        zIndex: 1,
        alignSelf: 'center',
        transition: 'all 0.2s ease-in-out'
    };

    const scrollBtn = {
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
        position: 'absolute',
        borderRadius: 30,
        height: 30,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.gray08,
        color: theme.colors.white01,
        zIndex: 2,
        alignSelf: 'center',
        transition: 'all 0.2s ease-in-out',
        ':hover': {
            backgroundColor: theme.colors.gray11
        }
    };

    return {
        container: {
            position: 'relative',
            ...container
        },
        headerContainer: {
            position: 'relative',
            backgroundColor: theme.colors.gray01,
            display: 'flex',
            alignItems: 'center',
            minHeight: 40,
            height: 40,
            zIndex: 2,
            borderRadius: '6px 6px 0 0',
            overflow: 'hidden',
            '.icon-corner': {
                borderRadius: '5px 0 0 0',
                overflow: 'hidden'
            }
        },
        header: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: theme.fonts.fontSizes.size03,
            fontWeight: theme.fonts.fontWeights.normal
        },
        subheader: {
            display: 'none',
            color: theme.colors.gray04,
            letterSpacing: '1px',
            marginTop: '4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        headerTitleWrapper: {
            display: 'flex',
            minWidth: 0,
            flexDirection: 'column',
            height: '30px',
            justifyContent: 'center',
            marginRight: theme.margins.margin00,
            flex: 1
        },
        title: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        editIcon: {
            cursor: 'pointer',
            margin: '0 0 -1px 6px',
            svg: {
                width: 12,
                height: 12
            },
            ':hover': {
                'svg g': {
                    fill: theme.colors.blue01
                }
            }
        },
        headerWrapper: {},
        menuButtonSpacer: {
            height: 'calc(100% - 8px)',
            width: 30,
            margin: 4
        },
        menuOpen: {
            ...theme.content.hoverMenu,
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            borderRadius: 6
        },
        menuToggle: {
            borderRadius: 6,
            margin: 3,
            width: 34,
            height: 34,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)'
            },
            ':active': {
                backgroundColor: theme.colors.gray02
            }
        },
        menuItem: {
            ...theme.content.hoverMenuOption,
            a: {
                display: 'flex',
                color: theme.colors.black01,
                textDecoration: 'none'
            },
            p: {
                marginLeft: 10
            },
            svg: {
                width: 14
            },
            ':hover': {
                ...theme.content.hoverMenuOption[':hover'],
                a: {
                    color: theme.colors.white01,
                    textDecoration: 'none'
                }
            }
        },
        spacer: {
            flex: 1
        },
        hitContainer: {
            backgroundColor: theme.colors.gray01,
            paddingBottom: theme.margins.margin00,
            WebkitOverflowScrolling: 'touch',
            overflowY: 'scroll',
            flex: 1,
            borderRadius: '0 0 6px 6px'
        },
        colorIcon: {
            position: 'absolute',
            top: 2,
            left: 2
        },
        emptyState: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.colors.gray04,
            padding: `${theme.margins.margin02} 0`,
            svg: {
                width: 'calc(100% - 60px)',
                height: 210,
                marginBottom: 20
            }
        },
        headerTooltip: {
            ...theme.content.tooltipTextSmall,
            alignItems: 'unset',
            height: 'unset',
            padding: 10,
            flexDirection: 'column',
            p: {
                display: 'block',
                textTransform: 'capitalize'
            }
        },
        headerTooltipSubtitle: {
            lineHeight: '1.2em',
            marginTop: 6
        },
        headerTooltipTop: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        topScrollBtnHidden: {
            ...scrollBtnHidden,
            transform: 'translateY(0px)',
            top: 0
        },
        topScrollBtn: {
            ...scrollBtn,
            transform: 'translateY(50px)',
            top: 0
        },
        bottomScrollBtnHidden: {
            ...scrollBtnHidden,
            width: 120,
            bottom: -30
        },
        bottomScrollBtn: {
            ...scrollBtn,
            width: 120,
            bottom: 20
        },
        loadingBlock: {
            margin: '4px 3px',
            width: 'calc(100% - 6px)',
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
            ...theme.content.flickerAnimation
        },
        resizeHandle: {
            cursor: 'ew-resize',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 4,
            width: 4
        }
    };
};
