import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { KeywordSearchUI } from './ui';

export class KeywordSearch extends PureComponent {
    static displayName = 'KeywordSearchContainer';

    static propTypes = {
        onSearchClear: PropTypes.func,
        resultsIndex: PropTypes.number.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        setResultsIndex: PropTypes.func.isRequired
    };

    static defaultProps = {
        onSearchClear: undefined,
        searchResults: null
    };

    constructor(props) {
        super(props);

        this.onClear = this.onClear.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.initInput = this.initInput.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(event) {
        const { keyCode, shiftKey } = event;
        // Update resultsIndex only if the keyword input is in focus
        if (this.input === document.activeElement && keyCode === 13) {
            const { resultsIndex, searchResults, setResultsIndex } = this.props;
            const hasSearchResults = searchResults && searchResults.length > 0;
            const isPrevResultEnabled = hasSearchResults && resultsIndex > 0;
            const isNextResultsEnabled = hasSearchResults && resultsIndex < searchResults.length - 1;

            if (isNextResultsEnabled && !shiftKey) {
                setResultsIndex(resultsIndex + 1);
            } else if (isPrevResultEnabled && shiftKey) {
                setResultsIndex(resultsIndex - 1);
            }
        }
    }

    initInput(input) {
        this.input = input;
    }

    onClear() {
        const { onSearchClear } = this.props;

        if (this.input) {
            this.input.value = '';
            this.input.focus();
        }

        if (onSearchClear && typeof onSearchClear === 'function') {
            onSearchClear();
        }
    }

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <KeywordSearchUI {...rest} initInput={this.initInput} onClear={this.onClear} />;
    }
}

export const KeywordSearchContainer = compose()(KeywordSearch);
