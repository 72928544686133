import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormRowUI } from './ui';

export class FormRow extends PureComponent {
    static displayName = 'FormRowContainer';

    static propTypes = {
        description: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.string]),
        forId: PropTypes.string,
        label: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        description: undefined,
        forId: undefined,
        label: undefined,
        styles: undefined
    };

    render() {
        const { children, description, label, styles, forId } = this.props;
        return (
            <FormRowUI description={description} label={label} styles={styles} forId={forId}>
                {children}
            </FormRowUI>
        );
    }
}

export const FormRowContainer = compose()(FormRow);
