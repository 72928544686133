import React, { PureComponent } from 'react';
import { BackButton } from 'components/BackButton';
import { Div } from 'components/Div';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { Text } from 'components/Text';
import { PDFViewer } from 'components/PDFViewer';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { Notice } from 'components/Notice';
import { LoaderLogo } from 'components/LoaderLogo';
import { styleSheet } from './stylesheet';

class Research extends PureComponent {
    static propTypes = {
        pdfUrl: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        initialSearchTerm: PropTypes.string,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        researchId: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        researchLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        initialSearchTerm: undefined,
        pdfUrl: undefined,
        minimized: false,
        passedStyles: {},
        researchId: undefined,
        setToolbarTitle: null
    };

    componentDidMount() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    componentDidUpdate({ title: prevTitle }) {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title !== prevTitle) {
            setToolbarTitle(title);
        }
    }

    render() {
        const {
            initialSearchTerm,
            researchLoading,
            pdfUrl,
            researchId,
            styles,
            theme,
            passedStyles,
            containerRef,
            minimized
        } = this.props;

        if (minimized) {
            return null;
        }

        if (researchLoading) {
            return (
                <Div styles={styles.wrapperCentered}>
                    <LoaderLogo height={80} />
                    <Text size={3}>Validating Permission & Loading...</Text>
                </Div>
            );
        }

        return (
            <WithPermission permissions={[PERMISSIONS.featureStreamsResearch]}>
                {({ restricted }) =>
                    restricted ? (
                        <Div styles={styles.wrapperCentered}>
                            <Notice type="info" styles={styles.editInProgress} richContent>
                                <Text size={3}>You are not authorized to view research on Aiera</Text>
                                <Text size={3} styles={{ marginTop: 6, color: theme.colors.gray06 }}>
                                    Please reach out to <a href="mailto:sales@aiera.com">sales@aiera.com</a>
                                </Text>
                            </Notice>
                        </Div>
                    ) : (
                        <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                                {m => m && <BackButton back styles={styles.backButton} />}
                            </MediaQuery>
                            <PDFViewer
                                targetId={researchId}
                                targetType="content_research"
                                initialSearchTerm={initialSearchTerm}
                                pdfUrl={pdfUrl}
                            />
                        </Div>
                    )
                }
            </WithPermission>
        );
    }
}

export const ResearchUI = compose(withStyleSheet(styleSheet))(Research);
