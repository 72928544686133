import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { styleSheet } from './stylesheet';

class Modal extends PureComponent {
    static propTypes = {
        cancelClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        isOpen: PropTypes.bool.isRequired,
        modalContainer: PropTypes.objectOf(PropTypes.any).isRequired,
        mounted: PropTypes.bool.isRequired,
        onModalClose: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
        useShell: PropTypes.bool.isRequired
    };

    static defaultProps = {
        cancelClassName: null,
        passedStyles: {},
        title: null
    };

    render() {
        const {
            styles,
            passedStyles,
            theme,
            children,
            isOpen,
            modalContainer,
            mounted,
            onModalClose,
            title,
            cancelClassName,
            useShell
        } = this.props;

        if (isOpen) {
            return ReactDOM.createPortal(
                <OutsideClickHandler
                    onClick={onModalClose}
                    cancelClassName={['modal-cancelOutsideClick', cancelClassName]}
                >
                    {({ setRef }) =>
                        useShell ? (
                            <Div className="modal-container" id="modal-container" styles={styles.containerShell}>
                                <Div ref={setRef} styles={{ ...styles.modalShell, ...passedStyles }}>
                                    {mounted && children}
                                </Div>
                            </Div>
                        ) : (
                            <Div className="modal-container" id="modal-container" styles={styles.container}>
                                <Div styles={{ ...styles.content, ...passedStyles }} ref={setRef}>
                                    <Div styles={styles.header} className="modalHeader">
                                        {title && typeof title === 'string' && <Heading size={1}>{title}</Heading>}
                                        {title && typeof title === 'object' && title}
                                        <Div styles={styles.closeModal} onClick={onModalClose}>
                                            <Icon type="xMark" color={theme.colors.black01} />
                                        </Div>
                                    </Div>
                                    {mounted && children}
                                </Div>
                            </Div>
                        )
                    }
                </OutsideClickHandler>,
                modalContainer
            );
        }

        return null;
    }
}

export const ModalUI = compose(withStyleSheet(styleSheet))(Modal);
