import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withEventDetails, withDisconnectEvent, withDisconnectPrivateRecording } from 'graphql/audioCalls';
import { get } from 'utils';
import { EventTitleInfoUI } from './ui';

export class EventTitleInfo extends PureComponent {
    static displayName = 'EventTitleInfoContainer';

    static propTypes = {
        callDate: PropTypes.string,
        callType: PropTypes.string,
        company: PropTypes.objectOf(PropTypes.any),
        disconnectCall: PropTypes.func.isRequired,
        disconnectPrivateRecording: PropTypes.func.isRequired,
        eventId: PropTypes.string,
        hasPublishedTranscript: PropTypes.bool.isRequired,
        hasUnknownTime: PropTypes.bool,
        inProgress: PropTypes.bool,
        isArchived: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool,
        isPublic: PropTypes.bool,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        isUploading: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        minimal: PropTypes.bool,
        onEditEvent: PropTypes.func.isRequired,
        privateRecordingId: PropTypes.string,
        publishedTranscriptSource: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.any),
        title: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any),
        waiting: PropTypes.bool
    };

    static defaultProps = {
        callDate: undefined,
        callType: 'Unknown',
        company: undefined,
        eventId: undefined,
        hasUnknownTime: false,
        inProgress: false,
        isPublic: false,
        isOwner: false,
        loading: false,
        minimal: false,
        privateRecordingId: undefined,
        publishedTranscriptSource: undefined,
        styles: undefined,
        tags: [],
        title: undefined,
        userTags: [],
        waiting: false
    };

    constructor(props) {
        super(props);

        this.disconnect = this.disconnect.bind(this);

        this.state = {
            disconnected: false,
            disconnecting: false
        };
    }

    disconnect() {
        this.setState({ disconnecting: true }, () => {
            const { disconnectCall, disconnectPrivateRecording, eventId, privateRecordingId } = this.props;
            (privateRecordingId ? disconnectPrivateRecording(privateRecordingId) : disconnectCall(eventId)).finally(
                () => {
                    this.setState({
                        disconnected: true,
                        disconnecting: false
                    });
                }
            );
        });
    }

    render() {
        const { disconnected, disconnecting } = this.state;
        const {
            callDate,
            callType,
            company,
            eventId,
            hasPublishedTranscript,
            hasUnknownTime,
            inProgress,
            isArchived,
            isOwner,
            isPublic,
            isRead,
            isStarred,
            isUploading,
            loading,
            minimal,
            onEditEvent,
            publishedTranscriptSource,
            styles,
            tags,
            title,
            userTags,
            waiting
        } = this.props;
        return (
            <EventTitleInfoUI
                callDate={callDate}
                callType={callType}
                companyName={get(company, 'commonName')}
                disconnect={this.disconnect}
                disconnected={disconnected}
                disconnecting={disconnecting}
                eventId={eventId}
                hasPublishedTranscript={hasPublishedTranscript}
                hasUnknownTime={hasUnknownTime}
                inProgress={inProgress}
                isPublic={isPublic}
                isArchived={isArchived}
                isOwner={isOwner}
                isRead={isRead}
                isStarred={isStarred}
                isUploading={isUploading}
                loading={loading}
                minimal={minimal}
                onEditEvent={onEditEvent}
                publishedTranscriptSource={publishedTranscriptSource}
                styles={styles}
                tags={tags}
                title={title}
                userTags={userTags}
                waiting={waiting}
            />
        );
    }
}

export const EventTitleInfoContainer = compose(
    withUrlContext(['audioCallId']),
    withDisconnectEvent(),
    withDisconnectPrivateRecording(),
    withEventDetails({ fetchPolicy: 'cache-only', withCompany: true }),
    withProps(({ audioCall }) => {
        const events = get(audioCall, 'events');
        const inProgress = get(audioCall, 'isLive');
        return {
            ...audioCall,
            isArchived: get(audioCall, 'userSettings.archived', false),
            isRead: get(audioCall, 'userSettings.isRead', false),
            isStarred: get(audioCall, 'userSettings.starred', false),
            isUploading: get(audioCall, 'isUploading', false),
            company: get(audioCall, 'primaryCompany'),
            inProgress,
            tags: get(audioCall, 'tags', []),
            userTags: get(audioCall, 'userSettings.tags', []),
            waiting: inProgress && !events.length
        };
    })
)(EventTitleInfo);
