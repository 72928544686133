import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TooltipTagFormUI } from './ui';

export class TooltipTagForm extends PureComponent {
    static displayName = 'TooltipTagFormContainer';

    static propTypes = {
        className: PropTypes.string,
        buttonDisabled: PropTypes.bool,
        error: PropTypes.string,
        hideTooltip: PropTypes.func,
        loading: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        buttonDisabled: false,
        error: null,
        hideTooltip: undefined,
        loading: false,
        onCancel: undefined,
        onSubmit: undefined,
        styles: undefined,
        type: undefined
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onPlainText = this.onPlainText.bind(this);

        this.state = {
            selectedTagText: '',
            newTagText: ''
        };
    }

    handleChange({ value }) {
        this.setState({ selectedTagText: value });
    }

    onPlainText({ value }) {
        this.setState({ newTagText: value });
    }

    onSubmit() {
        const { onSubmit, hideTooltip } = this.props;
        const { selectedTagText, newTagText } = this.state;

        if (onSubmit) {
            onSubmit({ tag: selectedTagText && selectedTagText.length > 0 ? selectedTagText : newTagText });
            if (hideTooltip) hideTooltip();
        }
    }

    render() {
        const { className, buttonDisabled, error, hideTooltip, loading, onCancel, styles, type } = this.props;
        const { selectedTagText } = this.state;
        return (
            <TooltipTagFormUI
                className={className}
                buttonDisabled={buttonDisabled}
                error={error}
                loading={loading}
                onCancel={onCancel || hideTooltip}
                onChange={this.handleChange}
                onPlainText={this.onPlainText}
                onSubmit={this.onSubmit}
                styles={styles}
                type={type}
                value={selectedTagText}
            />
        );
    }
}

export const TooltipTagFormContainer = compose()(TooltipTagForm);
