export const styleSheet = theme => {
    return {
        container: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            borderRadius: 6,
            marginRight: 6,
            height: 30,
            fontSize: theme.fonts.fontSizes.size01,
            boxShadow: 'unset',
            backgroundColor: theme.colors.green03,
            color: theme.colors.white01,
            svg: {
                marginRight: 4
            },
            ':hover': {
                backgroundColor: theme.colors.green06
            },
            ':active': {
                backgroundColor: theme.colors.green07
            }
        },
        disableTitle: {
            height: 75,
            position: 'absolute',
            top: -8,
            left: -20,
            right: -20,
            zIndex: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(4px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        fileUpload: {
            label: { display: 'block' }
        },
        processing: {
            textAlign: 'center',
            marginTop: 14,
            color: theme.colors.gray08,
            lineHeight: '1.3em'
        },
        notice: {
            borderRadius: 6,
            margin: '10px 0 20px'
        },
        noticeAudio: {
            paddingTop: 10,
            marginTop: 10,
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            position: 'relative',
            svg: {
                position: 'absolute',
                left: -28,
                top: 14
            }
        },
        emailDocuments: {
            paddingTop: 10,
            marginTop: 10,
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            position: 'relative',
            a: {
                color: 'blue',
                textDecoration: 'none',
                ':hover': {
                    textDecoration: 'underline'
                }
            },
            svg: {
                position: 'absolute',
                left: -29,
                top: 14
            }
        },
        saving: {
            marginBottom: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 53.5,
            border: `2px dotted ${theme.colors.gray09}`,
            borderRadius: 6,
            color: theme.colors.gray08,
            fontSize: theme.fonts.fontSizes.size02,
            letterSpacing: '1px',
            lineHeight: '1.5em',
            opacity: 0.8,
            textTransform: 'uppercase'
        },
        saveDocument: {
            ...theme.content.blueButton,
            marginTop: 20,
            color: theme.colors.white01,
            borderRadius: 6,
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            boxShadow: 'unset'
        },
        success: {
            marginTop: 16,
            lineHeight: '1.2em',
            textAlign: 'center'
        },
        tagEquityLoading: {
            marginTop: 20,
            height: 41,
            marginBottom: 20
        },
        tagEquity: {
            marginTop: 20,
            marginBottom: 20
        },
        title: {
            marginBottom: 20
        },
        titleWrapper: {
            position: 'relative'
        },
        tooltip: {
            ...theme.content.hoverMenu,
            overflow: 'hidden',
            overflowY: 'auto'
        },
        tooltipContent: {
            position: 'relative',
            backgroundColor: 'white',
            padding: '18px 20px',
            width: 440
        }
    };
};
