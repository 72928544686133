import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { AddMonitorButton } from 'components/AddMonitorButton';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { InternalLink } from 'components/InternalLink';
import { ExternalLink } from 'components/ExternalLink';
import { Modal } from 'components/Modal';
import { Span } from 'components/Span';
import { Streams } from 'components/Streams';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { get, titleize, toDateString } from 'utils';
import { DashboardGalleryMenu } from './DashboardGalleryMenu';
import { styleSheet } from './stylesheet';
import { LoaderLogo } from '../LoaderLogo';

class DashboardGallery extends PureComponent {
    static propTypes = {
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        customDashFormUrl: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        onClickDashboard: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchTerm: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.object),
        showUpgradeModal: PropTypes.func.isRequired,
        streams: PropTypes.arrayOf(PropTypes.object).isRequired,
        streamsLoading: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        currentDashboard: null,
        passedStyles: {},
        sections: []
    };

    renderHeader() {
        const { currentDashboard, searchTerm, styles, theme } = this.props;

        if (!currentDashboard) {
            return null;
        }

        const {
            creatingUser,
            description,
            dashboardType,
            modified,
            name,
            tags,
            clonedToId,
            starred
        } = currentDashboard;
        const added = clonedToId || starred;
        return (
            <Div styles={styles.header}>
                <Div styles={styles.headerLeft}>
                    <Heading size={2}>{name}</Heading>
                    {description && (
                        <Text size={2} styles={styles.description}>
                            {description}
                        </Text>
                    )}
                    {tags && tags.length > 0 && (
                        <Div styles={styles.tagsContainer}>
                            {tags.map(tag => (
                                <Text key={tag} size={0} styles={styles.tag}>
                                    {tag}
                                </Text>
                            ))}
                        </Div>
                    )}
                </Div>
                <Div styles={styles.headerRight}>
                    <Div styles={styles.monitorActions}>
                        {!!added && (
                            <Div styles={styles.previouslyAdded}>
                                <Icon type="checkMarkSmall" color={theme.colors.yellow07} />
                                <Text size={1}>You&apos;ve added this monitor.</Text>
                            </Div>
                        )}
                        <WithPermission permissions={[PERMISSIONS.unlockedDashboardGalleryAllAccess]}>
                            {({ restricted: limitedAccess, isLoading }) => (
                                <WithPermission permissions={[PERMISSIONS.unlockedFollowDashboard]}>
                                    {({ restricted }) =>
                                        added && dashboardType === 'equity' ? null : (
                                            <AddMonitorButton
                                                isStarred={starred}
                                                follow={dashboardType === 'equity'}
                                                navOnCreate={false}
                                                restricted={restricted || isLoading || limitedAccess}
                                                dashboardGallerySearch={searchTerm}
                                                dashboardId={currentDashboard.id}
                                                styles={styles.followButton}
                                            />
                                        )
                                    }
                                </WithPermission>
                            )}
                        </WithPermission>
                    </Div>
                    {(creatingUser || modified) && dashboardType !== 'equity' && (
                        <Div styles={styles.dashInfo}>
                            {creatingUser && (
                                <Div>
                                    <Text size={0} uppercase styles={styles.dashInfoLabel}>
                                        Created By
                                    </Text>
                                    <Text size={2}>
                                        {titleize(
                                            `${get(creatingUser, 'firstName', '')} ${get(creatingUser, 'lastName', '')}`
                                        )}
                                    </Text>
                                </Div>
                            )}
                            {modified && (
                                <Div styles={styles.dashLastModified}>
                                    <Text size={0} uppercase styles={styles.dashInfoLabel}>
                                        Last Modified
                                    </Text>
                                    <Text size={2}>{toDateString(modified, true)}</Text>
                                </Div>
                            )}
                        </Div>
                    )}
                </Div>
            </Div>
        );
    }

    renderModalTitle() {
        const { styles, theme } = this.props;
        const tooltipText = (
            <Text size={1} styles={styles.modalHeaderText}>
                Click the &quot;Add Monitor&ldquo; button to add monitors to your sidebar menu, for easy access.
            </Text>
        );
        return (
            <Tooltip
                content={tooltipText}
                isEnabled
                styles={styles.modalHeaderTooltip}
                useElementOffsetLeft
                useElementOffsetTop
                xOffset={23}
                yOffset={-10}
            >
                {({ showTooltip, hideTooltip }) => (
                    <Fragment>
                        <Heading size={1} styles={styles.modalHeader}>
                            <Icon type="gallery" color={theme.colors.black01} />
                            Gallery
                            <Span onMouseEnter={showTooltip} onMouseLeave={hideTooltip} styles={styles.modalHeaderIcon}>
                                <Icon type="circleInfo02" color={theme.colors.gray04} />
                            </Span>
                        </Heading>
                    </Fragment>
                )}
            </Tooltip>
        );
    }

    renderStreams() {
        const { currentDashboard, streams, streamsLoading, styles } = this.props;

        if (streamsLoading) {
            return <LoaderLogo height={60} styles={styles.streamsLoading} />;
        }

        return (
            <Streams
                allowNewStreams={false}
                dashboardId={get(currentDashboard, 'id')}
                dashboardGuid={get(currentDashboard, 'dashboardGuid')}
                isCreator={false}
                streams={streams}
                streamDisplayMode={STREAM_DISPLAY_MODES.default}
                styles={styles.streams}
            />
        );
    }

    render() {
        const {
            currentDashboard,
            customDashFormUrl,
            loading,
            onClickDashboard,
            onClose,
            onKeyDown,
            onSearch,
            passedStyles,
            searchTerm,
            sections,
            showUpgradeModal,
            styles,
            theme
        } = this.props;
        const requestTooltip = (
            <Text size={3}>
                Send Aiera Research a monitor topic you&apos;d like covered. Our staff will get busy building it for
                you.
            </Text>
        );

        return (
            <Modal isOpen onModalClose={onClose} styles={styles.modal} title={this.renderModalTitle()}>
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div styles={styles.menu}>
                        <DashboardGalleryMenu
                            currentDashboard={currentDashboard}
                            loading={loading}
                            onClickDashboard={onClickDashboard}
                            onClose={onClose}
                            onKeyDown={onKeyDown}
                            onSearch={onSearch}
                            searchTerm={searchTerm}
                            sections={sections}
                            styles={styles.dashboardGalleryMenu}
                        />
                        <Div styles={styles.buttons}>
                            <WithPermission permissions={[PERMISSIONS.unlockedCreateDashboard]}>
                                {({ restricted, isLoading }) =>
                                    restricted || isLoading ? (
                                        <Div
                                            styles={styles.button}
                                            onClick={() => (isLoading ? {} : showUpgradeModal('dashboard'))}
                                        >
                                            <Icon type="lock02" color={theme.colors.gray06} />
                                            <Text size={1}>Custom</Text>
                                        </Div>
                                    ) : (
                                        <InternalLink to={customDashFormUrl} styles={styles.button}>
                                            <Icon type="newDashboard" color={theme.colors.gray06} />
                                            <Text size={1}>Custom</Text>
                                        </InternalLink>
                                    )
                                }
                            </WithPermission>
                            <Tooltip
                                styles={styles.tooltip}
                                isEnabled
                                useElementOffsetLeft
                                useElementOffsetTop
                                xOffset={0}
                                yOffset={-84}
                                content={requestTooltip}
                            >
                                {({ showTooltip, hideTooltip }) => (
                                    <WithPermission permissions={[PERMISSIONS.unlockedRequestCustomDashboard]}>
                                        {({ restricted, isLoading }) =>
                                            restricted || isLoading ? (
                                                <Div
                                                    onMouseEnter={showTooltip}
                                                    onMouseLeave={hideTooltip}
                                                    styles={styles.button}
                                                    onClick={() =>
                                                        isLoading ? {} : showUpgradeModal('dashboardRequest')
                                                    }
                                                >
                                                    <Icon type="lock02" color={theme.colors.gray06} />
                                                    <Text size={1}>Request</Text>
                                                </Div>
                                            ) : (
                                                <ExternalLink
                                                    onMouseEnter={showTooltip}
                                                    onMouseLeave={hideTooltip}
                                                    styles={styles.button}
                                                    href="mailto:research@aiera.com?subject=Hi! I would like this topic covered in a monitor. Can you build it for me?&body=Here is the topic I would like covered:"
                                                >
                                                    <Icon type="gift" color={theme.colors.gray06} />
                                                    <Text size={1}>Request</Text>
                                                </ExternalLink>
                                            )
                                        }
                                    </WithPermission>
                                )}
                            </Tooltip>
                        </Div>
                    </Div>
                    <Div styles={styles.content}>
                        {this.renderHeader()}
                        {currentDashboard && this.renderStreams()}
                    </Div>
                </Div>
            </Modal>
        );
    }
}

export const DashboardGalleryUI = compose(withStyleSheet(styleSheet))(DashboardGallery);
