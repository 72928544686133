import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { DateInput } from 'components/DateInput';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { FormRow } from 'components/FormRow';
import { Heading } from 'components/Heading';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { WithPermission } from 'components/WithPermission';
import { ENVS, PERMISSIONS } from 'consts';
import { CONNECT_OFFSET_SECONDS_OPTIONS, CONNECTION_TYPES, SCHEDULE_OPTIONS } from '../consts';
import { styleSheet } from './stylesheet';

const TODAY = new Date();

class Scheduling extends PureComponent {
    static propTypes = {
        connectionType: PropTypes.string,
        connectOffsetSeconds: PropTypes.number,
        date: PropTypes.objectOf(PropTypes.any),
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        meridiem: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        scheduledFor: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        time: PropTypes.string,
        type: PropTypes.string,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        connectionType: undefined,
        connectOffsetSeconds: 0,
        date: null,
        passedStyles: {},
        scheduledFor: undefined,
        time: '',
        type: undefined
    };

    renderOptions() {
        const { styles, type } = this.props;
        return SCHEDULE_OPTIONS.map(({ description, label, value }) => (
            <Option key={value} type="radio" value={value}>
                <Div>
                    <Text size={3} weight={type === value ? 'medium' : 'normal'}>
                        {label}
                    </Text>
                    <Text size={1} styles={styles.optionDescription}>
                        {description}
                    </Text>
                </Div>
            </Option>
        ));
    }

    render() {
        const {
            connectionType,
            connectOffsetSeconds,
            date,
            errors,
            meridiem,
            onBlur,
            onChange,
            onFocus,
            passedStyles,
            scheduledFor,
            styles,
            time,
            type,
            visible
        } = this.props;
        if (!visible) return null;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Heading size={2} styles={styles.heading}>
                    Schedule
                </Heading>
                <FormRow
                    description="Aiera will automatically connect to your call when you decide"
                    label="When does this event take place?"
                    styles={styles.formRow}
                >
                    <MultiSelect name="scheduleType" onChange={onChange} required selected={[type]}>
                        {this.renderOptions()}
                    </MultiSelect>
                </FormRow>
                {type === 'future' && (
                    <FormRow
                        description="Aiera will automatically connect at this time"
                        label="Date & Time"
                        styles={styles.formRow}
                    >
                        <Div styles={styles.dateTimeContainer}>
                            <WithPermission
                                env={ENVS.development}
                                permissions={[PERMISSIONS.unlockedCreatePastPrivateRecording]}
                            >
                                {({ isLoading, restricted }) => (
                                    <DateInput
                                        minDate={isLoading || restricted ? TODAY : undefined}
                                        name="scheduleDate"
                                        onChange={onChange}
                                        styles={styles.dateInput}
                                        value={date}
                                    />
                                )}
                            </WithPermission>
                            <TextInput
                                error={errors.scheduleTime}
                                mask={{ regex: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$' }}
                                name="scheduleTime"
                                onBlur={event => onBlur({ event, name: 'scheduleTime', value: time })}
                                onChange={onChange}
                                onFocus={event => onFocus({ event, name: 'scheduleTime' })}
                                placeholder="10:00"
                                styles={styles.textInput}
                                value={time}
                            />
                            <Dropdown
                                disableHoverOpen
                                name="scheduleMeridiem"
                                onChange={onChange}
                                options={[
                                    { label: 'AM', value: 'AM' },
                                    { label: 'PM', value: 'PM' }
                                ]}
                                styles={styles.dropdown}
                                value={meridiem}
                            />
                            {!!scheduledFor && !!time && (
                                <Text size={2} styles={styles.scheduledFor}>
                                    {scheduledFor}
                                </Text>
                            )}
                        </Div>
                    </FormRow>
                )}
                {type === 'future' && connectionType !== CONNECTION_TYPES.webcast.value && (
                    <FormRow
                        description="How soon before the call starts should we connect?"
                        label="When should we connect?"
                        styles={styles.formRow}
                    >
                        <Dropdown
                            disableHoverOpen
                            name="connectOffsetSeconds"
                            onChange={onChange}
                            options={CONNECT_OFFSET_SECONDS_OPTIONS}
                            value={connectOffsetSeconds}
                        />
                    </FormRow>
                )}
            </Div>
        );
    }
}

export const SchedulingUI = compose(withStyleSheet(styleSheet))(Scheduling);
