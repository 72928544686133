import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { SearchCommandLegendUI } from './ui';

export class SearchCommandLegend extends PureComponent {
    static displayName = 'SearchCommandLegendContainer';

    static tooltipOptions = {
        slideIn: true,
        xOffset: 0,
        yOffset: 10,
        useElementOffsetLeft: true,
        useElementOffsetBottom: true
    };

    render() {
        return <SearchCommandLegendUI />;
    }
}

export const SearchCommandLegendContainer = compose()(SearchCommandLegend);
