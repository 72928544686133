export const fontFamily = "'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif";
export const fontFamilyContent = "'CharisSIL', Georgia, Times, 'Times New Roman', serif";
export const fontWeights = {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    black: 900
};
export const fontSizes = {
    size00: '10px',
    size01: '12px',
    size02: '13px',
    size03: '15px',
    size04: '16px',
    size05: '18px',
    size06: '25px'
};

export const lineHeights = {
    size06: '32px'
};
