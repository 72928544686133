import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TickerTag } from 'components/TickerTag';
import { styleSheet } from './stylesheet';

// eslint-disable-next-line react/prop-types
const EquityAutocompleteOption = ({ exchange, name: companyName, ticker }) => {
    let name = exchange || '';
    if (companyName) name += ` • ${companyName}`;
    return (
        <Div styles={{ display: 'flex', alignItems: 'center' }}>
            <TickerTag ticker={ticker} />
            <Text size={3} styles={{ marginLeft: 10 }}>
                {name}
            </Text>
        </Div>
    );
};

class EquityAutocomplete extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        clearOnSelect: PropTypes.bool.isRequired,
        getTagLabel: PropTypes.func.isRequired,
        icon: PropTypes.string,
        id: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        resultsPlaceholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        useTags: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        wrapTags: PropTypes.bool.isRequired
    };

    static defaultProps = {
        icon: undefined,
        id: undefined,
        initialSearchTerm: null,
        label: undefined,
        passedStyles: {},
        resultsPlaceholder: undefined,
        value: undefined
    };

    render() {
        const {
            autoFocus,
            clearOnSelect,
            getTagLabel,
            icon,
            id,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onSearch,
            onSelect,
            options,
            passedStyles,
            placeholder,
            resultsPlaceholder,
            styles,
            useTags,
            value,
            wrapTags
        } = this.props;
        return (
            <Autocomplete
                autoFocus={autoFocus}
                clearOnSelect={clearOnSelect}
                OptionComponent={EquityAutocompleteOption}
                getTagLabel={getTagLabel}
                icon={icon}
                id={id}
                initialSearchTerm={initialSearchTerm}
                keepInViewport
                label={label}
                loading={loading}
                menuStyles={styles.menu}
                multi={multi}
                name={name}
                onChange={onSelect}
                onSearch={onSearch}
                options={options}
                placeholder={placeholder}
                resultsPlaceholder={resultsPlaceholder}
                styles={{ ...styles.container, ...passedStyles }}
                strictOptions={false}
                useTags={useTags}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const EquityAutocompleteUI = compose(withStyleSheet(styleSheet))(EquityAutocomplete);
