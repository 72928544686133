import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { routes } from 'routes';
import { OPERATORS, TYPES } from 'consts/filters';
import { get, validateInputs, hasPermission, copyToClipboard } from 'utils';
import {
    getBookmarkFilters,
    getTypeFromStream,
    mapRulesToFilters,
    mapContentTypesToFilters,
    mapScopesToSelectedType
} from 'utils/streams';
import { PERMISSIONS } from 'consts';
import { withUser } from 'graphql/user';
import { withData, formatInput } from './data';
import { StreamFormUI } from './ui';

// Formik props
const INITIAL_VALUES = {
    name: '',
    streamSearchTerm: '',
    bookmarkTags: ''
};
const INPUT_VALIDATION_RULES = [{ name: 'name' }];

const EQUITY_SCOPE_FILTERS = [TYPES.equity, TYPES.index, TYPES.sector, TYPES.watchlist];
const EXCLUDED_FILTERS = [TYPES.searchTerm, TYPES.type, TYPES.spotlightType, TYPES.tag];
const STREAM_TYPES = {
    content: {
        label: 'Transcripts & Event Docs',
        description: 'Monitor keywords across event transcripts, press releases, and presentation slides.'
    },
    corporate_action: {
        label: 'Corporate Activity',
        description: 'Monitor keywords across corporate activity.'
    },
    research: {
        label: 'Broker Research',
        description: 'Monitor keywords across all broker research reports, including audio and video.',
        permission: PERMISSIONS.featureStreamsResearch
        // lockedPermission: PERMISSIONS.unlockedResearchContent
    },
    news: {
        label: 'News & Media',
        description: 'Monitor keywords across news and media.'
    },
    document: {
        label: 'Document Uploads',
        description: "Your team's PDFs and Word docs uploaded through the dashboard or API.",
        permission: PERMISSIONS.featureUploads
    },
    spotlight: {
        label: 'Corporate Activity',
        description: 'Guidance updates, M&A, business updates and other company highlights.',
        permission: PERMISSIONS.featureStreamsSpotlight,
        lockedPermission: PERMISSIONS.unlockedSpotlightContent
    },
    filings: {
        label: 'Filings',
        description: 'Financial statements and other documents submitted to regulatory agencies.',
        permission: PERMISSIONS.featureStreamsFilings,
        lockedPermission: PERMISSIONS.unlockedEquityFilings
    },
    events: { label: 'Event Schedules', description: 'See upcoming, in-progress, recent, and custom events.' },
    bookmarks: {
        label: 'Highlights',
        description: 'View highlights created by you and members of your organization.',
        permission: PERMISSIONS.featureBookmarks
    },
    custom_data: {
        label: 'Custom Data',
        description: 'Create a search from your own custom data source.',
        permission: PERMISSIONS.featureStreamCustom,
        lockedPermission: PERMISSIONS.unlockedCreateStreamCustom
    }
};
const BOOKMARK_TYPES = {
    all: {
        label: 'All Highlights',
        description: 'Created by you and members of your organization'
    },
    my: {
        label: 'Mine Only',
        description: 'Created by you only'
    },
    team: {
        label: 'Team Only',
        description: 'Created by other members of your organization'
    }
};
const CONTENT_TYPES = {
    transcript: {
        label: 'Event Transcripts',
        description: 'Track terms in events, transcribed in real time.'
    },
    attachment: {
        label: 'Event Docs',
        description: 'Keywords in event press releases & slides.'
    },
    news: {
        label: 'News & Media',
        description: "Curated articles & media as they're published.",
        lockedPermission: PERMISSIONS.unlockedCreateStreamNews
    }
    /*
    streetaccount: {
        label: 'StreetAccount',
        description: 'Filtered to the sectors & equities you choose.'
    } */
};

const STREAM_TYPE_TRANSCRIPTS = 'transcripts';
const STREAM_TYPE_CONTENT = 'content';
const STREAM_TYPE_CORPORATE_ACTION = 'corporate_action';
const STREAM_TYPE_GSHEET = 'gsheet';
const STREAM_TYPE_CUSTOM_DATA = 'custom_data';
const STREAM_TYPE_SPOTLIGHT = 'spotlight';
const STREAM_TYPE_RESEARCH = 'research';
const STREAM_TYPE_NEWS = 'news';
const STREAM_TYPE_DOCUMENT = 'document';
const STREAM_TYPE_FILINGS = 'filings';
const SEARCH_TERM_STREAM_TYPES = [
    STREAM_TYPE_CONTENT,
    STREAM_TYPE_CORPORATE_ACTION,
    STREAM_TYPE_RESEARCH,
    STREAM_TYPE_NEWS,
    STREAM_TYPE_DOCUMENT,
    STREAM_TYPE_GSHEET,
    STREAM_TYPE_CUSTOM_DATA,
    STREAM_TYPE_SPOTLIGHT,
    STREAM_TYPE_FILINGS
];

function formFilters(streamType, filters) {
    const excluded = streamType === STREAM_TYPE_GSHEET ? [...EXCLUDED_FILTERS, TYPES.source] : EXCLUDED_FILTERS;
    return filters.filter(f => f && !excluded.includes(f.type));
}

function mapBookmarkTagsToFilters(tags) {
    if (tags && tags.length) {
        return [
            {
                type: TYPES.tag,
                operator: OPERATORS.is,
                value: tags
            }
        ];
    }
    return [];
}

function mapFiltersToBookmarkTags(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.tag && f.operator === OPERATORS.is),
        'value',
        []
    );
}

function mapFiltersToSearchTerms(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.searchTerm && f.operator === OPERATORS.is),
        'value',
        []
    );
}

function mapSearchTermsToFilters(terms) {
    if (terms && terms.length) {
        return [
            {
                type: TYPES.searchTerm,
                operator: OPERATORS.is,
                value: terms
            }
        ];
    }
    return [];
}

function mapFilingFormsToFilters(types) {
    if (types && types.length) {
        return types.map(value => ({
            type: TYPES.filingForm,
            operator: OPERATORS.is,
            value
        }));
    }

    return [];
}

function mapSpotlightTypesToFilters(types) {
    if (types && types.length) {
        return types.map(value => ({
            type: TYPES.spotlightType,
            operator: OPERATORS.is,
            value
        }));
    }

    return [];
}

function mapFiltersToContentTypes(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.type && f.operator === OPERATORS.is),
        'value.type',
        []
    );
}

function mapFiltersToSpotlightTypes(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.spotlightType && f.operator === OPERATORS.is),
        'value',
        []
    );
}

function mapFiltersToFilingForms(filters) {
    return get(
        filters.find(f => f && f.type === TYPES.filingForm && f.operator === OPERATORS.is),
        'value',
        []
    );
}

function mapEquityScopesToFilters(equityScopes) {
    if (equityScopes && equityScopes.length) {
        return equityScopes.map(({ type, value }) => ({
            operator: OPERATORS.is,
            type,
            value
        }));
    }

    return [];
}

function mapGSheetSourceToFilter(source) {
    if (!source) return null;
    return {
        type: TYPES.source,
        operator: OPERATORS.is,
        value: [source]
    };
}

function allFilters({
    bookmarkTags,
    equityScope,
    streamType,
    filingForms,
    filters,
    searchTerms,
    contentTypes,
    gSheetSource,
    spotlightTypes,
    selectedBookmarkType
}) {
    return [
        ...mapBookmarkTagsToFilters(bookmarkTags),
        ...mapContentTypesToFilters(contentTypes),
        ...mapFilingFormsToFilters(filingForms),
        ...mapSpotlightTypesToFilters(spotlightTypes),
        ...mapEquityScopesToFilters(equityScope),
        ...formFilters(streamType, filters),
        ...mapSearchTermsToFilters(searchTerms),
        ...getBookmarkFilters(selectedBookmarkType),
        mapGSheetSourceToFilter(gSheetSource)
    ];
}

export class StreamForm extends PureComponent {
    static displayName = 'StreamFormContainer';

    static propTypes = {
        createStream: PropTypes.func.isRequired,
        dashboard: PropTypes.objectOf(PropTypes.any),
        dashboardId: PropTypes.string,
        dashboardType: PropTypes.string,
        deleteStream: PropTypes.func.isRequired,
        errors: PropTypes.objectOf(PropTypes.any),
        handleBlur: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isOpen: PropTypes.bool,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        pinToEquityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        removeFilingFormLens: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        sharedStreamId: PropTypes.string,
        stream: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
            rules: PropTypes.arrayOf(
                PropTypes.shape({
                    ruleType: PropTypes.string.isRequired,
                    condition: PropTypes.string.isRequired,
                    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired
                })
            ),
            streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            streamType: PropTypes.string.isRequired,
            uxPreferences: PropTypes.shape({
                color: PropTypes.string
            })
        }),
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string,
        touched: PropTypes.objectOf(PropTypes.any),
        updateStream: PropTypes.func.isRequired,
        user: PropTypes.objectOf(PropTypes.any),
        values: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        dashboard: undefined,
        dashboardId: null,
        dashboardType: 'default',
        errors: {},
        isOpen: false,
        loading: false,
        pinToEquityId: undefined,
        sharedStreamId: null,
        stream: null,
        streamId: null,
        styles: {},
        title: 'Create Search',
        touched: {},
        user: undefined,
        values: {}
    };

    constructor(props) {
        super(props);

        this.backToNew = this.backToNew.bind(this);
        this.clearTags = this.clearTags.bind(this);
        this.configureNewStream = this.configureNewStream.bind(this);
        this.copySearchTerms = this.copySearchTerms.bind(this);
        this.isFormDisabled = this.isFormDisabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeFilters = this.onChangeFilters.bind(this);
        this.onChangeGSheet = this.onChangeGSheet.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onCustomChange = this.onCustomChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEquityScopeChange = this.onEquityScopeChange.bind(this);
        this.onFilingFormChange = this.onFilingFormChange.bind(this);
        this.onSpotlightTypeChange = this.onSpotlightTypeChange.bind(this);
        this.onSelectBookmarkType = this.onSelectBookmarkType.bind(this);
        this.onSelectColor = this.onSelectColor.bind(this);
        this.onSelectContentTypes = this.onSelectContentTypes.bind(this);
        this.onSelectDashboard = this.onSelectDashboard.bind(this);
        this.onSelectType = this.onSelectType.bind(this);
        this.onSetSearchTerms = this.onSetSearchTerms.bind(this);
        this.onTagChange = this.onTagChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.setBookmarkTags = this.setBookmarkTags.bind(this);
        this.toggleFilterMode = this.toggleFilterMode.bind(this);

        this.allFilters = memoize(allFilters);
        this.formFilters = memoize(formFilters);
        this.mapFiltersToBookmarkTags = memoize(mapFiltersToBookmarkTags);
        this.mapFiltersToSearchTerms = memoize(mapFiltersToSearchTerms);
        this.mapFiltersToContentTypes = memoize(mapFiltersToContentTypes);
        this.mapFiltersToFilingForms = memoize(mapFiltersToFilingForms);
        this.mapFiltersToSpotlightTypes = memoize(mapFiltersToSpotlightTypes);
        this.mapScopesToBookmarkType = memoize(mapScopesToSelectedType);

        this.state = {
            deleting: false,
            hasChanges: false,
            customData: {},
            submitting: false,
            searchTermsCopied: false,
            ...this.mapStreamPropsToState()
        };
    }

    componentDidUpdate(prevProps) {
        const { loading: prevLoading, stream: prevStream } = prevProps;
        const { loading, stream } = this.props;
        // Map stream props to state once loaded
        if ((prevStream !== stream && stream) || prevLoading !== loading) {
            this.setState({
                ...this.mapStreamPropsToState()
            });
        }
    }

    mapStreamPropsToState() {
        const { dashboardId, loading, sharedStreamId, stream, streamId } = this.props;
        const filters = [];
        const filingFilters = [];
        const equityScope = [];
        const scopes = [];
        const preselectedType =
            streamId.includes('new|') && Object.keys(STREAM_TYPES).includes(streamId.split('|')[1])
                ? streamId.split('|')[1]
                : null;
        const streamType = getTypeFromStream(stream, preselectedType || 'content');
        mapRulesToFilters(get(stream, 'rules'), streamType).forEach(f => {
            if (EQUITY_SCOPE_FILTERS.includes(f.type) && f.operator === OPERATORS.is) {
                equityScope.push(f);
            } else if (f.type === TYPES.filingForm && f.operator === OPERATORS.is) {
                filingFilters.push(f);
            } else if (f.type === TYPES.scope) {
                scopes.push(f);
            } else {
                filters.push(f);
            }
        });
        let contentTypes = [];
        let gSheetSource = '';
        let customData = {};
        let filingForms = [];
        let spotlightTypes = [];
        let selectedBookmarkType = Object.keys(BOOKMARK_TYPES)[0];

        if (streamType === 'bookmarks') {
            selectedBookmarkType = this.mapScopesToBookmarkType(scopes);
        }

        if (streamType === 'content') {
            contentTypes = this.mapFiltersToContentTypes(filters);
        }

        if (streamType === 'corporate_action') {
            contentTypes = ['corporate_action'];
        }

        if (streamType === 'spotlight') {
            contentTypes = ['spotlight'];
            spotlightTypes = this.mapFiltersToSpotlightTypes(filters);
        }

        if (streamType === 'research') {
            contentTypes = ['research'];
        }

        if (streamType === 'news') {
            contentTypes = ['news'];
        }

        if (streamType === 'document') {
            contentTypes = ['document'];
        }

        if (streamType === 'filings') {
            contentTypes = ['filing'];
            filingForms = this.mapFiltersToFilingForms(filingFilters);
        }

        if (streamType === 'gsheet') {
            gSheetSource = get(
                filters.find(({ type }) => type === 'source'),
                'value.source[0]'
            );
        }

        if (streamType === 'custom_data') {
            customData = {
                dataCollectionId: get(stream, 'dataCollectionId'),
                streamMatchTemplateId: get(stream, 'streamMatchTemplateId')
            };
        }

        let page = !streamId || streamId.includes('new') ? 'newStream' : 'editStream';
        if (sharedStreamId) page = 'shareStream';

        // If streamId include "new|<streamType>" it means we are jumping
        // directly to the configure page
        if (preselectedType) {
            page = 'newStreamConfigure';
            if (preselectedType === 'content') {
                contentTypes = ['transcript', 'attachment'];
            }
        }

        return {
            bookmarkTags: this.mapFiltersToBookmarkTags(filters),
            contentTypes,
            customData,
            equityScope,
            filingForms,
            filters,
            gSheetSource,
            ignoreDashFilters: get(stream, 'filterMode') === 'ignore_dashboard',
            loading,
            page,
            spotlightTypes,
            searchTerms: this.mapFiltersToSearchTerms(filters),
            selectedBookmarkType,
            selectedColor: get(stream, 'uxPreferences.color'),
            selectedDashboard: dashboardId,
            streamType
        };
    }

    backToNew() {
        this.setState({ page: 'newStream', filters: [], searchTerms: [], contentTypes: [] });
    }

    clearTags() {
        this.setState({ searchTerms: [] });
    }

    configureNewStream() {
        const { user } = this.props;
        this.setState(({ streamType }) => {
            let state = {
                page: 'newStreamConfigure'
            };
            const filters = {
                equityScope: [],
                filingForms: [],
                filters: [],
                searchTerms: [],
                contentTypes: [],
                spotlightTypes: []
            };

            // Default content stream filters
            if (streamType === 'content') {
                const contentTypes = ['transcript', 'attachment'];
                filters.contentTypes = contentTypes;
                state.contentTypes = contentTypes;
            }

            if (streamType === 'corporate_action') {
                filters.contentTypes = ['corporate_action'];
                state.contentTypes = ['corporate_action'];
            }

            if (streamType === 'spotlight') {
                filters.contentTypes = ['spotlight'];
                state.contentTypes = ['spotlight'];
            }

            if (streamType === 'research') {
                filters.contentTypes = ['research'];
                state.contentTypes = ['research'];
            }

            if (streamType === 'document') {
                filters.contentTypes = ['document'];
                state.contentTypes = ['document'];
            }

            if (streamType === 'news') {
                if (hasPermission(user, PERMISSIONS.unlockedCreateStreamNews)) {
                    filters.contentTypes = ['news'];
                    state.contentTypes = ['news'];
                }
            }

            if (streamType === 'filings') {
                filters.contentTypes = ['filing'];
                state.contentTypes = ['filing'];
            }

            if (streamType === 'bookmarks') {
                state = {
                    ...state,
                    bookmarkTags: [],
                    selectedBookmarkType: Object.keys(BOOKMARK_TYPES)[0]
                };
            }

            state.filters = this.allFilters(filters);
            return state;
        });
    }

    copySearchTerms() {
        const { searchTerms } = this.state;
        copyToClipboard(searchTerms.join(', '));
        this.setState({ searchTermsCopied: true }, () => {
            setTimeout(() => this.setState({ searchTermsCopied: false }), 5000);
        });
    }

    isFormDisabled() {
        const {
            deleting,
            submitting,
            streamType,
            contentTypes,
            searchTerms,
            gSheetSource,
            customData,
            hasChanges
        } = this.state;
        const { values } = this.props;
        return (
            deleting ||
            submitting ||
            !values ||
            !get(values.name, 'length') ||
            !hasChanges ||
            searchTerms.length > 50 ||
            (streamType === STREAM_TYPE_TRANSCRIPTS && !searchTerms.length) ||
            (streamType === STREAM_TYPE_CONTENT && !contentTypes.length) ||
            (streamType === STREAM_TYPE_GSHEET && !gSheetSource) ||
            (streamType === STREAM_TYPE_CUSTOM_DATA && get(customData, 'isDisabled'))
        );
    }

    onCancel() {
        const { hasChanges } = this.state;
        const { onClose } = this.props;
        let confirm = true;
        // eslint-disable-next-line no-alert
        if (hasChanges) confirm = window.confirm('You have unsaved changes. Are you sure you want to cancel?');
        if (confirm) onClose();
    }

    onChange(event) {
        const { handleChange } = this.props;
        handleChange(event);
        this.setState({ hasChanges: true });
    }

    onChangeFilters({ value }) {
        this.setState({
            filters: value,
            hasChanges: true
        });
    }

    onChangeGSheet({ value }) {
        this.setState({
            gSheetSource: value
        });
    }

    onCreate() {
        const { createStream, dashboardId, history, onClose, pinToEquityId, values, reporter, stream } = this.props;
        const {
            bookmarkTags: tags,
            customData,
            equityScope,
            filingForms,
            filters,
            ignoreDashFilters,
            searchTerms: terms,
            selectedBookmarkType,
            selectedColor,
            selectedDashboard,
            streamType,
            contentTypes,
            gSheetSource,
            spotlightTypes
        } = this.state;
        const bookmarkTags = get(values, 'bookmarkTags', '').length ? tags.concat([values.bookmarkTags]) : tags;
        const untaggedTerm = values.streamSearchTerm.trim();
        const searchTerms = untaggedTerm.length ? terms.concat([untaggedTerm]) : terms;
        const input = formatInput({
            color: selectedColor,
            dashboardId: selectedDashboard,
            filters: this.allFilters({
                bookmarkTags,
                equityScope,
                streamType,
                filingForms,
                filters,
                searchTerms,
                contentTypes,
                gSheetSource,
                spotlightTypes,
                selectedBookmarkType
            }),
            ignoreDashFilters,
            name: values.name,
            streamType,
            pinToEquityId,
            dataCollectionId: streamType === 'custom_data' ? get(customData, 'dataCollectionId') : null,
            streamMatchTemplateId: streamType === 'custom_data' ? get(customData, 'streamMatchTemplateId') : null,
            uxPreferences: get(stream, 'uxPreferences', {})
        });

        this.setState({ submitting: true }, () => {
            createStream(input)
                .then(() => {
                    this.setState({ submitting: false }, () => {
                        onClose();
                        // Redirect to new dashboard if changing from current one
                        if (dashboardId !== selectedDashboard) {
                            history.push(generatePath(routes.dashboard, { dashboardId: selectedDashboard }));
                        }

                        // Track Stream Create
                        reporter.track(reporter.actions.submit, reporter.objects.streamCreate, {
                            component: 'StreamForm',
                            dashboardId: selectedDashboard,
                            streamType,
                            contentTypes,
                            name: values.name
                        });
                    });
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        });
    }

    onCustomChange({ name, value }) {
        // dataCollectionId
        // streamMatchTemplateId
        this.setState(prevState => ({
            customData: {
                ...prevState.customData,
                [name]: value
            }
        }));
    }

    onDelete() {
        const { deleteStream, onClose, reporter, streamId } = this.props;
        // eslint-disable-next-line no-alert
        if (streamId && window.confirm('Are you sure you want to delete this saved search?')) {
            this.setState({ deleting: true }, () => {
                deleteStream(streamId)
                    .then(() => {
                        this.setState({ deleting: false }, () => {
                            onClose();

                            // Track Stream Delete
                            reporter.track(reporter.actions.submit, reporter.objects.streamDelete, {
                                component: 'StreamForm',
                                streamId
                            });
                        });
                    })
                    .catch(() => {
                        this.setState({ deleting: false });
                    });
            });
        }
    }

    onEquityScopeChange({ value }) {
        this.setState({
            equityScope: value,
            hasChanges: true
        });
    }

    onSelectBookmarkType({ value }) {
        this.setState({
            hasChanges: true,
            selectedBookmarkType: Array.isArray(value) ? value[0] : value
        });
    }

    onSelectColor({ value }) {
        this.setState({
            hasChanges: true,
            selectedColor: value
        });
    }

    onSelectContentTypes({ value }) {
        this.setState({
            contentTypes: value,
            hasChanges: true
        });
    }

    onSelectDashboard({ value }) {
        const { selectedDashboard } = this.state;
        const newValue = Array.isArray(value) ? value[0] : value;
        if (selectedDashboard !== newValue) {
            this.setState({
                hasChanges: !!selectedDashboard,
                selectedDashboard: newValue
            });
        }
    }

    onFilingFormChange({ value }) {
        this.setState({
            hasChanges: true,
            filingForms: value
        });
    }

    onSpotlightTypeChange({ value }) {
        this.setState({
            hasChanges: true,
            spotlightTypes: value
        });
    }

    onSelectType({ value }) {
        const { filters, searchTerms, streamType } = this.state;
        const { values } = this.props;
        const newStreamType = Array.isArray(value) ? value[0] : value;

        if (streamType !== newStreamType) {
            let confirm = true;
            let confirmResetText;
            let resetFilters = false;
            let resetSearchTerms = false;
            let terms = [...searchTerms];

            if (this.formFilters(streamType, filters).length) {
                confirmResetText = 'filters';
                resetFilters = true;
            }

            if (!SEARCH_TERM_STREAM_TYPES.includes(newStreamType) && searchTerms.length) {
                confirmResetText = confirmResetText ? `${confirmResetText} and search terms` : 'search terms';
                resetSearchTerms = true;
            }

            if (confirmResetText) {
                // eslint-disable-next-line no-alert
                confirm = window.confirm(
                    `Changing the search type will clear your ${confirmResetText}. Are you sure you want to continue?`
                );
            }

            // When switching from a stream type that doesn't support search terms to a type that does,
            // if the name input has a value and there are no search terms yet,
            // use the input value to create a default search term
            if (
                !SEARCH_TERM_STREAM_TYPES.includes(streamType) &&
                SEARCH_TERM_STREAM_TYPES.includes(newStreamType) &&
                !searchTerms.length &&
                values.name.length
            ) {
                terms = [values.name];
            }

            if (confirm) {
                this.setState({
                    filters: resetFilters ? [] : filters,
                    hasChanges: true,
                    searchTerms: resetSearchTerms ? [] : terms,
                    streamType: newStreamType
                });
            }
        }
    }

    onSetSearchTerms({ value }) {
        const { setFieldValue, values } = this.props;
        this.setState(
            {
                hasChanges: true,
                searchTerms: value
            },
            () => {
                setFieldValue('streamSearchTerm', '');
                if (!values.name) {
                    setFieldValue('name', value[0]);
                }
            }
        );
    }

    onTagChange() {
        this.setState({ hasChanges: true });
    }

    onUpdate() {
        const {
            dashboardId,
            history,
            onClose,
            removeFilingFormLens,
            reporter,
            stream,
            updateStream,
            values
        } = this.props;
        const {
            bookmarkTags: tags,
            customData,
            equityScope,
            filingForms,
            filters,
            ignoreDashFilters,
            searchTerms: terms,
            selectedColor,
            selectedDashboard,
            streamType,
            contentTypes,
            gSheetSource,
            spotlightTypes,
            selectedBookmarkType
        } = this.state;
        const streamId = get(stream, 'streamId', get(stream, 'id'));
        if (streamId) {
            const bookmarkTags = get(values, 'bookmarkTags', '').length ? tags.concat([values.bookmarkTags]) : tags;
            const untaggedTerm = values.streamSearchTerm.trim();
            const searchTerms = untaggedTerm.length ? terms.concat([untaggedTerm]) : terms;
            const input = formatInput({
                streamId,
                streamType,
                dashboardId: selectedDashboard,
                name: values.name,
                color: selectedColor,
                filters: this.allFilters({
                    bookmarkTags,
                    equityScope,
                    streamType,
                    filingForms,
                    filters,
                    searchTerms,
                    contentTypes,
                    gSheetSource,
                    spotlightTypes,
                    selectedBookmarkType
                }),
                ignoreDashFilters,
                dataCollectionId: streamType === 'custom_data' ? get(customData, 'dataCollectionId') : null,
                streamMatchTemplateId: streamType === 'custom_data' ? get(customData, 'streamMatchTemplateId') : null,
                uxPreferences: get(stream, 'uxPreferences', {})
            });
            const mutations = [updateStream(input)];
            // Remove filing form lens rules when a filing form filter is added
            if (filingForms.length || filters.some(f => f && f.type === TYPES.filingForm)) {
                mutations.push(removeFilingFormLens(streamId, get(stream, 'lens.rules', [])));
            }
            this.setState({ submitting: true }, () => {
                Promise.all(mutations)
                    .then(() => {
                        this.setState({ submitting: false }, () => {
                            onClose();
                            // Redirect to new dashboard if changing from current one
                            if (dashboardId !== selectedDashboard) {
                                history.push(generatePath(routes.dashboard, { dashboardId: selectedDashboard }));
                            }

                            // Track Stream Update
                            reporter.track(reporter.actions.submit, reporter.objects.streamUpdate, {
                                component: 'StreamForm',
                                dashboardId: selectedDashboard,
                                streamType,
                                contentTypes,
                                name: values.name
                            });
                        });
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            });
        }
    }

    setBookmarkTags({ value }) {
        const { setFieldValue } = this.props;
        this.setState(
            {
                hasChanges: true,
                bookmarkTags: value
            },
            () => {
                setFieldValue('bookmarkTags', '');
            }
        );
    }

    toggleFilterMode() {
        this.setState(({ ignoreDashFilters }) => ({
            hasChanges: true,
            ignoreDashFilters: !ignoreDashFilters
        }));
    }

    render() {
        const {
            bookmarkTags,
            contentTypes,
            customData,
            deleting,
            equityScope,
            filingForms,
            filters,
            gSheetSource,
            ignoreDashFilters,
            loading,
            page,
            searchTerms,
            searchTermsCopied,
            selectedBookmarkType,
            selectedColor,
            selectedDashboard,
            spotlightTypes,
            streamType,
            submitting
        } = this.state;
        const {
            dashboard,
            dashboardId,
            dashboardType,
            errors,
            handleBlur,
            isOpen,
            stream,
            styles,
            title,
            touched,
            values,
            streamId
        } = this.props;
        return (
            <StreamFormUI
                bookmarkTags={bookmarkTags}
                bookmarkTypeOptions={BOOKMARK_TYPES}
                buttonsDisabled={deleting || submitting}
                clearTags={this.clearTags}
                contentTypeLabelMap={CONTENT_TYPES}
                contentTypes={contentTypes}
                copySearchTerms={this.copySearchTerms}
                customData={customData}
                dashboard={dashboard}
                dashboardId={dashboardId}
                dashboardType={dashboardType}
                deleting={deleting}
                equityScope={equityScope}
                errors={errors}
                filingForms={filingForms}
                filters={this.formFilters(streamType, filters)}
                gSheetSource={gSheetSource}
                ignoreDashFilters={ignoreDashFilters}
                isOpen={isOpen}
                isSubmitButtonDisabled={this.isFormDisabled()}
                loading={loading}
                onBack={this.backToNew}
                onBlur={handleBlur}
                onBookmarkTagChange={this.onBookmarkTagChange}
                onCancel={this.onCancel}
                onChange={this.onChange}
                onChangeFilters={this.onChangeFilters}
                onChangeGSheet={this.onChangeGSheet}
                onContinue={this.configureNewStream}
                onCreate={this.onCreate}
                onCustomChange={this.onCustomChange}
                onDelete={this.onDelete}
                onEquityScopeChange={this.onEquityScopeChange}
                onFilingFormChange={this.onFilingFormChange}
                onSelectBookmarkType={this.onSelectBookmarkType}
                onSelectColor={this.onSelectColor}
                onSelectContentTypes={this.onSelectContentTypes}
                onSelectDashboard={this.onSelectDashboard}
                onSelectType={this.onSelectType}
                onSetSearchTerms={this.onSetSearchTerms}
                onSpotlightTypeChange={this.onSpotlightTypeChange}
                onTagChange={this.onTagChange}
                onUpdate={this.onUpdate}
                page={page}
                previewFilters={this.allFilters({
                    bookmarkTags,
                    equityScope,
                    streamType,
                    filingForms,
                    filters,
                    searchTerms,
                    contentTypes,
                    gSheetSource,
                    spotlightTypes,
                    selectedBookmarkType
                })}
                searchTerms={searchTerms}
                searchTermsCopied={searchTermsCopied}
                selectedBookmarkType={selectedBookmarkType}
                selectedColor={selectedColor}
                selectedDashboard={selectedDashboard}
                setBookmarkTags={this.setBookmarkTags}
                shareableLink={get(stream, 'shareableLink')}
                showSearchTerms={SEARCH_TERM_STREAM_TYPES.includes(streamType)}
                spotlightTypes={spotlightTypes}
                stream={stream}
                streamId={streamId}
                streamType={streamType}
                streamTypeLabelMap={STREAM_TYPES}
                styles={styles}
                submitting={submitting}
                title={title}
                toggleFilterMode={this.toggleFilterMode}
                touched={touched}
                values={values}
            />
        );
    }
}

export const StreamFormContainer = compose(
    withUrlContext(['history', 'sharedStreamId']),
    withData(),
    withUser({ fetchPolicy: 'cache-first' }),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ stream }) =>
            stream ? { ...INITIAL_VALUES, name: get(stream, 'name', '') } : INITIAL_VALUES,
        validate: values => {
            return { ...validateInputs(values, INPUT_VALIDATION_RULES) };
        }
    }),
    withReporting()
)(StreamForm);
