import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { STREAM_DISPLAY_MODES } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { Toggle } from 'components/Toggle';
import { styleSheet } from './stylesheet';

class LiveEventRow extends PureComponent {
    static propTypes = {
        company: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]).isRequired,
        displayMode: PropTypes.string.isRequired,
        exchangeName: PropTypes.string,
        hasTranscript: PropTypes.bool,
        isLive: PropTypes.bool,
        overThreshold: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        price: PropTypes.string,
        priceMovementPercent: PropTypes.number,
        priceMovementAbsolute: PropTypes.number,
        selected: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string,
        toggleEvent: PropTypes.func.isRequired
    };

    static defaultProps = {
        company: null,
        exchangeName: null,
        hasTranscript: false,
        isLive: false,
        overThreshold: false,
        passedStyles: {},
        price: undefined,
        priceMovementAbsolute: undefined,
        priceMovementPercent: undefined,
        selected: false,
        ticker: null,
        title: null
    };

    renderReaction() {
        const { overThreshold, styles, theme, price, priceMovementPercent, priceMovementAbsolute } = this.props;
        const isPositive = priceMovementAbsolute && priceMovementAbsolute > 0;
        const redBg = overThreshold ? theme.colors.red05 : theme.colors.red03;
        const greenBg = overThreshold ? theme.colors.green09 : theme.colors.green05;

        return (
            !!price && (
                <Div
                    styles={{
                        ...styles.priceReaction,
                        backgroundColor: isPositive ? greenBg : redBg
                    }}
                >
                    <Text size={0} styles={styles.reactionLabel} uppercase>
                        Reaction
                    </Text>
                    <Div styles={styles.spacer} />
                    <Text size={1}>
                        {price}{' '}
                        <Text size={1} span styles={{ color: isPositive ? theme.colors.green03 : theme.colors.red01 }}>
                            ({priceMovementAbsolute.toFixed(2)}) {priceMovementPercent.toFixed(2)}%
                        </Text>
                    </Text>
                    <Icon
                        type={isPositive ? 'triangleUp' : 'triangleDown'}
                        color={isPositive ? theme.colors.green03 : theme.colors.red01}
                    />
                </Div>
            )
        );
    }

    render() {
        const {
            company,
            date,
            displayMode,
            exchangeName,
            hasTranscript,
            isLive,
            passedStyles,
            selected,
            styles,
            ticker,
            title,
            toggleEvent
        } = this.props;
        const liveLabel = hasTranscript ? 'live' : 'waiting';
        return (
            <Div onClick={toggleEvent} styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.mainContent}>
                    <Div styles={styles.left}>
                        <CardHeader
                            styles={styles.cardHeader}
                            context={displayMode === STREAM_DISPLAY_MODES.default ? ticker : undefined}
                            secondaryContext={exchangeName}
                            title={company}
                            truncateTitle
                        />
                        <Div styles={styles.row}>
                            <Text styles={styles.title} size={2}>
                                {title}
                            </Text>
                        </Div>
                        <Div styles={styles.row}>
                            {isLive ? (
                                <Div styles={styles.liveStatus}>
                                    <Div styles={styles.liveDot} />
                                    <Text size={0} uppercase>{`${liveLabel} from ${date.toString('h:mmTT')}`}</Text>
                                </Div>
                            ) : (
                                <Div styles={styles.dateTime}>
                                    <Text size={0}>
                                        <TimeAgo date={date} />
                                    </Text>
                                    <Text size={0}>&nbsp;{`at ${date.toString('h:mmTT')}`}</Text>
                                </Div>
                            )}
                        </Div>
                    </Div>
                    <Div styles={styles.right}>
                        <Toggle on={selected} />
                    </Div>
                </Div>
                {this.renderReaction()}
            </Div>
        );
    }
}

export const LiveEventRowUI = compose(withStyleSheet(styleSheet))(LiveEventRow);
