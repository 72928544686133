import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flattenDeep';
import { compose } from 'recompose';
import { Div } from 'components/Div';

/**
 * This utility component can be used by wrapping any component or DOM element.
 *
 * It requires an onClick function as a prop that will fire if a user clicks outside the element having the ref that is
 * passed/set by OutsideClickHandler.
 *
 * It also takes an optional styles prop which is used only when children is not a function. In this case, children are
 * rendered with an enclosing div with said styles applied.
 *
 * There are two ways to use OutsideClickHandler:
 *
 * When you wrap a component with it, passing children as a function, you get a setRef variable in return
 * which you can set on any DOM element. The onClick function will be called if the user clicks anywhere outside
 * the element that has the setRef.
 *
 * Usage when children is a function:
 *
 *   <OutsideClickHandler onClick={onClick}>
 *       {({ setRef }) => {
 *           return (
 *               <Div ref={setRef}> // onClick will fire if user clicks outside this div
 *                   <Calendar { ...props } />
 *               </Div>
 *           );
 *       }}
 *   </OutsideClickHandler>
 *
 * Usage when children is a component:
 *
 *   <OutsideClickHandler onClick={onClick} styles={someStyles}>
 *       <Calendar { ...props } />
 *   </OutsideClickHandler>
 */
export class OutsideClickHandler extends PureComponent {
    static displayName = 'OutsideClickHandlerContainer';

    static propTypes = {
        cancelClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        className: PropTypes.string,
        eventType: PropTypes.string,
        onClick: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        cancelClassName: null,
        className: undefined,
        eventType: 'pointerdown',
        onClick: undefined,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const { eventType } = this.props;
        document.addEventListener(eventType, this.handleClickOutside);
    }

    componentWillUnmount() {
        const { eventType } = this.props;
        document.removeEventListener(eventType, this.handleClickOutside);
    }

    // Set the wrapper ref
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    // If the user clicks outside the ref element, fire onClick function passed in as a prop
    // Skip onClick if the target element has a parent with the className cancelOutsideClick (e.g. Tooltip)
    handleClickOutside(event) {
        const { cancelClassName, onClick } = this.props;
        const cancelNames = flatten(Array.isArray(cancelClassName) ? cancelClassName : [cancelClassName]);
        if (
            onClick &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            !event
                .composedPath()
                .some(el => typeof el.className === 'string' && cancelNames.some(name => el.className.includes(name)))
        ) {
            onClick();
        }
    }

    render() {
        const { styles, children, className } = this.props;

        if (typeof children === 'function') {
            return children({ setRef: this.setWrapperRef });
        }

        return (
            <Div className={className} styles={styles} ref={this.setWrapperRef}>
                {children}
            </Div>
        );
    }
}

export const OutsideClickHandlerContainer = compose()(OutsideClickHandler);
