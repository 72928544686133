export const styleSheet = theme => ({
    searchContainer: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 3,
        color: theme.colors.white01,
        position: 'absolute',
        top: theme.margins.margin01,
        alignSelf: 'center',
        maxWidth: 700,
        width: '100%',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'row',
            alignItems: 'center',
            width: 'calc(100% - 10px)',
            left: theme.margins.margin00,
            top: theme.margins.margin00,
            '@supports (height: env(safe-area-inset-top))': {
                top: 'calc(10px + env(safe-area-inset-top))'
            }
        }
    },
    closeMobile: {
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        ...theme.content.fadeInAnimation
    },
    clickToHide: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    noPreview: {
        color: theme.colors.gray04,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.margins.margin02,
        height: '100%'
    },
    noTypes: {
        color: theme.colors.gray04,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.margins.margin02
    },
    types: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white01,
        borderRadius: '0 0 6.5px 6.5px',
        paddingLeft: 2
    },
    typeBoxes: {
        display: 'flex'
    },
    resultType: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.colors.black01,
        alignItems: 'center',
        height: 22,
        padding: '0px 6px',
        borderRadius: 6,
        backgroundColor: theme.colors.gray10,
        margin: '0 2px 4px',
        ':last-of-type': {
            marginRight: 10
        },
        ':hover': {
            p: {
                color: theme.colors.black01
            }
        },
        p: {
            color: theme.colors.gray06,
            marginLeft: 4
        }
    },
    resultTypeLocked: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.colors.black01,
        alignItems: 'center',
        height: 22,
        padding: '0px 6px',
        borderRadius: 6,
        backgroundColor: theme.colors.yellow07,
        margin: '0 2px 4px',
        ':last-of-type': {
            marginRight: 10
        },
        svg: {
            height: 12
        },
        p: {
            color: theme.colors.black01,
            marginRight: 4
        }
    },
    searchInput: {
        width: '100%',
        input: {
            backgroundColor: theme.colors.white01,
            borderRadius: '6.5px 6.5px 0 0',
            boxShadow: 'unset',
            ':hover': {
                backgroundColor: theme.colors.white01,
                boxShadow: 'unset',
                ':not(:focus)': {
                    backgroundColor: theme.colors.white01,
                    boxShadow: 'unset'
                }
            },
            ':focus': {
                backgroundColor: theme.colors.white01,
                boxShadow: 'unset'
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderRadius: 6.5
            }
        }
    },
    resultPreview: {
        backgroundColor: theme.colors.white01,
        width: 250,
        minHeight: 200,
        flexShrink: 0,
        boxShadow: `inset 1px 0 0 0 ${theme.colors.gray05}`,
        overflowY: 'scroll',
        ':hover': {
            boxShadow: `inset 1px 0 0 0 ${theme.colors.gray05}`,
            backgroundColor: theme.colors.gray10,
            '> div': {
                cursor: 'pointer',
                backgroundColor: theme.colors.gray10
            },
            '.noPreview': {
                boxShadow: `inset 1px 0 0 0 ${theme.colors.gray05}`,
                cursor: 'unset',
                backgroundColor: theme.colors.white01
            }
        }
    },
    searchResults: {
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            borderRadius: 6.5,
            WebkitOverflowScrolling: 'touch',
            overflowY: 'scroll'
        }
    },
    verticalResults: {
        display: 'flex',
        overflow: 'hidden',
        borderRadius: 6.5,
        maxWidth: 700,
        width: '100%',
        maxHeight: 'calc(100% - 120px)',
        position: 'absolute',
        top: 100,
        backgroundColor: theme.colors.white01,
        alignSelf: 'center',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'column',
            width: 'calc(100% - 20px)',
            top: 60,
            left: theme.margins.margin00,
            right: theme.margins.margin00,
            '@supports (height: env(safe-area-inset-top))': {
                top: 'calc(60px + env(safe-area-inset-top))'
            }
        }
    },
    toggle: {
        alignSelf: 'flex-end',
        height: 'unset',
        padding: 5,
        margin: 4,
        marginTop: 0,
        p: {
            textTransform: 'capitalize',
            letterSpacing: 0
        }
    }
});
