import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { TYPE_VALUES } from 'consts/filters';
import { compose } from 'recompose';
import { FiltersUI } from './ui';

function getTypes(types, searchTerm) {
    return types.filter(type =>
        (TYPE_VALUES[type].label || '').toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
}

export class Filters extends PureComponent {
    static displayName = 'FiltersContainer';

    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        searchable: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.arrayOf(PropTypes.any),
        types: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        name: null,
        onChange: null,
        searchable: false,
        styles: undefined,
        value: [],
        types: []
    };

    constructor(props) {
        super(props);

        this.addFilter = this.addFilter.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.getTypes = memoize(getTypes);
        this.onSearch = this.onSearch.bind(this);
        this.removeFilter = this.removeFilter.bind(this);

        this.state = {
            searchTerm: '',
            value: []
        };
    }

    componentDidMount() {
        this.checkValue();
    }

    componentDidUpdate({ value: prevValue }) {
        const { value } = this.props;
        if (value !== prevValue) {
            this.checkValue();
        }
    }

    checkValue() {
        // We need to track the value in state and in props
        // in order to properly handle extra filters in the array
        // that are no completely filled out. The parent can now
        // filter nulls out (needed to send to server) and we'll
        // keep them here in state so that the user can fill them in.
        //
        // If the values in the props change and we can't find them all
        // in the current state value, then we need to update the state.
        let { value } = this.props;
        value = value || [];
        const { value: stateValue } = this.state;
        if (!value.every(v => stateValue.includes(v)) || !stateValue.every(v => value.includes(v))) {
            this.setState({
                value: value ? value.filter(v => v.type && v.value) : null
            });
        }
    }

    onSearch({ value: searchTerm }) {
        this.setState({ searchTerm });
    }

    addFilter(type) {
        const { name, onChange } = this.props;
        this.setState(
            ({ value }) => ({ value: [...(value || []), { type }] }),
            () => {
                const { value } = this.state;
                if (onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    removeFilter(index) {
        const { name, onChange } = this.props;
        let filterValue = {};
        this.setState(
            ({ value }) => {
                const newValue = value.slice();
                filterValue = value[index];
                newValue.splice(index, 1);
                return { value: newValue };
            },
            () => {
                const { value } = this.state;
                // Only trigger an onChange if the filter we removed had a full value
                if (filterValue && filterValue.type && filterValue.value && onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    changeFilter(index, { value: filterValue }) {
        const { name, onChange } = this.props;
        this.setState(
            ({ value }) => {
                const newValue = value.slice();
                newValue[index] = { ...newValue[index], value: filterValue };
                return { value: newValue };
            },
            () => {
                const { value } = this.state;
                if (onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    render() {
        const { name, searchable, styles, types } = this.props;
        const { searchTerm, value } = this.state;
        return (
            <FiltersUI
                addFilter={this.addFilter}
                filters={value}
                name={name}
                onChange={this.changeFilter}
                onSearch={this.onSearch}
                removeFilter={this.removeFilter}
                searchable={searchable}
                searchTerm={searchTerm}
                styles={styles}
                types={this.getTypes(types, searchTerm)}
            />
        );
    }
}

export const FiltersContainer = compose()(Filters);
