import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUserPreferences } from 'graphql/user';
import { hasPreference } from 'utils';

export class WithPreference extends PureComponent {
    static displayName = 'WithPreferenceContainer';

    static propTypes = {
        preferences: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
        defaultValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
        missingComponent: PropTypes.elementType
    };

    static defaultProps = {
        preferences: [],
        type: 'ux',
        name: null,
        value: null,
        defaultValue: null,
        missingComponent: null
    };

    render() {
        const {
            preferences,
            children,
            type,
            name,
            value,
            defaultValue,
            missingComponent: MissingComponent
        } = this.props;

        const hasPref = hasPreference(preferences, { type, name, value }, defaultValue);
        if (typeof children === 'function') {
            return children(hasPref);
        }
        if (hasPref) {
            return children;
        }
        return !!MissingComponent && <MissingComponent />;
    }
}

export const WithPreferenceContainer = compose(withUserPreferences({ fetchPolicy: 'cache-first' }))(WithPreference);
