import qs from 'qs';
import uuid from 'uuid/v4';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import DebounceLink from 'apollo-link-debounce';
import apolloLogger from 'apollo-link-logger';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import Mixpanel from 'mixpanel-browser';
import { isTracking } from 'middleware/reporting';
import { toUpperSnakeCase, get } from 'utils';
import introspectionQueryResultData from 'graphql/fragmentTypes';

export function configureGraphqlClient(config, { store, bugsnagClient, mobileBridge, integration }) {
    const getUserInfo = () => {
        const state = store.getState();
        return {
            sessionId: get(state, 'User.sessionId'),
            userId: get(state, 'User.userId')
        };
    };

    const getAuthHeaders = () => {
        const { search } = window.location;
        const { asAieraUser, aieraIdpToken } = window.sessionStorage;
        if (asAieraUser) {
            return Promise.resolve({
                'X-Api-Key': asAieraUser
            });
        }

        const params = qs.parse(search.includes('?') ? search.slice(1) : search);
        if (params.apiKey) {
            return Promise.resolve({
                'X-Api-Key': params.apiKey
            });
        }

        if (params.code || aieraIdpToken) {
            if (!aieraIdpToken) {
                window.sessionStorage.aieraIdpToken = params.code;
                const newUrl = new URL(window.location.href);
                newUrl.searchParams.delete('code');
                window.history.replaceState(window.history.state, '', newUrl.toString());
            }
            return Promise.resolve({
                'X-Aiera-IDP-Token': params.code || aieraIdpToken
            });
        }

        return integration.enabled().then(enabled =>
            enabled
                ? integration.getIntegrationInfo().then(({ apiKey, userId }) =>
                      Object.entries({
                          'X-Api-Key': apiKey,
                          'X-Partner-User-Id': userId
                      }).reduce((headers, [header, value]) => (value ? { ...headers, [header]: value } : headers), {})
                  )
                : Promise.resolve({})
        );
    };

    const trackEvent = (event, properties) => {
        if (isTracking()) {
            const { userId, sessionId } = getUserInfo();
            Mixpanel.track(toUpperSnakeCase(event), {
                distinct_id: userId,
                userId,
                sessionId,
                ...properties
            });
        }
    };

    // The following are Apollo Links which get run as middleware through
    // an apollo network request.
    // Docs can be found here: https://www.apollographql.com/docs/link/overview.html
    //

    const aieraFetch = (uri, options) => {
        let optionsBody = JSON.parse(options.body);
        if (!Array.isArray(optionsBody)) {
            optionsBody = [optionsBody];
        }
        const opQuery = optionsBody
            .map(operation => {
                let vars = get(operation, 'variables', {});
                vars =
                    config.NODE_ENV === 'development'
                        ? Object.keys(vars)
                              .filter(key => key !== 'password' && vars[key])
                              .map(
                                  key =>
                                      `${key}=${
                                          typeof vars[key] === 'object'
                                              ? JSON.stringify(vars[key]).replace(/"/g, '')
                                              : vars[key]
                                      }`
                              )
                              .join(',')
                        : '';
                vars = vars ? `(${vars.substring(0, 200)}${vars.length > 200 ? '...' : ''})` : vars;
                return `op=${operation.operationName}${vars}`;
            })
            .join('&');
        return getAuthHeaders().then(headers => {
            return fetch(`${uri}?${opQuery}`, {
                ...options,
                // Override the abort signal for batched operations since we don't want to cancel
                // all operations in the request when only one has aborted. Unfortunately there's no
                // way to cancel only part of the request so we have to just let them all through.
                signal: optionsBody.length > 1 ? null : options.signal,
                headers: {
                    ...options.headers,
                    ...{
                        'X-Aiera-User-Agent': mobileBridge.enabled() ? 'Aiera/App' : 'Aiera/Desktop',
                        'X-Aiera-Api-Version': config.API_VERSION,
                        'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
                    },
                    ...headers
                }
            });
        });
    };

    const httpLink = new BatchHttpLink({
        uri: config.GRAPHQL_ENDPOINT,
        credentials: 'include',
        fetch: aieraFetch,
        batchKey: operation => operation.getContext().batchKey || uuid()
    });

    const abortLink = new ApolloLink((operation, forward) => {
        // Check the context for a function we can call to pass along
        // a function that can be used to abort the request. Also check
        // that `abortable` is true. This flag makes it easy to opt
        // out of this behavior for certain requests since we're enabling
        // this by default.
        const { setAbort, abortable = false } = operation.getContext();
        // This var gets set to true when the request is complete so that
        // the abort becomes a noop. (It works without this, but just a safety net)
        let finished = false;
        if (window.AbortController && abortable && setAbort) {
            const controller = new window.AbortController();
            // Pass the abort function back to the caller
            setAbort(() => {
                if (!finished) {
                    if (config.AIERA_ENV !== 'production') {
                        // eslint-disable-next-line no-console
                        console.log('aborting gql operation', operation);
                    }
                    controller.abort();
                }
            });
            // Set the signal on the fetchOptions object, can read more
            // about how it works here: https://developer.mozilla.org/en-US/docs/Web/API/AbortController
            operation.setContext(prevContext => ({
                fetchOptions: {
                    ...(prevContext.fetchOptions || {}),
                    signal: controller.signal
                }
            }));
        }
        // the function passed to .map() is called when the request is complete. Mark finished
        // and pass the result back through.
        return forward(operation).map(result => {
            finished = true;
            return result;
        });
    });

    // This link is composed of two links:
    // 1. A generic link that listens for successful graphql requests and tracks
    //    them only if the operation was a mutation.
    // 2. An error link that inspects both graphql and generic network errors
    //    and tracks them
    const reportingLink = ApolloLink.from([
        new ApolloLink((operation, forward) =>
            forward(operation).map(result => {
                const {
                    operationName,
                    query: { definitions }
                } = operation;
                const mutation = definitions.some(d => d.operation === 'mutation');
                if (mutation) {
                    trackEvent(`${operationName}Success`, {
                        operationName
                    });
                }
                return result;
            })
        ),
        onError(({ response, graphQLErrors, networkError, operation }) => {
            const { operationName } = operation;
            if (graphQLErrors) {
                const statusCode = get(graphQLErrors, '[0].code');
                const errorsStr = graphQLErrors.map(e => `${e.constructor.name} - ${e.message}`);
                trackEvent(`${operationName}Failure`, {
                    error: errorsStr,
                    operationName,
                    code: statusCode
                });
                bugsnagClient.notify(`GraphQL Errors: [${errorsStr.join(', ')}]`, {
                    beforeSend: () => statusCode !== 401
                });
                if (response) {
                    response.errors = statusCode === 401 ? null : response.errors;
                }
            } else if (networkError) {
                const statusCode = get(networkError, 'statusCode');
                trackEvent(`${operationName}Failure`, {
                    error: `${networkError.name}: ${networkError.message}`,
                    operationName,
                    code: statusCode
                });
                bugsnagClient.notify(`GraphQL Error: ${networkError.constructor.name} - ${networkError.message}`, {
                    beforeSend: () => statusCode !== 401
                });
                if (response) {
                    response.errors = statusCode === 401 ? null : response.errors;
                }
            }
        })
    ]);

    // Network errors only, when the server isn't responding or 500 error so
    // we can retry a few times to see if we regain a connection.
    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: 1000
        },
        attempts: 5
    });

    const debounceLink = new DebounceLink(100);

    let links = [retryLink, debounceLink, reportingLink, abortLink, httpLink];
    if (config.NODE_ENV === 'development') {
        links = [apolloLogger, ...links];
    }

    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData
    });

    return new ApolloClient({
        link: ApolloLink.from(links),
        cache: new InMemoryCache({
            fragmentMatcher,
            // eslint-disable-next-line no-underscore-dangle
            dataIdFromObject: obj => (obj.__typename === 'Query' ? 'ROOT_QUERY' : defaultDataIdFromObject(obj)),
            cacheRedirects: {
                Query: {
                    events: (_, args, { getCacheKey }) => {
                        return args.eventIds
                            ? args.eventIds.map(id => getCacheKey({ __typename: 'ScheduledAudioCall', id }))
                            : undefined;
                    },
                    equities: (_, args, { getCacheKey }) => {
                        return args.equityIds
                            ? args.equityIds.map(id => getCacheKey({ __typename: 'Equity', id }))
                            : undefined;
                    }
                }
            }
        })
    });
}
