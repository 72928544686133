import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { Aiera } from 'aiera-sdk';
import { copyToClipboard } from 'utils';
import { TranscrippetModalUI } from './ui';

const PUBLIC_API_ENDPOINT = 'https://public.aiera.com/aiera-sdk/0.0.69/';
// dev+transcrippets@aiera - user 10714, org 4121
const API_KEY_TRANSCRIPPETS = '750bcdb788895603e78c34f3f28f12dd';
function generateEmbedCode(transcrippetId) {
    return `<script src="${PUBLIC_API_ENDPOINT}embed.js"></script><iframe id="aiera-transcrippet" width="100%" style="border:none"></iframe><script>const e=new Aiera.Module("${PUBLIC_API_ENDPOINT}modules/Transcrippet/index.html","aiera-transcrippet");e.load().then(()=>{e.authenticateApiKey("${API_KEY_TRANSCRIPPETS}")});e.on("authenticated",()=>{e.configure({options:{transcrippetGuid:"${transcrippetId}"}})});e.on("transcrippet-height",t=>{const n=document.getElementById("aiera-transcrippet");n&&n.style&&(n.style.height=t+'px')});</script>`;
}

function generateLink(transcrippetId) {
    return `https://public.aiera.com/shared/transcrippet.html?id=${transcrippetId}`;
}

export class TranscrippetModal extends PureComponent {
    static displayName = 'TranscrippetModalContainer';

    static propTypes = {
        onClose: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        transcrippetId: PropTypes.string.isRequired
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.loadComponent = this.loadComponent.bind(this);
        this.downloadImage = this.downloadImage.bind(this);
        this.copyEmbedCodeToClipboard = this.copyEmbedCodeToClipboard.bind(this);
        this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);

        this.state = {
            copiedLink: false,
            copiedEmbed: false
        };
    }

    componentDidMount() {
        this.pollIframe = setInterval(() => {
            const ifr = document.getElementById('transcrippet-frame');
            if (ifr) {
                this.loadComponent();
                clearInterval(this.pollIframe);
            }
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(this.pollIframe);
    }

    downloadImage() {
        if (this.eventSnippet) {
            this.eventSnippet.downloadScreenshot();
        }
    }

    copyEmbedCodeToClipboard(event) {
        const { transcrippetId } = this.props;
        event.preventDefault();
        event.stopPropagation();
        const embedCode = generateEmbedCode(transcrippetId);
        copyToClipboard(embedCode);
        this.setState({ copiedEmbed: true }, () => {
            setTimeout(() => {
                this.setState({ copiedEmbed: false });
            }, 5000);
        });
    }

    copyLinkToClipboard(event) {
        const { transcrippetId } = this.props;
        event.preventDefault();
        event.stopPropagation();
        const transcrippetLink = generateLink(transcrippetId);
        copyToClipboard(transcrippetLink);
        this.setState({ copiedLink: true }, () => {
            setTimeout(() => {
                this.setState({ copiedLink: false });
            }, 5000);
        });
    }

    loadComponent() {
        const { transcrippetId } = this.props;
        if (transcrippetId) {
            this.eventSnippet = new Aiera.Module(
                `${PUBLIC_API_ENDPOINT}modules/Transcrippet/index.html`,
                'transcrippet-frame'
            );
            this.eventSnippet.load().then(() => {
                this.eventSnippet.authenticateApiKey(API_KEY_TRANSCRIPPETS);
            });
            this.eventSnippet.on('authenticated', () => {
                this.eventSnippet.configure({
                    options: {
                        transcrippetGuid: transcrippetId
                    }
                });
            });
            this.eventSnippet.on('transcrippet-height', height => {
                const iframeEle = document.getElementById('transcrippet-frame');
                if (iframeEle && iframeEle.style) {
                    iframeEle.style.height = `${height}px`;
                }
            });
        }
    }

    render() {
        const { onClose, styles, transcrippetId } = this.props;
        const { copiedEmbed, copiedLink } = this.state;
        return (
            <TranscrippetModalUI
                id={transcrippetId}
                onDownload={this.downloadImage}
                onClose={onClose}
                onCopyLink={this.copyLinkToClipboard}
                onCopyEmbed={this.copyEmbedCodeToClipboard}
                copiedLink={copiedLink}
                copiedEmbed={copiedEmbed}
                embedCode={generateEmbedCode(transcrippetId)}
                transcrippetLink={generateLink(transcrippetId)}
                styles={styles}
            />
        );
    }
}

export const TranscrippetModalContainer = compose(
    withUrlContext(['transcrippetId']),
    withReporting()
)(TranscrippetModal);
