import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import XDate from 'xdate';
import { generatePath } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withIntegration } from 'provider/integration';
import { withEventDetails, withCreateEvent } from 'graphql/audioCalls';
import { scheduledAudioCallEventFragment } from 'graphql/fragments/audioCalls';
import { withGetUser, withUserPreferences } from 'graphql/user';
import { config } from 'configuration';
import { routes } from 'routes';
import { PERMISSIONS, PREFERENCES } from 'consts';
import {
    get,
    copyToClipboard,
    hasPermission,
    generateTabId,
    generateModalId,
    getCompanyQuotes,
    hasPreference
} from 'utils';
import { EventHeaderUI } from './ui';
import { generateWordDocument } from './doc';

export class EventHeader extends PureComponent {
    static displayName = 'EventHeaderContainer';

    static propTypes = {
        callDate: PropTypes.string,
        client: PropTypes.objectOf(PropTypes.any).isRequired,
        company: PropTypes.objectOf(PropTypes.any),
        createTabPath: PropTypes.func.isRequired,
        deleteEvent: PropTypes.func.isRequired,
        disableCompany: PropTypes.bool.isRequired,
        disableNav: PropTypes.bool,
        eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        events: PropTypes.arrayOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        inProgress: PropTypes.bool,
        integration: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        primaryQuote: PropTypes.objectOf(PropTypes.any),
        shareId: PropTypes.string,
        showWordDownload: PropTypes.bool.isRequired,
        title: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        callDate: null,
        company: null,
        disableNav: false,
        eventId: null,
        events: [],
        inProgress: false,
        integration: null,
        preferences: undefined,
        primaryQuote: undefined,
        shareId: null,
        title: null,
        user: null
    };

    constructor(props) {
        super(props);

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.onDownloadWord = this.onDownloadWord.bind(this);
        this.onTickerClick = this.onTickerClick.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.openEditEventModal = this.openEditEventModal.bind(this);

        this.state = {
            copied: false,
            downloadingWord: false
        };
    }

    openEditEventModal() {
        const { history, pathname, user, eventId } = this.props;
        if (hasPermission(user, PERMISSIONS.unlockedCreatePrivateRecording)) {
            history.push(
                generateModalId({ pathname, id: eventId, type: 'privateRecording', tabId: generateTabId({ eventId }) })
            );
        } else {
            history.push(
                generateModalId({
                    pathname,
                    id: 'createEvent',
                    type: 'upgrade',
                    tabId: generateTabId({ eventId })
                })
            );
        }
    }

    removeEvent() {
        const { deleteEvent, eventId, history, pathname } = this.props;
        const confirmRemove = window.confirm('Are you sure you want to remove this event?'); // eslint-disable-line no-alert
        if (confirmRemove) {
            deleteEvent(eventId).then(() => {
                history.push({ pathname, search: '' });
            });
        }
    }

    copyToClipboard() {
        const { callDate, primaryQuote, shareId } = this.props;
        const eventDate = callDate ? new XDate(callDate) : undefined;
        let publicEventUrl;

        if (primaryQuote && eventDate) {
            const tickerString = `/${get(primaryQuote, 'localTicker')}`;
            const mm = `/${eventDate.toString('MM')}`;
            const dd = `/${eventDate.toString('dd')}`;
            const yyyy = `/${eventDate.toString('yyyy')}`;
            publicEventUrl = `${config.DASHBOARD_ENDPOINT}p${tickerString}/event${mm}${dd}${yyyy}/${shareId}`;
        } else if (shareId) {
            publicEventUrl = `${config.DASHBOARD_ENDPOINT}p/event/shared/${shareId}`;
        }

        copyToClipboard(publicEventUrl).then(() =>
            this.setState({ copied: true }, () => {
                setTimeout(() => {
                    this.setState({
                        copied: false
                    });
                }, 3000);
            })
        );
    }

    onDownloadWord() {
        const { showWordDownload, eventId, title, client, preferences } = this.props;
        const showEstimatedSpeakers = hasPreference(
            preferences,
            { ...PREFERENCES.showEstimatedSpeakers, value: true },
            true
        );
        if (showWordDownload) {
            this.setState({ downloadingWord: true }, () => {
                client
                    .query({
                        query: gql`
                            query GetLiveTranscriptEvents($eventId: [ID]) {
                                audioCalls: events(eventIds: $eventId) {
                                    items {
                                        ...scheduledAudioCallEvent
                                    }
                                }
                            }
                            ${scheduledAudioCallEventFragment}
                        `,
                        fetchPolicy: 'no-cache',
                        variables: {
                            eventId: [eventId]
                        }
                    })
                    .then(results => {
                        generateWordDocument({
                            showEstimatedSpeakers,
                            events: get(results, 'data.audioCalls[0].items'),
                            onComplete: () => this.setState({ downloadingWord: false }),
                            title
                        });
                    });
            });
        }
    }

    onTickerClick() {
        const { disableNav, company, history, integration, primaryQuote } = this.props;
        const companyId = get(company, 'id');

        if (!disableNav && companyId) {
            history.push(generatePath(routes.company, { companyId }));
        }

        if (integration) {
            const ticker = get(primaryQuote, 'localTicker', null);
            integration.enabled().then(enabled => enabled && integration.emit('click', { ticker }));
        }
    }

    render() {
        const { callDate, disableCompany, disableNav, ...rest } = this.props;
        const { copied, downloadingWord } = this.state;
        // PUT OTHER PROPS AFTER {...rest}
        return (
            <EventHeaderUI
                {...rest}
                callDate={callDate}
                copyToClipboard={this.copyToClipboard}
                disableCompany={disableCompany}
                disableNav={disableNav}
                downloadingWord={downloadingWord}
                isUpcoming={callDate ? new XDate(callDate).getTime() > new XDate().getTime() : false}
                openEditEventModal={this.openEditEventModal}
                onDownloadWord={this.onDownloadWord}
                onTickerClick={this.onTickerClick}
                removeEvent={this.removeEvent}
                urlCopied={copied}
            />
        );
    }
}

export const EventHeaderContainer = compose(
    withUrlContext(['audioCallId']),
    withIntegration(),
    withEventDetails({ fetchPolicy: 'cache-only', withCompany: true }),
    withUserPreferences(),
    withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
    withProps(({ audioCall, user }) => {
        const events = get(audioCall, 'events', []);
        const createdByUserId = get(audioCall, 'createdByUserId');
        const inProgress = get(audioCall, 'isLive') || get(audioCall, 'isUploading');
        const company = get(audioCall, 'primaryCompany');
        const instruments = get(company, 'instruments', []);
        const { primaryQuote } = getCompanyQuotes(instruments);
        return {
            ...audioCall,
            company,
            events,
            inProgress,
            isEventOwner: !!get(user, 'id') && !!createdByUserId && get(user, 'id') === createdByUserId,
            primaryQuote,
            waiting: inProgress && (!events.length || events.length === 0),
            showWordDownload: events.length > 0
        };
    }),
    withCreateEvent(),
    withApollo
)(EventHeader);
