export const styleSheet = theme => ({
    container: {
        display: 'flex',
        padding: '0 3px',
        zIndex: 2,
        position: 'relative',
        backgroundColor: theme.colors.gray01,
        justifyContent: 'flex-start'
    },
    checkbox: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.colors.black01,
        alignItems: 'center',
        height: 22,
        padding: '0 5px 0px 4px',
        borderRadius: 6,
        backgroundColor: theme.colors.gray10,
        margin: '2px 2px 4px',
        maxWidth: 100,
        ':hover': {
            p: {
                color: theme.colors.black01
            }
        },
        p: {
            color: theme.colors.gray06,
            marginLeft: 4
        }
    }
});
