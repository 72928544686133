import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { Icon } from 'components/Icon';
import { TextInput } from 'components/TextInput';
import { LoaderLogo } from 'components/LoaderLogo';

import { styleSheet } from './stylesheet';

class PopupSearchMenu extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        initialSearchText: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.any),
        onClose: PropTypes.func.isRequired,
        onRemove: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        onSelectOption: PropTypes.func.isRequired,
        loadingOption: PropTypes.string,
        loadingRemove: PropTypes.bool.isRequired,
        searchPlaceholder: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        passedStyles: {},
        loadingOption: null,
        initialSearchText: undefined,
        options: [],
        onRemove: undefined,
        searchPlaceholder: 'Search financials...'
    };

    renderOptions() {
        const { styles, loadingOption, onSelectOption, options, theme } = this.props;
        let lastCategory;

        const rows = options.map(r => {
            let categoryContent;

            if (r.category !== lastCategory) {
                lastCategory = r.category;
                categoryContent = (
                    <Div key={`menu-category-${r.category}`} styles={styles.dropDownMenuCategory}>
                        <Text size={0} uppercase weight="medium">
                            {r.category}
                        </Text>
                    </Div>
                );
            }

            if (loadingOption === r.key) {
                return (
                    <Fragment key={`menu-option-loading-${r.key}`}>
                        {categoryContent}
                        <Div styles={styles.dropDownMenuOptionLoading}>
                            <LoaderLogo styles={{ margin: 'auto' }} type={2} height={30} color={theme.colors.white01} />
                        </Div>
                    </Fragment>
                );
            }

            return (
                <Fragment key={`menu-option-${r.key}`}>
                    {categoryContent}
                    <Div styles={styles.dropDownMenuOption} onClick={() => onSelectOption(r)}>
                        <Text size={3}>{r.title}</Text>
                    </Div>
                </Fragment>
            );
        });

        return (
            <Div styles={lastCategory ? styles.optionsContainerWithCategories : styles.optionsContainer}>{rows}</Div>
        );
    }

    render() {
        const {
            styles,
            className,
            passedStyles,
            theme,
            onClose,
            initialSearchText,
            onRemove,
            onSearch,
            loadingRemove,
            searchPlaceholder
        } = this.props;
        return (
            <OutsideClickHandler
                onClick={onClose}
                className={className}
                styles={{ ...styles.container, ...passedStyles }}
            >
                <Div styles={styles.searchHeader}>
                    <TextInput
                        autoFocus
                        autoComplete="off"
                        styles={styles.menuSearchInput}
                        className="draggableCancel"
                        icon="search"
                        name="term"
                        onBlur={() => {}}
                        onChange={onSearch}
                        onKeyDown={() => {}}
                        placeholder={searchPlaceholder}
                        error={undefined}
                        initialValue={initialSearchText}
                    />
                    <Div onClick={onClose} styles={styles.closeMenu}>
                        <Icon type="circleX" color="#5e6380" />
                    </Div>
                </Div>
                {this.renderOptions()}
                {onRemove && (
                    <Div styles={styles.dropDownMenuRemoveRow} onClick={onRemove}>
                        {loadingRemove ? (
                            <LoaderLogo styles={{ margin: 'auto' }} type={2} height={30} color={theme.colors.white01} />
                        ) : (
                            <Fragment>
                                <Text size={0} uppercase>
                                    remove row
                                </Text>
                                <Icon type="trash02" color={theme.colors.gray04} />
                            </Fragment>
                        )}
                    </Div>
                )}
            </OutsideClickHandler>
        );
    }
}

export const PopupSearchMenuUI = compose(withStyleSheet(styleSheet))(PopupSearchMenu);
