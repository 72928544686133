import { types } from 'actions/transcriptEditor';

const { TRANSCRIPT_EDITOR_ACTIVE, TRANSCRIPT_EDITOR_AWAY } = types;

const initialState = {
    status: 'away'
};

export function transcriptEditorReducer(state = initialState, action) {
    const { type } = action;

    if (type === TRANSCRIPT_EDITOR_AWAY) {
        return {
            ...state,
            status: 'away'
        };
    }

    if (type === TRANSCRIPT_EDITOR_ACTIVE) {
        return {
            ...state,
            status: 'active'
        };
    }

    return state;
}
