import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NO_OP } from 'consts';
import { TaggedInputOldUI } from './ui';

export class TaggedInputOld extends PureComponent {
    static displayName = 'TaggedInputOldContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        disabled: PropTypes.bool,
        inputIcon: PropTypes.string,
        inputLabel: PropTypes.string,
        inputName: PropTypes.string.isRequired,
        inputPlaceholder: PropTypes.string,
        inputRef: PropTypes.objectOf(PropTypes.object),
        inputValue: PropTypes.string.isRequired,
        onInputBlur: PropTypes.func,
        onInputChange: PropTypes.func.isRequired,
        onInputFocus: PropTypes.func,
        onInputKeyDown: PropTypes.func,
        onTagRemove: PropTypes.func.isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        styles: {},
        disabled: false,
        inputIcon: null,
        inputLabel: null,
        inputRef: null,
        inputPlaceholder: null,
        onInputBlur: NO_OP,
        onInputFocus: NO_OP,
        onInputKeyDown: NO_OP,
        tags: null
    };

    render() {
        const {
            children,
            styles,
            disabled,
            inputIcon,
            inputLabel,
            inputName,
            inputPlaceholder,
            inputRef,
            inputValue,
            onInputBlur,
            onInputChange,
            onInputFocus,
            onInputKeyDown,
            onTagRemove,
            tags
        } = this.props;
        return (
            <TaggedInputOldUI
                styles={styles}
                disabled={disabled}
                inputIcon={inputIcon}
                inputLabel={inputLabel}
                inputName={inputName}
                inputPlaceholder={inputPlaceholder}
                inputRef={inputRef}
                inputValue={inputValue}
                onInputBlur={onInputBlur}
                onInputChange={onInputChange}
                onInputFocus={onInputFocus}
                onInputKeyDown={onInputKeyDown}
                onTagRemove={onTagRemove}
                tags={tags}
            >
                {children}
            </TaggedInputOldUI>
        );
    }
}

export const TaggedInputOldContainer = compose()(TaggedInputOld);
