export const styleSheet = theme => ({
    newFilterForm: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8
    },
    newFilterButton: {
        cursor: 'pointer',
        padding: '0 14px',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    trashFilterButton: {
        cursor: 'pointer',
        padding: '0 14px',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    filter: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.margins.margin00,
        ':first-of-type': {
            marginTop: 0
        }
    },
    label: {
        ...theme.content.formLabel
    }
});
