import gql from 'graphql-tag';
import pick from 'lodash/pick';
import { graphql as apolloGraphql, withApollo } from '@apollo/react-hoc';
import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { config } from 'configuration';
import { statusBannerFire } from 'actions/statusBanner';
import { mapPropsToOptions } from 'hoc/utils';
import { get } from 'utils';

export const graphql = (query, apolloConfig) =>
    compose(
        withApollo,
        withHandlers(() => {
            let abortFunc;
            return {
                setApolloAbort: () => abort => {
                    abortFunc = abort;
                },
                apolloAbort: () => () => abortFunc && abortFunc()
            };
        }),
        lifecycle({
            componentWillUnmount() {
                this.props.apolloAbort();
            }
        }),
        apolloGraphql(query, {
            ...apolloConfig,
            options: ({ setApolloAbort, ...props }) => {
                const apolloOptions = mapPropsToOptions(apolloConfig.options, props) || {};
                return {
                    ...apolloOptions,
                    context: {
                        setAbort: setApolloAbort,
                        ...(apolloOptions.context || {})
                    }
                };
            },
            props: (...args) => {
                const [{ mutate, data: apolloData, ownProps }, prevData] = args;

                // Short circuit for mutations, just pass everything through
                if (mutate) return apolloConfig.props(...args);

                const { client } = ownProps;
                // When changing the variables for a graphql component,
                // apollo will currently return the cached data even if the
                // variables do not match. This is a known bug described
                // here: https://github.com/apollographql/react-apollo/issues/2202.
                //
                // The following code will check the cache for the current variables,
                // and will not pass the cached data through if the variables do not match.
                // This wrapper can be removed once teh above issue is addressed.
                let cache;
                try {
                    cache = client.readQuery({ query, variables: apolloData.variables });
                } catch (e) {
                    cache = null;
                }

                // The `returnPreviousData` apolloConfig.option allows the component to use the default
                // apollo behaviour which is to return the cahced data from the previous query
                // if it exists, even though the variables don't match. In general we don't
                // want this, but search is an example where we do want this behavior. Apollo seems to be close to
                // resolving the issue, at which point this whole util can be removed and existing
                // components refactored to use their approach.
                const { returnPreviousData = false, fetchPolicy } = mapPropsToOptions(apolloConfig.options, ownProps);
                const data =
                    returnPreviousData || cache || fetchPolicy === 'no-cache'
                        ? apolloData
                        : pick(apolloData, [
                              // These are the fields on "data" that apollo always passes
                              // through, so continue to let them through but nothing else
                              'variables',
                              'error',
                              'loading',
                              'refetch',
                              'fetchMore',
                              'startPolling',
                              'stopPolling',
                              'subscribeToMore',
                              'updateQuery',
                              'networkStatus',
                              'called'
                          ]);

                if (config.APOLLO_DEBUG && data.error) {
                    // eslint-disable-next-line no-console
                    console.error('graphql error', data.error);
                }

                return apolloConfig.props ? apolloConfig.props({ data, ownProps }, prevData) : { ...ownProps, ...data };
            }
        })
    );

export const withDomainQuality = () =>
    compose(
        graphql(
            gql`
                query withDomainQuality($url: String!) {
                    webcastDomainConnectionQualityScore(url: $url)
                }
            `,
            {
                props: ({ data }) => ({
                    domainScore: get(data, 'webcastDomainConnectionQualityScore')
                }),
                options: {
                    context: {
                        debounceKey: 'withDomainQuality',
                        debounceTimeout: 300
                    },
                    fetchPolicy: 'cache-first'
                },
                skip: ({ url }) => !url
            }
        )
    );

export const withUpdateUserObjectSettings = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation BulkUpdateUserObjectSettings($inputs: [UpdateUserObjectSettingsInput]!) {
                    bulkUpdateUserObjectSettings(inputs: $inputs) {
                        success
                        settings {
                            target {
                                ... on Content {
                                    id
                                    tags {
                                        tag
                                        users {
                                            id
                                            username
                                        }
                                    }
                                    userSettings {
                                        id
                                        isRead
                                        archived
                                        starred
                                        tags
                                    }
                                }
                                ... on ScheduledAudioCall {
                                    id
                                    tags {
                                        tag
                                        users {
                                            id
                                            username
                                        }
                                    }
                                    userSettings {
                                        id
                                        isRead
                                        archived
                                        starred
                                        tags
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    bulkUpdateItems: targetDetailsArray => {
                        return mutate({
                            variables: {
                                inputs: targetDetailsArray
                            }
                        })
                            .then(() => {
                                setStatusBanner('Updated successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error updating: ${error}`, 'error', 'circleX');
                            });
                    },
                    markRead: ({ targetId, targetType, isRead }) => {
                        return mutate({
                            variables: {
                                inputs: [{ targetId, targetType, isRead }]
                            }
                        }).catch(() => {
                            // Could not mark as read
                        });
                    },
                    addTags: ({ targetId, targetType, tags }) => {
                        return mutate({
                            variables: {
                                inputs: [{ targetId, targetType, tags }]
                            }
                        }).catch(() => {
                            // Could not add tags
                        });
                    }
                })
            }
        )
    );
