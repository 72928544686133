import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { MinMaxInputUI } from './ui';

export class MinMaxInput extends PureComponent {
    static displayName = 'MinMaxInputContainer';

    static propTypes = {
        mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        maxLabel: PropTypes.string,
        minLabel: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    };

    static defaultProps = {
        mask: undefined,
        maxLabel: null,
        minLabel: null,
        name: undefined,
        onChange: null,
        styles: undefined,
        value: null
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange({ name: inputName, value: inputValue, event }) {
        const { name, onChange, value } = this.props;
        const formattedName = name ? inputName.replace(`${name}:`, '') : inputName;
        if (onChange) {
            const newValue = value || {};
            onChange({ name, event, value: { ...newValue, [formattedName]: inputValue } });
        }
    }

    render() {
        const { styles, value, mask, minLabel, maxLabel, name } = this.props;
        return (
            <MinMaxInputUI
                minLabel={minLabel}
                maxLabel={maxLabel}
                mask={mask}
                name={name}
                onChange={this.onChange}
                styles={styles}
                value={value}
            />
        );
    }
}

export const MinMaxInputContainer = compose()(MinMaxInput);
