import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TickerTag } from 'components/TickerTag';
import { styleSheet } from './stylesheet';

/* eslint-disable react/prop-types */
const AutocompleteOption = ({ label, exchange, name: companyName, ticker, isGroupHeader }) => {
    // Group heading
    if (isGroupHeader) {
        return (
            <Div>
                <Text size={3} weight="medium">
                    {label}
                </Text>
            </Div>
        );
    }

    // Equity option
    if (ticker) {
        let name = exchange || '';
        if (companyName) name += ` • ${companyName}`;
        return (
            <Div styles={{ display: 'flex', alignItems: 'center' }}>
                <TickerTag ticker={ticker} />
                <Text size={3} styles={{ marginLeft: 10 }}>
                    {name}
                </Text>
            </Div>
        );
    }

    return (
        <Div>
            <Text size={1}>{label}</Text>
        </Div>
    );
};

class EquityScopeAutocomplete extends PureComponent {
    static propTypes = {
        getTagLabel: PropTypes.func.isRequired,
        getTagStyle: PropTypes.func.isRequired,
        icon: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        resultsPlaceholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
        wrapTags: PropTypes.bool.isRequired
    };

    static defaultProps = {
        icon: undefined,
        initialSearchTerm: null,
        label: null,
        passedStyles: {},
        value: null
    };

    render() {
        const {
            disabled,
            getTagLabel,
            getTagStyle,
            icon,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onChange,
            onSearch,
            options,
            passedStyles,
            placeholder,
            resultsPlaceholder,
            styles,
            value,
            wrapTags
        } = this.props;
        return (
            <Autocomplete
                disabled={disabled}
                getTagLabel={getTagLabel}
                getTagStyle={getTagStyle}
                icon={icon}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                menuStyles={styles.menu}
                multi={multi}
                name={name}
                onChange={onChange}
                onSearch={onSearch}
                OptionComponent={AutocompleteOption}
                options={options}
                placeholder={placeholder}
                resultsPlaceholder={resultsPlaceholder}
                styles={{ ...styles.container, ...passedStyles }}
                strictOptions={false}
                useTags
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const EquityScopeAutocompleteUI = compose(withStyleSheet(styleSheet))(EquityScopeAutocomplete);
