import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { TYPES, OPERATORS } from 'consts/filters';
import { statusBannerFire } from 'actions/statusBanner';
import { bookmarkFragment, bookmarkTargetFragment } from 'graphql/fragments/bookmarks';
import { scheduledAudioCallEventFragment } from 'graphql/fragments/audioCalls';
import { streamFragment, ruleFragment } from 'graphql/fragments/streams';
import { withUrlContext } from 'hoc/url';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import {
    updateStreamMatchFragmentBookmark,
    getBookmarkFilters,
    mapFiltersToRules,
    mapFilterToRule
} from 'utils/streams';

export const contentHighlightsStreamQuery = gql`
    query highlightStream(
        $streamTypes: [StreamType]!
        $rules: [StreamRuleInput]
        $size: Int = 100
        $offset: Int = 0
        $sort: String
    ) {
        streams(filter: { previewStreamTypes: $streamTypes }) {
            ...stream
            matches(size: $size, fromIndex: $offset, sort: $sort, filter: { rules: $rules }) {
                total
                results {
                    id
                    bookmark {
                        ...bookmark
                        ...bookmarkTarget
                    }
                }
            }
        }
    }
    ${streamFragment}
    ${bookmarkFragment}
    ${bookmarkTargetFragment}
`;

function mapTargetType(targetType) {
    if (targetType === 'filing') return 'content';
    return targetType;
}

function generateStreamVars({ eventId, contentId, filterKey, sortKey }) {
    const streamVars = {
        returnStream: true,
        sort: sortKey === 'chron' ? 'transcript_timestamp' : undefined,
        streamTypes: ['bookmarks']
    };
    if (eventId) {
        const filters = [
            { type: TYPES.event, operator: OPERATORS.is, value: eventId },
            ...getBookmarkFilters(filterKey)
        ];

        streamVars.rules = mapFiltersToRules(filters);
    } else if (contentId) {
        const filters = [
            { type: TYPES.content, operator: OPERATORS.is, value: contentId },
            ...getBookmarkFilters(filterKey)
        ];

        streamVars.rules = mapFiltersToRules(filters);
    }

    return streamVars;
}

export const withCreateBookmark = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withUrlContext(['dashboardId', 'tabs']),
        graphql(
            gql`
                query withCreateBookmarkDashboard($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        dashboardId
                        equity {
                            id
                        }
                        rules {
                            ...rule
                        }
                    }
                }
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    return {
                        dashboardRules: get(data, 'dashboards[0].rules', []),
                        equityId: get(data, 'dashboards[0].equity.id')
                    };
                },
                skip: ({ dashboardId }) => !dashboardId,
                options: ({ dashboardId }) => {
                    return {
                        fetchPolicy: 'cache-only',
                        variables: {
                            dashboardId
                        }
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation CreateBookmark(
                    $dashboardId: ID
                    $dashboardRules: [StreamRuleInput]
                    $equityIds: [ID]
                    $highlight: String
                    $highlightColor: String
                    $includeNewsHighlights: Boolean = false
                    $note: String
                    $offset: Int = 0
                    $reminder: DateTime
                    $returnStream: Boolean = false
                    $rules: [StreamRuleInput]
                    $size: Int = 20
                    $sort: String
                    $streamId: ID
                    $streamTypes: [StreamType]
                    $tags: [String]
                    $targetEndId: ID
                    $targetEndOffset: Int
                    $targetId: ID!
                    $targetOffset: Int
                    $targetStreamId: ID
                    $targetType: BookmarkTargetType!
                    $withDashboardHighlights: Boolean = false
                ) {
                    createBookmark(
                        input: {
                            equityIds: $equityIds
                            highlight: $highlight
                            highlightColor: $highlightColor
                            note: $note
                            reminder: $reminder
                            tags: $tags
                            targetEndId: $targetEndId
                            targetEndOffset: $targetEndOffset
                            targetId: $targetId
                            targetOffset: $targetOffset
                            targetStreamId: $targetStreamId
                            targetType: $targetType
                        }
                    ) {
                        bookmark {
                            ...bookmark
                            ...bookmarkTarget
                        }
                        affectedTargets {
                            ... on ScheduledAudioCallEvent {
                                ...scheduledAudioCallEvent
                            }
                            ... on NewsContent {
                                id
                                bodyWithHighlights: body(highlight: { streamId: $streamId })
                                    @include(if: $includeNewsHighlights)
                                body @skip(if: $includeNewsHighlights)
                            }
                        }
                        query {
                            streams(filter: { previewStreamTypes: $streamTypes }) @include(if: $returnStream) {
                                ...stream
                                matches(size: $size, fromIndex: $offset, sort: $sort, filter: { rules: $rules }) {
                                    total
                                    results {
                                        id
                                        bookmark {
                                            ...bookmark
                                            ...bookmarkTarget
                                        }
                                    }
                                }
                            }
                            dashboards(filter: { dashboardIds: [$dashboardId] })
                                @include(if: $withDashboardHighlights) {
                                id
                                dashboardId
                                relatedBookmarks(
                                    scope: "organization"
                                    size: 30
                                    fromIndex: 0
                                    filter: { rules: $dashboardRules }
                                ) {
                                    id
                                    bookmark {
                                        ...bookmark
                                        ...bookmarkTarget
                                    }
                                    collapsed {
                                        id
                                        bookmark {
                                            ...bookmark
                                            ...bookmarkTarget
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ${scheduledAudioCallEventFragment}
                ${bookmarkTargetFragment}
                ${streamFragment}
                ${bookmarkFragment}
            `,
            {
                props: ({
                    mutate,
                    ownProps: { setStatusBanner, dashboardId, dashboardRules = [], tabs = [], equityId }
                }) => ({
                    createBookmark: ({
                        contentId,
                        equityIds,
                        eventId,
                        filterKey,
                        highlight,
                        highlightColor = '#E6F5FF',
                        includeNewsHighlights = false,
                        matchId,
                        note,
                        reminder,
                        sortKey,
                        streamId,
                        tags,
                        targetEndId,
                        targetEndOffset,
                        targetId,
                        targetOffset,
                        targetStreamId,
                        targetType
                    }) => {
                        const streamVars = generateStreamVars({ contentId, eventId, sortKey, filterKey });
                        const rules = dashboardRules.map(({ condition, ruleType, value }) => ({
                            condition,
                            ruleType,
                            value
                        }));

                        if (equityId) {
                            rules.push(
                                mapFilterToRule({
                                    operator: OPERATORS.is,
                                    type: TYPES.equity,
                                    value: equityId
                                })
                            );
                        }

                        return mutate({
                            variables: {
                                dashboardRules: rules,
                                dashboardId,
                                equityIds,
                                highlight,
                                highlightColor,
                                includeNewsHighlights,
                                note,
                                reminder,
                                returnStream: false,
                                streamId,
                                tags,
                                targetEndId,
                                targetEndOffset,
                                targetId,
                                targetOffset,
                                targetStreamId,
                                targetType: mapTargetType(targetType),
                                withDashboardHighlights: !!dashboardId && tabs.length === 0,
                                ...streamVars
                            },
                            update: (proxy, response) => {
                                if (matchId) {
                                    const bookmark = get(response, 'data.createBookmark.bookmark');
                                    updateStreamMatchFragmentBookmark({
                                        apolloClient: proxy,
                                        bookmark,
                                        matchId,
                                        targetType
                                    });
                                }
                                if (eventId || contentId) {
                                    const streams = get(response, 'data.createBookmark.query.streams');
                                    proxy.writeQuery({
                                        query: contentHighlightsStreamQuery,
                                        data: { streams },
                                        variables: streamVars
                                    });
                                }
                            }
                        }).catch(error => {
                            setStatusBanner(`Error creating highlight: ${error}`, 'error', 'circleX');
                            throw error;
                        });
                    }
                })
            }
        )
    );

export const withDeleteBookmark = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withUrlContext(['dashboardId', 'tabs']),
        graphql(
            gql`
                query withCreateBookmarkDashboard($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        dashboardId
                        rules {
                            ...rule
                        }
                    }
                }
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    return {
                        dashboardRules: get(data, 'dashboards[0].rules', [])
                    };
                },
                skip: ({ dashboardId }) => !dashboardId,
                options: ({ dashboardId }) => {
                    return {
                        fetchPolicy: 'cache-only',
                        variables: {
                            dashboardId
                        }
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation DeleteBookmark(
                    $bookmarkId: ID!
                    $dashboardId: ID
                    $dashboardRules: [StreamRuleInput]
                    $includeNewsHighlights: Boolean = false
                    $offset: Int = 0
                    $returnStream: Boolean = false
                    $rules: [StreamRuleInput]
                    $size: Int = 20
                    $sort: String
                    $streamId: ID
                    $streamTypes: [StreamType]
                    $withDashboardHighlights: Boolean = false
                ) {
                    deleteBookmark(bookmarkId: $bookmarkId) {
                        bookmark {
                            ...bookmark
                        }
                        query {
                            streams(filter: { previewStreamTypes: $streamTypes }) @include(if: $returnStream) {
                                ...stream
                                matches(size: $size, fromIndex: $offset, sort: $sort, filter: { rules: $rules }) {
                                    total
                                    results {
                                        id
                                        bookmark {
                                            ...bookmark
                                            ...bookmarkTarget
                                        }
                                    }
                                }
                            }
                            dashboards(filter: { dashboardIds: [$dashboardId] })
                                @include(if: $withDashboardHighlights) {
                                id
                                dashboardId
                                relatedBookmarks(
                                    scope: "organization"
                                    size: 30
                                    fromIndex: 0
                                    filter: { rules: $dashboardRules }
                                ) {
                                    id
                                    bookmark {
                                        ...bookmark
                                        ...bookmarkTarget
                                    }
                                    collapsed {
                                        id
                                        bookmark {
                                            ...bookmark
                                            ...bookmarkTarget
                                        }
                                    }
                                }
                            }
                        }
                        affectedTargets {
                            ... on ScheduledAudioCallEvent {
                                ...scheduledAudioCallEvent
                            }
                            ... on NewsContent {
                                id
                                bodyWithHighlights: body(highlight: { streamId: $streamId })
                                    @include(if: $includeNewsHighlights)
                                body @skip(if: $includeNewsHighlights)
                            }
                        }
                    }
                }
                ${bookmarkTargetFragment}
                ${scheduledAudioCallEventFragment}
                ${streamFragment}
                ${bookmarkFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner, dashboardId, dashboardRules = [], tabs = [] } }) => ({
                    deleteBookmark: ({
                        bookmarkId,
                        contentId,
                        eventId,
                        filterKey,
                        includeNewsHighlights = false,
                        matchId,
                        sortKey,
                        streamId,
                        targetType
                    }) => {
                        const streamVars = generateStreamVars({ eventId, contentId, sortKey, filterKey });

                        return mutate({
                            variables: {
                                bookmarkId,
                                dashboardId,
                                dashboardRules: dashboardRules.map(({ condition, ruleType, value }) => ({
                                    condition,
                                    ruleType,
                                    value
                                })),
                                includeNewsHighlights,
                                returnStream: false,
                                streamId,
                                withDashboardHighlights: !!dashboardId && tabs.length === 0,
                                ...streamVars
                            },
                            update: (proxy, response) => {
                                if (matchId) {
                                    updateStreamMatchFragmentBookmark({
                                        apolloClient: proxy,
                                        bookmark: null,
                                        matchId,
                                        targetType
                                    });
                                }
                                if (eventId || contentId) {
                                    const streams = get(response, 'data.deleteBookmark.query.streams');
                                    proxy.writeQuery({
                                        query: contentHighlightsStreamQuery,
                                        data: { streams },
                                        variables: streamVars
                                    });
                                }
                            }
                        }).catch(error => {
                            setStatusBanner(`Error deleting highlight: ${error}`, 'error', 'circleX');
                            throw error;
                        });
                    }
                })
            }
        )
    );

export const withUpdateBookmark = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation UpdateBookmark(
                    $bookmarkId: ID!
                    $equityIds: [ID]
                    $highlight: String
                    $highlightColor: String
                    $includeNewsHighlights: Boolean = false
                    $note: String
                    $reminder: DateTime
                    $streamId: ID
                    $tags: [String]
                ) {
                    updateBookmark(
                        input: {
                            bookmarkId: $bookmarkId
                            equityIds: $equityIds
                            highlight: $highlight
                            highlightColor: $highlightColor
                            note: $note
                            reminder: $reminder
                            tags: $tags
                        }
                    ) {
                        bookmark {
                            ...bookmark
                        }
                        affectedTargets {
                            ... on ScheduledAudioCallEvent {
                                ...scheduledAudioCallEvent
                            }
                            ... on NewsContent {
                                id
                                bodyWithHighlights: body(highlight: { streamId: $streamId })
                                    @include(if: $includeNewsHighlights)
                                body @skip(if: $includeNewsHighlights)
                            }
                        }
                    }
                }
                ${bookmarkFragment}
                ${scheduledAudioCallEventFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    updateBookmark: ({
                        bookmarkId,
                        equityIds,
                        highlight,
                        highlightColor,
                        note,
                        reminder,
                        tags,
                        streamId,
                        includeNewsHighlights = false
                    }) => {
                        return mutate({
                            variables: {
                                bookmarkId,
                                equityIds,
                                highlight,
                                highlightColor,
                                includeNewsHighlights,
                                note,
                                reminder,
                                streamId,
                                tags
                            }
                        }).catch(error => {
                            setStatusBanner(`Error updating highlight: ${error}`, 'error', 'circleX');
                            throw error;
                        });
                    }
                })
            }
        )
    );
