import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Notice } from 'components/Notice';
import { AskAieraForm } from 'components/AskAieraForm';
import { AskAieraEventQuestions } from 'components/AskAieraEventQuestions';
import { Icon } from 'components/Icon';
import { get } from 'utils';
import { styleSheet } from './stylesheet';
import { EventMatch } from '../EventMatch';

class EventAskAiera extends PureComponent {
    static propTypes = {
        canAskQuestions: PropTypes.bool.isRequired,
        eventId: PropTypes.string.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        onNodeSelect: PropTypes.func.isRequired,
        selectedNode: PropTypes.string,
        selectedAnswer: PropTypes.objectOf(PropTypes.any),
        selectAnswer: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        selectedAnswer: null,
        selectedNode: undefined,
        passedStyles: {}
    };

    render() {
        const {
            canAskQuestions,
            eventId,
            theme,
            passedStyles,
            styles,
            selectedAnswer,
            selectAnswer,
            onNodeSelect,
            selectedNode
        } = this.props;

        const citations = get(selectedAnswer, 'citations', []);
        if (citations.length > 0) {
            const { question, answer } = selectedAnswer;
            return (
                <Div styles={styles.citations}>
                    <Div styles={styles.selectedTermWrapper}>
                        <Div styles={styles.selectedTerm} onClick={() => selectAnswer(null)}>
                            <Div styles={styles.selectedTermTitle}>
                                <Text styles={styles.selectedTermName} size={3} weight="medium">
                                    {question}
                                </Text>
                                <Text className="selectedTermParent" size={0}>
                                    Citations
                                </Text>
                            </Div>
                            <Div className="selectedTermBack" styles={styles.selectedTermBack}>
                                <Icon type="arrowLeft" color={theme.colors.black01} />
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        styles={styles.citationsAnswer}
                        ref={c => {
                            if (c?.scrollIntoView && !selectedNode) {
                                c.scrollIntoView();
                            }
                        }}
                    >
                        <Text span uppercase weight="medium">
                            Answer
                        </Text>
                        {answer}
                    </Div>
                    {citations.map((citation, idx) => {
                        const { id: cId } = citation;
                        return (
                            <EventMatch
                                selectedNode={selectedNode}
                                key={cId}
                                data={citation}
                                index={idx + 1}
                                total={citations.length}
                                onNodeSelect={onNodeSelect}
                            />
                        );
                    })}
                </Div>
            );
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {canAskQuestions ? (
                    <Fragment>
                        <Notice type="info" styles={{ borderRadius: 6, margin: 20 }}>
                            <Text size={3}>
                                Questions are answered by artifical intelligence, and may not be completely accurate.
                            </Text>
                        </Notice>
                        <AskAieraForm eventId={eventId} />
                        <AskAieraEventQuestions eventId={eventId} onSelectAnswer={selectAnswer} />
                    </Fragment>
                ) : (
                    <Notice type="info" styles={{ borderRadius: 6, margin: 20 }}>
                        <Text size={3}>
                            Ask Aiera will be enabled after the event has a transcript, and is no longer live.
                        </Text>
                        <br />
                        <Text size={3}>Please check back shortly.</Text>
                    </Notice>
                )}
            </Div>
        );
    }
}

export const EventAskAieraUI = compose(withStyleSheet(styleSheet))(EventAskAiera);
