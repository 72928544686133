export const styleSheet = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.white03
        }
    },
    urlContainer: {
        height: '12px',
        flex: 1,
        position: 'relative'
    },
    inviteCodeContainer: {
        marginTop: theme.margins.margin02
    },
    inviteUrl: {
        position: 'absolute',
        left: theme.margins.margin00,
        right: theme.margins.margin00,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    copiedContainer: {
        display: 'flex',
        marginTop: '1px'
    },
    copiedText: {
        marginRight: theme.margins.margin00
    }
});
