import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ContentFilter } from 'components/ContentFilter';
import { CorporateActionFilter } from 'components/CorporateActionFilter';
import { CustomFilter } from 'components/CustomFilter';
import { Div } from 'components/Div';
import { EquityFilter } from 'components/EquityFilter';
import { EventFilter } from 'components/EventFilter';
import { FilingFilter } from 'components/FilingFilter';
import { Icon } from 'components/Icon';
import { NewsFilter } from 'components/NewsFilter';
import { PremiumResearchFilter } from 'components/PremiumResearchFilter';
import { ResearchFilter } from 'components/ResearchFilter';
import { SpotlightFilter } from 'components/SpotlightFilter';
import { StreetAccountFilter } from 'components/StreetAccountFilter';
import { Text } from 'components/Text';
import { TranscriptFilter } from 'components/TranscriptFilter';
import { styleSheet } from './stylesheet';

const FILTER_MAP = {
    content: ContentFilter,
    corporate_action: CorporateActionFilter,
    custom_data: CustomFilter,
    equities: EquityFilter,
    events: EventFilter,
    filings: FilingFilter,
    news: NewsFilter,
    premium: PremiumResearchFilter,
    research: ResearchFilter,
    spotlight: SpotlightFilter,
    streetAccount: StreetAccountFilter,
    transcripts: TranscriptFilter
};

class FiltersOld extends PureComponent {
    static propTypes = {
        addFilter: PropTypes.func.isRequired,
        changeFilter: PropTypes.func.isRequired,
        filterType: PropTypes.string.isRequired,
        filters: PropTypes.arrayOf(PropTypes.any).isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeFilter: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        label: null,
        passedStyles: {}
    };

    renderFilters() {
        const { addFilter, changeFilter, filterType, filters, name, removeFilter, styles, theme } = this.props;
        const FilterElement = FILTER_MAP[filterType];
        return filters.map((filter, index) => {
            const filterKey = `${name}-filter-${index}`;
            return (
                <Div key={filterKey} styles={styles.filter}>
                    <FilterElement value={filter} onChange={v => changeFilter(index, v)} name={filterKey} />
                    {index === filters.length - 1 ? (
                        <Div styles={styles.newFilterButton} onClick={addFilter}>
                            <Icon type="circlePlus" color={theme.colors.black01} />
                        </Div>
                    ) : (
                        <Div styles={styles.trashFilterButton} onClick={() => removeFilter(index)}>
                            <Icon type="trash02" color={theme.colors.black01} />
                        </Div>
                    )}
                </Div>
            );
        });
    }

    render() {
        const { styles, passedStyles, label, filterType } = this.props;
        const FilterElement = FILTER_MAP[filterType];

        if (!FilterElement) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {label && (
                    <Text styles={styles.label} size={0} uppercase>
                        {label}
                    </Text>
                )}
                {this.renderFilters()}
            </Div>
        );
    }
}

export const FiltersOldUI = compose(withStyleSheet(styleSheet))(FiltersOld);
