import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { PublicLayoutUI } from './ui';

export class PublicLayout extends PureComponent {
    static displayName = 'PublicLayoutContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <PublicLayoutUI {...rest} />;
    }
}

export const PublicLayoutContainer = compose()(PublicLayout);
