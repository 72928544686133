import React, { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import isArray from 'lodash/isArray';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { styleSheet } from './stylesheet';

class Tooltip extends PureComponent {
    static propTypes = {
        blockBackground: PropTypes.bool.isRequired,
        bottom: PropTypes.number,
        cancelClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        content: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object, PropTypes.func]),
        hideTooltip: PropTypes.func.isRequired,
        hoverElementWidth: PropTypes.number,
        isEnabled: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        left: PropTypes.number,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        persistOnMouseExit: PropTypes.bool,
        right: PropTypes.number,
        showTooltip: PropTypes.func.isRequired,
        slideIn: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tooltipContainer: PropTypes.objectOf(PropTypes.any).isRequired,
        tooltipRef: PropTypes.objectOf(PropTypes.any).isRequired,
        top: PropTypes.number,
        useMaxHoverWidth: PropTypes.bool.isRequired,
        useMinHoverWidth: PropTypes.bool.isRequired,
        useOutsideClickHandler: PropTypes.bool.isRequired
    };

    static defaultProps = {
        bottom: undefined,
        cancelClassName: null,
        content: null,
        hoverElementWidth: null,
        left: undefined,
        passedStyles: {},
        persistOnMouseExit: false,
        right: undefined,
        top: undefined
    };

    renderTooltip() {
        const {
            top,
            left,
            bottom,
            right,
            cancelClassName,
            content,
            hideTooltip,
            hoverElementWidth,
            passedStyles,
            persistOnMouseExit,
            showTooltip,
            slideIn,
            styles,
            tooltipContainer,
            tooltipRef,
            useMaxHoverWidth,
            useMinHoverWidth,
            useOutsideClickHandler
        } = this.props;
        const onMouseLeave = persistOnMouseExit ? undefined : hideTooltip;
        const slideInStyle = slideIn ? styles.slideIn : {};
        let tooltipContent = content;

        const tooltipStyles = {
            top,
            left,
            bottom,
            right,
            minWidth:
                useMinHoverWidth && hoverElementWidth && hoverElementWidth > 0
                    ? hoverElementWidth
                    : passedStyles.minWidth,
            maxWidth:
                useMaxHoverWidth && hoverElementWidth && hoverElementWidth > 0
                    ? hoverElementWidth
                    : passedStyles.maxWidth
        };

        let className = 'modal-cancelOutsideClick';
        if (cancelClassName) {
            className = `${className} ${cancelClassName}`;
        }

        if (typeof content === 'function') {
            tooltipContent = content({ hideTooltip });
        } else if (!isArray(content)) {
            tooltipContent = React.cloneElement(content, { hideTooltip, showTooltip });
        }

        return ReactDOM.createPortal(
            useOutsideClickHandler || cancelClassName ? (
                <OutsideClickHandler
                    onClick={hideTooltip}
                    cancelClassName={['tooltip-cancelOutsideClick', cancelClassName]}
                >
                    <Div
                        ref={tooltipRef}
                        onMouseLeave={onMouseLeave}
                        styles={{ ...slideInStyle, ...styles.container, ...tooltipStyles, ...passedStyles }}
                        className={className}
                    >
                        {tooltipContent}
                    </Div>
                </OutsideClickHandler>
            ) : (
                <Div
                    ref={tooltipRef}
                    onMouseLeave={onMouseLeave}
                    styles={{ ...slideInStyle, ...styles.container, ...tooltipStyles, ...passedStyles }}
                    className={className}
                >
                    {tooltipContent}
                </Div>
            ),
            tooltipContainer
        );
    }

    renderBackgroundBlocker() {
        const { hideTooltip, styles } = this.props;

        return (
            <Div
                styles={styles.backgroundBlocker}
                onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onMouseUp={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    hideTooltip(e);
                }}
            />
        );
    }

    render() {
        const { blockBackground, children, hideTooltip, isEnabled, isVisible, showTooltip } = this.props;

        return (
            <Fragment>
                {typeof children === 'function'
                    ? children({
                          showTooltip: isEnabled ? showTooltip : undefined,
                          hideTooltip: isEnabled ? hideTooltip : undefined,
                          isTooltipVisible: isVisible
                      })
                    : children}
                {isEnabled && isVisible && blockBackground && this.renderBackgroundBlocker()}
                {isEnabled && isVisible && this.renderTooltip()}
            </Fragment>
        );
    }
}

export const TooltipUI = compose(withStyleSheet(styleSheet))(Tooltip);
