import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { EventLogsUI } from './ui';

export class EventLogs extends PureComponent {
    static displayName = 'EventLogsContainer';

    static propTypes = {
        loading: PropTypes.bool,
        logs: PropTypes.arrayOf(PropTypes.object),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        loading: true,
        logs: [],
        styles: undefined
    };

    render() {
        const { loading, logs, styles } = this.props;
        return <EventLogsUI loading={loading} logs={logs} styles={styles} />;
    }
}

export const EventLogsContainer = compose(withData())(EventLogs);
