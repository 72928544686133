import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { ContentStreamToolbarUI } from './ui';

export class ContentStreamToolbar extends PureComponent {
    static displayName = 'ContentStreamToolbarContainer';

    static propTypes = {
        show: PropTypes.bool.isRequired,
        streamContentTypes: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        toggleContentType: PropTypes.func.isRequired,
        contentTypes: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        streamContentTypes: [],
        styles: undefined,
        contentTypes: []
    };

    render() {
        const { streamContentTypes, styles, toggleContentType, contentTypes, show } = this.props;
        if (show) {
            return (
                <ContentStreamToolbarUI
                    streamContentTypes={streamContentTypes}
                    styles={styles}
                    toggleContentType={toggleContentType}
                    contentTypes={contentTypes}
                />
            );
        }

        return null;
    }
}

export const ContentStreamToolbarContainer = compose()(ContentStreamToolbar);
