import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { statusBannerFire } from 'actions/statusBanner';
import { withGetUser } from 'graphql/user';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
        graphql(
            gql`
                query withPDFAnnotations($targetId: ID, $targetType: AnnotationTargetType) {
                    annotations(filter: { targetIds: [$targetId], targetTypes: [$targetType] }) {
                        __typename
                        id
                        annotationId
                        created
                        externalAnnotationId
                        highlight
                        highlightHash
                        shared
                        targetId
                        targetType
                        user {
                            id
                            email
                        }
                        userId
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const refetch = get(data, 'refetch');
                    const annotations = get(data, 'annotations', []);
                    const authorMap = {};
                    annotations.forEach(({ user }) => {
                        authorMap[user.id] = user.email;
                    });
                    return {
                        authorMap,
                        refetchAnnotations: refetch,
                        annotations: get(data, 'annotations', [])
                    };
                },
                options: ({ targetId, targetType }) => ({
                    fetchPolicy: 'cache-first',
                    returnPreviousData: true,
                    variables: {
                        targetId,
                        targetType
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation CreateAnnotation(
                    $highlight: String!
                    $targetId: ID!
                    $targetType: AnnotationTargetType!
                    $externalAnnotationId: String
                ) {
                    createAnnotation(
                        input: {
                            highlight: $highlight
                            targetId: $targetId
                            targetType: $targetType
                            externalAnnotationId: $externalAnnotationId
                        }
                    ) {
                        annotation {
                            id
                            annotationId
                            highlight
                            status
                            targetId
                            targetType
                        }
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner, targetId, targetType } }) => ({
                    createAnnotation: ({ highlight, annotationId }) => {
                        return mutate({
                            variables: {
                                highlight: JSON.stringify(highlight),
                                targetId,
                                targetType,
                                externalAnnotationId: annotationId
                            },
                            context: {
                                debounceKey: `createAnnotation`,
                                debounceTimeout: 300
                            }
                        }).catch(() => setStatusBanner('Error saving annotation', 'error', 'circleX'));
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation updateAnnotation(
                    $highlight: String!
                    $targetId: ID!
                    $targetType: AnnotationTargetType!
                    $externalAnnotationId: String
                ) {
                    updateAnnotation(
                        input: {
                            highlight: $highlight
                            targetId: $targetId
                            targetType: $targetType
                            externalAnnotationId: $externalAnnotationId
                        }
                    ) {
                        annotation {
                            id
                            annotationId
                            highlight
                            status
                            targetId
                            targetType
                        }
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner, targetId, targetType } }) => ({
                    updateAnnotation: ({ highlight, annotationId }) => {
                        return mutate({
                            variables: {
                                highlight: JSON.stringify(highlight),
                                targetId,
                                targetType,
                                externalAnnotationId: annotationId
                            },
                            context: {
                                debounceKey: `updateAnnotation`,
                                debounceTimeout: 300
                            }
                        }).catch(() => setStatusBanner('Error saving annotation update', 'error', 'circleX'));
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation DeleteAnnotation($externalAnnotationId: String, $targetId: ID!) {
                    deleteAnnotation(input: { externalAnnotationId: $externalAnnotationId, targetId: $targetId }) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner, targetId } }) => ({
                    deleteAnnotation: ({ annotationId }) => {
                        return mutate({
                            variables: {
                                externalAnnotationId: annotationId,
                                targetId
                            },
                            context: {
                                debounceKey: `deleteAnnotation`,
                                debounceTimeout: 300
                            }
                        }).catch(() => setStatusBanner('Error deleting annotation', 'error', 'circleX'));
                    }
                })
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.trySubscribe = targetId => {
                    if (this.subscription && this.subscribedId !== targetId) {
                        this.subscription.unsubscribe();
                        this.subscription = null;
                        this.subscribedId = null;
                    }
                    if (!this.subscription) {
                        const { realtime } = this.props;
                        this.subscription = realtime.subscribe(
                            `annotation_changes_target_${targetId}`,
                            'modified',
                            () => {
                                const { refetchAnnotations } = this.props;
                                if (refetchAnnotations) {
                                    refetchAnnotations();
                                }
                            }
                        );
                        this.subscribedId = targetId;
                    }
                };
            },
            componentDidUpdate() {
                const { targetId } = this.props;
                this.trySubscribe(targetId);
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                    this.subscribedId = null;
                }
            }
        })
    );
