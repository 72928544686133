import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { mapPropsToOptions } from 'hoc/utils';
import { get } from 'utils';

export const withCreateStream = (options = {}) =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CreateStream(
                    $dashboardIds: [ID]
                    $name: String
                    $rules: [StreamRuleInput]
                    $streamType: StreamType!
                    $uxPreferences: GenericScalar
                ) {
                    createStream(
                        input: {
                            dashboardIds: $dashboardIds
                            name: $name
                            rules: $rules
                            streamType: $streamType
                            uxPreferences: $uxPreferences
                        }
                    ) {
                        stream {
                            ...stream
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    createStream: ({ dashboardIds, name, rules, streamType, uxPreferences, showBanner = true }) =>
                        mutate({
                            variables: { dashboardIds, name, rules, streamType, uxPreferences },
                            update: (proxy, response) => {
                                const query = gql`
                                    query withCreateStreamDashboards($dashboardIds: [ID]!) {
                                        dashboards(filter: { dashboardIds: $dashboardIds }) {
                                            id
                                            streams {
                                                ...stream
                                            }
                                        }
                                    }
                                    ${streamFragment}
                                `;
                                try {
                                    const { dashboards } = proxy.readQuery({
                                        query,
                                        variables: { dashboardIds }
                                    });
                                    const dashboard = get(dashboards, '[0]');
                                    const stream = get(response, 'data.createStream.stream');
                                    if (dashboard && stream) {
                                        const streams = get(dashboard, 'streams', []);
                                        proxy.writeQuery({
                                            query,
                                            data: {
                                                dashboards: [
                                                    {
                                                        ...dashboard,
                                                        streams: [...streams, stream]
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                } catch {
                                    // The stream's dashboard isn't in the cache yet, so do nothing
                                }
                            }
                        })
                            .then(() => {
                                if (showBanner) setStatusBanner('Search saved successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error saving search: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                }),
                options: props => {
                    let ret = {};
                    const { batchKey } = mapPropsToOptions(options, props);
                    if (batchKey) {
                        ret = { context: { batchKey } };
                    }
                    return ret;
                }
            }
        )
    );

export const withDeleteStream = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation DeleteStream($streamId: ID!) {
                    deleteStream(streamId: $streamId) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    const { dashboardId } = ownProps || {};
                    return {
                        deleteStream: streamId =>
                            mutate({
                                variables: { streamId },
                                update: proxy => {
                                    const query = gql`
                                        query withDeleteStream($dashboardId: ID) {
                                            dashboards(filter: { dashboardIds: [$dashboardId] }) {
                                                id
                                                streams {
                                                    id
                                                }
                                            }
                                        }
                                    `;
                                    const { dashboards } = proxy.readQuery({
                                        query,
                                        variables: { dashboardId }
                                    });
                                    const dashboard = get(dashboards, '[0]');
                                    if (dashboard) {
                                        const streams = get(dashboard, 'streams', []).filter(s => s.id !== streamId);
                                        proxy.writeQuery({ query, data: { dashboards: [{ ...dashboard, streams }] } });
                                    }
                                }
                            })
                                .then(() => {
                                    if (dashboardId !== 'aieracast') {
                                        setStatusBanner('Saved Search deleted successfully!');
                                    }
                                })
                                .catch(error => {
                                    setStatusBanner(`Error deleting Saved Search: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        )
    );

export const withUpdateStreamUxPreferences = () =>
    compose(
        graphql(
            gql`
                mutation updateStreamUxPreferences($streamId: ID!, $uxPreferences: GenericScalar) {
                    updateStreamUxPreferences(streamId: $streamId, uxPreferences: $uxPreferences) {
                        stream {
                            ...stream
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ mutate }) => ({
                    saveStreamUXPreference: (streamId, uxPreferences) => {
                        return mutate({
                            variables: {
                                streamId,
                                uxPreferences
                            }
                        });
                    }
                })
            }
        )
    );
