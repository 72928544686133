import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withTabContext } from 'hoc/tabContext';
import { onlyUpdateForKeysPassthrough } from 'hoc/utils';
import { parseTabId } from 'utils';
import qs from 'qs';

export const withQueryParams = () =>
    compose(
        withRouter,
        withProps(({ location: { search, hash, pathname }, match: { params } }) => {
            const searchQuery = search.includes('?') ? search.slice(1) : search;
            const queryParams = qs.parse(searchQuery);
            const hashQuery = hash.includes('#') ? hash.slice(1) : hash;
            const hashParams = qs.parse(hashQuery);
            return {
                ...params,
                ...queryParams,
                ...hashParams,
                pathname
            };
        })
    );

export const withUrlContext = keys =>
    onlyUpdateForKeysPassthrough(
        keys,
        compose(
            withQueryParams(),
            withTabContext(),
            withProps(({ tabId }) => parseTabId(tabId))
        )
    );
