import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class AddToCalendarDropdown extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, children } = this.props;
        return <Div styles={{ ...styles.container, ...passedStyles }}>{children}</Div>;
    }
}

export const AddToCalendarDropdownUI = compose(withStyleSheet(styleSheet))(AddToCalendarDropdown);
