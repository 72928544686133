export const styleSheet = theme => {
    const container = {
        alignItems: 'center',
        backgroundColor: theme.colors.white01,
        borderRadius: 6,
        boxShadow: `inset 0 0 0 1px ${theme.colors.gray03}`,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        svg: {
            height: 14
        },
        ':hover': {
            boxShadow: 'unset',
            backgroundColor: theme.colors.gray03
        }
    };
    return {
        container: {
            ...container,
            ':active': {
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        containerEnabled: {
            ...container,
            ':active': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray03}`,
                backgroundColor: 'transparent',
                'svg g': {
                    fill: theme.colors.gray06
                }
            }
        },
        tooltip: {
            ...theme.content.tooltipText,
            display: 'flex',
            paddingRight: 16
        },
        tooltipText: {
            marginRight: 16
        }
    };
};
