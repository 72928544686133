export const styleSheet = theme => ({
    container: {
        ...theme.content.tooltipText,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    label: {
        letterSpacing: 2,
        opacity: 0.6,
        marginBottom: 2
    },
    spacer: {
        display: 'flex',
        flex: 1,
        minWidth: 20
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        span: {
            marginLeft: 6
        }
    }
});
