import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath, Link } from 'react-router-dom';
import XDate from 'xdate';
import { withStyleSheet } from 'hoc/styles';
import { routes } from 'routes';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { ExternalLink } from 'components/ExternalLink';

import { get, titleize } from 'utils';
import { styleSheet } from './stylesheet';

const DETAIL_LIST_ORDER = [
    'Event ID',
    'Event Type',
    'Date',
    'Conf. number',
    'Conf. PIN',
    'Presentation',
    'Press Release',
    'Broadcast URL',
    'Audio URL',
    'Conference'
];

class EventDetails extends PureComponent {
    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        audioProxy: PropTypes.string,
        broadcastUrl: PropTypes.string,
        callDate: PropTypes.string,
        callType: PropTypes.string,
        conference: PropTypes.objectOf(PropTypes.any),
        conferenceNumber: PropTypes.string,
        conferencePin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hasUnknownTime: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        openAttachment: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pressUrl: PropTypes.string,
        slidesUrl: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tabId: PropTypes.string,
        transcriptionStatus: PropTypes.string
    };

    static defaultProps = {
        attachments: [],
        audioProxy: null,
        broadcastUrl: null,
        callDate: null,
        callType: 'Unknown',
        conference: null,
        conferenceNumber: null,
        conferencePin: null,
        hasUnknownTime: false,
        id: null,
        passedStyles: {},
        pressUrl: null,
        slidesUrl: null,
        tabId: null,
        transcriptionStatus: null
    };

    renderDetailItem(label, value) {
        const { styles } = this.props;

        return (
            <Div styles={styles.detailItem} key={label}>
                <Div styles={styles.textLabel}>
                    <Text size={3}>{label}</Text>
                </Div>
                <Div styles={styles.detailValue}>
                    <Text size={3}>{value}</Text>
                </Div>
            </Div>
        );
    }

    render() {
        const {
            attachments,
            audioProxy,
            broadcastUrl,
            callType,
            callDate,
            conference,
            conferenceNumber,
            conferencePin,
            hasUnknownTime,
            id,
            openAttachment,
            passedStyles,
            pressUrl,
            slidesUrl,
            styles,
            tabId,
            transcriptionStatus
        } = this.props;
        let dateTime;
        if (callDate) {
            dateTime = new XDate(callDate).toString(hasUnknownTime ? 'MMM d, yyyy' : 'h:mm TT, MMM d, yyyy');
        }
        const dict = {
            'Event Type': titleize(callType),
            Date: dateTime,
            'Event ID': id,
            'Conf. number': conferenceNumber,
            'Conf. PIN': conferencePin
        };
        const detailList = [];

        if (broadcastUrl) {
            dict['Broadcast URL'] = <ExternalLink href={broadcastUrl}>View Source</ExternalLink>;
        }

        if (audioProxy && ['finished', 'archived'].includes(transcriptionStatus)) {
            dict['Audio URL'] = <ExternalLink href={audioProxy}>Download Audio</ExternalLink>;
        }

        if (conference && conference.id && conference.title) {
            dict.Conference = (
                <Link to={generatePath(routes.conference, { eventGroupId: conference.id })}>{conference.title}</Link>
            );
        }

        if (pressUrl && tabId) {
            const attachmentIndex = attachments.findIndex(
                i =>
                    get(i, 'title', '')
                        .toLowerCase()
                        .includes('press') && get(i, 'mimeType', '').includes('pdf')
            );
            if (attachmentIndex >= 0) {
                dict['Press Release'] = (
                    <Div styles={styles.rowLink} onClick={() => openAttachment(attachmentIndex)}>
                        View Press Release
                    </Div>
                );
            } else {
                dict['Press Release'] = <ExternalLink href={pressUrl}>View Press Release</ExternalLink>;
            }
        }

        if (slidesUrl && tabId) {
            const attachmentIndex = attachments.findIndex(
                i =>
                    get(i, 'title', '')
                        .toLowerCase()
                        .includes('slides') && get(i, 'mimeType', '').includes('pdf')
            );
            if (attachmentIndex >= 0) {
                dict.Presentation = (
                    <Div styles={styles.rowLink} onClick={() => openAttachment(attachmentIndex)}>
                        View Presentation
                    </Div>
                );
            } else {
                dict.Presentation = <ExternalLink href={slidesUrl}>View Presentation</ExternalLink>;
            }
        }

        DETAIL_LIST_ORDER.forEach(key => {
            if (dict[key]) {
                detailList.push(this.renderDetailItem(key, dict[key]));
            }
        });

        return <Div styles={{ ...styles.container, ...passedStyles }}>{detailList}</Div>;
    }
}

export const EventDetailsUI = compose(withStyleSheet(styleSheet))(EventDetails);
