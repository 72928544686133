import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { VIDEO_URLS } from 'consts';
import { get } from 'utils';
import { VideoPlayerUI } from './ui';

export class VideoPlayer extends PureComponent {
    static displayName = 'VideoPlayerContainer';

    static propTypes = {
        autoplay: PropTypes.bool,
        onClickPreview: PropTypes.func,
        playerRef: PropTypes.objectOf(PropTypes.any),
        showControls: PropTypes.bool,
        showPreview: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        videoType: PropTypes.string.isRequired
    };

    static defaultProps = {
        autoplay: false,
        onClickPreview: undefined,
        playerRef: undefined,
        showControls: true,
        showPreview: false,
        styles: undefined
    };

    render() {
        const { autoplay, onClickPreview, playerRef, showControls, showPreview, styles, videoType } = this.props;
        return (
            <VideoPlayerUI
                autoplay={autoplay}
                onClickPreview={onClickPreview}
                player={get(VIDEO_URLS, `${videoType}.player`)}
                playerRef={playerRef}
                showControls={showControls}
                showPreview={showPreview}
                styles={styles}
                url={get(VIDEO_URLS, `${videoType}.url`)}
            />
        );
    }
}

export const VideoPlayerContainer = compose()(VideoPlayer);
