import { compose, lifecycle } from 'recompose';
import XDate from 'xdate';
import { withRealtime } from 'provider/realtime';

export const withData = () =>
    compose(
        withRealtime(),
        lifecycle({
            componentDidMount() {
                const { isPartial } = this.props;
                this.trySubscribe = () => {
                    const { eventId, realtime } = this.props;
                    if (!this.subscriptions && eventId) {
                        this.subscriptions = [
                            realtime.subscribe(
                                `scheduled_audio_call_${eventId}_events_changes`,
                                'partial_transcript',
                                ({
                                    start_timestamp_ms: startTimestampMs,
                                    pretty_transcript: prettyTranscript,
                                    transcript,
                                    index: partialIndex
                                }) => {
                                    this.setState({
                                        partialTranscript: prettyTranscript || transcript,
                                        transcriptTime: new XDate(startTimestampMs),
                                        partialIndex
                                    });
                                }
                            ),
                            realtime.subscribe(
                                `scheduled_audio_call_${eventId}_events_changes`,
                                'partial_transcript_clear',
                                ({ index }) => {
                                    this.setState({ clearedPartialIndex: index });
                                }
                            )
                        ];
                    }
                };
                if (isPartial) this.trySubscribe();
            },
            componentDidUpdate({ lastTranscriptId: prevId }) {
                const { isPartial, lastTranscriptId } = this.props;
                const { partialIndex, clearedPartialIndex } = this.state || {};
                if (isPartial) {
                    this.trySubscribe();
                    if (prevId !== lastTranscriptId && partialIndex === clearedPartialIndex) {
                        this.setState({ partialTranscript: '' });
                    }
                }
            },

            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        })
    );
