import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { CustomStreamUI } from './ui';

export class CustomStream extends PureComponent {
    static displayName = 'CustomStreamContainer';

    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        refetch: PropTypes.func,
        stream: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string,
        template: PropTypes.objectOf(PropTypes.any),
        templateType: PropTypes.string
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        refetch: null,
        stream: null,
        streamId: null,
        styles: undefined,
        subtitle: undefined,
        template: null,
        templateType: null
    };

    componentDidUpdate({ stream: prevStream }) {
        const { refetch, stream } = this.props;
        if (
            get(stream, 'dataCollectionId') !== get(prevStream, 'dataCollectionId') ||
            get(stream, 'streamMatchTemplateId') !== get(prevStream, 'streamMatchTemplateId')
        ) {
            if (refetch) {
                refetch();
            }
        }
    }

    render() {
        const {
            dashboardId,
            loadMoreMatches,
            loading,
            matches,
            streamId,
            streamProps,
            styles,
            subtitle,
            template,
            templateType
        } = this.props;
        return (
            <CustomStreamUI
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
                template={template}
                templateType={templateType}
            />
        );
    }
}

export const CustomStreamContainer = compose(withData())(CustomStream);
