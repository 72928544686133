export const styleSheet = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        textAlign: 'center',
        color: theme.colors.gray06,
        backgroundColor: 'rgba(229, 231, 239, 0.8)',
        // backdropFilter: 'blur(5px)', TODO renable when chrome fixes support
        top: 8,
        zIndex: 2,
        padding: '8px 8px',
        margin: '0 10px',
        borderRadius: 20,
        marginTop: -3,
        letterSpacing: 1,
        '.dateScrollTop': {
            color: theme.colors.gray04,
            cursor: 'pointer',
            ':hover': {
                color: theme.colors.gray06
            },
            ':active': {
                color: theme.colors.gray08
            }
        }
    }
});
