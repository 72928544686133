export const styleSheet = theme => ({
    container: {},
    header: {
        position: 'relative',
        backgroundColor: theme.colors.gray01,
        display: 'flex',
        alignItems: 'center',
        minHeight: 40,
        height: 40,
        zIndex: 2,
        borderRadius: '4px 4px 0 0',
        overflow: 'hidden',
        padding: 2,
        paddingRight: theme.margins.margin00,
        input: {
            fontSize: theme.fonts.fontSizes.size02,
            height: 30,
            borderRadius: 15,
            ':focus': {
                boxShadow: 'unset'
            },
            ':not(:placeholder-shown)': {
                backgroundColor: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        '.iconWrapper': {
            height: 30,
            svg: {
                height: 14
            }
        }
    },
    search: {
        flex: 1
    }
});
