export const styleSheet = theme => {
    return {
        container: {
            ...theme.content.slideUpAnimation,
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: '0 -4px 4px 0 rgba(66, 65, 82, 0.07)',
            backgroundColor: theme.colors.gray08,
            zIndex: 1
        },
        listenNow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.colors.gray04,
            flex: 1,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 6,
            padding: '0px 6px',
            marginRight: 10,
            marginLeft: 40,
            height: 34
        },
        event: {
            marginLeft: 10
        },
        eventHeader: {
            display: 'flex',
            alignItems: 'baseline'
        },
        ticker: {
            color: theme.colors.white01
        },
        callType: {
            color: theme.colors.gray04,
            marginLeft: 6
        },
        date: {
            marginTop: 3,
            color: theme.colors.gray04,
            opacity: 0.6
        },
        audioControl: {
            padding: '0 4px 0 2px',
            height: 30,
            width: 30,
            borderRadius: 15,
            background: theme.colors.gradient03,
            cursor: 'pointer',
            marginLeft: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '.playIcon': {
                height: 12,
                marginLeft: 4
            },
            '.pauseIcon': {
                height: 12,
                marginLeft: 2
            },
            ':active': {
                background: theme.colors.gradient03c
            },
            ':hover': {
                background: theme.colors.gradient03b
            }
        }
    };
};
