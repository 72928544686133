import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { StreamAlertsTooltipUI } from './ui';

export class StreamAlertsTooltip extends PureComponent {
    static displayName = 'StreamAlertsTooltipContainer';

    static propTypes = {
        averageDailyVolume: PropTypes.number,
        enableRealTime: PropTypes.bool,
        loading: PropTypes.bool,
        name: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        targetId: PropTypes.string.isRequired,
        targetType: PropTypes.string.isRequired,
        toggleValueRealTimeEmail: PropTypes.bool,
        toggleValueRealTimeInApp: PropTypes.bool,
        updateAlertSettings: PropTypes.func.isRequired,
        updateAverageDailyVolume: PropTypes.func.isRequired
    };

    static defaultProps = {
        averageDailyVolume: undefined,
        enableRealTime: true,
        loading: false,
        name: undefined,
        styles: undefined,
        toggleValueRealTimeEmail: false,
        toggleValueRealTimeInApp: false
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.updateAverageDailyVolume = this.updateAverageDailyVolume.bind(this);

        this.state = {
            realTimeDelivery: [],
            averageDailyVolumeLoading: false
        };
    }

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate({ toggleValueRealTimeEmail: prevRealTimeEmail, toggleValueRealTimeInApp: prevRealTimeInApp }) {
        const { toggleValueRealTimeEmail, toggleValueRealTimeInApp } = this.props;
        let shouldUpdate = false;
        if (prevRealTimeEmail !== toggleValueRealTimeEmail || prevRealTimeInApp !== toggleValueRealTimeInApp) {
            shouldUpdate = true;
        }
        if (shouldUpdate) {
            const realTimeDelivery = [];
            if (toggleValueRealTimeInApp) {
                realTimeDelivery.push('realTimeInApp');
            }
            if (toggleValueRealTimeEmail) {
                realTimeDelivery.push('realTimeEmail');
            }
            this.setState({ realTimeDelivery });
        }
    }

    onChange({ name, value }) {
        const { updateAlertSettings, targetType, targetId } = this.props;
        const { realTimeDelivery } = this.state;
        const resetState = {
            targetId,
            targetType,
            realtimeNotificationsEmail: false,
            realtimeNotificationsInApp: false
        };

        if (name === 'realTimeDelivery') {
            if (!value.includes('realTimeInApp') && !value.includes('realTimeEmail')) {
                // Email and InApp both not selected, so reset state
                this.setState(
                    {
                        realTimeDelivery: []
                    },
                    () => {
                        updateAlertSettings(resetState);
                    }
                );
            } else if (realTimeDelivery.length > 0 && value.length > 0) {
                // Already had one selected, still have at least one selected
                const newState = {
                    ...resetState
                };
                if (value.includes('realTimeEmail')) {
                    newState.realtimeNotificationsEmail = true;
                }
                if (value.includes('realTimeInApp')) {
                    newState.realtimeNotificationsInApp = true;
                }
                this.setState(
                    {
                        realTimeDelivery: value
                    },
                    () => {
                        updateAlertSettings(newState);
                    }
                );
            } else if (realTimeDelivery.length === 0 && value.length > 0) {
                // Selected one delivery type for the first time
                const newState = {
                    ...resetState
                };
                if (value.includes('realTimeEmail')) {
                    newState.realtimeNotificationsEmail = true;
                }
                if (value.includes('realTimeInApp')) {
                    newState.realtimeNotificationsInApp = true;
                }
                this.setState(
                    {
                        realTimeDelivery: value
                    },
                    () => {
                        updateAlertSettings(newState);
                    }
                );
            }
        }
    }

    updateAverageDailyVolume() {
        const { updateAverageDailyVolume, targetId } = this.props;
        this.setState({ averageDailyVolumeLoading: true }, () => {
            updateAverageDailyVolume(targetId).finally(() => {
                this.setState({ averageDailyVolumeLoading: false });
            });
        });
    }

    render() {
        const { realTimeDelivery, averageDailyVolumeLoading } = this.state;
        const { averageDailyVolume, enableRealTime, loading, name, styles } = this.props;
        const hasAlerts = realTimeDelivery.length > 0;
        return (
            <StreamAlertsTooltipUI
                averageDailyVolume={averageDailyVolume}
                averageDailyVolumeLoading={averageDailyVolumeLoading}
                enableRealTime={enableRealTime}
                hasAlerts={hasAlerts}
                hasWarning={hasAlerts && !!averageDailyVolume && averageDailyVolume > 100}
                loading={loading}
                name={name}
                onChange={this.onChange}
                realTimeDelivery={realTimeDelivery}
                styles={styles}
                updateAverageDailyVolume={this.updateAverageDailyVolume}
            />
        );
    }
}

export const StreamAlertsTooltipContainer = compose(withData())(StreamAlertsTooltip);
