export const styleSheet = theme => {
    const container = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 15,
        width: '100%',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        ':first-of-type': {
            borderRadius: '6px 6px 0 0'
        },
        ':last-of-type': {
            borderRadius: '0 0 6px 6px',
            borderBottom: 'unset'
        }
    };
    const checkbox = {
        border: `1px solid ${theme.colors.gray09}`,
        borderRadius: 3,
        height: 20,
        width: 20,
        flexShrink: 0
    };
    const radio = {
        border: `1px solid ${theme.colors.gray09}`,
        borderRadius: 10,
        height: 16,
        width: 16,
        flexShrink: 0
    };
    return {
        container: {
            ...container,
            ':hover': {
                backgroundColor: theme.colors.gray10
            },
            ':active': {
                backgroundColor: theme.colors.gray03
            }
        },
        containerDisabled: {
            cursor: 'not-allowed'
        },
        containerLocked: {
            ...container,
            cursor: 'default'
        },
        checkbox: {
            ...checkbox
        },
        checkboxSelected: {
            ...checkbox,
            backgroundColor: theme.colors.blue08,
            border: 0,
            boxShadow: '0 2px 4px 0 rgba(66,65,82,0.06)',
            svg: {
                height: 11,
                margin: 5,
                width: 11
            }
        },
        radio: {
            ...radio
        },
        radioSelected: {
            ...radio,
            backgroundColor: theme.colors.blue08,
            border: 0,
            boxShadow: '0 2px 4px 0 rgba(66,65,82,0.06)',
            svg: {
                height: 9,
                margin: 4,
                width: 9
            }
        },
        selectDisabled: {
            opacity: 0.3
        },
        justifiedLeft: {
            marginLeft: 'auto'
        },
        lock: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 6,
            height: 26,
            width: 26,
            backgroundColor: theme.colors.yellow07,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.yellow06
            },
            ':active': {
                backgroundColor: theme.colors.yellow04
            }
        }
    };
};
