export const styleSheet = theme => ({
    container: {
        width: 608
    },
    customForm: {
        width: 608,
        marginTop: theme.margins.margin02
    },
    sourceAndTemplateContainer: {
        display: 'flex'
    },
    sourceType: {
        width: '50%',
        marginRight: theme.margins.margin01
    },
    templateType: {
        width: '50%'
    },
    templateFields: {
        display: 'flex',
        flexBasis: 1,
        flexWrap: 'wrap'
    },
    templateField: {
        width: '50%',
        marginTop: theme.margins.margin01,
        ':nth-of-type(odd)': {
            width: 'calc(50% - 20px)',
            marginRight: theme.margins.margin01
        }
    },
    urlInput: {
        marginTop: theme.margins.margin02,
        width: '100%'
    },
    gSheetInfo: {
        paddingTop: theme.margins.margin01
    },
    gSheetEmail: {
        backgroundColor: theme.colors.yellow06,
        boxShadow: 'inset 0 -1px 0 0 rgb(255, 234, 155)',
        padding: '4px 2px',
        display: 'inline-block',
        margin: '4px 0'
    },
    gSheetInstruction: {
        '.noticeContent': {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            span: {
                display: 'block',
                backgroundColor: theme.colors.yellow01
            }
        }
    },
    configContainer: {
        display: 'flex'
    },
    sampleControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: `calc(${theme.margins.margin01} + 27px)`,
        minWidth: 60,
        '> *': {
            paddingLeft: 15
        }
    },
    sampleError: {
        ...theme.content.tooltipText,
        maxHeight: 100,
        maxWidth: 350,
        overflowY: 'scroll',
        cursor: 'default'
    },
    sampleReprocess: {
        cursor: 'pointer'
    },
    option: {
        padding: `4px 12px`,
        minHeight: 40,
        height: 'unset'
    },
    optionBlock: {
        display: 'block',
        width: '100%'
    },
    optionValues: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    optionText: {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    bodyContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        flex: 1
    },
    bodyRawLabel: {
        ...theme.content.formLabel
    },
    bodyRaw: {
        '.checkbox': {
            margin: '5px 10px',
            backgroundColor: theme.colors.white03
        }
    }
});
