import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { DropdownOptionWrapUI } from './ui';

export class DropdownOptionWrap extends PureComponent {
    static displayName = 'DropdownOptionWrapContainer';

    static propTypes = {
        className: PropTypes.string,
        option: PropTypes.objectOf(PropTypes.any).isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        className: undefined
    };

    render() {
        const { className, option, onMouseDown, onMouseEnter, styles } = this.props;
        return (
            <DropdownOptionWrapUI
                className={className}
                option={option}
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                styles={styles}
            />
        );
    }
}

export const DropdownOptionWrapContainer = compose()(DropdownOptionWrap);
