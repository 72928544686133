export const styleSheet = theme => {
    const result = {
        cursor: 'pointer',
        fontSize: theme.fonts.fontSizes.size03,
        paddingTop: 12,
        paddingBottom: 12,
        lineHeight: '1.3em',
        paddingRight: theme.margins.margin01,
        paddingLeft: theme.margins.margin00,
        '.localTicker': {
            lineHeight: '1em'
        }
    };

    return {
        results: {
            width: '100%'
        },
        result: {
            ...result,
            ':nth-of-type(even)': {
                backgroundColor: theme.colors.gray10
            }
        },
        resultHighlighted: {
            ...result,
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01,
            '.identifierTag': {
                p: {
                    color: theme.colors.black01
                }
            },
            '.localTicker': {
                color: theme.colors.black01
            },
            em: {
                color: theme.colors.black01
            },
            p: {
                color: theme.colors.white01
            },
            div: {
                color: theme.colors.white01
            }
        },
        preview: {
            ...theme.content.box,
            margin: theme.margins.margin00,
            borderRadius: 6,
            overflow: 'hidden'
        },
        previewBlock: {
            padding: theme.margins.margin00,
            borderBottom: `1px solid ${theme.colors.gray03}`,
            ':last-of-type': {
                borderBottom: 'unset'
            }
        },
        previewLabel: {
            color: theme.colors.gray06,
            letterSpacing: 1,
            marginBottom: 4
        },
        resultHeaders: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.colors.gray05,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            fontSize: theme.fonts.fontSizes.size01,
            textAlign: 'left',
            letterSpacing: 1,
            color: theme.colors.gray06,
            paddingLeft: theme.margins.margin00
        },
        resultTop: {},
        summary: {
            ...theme.content.annotated,
            color: theme.colors.gray08,
            marginTop: 8,
            fontSize: theme.fonts.fontSizes.size01,
            lineHeight: '1.3em'
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            margin: '12px auto'
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            margin: 10,
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        title: {
            fontSize: 14,
            lineHeight: '1.2em'
        },
        subTitle: {
            marginTop: 2,
            color: theme.colors.gray06,
            lineHeight: '1.2em'
        },
        equities: {
            marginTop: theme.margins.margin00,
            marginLeft: 5
        },
        singleEquity: {
            marginLeft: theme.margins.margin01,
            float: 'right'
        }
    };
};
