import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { get } from 'utils';
import { withData } from './data';
import { DashboardStatsUI } from './ui';

export class DashboardStats extends PureComponent {
    static displayName = 'DashboardStatsContainer';

    static propTypes = {
        chartData: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashboardId: PropTypes.string,
        filterMonths: PropTypes.number.isRequired,
        hasFilters: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool,
        isOpen: PropTypes.bool,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        name: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        searchTerm: PropTypes.string,
        selectRange: PropTypes.func.isRequired,
        streamCounts: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        dashboardId: null,
        isEmpty: false,
        isOpen: false,
        loading: false,
        name: undefined,
        searchTerm: undefined,
        styles: undefined
    };

    render() {
        const {
            chartData,
            dashboardId,
            filterMonths,
            hasFilters,
            isEmpty,
            isOpen,
            loading,
            location,
            name,
            onClose,
            onSearch,
            searchTerm,
            selectRange,
            streamCounts,
            styles
        } = this.props;
        return (
            <DashboardStatsUI
                chartData={chartData}
                dashboardId={dashboardId}
                filterMonths={filterMonths}
                dashDateFilter={get(location, 'state.dashDateRange')}
                hasFilters={hasFilters}
                isEmpty={isEmpty}
                isOpen={isOpen}
                loading={loading}
                name={name && `${name} Analysis`}
                onClose={onClose}
                onSearch={onSearch}
                searchTerm={searchTerm}
                selectRange={selectRange}
                streamCounts={streamCounts}
                styles={styles}
            />
        );
    }
}

export const DashboardStatsContainer = compose(
    withUrlContext(['dst', 'location']),
    withStateHandlers(
        ({ dst }) => ({
            filterMonths: 13,
            searchTerm: dst
        }),
        {
            selectRange: () => filterMonths => ({
                filterMonths
            }),
            onSearch: () => ({ value: searchTerm }) => ({
                searchTerm
            })
        }
    ),
    withData()
)(DashboardStats);
