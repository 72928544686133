import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withData } from './data';
import { LiveEventRowUI } from './ui';

export class LiveEventRow extends PureComponent {
    static displayName = 'LiveEventRowContainer';

    static propTypes = {
        addEvent: PropTypes.func.isRequired,
        company: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]).isRequired,
        displayMode: PropTypes.string.isRequired,
        exchangeName: PropTypes.string,
        hasTranscript: PropTypes.bool,
        isLive: PropTypes.bool,
        eventId: PropTypes.string.isRequired,
        overThreshold: PropTypes.bool,
        price: PropTypes.string,
        priceMovementPercent: PropTypes.number,
        priceMovementAbsolute: PropTypes.number,
        removeEvent: PropTypes.func.isRequired,
        selected: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        company: null,
        exchangeName: null,
        hasTranscript: false,
        isLive: false,
        overThreshold: false,
        price: undefined,
        priceMovementAbsolute: undefined,
        priceMovementPercent: undefined,
        selected: false,
        styles: {},
        ticker: null,
        title: null
    };

    constructor(props) {
        super(props);

        this.toggleEvent = this.toggleEvent.bind(this);
    }

    toggleEvent(e) {
        const { addEvent, eventId, title, removeEvent, selected } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (!this.toggling) {
            this.toggling = true;
            if (selected) {
                removeEvent({ eventId, title }).finally(() => {
                    this.toggling = false;
                });
            } else {
                addEvent({ eventId, title }).finally(() => {
                    this.toggling = false;
                });
            }
        }
    }

    render() {
        const {
            company,
            date,
            displayMode,
            exchangeName,
            hasTranscript,
            isLive,
            overThreshold,
            price,
            priceMovementPercent,
            priceMovementAbsolute,
            selected,
            styles,
            ticker,
            title
        } = this.props;
        return (
            <LiveEventRowUI
                company={company}
                date={date}
                displayMode={displayMode}
                exchangeName={exchangeName}
                hasTranscript={hasTranscript}
                isLive={isLive}
                overThreshold={overThreshold}
                price={price}
                priceMovementAbsolute={priceMovementAbsolute}
                priceMovementPercent={priceMovementPercent}
                selected={selected}
                styles={styles}
                ticker={ticker}
                title={title}
                toggleEvent={this.toggleEvent}
            />
        );
    }
}

export const LiveEventRowContainer = compose(withData())(LiveEventRow);
