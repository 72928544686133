export const styleSheet = theme => ({
    container: {
        borderBottom: `1px dashed ${theme.colors.gray04}`
    },
    tooltip: {
        width: '205px',
        color: theme.colors.white01,
        backgroundColor: 'rgba(16, 4, 32, 0.6)',
        whiteSpace: 'normal',
        borderRadius: 6,
        lineHeight: '1.1em',
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)'
    },
    toolTipText: {
        whiteSpace: 'pre-wrap',
        padding: `${theme.margins.margin00} 12px`
    },
    toolTipHeader: {
        padding: `${theme.margins.margin00} 12px`,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        justifyContent: 'space-between'
    }
});
