export const styleSheet = theme => ({
    loadingSmallText: {
        height: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.07)',
        borderRadius: '6px',
        marginRight: theme.margins.margin00,
        ...theme.content.flickerAnimation
    },
    loadingMediumText: {
        height: '15px',
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
        borderRadius: '6px',
        ...theme.content.flickerAnimation
    }
});
