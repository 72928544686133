import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { generateTabURL } from 'utils';
import { popoutManager } from 'utils/popout';
import { withUrlContext } from 'hoc/url';
import { StreetAccountCardUI } from './ui';

export class StreetAccountCard extends PureComponent {
    static displayName = 'StreetAccountCardContainer';

    static propTypes = {
        bookmarkId: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any),
        company: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        highlights: PropTypes.string,
        id: PropTypes.string.isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        ticker: PropTypes.string,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        bookmarkId: undefined,
        categories: [],
        company: undefined,
        equities: [],
        highlights: undefined,
        score: undefined,
        styles: {},
        streamId: null,
        ticker: undefined
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        if (e.shiftKey) {
            this.handlePopout(e);
        }
    }

    handlePopout(e) {
        const { id, streamId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generateTabURL({
                pathname: generatePath(routes.popoutStreetAccount, {
                    type: 'streetaccount',
                    streetAccountId: id
                }),
                streetAccountId: id,
                match: !!streamId,
                streamId
            }),
            name: `popout-sa-${id}`,
            width: 750
        });
    }

    render() {
        const {
            bookmarkId,
            categories,
            company,
            displayMode,
            equities,
            highlights,
            id,
            matchId,
            pathname,
            publishedDate,
            score,
            styles,
            streamId,
            ticker,
            title
        } = this.props;
        return (
            <StreetAccountCardUI
                bookmarkId={bookmarkId}
                categories={categories}
                company={company}
                displayMode={displayMode}
                equities={equities}
                highlights={highlights}
                id={id}
                matchId={matchId}
                onClick={this.handleClick}
                publishedDate={new XDate(publishedDate)}
                score={score}
                streamId={streamId}
                styles={styles}
                ticker={ticker}
                title={title}
                to={generateTabURL({ pathname, streetAccountId: id, streamId, match: !!streamId })}
            />
        );
    }
}

export const StreetAccountCardContainer = compose(withUrlContext(['pathname']))(StreetAccountCard);
