export const styleSheet = theme => ({
    container: {},
    resultsLoading: {
        margin: 'auto'
    },
    resultsHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '5px 0'
    },
    resultsHeader: {
        color: theme.colors.gray04,
        fontSize: theme.fonts.fontSizes.size00,
        letterSpacing: '1px'
    },
    resultsHeaderSelected: {
        backgroundColor: theme.colors.gray04,
        borderRadius: '10px',
        color: theme.colors.white01,
        fontWeight: theme.fonts.fontWeights.medium,
        marginLeft: '5px',
        padding: '2px 4px 2px 5px'
    },
    resultsContainer: {
        border: `1px solid ${theme.colors.gray02}`,
        borderBottom: 0,
        borderRadius: '3px 3px 0 0',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 300,
        minHeight: 200,
        overflow: 'scroll',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            maxHeight: 200
        }
    },
    result: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: `1px solid ${theme.colors.gray01}`,
        padding: `calc(${theme.margins.margin00} + 5px)`,
        ':last-of-type': {
            borderBottom: 'none'
        }
    },
    resultRow: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    resultRowSearch: {
        borderBottom: 0
    },
    resultRowListItem: {
        marginTop: `calc(${theme.margins.margin00} + 5px)`
    },
    resultRowIdentifier: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '80px'
    },
    resultRowExchange: {
        color: theme.colors.gray04,
        marginLeft: theme.margins.margin01,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100px'
    },
    resultRowName: {
        color: theme.colors.gray04,
        margin: `0 ${theme.margins.margin01}`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '270px'
    },
    resultRowNoMatch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.colors.gray01}`,
        height: '46px',
        padding: `7px calc(${theme.margins.margin00} + 5px)`
    },
    resultRowRequested: {
        padding: '16px 15px'
    },
    noMatchIdentifier: {
        color: theme.colors.red01,
        letterSpacing: '1px'
    },
    requestedIdentifier: {
        color: theme.colors.green02,
        fontWeight: theme.fonts.fontWeights.medium
    },
    requestedText: {
        color: theme.colors.green02,
        marginLeft: theme.margins.margin02
    },
    invalidText: {
        color: theme.colors.red01
    },
    requestIdentifierContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    requestIdentifierInput: {
        input: {
            width: '150px',
            height: '30px',
            fontSize: theme.fonts.fontSizes.size02,
            margin: 0,
            padding: `0 ${theme.margins.margin02} 0 ${theme.margins.margin00}`,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap'
        },
        'div:first-of-type': {
            height: '30px',
            right: `calc(${theme.margins.margin00} - 3px)`
        },
        svg: {
            width: '14px',
            height: '14px'
        }
    },
    requestIdentifierButton: {
        justifyContent: 'center',
        marginLeft: theme.margins.margin00,
        width: '80px'
    },
    requestIdentifierButtonText: {
        fontSize: theme.fonts.fontSizes.size01,
        letterSpacing: '1px'
    },
    addIdentifierContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white02,
        border: `1px solid ${theme.colors.gray02}`,
        borderRadius: '0 0 3px 3px',
        boxShadow: '0 -3px 5px 0 rgba(0, 0, 0, 0.03)',
        height: '50px',
        paddingRight: theme.margins.margin01
    },
    addIdentifierInputContainer: {
        width: '100%'
    },
    addIdentifierInput: {
        input: {
            backgroundColor: theme.colors.white02,
            boxShadow: 'unset',
            height: '48px',
            margin: 0,
            fontSize: theme.fonts.fontSizes.size03,
            ':focus': {
                backgroundColor: 'unset',
                boxShadow: 'unset'
            }
        },
        ':hover': {
            'input:not(:focus)': {
                boxShadow: 'unset'
            }
        }
    },
    searchLoading: {
        width: '50px'
    },
    searchResultsWrapper: {
        position: 'relative',
        width: '100%'
    },
    searchResultsContainer: {
        position: 'absolute',
        backgroundColor: theme.colors.white02,
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: `0 0 2px 2px`,
        marginTop: '-2px',
        minHeight: '48px',
        maxHeight: '120px',
        width: '100%',
        overflow: 'scroll',
        zIndex: 1
    },
    noResultsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 15px'
    },
    noResultsContainerRequested: {
        padding: '16px 15px'
    },
    noResults: {
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`
    },
    bold: {
        fontWeight: theme.fonts.fontWeights.medium
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        flexGrow: 0,
        flexShrink: 0,
        margin: `0 calc(${theme.margins.margin00} - 5px) 0 auto`,
        paddingTop: '1px',
        height: '14px',
        width: '14px'
    },
    checked: {
        background: theme.colors.gradient04,
        boxShadow: 'none'
    },
    unchecked: {
        backgroundColor: theme.colors.white01,
        boxShadow: `inset 0 0 0 1px ${theme.colors.gray01}`
    },
    faded: {
        color: theme.colors.gray04
    },
    error: {
        margin: 0
    },
    loading: {
        margin: 'auto'
    }
});
