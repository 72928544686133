import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class MultiSelect extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        label: PropTypes.string,
        disabled: PropTypes.bool.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        label: undefined
    };

    render() {
        const { styles, passedStyles, children, disabled, label } = this.props;
        const containerStyles = disabled
            ? { ...styles.container, ...styles.disabled, ...passedStyles }
            : { ...styles.container, ...passedStyles };
        return (
            <Fragment>
                {label && label.length > 0 && (
                    <Div style={styles.labelContainer}>
                        <Text label uppercase styles={styles.label}>
                            {label}
                        </Text>
                    </Div>
                )}
                <Div styles={containerStyles}>{children}</Div>
            </Fragment>
        );
    }
}

export const MultiSelectUI = compose(withStyleSheet(styleSheet))(MultiSelect);
