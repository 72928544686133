import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { AskAieraQuestionCard } from 'components/AskAieraQuestionCard';
import { LoaderLogo } from 'components/LoaderLogo';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class AskAieraEventQuestions extends PureComponent {
    static propTypes = {
        eventId: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(PropTypes.any).isRequired,
        loading: PropTypes.bool.isRequired,
        loadingPdf: PropTypes.bool.isRequired,
        loadingWord: PropTypes.bool.isRequired,
        onDownloadPdf: PropTypes.func.isRequired,
        onDownloadWord: PropTypes.func.isRequired,
        onSelectAnswer: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        onSelectAnswer: undefined
    };

    render() {
        const {
            eventId,
            loading,
            loadingPdf,
            loadingWord,
            questions,
            onDownloadPdf,
            onDownloadWord,
            onSelectAnswer,
            styles,
            theme
        } = this.props;
        return (
            <Div styles={styles.allQuestions}>
                {loading && (!questions || questions.length === 0) ? (
                    <Div styles={styles.questionsEmpty}>
                        <LoaderLogo height={60} />
                    </Div>
                ) : questions.length === 0 ? (
                    <Div styles={styles.questionsEmpty}>
                        <Text size={3}>No Questions Asked</Text>
                    </Div>
                ) : (
                    <Fragment>
                        <Div styles={styles.questionsHeader}>
                            <Text size={1} uppercase weight="medium" styles={styles.yourQuestions}>
                                Your Questions
                            </Text>
                            <Div styles={styles.export}>
                                <Div onClick={loadingPdf ? undefined : () => onDownloadPdf(styles)}>
                                    <Icon type="pdf" color={loadingPdf ? theme.colors.gray04 : theme.colors.gray06} />
                                </Div>
                                <Div onClick={loadingWord ? undefined : onDownloadWord}>
                                    <Icon
                                        type="doc"
                                        color={loadingWord ? theme.colors.gray04 : theme.colors.gray06}
                                        styles={{ width: 16, height: 14, marginLeft: 6 }}
                                    />
                                </Div>
                            </Div>
                        </Div>
                        {questions.map(q => {
                            const { question, citations, answer, id, isTemplate, autoQuestion, feedback } = q;
                            const selectQ = onSelectAnswer ? () => onSelectAnswer(q) : undefined;
                            return (
                                <AskAieraQuestionCard
                                    key={id}
                                    eventId={eventId}
                                    id={id}
                                    answer={answer}
                                    autoQuestion={autoQuestion}
                                    citations={citations}
                                    isTemplate={isTemplate}
                                    feedback={feedback}
                                    question={question}
                                    onSelectAnswer={selectQ}
                                />
                            );
                        })}
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const AskAieraEventQuestionsUI = compose(withStyleSheet(styleSheet))(AskAieraEventQuestions);
