import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PhoneNumberInputUI } from './ui';

export class PhoneNumberInput extends PureComponent {
    static displayName = 'PhoneNumberInputContainer';

    static propTypes = {
        autoComplete: PropTypes.string,
        defaultCountry: PropTypes.string,
        error: PropTypes.string,
        helpElement: PropTypes.objectOf(PropTypes.any),
        label: PropTypes.string,
        localStorageKey: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        autoComplete: undefined,
        defaultCountry: undefined,
        error: undefined,
        helpElement: undefined,
        label: undefined,
        localStorageKey: undefined,
        name: undefined,
        placeholder: undefined,
        styles: undefined,
        value: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            formatNumber: null,
            ReactPhoneInput: null
        };
    }

    // Dynamically import the PhoneInput component and formatPhoneNumber util and set it in state
    componentDidMount() {
        // eslint-disable-next-line import/no-extraneous-dependencies
        import('react-phone-number-input').then(({ default: ReactPhoneInput, formatPhoneNumber }) => {
            this.setState({
                formatNumber: formatPhoneNumber,
                ReactPhoneInput
            });
        });
    }

    render() {
        const { formatNumber, ReactPhoneInput } = this.state;
        const {
            autoComplete,
            defaultCountry,
            error,
            helpElement,
            label,
            localStorageKey,
            name,
            onChange,
            placeholder,
            styles,
            value
        } = this.props;
        return (
            <PhoneNumberInputUI
                autoComplete={autoComplete}
                defaultCountry={defaultCountry}
                error={error}
                formatNumber={formatNumber}
                helpElement={helpElement}
                label={label}
                localStorageKey={localStorageKey}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                ReactPhoneInput={ReactPhoneInput}
                styles={styles}
                value={value}
            />
        );
    }
}

export const PhoneNumberInputContainer = compose()(PhoneNumberInput);
