import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { get } from 'utils';
import { ContentTabsUI } from './ui';

export class ContentTabs extends PureComponent {
    static displayName = 'ContentTabsContainer';

    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedRef: PropTypes.func,
        selectedOption: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedRef: undefined,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.setOptionRef = this.setOptionRef.bind(this);

        this.state = {
            left: 0,
            width: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { options, selectedOption } = this.props;
        const { selectedOption: prevSelectedOption } = prevProps;
        if (selectedOption !== prevSelectedOption) {
            const hasSelectedOption = options.some(option => get(option, 'value', option) === selectedOption);

            // If no option selected, hide selected indicator
            if (!hasSelectedOption) {
                this.setState({
                    left: 0,
                    width: 0
                });
            }
        }
    }

    setOptionRef(node, option) {
        if (node && option) {
            const { selectedOption } = this.props;
            const { left, width } = this.state;
            if (option === selectedOption && (node.offsetLeft !== left || node.offsetWidth !== width)) {
                this.setState({
                    left: node.offsetLeft,
                    width: node.offsetWidth
                });
            }
        }
    }

    render() {
        const { styles, onSelect, options, passedRef, selectedOption } = this.props;
        const { left, width } = this.state;
        return (
            <ContentTabsUI
                passedRef={passedRef}
                left={left}
                onSelect={onSelect}
                options={options}
                selectedOption={selectedOption}
                setOptionRef={this.setOptionRef}
                styles={styles}
                width={width}
            />
        );
    }
}

export const ContentTabsContainer = compose()(ContentTabs);
