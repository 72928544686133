import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import XDate from 'xdate';
import { compose, withPropsOnChange } from 'recompose';
import { equityFinancialFragment } from 'graphql/fragments/equities';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { formatFinancial, get, getNativePrice } from 'utils';

const CATEGORY_MAP = {
    KPIs: 'KPIS',
    'Income Statement': 'Income Statement',
    'Cash Flow Statement': 'Cash Flow',
    'Balance Sheet': 'Balance Sheet'
};
const CURRENT_YEAR = new XDate().getFullYear();
const DISPLAY_UNITS_MAP = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B'
};

function formatPrice(category, format, currency, amount, displayUnits) {
    // Convert values of type currency to be in the financial item's display units
    const value = displayUnits && format === 'currency' ? amount / displayUnits : amount;
    if (format === 'currency') {
        // For metrics with displayUnits, we want to use the raw value since we already do the conversion above
        return displayUnits
            ? `${getNativePrice({
                  currency,
                  decimals: 0,
                  doNotAbbreviate: true,
                  postFix: category === 'KPIS' ? DISPLAY_UNITS_MAP[displayUnits] : undefined,
                  price: value,
                  raw: true,
                  useParentheses: true
              })}`
            : `${getNativePrice({ currency, price: value, raw: false, useParentheses: true })}`;
    }
    if (format === 'percentage') {
        return formatFinancial('percentage', value);
    }
    return formatFinancial('number', value);
}

function normalizeFinancials(financials, currentCategory, currency) {
    if (!financials) return null;
    const quarters = [];
    const rows = [];
    financials
        .filter(({ category }) => CATEGORY_MAP[currentCategory] === category)
        .forEach(financial => {
            const ret = { ...financial };
            let priorYearActuals = [];
            let currentYearActuals = [];
            let quarterlyEstimates = [];
            let priorYearEndActual = null;
            let currentYearEndActual = null;
            let annualEstimate = null;
            get(financial, 'values', []).forEach(value => {
                const val = formatPrice(
                    financial.category,
                    financial.format,
                    value.currency || currency,
                    value.value,
                    financial.displayUnits
                );
                // Prior and current year actuals
                // Only include quarterly financials for Balance Sheet items
                if (value.type === 'actual') {
                    if (CURRENT_YEAR - 1 === value.year) {
                        if (value.quarter === 0) {
                            if (financial.category !== 'Balance Sheet') {
                                priorYearEndActual = {
                                    ...value,
                                    label: `${value.year}`,
                                    value: val
                                };
                            }
                        } else {
                            priorYearActuals.push({
                                ...value,
                                label: `${value.quarter}Q'${value.year - 2000}`,
                                value: val
                            });
                        }
                    } else if (value.quarter === 0) {
                        if (financial.category !== 'Balance Sheet') {
                            currentYearEndActual = {
                                ...value,
                                label: `${value.year}`,
                                value: val
                            };
                        }
                    } else {
                        currentYearActuals.push({
                            ...value,
                            label: `${value.quarter}Q'${value.year - 2000}`,
                            value: val
                        });
                    }
                } else if (value.type === 'consensus') {
                    // Estimates
                    if (value.quarter === 0) {
                        annualEstimate = {
                            ...value,
                            label: `${value.year}E`,
                            value: val
                        };
                    } else {
                        quarterlyEstimates.push({
                            ...value,
                            label: `${value.quarter}Q'${value.year - 2000}E`,
                            value: val
                        });
                    }
                }
            });
            // Sort by quarter in ascending order
            priorYearActuals = sortBy(priorYearActuals, 'quarter');
            currentYearActuals = sortBy(currentYearActuals, 'quarter');
            quarterlyEstimates = sortBy(quarterlyEstimates, 'quarter');
            // Combine the quarterly and annual actuals, as well as the estimates
            ret.actuals = [
                ...priorYearActuals,
                ...currentYearActuals,
                ...quarterlyEstimates,
                priorYearEndActual,
                currentYearEndActual,
                financial.category === 'Balance Sheet'
                    ? { ...annualEstimate, label: `4Q'${CURRENT_YEAR - 2000}E` }
                    : annualEstimate
            ].filter(a => a);
            // Append labels from actuals and estimates to list of quarters
            ret.actuals.forEach(({ label, quarter, year, type }) =>
                quarters.push({
                    isAnnual: quarter === 0,
                    isEstimate: type === 'consensus',
                    label,
                    quarter,
                    year
                })
            );
            rows.push(ret);
        });
    return {
        financials: groupBy(sortBy(rows, 'ordering'), ({ subsection }) => subsection),
        quarters: orderBy(
            uniqBy(quarters, 'label'),
            ['isAnnual', 'isEstimate', 'year', 'quarter'],
            ['asc', 'asc', 'asc', 'asc']
        ).map(({ label }) => label)
    };
}

export const withData = () =>
    compose(
        graphql(
            gql`
                query withEquityFinancialsModal($equityId: ID) {
                    equities(equityIds: [$equityId]) {
                        id
                        commonName
                        financials {
                            ...equityFinancial
                            currency {
                                id
                                currencyCode
                                symbol
                                symbolPrefix
                                minorSymbol
                                minorSymbolPrefix
                            }
                            values {
                                date
                                equityId
                                key
                                quarter
                                type
                                value
                                year
                            }
                        }
                    }
                }
                ${equityFinancialFragment}
            `,
            {
                props: ({ data }) => {
                    const equity = get(data, 'equities[0]');
                    return {
                        equity,
                        financials: get(equity, 'financials'),
                        loading: get(data, 'loading')
                    };
                },
                skip: ({ equityId }) => !equityId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withMemo({ normalizeFinancials }),
        withPropsOnChange(
            ['currentTab', 'financials'],
            ({ currentTab, currency, financials, normalizeFinancials: normalize }) => ({
                ...normalize(financials, currentTab, currency)
            })
        )
    );
