import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { config } from 'configuration';
import { withUpdateStreamUxPreferences } from 'graphql/streams';
import { routes } from 'routes';
import { copyToClipboard, generateModalId } from 'utils';
import { withUrlContext } from 'hoc/url';
import { Scroller } from 'utils/scroll';
import { StreamUI } from './ui';

const MIN_STREAM_WIDTH = 294;
const MAX_STREAM_WIDTH = 588;

export class Stream extends PureComponent {
    static displayName = 'StreamContainer';

    static propTypes = {
        averageDailyVolume: PropTypes.number,
        className: PropTypes.string,
        color: PropTypes.string,
        componentVisibilityRef: PropTypes.objectOf(PropTypes.any),
        dashboardId: PropTypes.string,
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        dashEquityScope: PropTypes.arrayOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        disableResize: PropTypes.bool,
        disableScrollButton: PropTypes.bool,
        dragHandleProps: PropTypes.objectOf(PropTypes.any),
        draggableProps: PropTypes.objectOf(PropTypes.any),
        draggableRef: PropTypes.func,
        EmptyComponent: PropTypes.objectOf(PropTypes.any),
        emptyText: PropTypes.string,
        hasFilters: PropTypes.bool,
        headerDisabled: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        hoverStyles: PropTypes.objectOf(PropTypes.any),
        invert: PropTypes.bool,
        lenses: PropTypes.arrayOf(PropTypes.any),
        loadMoreMatches: PropTypes.func,
        loaderElement: PropTypes.element,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        offset: PropTypes.number,
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        onResizeEnd: PropTypes.func,
        onResizeStart: PropTypes.func,
        onScrollEnd: PropTypes.func,
        renderHeader: PropTypes.func,
        renderResultsHeader: PropTypes.func,
        renderToolbar: PropTypes.func,
        saveStreamUXPreference: PropTypes.func.isRequired,
        scrollButtonDisabled: PropTypes.bool,
        searchTerms: PropTypes.arrayOf(PropTypes.string),
        streamId: PropTypes.string,
        streamType: PropTypes.string,
        streamRef: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        title: PropTypes.string,
        uxPreferences: PropTypes.objectOf(PropTypes.any),
        wasVisible: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        averageDailyVolume: undefined,
        className: undefined,
        color: undefined,
        componentVisibilityRef: undefined,
        dashboardId: null,
        dashDateRange: null,
        dashSearchTerm: null,
        dashEquityScope: null,
        disableResize: false,
        disableScrollButton: false,
        dragHandleProps: {},
        draggableProps: {},
        draggableRef: undefined,
        EmptyComponent: undefined,
        emptyText: undefined,
        hasFilters: false,
        headerDisabled: false,
        hoverStyles: {},
        invert: false,
        lenses: [],
        loadMoreMatches: undefined,
        loaderElement: undefined,
        loading: false,
        offset: 0,
        onEdit: null,
        onRemove: undefined,
        onResizeEnd: undefined,
        onResizeStart: undefined,
        onScrollEnd: undefined,
        renderHeader: null,
        renderResultsHeader: null,
        renderToolbar: null,
        scrollButtonDisabled: false,
        searchTerms: [],
        streamId: null,
        streamType: null,
        streamRef: undefined,
        subtitle: undefined,
        title: undefined,
        uxPreferences: {},
        wasVisible: false,
        width: MIN_STREAM_WIDTH
    };

    constructor(props) {
        super(props);

        this.copySearchTerms = this.copySearchTerms.bind(this);
        this.exportEvents = this.exportEvents.bind(this);
        this.exportMatches = this.exportMatches.bind(this);
        this.hideEditIcon = this.hideEditIcon.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onScrollEnd = this.onScrollEnd.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onResizeEnd = this.onResizeEnd.bind(this);
        this.onResizeStart = this.onResizeStart.bind(this);
        this.shareStream = this.shareStream.bind(this);
        this.showEditIcon = this.showEditIcon.bind(this);
        this.onScrollToEnd = this.onScrollToEnd.bind(this);
        this.checkScroll = this.checkScroll.bind(this);

        this.hasMoreResults = true;
        this.scroller = new Scroller();
        this.scrollRef = createRef();
        this.resizing = false;

        this.state = {
            copied: false,
            menuIsFocused: false,
            menuIsOpen: false,
            loadingMoreMatches: false,
            showScrollBtn: false,
            searchTermsCopied: false,
            width: props.width
        };
    }

    componentDidMount() {
        const { streamRef, disableScrollButton, invert } = this.props;
        this.scroller.setScrollContainer(this.scrollRef.current);
        this.scroller.on(invert ? 'scrollTop' : 'scrollBottom', this.onScrollEnd);
        if (invert) {
            this.scroller.startAutoScroll();
            this.scroller.scrollToBottom();
        }
        if (!disableScrollButton) {
            this.scroller.on('scroll', this.checkScroll);
        }
        if (streamRef && !streamRef.current) {
            streamRef.current = this;
        }
    }

    componentDidUpdate({ loading: prevLoading, invert: prevInvert, streamRef: prevStreamRef }) {
        const { loading, invert, streamRef } = this.props;
        // Reset hasMoreResults any time we're loading data from the network again.
        if (loading && !prevLoading) {
            this.hasMoreResults = true;
        }
        if (((!loading && prevLoading) || prevInvert !== invert) && invert) {
            this.initialScroll = true;
            this.scroller.startAutoScroll();
            this.scroller.scrollToBottom();
        }
        if (prevInvert !== invert && !invert) {
            this.initialScroll = false;
            this.scroller.stopAutoScroll();
            this.scroller.scrollToTop();
        }

        if (!prevStreamRef && streamRef) {
            streamRef.current = this;
        }
    }

    componentWillUnmount() {
        this.scroller.cleanup();
        clearTimeout(this.shareTimeout);
        window.removeEventListener('mousemove', this.onResize);
        window.removeEventListener('mouseup', this.onResizeEnd);
        if (this.resizeTimeout) {
            cancelAnimationFrame(this.resizeTimeout);
            this.resizeTimeout = null;
        }
    }

    copySearchTerms() {
        const { searchTerms } = this.props;

        if (searchTerms && searchTerms.length > 0) {
            copyToClipboard(searchTerms.join(', '));
            this.setState(
                {
                    searchTermsCopied: true
                },
                () => setTimeout(() => this.setState({ searchTermsCopied: false }), 3000)
            );
        }
    }

    checkScroll() {
        const { invert, streamType, streamId, title, scrollButtonDisabled } = this.props;
        const { showScrollBtn } = this.state;
        if (!scrollButtonDisabled) {
            window.requestAnimationFrame(() => {
                const diff = invert
                    ? this.scroller.scrollHeight - this.scroller.scrollTop - this.scroller.clientHeight
                    : this.scroller.scrollTop;

                if (!showScrollBtn && diff > 600) {
                    this.setState(
                        {
                            showScrollBtn: true
                        },
                        () => {
                            if (window.heap) {
                                window.heap.track('Stream Scroll', {
                                    streamType,
                                    streamId,
                                    streamTitle: title
                                });
                            }
                        }
                    );
                } else if (showScrollBtn && diff < 600) {
                    this.setState({
                        showScrollBtn: false
                    });
                }
            });
        }
    }

    exportEvents() {
        const { dashboardId, history, location, streamId } = this.props;
        history.push(generateModalId({ dashboardId, location, id: streamId, type: 'exportEvents' }));
    }

    exportMatches() {
        const { history, location, streamId, dashEquityScope, dashSearchTerm, lenses } = this.props;
        history.push(generateModalId({ location, id: streamId, type: 'streamMatches' }), {
            dashEquityScope,
            dashSearchTerm,
            lenses
        });
    }

    onScrollToEnd() {
        const { invert } = this.props;
        if (invert) {
            this.scroller.scrollToBottom({
                behavior: 'smooth'
            });
        } else {
            this.scroller.scrollToTop({
                behavior: 'smooth'
            });
        }
    }

    hideEditIcon() {
        this.setState({ menuIsFocused: false });
    }

    showEditIcon() {
        this.setState({ menuIsFocused: true });
    }

    hideMenu() {
        this.setState({ menuIsOpen: false });
    }

    loadMoreMatches() {
        const { invert, loadMoreMatches, offset } = this.props;
        const { loadingMoreMatches } = this.state;
        if (this.hasMoreResults && loadMoreMatches && !loadingMoreMatches) {
            const scrollDiff = this.scroller.scrollHeight - this.scroller.scrollTop;
            this.setState({ loadingMoreMatches: true }, () => {
                if (invert) {
                    this.scroller.scrollTo({ top: this.scroller.scrollHeight - scrollDiff });
                }
                return loadMoreMatches(offset)
                    .then(hasMoreResults => {
                        this.hasMoreResults = hasMoreResults;
                    })
                    .finally(() => {
                        this.setState({ loadingMoreMatches: false }, () => {
                            if (invert) {
                                this.scroller.scrollTo({ top: this.scroller.scrollHeight - scrollDiff });
                            }
                        });
                    });
            });
        }
    }

    shareStream() {
        const { streamId } = this.props;
        copyToClipboard(
            `${config.DASHBOARD_ENDPOINT.slice(0, -1)}${generatePath(routes.dashboardHome)}?sharedStreamId=${streamId}`
        );
        this.setState({ copied: true });
        clearTimeout(this.shareTimeout);
        this.shareTimeout = setTimeout(() => {
            this.setState({ copied: false });
        }, 2000);
    }

    onEdit(e) {
        e.preventDefault();
        e.stopPropagation();
        const { onEdit, streamId } = this.props;
        if (onEdit) onEdit(streamId);
    }

    onRemove(confirm = true) {
        const { onRemove, streamId } = this.props;
        if (onRemove) onRemove(streamId, confirm);
    }

    onScrollEnd() {
        const { onScrollEnd, invert } = this.props;
        if (!invert || this.initialScroll) {
            this.loadMoreMatches();
            if (onScrollEnd) {
                onScrollEnd();
            }
        }
    }

    onToggleMenu() {
        const { headerDisabled } = this.props;
        if (!headerDisabled) {
            clearTimeout(this.shareTimeout);
            this.setState(({ menuIsOpen }) => ({
                copied: false,
                menuIsOpen: !menuIsOpen
            }));
        }
    }

    onResize(e) {
        if (e.stopPropagation) e.stopPropagation();
        if (e.preventDefault) e.preventDefault();
        const { width } = this.props;
        const { width: stateWidth } = this.state;

        // Grabbing the distance from the initial click
        // X coordinates, to the current mouse event
        // X coordinates, and adding that to the original
        // stream width. Then updating the state value to
        // update the UI.
        const newWidth = width + e.x - this.resizeLocation;
        if (Math.abs(stateWidth - newWidth) > 3) {
            if (this.resizeTimeout) {
                cancelAnimationFrame(this.resizeTimeout);
                this.resizeTimeout = null;
            }

            this.resizeTimeout = requestAnimationFrame(() => {
                this.setState({
                    width:
                        newWidth > MAX_STREAM_WIDTH
                            ? MAX_STREAM_WIDTH
                            : newWidth < MIN_STREAM_WIDTH
                            ? MIN_STREAM_WIDTH
                            : newWidth
                });
            });
        }
    }

    onResizeEnd() {
        const { onResizeEnd, saveStreamUXPreference, streamId, uxPreferences } = this.props;
        window.removeEventListener('mousemove', this.onResize);
        window.removeEventListener('mouseup', this.onResizeEnd);
        if (onResizeEnd) onResizeEnd();
        if (saveStreamUXPreference) {
            const { width } = this.state;
            saveStreamUXPreference(streamId, { ...uxPreferences, width });
        }
    }

    onResizeStart(e) {
        const { onResizeStart } = this.props;
        this.resizeLocation = e.nativeEvent.x;
        window.addEventListener('mouseup', this.onResizeEnd);
        window.addEventListener('mousemove', this.onResize);
        if (onResizeStart) onResizeStart();
    }

    render() {
        const {
            averageDailyVolume,
            children,
            className,
            color,
            componentVisibilityRef,
            dashboardId,
            dashDateRange,
            disableResize,
            draggableProps,
            draggableRef,
            dragHandleProps,
            EmptyComponent,
            emptyText,
            hasFilters,
            headerDisabled,
            hoverStyles,
            loaderElement,
            loading,
            renderHeader,
            renderResultsHeader,
            renderToolbar,
            invert,
            searchTerms,
            streamId,
            streamType,
            styles,
            subtitle,
            title,
            wasVisible
        } = this.props;
        const {
            copied,
            searchTermsCopied,
            loadingMoreMatches,
            menuIsFocused,
            menuIsOpen,
            showScrollBtn,
            width
        } = this.state;
        return (
            <StreamUI
                averageDailyVolume={averageDailyVolume}
                className={className}
                color={color}
                copied={copied}
                copySearchTerms={this.copySearchTerms}
                componentVisibilityRef={componentVisibilityRef}
                dashboardId={dashboardId}
                dashDateRange={dashDateRange}
                disableResize={disableResize}
                draggableProps={draggableProps}
                draggableRef={draggableRef}
                dragHandleProps={dragHandleProps}
                EmptyComponent={EmptyComponent}
                emptyText={emptyText}
                exportEvents={this.exportEvents}
                exportMatches={this.exportMatches}
                hasFilters={hasFilters}
                headerDisabled={headerDisabled}
                hideEditIcon={this.hideEditIcon}
                hideMenu={this.hideMenu}
                hoverStyles={hoverStyles}
                loaderElement={loaderElement}
                loading={loading || loadingMoreMatches}
                menuIsFocused={menuIsFocused}
                menuIsOpen={menuIsOpen}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onResizeStart={this.onResizeStart}
                onToggleMenu={this.onToggleMenu}
                onScrollToEnd={this.onScrollToEnd}
                renderHeader={renderHeader}
                renderResultsHeader={renderResultsHeader}
                renderToolbar={renderToolbar}
                invert={invert}
                scrollRef={this.scrollRef}
                searchTerms={searchTerms}
                searchTermsCopied={searchTermsCopied}
                shareStream={this.shareStream}
                showEditIcon={this.showEditIcon}
                showTopScrollBtn={!invert && showScrollBtn}
                showBottomScrollBtn={invert && showScrollBtn}
                streamId={streamId}
                streamType={streamType}
                styles={styles}
                subtitle={subtitle}
                title={title}
                wasVisible={wasVisible}
                width={width}
            >
                {children}
            </StreamUI>
        );
    }
}

export const StreamContainer = compose(
    withUrlContext(['history', 'location']),
    withUpdateStreamUxPreferences()
)(Stream);
