import { compose, withPropsOnChange } from 'recompose';
import { withMemo } from 'hoc/utils';
import { get, getCompanyQuotes } from 'utils';

function normalizeCompanies(companies) {
    return (companies || []).map(company => {
        const instruments = get(company, 'instruments', []);
        const { primaryQuote } = getCompanyQuotes(instruments);
        return {
            commonName: get(company, 'commonName'),
            exchangeName: get(primaryQuote, 'exchangeName.shortName'),
            icon: get(company, 'iconUrl'),
            id: get(company, 'id'),
            localTicker: get(primaryQuote, 'localTicker')
        };
    });
}

export const withData = () =>
    compose(
        withMemo({ normalize: normalizeCompanies }),
        withPropsOnChange(['companies'], ({ companies, normalize }) => ({
            companies: normalize(companies)
        }))
    );
