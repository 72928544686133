import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { format as timeagoFormat } from 'timeago.js';

function format(date) {
    return timeagoFormat(date instanceof XDate ? date.toDate() : date);
}

export class TimeAgo extends PureComponent {
    static displayName = 'TimeAgoContainer';

    static propTypes = {
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)])
    };

    static defaultProps = {
        date: null
    };

    constructor(props) {
        super(props);

        this.update = this.update.bind(this);

        const { date } = props;
        this.state = {
            timeago: format(date)
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        const { date } = this.props;
        if (prevProps.date !== date) {
            this.update();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    update() {
        const { date } = this.props;

        this.setState({
            timeago: format(date)
        });
        clearTimeout(this.timeout);

        // Get number of seconds between now and the date we're working with
        const diff = Math.abs(new XDate().diffSeconds(date));
        // Default to every hour
        let next = 3600;
        if (diff <= 60) {
            // If less than a minute, run every second
            next = 1;
        } else if (diff <= 3600) {
            // if less than 1 hour, run every minute
            next = 60;
        } else if (diff <= 86400) {
            // if less than 24 hours, run every 30 minutes
            next = 1800;
        }
        this.timeout = setTimeout(this.update, next * 1000);
    }

    render() {
        const { children } = this.props;
        const { timeago } = this.state;
        return typeof children === 'function' ? children({ timeago }) : timeago;
    }
}

export const TimeAgoContainer = compose()(TimeAgo);
