import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { statusBannerHide } from 'actions/statusBanner';
import { StatusBannerUI } from './ui';

export class StatusBannerConnected extends Component {
    static displayName = 'StatusBannerContainer';

    static propTypes = {
        duration: PropTypes.number,
        isVisible: PropTypes.bool.isRequired,
        hideStatusBanner: PropTypes.func.isRequired
    };

    static defaultProps = {
        duration: 3000
    };

    constructor(props) {
        super(props);

        this.state = {
            showBanner: false
        };
    }

    componentDidUpdate(prevProps) {
        const { isVisible, duration, hideStatusBanner } = this.props;
        if (prevProps.isVisible !== isVisible) {
            // Clear existing timeout
            clearTimeout(this.hideBannerTimer);

            this.hideBannerTimer = setTimeout(() => {
                if (isVisible) {
                    hideStatusBanner();
                }
            }, duration);

            this.setState({ showBanner: isVisible });
        }
    }

    componentWillUnmount() {
        if (this.hideBannerTimer) clearTimeout(this.hideBannerTimer);
    }

    render() {
        const { ...rest } = this.props;
        const { showBanner } = this.state;

        // PUT OTHER PROPS AFTER {...rest}
        return <StatusBannerUI {...rest} visible={showBanner} />;
    }
}

const mapStateToProps = ({ StatusBanner }) => {
    const { text, icon, duration, visible, type } = StatusBanner;

    return {
        isVisible: visible,
        type,
        text,
        icon,
        duration
    };
};

const mapDispatchToProps = {
    hideStatusBanner: statusBannerHide
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(StatusBannerConnected);

export const StatusBannerContainer = compose()(connectedComponent);
