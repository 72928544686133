import { types } from 'actions/user';

const { USER_IS_IDLE, USER_LOAD, USER_LOGOUT } = types;

const initialState = {
    email: null,
    isAdmin: false,
    isAnalyst: false,
    isEditor: false,
    isIdle: false,
    name: null,
    phone: null,
    sessionId: null
};

export function userReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === USER_IS_IDLE) {
        return {
            ...state,
            ...payload
        };
    }

    if (type === USER_LOAD) {
        return {
            ...state,
            ...payload
        };
    }

    if (type === USER_LOGOUT) {
        return {
            ...initialState
        };
    }

    return state;
}
