import gql from 'graphql-tag';
import XDate from 'xdate';
import { compose, withPropsOnChange } from 'recompose';
import { withCreateBookmark, withDeleteBookmark, withUpdateBookmark } from 'graphql/bookmarks';
import { bookmarkFragment } from 'graphql/fragments/bookmarks';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';

// Format equities for EquityAutocomplete
function normalizeEquities(equities) {
    return (equities || []).map(equity => ({
        exchange: get(equity, 'exchange.shortName', ''),
        label: get(equity, 'localTicker'),
        value: get(equity, 'id')
    }));
}

function normalizeTarget(bookmarkId, target, targetType) {
    if (!target) return null;
    let dateProp;
    let titleProp = 'title';
    if (!!bookmarkId && bookmarkId !== 'new') {
        if (targetType === 'content') {
            dateProp = 'publishedDate';
        }
        if (targetType === 'event') {
            dateProp = 'callDate';
        }
        if (targetType === 'filing') {
            dateProp = 'filing.arrivalDate';
            titleProp = 'filing.form.formNameShort';
        }
        if (targetType === 'transcript') {
            dateProp = 'event.callDate';
            titleProp = 'event.title';
        }
    } else {
        if (targetType === 'content') dateProp = 'publishedDate';
        if (targetType === 'filing') dateProp = 'arrivalDate';
        if (targetType === 'transcript') dateProp = 'callDate';
    }
    return {
        id: get(target, 'id'),
        date: new XDate(get(target, dateProp, new Date())),
        hasUnknownTime: get(target, 'event.hasUnknownTime', false),
        title: get(target, titleProp)
    };
}

export const withData = () =>
    compose(
        withMemo({ normalizeEquities, normalizeTarget }),
        graphql(
            gql`
                query withBookmarkFormTarget(
                    $bookmarkId: ID
                    $eventId: ID
                    $targetId: ID
                    $withBookmark: Boolean!
                    $withContent: Boolean!
                    $withEvents: Boolean!
                    $withFilings: Boolean!
                ) {
                    bookmarks(filter: { bookmarkIds: [$bookmarkId] }) @include(if: $withBookmark) {
                        ...bookmark
                        equities {
                            id
                            localTicker
                            exchange {
                                id
                                shortName
                            }
                        }
                        target {
                            ... on Content {
                                id
                                publishedDate
                                title
                            }
                            ... on FilingContent {
                                filing {
                                    id
                                    arrivalDate
                                    form {
                                        id
                                        formNameShort
                                    }
                                }
                            }
                            ... on ScheduledAudioCall {
                                id
                                callDate
                                hasUnknownTime
                                title
                            }
                            ... on ScheduledAudioCallEvent {
                                id
                                event {
                                    id
                                    callDate
                                    hasUnknownTime
                                    title
                                }
                            }
                        }
                    }
                    content(filter: { contentIds: [$targetId] }) @include(if: $withContent) {
                        id
                        publishedDate
                        title
                    }
                    events(eventIds: [$eventId]) @include(if: $withEvents) {
                        id
                        callDate
                        hasUnknownTime
                        title
                    }
                    filings(filter: { filingIds: [$targetId] }) @include(if: $withFilings) {
                        id
                        arrivalDate
                        form {
                            id
                            formNameShort
                        }
                        title
                    }
                }
                ${bookmarkFragment}
            `,
            {
                props: ({ data, ownProps: { bookmarkId, targetType } }) => {
                    const bookmark = get(data, 'bookmarks[0]');
                    let target;
                    if (!!bookmarkId && bookmarkId !== 'new') {
                        target = get(bookmark, 'target');
                    } else {
                        if (targetType === 'content') target = get(data, 'content[0]');
                        if (targetType === 'filing') target = get(data, 'filings[0]');
                        if (targetType === 'transcript') target = get(data, 'events[0]');
                    }
                    return {
                        bookmark,
                        equities: get(bookmark, 'equities', []),
                        loading: get(data, 'loading'),
                        target
                    };
                },
                options: ({ bookmarkId, eventId, targetId, targetType }) => {
                    const withBookmark = !!bookmarkId && bookmarkId !== 'new';
                    return {
                        fetchPolicy: 'cache-and-network',
                        variables: {
                            bookmarkId,
                            eventId,
                            targetId,
                            withBookmark,
                            withContent: !withBookmark && targetType === 'content',
                            withEvents: !withBookmark && targetType === 'transcript',
                            withFilings: !withBookmark && targetType === 'filing'
                        }
                    };
                },
                skip: ({ bookmarkId, targetId, targetType }) =>
                    !bookmarkId || (bookmarkId === 'new' && (!targetId || !targetType))
            }
        ),
        withPropsOnChange(
            ['bookmarkId', 'target', 'targetType'],
            ({
                bookmark,
                bookmarkId,
                highlight,
                normalizeTarget: normalize,
                streamId,
                target,
                targetId,
                targetType
            }) => ({
                highlight: highlight || get(bookmark, 'highlight'),
                streamId: streamId || get(bookmark, 'targetStreamId'),
                target: normalize(bookmarkId, target, targetType),
                targetId: targetId || get(bookmark, 'targetId'),
                targetType: targetType || get(bookmark, 'targetType')
            })
        ),
        withPropsOnChange(['equities'], ({ equities, normalizeEquities: normalizedEquities }) => ({
            equities: normalizedEquities(equities)
        })),
        withCreateBookmark(),
        withDeleteBookmark(),
        withUpdateBookmark()
    );
