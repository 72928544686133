import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import XDate from 'xdate';
import { DashboardStatsChart } from 'components/DashboardStatsChart';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Heading } from 'components/Heading';
import { TextInput } from 'components/TextInput';
import { BoxTable } from 'components/BoxTable';
import { BoxTd } from 'components/BoxTd';
import { BoxTh } from 'components/BoxTh';
import { BoxTr } from 'components/BoxTr';
import { config } from 'configuration';
import { withStyleSheet } from 'hoc/styles';
import { get, numberWithCommas } from 'utils';
import { styleSheet } from './stylesheet';

const RANGE_MAP = {
    1: '1mos',
    3: '3mos',
    6: '6mos',
    13: '1yr'
};

class DashboardStats extends PureComponent {
    static propTypes = {
        chartData: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashboardId: PropTypes.string,
        dashDateFilter: PropTypes.arrayOf(PropTypes.any),
        filterMonths: PropTypes.number.isRequired,
        hasFilters: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        name: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        searchTerm: PropTypes.string,
        selectRange: PropTypes.func.isRequired,
        streamCounts: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        dashboardId: null,
        dashDateFilter: undefined,
        name: undefined,
        searchTerm: ''
    };

    renderStreams() {
        const { styles, theme, streamCounts, filterMonths, searchTerm, onSearch, dashDateFilter } = this.props;
        const streamRows = streamCounts.map(({ id, name, description, color, total }) => {
            return (
                <BoxTr styles={styles.streamRow} key={id}>
                    <BoxTd>
                        <Div styles={styles.streamName}>
                            {color && <Div styles={{ ...styles.dot, backgroundColor: color }} />}
                            <Text size={3} weight="medium">
                                {name}
                            </Text>
                        </Div>
                    </BoxTd>
                    <BoxTd styles={styles.streamTotal}>
                        <Text size={3}>{numberWithCommas(total)}</Text>
                    </BoxTd>
                    <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                        <BoxTd>
                            <Text styles={styles.streamRulesText} size={2}>
                                {description}
                            </Text>
                        </BoxTd>
                    </MediaQuery>
                </BoxTr>
            );
        });

        let matchesLabel = 'Matches';

        if (!dashDateFilter) {
            matchesLabel = `Matches (${RANGE_MAP[filterMonths]})`;
        }

        return (
            <Fragment>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m =>
                        m ? (
                            <Div styles={styles.mobileSearchContainer}>
                                <TextInput
                                    icon="search03"
                                    styles={styles.searchMobile}
                                    clearable
                                    placeholder="Filter matches further..."
                                    name="dashboardStatsSearch"
                                    value={searchTerm}
                                    onChange={onSearch}
                                />
                            </Div>
                        ) : (
                            <TextInput
                                icon="search03"
                                styles={styles.search}
                                clearable
                                placeholder="Filter matches further..."
                                name="dashboardStatsSearch"
                                value={searchTerm}
                                onChange={onSearch}
                            />
                        )
                    }
                </MediaQuery>
                <BoxTable styles={styles.streams}>
                    <BoxTr>
                        <BoxTh>saved search</BoxTh>
                        <BoxTh>{matchesLabel}</BoxTh>
                        <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                            <BoxTh>Search terms</BoxTh>
                        </MediaQuery>
                    </BoxTr>
                    {streamRows}
                </BoxTable>
            </Fragment>
        );
    }

    renderChart() {
        const { styles, selectRange, chartData, filterMonths, dashDateFilter } = this.props;
        return (
            <Div styles={styles.chart}>
                <DashboardStatsChart
                    dashDateFilter={dashDateFilter}
                    streams={chartData}
                    selectRange={selectRange}
                    filterMonths={filterMonths}
                />
            </Div>
        );
    }

    renderExport() {
        const { dashboardId, styles, theme, dashDateFilter } = this.props;
        let dateRange = '';
        if (dashDateFilter) {
            const startDate = new XDate(dashDateFilter[0]).toString('yyyy-MM-dd');
            const endDate = new XDate(dashDateFilter[1]).toString('yyyy-MM-dd');
            dateRange = `/${startDate}/${endDate}`;
        }
        return (
            <MediaQuery minWidth={theme.breakpoints.internal.mobile}>
                <ExternalLink
                    href={`${get(config, 'API_ENDPOINT')}/dashboards/${dashboardId}/equities/export${dateRange}`}
                    styles={styles.exportButton}
                    data-tname="monitor-stats-csv-export"
                >
                    <Text size={1}>Export Data</Text>
                    <Icon type="csv" color={theme.colors.gray06} />
                </ExternalLink>
            </MediaQuery>
        );
    }

    render() {
        const {
            chartData,
            hasFilters,
            isEmpty,
            isOpen,
            loading,
            name,
            onClose,
            streamCounts,
            styles,
            theme,
            searchTerm
        } = this.props;
        return (
            <Modal isOpen={isOpen} onModalClose={onClose} title={name} useShell styles={styles.container}>
                <Div styles={styles.header} className="modalHeader">
                    <Div>
                        <Heading size={1}>{name}</Heading>
                    </Div>
                    {name && (searchTerm || hasFilters) && (
                        <Text size={1} styles={styles.filteredBy}>
                            {searchTerm ? `Filtered by "${searchTerm}"` : 'Filtered by Monitor'}
                        </Text>
                    )}
                    <Div styles={styles.spacer} />
                    <Div styles={styles.closeModal} onClick={onClose}>
                        <Icon type="xMark" color={theme.colors.black01} />
                    </Div>
                </Div>
                {loading && chartData.length === 0 && streamCounts.length === 0 && (
                    <Div styles={styles.loading}>
                        <LoaderLogo height={60} />
                    </Div>
                )}
                {isEmpty || (!loading && !chartData.length && !streamCounts.length) ? (
                    <Div styles={styles.noStreamsContainer}>
                        <Text size={3}>
                            Create saved &quot;Content&ldquo; keyword searches
                            <br />
                            to view a match count comparison.
                        </Text>
                    </Div>
                ) : (
                    <Div styles={styles.content}>
                        {this.renderExport()}
                        {chartData.length > 0 && this.renderChart()}
                        {streamCounts.length > 0 && this.renderStreams()}
                    </Div>
                )}
                {loading && streamCounts.length > 0 && (
                    <Div styles={styles.statsLoading}>
                        <Text size={3}>Fetching data...</Text>
                        <LoaderLogo type={2} height={60} />
                    </Div>
                )}
            </Modal>
        );
    }
}

export const DashboardStatsUI = compose(withStyleSheet(styleSheet))(DashboardStats);
