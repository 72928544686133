import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get, getCompanyQuotes } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withFiling($filingId: ID!, $streamId: ID, $includeHighlights: Boolean = false) {
                    content(filter: { contentIds: [$filingId] }) {
                        __typename
                        id
                        title
                        bodyWithHighlights: body(allowRaw: true, highlight: { streamId: $streamId })
                            @include(if: $includeHighlights)
                        body(allowRaw: true) @skip(if: $includeHighlights)
                        contentType
                        displayType
                        publishedDate
                        primaryCompany {
                            id
                            commonName
                            iconUrl
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        events {
                            id
                            callDate
                            title
                        }
                        userSettings {
                            id
                            archived
                            isRead
                            starred
                            tags
                        }
                        tags {
                            tag
                            users {
                                id
                                username
                            }
                        }
                        ... on FilingContent {
                            pdfUrl
                            filing {
                                id
                                form {
                                    id
                                    formName
                                    formNameShort
                                    formNumber
                                }
                                periodEndDate
                                releaseDate
                                arrivalDate
                                isAmendment
                                officialUrl
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const content = get(data, 'content[0]');
                    const filing = get(content, 'filing');
                    const primaryCompany = get(content, 'primaryCompany');
                    const instruments = get(primaryCompany, 'instruments', []);
                    const { primaryQuote } = getCompanyQuotes(instruments);
                    return {
                        arrivalDate: get(filing, 'arrivalDate'),
                        body: get(content, 'bodyWithHighlights', get(content, 'body')),
                        pdfUrl: get(content, 'pdfUrl'),
                        company: get(primaryCompany, 'commonName'),
                        companyId: get(primaryCompany, 'id'),
                        contentType: get(content, 'contentType'),
                        displayType: get(content, 'displayType'),
                        events: get(content, 'events'),
                        exchangeName: get(primaryCompany, 'exchange.shortName'),
                        isAmendment: get(filing, 'isAmendment'),
                        isRead: get(content, 'userSettings.isRead', false),
                        isArchived: get(content, 'userSettings.archived', false),
                        isStarred: get(content, 'userSettings.starred', false),
                        filingError: !!get(data, 'error'),
                        filingLoading: get(data, 'loading'),
                        formName: get(filing, 'form.formName'),
                        formNumber: get(filing, 'form.formNumber'),
                        formShortName: get(filing, 'form.formNameShort'),
                        officialUrl: get(filing, 'officialUrl'),
                        periodEndDate: get(filing, 'periodEndDate'),
                        publishedDate: get(content, 'content.publishedDate'),
                        tags: get(content, 'tags', []),
                        ticker: get(primaryQuote, 'localTicker'),
                        title: get(content, 'title'),
                        userTags: get(content, 'userSettings.tags', [])
                    };
                },
                options: ({ filingId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        filingId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
