import gql from 'graphql-tag';
import { compose, lifecycle, withProps } from 'recompose';
import { EVENT_LOG_STATES, EVENT_LOG_STATE_TYPES } from 'consts';
import { withDisconnectPrivateRecording, withEventLogs } from 'graphql/audioCalls';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';

const IGNORED_STATE_TYPES = [EVENT_LOG_STATE_TYPES.conference];

function normalizeLogs(logs, logHistory) {
    let currentState = null;
    let hasTranscript = false;
    if (logs && logs.length) {
        const filteredLogs = logs.filter(log => !IGNORED_STATE_TYPES.includes(log.stateType));
        filteredLogs.forEach((log, idx) => {
            if (log.state === EVENT_LOG_STATES.completed && log.stateType === EVENT_LOG_STATE_TYPES.firstTranscript) {
                hasTranscript = true;
            }
            // Set the last state as current
            if (idx === filteredLogs.length - 1) {
                currentState = log;
            }
        });
    }
    // Check logs history to see if we received any transcript items
    if (
        !hasTranscript &&
        logHistory &&
        logHistory.find(
            l => l.state === EVENT_LOG_STATES.completed && l.stateType === EVENT_LOG_STATE_TYPES.firstTranscript
        )
    ) {
        hasTranscript = true;
    }
    return {
        currentState,
        hasTranscript
    };
}

export const withData = () =>
    compose(
        withDisconnectPrivateRecording(),
        withEventLogs({ variables: { withHistory: true } }),
        graphql(
            gql`
                query withUserPusherToken {
                    currentUser {
                        id
                        pusherToken
                    }
                }
            `,
            {
                props: ({ data }) => ({ pusherToken: get(data, 'currentUser.pusherToken') }),
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                // We may not have the pusher token on mount, so save a function onto the component that we can either
                // call here or in componentDidUpdate to subscribe as soon as we have the token.
                this.trySubscribe = () => {
                    if (!this.subscription) {
                        const { pusherToken, eventId, realtime } = this.props;
                        if (pusherToken && eventId) {
                            this.subscription = realtime.subscribe(
                                `scheduled_audio_call_${eventId}_changes`,
                                'modified',
                                () => {
                                    const { refreshLogs } = this.props;
                                    if (refreshLogs) refreshLogs();
                                }
                            );
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                }
            }
        }),
        withMemo({ normalize: normalizeLogs }),
        withProps(({ logHistory, logs, normalize }) => ({
            ...normalize(logs, logHistory)
        }))
    );
