export const styleSheet = theme => ({
    container: {
        padding: theme.margins.margin01
    },
    emoji: {
        fontSize: theme.fonts.fontSizes.size01,
        marginLeft: 5
    },
    green: {
        color: theme.colors.green03
    },
    logRow: {
        alignItems: 'center',
        display: 'flex',
        height: 20,
        marginTop: theme.margins.margin00,
        ':first-of-type': {
            marginTop: 0
        }
    },
    red: {
        color: theme.colors.red01
    },
    timestamp: {
        color: theme.colors.gray04,
        minWidth: 80
    }
});
