export const styleSheet = theme => {
    const hoverButtonStyles = {
        backgroundColor: theme.colors.gray11,
        '.userInfoIconWrapper': {
            'svg g': {
                fill: theme.colors.orange02
            }
        },
        '.userNameText': {
            color: theme.colors.white01
        },
        '.userOrgText': {
            color: theme.colors.gray04
        },
        '.chevron': {
            'svg g': {
                fill: theme.colors.gray04
            }
        }
    };
    const menuButton = {
        minWidth: 140,
        maxWidth: 200,
        height: 30,
        flexShrink: 0,
        color: theme.colors.gray04,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.gray11,
        position: 'relative',
        ':hover': {
            ...hoverButtonStyles
        }
    };
    const tooltip = {
        display: 'block',
        zIndex: 1,
        backgroundColor: theme.colors.gray11,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.2)',
        color: theme.colors.gray04,
        borderRadius: 6,
        overflow: 'hidden',
        '.menuButton': {
            borderRadius: '0 0 6px 6px',
            margin: 0
        },
        ':hover': {
            ...hoverButtonStyles,
            '.chevron': {
                transform: 'rotate(90deg)',
                'svg g': {
                    fill: theme.colors.gray04
                }
            }
        }
    };
    return {
        name: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        tooltip: {
            ...tooltip
        },
        organization: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: 2,
            color: theme.colors.gray06,
            letterSpacing: 1
        },
        menuButton: {
            ...menuButton,
            borderRadius: 6,
            padding: '0 10px'
        },
        userInfoText: {
            flex: 1,
            minWidth: 0,
            margin: `0 ${theme.margins.margin00} 0 8px`
        },
        hoverMenuOption: {
            '> *': {
                color: theme.colors.gray04,
                height: 30,
                paddingLeft: 10,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                lineHeight: '16px',
                textDecoration: 'none',
                p: {
                    marginLeft: 8
                },
                ':hover': {
                    color: theme.colors.white01,
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    'svg g': {
                        fill: theme.colors.white01
                    },
                    '.optionHoverIndicator': {
                        width: 4
                    }
                }
            }
        },
        externalLink: {
            textDecoration: 'none',
            color: theme.colors.gray04,
            ':hover': {
                textDecoration: 'none'
            }
        },
        iconWrapper: {
            width: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        signOutButton: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            a: {
                padding: '0 10px',
                height: 30,
                color: theme.colors.gray04,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                letterSpacing: 1,
                textDecoration: 'none',
                p: {
                    textDecoration: 'none'
                },
                ':hover': {
                    color: theme.colors.white01
                }
            }
        },
        chevron: {
            transition: 'all 0.2s ease-in-out',
            transform: 'rotate(180deg)',
            height: 18
        },
        optionHoverIndicator: {
            transition: 'all 0.2s ease-in-out',
            position: 'absolute',
            right: 0,
            width: 0,
            height: 18,
            borderRadius: '5px 0 0 5px',
            backgroundColor: theme.colors.orange01
        }
    };
};
