import { keyframes } from '@emotion/react';
import { slideUpFrames } from 'styles/content';

const slideDownFrames = keyframes`
  0%   { transform: translateY(-30px) }
  100%   { transform: translateY(0) }
`;

export const styleSheet = theme => {
    const showHiddenEquitiesContainer = {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: '0 0 13px 13px',
        whiteSpace: 'nowrap',
        p: {
            color: theme.colors.red01
        },
        ':hover': {
            color: theme.colors.red01,
            p: {
                color: theme.colors.red09
            },
            '.toggleOn': {
                backgroundColor: theme.colors.red09
            },
            '.toggleOff': {
                backgroundColor: theme.colors.red01
            }
        }
    };
    return {
        container: {
            position: 'relative',
            width: 250,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        },
        bulkControls: {
            display: 'flex',
            height: 30,
            alignItems: 'center'
        },
        company: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        description: {
            marginTop: 4,
            marginBottom: 8,
            lineHeight: '1.3em'
        },
        empty: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
        },
        equityInfo: {
            marginLeft: 10,
            flex: 1,
            overflow: 'hidden'
        },
        equityRow: {
            alignItems: 'center',
            padding: '10px 12px',
            display: 'flex',
            cursor: 'pointer',
            ':nth-of-type(odd)': {
                backgroundColor: theme.colors.gray10
            },
            ':hover': {
                backgroundColor: theme.colors.yellow03,
                p: {
                    color: theme.colors.black01
                },
                '.closeIcon': {
                    display: 'flex'
                }
            }
        },
        exchange: {
            marginLeft: 4,
            color: theme.colors.gray06,
            letterSpacing: '1px'
        },
        header: {
            backgroundColor: theme.colors.white01,
            padding: '12px 12px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        hiddenEquitiesContainer: {
            boxShadow: `0 4px 8px -1px ${theme.colors.gray02}`,
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            justifyContent: 'space-between',
            maxHeight: 213,
            overflow: 'hidden',
            width: '100%',
            zIndex: 1,
            // slide down animation
            WebkitAnimation: `${slideDownFrames} 0.5s 1`,
            animation: `${slideDownFrames} 0.5s 1`
        },
        hiddenEquitiesContainerHidden: {
            boxShadow: `0 4px 8px -1px ${theme.colors.gray02}`,
            height: 46,
            padding: '10px 8px',
            zIndex: 1,
            // slide up animation
            WebkitAnimation: `${slideUpFrames} 0.2s 1`,
            animation: `${slideUpFrames} 0.2s 1`
        },
        hiddenEquitiesWrapper: {
            flexShrink: 0,
            margin: '10px 8px',
            maxHeight: 110,
            overflowY: 'auto'
        },
        hiddenEquityRow: {
            margin: '0 4px 4px',
            borderRadius: 6,
            alignItems: 'center',
            padding: '8px 10px',
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: theme.colors.red05,
            '.ticker': {
                width: 44,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            },
            '.name': {
                flex: 1,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            },
            ':hover': {
                backgroundColor: theme.colors.red07,
                p: {
                    color: theme.colors.black01
                },
                '.closeIcon': {
                    display: 'flex'
                }
            },
            ':last-of-type': {
                margin: '0 4px'
            }
        },
        listContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        listWrapper: {
            height: '100%',
            position: 'relative'
        },
        loader: {
            margin: `${theme.margins.margin01} auto`
        },
        loadingContainer: {
            justifyContent: 'center'
        },
        numSelected: {
            color: theme.colors.gray06
        },
        remove: {
            display: 'none',
            paddingLeft: theme.margins.margin00,
            alignItems: 'center'
        },
        removeSelected: {
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.gray06,
            cursor: 'pointer',
            p: {
                marginLeft: 6
            },
            ':hover': {
                color: theme.colors.black01,
                'svg g': {
                    fill: theme.colors.black01
                }
            }
        },
        restoreAll: {
            alignItems: 'center',
            color: theme.colors.gray06,
            cursor: 'pointer',
            display: 'flex',
            margin: '10px 13px 0px auto',
            p: {
                marginLeft: 6
            },
            ':hover': {
                color: theme.colors.black01,
                'svg g': {
                    fill: theme.colors.black01
                }
            }
        },
        search: {
            width: '100%',
            margin: 0,
            marginTop: 6,
            marginBottom: 6,
            input: {
                paddingLeft: 30,
                borderRadius: 15,
                fontSize: theme.fonts.fontSizes.size01,
                height: 30
            },
            '.iconWrapper': {
                height: 30,
                left: 8,
                svg: {
                    height: 15,
                    width: 15
                }
            }
        },
        showHiddenEquitiesContainer: {
            ...showHiddenEquitiesContainer
        },
        showHiddenEquitiesContainerHidden: {
            ...showHiddenEquitiesContainer,
            padding: '2px 5px 2px',
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                color: theme.colors.red01,
                p: {
                    color: theme.colors.red01
                },
                '.toggleOn': {
                    backgroundColor: theme.colors.red09
                },
                '.toggleOff': {
                    backgroundColor: theme.colors.red01
                }
            }
        },
        showHiddenEquitiesLink: {
            marginLeft: 8
        },
        showHiddenEquitiesToggle: {
            '.toggleOn': {
                backgroundColor: theme.colors.red01
            },
            ':hover': {
                '.toggleOn': {
                    backgroundColor: theme.colors.red09
                }
            }
        },
        showHiddenEquitiesToggleHidden: {
            ':hover': {
                '.toggleOff': {
                    backgroundColor: theme.colors.red01
                }
            }
        },
        spacer: {
            flex: 1
        },
        ticker: {
            display: 'flex',
            letterSpacing: '1px',
            marginBottom: 2
        }
    };
};
