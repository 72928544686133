import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId } from 'utils';
import { withData } from './data';
import { EventCorporateActionsUI } from './ui';

export class EventCorporateActions extends PureComponent {
    static displayName = 'EventCorporateActionsContainer';

    static propTypes = {
        corpActions: PropTypes.arrayOf(PropTypes.object),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        pathname: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        corpActions: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.openUpgradeModal = this.openUpgradeModal.bind(this);
    }

    openUpgradeModal() {
        const { history, pathname } = this.props;
        history.push(generateModalId({ pathname, id: 'spotlight', type: 'upgrade' }));
    }

    render() {
        const { corpActions, pathname, styles } = this.props;
        return (
            <EventCorporateActionsUI
                corpActions={corpActions}
                pathname={pathname}
                openUpgradeModal={this.openUpgradeModal}
                styles={styles}
            />
        );
    }
}

export const EventCorporateActionsContainer = compose(
    withUrlContext(['history', 'pathname']),
    withData()
)(EventCorporateActions);
