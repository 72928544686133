import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { FeaturePreviewUI } from './ui';

export class FeaturePreview extends PureComponent {
    static displayName = 'FeaturePreviewContainer';

    static propTypes = {
        growLeft: PropTypes.bool,
        growUp: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        growLeft: false,
        growUp: false,
        styles: undefined,
        xOffset: 0,
        yOffset: 0
    };

    render() {
        const { growLeft, growUp, styles, xOffset, yOffset } = this.props;
        return (
            <FeaturePreviewUI growLeft={growLeft} growUp={growUp} styles={styles} xOffset={xOffset} yOffset={yOffset} />
        );
    }
}

export const FeaturePreviewContainer = compose()(FeaturePreview);
