export const styleSheet = theme => ({
    container: {
        position: 'relative',
        ':hover': {
            'textarea:not(:focus)': {
                boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
                backgroundColor: theme.colors.white02
            }
        }
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        ...theme.content.formLabel
    },
    textError: {
        ...theme.content.formLabel,
        color: theme.colors.inputError
    },
    input: {
        display: 'block',
        width: '100%',
        backgroundColor: theme.colors.white03,
        border: 'none',
        borderRadius: '6px',
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size04,
        padding: '8px 12px',
        transition: 'all 0.2s ease-in-out',
        resize: 'none',
        lineHeight: '1.3em',
        '::placeholder': {
            color: theme.colors.gray04
        },
        ':focus': {
            backgroundColor: theme.colors.white01,
            boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 50px ${theme.colors.input} inset`
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            WebkitAppearance: 'none'
        }
    },
    inputWithText: {
        backgroundColor: theme.colors.white01,
        boxShadow: `0 0 0 1px ${theme.colors.gray01}`
    },
    inputError: {
        boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`,
        '::placeholder': {
            color: theme.colors.inputError
        },
        ':focus': {
            boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`
        }
    },
    charLimit: {
        color: theme.colors.gray04,
        textAlign: 'right',
        marginTop: 6,
        marginBottom: -16
    },
    charLimitReached: {
        letterSpacing: 1,
        color: theme.colors.red01,
        textAlign: 'right',
        marginTop: 6,
        marginBottom: -16
    }
});
