export const styleSheet = () => ({
    container: {
        height: '100%',
        cursor: 'grab',
        width: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        flexShrink: 0
    }
});
