import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Checkbox } from 'components/Checkbox';
import { Toggle } from 'components/Toggle';
import { Icon } from 'components/Icon';
import { WithPermission } from 'components/WithPermission';
import { CompanyResults } from './CompanyResults';
import { EventResults } from './EventResults';
import { NewsResults } from './NewsResults';
import { TranscriptResults } from './TranscriptResults';
// import { StreetAccountResults } from './StreetAccountResults';
import { styleSheet } from './stylesheet';

class MobileSearch extends PureComponent {
    static propTypes = {
        companyMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        eventMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        hasMoreCompanies: PropTypes.bool.isRequired,
        hasMoreEvents: PropTypes.bool.isRequired,
        hasMoreNews: PropTypes.bool.isRequired,
        hasMoreTranscripts: PropTypes.bool.isRequired,
        hideSearch: PropTypes.func.isRequired,
        highlightResult: PropTypes.func.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        indexElement: PropTypes.func.isRequired,
        loadingCompanies: PropTypes.bool.isRequired,
        loadingEvents: PropTypes.bool.isRequired,
        loadingMoreCompanies: PropTypes.bool.isRequired,
        loadingMoreEvents: PropTypes.bool.isRequired,
        loadingMoreNews: PropTypes.bool.isRequired,
        loadingMoreTranscripts: PropTypes.bool.isRequired,
        loadingNews: PropTypes.bool.isRequired,
        // loadingStreetaccount: PropTypes.bool.isRequired,
        loadingTranscripts: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        singleType: PropTypes.bool.isRequired,
        newsMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        onClickResult: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        openUpgradeModal: PropTypes.func.isRequired,
        previewElement: PropTypes.element,
        resultTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
        scrollRef: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        setPreview: PropTypes.func.isRequired,
        selectedResultTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        // streetAccountMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleType: PropTypes.func.isRequired,
        toggleTypeAmount: PropTypes.func.isRequired,
        transcriptMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        highlightedResult: null,
        previewElement: undefined
    };

    constructor(props) {
        super(props);

        this.renderTypeSelector = this.renderTypeSelector.bind(this);
    }

    renderTypeSelector(resultType) {
        const { styles, theme, selectedResultTypes, toggleType, openUpgradeModal } = this.props;
        const { key, label, permission } = resultType;
        const on = selectedResultTypes.includes(key);
        const resultToggle = (
            <Div key={key} styles={styles.resultType} onClick={() => toggleType(resultType)}>
                <Checkbox size={3} checked={on} />
                <Text size={1}>{label}</Text>
            </Div>
        );

        if (permission) {
            return (
                <WithPermission key={key} permissions={[permission]}>
                    {({ restricted, isLoading }) =>
                        restricted || isLoading ? (
                            <Div
                                styles={styles.resultTypeLocked}
                                onClick={() => (isLoading ? {} : openUpgradeModal(key))}
                            >
                                <Text size={1}>{label}</Text>
                                <Icon type="lock02" color={theme.colors.black01} />
                            </Div>
                        ) : (
                            resultToggle
                        )
                    }
                </WithPermission>
            );
        }

        return resultToggle;
    }

    renderTypesSelector() {
        const { styles, resultTypes, singleType, toggleTypeAmount } = this.props;
        return (
            <Div
                styles={styles.types}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Div styles={styles.typeBoxes}>{resultTypes.map(this.renderTypeSelector)}</Div>
                <Toggle
                    leftLabel="multi"
                    rightLabel="single"
                    styles={styles.toggle}
                    on={singleType}
                    gray
                    onClick={toggleTypeAmount}
                />
            </Div>
        );
    }

    renderNoTypes() {
        const { styles } = this.props;

        return (
            <Div styles={styles.noTypes}>
                <Text size={3}>Please select a search type</Text>
            </Div>
        );
    }

    renderVerticalResults() {
        const {
            styles,
            theme,
            searchTerm,
            previewElement,
            companyMatches,
            eventMatches,
            hasMoreCompanies,
            hasMoreEvents,
            hasMoreNews,
            hasMoreTranscripts,
            highlightResult,
            highlightedResult,
            indexElement,
            newsMatches,
            // streetAccountMatches,
            transcriptMatches,
            selectedResultTypes,
            loadingCompanies,
            loadingEvents,
            loadingNews,
            // loadingStreetaccount,
            loadingTranscripts,
            loadMoreResults,
            loadingMoreCompanies,
            loadingMoreEvents,
            loadingMoreNews,
            loadingMoreTranscripts,
            scrollRef,
            setPreview,
            onClickResult
        } = this.props;
        if (!searchTerm || searchTerm.length === 0) {
            return null;
        }

        return (
            <Div styles={styles.verticalResults}>
                <Div styles={styles.searchResults} ref={scrollRef}>
                    <CompanyResults
                        enabled={selectedResultTypes.includes('companies')}
                        hasMoreResults={hasMoreCompanies}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingCompanies}
                        loadingMoreResults={loadingMoreCompanies}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={companyMatches}
                    />
                    <EventResults
                        enabled={selectedResultTypes.includes('events')}
                        hasMoreResults={hasMoreEvents}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingEvents}
                        loadingMoreResults={loadingMoreEvents}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={eventMatches}
                        setPreview={setPreview}
                    />
                    <TranscriptResults
                        enabled={selectedResultTypes.includes('transcripts')}
                        hasMoreResults={hasMoreTranscripts}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingTranscripts}
                        loadingMoreResults={loadingMoreTranscripts}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={transcriptMatches}
                        setPreview={setPreview}
                    />
                    {/*
                    <StreetAccountResults
                        highlightResult={highlightResult}
                        highlightedResult={highlightedResult}
                        indexElement={indexElement}
                        enabled={selectedResultTypes.includes('streetaccount')}
                        results={streetAccountMatches}
                        loading={loadingStreetaccount}
                        setPreview={setPreview}
                        onClickResult={onClickResult}
                    /> */}
                    <NewsResults
                        enabled={selectedResultTypes.includes('news')}
                        hasMoreResults={hasMoreNews}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingNews}
                        loadingMoreResults={loadingMoreNews}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={newsMatches}
                        setPreview={setPreview}
                    />
                    {selectedResultTypes.length === 0 && this.renderNoTypes()}
                </Div>
                <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                    {selectedResultTypes.length > 0 && (
                        <Div styles={styles.resultPreview}>
                            {previewElement || (
                                <Div className="noPreview" styles={styles.noPreview}>
                                    <Text size={3}>Result Preview</Text>
                                </Div>
                            )}
                        </Div>
                    )}
                </MediaQuery>
            </Div>
        );
    }

    render() {
        const { styles, theme, hideSearch, visible, onSearch, searchTerm } = this.props;
        if (!visible) {
            return null;
        }

        return (
            <Div styles={styles.container}>
                <Div styles={styles.clickToHide} onClick={hideSearch} />
                <Div styles={styles.searchContainer}>
                    <TextInput
                        autoComplete="off"
                        autoFocus
                        clearable
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onChange={onSearch}
                        value={searchTerm}
                        name="globalSearch"
                        icon="search03"
                        styles={styles.searchInput}
                    />
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        <Div styles={styles.closeMobile} onClick={hideSearch}>
                            <Icon type="xMark" color={theme.colors.white01} />
                        </Div>
                    </MediaQuery>
                    <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                        {this.renderTypesSelector()}
                    </MediaQuery>
                </Div>
                {this.renderVerticalResults()}
            </Div>
        );
    }
}

export const MobileSearchUI = compose(withStyleSheet(styleSheet))(MobileSearch);
