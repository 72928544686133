export const styleSheet = theme => ({
    modal: {
        height: 'calc(100vh - 100px)',
        maxHeight: 800,
        width: 'calc(100% - 80px)',
        maxWidth: 1200,
        '.modalHeader': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `20px 30px`,
            '> div': {
                ':last-of-type': {
                    marginLeft: 'unset'
                }
            }
        }
    },
    container: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden'
    },
    menu: {
        display: 'flex',
        flexDirection: 'column'
    },
    dashboardGalleryMenu: {
        flex: 1
    },
    modalHeader: {
        flexShrink: 0,
        '> svg': {
            height: 20,
            width: 25,
            marginRight: theme.margins.margin00,
            marginBottom: -2
        }
    },
    modalHeaderIcon: {
        marginLeft: theme.margins.margin00,
        svg: {
            height: 15,
            width: 15
        }
    },
    modalHeaderText: {
        lineHeight: '1.3em'
    },
    modalHeaderTooltip: {
        ...theme.content.tooltipText,
        opacity: 1,
        width: 290
    },
    content: {
        borderRadius: 6,
        paddingBottom: 2,
        overflow: 'hidden',
        display: 'flex',
        backgroundColor: theme.colors.gray12,
        flexDirection: 'column',
        width: '100%',
        marginRight: 30,
        marginBottom: 20,
        boxShadow: '4px 10px 14px 0 rgba(0, 0, 0, 0.16)',
        zIndex: 1
    },
    header: {
        display: 'flex',
        color: theme.colors.white01,
        padding: '10px 10px 12px 10px'
    },
    headerLeft: {},
    headerRight: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        justifyContent: 'space-between'
    },
    monitorActions: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    previouslyAdded: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.margins.margin00,
        whiteSpace: 'nowrap',
        p: {
            opacity: 0.8
        },
        svg: {
            marginRight: 6
        }
    },
    description: {
        marginTop: 4,
        marginRight: theme.margins.margin03,
        color: theme.colors.gray05,
        lineHeight: '1.3em'
    },
    tagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 8,
        'p:first-of-type': {
            marginLeft: 0
        }
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.gray05,
        borderRadius: 4,
        color: theme.colors.black01,
        height: 16,
        marginRight: 5,
        marginBottom: 5,
        padding: '0 4px',
        ':hover': {
            backgroundColor: theme.colors.gray07
        },
        p: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    dashInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 12,
        p: {
            whiteSpace: 'nowrap'
        }
    },
    dashInfoLabel: {
        letterSpacing: 1,
        color: theme.colors.gray05,
        opacity: 0.6,
        paddingBottom: 4
    },
    dashLastModified: {
        marginLeft: theme.margins.margin01
    },
    infoIcon: {
        marginTop: 2,
        marginRight: 8,
        marginLeft: 8
    },
    infoIconTooltip: {
        ...theme.content.tooltipTextSmall,
        flexDirection: 'column',
        height: 'unset',
        padding: '8px 10px',
        lineHeight: '1em',
        justifyContent: 'center',
        width: 310,
        textAlign: 'left',
        alignItems: 'flex-start',
        p: {
            ':first-of-type': {
                marginTop: 0
            },
            marginTop: 6
        }
    },
    infoIconTooltipRow: {
        display: 'flex',
        marginTop: theme.margins.margin00,
        ':nth-of-type(2)': {
            marginLeft: 24
        }
    },
    infoIconTooltipIcon: {
        marginTop: 4,
        display: 'flex',
        justifyContent: 'center',
        width: 20,
        marginRight: 6,
        flexShrink: 0
    },
    followButton: {
        alignSelf: 'flex-end',
        margin: 'auto 0 auto 6px'
    },
    selectDashBtn: {
        ...theme.content.iconButton,
        alignSelf: 'flex-end',
        backgroundColor: theme.colors.green03,
        ':hover': {
            backgroundColor: theme.colors.green06
        },
        ':active': {
            backgroundColor: theme.colors.green07
        }
    },
    unselectDashBtn: {
        borderRadius: 3.5,
        boxShadow: 'unset',
        height: 30,
        padding: `0 ${theme.margins.margin00}`,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-end',
        flexShrink: 0,
        cursor: 'pointer',
        backgroundColor: theme.colors.gray08,
        p: {
            color: theme.colors.white01,
            marginLeft: 6
        },
        svg: {
            height: 13,
            marginBottom: 1
        },
        ':hover': {
            backgroundColor: theme.colors.gray11
        },
        ':active': {
            backgroundColor: theme.colors.gray12
        }
    },
    streams: {
        zoom: 0.7,
        '.stream': {
            pointerEvents: 'none',
            ':first-of-type': {
                paddingLeft: 8,
                width: 298
            }
        }
    },
    cancelBtn: {
        boxShadow: 'unset',
        padding: '0 2px 0 0',
        p: {
            color: theme.colors.gray06
        },
        ':hover': {
            boxShadow: 'unset',
            p: {
                color: theme.colors.black01
            }
        },
        ':active': {
            backgroundColor: 'unset'
        }
    },
    submitBtn: {
        ...theme.content.blueButton,
        border: 'unset',
        borderRadius: 3,
        boxShadow: 'unset',
        color: theme.colors.white01,
        marginLeft: 15,
        'svg g': {
            fill: theme.colors.white01
        }
    },
    buttonDisabled: {
        borderRadius: 3,
        cursor: 'not-allowed',
        opacity: 0.6
    },
    streamsLoading: {
        margin: '100px auto'
    },
    buttons: {
        boxShadow: `-1px 0px 0 1px ${theme.colors.gray01}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 28px'
    },
    tooltip: {
        ...theme.content.tooltipText,
        maxWidth: 270
    },
    button: {
        cursor: 'pointer',
        backgroundColor: theme.colors.white01,
        boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
        borderRadius: 3,
        padding: ' 0 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        a: {
            textDecoration: 'none',
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.colors.gray08
        },
        p: {
            color: theme.colors.gray08
        },
        ':hover': {
            boxShadow: `0 0 0 1px ${theme.colors.gray07}`,
            svg: {
                g: {
                    fill: theme.colors.blue08
                }
            },
            p: {
                color: theme.colors.black01
            }
        },
        ':active': {
            backgroundColor: 'unset'
        },
        svg: {
            marginRight: 7,
            marginBottom: 1
        }
    }
});
