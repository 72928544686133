export const styleSheet = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 4,
        marginRight: 30
    },
    containerMinimal: {},
    equityMinimal: {
        display: 'flex',
        alignItems: 'center',
        letterSpacing: 1
    },
    equity: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 8,
        marginBottom: 8,
        letterSpacing: 1
    },
    ticker: {
        color: theme.colors.blue15
    },
    exchange: {
        color: theme.colors.blue15,
        opacity: 0.5,
        fontWeight: 400,
        marginTop: 2
    },
    text: {
        marginLeft: 6
    },
    identifierInitials: {
        backgroundColor: theme.colors.gray10,
        flexShrink: 0,
        width: 22,
        height: 22,
        borderRadius: 11,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '-1px 0px 2px 0px rgba(0, 0, 0, 0.2)',
        p: {
            paddingLeft: 1,
            color: theme.colors.gray06,
            letterSpacing: 1
        }
    },
    identifierIcon: {
        flexShrink: 0,
        width: 22,
        height: 22,
        borderRadius: 11,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.white01,
        boxShadow: '-1px 0px 2px 0px rgba(0, 0, 0, 0.2)',
        img: {
            minHeight: '65%',
            maxHeight: '100%'
        }
    },
    toggle: {
        padding: '2px 4px',
        backgroundColor: theme.colors.gray03,
        borderRadius: 2,
        marginBottom: 8,
        ':hover': {
            backgroundColor: theme.colors.gray05
        }
    }
});
