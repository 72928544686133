import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { dashboardFragment, dashboardSectionFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withDashboardForm($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        ...dashboard
                        categories
                        galleryRules {
                            ...rule
                        }
                        rules {
                            ...rule
                        }
                    }
                }
                ${dashboardFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data }) => ({
                    dashboard: get(data, 'dashboards[0]'),
                    loading: get(data, 'loading')
                }),
                skip: ({ dashboardId }) => !dashboardId || dashboardId === 'new',
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                query withDashboardCategories {
                    galleryCategories
                }
            `,
            {
                props: ({ data }) => ({
                    categoryOptions: get(data, 'galleryCategories', []).map(c => ({ value: c, label: c }))
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                mutation CreateDashboard($input: CreateDashboardInput!) {
                    createDashboard(input: $input) {
                        success
                        dashboard {
                            ...dashboard
                            categories
                            galleryRules {
                                ...rule
                            }
                            rules {
                                ...rule
                            }
                        }
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
                ${ruleFragment}
            `,
            {
                props: ({ mutate }) => ({
                    createDashboard: input =>
                        mutate({
                            variables: { input }
                        }).then(
                            ({
                                data: {
                                    createDashboard: { dashboard }
                                }
                            }) => dashboard
                        )
                })
            }
        ),
        graphql(
            gql`
                mutation UpdateDashboard($input: UpdateDashboardInput!, $dashboardId: ID) {
                    updateDashboard(input: $input) {
                        success
                        dashboard {
                            ...dashboard
                            categories
                            galleryRules {
                                ...rule
                            }
                            rules {
                                ...rule
                            }
                        }
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                            dashboards(filter: { dashboardIds: [$dashboardId] }) {
                                ...dashboard
                                rules {
                                    ...rule
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
                ${ruleFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        updateDashboard: input =>
                            mutate({
                                variables: { input }
                            })
                                .then(() => {
                                    setStatusBanner('Monitor updated successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error updating monitor: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation DeleteDashboard($dashboardId: ID!) {
                    deleteDashboard(dashboardId: $dashboardId) {
                        success
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        deleteDashboard: dashboardId =>
                            mutate({
                                variables: { dashboardId }
                            })
                                .then(() => {
                                    setStatusBanner('Monitor deleted successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error deleting monitor: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation CreateStream($input: StreamInput!) {
                    createStream(input: $input) {
                        success
                        stream {
                            ...stream
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ mutate }) => ({
                    createStream: input =>
                        mutate({
                            variables: { input }
                        })
                }),
                options: {
                    context: {
                        batchKey: 'dashFormStreamMutation'
                    }
                }
            }
        )
    );
