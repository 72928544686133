import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Th } from 'components/Th';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class BoxTh extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        sortableStyles: PropTypes.objectOf(PropTypes.any),
        sortable: PropTypes.bool,
        sortDirection: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        sortable: false,
        sortDirection: undefined,
        sortableStyles: {}
    };

    render() {
        const { styles, theme, passedStyles, children, sortable, sortDirection, sortableStyles, ...rest } = this.props;
        const containerStyle = sortable ? styles.containerSortable : styles.container;
        let iconType = 'sortable';
        if (sortDirection) {
            iconType = sortDirection === 'desc' ? 'sortDown' : 'sortUp';
        }

        return (
            <Th styles={{ ...containerStyle, ...passedStyles }} {...rest}>
                <Div styles={{ ...styles.columns, ...sortableStyles }}>
                    {typeof children === 'string' ? (
                        <Text styles={styles.text} size={0} uppercase>
                            {children}
                        </Text>
                    ) : (
                        children
                    )}
                    {sortable && (
                        <Div styles={styles.sortContainer}>
                            <Icon type={iconType} color={theme.colors.black01} />
                        </Div>
                    )}
                </Div>
            </Th>
        );
    }
}

export const BoxThUI = compose(withStyleSheet(styleSheet))(BoxTh);
