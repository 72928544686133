import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mapStylesProp } from 'utils/styles';
import { withTheme } from '@emotion/react';

const baseStyle = props => ({
    fontSize: props.theme.fonts.fontSizes[`size0${props.size}`],
    fontFamily: props.serif ? props.theme.fonts.fontFamilyContent : undefined,
    fontWeight: props.theme.fonts.fontWeights[props.weight],
    // eslint-disable-next-line no-nested-ternary
    textTransform: props.uppercase ? 'uppercase' : props.capitalize ? 'capitalize' : 'none',
    ...props.styles
});

const Span = styled('span')(baseStyle);
const P = styled('p')(baseStyle);
const Label = styled('label')(baseStyle);

class TextComponent extends PureComponent {
    static propTypes = {
        capitalize: PropTypes.bool,
        children: PropTypes.node,
        contentEditable: PropTypes.bool,
        label: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        serif: PropTypes.bool,
        size: PropTypes.number,
        span: PropTypes.bool,
        styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
        uppercase: PropTypes.bool,
        weight: PropTypes.string
    };

    static defaultProps = {
        capitalize: false,
        children: null,
        contentEditable: false,
        label: false,
        passedStyles: {},
        serif: false,
        size: 1,
        span: false,
        styles: {},
        uppercase: false,
        weight: 'regular'
    };

    render() {
        const {
            capitalize,
            children,
            contentEditable,
            label,
            serif,
            size,
            span,
            weight,
            uppercase,
            styles,
            passedStyles,
            ...rest
        } = this.props;
        let Element = P;
        let labelFor = null;

        if (span) Element = Span;
        if (label) {
            Element = Label;
            labelFor = children;
        }

        return (
            <Element
                htmlFor={labelFor}
                size={size}
                serif={serif}
                weight={weight}
                capitalize={capitalize}
                contentEditable={contentEditable}
                uppercase={uppercase}
                styles={{ ...mapStylesProp({ styles }), ...passedStyles }}
                suppressContentEditableWarning={!!contentEditable}
                {...rest}
            >
                {children}
            </Element>
        );
    }
}

export const Text = withTheme(TextComponent);
