import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import XDate from 'xdate';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { config } from 'configuration';
import { withStyleSheet } from 'hoc/styles';
import { get } from 'utils';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { Text } from 'components/Text';
import { EventCountdown } from 'components/EventCountdown';
import { Notice } from 'components/Notice';
import { Dropdown } from 'components/Dropdown';
import { WithPreference } from 'components/WithPreference';
import { PREFERENCES } from 'consts';
import { AddToCalendarButton } from '../AddToCalendarButton';
import { AddToCalendarDropdown } from '../AddToCalendarDropdown';
import { styleSheet } from './stylesheet';

class EventNoTranscript extends PureComponent {
    static propTypes = {
        broadcastUrl: PropTypes.string,
        callDate: PropTypes.string,
        conferenceNumber: PropTypes.string,
        conferencePin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        equity: PropTypes.objectOf(PropTypes.any),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        inProgress: PropTypes.bool,
        openIcalModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        waiting: PropTypes.bool,
        watchlists: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        broadcastUrl: null,
        callDate: null,
        conferenceNumber: null,
        conferencePin: null,
        equity: null,
        id: null,
        inProgress: false,
        passedStyles: {},
        title: null,
        waiting: false
    };

    renderAddToCalendar() {
        const { styles, id, broadcastUrl, callDate, conferenceNumber, conferencePin, title, equity } = this.props;
        const AddToCalendar = AddToCalendarHOC(AddToCalendarButton, AddToCalendarDropdown);
        const name = get(equity, 'commonName', get(equity, 'name', null));
        const startDatetime = new XDate(callDate).toString("yyyyMMdd'T'HHmmss");
        const endDatetime = new XDate(callDate).addHours(1).toString("yyyyMMdd'T'HHmmss");
        const duration = '0100'; // default to 1 hour since the events don't have a scheduled end time

        // Build the description based on available props
        let description = `<a href="${config.DASHBOARD_ENDPOINT}events?tabs[0]=evt|${id}" target="_blank">Watch live stream on Aiera!</a>\n`;
        if (conferenceNumber) {
            description += `\nConference number: ${conferenceNumber}`;
        }
        if (conferencePin) {
            description += `\nConference pin: ${conferencePin}`;
        }
        if (broadcastUrl) {
            description += `\nBroadcast url: ${broadcastUrl}`;
        }

        const event = {
            title: `${name} - ${title}`,
            description,
            startDatetime,
            endDatetime,
            duration
        };
        return (
            <Div styles={styles.addToCalendar}>
                <AddToCalendar
                    className="addToCalendar"
                    linkProps={{
                        className: 'addToCalendar__link'
                    }}
                    event={event}
                />
            </Div>
        );
    }

    render() {
        const { children, callDate, inProgress, openIcalModal, passedStyles, styles, waiting, watchlists } = this.props;
        const isUpcoming = callDate && new XDate(callDate) > new XDate();

        if ((inProgress && !waiting) || !isUpcoming) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <EventCountdown styles={styles.noEventsCountdown} callDate={callDate} showDays />
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                    {this.renderAddToCalendar()}
                </WithPreference>
                {children}
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                    <Div styles={styles.proTips}>
                        <Notice type="info" styles={styles.proTipNotice} richContent>
                            <Text size={3} weight="medium">
                                Get calendar event notifications for every
                                <br />
                                ticker in your watchlist — sync with iCal
                            </Text>
                            <Dropdown
                                name="upcoming-event-ical"
                                styles={styles.watchlistDropdown}
                                disableHoverOpen
                                options={watchlists}
                                onChange={openIcalModal}
                                placeholder="Select Watchlist..."
                            />
                        </Notice>
                        <Notice type="info" styles={styles.proTipNotice} richContent>
                            <Div>
                                <Text size={3} weight="medium">
                                    Rewind live audio to hear it again
                                </Text>
                                <Text size={3}>
                                    Up the &lsquo;playback rate&rsquo; to get back
                                    <br />
                                    to live without missing a moment.
                                </Text>
                            </Div>
                            <Img
                                styles={{ borderRadius: 6 }}
                                height="46"
                                width="207"
                                src={`${get(config, 'CDN')}/ui/playback-rate.png`}
                            />
                        </Notice>
                        <Notice type="info" styles={styles.proTipNotice} richContent>
                            <Div>
                                <Text size={3} weight="medium">
                                    Click and drag text...
                                </Text>
                                <Text size={3}>
                                    To create a highlight, or automatically
                                    <br />
                                    copy it to your clipboard.
                                </Text>
                            </Div>
                            <Img
                                styles={{ borderRadius: 6 }}
                                height="64"
                                width="auto"
                                src={`${get(config, 'CDN')}/ui/transcript-select.png`}
                            />
                        </Notice>
                        <Notice type="info" styles={styles.proTipNotice} richContent>
                            <Div>
                                <Text size={3} weight="medium">
                                    Have an iPhone?
                                </Text>
                                <Text size={3}>
                                    Listen to live events from anywhere
                                    <br />
                                    with our mobile iPhone app
                                </Text>
                            </Div>
                            <a href="https://apps.apple.com/us/app/aiera/id1455974709" target="_blank" rel="noreferrer">
                                <Img
                                    data-tname="upcoming-event-mobile"
                                    styles={{ borderRadius: 6 }}
                                    height="40"
                                    width="auto"
                                    src={`${get(config, 'CDN')}/ui/app-store.svg`}
                                />
                            </a>
                        </Notice>
                    </Div>
                </WithPreference>
            </Div>
        );
    }
}

export const EventNoTranscriptUI = compose(withStyleSheet(styleSheet))(EventNoTranscript);
