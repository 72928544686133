import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { DashboardDropdown } from 'components/DashboardDropdown';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';
import { CAPABILITIES } from 'consts';
import { styleSheet } from './stylesheet';

class CloneStreamTooltip extends PureComponent {
    static propTypes = {
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        isEnabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onSelectDashboard: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        selectedDashboard: PropTypes.string,
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitButtonText: PropTypes.string,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        currentDashboard: undefined,
        placeholder: 'Enter new search title...',
        passedStyles: {},
        selectedDashboard: undefined,
        stream: null,
        submitButtonText: 'Clone search'
    };

    renderDropdown() {
        const { currentDashboard, onSelectDashboard, selectedDashboard, styles } = this.props;
        return (
            <DashboardDropdown
                cancelClassName="cloneStreamTooltip"
                currentDashboard={currentDashboard}
                disableHoverOpen
                hasCapabilities={[CAPABILITIES.addStream]}
                name="cloneStreamDashboardId"
                onChange={onSelectDashboard}
                styles={styles.dropdown}
                value={selectedDashboard}
            />
        );
    }

    renderForm() {
        const {
            loading,
            onChange,
            onKeyDown,
            onSubmit,
            passedStyles,
            placeholder,
            stream,
            styles,
            submitButtonText,
            value
        } = this.props;
        return (
            <TooltipForm
                buttonDisabled={!value.length || !stream}
                inputName="cloneStreamName"
                loading={loading}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onSubmit={onSubmit}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                submitButtonText={submitButtonText}
                formFooterComponent={this.renderDropdown()}
                value={value}
            />
        );
    }

    render() {
        const { styles, children, isEnabled } = this.props;
        return (
            <Tooltip
                blockBackground
                cancelClassName="cloneStreamTooltip"
                content={this.renderForm()}
                hideOnScroll={false}
                isEnabled={isEnabled}
                persistOnMouseExit
                slideIn
                styles={styles.tooltip}
                useElementOffsetLeft
                useElementOffsetTop
                useOutsideClickHandler
                xOffset={-1}
                yOffset={-109}
            >
                {children}
            </Tooltip>
        );
    }
}

export const CloneStreamTooltipUI = compose(withStyleSheet(styleSheet))(CloneStreamTooltip);
