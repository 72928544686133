import gql from 'graphql-tag';
import flatten from 'lodash/flatten';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dashboardFragment, eventMatchFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { getDefaultStreamProps, withStreamPaging, withUpdateStreamOnChanges, withEventUpdates } from 'hoc/streams';
import { withMemo } from 'hoc/utils';
import { withReporting } from 'provider/reporting';
import { get } from 'utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';

const mapStateToProps = ({ User: userStore }) => ({
    userIsIdle: get(userStore, 'isIdle')
});

export const withData = () =>
    compose(
        connect(mapStateToProps),
        withMemo({ formatMatches: formatMatchesByType, mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query withLiveEventsStream(
                    $streamId: ID
                    $size: Int = 20
                    $offset: Int = 0
                    $filter: StreamMatchFilter
                ) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        rules {
                            ...rule
                        }
                        dashboards {
                            ...dashboard
                            streams {
                                ...stream
                                rules {
                                    ...rule
                                }
                            }
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...eventMatch
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${eventMatchFragment}
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { fetchMore, loading, matches, refetch, rules, stream, subtitle } = getDefaultStreamProps(
                        data,
                        formatMatches
                    );
                    const dashboard = get(data, 'streams[0].dashboards[0]');
                    const selectedEventIds = new Set(
                        flatten(
                            get(dashboard, 'streams', []).map(s =>
                                get(s, 'rules', [])
                                    .filter(r => r.ruleType === 'event_id')
                                    .map(r => r.value)
                            )
                        )
                    );
                    return {
                        dashboard,
                        fetchMore,
                        loading,
                        matches,
                        refetch,
                        rules,
                        selectedEventIds,
                        stream,
                        subtitle
                    };
                },
                skip: ({ streamId, streamProps, userIsIdle }) =>
                    !get(streamProps, 'wasVisible', false) || !streamId || userIsIdle,
                options: ({ dashDateRange, dashEquityScope, dashFilters, mapFilters, searchTerm, streamId }) => {
                    let rules = mapFilters({
                        dateRange: dashDateRange,
                        equityScope: dashEquityScope,
                        filters: dashFilters
                    });
                    if (searchTerm) {
                        rules = [...rules, { ruleType: 'search_term', condition: 'is_equal', value: searchTerm }];
                    }
                    return {
                        notifyOnNetworkStatusChange: true,
                        fetchPolicy: 'cache-and-network',
                        variables: {
                            filter: {
                                rules
                            },
                            streamId
                        },
                        context: {
                            debounceKey: `debounce:live_events:${streamId}`,
                            debounceTimeout: searchTerm || dashDateRange ? 300 : 0
                        }
                    };
                }
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging(),
        withEventUpdates(),
        withReporting(),
        graphql(
            gql`
                mutation addLiveEvent($input: StreamInput!) {
                    createStream(input: $input) {
                        success
                        stream {
                            ...stream
                            rules {
                                ...rule
                            }
                            dashboards {
                                ...dashboard
                                streams {
                                    ...stream
                                    rules {
                                        ...rule
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: ({
                    mutate,
                    ownProps: {
                        reporter,
                        dashboardId,
                        streamProps: { index }
                    }
                }) => {
                    return {
                        addEvent: event =>
                            mutate({
                                variables: {
                                    input: {
                                        name: get(event, 'title'),
                                        streamType: 'transcripts',
                                        dashboardIds: [dashboardId],
                                        position: index + 1,
                                        rules: [
                                            {
                                                ruleType: 'event_id',
                                                condition: 'is_equal',
                                                value: get(event, 'eventId')
                                            }
                                        ]
                                    }
                                }
                            }).then(() => {
                                reporter.track(reporter.actions.click, reporter.objects.addAieracastStream, {
                                    component: 'LiveEventsStream',
                                    event
                                });
                            })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation removeLiveEvent($dashboardId: ID!, $streamId: ID!) {
                    deleteStream(streamId: $streamId) {
                        success
                        query {
                            dashboards(filter: { dashboardIds: [$dashboardId] }) {
                                ...dashboard
                                streams {
                                    ...stream
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate, ownProps: { dashboardId, dashboard } }) => {
                    return {
                        removeEvent: event => {
                            const eventId = get(event, 'eventId');
                            const stream = get(dashboard, 'streams', []).find(
                                s =>
                                    get(s, 'rules', []).filter(r => r.ruleType === 'event_id' && r.value === eventId)
                                        .length
                            );
                            return mutate({
                                variables: {
                                    streamId: get(stream, 'streamId'),
                                    dashboardId
                                }
                            });
                        }
                    };
                }
            }
        )
    );
