import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange, withStateHandlers } from 'recompose';
import { get } from 'utils';
import { mapContentTypesToFilters, mapFilingFormsToFilters } from 'utils/streams';
import { withData } from './data';
import { ContentStreamUI } from './ui';

export class ContentStream extends PureComponent {
    static displayName = 'ContentStreamContainer';

    static propTypes = {
        clearFilingForms: PropTypes.func.isRequired,
        contentTypes: PropTypes.arrayOf(PropTypes.string),
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        dashEquityScope: PropTypes.arrayOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        dashboardId: PropTypes.string.isRequired,
        dashboardType: PropTypes.string,
        filingForms: PropTypes.arrayOf(PropTypes.string),
        hasFilingFilter: PropTypes.bool,
        hasFilters: PropTypes.bool,
        isOnlyFilings: PropTypes.bool,
        lenses: PropTypes.arrayOf(PropTypes.any),
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        searchTerms: PropTypes.arrayOf(PropTypes.string),
        setStreamLens: PropTypes.func.isRequired,
        showFilingStreamToolbar: PropTypes.bool,
        showTypeSelector: PropTypes.bool,
        streamContentTypes: PropTypes.arrayOf(PropTypes.string),
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string,
        toggleContentTypes: PropTypes.func.isRequired,
        toggleFilingForms: PropTypes.func.isRequired
    };

    static defaultProps = {
        contentTypes: [],
        dashboardType: 'default',
        dashDateRange: null,
        dashEquityScope: null,
        dashSearchTerm: null,
        filingForms: undefined,
        hasFilingFilter: false,
        hasFilters: false,
        isOnlyFilings: false,
        lenses: [],
        loadMoreMatches: null,
        loading: false,
        matches: [],
        searchTerms: [],
        showFilingStreamToolbar: false,
        showTypeSelector: false,
        streamId: null,
        streamContentTypes: [],
        styles: undefined,
        subtitle: undefined
    };

    constructor(props) {
        super(props);

        this.chartRef = createRef();
        this.toggleContentType = this.toggleContentType.bind(this);
        this.toggleFilingForm = this.toggleFilingForm.bind(this);
    }

    componentDidUpdate({ hasFilingFilter: prevHasFilter, streamProps: prevStreamProps }) {
        const { clearFilingForms, hasFilingFilter, streamProps } = this.props;
        const prevWidth = get(prevStreamProps, 'width');
        const width = get(streamProps, 'width');
        if (width !== prevWidth && this.chartRef.current) {
            this.chartRef.current.chart.update({
                chart: {
                    width
                }
            });
        }
        if (!prevHasFilter && hasFilingFilter) clearFilingForms();
    }

    toggleContentType(type) {
        const { streamId, toggleContentTypes, setStreamLens, contentTypes } = this.props;
        const typeSet = new Set(contentTypes);
        let updateTypes = false;
        if (typeSet.has(type)) {
            if (typeSet.size > 1) {
                typeSet.delete(type);
                updateTypes = true;
            }
        } else {
            typeSet.add(type);
            updateTypes = true;
        }

        if (typeSet.has('streetaccount')) {
            typeSet.delete('streetaccount');
            updateTypes = true;
        }

        if (updateTypes) {
            toggleContentTypes([...typeSet]);
            setStreamLens({ streamId, contentTypes: [...typeSet] });
        }
    }

    toggleFilingForm(filingForm) {
        const { filingForms, setStreamLens, streamId, toggleFilingForms } = this.props;
        const formSet = new Set(filingForms);
        let updateForms = false;
        if (formSet.has(filingForm)) {
            if (formSet.size > 1) {
                formSet.delete(filingForm);
                updateForms = true;
            }
        } else {
            formSet.add(filingForm);
            updateForms = true;
        }
        if (updateForms) {
            const forms = [...formSet];
            toggleFilingForms(forms);
            setStreamLens({ streamId, filingForms: forms });
        }
    }

    render() {
        const {
            contentTypes,
            dashboardId,
            dashboardType,
            dashDateRange,
            dashSearchTerm,
            dashEquityScope,
            filingForms,
            hasFilingFilter,
            hasFilters,
            isOnlyFilings,
            lenses,
            loadMoreMatches,
            loading,
            matches,
            searchTerms,
            showFilingStreamToolbar,
            showTypeSelector,
            streamContentTypes,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;

        return (
            <ContentStreamUI
                chartRef={this.chartRef}
                contentTypes={contentTypes}
                dashboardId={dashboardId}
                dashboardType={dashboardType}
                dashDateRange={dashDateRange}
                dashEquityScope={dashEquityScope}
                dashSearchTerm={dashSearchTerm}
                filingForms={filingForms}
                hasFilters={hasFilters || hasFilingFilter}
                isOnlyFilings={isOnlyFilings}
                lenses={lenses}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                searchTerms={searchTerms}
                showFilingStreamToolbar={showFilingStreamToolbar}
                showTypeSelector={showTypeSelector}
                streamContentTypes={streamContentTypes}
                streamDisplayMode={streamDisplayMode}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                toggleContentType={this.toggleContentType}
                toggleFilingForm={this.toggleFilingForm}
                subtitle={subtitle}
            />
        );
    }
}

export const ContentStreamContainer = compose(
    withStateHandlers(
        () => ({
            filingFormsSet: false,
            filtersSet: false,
            inStateContentTypes: [],
            inStateFilingForms: []
        }),
        {
            clearFilingForms: () => () => ({
                filingFormsSet: false,
                inStateFilingForms: []
            }),
            toggleContentTypes: () => inStateContentTypes => ({
                filtersSet: true,
                inStateContentTypes
            }),
            toggleFilingForms: () => inStateFilingForms => ({
                filingFormsSet: true,
                inStateFilingForms
            })
        }
    ),
    withData(),
    withPropsOnChange(
        ['filingFormsSet', 'filtersSet', 'inStateContentTypes', 'inStateFilingForms'],
        ({ filingFormsSet, filtersSet, inStateContentTypes, inStateFilingForms }) => {
            let lenses;
            if (filtersSet) lenses = mapContentTypesToFilters(inStateContentTypes);
            if (filingFormsSet) lenses = mapFilingFormsToFilters(inStateFilingForms);
            return { lenses };
        }
    )
)(ContentStream);
