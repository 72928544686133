import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { NewsSidebarUI } from './ui';

export class NewsSidebar extends PureComponent {
    static displayName = 'NewsSidebarContainer';

    static propTypes = {
        body: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        loading: PropTypes.bool,
        matchCount: PropTypes.number.isRequired,
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        onSearch: PropTypes.func.isRequired,
        selectBlock: PropTypes.func.isRequired,
        selectTab: PropTypes.func.isRequired,
        selectedTab: PropTypes.string.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        body: undefined,
        keyMentions: [],
        loading: false,
        monitorMatches: [],
        shareBookmarks: false,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSearchFocus = this.onSearchFocus.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.scrollToSearchResult = this.scrollToSearchResult.bind(this);
        this.selectDefaultTab = this.selectDefaultTab.bind(this);

        this.state = {
            searchTerm: '',
            searchResults: []
        };
    }

    handleKeywordSearch(searchTerm) {
        const { body, containerRef } = this.props;
        const searchResults = [];

        // Only do the keyword lookup if the search term is at least 2 chars
        if (searchTerm.length > 1 && body) {
            const regex = new RegExp(searchTerm, 'gi');
            const nodes = get(containerRef.current.getElementsByClassName('raw-text'), '[0].children', []);
            nodes.forEach(node => {
                if (node.innerText.match(regex)) {
                    searchResults.push({
                        node,
                        text: node.innerText
                    });
                }
            });
        }

        this.setState({
            searchResults
        });
    }

    onSearchFocus() {
        const { searchTerm } = this.state;
        const { selectedTab, selectTab } = this.props;

        if (searchTerm && searchTerm.length > 0 && selectedTab !== 'searchResults') {
            selectTab('searchResults');
        }
    }

    onSearch({ value }) {
        const { onSearch, selectedTab, selectTab } = this.props;
        this.setState(
            {
                searchTerm: value
            },
            () => {
                if (selectedTab !== 'searchResults' && value && value.length > 0) {
                    selectTab('searchResults');
                } else if (!value || value.length === 0) {
                    this.selectDefaultTab();
                }
                this.handleKeywordSearch(value);
                onSearch(value);
            }
        );
    }

    scrollToSearchResult(index) {
        const { selectBlock } = this.props;
        const { searchResults } = this.state;
        const node = get(searchResults[index], 'node');
        if (node) {
            selectBlock(node);
        }
    }

    selectDefaultTab() {
        const { selectTab } = this.props;
        selectTab('highlights');
    }

    render() {
        const {
            containerRef,
            highlightsFilterKey,
            highlightsSortKey,
            keyMentions,
            loading,
            matchCount,
            monitorMatches,
            selectBlock,
            selectedTab,
            selectTab,
            setHighlightsFilter,
            setHighlightsSort,
            shareBookmarks,
            styles
        } = this.props;
        const { searchTerm, searchResults } = this.state;

        return (
            <NewsSidebarUI
                containerRef={containerRef}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                keyMentions={keyMentions}
                loading={loading}
                matchCount={matchCount}
                monitorMatches={monitorMatches}
                onSearch={this.onSearch}
                onSearchFocus={this.onSearchFocus}
                selectBlock={selectBlock}
                scrollToSearchResult={this.scrollToSearchResult}
                searchResults={searchResults}
                searchTerm={searchTerm}
                selectTab={selectTab}
                selectedTab={selectedTab}
                setHighlightsFilter={setHighlightsFilter}
                setHighlightsSort={setHighlightsSort}
                shareBookmarks={shareBookmarks}
                styles={styles}
            />
        );
    }
}

export const NewsSidebarContainer = compose(withData())(NewsSidebar);
