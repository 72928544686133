import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class Notice extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        type: PropTypes.string,
        heading: PropTypes.string,
        noIcon: PropTypes.bool,
        richContent: PropTypes.bool
    };

    static defaultProps = {
        noIcon: false,
        richContent: false,
        passedStyles: {},
        type: 'error',
        heading: null
    };

    render() {
        const { noIcon, passedStyles, styles, children, heading, theme, type, richContent } = this.props;
        const iconType = {
            success: 'circleCheck',
            error: 'circleX',
            info: 'circleInfo'
        };

        const iconColor = {
            success: theme.colors.success,
            error: theme.colors.error,
            info: theme.colors.info
        };

        const iconContainerStyles = {
            ...styles.iconContainer,
            paddingTop: heading && heading.length > 0 ? 0 : '3px'
        };

        const textContentStyles = {
            ...styles.textContent,
            color: type === 'error' ? theme.colors.error : theme.colors.text
        };

        let containerStyles = styles.container;

        if (type === 'info') {
            containerStyles = {
                ...styles.container,
                ...styles.info
            };
        }

        return (
            <Div styles={{ ...containerStyles, ...passedStyles }}>
                {!noIcon && (
                    <Div styles={iconContainerStyles}>
                        <Icon type={iconType[type]} color={iconColor[type]} />
                    </Div>
                )}
                <Div className="noticeContent" styles={textContentStyles}>
                    {heading && heading.length > 0 && (
                        <Text size={3} weight="medium" styles={styles.heading}>
                            {heading}
                        </Text>
                    )}
                    {!richContent ? (
                        <Text size={3} weight="regular">
                            {children}
                        </Text>
                    ) : (
                        children
                    )}
                </Div>
            </Div>
        );
    }
}

export const NoticeUI = compose(withStyleSheet(styleSheet))(Notice);
