import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { CardEquitiesUI } from './ui';

export class CardEquities extends PureComponent {
    static displayName = 'CardEquitiesContainer';

    static propTypes = {
        equities: PropTypes.arrayOf(PropTypes.any),
        keepOpen: PropTypes.bool,
        showTickers: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        equities: [],
        keepOpen: false,
        showTickers: true,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            expanded: false
        };
    }

    toggle(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(({ expanded }) => ({
            expanded: !expanded
        }));
    }

    render() {
        const { keepOpen, styles, showTickers, equities } = this.props;
        const { expanded } = this.state;
        return (
            <CardEquitiesUI
                equities={equities}
                expanded={expanded}
                keepOpen={keepOpen}
                showTickers={showTickers}
                styles={styles}
                toggle={this.toggle}
            />
        );
    }
}

export const CardEquitiesContainer = compose()(CardEquities);
