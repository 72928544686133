import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { FilingFormAutocompleteUI } from './ui';

function formatFilingForm(form) {
    return {
        label: get(form, 'formNumber'),
        value: get(form, 'id')
    };
}

function formatFilingForms(forms) {
    return (forms || []).map(formatFilingForm);
}

export class FilingFormAutocomplete extends PureComponent {
    static propTypes = {
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        forms: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)])
    };

    static defaultProps = {
        initialSearchTerm: null,
        label: null,
        loading: false,
        multi: false,
        name: 'filingFormAutocomplete',
        onChange: null,
        placeholder: 'Filter by form type',
        forms: [],
        styles: {},
        useTags: false,
        value: null
    };

    constructor(props) {
        super(props);

        this.formatOptions = memoize(formatFilingForms);
        this.getTagLabel = this.getTagLabel.bind(this);
    }

    getTagLabel(tag) {
        const { forms } = this.props;
        const form = (forms || []).find(f => f.id === tag);
        return get(form, 'formNumber');
    }

    render() {
        const {
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onChange,
            placeholder,
            forms,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <FilingFormAutocompleteUI
                getTagLabel={this.getTagLabel}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={onChange}
                options={this.formatOptions(forms)}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const FilingFormAutocompleteContainer = compose(withData())(FilingFormAutocomplete);
