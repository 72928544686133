import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { InputEle } from 'components/InputEle';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class KeywordSearch extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        searchTerm: PropTypes.string.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        resultsIndex: PropTypes.number.isRequired,
        onClear: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        setResultsIndex: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        initInput: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        disabled: false,
        searchResults: null
    };

    renderResults() {
        const { styles, theme, searchTerm, searchResults, resultsIndex, setResultsIndex, onClear } = this.props;

        if (!searchTerm || searchTerm.length === 0) {
            return null;
        }

        const hasSearchTerm = searchTerm && searchTerm.length;
        const hasSearchResults = searchResults && searchResults.length > 0;
        const isPrevResultEnabled = hasSearchResults && resultsIndex > 0;
        const isNextResultsEnabled = hasSearchResults && resultsIndex < searchResults.length - 1;
        const prevResultStyles = isPrevResultEnabled ? styles.resultsButtonEnabled : styles.resultsButtonDisabled;
        const nextResultStyles = isNextResultsEnabled ? styles.resultsButtonEnabled : styles.resultsButtonDisabled;
        const clearResultsStyles = hasSearchTerm ? styles.resultsButtonEnabled : styles.resultsButtonDisabled;
        const prevResultSvgColor = isPrevResultEnabled ? theme.colors.black01 : theme.colors.gray05;
        const nextResultSvgColor = isNextResultsEnabled ? theme.colors.black01 : theme.colors.gray05;
        const clearResultsSvgColor = hasSearchTerm ? theme.colors.black01 : theme.colors.gray05;

        return (
            <Div styles={styles.searchResultsNav}>
                {searchTerm.length === 1 ? (
                    <Text styles={styles.minimumText} size={1}>
                        Min. 2 chars
                    </Text>
                ) : (
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => (
                            <Fragment>
                                {!m && (
                                    <Div styles={styles.numResults}>
                                        {`${searchResults.length ? resultsIndex + 1 : 0}/${searchResults.length}`}
                                    </Div>
                                )}
                                <Div
                                    onClick={() => (isPrevResultEnabled ? setResultsIndex(resultsIndex - 1) : {})}
                                    styles={{ ...styles.prevResult, ...prevResultStyles }}
                                >
                                    <Icon type="chevron" color={prevResultSvgColor} />
                                </Div>
                                <Div
                                    onClick={() => (isNextResultsEnabled ? setResultsIndex(resultsIndex + 1) : {})}
                                    styles={{ ...styles.nextResult, ...nextResultStyles }}
                                >
                                    <Icon type="chevron" color={nextResultSvgColor} />
                                </Div>
                                {!m && (
                                    <Div
                                        onClick={() => (hasSearchTerm ? onClear() : {})}
                                        styles={{ ...styles.clearResults, ...clearResultsStyles }}
                                    >
                                        <Icon type="circleX" color={clearResultsSvgColor} />
                                    </Div>
                                )}
                            </Fragment>
                        )}
                    </MediaQuery>
                )}
            </Div>
        );
    }

    render() {
        const { styles, passedStyles, theme, onSearch, disabled, initInput } = this.props;

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.searchFilter}>
                    <Div styles={styles.searchIcon} className="searchIcon">
                        <Icon type="search03" color={theme.colors.gray06} />
                    </Div>
                    <InputEle
                        styles={styles.searchInput}
                        icon="search"
                        onChange={onSearch}
                        iconColor={theme.colors.black01}
                        placeholder="Find keyword"
                        disabled={disabled}
                        ref={initInput}
                    />
                    {this.renderResults()}
                </Div>
            </Div>
        );
    }
}

export const KeywordSearchUI = compose(withStyleSheet(styleSheet))(KeywordSearch);
