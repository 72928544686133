import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { CloneDashboardTooltip } from 'components/CloneDashboardTooltip';
import { Div } from 'components/Div';
import { EquityScopeAutocomplete } from 'components/EquityScopeAutocomplete';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { TaggedInput } from 'components/TaggedInput';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Textarea } from 'components/Textarea';
import { Toggle } from 'components/Toggle';
import { Tooltip } from 'components/Tooltip';
import { ExternalLink } from 'components/ExternalLink';
import { Autocomplete } from 'components/Autocomplete';
import { WithPermission } from 'components/WithPermission';
import { Notice } from 'components/Notice';
import { PERMISSIONS } from 'consts';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class DashboardForm extends PureComponent {
    static propTypes = {
        buttonsDisabled: PropTypes.bool.isRequired,
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
        categoryOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        dashboardId: PropTypes.string.isRequired,
        deleting: PropTypes.bool.isRequired,
        editing: PropTypes.bool.isRequired,
        equityScope: PropTypes.arrayOf(PropTypes.any),
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        galleryScope: PropTypes.arrayOf(PropTypes.any),
        isOpen: PropTypes.bool.isRequired,
        isSubmitButtonDisabled: PropTypes.bool.isRequired,
        onAddStreamTaggedInput: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onCategoryChange: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        onCreate: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onEquityScopeChange: PropTypes.func.isRequired,
        onGalleryScopeChange: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onRemoveStreamTaggedInput: PropTypes.func.isRequired,
        onStreamTagChange: PropTypes.func.isRequired,
        onStreamTagInputChange: PropTypes.func.isRequired,
        onTagChange: PropTypes.func.isRequired,
        onTagEdit: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchable: PropTypes.bool.isRequired,
        showAdvanced: PropTypes.bool.isRequired,
        showUpgradeModal: PropTypes.func.isRequired,
        streamTaggedInputRefs: PropTypes.objectOf(PropTypes.any).isRequired,
        streamTaggedInputs: PropTypes.arrayOf(PropTypes.string).isRequired,
        streamTags: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        submitting: PropTypes.bool.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleAdvanced: PropTypes.func.isRequired,
        toggleSearchable: PropTypes.func.isRequired,
        touched: PropTypes.objectOf(PropTypes.any).isRequired,
        values: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        equityScope: undefined,
        galleryScope: undefined,
        onClose: undefined,
        passedStyles: {}
    };

    renderQuickStreamCreate() {
        const {
            buttonsDisabled,
            onAddStreamTaggedInput,
            onRemoveStreamTaggedInput,
            onStreamTagChange,
            onStreamTagInputChange,
            onTagEdit,
            streamTaggedInputRefs,
            streamTaggedInputs,
            streamTags,
            styles,
            theme,
            values
        } = this.props;
        const taggedInputs = streamTaggedInputs.map(key => {
            const tags = get(streamTags, key, []);
            return (
                <Div key={key} styles={styles.streamTaggedInputContainer}>
                    <Div className="streamTaggedInputIcon" onClick={() => onRemoveStreamTaggedInput(key)}>
                        <Icon type="circleX" color={theme.colors.black01} />
                    </Div>
                    {tags.length === 0 && (
                        <Text styles={styles.noQuickTerms}>
                            Type a term below, and press enter to start building your search.
                        </Text>
                    )}
                    <TaggedInput
                        autoFocus
                        canEditTags
                        inputRef={streamTaggedInputRefs[key]}
                        name={key}
                        onChange={onStreamTagChange}
                        onInputChange={onStreamTagInputChange}
                        onTagChange={onTagEdit}
                        placeholder="Add search term..."
                        styles={styles.streamTaggedInput}
                        tags={streamTags[key]}
                        textInputStyles={styles.streamTextInput}
                        value={values[key]}
                    />
                </Div>
            );
        });
        const addStreamTooltipContent = (
            <Div>
                <Text size={2}>
                    A search is a feed, with results scoped by keywords,
                    <br />
                    equities, sectors, watchlists and more.
                </Text>
                <Text size={2} styles={styles.addStreamTooltipText}>
                    Add as many searches as you&apos;d like, then create your
                    <br />
                    monitor to edit, filter, and add more searches!
                </Text>
            </Div>
        );
        return (
            <Div styles={styles.bulkStreamsContainer}>
                <Text label styles={styles.bulkStreamsLabel}>
                    bulk search creation
                </Text>
                <Div styles={styles.taggedInputsContainer}>{taggedInputs}</Div>
                <Tooltip
                    content={addStreamTooltipContent}
                    isEnabled
                    slideIn
                    styles={styles.addStreamTooltip}
                    useElementOffsetLeft
                    useElementOffsetTop
                    xOffset={86}
                    yOffset={-116}
                >
                    {({ showTooltip, hideTooltip }) => (
                        <Div styles={styles.addStreamLine} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                            <ActionButton
                                disabled={buttonsDisabled}
                                onClick={onAddStreamTaggedInput}
                                styles={styles.addStreamButton}
                                loaderColor={theme.colors.blue04}
                            >
                                <Icon type="plus" color={theme.colors.blue13} />
                                <Text size={1}>Add Search</Text>
                            </ActionButton>
                        </Div>
                    )}
                </Tooltip>
            </Div>
        );
    }

    renderForm() {
        const {
            categories,
            categoryOptions,
            dashboardId,
            editing,
            equityScope,
            errors,
            galleryScope,
            onBlur,
            onCategoryChange,
            onChange,
            onEquityScopeChange,
            onGalleryScopeChange,
            onKeyDown,
            onTagChange,
            onTagEdit,
            searchable,
            showAdvanced,
            styles,
            tags,
            theme,
            toggleAdvanced,
            toggleSearchable,
            touched,
            values
        } = this.props;
        return (
            <Fragment>
                <TextInput.Formik
                    autoFocus
                    autoComplete="off"
                    styles={styles.input}
                    label="name *"
                    required
                    name="name"
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder="Enter monitor name..."
                    error={touched.name && errors.name}
                    value={values.name}
                />
                <Div
                    styles={showAdvanced ? styles.toggleAdvanced : styles.toggleAdvancedClosed}
                    onClick={toggleAdvanced}
                >
                    <Icon type="chevron02" color={theme.colors.gray06} />
                    <Text size={1}>{showAdvanced ? 'Hide advanced settings' : 'Show advanced settings'}</Text>
                </Div>
                {showAdvanced && (
                    <Fragment>
                        {editing && (
                            <Notice richContent type="info" styles={styles.dashId}>
                                <Text size={3} weight="medium">
                                    Monitor ID for API usage
                                </Text>
                                <Text size={3}>{dashboardId}</Text>
                            </Notice>
                        )}
                        <Textarea.Formik
                            styles={styles.textarea}
                            label="description"
                            name="description"
                            onBlur={onBlur}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            placeholder={
                                `Optionally describe your monitor for future reference, \nor in case you share it with ` +
                                'your team.'
                            }
                            error={touched.description && errors.description}
                            value={values.description}
                            maxLength={120}
                        />
                        <Tooltip
                            content={<Text size={3}>Searchable monitors can be cloned by members of your team.</Text>}
                            isEnabled
                            styles={styles.tooltip}
                            useElementOffsetLeft
                            useElementOffsetTop
                            xOffset={0}
                            yOffset={-62}
                        >
                            {({ showTooltip, hideTooltip }) => (
                                <Div
                                    styles={styles.visibilityContainer}
                                    onClick={toggleSearchable}
                                    onMouseEnter={showTooltip}
                                    onMouseLeave={hideTooltip}
                                >
                                    <Toggle
                                        formLabel="Visibility"
                                        leftLabel="Searchable by my team"
                                        styles={styles.visibilityToggle}
                                        on={searchable}
                                        gray
                                    />
                                </Div>
                            )}
                        </Tooltip>
                        <EquityScopeAutocomplete
                            label="Filters"
                            name="equityScope"
                            onChange={onEquityScopeChange}
                            styles={styles.equityScope}
                            value={equityScope}
                        />
                        {!editing && this.renderQuickStreamCreate()}
                        <WithPermission permissions={PERMISSIONS.writeDashGallery}>
                            <Div styles={styles.galleryNote}>
                                <Text size={3} weight="medium">
                                    Gallery Classifiers
                                </Text>
                                <TaggedInput
                                    canEditTags
                                    label="Search Tags"
                                    name="tags"
                                    onChange={onTagChange}
                                    onInputChange={({ event }) => onChange(event)}
                                    onTagChange={onTagEdit}
                                    placeholder="Enter words that describe this monitor..."
                                    styles={styles.tags}
                                    tags={tags}
                                    topRightText='press the "enter&ldquo; key to add term'
                                    topRightTextAlt='press the "delete&ldquo; key to remove term'
                                    value={values.tags}
                                />
                                <EquityScopeAutocomplete
                                    label="Equity Scope"
                                    name="galleryScope"
                                    onChange={onGalleryScopeChange}
                                    styles={styles.equityScope}
                                    value={galleryScope}
                                />
                                <Autocomplete
                                    styles={styles.galleryCategories}
                                    label="Categories"
                                    multi
                                    useTags
                                    name="galleryCategories"
                                    onChange={onCategoryChange}
                                    options={categoryOptions}
                                    placeholder="Select at least one category"
                                    value={categories}
                                />
                            </Div>
                        </WithPermission>
                    </Fragment>
                )}
            </Fragment>
        );
    }

    renderButtons() {
        const {
            buttonsDisabled,
            dashboardId,
            deleting,
            editing,
            isSubmitButtonDisabled,
            onCancel,
            onClose,
            onCreate,
            onDelete,
            onUpdate,
            showUpgradeModal,
            styles,
            submitting,
            theme,
            values
        } = this.props;
        const requestTooltip = (
            <Text size={3}>
                Send Aiera Research a topic you&apos;d like monitored. Our staff will get busy building it specially for
                you.
            </Text>
        );

        let buttons;

        // Edit dashboard form
        if (editing) {
            buttons = (
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => (
                        <Fragment>
                            <ActionButton
                                disabled={buttonsDisabled}
                                loaderColor={theme.colors.gray08}
                                loading={deleting}
                                onClick={onDelete}
                                size={2}
                                styles={{ ...styles.button, ...styles.buttonDelete }}
                            >
                                {!m && <Icon type="trash02" color={theme.colors.gray04} />}
                                <Text size={3}>Remove</Text>
                            </ActionButton>
                            <CloneDashboardTooltip
                                onClone={onClose}
                                dashboardId={dashboardId}
                                tooltipOptions={{
                                    styles: styles.tooltipClone,
                                    xOffset: -1,
                                    yOffset: -61
                                }}
                                value={values.name}
                            >
                                {({ showTooltip }) => (
                                    <ActionButton
                                        disabled={buttonsDisabled}
                                        onClick={showTooltip}
                                        size={2}
                                        styles={{ ...styles.button, ...styles.buttonClone }}
                                    >
                                        {!m && <Icon type="copy" color={theme.colors.gray04} />}
                                        <Text size={3}>Clone</Text>
                                    </ActionButton>
                                )}
                            </CloneDashboardTooltip>
                            <ActionButton size={2} styles={styles.cancelButton} onClick={onCancel}>
                                <Text size={3}>Cancel</Text>
                            </ActionButton>
                            <ActionButton
                                size={2}
                                disabled={buttonsDisabled || isSubmitButtonDisabled}
                                loading={submitting}
                                loaderColor={theme.colors.white01}
                                onClick={onUpdate}
                                styles={{
                                    ...styles.button,
                                    ...styles.buttonSubmit,
                                    ...(buttonsDisabled || isSubmitButtonDisabled ? styles.buttonDisabled : {})
                                }}
                            >
                                <Text size={3}>{m ? 'Save' : 'Save Changes'}</Text>
                            </ActionButton>
                        </Fragment>
                    )}
                </MediaQuery>
            );
        }

        // Create dashboard form
        if (!editing) {
            buttons = (
                <Fragment>
                    <WithPermission permissions={PERMISSIONS.unlockedRequestCustomDashboard}>
                        {({ restricted, isLoading }) => (
                            <Tooltip
                                styles={styles.tooltip}
                                isEnabled
                                useElementOffsetLeft
                                useElementOffsetTop
                                xOffset={0}
                                yOffset={-84}
                                content={requestTooltip}
                            >
                                {({ showTooltip, hideTooltip }) =>
                                    restricted || isLoading ? (
                                        <Div
                                            onMouseEnter={showTooltip}
                                            onMouseLeave={hideTooltip}
                                            styles={styles.requestButton}
                                            onClick={isLoading ? undefined : showUpgradeModal}
                                        >
                                            <Icon type="lock02" color={theme.colors.gray04} />
                                            <Text size={3}>Monitor Request</Text>
                                        </Div>
                                    ) : (
                                        <ExternalLink
                                            onMouseEnter={showTooltip}
                                            onMouseLeave={hideTooltip}
                                            styles={styles.requestButton}
                                            href="mailto:research@aiera.com?subject=Hi! I would like this topic covered in a monitor. Could you build it for me?&body=Here is the topic I would like covered:"
                                        >
                                            <Icon type="gift" color={theme.colors.gray04} />
                                            <Text size={3}>Monitor Request</Text>
                                        </ExternalLink>
                                    )
                                }
                            </Tooltip>
                        )}
                    </WithPermission>
                    <Div styles={styles.spacer} />
                    <ActionButton size={2} styles={styles.cancelButton} onClick={onCancel}>
                        <Text size={3}>Cancel</Text>
                    </ActionButton>
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSubmit,
                            ...(buttonsDisabled || isSubmitButtonDisabled ? styles.buttonDisabled : {})
                        }}
                        disabled={buttonsDisabled || isSubmitButtonDisabled}
                        loading={submitting}
                        loaderColor={theme.colors.white01}
                        onClick={onCreate}
                        size={2}
                    >
                        <Text size={3}>Create Monitor</Text>
                    </ActionButton>
                </Fragment>
            );
        }

        return <Div styles={styles.buttonsContainer}>{buttons}</Div>;
    }

    render() {
        const { styles, passedStyles, editing, isOpen, onCancel } = this.props;
        return (
            <Modal isOpen={isOpen} onModalClose={onCancel} title={`${editing ? 'Edit' : 'Create'} Monitor`}>
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div styles={styles.content}>{this.renderForm()}</Div>
                    {this.renderButtons()}
                </Div>
            </Modal>
        );
    }
}

export const DashboardFormUI = compose(withStyleSheet(styleSheet))(DashboardForm);
