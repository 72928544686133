import gql from 'graphql-tag';
import { compose } from 'recompose';
import { notificationMatchFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges
} from 'hoc/streams';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';

export const withData = () =>
    compose(
        withMemo({
            formatMatches: formatMatchesByType,
            mapFilters: mapDashFiltersToRules
        }),
        graphql(
            gql`
                query withNotificationList(
                    $size: Int = 20
                    $offset: Int = 0
                    $filter: StreamMatchFilter
                    $withMatches: Boolean = false
                ) {
                    notificationsStream {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: $filter) @include(if: $withMatches) {
                            results {
                                ...notificationMatch
                            }
                        }
                        rules @include(if: $withMatches) {
                            ...rule
                        }
                        unreadCount
                    }
                }
                ${streamFragment}
                ${notificationMatchFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { fetchMore, loading, matches, refetch, rules, stream, subtitle } = getDefaultStreamProps(
                        data,
                        formatMatches,
                        'notificationsStream'
                    );
                    return {
                        fetchMore,
                        loading,
                        matches,
                        refetch,
                        rules,
                        stream,
                        subtitle,
                        unreadCount: get(data, 'notificationsStream.unreadCount', 0)
                    };
                },
                options: ({
                    dashDateRange,
                    dashSearchTerm,
                    dashSources,
                    dashEquityScope,
                    isTooltipOpen,
                    mapFilters,
                    streamId
                }) => {
                    const defaultStreamOptions = getDefaultStreamOptions({
                        dashDateRange,
                        dashSearchTerm,
                        dashSources,
                        dashEquityScope,
                        mapFilters,
                        streamId,
                        streamType: 'notifications'
                    });
                    return {
                        ...defaultStreamOptions,
                        variables: {
                            ...defaultStreamOptions.variables,
                            withMatches: !!isTooltipOpen
                        }
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation MarkNotificationsRead {
                    markNotificationsRead {
                        query {
                            notificationsStream {
                                ...stream
                                unreadCount
                            }
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    markNotificationsRead: () =>
                        mutate().catch(error => {
                            setStatusBanner(`Error marking alerts read: ${error}`, 'error', 'circleX');
                        })
                })
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging({ matchesPath: 'notificationsStream' })
    );
