import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { PremiumResearchFilterUI } from './ui';

export class PremiumResearchFilter extends PureComponent {
    static displayName = 'PremiumResearchFilterContainer';

    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        onChange: undefined,
        name: 'eventFilter',
        value: null
    };

    render() {
        const { name, value, onChange } = this.props;
        return <PremiumResearchFilterUI value={value} onChange={onChange} name={name} />;
    }
}

export const PremiumResearchFilterContainer = compose()(PremiumResearchFilter);
