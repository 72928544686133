import gql from 'graphql-tag';
import { mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';

export const getCountriesQuery = alias => gql`
    query ${alias || 'GetCountries'} {
        countries {
            id
            countryCode
            countryCode3
            shortName
            legalName
        }
    }
`;

export const withCountries = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withCountries';
    return graphql(getCountriesQuery(alias), {
        props: ({ data }) => ({
            countriesError: data.error,
            countriesLoading: data.loading,
            countries: data.countries
        }),
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};
