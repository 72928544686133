import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Table } from 'components/Table';
import { Tr } from 'components/Tr';
import { Th } from 'components/Th';
import { Td } from 'components/Td';
import { CirclePlusButton } from 'components/CirclePlusButton';
import { PopupSearchMenu } from 'components/PopupSearchMenu';
import { Hint } from 'components/Hint';

import { ExpandedDifferentialRow } from '../ExpandedDifferentialRow';
import { EventStreamMatchDifferentials } from '../EventStreamMatchDifferentials';
import { styleSheet } from './stylesheet';

function highlightsText(highlights) {
    let highlightString = 'highlights';
    if (highlights.length === 1) {
        highlightString = 'highlight';
    }
    return highlightString;
}

class EventDifferentials extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showMenu: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        expandedRowTitle: PropTypes.string,
        toggleMenu: PropTypes.func.isRequired,
        expandRow: PropTypes.func.isRequired,
        equityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        collapseRow: PropTypes.func.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        financials: PropTypes.arrayOf(PropTypes.any),
        financialColumnLabels: PropTypes.arrayOf(PropTypes.any),
        menuResults: PropTypes.arrayOf(PropTypes.any),
        menuOffset: PropTypes.number,
        onRemoveFinancial: PropTypes.func.isRequired,
        onSearchFinancials: PropTypes.func.isRequired,
        onSelectOption: PropTypes.func.isRequired,
        eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleFinancialMenuRef: PropTypes.func.isRequired,
        // callType: PropTypes.string,
        // isComplete: PropTypes.bool.isRequired,
        shareId: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        expandedRowTitle: null,
        showMenu: null,
        menuResults: [],
        menuOffset: null,
        financials: [],
        financialColumnLabels: [],
        equityId: null,
        eventId: null,
        // callType: null,
        shareId: null
    };

    renderCell(value, styleOverride, className) {
        const { styles } = this.props;

        return (
            <Td className={className} styles={styleOverride || styles.tableRowValue}>
                <Text size={2}>{value}</Text>
            </Td>
        );
    }

    renderHeaderCell(value, styleOverride) {
        const { styles } = this.props;

        return (
            <Th styles={styleOverride || styles.tableHeaderColumnLabel}>
                <Text size={0} uppercase>
                    {value}
                </Text>
            </Th>
        );
    }

    renderHeaderTitleCell({ title, onClick, includeFinancialMenu, toolTip }) {
        const { styles, toggleMenu, handleFinancialMenuRef } = this.props;

        const clickHandler = includeFinancialMenu
            ? () => {
                  toggleMenu('new');
              }
            : onClick;

        return (
            <Th rowSpan={2} styles={styles.tableHeaderTitle}>
                <Div styles={styles.headerTitleWrapper}>
                    {toolTip ? (
                        <Hint text="How it works" description={toolTip}>
                            <Text size={3} weight="medium">
                                {title}
                            </Text>
                        </Hint>
                    ) : (
                        <Text size={3} weight="medium">
                            {title}
                        </Text>
                    )}
                    <CirclePlusButton
                        styles={styles.plusButton}
                        onClick={clickHandler}
                        ref={node => handleFinancialMenuRef(node, 'new')}
                    />
                </Div>
            </Th>
        );
    }

    renderFinancials() {
        const {
            styles,
            theme,
            financials,
            expandedRowTitle,
            expandRow,
            collapseRow,
            toggleMenu,
            financialColumnLabels,
            onNodeSelect,
            handleFinancialMenuRef
        } = this.props;

        const financialRows = financials.map((f, idx) => {
            const { locked } = f;
            const isExpanded = expandedRowTitle && expandedRowTitle.toLowerCase() === f.title.toLowerCase();
            const boxType = isExpanded ? 'boxMinus' : 'boxPlus';
            const boxColor = isExpanded ? theme.colors.black01 : theme.colors.gray04;
            const toggleExpand = isExpanded ? () => collapseRow(f.title) : () => expandRow(f.title);
            const isLastRow = idx === financials.length - 1;
            let tableStyles = isExpanded ? styles.tableRowExpanded : styles.tableRow;

            if (isLastRow && !isExpanded) {
                tableStyles = { ...tableStyles, ...styles.tableRowNoBottomBorder };
            }

            return (
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile} key={`financials-${f.title}`}>
                    {m => (
                        <Fragment>
                            <Tr styles={tableStyles}>
                                <Td styles={styles.tableRowTitle}>
                                    <Div styles={styles.rowTitleWrapper}>
                                        <Div styles={styles.titlePlus} onClick={toggleExpand}>
                                            <Icon type={boxType} color={boxColor} />
                                            <Div>
                                                <Text
                                                    size={3}
                                                    weight={isExpanded ? 'medium' : 'normal'}
                                                    styles={styles.rowTitleText}
                                                >
                                                    {f.title}
                                                </Text>
                                                <Text size={0} uppercase styles={styles.rowSubtext}>
                                                    {`${f.highlights.length} ${highlightsText(f.highlights)}`}
                                                </Text>
                                            </Div>
                                        </Div>
                                        {/* eslint-disable jsx-a11y/mouse-events-have-key-events */}
                                        {locked ? (
                                            <Div styles={styles.lockedFinancial} className="lockedFinancial">
                                                <Icon type="lock" color={theme.colors.gray01} />
                                            </Div>
                                        ) : (
                                            <Div
                                                styles={styles.dropDownButton}
                                                className="dropDownButton"
                                                onClick={() => toggleMenu(f.title, f.userFinancialKpiId)}
                                                ref={node => handleFinancialMenuRef(node, f.title)}
                                            >
                                                <Icon type="change" color={theme.colors.gray04} />
                                            </Div>
                                        )}
                                    </Div>
                                </Td>
                                {this.renderCell(f.columnData[0], {
                                    ...styles.tableRowValue,
                                    ...styles.tableCellPaddingLeft
                                })}
                                {!m && this.renderCell(f.columnData[1])}
                                {this.renderCell(f.columnData[2])}
                                {!m &&
                                    this.renderCell(
                                        f.columnData[3],
                                        {
                                            ...styles.tableRowValue,
                                            ...styles.tableCellBorderLeftBottom
                                        },
                                        'finanicalDivider'
                                    )}
                                {!m && this.renderCell(f.columnData[4])}
                            </Tr>
                            {isExpanded && (
                                <ExpandedDifferentialRow
                                    onNodeSelect={onNodeSelect}
                                    colSpan={6}
                                    labels={f.chartLabels}
                                    data={[
                                        { name: 'Actuals', color: '#8C9FAD', data: f.chartActuals },
                                        {
                                            name: 'Estimates',
                                            data: f.chartEstimates,
                                            color: '#AEC2D1'
                                        }
                                    ]}
                                    highlights={f.highlights}
                                />
                            )}
                        </Fragment>
                    )}
                </MediaQuery>
            );
        });

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Fragment>
                        <Tr styles={styles.tableHeaderRow}>
                            {this.renderHeaderTitleCell({
                                title: 'Financials',
                                includeFinancialMenu: true,
                                toolTip:
                                    'Equity terms populate based on a stock specific ontology. By default, event terms are based on historic importance. You can also set your own custom terms, which will appear unique to you.'
                            })}
                            <Th
                                colSpan={m ? 2 : 3}
                                styles={{ ...styles.tableHeaderGroupLabel, ...styles.tableCellPaddingLeft }}
                            >
                                <Text size={0} uppercase weight="medium">
                                    variance
                                </Text>
                            </Th>
                            {!m && (
                                <Th
                                    colSpan={2}
                                    styles={{ ...styles.tableHeaderGroupLabel, ...styles.tableCellBorderLeft }}
                                >
                                    <Text size={0} uppercase weight="medium">
                                        Y/Y Growth
                                    </Text>
                                </Th>
                            )}
                        </Tr>
                        <Tr styles={styles.tableHeaderRowShadow}>
                            {this.renderHeaderCell(financialColumnLabels[0])}
                            {!m && this.renderHeaderCell(financialColumnLabels[1])}
                            {this.renderHeaderCell(financialColumnLabels[2])}
                            {!m &&
                                this.renderHeaderCell(financialColumnLabels[3], {
                                    ...styles.tableHeaderColumnLabel,
                                    ...styles.tableColumnLabelCellBorderLeftBottom
                                })}
                            {!m && this.renderHeaderCell(financialColumnLabels[4])}
                        </Tr>
                        {financialRows}
                    </Fragment>
                )}
            </MediaQuery>
        );
    }

    render() {
        const {
            styles,
            passedStyles,
            showMenu,
            menuResults,
            menuOffset,
            toggleMenu,
            onSearchFinancials,
            onSelectOption,
            onRemoveFinancial,
            // callType,
            // isComplete,
            eventId,
            equityId,
            onNodeSelect,
            shareId
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {showMenu && (
                    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
                    <PopupSearchMenu
                        className="tab-tooltip"
                        searchPlaceholder="Search financials..."
                        styles={{ ...styles.popUp, top: menuOffset }}
                        onClose={() => toggleMenu(null)}
                        onSearch={onSearchFinancials}
                        onSelectOption={onSelectOption}
                        onRemove={showMenu !== 'new' ? onRemoveFinancial : undefined}
                        initialSearchText={showMenu !== 'new' ? showMenu : undefined}
                        options={menuResults}
                    />
                )}
                <Div styles={styles.tableWrapper}>
                    <Table styles={styles.table}>
                        {/* callType === 'earnings' && isComplete && this.renderFinancials() */}
                        <EventStreamMatchDifferentials
                            equityId={equityId}
                            eventId={eventId}
                            shareId={shareId}
                            onNodeSelect={onNodeSelect}
                        />
                    </Table>
                </Div>
            </Div>
        );
    }
}

export const EventDifferentialsUI = compose(withStyleSheet(styleSheet))(EventDifferentials);
