import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class ActionMenu extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        hideTooltip: PropTypes.func,
        hoverOpen: PropTypes.bool.isRequired,
        menuStyles: PropTypes.objectOf(PropTypes.any),
        openByDefault: PropTypes.bool.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        onHide: PropTypes.func,
        onShow: PropTypes.func
    };

    static defaultProps = {
        onHide: undefined,
        onShow: undefined,
        className: undefined,
        hideTooltip: undefined,
        menuStyles: {},
        passedStyles: {}
    };

    constructor(props) {
        super(props);

        this.renderMenu = this.renderMenu.bind(this);
    }

    renderMenu(manageTooltip) {
        const { className, menuStyles, options, styles, theme, hideTooltip } = this.props;
        const closeTooltip = get(manageTooltip, 'hideTooltip', hideTooltip);

        return (
            <Div styles={{ ...styles.menu, ...menuStyles }} className={className}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {isMobile =>
                        options.flatMap(
                            ({
                                closeOnClick,
                                content,
                                hideMobile,
                                iconColor = theme.colors.gray04,
                                iconType,
                                label,
                                loading,
                                name,
                                onClick,
                                permissions
                            }) =>
                                !isMobile || (isMobile && !hideMobile)
                                    ? [
                                          <WithPermission permissions={permissions} key={name}>
                                              <Div
                                                  data-tname={`actionMenu-${name}`}
                                                  onClick={e => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                  }}
                                                  onMouseDown={e => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      if (!loading && onClick) onClick(e);
                                                  }}
                                                  onMouseUp={e => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      if (closeOnClick && closeTooltip) closeTooltip(e);
                                                  }}
                                                  onTouchStart={loading ? undefined : onClick}
                                                  onTouchEnd={closeOnClick ? closeTooltip : undefined}
                                                  styles={styles.menuItem}
                                              >
                                                  {loading ? (
                                                      <LoaderLogo height={24} styles={styles.loading} />
                                                  ) : (
                                                      content || (
                                                          <Fragment>
                                                              <Icon type={iconType} color={iconColor} />
                                                              <Text size={1}>{label}</Text>
                                                          </Fragment>
                                                      )
                                                  )}
                                              </Div>
                                          </WithPermission>
                                      ]
                                    : []
                        )
                    }
                </MediaQuery>
            </Div>
        );
    }

    render() {
        const { onHide, onShow, hoverOpen, openByDefault, passedStyles, styles, theme } = this.props;

        if (openByDefault) {
            // Pass "hideTooltip" as a prop
            // when using this with a tooltip
            return this.renderMenu();
        }

        return (
            <Tooltip
                content={this.renderMenu}
                isEnabled
                useElementOffsetRight
                useElementOffsetTop
                growLeft
                xOffset={0}
                yOffset={0}
                persistOnMouseExit
                blockBackground // blocks user scrolling
                onHide={onHide}
                onShow={onShow}
                hideOnScroll={false} // In live events scrolling is forced
            >
                {({ showTooltip }) => (
                    <Div
                        className="actionMenu-hover-btn"
                        onClick={
                            hoverOpen
                                ? undefined
                                : e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showTooltip(e);
                                  }
                        }
                        onMouseEnter={hoverOpen ? showTooltip : undefined}
                        onMouseUp={
                            hoverOpen
                                ? undefined
                                : e => {
                                      // Prevents unintended affects
                                      // of selecting this node in other UIs
                                      // specifically transcript event items
                                      e.preventDefault();
                                      e.stopPropagation();
                                  }
                        }
                        styles={{ ...styles.container, ...passedStyles }}
                    >
                        <Icon type="more" color={theme.colors.gray06} />
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const ActionMenuUI = compose(withStyleSheet(styleSheet))(ActionMenu);
