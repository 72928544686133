/* eslint-disable */
import EventEmitter from 'events';
import autoBind from 'auto-bind';
import XDate from 'xdate';
import { config } from 'configuration';
import { mobileBridge } from 'provider/mobileBridge';

/*
 * This class was originally written by Gridspace, and it's been modified
 * to add DTMF tone support, microphone decibel tracking, and the ability
 * to store arbitrary connection data so consumers can see what we are
 * currently connected to. There should only ever be one instance of this
 * class.
 */
export class WebRTC extends EventEmitter {
    State = {
        UNINITIALIZED: 'uninitialized', // Initial state, have not received voip token.
        AUTHENTICATING: 'authenticating', // Sent authentication token, waiting for response.
        AUTHENTICATED: 'authenticated', // Auth successful, ready to connect.
        RINGING: 'ringing', // Incoming call is connecting.
        CONNECTING: 'connecting', // Establishing a connection.
        CONNECTED: 'connected' // Connected to a call.
    };

    Error = {
        NONE: 'none',
        UNKNOWN: 'unknown',
        LOST_INTERNET: 'lost-internet',
        NO_MIC: 'no-mic',
        MIC_DISABLED: 'mic-disabled',
        MIC_PERMISSION_DENIED: 'mic-permission-denied',
        AUTHENTICATION_FAILED: 'authentication-failed',
        OUTGOING_NOT_PERMITTED: 'outgoing-not-permitted'
    };

    constructor(options = {}) {
        super();
        autoBind(this);

        this._uri = options.uri || 'wss://api.gridspace.com/webrtc/';
        this._state = 'uninitialized';
        this._sock = null;
        this._localMediaStream = null;
        this.connection = null;
        this.decibels = 0;
        this._calltimer = null;

        // Handle mobile webrtc
        mobileBridge.on('webrtc:event', (...args) => {
            this.emit(...args);
        });
        this.on('connection', connection => (this.connection = connection));
    }

    voipSupported() {
        return !!window.RTCPeerConnection;
    }

    authenticateMobile(...args) {
        mobileBridge.emit('webrtc:rpc', { cmd: 'authenticate', args });
    }

    authenticate(token, pcConfig) {
        if (this._state !== this.State.UNINITIALIZED) {
            throw 'Cannot call authenticate twice in a row';
        }

        if (!token) {
            throw 'Must provide a token';
        }

        this._state = this.State.AUTHENTICATING;

        // Create the audio element if needed.
        if (!document.getElementById('audio_remote')) {
            var remote_audio = document.createElement('audio');
            remote_audio.id = 'audio_remote';
            remote_audio.autoplay = true;
            document.body.appendChild(remote_audio);
        }

        this._token = token;
        this._pcConfig = pcConfig;

        this._sock = new WebSocket(this._uri);
        this._sock.onopen = this._onSocketConnected.bind(this);

        this._sock.onmessage = function(event) {
            var data = JSON.parse(event.data);
            this._onSocketMessage(data);
        }.bind(this);
        this._sock.onerror = this._onSocketError.bind(this);
        this._sock.onclose = this._onSocketClose.bind(this);
    }

    sendDTMF(tone) {
        if (this.connection) {
            const { metaData } = this.connection;
            fetch(
                `${config.API_ENDPOINT}/hooks/gridspace/call/connection/${metaData.id}/dtmf?token=${metaData.token}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ digits: tone })
                }
            );
            this.connection = {
                ...this.connection,
                tones: [...this.connection.tones, tone]
            };
        }
    }

    connectMobile(...args) {
        mobileBridge.emit('webrtc:rpc', { cmd: 'connect', args });
    }

    connect(data, listenOnly, metaData) {
        // Initiate an outbound call.
        // Call will be handled by the application bound to the Token passed to init. Data is an
        // optional opaque json-serializable object that will be passed in the POST parameters
        // to the initial connection url associated with the application.
        if (this._state !== this.State.AUTHENTICATED || !this._token) {
            throw 'Initialize before calling ready';
        }

        // Ready to begin the voip connection!
        this._state = this.State.CONNECTING;
        this._send({ type: 'connect', body: data });
        this._connect(listenOnly, { ...data, metaData });
    }

    mute() {
        this.setMuted(true);
    }

    unmute() {
        this.setMuted(false);
    }

    setMuted(muted) {
        if (muted != this.muted) {
            this.muted = muted;

            if (!this._localMediaStream || !this._localMediaStream.getAudioTracks) {
                return;
            }
            var tracks = this._localMediaStream.getAudioTracks();
            tracks.forEach(function(track) {
                track.enabled = !muted;
            });
            this.emit('muted', this.muted);
        }
    }

    // Set the audio output volume.
    // ``volume`` is a float from 0.0 (silent) to 1.0.
    setSpeakerVolume(volume) {
        var remoteAudio = document.getElementById('audio_remote');
        if (remoteAudio) {
            remoteAudio.volume = volume;
        }
    }

    hangUp() {
        // Hang up an active connection.
        if (this._state === this.State.CONNECTED) {
            this._state = this.State.AUTHENTICATED;
            console.debug('Voip: hangup');
            this._send({ type: 'hangup' });
            this._disconnect();
        }
    }

    resetMobile(...args) {
        mobileBridge.emit('webrtc:rpc', { cmd: 'reset', args });
    }

    reset() {
        console.debug('voip: reset');
        this.hangUp();
        if (this._sock) {
            this._sock.onmessage = null;
            this._sock.close();
            this._sock = null;
        }
        this._token = null;
        this._state = this.State.UNINITIALIZED;
        clearInterval(this._callTimer);
        this.setMuted(false);
    }

    get state() {
        return this._state;
    }

    get listenOnly() {
        return !this._localMediaStream;
    }

    _onVoipConnected(stream, connectionData) {
        if (this._state == this.State.CONNECTING) {
            this._state = this.State.CONNECTED;
            this.connection = {
                ...connectionData,
                startTime: new XDate(),
                duration: 0,
                currentTime: 0,
                tones: []
            };
            clearInterval(this._callTimer);
            this._callTimer = setInterval(() => {
                const currentTime = this.connection.startTime.diffSeconds(new XDate());
                this.connection = {
                    ...this.connection,
                    duration: currentTime,
                    currentTime
                };
                this.emit('timeChange', this.connection.currentTime);
            }, 500);
            // Latest chrome seems to support this
            // https://discourse.wicg.io/t/hint-attribute-in-webrtc-to-influence-underlying-audio-video-buffering/4038
            // See: https://jsfiddle.net/75cnfojy/
            this._peerConnection.getReceivers().forEach(receiver => {
                receiver.playoutDelayHint = 1;
            });
        } else {
            this._disconnect();
        }
    }

    _send(message) {
        if (this._sock) {
            this._sock.send(JSON.stringify(message));
        }
    }

    _onSocketConnected() {
        if (this._state === this.State.AUTHENTICATING) {
            // Send the auth token.
            this._send({
                type: 'auth',
                body: this._token
            });
        }
    }

    _onSocketError() {
        if (this._state !== this.State.UNINITIALIZED) {
            this._disconnect();
            this.emit('error', this.Error.LOST_INTERNET);
        }
    }

    _onSocketClose() {
        if (this._state === this.State.CONNECTED) {
            if (this._resumeToken) {
                console.warn('Socket disconnected unexpectedly, trying to reconnect');
                this.reconnect();
            } else {
                console.error('Socket disconnected unexpectedly');
            }
        }
    }

    reconnect() {
        this._sock = new WebSocket(this._uri);
        this._sock.onopen = () => {
            // Try to resume
            this._send({
                type: 'resume',
                body: this._resumeToken
            });
        };

        this._sock.onmessage = function(event) {
            var data = JSON.parse(event.data);
            this._onSocketMessage(data);
        }.bind(this);
        this._sock.onerror = this._onSocketError.bind(this);
        this._sock.onclose = this._onSocketClose.bind(this);
    }

    answer() {
        if (this._state === this.State.RINGING) {
            this._send({
                type: 'ringanswer',
                body: true
            });
            this.muted = false;
            this._state = this.State.CONNECTING;
            this._connect();
        }
    }

    decline() {
        if (this._state === this.State.RINGING) {
            this._send({
                type: 'ringanswer',
                body: false
            });
            this._state = this.State.AUTHENTICATED;
        }
    }

    _onSocketMessage(message) {
        console.debug('Rtc sock message:\n', message);
        if (message.type === 'auth') {
            if (this._state === this.State.AUTHENTICATING) {
                if (message.body === 'OK') {
                    this._resumeToken = message.resume_token;
                    this._state = this.State.AUTHENTICATED;
                    this.emit('authenticated');
                } else {
                    this._resumeToken = null;
                    this._token = null;
                    this._state = this.State.UNINITIALIZED;
                    this.emit('error', this.Error.AUTHENTICATION_FAILED);
                }
            }
        } else if (message.type === 'ring') {
            if (this._state === this.State.AUTHENTICATED) {
                this._state = this.State.RINGING;
                this.emit('incoming-call');
            }
        } else if (message.type === 'desc') {
            var desc = message.body;
            if (desc.type == 'answer') {
                this._peerConnection.setRemoteDescription(new RTCSessionDescription(desc));
            } else {
                console.debug('Unsupported SDP type. Your code may differ here.');
            }
        } else if (message.type === 'candidate') {
            this._peerConnection.addIceCandidate(new RTCIceCandidate(message.body));
        } else if (message.type === 'hangup') {
            if (this._state === this.State.CONNECTED) {
                this._state = this.State.AUTHENTICATED;
                this._disconnect();
                this.emit('remote-party-hung-up');
            }
        } else if (message.type === 'transfer') {
            if (this._state === this.State.CONNECTED) {
                // Don't do a full disconnect because we want to keep the media stream.
                if (this._peerConnection) {
                    this._peerConnection.close();
                    this._peerConnection = null;
                }
                this._state = this.State.CONNECTING;
                this._connect();
            }
        } else if (message.type === 'reset') {
            this.reset();
        } else if (message.type === 'error') {
            console.error('RTC error message: ', message);
        } else if (message.type === 'resume') {
            console.log('webrtc connection resumed');
        } else {
            console.error('Unknown message type:', message.type);
        }
    }

    _addStream(stream) {
        stream.getTracks().forEach(track => {
            this._peerConnection.addTrack(track, stream);
        });
    }

    getMediaStream() {
        return navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    }

    _trackAudio() {
        const AudioContext = window.AudioContext || window.webkitAudioContext;

        if (!this._localMediaStream || !AudioContext) return;

        const ctx = new AudioContext();
        const source = ctx.createMediaStreamSource(this._localMediaStream);

        const analyser = ctx.createAnalyser();
        analyser.fftSize = 32;
        const freqData = new Uint8Array(analyser.frequencyBinCount);

        source.connect(analyser);

        const getCurrentDecibels = () => {
            analyser.getByteFrequencyData(freqData);
            const decibels = this._localMediaStream ? freqData.reduce((sum, cur) => sum + cur, 0) / (16 * 255) : 0;

            this.decibels = decibels;
            this.emit('decibels', decibels);

            if (this._localMediaStream) {
                // If we are still connected, get the next freqData as soon as possible
                this._animationFrame = requestAnimationFrame(getCurrentDecibels);
            } else {
                ctx.close();
            }
        };

        this._animationFrame = requestAnimationFrame(getCurrentDecibels);
    }

    _connect(listenOnly, connectionData) {
        console.debug('Using RTCPeerConfiguration', this._pcConfig);
        this._peerConnection = new RTCPeerConnection(this._pcConfig);

        this._peerConnection.onicecandidate = function(evt) {
            console.debug('onicecandidate', evt);
            if (evt.candidate) {
                this._send({
                    type: 'candidate',
                    body: evt.candidate
                });
            } else {
                this.emit('connected');
            }
        }.bind(this);

        // let the "negotiationneeded" event trigger offer generation
        this._peerConnection.onnegotiationneeded = function() {
            console.debug('ON negotiationneeded');
            this._createAndSendOffer();
        }.bind(this);

        // once remote video track arrives, show it in the remote video element
        this._peerConnection.ontrack = function(evt) {
            console.debug('ON REMOTE TRACK', evt);
        };

        this._peerConnection.ontrack = function(evt) {
            console.debug('Stream added to peer connection', evt);
            var remoteAudio = document.getElementById('audio_remote');
            // No longer supported: remoteAudio.src = window.URL.createObjectURL(evt.stream);
            remoteAudio.srcObject = evt.streams[0];

            // This is where we'll say we're connected. Pass the audio stream along for
            // visualizations.
            this._onVoipConnected(evt.streams[0], connectionData || this.connection);
        }.bind(this);

        // If we've already gotten the local media (for example, during a transfer)
        // don't bother requesting it again.
        if (this._localMediaStream) {
            this._addStream(this._localMediaStream);
        } else if (!listenOnly) {
            // get a local stream, show it in a self-view and add it to be sent
            this.getMediaStream()
                .then(stream => {
                    // Make sure we haven't aborted in the meantime.
                    if (this._state === this.State.CONNECTING || this._state === this.State.CONNECTED) {
                        console.debug('GOT USER MEDIA');
                        this._localMediaStream = stream;
                        this._addStream(this._localMediaStream);
                        this._trackAudio();
                    }
                })
                .catch(function(error) {
                    console.error(error);
                });
        } else {
            // Listen only mode. This just makes a media stream that outputs silence.
            // There is probably a better way to do this by creating a "recvonly" sdp
            // offer. There is not much info online on how to do this unfortunately.
            // I think it involves RTCRtpTransceiver. Anyway, this works for now.
            this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            let dest = this.audioCtx.createMediaStreamDestination();
            this._localMediaStream = dest.stream;
            this._addStream(this._localMediaStream);
        }
    }

    _createAndSendOffer() {
        console.debug('Create and send offer');
        this._peerConnection
            .createOffer()
            .then(
                function(offer) {
                    console.debug('created offer', offer.sdp);
                    return this._peerConnection.setLocalDescription(offer);
                }.bind(this),
                function(error) {
                    console.debug('offer failed', error);
                }
            )
            .then(
                function() {
                    console.debug('local description set ok');
                    this._send({
                        type: 'desc',
                        body: this._peerConnection.localDescription
                    });
                }.bind(this)
            );
    }

    _disconnect() {
        if (this._peerConnection) {
            this._peerConnection.close();
            this._peerConnection = null;
        }

        // NOTE: Firefox <44 has a bug where the icon still shows up after disconnecting. It's
        // a bug with them - not us. https://bugzilla.mozilla.org/show_bug.cgi?id=1192170
        if (this._localMediaStream) {
            var tracks = this._localMediaStream.getTracks();
            for (var i = 0; i < tracks.length; ++i) {
                tracks[i].stop();
            }
            this._localMediaStream = null;
        }
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
        }

        clearInterval(this._callTimer);
        this.connection = null;
        this.decibels = 0;

        this.emit('disconnected');
    }
}
