import gql from 'graphql-tag';

export const notificationFragment = gql`
    fragment notification on Notification {
        id
        content {
            id
            equities {
                id
                localTicker
                commonName
            }
            ... on NewsContent {
                id
                newsSource {
                    id
                    name
                }
            }
            title
        }
        created
        dashboardId
        deleted
        equityId
        eventId
        transcriptId
        expires
        isRead
        message {
            ... on ContentNotificationMessage {
                numMentions
                numMentionsChangePercent
                period
            }
            ... on EventPriceReactionNotificationMessage {
                endOrLatestPrice
                movementAbsolute
                movementPercent
            }
            ... on EventQuicklinksMatchNotificationMessage {
                highlight
            }
            ... on SpotlightMatchNotificationMessage {
                highlight
                keywordMatches
            }
            ... on StreamMatchNotificationMessage {
                highlight
                keywordMatches
            }
            ... on StreamSpikeNotificationMessage {
                highlights
                numMentions
                numMentionsChangePercent
                period
            }
        }
        modified
        notificationId
        notificationType
        organizationId
        priority
        schedule
        streamId
        userId
    }
`;

export const userSettingsFragment = gql`
    fragment userSettings on UserObjectSettings {
        id
        dailyDigestEnabled
        realtimeNotificationsEnabled
        realtimeNotificationsEveryMatch
        realtimeNotificationsSpikes
        realtimeNotificationsEmail
        realtimeNotificationsInApp
        targetId
        targetType
        userId
        weeklyDigestEnabled
    }
`;
