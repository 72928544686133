import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { INPUT_TYPES, TYPE_VALUES, valueMap } from 'consts/filters';
import { get } from 'utils';
import { CountryAutocomplete } from 'components/CountryAutocomplete';
import { IndexAutocomplete } from 'components/IndexAutocomplete';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { MinMaxInput } from 'components/MinMaxInput';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class Filters extends PureComponent {
    static propTypes = {
        addFilter: PropTypes.func.isRequired,
        filters: PropTypes.arrayOf(PropTypes.object),
        name: PropTypes.string,
        onChange: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeFilter: PropTypes.func.isRequired,
        searchable: PropTypes.bool.isRequired,
        searchTerm: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        types: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        filters: [],
        name: '',
        onChange: null,
        passedStyles: {},
        searchTerm: '',
        types: []
    };

    renderFilter({ filter, index }) {
        const { styles, onChange, name } = this.props;
        const { type: inputType, options } = valueMap[filter.type];
        const value = get(filter, 'value');
        const inputProps = {
            onChange: v => onChange && onChange(index, v),
            options,
            name,
            value
        };

        if (inputType === INPUT_TYPES.countryAutocomplete) {
            return (
                <Div styles={styles.country}>
                    <CountryAutocomplete
                        initialSearchTerm={get(value, '[0].label')}
                        multi
                        useTags
                        placeholder="Enter country..."
                        resultsPlacholder="Search by country name..."
                        {...inputProps}
                    />
                </Div>
            );
        }

        if (inputType === INPUT_TYPES.indexAutocomplete) {
            return (
                <Div styles={styles.index}>
                    <IndexAutocomplete
                        initialSearchTerm={get(value, '[0].label')}
                        multi
                        useTags
                        placeholder="Choose index..."
                        resultsPlacholder="Search by index name..."
                        {...inputProps}
                    />
                </Div>
            );
        }

        if (inputType === INPUT_TYPES.minMax) {
            return <MinMaxInput styles={styles.minMax} {...inputProps} />;
        }

        if (inputType === INPUT_TYPES.dropdown) {
            return (
                <Dropdown
                    disableHoverOpen
                    options={options}
                    placeholder="Select option"
                    styles={styles.dropdown}
                    {...inputProps}
                />
            );
        }

        if (inputType === INPUT_TYPES.multiSelect) {
            return (
                <Dropdown
                    disableHoverOpen
                    multi
                    options={options}
                    placeholder="Select option"
                    styles={styles.dropdown}
                    {...inputProps}
                />
            );
        }

        return <TextInput {...inputProps} />;
    }

    render() {
        const {
            addFilter,
            filters,
            name,
            onSearch,
            passedStyles,
            removeFilter,
            searchable,
            searchTerm,
            styles,
            theme,
            types
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.typeListContainer}>
                    {searchable && (
                        <TextInput
                            clearable
                            icon="search"
                            iconColor={theme.colors.gray04}
                            placeholder="Search filters..."
                            name="typeFilter"
                            onChange={onSearch}
                            styles={styles.searchBox}
                            value={searchTerm}
                        />
                    )}
                    <Div styles={styles.typeList}>
                        {types.map(type => {
                            const disabled = filters.some(({ type: filterType }) => type === filterType);
                            return (
                                <Div
                                    key={`filter-type-${type}`}
                                    onClick={() => !disabled && addFilter(TYPE_VALUES[type].value)}
                                    styles={[styles.type, disabled && styles.disabled]}
                                >
                                    {TYPE_VALUES[type].label}
                                </Div>
                            );
                        })}
                    </Div>
                </Div>
                <Div styles={styles.filterList}>
                    {filters && filters.length > 0 ? (
                        filters.map((filter, index) => {
                            const filterKey = `${name}-filter-${index}`;
                            return (
                                <Div key={filterKey} styles={styles.filterContainer}>
                                    <Div styles={styles.filter}>
                                        <Text size={0} styles={styles.filterLabel} weight="medium" uppercase>
                                            {TYPE_VALUES[filter.type].label}
                                        </Text>
                                        <Div styles={styles.filterRight}>{this.renderFilter({ filter, index })}</Div>
                                    </Div>
                                    <Div styles={styles.trashFilterButton} onClick={() => removeFilter(index)}>
                                        <Icon type="trash02" color={theme.colors.black01} />
                                    </Div>
                                </Div>
                            );
                        })
                    ) : (
                        <Div styles={styles.noFilters}>
                            <Text size={3} weight="medium">
                                Advanced filters
                            </Text>
                            <Text size={3}>Select an option from the left</Text>
                        </Div>
                    )}
                </Div>
            </Div>
        );
    }
}

export const FiltersUI = compose(withStyleSheet(styleSheet))(Filters);
