import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SENTIMENT_TYPES } from 'consts';
import { StreamRowUI } from './ui';

export class StreamRow extends PureComponent {
    static displayName = 'StreamRowContainer';

    static propTypes = {
        containerStyles: PropTypes.objectOf(PropTypes.any),
        includeHighlight: PropTypes.bool,
        name: PropTypes.string,
        onClick: PropTypes.func,
        sentiment: PropTypes.oneOf(SENTIMENT_TYPES),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        containerStyles: {},
        includeHighlight: false,
        name: undefined,
        onClick: null,
        sentiment: null,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            showHighlight: props.includeHighlight
        };
    }

    render() {
        const { containerStyles, styles, sentiment, onClick, children, name } = this.props;
        const { showHighlight } = this.state;
        return (
            <StreamRowUI
                containerStyles={containerStyles}
                name={name}
                onClick={onClick}
                sentiment={sentiment}
                showHighlight={showHighlight}
                styles={styles}
            >
                {children}
            </StreamRowUI>
        );
    }
}

export const StreamRowContainer = compose()(StreamRow);
