export const styleSheet = theme => {
    const resultTd = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: 14,
        lineHeight: '1em'
    };
    return {
        results: {
            width: '100%'
        },
        result: {
            td: {
                ...resultTd
            },
            ':nth-of-type(odd)': {
                td: {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        resultHighlighted: {
            td: {
                ...resultTd,
                backgroundColor: theme.colors.blue08,
                color: theme.colors.white01,
                'svg g': {
                    fill: theme.colors.white01
                },
                span: {
                    color: theme.colors.white01
                },
                p: {
                    color: theme.colors.white01
                }
            },
            '.monitorType': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: 'unset',
                'path:last-of-type': {
                    fill: theme.colors.yellow09
                },
                'path:first-of-type': {
                    fill: theme.colors.white01
                }
            },
            '.searchTag': {
                visibility: 'visible',
                boxShadow: 'unset',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: theme.colors.white01
            }
        },
        subTextWrapper: {
            position: 'relative',
            display: 'flex',
            marginTop: 2
        },
        streamCount: {
            color: theme.colors.gray04,
            marginRight: 4
        },
        streamNamesContainer: {
            position: 'relative',
            display: 'flex',
            flex: 1
        },
        streamNames: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: theme.colors.gray06,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        tagCell: {
            width: 51,
            padding: '0 12px'
        },
        equityIcon: {
            width: 30,
            padding: '8px 8px 8px 12px',
            '.monitorType': {
                padding: '0 0 2px 3px',
                height: 30,
                width: 30,
                borderRadius: 15,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        monitorIcon: {
            width: 30,
            padding: '8px 8px 8px 12px',
            '.monitorType': {
                height: 30,
                width: 30,
                borderRadius: 15,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        iconContainer: {
            boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.05)',
            backgroundColor: theme.colors.white01
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            padding: '12px 0',
            div: {
                margin: 'auto'
            }
        },
        loadButtonRow: {
            td: {
                padding: 10
            }
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        loadMoreButtonHighlighted: {
            borderRadius: 6,
            justifyContent: 'center',
            boxShadow: `0 0 0 1px ${theme.colors.blue08}`,
            p: {
                color: theme.colors.blue08
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        searchTag: {
            visibility: 'hidden',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxShadow: `0 0 0 1px ${theme.colors.gray05}`,
            backgroundColor: theme.colors.gray10,
            borderRadius: 6,
            padding: '4px 6px',
            color: theme.colors.gray06,
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: 170,
                textOverflow: 'ellipsis'
            }
        }
    };
};
