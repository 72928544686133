export const styleSheet = theme => ({
    actionBlock: {
        marginBottom: 10
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden'
    },
    columns: {
        display: 'flex',
        flex: 1,
        backgroundColor: theme.colors.white01,
        height: '100%',
        overflow: 'hidden'
    },
    containerLoading: {
        backgroundColor: theme.colors.white01,
        paddingBottom: theme.margins.margin03,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    fullTextContainer: {
        overflow: 'scroll',
        height: '100%',
        wordBreak: 'break-word',
        padding: `24px ${theme.margins.margin02} 8px`,
        '.filing-form': {
            all: 'initial'
        },
        '.selectedBlock': {
            position: 'relative',
            '&:before': {
                content: '" "',
                display: 'block',
                whiteSpace: 'pre',
                position: 'absolute',
                left: -30,
                width: 2,
                backgroundColor: theme.colors.blue08,
                height: '100%',
                zIndex: 3
            }
        },
        '.searchTerm': {
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01
        },
        '> *': {
            overflowWrap: 'break-word'
        },
        div: {
            maxWidth: '100%'
        },
        img: {
            maxWidth: '100%'
        },
        table: {
            maxWidth: '100%'
        },
        span: {
            maxWidth: '100%'
        },
        hr: {
            maxWidth: '100%'
        },
        '> span': {
            display: 'block',
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        ...theme.content.annotated,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: theme.margins.margin01,
            paddingBottom: 0
        }
    },
    loading: {
        padding: theme.margins.margin03,
        color: theme.colors.gray02
    },
    loadingBlock: {
        height: '24px',
        marginBottom: '10px',
        backgroundColor: theme.colors.gray03
    },
    loadingBlocks: {
        width: '80%',
        paddingTop: theme.margins.margin01,
        '> div:last-child': {
            marginRight: theme.margins.margin03
        }
    },
    eventsContainer: {
        position: 'sticky',
        bottom: -8,
        borderTop: `1px solid ${theme.colors.gray04}`,
        padding: theme.margins.margin01,
        backgroundColor: theme.colors.white01
    },
    eventsTitle: {
        fontSize: theme.fonts.fontSizes.size05,
        marginBottom: theme.margins.margin00
    },
    event: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white01,
        padding: '1px 0px'
    }
});
