import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import XDate from 'xdate';
import sortBy from 'lodash/sortBy';
import { get } from 'utils';
import { generateStreamDescription, mapDashFiltersToRules } from 'utils/streams';
import { graphql } from 'graphql/utils';
import { streamFragment, ruleFragment } from 'graphql/fragments/streams';
import { withMemo } from 'hoc/utils';

const STREAM_TYPES = ['content'];

function normalizeChartData(streams = []) {
    const normalizedStreams = streams
        .filter(({ streamType }) => STREAM_TYPES.includes(streamType))
        .map(stream => {
            const name = get(stream, 'name');
            const color = get(stream, 'uxPreferences.color');
            const data = get(stream, 'matches.totalPerDay', []).map(({ x, y }) => ({
                x: new XDate(x).getTime(),
                y
            }));

            return {
                name,
                color,
                data
                // marker: { enabled: true, radius: 3 }
            };
        });

    return { chartData: normalizedStreams };
}

function normalizeCounts(streams = [], filterMonths, location) {
    const hasDateRange = get(location, 'state.dashDateRange', []).length === 2;
    const filterFrom = new XDate().addMonths(-filterMonths);
    const normalizedStreams = streams
        .filter(({ streamType }) => STREAM_TYPES.includes(streamType))
        .map(stream => {
            const id = get(stream, 'id');
            const name = get(stream, 'name');
            const rules = get(stream, 'rules');
            const color = get(stream, 'uxPreferences.color');
            const total = get(stream, 'matches.totalPerDay', []).reduce((acc, { x, y }) => {
                const diffMonths = filterFrom.diffMonths(new XDate(x));

                // If we have a date range, it means the dashboard itself has a
                // date filter. This means the data will be correct, and we
                // don't need to filter which total per days we are
                // accumulating - we want them all.
                if (hasDateRange || (diffMonths > 0 && diffMonths <= filterMonths)) {
                    return acc + y;
                }

                return acc;
            }, 0);

            return {
                id,
                name,
                total,
                color,
                description: generateStreamDescription({ rules })
            };
        });

    return { streamCounts: sortBy(normalizedStreams, ['total']).reverse() };
}

export const withData = () =>
    compose(
        withMemo({ mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query withDashboardStats($dashboardId: ID, $filter: StreamMatchFilter) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        dashboardId
                        name
                        streams {
                            ...stream
                            rules {
                                ...rule
                            }
                            matches(size: 0, filter: $filter) {
                                total
                                totalPerDay {
                                    x: date
                                    y: total
                                }
                            }
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    const dashboard = get(data, 'dashboards[0]');
                    const streams = get(dashboard, 'streams', []);
                    const loading = get(data, 'loading');
                    return {
                        isEmpty: !loading && streams.length === 0,
                        loading,
                        name: get(dashboard, 'name'),
                        streams
                    };
                },
                skip: ({ dashboardId }) => !dashboardId,
                options: ({ dashboardId, filterMonths, searchTerm, location, mapFilters }) => {
                    const rules = mapFilters({
                        searchTerm,
                        dateRange: get(location, 'state.dashDateRange'),
                        equityScope: get(location, 'state.dashEquityScope')
                    });

                    if (!rules.some(({ ruleType }) => ruleType === 'date')) {
                        rules.push({
                            ruleType: 'date',
                            condition: 'is_greater_than_or_equal_to',
                            value: new XDate().addMonths(-filterMonths).toString('yyyy-MM-dd')
                        });
                    }
                    return {
                        fetchPolicy: 'cache-and-network',
                        returnPreviousData: true,
                        context: {
                            debounceKey: `dashboardStats`,
                            debounceTimeout: 300
                        },
                        variables: {
                            dashboardId,
                            filter: {
                                applyLens: true,
                                rules
                            }
                        }
                    };
                }
            }
        ),
        withMemo({ normalizeCounts, normalizeChartData }),
        withPropsOnChange(
            ['normalizeCounts', 'normalizeChartData', 'streams', 'filterMonths', 'location'],
            ({ normalizeCounts: counts, normalizeChartData: chartData, streams, filterMonths, location }) => ({
                ...counts(streams, filterMonths, location),
                ...chartData(streams),
                hasFilters:
                    !!get(location, 'state.dashDateRange') || get(location, 'state.dashEquityScope', []).length > 0
            })
        )
    );
