import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';

import { abbrevNum, get } from 'utils';
import { styleSheet } from './stylesheet';

class BarChart extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        data: PropTypes.arrayOf(PropTypes.any).isRequired,
        height: PropTypes.number,
        onSeriesPointClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        height: 150
    };

    render() {
        const { styles, theme, passedStyles, labels, data, height, onSeriesPointClick } = this.props;
        const options = {
            exporting: { enabled: false },
            chart: {
                type: 'column',
                height,
                backgroundColor: '#ebf2f9',
                borderRadius: 6,
                spacingLeft: 12,
                spacingRight: 12
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: null
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: null
                },
                type: 'category',
                categories: labels
            },
            yAxis: {
                height: 84,
                top: 20,
                gridLineWidth: 1,
                gridLineColor: 'rgba(0, 0, 0, 0.03)',
                title: {
                    text: null
                }
            },
            tooltip: {
                backgroundColor: theme.colors.white01,
                borderColor: theme.colors.gray01,
                borderRadius: 4,
                borderWidth: 1,
                valueDecimals: 2,
                shadow: false,
                formatter() {
                    return `${get(this, 'series.name')}<br/> ${abbrevNum(this.y, 2)}`;
                },
                style: {
                    fontFamily: theme.fonts.fontFamily,
                    fontSize: theme.fonts.fontSizes.size01,
                    letterSpacing: '1px'
                }
            },
            plotOptions: {
                column: {
                    animation: false,
                    borderRadius: 3,
                    pointPadding: 2,
                    borderWidth: 0,
                    pointWidth: 26,
                    dataLabels: {
                        enabled: true,
                        formatter() {
                            return `${abbrevNum(this.y, 2)}${get(this, 'series.name') === 'Estimates' ? ' E' : ''}`;
                        }
                    }
                },
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: event => {
                                onSeriesPointClick(event);
                            }
                        }
                    }
                }
            },
            series: data
        };

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </Div>
        );
    }
}

export const BarChartUI = compose(withStyleSheet(styleSheet))(BarChart);
