import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';
import { BookmarkListUI } from './ui';

export class BookmarkList extends PureComponent {
    static displayName = 'BookmarkListContainer';

    static propTypes = {
        archiveBookmarks: PropTypes.func.isRequired,
        bookmarks: PropTypes.arrayOf(PropTypes.object),
        deleteBookmarks: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        setTooltipVisibility: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        bookmarks: undefined,
        loading: false,
        loadMoreMatches: undefined,
        streamId: undefined,
        streamProps: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onArchive = this.onArchive.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectBookmark = this.onSelectBookmark.bind(this);
        this.onTooltipHide = this.onTooltipHide.bind(this);

        this.state = {
            archiving: false,
            deleting: false,
            selectedBookmarks: []
        };
    }

    onArchive() {
        const { selectedBookmarks } = this.state;
        const { archiveBookmarks } = this.props;
        this.setState({ archiving: true }, () => {
            archiveBookmarks(selectedBookmarks)
                .then(() => {
                    this.setState({
                        archiving: false,
                        selectedBookmarks: []
                    });
                })
                .catch(() => {
                    this.setState({ archiving: false });
                });
        });
    }

    onDelete() {
        const { selectedBookmarks } = this.state;
        const { deleteBookmarks } = this.props;
        this.setState({ deleting: true }, () => {
            deleteBookmarks(selectedBookmarks)
                .then(() => {
                    this.setState({
                        deleting: false,
                        selectedBookmarks: []
                    });
                })
                .catch(() => {
                    this.setState({ deleting: false });
                });
        });
    }

    onSelectBookmark(id) {
        this.setState(({ selectedBookmarks }) => ({
            selectedBookmarks: selectedBookmarks.includes(id)
                ? selectedBookmarks.filter(i => i !== id)
                : [...selectedBookmarks, id]
        }));
    }

    onTooltipHide() {
        const { setTooltipVisibility } = this.props;
        this.setState({ selectedBookmarks: [] }, () => {
            setTooltipVisibility(false);
        });
    }

    render() {
        const { archiving, deleting, selectedBookmarks } = this.state;
        const { bookmarks, loading, loadMoreMatches, setTooltipVisibility, streamId, streamProps, styles } = this.props;
        return (
            <BookmarkListUI
                archiving={archiving}
                bookmarks={bookmarks}
                deleting={deleting}
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                onArchive={this.onArchive}
                onDelete={this.onDelete}
                onSelectBookmark={this.onSelectBookmark}
                onTooltipHide={this.onTooltipHide}
                selectedBookmarks={selectedBookmarks}
                setTooltipVisibility={setTooltipVisibility}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
            />
        );
    }
}

export const BookmarkListContainer = compose(
    withUrlContext(['pathname']),
    withStateHandlers(
        () => ({
            isTooltipVisible: false
        }),
        {
            setTooltipVisibility: () => isTooltipVisible => ({ isTooltipVisible })
        }
    ),
    withData()
)(BookmarkList);
