import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose, withProps } from 'recompose';
import { PERMISSIONS } from 'consts';
import { withUrlContext } from 'hoc/url';
import { withEventDetails } from 'graphql/audioCalls';
import { get, generateModalId } from 'utils';
import { UpsellGateUI } from './ui';

const DATE_FORMAT = 'MMM dS, yyyy';

export class UpsellGate extends PureComponent {
    static displayName = 'UpsellGateContainer';

    static propTypes = {
        callDate: PropTypes.string,
        callType: PropTypes.string,
        company: PropTypes.objectOf(PropTypes.any),
        eventTitle: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isLive: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        source: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        callDate: null,
        callType: 'Unknown',
        company: undefined,
        eventTitle: 'Loading...',
        loading: false,
        source: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.openUpgradeModal = this.openUpgradeModal.bind(this);
    }

    getPropsForSource() {
        const { callDate, callType, company, eventTitle, isLive, source } = this.props;
        const name = get(company, 'commonName');
        let pageTitle = eventTitle;
        let subtitle;
        if (callDate && callType) {
            const date = `${new XDate(callDate).toString(DATE_FORMAT)}`;
            pageTitle = pageTitle || `${callType} call - ${date}`;
            subtitle = `Event took place on ${date}`;
        }
        if (source === 'scripts_asia') {
            subtitle = null;
            if (callDate) {
                subtitle =
                    new XDate().diffDays(new XDate(callDate)) < 0
                        ? `Event took place on ${new XDate(callDate).toString(DATE_FORMAT)}`
                        : `Event scheduled for ${new XDate(callDate).toString(DATE_FORMAT)}`;
            }
            return {
                description:
                    "Access the world's leading provider of investor event coverage for Asia Pacific. " +
                    "View, search, and analyze Scripts Asia's entire catalog of transcripts and recordings, " +
                    "including Aiera's event sentiment.",
                companyName: name,
                locked: true,
                pageTitle,
                permission: PERMISSIONS.unlockedScriptsAsia,
                subtitle,
                title: 'Unlock Scripts Asia'
            };
        }
        return {
            description:
                'Live events are only available for Professional members. Upgrade now for access ' +
                'to live event transcripts and audio.',
            companyName: name,
            locked: isLive,
            onClick: this.openUpgradeModal,
            pageTitle,
            permission: PERMISSIONS.unlockedLiveEvents,
            subtitle,
            title: 'Unlock Live Events'
        };
    }

    openUpgradeModal() {
        const { history, pathname } = this.props;
        history.push(generateModalId({ pathname, id: 'liveEvents', type: 'upgrade' }));
    }

    render() {
        const { loading, styles } = this.props;
        return <UpsellGateUI loading={loading} styles={styles} {...this.getPropsForSource()} />;
    }
}

export const UpsellGateContainer = compose(
    withUrlContext(['history', 'pathname', 'audioCallId']),
    withEventDetails({ fetchPolicy: 'cache-only', withCompany: true }),
    withProps(({ audioCall }) => ({
        callDate: get(audioCall, 'callDate'),
        callType: get(audioCall, 'callType'),
        company: get(audioCall, 'primaryCompany'),
        eventTitle: get(audioCall, 'title'),
        isLive: get(audioCall, 'isLive', false)
    }))
)(UpsellGate);
