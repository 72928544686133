import gql from 'graphql-tag';
import { compose } from 'recompose';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

function formatSpeakers(speakers) {
    return (speakers || []).map(speaker => ({
        label: speaker.name,
        value: speaker.personId
    }));
}

export const withData = () =>
    compose(
        withMemo({ formatSpeakers }),
        graphql(
            gql`
                query withSpeakerAutocomplete($search: String!) {
                    availableSpeakers(search: $search) {
                        id
                        personId
                        name
                        title
                    }
                }
            `,
            {
                props: ({ data, ownProps: { formatSpeakers: formatOptions } }) => {
                    return {
                        loading: get(data, 'loading'),
                        options: formatOptions(get(data, 'availableSpeakers', []))
                    };
                },
                options: ({ searchTerm }) => ({
                    fetchPolicy: 'cache-first',
                    variables: { search: searchTerm },
                    context: {
                        debounceKey: 'speakerAutocomplete',
                        debounceTimeout: 300
                    }
                }),
                skip: ({ searchTerm }) => !searchTerm
            }
        )
    );
