import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DialPadUI } from './ui';

export class DialPad extends PureComponent {
    static displayName = 'DialPadContainer';

    static propTypes = {
        allowInternational: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        number: PropTypes.string,
        onTone: PropTypes.func.isRequired,
        onCall: PropTypes.func.isRequired,
        onHangUp: PropTypes.func.isRequired,
        connected: PropTypes.bool,
        connecting: PropTypes.bool
    };

    static defaultProps = {
        allowInternational: false,
        number: null,
        styles: {},
        connected: false,
        connecting: false
    };

    constructor(props) {
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onPressCall = this.onPressCall.bind(this);
        this.onPressKeyPad = this.onPressKeyPad.bind(this);
        this.onPressBackspace = this.onPressBackspace.bind(this);

        this.state = {
            pressed: null,
            tones: props.number ? props.number.split('') : []
        };
    }

    componentDidUpdate({ number: prevNumber }) {
        const { number } = this.props;
        if (prevNumber !== number) {
            this.setState({
                tones: number ? number.split('') : []
            });
        }
    }

    onPressKeyPad(key) {
        const { onTone } = this.props;
        this.setState(
            state => {
                return {
                    pressed: key,
                    tones: key ? state.tones : [...state.tones, state.pressed].filter(n => n)
                };
            },
            () => {
                if (onTone) {
                    onTone(key);
                }
            }
        );
    }

    onChangeInput({ value }) {
        this.setState({
            tones: value.split('')
        });
    }

    onPressBackspace() {
        this.setState(state => ({
            tones: state.tones.slice(0, -1)
        }));
    }

    onPressCall() {
        const { onCall, onHangUp, connected, number } = this.props;
        const { tones } = this.state;
        if (onCall && !connected) {
            onCall({ tones });
        } else if (connected) {
            this.setState({ tones: number ? number.split() : [] }, () => {
                if (onHangUp) {
                    onHangUp();
                }
            });
        }
    }

    render() {
        const { allowInternational, styles, connected, connecting } = this.props;
        const { pressed, tones } = this.state;
        return (
            <DialPadUI
                allowInternational={allowInternational}
                styles={styles}
                connected={connected}
                connecting={connecting}
                onChangeInput={this.onChangeInput}
                onPressCall={this.onPressCall}
                onPressKeypad={this.onPressKeyPad}
                onPressBackspace={this.onPressBackspace}
                pressed={pressed}
                tones={tones.join('')}
            />
        );
    }
}

export const DialPadContainer = compose()(DialPad);
