import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import unescape from 'lodash/unescape';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { highlightAdd } from 'actions/highlight';
import { HIGHLIGHT_COLORS } from 'consts';
import { withReporting } from 'provider/reporting';
import { withCreateBookmark, withDeleteBookmark } from 'graphql/bookmarks';
import { withUrlContext } from 'hoc/url';
import { copyToClipboard, generateModalId, get } from 'utils';
import { NewsActionMenuUI } from './ui';

export class NewsActionMenu extends PureComponent {
    static displayName = 'NewsActionMenuContainer';

    static propTypes = {
        addHighlight: PropTypes.func.isRequired,
        body: PropTypes.string,
        bookmarkId: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        createBookmark: PropTypes.func.isRequired,
        deleteBookmark: PropTypes.func.isRequired,
        hideTooltip: PropTypes.func,
        highlight: PropTypes.string,
        highlightsFilterKey: PropTypes.string,
        highlightsSortKey: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        id: PropTypes.string,
        onHighlight: PropTypes.func,
        openByDefault: PropTypes.bool,
        pageId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tabs: PropTypes.arrayOf(PropTypes.any),
        useSelection: PropTypes.bool
    };

    static defaultProps = {
        body: '',
        bookmarkId: undefined,
        hideTooltip: undefined,
        highlight: undefined,
        highlightsFilterKey: undefined,
        highlightsSortKey: undefined,
        id: undefined,
        onHighlight: undefined,
        openByDefault: false,
        pageId: undefined,
        styles: undefined,
        tabs: [],
        useSelection: false
    };

    constructor(props) {
        super(props);

        this.calculateSelectionOffsets = this.calculateSelectionOffsets.bind(this);
        this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
        this.getHighlight = this.getHighlight.bind(this);
        this.onBookmark = this.onBookmark.bind(this);
        this.openBookmarkForm = this.openBookmarkForm.bind(this);

        this.state = {
            copiedText: false,
            submitting: false
        };
    }

    copyTextToClipboard(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ copiedText: true });
        copyToClipboard(get(this.getHighlight(), 'text', ''));
    }

    calculateSelectionOffsets(selection) {
        const { containerRef } = this.props;
        const range = selection.getRangeAt(0);
        const endRange = range.cloneRange();
        const startRange = range.cloneRange();
        const commonNode = containerRef.current?.querySelector('.article');
        endRange.selectNodeContents(commonNode);
        endRange.setEnd(range.endContainer, range.endOffset);
        startRange.selectNodeContents(commonNode);
        startRange.setEnd(range.startContainer, range.startOffset);

        return { startOffset: startRange.toString().length, endOffset: endRange.toString().length };
    }

    getHighlight() {
        const { highlight, useSelection, body } = this.props;
        const selection = window.getSelection();
        let highlightText = highlight?.replace(/<[^>]*>?/gm, '');
        let startOffset;
        let endOffset;

        if (useSelection && selection?.toString()?.length > 0) {
            const offsets = this.calculateSelectionOffsets(selection);
            startOffset = get(offsets, 'startOffset');
            endOffset = get(offsets, 'endOffset');
            // Handle &amp; etc.
            // handle html elements
            // handle new lines
            highlightText = unescape(body)
                .replace(/<[^>]*>?/gm, '')
                .replace(/\r?\n|\r/g, '')
                .slice(startOffset, endOffset);

            selection.collapseToEnd();
        }

        return { endOffset, text: highlightText, startOffset, selection };
    }

    onBookmark(event, color) {
        event.preventDefault();
        event.stopPropagation();
        const {
            bookmarkId,
            createBookmark,
            deleteBookmark,
            highlightsFilterKey,
            highlightsSortKey,
            id,
            onHighlight,
            pageId,
            reporter
        } = this.props;
        if (onHighlight) onHighlight();
        this.setState({ submitting: true }, () => {
            if (bookmarkId) {
                deleteBookmark({
                    bookmarkId,
                    contentId: id,
                    filterKey: highlightsFilterKey,
                    includeNewsHighlights: !!pageId,
                    sortKey: highlightsSortKey,
                    streamId: pageId,
                    targetType: 'content'
                })
                    .then(() => {
                        // Track Highlight Delete
                        reporter.track(reporter.actions.submit, reporter.objects.highlightDelete, {
                            component: 'NewsActionMenu',
                            bookmarkId,
                            id,
                            pageId
                        });
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            } else {
                const highlight = this.getHighlight();
                createBookmark({
                    contentId: id,
                    filterKey: highlightsFilterKey,
                    highlight: highlight.text,
                    highlightColor: color || HIGHLIGHT_COLORS[0],
                    includeNewsHighlights: !!pageId,
                    sortKey: highlightsSortKey,
                    streamId: pageId,
                    targetEndOffset: highlight.endOffset - 1, // Server wants exact bounds
                    targetId: id,
                    targetOffset: highlight.startOffset,
                    targetType: 'content'
                })
                    .then(() => {
                        // Track Highlight Create
                        reporter.track(reporter.actions.submit, reporter.objects.highlightCreate, {
                            component: 'NewsActionMenu',
                            id,
                            pageId
                        });
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            }
        });
    }

    openBookmarkForm(event) {
        event.preventDefault();
        event.stopPropagation();
        const {
            addHighlight,
            bookmarkId,
            highlightsFilterKey,
            highlightsSortKey,
            history,
            id,
            onHighlight,
            pageId,
            pathname,
            tabs
        } = this.props;
        const highlight = this.getHighlight();
        addHighlight({
            contentId: id,
            highlight: highlight.text,
            highlightsSortKey,
            highlightsFilterKey,
            includeNewsHighlights: !!pageId,
            streamId: pageId,
            targetType: 'content',
            targetId: id,
            targetOffset: highlight.startOffset,
            targetEndOffset: highlight.endOffset - 1 // Server wants exact bounds
        });
        history.push(generateModalId({ pathname, id: bookmarkId || 'new', type: 'bookmark', tabId: tabs[0] }));
        if (onHighlight) onHighlight();
    }

    render() {
        const { submitting, copiedText } = this.state;
        const { bookmarkId, hideTooltip, openByDefault, styles } = this.props;

        return (
            <NewsActionMenuUI
                bookmarkId={bookmarkId}
                copiedText={copiedText}
                copyTextToClipboard={this.copyTextToClipboard}
                hideTooltip={hideTooltip}
                onBookmark={this.onBookmark}
                openBookmarkForm={this.openBookmarkForm}
                openByDefault={openByDefault}
                styles={styles}
                submitting={submitting}
            />
        );
    }
}

const mapDispatchToProps = {
    addHighlight: highlightAdd
};

export const NewsActionMenuContainer = compose(
    connect(undefined, mapDispatchToProps),
    withUrlContext(['history', 'pathname', 'tabs', 'pageId']),
    withCreateBookmark(),
    withDeleteBookmark(),
    withReporting()
)(NewsActionMenu);
