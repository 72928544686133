import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withStreetAccount($streetAccountId: ID!, $streamId: ID, $includeHighlights: Boolean = false) {
                    content(filter: { contentIds: [$streetAccountId] }) {
                        __typename
                        id
                        title
                        bodyWithHighlights: body(highlight: { streamId: $streamId }) @include(if: $includeHighlights)
                        body @skip(if: $includeHighlights)
                        publishedDate
                        primaryEquity {
                            id
                            commonName
                            localTicker
                        }
                        equities {
                            id
                            commonName
                            localTicker
                            icon
                        }
                        categories {
                            id
                            shortName
                            displayName
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    ticker: get(data, 'content[0].primaryEquity.localTicker'),
                    company: get(data, 'content[0].primaryEquity.commonName'),
                    body: get(data, 'content[0].bodyWithHighlights', get(data, 'content[0].body')),
                    title: get(data, 'content[0].title'),
                    publishedDate: get(data, 'content[0].publishedDate'),
                    categories: get(data, 'content[0].categories', []),
                    equities: get(data, 'content[0].equities'),

                    streetAccountLoading: get(data, 'loading'),
                    streetAccountError: !!get(data, 'error')
                }),
                options: ({ streetAccountId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        streetAccountId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
