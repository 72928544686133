import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon';
import { Notification } from 'components/Notification';
import { Stream } from 'components/Stream';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

class NotificationList extends PureComponent {
    static propTypes = {
        isTooltipOpen: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        matches: PropTypes.arrayOf(PropTypes.object),
        onTooltipHide: PropTypes.func.isRequired,
        onTooltipShow: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        unreadCount: PropTypes.number.isRequired
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        passedStyles: {},
        streamId: null,
        streamProps: {},
        subtitle: undefined
    };

    render() {
        const {
            isTooltipOpen,
            loadMoreMatches,
            loading,
            matches,
            onTooltipHide,
            onTooltipShow,
            passedStyles,
            streamId,
            streamProps,
            styles,
            subtitle,
            theme,
            unreadCount
        } = this.props;
        return (
            <Tooltip
                isEnabled
                onHide={onTooltipHide}
                onShow={onTooltipShow}
                persistOnMouseExit
                useElementOffsetLeft
                useElementOffsetTop
                useOutsideClickHandler
                xOffset={-112}
                yOffset={38}
                content={({ hideTooltip }) => (
                    <Div id="nested-tooltip" styles={styles.notificationList}>
                        <Stream
                            {...streamProps}
                            emptyText="No alerts. Take it easy..."
                            loadMoreMatches={loadMoreMatches}
                            loading={loading}
                            offset={matches.length}
                            renderToolbar={() => {}}
                            streamId={streamId}
                            styles={styles.stream}
                            subtitle={subtitle}
                            width={288}
                        >
                            {matches.map(m => (
                                <ErrorBoundary key={`notification-row-error-boundary-${m.id}`}>
                                    <Notification hideTooltip={hideTooltip} key={`notification-row-${m.id}`} {...m} />
                                </ErrorBoundary>
                            ))}
                        </Stream>
                        <Link className="manageAlerts" to={routes.alerts}>
                            <Text size={1}>Manage Alert Settings</Text>
                        </Link>
                    </Div>
                )}
            >
                {({ showTooltip }) => (
                    <Hint
                        growLeft
                        text={`${unreadCount} Unread Notifications`}
                        name="notification-list"
                        onClick={isTooltipOpen ? undefined : showTooltip}
                        styles={{ ...styles.container, ...passedStyles }}
                    >
                        <Icon color={theme.colors.gray04} type="bell03" />
                        {unreadCount > 0 && (
                            <Div styles={styles.unreadCount}>
                                <Text size={1}>{unreadCount}</Text>
                            </Div>
                        )}
                    </Hint>
                )}
            </Tooltip>
        );
    }
}

export const NotificationListUI = compose(withStyleSheet(styleSheet))(NotificationList);
