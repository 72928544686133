import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionMenu } from 'components/ActionMenu';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { PERMISSIONS, HIGHLIGHT_COLORS } from 'consts';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class NewsActionMenu extends PureComponent {
    static propTypes = {
        bookmarkId: PropTypes.string,
        copiedText: PropTypes.bool.isRequired,
        copyTextToClipboard: PropTypes.func.isRequired,
        hideTooltip: PropTypes.func,
        onBookmark: PropTypes.func.isRequired,
        openBookmarkForm: PropTypes.func.isRequired,
        openByDefault: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        bookmarkId: null,
        hideTooltip: undefined,
        passedStyles: {}
    };

    render() {
        const {
            bookmarkId,
            copiedText,
            copyTextToClipboard,
            hideTooltip,
            onBookmark,
            openBookmarkForm,
            openByDefault,
            passedStyles,
            styles,
            submitting,
            theme
        } = this.props;

        return (
            <ActionMenu
                className="tab-tooltip"
                styles={passedStyles}
                menuStyles={styles.menu}
                hideTooltip={hideTooltip}
                openByDefault={openByDefault}
                options={[
                    {
                        iconType: 'highlighter',
                        iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                        label: bookmarkId ? 'Remove highlight' : 'Highlight match',
                        onClick: bookmarkId ? onBookmark : undefined,
                        loading: submitting,
                        name: 'toggleHighlight',
                        closeOnClick: true,
                        permissions: [PERMISSIONS.featureBookmarks],
                        content: bookmarkId ? (
                            undefined
                        ) : (
                            <Fragment>
                                <Icon type="highlighter" color={theme.colors.gray04} />
                                <Div styles={styles.colorOptions}>
                                    {HIGHLIGHT_COLORS.map(color => (
                                        <Div
                                            key={color}
                                            onMouseDown={e => onBookmark(e, color)}
                                            onTouchStart={e => onBookmark(e, color)}
                                            styles={{
                                                ...styles.colorOption,
                                                backgroundColor: getHighlightColor(color)?.dark,
                                                border: `1px solid ${getHighlightColor(color)?.dark02}`
                                            }}
                                        />
                                    ))}
                                </Div>
                            </Fragment>
                        )
                    },
                    {
                        iconType: 'bookmarkWithNote',
                        iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                        label: bookmarkId ? 'Edit highlight' : 'Highlight with note',
                        onClick: openBookmarkForm,
                        name: 'openBookmarkForm',
                        closeOnClick: true,
                        permissions: [PERMISSIONS.featureBookmarks],
                        hideMobile: true
                    },
                    {
                        iconType: 'copyToClipboard',
                        iconColor: theme.colors.gray04,
                        label: copiedText ? 'Copied!' : 'Copy Text to Clipboard',
                        onClick: copyTextToClipboard,
                        closeOnClick: true,
                        name: 'copyTextToClipboard'
                    }
                ]}
            />
        );
    }
}

export const NewsActionMenuUI = compose(withStyleSheet(styleSheet))(NewsActionMenu);
