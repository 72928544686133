import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { TYPES } from 'consts/filters';
import { StreamFilter } from 'components/StreamFilter';
import { styleSheet } from './stylesheet';

class StreetAccountFilter extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        passedStyles: {},
        value: null
    };

    render() {
        const { styles, passedStyles, onChange, name, value } = this.props;
        return (
            <StreamFilter
                value={value}
                name={name}
                styles={{ ...styles.container, ...passedStyles }}
                types={[TYPES.country, TYPES.publishDate, TYPES.alertType]}
                onChange={onChange}
            />
        );
    }
}

export const StreetAccountFilterUI = compose(withStyleSheet(styleSheet))(StreetAccountFilter);
