import React from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';

class Realtime {
    constructor(key, options) {
        this.pusher = new Pusher(key, options);
        this.channelInfo = {};
    }

    subscribe(channelName, eventName, callback) {
        const channelInfo = this.channelInfo[channelName] || {
            channel: null,
            count: 0,
            succeeded: false,
            unsubscribe: false
        };
        const unsubscribe = () => {
            if (!channelInfo.channel) return;
            channelInfo.channel.unbind(eventName, callback);
            channelInfo.count -= 1;
            if (!channelInfo.count && channelInfo.succeeded) {
                channelInfo.channel.unsubscribe();
                channelInfo.channel = null;
            } else if (!channelInfo.count && !channelInfo.succeeded) {
                channelInfo.unsubscribe = true;
            }
        };

        if (!channelInfo.channel || !channelInfo.channel.subscribed) {
            channelInfo.unsubscribe = false;
            channelInfo.channel = this.pusher.subscribe(channelName);
        }
        channelInfo.channel.bind('pusher:subscription_succeeded', () => {
            channelInfo.succeeded = true;
            if (channelInfo.unsubscribe) {
                channelInfo.unsubscribe = false;
                unsubscribe();
            }
        });
        channelInfo.channel.bind(eventName, callback);
        channelInfo.count += 1;
        this.channelInfo[channelName] = channelInfo;

        return {
            unsubscribe,
            isSubscribed: () => channelInfo.channel && channelInfo.channel.subscribed
        };
    }

    isConnected() {
        return this.pusher.connection.state === 'connected';
    }
}

export function configureRealtimeClient(config) {
    // Very noisy, enable when needed
    // Pusher.logToConsole = config.NODE_ENV === 'development';

    return new Realtime(config.PUSHER_KEY, {
        cluster: config.PUSHER_CLUSTER,
        forceTLS: true
    });
}

const RealtimeContext = React.createContext();
export const RealtimeProvider = ({ children, realtime }) => (
    <RealtimeContext.Provider value={realtime}>{React.Children.only(children)}</RealtimeContext.Provider>
);
RealtimeProvider.propTypes = { realtime: PropTypes.objectOf(PropTypes.any).isRequired };

export const withRealtime = () => BaseComponent => props => (
    <RealtimeContext.Consumer>{realtime => <BaseComponent {...props} realtime={realtime} />}</RealtimeContext.Consumer>
);
