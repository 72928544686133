import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { PERMISSIONS, PREFERENCES } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { WithPermission } from 'components/WithPermission';
import { WithPreference } from 'components/WithPreference';
import { ContentTabs } from 'components/ContentTabs';
import { get, hasPermission } from 'utils';

import { Notice } from 'components/Notice';
import { EventCorporateActions } from './EventCorporateActions';
import { EventDetails } from './EventDetails';
import { EventDialer } from './EventDialer';
import { EventDifferentials } from './EventDifferentials';
import { EventFilings } from './EventFilings';
import { EventHeader } from './EventHeader';
import { EventLogs } from './EventLogs';
import { EventPriceAlert } from './EventPriceAlert';
import { EventSidebar } from './EventSidebar';
import { EventTitleInfo } from './EventTitleInfo';
import { EventTranscripts } from './EventTranscripts';
import { PrivateRecordingGate } from './PrivateRecordingGate';
import { UpsellGate } from './UpsellGate';
import { styleSheet } from './stylesheet';
import { EventMobilePlayer } from './EventMobilePlayer';
import { EventAskAiera } from './EventAskAiera';

class Event extends PureComponent {
    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        audioCall: PropTypes.objectOf(PropTypes.any),
        audioCallId: PropTypes.string,
        callDate: PropTypes.string,
        callType: PropTypes.string.isRequired,
        disableNav: PropTypes.bool,
        disableCompany: PropTypes.bool.isRequired,
        disableQA: PropTypes.bool.isRequired,
        disableSidebar: PropTypes.bool.isRequired,
        disableTitle: PropTypes.bool.isRequired,
        dismissPriceAlert: PropTypes.func.isRequired,
        displayType: PropTypes.string.isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        eventId: PropTypes.string,
        eventRef: PropTypes.objectOf(PropTypes.any).isRequired,
        eventNotFound: PropTypes.bool.isRequired,
        framedEvent: PropTypes.bool,
        focusMode: PropTypes.bool.isRequired,
        handleMouseOut: PropTypes.func.isRequired,
        handleMouseOver: PropTypes.func.isRequired,
        handleRef: PropTypes.func.isRequired,
        handleScrollContainerRef: PropTypes.func.isRequired,
        hasCorpActivity: PropTypes.bool.isRequired,
        hasFilings: PropTypes.bool.isRequired,
        hasLiveTranscript: PropTypes.bool.isRequired,
        hasPublishedTranscript: PropTypes.bool.isRequired,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isEditing: PropTypes.bool.isRequired,
        isEventOwner: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool,
        isUploading: PropTypes.bool.isRequired,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        loadingAudioCalls: PropTypes.bool,
        loadingSidebar: PropTypes.bool.isRequired,
        localMonitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        minimized: PropTypes.bool,
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        onContentTabSelect: PropTypes.func.isRequired,
        onKeywordSearch: PropTypes.func.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        onSearchClear: PropTypes.func.isRequired,
        onTimeSelect: PropTypes.func.isRequired,
        openEditEventModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        priceHighlight: PropTypes.string,
        priceHighlightMovementAbsolute: PropTypes.number,
        priceHighlightMovementPercent: PropTypes.number,
        privateRecording: PropTypes.objectOf(PropTypes.any),
        publishedTranscriptSource: PropTypes.string,
        resultsIndex: PropTypes.number.isRequired,
        reverseTranscripts: PropTypes.bool,
        scrollToBottom: PropTypes.func.isRequired,
        scrollToTop: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        searchTerm: PropTypes.string.isRequired,
        selectedContentTab: PropTypes.string.isRequired,
        selectedEvent: PropTypes.objectOf(PropTypes.any),
        selectedNode: PropTypes.string,
        selectSidebarTab: PropTypes.func.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        setResultsIndex: PropTypes.func.isRequired,
        sidebarTab: PropTypes.string.isRequired,
        shareId: PropTypes.string,
        showDialPad: PropTypes.bool,
        showPriceAlert: PropTypes.bool.isRequired,
        showCustomGate: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        streamMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        toggleDialPad: PropTypes.func.isRequired,
        toggleFocus: PropTypes.func.isRequired,
        toggleReverse: PropTypes.func.isRequired,
        tonalSentiment: PropTypes.objectOf(PropTypes.any),
        transcriptEvents: PropTypes.arrayOf(PropTypes.object),
        transcriptNodes: PropTypes.objectOf(PropTypes.any).isRequired,
        transcriptionStatus: PropTypes.string,
        uploadPercentComplete: PropTypes.number,
        uploadStatus: PropTypes.string,
        userSettings: PropTypes.objectOf(PropTypes.any),
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        attachments: [],
        audioCall: null,
        audioCallId: null,
        callDate: undefined,
        disableNav: false,
        equity: null,
        eventId: null,
        framedEvent: false,
        isPublic: false,
        loadingAudioCalls: false,
        minimized: false,
        passedStyles: {},
        priceHighlight: undefined,
        priceHighlightMovementPercent: undefined,
        priceHighlightMovementAbsolute: undefined,
        privateRecording: undefined,
        publishedTranscriptSource: undefined,
        reverseTranscripts: false,
        searchResults: null,
        selectedEvent: null,
        selectedNode: null,
        shareId: null,
        showDialPad: true,
        title: '',
        tonalSentiment: undefined,
        transcriptEvents: [],
        transcriptionStatus: undefined,
        uploadPercentComplete: undefined,
        uploadStatus: undefined,
        userSettings: {},
        user: null
    };

    renderContent() {
        const {
            attachments,
            audioCall,
            audioCallId,
            loadingAudioCalls,
            callType,
            disableQA,
            disableTitle,
            displayType,
            dismissPriceAlert,
            equity,
            eventId,
            framedEvent,
            focusMode,
            handleRef,
            handleScrollContainerRef,
            hasCorpActivity,
            hasFilings,
            hasPublishedTranscript,
            highlightsFilterKey,
            highlightsSortKey,
            isEditing,
            isEventOwner,
            isPublic,
            onContentTabSelect,
            onKeywordSearch,
            onNodeSelect,
            onSearchClear,
            onTimeSelect,
            openEditEventModal,
            priceHighlight,
            priceHighlightMovementAbsolute,
            priceHighlightMovementPercent,
            privateRecording,
            resultsIndex,
            reverseTranscripts,
            scrollToBottom,
            scrollToTop,
            searchResults,
            searchTerm,
            selectedContentTab,
            selectedEvent,
            selectedNode,
            setResultsIndex,
            shareId,
            showDialPad,
            showPriceAlert,
            streamMatches,
            styles,
            theme,
            title,
            toggleDialPad,
            toggleFocus,
            toggleReverse,
            transcriptNodes,
            transcriptionStatus,
            uploadPercentComplete,
            uploadStatus,
            user
        } = this.props;
        const options = [{ label: 'Transcript', value: 'transcript' }];

        if (callType !== 'custom') {
            options.push({ label: 'Differentials', value: 'differentials' });
        }

        if (hasPermission(user, PERMISSIONS.featureStreamsSpotlight) && hasCorpActivity) {
            options.push({ label: 'Corporate Activity', value: 'spotlights' });
        }

        if (hasFilings) {
            options.push({ label: 'Filings', value: 'filings' });
        }

        options.push({ label: 'Event Details', value: 'details' });

        if (callType === 'custom') {
            options.push({ label: 'Event Logs', value: 'logs' });
        }

        return (
            <Div styles={styles.contentWrapper}>
                {showPriceAlert && (
                    <EventPriceAlert
                        dismissPriceAlert={dismissPriceAlert}
                        price={priceHighlight}
                        priceMovementPercent={priceHighlightMovementPercent}
                        priceMovementAbsolute={priceHighlightMovementAbsolute}
                    />
                )}
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                    {!focusMode && displayType !== 'minimal' && (
                        <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                            {matches => (
                                <ContentTabs
                                    styles={styles.tabs}
                                    onSelect={onContentTabSelect}
                                    selectedOption={selectedContentTab}
                                    options={
                                        matches
                                            ? [
                                                  ...options,
                                                  {
                                                      label: 'Ask Aiera',
                                                      value: 'askaiera',
                                                      permissions: [PERMISSIONS.featureEventsAskAiera]
                                                  }
                                              ]
                                            : options
                                    }
                                />
                            )}
                        </MediaQuery>
                    )}
                </WithPreference>
                <Div styles={styles.content} ref={handleScrollContainerRef}>
                    {!focusMode &&
                        callType !== 'custom' &&
                        (!loadingAudioCalls || Object.keys(transcriptNodes).length > 0) && (
                            <Fragment>
                                {selectedContentTab === 'differentials' && (
                                    <ErrorBoundary>
                                        <EventDifferentials
                                            eventId={eventId}
                                            shareId={isPublic ? shareId : undefined}
                                            attachments={attachments}
                                            transcriptNodes={transcriptNodes}
                                            onNodeSelect={onNodeSelect}
                                            initialCollapsedState
                                        />
                                    </ErrorBoundary>
                                )}
                                {!isPublic && selectedContentTab === 'spotlights' && (
                                    <WithPermission permissions={PERMISSIONS.featureStreamsSpotlight}>
                                        <WithPreference
                                            {...PREFERENCES.eventTranscriptsOnly}
                                            value={false}
                                            defaultValue={false}
                                        >
                                            <ErrorBoundary>
                                                <EventCorporateActions eventId={eventId} />
                                            </ErrorBoundary>
                                        </WithPreference>
                                    </WithPermission>
                                )}
                                {!isPublic && selectedContentTab === 'filings' && (
                                    <WithPermission permissions={PERMISSIONS.featureStreamsFilings}>
                                        <ErrorBoundary>
                                            <EventFilings eventId={eventId} />
                                        </ErrorBoundary>
                                    </WithPermission>
                                )}
                            </Fragment>
                        )}
                    {selectedContentTab === 'transcript' && (
                        <Fragment>
                            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                                {hasPref =>
                                    !disableTitle && (
                                        <EventTitleInfo
                                            attachments={attachments}
                                            eventId={eventId}
                                            hasPublishedTranscript={hasPublishedTranscript}
                                            isOwner={isEventOwner}
                                            isPublic={isPublic}
                                            loading={loadingAudioCalls}
                                            minimal={!hasPref}
                                            onEditEvent={openEditEventModal}
                                            privateRecordingId={get(privateRecording, 'id')}
                                        />
                                    )
                                }
                            </WithPreference>
                            <EventTranscripts
                                attachments={attachments}
                                disableQA={disableQA}
                                displayType={displayType}
                                focusMode={focusMode}
                                handleRef={handleRef}
                                hasPrivateRecordingAudio={get(privateRecording, 'connectionType') === 'audio_upload'}
                                isPrivateRecording={!!get(privateRecording, 'id')}
                                highlightsFilterKey={highlightsFilterKey}
                                highlightsSortKey={highlightsSortKey}
                                isEditing={isEditing}
                                isPublic={isPublic}
                                loading={loadingAudioCalls}
                                onKeywordSearch={onKeywordSearch}
                                onNodeSelect={onNodeSelect}
                                onSearchClear={onSearchClear}
                                onTimeSelect={onTimeSelect}
                                resultsIndex={resultsIndex}
                                reverseTranscripts={reverseTranscripts}
                                scrollToBottom={scrollToBottom}
                                scrollToTop={scrollToTop}
                                searchResults={searchResults}
                                searchTerm={searchTerm}
                                selectedEvent={selectedEvent}
                                selectedNode={selectedNode}
                                setResultsIndex={setResultsIndex}
                                shareId={isPublic ? shareId : undefined}
                                streamMatches={streamMatches}
                                toggleFocus={toggleFocus}
                                toggleReverse={toggleReverse}
                                transcriptionStatus={transcriptionStatus}
                                uploadPercentComplete={uploadPercentComplete}
                                uploadStatus={uploadStatus}
                            />
                        </Fragment>
                    )}
                    {selectedContentTab === 'details' && <EventDetails title={title} equity={equity} />}
                    {selectedContentTab === 'logs' && <EventLogs eventId={eventId} />}
                    {selectedContentTab === 'askaiera' && <EventAskAiera eventId={eventId} />}
                </Div>
                <WithPermission permissions={PERMISSIONS.featureDialOut}>
                    {callType === 'custom' && showDialPad && (
                        <WithPermission permissions={PERMISSIONS.eventsMic}>
                            {({ restricted }) => (
                                <EventDialer
                                    listenOnly={restricted}
                                    focusMode={focusMode}
                                    toggleDialPad={toggleDialPad}
                                    audioCallId={audioCallId}
                                />
                            )}
                        </WithPermission>
                    )}
                </WithPermission>
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                    {hasPref => (
                        <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                            {matches =>
                                (hasPref || matches) &&
                                !framedEvent && (
                                    <EventMobilePlayer
                                        {...audioCall}
                                        audioCallId={audioCallId}
                                        styles={styles.footer}
                                        audioCall={audioCall}
                                    />
                                )
                            }
                        </MediaQuery>
                    )}
                </WithPreference>
            </Div>
        );
    }

    renderSidebar() {
        const {
            equity,
            eventId,
            highlightsFilterKey,
            highlightsSortKey,
            isPublic,
            keyMentions,
            loadingSidebar,
            localMonitorMatches,
            monitorMatches,
            onNodeSelect,
            selectedNode,
            selectSidebarTab,
            setHighlightsFilter,
            setHighlightsSort,
            sidebarTab,
            streamMatches,
            tonalSentiment,
            transcriptEvents,
            userSettings
        } = this.props;
        const shareBookmarks = get(userSettings, 'shareBookmarks', false);
        return (
            <EventSidebar
                equityId={get(equity, 'equityId')}
                eventId={eventId}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                isPublic={isPublic}
                keyMentions={keyMentions}
                loading={loadingSidebar}
                localMonitorMatches={localMonitorMatches}
                monitorMatches={monitorMatches}
                onNodeSelect={onNodeSelect}
                selectedNode={selectedNode}
                selectedTab={sidebarTab}
                selectTab={selectSidebarTab}
                setHighlightsFilter={setHighlightsFilter}
                setHighlightsSort={setHighlightsSort}
                shareBookmarks={shareBookmarks}
                streamMatches={streamMatches}
                tonalSentiment={tonalSentiment}
                transcriptEvents={transcriptEvents}
            />
        );
    }

    render() {
        const {
            loadingAudioCalls,
            callDate,
            callType,
            disableCompany,
            disableNav,
            disableSidebar,
            eventId,
            eventRef,
            eventNotFound,
            handleMouseOut,
            handleMouseOver,
            hasLiveTranscript,
            isEventOwner,
            isPublic,
            isUploading,
            focusMode,
            minimized,
            openEditEventModal,
            passedStyles,
            privateRecording,
            publishedTranscriptSource,
            shareId,
            showCustomGate,
            styles,
            theme
        } = this.props;

        if (minimized) {
            return null;
        }

        if (eventNotFound) {
            return (
                <Div styles={styles.notFound}>
                    <Notice type="info">Sorry, no event found with that ID</Notice>
                </Div>
            );
        }

        return (
            /* eslint-disable */
            <Fragment>
                <Div
                    ref={eventRef}
                    className="enable-quick-context"
                    styles={{ ...styles.container, ...passedStyles }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    {/* eslint-enable */}
                    <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                        <EventHeader
                            focusMode={focusMode}
                            disableCompany={disableCompany}
                            disableNav={disableNav}
                            eventId={eventId}
                            isPublic={isPublic}
                            loading={loadingAudioCalls}
                            publishedSource={publishedTranscriptSource}
                            shareId={isPublic ? shareId : undefined}
                        />
                    </WithPreference>
                    <Div styles={styles.columns}>
                        {!focusMode && !disableSidebar && (
                            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                                <MediaQuery maxWidth={theme.breakpoints.external.tablet}>
                                    {matches => (matches ? null : this.renderSidebar())}
                                </MediaQuery>
                            </WithPreference>
                        )}
                        {this.renderContent()}
                    </Div>
                </Div>
                {callType === 'custom' && showCustomGate && (
                    <WithPermission permissions={[PERMISSIONS.unlockedCreatePrivateRecording]}>
                        {({ isLoading, restricted }) =>
                            !loadingAudioCalls &&
                            !isLoading &&
                            !restricted &&
                            !!privateRecording &&
                            get(privateRecording, 'connectionType') !== 'audio_upload' &&
                            !hasLiveTranscript &&
                            !isUploading && (
                                <PrivateRecordingGate
                                    callDate={callDate}
                                    eventId={eventId}
                                    isOwner={isEventOwner}
                                    onEdit={openEditEventModal}
                                    privateRecordingId={get(privateRecording, 'id')}
                                />
                            )
                        }
                    </WithPermission>
                )}
                {!isPublic && <UpsellGate loading={loadingAudioCalls} source={publishedTranscriptSource} />}
            </Fragment>
        );
    }
}

export const EventUI = compose(withStyleSheet(styleSheet))(Event);
