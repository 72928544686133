import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { withStyleSheet } from 'hoc/styles';
import { styleSheet } from './stylesheet';

const A = styled('a')(props => ({
    color: props.theme.colors.externalLink,
    cursor: 'pointer',
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'underline'
    },
    ...props.styles
}));

class ExternalLink extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        href: PropTypes.string.isRequired,
        useClick: PropTypes.bool
    };

    static defaultProps = {
        useClick: false,
        passedStyles: {}
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { href, useClick } = this.props;
        if (useClick) {
            e.preventDefault();
            window.open(href, '_blank');
        }
    }

    render() {
        const { styles, children, href, passedStyles, ...rest } = this.props;
        return (
            <A href={href} styles={{ ...styles, ...passedStyles }} {...rest} target="_blank" onClick={this.handleClick}>
                {children}
            </A>
        );
    }
}

export const ExternalLinkUI = compose(withStyleSheet(styleSheet))(ExternalLink);
