import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withEventCorporateActions($eventId: ID!) {
                    events(eventIds: [$eventId]) {
                        id
                        spotlightContent: content(filter: { contentTypes: [spotlight] }) {
                            id
                            contentType
                            displayType
                            title
                            publishedDate
                            ... on GuidanceSpotlightContent {
                                spotlightSubtype: guidanceTrend
                                eventDate
                            }
                            ... on PartnershipSpotlightContent {
                                eventDate
                            }
                            ... on AssetPurchaseSpotlightContent {
                                eventDate
                            }
                            ... on BuybackSpotlightContent {
                                eventDate
                            }
                            ... on SalesMetricSpotlightContent {
                                eventDate
                            }
                            ... on MAndASpotlightContent {
                                eventDate
                            }
                            ... on SpinOffSpotlightContent {
                                eventDate
                            }
                            ... on IPOSpotlightContent {
                                eventDate
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    loading: get(data, 'loading'),
                    corpActions: get(data, 'events[0].spotlightContent')
                }),
                skip: ({ eventId }) => !eventId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
