import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { toDateTimeString } from 'utils';
import { withStyleSheet } from 'hoc/styles';

import { Div } from 'components/Div';
import { Heading } from 'components/Heading';
import { Text } from 'components/Text';
import { BackButton } from 'components/BackButton';
import { RawHTML } from 'components/RawHTML';
import { ContentCompanies } from 'components/ContentCompanies';

import { styleSheet } from './stylesheet';

class StreetAccount extends PureComponent {
    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.string,
        loadingDetails: PropTypes.bool.isRequired,
        setToolbarTitle: PropTypes.func,
        minimized: PropTypes.bool,
        body: PropTypes.string,
        title: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        equities: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        setToolbarTitle: null,
        minimized: false,
        passedStyles: {},
        body: undefined,
        publishedDate: undefined
    };

    componentDidUpdate() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    renderCategories() {
        const { categories, styles } = this.props;

        const mappedCategories = categories.map(({ displayName, categoryId }) => (
            <Div key={`sa-page-cat-${categoryId}`} styles={styles.category}>
                <Text size={0} uppercase>
                    {displayName}
                </Text>
            </Div>
        ));

        if (categories && categories.length) {
            return (
                <Div styles={styles.categoryContainer}>
                    <Text styles={styles.catLabel} size={0} uppercase>
                        streetaccount
                    </Text>
                    <Div styles={styles.categories}>{mappedCategories}</Div>
                </Div>
            );
        }

        return null;
    }

    renderFullText() {
        const { body, styles, title, publishedDate, equities } = this.props;
        if (body) {
            return (
                <Div styles={styles.fullTextContainer} className="enable-quick-context">
                    {/* TODO replace with companies if re-enabling StreetAccount */}
                    <ContentCompanies equities={equities} />
                    <Heading size={1} styles={styles.fullTextHeading}>
                        {title || 'No document title'}
                    </Heading>
                    <Text size={1} styles={styles.publishDate}>
                        {toDateTimeString(publishedDate, true)}
                    </Text>
                    <Div styles={styles.documentContent}>
                        <RawHTML html={body} />
                    </Div>
                    {this.renderCategories()}
                </Div>
            );
        }

        return null;
    }

    render() {
        const { styles, theme, passedStyles, loadingDetails, body, minimized, containerRef } = this.props;

        if (minimized) {
            return null;
        }

        if (loadingDetails && !body) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => m && <BackButton back styles={styles.backButton} />}
                    </MediaQuery>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        return (
            <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                {this.renderFullText()}
            </Div>
        );
    }
}

export const StreetAccountUI = compose(withStyleSheet(styleSheet))(StreetAccount);
