export const styleSheet = theme => ({
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    audioControl: {
        padding: '0 4px 0 2px',
        svg: {
            marginRight: 6,
            height: 10
        },
        width: 120,
        [`@media(max-width: 600px)`]: {
            width: 30,
            marginLeft: 6,
            padding: '0 2px 0 2px',
            svg: {
                marginRight: 0,
                height: 10
            }
        },
        height: 30,
        borderRadius: 6,
        background: theme.colors.gradient03,
        cursor: 'pointer',
        marginLeft: 14,
        marginRight: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        ':active': {
            marginTop: 1,
            boxShadow: 'unset',
            background: theme.colors.gradient03c
        },
        ':hover': {
            background: theme.colors.gradient03b,
            '.lockIcon': {
                display: 'block'
            },
            '.playIcon': {
                display: 'none'
            }
        },
        '.lockIcon': {
            display: 'none'
        }
    },
    autoPlayButton: {
        position: 'relative',
        height: 30,
        padding: '0 8px',
        border: `1px solid ${theme.colors.gray08}`,
        borderRadius: 6,
        margin: '0 10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        p: {
            marginLeft: 6
        },
        '.checkbox03': {
            boxShadow: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.gray13
        },
        ':active': {
            backgroundColor: theme.colors.gray11
        }
    },
    endTime: {
        fontFamily: 'monospace',
        width: 50,
        textAlign: 'center'
    },
    exchange: {
        flexShrink: 0,
        color: theme.colors.orange02,
        opacity: 0.4
    },
    highlightDot: {
        height: 6,
        width: 6,
        borderRadius: 6,
        backgroundColor: theme.colors.white01,
        boxShadow: `0 0 0 3px ${theme.colors.yellow02}`,
        top: -1,
        position: 'absolute'
    },
    knob: {
        cursor: 'grab',
        top: -8,
        borderRadius: 2,
        position: 'absolute',
        height: 20,
        width: 6,
        backgroundColor: theme.colors.white01,
        ':hover': {
            backgroundColor: theme.colors.blue08
        },
        ':active': {
            backgroundColor: theme.colors.blue08,
            cursor: 'grabbing'
        }
    },
    playbackRates: {
        display: 'flex',
        flexDirection: 'column-reverse'
    },
    playbackTooltip: {
        ...theme.content.hoverMenu,
        background: theme.colors.white01
    },
    playbackTooltipHeader: {
        padding: '14px 12px 6px',
        letterSpacing: 2,
        color: theme.colors.gray06
    },
    playbackTooltipOption: {
        ...theme.content.hoverMenuOption,
        fontFamily: 'monospace'
    },
    player: {
        flex: 1,
        position: 'relative',
        height: 28,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px'
    },
    progressBar: {
        height: 4,
        background: theme.colors.yellow02,
        borderRadius: '4px 0 0 4px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    removeEvent: {
        position: 'relative',
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.white01
            }
        }
    },
    seek: {
        height: 30,
        padding: '0 6px',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 6,
        svg: {
            height: 20,
            width: 20
        },
        [`@media(max-width: 600px)`]: {
            marginLeft: 6
        },
        cursor: 'pointer',
        backgroundColor: theme.colors.gray11,
        ':hover': {
            backgroundColor: theme.colors.gray13,
            svg: {
                fill: theme.colors.orange02
            }
        },
        ':active': {
            svg: {
                fill: theme.colors.white01
            }
        }
    },
    seekStart: {
        [`@media(max-width: 600px)`]: {
            marginLeft: 6
        }
    },
    speed: {
        height: 30,
        padding: '0 8px',
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.colors.gray11,
        fontFamily: 'monospace',
        ':hover': {
            backgroundColor: theme.colors.gray13,
            color: theme.colors.orange02
        },
        ':active': {
            color: theme.colors.white01
        }
    },
    spinner: {
        opacity: 0.7,
        marginRight: 6,
        height: 15,
        width: 15
    },
    startTime: {
        fontFamily: 'monospace',
        width: 50,
        textAlign: 'center'
    },
    stopLive: {
        marginRight: 8,
        height: 13
    },
    cursor: {
        cursor: 'pointer'
    },
    timelineWrapper: {
        position: 'relative',
        flex: 1,
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    timeline: {
        position: 'relative',
        flex: 1,
        margin: '0 10px',
        height: 4,
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: 4
    },
    trackInfo: {
        marginLeft: 8,
        maxWidth: 208,
        overflow: 'hidden',
        '.trackTitle': {
            display: 'block',
            color: theme.colors.white01,
            fontSize: theme.fonts.fontSizes.size01,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginBottom: 4,
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline'
            },
            ':active': {
                color: theme.colors.blue13
            }
        },
        '.ticker': {
            flexShrink: 0,
            fontSize: theme.fonts.fontSizes.size00,
            textTransform: 'uppercase',
            color: theme.colors.orange02,
            marginRight: 4,
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline'
            },
            ':active': {
                color: theme.colors.blue13
            }
        }
    },
    trackDate: {
        flexShrink: 0,
        color: theme.colors.gray04
    },
    trackDetails: {
        letterSpacing: 1,
        display: 'flex'
    },
    unavailable: {
        marginLeft: 14,
        marginRight: 4
    },
    volumeButton: {
        height: 30,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 6,
        borderRadius: 6,
        backgroundColor: theme.colors.gray11,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.gray13
        }
    },
    volumeTooltip: {
        border: `1px solid ${theme.colors.gray01}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 12,
        padding: 10,
        backgroundColor: 'white',
        transform: 'rotate(-90deg)'
    },
    goLive: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'rgb(255 255 255 / 20%) 0px 1px 0px 0px inset, rgb(0 0 0 / 20%) 0px 1px 2px 0px',
        backgroundColor: theme.colors.red01,
        borderRadius: 4,
        padding: '0px 6px',
        marginRight: 12,
        height: 22
    },
    live: {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.red01,
        borderRadius: 4,
        padding: '0px 6px',
        marginRight: 12,
        height: 22,
        width: 58
    },
    liveText: {
        letterSpacing: '1px'
    },
    latencyText: {
        position: 'absolute',
        width: 44,
        color: theme.colors.gray02,
        textAlign: 'center'
    },
    disabled: {
        opacity: 0.7,
        ':hover': {
            backgroundColor: 'inherit'
        }
    }
});
