import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import XDate from 'xdate';
import MediaQuery from 'react-responsive';
import { routes } from 'routes';
import { withStyleSheet } from 'hoc/styles';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { EventAlertToggle } from 'components/EventAlertToggle';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { InternalLink } from 'components/InternalLink';
import { Spinner } from 'components/Spinner';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { get, maybeGetUnknownTimeAgo } from 'utils';
import { RawHTML } from 'components/RawHTML';
import { styleSheet } from './stylesheet';

class EventCard extends PureComponent {
    static propTypes = {
        alertEnabled: PropTypes.bool.isRequired,
        canListen: PropTypes.bool.isRequired,
        company: PropTypes.string,
        conference: PropTypes.objectOf(PropTypes.any),
        creator: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]).isRequired,
        displayMode: PropTypes.string.isRequired,
        eventId: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
        exchangeName: PropTypes.string,
        hasTonalSentiment: PropTypes.bool.isRequired,
        hasTranscript: PropTypes.bool.isRequired,
        hasUnknownTime: PropTypes.bool.isRequired,
        isLive: PropTypes.bool.isRequired,
        isUpcoming: PropTypes.bool.isRequired,
        loadingAudio: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        noLiveAccess: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        onPopout: PropTypes.func,
        openUpgradeModal: PropTypes.func.isRequired,
        organizationName: PropTypes.string,
        overThreshold: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        price: PropTypes.string,
        priceMovementPercent: PropTypes.number,
        priceMovementAbsolute: PropTypes.number,
        processingAudio: PropTypes.bool.isRequired,
        summaryExpanded: PropTypes.bool.isRequired,
        summaryText: PropTypes.arrayOf(PropTypes.any),
        summaryTitle: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string,
        to: PropTypes.string,
        togglePlayEvent: PropTypes.func.isRequired,
        toggleSummary: PropTypes.func.isRequired
    };

    static defaultProps = {
        company: null,
        conference: null,
        creator: null,
        exchangeName: null,
        loadingAudio: false,
        onClick: null,
        onPopout: null,
        organizationName: null,
        passedStyles: {},
        price: undefined,
        priceMovementAbsolute: undefined,
        priceMovementPercent: undefined,
        summaryText: undefined,
        summaryTitle: undefined,
        ticker: null,
        title: null,
        to: null
    };

    renderMinimalPlayButton() {
        const {
            isLive,
            styles,
            hasTranscript,
            openUpgradeModal,
            processingAudio,
            theme,
            mediaPlayer,
            togglePlayEvent,
            canListen
        } = this.props;
        if (isLive) {
            return (
                <WithPermission permissions={PERMISSIONS.unlockedLiveEvents}>
                    {({ restricted, isLoading }) =>
                        restricted || isLoading ? (
                            <Hint
                                xOffset={-50}
                                text="Upgrade to Listen Live"
                                styles={styles.minimialListenLiveButtonLocked}
                                data-tname="event-card-upgrade-live"
                                onClick={isLoading ? undefined : openUpgradeModal}
                            >
                                <Icon className="lockIcon" type="lock02" color={theme.colors.gray06} />
                                <Icon className="playIcon" type="play" color={theme.colors.red01} />
                            </Hint>
                        ) : (
                            <Hint
                                xOffset={mediaPlayer.listening ? -30 : -24}
                                text={
                                    mediaPlayer.listening
                                        ? 'Stop Listening'
                                        : processingAudio
                                        ? 'Processing Audio'
                                        : 'Listen Live'
                                }
                                styles={styles.minimalListenLiveButton}
                                data-tname="event-card-play-live"
                                onClick={processingAudio ? undefined : togglePlayEvent}
                            >
                                {mediaPlayer.loading ? (
                                    <Spinner color="orange" />
                                ) : (
                                    <Icon
                                        type={mediaPlayer.listening ? 'pause' : processingAudio ? 'hourglass' : 'play'}
                                        color={processingAudio ? theme.colors.gray06 : theme.colors.red01}
                                    />
                                )}
                            </Hint>
                        )
                    }
                </WithPermission>
            );
        }

        if (canListen) {
            return (
                <Hint
                    xOffset={-24}
                    text={processingAudio ? 'Processing Audio' : 'Listen Now'}
                    styles={styles.minimalPlayButton}
                    data-tname="event-card-play-archived"
                    onClick={processingAudio ? undefined : togglePlayEvent}
                >
                    <Icon
                        type={mediaPlayer.listening ? 'pause' : processingAudio ? 'hourglass' : 'play'}
                        color={mediaPlayer.listening ? theme.colors.red01 : theme.colors.gray06}
                    />
                </Hint>
            );
        }
        if (hasTranscript) {
            return (
                <Hint xOffset={-30} text="Has Transcript" styles={styles.minimalTranscriptButton}>
                    <Icon type="documents" color={theme.colors.gray06} />
                </Hint>
            );
        }

        return null;
    }

    renderTonalSentiment() {
        const { styles, theme, displayMode } = this.props;
        return (
            <WithPermission permissions={[PERMISSIONS.featureEventsTonalSentiment]}>
                <Hint
                    growLeft
                    styles={
                        displayMode === STREAM_DISPLAY_MODES.minimal
                            ? styles.minimalHasTonalSentimentIcon
                            : styles.hasTonalSentimentIcon
                    }
                    text="Has tonal sentiment"
                    xOffset={-21}
                    yOffset={-23}
                >
                    <Icon color={theme.colors.green03} type="audioWav" />
                </Hint>
            </WithPermission>
        );
    }

    renderEventButtons() {
        const {
            alertEnabled,
            canListen,
            displayMode,
            eventId,
            hasTonalSentiment,
            hasTranscript,
            isLive,
            isUpcoming,
            loadingAudio,
            mediaPlayer,
            onPopout,
            openUpgradeModal,
            processingAudio,
            styles,
            theme,
            togglePlayEvent
        } = this.props;
        const popoutButton = (
            <MediaQuery minWidth={theme.breakpoints.internal.mobile}>
                <Hint text="New Window" xOffset={-28} onClick={onPopout} styles={styles.popoutButton}>
                    <Icon type="windowPopout" color={theme.colors.gray06} />
                </Hint>
            </MediaQuery>
        );
        const hasTonalSentimentIcon = hasTonalSentiment ? this.renderTonalSentiment() : null;
        if (isLive) {
            return (
                <Div styles={styles.actionButtons}>
                    <WithPermission permissions={PERMISSIONS.unlockedLiveEvents}>
                        {({ restricted, isLoading }) =>
                            restricted || isLoading ? (
                                <Hint
                                    xOffset={-50}
                                    text="Upgrade to Listen Live"
                                    styles={styles.listenLiveButtonLocked}
                                    data-tname="event-card-upgrade-live"
                                    onClick={isLoading ? undefined : openUpgradeModal}
                                >
                                    <Icon className="lockIcon" type="lock02" color={theme.colors.gray06} />
                                    <Icon className="playIcon" type="play" color={theme.colors.red01} />
                                </Hint>
                            ) : (
                                <Fragment>
                                    <Hint
                                        xOffset={mediaPlayer.listening ? -30 : -24}
                                        text={
                                            mediaPlayer.listening
                                                ? 'Stop Listening'
                                                : processingAudio
                                                ? 'Processing Audio'
                                                : 'Listen Live'
                                        }
                                        styles={styles.listenLiveButton}
                                        data-tname="event-card-play-live"
                                        onClick={processingAudio ? undefined : togglePlayEvent}
                                    >
                                        <Fragment>
                                            {mediaPlayer.loading || loadingAudio ? (
                                                <Spinner color="orange" />
                                            ) : processingAudio ? (
                                                <Icon type="hourglass" color={theme.colors.gray06} />
                                            ) : (
                                                <Icon
                                                    type={mediaPlayer.listening ? 'pause' : 'play'}
                                                    color={theme.colors.red01}
                                                />
                                            )}
                                        </Fragment>
                                    </Hint>
                                    {hasTonalSentimentIcon}
                                </Fragment>
                            )
                        }
                    </WithPermission>
                    {popoutButton}
                </Div>
            );
        }

        if (isUpcoming) {
            return (
                <Div styles={styles.actionButtons}>
                    <EventAlertToggle enabled={alertEnabled} eventId={eventId} styles={styles.eventAlertToggle} />
                    {popoutButton}
                </Div>
            );
        }

        if (canListen || hasTranscript) {
            return (
                <Div styles={styles.actionButtons}>
                    {canListen && (
                        <Hint
                            xOffset={-24}
                            text={processingAudio ? 'Processing Audio' : 'Listen Now'}
                            styles={styles.playButton}
                            data-tname="event-card-play-archived"
                            onClick={processingAudio ? undefined : togglePlayEvent}
                        >
                            {processingAudio ? (
                                <Icon
                                    type="hourglass"
                                    color={mediaPlayer.listening ? theme.colors.red01 : theme.colors.gray06}
                                />
                            ) : (
                                <Icon
                                    type={mediaPlayer.listening ? 'pause' : 'play'}
                                    color={mediaPlayer.listening ? theme.colors.red01 : theme.colors.gray06}
                                />
                            )}
                        </Hint>
                    )}
                    {displayMode === STREAM_DISPLAY_MODES.default && hasTranscript && (
                        <Hint xOffset={-30} text="Has Transcript" styles={styles.transcriptButton}>
                            <Icon type="documents" color={theme.colors.gray06} />
                        </Hint>
                    )}
                    {popoutButton}
                    {hasTonalSentimentIcon}
                </Div>
            );
        }

        return <Div styles={styles.actionButtons}>{popoutButton}</Div>;
    }

    renderReaction() {
        const {
            overThreshold,
            styles,
            theme,
            price,
            priceMovementPercent,
            priceMovementAbsolute,
            displayMode
        } = this.props;
        const isPositive = priceMovementAbsolute && priceMovementAbsolute > 0;
        const redBg = overThreshold ? theme.colors.red05 : theme.colors.red03;
        const greenBg = overThreshold ? theme.colors.green09 : theme.colors.green05;

        if (displayMode === STREAM_DISPLAY_MODES.minimal && !!price) {
            return (
                <Hint
                    xOffset={-50}
                    text="Price Reaction"
                    description={`${price} (${priceMovementAbsolute.toFixed(2)}) ${priceMovementPercent.toFixed(2)}%`}
                    styles={{
                        ...styles.minimalPriceReaction,
                        backgroundColor: isPositive ? theme.colors.green03 : theme.colors.red01
                    }}
                >
                    <Text size={0}>{priceMovementPercent.toFixed(2)}%</Text>
                    <Icon type={isPositive ? 'triangleUp' : 'triangleDown'} color={theme.colors.white01} />
                </Hint>
            );
        }

        return (
            !!price && (
                <Div
                    styles={{
                        ...styles.priceReaction,
                        backgroundColor: isPositive ? greenBg : redBg
                    }}
                >
                    <Text size={0} styles={styles.reactionLabel} uppercase>
                        Reaction
                    </Text>
                    <Div styles={styles.spacer} />
                    <Text size={1}>
                        {price}{' '}
                        <Text size={1} span styles={{ color: isPositive ? theme.colors.green03 : theme.colors.red01 }}>
                            ({priceMovementAbsolute.toFixed(2)}) {priceMovementPercent.toFixed(2)}%
                        </Text>
                    </Text>
                    <Icon
                        type={isPositive ? 'triangleUp' : 'triangleDown'}
                        color={isPositive ? theme.colors.green03 : theme.colors.red01}
                    />
                </Div>
            )
        );
    }

    renderConference() {
        const { conference, styles, theme } = this.props;
        return (
            !!conference &&
            get(conference, 'promoted') && (
                <InternalLink
                    to={generatePath(routes.conference, { eventGroupId: get(conference, 'id') })}
                    styles={styles.conferenceContainer}
                >
                    <Icon type="conference" color={theme.colors.gray04} />
                    <Text styles={styles.conferenceTitle}>
                        <Text weight="medium" span>
                            Conference
                        </Text>
                        {get(conference, 'title')}
                    </Text>
                </InternalLink>
            )
        );
    }

    renderSummary() {
        const { styles, summaryText, summaryTitle, theme, toggleSummary, summaryExpanded } = this.props;
        if (!summaryText || summaryText.length === 0) {
            return null;
        }
        return (
            <Div styles={styles.summaryContainer}>
                <Hint
                    growUp
                    yOffset={-32}
                    text={summaryExpanded ? 'Hide Summary' : 'Show Summary'}
                    description="Summarization may not be perfect"
                    styles={summaryExpanded ? styles.summaryHeaderExpanded : styles.summaryHeader}
                    name="toggle-event-card-summary"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleSummary();
                    }}
                >
                    <Icon type="summary" color={theme.colors.gray04} />
                    <Text styles={styles.summaryTitle}>
                        <Text weight="medium" span>
                            Automated Summary
                        </Text>
                        {summaryTitle}
                    </Text>
                    <Icon
                        styles={summaryExpanded ? styles.summaryChevronExpanded : styles.summaryChevron}
                        type="chevron02"
                        color={theme.colors.gray04}
                    />
                </Hint>
                {summaryExpanded && summaryTitle && summaryTitle.length > 0 && (
                    <Text styles={styles.summaryTitleLong} weight="medium">
                        {summaryTitle}
                    </Text>
                )}
                {summaryExpanded &&
                    summaryText.map(txt => (
                        <Div styles={styles.summaryText} key={txt.slice(0, 10)}>
                            <RawHTML html={txt} />
                        </Div>
                    ))}
            </Div>
        );
    }

    render() {
        const {
            canListen,
            company,
            creator,
            date,
            displayMode,
            exchangeName,
            eventType,
            hasUnknownTime,
            hasTonalSentiment,
            hasTranscript,
            isLive,
            noLiveAccess,
            onClick,
            organizationName,
            passedStyles,
            styles,
            theme,
            ticker,
            title,
            to
        } = this.props;
        const time = date.toString('h:mmTT');
        const useTime = Math.abs(new XDate().diffHours(date)) < 12;
        let formattedDate = date.toString('MM/dd/yyyy h:mmTT');
        let eventTitle = company;
        let subtitle = title;
        let eventContext = ticker;
        if (!company && creator) {
            eventContext = organizationName;
            eventTitle = title;
            subtitle = creator;
        }
        if (useTime) {
            formattedDate = ` at ${time}`;
        }
        /**
         * When hasUnknownTime is true,
         * show "yesterday", "today", or "tomorrow" when the date is in range
         * Otherwise, show a formatted date with no time
         * When useTime is true and hasUnknownTime is false,
         * show TimeAgo
         */
        let timeAgo;
        if (hasUnknownTime) {
            timeAgo = maybeGetUnknownTimeAgo(date, hasUnknownTime) || date.toString('MM/dd/yyyy');
        } else if (useTime) {
            timeAgo = <TimeAgo date={date} />;
        }
        const dateTimeBlock = (
            <Div styles={styles.dateTime}>
                {!!timeAgo && <Text size={0}>{timeAgo}</Text>}
                {!hasUnknownTime && <Text size={0}>{formattedDate}</Text>}
            </Div>
        );

        if (displayMode === STREAM_DISPLAY_MODES.minimal) {
            return (
                <Card
                    containerStyles={styles.containerMinimal}
                    styles={{ ...styles.minimal, ...passedStyles }}
                    to={to}
                    onClick={onClick}
                    name="event-card-compact"
                >
                    <Div styles={styles.minimalContentAndButtons}>
                        {this.renderMinimalPlayButton()}
                        <Div styles={styles.content}>
                            <Text styles={styles.minimalTicker} weight="medium">
                                {ticker}
                                <Text styles={styles.minimalExchange} span weight="regular">
                                    {exchangeName}
                                </Text>
                            </Text>
                            <Text styles={styles.minimalEventType} capitalize>
                                {eventType.replace(/_/g, ' ')}
                            </Text>
                        </Div>
                        <Div styles={styles.minimalRightSide}>
                            <Div styles={styles.minimalIcons}>
                                {hasTonalSentiment && this.renderTonalSentiment()}
                                {this.renderReaction()}
                            </Div>
                            {isLive ? (
                                <Div styles={styles.liveStatus}>
                                    <Div styles={styles.liveDot} />
                                    <Text size={0} uppercase>
                                        live
                                    </Text>
                                </Div>
                            ) : (
                                <Div styles={styles.dateTime}>
                                    <Text size={0}>{hasUnknownTime ? timeAgo : formattedDate}</Text>
                                </Div>
                            )}
                        </Div>
                    </Div>
                </Card>
            );
        }

        if (displayMode === STREAM_DISPLAY_MODES.compact) {
            return (
                <Card
                    containerStyles={styles.containerCompact}
                    styles={{ ...styles.compact, ...passedStyles }}
                    to={to}
                    onClick={onClick}
                    name="event-card-compact"
                >
                    <Div styles={styles.contentAndButtons}>
                        <Div styles={styles.content}>
                            {noLiveAccess && !canListen && !hasTranscript && (
                                <Div style={styles.noAccess}>
                                    <Icon type="warning" color={theme.colors.red01} />
                                    <Text size={1} style={styles.noAccessText}>
                                        Post-Event Replay Access
                                    </Text>
                                </Div>
                            )}
                            <CardHeader
                                styles={{ opacity: noLiveAccess && !canListen && !hasTranscript ? 0.5 : 1 }}
                                title={eventTitle}
                                subtitle={subtitle}
                                truncateTitle
                            />
                            {isLive ? (
                                <Div styles={styles.liveStatus}>
                                    <Div styles={styles.liveDot} />
                                    <Text size={0} uppercase>{`live from ${time}`}</Text>
                                </Div>
                            ) : (
                                dateTimeBlock
                            )}
                        </Div>
                        {(!noLiveAccess || canListen || hasTranscript) && to && this.renderEventButtons()}
                    </Div>
                    {this.renderReaction()}
                </Card>
            );
        }

        return (
            <Card styles={{ ...styles.container, ...passedStyles }} to={to} onClick={onClick} name="event-card">
                <Div styles={styles.contentAndButtons}>
                    <Div styles={styles.content}>
                        {noLiveAccess && !canListen && !hasTranscript && (
                            <Div style={styles.noAccess}>
                                <Icon type="warning" color={theme.colors.red01} />
                                <Text size={1} style={styles.noAccessText}>
                                    Post-Event Replay Access
                                </Text>
                            </Div>
                        )}
                        <CardHeader
                            styles={{ opacity: noLiveAccess && !canListen && !hasTranscript ? 0.5 : 1 }}
                            context={displayMode === STREAM_DISPLAY_MODES.default ? eventContext : undefined}
                            secondaryContext={exchangeName}
                            title={eventTitle}
                            subtitle={subtitle}
                            truncateTitle
                        />
                        {isLive ? (
                            <Div styles={styles.liveStatus}>
                                <Div styles={styles.liveDot} />
                                <Text size={0} uppercase>{`live from ${time}`}</Text>
                            </Div>
                        ) : (
                            dateTimeBlock
                        )}
                    </Div>
                    {(!noLiveAccess || canListen || hasTranscript) && to && this.renderEventButtons()}
                </Div>
                {this.renderReaction()}
                {displayMode === STREAM_DISPLAY_MODES.default && this.renderConference()}
                {this.renderSummary()}
            </Card>
        );
    }
}

export const EventCardUI = compose(withStyleSheet(styleSheet))(EventCard);
