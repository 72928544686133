/* eslint-disable */
/**
 * Polyfill for composedPath in Edge & IE
 * https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
 * https://gist.github.com/rockinghelvetica/00b9f7b5c97a16d3de75ba99192ff05c
 * https://stackoverflow.com/questions/58344817/alternative-to-composedpath-for-edge
 */
// Event.composedPath
(function(e, d, w) {
    if (!e.composedPath) {
        e.composedPath = function() {
            if (this.path) return this.path;
            let { target } = this;
            this.path = [];

            while (target.parentNode !== null) {
                this.path.push(target);
                target = target.parentNode;
            }

            this.path.push(d, w);
            return this.path;
        };
    }
})(Event.prototype, document, window);
