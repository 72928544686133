import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { ExternalLink } from 'components/ExternalLink';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class TranscrippetModal extends PureComponent {
    static propTypes = {
        embedCode: PropTypes.string.isRequired,
        transcrippetLink: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onCopyLink: PropTypes.func.isRequired,
        onCopyEmbed: PropTypes.func.isRequired,
        copiedLink: PropTypes.bool.isRequired,
        copiedEmbed: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const {
            onClose,
            passedStyles,
            styles,
            theme,
            onCopyEmbed,
            onCopyLink,
            onDownload,
            copiedEmbed,
            copiedLink,
            embedCode,
            transcrippetLink
        } = this.props;
        return (
            <Modal isOpen onModalClose={onClose} styles={styles.modal} title="Share Transcrippet™">
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div id="transcrippet-wrapper" styles={styles.transcrippetWrapper}>
                        <iframe width="100%" title="Aiera Transcrippet" height="100" id="transcrippet-frame" />
                    </Div>
                    <Div styles={styles.headerEmbed}>
                        <Text styles={{ color: theme.colors.gray06 }}>Post the embed code in your blog or website</Text>
                        <Div onClick={onCopyEmbed} styles={styles.buttonDownload}>
                            <Icon type="copyToClipboard" color={theme.colors.gray06} />
                            <Text>{copiedEmbed ? 'Copied!' : 'Copy Embed Code'}</Text>
                        </Div>
                    </Div>
                    <textarea readOnly style={styles.textarea}>
                        {embedCode}
                    </textarea>
                    <Div styles={styles.headerLink}>
                        <Text styles={{ color: theme.colors.gray06 }}>
                            Or, download an image of the Transcrippet and link directly to it
                        </Text>
                        <Div onClick={onDownload} styles={styles.buttonDownload}>
                            <Icon type="download" color={theme.colors.gray06} />
                            <Text>Download Image</Text>
                        </Div>
                    </Div>
                    <Div styles={styles.shareLinkWrapper}>
                        <Div onClick={onCopyLink} styles={styles.buttonShareLink}>
                            <Icon type="copyToClipboard" color={theme.colors.gray06} />
                            <Text styles={{ marginLeft: 4 }}>{copiedLink ? 'Copied!' : 'Copy Link'}</Text>
                        </Div>
                        <ExternalLink style={styles.externalLink} href={transcrippetLink}>
                            {transcrippetLink}
                        </ExternalLink>
                    </Div>
                </Div>
            </Modal>
        );
    }
}

export const TranscrippetModalUI = compose(withStyleSheet(styleSheet))(TranscrippetModal);
