export const styleSheet = theme => ({
    container: {
        borderTop: `1px solid ${theme.colors.gray01}`,
        margin: '25px 35px'
    },
    checkbox: {
        p: {
            color: 'inherit',
            fontSize: theme.fonts.fontSizes.size03,
            letterSpacing: 'normal',
            textTransform: 'unset'
        },
        '.rowBox': {
            border: `1px solid ${theme.colors.gray04}`,
            marginRight: theme.margins.margin00
        },
        '.rowBoxChecked': {
            marginRight: theme.margins.margin00
        },
        ':hover': {
            '.rowBox': {
                border: `1px solid ${theme.colors.gray06}`
            }
        }
    },
    formRow: {
        paddingTop: 30
    },
    formRowTitle: {
        paddingTop: 30,
        'div:last-of-type': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    heading: {
        padding: '25px 0 5px'
    },
    input: {
        ...theme.content.inputErrorSmall
    },
    localeAutocomplete: {
        ...theme.content.inputErrorSmall,
        '.iconWrapper': {
            top: '0 !important' // overwrite div:first-of-type style from inputErrorSmall
        }
    }
});
