import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'utils';
import { CopyLinkUI } from './ui';

export class CopyLink extends PureComponent {
    static displayName = 'CopyLinkContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        hideText: PropTypes.bool,
        textSize: PropTypes.number,
        url: PropTypes.string.isRequired
    };

    static defaultProps = {
        styles: {},
        hideText: false,
        textSize: 1
    };

    constructor(props) {
        super(props);

        this.copyToClipboard = this.copyToClipboard.bind(this);

        this.state = {
            copied: false
        };
    }

    copyToClipboard() {
        const { url } = this.props;
        if (url) {
            copyToClipboard(url).then(() => this.setState({ copied: true }));
        }
    }

    render() {
        const { styles, hideText, textSize, url } = this.props;
        const { copied } = this.state;
        return (
            <CopyLinkUI
                styles={styles}
                copied={copied}
                copyToClipboard={this.copyToClipboard}
                hideText={hideText}
                textSize={textSize}
                url={url}
            />
        );
    }
}

export const CopyLinkContainer = compose()(CopyLink);
