import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { connect } from 'react-redux';
import { TYPES, OPERATORS } from 'consts/filters';
import { statusBannerFire } from 'actions/statusBanner';
import { withMemo } from 'hoc/utils';
import { withRealtimeHighlights } from 'hoc/highlights';
import { formatMatchesByType, mapFiltersToRules, getBookmarkFilters } from 'utils/streams';
import { contentHighlightsStreamQuery } from 'graphql/bookmarks';
import { bookmarkFragment, bookmarkTargetFragment } from 'graphql/fragments/bookmarks';
import { streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        withMemo({
            formatMatchesByType
        }),
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withUserHighlightsCount {
                    currentUser {
                        id
                        highlightsCount
                    }
                }
            `,
            {
                props: ({ data }) => ({ highlightsCount: get(data, 'currentUser.highlightsCount') }),
                options: {
                    fetchPolicy: 'cache-first'
                },
                skip: ({ highlightsCount }) => highlightsCount !== undefined
            }
        ),
        graphql(contentHighlightsStreamQuery, {
            props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                const matches = formatMatches(get(data, 'streams[0].matches.results', []));
                return {
                    loading: !matches.length && get(data, 'loading'),
                    matches,
                    refreshHighlights: data.refetch,
                    fetchMore: data.fetchMore
                };
            },
            options: ({ newsId, filterKey, sortKey }) => {
                const filters = [
                    { type: TYPES.content, operator: OPERATORS.is, value: newsId },
                    ...getBookmarkFilters(filterKey)
                ];

                return {
                    fetchPolicy: 'cache-and-network',
                    returnPreviousData: true,
                    variables: {
                        streamTypes: ['bookmarks'],
                        rules: mapFiltersToRules(filters),
                        sort: sortKey === 'chron' ? 'target_id' : undefined
                    }
                };
            }
        }),
        graphql(
            gql`
                mutation ShareBookmark($bookmarkId: ID!, $shared: Boolean!) {
                    shareBookmark(input: { bookmarkId: $bookmarkId, shared: $shared }) {
                        bookmark {
                            ...bookmark
                        }
                    }
                }
                ${bookmarkFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    setVisibility: ({ bookmarkId, shared }) => {
                        return mutate({
                            variables: {
                                bookmarkId,
                                shared
                            }
                        })
                            .then(() => {
                                setStatusBanner('Highlight updated successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error updating highlight: ${error}`, 'error', 'circleX');
                                throw error;
                            });
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation manageContentSharing(
                    $shareSetting: Boolean!
                    $targetId: ID!
                    $streamTypes: [StreamType]!
                    $rules: [StreamRuleInput]
                    $size: Int = 100
                    $offset: Int = 0
                    $sort: String
                ) {
                    updateUserObjectSettings(
                        input: { targetType: content, targetId: $targetId, shareBookmarks: $shareSetting }
                    ) {
                        success
                        settings {
                            id
                            shareBookmarks
                        }
                        query {
                            content(filter: { contentIds: [$targetId] }) {
                                id
                                userSettings {
                                    id
                                    shareBookmarks
                                }
                            }
                            streams(filter: { previewStreamTypes: $streamTypes }) {
                                ...stream
                                matches(size: $size, fromIndex: $offset, sort: $sort, filter: { rules: $rules }) {
                                    total
                                    results {
                                        id
                                        bookmark {
                                            ...bookmark
                                            ...bookmarkTarget
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ${streamFragment}
                ${bookmarkFragment}
                ${bookmarkTargetFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, newsId, filterKey, sortKey } = ownProps;
                    const filters = [
                        { type: TYPES.content, operator: OPERATORS.is, value: newsId },
                        ...getBookmarkFilters(filterKey)
                    ];
                    return {
                        manageShareSettings: shareSetting =>
                            mutate({
                                variables: {
                                    targetId: newsId,
                                    shareSetting,
                                    streamTypes: ['bookmarks'],
                                    rules: mapFiltersToRules(filters),
                                    sort: sortKey === 'chron' ? 'target_id' : undefined,
                                    size: 100,
                                    offset: 0
                                }
                            })
                                .then(() => {
                                    setStatusBanner('Article settings changed successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error changing settings: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        ),
        withRealtimeHighlights()
    );
