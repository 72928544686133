import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { BookmarkForm } from 'components/BookmarkForm';
import { DashboardForm } from 'components/DashboardForm';
import { DashboardGallery } from 'components/DashboardGallery';
import { DashboardStats } from 'components/DashboardStats';
import { EquityFinancialsModal } from 'components/EquityFinancialsModal';
import { ExportEventsModal } from 'components/ExportEventsModal';
import { ExportMatchesModal } from 'components/ExportMatchesModal';
import { HighlightExportModal } from 'components/HighlightExportModal';
import { PrivateRecordingForm } from 'components/PrivateRecordingForm';
import { StreamForm } from 'components/StreamForm';
import { SubmitEventDetailsForm } from 'components/SubmitEventDetailsForm';
import { UpgradeModal } from 'components/UpgradeModal';
import { VideoModal } from 'components/VideoModal';
import { WatchlistForm } from 'components/WatchlistForm';
import { WatchlistIcalModal } from 'components/WatchlistIcalModal';
import { TranscrippetModal } from 'components/TranscrippetModal';
import { styleSheet } from './stylesheet';

class Modals extends PureComponent {
    static propTypes = {
        dashboardGalleryId: PropTypes.string,
        dashboardId: PropTypes.string,
        dashboardStatsId: PropTypes.string,
        editBookmarkId: PropTypes.string,
        editDashboardId: PropTypes.string,
        editPrivateRecordingId: PropTypes.string,
        editStreamId: PropTypes.string,
        editWatchlistId: PropTypes.string,
        equityFinancialsId: PropTypes.string,
        exportEventsId: PropTypes.string,
        exportHighlights: PropTypes.string,
        exportMatchesId: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        sectionId: PropTypes.string,
        sharedStreamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitEventDetailsId: PropTypes.string,
        transcrippetId: PropTypes.string,
        upgradeType: PropTypes.string,
        videoType: PropTypes.string,
        watchlistIcalId: PropTypes.string
    };

    static defaultProps = {
        dashboardGalleryId: null,
        dashboardId: null,
        dashboardStatsId: null,
        editBookmarkId: null,
        editDashboardId: null,
        editPrivateRecordingId: null,
        editStreamId: null,
        editWatchlistId: null,
        equityFinancialsId: null,
        exportEventsId: null,
        exportHighlights: null,
        exportMatchesId: null,
        passedStyles: {},
        sectionId: null,
        sharedStreamId: null,
        submitEventDetailsId: null,
        transcrippetId: null,
        upgradeType: null,
        videoType: null,
        watchlistIcalId: null
    };

    render() {
        const {
            dashboardGalleryId,
            dashboardId, // id of the current dashboard page
            dashboardStatsId,
            editBookmarkId,
            editDashboardId, // id of the dashboard being edited
            editPrivateRecordingId,
            editStreamId,
            editWatchlistId,
            equityFinancialsId,
            exportEventsId,
            exportHighlights,
            exportMatchesId,
            onClose,
            passedStyles,
            sectionId,
            sharedStreamId,
            styles,
            submitEventDetailsId,
            transcrippetId,
            upgradeType,
            videoType,
            watchlistIcalId
        } = this.props;
        return (
            <Fragment>
                {!!dashboardGalleryId && <DashboardGallery onClose={onClose} />}
                {!!dashboardStatsId && (
                    <DashboardStats
                        dashboardId={dashboardStatsId}
                        isOpen
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!editBookmarkId && (
                    <BookmarkForm
                        bookmarkId={editBookmarkId}
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!editDashboardId && (
                    <DashboardForm
                        dashboardId={editDashboardId}
                        sectionId={sectionId}
                        isOpen
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!editPrivateRecordingId && (
                    <PrivateRecordingForm
                        onClose={onClose}
                        privateRecordingId={editPrivateRecordingId}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {(!!editStreamId || !!sharedStreamId) && (
                    <StreamForm
                        dashboardId={dashboardId}
                        isOpen
                        onClose={onClose}
                        streamId={editStreamId || sharedStreamId}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!editWatchlistId && (
                    <WatchlistForm
                        isOpen
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                        watchlistId={editWatchlistId}
                    />
                )}
                {!!equityFinancialsId && <EquityFinancialsModal equityId={equityFinancialsId} onClose={onClose} />}
                {!!exportEventsId && (
                    <ExportEventsModal
                        dashboardId={dashboardId}
                        onClose={onClose}
                        streamId={exportEventsId}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!exportHighlights && <HighlightExportModal onClose={onClose} sortFilterString={exportHighlights} />}
                {!!exportMatchesId && (
                    <ExportMatchesModal
                        isOpen
                        onClose={onClose}
                        streamId={exportMatchesId}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!submitEventDetailsId && (
                    <SubmitEventDetailsForm
                        eventId={submitEventDetailsId}
                        isOpen
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!transcrippetId && (
                    <TranscrippetModal
                        onClose={onClose}
                        transcrippetId={transcrippetId}
                        styles={{ ...styles.container, ...passedStyles }}
                    />
                )}
                {!!upgradeType && (
                    <UpgradeModal
                        type={upgradeType}
                        styles={{ ...styles.container, ...passedStyles }}
                        onClose={onClose}
                    />
                )}
                {!!videoType && (
                    <VideoModal
                        onClose={onClose}
                        styles={{ ...styles.container, ...passedStyles }}
                        videoType={videoType}
                    />
                )}
                {!!watchlistIcalId && <WatchlistIcalModal onClose={onClose} watchlistId={watchlistIcalId} />}
            </Fragment>
        );
    }
}

export const ModalsUI = compose(withStyleSheet(styleSheet))(Modals);
