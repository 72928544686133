import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Emoji } from 'components/Emoji';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { NOTIFICATION_TYPE_EMOJI_MAP } from 'consts';
import { get, getNativePrice, toTimeString, truncateString } from 'utils';
import { styleSheet } from './stylesheet';

class Notification extends PureComponent {
    static propTypes = {
        content: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.string.isRequired,
        dashboard: PropTypes.objectOf(PropTypes.any),
        equity: PropTypes.objectOf(PropTypes.any),
        event: PropTypes.objectOf(PropTypes.any),
        footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        isRead: PropTypes.bool.isRequired,
        message: PropTypes.objectOf(PropTypes.any),
        notificationType: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        content: null,
        dashboard: null,
        equity: null,
        event: null,
        footer: null,
        message: null,
        notificationType: null,
        passedStyles: {},
        stream: null
    };

    renderEventNotification() {
        const { equity, event, footer, notificationType, styles } = this.props;
        const { callDate, callType, title } = event || {};
        return (
            <Div styles={styles.notificationContainer}>
                <Text size={1} weight="medium" styles={styles.notificationHeader} uppercase>
                    <Emoji
                        label="upcoming event"
                        styles={styles.notificationEmoji}
                        symbol={NOTIFICATION_TYPE_EMOJI_MAP[notificationType]}
                    />
                    Upcoming Event
                </Text>
                <Text size={1} styles={styles.notificationBody}>
                    {get(equity, 'commonName', '')} {callType === 'earnings' ? 'earnings' : `"${title}"`} event starting
                    soon{callDate ? `, at ${toTimeString(callDate)}` : ''}
                </Text>
                {footer ||
                    (callDate && (
                        <Text size={0} styles={styles.notificationFooter}>
                            {new XDate(callDate).toString("MMM d 'at' hh:mm TT")}
                        </Text>
                    ))}
            </Div>
        );
    }

    renderEventPriceNotification() {
        const { created, equity, event, footer, message, styles, theme } = this.props;
        const { callType, title } = event || {};
        const { endOrLatestPrice = 0, movementPercent = 0, movementAbsolute = 0 } = message || {};
        const isPriceUp = movementPercent > 0;
        const { color, emoji } = isPriceUp
            ? { color: theme.colors.green02, emoji: 'event_price_reaction_up' }
            : { color: theme.colors.red01, emoji: 'event_price_reaction_down' };
        const currency = get(equity, 'currency');
        return (
            <Div styles={styles.notificationContainer}>
                <Text size={1} weight="medium" styles={styles.notificationHeader} uppercase>
                    <Emoji
                        label={isPriceUp ? 'price reaction increase' : 'price reaction decrease'}
                        styles={styles.notificationEmoji}
                        symbol={NOTIFICATION_TYPE_EMOJI_MAP[emoji]}
                    />
                    Price Highlight
                </Text>
                <Text size={1} styles={styles.notificationBody}>
                    {get(equity, 'commonName', '')} {callType === 'earnings' ? 'earnings' : `"${title}"`} event has a
                    price reaction of&nbsp;
                    <Span styles={{ color }}>
                        {isPriceUp ? '+' : ''}
                        {(movementPercent * 100).toFixed(2)}%
                    </Span>
                    , {isPriceUp ? 'up' : 'down'} {getNativePrice({ currency, price: movementAbsolute })} to&nbsp;
                    {getNativePrice({ currency, price: endOrLatestPrice })}
                </Text>
                {footer || (
                    <Text size={0} styles={styles.notificationFooter}>
                        {new XDate(created).toString("MMM d 'at' hh:mm TT")}
                    </Text>
                )}
            </Div>
        );
    }

    renderEventQuicklinksNotification() {
        const { created, dashboard, equity, event, footer, notificationType, stream, styles } = this.props;
        const { callType, title } = event || {};
        const dashName = get(dashboard, 'name');
        const streamName = get(stream, 'name');
        return (
            <Div styles={styles.notificationContainer}>
                <Text size={1} weight="medium" styles={styles.notificationHeader} uppercase>
                    <Emoji
                        label="key mention"
                        styles={styles.notificationEmoji}
                        symbol={NOTIFICATION_TYPE_EMOJI_MAP[notificationType]}
                    />
                    Key Mention
                </Text>
                <Text size={1} styles={styles.notificationBody}>
                    <Span styles={styles.bold}>&ldquo;{streamName}&rdquo;</Span>
                    &nbsp;mentioned in {get(equity, 'commonName', '')}&nbsp;
                    {callType === 'earnings' ? 'earnings' : `"${title}"`} event
                </Text>
                {footer || (
                    <Text size={0} styles={styles.notificationFooter}>
                        {new XDate(created).toString("MMM d 'at' hh:mm TT")}
                        {!!(dashName || streamName) && <Span>,&nbsp;</Span>}
                        {dashName}
                        {streamName ? ` • ${streamName}` : ''}
                    </Text>
                )}
            </Div>
        );
    }

    renderSpotlightMatchNotification() {
        const { content, created, dashboard, footer, notificationType, stream, styles } = this.props;
        const dashName = get(dashboard, 'name');
        const streamName = get(stream, 'name');
        return (
            <Div styles={styles.notificationContainer}>
                <Text size={1} weight="medium" styles={styles.notificationHeader} uppercase>
                    <Emoji
                        label="corporate activity"
                        styles={styles.notificationEmoji}
                        symbol={NOTIFICATION_TYPE_EMOJI_MAP[notificationType]}
                    />
                    Corporate Activity
                </Text>
                <Text size={1} styles={styles.notificationBody}>
                    {truncateString(get(content, 'title', ''), 140)}
                </Text>
                {footer || (
                    <Text size={0} styles={styles.notificationFooter}>
                        {new XDate(created).toString("MMM d 'at' hh:mm TT")}
                        {!!(dashName || streamName) && <Span>,&nbsp;</Span>}
                        {dashName}
                        {streamName ? ` • ${streamName}` : ''}
                    </Text>
                )}
            </Div>
        );
    }

    renderStreamMatchNotification() {
        const { content, created, dashboard, equity, event, footer, message, stream, styles } = this.props;
        const dashName = get(dashboard, 'name');
        const equityName = get(equity, 'commonName', '');
        const streamName = get(stream, 'name');
        const newsSource = get(content, 'newsSource.name');
        const isEquityDash = !!get(dashboard, 'equityId') && !get(message, 'keywordMatches');
        const contentTitle = get(content, 'title', '');
        const eventTitle = get(event, 'title', '');
        return (
            <Div styles={styles.notificationContainer}>
                <Text size={1} styles={styles.notificationBody}>
                    <Span styles={styles.bold}>&ldquo;{isEquityDash ? equityName : streamName}&rdquo;</Span>
                    &nbsp;mentioned in {contentTitle ? 'article' : ''} &ldquo;
                    {truncateString(contentTitle || eventTitle, 60)}&rdquo;
                    {newsSource ? ` on ${newsSource}` : ''}
                </Text>
                {footer || (
                    <Text size={0} styles={styles.notificationFooter}>
                        {new XDate(created).toString("MMM d 'at' hh:mm TT")}
                        {!!(dashName || streamName) && <Span>,&nbsp;</Span>}
                        {dashName}
                        {streamName ? ` • ${streamName}` : ''}
                    </Text>
                )}
            </Div>
        );
    }

    render() {
        const { isRead, notificationType, onClick, passedStyles, styles } = this.props;
        let notification;
        if (notificationType === 'event') notification = this.renderEventNotification();
        if (notificationType === 'event_price_reaction') notification = this.renderEventPriceNotification();
        if (notificationType === 'event_quicklinks_match') notification = this.renderEventQuicklinksNotification();
        if (notificationType === 'spotlight_match') notification = this.renderSpotlightMatchNotification();
        if (notificationType === 'stream_match') notification = this.renderStreamMatchNotification();
        if (notification) {
            let containerStyles = { ...styles.container, ...passedStyles };
            if (!isRead) {
                containerStyles = { ...containerStyles, ...styles.containerUnread };
            }
            return (
                <Div onClick={onClick} styles={containerStyles}>
                    {notification}
                </Div>
            );
        }
        return null;
    }
}

export const NotificationUI = compose(withStyleSheet(styleSheet))(Notification);
