export const styleSheet = theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white01
    },
    containerLoading: {
        backgroundColor: theme.colors.white01,
        paddingBottom: theme.margins.margin03,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    fullTextHeading: {
        lineHeight: '1.35em',
        fontFamily: theme.fonts.fontFamilyContent,
        fontWeight: theme.fonts.fontWeights.bold
    },
    publishDate: {
        color: theme.colors.gray06,
        marginTop: 4,
        marginBottom: theme.margins.margin01
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    documentContent: {
        h1: {
            fontWeight: theme.fonts.fontWeights.medium,
            fontSize: theme.fonts.fontSizes.size05
        },
        ul: {
            display: 'block',
            listStyleType: 'disc',
            marginTop: '1em',
            marginBottom: '1em',
            paddingLeft: theme.margins.margin02
        },
        li: {
            display: 'list-item'
        },
        strong: {
            fontWeight: theme.fonts.fontWeights.medium
        },
        p: {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        },
        '> *': {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        }
    },
    fullTextContainer: {
        padding: `28px ${theme.margins.margin02}`,
        wordBreak: 'break-word',
        '> *': {
            overflowWrap: 'break-word'
        },
        span: {
            display: 'block',
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        ...theme.content.annotated,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: theme.margins.margin01,
            paddingBottom: 0
        }
    },
    loading: {
        padding: theme.margins.margin03,
        color: theme.colors.gray02
    },
    loadingBlock: {
        height: '24px',
        marginBottom: '10px',
        backgroundColor: theme.colors.gray03
    },
    loadingBlocks: {
        width: '80%',
        paddingTop: theme.margins.margin01,
        '> div:last-child': {
            marginRight: theme.margins.margin03
        }
    },
    categories: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    category: {
        marginRight: 4,
        marginTop: 6,
        letterSpacing: 1,
        padding: '3px 4px 2px',
        borderRadius: 2.5,
        backgroundColor: theme.colors.gray06,
        color: theme.colors.gray10
    },
    categoryContainer: {
        marginTop: theme.margins.margin01,
        marginBottom: 5
    },
    catLabel: {
        color: theme.colors.gray04,
        letterSpacing: 2
    },
    ticker: {
        color: theme.colors.orange04,
        fontSize: theme.fonts.fontSizes.size00,
        letterSpacing: '1px'
    },
    company: {
        marginTop: 3
    }
});
