import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { withData } from './data';
import { UploadDocumentButtonUI } from './ui';

export class UploadDocumentButton extends PureComponent {
    static displayName = 'UploadDocumentButtonContainer';

    static propTypes = {
        bulkUpload: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.beforeHide = this.beforeHide.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onHide = this.onHide.bind(this);
        this.tagCompany = this.tagCompany.bind(this);

        this.state = {
            bulkFiles: [],
            documentTitle: '',
            loading: false,
            status: 'waiting',
            companyId: undefined
        };
    }

    onChangeTitle({ value }) {
        this.setState({
            status: 'waiting',
            documentTitle: value
        });
    }

    onUpload({ fileName, bulkFiles }) {
        this.setState(({ documentTitle }) => ({
            bulkFiles,
            status: 'waiting',
            documentTitle: documentTitle || fileName
        }));
    }

    onSubmit() {
        const { bulkFiles, documentTitle, companyId } = this.state;
        const { bulkUpload } = this.props;
        this.setState(
            {
                loading: true
            },
            () => {
                bulkUpload({
                    files: bulkFiles.map(({ url, name }) => ({
                        fileUrl: url,
                        originalFilename: name,
                        title: bulkFiles.length === 1 ? documentTitle : undefined,
                        companyId
                    }))
                })
                    .then(() =>
                        this.setState(
                            {
                                loading: false,
                                status: 'success',
                                bulkFiles: [],
                                documentTitle: '',
                                companyId: undefined
                            },
                            () => {
                                setTimeout(() => this.setState({ status: 'waiting' }), 10000);
                            }
                        )
                    )
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        );
    }

    onDeleteFile({ bulkFilesRaw }) {
        const isSingleFile = bulkFilesRaw.length === 1;
        const singleFile = bulkFilesRaw[0];
        this.setState({
            bulkFiles: bulkFilesRaw,
            status: 'waiting',
            documentTitle: isSingleFile ? singleFile.name : ''
        });
    }

    onHide() {
        this.setState({
            bulkFiles: [],
            loading: false,
            documentTitle: '',
            companyId: undefined,
            status: 'waiting'
        });
    }

    beforeHide() {
        const { bulkFiles } = this.state;
        let confirm = true;
        if (bulkFiles.length > 0) {
            // eslint-disable-next-line no-alert
            confirm = window.confirm(
                'Are you sure you want to close the upload window? You will need to re-upload any un-saved files.'
            );
        }
        return confirm;
    }

    tagCompany({ companyId }) {
        this.setState({ companyId });
    }

    render() {
        const { styles } = this.props;
        const { bulkFiles, documentTitle, loading, status } = this.state;
        return (
            <WithPermission permissions={[PERMISSIONS.featureUploads]}>
                <UploadDocumentButtonUI
                    beforeHide={this.beforeHide}
                    bulkFiles={bulkFiles}
                    documentTitle={documentTitle}
                    loading={loading}
                    status={status}
                    onHide={this.onHide}
                    onChangeTitle={this.onChangeTitle}
                    onDeleteFile={this.onDeleteFile}
                    onUpload={this.onUpload}
                    onSubmit={this.onSubmit}
                    styles={styles}
                    tagCompany={this.tagCompany}
                />
            </WithPermission>
        );
    }
}

export const UploadDocumentButtonContainer = compose(withData())(UploadDocumentButton);
