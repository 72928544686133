import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateTabURL, get } from 'utils';

import { STREAM_DISPLAY_MODES } from 'consts';
import { ResearchCardUI } from './ui';

export class ResearchCard extends PureComponent {
    static displayName = 'ResearchCardContainer';

    static propTypes = {
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightsMatches: PropTypes.arrayOf(PropTypes.any),
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        publishers: PropTypes.arrayOf(PropTypes.any),
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        equities: [],
        highlightColor: undefined,
        highlights: null,
        highlightsMatches: [],
        highlightTitle: null,
        publishers: [],
        score: undefined,
        styles: {},
        streamId: null
    };

    render() {
        const {
            company,
            displayMode,
            equities,
            highlightColor,
            highlights,
            highlightsMatches,
            highlightTitle,
            id,
            matchId,
            pathname,
            publishedDate,
            publishers,
            score,
            styles,
            streamId,
            title
        } = this.props;
        const firstMatch = highlightsMatches.find(m => m.length > 0);
        const page = firstMatch ? firstMatch.join(',') : '';
        const source = get(publishers, '[0].source');
        return (
            <ResearchCardUI
                company={company}
                displayMode={displayMode}
                equities={equities}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                matchId={matchId}
                source={source}
                onClick={this.handleClick}
                publishedDate={publishedDate}
                score={score}
                streamId={streamId}
                styles={styles}
                title={title}
                to={generateTabURL({ pathname, page, researchId: id, streamId })}
            />
        );
    }
}

export const ResearchCardContainer = compose(withUrlContext(['pathname']))(ResearchCard);
