import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { ActionButton } from 'components/ActionButton';

import { styleSheet } from './stylesheet';

class AlertToast extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        visible: PropTypes.bool,
        type: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        visible: false,
        type: null
    };

    renderRefreshAlert() {
        const { styles } = this.props;
        return (
            <Div styles={styles.refreshAlert}>
                <Text weight="medium" size={3} styles={styles.title}>
                    Dashboard out of date
                </Text>
                <Div styles={styles.alertActions}>
                    <Text>The page will automatically refresh in about one minute</Text>
                    <ActionButton styles={styles.refreshButton} onClick={() => window.location.reload(true)}>
                        <Text size={1} uppercase>
                            Refresh now
                        </Text>
                    </ActionButton>
                </Div>
            </Div>
        );
    }

    render() {
        const { styles, passedStyles, visible, type } = this.props;
        const containerStyle = visible ? styles.container : styles.containerHidden;

        return (
            <Div styles={{ ...containerStyle, ...passedStyles }}>{type === 'refresh' && this.renderRefreshAlert()}</Div>
        );
    }
}

export const AlertToastUI = compose(withStyleSheet(styleSheet))(AlertToast);
