import gql from 'graphql-tag';
import { contentMatchFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { getDefaultStreamProps } from 'hoc/streams';
import { withMemo } from 'hoc/utils';
import { compose } from 'recompose';
import { get } from 'utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';

const transcriptMatchFragment = gql`
    fragment transcriptMatch on TranscriptStreamMatch {
        id
        highlights
        highlightFields
        itemId
        bookmark {
            id
            highlightColor
            status
        }
        transcript {
            id
            itemId
            startTimestamp
            startMs
            sentiment {
                primarySentiment
            }
            speaker {
                id
                name
                affiliation
                title
            }
            transcript
            event {
                eventId
                callType
                callDate
                pressUrl
                slidesUrl
                hasPublishedTranscript
                equity {
                    id
                    icon
                    localTicker
                    commonName
                    exchange {
                        id
                        shortName
                    }
                }
                tags {
                    tag
                    users {
                        id
                        username
                    }
                }
                id
                title
                userSettings {
                    id
                    archived
                    starred
                    isRead
                    tags
                }
            }
        }
        collapsed {
            id
            highlights
            highlightFields
            itemId
            transcript {
                id
                itemId
                startTimestamp
                startMs
                speaker {
                    id
                    name
                    affiliation
                    title
                }
                transcript
            }
        }
        userSettings {
            id
            score
        }
    }
`;
export const withData = () =>
    compose(
        withMemo({
            formatMatches: formatMatchesByType,
            mapFilters: mapDashFiltersToRules
        }),
        graphql(
            gql`
                query withContentStream($streamId: ID, $size: Int = 20, $offset: Int = 0, $filter: StreamMatchFilter) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        lens {
                            rules {
                                ...rule
                            }
                        }
                        rules {
                            ...rule
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...contentMatch
                                ...transcriptMatch
                            }
                        }
                    }
                }
                ${contentMatchFragment}
                ${transcriptMatchFragment}
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { loading, matches, stream, subtitle } = getDefaultStreamProps(data, formatMatches);
                    const searchTerms = get(stream, 'rules', [])
                        .filter(({ ruleType }) => ruleType === 'search_term')
                        .map(({ value }) => value);
                    return {
                        searchTerms,
                        loading,
                        matches,
                        stream,
                        streamName: get(stream, 'name'),
                        subtitle,
                        totalMatches: get(stream, 'matches.total')
                    };
                },
                skip: ({ exportMatchesId }) => !exportMatchesId,
                options: ({ location, dateRange, downloadSize, mapFilters, exportMatchesId }) => {
                    const dashEquityScope = get(location, 'state.dashEquityScope');
                    const dashSearchTerm = get(location, 'state.dashSearchTerm');
                    const lenses = get(location, 'lenses');
                    return {
                        notifyOnNetworkStatusChange: true,
                        fetchPolicy: 'cache-and-network',
                        variables: {
                            filter: {
                                applyLens: true,
                                rules: mapFilters
                                    ? mapFilters({
                                          dateRange,
                                          equityScope: dashEquityScope,
                                          searchTerm: dashSearchTerm
                                      })
                                    : undefined,
                                lenses: lenses?.length > 0 ? [{ rules: mapFilters({ filters: lenses }) }] : undefined
                            },
                            streamId: exportMatchesId,
                            size: downloadSize
                        },
                        context: {
                            debounceKey: `debounce:content:${exportMatchesId}`,
                            debounceTimeout: dateRange ? 300 : 0
                        }
                    };
                }
            }
        )
    );
