import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { FileUpload } from 'components/FileUpload';
import { FormRow } from 'components/FormRow';
import { Heading } from 'components/Heading';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { PhoneNumberInput } from 'components/PhoneNumberInput';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { colors } from 'styles/colors';
import {
    CONNECTION_OPTIONS,
    CONNECTION_TYPES,
    PARTICIPATION_OPTIONS,
    PARTICIPATION_TYPES,
    ZOOM_MEETING_TYPE_OPTIONS,
    ZOOM_MEETING_TYPES
} from '../consts';
import { styleSheet } from './stylesheet';

class ConnectionDetails extends PureComponent {
    static propTypes = {
        connectAccessId: PropTypes.string,
        connectCallerId: PropTypes.string,
        connectionType: PropTypes.string,
        connectPhoneNumber: PropTypes.string,
        connectPin: PropTypes.string,
        connectUrl: PropTypes.string,
        domainScore: PropTypes.number,
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        externalAudioStreamUrl: PropTypes.string,
        filename: PropTypes.string,
        lockConnectionTypeSelect: PropTypes.bool.isRequired,
        meetingType: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFileDelete: PropTypes.func.isRequired,
        onFileUpload: PropTypes.func.isRequired,
        onConnectDialNumber: PropTypes.string,
        onFocus: PropTypes.func.isRequired,
        organizationName: PropTypes.string,
        participationType: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        smsAlertBeforeCall: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        touched: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        connectAccessId: '',
        connectCallerId: '',
        connectionType: undefined,
        connectPhoneNumber: '',
        connectPin: '',
        connectUrl: '',
        domainScore: undefined,
        externalAudioStreamUrl: '',
        filename: undefined,
        onConnectDialNumber: '',
        organizationName: undefined,
        participationType: undefined,
        passedStyles: {}
    };

    constructor(props) {
        super(props);
        this.renderHelpContent = this.renderHelpContent.bind(this);
    }

    renderDomainScore() {
        const { domainScore, errors, styles } = this.props;
        if (domainScore !== undefined && !errors.connectUrl) {
            let color = colors.red02;
            let text = "*We're not sure if we'll be able to transcribe this recording";
            if (domainScore >= 0.9) {
                color = colors.green02;
                text = "*We're confident that we'll be able to transcribe this recording";
            }
            return (
                <Text size={1} styles={{ ...styles.inputExample, color }}>
                    {text}
                </Text>
            );
        }
        return null;
    }

    renderHelp(name, growLeft = false) {
        const { styles } = this.props;
        return (
            <Tooltip
                content={() => this.renderHelpContent(name)}
                growLeft={growLeft}
                isEnabled
                slideIn
                useElementOffsetBottom
                useElementOffsetLeft
                xOffset={growLeft ? 30 : 0}
                yOffset={10}
            >
                {({ hideTooltip, showTooltip }) => (
                    <Div
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                        styles={name === 'connectPhoneNumber' ? styles.helpContainerPadded : styles.helpContainer}
                    >
                        <Text size={1}>Help?</Text>
                    </Div>
                )}
            </Tooltip>
        );
    }

    renderHelpContent(name) {
        const { connectionType, meetingType, styles } = this.props;
        let content;
        switch (connectionType) {
            case CONNECTION_TYPES.googleMeet.value:
                content = (
                    <Div styles={styles.helpContentContainer}>
                        <Text>
                            To join the video meeting, click this link: https://meet.google.com/abc-defg-hij
                            <br />
                            Otherwise, to join by phone, dial&nbsp;
                            <Span
                                styles={name === 'connectPhoneNumber' ? styles.helpContentSpanHighlighted : undefined}
                            >
                                +1 123-456-7890
                            </Span>
                            &nbsp;and enter this PIN:&nbsp;
                            <Span styles={name === 'connectPin' ? styles.helpContentSpanHighlighted : undefined}>
                                123 456 789#
                            </Span>
                            <br />
                            To view more phone numbers, click this link: https://tel.meet/abc-defg-hij?hs=5
                        </Text>
                    </Div>
                );
                break;
            case CONNECTION_TYPES.zoom.value:
                content = (
                    <Div styles={styles.helpContentContainer}>
                        <Text>
                            Join Zoom Meeting
                            <br />
                            <Span styles={name === 'connectUrl' ? styles.helpContentSpanHighlighted : undefined}>
                                https://us02web.zoom.us/j/12345678910?pwd=VW132451T5NKxzJldGVuszgdSFJ6gTa5
                            </Span>
                            <br />
                            <br />
                            Meeting ID: 123 4567 8910
                            <br />
                            <Span
                                styles={
                                    name === 'connectPin' && meetingType === ZOOM_MEETING_TYPES.web.value
                                        ? styles.helpContentSpanHighlighted
                                        : undefined
                                }
                            >
                                Passcode: 33xHkR
                            </Span>
                            <br />
                            One tap mobile
                            <br />
                            +12345678910,,98765432100#,,,,*561804# US (Chicago)
                            <br />
                            +12345678910,,98765432100#,,,,*561804# US (New York)
                            <br />
                            <br />
                            Dial by your location
                            <br />
                            <Span styles={styles.helpContentSpan}>+1 312 626 6799 US (Chicago)</Span>
                            <br />
                            <Span
                                styles={
                                    name === 'connectPhoneNumber'
                                        ? styles.helpContentSpanHighlightedPadded
                                        : styles.helpContentSpan
                                }
                            >
                                +1 929 205 6099 US (New York)
                            </Span>
                            <br />
                            <Span styles={styles.helpContentSpan}>+1 301 715 8592 US (Washington DC)</Span>
                            <br />
                            <Span styles={styles.helpContentSpan}>+1 346 248 7799 US (Houston)</Span>
                            <br />
                            <Span styles={styles.helpContentSpan}>+1 669 900 6833 US (San Jose)</Span>
                            <br />
                            <Span styles={styles.helpContentSpan}>+1 253 215 8782 US (Tacoma)</Span>
                            <br />
                            <Span styles={name === 'connectAccessId' ? styles.helpContentSpanHighlighted : undefined}>
                                Meeting ID: 123 4567 8910
                            </Span>
                            <br />
                            <Span
                                styles={
                                    name === 'connectPin' && meetingType === ZOOM_MEETING_TYPES.phone.value
                                        ? styles.helpContentSpanHighlighted
                                        : undefined
                                }
                            >
                                Passcode: 123456
                            </Span>
                            <br />
                            <br />
                            Find your local number in the Audio Conferencing tab of your Zoom account settings:
                            <br />
                            https://zoom.us/profile/setting
                        </Text>
                    </Div>
                );
                break;
            default:
                content = null;
        }
        return content;
    }

    renderOptions(options, selected, optionStyles = {}) {
        const { styles } = this.props;
        return options.map(({ description, disabled, label, permission, value }) => (
            <Option
                key={value}
                disabled={disabled}
                permission={permission}
                styles={optionStyles}
                type="radio"
                value={value}
            >
                <Div>
                    <Text size={3} weight={selected === value ? 'medium' : 'normal'}>
                        {label}
                    </Text>
                    <Text size={1} styles={styles.optionDescription}>
                        {description}
                    </Text>
                </Div>
            </Option>
        ));
    }

    renderRowsByType() {
        const {
            connectAccessId,
            connectCallerId,
            connectionType,
            connectPhoneNumber,
            connectPin,
            connectUrl,
            errors,
            externalAudioStreamUrl,
            filename,
            meetingType,
            onBlur,
            onChange,
            onFileDelete,
            onFileUpload,
            onFocus,
            organizationName,
            styles,
            touched
        } = this.props;
        const rows = [];
        const localStorageKey = 'aiera:privateRecording:connectPhoneNumber';
        const localStoragePhoneNumber = localStorage.getItem(localStorageKey);
        const connectPhoneNumberInput = name => {
            return (
                <FormRow
                    description={`Enter the ${name ? `${name} ` : ''}dial-in number`}
                    key={`${(name || '').replace(/\\s/g, '')}ConnectPhoneNumber`}
                    label="Dial-in number*"
                    styles={styles.formRow}
                >
                    <PhoneNumberInput
                        autoComplete="off"
                        defaultCountry="US"
                        error={errors.connectPhoneNumber}
                        helpElement={this.renderHelp('connectPhoneNumber', !!localStoragePhoneNumber)}
                        localStorageKey={localStorageKey}
                        name="connectPhoneNumber"
                        onChange={onChange}
                        placeholder="(888)-123-4567"
                        value={connectPhoneNumber}
                    />
                </FormRow>
            );
        };
        if (connectionType === CONNECTION_TYPES.zoom.value) {
            rows.push(
                <FormRow
                    description="Choose if Aiera should connect via web or by phone"
                    key="zoomMeetingType"
                    label="Zoom meeting type*"
                    styles={styles.formRow}
                >
                    <MultiSelect name="meetingType" onChange={onChange} required selected={[meetingType]}>
                        {this.renderOptions(ZOOM_MEETING_TYPE_OPTIONS, meetingType)}
                    </MultiSelect>
                </FormRow>
            );
            if (meetingType === 'web') {
                rows.push(
                    <FormRow
                        description="Enter the Zoom meeting url"
                        key="connectUrl"
                        label="Meeting URL*"
                        styles={styles.formRow}
                    >
                        <TextInput
                            autoComplete="off"
                            error={errors.connectUrl}
                            name="connectUrl"
                            onBlur={event => onBlur({ event, name: 'connectUrl', value: connectUrl })}
                            onChange={onChange}
                            onFocus={event => onFocus({ event, name: 'connectUrl' })}
                            placeholder="https://zoom.us/j/8881234567?pwd=Ya1b2c3d4e5"
                            styles={styles.input}
                            value={connectUrl}
                        />
                        {this.renderHelp('connectUrl')}
                    </FormRow>
                );
            } else {
                rows.push(connectPhoneNumberInput('Zoom'));
                rows.push(
                    <FormRow
                        description="Enter the meeting ID"
                        key="zoomAccessId"
                        label="Meeting ID*"
                        styles={styles.formRow}
                    >
                        <TextInput
                            autoComplete="off"
                            error={errors.connectAccessId}
                            name="connectAccessId"
                            onBlur={event => onBlur({ event, name: 'connectAccessId', value: connectAccessId })}
                            onChange={onChange}
                            onFocus={event => onFocus({ event, name: 'connectAccessId' })}
                            placeholder="1234567890"
                            styles={styles.input}
                            value={connectAccessId}
                        />
                        {this.renderHelp('connectAccessId')}
                    </FormRow>
                );
            }
            rows.push(
                <FormRow
                    description="Enter the passcode (optional)"
                    key="zoomConnectPin"
                    label="Passcode"
                    styles={styles.formRow}
                >
                    <TextInput
                        autoComplete="off"
                        error={(touched.connectPin || touched.meetingType) && errors.connectPin}
                        name="connectPin"
                        onBlur={event => onBlur({ event, name: 'connectPin', value: connectPin })}
                        onChange={onChange}
                        onFocus={event => onFocus({ event, name: 'connectPin' })}
                        placeholder="123456"
                        styles={styles.input}
                        value={connectPin}
                    />
                    {this.renderHelp('connectPin')}
                </FormRow>
            );
            if (meetingType === 'web') {
                rows.push(
                    <FormRow
                        description="Enter the name of the caller ID Aiera should use when connecting (optional)"
                        key="connectCallerId"
                        label="Caller ID"
                        styles={styles.formRow}
                    >
                        <TextInput
                            autoComplete="off"
                            error={touched.connectCallerId && errors.connectCallerId}
                            name="connectCallerId"
                            onBlur={event => onBlur({ event, name: 'connectCallerId', value: connectCallerId })}
                            onChange={onChange}
                            onFocus={event => onFocus({ event, name: 'connectCallerId' })}
                            placeholder={organizationName}
                            styles={styles.input}
                            value={connectCallerId}
                        />
                        {!!organizationName && (
                            <Text size={1} styles={styles.callerIdFootnote}>
                                {`If left blank, the caller ID will be "${organizationName}"`}
                            </Text>
                        )}
                    </FormRow>
                );
            }
        }
        if (connectionType === CONNECTION_TYPES.googleMeet.value) {
            rows.push(connectPhoneNumberInput('Google Meet'));
            rows.push(
                <FormRow
                    description="Enter a PIN (optional)"
                    key="googleConnectPin"
                    label="PIN"
                    styles={styles.formRow}
                >
                    <TextInput
                        autoComplete="off"
                        error={touched.connectPin && errors.connectPin}
                        name="connectPin"
                        onBlur={event => onBlur({ event, name: 'connectPin', value: connectPin })}
                        onChange={onChange}
                        onFocus={event => onFocus({ event, name: 'connectPin' })}
                        placeholder="555888999"
                        styles={styles.input}
                        value={connectPin}
                    />
                    {this.renderHelp('connectPin')}
                </FormRow>
            );
        }
        if (connectionType === CONNECTION_TYPES.phoneNumber.value) {
            rows.push(connectPhoneNumberInput(null));
            rows.push(
                <FormRow
                    description="Enter the meeting ID or access code"
                    key="phoneNumberAccessId"
                    label="Meeting ID / Access Code"
                    styles={styles.formRow}
                >
                    <TextInput
                        autoComplete="off"
                        error={errors.connectAccessId}
                        name="connectAccessId"
                        onBlur={event => onBlur({ event, name: 'connectAccessId', value: connectAccessId })}
                        onChange={onChange}
                        onFocus={event => onFocus({ event, name: 'connectAccessId' })}
                        placeholder="1234567890"
                        styles={styles.input}
                        value={connectAccessId}
                    />
                </FormRow>
            );
            rows.push(
                <FormRow
                    description="Enter a PIN (optional)"
                    key="phoneNumberConnectPin"
                    label="PIN"
                    styles={styles.formRow}
                >
                    <TextInput
                        autoComplete="off"
                        error={touched.connectPin && errors.connectPin}
                        name="connectPin"
                        onBlur={event => onBlur({ event, name: 'connectPin', value: connectPin })}
                        onChange={onChange}
                        onFocus={event => onFocus({ event, name: 'connectPin' })}
                        placeholder="123456"
                        styles={styles.input}
                        value={connectPin}
                    />
                </FormRow>
            );
        }
        if (connectionType === CONNECTION_TYPES.webcast.value) {
            rows.push(
                <FormRow
                    description="Enter the url to access recording"
                    key="connectUrl"
                    label="Host URL*"
                    styles={styles.formRow}
                >
                    <Div styles={styles.connectUrl}>
                        <TextInput
                            autoComplete="off"
                            error={errors.connectUrl}
                            name="connectUrl"
                            onBlur={event => onBlur({ event, name: 'connectUrl', value: connectUrl })}
                            onChange={onChange}
                            onFocus={event => onFocus({ event, name: 'connectUrl' })}
                            placeholder="https://zoom.us/j/8881234567?pwd=Ya1b2c3d4e5"
                            styles={styles.input}
                            value={connectUrl}
                        />
                        {this.renderDomainScore()}
                    </Div>
                </FormRow>
            );
        }
        if (connectionType === CONNECTION_TYPES.audioStream.value) {
            rows.push(
                <FormRow
                    description="Enter the audio stream url"
                    key="externalAudioStreamUrl"
                    label="Audio Stream URL*"
                    styles={styles.formRow}
                >
                    <Div styles={styles.connectUrl}>
                        <TextInput
                            autoComplete="off"
                            error={errors.externalAudioStreamUrl}
                            name="externalAudioStreamUrl"
                            onBlur={event =>
                                onBlur({ event, name: 'externalAudioStreamUrl', value: externalAudioStreamUrl })
                            }
                            onChange={onChange}
                            onFocus={event => onFocus({ event, name: 'externalAudioStreamUrl' })}
                            placeholder="https://thebestaudiostreams.com/1/audio.m3u8"
                            styles={styles.input}
                            value={externalAudioStreamUrl}
                        />
                    </Div>
                </FormRow>
            );
        }
        if (connectionType === CONNECTION_TYPES.audioUpload.value) {
            rows.push(
                <FormRow
                    description="Upload an audio file that Aiera will transcribe"
                    key="audioUpload"
                    label="Upload Audio File"
                    styles={styles.formRow}
                >
                    <FileUpload
                        filename={filename}
                        onDelete={onFileDelete}
                        onUpload={onFileUpload}
                        styles={styles.upload}
                        type="audio"
                    />
                </FormRow>
            );
        }
        return rows;
    }

    render() {
        const {
            connectionType,
            errors,
            lockConnectionTypeSelect,
            meetingType,
            onChange,
            onConnectDialNumber,
            participationType,
            passedStyles,
            smsAlertBeforeCall,
            styles
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Heading size={2} styles={styles.heading}>
                    Connection Details
                </Heading>
                <FormRow
                    description="Choose how Aiera should connect"
                    label="Type of connection?"
                    styles={styles.formRowFirst}
                >
                    <MultiSelect
                        disabled={lockConnectionTypeSelect}
                        name="connectionType"
                        onChange={onChange}
                        required
                        selected={[connectionType]}
                    >
                        {this.renderOptions(CONNECTION_OPTIONS, connectionType)}
                    </MultiSelect>
                </FormRow>
                {connectionType && (
                    <Fragment>
                        {this.renderRowsByType()}
                        {([CONNECTION_TYPES.googleMeet.value, CONNECTION_TYPES.phoneNumber.value].includes(
                            connectionType
                        ) ||
                            (connectionType === CONNECTION_TYPES.zoom.value &&
                                meetingType === ZOOM_MEETING_TYPES.phone.value)) && (
                            <Fragment>
                                <FormRow
                                    description="Do you want to participate or only transcribe & record?"
                                    label="How should we connect?"
                                    styles={styles.formRow}
                                >
                                    <MultiSelect
                                        name="participationType"
                                        onChange={onChange}
                                        required
                                        selected={[participationType]}
                                    >
                                        {this.renderOptions(
                                            PARTICIPATION_OPTIONS,
                                            participationType,
                                            styles.optionPadded
                                        )}
                                    </MultiSelect>
                                </FormRow>
                                {participationType === PARTICIPATION_TYPES.participating.value && (
                                    <FormRow
                                        description="Must be a direct line. Extensions are not supported for personal numbers"
                                        label="Your phone number"
                                        styles={styles.formRow}
                                    >
                                        <PhoneNumberInput
                                            autoComplete="off"
                                            defaultCountry="US"
                                            error={errors.onConnectDialNumber}
                                            localStorageKey="aiera:privateRecording:onConnectDialNumber"
                                            name="onConnectDialNumber"
                                            onChange={onChange}
                                            placeholder="(888)-123-4567"
                                            value={onConnectDialNumber}
                                        />
                                    </FormRow>
                                )}
                                {participationType === PARTICIPATION_TYPES.participating.value && (
                                    <FormRow
                                        description="Get an SMS 5 minutes before the call"
                                        label="Alert me before"
                                        styles={styles.formRow}
                                    >
                                        <Checkbox
                                            checked={smsAlertBeforeCall}
                                            name="smsAlertBeforeCall"
                                            onChange={onChange}
                                            size={2}
                                            styles={styles.checkbox}
                                        >
                                            Aiera has permission to text me a reminder before
                                            <br />
                                            the call
                                        </Checkbox>
                                    </FormRow>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const ConnectionDetailsUI = compose(withStyleSheet(styleSheet))(ConnectionDetails);
