import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { TranscriptFilterUI } from './ui';

export class TranscriptFilter extends PureComponent {
    static displayName = 'TranscriptFilterContainer';

    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        onChange: undefined,
        name: 'transcriptFilter',
        value: null
    };

    render() {
        const { name, value, onChange } = this.props;
        return <TranscriptFilterUI value={value} onChange={onChange} name={name} />;
    }
}

export const TranscriptFilterContainer = compose()(TranscriptFilter);
