import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { Icon } from 'components/Icon';
import { WithPermission } from 'components/WithPermission';
import { ContentActionBlock } from 'components/ContentActionBlock';
import { PERMISSIONS } from 'consts';
import { getEventDateTime, maybeGetUnknownTimeAgo, toDateTimeString } from 'utils';
import { styleSheet } from './stylesheet';

const DATE_FORMAT = 'MMM dS, yyyy';

class EventTitleInfo extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string,
        callType: PropTypes.string,
        companyName: PropTypes.string,
        disconnect: PropTypes.func.isRequired,
        disconnected: PropTypes.bool.isRequired,
        disconnecting: PropTypes.bool.isRequired,
        eventId: PropTypes.string,
        hasPublishedTranscript: PropTypes.bool.isRequired,
        hasUnknownTime: PropTypes.bool.isRequired,
        inProgress: PropTypes.bool.isRequired,
        isArchived: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        isUploading: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        minimal: PropTypes.bool.isRequired,
        onEditEvent: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedTranscriptSource: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tags: PropTypes.arrayOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any).isRequired,
        waiting: PropTypes.bool
    };

    static defaultProps = {
        callDate: null,
        callType: 'Unknown',
        companyName: undefined,
        eventId: undefined,
        passedStyles: {},
        publishedTranscriptSource: null,
        title: null,
        waiting: false
    };

    renderRecordingControls() {
        const {
            callDate,
            disconnect,
            disconnected,
            disconnecting,
            inProgress,
            onEditEvent,
            styles,
            theme
        } = this.props;
        const retryable = callDate ? new XDate(callDate).diffDays(new XDate()) <= 2 : false;
        if (retryable) {
            return (
                <MediaQuery maxWidth={theme.breakpoints.internal.mobileEdge}>
                    {mobile => (
                        <Div styles={styles.recordingControls}>
                            {!mobile && (
                                <Text size={0} styles={styles.recordingControlsHeader} uppercase>
                                    Recording controls
                                </Text>
                            )}
                            <Div styles={styles.buttonsContainer}>
                                {inProgress && !disconnected && (
                                    <ActionButton
                                        disabled={disconnecting}
                                        loaderColor={theme.colors.white01}
                                        loading={disconnecting}
                                        onClick={disconnect}
                                        size={2}
                                        styles={styles.buttonDisconnect}
                                    >
                                        <Text size={1}>End Call</Text>
                                    </ActionButton>
                                )}
                                {!mobile && (
                                    <ActionButton onClick={onEditEvent} size={2} styles={styles.buttonEdit}>
                                        <Text size={1}>Modify & Retry</Text>
                                    </ActionButton>
                                )}
                            </Div>
                        </Div>
                    )}
                </MediaQuery>
            );
        }
        return null;
    }

    renderTranscriptHeader() {
        const {
            styles,
            callDate,
            callType,
            companyName,
            title,
            hasPublishedTranscript,
            hasUnknownTime,
            inProgress,
            isUploading,
            waiting,
            minimal,
            isArchived,
            isPublic,
            isRead,
            isStarred,
            eventId,
            tags,
            theme,
            userTags
        } = this.props;
        let pageTitle = title || 'Loading...';
        if (companyName && callType && callDate) {
            const date = `${new XDate(callDate).toString(DATE_FORMAT)}`;
            pageTitle = title || `${callType} call - ${date}`;
        }
        const timeAgo = <TimeAgo debug date={callDate} />;
        return (
            <Div styles={styles.header}>
                {!hasPublishedTranscript && !minimal && (
                    <Div styles={styles.printer} onClick={window.print}>
                        <Icon type="printer" color={theme.colors.black01} />
                    </Div>
                )}
                {!minimal && companyName && (
                    <Text size={3} weight="regular" styles={styles.name}>
                        {companyName}
                    </Text>
                )}
                <Text size={6} weight="medium" styles={styles.eventTitle}>
                    {pageTitle}
                </Text>
                {inProgress || isUploading ? (
                    <Div styles={styles.eventStatusLive}>
                        <Div styles={styles.liveDot} />
                        <Text size={0} uppercase>
                            {waiting ? 'waiting' : 'live'}
                        </Text>
                        <Text size={0} styles={styles.headingCallDate} uppercase>
                            {toDateTimeString(callDate, true, true)}
                        </Text>
                    </Div>
                ) : (
                    <Div styles={styles.eventStatus}>
                        <Text size={0} uppercase>
                            {hasUnknownTime ? maybeGetUnknownTimeAgo(callDate) || timeAgo : timeAgo}
                        </Text>
                        <Text size={0} styles={styles.headingCallDate} uppercase>
                            {getEventDateTime(callDate, hasUnknownTime)}
                        </Text>
                    </Div>
                )}
                {!isPublic && minimal && (
                    <ContentActionBlock
                        contentType="transcript"
                        isArchived={isArchived}
                        isRead={isRead}
                        isStarred={isStarred}
                        tags={tags}
                        userTags={userTags}
                        targetId={eventId}
                        targetType="event"
                        styles={styles.actionBlock}
                    />
                )}
            </Div>
        );
    }

    renderHeaderLoading() {
        const { styles } = this.props;

        return (
            <Div styles={styles.loadHeader}>
                <Div styles={styles.loadEventTitle} />
                <Div styles={styles.loadName} />
            </Div>
        );
    }

    render() {
        const {
            callType,
            companyName,
            isOwner,
            isUploading,
            loading,
            minimal,
            passedStyles,
            publishedTranscriptSource,
            styles,
            title
        } = this.props;
        const containerStyle = minimal ? styles.containerMinimal : styles.container;
        if (loading && !companyName && !title) {
            return <Div styles={{ ...containerStyle, ...passedStyles }}>{this.renderHeaderLoading()}</Div>;
        }
        return (
            <Div styles={{ ...containerStyle, ...passedStyles }}>
                <WithPermission permissions={[PERMISSIONS.unlockedCreatePrivateRecording]}>
                    {({ isLoading, restricted }) =>
                        callType === 'custom' && isOwner && !isLoading && !restricted ? (
                            <Div styles={styles.headerContainer}>
                                {this.renderTranscriptHeader()}
                                {!isUploading && this.renderRecordingControls()}
                            </Div>
                        ) : (
                            this.renderTranscriptHeader()
                        )
                    }
                </WithPermission>
                {publishedTranscriptSource === 'scripts_asia' && (
                    <Img styles={styles.scriptsAsia} src="/static/nohash/scripts-asia-logo.png" />
                )}
            </Div>
        );
    }
}

export const EventTitleInfoUI = compose(withStyleSheet(styleSheet))(EventTitleInfo);
