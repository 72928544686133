import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { config } from 'configuration';
import { get } from 'utils';
import { withData } from './data';
import { WatchlistIcalModalUI } from './ui';

const DATE_OPTIONS = [
    { label: '1 week', value: '1W' },
    { label: '2 weeks', value: '2W' },
    { label: '4 weeks (default)', value: '4W' },
    { label: '2 months', value: '2M' },
    { label: '3 months', value: '3M' }
];
const EVENT_TYPE_OPTIONS = [
    { label: 'Earnings', value: 'earnings' },
    { label: 'Earnings Releases', value: 'earnings_release' },
    { label: 'Investor Meetings', value: 'investor_meeting' },
    { label: 'Shareholder Meetings', value: 'shareholder_meeting' },
    { label: 'Presentations', value: 'presentation' },
    { label: 'Special Situations', value: 'special_situation' }
];
// By default, include all types except earnings releases
const DEFAULT_EVENT_TYPES = EVENT_TYPE_OPTIONS.filter(o => o.value !== 'earnings_release').map(o => o.value);

function getUrl(icalToken, watchlistId, endDate, selectedEventTypes) {
    if (!icalToken) return null;
    const types = selectedEventTypes.length ? `&types=${selectedEventTypes.join(',')}` : '';
    const url = `${config.API_ENDPOINT.replace(
        /https?/,
        'webcal'
    )}/calendars/ical/user?token=${icalToken}&end_date=${endDate}${types}`;
    if (watchlistId) return `${url}&watchlist_id=${watchlistId}`;
    return url;
}

export class WatchlistIcalModal extends PureComponent {
    static displayName = 'WatchlistIcalModalContainer';

    static propTypes = {
        icalToken: PropTypes.string,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        watchlist: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        icalToken: undefined,
        loading: false,
        styles: undefined,
        watchlist: undefined
    };

    constructor(props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.onGoBack = this.onGoBack.bind(this);
        this.onToggleEventType = this.onToggleEventType.bind(this);
        this.toggleCheckboxes = this.toggleCheckboxes.bind(this);

        this.url = memoize(getUrl);

        this.state = {
            selectedDate: '4W',
            selectedEventTypes: DEFAULT_EVENT_TYPES,
            showCheckboxes: false,
            showForm: true
        };
    }

    onChangeDate({ value }) {
        this.setState({ selectedDate: value });
    }

    onContinue() {
        this.setState({ showForm: false });
    }

    onGoBack() {
        this.setState({ showForm: true });
    }

    onToggleEventType(eventType) {
        this.setState(({ selectedEventTypes }) => ({
            selectedEventTypes: selectedEventTypes.includes(eventType)
                ? selectedEventTypes.filter(type => type !== eventType)
                : [...selectedEventTypes, eventType]
        }));
    }

    toggleCheckboxes() {
        this.setState(({ showCheckboxes }) => ({
            showCheckboxes: !showCheckboxes
        }));
    }

    render() {
        const { selectedDate, selectedEventTypes, showCheckboxes, showForm } = this.state;
        const { icalToken, loading, onClose, styles, watchlist } = this.props;
        return (
            <WatchlistIcalModalUI
                dateOptions={DATE_OPTIONS}
                eventTypeOptions={EVENT_TYPE_OPTIONS}
                icalToken={icalToken}
                loading={loading}
                onChangeDate={this.onChangeDate}
                onClose={onClose}
                onContinue={this.onContinue}
                onGoBack={this.onGoBack}
                onToggleEventType={this.onToggleEventType}
                selectedDate={selectedDate}
                selectedEventTypes={selectedEventTypes}
                showCheckboxes={showCheckboxes}
                showForm={showForm}
                styles={styles}
                toggleCheckboxes={this.toggleCheckboxes}
                url={this.url(icalToken, get(watchlist, 'id'), selectedDate, selectedEventTypes)}
                watchlist={watchlist}
            />
        );
    }
}

export const WatchlistIcalModalContainer = compose(withData())(WatchlistIcalModal);
