import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { withUrlContext } from 'hoc/url';
import { withEventDetails } from 'graphql/audioCalls';
import { generateModalId } from 'utils';

import { EventMentionsUI } from './ui';

export class EventMentions extends PureComponent {
    static displayName = 'EventMentionsContainer';

    static propTypes = {
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        collapsedDashboards: PropTypes.arrayOf(PropTypes.any),
        onCircleClick: PropTypes.func,
        onRemove: PropTypes.func,
        pathname: PropTypes.string.isRequired,
        tabId: PropTypes.string.isRequired,
        toggleDash: PropTypes.func
    };

    static defaultProps = {
        collapsedDashboards: [],
        onRemove: null,
        onCircleClick: null,
        toggleDash: () => {}
    };

    constructor(props) {
        super(props);

        this.openUpgradeModal = this.openUpgradeModal.bind(this);
        this.removeTerm = this.removeTerm.bind(this);

        this.state = {
            removingId: null
        };
    }

    openUpgradeModal() {
        const { history, tabId, pathname } = this.props;
        history.push(generateModalId({ pathname, tabId, id: 'topics', type: 'upgrade' }));
    }

    removeTerm(e, streamId) {
        const { onRemove } = this.props;

        // Prevent click through of tracked term row
        e.stopPropagation();

        this.setState(
            {
                removingId: streamId
            },
            () => {
                onRemove(streamId).finally(() => {
                    this.setState({
                        removingId: null
                    });
                });
            }
        );
    }

    render() {
        const { collapsedDashboards, onRemove, onCircleClick, toggleDash, ...rest } = this.props;
        const { removingId } = this.state;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <EventMentionsUI
                {...rest}
                toggleDash={toggleDash}
                collapsedDashboards={collapsedDashboards}
                onCircleClick={onCircleClick}
                openUpgradeModal={this.openUpgradeModal}
                removeTerm={this.removeTerm}
                removingId={removingId}
                showRemove={!!onRemove}
            />
        );
    }
}

export const EventMentionsContainer = compose(
    withUrlContext(['audioCallId', 'streamId', 'history', 'pathname', 'tabId']),
    withEventDetails({ fetchPolicy: 'cache-only' }),
    withProps(({ audioCall }) => ({
        ...audioCall
    }))
)(EventMentions);
