import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { AddToCalendarButtonUI } from './ui';

export class AddToCalendarButton extends PureComponent {
    static displayName = 'AddToCalendarButtonContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <AddToCalendarButtonUI {...rest} />;
    }
}

export const AddToCalendarButtonContainer = compose()(AddToCalendarButton);
