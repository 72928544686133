import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { LoadingBlockUI } from './ui';

export class LoadingBlock extends PureComponent {
    static displayName = 'LoadingBlockContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number
    };

    static defaultProps = {
        styles: undefined,
        size: 'small',
        height: undefined,
        width: 250
    };

    render() {
        const { styles, size, width, height } = this.props;
        return <LoadingBlockUI styles={styles} size={size} width={width} height={height} />;
    }
}

export const LoadingBlockContainer = compose()(LoadingBlock);
