import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { connect } from 'react-redux';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';
import { generateStreamDescription } from 'utils/streams';

const DEFAULT_MATCHES_PATH = 'streams[0]';

export const withStreamPaging = (options = {}) =>
    compose(
        withPropsOnChange(['fetchMore'], ({ fetchMore }) => ({
            loadMoreMatches: (offset, size) => {
                const matchesPath = get(options, 'matchesPath', DEFAULT_MATCHES_PATH);
                let hasMore = true;
                if (fetchMore) {
                    return fetchMore({
                        variables: { offset, size },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            const query = cloneDeep(prev);
                            const prevResults = get(prev, `${matchesPath}.matches.results`, []);
                            const newResults = get(fetchMoreResult, `${matchesPath}.matches.results`, []);
                            const prevIds = new Set(prevResults.map(m => m.id));
                            hasMore = newResults.length > 0;
                            set(query, `${matchesPath}.matches.results`, [
                                ...prevResults,
                                ...newResults.filter(r => !prevIds.has(r.id))
                            ]);
                            return query;
                        }
                    })
                        .then(() => hasMore)
                        .catch(() => {
                            // catch Unhandled Rejection (Invariant Violation): ObservableQuery with this id doesn't exist
                            // console.log(e);
                        });
                }
                return Promise.resolve(false); // user is idle, so no more matches
            },
            // This fetches *new* updates by asking for the first page of results, checking if any of
            // those results are missing from our current results and prepending any of those missing
            // ones to the list. This should make sure we don't clear out new pages the user loaded.
            //
            // This will work with an interval (as long as the interval is short enough that the number of new
            // results is going to be less than the page size) as well as with a realtime notification.
            getUpdates: () => {
                return (
                    fetchMore &&
                    fetchMore({
                        variables: {
                            offset: 0
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            const matchesPath = get(options, 'matchesPath', DEFAULT_MATCHES_PATH);
                            const query = cloneDeep(prev);
                            const prevResults = get(prev, `${matchesPath}.matches.results`, []);
                            const newResults = get(fetchMoreResult, `${matchesPath}.matches.results`, []);

                            if (newResults.length) {
                                const prevIds = new Set(prevResults.map(m => m.id));
                                const before = [];
                                const after = [];
                                let foundExisting = false;

                                newResults.forEach(m => {
                                    if (prevIds.has(m.id)) {
                                        foundExisting = true;
                                    } else if (foundExisting) {
                                        after.push(m);
                                    } else {
                                        before.push(m);
                                    }
                                });

                                if (before.length || after.length) {
                                    set(query, `${matchesPath}.matches.results`, [...before, ...prevResults, ...after]);
                                    return query;
                                }
                            }

                            return prev;
                        }
                    }).catch(() => {
                        // catch Unhandled Rejection (Invariant Violation): ObservableQuery with this id doesn't exist
                        // console.log(e);
                    })
                );
            }
        })),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                const { realtime } = this.props;
                // We may not have the pusher token on mount, so save a function
                // onto the component that we can either call here or in componentDidUpdate
                // to subscribe as soon as we have the token.
                this.trySubscribe = () => {
                    if (!this.subscription) {
                        const { dashboardGuid } = this.props;
                        if (dashboardGuid) {
                            this.subscription = realtime.subscribe(
                                `dashboard_${dashboardGuid}`,
                                'stream_matches',
                                ({ stream_ids: streamIds }) => {
                                    const { getUpdates, streamId } = this.props;
                                    if (streamIds.includes(streamId)) {
                                        getUpdates();
                                    }
                                }
                            );
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                }
            }
        })
    );

const mapStateToProps = ({ User: userStore }) => ({
    userIsIdle: get(userStore, 'isIdle')
});

export const withEventUpdates = () =>
    compose(
        connect(mapStateToProps),
        withRealtime(),
        withApollo,
        lifecycle({
            componentDidMount() {
                this.events = new Set();
                const { realtime } = this.props;
                if (!this.subscription) {
                    this.subscription = realtime.subscribe('scheduled_audio_call_changes', 'modified', ({ ids }) => {
                        ids.forEach(id => this.events.add(String(id)));
                    });
                }

                this.interval = setInterval(() => {
                    const { client, matches, userIsIdle } = this.props;
                    // Only do this if the user isn't idle
                    if (!userIsIdle) {
                        const eventIds = (matches || [])
                            .filter(m => this.events.has(String(m.eventId)))
                            .map(m => m.eventId);
                        if (eventIds.length) {
                            this.events = new Set();
                            client
                                .query({
                                    query: gql`
                                        query withUpdateStreamEvents($eventIds: [ID]!) {
                                            events(eventIds: $eventIds) {
                                                id
                                                transcriptionStatus
                                                hasTranscript
                                                hasPublishedTranscript
                                                priceHighlight {
                                                    overThreshold
                                                    movementAbsolute
                                                    movementPercent
                                                    endOrLatestPrice
                                                }
                                                processingAudio
                                                transcriptionAudioUrl
                                            }
                                        }
                                    `,
                                    fetchPolicy: 'network-only',
                                    variables: { eventIds }
                                })
                                .catch(e => {
                                    this.events = new Set([...this.events, ...eventIds]);
                                    throw e;
                                });
                        }
                    }
                }, 10000);
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                }
                clearInterval(this.interval);
            }
        })
    );

const withStreamRemoves = () =>
    compose(
        withRealtime(),
        lifecycle({
            componentDidMount() {
                const { realtime } = this.props;
                this.setState({ removedMatchIds: new Set() });
                this.trySubscribe = () => {
                    const { dashboardGuid } = this.props;
                    if (dashboardGuid && !this.subscription) {
                        this.subscription = realtime.subscribe(
                            `dashboard_${dashboardGuid}`,
                            'stream_removes',
                            removed => {
                                const { streamId } = this.props;
                                const removedIds = removed
                                    .filter(({ stream_id: removedStreamId }) => streamId === removedStreamId)
                                    .map(({ target_id: targetId }) => targetId);

                                this.setState(({ removedMatchIds = new Set() }) => ({
                                    removedMatchIds: new Set([...removedMatchIds, ...removedIds])
                                }));
                            }
                        );
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                }
                clearInterval(this.interval);
            }
        }),
        withPropsOnChange(['matches', 'removedMatchIds'], ({ matches, removedMatchIds }) => ({
            matches: matches && removedMatchIds ? matches.filter(m => !removedMatchIds.has(m.id)) : matches
        }))
    );

export const withUpdateStreamOnChanges = () =>
    compose(
        lifecycle({
            componentDidUpdate({ rules: prevRules }) {
                const { refetch, rules } = this.props;
                if (refetch && prevRules && !isEqual(prevRules, rules)) {
                    refetch();
                }
            }
        }),
        withStreamRemoves()
    );

export const getDefaultStreamProps = (data, formatMatches, matchesPath = 'streams[0]') => {
    const results = get(data, `${matchesPath}.matches.results`, []);
    const loading = (data.loading && !results.length) || data.networkStatus === 4;
    const matches = loading ? [] : formatMatches(results);
    const rules = get(data, `${matchesPath}.rules`, []);
    const subtitle = generateStreamDescription({ rules });
    return {
        fetchMore: data.fetchMore,
        lens: get(data, `${matchesPath}.lens.rules`),
        loading,
        matches,
        refetch: data.refetch,
        rules: get(data, `${matchesPath}.rules`),
        stream: get(data, matchesPath),
        subtitle
    };
};

export const getDefaultStreamOptions = ({
    applyLens,
    collapse,
    dashBookmarkTags,
    dashDateRange,
    dashScopes,
    dashSearchTerm,
    dashSources,
    dashEquityScope,
    dashFilters,
    lenses,
    mapFilters,
    sort,
    streamId,
    streamType
}) => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
        filter: {
            applyLens,
            rules: mapFilters
                ? mapFilters({
                      bookmarkTags: dashBookmarkTags,
                      dateRange: dashDateRange,
                      equityScope: dashEquityScope,
                      filters: dashFilters,
                      scopes: dashScopes,
                      searchTerm: dashSearchTerm,
                      sources: dashSources
                  })
                : undefined,
            lenses: lenses ? [{ rules: mapFilters({ filters: lenses }) }] : undefined
        },
        sort,
        streamId,
        ...(collapse ? { collapse } : {})
    },
    context: {
        debounceKey: `debounce:${streamType}:${streamId}`,
        debounceTimeout: dashSearchTerm || dashDateRange ? 300 : 0
    }
});
