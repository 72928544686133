import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class FileUpload extends Component {
    static propTypes = {
        allowMultiple: PropTypes.bool.isRequired,
        existingFilename: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        uploadedFiles: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        existingFilename: undefined,
        passedStyles: {}
    };

    render() {
        const {
            existingFilename,
            allowMultiple,
            onChange,
            onDelete,
            passedStyles,
            styles,
            theme,
            uploadedFiles
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {uploadedFiles.length > 0 ? (
                    uploadedFiles.map(({ name, progress, error, uploaded, uploading }, idx) => {
                        const key = `upload-${idx}`;
                        return (
                            <Div key={key} styles={styles.fileContainer}>
                                <Hint
                                    growUp
                                    onClick={() => onDelete(idx)}
                                    styles={{ ...styles.existingFile }}
                                    text="Click to remove file"
                                    yOffset={-34}
                                    xOffset={-14}
                                >
                                    <Div styles={styles.fileText}>
                                        {name && (
                                            <Text size={2} styles={styles.filename}>
                                                {name}
                                            </Text>
                                        )}
                                        {error ? (
                                            <Text size={2} styles={styles.error}>
                                                {error}
                                            </Text>
                                        ) : (
                                            <Div styles={styles.spacer} />
                                        )}
                                        {uploaded && (
                                            <Text size={2} styles={styles.success}>
                                                Success!
                                            </Text>
                                        )}
                                        {uploading && (
                                            <Text size={2} styles={styles.uploading}>
                                                Uploading...
                                            </Text>
                                        )}
                                    </Div>
                                    <Div
                                        className="progressBar"
                                        styles={{ ...styles.progressBar, width: `${progress}%` }}
                                    />
                                    <Div data-tname="file-delete-button" styles={styles.buttonDelete}>
                                        <Icon color={theme.colors.orange01} type="circleX" />
                                    </Div>
                                </Hint>
                            </Div>
                        );
                    })
                ) : existingFilename ? (
                    <Div styles={styles.fileContainer}>
                        <Hint
                            growUp
                            styles={{ ...styles.existingFile, ...styles.locked }}
                            text="Cannot edit file"
                            yOffset={-28}
                            xOffset={-14}
                        >
                            <Div styles={styles.fileText}>
                                <Text size={2} styles={styles.filename}>
                                    {existingFilename}
                                </Text>
                            </Div>
                        </Hint>
                    </Div>
                ) : (
                    <label
                        htmlFor="file-upload"
                        className="file-upload"
                        onDragOver={e => e.preventDefault()}
                        onDragLeave={e => e.preventDefault()}
                        onDrop={e => {
                            e.preventDefault();
                            onChange(e);
                        }}
                    >
                        <input id="file-upload" onChange={onChange} type="file" multiple={allowMultiple} />
                        Drag {allowMultiple ? 'files' : 'file'} here
                        <br />
                        or <Span styles={styles.blueText}>browse</Span>
                    </label>
                )}
                {allowMultiple && uploadedFiles.length === 0 && (
                    <Text styles={styles.bulkHint} size={1}>
                        Select multiple files by holding down CTRL, or ⌘ for Mac
                    </Text>
                )}
                {allowMultiple && uploadedFiles.length > 0 && (
                    <label
                        htmlFor="file-upload-more"
                        className="file-upload-more"
                        onDragOver={e => e.preventDefault()}
                        onDragLeave={e => e.preventDefault()}
                        onDrop={e => {
                            e.preventDefault();
                            onChange(e);
                        }}
                    >
                        <input id="file-upload-more" onChange={onChange} type="file" multiple={allowMultiple} />
                        Browse for more files
                    </label>
                )}
            </Div>
        );
    }
}

export const FileUploadUI = compose(withStyleSheet(styleSheet))(FileUpload);
