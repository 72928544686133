import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SidebarClosedUI } from './ui';

export class SidebarClosed extends PureComponent {
    static displayName = 'SidebarClosedContainer';

    static propTypes = {
        defaultDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        dashboardSections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        openModal: PropTypes.func.isRequired,
        recommendedDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        toggleSidebar: PropTypes.func.isRequired
    };

    static defaultProps = {
        styles: undefined
    };

    render() {
        const {
            styles,
            openModal,
            toggleSidebar,
            recommendedDashboards,
            defaultDashboards,
            dashboardSections
        } = this.props;
        return (
            <SidebarClosedUI
                styles={styles}
                openModal={openModal}
                toggleSidebar={toggleSidebar}
                recommendedDashboards={recommendedDashboards}
                defaultDashboards={defaultDashboards}
                dashboardSections={dashboardSections}
            />
        );
    }
}

export const SidebarClosedContainer = compose()(SidebarClosed);
