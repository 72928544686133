import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { GSheetCardUI } from './ui';

export class GSheetCard extends PureComponent {
    static displayName = 'GSheetCardContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        bottomLeft: PropTypes.string,
        bottomRight: PropTypes.string,
        topLeft: PropTypes.string,
        topRight: PropTypes.string,
        ticker: PropTypes.string,
        template: PropTypes.string,
        body: PropTypes.string
    };

    static defaultProps = {
        body: undefined,
        bottomLeft: undefined,
        bottomRight: undefined,
        topRight: undefined,
        topLeft: undefined,
        ticker: undefined,
        template: undefined,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            showSubContent: false
        };
    }

    handleClick() {
        this.setState(({ showSubContent }) => ({
            showSubContent: !showSubContent
        }));
    }

    render() {
        const { styles, body, bottomLeft, bottomRight, topLeft, topRight, ticker, template } = this.props;
        const { showSubContent } = this.state;
        return (
            <GSheetCardUI
                styles={styles}
                body={body}
                bottomLeft={bottomLeft}
                bottomRight={bottomRight}
                topLeft={topLeft}
                topRight={topRight}
                ticker={ticker}
                template={template}
                showSubContent={showSubContent}
                onClick={this.handleClick}
            />
        );
    }
}

export const GSheetCardContainer = compose()(GSheetCard);
