import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose, withStateHandlers, withPropsOnChange } from 'recompose';
import { searchHide } from 'actions/search';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { capitalize, get, generateTabURL, generateModalId } from 'utils';
import { PERMISSIONS } from 'consts';
import { Scroller } from 'utils/scroll';
import { withData } from './data';
import { MobileSearchUI } from './ui';

const RESULT_TYPES = [
    {
        key: 'companies',
        label: 'Companies'
    },
    {
        key: 'events',
        label: 'Events'
    },
    {
        key: 'transcripts',
        label: 'Transcripts'
    },
    /* {
        key: 'streetaccount',
        label: 'StreetAccount'
    }, */
    {
        key: 'news',
        label: 'News & Media',
        permission: PERMISSIONS.unlockedSearchNews
    }
];

const KEY_MAP = {
    '38': 'ArrowUp',
    '40': 'ArrowDown',
    '13': 'Enter',
    '27': 'Escape'
};

export class MobileSearch extends PureComponent {
    static displayName = 'MobileSearchContainer';

    static propTypes = {
        companyMatches: PropTypes.arrayOf(PropTypes.any),
        eventMatches: PropTypes.arrayOf(PropTypes.any),
        hideSearch: PropTypes.func.isRequired,
        highlightResult: PropTypes.func.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loadingCompanies: PropTypes.bool,
        loadingEvents: PropTypes.bool,
        loadingNews: PropTypes.bool,
        loadingStreetaccount: PropTypes.bool,
        loadingTranscripts: PropTypes.bool,
        loadMoreCompanies: PropTypes.func,
        loadMoreEvents: PropTypes.func,
        loadMoreNews: PropTypes.func,
        loadMoreTranscripts: PropTypes.func,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        newsMatches: PropTypes.arrayOf(PropTypes.any),
        onSearch: PropTypes.func.isRequired,
        onToggleResultType: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        previewElement: PropTypes.element,
        searchTerm: PropTypes.string,
        selectedResultTypes: PropTypes.arrayOf(PropTypes.string),
        setPreview: PropTypes.func.isRequired,
        singleType: PropTypes.bool.isRequired,
        streetAccountMatches: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        toggleTypeAmount: PropTypes.func.isRequired,
        transcriptMatches: PropTypes.arrayOf(PropTypes.any),
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        companyMatches: [],
        eventMatches: [],
        highlightedResult: null,
        loadingCompanies: false,
        loadingEvents: false,
        loadingNews: false,
        loadingStreetaccount: false,
        loadingTranscripts: false,
        loadMoreCompanies: undefined,
        loadMoreEvents: undefined,
        loadMoreNews: undefined,
        loadMoreTranscripts: undefined,
        newsMatches: [],
        previewElement: undefined,
        searchTerm: '',
        selectedResultTypes: [],
        streetAccountMatches: [],
        styles: undefined,
        transcriptMatches: []
    };

    constructor(props) {
        super(props);

        this.clearAndHideSearch = this.clearAndHideSearch.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleScrollRef = this.handleScrollRef.bind(this);
        this.highlightNextResult = this.highlightNextResult.bind(this);
        this.highlightPreviousResult = this.highlightPreviousResult.bind(this);
        this.indexElement = this.indexElement.bind(this);
        this.loadMoreResults = this.loadMoreResults.bind(this);
        this.navToResult = this.navToResult.bind(this);
        this.openUpgradeModal = this.openUpgradeModal.bind(this);

        this.scroller = new Scroller();

        this.resultRowElements = {
            companies: [],
            events: [],
            news: [],
            streetaccount: [],
            transcripts: []
        };

        // For loading more results
        this.hasMoreCompanies = false;
        this.hasMoreEvents = false;
        this.hasMoreNews = false;
        this.hasMoreTranscripts = false;

        this.state = {
            companyMatches: props.companyMatches,
            eventMatches: props.eventMatches,
            loadingMoreCompanies: false,
            loadingMoreEvents: false,
            loadingMoreNews: false,
            loadingMoreTranscripts: false,
            newsMatches: props.newsMatches,
            streetAccountMatches: props.streetAccountMatches,
            transcriptMatches: props.transcriptMatches
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const matches = {};
        const {
            companyMatches: prevCompanies,
            eventMatches: prevEvents,
            newsMatches: prevNews,
            transcriptMatches: prevTranscripts,
            visible: prevVisible
        } = prevProps;
        const { highlightedResult, companyMatches, eventMatches, newsMatches, transcriptMatches, visible } = this.props;

        // Update matches in state whenever results change
        // Companies
        if (!isEqual(prevCompanies, companyMatches)) {
            this.hasMoreCompanies = companyMatches.length === 10;
            matches.companyMatches = companyMatches;
        }
        // Events
        if (!isEqual(prevEvents, eventMatches)) {
            this.hasMoreEvents = eventMatches.length === 10;
            matches.eventMatches = eventMatches;
        }
        // News
        if (!isEqual(prevNews, newsMatches)) {
            this.hasMoreNews = newsMatches.length === 10;
            matches.newsMatches = newsMatches;
        }
        // Transcripts
        if (!isEqual(prevTranscripts, transcriptMatches)) {
            this.hasMoreTranscripts = transcriptMatches.length === 10;
            matches.transcriptMatches = transcriptMatches;
        }

        // When search opens, if there already is a highlighted
        // result (from a previous search nav), scroll into view
        if (visible && !prevVisible) {
            const index = get(highlightedResult, 'index');
            const type = get(highlightedResult, 'type');
            if (index && type && this.resultRowElements) {
                this.resultRowElements[type][index].scrollIntoView({ block: 'nearest' });
            }
        }

        this.setState({ ...matches });
    }

    componentWillUnmount() {
        this.scroller.cleanup();
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    indexElement(type, index, node) {
        this.resultRowElements[type][index] = node;
    }

    handleScrollRef(node) {
        this.scroller.setScrollContainer(node);
    }

    highlightNextResult() {
        const { companyMatches, eventMatches, newsMatches, streetAccountMatches, transcriptMatches } = this.state;
        const { highlightResult, highlightedResult, selectedResultTypes } = this.props;
        const type = get(highlightedResult, 'type');
        const orderedResultTypes = RESULT_TYPES.filter(({ key }) => selectedResultTypes.includes(key));
        const currentTypeIndex = orderedResultTypes.map(({ key }) => key).indexOf(type);
        const nextType =
            currentTypeIndex < orderedResultTypes.length - 1 ? orderedResultTypes[currentTypeIndex + 1].key : type;
        const index = get(highlightedResult, 'index');
        const resultMap = {
            companies: companyMatches,
            events: eventMatches,
            news: newsMatches,
            streetaccount: streetAccountMatches,
            transcripts: transcriptMatches
        };
        const currentResultsMaxIndex = resultMap[type].length - 1;
        let newType = type;
        let newIndex = index;

        if (index < currentResultsMaxIndex) {
            newIndex = index + 1;
        } else if (nextType !== type) {
            newType = nextType;
            newIndex = 0;
        }

        if (newType !== type || newIndex !== index) {
            highlightResult(newType, newIndex);
            if (this.resultRowElements[newType][newIndex]) {
                this.resultRowElements[newType][newIndex].scrollIntoView({ block: 'nearest' });
            }
        }
    }

    highlightPreviousResult() {
        const { companyMatches, eventMatches, newsMatches, streetAccountMatches, transcriptMatches } = this.state;
        const { highlightResult, highlightedResult, selectedResultTypes } = this.props;
        const type = get(highlightedResult, 'type');
        const orderedResultTypes = RESULT_TYPES.filter(({ key }) => selectedResultTypes.includes(key));
        const currentTypeIndex = orderedResultTypes.map(({ key }) => key).indexOf(type);
        const previousType = currentTypeIndex > 0 ? orderedResultTypes[currentTypeIndex - 1].key : type;
        const index = get(highlightedResult, 'index');
        const resultMap = {
            companies: companyMatches,
            events: eventMatches,
            news: newsMatches,
            streetaccount: streetAccountMatches,
            transcripts: transcriptMatches
        };
        let newType = type;
        let newIndex = index;

        if (index > 0) {
            newIndex = index - 1;
        } else if (previousType !== type) {
            newType = previousType;
            newIndex = resultMap[previousType].length - 1;
        }

        if (newType !== type || newIndex !== index) {
            highlightResult(newType, newIndex);
            if (this.resultRowElements[newType][newIndex]) {
                this.resultRowElements[newType][newIndex].scrollIntoView({ block: 'nearest' });
                const offsetParentTop = this.resultRowElements[newType][newIndex].offsetParent.offsetTop;
                const offsetTop = offsetParentTop + this.resultRowElements[newType][newIndex].offsetTop - 30;

                // The bounding rect top offset, measures the result offset from
                // the top of the viewport. When it is less than 130, it means the
                // result is being cut-off by the sticky header, and we should
                // scroll the results container to the proper position..
                //
                // the proper position is calculated using the parent (results type
                // container) top offset from the scroll container, plus the offset
                // of the result within the parent.. We can't use this every time,
                // because we don't want to keep the result at the top of the
                // scroll container
                if (this.resultRowElements[newType][newIndex].getBoundingClientRect().top < 130) {
                    this.scroller.scrollTo({ top: offsetTop });
                }
            }
        }
    }

    handleKeyDown(event) {
        const { which } = event;
        const key = KEY_MAP[which];
        const { visible } = this.props;

        if (visible && key === 'ArrowUp') {
            this.highlightPreviousResult();
        }

        if (visible && key === 'ArrowDown') {
            this.highlightNextResult();
        }

        if (visible && key === 'Enter') {
            this.navToResult();
        }

        if (visible && key === 'Escape') {
            this.clearAndHideSearch();
        }
    }

    loadMoreResults(type) {
        const { loadMoreCompanies, loadMoreEvents, loadMoreNews, loadMoreTranscripts } = this.props;
        const {
            companyMatches,
            eventMatches,
            newsMatches,
            transcriptMatches,
            loadingMoreCompanies,
            loadingMoreEvents,
            loadingMoreNews,
            loadingMoreTranscripts
        } = this.state;
        const key = capitalize(pluralize(type, 0));
        const hasMoreKey = `hasMore${key}`;
        const loadingMoreKey = `loadingMore${key}`;
        const matchesKey = `${type}Matches`;
        let loadMore;
        let loadingMore;
        let matches;

        switch (type) {
            case 'company':
                loadMore = loadMoreCompanies;
                loadingMore = loadingMoreCompanies;
                matches = companyMatches;
                break;
            case 'event':
                loadMore = loadMoreEvents;
                loadingMore = loadingMoreEvents;
                matches = eventMatches;
                break;
            case 'news':
                loadMore = loadMoreNews;
                loadingMore = loadingMoreNews;
                matches = newsMatches;
                break;
            case 'transcript':
                loadMore = loadMoreTranscripts;
                loadingMore = loadingMoreTranscripts;
                matches = transcriptMatches;
                break;
            default:
        }

        if (this[hasMoreKey] && loadMore && !loadingMore) {
            this.setState({ [loadingMoreKey]: true }, () =>
                loadMore(matches.length)
                    .then(({ hasMore, newResults }) => {
                        this[hasMoreKey] = hasMore;
                        this.setState({
                            [matchesKey]: [...matches, ...newResults],
                            [loadingMoreKey]: false
                        });
                    })
                    .catch(() => this.setState({ [loadingMoreKey]: false }))
            );
        }
    }

    navToResult() {
        const { companyMatches, eventMatches, newsMatches, streetAccountMatches, transcriptMatches } = this.state;
        const { hideSearch, history, pathname, highlightedResult } = this.props;
        const resultMap = {
            companies: companyMatches,
            events: eventMatches,
            news: newsMatches,
            streetaccount: streetAccountMatches,
            transcripts: transcriptMatches
        };
        const { index, type } = highlightedResult;
        const result = resultMap[type][index];

        if (result) {
            const { type: resultType, eventId, companyId, contentId } = result;
            let navPath;

            if (resultType === 'company') {
                navPath = generatePath(routes.company, { companyId });
            } else if (resultType === 'event') {
                navPath = generateTabURL({ pathname, eventId });
            } else if (resultType === 'streetaccount') {
                navPath = generateTabURL({ pathname, streetAccountId: contentId });
            } else if (resultType === 'news') {
                navPath = generateTabURL({ pathname, newsId: contentId });
            } else if (resultType === 'transcript') {
                navPath = generateTabURL({ pathname, eventId });
            }

            if (navPath) {
                history.push(navPath);
            }
            hideSearch();
        }
    }

    clearAndHideSearch() {
        const { hideSearch, onSearch, highlightResult, selectedResultTypes, setPreview } = this.props;
        const firstResultType =
            RESULT_TYPES.map(({ key }) => key).filter(key => selectedResultTypes.includes(key))[0] ||
            RESULT_TYPES[0].key;
        hideSearch();
        setPreview();
        highlightResult(firstResultType, 0);
        onSearch('');
    }

    openUpgradeModal(type) {
        const { history, location } = this.props;
        history.push(generateModalId({ location, id: type, type: 'upgrade' }));
    }

    render() {
        const {
            companyMatches,
            eventMatches,
            loadingMoreCompanies,
            loadingMoreEvents,
            loadingMoreNews,
            loadingMoreTranscripts,
            newsMatches,
            streetAccountMatches,
            transcriptMatches
        } = this.state;
        const {
            highlightResult,
            highlightedResult,
            loadingCompanies,
            loadingEvents,
            loadingNews,
            loadingStreetaccount,
            loadingTranscripts,
            singleType,
            onSearch,
            onToggleResultType,
            previewElement,
            searchTerm,
            selectedResultTypes,
            setPreview,
            styles,
            toggleTypeAmount,
            visible
        } = this.props;
        return (
            <MobileSearchUI
                scrollRef={this.handleScrollRef}
                companyMatches={companyMatches}
                eventMatches={eventMatches}
                hasMoreCompanies={this.hasMoreCompanies}
                hasMoreEvents={this.hasMoreEvents}
                hasMoreNews={this.hasMoreNews}
                hasMoreTranscripts={this.hasMoreTranscripts}
                hideSearch={this.clearAndHideSearch}
                highlightResult={highlightResult}
                highlightedResult={highlightedResult}
                indexElement={this.indexElement}
                loadingCompanies={loadingCompanies}
                loadingEvents={loadingEvents}
                loadingMoreCompanies={loadingMoreCompanies}
                loadingMoreEvents={loadingMoreEvents}
                loadingMoreNews={loadingMoreNews}
                loadingMoreTranscripts={loadingMoreTranscripts}
                loadingNews={loadingNews}
                loadingStreetaccount={loadingStreetaccount}
                loadingTranscripts={loadingTranscripts}
                loadMoreResults={this.loadMoreResults}
                singleType={singleType}
                newsMatches={newsMatches}
                onClickResult={this.navToResult}
                onSearch={onSearch}
                openUpgradeModal={this.openUpgradeModal}
                previewElement={previewElement}
                resultTypes={RESULT_TYPES}
                searchTerm={searchTerm}
                selectedResultTypes={selectedResultTypes}
                setPreview={setPreview}
                streetAccountMatches={streetAccountMatches}
                styles={styles}
                toggleType={onToggleResultType}
                toggleTypeAmount={toggleTypeAmount}
                transcriptMatches={transcriptMatches}
                visible={visible}
            />
        );
    }
}

const mapStateToProps = ({ Search: storeSearch }) => {
    const { visible } = storeSearch;
    return {
        visible
    };
};

const mapDispatchToProps = {
    hideSearch: searchHide
};

export const MobileSearchContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withUrlContext(['globalSearch', 'history', 'pathname', 'location']),
    withStateHandlers(
        {
            highlightedResult: {
                type: 'companies',
                index: 0
            },
            previewElement: undefined,
            searchTerm: '',
            selectedResultTypesMulti: ['companies', 'events', 'transcripts', /* 'streetaccount', */ 'news'],
            selectedResultTypesSingle: ['companies'],
            singleType: false
        },
        {
            highlightResult: () => (type, index) => ({
                highlightedResult: { type, index }
            }),
            toggleTypeAmount: ({ singleType, selectedResultTypesSingle, selectedResultTypesMulti }) => () => ({
                singleType: !singleType,
                highlightedResult: {
                    type: singleType ? selectedResultTypesSingle[0] : selectedResultTypesMulti[0],
                    index: 0
                }
            }),
            onToggleResultType: ({ selectedResultTypesMulti, singleType }) => ({ key }) => {
                const newResultTypes = new Set(selectedResultTypesMulti);

                if (singleType) {
                    return {
                        selectedResultTypesSingle: [key]
                    };
                }

                if (newResultTypes.has(key)) {
                    newResultTypes.delete(key);
                } else {
                    newResultTypes.add(key);
                }

                return {
                    selectedResultTypesMulti: [...newResultTypes]
                };
            },
            onSearch: () => search => ({
                searchTerm: search.value
            }),
            setPreview: () => previewElement => ({
                previewElement
            })
        }
    ),
    withPropsOnChange(
        ['selectedResultTypesMulti', 'selectedResultTypesSingle', 'singleType'],
        ({ selectedResultTypesMulti, selectedResultTypesSingle, singleType }) => ({
            selectedResultTypes: singleType ? selectedResultTypesSingle : selectedResultTypesMulti
        })
    ),
    withData()
)(MobileSearch);
