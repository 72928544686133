import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { PERMISSIONS } from 'consts';
import { generateTabURL, toDateString } from 'utils';
import { Div } from 'components/Div';
import { InternalLink } from 'components/InternalLink';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { WithPermission } from 'components/WithPermission';
import { styleSheet } from './stylesheet';

class EventCorporateActions extends PureComponent {
    static propTypes = {
        corpActions: PropTypes.arrayOf(PropTypes.object),
        openUpgradeModal: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        corpActions: [],
        passedStyles: {}
    };

    renderCorpActions() {
        const { corpActions, pathname, styles } = this.props;
        return corpActions.map(({ displayType, eventDate, spotlightSubtype, title, id }) => (
            <Div styles={styles.corpAction} to={generateTabURL({ pathname, spotlightId: id })}>
                <InternalLink styles={styles.link} to={generateTabURL({ pathname, spotlightId: id })}>
                    <Text size={3}>{title}</Text>
                </InternalLink>
                <Div styles={styles.actionDetails}>
                    <Div className="spotlight-tags">
                        <Text span styles={styles.spotlightTag} size={0} uppercase>
                            {displayType}
                            {spotlightSubtype && (
                                <Text span styles={styles.spotlightSubTag} size={0} uppercase>
                                    {spotlightSubtype}
                                </Text>
                            )}
                        </Text>
                    </Div>
                    <Text size={1} styles={styles.publishDate}>
                        {toDateString(eventDate)}
                    </Text>
                </Div>
            </Div>
        ));
    }

    renderRestricted() {
        const { styles, openUpgradeModal, theme } = this.props;

        return (
            <Div styles={styles.upsellContent} onClick={openUpgradeModal}>
                <Div styles={styles.upsellTitle}>
                    <Text size={5} weight="medium">
                        Unlock Corporate Activity
                    </Text>
                    <Icon type="lock02" color={theme.colors.black01} />
                </Div>
                <Text size={3}>
                    Guidance updates, mergers & acquisitions, and other corporate actions, associated with this event
                    are locked.
                </Text>
            </Div>
        );
    }

    render() {
        const { passedStyles, styles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <WithPermission permissions={[PERMISSIONS.unlockedSpotlightContent]} value={true} defaultValue={false}>
                    {({ restricted, isLoading }) => (
                        <Fragment>
                            {restricted && !isLoading ? this.renderRestricted() : this.renderCorpActions()}
                        </Fragment>
                    )}
                </WithPermission>
            </Div>
        );
    }
}

export const EventCorporateActionsUI = compose(withStyleSheet(styleSheet))(EventCorporateActions);
