import gql from 'graphql-tag';

export const watchlistFragment = gql`
    fragment watchlist on Watchlist {
        id
        creatorId
        equityCount
        name
        rules {
            ruleType
            condition
            value
            targetEquity {
                id
                equityId
                localTicker
                commonName
                exchange {
                    id
                    shortName
                }
            }
        }
        status
        watchlistId
        watchlistType
    }
`;

export const watchlistEquityFragment = gql`
    fragment watchlistEquity on Equity {
        id
        equityId
        commonName
        currency {
            id
            currencyCode
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
        last
        lastClose
        localTicker
        marketcap
        nextEarnings: nextEarningsDate
        priceChange
        priceChangePercent
        pricetoearnings
        sector: gicsSector {
            id
            name
        }
    }
`;
