import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withPropsOnChange } from 'recompose';
import { withSectors } from 'graphql/sectors';
import { sortSectors } from './selectors';
import { BulkFollowIdentifiersUI } from './ui';

export class BulkFollowIdentifiers extends PureComponent {
    static displayName = 'BulkFollowIdentifiersContainer';

    static propTypes = {
        error: PropTypes.string,
        hideSectors: PropTypes.bool,
        label: PropTypes.string,
        isReviewingResults: PropTypes.bool.isRequired,
        onReviewResults: PropTypes.func.isRequired,
        onToggleEquity: PropTypes.func,
        sectors: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            })
        ),
        sectorsLoading: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        error: null,
        hideSectors: false,
        label: undefined,
        onToggleEquity: null,
        sectors: [],
        sectorsLoading: false,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.handleReviewResults = this.handleReviewResults.bind(this);
        this.handleSectorsAdd = this.handleSectorsAdd.bind(this);
        this.handleSectorsInputChange = this.handleSectorsInputChange.bind(this);
        this.handleSectorsInputKeyDown = this.handleSectorsInputKeyDown.bind(this);
        this.handleSectorsRemove = this.handleSectorsRemove.bind(this);
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);

        this.taggedInput = React.createRef();

        this.state = {
            identifiers: null,
            identifiersInput: '',
            isSearchingSectors: false,
            maxTickersExceeded: false,
            searchResultsSectors: null,
            sectorsInput: '',
            selectedSectors: []
        };
    }

    handleReviewResults() {
        const { onReviewResults } = this.props;
        const { identifiersInput } = this.state;
        this.setState(
            {
                identifiers: identifiersInput.trim().length
                    ? [
                          ...new Set(
                              identifiersInput
                                  .replace(/\n/g, ',') // replace all newline characters with commas
                                  .split(',')
                                  .filter(t => t && t.trim().length) // filter out nulls and empty strings
                          )
                      ]
                    : null
            },
            () => {
                if (onReviewResults) onReviewResults();
            }
        );
    }

    handleSectorsAdd(sector) {
        const { selectedSectors } = this.state;
        this.setState(
            {
                isSearchingSectors: false,
                sectorsInput: '',
                selectedSectors: selectedSectors.some(s => s.id === sector.id && s.type === sector.type)
                    ? [...selectedSectors]
                    : [...selectedSectors, sector]
            },
            () => {
                if (this.taggedInput && this.taggedInput.current) this.taggedInput.current.focus();
            }
        );
    }

    handleSectorsRemove(sector) {
        const { selectedSectors } = this.state;
        const idx = selectedSectors.findIndex(s => s.id === sector.id && s.type === sector.type);
        this.setState(
            {
                selectedSectors: [...selectedSectors.slice(0, idx), ...selectedSectors.slice(idx + 1)]
            },
            () => {
                if (this.taggedInput && this.taggedInput.current) this.taggedInput.current.focus();
            }
        );
    }

    handleSectorsInputChange({ value: inputValue }) {
        const { sectors } = this.props;
        const value = inputValue.toLowerCase();
        const hasValue = value.trim().length > 0;
        const searchResultsSectors = [];

        if (hasValue) {
            sectors.forEach(({ id, name, numEquities, subSectors }) => {
                const matchedSubSectors = [];

                if (subSectors && subSectors.length) {
                    subSectors.forEach(({ subSectorId, name: subSectorName, numEquities: subSectorEquities }) => {
                        if (subSectorName.toLowerCase().includes(value)) {
                            matchedSubSectors.push({
                                id: subSectorId,
                                label: subSectorName,
                                numEquities: subSectorEquities,
                                type: 'subSector'
                            });
                        }
                    });
                }

                // Include the sector in the results if its name matches the search term
                // or if any of its sub-sectors' names match the search term
                if (name.toLowerCase().includes(value) || matchedSubSectors.length) {
                    searchResultsSectors.push({
                        id,
                        label: name,
                        numEquities,
                        subSectors: matchedSubSectors,
                        type: 'sector'
                    });
                }
            });
        }

        this.setState({
            isSearchingSectors: hasValue,
            sectorsInput: inputValue,
            searchResultsSectors
        });
    }

    handleSectorsInputKeyDown(event) {
        const { key, target } = event;
        if (key === 'Escape') {
            this.setState({
                isSearchingSectors: false,
                sectorsInput: ''
            });
            target.focus();
        }
    }

    handleTextAreaChange(identifiersInput) {
        const tickers = identifiersInput
            .replace(/\n/g, ',') // replace all newline characters with commas
            .split(',')
            .filter(t => t && t.trim().length); // filter out nulls and empty strings
        const maxTickersExceeded = tickers.length > 1000;
        this.setState({
            identifiersInput: maxTickersExceeded ? tickers.slice(0, 1000).join(', ') : identifiersInput,
            maxTickersExceeded
        });
    }

    // Used by parent component via bulkFollowIdentifiersRef to reset the state
    reset() {
        this.setState({
            identifiers: null,
            identifiersInput: '',
            isSearchingSectors: false,
            searchResultsSectors: null,
            sectorsInput: '',
            selectedSectors: []
        });
    }

    render() {
        const {
            identifiers,
            identifiersInput,
            isSearchingSectors,
            maxTickersExceeded,
            searchResultsSectors,
            sectorsInput,
            selectedSectors
        } = this.state;
        const { error, hideSectors, isReviewingResults, label, onToggleEquity, sectorsLoading, styles } = this.props;
        return (
            <BulkFollowIdentifiersUI
                error={error}
                hideSectors={hideSectors}
                identifiers={identifiers}
                identifiersInput={identifiersInput}
                isButtonDisabled={identifiersInput.trim().length === 0 && selectedSectors.length === 0}
                isReviewingResults={isReviewingResults}
                isSearchingSectors={isSearchingSectors}
                label={label}
                loading={sectorsLoading}
                maxTickersExceeded={maxTickersExceeded}
                onReviewResults={this.handleReviewResults}
                onSectorsAdd={this.handleSectorsAdd}
                onSectorsInputChange={this.handleSectorsInputChange}
                onSectorsInputKeyDown={this.handleSectorsInputKeyDown}
                onSectorsRemove={this.handleSectorsRemove}
                onTextAreaChange={this.handleTextAreaChange}
                onToggleEquity={onToggleEquity}
                searchResultsSectors={searchResultsSectors}
                sectorIds={selectedSectors.filter(s => s.type === 'sector').map(sector => sector.id)}
                sectorsInput={sectorsInput}
                selectedSectors={selectedSectors}
                styles={styles}
                subSectorIds={selectedSectors.filter(s => s.type === 'subSector').map(subSector => subSector.id)}
                taggedInputRef={this.taggedInput}
            />
        );
    }
}

export const BulkFollowIdentifiersContainer = compose(
    withSectors(({ hideSectors }) => ({
        skip: hideSectors,
        variables: {
            withSubSectors: true
        }
    })),
    withPropsOnChange(['sectors'], props => ({
        sectors: sortSectors(props.sectors || [])
    })),
    withProps(({ bulkFollowIdentifiersRef }) => ({
        ref: bulkFollowIdentifiersRef
    }))
)(BulkFollowIdentifiers);
