import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { get } from 'utils';
import { withMemo } from 'hoc/utils';
import { formatMatchesByType, mapStreamType, mapFiltersToRules } from 'utils/streams';
import {
    contentMatchFragment,
    customDataMatchFragment,
    dataCollectionFragment,
    equityMatchFragment,
    eventGroupMatchFragment,
    eventMatchFragment,
    gSheetMatchFragment,
    streamFragment,
    streamMatchTemplateFragment,
    transcriptMatchFragment
} from 'graphql/fragments/streams';
import { bookmarkFragment, bookmarkTargetFragment } from 'graphql/fragments/bookmarks';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withDataCollection($dataCollectionId: ID!) {
                    dataCollections(filter: { dataCollectionIds: [$dataCollectionId] }) {
                        ...dataCollection
                    }
                }
                ${dataCollectionFragment}
            `,
            {
                props: ({ data }) => {
                    return { dataCollectionStatus: get(data, 'dataCollections[0].processingStatus') };
                },
                skip: ({ dataCollectionId }) => !dataCollectionId,
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        ),
        withMemo({
            formatMatchesByType
        }),
        graphql(
            gql`
                query previewStream(
                    $streamTypes: [StreamType]!
                    $rules: [StreamRuleInput]
                    $size: Int = 20
                    $offset: Int = 0
                    $dashboardId: ID
                    $dataCollectionId: ID
                    $streamMatchTemplateId: ID
                ) {
                    streams(
                        filter: {
                            previewStreamTypes: $streamTypes
                            dataCollectionId: $dataCollectionId
                            streamMatchTemplateId: $streamMatchTemplateId
                        }
                    ) {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: { dashboardId: $dashboardId, rules: $rules }) {
                            total
                            results {
                                ...contentMatch
                                ...equityMatch
                                ...eventMatch
                                ...transcriptMatch
                                ...gSheetMatch
                                ...customDataMatch
                                ...eventGroupMatch
                                ... on BookmarkStreamMatch {
                                    id
                                    bookmark {
                                        ...bookmark
                                        ...bookmarkTarget
                                    }
                                }
                            }
                        }
                        streamMatchTemplate {
                            ...streamMatchTemplate
                        }
                    }
                }
                ${gSheetMatchFragment}
                ${contentMatchFragment}
                ${equityMatchFragment}
                ${eventMatchFragment}
                ${streamFragment}
                ${transcriptMatchFragment}
                ${customDataMatchFragment}
                ${eventGroupMatchFragment}
                ${streamMatchTemplateFragment}
                ${bookmarkFragment}
                ${bookmarkTargetFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                    const matches = formatMatches(get(data, 'streams[0].matches.results', []));
                    return {
                        loading: !matches.length && get(data, 'loading'),
                        matches,
                        refreshPreview: data.refetch,
                        fetchMore: data.fetchMore,
                        template: get(data, 'streams[0].streamMatchTemplate.configuration'),
                        templateType: get(data, 'streams[0].streamMatchTemplate.templateType')
                    };
                },
                options: ({ filters, streamTypes, dashboardId, dataCollectionId, streamMatchTemplateId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        streamTypes: streamTypes ? streamTypes.map(mapStreamType) : null,
                        dashboardId,
                        rules: mapFiltersToRules(filters, get(streamTypes, '[0]')),
                        dataCollectionId: streamTypes.includes('custom_data') ? dataCollectionId : null,
                        streamMatchTemplateId: streamTypes.includes('custom_data') ? streamMatchTemplateId : null
                    }
                }),
                skip: ({ streamTypes }) => !streamTypes || !streamTypes.filter(s => s && s !== 'custom').length
            }
        ),
        withPropsOnChange(['fetchMore'], ({ fetchMore }) => ({
            loadMoreMatches: offset => {
                let hasMore = true;
                return fetchMore({
                    variables: { offset },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        const prevStream = prev.streams[0];
                        const newStream = fetchMoreResult.streams[0];
                        const newMatches = newStream.matches;
                        hasMore = newMatches.results.length > 0;
                        return {
                            ...prev,
                            streams: [
                                {
                                    ...prevStream,
                                    matches: {
                                        ...prevStream.matches,
                                        results: [...prevStream.matches.results, ...newMatches.results]
                                    }
                                }
                            ]
                        };
                    }
                }).then(() => hasMore);
            }
        }))
    );
