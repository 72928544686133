import React, { lazy, Suspense } from 'react';

export const suspend = (Component, FallbackComponent) => props => (
    <Suspense fallback={FallbackComponent}>
        <Component {...props} />
    </Suspense>
);
export const Account = lazy(() => import('pages/lazy/Account'));
export const Agent = lazy(() => import('pages/lazy/Agent'));
export const Auth = lazy(() => import('pages/lazy/Auth'));
export const Calendar = lazy(() => import('pages/lazy/Calendar'));
export const Companies = lazy(() => import('pages/lazy/Companies'));
export const ConfirmEmail = lazy(() => import('pages/lazy/ConfirmEmail'));
export const Dashboard = lazy(() => import('pages/lazy/Dashboard'));
export const Documents = lazy(() => import('pages/lazy/Documents'));
export const Equity = lazy(() => import('pages/lazy/Equity'));
export const EventGroup = lazy(() => import('pages/lazy/EventGroup'));
export const FramedEvent = lazy(() => import('pages/lazy/FramedEvent'));
export const ManageAlerts = lazy(() => import('pages/lazy/ManageAlerts'));
export const Mobile = lazy(() => import('pages/lazy/Mobile'));
export const Playground = lazy(() => import('pages/lazy/Playground'));
export const Popout = lazy(() => import('pages/lazy/Popout'));
export const PublicAskAiera = lazy(() => import('pages/lazy/PublicAskAiera'));
export const PublicEvent = lazy(() => import('pages/lazy/PublicEvent'));
export const PublicEventMinimal = lazy(() => import('pages/lazy/PublicEventMinimal'));
export const PublicEmbeddedEvent = lazy(() => import('pages/lazy/PublicEmbeddedEvent'));
export const Register = lazy(() => import('pages/lazy/Register'));
export const ResetPassword = lazy(() => import('pages/lazy/ResetPassword'));
export const Research = lazy(() => import('pages/lazy/Research'));
export const Search = lazy(() => import('pages/lazy/Search'));
export const SetPassword = lazy(() => import('pages/lazy/SetPassword'));
export const TranscriptEditor = lazy(() => import('pages/lazy/TranscriptEditor'));
export const Watchlist = lazy(() => import('pages/lazy/Watchlist'));
export const Workspace = lazy(() => import('pages/lazy/Workspace'));
