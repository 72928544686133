import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { get } from 'utils';
import { generateOperators } from 'consts/filters';
import { FilterUI } from './ui';

export class Filter extends PureComponent {
    static displayName = 'FilterContainer';

    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        operatorName: PropTypes.string,
        operatorPlaceholder: PropTypes.string,
        typeName: PropTypes.string,
        typePlaceholder: PropTypes.string,
        types: PropTypes.arrayOf(PropTypes.any),
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        valueComponent: PropTypes.element
    };

    static defaultProps = {
        onChange: null,
        operatorName: 'operatorSelect',
        operatorPlaceholder: 'Operator',
        typeName: 'typeSelect',
        typePlaceholder: 'Filter type',
        types: [],
        value: null,
        valueComponent: null
    };

    constructor(props) {
        super(props);

        this.handleSelectType = this.handleSelectType.bind(this);
        this.handleSelectOperator = this.handleSelectOperator.bind(this);
        this.handleSelectValue = this.handleSelectValue.bind(this);
        this.generateOperators = memoize(generateOperators);
    }

    handleSelectType({ event, value }) {
        const { onChange, name, value: propValue } = this.props;
        const id = get(propValue, 'id');

        if (onChange) {
            onChange({
                event,
                name,
                value: {
                    id,
                    type: value,
                    operator: null,
                    value: null
                }
            });
        }
    }

    handleSelectOperator({ event, value }) {
        const { onChange, name, value: propValue } = this.props;
        const id = get(propValue, 'id', undefined);
        const typeValue = get(propValue, 'type', '');

        if (onChange) {
            onChange({
                event,
                name,
                value: {
                    id,
                    type: typeValue,
                    operator: value,
                    value: null
                }
            });
        }
    }

    handleSelectValue({ event, value }) {
        const { onChange, name, value: propValue } = this.props;
        const id = get(propValue, 'id', undefined);
        const typeValue = get(propValue, 'type', '');
        const operatorValue = get(propValue, 'operator', '');

        if (onChange) {
            onChange({
                event,
                name,
                value: {
                    id,
                    type: typeValue,
                    operator: operatorValue,
                    value
                }
            });
        }
    }

    render() {
        const {
            types,
            typePlaceholder,
            typeName,
            operatorPlaceholder,
            operatorName,
            valueComponent,
            value
        } = this.props;
        const typeValue = get(value, 'type', '');
        const operatorValue = get(value, 'operator', '');
        const filterValue = get(value, 'value', null);

        return (
            <FilterUI
                types={types}
                typeName={typeName}
                typePlaceholder={typePlaceholder}
                typeValue={typeValue}
                operators={this.generateOperators(typeValue)}
                operatorName={operatorName}
                operatorPlaceholder={operatorPlaceholder}
                operatorValue={operatorValue}
                handleSelectType={this.handleSelectType}
                handleSelectOperator={this.handleSelectOperator}
                handleSelectValue={this.handleSelectValue}
                filterValue={filterValue}
                valueComponent={valueComponent}
            />
        );
    }
}

export const FilterContainer = compose()(Filter);
