import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import { withStyleSheet } from 'hoc/styles';
import { routes } from 'routes';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { LoaderDots } from 'components/LoaderDots';
import { ModuleTable } from 'components/ModuleTable';
import { ModuleTr } from 'components/ModuleTr';
import { ModuleTd } from 'components/ModuleTd';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { InternalLink } from 'components/InternalLink';
import { CirclePlusButton } from 'components/CirclePlusButton';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { get } from 'utils';

import orderBy from 'lodash/orderBy';
import { styleSheet } from './stylesheet';

class EventMentions extends PureComponent {
    static propTypes = {
        collapsedDashboards: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashboardStreamMatches: PropTypes.arrayOf(PropTypes.any),
        mentionsTitle: PropTypes.string.isRequired,
        onCircleClick: PropTypes.func,
        onTermSelect: PropTypes.func.isRequired,
        openUpgradeModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeTerm: PropTypes.func.isRequired,
        removingId: PropTypes.string,
        selectedNode: PropTypes.string,
        showRemove: PropTypes.bool.isRequired,
        streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        streamMatches: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleDash: PropTypes.func
    };

    static defaultProps = {
        dashboardStreamMatches: [],
        onCircleClick: null,
        passedStyles: {},
        removingId: null,
        selectedNode: null,
        streamId: null,
        streamMatches: [],
        toggleDash: () => {}
    };

    constructor(props) {
        super(props);

        this.renderMatch = this.renderMatch.bind(this);
    }

    renderMatch(match) {
        const {
            styles,
            onTermSelect,
            selectedNode,
            theme,
            showRemove,
            removeTerm,
            removingId,
            streamId: currentStreamId
        } = this.props;
        const sentimentTooltip = (
            <Text size={1} styles={styles.sentimentTooltip}>
                Measures the sentiment score for this topic within this event and highlights any deviation from mentions
                of the same topic in events completed in the last two years
            </Text>
        );
        const selectedEventId = (selectedNode || '').toString();
        const { streamId, name: dataLabel } = match;
        const matches = get(match, 'matches', []);
        const eventIds = matches.map(m => m && m.eventId.toString()).sort();

        const totalInstances = eventIds.length;
        const currentIndex = eventIds.indexOf(selectedEventId.toString());
        const nextIndex = currentIndex + 1 < totalInstances ? currentIndex + 1 : 0;
        const onClick = totalInstances > 0 ? () => onTermSelect(streamId, eventIds[nextIndex]) : undefined;
        const rowStyle = totalInstances > 0 ? styles.dataRowClickable : styles.dataRow;
        const indexStyle = currentIndex + 1 > 0 ? styles.dataRowIndexActive : styles.dataRowIndex;
        const sentimentMovement = get(match, 'sentimentMovement');
        const sentimentPercent = get(match, 'sentimentMovementPercent');
        const isSelected = streamId === currentStreamId;
        const isPositive = sentimentMovement && sentimentMovement > 0;
        let sentimentStyle = styles.sentimentText;
        if (isPositive) {
            sentimentStyle = styles.sentimentTextPositive;
        } else {
            sentimentStyle = styles.sentimentTextNegative;
        }

        return (
            <ModuleTr key={dataLabel} styles={rowStyle} onClick={onClick}>
                <ModuleTd
                    styles={{
                        ...styles.dataRowValue,
                        backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                    }}
                >
                    <Div styles={styles.flexRow} className="dataRow">
                        <Text size={3} className="dataLabel" weight="normal">
                            {dataLabel}
                        </Text>
                        {showRemove && (
                            <Span styles={styles.trash} onClick={e => removeTerm(e, streamId)}>
                                {removingId === streamId ? (
                                    <LoaderDots gray />
                                ) : (
                                    <Icon type="trash" color={theme.colors.gray04} />
                                )}
                            </Span>
                        )}
                    </Div>
                    <WithPermission permissions={[PERMISSIONS.unlockedSentiment]}>
                        {sentimentMovement ? (
                            <Tooltip
                                slideIn
                                content={sentimentTooltip}
                                isEnabled
                                useElementOffsetLeft
                                useElementOffsetBottom
                                xOffset={-8}
                                yOffset={6}
                            >
                                {({ showTooltip, hideTooltip }) => (
                                    <Div styles={styles.sentiment}>
                                        <Span onMouseLeave={hideTooltip} onMouseEnter={showTooltip}>
                                            <Icon type="circleInfo02" color={theme.colors.gray04} />
                                        </Span>
                                        <Text styles={sentimentStyle} size={1}>
                                            {`Sentiment ${isPositive ? 'up' : 'down'}`}
                                        </Text>
                                        {new Array(sentimentPercent).fill(sentimentPercent).map((percent, index) => {
                                            const iconKey = `${index}-${dataLabel}`;
                                            return (
                                                <Icon
                                                    key={iconKey}
                                                    type={isPositive ? 'triangleUp' : 'triangleDown'}
                                                    color={isPositive ? theme.colors.green03 : theme.colors.red01}
                                                />
                                            );
                                        })}
                                    </Div>
                                )}
                            </Tooltip>
                        ) : null}
                    </WithPermission>
                </ModuleTd>
                {totalInstances >= 0 ? (
                    <ModuleTd
                        styles={{
                            backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                        }}
                    >
                        <Text size={1} uppercase styles={indexStyle}>
                            {totalInstances}
                        </Text>
                    </ModuleTd>
                ) : (
                    <ModuleTd
                        styles={{
                            backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                        }}
                    />
                )}
            </ModuleTr>
        );
    }

    renderDataRows() {
        const { streamMatches } = this.props;

        return orderBy(
            streamMatches,
            row => {
                const matches = get(row, 'matches', []);
                return matches.length;
            },
            'desc'
        ).map(this.renderMatch);
    }

    renderDataRowsByDash() {
        const { dashboardStreamMatches, styles, theme, toggleDash, collapsedDashboards, openUpgradeModal } = this.props;

        return dashboardStreamMatches.map((dash, index) => {
            const dashName = get(dash, 'name');
            const dashId = get(dash, 'id');
            const streams = get(dash, 'streams', []);
            const collapsed = collapsedDashboards.includes(dashId);
            const headerStyles = !collapsed ? styles.dashHeader : styles.dashHeaderCollapsed;
            const isTopics = dashName.toLowerCase() === 'topics' && index === 0;
            return (
                <Fragment key={dashId}>
                    <Div styles={headerStyles} onClick={() => toggleDash(dashId)}>
                        <Icon type="chevron02" color={theme.colors.black01} />
                        <Text weight="medium" size={3} styles={styles.dashTitle}>
                            {dashName}
                        </Text>
                        <Div styles={styles.spacer} />
                        {/* Topics is first, no dash link needed */}
                        {!isTopics && (
                            <InternalLink
                                styles={styles.dashIconLink}
                                to={generatePath(routes.dashboard, { dashboardId: dashId })}
                            >
                                <Icon type="columns02" color={theme.colors.gray04} />
                            </InternalLink>
                        )}
                    </Div>
                    {isTopics && !collapsed && (
                        <WithPermission permissions={PERMISSIONS.unlockedTopics}>
                            {({ restricted, isLoading }) =>
                                restricted || isLoading ? (
                                    <Div
                                        onClick={isLoading ? undefined : openUpgradeModal}
                                        styles={styles.upgradeTopics}
                                    >
                                        <Icon type="lock02" color={theme.colors.black01} />
                                        <Text size={3}>
                                            {`Upgrade to see ${pluralize(
                                                'topic',
                                                streams.length,
                                                true
                                            )} identified by Aiera NLP.`}
                                        </Text>
                                    </Div>
                                ) : (
                                    <ModuleTable wrapperStyles={styles.table} alternate>
                                        {streams.map(this.renderMatch)}
                                    </ModuleTable>
                                )
                            }
                        </WithPermission>
                    )}

                    {!collapsed && !isTopics && (
                        <ModuleTable wrapperStyles={styles.table} alternate>
                            {streams.map(this.renderMatch)}
                        </ModuleTable>
                    )}
                </Fragment>
            );
        });
    }

    render() {
        const {
            styles,
            passedStyles,
            mentionsTitle,
            streamMatches,
            dashboardStreamMatches,
            onCircleClick
        } = this.props;

        if (
            (!streamMatches || streamMatches.length === 0) &&
            (!dashboardStreamMatches || dashboardStreamMatches.length === 0)
        ) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {dashboardStreamMatches.length > 0 ? (
                    this.renderDataRowsByDash()
                ) : (
                    <Fragment>
                        <Div styles={styles.header}>
                            <Text weight="medium" size={3} styles={styles.dataTitle}>
                                {mentionsTitle}
                            </Text>
                            {onCircleClick && <CirclePlusButton onClick={onCircleClick} />}
                        </Div>
                        <ModuleTable wrapperStyles={styles.table} alternate>
                            {this.renderDataRows()}
                        </ModuleTable>
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const EventMentionsUI = compose(withStyleSheet(styleSheet))(EventMentions);
