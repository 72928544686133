import React, { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { MultiSelectUI } from './ui';

export class MultiSelect extends PureComponent {
    static displayName = 'MultiSelectContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        disabled: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        selected: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        styles: {},
        disabled: false,
        label: undefined,
        multi: false,
        name: '',
        onChange: null,
        required: false,
        selected: []
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({ value, event }) {
        const { disabled, multi, name, onChange, required, selected } = this.props;

        if (!disabled) {
            const idx = selected.indexOf(value);
            let newSelected = [...selected];

            // If the option is already selected and required isn't true, unselect it
            if (idx >= 0 && !required) {
                newSelected = [...selected.slice(0, idx), ...selected.slice(idx + 1)];
            }

            // If single-select, replace the selected option with the new one
            if (idx < 0 && !multi) {
                newSelected = [...selected.slice(0, -1), value];
            }

            // If multi-select, select the option
            if (idx < 0 && multi) {
                newSelected = [...selected, value];
            }

            if (onChange) onChange({ name, value: newSelected, event });
        }
    }

    render() {
        const { styles, children, disabled, label, selected } = this.props;

        return (
            <MultiSelectUI styles={styles} disabled={disabled} label={label}>
                {Children.map(children, child =>
                    React.cloneElement(child, {
                        onClick: this.handleChange,
                        selected: child ? selected.includes(child.props.value) : false
                    })
                )}
            </MultiSelectUI>
        );
    }
}

export const MultiSelectContainer = compose()(MultiSelect);
