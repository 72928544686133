import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { AddToCalendarDropdownUI } from './ui';

export class AddToCalendarDropdown extends PureComponent {
    static displayName = 'AddToCalendarDropdownContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <AddToCalendarDropdownUI {...rest} />;
    }
}

export const AddToCalendarDropdownContainer = compose()(AddToCalendarDropdown);
