import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { CopyLink } from 'components/CopyLink';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class ShareableLinkField extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        tooltipText: PropTypes.string,
        link: PropTypes.string,
        onRefresh: PropTypes.func,
        label: PropTypes.string.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        link: null,
        onRefresh: null,
        tooltipText: null
    };

    render() {
        const { styles, theme, passedStyles, link, onRefresh, tooltipText, label } = this.props;

        const tooltip = <Text size={3}>{tooltipText}</Text>;

        if (!link) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Text size={1} styles={styles.shareDashLabel} uppercase>
                    {label}
                </Text>
                <Tooltip
                    slideIn
                    styles={styles.tooltipShareDash}
                    isEnabled={!!tooltipText}
                    useElementOffsetLeft
                    useElementOffsetTop
                    xOffset={300}
                    yOffset={-100}
                    content={tooltip}
                >
                    {({ showTooltip, hideTooltip }) => (
                        <Div styles={styles.shareDash} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                            <CopyLink styles={styles.copyLink} textSize={3} url={link} />
                            {onRefresh && (
                                <Div styles={styles.shareDashUrlRefresh} onClick={onRefresh}>
                                    <Icon type="reset02" color={theme.colors.gray04} />
                                </Div>
                            )}
                        </Div>
                    )}
                </Tooltip>
            </Div>
        );
    }
}

export const ShareableLinkFieldUI = compose(withStyleSheet(styleSheet))(ShareableLinkField);
