export const styleSheet = theme => ({
    container: {
        transition: 'all 0.2s ease-in-out',
        backgroundColor: theme.colors.blue04,
        color: '#FFF',
        position: 'fixed',
        top: 0,
        left: '20%',
        right: '20%',
        maxWidth: '1000px',
        zIndex: 11,
        borderRadius: '0 0 5px 5px',
        padding: `15px ${theme.margins.margin01}`,
        boxShadow: `0 1px 2px 0 rgba(0, 0, 0, 0.3), inset 0 -2px 2px 0 rgba(0, 0, 0, 0.02), 0 0px 150px 0 rgba(0, 0, 0, 0.2)`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            left: 0,
            right: 0,
            borderRadius: '0 0 0 0'
        }
    },
    containerHidden: {
        transition: 'all 0.2s ease-in-out',
        backgroundColor: theme.colors.blue04,
        color: '#FFF',
        position: 'fixed',
        top: '-100px',
        left: '20%',
        right: '20%',
        maxWidth: '1000px',
        zIndex: 11,
        borderRadius: '0 0 5px 5px',
        padding: `15px ${theme.margins.margin01}`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            left: 0,
            right: 0,
            borderRadius: '0 0 0 0'
        }
    },
    refreshAlert: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'column'
        }
    },
    refreshButton: {
        marginLeft: theme.margins.margin01,
        color: 'rgba(250, 250, 250, 0.7)',
        boxShadow: '0 0 0 1px rgba(250, 250, 250, 0.5)',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        ':active': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexShrink: 0
        }
    },
    alertActions: {
        color: 'rgba(250, 250, 250, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            lineHeight: '1em',
            marginLeft: 0,
            marginTop: theme.margins.margin01
        }
    }
});
