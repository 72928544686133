export const styleSheet = theme => ({
    container: {
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        backgroundColor: theme.colors.white01
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    editInProgress: {
        borderRadius: 6,
        margin: '0 23px 20px',
        display: 'flex',
        '.noticeContent': {
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    wrapperCentered: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
