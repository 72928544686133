import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { PREFERENCES } from 'consts';
import { DEFAULT_WATCHLIST_FILTER } from 'consts/filters';
import { withUserPreferences } from 'graphql/user';
import { withUrlContext } from 'hoc/url';
import { generateModalId, get, hasPreference } from 'utils';
import { withData } from './data';
import { WelcomeMessageUI } from './ui';

export class WelcomeMessage extends PureComponent {
    static displayName = 'WelcomeMessageContainer';

    static propTypes = {
        firstName: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        primaryWatchlistId: PropTypes.string,
        primaryWatchlistName: PropTypes.string,
        saveUXPreference: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        updateDashboard: PropTypes.func.isRequired,
        updateGlobalPrefs: PropTypes.func.isRequired,
        updateWatchlist: PropTypes.func.isRequired,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        firstName: undefined,
        preferences: undefined,
        primaryWatchlistId: null,
        primaryWatchlistName: 'Primary Watchlist',
        styles: {},
        user: {}
    };

    constructor(props) {
        super(props);

        this.stepForward = this.stepForward.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.changeSetupPage = this.changeSetupPage.bind(this);
        this.finishOnboarding = this.finishOnboarding.bind(this);
        this.useDefaultWatchlist = this.useDefaultWatchlist.bind(this);

        this.state = {
            step: 0,
            setupPage: 0,
            savingDefault: false
        };
    }

    changeSetupPage(setupPage) {
        this.setState({ setupPage });
    }

    stepForward() {
        this.setState(({ step }) => ({
            step: step + 1
        }));
    }

    openGallery() {
        const { history, location, saveUXPreference } = this.props;
        const pathname = get(location, 'pathname');
        saveUXPreference({ name: PREFERENCES.showWelcome.name, value: 'false', hideSuccessBanner: true });
        history.push(generateModalId({ location: { pathname, search: '' }, id: 'new', type: 'dashboardGallery' }));
    }

    finishOnboarding() {
        const { saveUXPreference, updateDashboard, primaryWatchlistName, primaryWatchlistId } = this.props;
        saveUXPreference({ name: PREFERENCES.showWelcome.name, value: 'false', hideSuccessBanner: true }).then(() =>
            updateDashboard({
                equityScope: [{ label: primaryWatchlistName, type: 'watchlist', value: primaryWatchlistId }]
            })
        );
        this.setState({ step: 8 });
    }

    useDefaultWatchlist() {
        const { primaryWatchlistId, primaryWatchlistName, updateGlobalPrefs, updateWatchlist, user } = this.props;
        this.setState(
            {
                savingDefault: true
            },
            () => {
                // Turn off alerts by default to prevent flooding new users
                const disableAlertsMutation = updateGlobalPrefs({
                    eventPreferences: { emailEnabled: false }
                });
                // Hardcoding the default watchlist to S&P500
                const updateWatchlistMutation = updateWatchlist({
                    userId: get(user, 'userId'),
                    id: primaryWatchlistId,
                    name: primaryWatchlistName,
                    filters: DEFAULT_WATCHLIST_FILTER
                });
                const mutations = [disableAlertsMutation, updateWatchlistMutation];
                Promise.all(mutations).then(() => {
                    this.setState({ savingDefault: false }, this.finishOnboarding);
                });
            }
        );
    }

    render() {
        const { styles, firstName, preferences, primaryWatchlistId } = this.props;
        const { step, setupPage, savingDefault } = this.state;
        if (hasPreference(preferences, { ...PREFERENCES.showWelcome, value: true }, false)) {
            return (
                <WelcomeMessageUI
                    changeSetupPage={this.changeSetupPage}
                    firstName={firstName}
                    finishOnboarding={this.finishOnboarding}
                    openGallery={this.openGallery}
                    primaryWatchlistId={primaryWatchlistId}
                    savingDefault={savingDefault}
                    setupPage={setupPage}
                    styles={styles}
                    step={step}
                    stepForward={this.stepForward}
                    useDefaultWatchlist={this.useDefaultWatchlist}
                />
            );
        }
        return null;
    }
}

export const WelcomeMessageContainer = compose(
    withData(),
    withUrlContext(['history', 'location']),
    withUserPreferences({ variables: { withDetails: true } }),
    withPropsOnChange(['user'], ({ user }) => ({ firstName: get(user, 'firstName') }))
)(WelcomeMessage);
