import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Hint } from 'components/Hint';
import { Checkbox } from 'components/Checkbox';
import { capitalize, prettyLineBreak, get, prettyEventType } from 'utils';
import { Icon } from 'components/Icon';
import pluralize from 'pluralize';
import { styleSheet } from './stylesheet';

class AskAieraQuestionCard extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        onAnswerAuto: PropTypes.func.isRequired,
        onAnswerFeedback: PropTypes.func.isRequired,
        onAnswerTemplate: PropTypes.func.isRequired,
        onSelectAnswer: PropTypes.func,
        callType: PropTypes.string,
        citations: PropTypes.arrayOf(PropTypes.any),
        copied: PropTypes.bool.isRequired,
        copyToClipboard: PropTypes.func.isRequired,
        id: PropTypes.string,
        feedback: PropTypes.string,
        answer: PropTypes.arrayOf(PropTypes.any),
        autoQuestion: PropTypes.string,
        question: PropTypes.string.isRequired,
        template: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string
    };

    static defaultProps = {
        callType: undefined,
        passedStyles: {},
        id: undefined,
        feedback: undefined,
        answer: [],
        citations: [],
        onSelectAnswer: undefined,
        autoQuestion: undefined,
        template: undefined,
        ticker: undefined
    };

    render() {
        const {
            citations,
            callType,
            passedStyles,
            styles,
            theme,
            onAnswerAuto,
            onAnswerFeedback,
            onAnswerTemplate,
            onSelectAnswer,
            template,
            ticker,
            id,
            feedback,
            answer,
            autoQuestion,
            question,
            copyToClipboard,
            copied
        } = this.props;
        const showTemplateSelector = get(template, 'templateType') !== 'aiera';
        const templateId = get(template, 'id', id);
        const templateType = get(template, 'templateType');
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {showTemplateSelector && (
                    <Hint
                        hideOnScroll
                        yOffset={-26}
                        growUp
                        width={250}
                        text="Save Questions"
                        description="Will be suggested on every event, or only on events for a specific ticker."
                        styles={styles.questionTemplate}
                    >
                        <Text weight="medium">Save Question</Text>
                        <Div styles={styles.questionTemplateOptions}>
                            <Checkbox
                                onChange={({ value }) =>
                                    onAnswerTemplate({
                                        id: templateId,
                                        templateType: value ? 'universal' : undefined
                                    })
                                }
                                checked={templateType === 'universal'}
                                size={4}
                                styles={styles.questionTemplateCheckbox}
                            />
                            <Text
                                styles={{ marginRight: 10 }}
                                onClick={() =>
                                    onAnswerTemplate({
                                        id: templateId,
                                        templateType: templateType !== 'universal' ? 'universal' : undefined
                                    })
                                }
                            >
                                All Events
                            </Text>
                            {ticker && (
                                <Fragment>
                                    <Checkbox
                                        onChange={({ value }) =>
                                            onAnswerTemplate({
                                                id: templateId,
                                                templateType: value ? 'equity' : undefined
                                            })
                                        }
                                        checked={templateType === 'equity'}
                                        size={4}
                                        styles={styles.questionTemplateCheckbox}
                                    />
                                    <Text
                                        onClick={() =>
                                            onAnswerTemplate({
                                                id: templateId,
                                                templateType: templateType !== 'equity' ? 'equity' : undefined
                                            })
                                        }
                                    >
                                        {ticker}
                                    </Text>
                                </Fragment>
                            )}
                        </Div>
                    </Hint>
                )}
                {showTemplateSelector && templateType === 'equity' && callType && (
                    <Div styles={styles.autoAsk}>
                        <Text weight="medium">Automatically Ask Question On</Text>
                        <Div styles={styles.autoAskOptions}>
                            <Div styles={styles.autoAskOption}>
                                <Checkbox
                                    onChange={({ value }) =>
                                        onAnswerAuto({
                                            id: templateId,
                                            autoQuestion: value ? 'same_type' : undefined
                                        })
                                    }
                                    size={4}
                                    styles={styles.questionTemplateCheckbox}
                                    checked={autoQuestion === 'same_type'}
                                />
                                <Text
                                    capitalize
                                    onClick={() =>
                                        onAnswerAuto({
                                            id: templateId,
                                            autoQuestion: autoQuestion !== 'same_type' ? 'same_type' : undefined
                                        })
                                    }
                                >
                                    {pluralize(prettyEventType(callType))}
                                </Text>
                            </Div>
                            <Div styles={styles.autoAskOption}>
                                <Checkbox
                                    onChange={({ value }) =>
                                        onAnswerAuto({
                                            id: templateId,
                                            autoQuestion: value ? 'all_types' : undefined
                                        })
                                    }
                                    size={4}
                                    styles={styles.questionTemplateCheckbox}
                                    checked={autoQuestion === 'all_types'}
                                />
                                <Text
                                    onClick={() =>
                                        onAnswerAuto({
                                            id: templateId,
                                            autoQuestion: autoQuestion !== 'all_types' ? 'all_types' : undefined
                                        })
                                    }
                                >
                                    All Types
                                </Text>
                            </Div>
                        </Div>
                    </Div>
                )}
                <Text size={3} weight="medium" styles={styles.questionTitle} className="askAieraQuestionTitle">
                    {question ? capitalize(prettyLineBreak(question, 0.6, 30)) : ''}
                </Text>
                {!answer || answer.length === 0 ? (
                    <Text styles={styles.questionAnswer} size={3}>
                        Your answer should arrive within 2 minutes...
                    </Text>
                ) : (
                    <Fragment>
                        {answer.map(txt => (
                            <Div key={txt.slice(0, 30)}>
                                <Text size={3} styles={styles.questionAnswer}>
                                    {txt}
                                </Text>
                            </Div>
                        ))}
                        {citations.length > 0 && onSelectAnswer && (
                            <Div onClick={onSelectAnswer} styles={styles.citations}>
                                <Text size={1}>
                                    View {citations.length} {pluralize('Citation', citations.length)}
                                </Text>
                            </Div>
                        )}
                        <Div styles={styles.questionFeedback}>
                            <Div styles={styles.copy} onClick={copyToClipboard}>
                                <Icon
                                    type={copied ? 'checkMarkSmall' : 'copyToClipboard'}
                                    color={copied ? theme.colors.green01 : theme.colors.gray04}
                                />
                                <Text>{copied ? 'Copied' : 'Copy Answer'}</Text>
                            </Div>
                            <Div styles={{ flex: 1 }} />
                            <Hint
                                text="Good Answer"
                                description="Used to improve training data"
                                styles={{
                                    marginRight: 10,
                                    cursor: feedback === 'thumbs_up' ? 'default' : 'pointer',
                                    color: feedback === 'thumbs_up' ? theme.colors.blue08 : theme.colors.gray06
                                }}
                                onClick={
                                    feedback === 'thumbs_up'
                                        ? undefined
                                        : () =>
                                              onAnswerFeedback({
                                                  id,
                                                  feedback: 'thumbs_up'
                                              })
                                }
                            >
                                <Icon
                                    type="thumbUp"
                                    color={feedback === 'thumbs_up' ? theme.colors.blue08 : theme.colors.gray06}
                                />
                            </Hint>
                            <Hint
                                text="Bad Answer"
                                description="Ignored in training data"
                                styles={{
                                    cursor: feedback === 'thumbs_down' ? 'default' : 'pointer',
                                    color: feedback === 'thumbs_down' ? theme.colors.blue08 : theme.colors.gray06
                                }}
                                onClick={
                                    feedback === 'thumbs_down'
                                        ? undefined
                                        : () =>
                                              onAnswerFeedback({
                                                  id,
                                                  feedback: 'thumbs_down'
                                              })
                                }
                            >
                                <Icon
                                    type="thumbDown"
                                    color={feedback === 'thumbs_down' ? theme.colors.blue08 : theme.colors.gray06}
                                />
                            </Hint>
                        </Div>
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const AskAieraQuestionCardUI = compose(withStyleSheet(styleSheet))(AskAieraQuestionCard);
