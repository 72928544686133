import { get } from 'utils';
import { CONTENT_TYPES } from 'consts';

export function getBookmarkType(bookmark) {
    const { target } = bookmark;
    const targetType = get(target, '__typename');
    if (CONTENT_TYPES.includes(targetType)) {
        const { contentType } = target;
        return contentType;
    }
    return 'event';
}
