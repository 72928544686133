import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { statusBannerFire } from 'actions/statusBanner';
import { withUpdateWatchlist } from 'components/WatchlistForm/data';
import { watchlistFragment } from 'graphql/fragments/watchlists';
import { withUpdateGlobalAlertPrefs } from 'graphql/notifications';
import { graphql } from 'graphql/utils';
import { withUpdateCompaniesDashboard } from 'pages/Companies/data';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withUserWatchlists {
                    currentUser {
                        id
                        watchlists {
                            ...watchlist
                        }
                    }
                }
                ${watchlistFragment}
            `,
            {
                props: ({ data }) => {
                    const primaryWatchlist = get(data, 'currentUser.watchlists', []).find(
                        ({ watchlistType }) => watchlistType === 'primary_watchlist'
                    );
                    return {
                        primaryWatchlistId: get(primaryWatchlist, 'watchlistId'),
                        primaryWatchlistName: get(primaryWatchlist, 'name')
                    };
                },
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withUpdateCompaniesDashboard(),
        withUpdateWatchlist(),
        withUpdateGlobalAlertPrefs()
    );
