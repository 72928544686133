export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center'
    },
    errorCode: {
        position: 'absolute',
        bottom: theme.margins.margin02,
        right: theme.margins.margin03,
        color: theme.colors.gray02
    },
    backButton: {
        position: 'absolute',
        top: theme.margins.margin02,
        left: theme.margins.margin02
    }
});
