import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { STREAM_MENU_COLORS } from 'consts';
import { ColorMenuUI } from './ui';

export class ColorMenu extends PureComponent {
    static displayName = 'ColorMenuContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        onChange: PropTypes.func,
        colors: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
        label: PropTypes.string,
        hideLabel: PropTypes.bool,
        useHighlightColors: PropTypes.bool,
        value: PropTypes.string
    };

    static defaultProps = {
        styles: {},
        onChange: null,
        colors: STREAM_MENU_COLORS,
        name: 'colorMenu',
        label: 'select color',
        hideLabel: false,
        useHighlightColors: false,
        value: STREAM_MENU_COLORS[0]
    };

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(event, value) {
        const { onChange, name } = this.props;

        if (onChange) {
            onChange({ event, name, value });
        }
    }

    render() {
        const { colors, label, hideLabel, useHighlightColors, value, styles } = this.props;
        return (
            <ColorMenuUI
                styles={styles}
                selectedColor={value}
                label={label}
                hideLabel={hideLabel}
                colors={colors}
                onSelect={this.onSelect}
                useHighlightColors={useHighlightColors}
            />
        );
    }
}

export const ColorMenuContainer = compose()(ColorMenu);
