export const styleSheet = theme => {
    const button = {
        backgroundColor: theme.colors.white01,
        borderRadius: 3,
        height: 40,
        marginLeft: 15,
        svg: {
            marginRight: 7,
            marginBottom: 1
        }
    };
    const formRow = {
        marginTop: theme.margins.margin02,
        ':first-of-type': {
            marginTop: 14
        }
    };
    return {
        container: {},
        blockQuote: {
            ...theme.content.blockQuote,
            lineHeight: '1.3em',
            fontStyle: 'italic',
            padding: 20
        },
        bookmarkTags: {
            width: '100%'
        },
        button: {
            ...button
        },
        buttonCancel: {
            ...theme.content.cancelButton
        },
        buttonDelete: {
            ...button,
            marginLeft: 0,
            marginRight: 'auto'
        },
        buttonSubmit: {
            ...button,
            ...theme.content.blueButton,
            borderRadius: 3,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        buttonsContainer: {
            ...theme.content.modalButtonsContainer,
            justifyContent: 'flex-end'
        },
        colorMenu: {
            '> div:first-of-type': {
                backgroundColor: theme.colors.white01,
                display: 'inline-flex',
                padding: '2px 0'
            },
            '.colors': {
                width: 200
            }
        },
        dropdown: {
            width: 165
        },
        formContainer: {
            padding: '10px 35px 0 35px'
        },
        formRow: {
            ...formRow
        },
        formRowScrollable: {
            ...formRow,
            '.formRow-rightSide': {
                maxWidth: 450
            }
        },
        headerDate: {
            marginTop: 4,
            lineHeight: '1.3em',
            color: theme.colors.gray04
        },
        loading: {
            margin: '0 auto 30px auto'
        },
        modal: {
            width: '100%',
            maxWidth: 700
        },
        modalHeader: {
            flexDirection: 'column',
            marginRight: theme.margins.margin01,
            h2: {
                lineHeight: '1.2em'
            }
        },
        textarea: {
            textarea: {
                height: 100
            },
            width: '100%'
        }
    };
};
