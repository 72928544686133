import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { styleSheet } from './stylesheet';

class DashboardDropdown extends PureComponent {
    static propTypes = {
        cancelClassName: PropTypes.string,
        disableHoverOpen: PropTypes.bool,
        initialLabel: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            })
        ),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        cancelClassName: undefined,
        disableHoverOpen: false,
        initialLabel: undefined,
        label: undefined,
        onChange: undefined,
        options: [],
        passedStyles: {},
        size: undefined,
        value: undefined
    };

    render() {
        const {
            initialLabel,
            passedStyles,
            styles,
            cancelClassName,
            disableHoverOpen,
            label,
            name,
            onChange,
            options,
            value,
            size
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Dropdown
                    cancelClassName={cancelClassName}
                    disableHoverOpen={disableHoverOpen}
                    initialLabel={initialLabel}
                    label={label}
                    name={name}
                    onChange={onChange}
                    options={options}
                    value={value}
                    size={size}
                />
            </Div>
        );
    }
}

export const DashboardDropdownUI = compose(withStyleSheet(styleSheet))(DashboardDropdown);
