import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import styled from '@emotion/styled';
import { mapStylesProp } from 'utils/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';

import { styleSheet } from './stylesheet';

export const TextareaEle = styled('textarea')(mapStylesProp);

class Textarea extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        id: PropTypes.string,
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        disabled: PropTypes.bool,
        error: PropTypes.string,
        label: PropTypes.string,
        maxLength: PropTypes.number,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        placeholder: PropTypes.string,
        type: PropTypes.string.isRequired,
        remainingChar: PropTypes.number,
        tabIndex: PropTypes.number,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        textareaRef: PropTypes.func.isRequired,
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {},
        id: null,
        autoFocus: false,
        className: null,
        containerStyle: {},
        disabled: false,
        error: '',
        label: null,
        maxLength: undefined,
        onBlur: null,
        onChange: null,
        onFocus: null,
        onKeyDown: () => {},
        onKeyUp: () => {},
        placeholder: '',
        value: '',
        remainingChar: undefined,
        tabIndex: undefined,
        tooltipComponent: undefined
    };

    render() {
        const {
            styles,
            passedStyles,
            id,
            autoFocus,
            className,
            containerStyle,
            disabled,
            error,
            label,
            maxLength,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onKeyUp,
            placeholder,
            type,
            value,
            remainingChar,
            tabIndex,
            textareaRef,
            tooltipComponent,
            tooltipOptions
        } = this.props;

        let inputStyles = error && error.length > 1 ? { ...styles.input, ...styles.inputError } : styles.input;
        if (value && value.length > 0) inputStyles = { ...inputStyles, ...styles.inputWithText };

        // The draggableCancel class ensures that clicking the input doesn't trigger dragging by ResponsiveGridLayout
        const elementClass = className && className.length > 0 ? `draggableCancel ${className}` : 'draggableCancel';

        return (
            <Tooltip isEnabled={!!tooltipComponent} content={tooltipComponent} {...tooltipOptions}>
                {({ showTooltip, hideTooltip }) => (
                    <Div
                        styles={{ ...styles.container, ...containerStyle, ...passedStyles }}
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                    >
                        {label && label.length > 0 && (
                            <Div style={styles.labelContainer}>
                                <Text label uppercase styles={styles.label}>
                                    {label}
                                </Text>
                                {error && error.length > 0 && (
                                    <Text label uppercase styles={styles.textError}>
                                        {error}
                                    </Text>
                                )}
                            </Div>
                        )}
                        <TextareaEle
                            ref={textareaRef}
                            data-tname={name}
                            styles={inputStyles}
                            id={id}
                            autoFocus={autoFocus}
                            className={elementClass}
                            disabled={disabled}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            onFocus={onFocus}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            type={type}
                            value={value}
                            tabIndex={tabIndex}
                            maxLength={maxLength}
                        />
                        {remainingChar > 0 && (
                            <Text size={0} style={styles.charLimit}>
                                {remainingChar}
                            </Text>
                        )}
                        {remainingChar <= 0 && (
                            <Text size={0} uppercase style={styles.charLimitReached}>
                                limit reached
                            </Text>
                        )}
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const TextareaUI = compose(withStyleSheet(styleSheet))(Textarea);
