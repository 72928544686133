export const styleSheet = theme => ({
    container: {
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(67, 72, 89, 0.75)',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 82,
        zIndex: 9,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            top: 52
        }
    },
    button: {
        ...theme.content.blueButton,
        border: 'unset',
        borderRadius: 3,
        boxShadow: 'unset',
        color: theme.colors.white01,
        display: 'inline-flex',
        'svg g': {
            fill: theme.colors.white01
        }
    },
    buttonDisconnect: {
        backgroundColor: theme.colors.red01,
        border: 'unset',
        borderRadius: 3,
        boxShadow: 'unset',
        color: theme.colors.white01,
        display: 'inline-flex',
        margin: '30px 0 0 auto',
        ':hover': {
            backgroundColor: theme.colors.red09
        },
        ':active': {
            backgroundColor: theme.colors.red02
        }
    },
    content: {
        ...theme.content.box,
        border: 'unset',
        boxShadow: '0 2px 6px 0 rgba(55, 54, 72, 0.14)',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        padding: '30px 35px',
        width: '100%'
    },
    currentState: {
        color: theme.colors.gray06,
        margin: '5px auto 0',
        textTransform: 'capitalize'
    },
    description: {
        lineHeight: '1.5em',
        marginTop: theme.margins.margin02
    },
    eventCountdown: {
        margin: '30px auto'
    },
    eventCountdownLabels: {
        span: {
            fontSize: theme.fonts.fontSizes.size01,
            fontWeight: theme.fonts.fontWeights.regular
        }
    },
    footer: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.margins.margin02
    },
    headingContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    headingEditIcon: {
        cursor: 'pointer',
        svg: {
            height: 16,
            width: 16
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    inlineLink: {
        color: theme.colors.linkHover,
        ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    loading: {
        margin: '20px auto 0 auto'
    },
    logs: {
        padding: '20px 0'
    },
    separator: {
        marginTop: theme.margins.margin02
    }
});
