import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PDFViewerMinimalUI } from './ui';

export class PDFViewerMinimal extends PureComponent {
    static displayName = 'PDFViewerMinimalContainer';

    static propTypes = {
        pdfUrl: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.viewerRef = createRef();
        this.handleViewerRef = this.handleViewerRef.bind(this);
        this.initializePdf = this.initializePdf.bind(this);
    }

    componentDidMount() {
        const { pdfUrl } = this.props;
        window.addEventListener('message', this.onPdfEvent, true);
        if (pdfUrl) {
            this.initializePdf();
        }
    }

    componentDidUpdate({ pdfUrl: prevPdfUrl }) {
        const { pdfUrl } = this.props;
        if (pdfUrl && !prevPdfUrl) {
            this.initializePdf();
        }
    }

    handleViewerRef(container) {
        this.viewerRef.current = container;
    }

    initializePdf() {
        const { pdfUrl } = this.props;
        import('@pdftron/pdfjs-express').then(({ default: WebViewer }) => {
            WebViewer(
                {
                    licenseKey: '9LWWe7mbwRDiYD1s4Oh8',
                    config: 'https://public.aiera.com/js/pdfconfig-minimal.js',
                    path: 'https://public.aiera.com/js/webviewer/lib',
                    initialDoc: pdfUrl
                },
                this.viewerRef.current
            );
        });
    }

    render() {
        const { styles } = this.props;
        return <PDFViewerMinimalUI handleViewerRef={this.handleViewerRef} styles={styles} />;
    }
}

export const PDFViewerMinimalContainer = compose()(PDFViewerMinimal);
