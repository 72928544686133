import gql from 'graphql-tag';
import { mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import { resolveEquityFragment } from 'graphql/fragments/resolve';

export const sectorFragment = gql`
    fragment sector on GicsSector {
        id
        sectorId
        name
        numEquities
    }
`;

export const subSectorFragment = gql`
    fragment subSector on GicsSubSector {
        id
        subSectorId
        name
        numEquities
    }
`;

export const getSectorsQuery = alias => gql`
    query ${alias || 'GetSectors'} (
        $ids: [ID]
        $name: String
        $withSubSectors: Boolean = false
        $withEquities: Boolean = false
    ) {
        sectors(ids: $ids, name: $name) {
            ...sector
            subSectors @include(if: $withSubSectors) {
                id
                subSectorId
                name
                numEquities
            }
            equities @include(if: $withEquities) {
                ...resolveEquity
            }
        }
    }
    ${sectorFragment}
    ${resolveEquityFragment}
`;

export const getSubSectorsQuery = alias => gql`
    query ${alias || 'GetSubSectors'} (
        $ids: [ID]
        $withEquities: Boolean = false
    ) {
        subSectors: gicsSubSectors(ids: $ids) {
            ...subSector
            equities @include(if: $withEquities) {
                ...resolveEquity
            }
        }
    }
    ${subSectorFragment}
    ${resolveEquityFragment}
`;

export const withSectors = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withSectors';
    return graphql(getSectorsQuery(alias), {
        props: ({ data }) => ({
            sectorsError: data.error,
            sectorsLoading: data.loading,
            sectors: data.sectors
        }),
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};

export const withSubSectors = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withSubSectors';
    return graphql(getSubSectorsQuery(alias), {
        props: ({ data }) => ({
            subSectorsError: data.error,
            subSectorsLoading: data.loading,
            subSectors: data.subSectors
        }),
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};
