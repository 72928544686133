export const styleSheet = theme => ({
    actionMenu: {
        ...theme.content.cardActionMenu,
        opacity: 1
    },
    actionMenuHighlight: {
        ...theme.content.cardActionMenu,
        borderRadius: '0 6px 0 6px',
        boxShadow: 'unset',
        right: 13,
        top: 12,
        opacity: 1
    },
    buttonText: {
        color: theme.colors.white01,
        marginLeft: 6
    },
    card: {
        padding: '10px 0 0 0'
    },
    cardCompact: {
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        borderRadius: 'unset',
        padding: '7px 13px 10px'
    },
    checkbox: {
        div: {
            margin: '0 6px 0 15px'
        },
        '.checkboxChecked03': {
            backgroundColor: theme.colors.green02,
            border: `1px solid ${theme.colors.green02}`
        }
    },
    checkboxContainer: {
        alignItems: 'center',
        display: 'flex',
        p: {
            color: theme.colors.gray08
        }
    },
    containerCompact: {
        borderRadius: 'unset',
        margin: 'unset',
        padding: '7px 0 10px 0',
        width: 'unset'
    },
    footer: {
        alignItems: 'center',
        backgroundColor: theme.colors.white01,
        borderTop: `1px solid ${theme.colors.gray01}`,
        cursor: 'pointer',
        display: 'flex',
        marginTop: 15,
        padding: '10px 0',
        ':hover': {
            '.checkbox03': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray04}`
            },
            p: {
                color: theme.colors.black01
            }
        }
    },
    highlightIndividual: {
        position: 'relative',
        backgroundColor: theme.colors.white01,
        a: {
            textDecoration: 'none',
            color: theme.colors.black01
        }
    },
    icon: {
        position: 'absolute',
        top: 13,
        right: 13
    },
    highlight: {
        padding: 'unset',
        backgroundColor: theme.colors.white01,
        paddingTop: 12,
        ':hover': {
            backgroundColor: theme.colors.blue09,
            '.individualHighlight': {
                backgroundColor: theme.colors.blue09
            }
        }
    },
    highlightHeader: {
        margin: '0 34px 0 13px',
        '.subtitle': {
            color: theme.colors.gray04
        }
    },
    highlightText: {
        borderRadius: 6,
        margin: '12px 13px 0',
        padding: '12px 30px 12px 14px',
        lineHeight: '1.3em',
        fontSize: theme.fonts.fontSizes.size01,
        position: 'relative',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        '.highlightText': {
            fontStyle: 'italic'
        },
        '> svg': {
            position: 'absolute',
            top: 12,
            right: 12
        }
    },
    highlightSpeakerTime: {
        marginBottom: 6,
        opacity: 0.7
    },
    highlightNote: {
        margin: 13,
        display: 'flex',
        position: 'relative'
    },
    highlightNoteContent: {
        paddingTop: 2
    },
    highlightCreated: {
        marginTop: 2,
        color: theme.colors.gray04
    },
    highlightNoteText: {
        marginTop: 6,
        lineHeight: '1.3em'
    },
    highlightTags: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 6
    },
    highlightTag: {
        margin: '4px 4px 0 0',
        borderRadius: 6,
        padding: '4px 6px',
        backgroundColor: theme.colors.gray03,
        textTransform: 'capitalize',
        color: theme.colors.black01
    },
    highlightCreators: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginLeft: 4
    },
    highlightCreator: {
        flexShrink: 0,
        height: 24,
        width: 24,
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: -4
    },
    highlightCreatorLarge: {
        flexShrink: 0,
        height: 30,
        width: 30,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8
    },
    removeBtn: {
        alignItems: 'center',
        display: 'inline-flex',
        svg: {
            height: 7,
            marginBottom: 2,
            width: 7
        }
    },
    sharedIndicator: {
        position: 'absolute',
        right: 4,
        top: 0,
        height: 16,
        width: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    }
});
