import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { config } from 'configuration';
import { FooterUI } from './ui';

export class Footer extends PureComponent {
    static displayName = 'FooterContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <FooterUI
                {...rest}
                version={config.VERSION}
                showCommit={config.SHOW_DEBUG_INFO}
                commit={config.BUILD_COMMIT}
                commitLink={`${config.GITHUB_BASE}/commit/${config.BUILD_COMMIT}`}
                date={config.BUILD_DATE}
            />
        );
    }
}

export const FooterContainer = compose()(Footer);
