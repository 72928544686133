export const styleSheet = theme => ({
    container: {
        margin: '0 20px 10px',
        textArea: {
            paddingBottom: 50,
            backgroundColor: theme.colors.gray01,
            height: 130
        }
    },
    askButton: {
        ...theme.content.blueButton,
        borderRadius: 6,
        boxShadow: 'unset',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    askFormFocusCheck: {
        ':focus-within': {
            '.askFormEnter': {
                display: 'block'
            }
        }
    },
    askFormFooter: {
        position: 'relative',
        zIndex: 1,
        margin: '-40px 10px 0',
        display: 'flex',
        alignItems: 'center',
        '> p': {
            display: 'none',
            margin: '0 26px 0 16px',
            color: theme.colors.gray06
        }
    },
    previousQuestions: {
        marginBottom: 20,
        '.dropdown': {
            backgroundColor: theme.colors.gray01
        }
    }
});
