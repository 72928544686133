import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withData } from './data';
import { EventGroupAutocompleteUI } from './ui';

export class EventGroupAutocomplete extends PureComponent {
    static displayName = 'EventGroupAutocompleteContainer';

    static propTypes = {
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        updateSearchTerm: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    };

    static defaultProps = {
        initialSearchTerm: null,
        label: undefined,
        multi: false,
        name: 'eventGroupAutocomplete',
        options: [],
        placeholder: 'Select conferences',
        styles: undefined,
        value: null
    };

    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
    }

    onSearch({ value }) {
        const { updateSearchTerm } = this.props;
        updateSearchTerm(value);
    }

    render() {
        const { initialSearchTerm, label, multi, styles, placeholder, options, name, onChange, value } = this.props;
        return (
            <EventGroupAutocompleteUI
                initialSearchTerm={initialSearchTerm}
                label={label}
                multi={multi}
                name={name}
                onChange={onChange}
                onSearch={this.onSearch}
                options={options}
                placeholder={placeholder}
                styles={styles}
                value={value}
            />
        );
    }
}

export const EventGroupAutocompleteContainer = compose(
    withStateHandlers(({ initialSearchTerm }) => ({ term: initialSearchTerm || '' }), {
        updateSearchTerm: () => term => ({ term })
    }),
    withData()
)(EventGroupAutocomplete);
