import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { HintUI } from './ui';

export class Hint extends PureComponent {
    static displayName = 'HintContainer';

    static propTypes = {
        className: PropTypes.string,
        description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        disabled: PropTypes.bool,
        fromLeft: PropTypes.bool,
        growLeft: PropTypes.bool,
        growUp: PropTypes.bool,
        hideOnScroll: PropTypes.bool,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        slideIn: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        className: undefined,
        description: undefined,
        disabled: false,
        fromLeft: false,
        growLeft: false,
        growUp: undefined,
        hideOnScroll: false,
        name: undefined,
        onClick: undefined,
        onMouseEnter: undefined,
        onMouseLeave: undefined,
        slideIn: false,
        styles: undefined,
        width: 'auto',
        xOffset: 0,
        yOffset: 6
    };

    render() {
        const {
            children,
            className,
            description,
            disabled,
            fromLeft,
            growLeft,
            growUp,
            hideOnScroll,
            name,
            onClick,
            onMouseEnter,
            onMouseLeave,
            slideIn,
            styles,
            text,
            width,
            xOffset,
            yOffset
        } = this.props;
        return (
            <HintUI
                className={className}
                description={description}
                disabled={disabled}
                fromLeft={fromLeft}
                growLeft={growLeft}
                growUp={growUp}
                hideOnScroll={hideOnScroll}
                name={name}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                slideIn={slideIn}
                styles={styles}
                text={text}
                width={width}
                xOffset={xOffset}
                yOffset={yOffset}
            >
                {children}
            </HintUI>
        );
    }
}

export const HintContainer = compose()(Hint);
