import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TextareaOld } from 'components/TextareaOld';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class TooltipForm extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        buttonDisabled: PropTypes.bool.isRequired,
        error: PropTypes.string,
        formFooterComponent: PropTypes.element,
        formHeaderComponent: PropTypes.element,
        inputName: PropTypes.string.isRequired,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        onBlur: PropTypes.func,
        onCancel: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onSubmit: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        submitButtonText: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        error: null,
        formFooterComponent: null,
        formHeaderComponent: null,
        label: undefined,
        onBlur: undefined,
        onCancel: undefined,
        onFocus: undefined,
        onKeyDown: undefined,
        onSubmit: undefined,
        passedStyles: {},
        placeholder: '',
        submitButtonText: 'Submit',
        type: null,
        value: ''
    };

    renderInput() {
        const { styles, inputName, label, onChange, onKeyDown, onBlur, onFocus, placeholder, type, value } = this.props;

        if (type === 'textarea') {
            return (
                <TextareaOld
                    styles={styles.textArea}
                    label={label}
                    name={inputName}
                    onChange={v => onChange({ value: v })}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    value={value}
                />
            );
        }

        return (
            <TextInput
                styles={styles.input}
                autoFocus
                autoComplete="off"
                name={inputName}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                value={value}
            />
        );
    }

    render() {
        const {
            className,
            styles,
            passedStyles,
            buttonDisabled,
            loading,
            onCancel,
            onSubmit,
            submitButtonText,
            formHeaderComponent,
            formFooterComponent,
            error,
            theme
        } = this.props;
        return (
            <Div className={className} styles={{ ...styles.container, ...passedStyles }}>
                {formHeaderComponent}
                {this.renderInput()}
                {error && (
                    <Text size={2} styles={styles.error} uppercase weight="medium">
                        {error}
                    </Text>
                )}
                {formFooterComponent}
                <Div styles={styles.buttonsContainer}>
                    <ActionButton styles={{ ...styles.button, ...styles.buttonCancel }} onClick={onCancel}>
                        <Text size={1}>Cancel</Text>
                    </ActionButton>
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSubmit,
                            ...(buttonDisabled ? styles.buttonDisabled : {})
                        }}
                        disabled={buttonDisabled}
                        loading={loading}
                        loaderColor={theme.colors.white01}
                        onClick={onSubmit}
                    >
                        <Text size={1}>{submitButtonText}</Text>
                    </ActionButton>
                </Div>
            </Div>
        );
    }
}

export const TooltipFormUI = compose(withStyleSheet(styleSheet))(TooltipForm);
