import { types } from 'actions/search';
import { get } from 'utils';

const {
    SEARCH_SHOW,
    SEARCH_HIDE,
    SEARCH_HISTORY_ADD_TERM,
    SEARCH_HISTORY_CLEAR,
    SEARCH_TERM_SET,
    SEARCH_TERM_CLEAR
} = types;

const initialState = {
    visible: false,
    searchTerm: '',
    searchHistory: []
};

export function searchReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === SEARCH_SHOW) {
        return {
            ...state,
            visible: true
        };
    }

    if (type === SEARCH_HIDE) {
        return {
            ...state,
            visible: false
        };
    }

    if (type === SEARCH_HISTORY_ADD_TERM) {
        const history = [...(state.searchHistory || [])];
        const searchTerm = get(payload, 'searchTerm', '');
        const searchHistory =
            history.includes(searchTerm) || searchTerm.length === 0 ? history : [searchTerm, ...history.slice(0, 2)];
        return {
            ...state,
            searchHistory
        };
    }

    if (type === SEARCH_HISTORY_CLEAR) {
        return {
            ...state,
            searchHistory: []
        };
    }

    if (type === SEARCH_TERM_SET) {
        return {
            ...state,
            searchTerm: get(payload, 'searchTerm', '')
        };
    }

    if (type === SEARCH_TERM_CLEAR) {
        return {
            ...state,
            searchTerm: ''
        };
    }

    return state;
}
