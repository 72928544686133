import React, { Children, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import composeRefs from '@seznam/compose-react-refs';
import XDate from 'xdate';
import { config } from 'configuration';
import { withStyleSheet } from 'hoc/styles';
import { StreamAlertsTooltip } from 'components/StreamAlertsTooltip';
import { CloneStreamTooltip } from 'components/CloneStreamTooltip';
import { Div } from 'components/Div';
import { EmptyEventsSVG } from 'components/EmptyEventsSVG';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { LoadingBlock } from 'components/LoadingBlock';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { SortHandle } from 'components/SortHandle';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { ExternalLink } from 'components/ExternalLink';
import { PERMISSIONS } from 'consts';
import { get, randomInteger } from 'utils';
import { styleSheet } from './stylesheet';

class Stream extends PureComponent {
    static propTypes = {
        averageDailyVolume: PropTypes.number,
        className: PropTypes.string,
        color: PropTypes.string,
        componentVisibilityRef: PropTypes.objectOf(PropTypes.any),
        copied: PropTypes.bool,
        copySearchTerms: PropTypes.func.isRequired,
        dashboardId: PropTypes.string,
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        disableResize: PropTypes.bool.isRequired,
        draggableProps: PropTypes.objectOf(PropTypes.any).isRequired,
        draggableRef: PropTypes.func,
        dragHandleProps: PropTypes.objectOf(PropTypes.any).isRequired,
        EmptyComponent: PropTypes.objectOf(PropTypes.any),
        emptyText: PropTypes.string,
        exportEvents: PropTypes.func.isRequired,
        exportMatches: PropTypes.func.isRequired,
        hasFilters: PropTypes.bool.isRequired,
        headerDisabled: PropTypes.bool,
        hideEditIcon: PropTypes.func.isRequired,
        hideMenu: PropTypes.func.isRequired,
        invert: PropTypes.bool,
        loaderElement: PropTypes.element,
        loading: PropTypes.bool,
        menuIsFocused: PropTypes.bool.isRequired,
        onEdit: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onResizeStart: PropTypes.func.isRequired,
        onScrollToEnd: PropTypes.func.isRequired,
        onToggleMenu: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        renderHeader: PropTypes.func,
        renderResultsHeader: PropTypes.func,
        renderToolbar: PropTypes.func,
        scrollRef: PropTypes.objectOf(PropTypes.any),
        searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
        searchTermsCopied: PropTypes.bool.isRequired,
        shareStream: PropTypes.func.isRequired,
        showBottomScrollBtn: PropTypes.bool.isRequired,
        showEditIcon: PropTypes.func.isRequired,
        showTopScrollBtn: PropTypes.bool.isRequired,
        streamId: PropTypes.string,
        streamType: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        wasVisible: PropTypes.bool.isRequired,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    };

    static defaultProps = {
        averageDailyVolume: undefined,
        className: undefined,
        color: undefined,
        componentVisibilityRef: undefined,
        copied: false,
        dashboardId: null,
        dashDateRange: null,
        draggableRef: undefined,
        EmptyComponent: undefined,
        emptyText: 'No matches found',
        headerDisabled: false,
        invert: null,
        loaderElement: undefined,
        loading: false,
        passedStyles: {},
        renderHeader: null,
        renderResultsHeader: null,
        renderToolbar: null,
        scrollRef: undefined,
        streamId: null,
        streamType: null,
        subtitle: null,
        title: null
    };

    constructor(props) {
        super(props);

        this.renderStreamMenu = this.renderStreamMenu.bind(this);
    }

    defaultRenderToolbar() {
        const {
            averageDailyVolume,
            color,
            dragHandleProps,
            headerDisabled,
            hideEditIcon,
            hideMenu,
            menuIsFocused,
            onEdit,
            showEditIcon,
            streamId,
            streamType,
            styles,
            theme,
            title,
            wasVisible
        } = this.props;
        return (
            <OutsideClickHandler styles={styles.headerWrapper} onClick={hideMenu} cancelClassName="cloneStreamTooltip">
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => (
                        <Fragment>
                            <Tooltip
                                isEnabled={!m}
                                delay={1000}
                                slideIn
                                useElementOffsetLeft
                                useElementOffsetBottom
                                useMaxHoverWidth
                                useMinHoverWidth
                                yOffset={10}
                                xOffset={-10}
                                content={this.renderToolbarTooltip()}
                            >
                                {({ showTooltip, hideTooltip }) => (
                                    <Div
                                        className="stream-menu-container"
                                        styles={styles.headerContainer}
                                        {...dragHandleProps}
                                    >
                                        {color && (
                                            <Div styles={styles.colorIcon} className="icon-corner">
                                                <Icon type="triangleCorner" color={color} />
                                            </Div>
                                        )}
                                        <SortHandle color={theme.colors.gray04} />
                                        <Div
                                            styles={styles.headerTitleWrapper}
                                            onMouseEnter={e => {
                                                showEditIcon();
                                                if (!m) showTooltip(e);
                                            }}
                                            onMouseLeave={e => {
                                                hideEditIcon();
                                                if (!m) hideTooltip(e);
                                            }}
                                        >
                                            <Div styles={styles.title}>
                                                <Heading styles={styles.header} size={2}>
                                                    {title}
                                                </Heading>
                                                {!m && !headerDisabled && title && menuIsFocused && (
                                                    <WithPermission permissions={[PERMISSIONS.unlockedEditStream]}>
                                                        <Div styles={styles.editIcon} onClick={onEdit}>
                                                            <Icon type="pencil02" color={theme.colors.black01} />
                                                        </Div>
                                                    </WithPermission>
                                                )}
                                            </Div>
                                        </Div>
                                        <Div styles={styles.menuButtonSpacer} />
                                        {!headerDisabled && !m && (
                                            <Fragment>
                                                {streamType === 'content' && (
                                                    <WithPermission permissions={[PERMISSIONS.featureAlerts]}>
                                                        <StreamAlertsTooltip
                                                            averageDailyVolume={averageDailyVolume}
                                                            name="toggle-stream-alert"
                                                            targetId={streamId}
                                                            targetType="stream"
                                                            wasVisible={wasVisible}
                                                        />
                                                    </WithPermission>
                                                )}
                                            </Fragment>
                                        )}
                                        <Tooltip
                                            isEnabled
                                            useElementOffsetRight
                                            useElementOffsetBottom
                                            growLeft
                                            yOffset={3}
                                            xOffset={0}
                                            blockBackground
                                            useOutsideClickHandler
                                            persistOnMouseExit
                                            hideOnScroll={false}
                                            content={this.renderStreamMenu()}
                                        >
                                            {({ showTooltip: showMenu }) => (
                                                <Div
                                                    className="menuToggle"
                                                    styles={styles.menuToggle}
                                                    onClick={showMenu}
                                                >
                                                    <Icon type="more" color={theme.colors.gray06} />
                                                </Div>
                                            )}
                                        </Tooltip>
                                    </Div>
                                )}
                            </Tooltip>
                        </Fragment>
                    )}
                </MediaQuery>
            </OutsideClickHandler>
        );
    }

    renderStreamMenu() {
        const {
            copySearchTerms,
            dashboardId,
            dashDateRange,
            exportEvents,
            exportMatches,
            hideMenu,
            onEdit,
            onRemove,
            searchTerms,
            searchTermsCopied,
            streamType,
            streamId,
            styles,
            theme,
            copied,
            shareStream,
            headerDisabled
        } = this.props;
        let dateRange = '';
        if (dashDateRange) {
            const startDate = new XDate(dashDateRange[0]).toString('yyyy-MM-dd');
            const endDate = new XDate(dashDateRange[1]).toString('yyyy-MM-dd');
            dateRange = `/${startDate}/${endDate}`;
        }

        return ({ hideTooltip }) => (
            <Div styles={styles.menuOpen}>
                {!headerDisabled && (
                    <Div
                        styles={styles.menuItem}
                        onClick={e => {
                            onEdit(e);
                            hideTooltip(e);
                        }}
                    >
                        <Icon type="pencil02" color={theme.colors.gray08} />
                        <Text>Edit keywords & rules</Text>
                    </Div>
                )}
                {streamType === 'content' && (
                    <Div styles={styles.menuItem}>
                        <ExternalLink
                            href={`${get(
                                config,
                                'API_ENDPOINT'
                            )}/dashboards/${dashboardId}/streams/${streamId}/equities/export${dateRange}`}
                            data-tname="stream-csv-export"
                        >
                            <Icon type="csv" color={theme.colors.gray08} />
                            <Text>Export counts to CSV</Text>
                        </ExternalLink>
                    </Div>
                )}
                {streamType === 'content' && (
                    <Div
                        styles={styles.menuItem}
                        onClick={e => {
                            exportMatches();
                            hideTooltip(e);
                        }}
                    >
                        <Icon type="documents" color={theme.colors.gray08} />
                        <Text>Export matches to Word</Text>
                    </Div>
                )}
                {streamType === 'events' && (
                    <Div
                        styles={styles.menuItem}
                        onClick={e => {
                            exportEvents();
                            hideTooltip(e);
                        }}
                    >
                        <Icon type="csv" color={theme.colors.gray08} />
                        <Text>Export events to CSV</Text>
                    </Div>
                )}
                {searchTerms.length > 0 && streamType === 'content' && (
                    <Div
                        styles={styles.menuItem}
                        onClick={e => {
                            copySearchTerms();
                            hideTooltip(e);
                        }}
                    >
                        <Icon type="copyToClipboard" color={theme.colors.gray08} />
                        <Text>{searchTermsCopied ? 'Copied to Clipboard' : 'Copy Search Terms'}</Text>
                    </Div>
                )}
                <CloneStreamTooltip dashboardId={dashboardId} onSubmit={hideMenu} streamId={streamId}>
                    {({ showTooltip: showCloneTooltip }) => (
                        <Div styles={styles.menuItem} onClick={showCloneTooltip}>
                            <Icon type="copy" color={theme.colors.gray08} />
                            <Text>Duplicate search</Text>
                        </Div>
                    )}
                </CloneStreamTooltip>
                <WithPermission permissions={[PERMISSIONS.sharing]}>
                    <Div styles={styles.menuItem} onClick={shareStream}>
                        <Icon type="share" color={theme.colors.gray08} />
                        <Text>{copied ? 'Link Copied!' : 'Link to share search'}</Text>
                    </Div>
                </WithPermission>
                {!headerDisabled && (
                    <Div styles={styles.menuItem} onClick={onRemove}>
                        <Icon type="trash02" color={theme.colors.gray08} />
                        <Text>Remove Search</Text>
                    </Div>
                )}
            </Div>
        );
    }

    renderToolbarTooltip() {
        const { styles, title, subtitle, theme, hasFilters } = this.props;
        return (
            <Div styles={styles.headerTooltip}>
                <Div styles={styles.headerTooltipTop}>
                    <Text size={3} weight="medium">
                        {title}
                    </Text>
                    {hasFilters && <Icon type="filters" color={theme.colors.orange01} />}
                </Div>
                {subtitle && (
                    <Text styles={styles.headerTooltipSubtitle} size={1}>
                        {subtitle}
                    </Text>
                )}
            </Div>
        );
    }

    renderEmpty() {
        const { EmptyComponent, emptyText, styles } = this.props;
        return (
            EmptyComponent || (
                <Div styles={styles.emptyState}>
                    <EmptyEventsSVG />
                    <Text size={3}>{emptyText}</Text>
                </Div>
            )
        );
    }

    renderToolbar() {
        const { renderToolbar } = this.props;
        if (renderToolbar) {
            return renderToolbar(this.props);
        }

        return this.defaultRenderToolbar();
    }

    render() {
        const {
            className,
            componentVisibilityRef,
            disableResize,
            draggableProps,
            draggableRef,
            scrollRef,
            children,
            styles,
            passedStyles,
            loaderElement,
            loading,
            showTopScrollBtn,
            showBottomScrollBtn,
            onScrollToEnd,
            onResizeStart,
            renderHeader,
            renderResultsHeader,
            invert,
            width
        } = this.props;
        const loadingBlocks = randomInteger(1, 3);
        const containerStyles = {
            ...styles.container,
            width,
            minWidth: width,
            ...passedStyles
        };

        const loader =
            loading &&
            (loaderElement || (
                <Fragment>
                    <LoadingBlock height={160} styles={styles.loadingBlock} />
                    {loadingBlocks > 1 && <LoadingBlock height={160} styles={styles.loadingBlock} />}
                    {loadingBlocks > 2 && <LoadingBlock height={160} styles={styles.loadingBlock} />}
                </Fragment>
            ));

        return (
            <Div
                className={className}
                styles={containerStyles}
                ref={composeRefs(draggableRef, componentVisibilityRef)}
                {...draggableProps}
            >
                {this.renderToolbar()}
                {renderHeader && renderHeader(this.props)}
                <Div
                    className="streamScrollTop"
                    styles={showTopScrollBtn ? styles.topScrollBtn : styles.topScrollBtnHidden}
                    onClick={onScrollToEnd}
                >
                    <Text size={1}>Scroll to Top</Text>
                </Div>

                <Div className="streamHitContainer" ref={scrollRef} styles={styles.hitContainer}>
                    {invert && loader}
                    {renderResultsHeader && renderResultsHeader(this.props)}
                    {Children.toArray(children)
                        .filter(child => !!child)
                        .map((child, idx) => (
                            <ErrorBoundary key={`error-bound-${get(child, 'key', idx)}`}>
                                {React.cloneElement(child, { onScrollToEnd })}
                            </ErrorBoundary>
                        ))}
                    {Array.isArray(children) && children.length === 0 && !loading && this.renderEmpty()}
                    {!invert && loader}
                </Div>
                {!disableResize && <Div styles={styles.resizeHandle} onMouseDown={onResizeStart} />}
                <Div
                    className="streamScrollBottom"
                    styles={showBottomScrollBtn ? styles.bottomScrollBtn : styles.bottomScrollBtnHidden}
                    onClick={onScrollToEnd}
                >
                    <Text size={1}>Scroll to Bottom</Text>
                </Div>
            </Div>
        );
    }
}

export const StreamUI = compose(withStyleSheet(styleSheet))(Stream);
