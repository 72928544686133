import { keyframes } from '@emotion/react';
import { getHighlightColor } from 'utils';
import { colors } from './colors';
import { fontWeights, fontSizes } from './fonts';
import { margins } from './margins';

/**
 * KEYFRAMES
 */

export const fadeInFrames = keyframes`
  0%   { opacity:0; }
  100% { opacity:1; }
`;

export const flickerFrames = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
`;

export const liveDotFader = keyframes`
    0%, 100% {
        background-color: ${colors.orange01};
    }
    25%, 50% {
        background-color: ${colors.orange01};
        opacity: 0.7
    }
`;

export const newFadeFrames = keyframes`
  0%   { background-color: ${colors.red05};}
  100% { background-color: ${colors.white01}; }
`;

export const slideFrames = keyframes`
  0%   { transform: translateY(-4px);opacity: 0 }
  100%   { transform: translateY(0px);opacitY: 1 }
`;

export const slideUpFrames = keyframes`
  0%   { transform: translateY(10px);opacity: 0 }
  100%   { transform: translateY(0px);opacitY: 1 }
`;

/**
 * ANIMATIONS
 */

export const fadeInAnimation = {
    WebkitAnimation: `${fadeInFrames} 0.1s 1`,
    animation: `${fadeInFrames} 0.1s 1`
};

export const flickerAnimation = {
    WebkitAnimation: `${flickerFrames} 1.2s 1`,
    animation: `${flickerFrames} 1.2s 1`
};

export const liveDot = {
    WebkitAnimation: `${liveDotFader} 2.0s infinite`,
    animation: `${liveDotFader} 2.0s infinite`
};

export const newFadeAnimation = {
    WebkitAnimation: `${newFadeFrames} 1.2s 1`,
    animation: `${newFadeFrames} 1.2s 1`
};

export const slideInAnimation = {
    WebkitAnimation: `${slideFrames} 0.2s 1`,
    animation: `${slideFrames} 0.2s 1`
};

export const slideUpAnimation = {
    WebkitAnimation: `${slideFrames} 0.2s 1`,
    animation: `${slideFrames} 0.2s 1`
};

/**
 * CONTENT STYLES
 */

const annotation = {
    borderRadius: '2px',
    display: 'inline-block',
    padding: '0 1px',
    margin: '0 -1px'
};
export const highlightedStyles = {
    '.highlight_blue': {
        backgroundColor: getHighlightColor('blue').dark,
        cursor: 'pointer',
        ':hover': {
            boxShadow: `0 0 0 1px ${getHighlightColor('blue').dark02}`
        },
        ':active': {
            backgroundColor: getHighlightColor('blue').dark02,
            color: getHighlightColor('blue').color
        }
    },
    '.highlight_green': {
        backgroundColor: getHighlightColor('green').dark,
        cursor: 'pointer',
        ':hover': {
            boxShadow: `0 0 0 1px ${getHighlightColor('green').dark02}`
        },
        ':active': {
            backgroundColor: getHighlightColor('green').dark02,
            color: getHighlightColor('green').color
        }
    },
    '.highlight_yellow': {
        backgroundColor: getHighlightColor('yellow').dark,
        cursor: 'pointer',
        ':hover': {
            boxShadow: `0 0 0 1px ${getHighlightColor('yellow').dark02}`
        },
        ':active': {
            backgroundColor: getHighlightColor('yellow').dark02,
            color: getHighlightColor('yellow').color
        }
    },
    '.highlight_orange': {
        backgroundColor: getHighlightColor('orange').dark,
        cursor: 'pointer',
        ':hover': {
            boxShadow: `0 0 0 1px ${getHighlightColor('orange').dark02}`
        },
        ':active': {
            backgroundColor: getHighlightColor('orange').dark02,
            color: getHighlightColor('orange').color
        }
    },
    '.highlight_red': {
        backgroundColor: getHighlightColor('red').dark,
        cursor: 'pointer',
        ':hover': {
            boxShadow: `0 0 0 1px ${getHighlightColor('red').dark02}`
        },
        ':active': {
            backgroundColor: getHighlightColor('red').dark02,
            color: getHighlightColor('red').color
        }
    }
};
export const sentimentStyles = {
    '.sentiment_pos': {
        color: colors.green07
    },
    '.sentiment_neg': {
        color: colors.red09
    }
};
export const suggestedAnnotationStyles = {
    '.annotate_suggested': {
        ...annotation,
        backgroundColor: 'yellow',
        boxShadow: '0 1px 0 0 #e8e85c',
        padding: '0 2px',
        '&.quicklinks': {
            backgroundColor: '#FEFFAB'
        }
    }
};
export const annotatedStyles = {
    '.annotate_callout': {
        display: 'block',
        borderLeft: `3px solid ${colors.gray03}`,
        padding: '12px 16px',
        backgroundColor: colors.gray10,
        borderRadius: '2px'
    },
    '.callout': {
        display: 'block',
        borderLeft: `3px solid ${colors.gray03}`,
        padding: '12px 16px',
        backgroundColor: colors.gray10,
        borderRadius: '2px'
    },
    '.annotate_bold_text': {
        fontWeight: fontWeights.medium
    },
    '.annotate': {
        ...annotation,
        backgroundColor: colors.white01,
        boxShadow: `0 1px 0 0 ${colors.gray01}`
    },
    '.annotate_organizations': {
        ...annotation,
        backgroundColor: '#FBFFC3',
        boxShadow: '0 1px 0 0 #FFEBCE'
    },
    '.annotate_dates': {
        ...annotation,
        backgroundColor: '#FdF0FF',
        boxShadow: '0 1px 0 0 #E6D7E8'
    },
    '.annotate_locations': {
        ...annotation,
        backgroundColor: '#EDFFE8',
        boxShadow: '0 1px 0 0 #b9eaab'
    },
    '.annotate_people': {
        ...annotation,
        backgroundColor: '#E0EFFF',
        boxShadow: '0 1px 0 0 #C5D8EC'
    },
    '.annotate_finance_terms': {
        ...annotation,
        backgroundColor: '#E9FFE0',
        boxShadow: '0 1px 0 0 #99E07C'
    },
    '.annotate_product': {
        ...annotation,
        backgroundColor: '#FFE2FA',
        boxShadow: '0 1px 0 0 #E0B6D8'
    },
    '.annotate_kpi': {
        ...annotation,
        backgroundColor: '#FFEE9B',
        boxShadow: '0 1px 0 0 #E4D37C'
    },
    '.annotate_others': {
        ...annotation,
        backgroundColor: '#FFF1EA',
        boxShadow: '0 1px 0 0 #F7D9CE'
    },
    '.annotate_term_match': {
        ...annotation,
        backgroundColor: 'yellow',
        boxShadow: '0 1px 0 0 #e8e85c',
        padding: '0 2px',
        '&.quicklinks': {
            backgroundColor: '#FEFFAB'
        }
    },
    '.organization': {
        cursor: 'default',
        ':hover': {
            boxShadow: 'unset'
        },
        ':active': {
            backgroundColor: 'unset'
        }
    }
};
export const annotated = {
    '> *': {
        overflowWrap: 'anywhere'
    },
    h2: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes.size04
    },
    ...suggestedAnnotationStyles,
    ...annotatedStyles,
    ...highlightedStyles,
    ...sentimentStyles
};

export const autocompleteGroupHeader = {
    position: 'sticky',
    top: 0,
    cursor: 'unset',
    backgroundColor: colors.gray03,
    p: {
        color: colors.gray08,
        textTransform: 'uppercase',
        letterSpacing: 2,
        fontSize: fontSizes.size00
    }
};

export const blockQuote = {
    backgroundColor: colors.white02,
    borderRadius: '3px 0 0 3px',
    p: {
        lineHeight: '1.2em'
    }
};

export const blockQuoteRow = {
    backgroundColor: colors.white02,
    borderRadius: '3px 0 0 3px',
    overflow: 'hidden',
    p: {
        overflow: 'hidden',
        padding: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
};

export const blueButton = {
    backgroundColor: colors.blue08,
    ':hover': {
        backgroundColor: colors.blue10
    },
    ':active': {
        backgroundColor: colors.blue11
    }
};

export const box = {
    background: colors.white01,
    border: `1px solid ${colors.gray09}`,
    boxShadow: '0 1px 0 0 rgba(66,65,82,0.06)',
    borderRadius: 6
};

export const cancelButton = {
    boxShadow: 'unset',
    padding: '0 2px 0 0',
    p: {
        color: colors.gray06
    },
    ':hover': {
        boxShadow: 'unset',
        p: {
            color: colors.black01
        }
    },
    ':active': {
        backgroundColor: 'unset'
    }
};

export const cardActionMenu = {
    opacity: 0.9,
    position: 'absolute',
    right: 6,
    top: 6,
    transition: `all 0.1s ease-in-out`,
    zIndex: 1,
    ':hover': {
        opacity: 1,
        'svg g': {
            fill: colors.blue08
        }
    }
};

export const createButton = {
    backgroundColor: colors.blue01,
    boxShadow: '0 1px 2px 0px rgba(63, 63, 72 , 0.3)',
    border: '1px solid #4083d2',
    letterSpacing: '1px',
    p: {
        color: colors.white01,
        marginRight: margins.margin00
    },
    'svg g': {
        fill: colors.white01
    },
    ':active': {
        backgroundColor: '#2a68af',
        border: '1px solid #2a68af',
        boxShadow: '0 0 0 0 transparent'
    },
    ':hover': {
        backgroundColor: '#4185d4',
        boxShadow: '0 1px 2px 0px rgba(63, 63, 72 , 0.3)'
    }
};

export const formLabel = {
    marginBottom: '6px',
    textTransform: 'capitalize',
    color: colors.gray08,
    fontSize: fontSizes.size03
};

export const hoverMenu = {
    borderRadius: 6,
    overflow: 'hidden',
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)'
};

export const hoverMenuOption = {
    color: colors.gray12,
    backgroundColor: colors.white01,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px 0 12px',
    cursor: 'pointer',
    p: { lineHeight: '16px' },
    ':hover': {
        color: colors.white01,
        backgroundColor: colors.blue08,
        'svg g': {
            fill: colors.white01
        }
    },
    ':active': {
        color: colors.white01,
        backgroundColor: colors.blue04
    },
    ':first-of-type': {
        borderRadius: '6px 6px 0 0'
    },
    ':last-of-type': {
        borderRadius: '0 0 6px 6px'
    }
};

export const iconButton = {
    borderRadius: 3.5,
    boxShadow: 'unset',
    height: 30,
    padding: `0 ${margins.margin00}`,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    backgroundColor: colors.blue08,
    p: {
        color: colors.white01,
        marginLeft: 6,
        whiteSpace: 'nowrap'
    },
    svg: {
        height: 13,
        marginBottom: 1
    },
    ':hover': {
        backgroundColor: colors.blue10
    },
    ':active': {
        backgroundColor: colors.blue11
    }
};

export const inputErrorSmall = {
    'div:first-of-type': {
        position: 'absolute',
        right: 0,
        top: -20,
        'label:last-of-type': {
            fontSize: fontSizes.size01,
            marginLeft: 'auto',
            textTransform: 'none'
        }
    }
};

export const modalButtonsContainer = {
    alignItems: 'center',
    backgroundColor: colors.gray10,
    borderRadius: '0 0 6.5px 6.5px',
    boxShadow: 'inset 0 1px 0 0 rgba(0, 0, 0, 0.04)',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 36,
    padding: '16px 35px'
};

export const orangeButton = {
    backgroundColor: colors.orange02,
    ':active': {
        backgroundColor: colors.orange05
    },
    ':hover': {
        backgroundColor: colors.orange06
    }
};

export const textNote = {
    backgroundColor: colors.blue09,
    borderRadius: '0 6px 6px 0',
    borderLeft: `2px solid ${colors.blue12}`,
    padding: '10px 14px'
};

export const tooltipText = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.black01,
    borderRadius: 6.5,
    color: colors.white01,
    lineHeight: '1.2em',
    opacity: 0.9,
    padding: '10px 13px'
};

export const tooltipTextSmall = {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${margins.margin00}`,
    backgroundColor: colors.black01,
    opacity: 0.9,
    borderRadius: 6.5,
    color: colors.white01
};
