import { types } from 'actions/statusBanner';

const { STATUS_BANNER_FIRE, STATUS_BANNER_HIDE } = types;

const initialState = {
    visible: false,
    text: '',
    type: 'success',
    icon: 'circleCheck',
    duration: 3000
};

export function statusBannerReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === STATUS_BANNER_FIRE) {
        return {
            ...state,
            visible: true,
            type: payload.type,
            text: payload.text,
            icon: payload.icon,
            duration: payload.duration
        };
    }

    if (type === STATUS_BANNER_HIDE) {
        return {
            ...state,
            visible: false
        };
    }

    return state;
}
