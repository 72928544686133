const HIGHLIGHT_ADD = 'HIGHLIGHT_ADD';
const HIGHLIGHT_EXPORT = 'HIGHLIGHT_EXPORT';
const HIGHLIGHT_REMOVE = 'HIGHLIGHT_REMOVE';
const HIGHLIGHT_SELECTION_START = 'HIGHLIGHT_SELECTION_START';

export const types = {
    HIGHLIGHT_ADD,
    HIGHLIGHT_EXPORT,
    HIGHLIGHT_REMOVE,
    HIGHLIGHT_SELECTION_START
};

/**
 * These actions are for capturing a text highlight to create a bookmark.
 * The highlight is kept in redux to give the BookmarkForm modal access to
 * the target id, type, and highlighted text.
 * Only one highlight can be stored at a time.
 */

export function highlightAdd({
    contentId,
    eventId,
    highlight,
    highlightsFilterKey,
    highlightsSortKey,
    includeNewsHighlights,
    matchId,
    onSuccess,
    streamId,
    targetEndId,
    targetEndOffset,
    targetId,
    targetOffset,
    targetStreamId,
    targetType
}) {
    return {
        type: HIGHLIGHT_ADD,
        payload: {
            contentId,
            eventId,
            highlight,
            highlightsFilterKey,
            highlightsSortKey,
            includeNewsHighlights,
            matchId,
            onSuccess,
            streamId,
            targetEndId,
            targetEndOffset,
            targetId,
            targetOffset,
            targetStreamId,
            targetType
        }
    };
}

export function highlightExport(bookmarkIds) {
    return {
        type: HIGHLIGHT_EXPORT,
        payload: {
            bookmarkIds
        }
    };
}

export function highlightRemove() {
    return {
        type: HIGHLIGHT_REMOVE
    };
}

export function highlightSelectionStart(selectionStartId) {
    return {
        type: HIGHLIGHT_SELECTION_START,
        payload: {
            selectionStartId
        }
    };
}
