export const styleSheet = theme => ({
    container: {
        backgroundColor: `rgba(0, 0, 0, 0.4)`,
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        zIndex: 10,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    containerShell: {
        backgroundColor: `rgba(0, 0, 0, 0.4)`,
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: `${theme.margins.margin04} 0`
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white01,
        margin: `${theme.margins.margin04} auto`,
        borderRadius: 6,
        width: '100%',
        maxWidth: '600px',
        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.1)'
    },
    header: {
        padding: '30px 35px 0',
        display: 'flex',
        alignItems: 'center'
    },
    closeModal: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        height: 32,
        marginLeft: 'auto',
        svg: {
            margin: '2px 0 0 auto'
        },
        ':hover': {
            transform: 'scale(1.1)'
        }
    }
});
