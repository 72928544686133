import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Modal } from 'components/Modal';
import { VideoPlayer } from 'components/VideoPlayer';
import { styleSheet } from './stylesheet';

class VideoModal extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
        videoType: PropTypes.string.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        title: undefined
    };

    render() {
        const { onClose, passedStyles, styles, title, videoType } = this.props;
        return (
            <Modal isOpen onModalClose={onClose} styles={{ ...styles.container, ...passedStyles }} title={title}>
                <VideoPlayer autoplay videoType={videoType} />
            </Modal>
        );
    }
}

export const VideoModalUI = compose(withStyleSheet(styleSheet))(VideoModal);
