import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';

import { NewsSourceAutocompleteUI } from './ui';

export class NewsSourceAutocomplete extends PureComponent {
    static displayName = 'NewsSourceAutocompleteContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        options: PropTypes.arrayOf(PropTypes.object),
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        value: PropTypes.string
    };

    static defaultProps = {
        styles: undefined,
        options: [],
        initialSearchTerm: null,
        label: null,
        loading: false,
        name: 'newsSourceAutocomplete',
        onChange: null,
        placeholder: 'Filter by News Source',
        value: null
    };

    render() {
        const { styles, options, initialSearchTerm, label, loading, name, onChange, placeholder, value } = this.props;
        return (
            <NewsSourceAutocompleteUI
                styles={styles}
                options={options}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        );
    }
}

export const NewsSourceAutocompleteContainer = compose(withData())(NewsSourceAutocomplete);
