import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';

import { styleSheet } from './stylesheet';

class CirclePlusButton extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        hoverOptions: PropTypes.arrayOf(PropTypes.any),
        onClick: PropTypes.func.isRequired,
        tooltipOptions: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {},
        hoverOptions: []
    };

    render() {
        const { styles, theme, passedStyles, onClick, hoverOptions, tooltipOptions } = this.props;

        return (
            <Tooltip
                isEnabled={hoverOptions.length > 0}
                styles={styles.hoverMenu}
                content={({ hideTooltip }) => {
                    const options = [];
                    hoverOptions.forEach(({ label, icon, value, permission, restrictedVisible, restrictedIcon }) =>
                        permission
                            ? options.push(
                                  <WithPermission key={value} permissions={[PERMISSIONS[permission]]}>
                                      {({ restricted, isLoading }) => {
                                          if (restricted || isLoading) {
                                              if (restrictedVisible) {
                                                  return (
                                                      <Div
                                                          styles={styles.menuOption}
                                                          key={value}
                                                          onClick={() => {
                                                              onClick(value, restricted);
                                                              hideTooltip();
                                                          }}
                                                      >
                                                          <Div styles={styles.iconContainer}>
                                                              <Icon
                                                                  type={restrictedIcon}
                                                                  color={theme.colors.black01}
                                                              />
                                                          </Div>
                                                          <Text size={3}>{label}</Text>
                                                      </Div>
                                                  );
                                              }

                                              return null;
                                          }
                                          return (
                                              <Div
                                                  styles={styles.menuOption}
                                                  key={value}
                                                  onClick={() => {
                                                      onClick(value, restricted);
                                                      hideTooltip();
                                                  }}
                                              >
                                                  <Div styles={styles.iconContainer}>
                                                      <Icon type={icon} color={theme.colors.black01} />
                                                  </Div>
                                                  <Text size={3}>{label}</Text>
                                              </Div>
                                          );
                                      }}
                                  </WithPermission>
                              )
                            : options.push(
                                  <Div
                                      styles={styles.menuOption}
                                      key={value}
                                      onClick={() => {
                                          onClick(value);
                                          hideTooltip();
                                      }}
                                  >
                                      <Div styles={styles.iconContainer}>
                                          <Icon type={icon} color={theme.colors.black01} />
                                      </Div>
                                      <Text size={3}>{label}</Text>
                                  </Div>
                              )
                    );
                    return options;
                }}
                {...tooltipOptions}
            >
                {({ showTooltip }) => (
                    <Div
                        styles={{ ...styles.container, ...passedStyles }}
                        onClick={hoverOptions.length > 0 ? showTooltip : onClick}
                    >
                        <Icon type="plus" color={theme.colors.white01} />
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const CirclePlusButtonUI = compose(withStyleSheet(styleSheet))(CirclePlusButton);
