import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { get } from 'utils';
import { withUrlContext } from 'hoc/url';
import { withTrackUserActivity } from 'graphql/user';
import { withData } from './data';
import { CustomDataRecordUI } from './ui';

function formatRecord(fields) {
    return (fields || []).reduce(
        (record, field) => ({
            ...record,
            [field.name]: field.formatted || field.value
        }),
        {}
    );
}

export class CustomDataRecord extends PureComponent {
    static displayName = 'CustomDataRecordContainer';

    static propTypes = {
        dataRecordId: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        minimized: PropTypes.bool,
        record: PropTypes.objectOf(PropTypes.any),
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        template: PropTypes.objectOf(PropTypes.any),
        trackDataRecordActivity: PropTypes.func.isRequired
    };

    static defaultProps = {
        loading: false,
        minimized: false,
        record: null,
        setToolbarTitle: null,
        styles: undefined,
        template: null
    };

    constructor(props) {
        super(props);

        this.containerRef = createRef();
        this.formatRecord = memoize(formatRecord);
        this.getTemplateField = this.getTemplateField.bind(this);
    }

    componentDidMount() {
        const { dataRecordId, template, trackDataRecordActivity } = this.props;
        if (dataRecordId) {
            trackDataRecordActivity(dataRecordId);
        }

        if (template) {
            this.addStyles();
        }
    }

    componentDidUpdate({ dataRecordId: prevDataRecordId, template: prevTemplate }) {
        const { dataRecordId, setToolbarTitle, template, trackDataRecordActivity } = this.props;

        if (dataRecordId !== prevDataRecordId) {
            trackDataRecordActivity(dataRecordId);
        }

        if (template !== prevTemplate) {
            this.addStyles();
        }

        const title = this.getTemplateField('title', 'Data Record');
        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    addStyles() {
        const { template } = this.props;
        if (this.styleEle) {
            if (this.containerRef.current) {
                this.containerRef.current.removeChild(this.styleEle);
            }
            this.styleEle = null;
        }
        const css = get(template, `configuration.fullPageBodyCss`);
        if (css && this.containerRef.current) {
            this.styleEle = document.createElement('style');
            this.styleEle.scoped = true;
            this.styleEle.textContent = css;
            this.containerRef.current.append(this.styleEle);
        }
    }

    getTemplateField(field, defaultValue) {
        const { record, template } = this.props;
        return get(this.formatRecord(get(record, 'record')), get(template, `configuration.${field}`), defaultValue);
    }

    render() {
        const { loading, minimized, record, styles, template } = this.props;
        return (
            <CustomDataRecordUI
                containerRef={this.containerRef}
                equity={get(record, 'equity')}
                getTemplateField={this.getTemplateField}
                loading={loading}
                minimized={minimized}
                record={record}
                styles={styles}
                template={template}
            />
        );
    }
}

export const CustomDataRecordContainer = compose(
    withTrackUserActivity(),
    withUrlContext(['streamId']),
    withData()
)(CustomDataRecord);
