import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Table } from 'components/Table';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class ModuleTable extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        wrapperStyles: PropTypes.objectOf(PropTypes.any),
        alternate: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        wrapperStyles: {},
        alternate: false
    };

    render() {
        const { styles, passedStyles, children, alternate, wrapperStyles } = this.props;
        const containerStyle = alternate ? styles.containerAlt : styles.container;
        return (
            <Div styles={{ ...styles.tableWrapper, ...wrapperStyles }}>
                <Table styles={{ ...containerStyle, ...passedStyles }}>{children}</Table>
            </Div>
        );
    }
}

export const ModuleTableUI = compose(withStyleSheet(styleSheet))(ModuleTable);
