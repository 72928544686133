import gql from 'graphql-tag';

export const resolveEquityFragment = gql`
    fragment resolveEquity on Equity {
        id
        equityId
        localTicker
        commonName
        name
        exchange {
            id
            name
            shortName
        }
    }
`;
