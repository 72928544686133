import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get, getCompanyQuotes } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withSpotlight($spotlightId: ID!, $streamId: ID, $includeHighlights: Boolean = false) {
                    content(filter: { contentIds: [$spotlightId] }) {
                        __typename
                        id
                        title
                        bodyWithHighlights: body(highlight: { streamId: $streamId }) @include(if: $includeHighlights)
                        body @skip(if: $includeHighlights)
                        contentType
                        displayType
                        publishedDate
                        primaryCompany {
                            id
                            commonName
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        companies {
                            id
                            commonName
                            iconUrl
                            instruments {
                                id
                                isPrimary
                                quotes {
                                    id
                                    currency {
                                        id
                                        currencyCode
                                        minorSymbol
                                        minorSymbolPrefix
                                        symbol
                                        symbolPrefix
                                    }
                                    exchange {
                                        id
                                        country {
                                            id
                                            countryCode
                                        }
                                        shortName
                                    }
                                    isPrimary
                                    localTicker
                                }
                            }
                        }
                        categories {
                            id
                            shortName
                            displayName
                        }
                        events {
                            id
                            callDate
                            title
                        }
                        userSettings {
                            id
                            archived
                            isRead
                            starred
                            tags
                        }
                        tags {
                            tag
                            users {
                                id
                                username
                            }
                        }
                        ... on GuidanceSpotlightContent {
                            guidanceTrend
                            eventDate
                        }
                        ... on PartnershipSpotlightContent {
                            eventDate
                        }
                        ... on AssetPurchaseSpotlightContent {
                            eventDate
                        }
                        ... on BuybackSpotlightContent {
                            eventDate
                        }
                        ... on SalesMetricSpotlightContent {
                            eventDate
                        }
                        ... on MAndASpotlightContent {
                            eventDate
                        }
                        ... on SpinOffSpotlightContent {
                            eventDate
                        }
                        ... on IPOSpotlightContent {
                            eventDate
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const content = get(data, 'content[0]');
                    const primaryCompany = get(content, 'primaryCompany');
                    const instruments = get(primaryCompany, 'instruments', []);
                    const { primaryQuote } = getCompanyQuotes(instruments);
                    return {
                        body: get(content, 'bodyWithHighlights', get(content, 'body')),
                        categories: get(content, 'categories', []),
                        companies: get(content, 'companies'),
                        company: get(primaryCompany, 'commonName'),
                        companyId: get(primaryCompany, 'id'),
                        contentType: get(content, 'contentType'),
                        displayType: get(content, 'displayType'),
                        eventDate: get(content, 'eventDate'),
                        events: get(content, 'events'),
                        exchangeName: get(primaryQuote, 'exchange.shortName'),
                        isArchived: get(content, 'userSettings.archived', false),
                        isRead: get(content, 'userSettings.isRead', false),
                        isStarred: get(content, 'userSettings.starred', false),
                        publishedDate: get(content, 'publishedDate'),
                        spotlightError: !!get(data, 'error'),
                        spotlightLoading: get(data, 'loading'),
                        spotlightSubtype: get(content, 'guidanceTrend'),
                        tags: get(content, 'tags', []),
                        ticker: get(primaryQuote, 'localTicker'),
                        title: get(content, 'title'),
                        userTags: get(content, 'userSettings.tags', [])
                    };
                },
                options: ({ spotlightId, streamId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        spotlightId,
                        streamId,
                        includeHighlights: !!streamId
                    }
                })
            }
        )
    );
