import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

/* ----------- MUTATIONS ----------- */

export const requestEquitiesMutation = gql`
    mutation RequestEquities($identifiers: [String]!) {
        requestEquities(identifiers: $identifiers) {
            success
        }
    }
`;

/* ----------- APOLLO CONNECTORS ----------- */

export const withRequestEquities = () =>
    graphql(requestEquitiesMutation, {
        props: ({ mutate }) => ({
            requestEquities: identifiers =>
                mutate({
                    variables: { identifiers }
                })
                    .then(({ data }) => {
                        return get(data, 'requestEquities.success', false);
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(`Failure from requestEquities: ${error}`);
                        return false;
                    })
        })
    });
