import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { IconUI } from './ui';

export class Icon extends PureComponent {
    static displayName = 'IconContainer';

    render() {
        const { ...rest } = this.props;
        return <IconUI {...rest} />;
    }
}

export const IconContainer = compose()(Icon);
