import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { dashboardFragment, dashboardSectionFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

export const withCloneDashboard = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CloneDashboard($dashboardId: ID!, $name: String, $searchTerm: String) {
                    cloneDashboard(dashboardId: $dashboardId, name: $name) {
                        success
                        dashboard {
                            ...dashboard
                        }
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                            galleryDashboards(filter: { search: $searchTerm }) {
                                tag
                                dashboards {
                                    ...dashboard
                                    clonedToId
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        cloneDashboard: (dashboardId, name) =>
                            mutate({
                                variables: { dashboardId, name }
                            })
                                .then(
                                    ({
                                        data: {
                                            cloneDashboard: { dashboard }
                                        }
                                    }) => {
                                        setStatusBanner('Monitor cloned successfully!');
                                        return dashboard;
                                    }
                                )
                                .catch(error => {
                                    setStatusBanner(`Error cloning monitor: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        )
    );

export const withCreateDashboard = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CreateDashboard($name: String) {
                    createDashboard(input: { name: $name }) {
                        dashboard {
                            ...dashboard
                        }
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    createDashboard: ({ name }) =>
                        mutate({
                            variables: { name }
                        })
                            .then(
                                ({
                                    data: {
                                        createDashboard: { dashboard }
                                    }
                                }) => {
                                    setStatusBanner('Monitor created successfully!');
                                    return dashboard;
                                }
                            )
                            .catch(error => {
                                setStatusBanner(`Error creating monitor: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        )
    );
