/* eslint-disable */
import EventEmitter from 'events';
import autoBind from 'auto-bind';
import XDate from 'xdate';
import Twilio from 'twilio-client';
import { config } from 'configuration';
import { mobileBridge } from 'provider/mobileBridge';
import { get } from 'utils';
import set from 'lodash/set';

export class TwilioWebRTC extends EventEmitter {
    constructor() {
        super();
        autoBind(this);

        this.startTime = null;
        this.currentTime = 0;
        this.duration = 0;
        this.decibels = 0;
        this.metaData = null;
        this._calltimer = null;

        // Handle mobile webrtc
        mobileBridge.on('webrtc:event', (...args) => {
            this.emit(...args);
        });
    }

    initDevice() {
        this.resetDevice();
        this.device = new Twilio.Device();
        this.device.on('connect', this.onConnected);
        this.device.on('disconnect', this.onDisconnected);
        this.device.on('ready', this.onReady);
    }

    resetDevice() {
        if (this.connection) {
            this.device.disconnectAll();
        }
        if (this.device) {
            this.device.off('connect', this.onConnected);
            this.device.off('disconnect', this.onDisconnected);
            this.device.off('ready', this.onReady);
            this.device = null;
        }
    }

    get connection() {
        return this.device ? this.device.activeConnection() : null;
    }

    onReady() {
        this.emit('ready');
    }

    onConnected(connection) {
        const { customParameters = {} } = connection;
        this._callTimer = setInterval(() => {
            const currentTime = this.startTime.diffSeconds(new XDate());
            this.duration = currentTime;
            this.currentTime = currentTime;
            this.emit('timeChange', this.currentTime);
        }, 500);
        connection.mute(customParameters.get("listenOnly") === 'true');
        // Latest chrome seems to support this
        // https://discourse.wicg.io/t/hint-attribute-in-webrtc-to-influence-underlying-audio-video-buffering/4038
        // See: https://jsfiddle.net/75cnfojy/
        //
        // This is grabbing some internals from twilio, so will stop working
        // if they change this but won't crash or anything.
        // See:
        // https://github.com/twilio/twilio-client.js/blob/9c999544461fa1fa22cdfb9efe5ce30219b7ad7b/lib/twilio/rtc/peerconnection.js#L632
        // https://github.com/twilio/twilio-client.js/blob/9c999544461fa1fa22cdfb9efe5ce30219b7ad7b/lib/twilio/rtc/rtcpc.js#L30
        const pc = get(connection, 'mediaStream.version.pc');
        if (pc && pc.getReceivers()) {
            pc.getReceivers().forEach(receiver => {
                receiver.playoutDelayHint = 1;
                receiver.track.enabled = true;
            });
        }

        if(customParameters.get("listenOnly") === 'true'){
            if (pc && pc.getSenders()) {
                pc.getSenders().forEach(sender => {
                    sender.track.enabled = false;
                });
            }

            pc.ontrack = function(ev) {
                if (ev.streams && ev.streams[0]) {
                    const audioEl = document.createElement("AUDIO")
                    audioEl.srcObject = ev.streams[0];
                    audioEl.play();
                    audioEl.muted = false;
                }
            }
        }

        this.startTime = new XDate();
        this.emit('connected');
    }

    onDisconnected() {
        clearInterval(this._callTimer);
        this.startTime = null;
        this.currentTime = 0;
        this.duration = 0;
        this.decibels = 0;
        this.metaData = null;
        this._calltimer = null;
        this.emit('disconnected');
    }

    authenticate(data, pcConfig) {
        const { call_id: event_id, token: event_token } = data;
        this.initDevice();
        return fetch(`${config.STREAMS_ENDPOINT}/token?event_id=${event_id}&event_token=${event_token}`, {
            mode: 'cors'
        })
            .then(r => r.json())
            .then(({ token }) => {
                this.device.setup(token, {
                    rtcConfiguration: pcConfig,
                    debug: config.NODE_ENV === 'development',
                });
            });
    }

    connect(data, listenOnly, metaData) {
        const { call_id: event_id, token: event_token, mode, ...rest } = data;
        this.metaData = metaData;
        this.device.connect({
            event_id,
            event_token,
            listenOnly: !!listenOnly,
            // Old stuff uses mode, new stuff uses role so pass both
            role: mode === 'agent' ? 'agent' : 'attendee',
            mode,
            auth: config.STREAMS_AUTH_TOKEN,
            ...data
        });
    }

    reset() {
        this.resetDevice();
    }

    mute() {
        if (this.connection) {
            this.connection.mute(true);
            this.emit('muted', this.connection.isMuted());
        }
    }

    unmute() {
        if (this.connection) {
            const { customParameters = {} } = this.connection;
            if (customParameters.listenOnly === 'false') {
                this.connection.mute(false);
                this.emit('muted', this.connection.isMuted());
            }
        }
    }

    sendDTMF(tone, duration) {
        if (this.connection) {
            this.connection.sendDigits(String(tone));
        }
    }
}
