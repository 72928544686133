export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flex: 1
    },
    filterType: {
        width: 190,
        marginRight: theme.margins.margin00
    },
    operator: {
        width: 172,
        marginRight: theme.margins.margin00
    }
});
