export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        ...theme.content.blueButton,
        marginLeft: 8,
        borderRadius: 6,
        display: 'inline-flex',
        boxShadow: 'unset',
        p: {
            color: theme.colors.white01
        }
    },
    error: {
        alignSelf: 'flex-end',
        color: theme.colors.red01,
        margin: '10px 0 20px'
    },
    format: {
        marginTop: theme.margins.margin00
    },
    formatLabel: {
        opacity: 0.6,
        letterSpacing: 1,
        paddingBottom: 4
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: theme.margins.margin00
    },
    infoLinkText: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.margins.margin00,
        p: {
            color: theme.colors.gray06,
            marginLeft: 6
        }
    },
    label: {
        ...theme.content.formLabel,
        marginBottom: 10
    },
    maxTickersExceeded: {
        color: theme.colors.red01,
        letterSpacing: '1px',
        margin: '5px 0 0 auto'
    },
    searchResultsSectorsContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white01,
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: '0 0 2px 2px',
        maxHeight: '100px',
        top: `calc(${theme.margins.margin04} + 5px)`,
        left: 0,
        right: 0,
        overflowY: 'scroll',
        margin: `0 ${theme.margins.margin01}`,
        padding: `calc(${theme.margins.margin00} - 1px)`,
        zIndex: 1
    },
    searchResultsSectorsRow: {
        display: 'flex',
        cursor: 'pointer',
        fontSize: theme.fonts.fontSizes.size02,
        marginBottom: theme.margins.margin00
    },
    searchResultsSectorsRowHeader: {
        color: theme.colors.gray02,
        borderBottom: `1px solid ${theme.colors.gray01}`,
        marginBottom: theme.margins.margin00
    },
    searchResultsSubSectorsRow: {
        display: 'flex',
        cursor: 'pointer',
        fontSize: theme.fonts.fontSizes.size02,
        margin: `0 0 ${theme.margins.margin00} ${theme.margins.margin01}`
    },
    taggedInput: {
        marginTop: theme.margins.margin01
    },
    textArea: {
        display: 'flex',
        flexDirection: 'column',
        textarea: {
            height: 100
        }
    },
    tooltip: {
        ...theme.content.tooltipTextSmall,
        height: 'unset',
        padding: '10px 12px 12px',
        flexDirection: 'column',
        alignItems: 'flex-start'
    }
});
