import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';

import { DateDividerUI } from './ui';

export class DateDivider extends PureComponent {
    static displayName = 'DateDividerContainer';

    static propTypes = {
        onScrollToTop: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(XDate).isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    render() {
        const { styles, date, onScrollToTop } = this.props;
        return <DateDividerUI styles={styles} date={date} onScrollToTop={onScrollToTop} />;
    }
}

export const DateDividerContainer = compose()(DateDivider);
