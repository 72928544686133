import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';
import { styleSheet } from './stylesheet';

class CreateDashFromTermTooltip extends PureComponent {
    static propTypes = {
        isSubmitDisabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.string,
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        passedStyles: {},
        placeholder: 'Enter monitor name...',
        value: '',
        xOffset: 0,
        yOffset: -30
    };

    render() {
        const {
            children,
            isSubmitDisabled,
            loading,
            onChange,
            onSubmit,
            passedStyles,
            placeholder,
            styles,
            value,
            xOffset,
            yOffset
        } = this.props;
        return (
            <Tooltip
                content={({ hideTooltip }) => (
                    <TooltipForm
                        buttonDisabled={isSubmitDisabled}
                        inputName="name"
                        loading={loading}
                        onCancel={hideTooltip}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        placeholder={placeholder}
                        styles={{ ...styles.container, ...passedStyles }}
                        submitButtonText="Create Monitor"
                        value={value}
                    />
                )}
                isEnabled
                useElementOffsetBottom
                useElementOffsetLeft
                useOutsideClickHandler
                xOffset={xOffset}
                yOffset={yOffset}
            >
                {children}
            </Tooltip>
        );
    }
}

export const CreateDashFromTermTooltipUI = compose(withStyleSheet(styleSheet))(CreateDashFromTermTooltip);
