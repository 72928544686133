import gql from 'graphql-tag';
import { compose, lifecycle } from 'recompose';
import { get } from 'utils';
import { withRealtime } from 'provider/realtime';
import { graphql } from 'graphql/utils';

export const withRealtimeHighlights = () =>
    compose(
        graphql(
            gql`
                query withOrgToken {
                    currentUser {
                        id
                        userId
                        organization {
                            id
                            pusherToken
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({ orgPusherToken: get(data, 'currentUser.organization.pusherToken') }),
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                // We may not have the pusher token on mount, so save a function
                // onto the component that we can either call here or in componentDidUpdate
                // to subscribe as soon as we have the token.
                this.trySubscribe = () => {
                    if (!this.subscriptions) {
                        const { orgPusherToken, realtime } = this.props;
                        if (orgPusherToken) {
                            this.subscriptions = [
                                realtime.subscribe(`organization_${orgPusherToken}`, 'changed_objects', () => {
                                    const { refreshHighlights } = this.props;
                                    if (refreshHighlights) refreshHighlights();
                                })
                            ];
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate({ streams: prevStreams }) {
                const { refetch, streams } = this.props;
                if (refetch && prevStreams && prevStreams !== streams) {
                    refetch();
                }
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        })
    );
