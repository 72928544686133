import { keyframes } from '@emotion/react';

const slideInFrames = keyframes`
  0%   { transform: translateX(100%) }
  100%   { transform: translateX(0) }
`;

const minimize = keyframes`
  0%   { transform: translateY(0) }
  40%   { transform: translateY(calc(100% + 18px)) }
  85%   { transform: translateY(calc(100% + 18px)) scale(0.2, 1) translateX(20%) }
  100%   { transform: translateY(calc(100% + 18px)) translateX(150%) scale(0.2, 1) }
`;

const container = {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    maxWidth: '1700px',
    flexDirection: 'row-reverse',
    top: 46,
    right: 0,
    bottom: 48,
    zIndex: 4
};
export const styleSheet = theme => ({
    container: {
        ...container,
        '@media(max-width: 1870px)': {
            maxWidth: '100%'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            bottom: 0,
            top: 0,
            width: '100%',
            maxWidth: '100%',
            WebkitAnimation: `${slideInFrames} 0.2s 1`,
            animation: `${slideInFrames} 0.2s 1`
        },
        '@supports(-webkit-overflow-scrolling: touch)': {
            maxHeight: 'calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))'
        }
    },
    containerFullScreen: {
        ...container,
        top: 0,
        zIndex: 4
    },
    floatingTabMinimizeAnimation: {
        '.tab-wrapper': {
            WebkitAnimation: `${minimize} 0.7s 1 forwards`,
            animation: `${minimize} 0.7s 1 forwards`
        },
        '.tab-maximized': {
            '> *': {
                display: 'none'
            }
        },
        '.tab-content': {
            backgroundColor: theme.colors.white01,
            '> *': {
                display: 'none'
            }
        },
        '.tab-outside': {
            display: 'none',
            backgroundColor: 'transparent'
        }
    },
    floatingTab: {
        width: '100%',
        height: '100%',
        zIndex: 8,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flex: 1,
        borderRadius: '6px 0 0 6px',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.3)',
        ':first-of-type': {
            boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.3)'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            ':first-of-type': {
                boxShadow: 'unset'
            },
            borderRadius: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            marginLeft: 0
        },
        '@supports(-webkit-overflow-scrolling: touch)': {
            top: 'env(safe-area-inset-top)'
        },
        '@media(max-width: 1190px)': {
            boxShadow: 'unset'
        },
        '@media(min-width: 1200px, max-width: 1400px)': {
            width: 'calc(100% - 250px)'
        }
    },
    floatingTabFullScreen: {
        borderRadius: 6,
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 48,
        top: 8,
        height: 'unset',
        margin: 'auto',
        zIndex: 10
    },
    floatingTabFullScreenContainer: {
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 40,
        width: '100%',
        overflow: 'auto',
        zIndex: 9
    },
    page: {
        backgroundColor: theme.colors.white01,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        flexGrow: 1,
        flexShrink: 0,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            WebkitOverflowScrolling: 'touch',
            overflowY: 'scroll'
        }
    },
    floatingTabEventFocused: {
        maxWidth: '650px'
    },
    floatingTabEvent: {
        maxWidth: '1000px'
    },
    floatingTabReport: {
        maxWidth: 760
    },
    floatingTabNews: {
        maxWidth: 960
    },
    floatingTabCustomData: {
        maxWidth: 660
    },
    floatingTabSpotlight: {
        maxWidth: 660
    },
    floatingTabPDF: {
        maxWidth: 1200
    },
    floatingTabFiling: {
        maxWidth: 1060
    },
    floatingTabStreetAccount: {
        maxWidth: '660px'
    },
    floatingTabEquity: {
        maxWidth: '760px'
    },
    floatingExit: {
        display: 'none',
        [`@media(max-width: 1600px)`]: {
            display: 'flex',
            cursor: 'pointer',
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: '170px',
            zIndex: 1,
            transition: 'all 0.1s ease-in-out',
            opacity: 0,
            color: theme.colors.white01,
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': {
                opacity: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }
        }
    },
    spacer: {
        display: 'flex',
        flex: 1
    },
    toolbarControls: {
        display: 'flex',
        alignItems: 'center',
        padding: '0',
        svg: {
            marginRight: theme.margins.margin00
        }
    },
    toolbar: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.gray03,
        borderBottom: `1px solid ${theme.colors.gray01}`,
        height: '34px',
        display: 'flex',
        alignItems: 'center'
    },
    toolbarMinimized: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.gray03,
        borderBottom: `1px solid ${theme.colors.gray01}`,
        height: '34px',
        display: 'flex',
        alignItems: 'center'
    },
    tabBar: {
        position: 'fixed',
        bottom: 0,
        left: '190px',
        padding: '0 20px',
        alignItems: 'center',
        display: 'flex',
        height: '40px',
        backgroundColor: theme.colors.purple02,
        zIndex: 8,
        color: theme.colors.white01
    },
    scrolledHeader: {
        transition: 'all 0.4s ease-in-out',
        zIndex: 4,
        position: 'absolute',
        top: '34px',
        left: 0,
        right: 0,
        width: '100%',
        height: '45px',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            top: '0px'
        }
    },
    scrolledHeaderHidden: {
        transition: 'all 0.4s ease-in-out',
        zIndex: 1,
        position: 'absolute',
        top: '-12px',
        left: 0,
        right: 0,
        width: '100%',
        height: '45px',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            top: '-45px'
        }
    },
    tabLoader: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    footerSpacer: {
        color: 'transparent',
        display: 'inline-block',
        fontSize: theme.fonts.fontSizes.size01
    }
});
