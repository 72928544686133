import { keyframes } from '@emotion/react';

const skbouncedelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const styleSheet = theme => {
    const bounces = {
        '.bounce1': {
            WebkitAnimationDelay: '-0.32s',
            animationDelay: '-0.32s'
        },
        '.bounce2': {
            WebkitAnimationDelay: '-0.16s',
            animationDelay: '-0.16s',
            margin: '0 0 0 6px'
        },
        '.bounce3': {
            WebkitAnimationDelay: '-0.08s',
            animationDelay: '-0.08s',
            margin: '0 6px'
        }
    };

    return {
        container: {
            margin: '0 auto',
            textAlign: 'center',
            '> div': {
                width: '4px',
                height: '12px',
                backgroundImage: theme.colors.gradient01,
                borderRadius: '30%',
                display: 'inline-block',
                WebkitAnimation: `${skbouncedelay} 1.4s infinite ease-in-out both`,
                animation: `${skbouncedelay} 1.4s infinite ease-in-out both`
            },
            '.bounce1': {
                WebkitAnimationDelay: '-0.75s',
                animationDelay: '-0.75s'
            },
            '.bounce2': {
                WebkitAnimationDelay: '-0.50s',
                animationDelay: '-0.50s',
                margin: '0 0 0 6px'
            },
            '.bounce3': {
                WebkitAnimationDelay: '-0.25s',
                animationDelay: '-0.25s',
                margin: '0 6px'
            }
        },
        containerLight: {
            margin: '0 auto',
            textAlign: 'center',
            '> div': {
                width: '4px',
                height: '12px',
                backgroundColor: theme.colors.white01,
                borderRadius: '30%',
                display: 'inline-block',
                WebkitAnimation: `${skbouncedelay} 1.4s infinite ease-in-out both`,
                animation: `${skbouncedelay} 1.4s infinite ease-in-out both`
            },
            ...bounces
        },
        containerGray: {
            margin: '0 auto',
            textAlign: 'center',
            '> div': {
                width: '4px',
                height: '12px',
                backgroundColor: theme.colors.gray05,
                borderRadius: '30%',
                display: 'inline-block',
                WebkitAnimation: `${skbouncedelay} 1.4s infinite ease-in-out both`,
                animation: `${skbouncedelay} 1.4s infinite ease-in-out both`
            },
            ...bounces
        }
    };
};
