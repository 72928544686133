const defaultTextColor = '#333333';
const defaultFontSize = 12;

export const chart = {
    background: 'transparent',
    axis: {
        domain: {
            line: {
                stroke: '#E5E7EF',
                strokeWidth: 1
            }
        },
        ticks: {
            line: {
                stroke: 'transparent',
                strokeWidth: 0
            },
            text: {
                fill: defaultTextColor,
                fontSize: defaultFontSize,
                fontFamily: "'Rubik',sans-serif"
            }
        },
        legend: {
            text: {
                fill: defaultTextColor,
                fontSize: defaultFontSize,
                fontFamily: "'Rubik',sans-serif"
            }
        }
    },
    grid: {
        line: {
            stroke: '#F2F2F4',
            strokeWidth: 1
        }
    },
    legends: {
        text: {
            fill: defaultTextColor,
            fontSize: defaultFontSize,
            textTransform: 'capitalize',
            fontFamily: "'Rubik',sans-serif"
        }
    },
    labels: {
        text: {
            fill: defaultTextColor,
            fontSize: defaultFontSize,
            fontFamily: "'Rubik',sans-serif"
        }
    },
    markers: {
        lineColor: '#000',
        lineStrokeWidth: 1,
        textColor: defaultTextColor,
        fontSize: defaultFontSize
    },
    dots: {
        text: {
            fill: defaultTextColor,
            fontSize: defaultFontSize
        }
    },
    tooltip: {
        container: {
            background: 'white',
            color: 'inherit',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px',
            textTransform: 'capitalize'
        },
        basic: {
            whiteSpace: 'pre',
            display: 'flex',
            alignItems: 'center'
        },
        table: {},
        tableCell: {
            padding: '3px 5px'
        }
    }
};
