import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

export const RawHTML = ({ className, html }) => (
    // eslint-disable-next-line react/no-danger
    <div className={className} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
);

RawHTML.propTypes = {
    className: PropTypes.string,
    html: PropTypes.string.isRequired
};

RawHTML.defaultProps = {
    className: null
};
