import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';
import { LiveEventsStreamUI } from './ui';

export class LiveEventsStream extends PureComponent {
    static displayName = 'LiveEventsStreamContainer';

    static propTypes = {
        addEvent: PropTypes.func.isRequired,
        aieracastEventIds: PropTypes.string,
        dashboardId: PropTypes.string.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        onSearch: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        removeEvent: PropTypes.func.isRequired,
        searchTerm: PropTypes.string,
        selectedEventIds: PropTypes.instanceOf(Set),
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string
    };

    static defaultProps = {
        aieracastEventIds: null,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        searchTerm: '',
        selectedEventIds: new Set(),
        streamId: null,
        styles: undefined,
        subtitle: undefined
    };

    componentDidMount() {
        const { addEvent, aieracastEventIds, history, pathname } = this.props;
        if (aieracastEventIds) {
            aieracastEventIds
                .split(',')
                .map(eventId => () => addEvent({ eventId }))
                .reduce((m, p) => m.then(p), Promise.resolve())
                .then(() => {
                    history.replace(pathname);
                });
        }
    }

    render() {
        const {
            addEvent,
            dashboardId,
            loadMoreMatches,
            loading,
            matches,
            onSearch,
            removeEvent,
            searchTerm,
            selectedEventIds,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;
        return (
            <LiveEventsStreamUI
                addEvent={addEvent}
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                onSearch={onSearch}
                removeEvent={removeEvent}
                searchTerm={searchTerm}
                selectedEventIds={selectedEventIds}
                streamDisplayMode={streamDisplayMode}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
            />
        );
    }
}

export const LiveEventsStreamContainer = compose(
    withStateHandlers(
        () => ({
            searchTerm: ''
        }),
        {
            onSearch: () => ({ value: searchTerm }) => {
                return {
                    searchTerm
                };
            }
        }
    ),
    withUrlContext(['aieracastEventIds', 'history', 'pathname']),
    withData()
)(LiveEventsStream);
