import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const Svg = styled('svg')(props => ({
    ...props.style,
    path: {
        transition: 'all 0.2s ease-in-out'
    }
}));

export class Logo extends PureComponent {
    static propTypes = {
        styles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        markColor: PropTypes.string,
        color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        small: PropTypes.bool,
        height: PropTypes.number,
        link: PropTypes.string,
        width: PropTypes.number
    };

    static defaultProps = {
        styles: {},
        color: '#FFFFFF',
        small: false,
        height: undefined,
        link: undefined,
        width: undefined,
        markColor: '#EE5F1E'
    };

    render() {
        const { color, styles, small, height: hOverride, width: wOVerride, link, markColor } = this.props;
        const width = wOVerride || (small ? '93px' : '379px');
        const height = hOverride || (small ? '26px' : '104px');
        const logoMark = (
            <Svg width={width} height={height} viewBox="0 0 379 104" style={styles}>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group" transform="translate(2.000000, 1.000000)">
                        <path
                            d="M90.1818968,68.0767086 C90.1835878,73.8960136 87.079902,79.2739883 82.040957,82.184909 L53.2411884,98.8168093 C48.2022434,101.726885 41.9940265,101.72773 36.954236,98.8193457 L8.15024002,82.1950546 C3.11129505,79.2858248 0.00591839664,73.909541 0.00507293608,68.0910814 L0,34.8315082 C0,29.0130486 3.10284045,23.6367649 8.14178542,20.7283805 L39.7831471,2.45966864 C46.9754801,-1.69238819 55.9644168,3.5088852 55.9475076,11.8138443 L55.8646525,52.4120152 C55.8578888,55.6585838 54.1246946,58.656587 51.3143837,60.2815622 L29.8286944,72.7106779 C24.2131453,75.9589374 17.1865225,71.9074904 17.1848316,65.4202715 L17.1772225,43.9506459 C17.1755316,38.6250898 20.0154336,33.7028184 24.627421,31.0387721 L56.8470777,12.4268032 C64.032647,8.27559187 73.016511,13.4624924 73.0139746,21.7606879 L73.0012927,58.9575709 C72.9996018,64.2763633 70.163927,69.1910256 65.5595488,71.8550718 L46.9839347,82.6017211 C41.3675402,85.850826 34.3409174,81.7985336 34.3392265,75.3104692 L34.3349992,50.5105744 C34.3341537,47.2538603 36.0715752,44.2448662 38.8911862,42.6165091 L74.0243,22.3305283 C81.2090239,18.1818533 90.1903514,23.3687539 90.1886605,31.6652584 L90.1818968,68.0767086 Z"
                            id="Stroke-2"
                            stroke={markColor}
                            strokeWidth="2.905"
                        />
                        <path
                            d="M147.8141,23.0093778 C154.1781,23.0093778 174.109516,27.5479767 176.162136,47.7294197 C177.06179,56.5748582 177.550445,67.0693108 177.6281,79.2127775 L164.9391,79.2127775 L164.9391,73.4857775 C159.7361,78.3517775 154.1401,80.7837775 148.1511,80.7837775 C140.5901,80.7837775 134.3391,78.0517775 129.3981,72.5867775 C124.4951,67.0097775 122.0431,60.0477775 122.0431,51.7007775 C122.0431,43.5037775 124.4951,36.6717775 129.3981,31.2067775 C134.3021,25.7427775 140.4401,23.0093778 147.8141,23.0093778 Z M148.746682,36.4357775 C140.186268,36.4357775 134.231902,43.3753639 134.231902,51.9357775 C134.231902,60.4961911 140.186268,67.4357775 148.746682,67.4357775 C157.307095,67.4357775 163.413719,60.4961911 163.413719,51.9357775 C163.413719,43.3753639 157.307095,36.4357775 148.746682,36.4357775 Z"
                            id="Fill-13"
                            fill={color}
                        />
                        <path
                            d="M346.8141,23.0093778 C353.1781,23.0093778 373.109516,27.5479767 375.162136,47.7294197 C376.06179,56.5748582 376.550445,67.0693108 376.6281,79.2127775 L363.9391,79.2127775 L363.9391,73.4857775 C358.7361,78.3517775 353.1401,80.7837775 347.1511,80.7837775 C339.5901,80.7837775 333.3391,78.0517775 328.3981,72.5867775 C323.4951,67.0097775 321.0431,60.0477775 321.0431,51.7007775 C321.0431,43.5037775 323.4951,36.6717775 328.3981,31.2067775 C333.3021,25.7427775 339.4401,23.0093778 346.8141,23.0093778 Z M347.746682,36.4357775 C339.186268,36.4357775 333.231902,43.3753639 333.231902,51.9357775 C333.231902,60.4961911 339.186268,67.4357775 347.746682,67.4357775 C356.307095,67.4357775 362.413719,60.4961911 362.413719,51.9357775 C362.413719,43.3753639 356.307095,36.4357775 347.746682,36.4357775 Z"
                            id="Fill-13"
                            fill={color}
                        />
                        <path
                            d="M193.5739,79.2127775 L206.2069,79.2127775 L206.2069,24.5257775 L193.5739,24.5257775 L193.5739,79.2127775 Z M191.6649,8.9537775 C191.6649,6.7457775 192.4689,4.8367775 194.0789,3.2267775 C195.6879,1.6167775 197.6159,0.812777498 199.8619,0.812777498 C202.1449,0.812777498 204.0909,1.6167775 205.7019,3.2267775 C207.3109,4.7987775 208.1159,6.7267775 208.1159,9.0097775 C208.1159,11.2937775 207.3109,13.2397775 205.7019,14.8487775 C204.1299,16.4587775 202.2019,17.2637775 199.9189,17.2637775 C197.6349,17.2637775 195.6879,16.4587775 194.0789,14.8487775 C192.4689,13.2397775 191.6649,11.2747775 191.6649,8.9537775 L191.6649,8.9537775 Z"
                            id="Fill-14"
                            fill={color}
                        />
                        <path
                            d="M259.0406,44.5137775 C257.2806,37.7767775 253.0326,34.4077775 246.2956,34.4077775 C244.7606,34.4077775 243.3186,34.6417775 241.9726,35.1097775 C240.6246,35.5767775 239.3976,36.2507775 238.2946,37.1307775 C237.1896,38.0107775 236.2446,39.0677775 235.4586,40.3027775 C234.6726,41.5377775 234.0736,42.9417775 233.6616,44.5137775 L259.0406,44.5137775 Z M272.0106,54.8447775 L232.8196,54.8447775 C233.1566,59.3367775 234.6166,62.9107775 237.1996,65.5687775 C239.7816,68.1887775 243.0946,69.4987775 247.1376,69.4987775 C250.2816,69.4987775 252.8836,68.7507775 254.9416,67.2527775 C256.564193,66.0508867 258.368561,64.0284274 260.353153,61.1859172 C260.474651,61.0118956 264.528801,62.2481824 272.5156,64.8947775 C270.8686,67.7017775 269.1276,70.1077775 267.2936,72.1097775 C265.4596,74.1127775 263.4946,75.7597775 261.3976,77.0507775 C259.3026,78.3417775 257.0376,79.2867775 254.6046,79.8867775 C252.1716,80.4847775 249.5326,80.7847775 246.6876,80.7847775 C238.5276,80.7847775 231.9786,78.1637775 227.0366,72.9237775 C222.0966,67.6457775 219.6256,60.6467775 219.6256,51.9257775 C219.6256,43.2787775 222.0206,36.2787775 226.8116,30.9267775 C231.6416,25.6487775 238.0416,23.0097775 246.0146,23.0097775 C254.0616,23.0097775 260.4256,25.5737775 265.1036,30.7017775 C269.7456,35.7927775 272.0656,42.8477775 272.0656,51.8687775 L272.0106,54.8447775 Z"
                            id="Fill-15"
                            fill={color}
                        />
                        <path
                            d="M285.4292,24.5259775 L298.0622,24.5259775 L298.0622,29.4109775 C302.9632,23.0099775 314.2062,20.5869775 322.5152,26.7589775 L315.1812,36.8909775 C306.1552,31.9279775 298.0622,36.1509775 298.0622,50.8589775 L298.0622,79.2129775 L285.4292,79.2129775 L285.4292,24.5259775 Z"
                            id="Fill-17"
                            fill={color}
                        />
                    </g>
                </g>
            </Svg>
        );

        if (link) {
            return <Link to={link}>{logoMark}</Link>;
        }

        return logoMark;
    }
}
