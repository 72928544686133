import gql from 'graphql-tag';
import { compose } from 'recompose';
import { watchlistFragment } from 'graphql/fragments/watchlists';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withWatchlistIcalModal($watchlistId: ID) {
                    currentUser {
                        id
                        icalToken
                        watchlists(ids: [$watchlistId]) {
                            ...watchlist
                        }
                    }
                }
                ${watchlistFragment}
            `,
            {
                props: ({ data }) => ({
                    icalToken: get(data, 'currentUser.icalToken'),
                    loading: get(data, 'loading'),
                    watchlist: get(data, 'currentUser.watchlists[0]')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
