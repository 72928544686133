import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ExternalLayoutUI } from './ui';

export class ExternalLayout extends PureComponent {
    static displayName = 'ExternalLayoutContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ExternalLayoutUI {...rest} />;
    }
}

const connectedComponent = connect()(ExternalLayout);

export const ExternalLayoutContainer = compose()(connectedComponent);
