import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withLiveEventRow {
                    currentUser {
                        id
                    }
                }
            `,
            {
                props: () => ({}),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
