import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose, withStateHandlers } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { get, generateModalId } from 'utils';
import { withData } from './data';
import { DashboardGalleryUI } from './ui';

function getCurrentDashboard(dashboards = [], id) {
    return dashboards.find(d => d.id === id);
}

export class DashboardGallery extends PureComponent {
    static displayName = 'DashboardGalleryContainer';

    static propTypes = {
        currentDashboardId: PropTypes.string,
        dashboards: PropTypes.arrayOf(PropTypes.object),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        onClose: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.object),
        setCurrentDashboard: PropTypes.func.isRequired,
        streams: PropTypes.arrayOf(PropTypes.object),
        streamsLoading: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        currentDashboardId: null,
        dashboards: [],
        loading: false,
        sections: undefined,
        streams: [],
        streamsLoading: false,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.getCurrentDashboard = memoize(getCurrentDashboard);
        this.showUpgradeModal = this.showUpgradeModal.bind(this);
    }

    // Select the first section's dashboard when mounted with existing sections (e.g. user goes back in the browser)
    componentDidMount() {
        const { dashboards, setCurrentDashboard } = this.props;
        document.title = 'Aiera | Gallery';
        setCurrentDashboard(get(dashboards, '[0].id', null));
    }

    componentDidUpdate(prevProps) {
        const { dashboards: prevDashboards, searchTerm: prevSearch, sections: prevSections } = prevProps;
        const { currentDashboardId, dashboards, searchTerm, sections, setCurrentDashboard } = this.props;

        // Once sections load or if the search is cleared, select the first section's dashboard by default
        if ((!prevSections && sections) || (prevSearch.length && !searchTerm.length)) {
            setCurrentDashboard(get(dashboards, '[0].id', null));
        }

        // If the user follows an equity dashboard, the server will clone it and return a new dashboard
        // We are manually updating the gallery dashboards in the Apollo cache in the AddMonitorButton component
        // So, we need to find the newly cloned dashboard and set it as current
        if (!dashboards.some(d => d.id === currentDashboardId)) {
            const prevDash = prevDashboards.find(d => d.id === currentDashboardId);
            if (prevDash) {
                const equityId = get(prevDash, 'equity.id');
                const newDash = dashboards.find(d => get(d, 'equity.id') === equityId);
                if (newDash) {
                    setCurrentDashboard(newDash.id);
                }
            }
        }
    }

    showUpgradeModal(id) {
        const { history, location } = this.props;
        history.push(generateModalId({ location, id, type: 'upgrade' }));
    }

    render() {
        const {
            currentDashboardId,
            dashboards,
            loading,
            location,
            onClose,
            onKeyDown,
            onSearch,
            searchTerm,
            sections,
            setCurrentDashboard,
            streams,
            streamsLoading,
            styles
        } = this.props;
        return (
            <DashboardGalleryUI
                currentDashboard={this.getCurrentDashboard(dashboards, currentDashboardId)}
                customDashFormUrl={generateModalId({ location, id: 'new', type: 'dashboard' })}
                loading={loading}
                onClickDashboard={setCurrentDashboard}
                onClose={onClose}
                onKeyDown={onKeyDown}
                onSearch={onSearch}
                searchTerm={searchTerm}
                sections={sections}
                showUpgradeModal={this.showUpgradeModal}
                streams={streams}
                streamsLoading={streamsLoading}
                styles={styles}
            />
        );
    }
}

export const DashboardGalleryContainer = compose(
    withStateHandlers(
        {
            currentDashboardId: null,
            searchTerm: ''
        },
        {
            onKeyDown: () => event => {
                const { key } = event;

                if (key === 'Escape') {
                    return { searchTerm: '' };
                }

                return {};
            },
            onSearch: () => ({ value: searchTerm }) => ({ searchTerm }),
            setCurrentDashboard: () => currentDashboardId => ({ currentDashboardId })
        }
    ),
    withUrlContext(['history', 'location']),
    withData()
)(DashboardGallery);
