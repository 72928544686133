import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import XDate from 'xdate';
import { withStyleSheet } from 'hoc/styles';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { STREAM_DISPLAY_MODES } from 'consts';
import { styleSheet } from './stylesheet';

class EventGroupCard extends PureComponent {
    static propTypes = {
        displayMode: PropTypes.string.isRequired,
        eventGroupTitle: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hostEquityLocalTicker: PropTypes.string,
        hostEquityName: PropTypes.string,
        hostEquityExchangeName: PropTypes.string,
        numEvents: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.object),
        onClick: PropTypes.func.isRequired,
        to: PropTypes.string.isRequired
    };

    static defaultProps = {
        eventGroupTitle: null,
        startDate: null,
        endDate: null,
        hostEquityLocalTicker: null,
        hostEquityName: null,
        hostEquityExchangeName: null,
        numEvents: 0,
        passedStyles: {}
    };

    render() {
        const {
            displayMode,
            styles,
            passedStyles,
            eventGroupTitle,
            startDate,
            endDate,
            hostEquityLocalTicker,
            hostEquityName,
            hostEquityExchangeName,
            numEvents,
            onClick,
            to
        } = this.props;
        const isCompact = displayMode === STREAM_DISPLAY_MODES.compact;
        const containerStyles = isCompact ? styles.compact : styles.container;
        const formattedStart = new XDate(startDate).toString('MM/dd/yyyy');
        const formattedEnd = new XDate(endDate).toString('MM/dd/yyyy');
        if (displayMode === STREAM_DISPLAY_MODES.minimal) {
            return (
                <Card
                    containerStyles={styles.containerMinimal}
                    styles={{ ...styles.minimal, ...passedStyles }}
                    name="event-group-card"
                    onClick={onClick}
                    to={to}
                >
                    <Div styles={styles.minimalContent}>
                        <Text styles={styles.minimalDate} size={1}>
                            {formattedStart === formattedEnd ? formattedStart : `${formattedStart} – ${formattedEnd}`}
                        </Text>
                        <Text styles={styles.minimalTitle} size={2} weight="medium">
                            {eventGroupTitle}
                        </Text>
                        <Text styles={styles.minimalDetails} size={1}>
                            {pluralize('Presentation', numEvents, true)},{' '}
                            {hostEquityLocalTicker ? `Hosted by ${hostEquityLocalTicker}` : undefined}
                        </Text>
                    </Div>
                </Card>
            );
        }

        return (
            <Card
                containerStyles={isCompact ? styles.containerCompact : undefined}
                styles={{ ...containerStyles, ...passedStyles }}
                name="event-group-card"
                onClick={onClick}
                to={to}
            >
                <CardHeader
                    title={eventGroupTitle}
                    context={hostEquityLocalTicker}
                    secondaryContext={hostEquityExchangeName}
                    subtitle={
                        displayMode === STREAM_DISPLAY_MODES.default && hostEquityName
                            ? `Hosted by ${hostEquityName}`
                            : undefined
                    }
                    subtext={pluralize('Presentation', numEvents, true)}
                />
                <Div styles={styles.dateTime}>
                    <Text size={0}>
                        {formattedStart === formattedEnd ? formattedStart : `${formattedStart} – ${formattedEnd}`}
                    </Text>
                </Div>
            </Card>
        );
    }
}

export const EventGroupCardUI = compose(withStyleSheet(styleSheet))(EventGroupCard);
