import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withAudioCalls, withUserFinancialKpis } from 'graphql/audioCalls';
import { get, isEventComplete } from 'utils';
import { normalizeFinancials } from './selectors';
import { EventDifferentialsUI } from './ui';

export class EventDifferentials extends PureComponent {
    static displayName = 'EventDifferentialsContainer';

    static propTypes = {
        addFinancial: PropTypes.func.isRequired,
        callType: PropTypes.string,
        eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        equityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        financials: PropTypes.arrayOf(PropTypes.any),
        financialColumnLabels: PropTypes.arrayOf(PropTypes.any),
        financialKpis: PropTypes.arrayOf(PropTypes.any),
        isComplete: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired
        }).isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        removeFinancial: PropTypes.func.isRequired,
        replaceFinancial: PropTypes.func.isRequired,
        ticker: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        shareId: PropTypes.string
    };

    static defaultProps = {
        callType: null,
        eventId: null,
        equityId: null,
        financials: [],
        financialColumnLabels: [],
        financialKpis: [],
        loading: false,
        ticker: null,
        shareId: null
    };

    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.onAddFinancial = this.onAddFinancial.bind(this);
        this.onReplaceFinancial = this.onReplaceFinancial.bind(this);
        this.onRemoveFinancial = this.onRemoveFinancial.bind(this);
        this.onShowNewTermForm = this.onShowNewTermForm.bind(this);
        this.onHideNewTermForm = this.onHideNewTermForm.bind(this);
        this.onSaveNewTerm = this.onSaveNewTerm.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
        this.onSearchFinancials = this.onSearchFinancials.bind(this);
        this.expandRow = this.expandRow.bind(this);
        this.collapseRow = this.collapseRow.bind(this);
        this.generateFinancialSearchResults = this.generateFinancialSearchResults.bind(this);
        this.handleFinancialMenuRef = this.handleFinancialMenuRef.bind(this);

        this.newEle = React.createRef();
        this.switchBtns = {};

        this.state = {
            expandedRowTitle: null,
            showMenu: null,
            showNewTermForm: false,
            searchTerm: '',
            kpiId: null
        };
    }

    handleFinancialMenuRef(node, title) {
        if (node) {
            this.switchBtns[title] = {
                node,
                title,
                offset: node.offsetParent && node.offsetParent.offsetTop
            };
        }
    }

    onSelectOption(option) {
        const { showMenu, kpiId } = this.state;
        if (showMenu === 'new') {
            this.onAddFinancial(option);
        } else if (kpiId) {
            this.onReplaceFinancial(kpiId, option);
        }
    }

    onAddFinancial({ key }) {
        const { addFinancial, equityId, eventId } = this.props;
        if (addFinancial && key) {
            addFinancial({ financialTermKey: key, eventId, equityId }).finally(() => {
                this.setState({
                    searchTerm: '',
                    showMenu: null
                });
            });
        }
    }

    onRemoveFinancial() {
        const { removeFinancial, eventId } = this.props;
        const { kpiId } = this.state;
        if (kpiId && removeFinancial) {
            removeFinancial({ userFinancialKpiId: kpiId, eventId }).finally(() => {
                this.setState({
                    searchTerm: '',
                    showMenu: null
                });
            });
        }
    }

    onReplaceFinancial(userFinancialKpiId, { key }) {
        const { replaceFinancial, eventId } = this.props;
        if (replaceFinancial && userFinancialKpiId && key) {
            replaceFinancial({ userFinancialKpiId, financialTermKey: key, eventId }).finally(() => {
                this.setState({
                    searchTerm: '',
                    showMenu: null
                });
            });
        }
    }

    onSearchFinancials(searchTerm) {
        this.setState({
            searchTerm
        });
    }

    generateFinancialSearchResults() {
        const { financialKpis } = this.props;
        const { searchTerm } = this.state;
        const normalizedTerm = searchTerm.toLowerCase();
        return financialKpis.filter(k => {
            const synonyms = get(k, 'synonyms', []);
            const title = get(k, 'title');
            let hasMatch = title.toLowerCase().includes(normalizedTerm);
            // If no match yet, search synonyms.
            // exit subsearch on finding match
            if (!hasMatch) {
                for (let i = 0; i < synonyms.length - 1; i += 1) {
                    hasMatch = synonyms[i].toLowerCase().includes(normalizedTerm);
                    if (hasMatch) {
                        break;
                    }
                }
            }

            return hasMatch;
        });
    }

    toggleMenu(showMenu, kpiId) {
        this.setState({
            showMenu,
            kpiId: showMenu === 'new' ? null : kpiId
        });
    }

    expandRow(expandedRowTitle) {
        this.setState({
            expandedRowTitle,
            showNewTermForm: false
        });
    }

    collapseRow() {
        this.setState({ expandedRowTitle: null });
    }

    onShowNewTermForm() {
        this.setState({
            showNewTermForm: true,
            expandedRowTitle: null
        });
    }

    onHideNewTermForm() {
        this.setState({
            showNewTermForm: false
        });
    }

    onSaveNewTerm() {
        this.setState({
            showNewTermForm: false
        });
    }

    render() {
        const { expandedRowTitle, showMenu, showNewTermForm } = this.state;
        const {
            callType,
            equityId,
            eventId,
            financialColumnLabels,
            financials,
            isComplete,
            loading,
            onNodeSelect,
            ticker,
            shareId
        } = this.props;

        return (
            <EventDifferentialsUI
                callType={callType}
                collapseRow={this.collapseRow}
                equityId={equityId}
                eventId={eventId}
                expandRow={this.expandRow}
                expandedRowTitle={expandedRowTitle}
                financialColumnLabels={financialColumnLabels}
                financials={financials}
                handleFinancialMenuRef={this.handleFinancialMenuRef}
                isComplete={isComplete}
                loading={loading}
                menuOffset={this.switchBtns[showMenu] && this.switchBtns[showMenu].offset}
                menuResults={this.generateFinancialSearchResults()}
                onHideNewTermForm={this.onHideNewTermForm}
                onMonitorsChartDataPointClick={this.handleMonitorsChartDataPointClick}
                onNodeSelect={onNodeSelect}
                onRemoveFinancial={this.onRemoveFinancial}
                onSaveNewTerm={this.onSaveNewTerm}
                onSearchFinancials={this.onSearchFinancials}
                onSelectOption={this.onSelectOption}
                onShowNewTermForm={this.onShowNewTermForm}
                shareId={shareId}
                showMenu={showMenu}
                showNewTermForm={showNewTermForm}
                ticker={ticker}
                toggleHiddenMonitors={this.toggleHiddenMonitors}
                toggleMenu={this.toggleMenu}
            />
        );
    }
}

export const EventDifferentialsContainer = compose(
    withUrlContext(['audioCallId', 'tabs', 'streamId', 'index']),
    withAudioCalls({
        alias: 'withAudioCallsEventDifferentials',
        variables: { withDifferentials: true }
    }),
    withUserFinancialKpis(),
    withPropsOnChange(['audioCall'], ({ audioCall }) => {
        const equityId = get(audioCall, 'equity.equityId');
        const financialKpis = get(audioCall, 'equity.financialKpis', []);
        const financials = get(audioCall, 'differentials.financials', []);
        const latestQuarterObj = get(financials, '[0].latestQuarter', {});
        const latestQuarterQ = get(latestQuarterObj, 'quarter', '');
        const quarterLabel = `${latestQuarterQ}QA`;
        const quarterEstimateLabel = `${latestQuarterQ}QE`;

        return {
            callType: get(audioCall, 'callType', null),
            isComplete: isEventComplete(get(audioCall, 'transcriptionStatus')),
            equityId,
            financialColumnLabels: [quarterLabel, quarterEstimateLabel, '%CHG', quarterLabel, quarterEstimateLabel],
            ...normalizeFinancials(financials, financialKpis)
        };
    })
)(EventDifferentials);
