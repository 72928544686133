import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { getNativePrice } from 'utils';
import { EquityCardUI } from './ui';

export class EquityCard extends PureComponent {
    static displayName = 'EquityCardContainer';

    static propTypes = {
        company: PropTypes.string,
        currency: PropTypes.objectOf(PropTypes.any),
        equityId: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        last: PropTypes.number,
        lastClose: PropTypes.number,
        marketCap: PropTypes.number,
        nextEarnings: PropTypes.string,
        pe: PropTypes.number,
        sector: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string
    };

    static defaultProps = {
        company: 'N/A',
        currency: {},
        equityId: null,
        last: null,
        lastClose: null,
        marketCap: null,
        nextEarnings: null,
        pe: null,
        sector: null,
        styles: {},
        ticker: 'N/A'
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { equityId, history } = this.props;
        if (equityId) {
            history.push(generatePath(routes.equity, { equityId }));
        }
    }

    render() {
        const { company, currency, last, lastClose, styles, ticker, sector, marketCap, nextEarnings, pe } = this.props;
        let price = null;
        let priceChange;
        let priceChangePercent;

        if (last && lastClose) {
            price = getNativePrice({ price: last, currency });
            priceChange = (last - lastClose).toFixed(2);
            priceChangePercent = `${((priceChange * 100) / lastClose).toFixed(2)}%`;
        }

        return (
            <EquityCardUI
                company={company}
                marketCap={marketCap}
                nextEarnings={nextEarnings}
                onClick={this.handleClick}
                pe={pe}
                price={price}
                priceChange={priceChange}
                priceChangePercent={priceChangePercent}
                sector={sector}
                styles={styles}
                ticker={ticker}
            />
        );
    }
}

export const EquityCardContainer = compose(withUrlContext(['history']))(EquityCard);
