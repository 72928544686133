export const styleSheet = theme => {
    const groupedContent = {
        ...theme.content.annotated,
        borderLeft: `1px solid ${theme.colors.gray02}`,
        borderRight: `1px solid ${theme.colors.gray02}`,
        backgroundColor: theme.colors.white02,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        padding: '8px 13px',
        ':last-of-type': {
            borderBottom: `1px solid ${theme.colors.gray02}`,
            borderRadius: '0 0 6px 6px',
            paddingBottom: '10px'
        }
    };
    return {
        containerCompact: {
            borderRadius: 'unset',
            width: 'unset',
            margin: 'unset'
        },
        actionMenu: {
            ...theme.content.cardActionMenu
        },
        compact: {
            borderRadius: 'unset',
            border: 'unset',
            borderBottom: `1px solid ${theme.colors.gray03}`,
            padding: '7px 13px 10px',
            '.title': {
                ...theme.content.annotated,
                fontWeight: 'unset'
            }
        },
        containerComfortable: {
            '.title': {
                ...theme.content.annotated,
                fontWeight: 'unset'
            }
        },
        container: {
            '.title': {
                ...theme.content.annotated
            }
        },
        content: {
            ...theme.content.annotated,
            fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
            color: theme.colors.gray08,
            lineHeight: '1.3em',
            margin: '12px -5px -3px',
            padding: '3px 5px',
            borderRadius: 3.5
        },
        title: {
            marginTop: '3px'
        },
        subTextCompact: {
            display: 'flex',
            marginTop: 3
        },
        subText: {
            display: 'flex',
            marginTop: 3
        },
        source: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.colors.gray04
        },
        date: {
            whiteSpace: 'nowrap',
            color: theme.colors.gray04
        },
        textDarkerGray: {
            color: theme.colors.gray06
        },
        groupedContent: {
            ...groupedContent,
            ':hover': {
                backgroundColor: theme.colors.blue07,
                cursor: 'pointer'
            }
        },
        groupedContentLocked: {
            ...groupedContent
        },
        groupedContentDetails: {
            display: 'flex',
            alignItems: 'center'
        },
        groupedContentIndex: {
            marginLeft: 'auto'
        },
        spacer: {
            flex: 1
        },
        locked: {
            position: 'absolute',
            right: 5,
            bottom: 6,
            padding: '2px 5px',
            borderRadius: 6,
            cursor: 'pointer',
            backgroundColor: theme.colors.white01,
            svg: {
                height: 10
            },
            ':hover': {
                backgroundColor: theme.colors.yellow07,
                'svg g': {
                    fill: theme.colors.black01
                }
            },
            ':active': {
                backgroundColor: theme.colors.yellow08
            }
        }
    };
};
