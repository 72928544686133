import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { DropdownUI } from './ui';

export class Dropdown extends PureComponent {
    static displayName = 'DropdownContainer';

    static propTypes = {
        cancelClassName: PropTypes.string,
        disableHoverOpen: PropTypes.bool,
        disabled: PropTypes.bool,
        hideOnScroll: PropTypes.bool,
        initialLabel: PropTypes.string,
        label: PropTypes.string,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.any),
        OptionComponent: PropTypes.elementType,
        placeholder: PropTypes.string,
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool])
    };

    static defaultProps = {
        cancelClassName: undefined,
        hideOnScroll: undefined,
        initialLabel: undefined,
        options: [],
        styles: {},
        label: null,
        multi: false,
        name: undefined,
        onChange: undefined,
        OptionComponent: null,
        placeholder: undefined,
        value: null,
        disabled: false,
        disableHoverOpen: false,
        size: undefined
    };

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.onHighlight = this.onHighlight.bind(this);
        this.onTooltipShow = this.onTooltipShow.bind(this);
        this.onTooltipHide = this.onTooltipHide.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleOptionNode = this.handleOptionNode.bind(this);

        this.hideTooltip = null;
        this.dropdownRef = React.createRef();
        this.optionNodes = [];

        this.state = {
            highlightedIndex: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { highlightedIndex } = this.state;
        const { options } = this.props;
        const prevOptions = get(prevProps, 'options', []);
        // Index starts at 1, due to placeholder
        const maxIndex = options.length;

        if (prevOptions.length !== options.length || highlightedIndex > maxIndex) {
            this.onHighlight(0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(e) {
        const { options } = this.props;
        const { highlightedIndex } = this.state;
        const keyCode = get(e, 'code');
        // Index starts at 1, due to placeholder
        const maxIndex = options.length;

        if (keyCode === 'ArrowDown' || keyCode === 'ArrowUp' || keyCode === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }

        if (keyCode === 'ArrowDown' && highlightedIndex < maxIndex) {
            const nextIndex = highlightedIndex + 1;
            const optionNode = this.optionNodes[nextIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }
            this.onHighlight(nextIndex);
        }

        if (keyCode === 'ArrowUp' && highlightedIndex > 0) {
            const nextIndex = highlightedIndex - 1;
            const optionNode = this.optionNodes[nextIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }
            this.onHighlight(nextIndex);
        }

        if (keyCode === 'Enter') {
            const value = get(options, `[${highlightedIndex - 1}].value`);
            this.onSelect(e, value);
            if (this.hideTooltip) {
                this.hideTooltip();
            }
        }
    }

    handleOptionNode(node, optionIndex) {
        if (typeof node === 'object') {
            this.optionNodes[optionIndex] = node;
        }
    }

    onTooltipShow({ hideTooltip }) {
        const { options, value } = this.props;

        window.addEventListener('keydown', this.handleKeyDown);

        if (!this.hideTooltip) {
            // Not sure if there is a better way to handle
            // but this gives the dropdown the ability to
            // hide the tooltip on command. This is used
            // for keyboard management, when an option is selected
            this.hideTooltip = hideTooltip;
        }

        if (value) {
            const nodeIndex = options.findIndex(o => o.value === value);
            const optionNode = this.optionNodes[nodeIndex];
            if (optionNode) {
                optionNode.scrollIntoView({ block: 'nearest' });
            }
        }
    }

    onTooltipHide() {
        window.removeEventListener('keydown', this.handleKeyDown);

        if (this.dropdownRef && this.dropdownRef.current) {
            this.dropdownRef.current.blur();
        }
    }

    onSelect(event, selected) {
        const { name, multi, onChange, value } = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (onChange) {
            let newValue = selected;
            if (multi) {
                const currentValue = Array.isArray(value) ? value : [];
                newValue = currentValue.includes(selected)
                    ? currentValue.filter(v => v !== selected)
                    : [...currentValue, selected];
            }
            onChange({
                name,
                event,
                value: newValue
            });
        }
        if (!multi && this.hideTooltip) {
            this.hideTooltip();
        }
    }

    onHighlight(highlightedIndex) {
        this.setState({
            highlightedIndex
        });
    }

    render() {
        const {
            cancelClassName,
            initialLabel,
            options,
            value: selectedValue,
            hideOnScroll,
            label,
            multi,
            name,
            OptionComponent,
            styles,
            placeholder,
            disabled,
            disableHoverOpen,
            size
        } = this.props;
        const { highlightedIndex } = this.state;
        const value = multi ? selectedValue || [] : [selectedValue];
        return (
            <DropdownUI
                cancelClassName={cancelClassName}
                disableHoverOpen={disableHoverOpen}
                disabled={disabled}
                handleDropdownRef={this.dropdownRef}
                handleOptionNode={this.handleOptionNode}
                hideOnScroll={hideOnScroll}
                highlightedIndex={highlightedIndex}
                label={label}
                name={name}
                onHighlight={this.onHighlight}
                onSelect={this.onSelect}
                onTooltipHide={this.onTooltipHide}
                onTooltipShow={this.onTooltipShow}
                options={options}
                OptionComponent={OptionComponent}
                placeholder={placeholder || initialLabel}
                selectedOptions={options.filter(({ value: v }) => value.includes(v))}
                size={size}
                styles={styles}
            />
        );
    }
}

export const DropdownContainer = compose()(Dropdown);
