import React, { Component } from 'react';
import { TabContext } from 'context';

export function withTabContext() {
    // Return a function that accepts a component
    // and then returns that component with
    // some decorated props ya herd
    return BaseComponent =>
        class ContextComponent extends Component {
            static contextType = TabContext;

            render() {
                return <BaseComponent {...this.props} {...this.context} />;
            }
        };
}
