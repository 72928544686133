import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Emoji } from 'components/Emoji';
import { LoaderLogo } from 'components/LoaderLogo';
import { Text } from 'components/Text';
import { EVENT_LOG_STATE_EMOJI_MAP, EVENT_LOG_STATES, EVENT_LOG_STATE_TYPES } from 'consts';
import { capitalize, get, titleize, toDateTimeString } from 'utils';
import { styleSheet } from './stylesheet';

class EventLogs extends PureComponent {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        logs: PropTypes.arrayOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    renderStateType({ colorStyles, connectNumber, state, stateType }) {
        const { styles } = this.props;
        let ellipsis;
        let type = stateType;
        if ([EVENT_LOG_STATES.connecting, EVENT_LOG_STATES.receiving].includes(state)) {
            ellipsis = '...';
        }
        if (stateType === 'stream') {
            type = 'audio';
        }
        if (
            connectNumber &&
            [EVENT_LOG_STATES.connecting, EVENT_LOG_STATES.completed].includes(state) &&
            stateType === EVENT_LOG_STATE_TYPES.connection
        ) {
            return (
                <Text size={3} styles={colorStyles}>
                    {state === EVENT_LOG_STATES.connecting && 'to '}
                    {state === EVENT_LOG_STATES.completed && 'connection to '}
                    {connectNumber}
                    {ellipsis}
                </Text>
            );
        }
        // Return an emoji if the state is connected
        // This is to prevent displaying something like "Connected to connection"
        if (state === EVENT_LOG_STATES.connected) {
            return <Emoji label="key mention" styles={styles.emoji} symbol={EVENT_LOG_STATE_EMOJI_MAP.connected} />;
        }
        // Format type
        type = titleize(type).toLowerCase();
        if (state === EVENT_LOG_STATES.connecting) type = `to ${titleize(type).toLowerCase()}`;
        if (state === EVENT_LOG_STATES.error) type = `with ${type}`;
        return (
            <Text size={3} styles={colorStyles}>
                {type}
                {ellipsis}
            </Text>
        );
    }

    renderLogs() {
        const { logs, styles } = this.props;
        if (logs.length) {
            let connectNumber;
            return logs.map(({ parameters, state, stateId, stateType, timestamp }) => {
                let colorStyles = {};
                if (state === EVENT_LOG_STATES.completed && stateType === EVENT_LOG_STATE_TYPES.recording) {
                    colorStyles = styles.green;
                }
                if (state === EVENT_LOG_STATES.error) {
                    colorStyles = styles.red;
                }
                // Set connect number if not yet defined to be reused by logs with "connection" stateType
                if (!connectNumber && Object.keys(parameters || {}).length) {
                    connectNumber = get(parameters, 'connect_number');
                }
                return (
                    <Div key={stateId} styles={styles.logRow}>
                        <Text size={1} styles={styles.timestamp}>
                            {toDateTimeString(timestamp, false, false, 'h:mm:ssTT')}
                        </Text>
                        <Text size={3} styles={colorStyles}>
                            {capitalize(state)}&nbsp;
                        </Text>
                        {this.renderStateType({ colorStyles, connectNumber, state, stateType })}
                    </Div>
                );
            });
        }
        return <Text size={3}>No logs found</Text>;
    }

    render() {
        const { loading, passedStyles, styles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>{loading ? <LoaderLogo /> : this.renderLogs()}</Div>
        );
    }
}

export const EventLogsUI = compose(withStyleSheet(styleSheet))(EventLogs);
