import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { RawHTML } from 'components/RawHTML';
import { Icon } from 'components/Icon';
import { toDurationString, safeRegExp, get } from 'utils';
import { styleSheet } from './stylesheet';

class EventMatch extends PureComponent {
    static propTypes = {
        index: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        match: PropTypes.objectOf(PropTypes.any).isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchTerm: PropTypes.string,
        selectedNode: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        streamId: undefined,
        selectedNode: undefined,
        searchTerm: '',
        passedStyles: {}
    };

    // Split the text and insert styled spans when there are matches for the searchTerm
    getHighlightedText(transcript, stripHTML) {
        const { searchTerm, theme } = this.props;
        let highlighted = [transcript];

        if (transcript) {
            const transcriptNoHTML = transcript.replace(/(<([^>]+)>)/gi, '');
            const parts = (stripHTML ? transcriptNoHTML : transcript).split(safeRegExp(searchTerm));
            highlighted = parts.map(part => {
                if (part.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return `<span style="${`background-color: ${theme.colors.blue08};color: ${theme.colors.white01}`}">${part}</span>`;
                }
                return part;
            });
        }

        return highlighted.join('');
    }

    render() {
        const {
            children,
            index,
            total,
            match,
            mediaPlayer,
            onNodeSelect,
            passedStyles,
            searchTerm,
            selectedNode,
            streamId,
            styles,
            theme
        } = this.props;
        const transcript = get(match, 'text', get(match, 'transcript'));
        const eventId = get(match, 'eventId', get(match, 'id', get(match, 'itemId'))).toString();
        const speakerName = get(match, 'speakerName', get(match, 'speaker.name'));
        const speakerTitle = get(match, 'speakerTitle', get(match, 'speaker.title'));
        const speakerAffiliation = get(match, 'speakerAffiliation', get(match, 'speaker.affiliation'));
        const startMs = get(match, 'startMs');
        const hasTime = startMs > 0;
        const startTime = Math.max(parseInt(startMs || 0, 10) / 1000, 0);
        const isSelected = eventId === selectedNode;
        const selectNodeAndListen = e => {
            e.preventDefault();
            e.stopPropagation();
            onNodeSelect({ id: eventId, seekToAudio: true, withScroll: true });
            if (!mediaPlayer.listening && mediaPlayer.canListen) {
                mediaPlayer.listen();
            }
        };
        return (
            <Div
                styles={[isSelected ? styles.matchSelected : styles.match, passedStyles]}
                key={eventId}
                onClick={() =>
                    onNodeSelect({
                        id: eventId,
                        withScroll: true,
                        isTermMatch: !searchTerm,
                        seekToAudio: false,
                        streamId
                    })
                }
            >
                {speakerName ? (
                    <Div styles={styles.matchSpeakerBlock}>
                        <Div>
                            <Text size={1} weight="medium" styles={styles.matchSpeakerName}>
                                {speakerName}
                            </Text>
                            <Text size={0} styles={styles.matchSpeakerInfo}>
                                {speakerTitle}
                            </Text>
                            <Text size={0} styles={styles.matchSpeakerInfo}>
                                {speakerAffiliation}
                            </Text>
                        </Div>
                        <Text size={0} styles={styles.matchCount}>
                            {`${index} of ${total}`}
                        </Text>
                    </Div>
                ) : (
                    <Text size={0} styles={styles.matchCount}>
                        {`${index} of ${total}`}
                    </Text>
                )}
                <Div styles={styles.matchTranscript}>
                    <RawHTML html={searchTerm ? this.getHighlightedText(transcript, true) : transcript} />
                </Div>
                {hasTime && mediaPlayer.canListen && (
                    <Div styles={styles.listenButton} onClick={selectNodeAndListen}>
                        <Icon type="play" color={theme.colors.white01} />
                        <Text size={1}>Listen from {toDurationString(startTime)}</Text>
                    </Div>
                )}
                {children}
            </Div>
        );
    }
}

export const EventMatchUI = compose(withStyleSheet(styleSheet))(EventMatch);
