export const styleSheet = theme => ({
    container: {
        display: 'flex',
        '> *:not(:last-child)': {
            marginRight: theme.margins.margin00
        }
    },
    input: {
        width: 167,
        '.clearInput': {
            marginTop: 21,
            height: 40
        }
    }
});
