import React, { Fragment, PureComponent } from 'react';
import { BookmarkList } from 'components/BookmarkList';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { NotificationList } from 'components/NotificationList';
import { Text } from 'components/Text';
import { UserMenu } from 'components/UserMenu';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { isBeta, isInIframe } from 'utils';
import { UploadDocumentButton } from 'components/UploadDocumentButton';
import { Search } from '../Search';
import { styleSheet } from './stylesheet';

class Header extends PureComponent {
    static propTypes = {
        onLeaveBeta: PropTypes.func.isRequired,
        openZendesk: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    renderHeaderButton({ label, onClick, icon }) {
        const { styles, theme } = this.props;
        return (
            <Hint
                growLeft
                text={label}
                onClick={onClick}
                styles={styles.headerButton}
                name={`header-${label.toLowerCase().replace(' ', '-')}`}
            >
                <Icon color={theme.colors.gray04} type={icon} />
            </Hint>
        );
    }

    render() {
        const { passedStyles, styles, onLeaveBeta, openZendesk } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Search />
                <Div styles={styles.spacer} />
                {!isInIframe() && isBeta() && (
                    <Hint name="beta-tag" styles={styles.beta} growLeft onClick={onLeaveBeta} text="Leave Aiera Beta">
                        <Text weight="medium" uppercase size={0} styles={styles.betaText}>
                            beta
                        </Text>
                    </Hint>
                )}
                <UploadDocumentButton />
                {!isInIframe() && (
                    <Fragment>
                        <WithPermission permissions={[PERMISSIONS.featureLiveSupport, PERMISSIONS.unlockedLiveSupport]}>
                            {this.renderHeaderButton({
                                label: 'Live Support',
                                onClick: openZendesk,
                                icon: 'headset'
                            })}
                        </WithPermission>
                        <WithPermission permissions={[PERMISSIONS.featureBookmarks]}>
                            <BookmarkList />
                        </WithPermission>
                        <WithPermission permissions={[PERMISSIONS.featureAlerts]}>
                            <NotificationList />
                        </WithPermission>
                        <UserMenu />
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const HeaderUI = compose(withStyleSheet(styleSheet))(Header);
