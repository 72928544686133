import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { get } from 'utils';
import { withData } from './data';
import { UpgradeModalUI } from './ui';

export class UpgradeModal extends PureComponent {
    static displayName = 'UpgradeModalContainer';

    static propTypes = {
        adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
        calculating: PropTypes.bool,
        currentPaymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        getUpdatedSeats: PropTypes.func,
        hasBillingError: PropTypes.bool.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isSelfServe: PropTypes.bool,
        loading: PropTypes.bool,
        membershipType: PropTypes.string,
        newMembershipPriceId: PropTypes.string,
        nextBillingDate: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        organizationAdmin: PropTypes.bool.isRequired,
        paymentInterval: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        updateSubscription: PropTypes.func.isRequired,
        upgradePlan: PropTypes.objectOf(PropTypes.any),
        upgradePriceMap: PropTypes.objectOf(PropTypes.any),
        user: PropTypes.objectOf(PropTypes.any),
        users: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        calculating: false,
        currentPaymentAmount: undefined,
        getUpdatedSeats: null,
        isSelfServe: false,
        loading: false,
        membershipType: undefined,
        newMembershipPriceId: null,
        nextBillingDate: undefined,
        paymentInterval: undefined,
        styles: undefined,
        upgradePlan: null,
        upgradePriceMap: null,
        user: null,
        users: []
    };

    constructor(props) {
        super(props);

        this.onUpgrade = this.onUpgrade.bind(this);
        this.toggleUpgradeOption = this.toggleUpgradeOption.bind(this);

        this.state = {
            submitting: false,
            upgradeOption: 'currentUser'
        };
    }

    onUpgrade() {
        const { upgradeOption } = this.state;
        const { getUpdatedSeats, newMembershipPriceId, onClose, updateSubscription, user, users } = this.props;
        this.setState({ submitting: true }, () => {
            updateSubscription(getUpdatedSeats(newMembershipPriceId, upgradeOption, get(user, 'userId'), users))
                .then(() => {
                    this.setState({ submitting: false }, () => {
                        onClose();
                    });
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        });
    }

    toggleUpgradeOption({ value }) {
        const [upgradeOption] = value;
        this.setState({ upgradeOption });
    }

    render() {
        const { submitting, upgradeOption } = this.state;
        const {
            adminUsers,
            calculating,
            currentPaymentAmount,
            hasBillingError,
            history,
            isSelfServe,
            loading,
            membershipType,
            nextBillingDate,
            onClose,
            organizationAdmin,
            paymentInterval,
            styles,
            upgradePlan,
            upgradePriceMap,
            users
        } = this.props;
        return (
            <UpgradeModalUI
                adminUsers={adminUsers}
                calculating={calculating}
                currentPaymentAmount={currentPaymentAmount}
                goBack={history.goBack}
                hasBillingError={hasBillingError}
                isSelfServe={isSelfServe}
                loading={loading}
                membershipType={membershipType}
                nextBillingDate={nextBillingDate}
                onClose={onClose}
                onUpgrade={this.onUpgrade}
                organizationAdmin={organizationAdmin}
                paymentInterval={paymentInterval}
                styles={styles}
                submitting={submitting}
                toggleUpgradeOption={this.toggleUpgradeOption}
                upgradeOption={upgradeOption}
                upgradePlan={upgradePlan}
                upgradePriceMap={upgradePriceMap}
                userCount={users.length}
            />
        );
    }
}

export const UpgradeModalContainer = compose(withUrlContext(['history']), withData())(UpgradeModal);
