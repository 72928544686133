import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { WatchlistFiltersUI } from './ui';

export class WatchlistFilters extends PureComponent {
    static displayName = 'WatchlistFiltersContainer';

    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        name: null,
        onChange: null,
        styles: undefined,
        value: null
    };

    render() {
        const { name, onChange, styles, value } = this.props;
        return <WatchlistFiltersUI name={name} onChange={onChange} styles={styles} value={value} />;
    }
}

export const WatchlistFiltersContainer = compose()(WatchlistFilters);
