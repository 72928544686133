import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { styleSheet } from './stylesheet';

class TagAutocomplete extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        initialSearchTerm: PropTypes.string,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onPlainText: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    };

    static defaultProps = {
        initialSearchTerm: null,
        multi: false,
        onPlainText: undefined,
        passedStyles: {},
        value: null
    };

    render() {
        const {
            autoFocus,
            initialSearchTerm,
            multi,
            name,
            onChange,
            onPlainText,
            options,
            passedStyles,
            placeholder,
            styles,
            value
        } = this.props;
        return (
            <Autocomplete
                autoFocus={autoFocus}
                clearOnSelect
                icon="list02"
                initialSearchTerm={initialSearchTerm}
                menuStyles={styles.menu}
                multi={multi}
                name={name}
                onChange={onChange}
                onSearch={onPlainText}
                clearOnBlur={!onPlainText}
                filterOptions={!!onPlainText}
                strictOptions={!onPlainText}
                options={options}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                value={value}
            />
        );
    }
}

export const TagAutocompleteUI = compose(withStyleSheet(styleSheet))(TagAutocomplete);
