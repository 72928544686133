import { colors } from 'styles/colors';
import * as ALL_FILTERS from './filters';

export const CAPABILITIES = {
    addStream: 'add_stream',
    addEvent: 'add_event',
    clone: 'clone',
    contextPane: 'context_pane',
    dateFilter: 'date_filter',
    displayMode: 'display_mode',
    edit: 'edit',
    equityFilter: 'equity_filter',
    follow: 'follow',
    searchBox: 'search_box',
    statistics: 'statistics'
};
export const CONTENT_TYPES = [
    'AssetPurchaseSpotlightContent',
    'AttachmentContent',
    'BuybackSpotlightContent',
    'CorporateActionContent',
    'DocumentContent',
    'FilingContent',
    'GuidanceSpotlightContent',
    'IPOSpotlightContent',
    'MAndASpotlightContent',
    'NewsContent',
    'PartnershipSpotlightContent',
    'ResearchContent',
    'SalesMetricSpotlightContent',
    'SpinOffSpotlightContent',
    'StreetAccountContent'
];
export const EDITOR_QUEUE_URL = 'https://aiera.app/editor-queue';
export const ENVS = {
    local: 'local',
    development: 'development',
    staging: 'staging',
    beta: 'beta',
    production: 'production'
};
export const EVENT_TYPE_COLOR_MAP = {
    custom: { dark: colors.orange02, light: 'rgba(254, 89, 12, 0.14)' },
    earnings: { dark: colors.blue08, light: 'rgba(0, 122, 255, 0.14)' },
    earnings_release: { dark: colors.blue06, light: 'rgba(0, 122, 255, 0.1)' },
    investor_meeting: { dark: colors.green03, light: 'rgba(58, 185, 53, 0.2)' },
    presentation: { dark: colors.purple05, light: 'rgba(100, 55, 158, 0.14)' },
    shareholder_meeting: { dark: colors.red09, light: 'rgba(178, 19, 21, 0.14)' },
    special_situation: { dark: '#CC830B', light: 'rgba(244, 165, 34, 0.16)' },
    test: { dark: colors.gray04 }
};
export const EVENT_LOG_STATE_EMOJI_MAP = {
    connected: '✅'
};
export const EVENT_LOG_STATES = {
    completed: 'completed',
    connected: 'connected',
    connecting: 'connecting',
    dialingAgent: 'dialing_agent',
    error: 'error',
    initial: 'initial',
    receiving: 'receiving',
    stopped: 'stopped'
};
export const EVENT_LOG_STATE_TYPES = {
    agent: 'agent',
    conference: 'conference',
    connection: 'connection',
    firstTranscript: 'first_transcript',
    privateRecording: 'private_recording',
    recording: 'recording',
    stream: 'stream',
    transcriptData: 'transcript_data'
};
export const ENVIRONMENTS = [ENVS.local, ENVS.development, ENVS.staging, ENVS.beta, ENVS.production];
export const FILTERS = ALL_FILTERS;
export const HIGHLIGHT_COLORS = ['blue', 'green', 'yellow', 'orange', 'red'];
export const HIGHLIGHT_COLOR_MAP = {
    blue: { key: 'blue', color: '#E6F5FF', dark: '#d5ebf9', dark02: '#638ba5' },
    green: { key: 'green', color: '#E6FFE6', dark: '#c9ecc9', dark02: '#539253' },
    yellow: { key: 'yellow', color: '#FFFFE6', dark: '#eaecb7', dark02: '#b1b54d' },
    orange: { key: 'orange', color: '#FFF5E6', dark: '#f7ebd8', dark02: '#bb8e47' },
    red: { key: 'red', color: '#FAEBF5', dark: '#f1dbe9', dark02: '#9c4367' }
};
export const NO_OP = () => {};
export const NOTIFICATION_TYPE_EMOJI_MAP = {
    default: '🔔',
    event: '📅',
    event_stream_match: '✅',
    event_price_reaction_down: '📉',
    event_price_reaction_up: '📈',
    event_quicklinks_match: '🎯',
    spotlight_match: '🏢',
    stream_match: '✅',
    stream_match_rollup: '🔥'
};
export const PERMISSIONS = {
    apiAll: 'api:all',
    apiEvents: 'api:events',
    apiTonal: 'api:tonal',
    customEventsAudioStreamUrl: 'custom_events:allow_audio_stream_url',
    customEventsIntervention: 'custom_events:allow_human_intervention',
    editTranscripts: 'edit:transcripts',
    eventsMic: 'custom_events:microphone',
    eventsStreamProvider: 'custom_events:set_stream_provider',
    eventsWebcastUrl: 'custom_events:set_webcast_url',
    featureAlerts: 'feature:alerts',
    featureBeta: 'feature:beta',
    featureBookmarks: 'feature:bookmarks',
    featureCalendar: 'feature:calendar',
    featureCompanies: 'feature:companies',
    featureCompanySWOTAnalysis: 'feature:company_swot_analysis',
    featureCreateEvent: 'feature:create_event',
    featureDebugMode: 'feature:debug_mode',
    featureDialOut: 'feature:dial_out',
    featureEventsAskAiera: 'feature:events:ask_aiera',
    featureEventsTonalSentiment: 'feature:events:sentiment',
    featureIcal: 'feature:ical',
    featureLegacyEquityDashboards: 'feature:legacy_equity_dashboards',
    featureLiveSupport: 'feature:live_support',
    featureLogrocket: 'feature:logrocket',
    featureModelStreams: 'feature:model_streams',
    featureRequestRecordingPermission: 'feature:request_recording_permission',
    featureShowEstimatedSpeakers: 'feature:show_estimates_speakers',
    featureStreamCustom: 'feature:streams:custom_data',
    featureStreams: 'feature:streams',
    featureStreamsFilings: 'feature:streams:filings',
    featureStreamsSpotlight: 'feature:streams:spotlight',
    featureStreamsResearch: 'feature:streams:research',
    featureTranscrippets: 'feature:transcrippets',
    featureUploads: 'feature:uploads',
    sharing: 'sharing:all',
    sharingBookmarks: 'sharing:bookmarks',
    transcriptsOnlyEdited: 'transcripts:only_edited',
    transcriptsOnlyImproved: 'transcripts:only_improved',
    unlockedCreateDashboard: 'unlocked:create_dashboard',
    unlockedCreatePastPrivateRecording: 'unlocked:create_past_private_recording',
    unlockedCreatePrivateRecording: 'unlocked:create_private_recording',
    unlockedCreateStream: 'unlocked:create_stream',
    unlockedCreateStreamCustom: 'unlocked:create_stream_custom',
    unlockedCreateStreamNews: 'unlocked:create_stream_news',
    unlockedDashboardAnalysis: 'unlocked:dashboard_analysis',
    unlockedDashboardGalleryAllAccess: 'unlocked:dashboard_gallery_all_access',
    unlockedEditDashboard: 'unlocked:edit_dashboard',
    unlockedEditStream: 'unlocked:edit_stream',
    unlockedEquityFilings: 'unlocked:equity_filings',
    unlockedFilingsContent: 'unlocked:filings_content',
    unlockedFollowDashboard: 'unlocked:follow_dashboard',
    unlockedLiveEvents: 'unlocked:live_events',
    unlockedLiveSupport: 'unlocked:live_support',
    unlockedNewsContent: 'unlocked:news_content',
    unlockedAttachmentContent: 'unlocked:attachment_content',
    unlockedCorporateActionContent: 'unlocked:corporate_action_content',
    unlockedResearchContent: 'unlocked:research_content',
    unlockedRequestCustomDashboard: 'unlocked:request_custom_dashboard',
    unlockedScriptsAsia: 'unlocked:scripts_asia',
    unlockedSearchNews: 'unlocked:search_news',
    unlockedSentiment: 'unlocked:sentiment',
    unlockedSpotlightContent: 'unlocked:spotlight_content',
    unlockedTopics: 'unlocked:topics',
    writeDashGallery: 'write:dash_gallery'
};
export const PERMITTED_INVITE_CODE_TYPES = ['invite', 'invite_user', 'register', 'verify_email'];
export const PREFERENCES = {
    autoPlayAudio: { type: 'ux', name: 'autoPlayAudio' },
    editorAutoPlayAudio: { type: 'ux', name: 'editorAutoPlayAudio' },
    equityContextTab: { type: 'ux', name: 'equityContextTab' },
    eventFocusMode: { type: 'ux', name: 'eventFocusMode' },
    eventMarkupMenu: { type: 'ux', name: 'eventMarkupMenu' },
    eventTranscriptsOnly: { type: 'ux', name: 'eventTranscriptsOnly' },
    floatingTabFullScreen: { type: 'ux', name: 'floatingTabFullScreen' },
    hideTranscriptVersionNotice: { type: 'ux', name: 'hideTranscriptVersionNotice' },
    lockAudioScroll: { type: 'ux', name: 'lockAudioScroll' },
    reverseLiveTranscripts: { type: 'ux', name: 'reverseLiveTranscripts' },
    showCompanyContentFilters: { type: 'ux', name: 'showCompanyContentFilters' },
    showCompanyInstructions: { type: 'ux', name: 'showCompanyInstructions' },
    showCompanyPerformanceChart: { type: 'ux', name: 'showCompanyPerformanceChart' },
    showCompanySummary: { type: 'ux', name: 'showCompanySummary' },
    showCompanyStats: { type: 'ux', name: 'showCompanyStats' },
    showDashContext: { type: 'ux', name: 'showDashContext' },
    showDashFilter: { type: 'ux', name: 'showDashFilter' },
    showEquityContext: { type: 'ux', name: 'showEquityContext' },
    showEstimatedSpeakers: { type: 'ux', name: 'showEstimatedSpeakers' },
    showHumanEditedOnly: { type: 'ux', name: 'showHumanEditedOnly' },
    showTranscriptAnnotations: { type: 'ux', name: 'showTranscriptAnnotations' },
    showTranscriptHighlights: { type: 'ux', name: 'showTranscriptHighlights' },
    showTranscriptSentiment: { type: 'ux', name: 'showTranscriptSentiment' },
    showTranscriptTonalSentiment: { type: 'ux', name: 'showTranscriptTonalSentiment' },
    showTranscriptTimestamps: { type: 'ux', name: 'showTranscriptTimestamps' },
    showWelcome: { type: 'ux', name: 'showWelcome' },
    sidebar: { type: 'ux', name: 'sidebar' },
    sidebarCollapsedSections: { type: 'ux', name: 'sidebarCollapsedSections' },
    streamsConfig: { type: 'ux', name: 'streamsConfig' },
    streamDisplayMode: { type: 'ux', name: 'streamDisplayMode' },
    watchlistOrder: { type: 'ux', name: 'watchlistOrder' }
};
export const SENTIMENT_TYPES = ['neg', 'neu', 'pos'];
export const STREAM_DISPLAY_MODES = {
    default: 'default',
    comfortable: 'comfortable',
    compact: 'compact',
    minimal: 'minimal'
};
export const STREAM_MENU_COLORS = [
    'transparent',
    '#FC5C65',
    '#FD9645',
    '#FED42F',
    '#28DE81',
    '#2DCBBA',
    '#45AAF2',
    '#4B7BED',
    '#A55EEB',
    '#778CA3'
];
export const STRIPE_TEST_INTERVAL = 'week';
export const STRIPE_TEST_PRICES_KEY = 'aiera:feature:stripeTestPrices';

// Used in transcript editor
export const SPEAKER_IDS = {
    unknown: 1168538,
    multiple: 97730,
    operator: 207901
};

const TAB_TYPE_AUDIO_CALL = 'ac';
const TAB_TYPE_DATA_RECORD = 'dr';
const TAB_TYPE_EVENT = 'evt';
const TAB_TYPE_FILING = 'fl';
const TAB_TYPE_NEWS = 'nws';
const TAB_TYPE_REPORT = 'rpt';
const TAB_TYPE_SPOTLIGHT = 'ca';
const TAB_TYPE_STREET_ACCOUNT = 'sa';
const TAB_TYPE_PDF_SLIDES = 'pdfs';
const TAB_TYPE_PDF_PRESENTATION = 'pdfp';
const TAB_TYPE_RESEARCH = 'rs';
const TAB_TYPE_DOCUMENT = 'dct';
export const TAB_TYPES = {
    audioCall: TAB_TYPE_AUDIO_CALL,
    dataRecord: TAB_TYPE_DATA_RECORD,
    document: TAB_TYPE_DOCUMENT,
    event: TAB_TYPE_EVENT,
    filing: TAB_TYPE_FILING,
    news: TAB_TYPE_NEWS,
    pdfSlides: TAB_TYPE_PDF_SLIDES,
    pdfPresentation: TAB_TYPE_PDF_PRESENTATION,
    report: TAB_TYPE_REPORT,
    research: TAB_TYPE_RESEARCH,
    spotlight: TAB_TYPE_SPOTLIGHT,
    streetAccount: TAB_TYPE_STREET_ACCOUNT
};

export const VIDEO_TYPES = {
    workspaceHighlights: 'workspaceHighlights',
    welcomeToAiera: 'welcomeToAiera'
};
export const VIDEO_URLS = {
    [VIDEO_TYPES.workspaceHighlights]: {
        player: 'vimeo',
        title: 'Highlights Feature Overview',
        url: 'https://vimeo.com/515984958'
    },
    [VIDEO_TYPES.welcomeToAiera]: {
        player: 'vimeo',
        title: 'Welcome to Aiera',
        url: 'https://vimeo.com/678342609'
    }
};

export const constants = {
    AIERA_ORG_ID: 1,
    CONVICTION_HIGH: 'high',
    CONVICTION_LOW: 'standard',
    CONVICTION_MED: 'standard',
    EMAIL_SEATS: 'support@aiera.com',
    EMAIL_SUPPORT: 'help@aiera.com',
    NO_OP,
    PERMISSIONS,
    PERMITTED_INVITE_CODE_TYPES,
    PHONE_SUPPORT: '212-960-8085',
    SENTIMENT_TYPES,
    STREAM_MENU_COLORS,
    TAB_TYPES
};

export const TRACKING_ACTIONS = {
    click: 'Click',
    scroll: 'Scroll',
    submit: 'Submit'
};

export const TRACKING_OBJECTS = {
    addAieracastStream: 'Add Aieracast Stream',
    archiveBatchContent: 'Archive Batch Content',
    archiveEvent: 'Archive Event',
    archiveFiling: 'Archive Filing',
    archiveNews: 'Archive News',
    archiveSpotlight: 'Archive Spotlight',
    audioSeek: 'Audio Seek',
    audioRewind: 'Audio Rewind',
    audioStartOver: 'Audio Start Over',
    audioFF: 'Audio Fast Forward',
    audioPlaybackRate: 'Audio Playback Rate',
    audioPlay: 'Audio Play',
    audioDuration: 'Audio Duration',
    audioAutoPlay: 'Audio AutoPlay',
    audioPause: 'Audio Pause',
    audioStop: 'Audio Stop',
    globalSearch: 'Global Search',
    globalSearchResult: 'Global Search Result',
    highlightCreate: 'Highlight Create',
    highlightDelete: 'Highlight Delete',
    highlightUpdate: 'Highlight Update',
    monitorCreate: 'Monitor Create',
    monitorDelete: 'Monitor Delete',
    monitorUpdate: 'Monitor Update',
    monitorFilter: 'Monitor Filter',
    privateRecordingCreate: 'Private Recording Create',
    privateRecordingUpdate: 'Private Recording Update',
    starBatchContent: 'Star Batch Content',
    starEvent: 'Star Event',
    starFiling: 'Star Filing',
    starNews: 'Star News',
    starSpotlight: 'Star Spotlight',
    streamCreate: 'Stream Create',
    streamDelete: 'Stream Delete',
    streamUpdate: 'Stream Update',
    tagBatchContent: 'Tag Batch Content',
    tagEvent: 'Tag Event',
    tagFiling: 'Tag Filing',
    tagNews: 'Tag News',
    transcrippetCreate: 'Transcrippet Create',
    tagSpotlight: 'Tag Spotlight',
    watchlistCreate: 'Watchlist Create'
};
