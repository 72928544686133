import React, { PureComponent } from 'react';
import { Div } from 'components/Div';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { styleSheet } from './stylesheet';

class PDFViewerMinimal extends PureComponent {
    static propTypes = {
        handleViewerRef: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { handleViewerRef, styles, passedStyles } = this.props;

        return <Div styles={{ ...styles.container, ...passedStyles }} className="webviewer" ref={handleViewerRef} />;
    }
}

export const PDFViewerMinimalUI = compose(withStyleSheet(styleSheet))(PDFViewerMinimal);
