import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import memoize from 'memoize-one';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { generateTabURL } from 'utils';
import { popoutManager } from 'utils/popout';
import { STREAM_DISPLAY_MODES } from 'consts';
import { NewsCardUI } from './ui';

function canOpen(source) {
    return source !== 'lexisnexis';
}

function groupedWithLinks(newsId, pathname, streamId, grouped, source) {
    return grouped.map(g => {
        return {
            ...g,
            to: canOpen(source)
                ? generateTabURL({
                      newsId,
                      pathname,
                      match: 'match',
                      streamId,
                      itemId: g.itemId
                  })
                : undefined
        };
    });
}

export class NewsCard extends PureComponent {
    static displayName = 'NewsCardContainer';

    static propTypes = {
        additionalHighlights: PropTypes.arrayOf(PropTypes.any),
        bookmarkId: PropTypes.string,
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        newsSource: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        source: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        additionalHighlights: [],
        bookmarkId: undefined,
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        equities: [],
        grouped: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        newsSource: null,
        score: undefined,
        source: undefined,
        streamId: null,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.groupedWithLinks = memoize(groupedWithLinks);
        this.canOpen = memoize(canOpen);
    }

    handleClick(e) {
        if (e.shiftKey) {
            this.handlePopout(e);
        }
    }

    handlePopout(e) {
        const { id, streamId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generateTabURL({
                pathname: generatePath(routes.popoutNews, { type: 'news', newsId: id }),
                newsId: id,
                match: !!streamId,
                streamId
            }),
            name: `popout-news-${id}`,
            width: 750
        });
    }

    render() {
        const {
            additionalHighlights,
            bookmarkId,
            company,
            displayMode,
            equities,
            grouped,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            pathname,
            newsSource,
            publishedDate,
            score,
            source,
            styles,
            streamId,
            title
        } = this.props;
        return (
            <NewsCardUI
                additionalHighlights={additionalHighlights}
                bookmarkId={bookmarkId}
                company={company}
                displayMode={displayMode}
                equities={equities}
                grouped={this.groupedWithLinks(id, pathname, streamId, grouped, source)}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                matchId={matchId}
                newsSource={newsSource}
                onClick={this.handleClick}
                publishedDate={publishedDate}
                score={score}
                streamId={streamId}
                styles={styles}
                title={title}
                to={
                    this.canOpen(source)
                        ? generateTabURL({ pathname, newsId: id, streamId, match: !!streamId })
                        : undefined
                }
            />
        );
    }
}

export const NewsCardContainer = compose(withUrlContext(['pathname']))(NewsCard);
