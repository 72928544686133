export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.gray08,
        borderRadius: 3.5,
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
        paddingTop: 8
    },
    button: {
        border: 'unset',
        boxShadow: 'unset',
        height: 30,
        p: {
            letterSpacing: 1
        }
    },
    buttonCancel: {
        marginRight: 8,
        p: {
            color: theme.colors.gray04
        },
        ':active': {
            p: {
                color: theme.colors.white01
            }
        },
        ':hover': {
            p: {
                color: theme.colors.white02
            }
        }
    },
    buttonDisabled: {
        cursor: 'not-allowed',
        opacity: 0.7
    },
    buttonsContainer: {
        alignItems: 'center',
        backgroundColor: theme.colors.gray11,
        borderRadius: '0 0 6px 6px',
        display: 'flex',
        marginTop: 8,
        padding: '8px 8px'
    },
    buttonSubmit: {
        ...theme.content.blueButton,
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        p: {
            color: theme.colors.white01,
            fontWeight: theme.fonts.fontWeights.medium,
            letterSpacing: 1
        }
    },
    dropdown: {
        label: {
            color: theme.colors.gray02,
            fontSize: theme.fonts.fontSizes.size02
        },
        marginTop: theme.margins.margin00,
        p: {
            fontSize: theme.fonts.fontSizes.size04
        },
        padding: '0 10px'
    },
    textInput: {
        label: {
            color: theme.colors.gray02,
            fontSize: theme.fonts.fontSizes.size02
        },
        padding: '0 10px'
    }
});
