export const styleSheet = theme => ({
    container: {},
    menu: {
        maxHeight: 500
    },
    suggestedTags: {
        alignItems: 'center',
        display: 'flex',
        height: 20,
        marginTop: 2,
        overflowX: 'auto',
        'p:first-of-type': {
            color: theme.colors.gray06
        }
    },
    suggestedTag: {
        color: theme.colors.linkHover,
        cursor: 'pointer',
        marginLeft: 5,
        whiteSpace: 'nowrap',
        ':first-of-type': {
            marginLeft: 0
        },
        ':hover': {
            textDecoration: 'underline'
        }
    }
});
