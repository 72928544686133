import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AieraProvider } from 'provider';
import MediaQuery from 'react-responsive';

import { FloatingTab } from 'layouts';

import { breakpoints } from 'styles/breakpoints';
import { AudioPlaybar } from 'components/AudioPlaybar';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LoaderLogo } from 'components/LoaderLogo';
import { SecureRoute } from 'components/SecureRoute';
import { MediaControls } from 'components/MediaControls';
import { StatusBanner } from 'components/StatusBanner';
import { Zendesk } from 'components/Zendesk';

import { config } from 'configuration';

import './App.css';
import 'styles/fonts/load.css';

import { routes, externalRoutes } from 'routes';

// LAYOUTS
import { InternalLayout } from 'layouts/InternalLayout';

// PAGES
import { NoRouteMatch } from 'pages/NoRouteMatch';
import { Auth } from 'pages/Auth';

import {
    suspend,
    Account,
    Agent,
    Calendar,
    ConfirmEmail,
    Companies,
    Dashboard,
    Documents,
    Equity,
    EventGroup,
    FramedEvent,
    ManageAlerts,
    Mobile,
    Playground,
    Popout,
    PublicAskAiera,
    PublicEvent,
    PublicEventMinimal,
    PublicEmbeddedEvent,
    Register,
    ResetPassword,
    Research,
    Search,
    SetPassword,
    TranscriptEditor,
    Watchlist,
    Workspace
} from 'pages/lazy';
import { AuthGate } from './AuthGate';
import { BrowserGate } from './BrowserGate';
import { DeletedCheck } from './DeletedCheck';
import { MobileBridge } from './MobileBridge';
import { VersionCheck } from './VersionCheck';
import { UserGate } from './UserGate';

// IF NOT MAC, STYLE SCROLL BARS
if (!navigator.platform.match(/mac/i)) {
    import('./scrollbars.css');
}

function lazyLoad(Page) {
    return suspend(Page, <LoaderLogo styles={{ margin: 'auto' }} height={60} />);
}

function wrapWithInternal(Page, options = {}) {
    const { showFooter, allowScroll, lazy } = options;
    let PageComponent = Page;
    if (lazy) {
        PageComponent = lazyLoad(Page);
    }
    return routeProps => (
        <MediaQuery maxWidth={breakpoints.internal.mobile}>
            {isMobile => (
                <UserGate isMobile={isMobile}>
                    <InternalLayout showFooter={showFooter} allowScroll={allowScroll}>
                        <PageComponent {...routeProps} />
                    </InternalLayout>
                    <AudioPlaybar showRecommendedEvents showSavedTabs showEventInfo />
                </UserGate>
            )}
        </MediaQuery>
    );
}

export class App extends Component {
    render() {
        return (
            <AieraProvider>
                <BrowserGate>
                    <MobileBridge />
                    <VersionCheck localVersion={config.VERSION} />
                    <DeletedCheck />
                    <StatusBanner />
                    <AuthGate>
                        <MediaControls />
                        <Zendesk />
                        <div className="App">
                            <ErrorBoundary showErrorPage>
                                <Switch>
                                    {/* DEPRECATED ROUTES */}
                                    <Redirect from={routes.equities} to={routes.home} />
                                    <Redirect from={routes.ical} to={routes.watchlistRoot} />

                                    {/* PUBLIC ROUTES */}
                                    <Route
                                        exact
                                        path={externalRoutes.publicEmbedEvent}
                                        component={lazyLoad(PublicEmbeddedEvent)}
                                    />
                                    <Route
                                        exact
                                        path={externalRoutes.publicAskAiera}
                                        component={lazyLoad(PublicAskAiera)}
                                    />
                                    <Route exact path={externalRoutes.publicEvent} component={lazyLoad(PublicEvent)} />
                                    <Route
                                        exact
                                        path={externalRoutes.publicEventMinimal}
                                        component={lazyLoad(PublicEventMinimal)}
                                    />

                                    <Route
                                        exact
                                        path={externalRoutes.publicPrivateEvent}
                                        component={lazyLoad(PublicEvent)}
                                    />

                                    {/* DASHBOARD */}
                                    <Route
                                        exact
                                        path={routes.home}
                                        render={({ location }) => (
                                            <MediaQuery maxWidth={breakpoints.internal.mobile}>
                                                {m => (
                                                    <Redirect
                                                        to={{
                                                            ...location,
                                                            pathname: m ? routes.mobileHome : routes.companies
                                                        }}
                                                    />
                                                )}
                                            </MediaQuery>
                                        )}
                                    />
                                    <Route
                                        path={routes.account}
                                        render={wrapWithInternal(Account, { allowScroll: true, lazy: true })}
                                    />
                                    <Route
                                        path={[routes.alert, routes.alerts]}
                                        render={wrapWithInternal(ManageAlerts, { allowScroll: true, lazy: true })}
                                    />
                                    <Route path={routes.calendar} render={wrapWithInternal(Calendar, { lazy: true })} />
                                    <Route
                                        path={routes.companies}
                                        render={wrapWithInternal(Companies, { lazy: true })}
                                    />
                                    <Route
                                        path={routes.conference}
                                        render={wrapWithInternal(EventGroup, { lazy: true })}
                                    />
                                    <Route
                                        path={routes.dashboard}
                                        render={wrapWithInternal(Dashboard, { lazy: true })}
                                    />
                                    <Route
                                        path={[routes.equity, routes.equityDashboard]}
                                        render={wrapWithInternal(Equity, { lazy: true })}
                                    />
                                    <Route
                                        path={routes.documents}
                                        render={wrapWithInternal(Documents, { lazy: true })}
                                    />
                                    <Route path={routes.research} render={wrapWithInternal(Research, { lazy: true })} />
                                    <Route path={routes.search} render={wrapWithInternal(Search, { lazy: true })} />
                                    <Route
                                        path={routes.watchlist}
                                        render={wrapWithInternal(Watchlist, { lazy: true })}
                                    />
                                    <Route
                                        path={routes.workspace}
                                        render={wrapWithInternal(Workspace, { lazy: true })}
                                    />

                                    {/* AUTH */}
                                    <Redirect from="/logout" to={routes.signOut} />
                                    <Redirect from="/signout" to={routes.signOut} />
                                    <Route exact path={externalRoutes.signIn} component={Auth} />
                                    <Route exact path={routes.signOut} component={Auth} />
                                    <Route exact path={routes.resetPassword} component={lazyLoad(ResetPassword)} />
                                    <Route path={routes.resetPasswordCode} component={lazyLoad(SetPassword)} />
                                    <Route path={routes.newPassword} component={lazyLoad(SetPassword)} />
                                    <Route path={routes.setPassword} component={lazyLoad(SetPassword)} />
                                    <Route path={externalRoutes.confirmEmail} component={lazyLoad(ConfirmEmail)} />
                                    <Route
                                        exact
                                        path={externalRoutes.sso}
                                        render={({ match }) => {
                                            window.location = `https://auth.aiera.com/idp/login/${match.params.org}`;
                                        }}
                                    />

                                    {/* Registration & pricing */}
                                    <Route path={externalRoutes.register} component={lazyLoad(Register)} />
                                    <Route path={externalRoutes.pricing} component={lazyLoad(Register)} />

                                    {/* ROOT */}
                                    <Redirect from="/" exact to={routes.root} />
                                    <Redirect from="/index.html" exact to={routes.root} />

                                    {/* MOBILE APP ONLY — INCLUDES ALL MOBILE ROUTES */}
                                    <Route path={routes.mobile} component={lazyLoad(Mobile)} />

                                    <Route path={externalRoutes.agent} component={lazyLoad(Agent)} />

                                    {/* TRANSCRIPT EDITOR */}
                                    <Route
                                        exact
                                        path={externalRoutes.transcriptEditorEvent}
                                        component={lazyLoad(TranscriptEditor)}
                                    />
                                    <Route
                                        exact
                                        path={externalRoutes.transcriptEditorItem}
                                        component={lazyLoad(TranscriptEditor)}
                                    />
                                    <Route
                                        exact
                                        path={externalRoutes.transcriptEditor}
                                        component={lazyLoad(TranscriptEditor)}
                                    />

                                    <SecureRoute path={routes.framedEvent} component={lazyLoad(FramedEvent)} />

                                    <SecureRoute path={routes.popoutEvent} component={lazyLoad(Popout)} />
                                    <SecureRoute path={routes.popoutFiling} component={lazyLoad(Popout)} />
                                    <SecureRoute path={routes.popoutNews} component={lazyLoad(Popout)} />
                                    <SecureRoute path={routes.popoutSpotlight} component={lazyLoad(Popout)} />
                                    <SecureRoute path={routes.popoutStreetAccount} component={lazyLoad(Popout)} />

                                    {/* Dev playground route */}
                                    {['development', 'local'].includes(config.AIERA_ENV) && (
                                        <Route
                                            path={routes.playground}
                                            render={wrapWithInternal(Playground, { allowScroll: true, lazy: true })}
                                        />
                                    )}

                                    {/* 404 */}
                                    <Route
                                        render={wrapWithInternal(NoRouteMatch, {
                                            allowScroll: true,
                                            showFooter: true
                                        })}
                                    />
                                </Switch>
                            </ErrorBoundary>
                            <FloatingTab />
                        </div>
                    </AuthGate>
                </BrowserGate>
            </AieraProvider>
        );
    }
}
