import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose, toRenderProps } from 'recompose';
import composeRefs from '@seznam/compose-react-refs';
import { Droppable, Draggable } from 'lib/react-beautiful-dnd';
import { PERMISSIONS } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { withComponentVisibility } from 'hoc/window';
import { ActionButton } from 'components/ActionButton';
import { BookmarkStream } from 'components/BookmarkStream';
import { ContentStream } from 'components/ContentStream';
import { CustomStream } from 'components/CustomStream';
import { Div } from 'components/Div';
import { EventGroupStream } from 'components/EventGroupStream';
import { EventStream } from 'components/EventStream';
import { GSheetStream } from 'components/GSheetStream';
import { Graphic } from 'components/Graphic';
import { Icon } from 'components/Icon';
import { LiveEventsStream } from 'components/LiveEventsStream';
import { Notice } from 'components/Notice';
import { Text } from 'components/Text';
import { TranscriptStream } from 'components/TranscriptStream';
import { WithPermission } from 'components/WithPermission';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const STREAM_MAP = {
    bookmarks: BookmarkStream,
    content: ContentStream,
    custom_data: CustomStream,
    event_groups: EventGroupStream,
    events: EventStream,
    gsheet: GSheetStream,
    live_events: LiveEventsStream,
    transcripts: TranscriptStream
};

const StreamWrapper = toRenderProps(withComponentVisibility());

class Streams extends PureComponent {
    static propTypes = {
        allowNewStreams: PropTypes.bool.isRequired,
        canPageLeft: PropTypes.bool.isRequired,
        canPageRight: PropTypes.bool.isRequired,
        dashboardId: PropTypes.string.isRequired,
        dashboardGuid: PropTypes.string,
        dashboardType: PropTypes.string.isRequired,
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        dashFilters: PropTypes.arrayOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        dashSources: PropTypes.objectOf(PropTypes.any),
        dashEquityScope: PropTypes.arrayOf(PropTypes.any),
        dragging: PropTypes.bool,
        handleContainerRef: PropTypes.func.isRequired,
        isCreator: PropTypes.bool.isRequired,
        onDeleteStream: PropTypes.func.isRequired,
        onDragStart: PropTypes.func.isRequired,
        onEditStream: PropTypes.func.isRequired,
        onResizeStart: PropTypes.func.isRequired,
        onResizeEnd: PropTypes.func.isRequired,
        onSort: PropTypes.func.isRequired,
        pageLeft: PropTypes.func.isRequired,
        pageRight: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        resizing: PropTypes.bool.isRequired,
        showNewStreamModal: PropTypes.func,
        sorting: PropTypes.bool,
        streamDisplayMode: PropTypes.string.isRequired,
        streams: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                name: PropTypes.string.isRequired,
                streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                streamType: PropTypes.string.isRequired,
                uxPreferences: PropTypes.shape({
                    color: PropTypes.string,
                    streamSortOrder: PropTypes.arrayOf(PropTypes.string)
                })
            })
        ),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        dashboardGuid: null,
        dashDateRange: null,
        dashFilters: null,
        dashSearchTerm: null,
        dashSources: null,
        dashEquityScope: null,
        dragging: false,
        passedStyles: {},
        showNewStreamModal: undefined,
        sorting: false,
        streams: []
    };

    renderEmptyMessage() {
        const { streams, dashboardId, styles, theme } = this.props;

        if (streams.length === 1 && dashboardId === 'aieracast') {
            return (
                <Div styles={styles.emptyAieracast}>
                    <Graphic type="arrowLeftCurved" color={theme.colors.white01} />
                    <Div styles={styles.emptyAieracastContent}>
                        <Text size={6} weight="medium">
                            Welcome to Aieracast
                        </Text>
                        <Div styles={styles.emptyContentRow}>
                            <Text size={3} weight="medium">
                                1
                            </Text>
                            <Text size={3}>Click any event from the left to begin streaming it live.</Text>
                        </Div>
                        <Div styles={styles.emptyContentRow}>
                            <Text size={3} weight="medium">
                                2
                            </Text>
                            <Text size={3}>To hide the event, just click it again.</Text>
                        </Div>
                        <Div styles={styles.emptyContentRow}>
                            <Text size={3} weight="medium">
                                3
                            </Text>
                            <Text size={3}>Click even more events, to stream multiple events in real-time.</Text>
                        </Div>
                    </Div>
                </Div>
            );
        }

        return null;
    }

    renderNewStreamButton({ restricted, isLoading, type, icon, label }) {
        const { styles, theme, showNewStreamModal } = this.props;
        return (
            <ActionButton
                styles={restricted || isLoading ? styles.newStreamButtonLocked : styles.newStreamButton}
                onClick={() => (isLoading ? {} : showNewStreamModal(type))}
            >
                <Icon type={restricted || isLoading ? 'lock02' : icon} color={theme.colors.gray04} />
                <Text size={3}>{label}</Text>
            </ActionButton>
        );
    }

    renderNewStream() {
        const { styles, theme, streams, isCreator, allowNewStreams } = this.props;

        if (!allowNewStreams) {
            return <Div styles={styles.endOfStreams} />;
        }

        if (!isCreator) {
            return (
                <Div styles={styles.buttonWrapper}>
                    <Notice
                        type="info"
                        heading="New search"
                        richContent
                        styles={streams && streams.length > 0 ? styles.notice : styles.noticeLeftMargin}
                    >
                        <Text size={3}>
                            Add this monitor
                            <br />
                            to add your own searches.
                        </Text>
                    </Notice>
                </Div>
            );
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m =>
                    m ? (
                        <Div styles={styles.buttonWrapper}>
                            <Notice
                                richContent
                                type="info"
                                heading="New search"
                                styles={streams && streams.length > 0 ? styles.notice : styles.noticeLeftMargin}
                            >
                                <Text size={3}>At this time searches may only be created using a larger device.</Text>
                            </Notice>
                        </Div>
                    ) : (
                        <Fragment>
                            <Div styles={styles.newStream}>
                                <WithPermission permissions={[PERMISSIONS.unlockedCreateStream]}>
                                    {({ restricted, isLoading }) => (
                                        <Fragment>
                                            <Text styles={styles.newSavedSearch} size={0} uppercase>
                                                new saved search
                                            </Text>
                                            {this.renderNewStreamButton({
                                                restricted,
                                                isLoading,
                                                type: 'content',
                                                icon: 'report',
                                                label: 'Transcripts & Event Docs'
                                            })}
                                            <WithPermission permissions={[PERMISSIONS.unlockedCorporateActionContent]}>
                                                {this.renderNewStreamButton({
                                                    restricted,
                                                    isLoading,
                                                    type: 'corporate_action',
                                                    icon: 'corpAction',
                                                    label: 'Corporate Activity'
                                                })}
                                            </WithPermission>
                                            <WithPermission permissions={[PERMISSIONS.featureStreamsResearch]}>
                                                {this.renderNewStreamButton({
                                                    restricted,
                                                    isLoading,
                                                    type: 'research',
                                                    icon: 'briefcase',
                                                    label: 'Broker Research'
                                                })}
                                            </WithPermission>
                                            <WithPermission permissions={[PERMISSIONS.featureStreamsSpotlight]}>
                                                {this.renderNewStreamButton({
                                                    restricted,
                                                    isLoading,
                                                    type: 'spotlight',
                                                    icon: 'briefcase',
                                                    label: 'Corporate Activity'
                                                })}
                                            </WithPermission>
                                            <WithPermission permissions={[PERMISSIONS.featureUploads]}>
                                                {this.renderNewStreamButton({
                                                    restricted,
                                                    isLoading,
                                                    type: 'document',
                                                    icon: 'files',
                                                    label: 'Document Uploads'
                                                })}
                                            </WithPermission>
                                            {this.renderNewStreamButton({
                                                restricted,
                                                isLoading,
                                                type: 'news',
                                                icon: 'newspaper',
                                                label: 'News & Media'
                                            })}
                                            {this.renderNewStreamButton({
                                                restricted,
                                                isLoading,
                                                type: 'filings',
                                                icon: 'filings',
                                                label: 'Filings'
                                            })}
                                            {this.renderNewStreamButton({
                                                restricted,
                                                isLoading,
                                                type: 'events',
                                                icon: 'calendar',
                                                label: 'Event Schedules'
                                            })}
                                            {this.renderNewStreamButton({
                                                restricted,
                                                isLoading,
                                                type: 'bookmarks',
                                                icon: 'highlighter',
                                                label: 'Highlights'
                                            })}
                                        </Fragment>
                                    )}
                                </WithPermission>
                            </Div>
                            <Div styles={styles.newStreamSpacer} />
                        </Fragment>
                    )
                }
            </MediaQuery>
        );
    }

    render() {
        const {
            canPageLeft,
            canPageRight,
            dashDateRange,
            dashFilters,
            dashSearchTerm,
            dashSources,
            dashEquityScope,
            dashboardId,
            dashboardGuid,
            dashboardType,
            dragging,
            handleContainerRef,
            isCreator,
            onDeleteStream,
            onDragStart,
            onEditStream,
            onResizeStart,
            onResizeEnd,
            onSort,
            pageLeft,
            pageRight,
            passedStyles,
            resizing,
            sorting,
            streamDisplayMode,
            streams,
            styles,
            theme
        } = this.props;
        return (
            <Fragment>
                <Div className="streams" styles={styles.sortableWrapper}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => (
                            <Fragment>
                                {!m && canPageLeft && (
                                    <Div className="paging" styles={styles.leftArrow} onClick={pageLeft}>
                                        <Icon type="chevron02" color={theme.colors.gray06} />
                                    </Div>
                                )}
                                {!m && canPageRight && (
                                    <Div className="paging" styles={styles.rightArrow} onClick={pageRight}>
                                        <Icon type="chevron02" color={theme.colors.gray06} />
                                    </Div>
                                )}
                            </Fragment>
                        )}
                    </MediaQuery>
                    <Droppable droppableId="streamsContainer" type="streams" direction="horizontal">
                        {provided => (
                            <Div
                                styles={{
                                    ...styles.container,
                                    ...passedStyles,
                                    ...(dragging || resizing ? {} : styles.snap)
                                }}
                                ref={composeRefs(provided.innerRef, handleContainerRef)}
                                {...provided.droppableProps}
                            >
                                {streams.map(
                                    ({ averageDailyVolume, streamId, name, uxPreferences, streamType }, index) => {
                                        const StreamComponent = STREAM_MAP[streamType];
                                        const className = ['stream'];
                                        if (sorting) {
                                            className.push('sorting');
                                        }
                                        return (
                                            StreamComponent && (
                                                <Draggable
                                                    draggableId={streamId}
                                                    index={index}
                                                    key={`draggable-column-${streamId}-${streamType}`}
                                                    payload={{ onDragEnd: onSort, onDragStart }}
                                                >
                                                    {dragProvided => (
                                                        <StreamWrapper>
                                                            {({ componentVisibilityRef, wasVisible }) => (
                                                                <StreamComponent
                                                                    dashboardId={dashboardId}
                                                                    dashboardGuid={dashboardGuid}
                                                                    dashboardType={dashboardType}
                                                                    dashDateRange={dashDateRange}
                                                                    dashFilters={dashFilters}
                                                                    dashSearchTerm={dashSearchTerm}
                                                                    dashSources={dashSources}
                                                                    dashEquityScope={dashEquityScope}
                                                                    key={`${streamId}-${streamType}`}
                                                                    streamId={streamId}
                                                                    streamDisplayMode={streamDisplayMode}
                                                                    streamProps={{
                                                                        averageDailyVolume,
                                                                        className: className.join(' '),
                                                                        color: get(uxPreferences, 'color'),
                                                                        componentVisibilityRef,
                                                                        dragHandleProps: dragProvided.dragHandleProps,
                                                                        draggableProps: dragProvided.draggableProps,
                                                                        draggableRef: dragProvided.innerRef,
                                                                        headerDisabled: !isCreator,
                                                                        index,
                                                                        onEdit: onEditStream,
                                                                        onRemove: onDeleteStream,
                                                                        onResizeEnd,
                                                                        onResizeStart,
                                                                        title: name,
                                                                        streamType,
                                                                        uxPreferences,
                                                                        width: get(uxPreferences, 'width'),
                                                                        wasVisible
                                                                    }}
                                                                />
                                                            )}
                                                        </StreamWrapper>
                                                    )}
                                                </Draggable>
                                            )
                                        );
                                    }
                                )}
                                {provided.placeholder}
                                {this.renderNewStream()}
                                {this.renderEmptyMessage()}
                            </Div>
                        )}
                    </Droppable>
                </Div>
            </Fragment>
        );
    }
}

export const StreamsUI = compose(withStyleSheet(styleSheet))(Streams);
