import { colors } from './colors';
import * as fonts from './fonts';
import { breakpoints } from './breakpoints';
import { margins } from './margins';
import * as utils from './utils';
import * as content from './content';
import { chart } from './charts';

export const theme = {
    colors,
    content,
    fonts,
    breakpoints,
    margins,
    chart,
    utils
};
