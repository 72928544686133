import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Redirect, withRouter, matchPath } from 'react-router-dom';
import { externalRoutes, routes } from 'routes';
import { graphqlClient } from 'provider';
import { config } from 'configuration';
import { appSetLoginRoute } from 'actions/app';
import { withGetUser, withTrackUserActivity } from 'graphql/user';
import { get } from 'utils';

export class AuthGate extends PureComponent {
    static displayName = 'AuthGateContainer';

    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired
        }).isRequired,
        setLoginRoute: PropTypes.func.isRequired,
        user: PropTypes.objectOf(PropTypes.any),
        userLoading: PropTypes.bool,
        userError: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    };

    static defaultProps = {
        user: null,
        userLoading: false,
        userError: null
    };

    constructor(props) {
        super(props);

        const pathname = get(props, 'location.pathname', '');
        const searchString = get(props, 'location.search', '');

        this.runAppcues = this.runAppcues.bind(this);
        this.enableApollo = this.enableApollo.bind(this);
        this.apolloEnabled = false;

        this.state = {
            lastPath: `${pathname}${searchString}`
        };
    }

    componentDidMount() {
        this.runAppcues();
        this.enableApollo();
    }

    componentDidUpdate(prevProps) {
        const { location, setLoginRoute, user } = this.props;
        const { lastPath } = this.state;
        this.enableApollo();
        if (user && window.sessionStorage.aieraIdpToken) {
            window.sessionStorage.removeItem('aieraIdpToken');
        }
        if (prevProps.location.pathname !== location.pathname) {
            this.runAppcues();
            if (!matchPath(location.pathname, { exact: false, path: externalRoutes.signIn, strict: false })) {
                this.setState({ lastPath: location.pathname });
            } else if (!matchPath(lastPath, { path: externalRoutes.signIn })) {
                setLoginRoute(lastPath);
            }
        }
    }

    runAppcues() {
        const { user } = this.props;
        if (!config.actingAsUser() && config.APPCUES_ENABLED && window.Appcues) {
            window.Appcues.page();
            if (user) {
                const {
                    createDate,
                    customerType,
                    email,
                    hasApp,
                    firstName,
                    lastActive,
                    lastName,
                    organization,
                    organizationAdmin,
                    organizationId,
                    permissions,
                    tier,
                    usedIcal,
                    userId
                } = user;
                window.Appcues.identify(userId, {
                    companyName: get(organization, 'name'),
                    createdDate: createDate,
                    customerType,
                    email,
                    firstName,
                    hasApp,
                    lastActive,
                    lastName,
                    organizationAdmin,
                    organizationId,
                    permissions,
                    privateRecordingCount: get(organization, 'privateRecordingCount', 0),
                    tier,
                    usedIcal,
                    userId
                });
            }
        }
    }

    enableApollo() {
        const { user } = this.props;
        const { isAdmin, userId, email } = user || {};

        if (isAdmin && !this.apolloEnabled && userId && email) {
            this.apolloEnabled = true;
            // eslint-disable-next-line no-underscore-dangle
            window.__APOLLO_CLIENT__ = graphqlClient;
        }
    }

    render() {
        const { children, user, userError, userLoading, location } = this.props;
        const onExternalPage = Object.values(externalRoutes).some(path => matchPath(location.pathname, { path }));
        const onSignoutPage = matchPath(location.pathname, { path: routes.signOut });
        const errors = get(userError, 'graphqlErrors', []);
        const authError = errors.some(e => String(get(e, 'statusCode', get(e, 'code'))) === '401');
        // Kick to login if not logged in
        // Don't redirect if already on login
        if (((!userLoading && !user) || authError) && !onExternalPage && !onSignoutPage) {
            return <Redirect to={routes.signOut} />;
        }

        // Redirect self-serve users that haven't completed checkout to the pricing page
        if (
            !onExternalPage &&
            get(user, 'status') === 'new' &&
            get(user, 'organization.isSelfServeBilling') &&
            !get(user, 'billingProductPriceId')
        ) {
            return <Redirect to={externalRoutes.pricing} />;
        }

        // Redirect logged in users that are registered but not yet verified to the confirm email page
        if (!onExternalPage && get(user, 'status') === 'new') {
            return <Redirect to={externalRoutes.confirmEmail} />;
        }

        return children;
    }
}

const mapDispatchToProps = {
    setLoginRoute: appSetLoginRoute
};

const userEvents = ['mousemove', 'touchmove', 'scroll', 'click'];
export const AuthGateContainer = compose(
    withRouter,
    connect(undefined, mapDispatchToProps),
    // This User Query must match the success query
    // returned from the user login mutation. Since this
    // is polling, and drives the authgate - it will usually
    // begin as null for the user. But after login, if the mutation
    // success query is the same, it will update the user value here.
    // otherwise it will redirect to logout.
    withGetUser({
        alias: 'authGate',
        fetchPolicy: 'network-only',
        variables: { withLoginUrl: true, withDetails: true, withOrganization: true },
        pollInterval: 30000
    }),
    withTrackUserActivity(),
    lifecycle({
        componentDidMount() {
            this.props.trackActivity(true);
            this.listener = () => {
                const { windowActive, trackActivity } = this.props;
                // There's already a visibility check in `trackActivity`, but for this we additionally
                // want to check that the tab is active so that moving your mouse over an inactive
                // tab doesn't trigger until you actually interact with the tab.
                if (windowActive) {
                    trackActivity();
                }
            };
            userEvents.forEach(event => window.addEventListener(event, this.listener, true));
        },
        componentDidUpdate({ windowActive: prevWindowActive, user: prevUser }) {
            const { windowActive, trackActivity, user } = this.props;
            if (windowActive && !prevWindowActive) {
                trackActivity();
            }
            if (get(user, 'userId') !== get(prevUser, 'userId')) {
                trackActivity(true);
            }
        },
        componentWillUnmount() {
            userEvents.forEach(event => window.removeEventListener(event, this.listener, true));
        }
    })
)(AuthGate);
