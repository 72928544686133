import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { A } from 'components/A';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class Card extends PureComponent {
    static propTypes = {
        containerStyles: PropTypes.objectOf(PropTypes.any).isRequired,
        externalTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isHovering: PropTypes.bool.isRequired,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func.isRequired,
        onMouseLeave: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        sentiment: PropTypes.string,
        showHighlight: PropTypes.bool.isRequired,
        showSubContent: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        subContentButtonContent: PropTypes.objectOf(PropTypes.any),
        subContentButtonText: PropTypes.string,
        subContentRows: PropTypes.arrayOf(PropTypes.any),
        subContentSubtitle: PropTypes.string,
        subContentTitle: PropTypes.string.isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        toggleSubContent: PropTypes.func.isRequired
    };

    static defaultProps = {
        externalTo: null,
        name: undefined,
        onClick: null,
        passedStyles: {},
        sentiment: null,
        subContentButtonContent: null,
        subContentButtonText: null,
        subContentRows: [],
        subContentSubtitle: '',
        to: null
    };

    renderSubContent() {
        const {
            styles,
            theme,
            showSubContent,
            subContentButtonContent,
            subContentButtonText,
            subContentRows,
            subContentSubtitle,
            subContentTitle,
            toggleSubContent
        } = this.props;

        if (!subContentRows || !subContentRows.length) {
            return null;
        }

        const extraCard = <Div styles={styles.extraCardBorder} />;

        return (
            <Fragment>
                <Div
                    onClick={toggleSubContent}
                    styles={showSubContent ? styles.toggleSubContentBtnOpen : styles.toggleSubContentBtn}
                    className="subContentButton"
                    data-tname="additional-matches"
                >
                    <Icon type="chevron02" color={theme.colors.black01} styles={styles.chevron} />
                    {showSubContent ? (
                        <Div styles={styles.subContentTitle}>
                            <Text size={1} weight="medium">
                                {subContentTitle}
                            </Text>
                            <Text size={0} styles={styles.subContentTitleText}>
                                {subContentSubtitle}
                            </Text>
                        </Div>
                    ) : (
                        <Fragment>
                            <Text styles={styles.subContentButtonText} size={1}>
                                {subContentButtonText ||
                                    `${pluralize('additional match', subContentRows.length, true)}`}
                            </Text>
                            {subContentButtonContent}
                        </Fragment>
                    )}
                </Div>
                {showSubContent && subContentRows}
                {!showSubContent && (
                    <Div className="extraCardEdges">
                        {subContentRows.length > 0 && extraCard}
                        {subContentRows.length > 1 && extraCard}
                        {subContentRows.length > 2 && extraCard}
                    </Div>
                )}
            </Fragment>
        );
    }

    render() {
        const {
            externalTo,
            isHovering,
            to,
            styles,
            passedStyles,
            containerStyles,
            name,
            onClick,
            onMouseEnter,
            onMouseLeave,
            sentiment,
            showHighlight,
            subContentRows,
            children,
            theme
        } = this.props;
        const highlightStyles = showHighlight ? styles.highlight : {};
        const cardStyles = {
            ...(subContentRows && subContentRows.length > 0 ? styles.primaryCardSubContent : styles.primaryCard),
            ...(to ? styles.pointer : {})
        };
        const mobileCardStyles =
            subContentRows && subContentRows.length > 0 ? styles.primaryCardSubContentMobile : styles.primaryCardMobile;
        let sentimentStyles = styles.sentiment;
        if (sentiment === 'neg') sentimentStyles = { ...sentimentStyles, ...styles.sentimentNeg };
        if (sentiment === 'pos') sentimentStyles = { ...sentimentStyles, ...styles.sentimentPos };

        const Wrapper = to ? Link : externalTo ? A : Fragment;
        const wrapperProps = to ? { to } : externalTo ? { target: '_blank', href: externalTo } : null;

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Div
                        data-tname={name}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        styles={{ ...styles.container, ...containerStyles }}
                    >
                        <Wrapper {...wrapperProps}>
                            <Div
                                className="card"
                                styles={{ ...(m ? mobileCardStyles : cardStyles), ...highlightStyles, ...passedStyles }}
                                onClick={onClick}
                            >
                                {sentiment && <Div styles={sentimentStyles} />}
                                {typeof children === 'function' ? children({ isHovering }) : children}
                            </Div>
                        </Wrapper>
                        {this.renderSubContent()}
                    </Div>
                )}
            </MediaQuery>
        );
    }
}

export const CardUI = compose(withStyleSheet(styleSheet))(Card);
