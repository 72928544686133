export const styleSheet = theme => ({
    container: {},
    priceContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        svg: {
            margin: '0 0 1px 5px'
        }
    },
    priceChange: {
        marginLeft: '5px'
    },
    textGreen: {
        color: theme.colors.green03
    },
    textRed: {
        color: theme.colors.red01
    },
    label: {
        color: theme.colors.gray06,
        letterSpacing: 1,
        marginTop: 4
    },
    metrics: {
        marginTop: theme.margins.margin00,
        paddingTop: theme.margins.margin00,
        borderTop: `1px solid ${theme.colors.gray03}`,
        display: 'flex',
        justifyContent: 'space-between'
    }
});
