// COLORS
const black01 = '#100420';

const blue01 = '#4A90E2';
const blue02 = '#46A5D0';
const blue03 = '#5179AF';
const blue04 = '#1547A0';
const blue05 = '#F5F7FC';
const blue06 = '#87CEEB';
const blue07 = 'rgba(230, 242, 255, 0.75)';
const blue08 = '#007AFF';
const blue09 = '#f7fbff';
const blue10 = '#0073f1';
const blue11 = '#0066d6';
const blue12 = '#bdeaff';
const blue13 = '#559dff';
const blue14 = '#d4e6ff';
const blue15 = '#343d77';

const gray01 = '#E5E7EF';
const gray02 = '#cacedc';
const gray03 = '#F0F0F7';
const gray04 = '#A4A8B8';
const gray05 = '#DDE0EA';
const gray06 = '#717181';
const gray07 = '#D5D6DE';
const gray08 = '#43475A';
const gray09 = '#DADAE8';
const gray10 = '#F9F9FB';
const gray11 = '#333546';
const gray12 = '#262735';
const gray13 = '#212230';
const gray14 = '#2d2f3e';
const gray15 = '#40435d';
const gray16 = '#3e4054';

const green01 = '#89D046';
const green02 = '#3BBA35';
const green03 = '#2F942A';
const green04 = '#20D218';
const green05 = '#F5FAF9';
const green06 = '#288623';
const green07 = '#1d6b19';
const green08 = '#cdf3cb';
const green09 = '#cff5c0';
const green10 = '#48b942';
const green11 = '#e8ffe7';

const orange01 = '#EB434D';
const orange02 = '#FE590C';
const orange03 = '#FC740D';
const orange04 = '#FF5701';
const orange05 = '#d65001';
const orange06 = '#ef5100';
const orange07 = '#d34700';
const orange08 = '#b93f00';

const pink01 = '#D04670';
const pink02 = '#C31A00';

const purple01 = '#443350';
const purple02 = '#291C33';
const purple03 = '#5e5494';
const purple04 = '#F3E2FD';
const purple05 = '#64379e';

const red01 = '#C9251E';
const red02 = '#E8191E';
const red03 = '#FAF5F5';
const red04 = '#fdf5f5';
const red05 = '#ffdada';
const red06 = '#ff4a2e';
const red07 = '#eba7b9';
const red08 = '#DE274A';
const red09 = '#b31316';
const red10 = '#FF6060';

const white01 = '#FFFFFF';
const white02 = '#FAFAFC';
const white03 = '#F5F5F8';

const yellow01 = '#FFFEF5';
const yellow02 = '#F5A623';
const yellow03 = 'rgba(255, 231, 14, 0.06)';
const yellow04 = '#F3E211';
const yellow05 = '#e8d20e';
const yellow06 = '#fff8ba';
const yellow07 = '#ffeb3f';
const yellow08 = '#b3a100';
const yellow09 = '#ffe400';
const yellow10 = '#faf2cf';
const yellow11 = '#edb53b';
const yellow12 = '#a67203';

// GRADIENTS
const gradient01Old = `linear-gradient(-135deg, ${orange01} 0%, ${orange02} 100%)`;
const gradient01 = `linear-gradient(-135deg, ${pink02} 0%, ${orange02} 100%)`;
const gradient02 = `linear-gradient(-135deg, #FF5000 0%, #D60D3C 100%)`;
const gradient03 = `linear-gradient(-135deg, #F27121 0%, #E94057 50%, #6B0E63 100%)`;
const gradient03b = `linear-gradient(-135deg, #e86c1f 0%, #de3c52 50%, #6B0E63 100%)`;
const gradient03c = `linear-gradient(-135deg, #cc5f1c 0%, #b12f41 50%, #6B0E63 100%)`;
const gradient04 = `linear-gradient(90deg, ${green02} 0%, ${green01} 100%);`;

const contentColors = {
    backArrow: purple01,
    backLink: purple01,
    buttonForm: purple01,
    buttonFormHover: purple02,
    buttonFormActive: purple03,
    contextPaneBg: gray03,
    contextPaneText: gray06,
    error: pink01,
    externalLink: blue01,
    headerBg: white02,
    iconGray: gray02,
    info: blue02,
    input: gray03,
    inputError: pink01,
    inputFocus: blue08,
    inputIconDefault: gray02,
    inputLabel: purple01,
    inputPlaceholder: gray02,
    convictionHigh: '#fff9ce',
    convictionLow: '#dfdfec',
    convictionMed: '#dcceff',
    linkColorSecondary: gray04,
    linkHover: blue01,
    statusBanner: gray11,
    statusBannerError: red01,
    success: green01,
    tickerTagBorder: gray07,
    text: black01,
    textGray: gray04
};

export const colors = {
    black01,
    blue01,
    blue02,
    blue03,
    blue04,
    blue05,
    blue06,
    blue07,
    blue08,
    blue09,
    blue10,
    blue11,
    blue12,
    blue13,
    blue14,
    blue15,
    gradient01,
    gradient01Old,
    gradient02,
    gradient03,
    gradient03b,
    gradient03c,
    gradient04,
    gray01,
    gray02,
    gray03,
    gray04,
    gray05,
    gray06,
    gray07,
    gray08,
    gray09,
    gray10,
    gray11,
    gray12,
    gray13,
    gray14,
    gray15,
    gray16,
    green01,
    green02,
    green03,
    green04,
    green05,
    green06,
    green07,
    green08,
    green09,
    green10,
    green11,
    orange01,
    orange02,
    orange03,
    orange04,
    orange05,
    orange06,
    orange07,
    orange08,
    pink01,
    pink02,
    purple01,
    purple02,
    purple03,
    purple04,
    purple05,
    red01,
    red02,
    red03,
    red04,
    red05,
    red06,
    red07,
    red08,
    red09,
    red10,
    white01,
    white02,
    white03,
    yellow01,
    yellow02,
    yellow03,
    yellow04,
    yellow05,
    yellow06,
    yellow07,
    yellow08,
    yellow09,
    yellow10,
    yellow11,
    yellow12,
    ...contentColors
};
