export const styleSheet = theme => {
    const textarea = {
        resize: 'none',
        overflow: 'auto',
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
        lineHeight: 'inherit',
        width: '100%',
        padding: 0,
        margin: 0,
        marginBottom: -5,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            fontSize: 16
        }
    };
    return {
        container: {
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            textarea,
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                '.actionMenu-hover-btn': {
                    visibility: 'visible'
                }
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                wordBreak: 'break-word',
                flexDirection: 'column'
            }
        },
        containerSelected: {
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            textarea,
            backgroundColor: theme.colors.blue09,
            boxShadow: `inset 3px 0 0 0 ${theme.colors.blue08}`,
            ':hover': {
                '.actionMenu-hover-btn': {
                    visibility: 'visible'
                }
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                wordBreak: 'break-word',
                flexDirection: 'column'
            }
        },
        timeStamp: {
            padding: '5px 7px',
            letterSpacing: '1px',
            marginTop: '4px'
        },
        timeStampSelected: {
            display: 'inline-block',
            background: theme.colors.blue08,
            boxShadow: '0 1px 2px 0 rgba(0,0,0,0.18)',
            borderRadius: '3px',
            color: theme.colors.white01,
            padding: '5px 7px',
            letterSpacing: '1px',
            marginTop: '2px'
        },
        timeStampContainer: {
            flexShrink: 0,
            width: '98px'
        },
        footer: {
            display: 'flex'
        },
        editIcons: {
            padding: '4px 2px 4px',
            display: 'flex'
        },
        editIcon: {
            cursor: 'pointer',
            padding: '0px 6px',
            height: 16,
            opacity: 0.5,
            ':hover': {
                opacity: 0.7
            }
        },
        flex: {
            flex: 1
        },
        saving: {
            opacity: 0.4,
            ':hover': {
                opacity: 0.4
            }
        },
        savingLoader: {
            marginRight: theme.margins.margin00
        },
        transcriptText: {
            flex: 1,
            cursor: 'pointer',
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.3em',
            padding: '5px 8px',
            ':hover': {
                color: theme.colors.black01
            }
        },
        transcriptTextSelected: {
            cursor: 'default',
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.3em',
            backgroundColor: theme.colors.blue07,
            borderRadius: '3px',
            padding: '5px 8px',
            width: '100%'
        },
        hoverActionMenu: {
            zIndex: 6,
            position: 'absolute',
            top: -12,
            right: 12,
            display: 'flex',
            visibility: 'hidden',
            backgroundColor: theme.colors.white02,
            ':hover': {
                backgroundColor: theme.colors.white01,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        }
    };
};
