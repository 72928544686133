import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { styleSheet } from './stylesheet';

class EventGroupAutocomplete extends PureComponent {
    static propTypes = {
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        multi: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    };

    static defaultProps = {
        initialSearchTerm: null,
        label: undefined,
        multi: false,
        passedStyles: {},
        value: null
    };

    render() {
        const {
            initialSearchTerm,
            label,
            multi,
            styles,
            passedStyles,
            name,
            options,
            placeholder,
            onChange,
            onSearch,
            value
        } = this.props;
        return (
            <Autocomplete
                clearOnSelect
                icon="list02"
                initialSearchTerm={initialSearchTerm}
                label={label}
                menuStyles={styles.menu}
                multi={multi}
                name={name}
                onChange={onChange}
                onSearch={onSearch}
                options={options}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                strictOptions={false}
                value={value}
            />
        );
    }
}

export const EventGroupAutocompleteUI = compose(withStyleSheet(styleSheet))(EventGroupAutocomplete);
