export const styleSheet = theme => {
    return {
        container: {
            margin: `5px ${theme.margins.margin01} 0`,
            zIndex: 6, // show over the scroll to top button
            position: 'relative'
        },
        lockIcon: {
            position: 'relative',
            top: 0,
            right: -8
        },
        corpAction: {
            padding: '10px 0 16px',
            borderBottom: `1px solid ${theme.colors.gray03}`,
            display: 'flex',
            flexDirection: 'column',
            a: {
                lineHeight: '1.3em'
            }
        },
        link: {
            backgroundColor: 'transparent'
        },
        actionDetails: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 4
        },
        publishDate: {
            color: theme.colors.gray04,
            whiteSpace: 'nowrap',
            marginRight: theme.margins.margin01
        },
        spotlightTag: {
            height: 20,
            display: 'flex',
            alignItems: 'center',
            letterSpacing: 1,
            padding: '0 8px',
            marginRight: theme.margins.margin00,
            borderRadius: 20,
            overflow: 'hidden',
            backgroundColor: theme.colors.gray03,
            color: theme.colors.gray08
        },
        spotlightSubTag: {
            height: 20,
            display: 'flex',
            alignItems: 'center',
            marginLeft: 4,
            marginRight: -8,
            padding: '0 8px',
            borderLeft: 'solid 1px white',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            backgroundColor: theme.colors.gray05,
            color: theme.colors.gray08
        },
        upsellContent: {
            maxWidth: 510,
            cursor: 'pointer',
            padding: '14px 16px',
            backgroundColor: theme.colors.yellow09,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 6,
            boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.06)',
            margin: `${theme.margins.margin02} auto`,
            p: {
                lineHeight: '1.3em'
            },
            ':hover': {
                backgroundColor: theme.colors.yellow07
            },
            ':active': {
                backgroundColor: theme.colors.yellow08
            }
        },
        upsellTitle: {
            marginBottom: theme.margins.margin00,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            svg: {
                marginBottom: 2,
                marginRight: 8
            }
        }
    };
};
