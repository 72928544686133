import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { statusBannerFire } from 'actions/statusBanner';
import { bookmarkFragment } from 'graphql/fragments/bookmarks';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation ShareBookmark($bookmarkId: ID!, $shared: Boolean!) {
                    shareBookmark(input: { bookmarkId: $bookmarkId, shared: $shared }) {
                        bookmark {
                            ...bookmark
                        }
                    }
                }
                ${bookmarkFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    setVisibility: ({ bookmarkId, shared }) => {
                        return mutate({
                            variables: {
                                bookmarkId,
                                shared
                            }
                        })
                            .then(() => {
                                setStatusBanner('Highlight updated successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error updating highlight: ${error}`, 'error', 'circleX');
                                throw error;
                            });
                    }
                })
            }
        )
    );
