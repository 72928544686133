import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { LocaleAutocompleteUI } from './ui';

export class LocaleAutocomplete extends PureComponent {
    static displayName = 'LocaleAutocompleteContainer';

    static propTypes = {
        defaultValue: PropTypes.string,
        error: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        locales: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
    };

    static defaultProps = {
        defaultValue: undefined,
        error: undefined,
        initialSearchTerm: undefined,
        label: undefined,
        locales: [],
        loading: false,
        multi: false,
        name: 'localeAutocomplete',
        onChange: undefined,
        placeholder: 'Select a language',
        styles: {},
        useTags: false,
        value: undefined
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange({ event, value }) {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange({ event, name, value });
        }
    }

    render() {
        const {
            defaultValue,
            error,
            initialSearchTerm,
            label,
            locales,
            loading,
            multi,
            name,
            placeholder,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <LocaleAutocompleteUI
                defaultValue={defaultValue}
                error={error}
                initialSearchTerm={initialSearchTerm}
                label={label}
                locales={locales}
                loading={loading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const LocaleAutocompleteContainer = compose(withData())(LocaleAutocomplete);
