import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
// import XDate from 'xdate';
import { toDateTimeString, prettyLineBreak, prettyEventType } from 'utils';

const ReportPDF = ({ Document, Link, Page, PDFText, styles, View, Image, title, created, blocks }) => (
    <Document>
        <Page size="A4" style={styles.pdfPage}>
            <View style={styles.pdfDisclaimer}>
                <PDFText style={styles.pdfDisclaimerText}>
                    Reports are generated using artifical intelligence, and may not be perfect.
                </PDFText>
            </View>
            <View style={styles.pdfHeader}>
                <PDFText style={styles.pdfTitle}>{title}</PDFText>
                {created && (
                    <PDFText style={styles.pdfSubTitle}>Generated by Aiera, on {toDateTimeString(created)}</PDFText>
                )}
            </View>
            {blocks.map(
                ({ localTicker, eventDate, eventId, content, eventType, url, title: blockTitle, quarter, year }) => (
                    <View wrap={false} style={styles.pdfBlock} key={eventId}>
                        <Link href={url} style={styles.pdfBlockTitle}>
                            {localTicker}, {prettyEventType(eventType)}
                            {quarter && `, Q${quarter} ${year}`}
                        </Link>
                        <PDFText style={styles.pdfBlockSummaryTitle}>
                            {blockTitle.length > 120 ? blockTitle : prettyLineBreak(blockTitle, 0.6, 68)}
                        </PDFText>
                        <PDFText style={styles.pdfBlockDate}>{eventDate}</PDFText>
                        {content.map(txt => (
                            <PDFText key={txt.slice(0, 10)} style={styles.pdfBlockText}>
                                {txt}
                            </PDFText>
                        ))}
                    </View>
                )
            )}
            <View style={styles.pdfFooter} fixed>
                <Link href="http://www.aiera.com" style={styles.pdfWatermarkText}>
                    Report generated on Aiera.com
                </Link>
                <Link href="http://www.aiera.com" style={{ textDecoration: 'none' }}>
                    <Image src="/static/nohash/aiera-logo.jpg" style={{ width: 70 }} />
                </Link>
            </View>
        </Page>
    </Document>
);

ReportPDF.propTypes = {
    Document: PropTypes.elementType.isRequired,
    Link: PropTypes.elementType.isRequired,
    Page: PropTypes.elementType.isRequired,
    PDFText: PropTypes.elementType.isRequired,
    View: PropTypes.elementType.isRequired,
    Image: PropTypes.elementType.isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.string,
    created: PropTypes.string,
    blocks: PropTypes.arrayOf(PropTypes.any)
};

ReportPDF.defaultProps = {
    title: '',
    created: '',
    blocks: []
};

export const generatePdfDocument = async ({ onComplete, styles, created, blocks, title }) => {
    await import('@react-pdf/renderer').then(async ({ Document, Page, Text, View, Link, Image, pdf }) => {
        const blob = await pdf(
            <ReportPDF
                Document={Document}
                Link={Link}
                Page={Page}
                PDFText={Text}
                styles={styles}
                View={View}
                Image={Image}
                title={title}
                created={created}
                blocks={blocks}
            />
        ).toBlob();
        onComplete();
        const fileName = `aiera-report-${title.replace(/\s+/g, '-').toLowerCase()}.pdf`;
        saveAs(blob, fileName);
    });
};
