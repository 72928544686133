import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import uuid from 'uuid/v4';
import { generatePath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { withReporting } from 'provider/reporting';
import { statusBannerFire } from 'actions/statusBanner';
import { STREAM_MENU_COLORS } from 'consts';
import { OPERATORS, TYPES } from 'consts/filters';
import { routes } from 'routes';
import { get, validateInputs, generateModalId } from 'utils';
import { mapDashFiltersToRules, mapDashRulesToFilters, mapFiltersToRules } from 'utils/streams';
import { withData } from './data';
import { DashboardFormUI } from './ui';

// Props for bulk stream creation
const INITIAL_STREAM_TAGGED_INPUT_KEYS = [`stream-tagged-input-${uuid()}`, `stream-tagged-input-${uuid()}`];
const STREAM_CONTENT_TYPE_FILTERS = ['news', 'transcript'].map(value => ({
    type: TYPES.type,
    operator: OPERATORS.is,
    value
}));

// Formik props
const INITIAL_VALUES = {
    name: '',
    description: '',
    tags: '',
    categories: '',
    [INITIAL_STREAM_TAGGED_INPUT_KEYS[0]]: '',
    [INITIAL_STREAM_TAGGED_INPUT_KEYS[1]]: ''
};
const INPUT_VALIDATION_RULES = [{ name: 'name' }];

export class DashboardForm extends PureComponent {
    static displayName = 'DashboardFormContainer';

    static propTypes = {
        categoryOptions: PropTypes.arrayOf(PropTypes.object),
        createDashboard: PropTypes.func.isRequired,
        createStream: PropTypes.func.isRequired,
        dashboard: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            searchable: PropTypes.bool.isRequired,
            tags: PropTypes.arrayOf(PropTypes.string),
            categories: PropTypes.arrayOf(PropTypes.string)
        }),
        dashboardId: PropTypes.string.isRequired,
        deleteDashboard: PropTypes.func.isRequired,
        errors: PropTypes.objectOf(PropTypes.any),
        handleBlur: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        isOpen: PropTypes.bool,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        sectionId: PropTypes.string,
        setFieldValue: PropTypes.func.isRequired,
        setStatusBanner: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        touched: PropTypes.objectOf(PropTypes.any),
        updateDashboard: PropTypes.func.isRequired,
        values: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        categoryOptions: [],
        dashboard: undefined,
        errors: {},
        isOpen: false,
        loading: false,
        sectionId: undefined,
        styles: {},
        touched: {},
        values: {}
    };

    constructor(props) {
        super(props);

        this.onAddStreamTaggedInput = this.onAddStreamTaggedInput.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEquityScopeChange = this.onEquityScopeChange.bind(this);
        this.onGalleryScopeChange = this.onGalleryScopeChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onRemoveStreamTaggedInput = this.onRemoveStreamTaggedInput.bind(this);
        this.onStreamTagChange = this.onStreamTagChange.bind(this);
        this.onStreamTagInputChange = this.onStreamTagInputChange.bind(this);
        this.onTagChange = this.onTagChange.bind(this);
        this.onTagEdit = this.onTagEdit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.showUpgradeModal = this.showUpgradeModal.bind(this);
        this.toggleSearchable = this.toggleSearchable.bind(this);
        this.toggleAdvanced = this.toggleAdvanced.bind(this);

        this.streamTaggedInputRefs = {
            [INITIAL_STREAM_TAGGED_INPUT_KEYS[0]]: createRef(),
            [INITIAL_STREAM_TAGGED_INPUT_KEYS[1]]: createRef()
        };

        this.state = {
            deleting: false,
            equityScope: null,
            galleryScope: null,
            hasChanges: false,
            loading: props.loading,
            searchable: get(props, 'dashboard.searchable', true),
            showAdvanced: false,
            streamTaggedInputs: INITIAL_STREAM_TAGGED_INPUT_KEYS,
            streamTags: {},
            submitting: false,
            tags: get(props, 'dashboard.tags', []),
            categories: get(props, 'dashboard.categories', [])
        };
    }

    componentDidMount() {
        const { dashboard } = this.props;
        if (dashboard) this.loadDashboard();
    }

    componentDidUpdate(prevProps) {
        const { dashboard: prevDashboard } = prevProps;
        const { dashboard } = this.props;

        if (prevDashboard !== dashboard && dashboard) {
            this.loadDashboard();
        }
    }

    loadDashboard() {
        const { dashboard, loading, setFieldValue } = this.props;

        setFieldValue('name', dashboard.name);
        setFieldValue('description', dashboard.description);

        this.setState({
            equityScope: get(mapDashRulesToFilters(get(dashboard, 'rules')), 'equityScope', null),
            galleryScope: get(mapDashRulesToFilters(get(dashboard, 'galleryRules')), 'equityScope', null),
            loading,
            searchable: get(dashboard, 'searchable', true),
            tags: get(dashboard, 'tags', []),
            categories: get(dashboard, 'categories', [])
        });
    }

    onAddStreamTaggedInput() {
        const key = `stream-tagged-input-${uuid()}`;
        this.streamTaggedInputRefs[key] = createRef();
        this.setState(({ streamTaggedInputs }) => ({
            streamTaggedInputs: [...streamTaggedInputs, key]
        }));
    }

    onCancel() {
        const { hasChanges } = this.state;
        const { onClose } = this.props;
        let confirm = true;
        if (hasChanges) confirm = window.confirm('You have unsaved changes. Are you sure you want to cancel?');
        if (confirm) onClose();
    }

    onChange(event) {
        const { handleChange } = this.props;
        this.setState({ hasChanges: true });
        handleChange(event);
    }

    onCreate() {
        const { galleryScope, equityScope, searchable, streamTags, tags, categories } = this.state;
        const {
            createDashboard,
            createStream,
            history,
            onClose,
            values,
            reporter,
            sectionId,
            setStatusBanner
        } = this.props;
        const input = {
            name: values.name,
            description: values.description,
            searchable,
            sectionId
        };
        const streamInputs = [];

        if (tags.length) {
            input.tags = tags;
        }

        if (categories.length) {
            input.categories = categories;
        }

        if (equityScope && equityScope.length) {
            input.rules = mapDashFiltersToRules({ equityScope });
        }

        if (galleryScope && galleryScope.length) {
            input.galleryRules = mapDashFiltersToRules({ equityScope: galleryScope });
        }

        // Bulk stream creation
        if (!isEmpty(streamTags)) {
            Object.values(streamTags).forEach((searchTerms, idx) => {
                if (searchTerms.length) {
                    const searchTermFilter = {
                        type: TYPES.searchTerm,
                        operator: OPERATORS.is,
                        value: searchTerms
                    };
                    streamInputs.push({
                        name: searchTerms[0],
                        rules: mapFiltersToRules([searchTermFilter, ...STREAM_CONTENT_TYPE_FILTERS]),
                        streamType: 'content',
                        uxPreferences: {
                            color: STREAM_MENU_COLORS[idx + 1]
                        }
                    });
                }
            });
        }

        this.setState({ submitting: true }, () => {
            createDashboard(input)
                .then(({ id }) => {
                    if (streamInputs.length) {
                        return Promise.all(streamInputs.map(i => createStream({ ...i, dashboardIds: [id] }))).then(
                            () => id
                        );
                    }
                    return id;
                })
                .then(id => {
                    this.setState({ submitting: false }, () => {
                        onClose();
                        const streamMsg = streamInputs.length
                            ? ` and ${pluralize('search', streamInputs.length, true)} `
                            : ' ';
                        setStatusBanner(`Monitor${streamMsg}created successfully!`);
                        history.push(generatePath(routes.dashboard, { dashboardId: id }));

                        // Track Creating a Monitor
                        reporter.track(reporter.actions.submit, reporter.objects.monitorCreate, {
                            component: 'DashboardForm',
                            dashboardId: id
                        });
                    });
                })
                .catch(error => {
                    this.setState({ submitting: false }, () => {
                        setStatusBanner(`Error creating monitor: ${error}`, 'error', 'circleX');
                    });
                });
        });
    }

    onDelete() {
        const { history, dashboardId, deleteDashboard, onClose, reporter } = this.props;
        const confirmDelete = window.confirm(
            'Deleting this monitor will delete its saved searches as well. Are you sure you want to continue?'
        );

        if (confirmDelete) {
            this.setState({ deleting: true }, () => {
                deleteDashboard(dashboardId)
                    .then(() => {
                        this.setState({ deleting: false }, () => {
                            onClose();
                            history.push(routes.dashboardHome);
                            // Track deleting a Monitor
                            reporter.track(reporter.actions.submit, reporter.objects.monitorDelete, {
                                component: 'DashboardForm',
                                dashboardId
                            });
                        });
                    })
                    .catch(() => {
                        this.setState({ deleting: false });
                    });
            });
        }
    }

    onKeyDown(event) {
        const { key, target } = event;

        if (key === 'Escape') {
            const { setFieldValue } = this.props;
            const { name } = target;
            setFieldValue(name, '');
            target.focus();
            this.setState({ hasChanges: true });
        }
    }

    onRemoveStreamTaggedInput(key) {
        this.setState(({ streamTaggedInputs, streamTags }) => {
            // Remove this stream group's tags from state
            const tags = { ...streamTags };
            if (tags[key]) {
                delete tags[key];
            }

            // Remove the tagged input's ref when there are more than the defaults
            if (streamTaggedInputs.length > 2) {
                const inputRefs = { ...this.streamTaggedInputRefs };
                if (inputRefs[key]) {
                    delete inputRefs[key];
                }
                this.streamTaggedInputRefs = { ...inputRefs };
            }

            return {
                streamTaggedInputs:
                    streamTaggedInputs.length > 2
                        ? streamTaggedInputs.filter(inputKey => inputKey !== key)
                        : streamTaggedInputs,
                streamTags: { ...tags }
            };
        });
    }

    onGalleryScopeChange({ value }) {
        this.setState({
            galleryScope: value,
            hasChanges: true
        });
    }

    onEquityScopeChange({ value }) {
        this.setState({
            equityScope: value,
            hasChanges: true
        });
    }

    onStreamTagChange({ name, value }) {
        const { streamTags } = this.state;
        const { setFieldValue } = this.props;

        this.setState(({ streamTags: prevStreamTags }) => ({
            hasChanges: true,
            streamTags: {
                ...prevStreamTags,
                [name]: value
            }
        }));
        setFieldValue(name, '');

        // Scroll to bottom of tags container (only when adding new tags)
        const isAdding = get(value, 'length', 0) > get(streamTags, `[${name}].length`, 0);
        // Find the scrolling container from the input ref (two levels up)
        const node = get(this.streamTaggedInputRefs, `[${name}].current.parentNode.parentNode`);
        if (node && isAdding) {
            node.scrollTop = node.scrollHeight;
        }
    }

    onStreamTagInputChange({ name, value }) {
        const { setFieldValue } = this.props;
        setFieldValue(name, value);
    }

    onCategoryChange({ value }) {
        const { setFieldValue } = this.props;
        this.setState({
            hasChanges: true,
            categories: value
        });
        setFieldValue('categories', '');
    }

    onTagChange({ value }) {
        const { setFieldValue } = this.props;
        this.setState({
            hasChanges: true,
            tags: value
        });
        setFieldValue('tags', '');
    }

    onTagEdit() {
        this.setState({ hasChanges: true });
    }

    onUpdate() {
        const { galleryScope, equityScope, searchable, tags, categories } = this.state;
        const { dashboard, onClose, updateDashboard, values, reporter } = this.props;
        const dashboardId = get(dashboard, 'id');

        if (dashboardId) {
            const input = {
                dashboardId,
                name: values.name,
                description: values.description,
                searchable
            };

            if (tags.length) {
                input.tags = tags;
            }

            if (categories.length) {
                input.categories = categories;
            }

            if (equityScope && equityScope.length) {
                input.rules = mapDashFiltersToRules({ equityScope });
            }

            if (galleryScope && galleryScope.length) {
                input.galleryRules = mapDashFiltersToRules({ equityScope: galleryScope });
            }

            this.setState({ submitting: true }, () => {
                updateDashboard(input)
                    .then(() => {
                        this.setState({ submitting: false }, () => {
                            onClose();
                            // Track updating a Monitor
                            reporter.track(reporter.actions.submit, reporter.objects.monitorUpdate, {
                                component: 'DashboardForm',
                                dashboardId
                            });
                        });
                    })
                    .catch(() => {
                        this.setState({ submitting: false });
                    });
            });
        }
    }

    showUpgradeModal() {
        const { history, location } = this.props;
        history.push(generateModalId({ location, id: 'dashboardRequest', type: 'upgrade' }));
    }

    toggleSearchable() {
        this.setState(({ searchable }) => ({
            hasChanges: true,
            searchable: !searchable
        }));
    }

    toggleAdvanced() {
        this.setState(({ showAdvanced }) => ({
            showAdvanced: !showAdvanced
        }));
    }

    render() {
        const {
            styles,
            categoryOptions,
            dashboardId,
            errors,
            handleBlur,
            isOpen,
            onClose,
            touched,
            values
        } = this.props;
        const {
            categories,
            deleting,
            equityScope,
            galleryScope,
            hasChanges,
            loading,
            searchable,
            showAdvanced,
            streamTaggedInputs,
            streamTags,
            submitting,
            tags
        } = this.state;
        return (
            <DashboardFormUI
                buttonsDisabled={deleting || loading || submitting}
                categories={categories}
                categoryOptions={categoryOptions}
                dashboardId={dashboardId}
                deleting={deleting}
                editing={dashboardId !== 'new'}
                equityScope={equityScope}
                errors={errors}
                galleryScope={galleryScope}
                isOpen={isOpen}
                isSubmitButtonDisabled={!hasChanges || !values.name || Object.keys(errors).length > 0}
                onAddStreamTaggedInput={this.onAddStreamTaggedInput}
                onBlur={handleBlur}
                onCancel={this.onCancel}
                onCategoryChange={this.onCategoryChange}
                onChange={this.onChange}
                onClose={onClose}
                onCreate={this.onCreate}
                onDelete={this.onDelete}
                onEquityScopeChange={this.onEquityScopeChange}
                onGalleryScopeChange={this.onGalleryScopeChange}
                onKeyDown={this.onKeyDown}
                onRemoveStreamTaggedInput={this.onRemoveStreamTaggedInput}
                onStreamTagChange={this.onStreamTagChange}
                onStreamTagInputChange={this.onStreamTagInputChange}
                onTagChange={this.onTagChange}
                onTagEdit={this.onTagEdit}
                onUpdate={this.onUpdate}
                searchable={searchable}
                showAdvanced={showAdvanced}
                showUpgradeModal={this.showUpgradeModal}
                streamTaggedInputRefs={this.streamTaggedInputRefs}
                streamTaggedInputs={streamTaggedInputs}
                streamTags={streamTags}
                styles={styles}
                submitting={submitting}
                tags={tags}
                toggleAdvanced={this.toggleAdvanced}
                toggleSearchable={this.toggleSearchable}
                touched={touched}
                values={values}
            />
        );
    }
}

export const DashboardFormContainer = compose(
    connect(undefined, { setStatusBanner: statusBannerFire }),
    withRouter,
    withData(),
    withReporting(),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ dashboard }) =>
            dashboard
                ? {
                      ...INITIAL_VALUES,
                      name: get(dashboard, 'name', ''),
                      description: get(dashboard, 'description', '')
                  }
                : INITIAL_VALUES,
        validate: (loading, values) => (loading ? {} : { ...validateInputs(values, INPUT_VALIDATION_RULES) })
    })
)(DashboardForm);
