import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';

import { TagAutocompleteUI } from './ui';

export class TagAutocomplete extends PureComponent {
    static displayName = 'TagAutocompleteContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        initialSearchTerm: PropTypes.string,
        multi: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onPlainText: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        options: PropTypes.arrayOf(PropTypes.any),
        placeholder: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    };

    static defaultProps = {
        autoFocus: false,
        initialSearchTerm: null,
        styles: undefined,
        multi: false,
        options: [],
        onPlainText: undefined,
        placeholder: 'Select tag',
        name: 'tagAutocomplete',
        value: null
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange({ event, name, value }) {
        const { onChange, options } = this.props;
        const label = get(
            options.find(({ value: id }) => id === value),
            'label'
        );

        onChange({ event, name, value, label });
    }

    render() {
        const {
            autoFocus,
            initialSearchTerm,
            multi,
            styles,
            placeholder,
            options,
            onPlainText,
            name,
            value
        } = this.props;
        return (
            <TagAutocompleteUI
                autoFocus={autoFocus}
                initialSearchTerm={initialSearchTerm}
                multi={multi}
                onChange={this.onChange}
                onPlainText={onPlainText}
                name={name}
                options={options}
                placeholder={placeholder}
                styles={styles}
                value={value}
            />
        );
    }
}

export const TagAutocompleteContainer = compose(withData())(TagAutocomplete);
