import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { popoverPDFHide } from 'actions/popoverPDF';
import { PopoverPDFUI } from './ui';

export class PopoverPDF extends PureComponent {
    static displayName = 'PopoverPDFContainer';

    static propTypes = {
        hidePopover: PropTypes.func.isRequired,
        url: PropTypes.string
    };

    static defaultProps = {
        url: null
    };

    constructor(props) {
        super(props);

        this.setResizing = this.setResizing.bind(this);

        this.state = {
            url: props.url,
            resizing: false
        };
    }

    componentDidUpdate({ url: prevUrl }) {
        const { url } = this.props;
        if (url !== prevUrl) {
            this.setState(
                {
                    url: null
                },
                () => {
                    this.setState({ url });
                }
            );
        }
    }

    setResizing(resizing) {
        this.setState({ resizing });
    }

    render() {
        const { hidePopover } = this.props;
        const { url, resizing } = this.state;

        return <PopoverPDFUI url={url} hidePopover={hidePopover} resizing={resizing} setResizing={this.setResizing} />;
    }
}

const mapStateToProps = ({ PopoverPDF: Popover }) => {
    const { url } = Popover;
    return {
        url
    };
};
const mapDispatchToProps = {
    hidePopover: popoverPDFHide
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PopoverPDF);

export const PopoverPDFContainer = compose()(connectedComponent);
