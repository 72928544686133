import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { ContentTabs } from 'components/ContentTabs';
import { CreateDashFromTermTooltip } from 'components/CreateDashFromTermTooltip';
import { CreateStreamFromTermTooltip } from 'components/CreateStreamFromTermTooltip';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { CAPABILITIES } from 'consts';
import { get, truncateString } from 'utils';
import { CompanyResults } from './CompanyResults';
import { ConferenceResults } from './ConferenceResults';
import { EventResults } from './EventResults';
import { FilingResults } from './FilingResults';
import { MonitorResults } from './MonitorResults';
import { NewsResults } from './NewsResults';
import { SpotlightResults } from './SpotlightResults';
import { TranscriptResults } from './TranscriptResults';
import { styleSheet } from './stylesheet';

const PERMISSIONED_CONTENT_TYPES = ['filing', 'news'];
const RESULTS_ELEMENT_MAP = {
    companies: CompanyResults,
    conferences: ConferenceResults,
    events: EventResults,
    filing: FilingResults,
    monitors: MonitorResults,
    news: NewsResults,
    spotlight: SpotlightResults,
    transcripts: TranscriptResults
};

class Search extends PureComponent {
    static propTypes = {
        clearHistory: PropTypes.func.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightResult: PropTypes.func.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        indexElement: PropTypes.func.isRequired,
        inputRef: PropTypes.objectOf(PropTypes.object).isRequired,
        loading: PropTypes.bool.isRequired,
        loadingResults: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        monitorCapabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
        monitorName: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        onHideAutocomplete: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSearchClear: PropTypes.func.isRequired,
        onShowAutocomplete: PropTypes.func.isRequired,
        resultTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
        scrollRef: PropTypes.func.isRequired,
        searchHistory: PropTypes.arrayOf(PropTypes.string).isRequired,
        searchInMonitor: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.any).isRequired,
        searchTerm: PropTypes.string.isRequired,
        selectedResultType: PropTypes.string.isRequired,
        selectResultType: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleAllResults: PropTypes.func.isRequired
    };

    static defaultProps = {
        highlightedResult: null,
        monitorName: undefined
    };

    constructor(props) {
        super(props);

        this.renderVerticalResults = this.renderVerticalResults.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
    }

    renderButtons(hideTooltip) {
        const { monitorName, searchResults, searchTerm, styles, theme } = this.props;
        const dashTooltipYOffset = searchResults.length > 5 ? -102 : -30;
        const streamTooltipYOffset = searchResults.length > 5 ? -259 : -30;
        return (
            <Div styles={styles.buttonContainer}>
                <CreateDashFromTermTooltip onSubmit={hideTooltip} searchTerm={searchTerm} yOffset={dashTooltipYOffset}>
                    {({ showTooltip }) => (
                        <ActionButton
                            styles={styles.iconButton}
                            onClick={showTooltip}
                            name="create-monitor-from-global-search"
                        >
                            <Icon type="newDashboard" color={theme.colors.gray04} />
                            <Text size={1}>
                                Create &ldquo;
                                {searchTerm.length > 20 ? truncateString(searchTerm, 20) : searchTerm}&rdquo; Monitor
                            </Text>
                        </ActionButton>
                    )}
                </CreateDashFromTermTooltip>
                {!!monitorName && (
                    <CreateStreamFromTermTooltip
                        onSubmit={hideTooltip}
                        searchTerm={searchTerm}
                        yOffset={streamTooltipYOffset}
                    >
                        {({ showTooltip }) => (
                            <ActionButton
                                styles={styles.iconButton}
                                onClick={showTooltip}
                                name="create-search-from-global-search"
                            >
                                <Icon type="newStream" color={theme.colors.gray04} />
                                <Text size={1} styles={styles.addSearch}>
                                    Add Search to &ldquo;{monitorName}&rdquo;
                                </Text>
                            </ActionButton>
                        )}
                    </CreateStreamFromTermTooltip>
                )}
            </Div>
        );
    }

    renderNoResults() {
        const { styles, searchTerm, searchResults, loading, loadingResults } = this.props;

        if (!loading && !loadingResults && searchResults.length === 0) {
            if (searchTerm) {
                return (
                    <Div styles={styles.noResults}>
                        <Text size={3}>
                            No results found for {searchTerm},<br /> please try another keyword.
                        </Text>
                    </Div>
                );
            }
            return (
                <Div styles={styles.noResults}>
                    <Text size={3}>Enter a keyword to begin your search.</Text>
                </Div>
            );
        }

        return null;
    }

    renderVerticalResults({ hideTooltip }) {
        const {
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            loadMoreResults,
            loadingResults,
            loadingMoreResults,
            monitorCapabilities,
            monitorName,
            onClickResult,
            onSearch,
            resultTypes,
            scrollRef,
            searchHistory,
            searchInMonitor,
            searchTerm,
            searchResults,
            selectedResultType,
            selectResultType,
            styles,
            theme,
            toggleAllResults
        } = this.props;
        const highlightedType = get(highlightedResult, 'type');
        const highlightedIndex = get(highlightedResult, 'index');
        const hasSearchTerm = searchTerm && searchTerm.length > 0;
        const ResultsElement = RESULTS_ELEMENT_MAP[selectedResultType];

        return (
            <Div styles={styles.searchResults} ref={scrollRef} className="globalSearchUI">
                {!hasSearchTerm &&
                    searchHistory.map((term, idx) => (
                        <Div
                            styles={
                                highlightedType === 'history' && highlightedIndex === idx
                                    ? styles.manualResultRowHighlighted
                                    : styles.manualResultRow
                            }
                            onMouseEnter={() => highlightResult('history', idx)}
                            onClick={() => onSearch({ value: term })}
                            ref={node => indexElement('history', idx, node)}
                            key={`history-${term}`}
                        >
                            <Icon type="search03" color={theme.colors.gray04} />
                            <Text>{term}</Text>
                        </Div>
                    ))}
                {hasSearchTerm && monitorName && monitorCapabilities.includes(CAPABILITIES.searchBox) && (
                    <Div
                        styles={
                            highlightedType === 'searchMonitor'
                                ? styles.manualResultRowHighlighted
                                : styles.manualResultRow
                        }
                        onMouseEnter={() => highlightResult('searchMonitor', 0)}
                        onClick={() => {
                            searchInMonitor();
                            hideTooltip();
                        }}
                        ref={node => indexElement('searchMonitor', 0, node)}
                    >
                        <Icon type="search03" color={theme.colors.gray04} />
                        <Text>{searchTerm}</Text>
                        <Div styles={styles.spacer} />
                        <Div styles={styles.searchTag} size={1}>
                            <Text>{`Search in ${monitorName}`}</Text>
                        </Div>
                    </Div>
                )}
                {hasSearchTerm && (
                    <Div
                        styles={
                            highlightedType === 'searchAll' ? styles.manualResultRowHighlighted : styles.manualResultRow
                        }
                        onMouseEnter={() => highlightResult('searchAll', 0)}
                        onClick={toggleAllResults}
                        ref={node => indexElement('searchAll', 0, node)}
                    >
                        <Icon type="search03" color={theme.colors.gray04} />
                        <Text>{searchTerm}</Text>
                        <Div styles={styles.spacer} />
                        <Div styles={styles.searchTag} size={1}>
                            <Text>All Aiera</Text>
                        </Div>
                    </Div>
                )}
                <ContentTabs
                    passedRef={node => indexElement('resultTabs', 0, node)}
                    options={resultTypes}
                    onSelect={selectResultType}
                    selectedOption={selectedResultType}
                    styles={highlightedType === 'resultTabs' ? styles.tabContainerHighlighted : styles.tabContainer}
                />
                {!PERMISSIONED_CONTENT_TYPES.includes(selectedResultType) && ResultsElement && (
                    <ResultsElement
                        enabled
                        hasMoreResults={hasMoreResults}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingResults}
                        loadingMoreResults={loadingMoreResults}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={searchResults}
                    />
                )}
                {selectedResultType === 'news' && (
                    <NewsResults
                        enabled
                        hasMoreResults={hasMoreResults}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingResults}
                        loadingMoreResults={loadingMoreResults}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={searchResults}
                    />
                )}
                {selectedResultType === 'filing' && (
                    <FilingResults
                        enabled
                        hasMoreResults={hasMoreResults}
                        highlightedResult={highlightedResult}
                        highlightResult={highlightResult}
                        indexElement={indexElement}
                        loading={loadingResults}
                        loadingMoreResults={loadingMoreResults}
                        loadMoreResults={loadMoreResults}
                        onClickResult={onClickResult}
                        results={searchResults}
                    />
                )}
                {this.renderNoResults()}
                {!!searchTerm && this.renderButtons(hideTooltip)}
            </Div>
        );
    }

    render() {
        const {
            clearHistory,
            styles,
            onBlur,
            onHideAutocomplete,
            onShowAutocomplete,
            onSearch,
            searchHistory,
            searchTerm,
            inputRef,
            onSearchClear
        } = this.props;

        return (
            <Tooltip
                isEnabled
                useElementOffsetBottom
                useElementOffsetLeft
                useMaxHoverWidth
                useMinHoverWidth
                persistOnMouseExit
                yOffset={-5}
                xOffset={0}
                onShow={onShowAutocomplete}
                onHide={onHideAutocomplete}
                content={this.renderVerticalResults}
                cancelClassName="globalSearchUI"
                useOutsideClickHandler
                hideOnScroll={false}
                styles={styles.verticalResults}
            >
                {({ showTooltip, isTooltipVisible }) => (
                    <Div
                        styles={{
                            ...styles.searchContainer,
                            ...(isTooltipVisible ? styles.searchResultsAreVisible : {}),
                            ...styles.hasSearchTerm
                        }}
                        onClick={showTooltip}
                        onFocus={showTooltip}
                    >
                        <TextInput
                            className="globalSearchUI"
                            inputRef={inputRef}
                            autoComplete="off"
                            clearable
                            onChange={onSearch}
                            onClear={onSearchClear}
                            onBlur={onBlur}
                            value={searchTerm}
                            name="globalSearch"
                            icon="search03"
                            placeholder="Ticker, Event, Keyword..."
                            styles={styles.searchInput}
                        />
                        {!searchTerm && isTooltipVisible && searchHistory.length > 0 && (
                            <Div className="globalSearchUI" styles={styles.clearHistory} onClick={clearHistory}>
                                <Text size={0}>Clear History</Text>
                            </Div>
                        )}
                        <Text className="slashBox" size={0} weight="medium" styles={styles.slashBox}>
                            /
                        </Text>
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const SearchUI = compose(withStyleSheet(styleSheet))(Search);
