import gql from 'graphql-tag';
import XDate from 'xdate';
import { compose, withPropsOnChange } from 'recompose';
import { TYPES, OPERATORS } from 'consts/filters';
import { contentHighlightsStreamQuery } from 'graphql/bookmarks';
import { bookmarkFragment, bookmarkTargetFragment } from 'graphql/fragments/bookmarks';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { formatBookmarkTargetProps, formatMatchesByType, mapFiltersToRules, getBookmarkFilters } from 'utils/streams';

function getTargetIdByType(target) {
    const type = get(target, '__typename');
    let targetId;
    switch (type) {
        case 'FilingContent':
            targetId = get(target, 'contentId');
            break;
        case 'ScheduledAudioCallEvent':
            targetId = get(target, 'event.id');
            break;
        default:
            targetId = get(target, 'id');
            break;
    }
    return targetId;
}

function normalizeHighlights(bookmarkIds, bookmarks) {
    const highlights = [];
    const targetIds = [];
    if (bookmarks && bookmarks.length) {
        bookmarkIds.forEach(id => {
            const bookmark = bookmarks.find(b => b.id === id);
            if (bookmark) {
                const { target, user } = bookmark;
                const targetId = getTargetIdByType(target);
                highlights.push({
                    created: new XDate(get(bookmark, 'created', new Date())),
                    creatorName: `${get(user, 'firstName')} ${get(user, 'lastName')}`,
                    highlight: get(bookmark, 'highlight'),
                    highlightColor: get(bookmark, 'highlightColor'),
                    id: get(bookmark, 'id'),
                    note: get(bookmark, 'note'),
                    target,
                    targetId,
                    targetStreamId: get(bookmark, 'targetStreamId'),
                    targetType: get(bookmark, 'targetType'),
                    type: 'bookmark',
                    userId: get(user, 'id'),
                    ...formatBookmarkTargetProps(target)
                });
                targetIds.push(targetId);
            }
        });
    }
    return {
        highlights,
        isMultiSource: [...new Set(targetIds)].length > 1
    };
}

export const withData = () =>
    compose(
        withMemo({
            formatMatchesByType,
            normalize: normalizeHighlights
        }),
        graphql(contentHighlightsStreamQuery, {
            props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                const matches = formatMatches(get(data, 'streams[0].matches.results', []));
                return {
                    loading: !matches.length && get(data, 'loading'),
                    highlights: matches,
                    refreshPreview: data.refetch,
                    fetchMore: data.fetchMore
                };
            },
            options: ({ eventId, newsId, sortFilterString }) => {
                const parts = sortFilterString.split('|');
                const sortKey = parts[0];
                const filterKey = parts[1];
                const filters = [...getBookmarkFilters(filterKey)];
                if (eventId) filters.push({ type: TYPES.event, operator: OPERATORS.is, value: eventId });
                if (newsId) filters.push({ type: TYPES.content, operator: OPERATORS.is, value: newsId });
                return {
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        streamTypes: ['bookmarks'],
                        rules: mapFiltersToRules(filters),
                        sort: sortKey === 'chron' ? 'transcript_timestamp' : undefined
                    }
                };
            },
            skip: ({ bookmarkIds }) => !!bookmarkIds
        }),
        graphql(
            gql`
                query Bookmarks($bookmarkIds: [ID]) {
                    bookmarks(filter: { bookmarkIds: $bookmarkIds }) {
                        ...bookmark
                        ...bookmarkTarget
                    }
                }
                ${bookmarkFragment}
                ${bookmarkTargetFragment}
            `,
            {
                props: ({ data }) => ({
                    highlights: get(data, 'bookmarks', []),
                    loading: get(data, 'loading'),
                    refreshPreview: get(data, 'refetch')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                },
                skip: ({ bookmarkIds }) => !bookmarkIds
            }
        ),
        withPropsOnChange(['highlights'], ({ bookmarkIds, normalize, highlights }) =>
            bookmarkIds ? { ...normalize(bookmarkIds, highlights) } : { highlights }
        )
    );
