import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionMenu } from 'components/ActionMenu';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { PERMISSIONS, HIGHLIGHT_COLORS } from 'consts';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class StreamActionMenu extends PureComponent {
    static propTypes = {
        bookmarkId: PropTypes.string,
        copied: PropTypes.bool.isRequired,
        copyToClipboard: PropTypes.func,
        deleteDoc: PropTypes.func.isRequired,
        enableScoring: PropTypes.bool.isRequired,
        isDocumentOwner: PropTypes.bool.isRequired,
        noBookmark: PropTypes.bool.isRequired,
        onBookmark: PropTypes.func.isRequired,
        onScore: PropTypes.func.isRequired,
        openBookmarkForm: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        targetType: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        bookmarkId: null,
        copyToClipboard: undefined,
        passedStyles: {},
        score: null,
        targetType: undefined
    };

    render() {
        const {
            noBookmark,
            bookmarkId,
            copied,
            copyToClipboard,
            deleteDoc,
            enableScoring,
            isDocumentOwner,
            onBookmark,
            onScore,
            openBookmarkForm,
            score,
            passedStyles,
            styles,
            submitting,
            targetType,
            theme
        } = this.props;
        const defaultOptions = [
            {
                closeOnClick: true,
                iconType: 'highlighter',
                iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                label: bookmarkId ? 'Remove highlight' : 'Highlight match',
                onClick: onBookmark,
                loading: submitting,
                name: 'toggleHighlight',
                permissions: [PERMISSIONS.featureBookmarks],
                content: bookmarkId ? null : (
                    <Fragment>
                        <Icon type="highlighter" color={theme.colors.gray04} />
                        <Div styles={styles.colorOptions}>
                            {HIGHLIGHT_COLORS.map(color => (
                                <Div
                                    key={color}
                                    onMouseDown={e => onBookmark(e, color)}
                                    styles={{
                                        ...styles.colorOption,
                                        backgroundColor: getHighlightColor(color)?.dark,
                                        border: `1px solid ${getHighlightColor(color)?.dark02}`
                                    }}
                                />
                            ))}
                        </Div>
                    </Fragment>
                )
            },
            {
                closeOnClick: true,
                iconType: 'bookmarkWithNote',
                iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                label: bookmarkId ? 'Edit highlight' : 'Highlight with note',
                onClick: openBookmarkForm,
                name: 'openBookmarkForm',
                permissions: [PERMISSIONS.featureBookmarks]
            }
        ];
        const scoringOptions = [
            {
                closeOnClick: true,
                iconType: 'thumbUp',
                iconColor: score !== undefined && score > 0 ? theme.colors.blue08 : theme.colors.gray04,
                label: 'Good match',
                onClick: e => onScore(e, 1),
                name: 'thumbUp',
                permissions: [PERMISSIONS.featureModelStreams]
            },
            {
                closeOnClick: true,
                iconType: 'thumbDown',
                iconColor: score !== undefined && score < 0 ? theme.colors.blue08 : theme.colors.gray04,
                label: 'Bad match',
                onClick: e => onScore(e, -1),
                name: 'thumbDown',
                permissions: [PERMISSIONS.featureModelStreams]
            }
        ];
        let options = [];
        if (!noBookmark) {
            options = [...defaultOptions];
        }
        if (targetType === 'document' && isDocumentOwner) {
            options = [
                ...options,
                {
                    closeOnClick: true,
                    iconType: 'trash',
                    iconColor: theme.colors.gray04,
                    label: 'Delete Document',
                    onClick: deleteDoc,
                    name: 'deleteDocument'
                }
            ];
        }
        if (enableScoring) {
            options = [...options, ...scoringOptions];
        }
        if (copyToClipboard) {
            options = [
                ...options,
                {
                    iconType: 'share',
                    iconColor: theme.colors.gray04,
                    label: copied ? 'Copied!' : 'Copy link',
                    onClick: copyToClipboard,
                    name: 'copyToClipboard'
                }
            ];
        }
        return <ActionMenu styles={passedStyles} options={options} />;
    }
}

export const StreamActionMenuUI = compose(withStyleSheet(styleSheet))(StreamActionMenu);
