import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class TaggedInputOld extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        disabled: PropTypes.bool.isRequired,
        inputIcon: PropTypes.string,
        inputLabel: PropTypes.string,
        inputName: PropTypes.string.isRequired,
        inputPlaceholder: PropTypes.string,
        inputRef: PropTypes.objectOf(PropTypes.object),
        inputValue: PropTypes.string.isRequired,
        onInputBlur: PropTypes.func.isRequired,
        onInputChange: PropTypes.func.isRequired,
        onInputFocus: PropTypes.func.isRequired,
        onInputKeyDown: PropTypes.func.isRequired,
        onTagRemove: PropTypes.func.isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        passedStyles: {},
        inputIcon: '',
        inputLabel: null,
        inputPlaceholder: null,
        inputRef: null,
        tags: null
    };

    renderTags() {
        const { styles, theme, onTagRemove, tags } = this.props;
        const content = tags.map(tag => {
            const { id, label } = tag;
            return (
                <Div key={`${id}-${label}`} styles={styles.tag} onClick={() => onTagRemove(tag)}>
                    <Text size={1}>{label}</Text>
                    <Div styles={styles.removeIcon}>
                        <Icon type="minus" color={theme.colors.gray04} />
                    </Div>
                </Div>
            );
        });

        return <Div styles={styles.tagsContainer}>{content}</Div>;
    }

    render() {
        const {
            children,
            styles,
            passedStyles,
            disabled,
            inputIcon,
            inputLabel,
            inputName,
            inputPlaceholder,
            inputRef,
            inputValue,
            onInputBlur,
            onInputChange,
            onInputFocus,
            onInputKeyDown,
            tags
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.inputContainer}>
                    <TextInput
                        styles={styles.input}
                        autoComplete="off"
                        className="draggableCancel"
                        disabled={disabled}
                        icon={inputIcon}
                        label={inputLabel}
                        name={inputName}
                        onBlur={onInputBlur}
                        onChange={onInputChange}
                        onFocus={onInputFocus}
                        onKeyDown={onInputKeyDown}
                        placeholder={inputPlaceholder}
                        value={inputValue}
                        inputRef={inputRef}
                    />
                    {tags && tags.length > 0 && this.renderTags()}
                    {children}
                </Div>
            </Div>
        );
    }
}

export const TaggedInputOldUI = compose(withStyleSheet(styleSheet))(TaggedInputOld);
