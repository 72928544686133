export const styleSheet = theme => {
    const company = {
        flexShrink: 0,
        width: '294px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        borderRight: '1px solid rgba(0,0,0,0.15)',
        height: '100%',
        '.companyName': {
            whiteSpace: 'nowrap',
            width: 220,
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            overflow: 'hidden'
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    };

    return {
        container: {
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            backgroundColor: theme.colors.white01
        },
        backButton: {
            margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
            [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
                margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.colors.gray08,
            height: 52,
            color: theme.colors.white01,
            '.otherAttachment': {
                color: theme.colors.white01,
                textDecoration: 'none',
                height: 52,
                display: 'flex',
                alignItems: 'center',
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                cursor: 'pointer',
                padding: '0 20px',
                svg: {
                    marginRight: 8
                },
                ':hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    'svg g': {
                        fill: theme.colors.blue01
                    }
                }
            }
        },
        company: {
            ...company,
            justifyContent: 'space-between',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                flexShrink: 1,
                width: 'unset'
            },
            a: {
                color: theme.colors.white01,
                textDecoration: 'none',
                height: '30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginRight: theme.margins.margin00
            }
        },
        companyTicker: {
            color: theme.colors.gray04,
            letterSpacing: '1px',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                color: theme.colors.white01
            }
        },
        exchange: {
            opacity: 0.5,
            marginLeft: 4
        },
        event: {
            height: 52,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            paddingLeft: 16,
            '.eventBack': {
                height: 52,
                padding: '0 16px',
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.gray04,
                textDecoration: 'none',
                svg: {
                    marginRight: 10
                },
                cursor: 'pointer',
                ':hover': {
                    color: theme.colors.white01,
                    'svg g': {
                        fill: theme.colors.white01
                    }
                }
            }
        },
        eventText: {
            flex: 1,
            height: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            a: {
                alignSelf: 'flex-start',
                cursor: 'pointer',
                textDecoration: 'none',
                color: theme.colors.white01,
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        },
        eventTickerRow: {
            color: theme.colors.orange02
        },
        eventExchange: {
            opacity: 0.5,
            marginLeft: 4
        },
        eventDate: {
            color: theme.colors.gray04,
            marginLeft: 6
        }
    };
};
