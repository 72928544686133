export const styleSheet = theme => ({
    containerCompact: {
        borderRadius: 'unset',
        width: 'unset',
        margin: 'unset'
    },
    compact: {
        borderRadius: 'unset',
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        padding: '7px 13px 10px',
        '.title': {
            fontWeight: 'unset'
        }
    },
    container: {
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.blue09,
            '.categories': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)'
            }
        }
    },
    containerComfortable: {
        cursor: 'pointer',
        '.title': {
            fontWeight: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.blue09,
            '.categories': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)'
            }
        }
    },
    content: {
        ...theme.content.annotated,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        marginTop: 15
    },
    title: {
        marginTop: '3px'
    },
    categories: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    category: {
        padding: '3px 4px 2px',
        marginRight: 4,
        marginTop: 6,
        borderRadius: 2.5,
        letterSpacing: 1,
        backgroundColor: theme.colors.gray06,
        color: theme.colors.gray10
    },
    categoryContainer: {
        backgroundColor: theme.colors.gray10,
        padding: 13,
        marginTop: theme.margins.margin00,
        borderRadius: '0 0 6px 6px',
        marginBottom: -10,
        marginLeft: -13,
        marginRight: -13
    },
    catLabel: {
        color: theme.colors.gray04,
        letterSpacing: 2
    },
    spacer: {
        flex: 1
    }
});
