import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import memoize from 'memoize-one';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { generateTabURL } from 'utils';
import { popoutManager } from 'utils/popout';
import { STREAM_DISPLAY_MODES } from 'consts';
import { SpotlightCardUI } from './ui';

function groupedWithLinks(spotlightId, pathname, streamId, grouped) {
    return grouped.map(g => {
        return {
            ...g,
            to: generateTabURL({
                spotlightId,
                pathname,
                match: 'match',
                streamId,
                itemId: g.itemId
            })
        };
    });
}

export class SpotlightCard extends PureComponent {
    static displayName = 'SpotlightCardContainer';

    static propTypes = {
        additionalHighlights: PropTypes.arrayOf(PropTypes.any),
        bookmarkId: PropTypes.string,
        company: PropTypes.string,
        displayMode: PropTypes.string,
        eventDate: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        events: PropTypes.arrayOf(PropTypes.object),
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        displayType: PropTypes.string,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        spotlightSubtype: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        additionalHighlights: [],
        bookmarkId: undefined,
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        displayType: null,
        equities: [],
        events: [],
        grouped: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        score: undefined,
        spotlightSubtype: null,
        styles: {},
        streamId: null
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.groupedWithLinks = memoize(groupedWithLinks);
    }

    handleClick(e) {
        if (e.shiftKey) {
            this.handlePopout(e);
        }
    }

    handlePopout(e) {
        const { id, streamId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generateTabURL({
                pathname: generatePath(routes.popoutSpotlight, { type: 'spotlight', spotlightId: id }),
                spotlightId: id,
                match: !!streamId,
                streamId
            }),
            name: `popout-spotlight-${id}`,
            width: 750
        });
    }

    render() {
        const {
            additionalHighlights,
            bookmarkId,
            company,
            displayMode,
            displayType,
            equities,
            eventDate,
            events,
            grouped,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            pathname,
            publishedDate,
            score,
            spotlightSubtype,
            styles,
            streamId,
            title
        } = this.props;
        return (
            <SpotlightCardUI
                additionalHighlights={additionalHighlights}
                bookmarkId={bookmarkId}
                company={company}
                displayMode={displayMode}
                displayType={displayType}
                equities={equities}
                eventDate={eventDate}
                events={events}
                grouped={this.groupedWithLinks(id, pathname, streamId, grouped)}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                matchId={matchId}
                onClick={this.handleClick}
                pathname={pathname}
                publishedDate={publishedDate}
                score={score}
                spotlightSubtype={spotlightSubtype}
                streamId={streamId}
                styles={styles}
                title={title}
                to={generateTabURL({ pathname, spotlightId: id, streamId, match: !!streamId })}
            />
        );
    }
}

export const SpotlightCardContainer = compose(withUrlContext(['pathname']))(SpotlightCard);
