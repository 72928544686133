import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class AddToCalendarButton extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        onClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, theme, onClick, children } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} onClick={onClick}>
                {children}
                <Icon type="plus" color={theme.colors.black01} />
            </Div>
        );
    }
}

export const AddToCalendarButtonUI = compose(withStyleSheet(styleSheet))(AddToCalendarButton);
