export const styleSheet = theme => {
    const tab = {
        display: 'flex',
        flex: 1,
        a: {
            paddingBottom: 'env(safe-area-inset-bottom)',
            transition: 'all 0.2s ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        svg: {
            opacity: 0.6,
            height: 16,
            width: 'auto'
        },
        '.selected': {
            flex: 1,
            transition: 'all 0.2s ease-in-out',
            svg: {
                opacity: 1,
                g: {
                    fill: theme.colors.orange04
                }
            }
        }
    };

    return {
        container: {
            background: theme.colors.gray08,
            display: 'flex',
            height: '44px',
            zIndex: 1,
            '@supports (height: env(safe-area-inset-bottom))': {
                height: 'calc(44px + env(safe-area-inset-bottom))'
            }
        },
        tabMenu: {
            ...tab
        },
        tabAlerts: {
            ...tab,
            position: 'relative'
        },
        tabSearch: {
            display: 'flex',
            flex: 1,
            paddingBottom: 'env(safe-area-inset-bottom)',
            transition: 'all 0.2s ease-in-out',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.6,
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            svg: {
                height: 16,
                width: 'auto'
            }
        },
        tab,
        unreadCount: {
            alignItems: 'center',
            backgroundColor: theme.colors.red08,
            borderRadius: 6,
            boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3)',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '35%',
            minWidth: 15,
            padding: '2px 4px',
            position: 'absolute',
            top: 8,
            zIndex: 1,
            p: {
                color: theme.colors.white01,
                fontSize: theme.fonts.fontSizes.size00
            }
        }
    };
};
