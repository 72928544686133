export const styleSheet = theme => {
    const actionButton = {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.gray03,
        marginTop: 5,
        borderRadius: 6,
        ':hover': {
            boxShadow: 'unset',
            backgroundColor: theme.colors.gray05,
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    };

    return {
        containerMinimal: {
            borderRadius: 'unset',
            width: 'unset',
            margin: 'unset'
        },
        containerCompact: {
            borderRadius: 'unset',
            width: 'unset',
            margin: 'unset'
        },
        compact: {
            borderRadius: 'unset',
            border: 'unset',
            borderBottom: `1px solid ${theme.colors.gray03}`,
            display: 'flex',
            flexDirection: 'column',
            padding: 0
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0
        },
        contentAndButtons: {
            display: 'flex',
            flexDirection: 'row',
            padding: '10px 13px',
            justifyContent: 'space-between'
        },
        content: { flex: 1 },
        groupedContent: {
            ...theme.content.annotated,
            borderLeft: `1px solid ${theme.colors.gray02}`,
            borderRight: `1px solid ${theme.colors.gray02}`,
            backgroundColor: theme.colors.white02,
            fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
            color: theme.colors.gray08,
            lineHeight: '1.3em',
            padding: '8px 13px',
            ':hover': {
                backgroundColor: theme.colors.blue07,
                cursor: 'pointer'
            },
            ':last-of-type': {
                borderBottom: `1px solid ${theme.colors.gray02}`,
                borderRadius: '0 0 6px 6px',
                paddingBottom: '10px'
            }
        },
        groupedContentDetails: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        groupedContentTime: {
            color: theme.colors.gray06
        },
        groupedContentIndex: {
            color: theme.colors.gray06
        },
        groupedContentDate: {
            color: theme.colors.gray06,
            marginRight: '3px'
        },
        spacer: {
            flex: 1
        },
        liveStatus: {
            display: 'flex',
            letterSpacing: 1,
            color: theme.colors.orange01,
            marginTop: 4
        },
        liveDot: {
            height: '7px',
            width: '7px',
            borderRadius: '3.5px',
            margin: '1px 5px 0 0',
            backgroundColor: theme.colors.orange01
        },
        actionButtons: {
            borderRadius: '0 6px 6px 0',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '-10px -13px -10px 13px',
            padding: '0 5px 5px',
            width: 50
        },
        eventAlertToggle: {
            marginTop: 5
        },
        hasTonalSentimentIcon: {
            position: 'absolute',
            right: 52,
            svg: {
                height: 16,
                width: 14
            },
            top: 8
        },
        transcriptButton: {
            ...actionButton
        },
        listenLiveButton: {
            ...actionButton,
            img: {
                height: 14,
                width: 14
            },
            svg: {
                height: 14
            }
        },
        listenLiveButtonLocked: {
            ...actionButton,
            img: {
                height: 14,
                width: 14
            },
            svg: {
                height: 14
            },
            '.lockIcon': {
                display: 'none'
            },
            ':hover': {
                '.lockIcon': {
                    display: 'block'
                },
                '.playIcon': {
                    display: 'none'
                }
            }
        },
        minimal: {
            border: 'unset',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            margin: 0
        },
        minimalTicker: {
            fontSize: theme.fonts.fontSizes.size01,
            color: theme.colors.blue08
        },
        minimalExchange: {
            fontSize: theme.fonts.fontSizes.size00,
            letterSpacing: 1,
            marginLeft: 4,
            color: theme.colors.gray04,
            opacity: 0.3
        },
        minimalEventType: {
            marginTop: 4,
            color: theme.colors.white01
        },
        minimalContentAndButtons: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '10px 16px',
            backgroundColor: theme.colors.gray14,
            justifyContent: 'space-between',
            ':hover': {
                backgroundColor: theme.colors.gray11
            }
        },
        minimalPlayButton: {
            display: 'flex',
            height: 28,
            width: 28,
            paddingLeft: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.gray12,
            marginRight: 10,
            borderRadius: 15,
            ':hover': {
                boxShadow: 'unset',
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            },
            svg: {
                height: 12
            }
        },
        minimalTranscriptButton: {
            display: 'flex',
            height: 28,
            width: 28,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.gray12,
            marginRight: 10,
            borderRadius: 15,
            svg: {
                height: 12
            }
        },
        minimalListenLiveButton: {
            display: 'flex',
            height: 28,
            width: 28,
            paddingLeft: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.gray12,
            marginRight: 10,
            borderRadius: 15,
            ':hover': {
                boxShadow: 'unset',
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            },
            img: {
                height: 12,
                width: 12
            },
            svg: {
                height: 12
            }
        },
        minimialListenLiveButtonLocked: {
            display: 'flex',
            height: 28,
            width: 28,
            paddingLeft: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.gray12,
            marginRight: 10,
            borderRadius: 15,
            img: {
                height: 12,
                width: 12
            },
            svg: {
                height: 12
            },
            '.lockIcon': {
                display: 'none'
            },
            ':hover': {
                boxShadow: 'unset',
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                },
                '.lockIcon': {
                    display: 'block'
                },
                '.playIcon': {
                    display: 'none'
                }
            }
        },
        minimalPriceReaction: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            height: 13,
            paddingLeft: 4,
            paddingRight: 3,
            marginLeft: 6,
            marginRight: 2,
            marginBottom: 1,
            color: theme.colors.white01,
            p: {
                marginTop: 1
            },
            svg: {
                marginLeft: 2
            }
        },
        minimalRightSide: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
        },
        minimalIcons: {
            position: 'relative',
            display: 'flex'
        },
        minimalHasTonalSentimentIcon: {
            marginRight: 4,
            svg: {
                height: 16,
                width: 14
            }
        },
        noAccess: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8,
            marginTop: 2
        },
        noAccessText: { fontWeight: 500, marginLeft: 7, color: theme.colors.red01 },
        popoutButton: {
            ...actionButton,
            svg: {
                height: 14,
                width: 14
            }
        },
        playButton: {
            ...actionButton,
            svg: {
                height: 14
            }
        },
        alertToggle: {
            marginTop: 12
        },
        dateTime: {
            display: 'flex',
            color: theme.colors.gray06,
            paddingTop: '4px',
            'p:first-of-type': {
                textTransform: 'capitalize',
                marginRight: 3
            }
        },
        priceReaction: {
            backgroundColor: theme.colors.gray10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 10px 8px 13px',
            borderRadius: 6,
            margin: '0 5px 5px',
            svg: {
                marginLeft: 2
            }
        },
        reactionLabel: {
            opacity: 0.5,
            letterSpacing: 1
        },
        conferenceContainer: {
            backgroundColor: theme.colors.gray03,
            borderRadius: 6,
            margin: '0 5px 5px',
            a: {
                color: theme.colors.gray08,
                textDecoration: 'none',
                height: 28,
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px'
            },
            svg: {
                flexShrink: 0
            },
            ':hover': {
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        conference: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        conferenceTitle: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginLeft: 5,
            span: {
                marginRight: 5
            }
        },
        summaryContainer: {
            backgroundColor: theme.colors.gray03,
            borderRadius: 6,
            overflow: 'hidden',
            margin: '0 5px 5px',
            color: theme.colors.gray08,
            display: 'flex',
            flexDirection: 'column'
        },
        summaryChevron: {
            transform: 'rotate(180deg)'
        },
        summaryChevronExpanded: {
            transform: 'rotate(90deg)'
        },
        summaryHeaderExpanded: {
            borderBottom: `1px solid ${theme.colors.gray05}`,
            padding: '0 10px 0 8px',
            marginBottom: 8,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            svg: {
                flexShrink: 0
            },
            ':hover': {
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        summaryHeader: {
            padding: '0 10px 0 8px',
            height: 28,
            display: 'flex',
            alignItems: 'center',
            svg: {
                flexShrink: 0
            },
            ':hover': {
                backgroundColor: theme.colors.gray05,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        summaryText: {
            cursor: 'default',
            padding: '0px 12px 8px',
            backgroundColor: theme.colors.gray03,
            lineHeight: '1.3em',
            fontSize: theme.fonts.fontSizes.size01,
            '.summary_qa_title': {
                color: theme.colors.black01,
                fontWeight: theme.fonts.fontWeights.medium
            }
        },
        summaryTitleLong: {
            cursor: 'default',
            padding: '0px 12px 8px',
            backgroundColor: theme.colors.gray03,
            lineHeight: '1.3em'
        },
        summaryTitle: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: '0 5px',
            flex: 1,
            span: {
                marginRight: 5
            }
        }
    };
};
