import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SENTIMENT_TYPES } from 'consts';
import { CardUI } from './ui';

export class Card extends PureComponent {
    static displayName = 'CardContainer';

    static propTypes = {
        containerStyles: PropTypes.objectOf(PropTypes.any),
        externalTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        includeHighlight: PropTypes.bool,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        sentiment: PropTypes.oneOf(SENTIMENT_TYPES),
        styles: PropTypes.objectOf(PropTypes.any),
        subContentButtonContent: PropTypes.objectOf(PropTypes.any),
        subContentButtonText: PropTypes.string,
        subContentRows: PropTypes.arrayOf(PropTypes.any),
        subContentSubtitle: PropTypes.string,
        subContentTitle: PropTypes.string,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        containerStyles: {},
        externalTo: null,
        includeHighlight: false,
        name: undefined,
        onClick: null,
        onMouseEnter: undefined,
        onMouseLeave: undefined,
        sentiment: null,
        styles: {},
        subContentButtonContent: undefined,
        subContentButtonText: undefined,
        subContentRows: [],
        subContentSubtitle: undefined,
        subContentTitle: 'Additional matches',
        to: null
    };

    constructor(props) {
        super(props);

        this.handleSubContentToggle = this.handleSubContentToggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.state = {
            isHovering: false,
            showHighlight: props.includeHighlight,
            showSubContent: false
        };
    }

    handleSubContentToggle(e) {
        const { showSubContent } = this.state;
        e.stopPropagation();
        this.setState({
            showSubContent: !showSubContent
        });
    }

    onMouseEnter() {
        const { onMouseEnter } = this.props;
        this.setState({ isHovering: true }, () => {
            if (onMouseEnter) onMouseEnter();
        });
    }

    onMouseLeave() {
        const { onMouseLeave } = this.props;
        this.setState({ isHovering: false }, () => {
            if (onMouseLeave) onMouseLeave();
        });
    }

    render() {
        const {
            containerStyles,
            externalTo,
            styles,
            sentiment,
            subContentButtonContent,
            subContentButtonText,
            subContentRows,
            subContentSubtitle,
            subContentTitle,
            to,
            name,
            onClick,
            children
        } = this.props;
        const { isHovering, showHighlight, showSubContent } = this.state;
        return (
            <CardUI
                containerStyles={containerStyles}
                isHovering={isHovering}
                name={name}
                onClick={onClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                sentiment={sentiment}
                showHighlight={showHighlight}
                showSubContent={showSubContent}
                styles={styles}
                subContentButtonContent={subContentButtonContent}
                subContentButtonText={subContentButtonText}
                subContentRows={subContentRows}
                subContentSubtitle={subContentSubtitle}
                subContentTitle={subContentTitle}
                externalTo={externalTo}
                to={to}
                toggleSubContent={this.handleSubContentToggle}
            >
                {children}
            </CardUI>
        );
    }
}

export const CardContainer = compose()(Card);
