import { compose, lifecycle } from 'recompose';
import { mapPropsToOptions } from 'hoc/utils';
import { MediaPlayer } from 'utils/media';

export function registerMediaEvents(emitter, updater, { trackDetails, trackAudio }) {
    emitter.on('activeChange', updater);
    emitter.on('statusChange', updater);
    emitter.on('error', console.error); // eslint-disable-line no-console

    if (trackDetails) {
        emitter.on('timeChange', updater);
        emitter.on('durationChange', updater);
    }

    if (trackAudio) {
        emitter.on('decibelChange', updater);
    }
}

export function unregisterMediaEvents(emitter, updater) {
    emitter.off('activeChange', updater);
    emitter.off('statusChange', updater);
    emitter.off('timeChange', updater);
    emitter.off('durationChange', updater);
    emitter.off('decibelChange', updater);
    emitter.on('error', console.error); // eslint-disable-line no-console
}

export const withActiveMediaPlayer = (options = {}, alias) =>
    compose(
        lifecycle({
            componentDidMount() {
                const opts = {
                    trackDetails: true,
                    ...mapPropsToOptions(options, this.props)
                };
                // In this case, we don't get a single mediaPlayer
                // instance. We listen on the "activeEvents" channel
                // for updates, and pass down the state/API of the current
                // active player on each update.
                const emitter = MediaPlayer.activeEvents;
                this.onUpdate = () => {
                    const mediaPlayer = MediaPlayer.activePlayer;
                    this.setState({
                        [alias || 'mediaPlayer']: mediaPlayer
                            ? {
                                  ...mediaPlayer,
                                  ...mediaPlayer.state
                              }
                            : null
                    });
                };
                registerMediaEvents(emitter, this.onUpdate, {
                    trackDetails: opts.trackDetails,
                    trackAudio: opts.trackAudio
                });
            },
            componentWillUnmount() {
                const emitter = MediaPlayer.activeEvents;
                unregisterMediaEvents(emitter, this.onUpdate);
            }
        })
    );
