export const styleSheet = theme => {
    const company = {
        flexShrink: 0,
        width: '320px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        borderRight: '1px solid rgba(0,0,0,0.15)',
        height: '100%',
        '.companyName': {
            whiteSpace: 'nowrap',
            width: 260,
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            overflow: 'hidden'
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    };
    return {
        container: {
            backgroundColor: theme.colors.gray08,
            boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.1)',
            height: '52px',
            padding: 0,
            zIndex: 2,
            transition: 'all 0.2s ease-in-out',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.white01
        },
        headerInfo: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0 12px',
            flex: 1
        },
        headerTitle: {
            display: 'flex',
            position: 'relative'
        },
        headerTitlePlaceholder: {
            opacity: 0,
            maxWidth: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        headerTitleTextWrapper: {
            display: 'flex',
            position: 'absolute',
            left: 0,
            right: theme.margins.margin00,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        headerTitleText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize'
        },
        headerSmallText: {
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical'
        },
        backIcon: {
            height: '30px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            borderRadius: '6px',
            cursor: 'pointer',
            marginLeft: '12px',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                'svg g': {
                    fill: theme.colors.blue01
                }
            },
            svg: {
                height: '12px',
                width: '12px',
                transform: 'rotate(180deg)'
            }
        },
        filedDate: {
            letterSpacing: 1,
            color: theme.colors.gray04,
            marginLeft: '6px'
        },
        endDate: {
            color: theme.colors.orange02
        },
        company: {
            ...company,
            justifyContent: 'space-between',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                flexShrink: 1,
                width: 'unset'
            }
        },
        companySummary: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: theme.margins.margin00
        },
        companyTicker: {
            color: theme.colors.gray04,
            letterSpacing: '1px',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                color: theme.colors.white01
            }
        },
        exchange: {
            opacity: 0.5,
            marginLeft: 4
        },
        columns: {
            display: 'flex'
        },
        loadingPlaceholders: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '12px'
        },
        loadingSmallText: {
            height: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
            borderRadius: '6px',
            width: '60px',
            marginRight: theme.margins.margin00,
            ...theme.content.flickerAnimation
        },
        loadingMediumText: {
            height: '15px',
            backgroundColor: 'rgba(255, 255, 255, 0.09)',
            borderRadius: '6px',
            width: '250px',
            ...theme.content.flickerAnimation
        },
        formNumber: {
            marginRight: 12,
            color: theme.colors.orange02,
            textAlign: 'right'
        },
        titleName: {
            marginLeft: 6,
            fontWeight: theme.fonts.fontWeights.regular,
            color: theme.colors.gray02,
            opacity: 0.5
        }
    };
};
