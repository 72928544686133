import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class BackButton extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        route: PropTypes.string,
        text: PropTypes.string,
        back: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        text: 'Back',
        back: false,
        route: null
    };

    render() {
        const { text, styles, theme, route, back, history, passedStyles } = this.props;

        if (back) {
            return (
                <Div>
                    <Div onClick={history.goBack} styles={{ ...styles.container, ...passedStyles }}>
                        <Icon type="arrowLeft" color={theme.colors.backArrow} />
                        <Text styles={styles.text}>{text}</Text>
                    </Div>
                </Div>
            );
        }

        return (
            <Div styles={{ ...styles.containerLink, ...passedStyles }}>
                <Link to={route}>
                    <Icon type="arrowLeft" color={theme.colors.backArrow} />
                    <Text styles={styles.text}>{text}</Text>
                </Link>
            </Div>
        );
    }
}

export const BackButtonUI = compose(withStyleSheet(styleSheet))(BackButton);
