import { types as userTypes } from 'actions/user';
import { types as tabTypes } from 'actions/tabs';
import { parseTabId } from 'utils';

const { TAB_REMOVE, TAB_REPLACE, TAB_MINIMIZE, TAB_MAXIMIZE, TAB_TITLE_STORE } = tabTypes;
const { USER_IS_LOGGED_OUT } = userTypes;

const initialState = {
    maximizedTabId: null,
    minimizedTabIds: [],
    titleMap: {}
};

export function tabsReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === TAB_MAXIMIZE) {
        const { id } = payload;
        const { minimizedTabIds } = state;
        const newMin = new Set(minimizedTabIds);

        if (newMin.has(id)) {
            newMin.delete(id);
        }

        return {
            ...state,
            minimizedTabIds: [...newMin],
            maximizedTabId: id
        };
    }

    if (type === TAB_MINIMIZE) {
        const { id } = payload;
        const { minimizedTabIds } = state;
        const newMin = new Set(minimizedTabIds);

        const { tabType, id: plainId } = parseTabId(id);
        const newId = `${tabType}|${plainId}`;

        const hasMin = minimizedTabIds.find(tabId => {
            const { tabType: tt, id: plain } = parseTabId(tabId);
            const minId = `${tt}|${plain}`;
            return minId === newId;
        });

        if (!hasMin) {
            newMin.add(id);
        }

        return {
            ...state,
            minimizedTabIds: [...newMin],
            maximizedTabId: null
        };
    }

    if (type === TAB_REMOVE) {
        const { id } = payload;
        const { maximizedTabId, minimizedTabIds } = state;
        const newMin = new Set(minimizedTabIds);

        if (newMin.has(id)) {
            newMin.delete(id);
        }

        return {
            ...state,
            minimizedTabIds: [...newMin],
            maximizedTabId: maximizedTabId === id ? null : maximizedTabId
        };
    }

    if (type === TAB_REPLACE) {
        const { originalTabId, newTabId } = payload;
        const { minimizedTabIds } = state;
        const newMin = new Set(minimizedTabIds);

        if (newMin.has(newTabId)) {
            newMin.delete(newTabId);
            newMin.add(originalTabId);
        }

        return {
            ...state,
            minimizedTabIds: [...newMin],
            maximizedTabId: newTabId
        };
    }

    if (type === TAB_TITLE_STORE) {
        const { tabId, title } = payload;
        return {
            ...state,
            titleMap: {
                ...state.titleMap,
                [tabId]: title
            }
        };
    }

    if (type === USER_IS_LOGGED_OUT) {
        return {
            ...initialState
        };
    }

    return state;
}
