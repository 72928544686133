import merge from 'lodash/merge';
import pick from 'lodash/pick';
import memoize from 'memoize-one';
import {
    compose,
    setDisplayName,
    shallowEqual,
    shouldUpdate,
    withProps,
    withPropsOnChange,
    wrapDisplayName
} from 'recompose';
import { config } from 'configuration';

export function mapPropsToOptions(mapper, props = {}) {
    return typeof mapper === 'function' ? mapper(props) : mapper;
}

export function mergeOptions(...opts) {
    return props => merge(...opts.map(o => mapPropsToOptions(o, props)));
}

// Helper function that expects either a list of keys or a function that
// takes props and returns a list of keys. It returns a function either way.
function propKeysFunc(propKeys) {
    return typeof propKeys === 'function' ? propKeys : () => propKeys;
}

// rcompose's internal onUpdateForKeys only takes a hardcoded list of keys,
// this is an updated version that takes in a function that takes props
// and can return the list of keys dynamically
export const onlyUpdateForKeys = propKeys => {
    const hoc = shouldUpdate((props, nextProps) => {
        const next = pick(nextProps, propKeysFunc(propKeys)(nextProps));
        const current = pick(props, propKeysFunc(propKeys)(props));
        return !shallowEqual(next, current);
    });

    if (config.NODE_ENV !== 'production') {
        return BaseComponent => setDisplayName(wrapDisplayName(BaseComponent, 'onlyUpdateForKeys'))(hoc(BaseComponent));
    }
    return hoc;
};

// This is a special case where we have an HOC that will add a bunch of props, but
// we only want to re-render if some subset of them change. Using onlyUpdateForKeys,
// directly has issues because it will also NOT re-render for parent props that have
// changed. This helper allows us to create a composable HOC where parent prop changes
// will still re-render, but props updated within "hoc" will only cause a re-render
// when the selected "keys" changed.
export const onlyUpdateForKeysPassthrough = (propKeys, hoc) =>
    compose(
        withProps(ownerProps => ({ ownerPropKeys: Object.keys(ownerProps) })),
        hoc,
        onlyUpdateForKeys(({ ownerPropKeys, ...props }) => {
            const keys = [...(propKeysFunc(propKeys)(props) || []), ...ownerPropKeys];
            return keys;
        })
    );

export const withMemo = (funcs, equalityFn) =>
    withPropsOnChange([], () => {
        return Object.fromEntries(Object.keys(funcs).map(k => [k, memoize(funcs[k], equalityFn)]));
    });
