export const styleSheet = theme => ({
    container: {
        width: 240,
        '.dial-input': {
            backgroundColor: 'transparent'
        },
        '.dial-input:focus': {
            outline: 'none !important'
        }
    },
    tones: {
        letterSpacing: '4px',
        fontWeight: 400,
        padding: theme.margins.margin00,
        backgroundColor: 'transparent',
        input: {
            textAlign: 'center',
            ':disabled': {
                color: 'inherit'
            }
        }
    },
    dialPad: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    button: {
        userSelect: 'none',
        height: 50,
        width: 50,
        borderRadius: 25,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    key: {
        border: `solid ${theme.colors.gray04} 1px`,
        color: theme.colors.black01,
        margin: '10px 15px',
        ':hover': {
            backgroundColor: theme.colors.gray07
        }
    },
    pressed: {
        backgroundColor: theme.colors.gray04,
        '@media (hover: hover)': {
            ':hover': {
                backgroundColor: theme.colors.gray04
            }
        }
    },
    phoneBg: {
        margin: '10px auto',
        transition: 'all .1s linear'
    },
    phoneBgConnected: {
        backgroundColor: theme.colors.red01,
        transform: 'rotate(130deg)'
    },
    phoneBgDisconnected: {
        backgroundColor: theme.colors.green01
    },
    otherButton: {
        margin: '10px 15px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50
    },
    backspaceContainer: {
        cursor: 'pointer',
        paddingRight: 8
    },
    backspace: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        borderRadius: 5,
        backgroundColor: theme.colors.gray07,
        svg: {
            height: 9,
            width: 9
        }
    },
    backspaceLeft: {
        width: 0,
        height: 0,
        borderRadius: 5,
        marginRight: -3,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderRight: `10px solid ${theme.colors.gray07}`
    }
});
