import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withCloneDashboard } from 'graphql/dashboards';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { CloneDashboardTooltipUI } from './ui';

export class CloneDashboardTooltip extends PureComponent {
    static displayName = 'CloneDashboardTooltipContainer';

    static propTypes = {
        cloneDashboard: PropTypes.func.isRequired,
        dashboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        onClone: PropTypes.func,
        tooltipOptions: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.string
    };

    static defaultProps = {
        dashboardId: undefined,
        onClone: null,
        tooltipOptions: {},
        value: ''
    };

    constructor(props) {
        super(props);

        this.handleClone = this.handleClone.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            loading: false,
            value: props.value
        };
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue } = prevProps;
        const { value } = this.props;
        if (prevValue !== value) {
            this.setState({ value });
        }
    }

    handleChange({ value }) {
        this.setState({ value });
    }

    handleClone() {
        const { cloneDashboard, dashboardId, history, onClone } = this.props;
        const { value } = this.state;
        this.setState({ loading: true }, () => {
            cloneDashboard(dashboardId, value)
                .then(({ id }) => {
                    this.setState({ loading: false }, () => {
                        if (onClone) onClone();
                        history.push(generatePath(routes.dashboard, { dashboardId: id }));
                    });
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        });
    }

    render() {
        const { children, tooltipOptions } = this.props;
        const { loading, value } = this.state;

        return (
            <CloneDashboardTooltipUI
                loading={loading}
                onChange={this.handleChange}
                onClone={this.handleClone}
                value={value}
                tooltipOptions={tooltipOptions}
            >
                {children}
            </CloneDashboardTooltipUI>
        );
    }
}

export const CloneDashboardTooltipContainer = compose(
    withUrlContext(['history']),
    withCloneDashboard()
)(CloneDashboardTooltip);
