/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import autosize from 'autosize';
import { TextareaUI } from './ui';

class Formik extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => {}
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange({ event }) {
        const { onChange } = this.props;
        onChange(event);
    }

    render() {
        return <Textarea {...this.props} onChange={this.onChange} />;
    }
}

export class Textarea extends PureComponent {
    static displayName = 'TextareaContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        disabled: PropTypes.bool,
        error: PropTypes.string,
        id: PropTypes.string,
        initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        maxLength: PropTypes.number,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tabIndex: PropTypes.number,
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.string,
        useAutosize: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    static defaultProps = {
        autoFocus: false,
        className: '',
        containerStyle: {},
        disabled: false,
        error: '',
        id: null,
        initialValue: undefined,
        label: null,
        maxLength: undefined,
        onBlur: null,
        onChange: null,
        onFocus: null,
        onKeyDown: () => {},
        onKeyUp: () => {},
        placeholder: '',
        styles: {},
        tabIndex: undefined,
        tooltipComponent: undefined,
        tooltipOptions: {},
        type: 'text',
        useAutosize: true,
        value: undefined
    };

    static Formik = Formik;

    constructor(props) {
        super(props);

        const { initialValue, maxLength, value } = props;

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleRef = this.handleRef.bind(this);
        this.focus = this.focus.bind(this);
        this.textareaRef = null;

        this.state = {
            isFocused: false,
            inputValue: initialValue || '',
            remainingChar: maxLength - (initialValue || value || '').length
        };
    }

    componentDidUpdate({ maxLength: prevMaxLength, value: prevValue }) {
        const { maxLength, value } = this.props;

        if (value && value.length && (maxLength !== prevMaxLength || value !== prevValue)) {
            this.setState({
                remainingChar: maxLength - value.length
            });
        }
    }

    handleRef(node) {
        const { useAutosize } = this.props;
        if (useAutosize) {
            autosize(node);
        }

        if (node) {
            this.textareaRef = node;
        }
    }

    handleBlur(e) {
        const { onBlur } = this.props;

        this.setState({
            isFocused: false
        });

        if (onBlur) {
            onBlur(e);
        }
    }

    handleChange(e) {
        const { onChange, name, value, maxLength } = this.props;
        const {
            target: { value: inputValue }
        } = e;

        if (maxLength && inputValue) {
            this.setState({
                remainingChar: maxLength - inputValue.length
            });
        }

        // Only update the state input value when the provided value prop is undefined
        // This should only happen when the component is uncontrolled (initialValue is passed in instead of value)
        if (value === undefined) {
            this.setState({
                inputValue
            });
        }

        if (onChange) {
            onChange({ event: e, name, value: inputValue });
        }
    }

    handleFocus(e) {
        const { onFocus } = this.props;

        this.setState({
            isFocused: true
        });

        if (onFocus) {
            onFocus(e);
        }
    }

    handleKeyDown(event) {
        const { onKeyDown } = this.props;
        const { target } = event;

        if (onKeyDown) onKeyDown(event);
        target.focus();
    }

    focus() {
        if (this.textareaRef) {
            this.textareaRef.focus();
        }
    }

    render() {
        const {
            autoFocus,
            className,
            containerStyle,
            disabled,
            error,
            id,
            label,
            maxLength,
            name,
            onKeyUp,
            placeholder,
            styles,
            tabIndex,
            tooltipComponent,
            tooltipOptions,
            type,
            value
        } = this.props;
        const { isFocused, inputValue, remainingChar } = this.state;

        return (
            <TextareaUI
                textareaRef={this.handleRef}
                autoFocus={autoFocus}
                className={className}
                containerStyle={containerStyle}
                disabled={disabled}
                error={error}
                id={id}
                isFocused={isFocused}
                label={label}
                name={name}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyDown}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                maxLength={maxLength}
                remainingChar={remainingChar}
                styles={styles}
                type={type}
                value={value || inputValue}
                tabIndex={tabIndex}
                tooltipComponent={tooltipComponent}
                tooltipOptions={tooltipOptions}
            />
        );
    }
}

export const TextareaContainer = compose()(Textarea);
