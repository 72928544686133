const TRANSCRIPT_EDITOR_ACTIVE = 'TRANSCRIPT_EDITOR_ACTIVE';
const TRANSCRIPT_EDITOR_AWAY = 'TRANSCRIPT_EDITOR_AWAY';

export const types = {
    TRANSCRIPT_EDITOR_ACTIVE,
    TRANSCRIPT_EDITOR_AWAY
};

export function transcriptEditorActive() {
    return {
        type: TRANSCRIPT_EDITOR_ACTIVE
    };
}

export function transcriptEditorAway() {
    return {
        type: TRANSCRIPT_EDITOR_AWAY
    };
}
