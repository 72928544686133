export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: theme.colors.white01,
        height: '100%',
        overflow: 'hidden'
    },
    containerLoading: {
        backgroundColor: theme.colors.white01,
        paddingBottom: theme.margins.margin03,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    content: {
        overflow: 'auto',
        height: '100%'
    },
    download: {
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.gray14
        }
    },
    downloadOptions: {
        display: 'flex',
        marginRight: 30
    },
    downloadOption: {
        marginLeft: 12,
        borderRadius: 6,
        display: 'flex',
        svg: {
            marginRight: 6
        }
    },
    fullTextHeading: {
        padding: `0 ${theme.margins.margin02}`,
        lineHeight: '1.2em',
        fontFamily: theme.fonts.fontFamilyContent,
        fontWeight: theme.fonts.fontWeights.bold,
        marginBottom: theme.margins.margin00,
        span: {
            display: 'block',
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    eventDate: {
        marginBottom: 14,
        color: theme.colors.gray06
    },
    reportBlocksHeader: {
        fontSize: theme.fonts.fontSizes.size05,
        marginBottom: 20
    },
    reportBlock: {
        marginTop: 34,
        a: {
            color: theme.colors.blue10,
            textDecoration: 'none',
            ':hover': {
                color: theme.colors.blue11,
                textDecoration: 'underline'
            }
        }
    },
    reportBlockHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    reportBlocks: {
        padding: `0 ${theme.margins.margin02}`,
        marginTop: 24,
        strong: {
            fontWeight: theme.fonts.fontWeights.medium
        },
        '.raw-text': {
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em',
            marginBottom: 10
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    reportBlockTitle: {
        lineHeight: '1.3em',
        letterSpacing: '-0.2px',
        marginBottom: 4,
        marginTop: 4,
        maxWidth: 620
    },
    reportDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    reportSummary: {
        backgroundColor: theme.colors.gray03,
        margin: 30,
        borderRadius: 6,
        padding: '18px 20px'
    },
    reportSummaryText: {
        marginTop: 10,
        lineHeight: '1.3em'
    },
    reportNotice: {
        borderRadius: 6,
        margin: '0 30px 30px'
    },
    toggleSummary: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        svg: {
            transition: 'all 0.2s ease-in-out',
            flexShrink: 0,
            marginRight: 8
        }
    },
    toggleSummaryLabel: {
        flexShrink: 0,
        marginRight: 8
    },
    toggleSummaryPreview: {
        transition: 'all 0.2s ease-in-out',
        color: theme.colors.gray06,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    fullTextContainer: {
        padding: '28px 0',
        wordBreak: 'break-word',
        '> *': {
            overflowWrap: 'break-word'
        },
        ...theme.content.annotated,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        '.selectedBlock': {
            position: 'relative',
            '&:before': {
                content: '" "',
                display: 'block',
                whiteSpace: 'pre',
                position: 'absolute',
                left: -30,
                width: 2,
                backgroundColor: theme.colors.blue08,
                height: '100%',
                zIndex: 1
            }
        },
        '.searchTerm': {
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    loading: {
        padding: theme.margins.margin03,
        color: theme.colors.gray02
    },
    loadingBlock: {
        height: '24px',
        marginBottom: '10px',
        backgroundColor: theme.colors.gray03
    },
    loadingBlocks: {
        width: '80%',
        paddingTop: theme.margins.margin01,
        '> div:last-child': {
            marginRight: theme.margins.margin03
        }
    },
    typeDate: {
        padding: `0 ${theme.margins.margin02}`,
        p: {
            color: theme.colors.gray06
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    pdfPage: {
        paddingTop: 26,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 26,
        fontFamily: 'Rubik'
    },
    pdfBlock: {
        marginBottom: 30
    },
    pdfBlockTitle: {
        fontWeight: 700,
        fontSize: 12
    },
    pdfBlockText: {
        fontSize: 12,
        marginBottom: 10,
        lineHeight: '1.3em'
    },
    pdfBlockDate: {
        color: '#717181',
        fontSize: 9,
        marginBottom: 12
    },
    pdfHeader: {
        marginBottom: 24
    },
    pdfTitle: {
        color: '#000000',
        fontSize: 24,
        fontFamily: 'CharisSIL',
        fontWeight: 700
    },
    pdfBlockSummaryTitle: {
        color: '#000000',
        fontSize: 14,
        fontWeight: 700,
        marginTop: 4,
        marginBottom: 4,
        lineHeight: '1.4em',
        letterSpacing: -0.2
    },
    pdfSubTitle: {
        color: '#717181',
        fontSize: 12,
        marginBottom: 4,
        textTransform: 'capitalize'
    },
    pdfFooter: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 12
    },
    pdfWatermarkText: {
        marginTop: 4,
        fontSize: 12
    },
    pdfDisclaimer: {
        backgroundColor: '#f5fdff',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        paddingRight: 14,
        marginBottom: 10,
        borderRadius: 6
    },
    pdfDisclaimerText: {
        color: '#568f9b',
        fontSize: 12
    }
});
