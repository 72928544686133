import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generateModalId } from 'utils';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';

import { DashboardMenuOptionUI } from './ui';

export class DashboardMenuOption extends PureComponent {
    static displayName = 'DashboardMenuOptionContainer';

    static propTypes = {
        dashboardType: PropTypes.string,
        deleteDashboard: PropTypes.func.isRequired,
        description: PropTypes.string,
        draggable: PropTypes.bool.isRequired,
        editable: PropTypes.bool.isRequired,
        onDragDashboard: PropTypes.func,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        icon: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        dashboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // FROM ROUTE
        index: PropTypes.number.isRequired,
        isDefault: PropTypes.bool.isRequired,
        isShared: PropTypes.bool.isRequired,
        isFollowed: PropTypes.bool.isRequired,
        isRecommended: PropTypes.bool,
        label: PropTypes.string.isRequired,
        numUnread: PropTypes.number,
        pathname: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        sectionId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        unfollowMonitor: PropTypes.func.isRequired,
        unselectable: PropTypes.bool
    };

    static defaultProps = {
        dashboardId: undefined, // FROM ROUTE
        dashboardType: null,
        description: null,
        icon: undefined,
        isRecommended: false,
        numUnread: 0,
        styles: undefined,
        onDragDashboard: undefined,
        unselectable: false
    };

    constructor(props) {
        super(props);

        this.onEditDashboard = this.onEditDashboard.bind(this);
        this.onDeleteDashboard = this.onDeleteDashboard.bind(this);

        this.state = {
            deleting: false
        };
    }

    onEditDashboard() {
        const { history, pathname, id } = this.props;
        history.push(generateModalId({ pathname, type: 'dashboard', id }));
    }

    onDeleteDashboard() {
        const { deleteDashboard, unfollowMonitor, id, dashboardId, history, isShared, dashboardType } = this.props;
        const message =
            dashboardType === 'equity'
                ? 'Are you sure you want to remove this equity monitor from your sidebar? You can add it again later ' +
                  'directly from the equity page.'
                : 'Deleting this monitor will delete its saved searches as well. Are you sure you want to continue?';
        const confirmed = window.confirm(message);
        if (confirmed) {
            this.setState({ deleting: true }, () => {
                if (isShared) {
                    unfollowMonitor(id)
                        .then(() => {
                            this.setState({ deleting: false });
                            if (dashboardId === id) {
                                history.push(routes.dashboardHome);
                            }
                        })
                        .catch(() => {
                            this.setState({ deleting: false });
                        });
                } else {
                    deleteDashboard(id)
                        .then(() => {
                            this.setState({ deleting: false });
                            if (dashboardId === id) {
                                history.push(routes.dashboardHome);
                            }
                        })
                        .catch(() => {
                            this.setState({ deleting: false });
                        });
                }
            });
        }
    }

    render() {
        const {
            dashboardType,
            description,
            draggable,
            editable,
            icon,
            id,
            index,
            isDefault,
            isFollowed,
            isRecommended,
            isShared,
            label,
            numUnread,
            onDragDashboard,
            route,
            sectionId,
            styles,
            unselectable
        } = this.props;
        const { deleting } = this.state;
        return (
            <DashboardMenuOptionUI
                dashboardType={dashboardType}
                description={description}
                draggable={draggable}
                editable={editable}
                icon={icon}
                id={id}
                index={index}
                isDefault={isDefault}
                isFollowed={isFollowed}
                isRecommended={isRecommended}
                isShared={isShared}
                label={label}
                deleting={deleting}
                numUnread={numUnread}
                onDeleteDashboard={this.onDeleteDashboard}
                onDragDashboard={onDragDashboard}
                onEditDashboard={this.onEditDashboard}
                route={route}
                sectionId={sectionId}
                styles={styles}
                unselectable={unselectable}
            />
        );
    }
}

export const DashboardMenuOptionContainer = compose(
    withUrlContext(['history', 'pathname', 'dashboardId']),
    withData()
)(DashboardMenuOption);
