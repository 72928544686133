export const styleSheet = theme => ({
    container: {
        position: 'relative',
        a: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 4px 0 8px',
            height: 30,
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.colors.gray05,
            borderRadius: 6,
            ':hover': {
                backgroundColor: theme.colors.gray13,
                color: theme.colors.white01,
                '.menuIcon': {
                    visibility: 'visible',
                    'svg g': {
                        fill: theme.colors.white01
                    }
                },
                '.dashboardColumns': {
                    display: 'block',
                    'svg g': {
                        fill: theme.colors.blue08
                    }
                }
            },
            ':active': {
                backgroundColor: theme.colors.gray13
            }
        },
        '.selected': {
            backgroundColor: theme.colors.gray14,
            borderRadius: 6,
            p: {
                color: theme.colors.white01
            },
            '.optionIcon': {
                svg: {
                    g: {
                        fill: theme.colors.white01
                    },
                    path: {
                        fill: theme.colors.white01
                    }
                }
            },
            '.menuIcon': {
                visibility: 'visible',
                'svg g': {
                    fill: theme.colors.white01
                }
            },
            '.dashboardColumns': {
                display: 'block'
            },
            '.dashboardUnfollowed': {
                path: {
                    ':last-of-type': {
                        stroke: theme.colors.white01,
                        fill: theme.colors.blue08
                    }
                }
            },
            '.dashboardFollowed': {
                path: {
                    ':last-of-type': {
                        fill: theme.colors.yellow02
                    }
                }
            },
            '.dashboardEquity': {
                path: {
                    ':last-of-type': {
                        fill: theme.colors.yellow02
                    }
                }
            },
            ':hover': {
                backgroundColor: theme.colors.gray14,
                p: {
                    color: theme.colors.white01
                },
                '.menuIcon': {
                    visibility: 'visible',
                    'svg g': {
                        fill: theme.colors.white01
                    }
                }
            }
        }
    },
    dashboardMenuOptionLabel: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        p: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    defaultIconContainer: {
        textAlign: 'center',
        marginRight: 9,
        width: 15,
        height: 16,
        paddingTop: 0.5,
        svg: {
            width: 16
        }
    },
    dashboardIconContainer: {
        textAlign: 'center',
        marginRight: 9,
        width: 15,
        height: 16,
        paddingTop: 0.5
    },
    menuIcon: {
        height: 22,
        width: 22,
        display: 'flex',
        visibility: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        cursor: 'pointer',
        ':hover': {
            boxShadow: 'unset',
            backgroundColor: theme.colors.gray13
        },
        ':active': {
            backgroundColor: theme.colors.gray11
        }
    },
    hoverMenu: {
        ...theme.content.hoverMenu
    },
    hoverMenuOption: {
        ...theme.content.hoverMenuOption
    },
    menuIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        marginBottom: 2,
        textAlign: 'center'
    },
    unread: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.red08,
        borderRadius: 6,
        minWidth: 20,
        marginRight: 1,
        padding: '3px 4px',
        boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3)'
    },
    unreadMenuIcon: {
        marginRight: 0
    }
});
