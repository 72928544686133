import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import uniqBy from 'lodash/uniqBy';
import { compose, withProps, withPropsOnChange, withStateHandlers } from 'recompose';
import { withBaseSearch } from 'graphql/search';
import { get } from 'utils';
import { EquityAutocompleteUI } from './ui';

const SEARCH_TYPE = 'equities';

function formatResults(results = []) {
    return results.map(({ equity }) => {
        const id = get(equity, 'equityId');
        const ticker = get(equity, 'localTicker');
        const name = get(equity, 'commonName', get(equity, 'name', ''));
        const exchange = get(equity, 'exchange.shortName', '');
        return {
            companyId: get(equity, 'company.id'),
            label: ticker,
            value: id,
            exchange,
            name,
            ticker
        };
    });
}

export class EquityAutocomplete extends PureComponent {
    static displayName = 'EquityAutocompleteContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        clearOnSelect: PropTypes.bool,
        equities: PropTypes.arrayOf(PropTypes.object),
        icon: PropTypes.string,
        id: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        results: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        resultsPlaceholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        updateSearchTerm: PropTypes.func.isRequired,
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        wrapTags: PropTypes.bool
    };

    static defaultProps = {
        autoFocus: false,
        clearOnSelect: false,
        equities: null,
        icon: undefined,
        id: undefined,
        initialSearchTerm: null,
        label: undefined,
        loading: false,
        multi: false,
        name: 'equityAutocomplete',
        onChange: null,
        placeholder: undefined,
        results: [],
        resultsPlaceholder: undefined,
        styles: {},
        useTags: false,
        value: undefined,
        wrapTags: false
    };

    constructor(props) {
        super(props);

        this.getTagLabel = this.getTagLabel.bind(this);
        this.getCompanyId = this.getCompanyId.bind(this);
        this.getCompanyName = this.getCompanyName.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);

        this.formatResults = memoize(formatResults);

        this.state = {
            selectedEquities: props.equities || []
        };
    }

    componentDidUpdate({ equities: prevEquities }) {
        const { equities } = this.props;
        const prev = prevEquities || [];
        const current = equities || [];
        if (prev.length !== current.length) {
            this.setState({ selectedEquities: current });
        }
    }

    getTagLabel(equityId) {
        const { selectedEquities } = this.state;
        const equity = selectedEquities.find(e => e.value === equityId);

        if (equity) {
            return `${equity.label}:${equity.exchange}`;
        }

        return equityId;
    }

    getCompanyId(equityId) {
        const { selectedEquities } = this.state;
        const equity = selectedEquities.find(e => e.value === equityId);

        if (equity) {
            return equity.companyId;
        }

        return null;
    }

    getCompanyName(equityId) {
        const { selectedEquities } = this.state;
        const equity = selectedEquities.find(e => e.value === equityId);

        if (equity) {
            return equity.name;
        }

        return null;
    }

    onSearch({ value }) {
        const { updateSearchTerm } = this.props;
        updateSearchTerm(value);
    }

    onSelect({ event, value }) {
        const { onChange, name, results } = this.props;
        // We need to keep the selected equities in state because when the input value changes, so do the results
        // and we need a way to look up previously selected equities in order to generate the tag labels
        this.setState(
            ({ selectedEquities }) => ({
                selectedEquities: uniqBy(
                    [...selectedEquities, ...this.formatResults(results).filter(e => value && value.includes(e.value))],
                    'value'
                )
            }),
            () => {
                if (onChange)
                    onChange({
                        event,
                        name,
                        value,
                        companyName: this.getCompanyName(value),
                        companyId: this.getCompanyId(value),
                        label: this.getTagLabel(value)
                    });
            }
        );
    }

    render() {
        const {
            autoFocus,
            clearOnSelect,
            icon,
            id,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            placeholder,
            results,
            resultsPlaceholder,
            styles,
            useTags,
            value,
            wrapTags
        } = this.props;
        return (
            <EquityAutocompleteUI
                autoFocus={autoFocus}
                clearOnSelect={clearOnSelect}
                getTagLabel={this.getTagLabel}
                icon={icon}
                id={id}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onSearch={this.onSearch}
                onSelect={this.onSelect}
                options={this.formatResults(results)}
                placeholder={placeholder}
                resultsPlaceholder={resultsPlaceholder}
                styles={styles}
                useTags={useTags}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const EquityAutocompleteContainer = compose(
    withStateHandlers(() => ({ term: '' }), {
        updateSearchTerm: () => term => ({ term })
    }),
    withProps({
        searchType: SEARCH_TYPE,
        minTermLength: 1
    }),
    withBaseSearch(({ term = '' }) => ({
        skip: !term.length,
        variables: {
            withEquities: true
        },
        context: { debounceKey: 'withEquityAutocomplete', debounceTimeout: term.length ? 300 : 0 }
    })),
    withPropsOnChange(['searchLoading', 'searchResults'], ({ searchLoading, searchResults }) => ({
        loading: searchLoading,
        results: get(searchResults, 'hits', [])
    }))
)(EquityAutocomplete);
