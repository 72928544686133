import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { LoaderDotsUI } from './ui';

export class LoaderDots extends PureComponent {
    static displayName = 'LoaderDotsContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        gray: PropTypes.bool,
        light: PropTypes.bool
    };

    static defaultProps = {
        styles: {},
        gray: false,
        light: false
    };

    render() {
        const { styles, gray, light } = this.props;
        return <LoaderDotsUI styles={styles} gray={gray} light={light} />;
    }
}

export const LoaderDotsContainer = compose()(LoaderDots);
