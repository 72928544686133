import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class ContentStreamChart extends PureComponent {
    static propTypes = {
        chartRef: PropTypes.objectOf(PropTypes.any),
        options: PropTypes.objectOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        rangeTotal: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        chartRef: undefined,
        rangeTotal: undefined,
        passedStyles: {}
    };

    render() {
        const { styles, chartRef, passedStyles, options, rangeTotal } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType="stockChart"
                    options={options}
                    ref={chartRef}
                />
                {rangeTotal && rangeTotal > 0 ? (
                    <Hint
                        growUp
                        yOffset={-20}
                        xOffset={-7}
                        styles={styles.rangeTotal}
                        text={`${rangeTotal} Mentions for the selected range`}
                    >
                        <Text>{rangeTotal} Mentions</Text>
                    </Hint>
                ) : null}
            </Div>
        );
    }
}

export const ContentStreamChartUI = compose(withStyleSheet(styleSheet))(ContentStreamChart);
