export const styleSheet = theme => ({
    container: {
        display: 'flex',
        borderRadius: 6.5,
        overflow: 'hidden'
    },
    searchBox: {
        marginBottom: theme.margins.margin00,
        input: {
            borderRadius: 15,
            height: 30,
            backgroundColor: theme.colors.gray01,
            width: 164,
            fontSize: 14,
            transition: 'all 0.2s ease-in-out',
            ':hover': {
                borderRadius: 15,
                backgroundColor: theme.colors.white01
            }
        },
        '.iconWrapper': {
            height: 30,
            svg: {
                height: 14
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            maxWidth: 'unset',
            width: '100%',
            marginBottom: 6,
            marginRight: 'unset'
        }
    },
    typeListContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 200,
        padding: '14px 0',
        backgroundColor: theme.colors.gray01
    },
    typeList: {},
    type: {
        cursor: 'pointer',
        padding: `calc(${theme.margins.margin00} / 1.5) ${theme.margins.margin01}`,
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        }
    },
    disabled: {
        cursor: 'not-allowed',
        color: theme.colors.gray04,
        ':hover': {
            backgroundColor: 'unset'
        }
    },
    country: {
        width: '100%'
    },
    index: {
        width: '100%'
    },
    dropdown: {
        minWidth: 150
    },
    filterLabel: {
        letterSpacing: 1,
        marginBottom: 6
    },
    filterList: {
        flex: 1,
        padding: 16,
        paddingBottom: 4,
        backgroundColor: theme.colors.gray03
    },
    filterContainer: {
        ...theme.content.box,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.margins.margin00,
        borderRadius: 6
    },
    filter: {
        padding: theme.margins.margin00,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%'
    },
    filterRight: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    trashFilterButton: {
        padding: '0 16px',
        borderRadius: '0 6px 6px 0',
        cursor: 'pointer',
        backgroundColor: theme.colors.gray10,
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            backgroundColor: theme.colors.gray03
        }
    },
    minMax: {
        '> div': {
            width: 182
        }
    },
    noFilters: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'center',
        lineHeight: '1.2em',
        color: theme.colors.gray06,
        paddingBottom: 10
    }
});
