import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import styled from '@emotion/styled';
import { mapStylesProp } from 'utils/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';

import { styleSheet } from './stylesheet';

export const InputEle = styled('input')(mapStylesProp);

class TextInput extends PureComponent {
    static propTypes = {
        autoComplete: PropTypes.string.isRequired,
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        clearable: PropTypes.bool.isRequired,
        containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        disabled: PropTypes.bool,
        error: PropTypes.string,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        iconRight: PropTypes.string,
        iconRightColor: PropTypes.string,
        id: PropTypes.string,
        inputRef: PropTypes.objectOf(PropTypes.object),
        isFocused: PropTypes.bool.isRequired,
        label: PropTypes.string,
        maxLength: PropTypes.string,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onClear: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tabIndex: PropTypes.number,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any).isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    static defaultProps = {
        autoFocus: false,
        className: null,
        containerStyle: {},
        disabled: false,
        error: '',
        icon: null,
        iconColor: null,
        iconRight: null,
        iconRightColor: null,
        id: null,
        inputRef: null,
        label: null,
        maxLength: undefined,
        onBlur: null,
        onChange: null,
        onClear: null,
        onFocus: null,
        onKeyDown: () => {},
        onKeyUp: () => {},
        passedStyles: {},
        placeholder: '',
        tabIndex: undefined,
        tooltipComponent: undefined,
        value: ''
    };

    render() {
        const {
            styles,
            passedStyles,
            theme,
            id,
            autoComplete,
            autoFocus,
            className,
            clearable,
            containerStyle,
            disabled,
            error,
            isFocused,
            label,
            icon,
            inputRef,
            maxLength,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onKeyUp,
            onClear,
            placeholder,
            type,
            value,
            tabIndex,
            iconColor,
            iconRight,
            iconRightColor,
            tooltipComponent,
            tooltipOptions
        } = this.props;

        let iconColorOverride = iconColor;
        let iconRightColorOverride = iconRightColor;
        let inputStyles = error && error.length > 1 ? { ...styles.input, ...styles.inputError } : styles.input;
        if (value && value.length > 0) inputStyles = { ...inputStyles, ...styles.inputWithText };
        if (icon) {
            inputStyles = { ...inputStyles, ...styles.inputWithIcon };

            if (!iconColor) {
                iconColorOverride = error && error.length > 1 ? theme.colors.inputError : theme.colors.inputIconDefault;
                if (isFocused) iconColorOverride = theme.colors.inputFocus;
            }
        }

        if (disabled) {
            inputStyles = { ...inputStyles, ...styles.disabled };
        }

        if (iconRight && !iconRightColor) {
            iconRightColorOverride =
                error && error.length > 1 ? theme.colors.inputError : theme.colors.inputIconDefault;
            if (isFocused) iconRightColorOverride = theme.colors.inputFocus;
        }

        // The draggableCancel class ensures that clicking the input doesn't trigger dragging by ResponsiveGridLayout
        const elementClass = className && className.length > 0 ? `draggableCancel ${className}` : 'draggableCancel';
        const containerClass = onClear ? styles.containerClearable : styles.container;

        return (
            <Tooltip isEnabled={!!tooltipComponent} content={tooltipComponent} {...tooltipOptions}>
                {({ showTooltip, hideTooltip }) => (
                    <Div
                        styles={{ ...containerClass, ...containerStyle, ...passedStyles }}
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                    >
                        {(label || error) && (
                            <Div style={styles.labelContainer}>
                                {label && (
                                    <Text label uppercase styles={styles.label}>
                                        {label}
                                    </Text>
                                )}
                                {error && (
                                    <Text label uppercase styles={styles.textError}>
                                        {error}
                                    </Text>
                                )}
                            </Div>
                        )}
                        {icon && (
                            <Div styles={styles.iconWrapper} className="iconWrapper">
                                <Icon type={icon} color={iconColorOverride} />
                            </Div>
                        )}
                        <InputEle
                            data-tname={name}
                            ref={inputRef}
                            styles={inputStyles}
                            id={id}
                            autoComplete={autoComplete}
                            autoFocus={autoFocus}
                            className={elementClass}
                            disabled={disabled}
                            maxLength={maxLength}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            onFocus={onFocus}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            type={type}
                            value={value}
                            tabIndex={tabIndex}
                        />
                        {iconRight && (
                            <Div styles={styles.iconRightWrapper} className="iconRightWrapper">
                                <Icon type={iconRight} color={iconRightColorOverride} />
                            </Div>
                        )}
                        {clearable && value && (
                            <Div
                                className="clearInput"
                                styles={[styles.clearInput, label && styles.clearInputLabel]}
                                onMouseDown={onClear}
                                onTouchStart={onClear}
                            >
                                <Icon type="circleX" color={theme.colors.gray04} />
                            </Div>
                        )}
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const TextInputUI = compose(withStyleSheet(styleSheet))(TextInput);
