import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withNewsSources {
                    newsSources {
                        id
                        name
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    options: get(data, 'newsSources', []).map(({ id, name }) => ({ value: id, label: name }))
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
