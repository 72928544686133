import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { compose, withProps, withPropsOnChange, withStateHandlers } from 'recompose';
import { withIndices } from 'graphql/indices';
import { withBaseSearch } from 'graphql/search';
import { withSectors } from 'graphql/sectors';
import { get } from 'utils';
import { theme } from 'styles';
import { withWatchlistsData } from './data';
import { EquityScopeAutocompleteUI } from './ui';

const EQUITY_OPTIONS_HEADER = [
    {
        label: 'Equities',
        value: 'equityOptionsHeader',
        isGroupHeader: true,
        disabled: true,
        styles: theme.content.autocompleteGroupHeader
    }
];
const INDEX_OPTIONS_HEADER = [
    {
        label: 'Indices',
        value: 'indexOptionsHeader',
        isGroupHeader: true,
        disabled: true,
        styles: theme.content.autocompleteGroupHeader,
        type: 'index'
    }
];
const SECTOR_OPTIONS_HEADER = [
    {
        label: 'Sectors',
        value: 'sectorOptionsHeader',
        isGroupHeader: true,
        disabled: true,
        styles: theme.content.autocompleteGroupHeader,
        type: 'sector'
    }
];
const WATCHLIST_OPTIONS_HEADER = [
    {
        label: 'Watchlists',
        value: 'watchlistOptionsHeader',
        isGroupHeader: true,
        disabled: true,
        styles: theme.content.autocompleteGroupHeader,
        type: 'watchlist'
    }
];

function sectorOptions(sectors = []) {
    if (!sectors || !sectors.length) return [];
    const groupedSectors = sectors.map(({ name, id: gicsSectorId, subSectors = [] }) => ({
        label: name,
        type: 'sector',
        value: {
            value: { gicsSectorId },
            type: 'sector',
            label: name
        },
        options: subSectors.map(({ name: subSectorName, id: gicsSubSectorId }) => ({
            label: subSectorName,
            type: 'sector',
            value: {
                label: subSectorName,
                type: 'sector',
                value: { gicsSubSectorId }
            }
        }))
    }));
    const flattenedSectors = flatten(
        groupedSectors.map(s => (s.options ? [s, ...s.options.map(o => ({ ...o, parent: s }))] : s))
    );
    return [...SECTOR_OPTIONS_HEADER, ...flattenedSectors];
}

function equityOptions(results = []) {
    if (!results.length) return [];
    const equities = results.map(({ equity }) => {
        const id = get(equity, 'equityId');
        const ticker = get(equity, 'localTicker');
        const name = get(equity, 'commonName', get(equity, 'name', ''));
        const exchange = get(equity, 'exchange.shortName', '');
        return {
            id,
            label: ticker,
            value: {
                label: `${ticker}:${exchange}`,
                type: 'equity',
                value: id
            },
            exchange,
            name,
            ticker,
            type: 'equity'
        };
    });
    return [...EQUITY_OPTIONS_HEADER, ...equities];
}

function groupedOptions(watchlists = [], equities = [], indices = [], sectors = []) {
    return watchlists.concat(equities, indices, sectors);
}

function indexOptions(indices = []) {
    if (!indices || !indices.length) return [];
    const indexes = sortBy(indices, ({ displayName }) => displayName).map(({ id, displayName }) => ({
        id,
        label: displayName,
        value: {
            label: displayName,
            type: 'index',
            value: id
        },
        type: 'index'
    }));
    return [...INDEX_OPTIONS_HEADER, ...indexes];
}

function search(items, term) {
    if (!term || !term.length) return items;
    return (items || []).filter(
        item =>
            item.disabled ||
            item.label.toLowerCase().includes(term) ||
            (item.options &&
                item.options.length &&
                item.options.some(({ label }) => label.toLowerCase().includes(term)))
    );
}

function watchlistOptions(watchlists = []) {
    if (!watchlists.length) return [];
    const options = watchlists.map(({ name, watchlistId }) => ({
        label: name,
        type: 'watchlist',
        value: {
            label: name,
            type: 'watchlist',
            value: watchlistId
        }
    }));
    return [...WATCHLIST_OPTIONS_HEADER, ...options];
}

export class EquityScopeAutocomplete extends PureComponent {
    static displayName = 'EquityScopeAutocompleteContainer';

    static propTypes = {
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        indices: PropTypes.arrayOf(PropTypes.object),
        indicesLoading: PropTypes.bool,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        resultsPlaceholder: PropTypes.string,
        searchLoading: PropTypes.bool,
        searchResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        sectors: PropTypes.arrayOf(PropTypes.any),
        sectorsLoading: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        term: PropTypes.string,
        updateSearchTerm: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        watchlists: PropTypes.arrayOf(PropTypes.object),
        watchlistsLoading: PropTypes.bool,
        wrapTags: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        icon: undefined,
        indices: undefined,
        indicesLoading: false,
        initialSearchTerm: null,
        label: undefined,
        multi: true,
        name: 'equityAutocomplete',
        onChange: null,
        placeholder: 'Filter by equity, index, sector, or watchlist',
        resultsPlaceholder: 'Search for equity, index, sector, or watchlist',
        searchLoading: false,
        searchResults: null,
        sectors: [],
        sectorsLoading: false,
        styles: {},
        term: undefined,
        value: undefined,
        watchlists: [],
        watchlistsLoading: false,
        wrapTags: false
    };

    constructor(props) {
        super(props);

        this.getTagLabel = this.getTagLabel.bind(this);
        this.getTagStyle = this.getTagStyle.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.equityOptions = memoize(equityOptions);
        this.groupedOptions = memoize(groupedOptions);
        this.indexOptions = memoize(indexOptions);
        this.search = memoize(search);
        this.sectorOptions = memoize(sectorOptions);
        this.watchlistOptions = memoize(watchlistOptions);

        this.state = {
            searchResults: []
        };
    }

    componentDidUpdate(prevProps) {
        const { searchResults: prevSearchResults, term: prevTerm } = prevProps;
        const { searchResults: searchResultsProp, term } = this.props;
        // Update search results in state when the equity search results change
        if (prevSearchResults && (prevSearchResults !== searchResultsProp || prevTerm !== term)) {
            this.setState(({ searchResults }) => ({
                searchResults: uniqBy(
                    [
                        ...searchResults.filter(r => r.type === 'watchlist'),
                        ...this.equityOptions(searchResultsProp),
                        ...searchResults.filter(r => r.type === 'index'),
                        ...searchResults.filter(r => r.type === 'sector')
                    ],
                    'value'
                )
            }));
        }
    }

    getTagStyle(tag) {
        const type = get(tag, 'type');
        let backgroundColor = theme.colors.yellow06;
        if (type === 'watchlist') {
            backgroundColor = theme.colors.blue12;
        }
        if (type === 'equity') {
            backgroundColor = theme.colors.green08;
        }
        if (type === 'index') {
            backgroundColor = theme.colors.red05;
        }
        return { backgroundColor };
    }

    getTagLabel(tag) {
        return get(tag, 'label');
    }

    onChange({ event, value }) {
        const { onChange, name } = this.props;

        if (onChange) {
            onChange({ event, name, value });
        }
    }

    onSearch({ value }) {
        const { indices, sectors, watchlists, updateSearchTerm } = this.props;
        const term = (value || '').trim().toLowerCase();
        // Keep index, sector, and watchlist search results in state
        // while we wait for the equity search results
        const filteredIndices = this.search(this.indexOptions(indices), term);
        const filteredSectors = this.search(this.sectorOptions(sectors), term);
        const filteredWatchlists = this.search(this.watchlistOptions(watchlists), term);
        // Do not include the sector and watchlist headers in the search results
        this.setState({
            searchResults: [...(filteredWatchlists || []), ...(filteredIndices || []), ...(filteredSectors || [])]
        });
        // Trigger a new equity search
        updateSearchTerm(value);
    }

    render() {
        const { searchResults: searchResultsState } = this.state;
        const {
            disabled,
            icon,
            indices,
            indicesLoading,
            initialSearchTerm,
            label,
            multi,
            name,
            placeholder,
            resultsPlaceholder,
            searchLoading,
            searchResults,
            sectors,
            sectorsLoading,
            styles,
            term,
            value,
            watchlists,
            watchlistsLoading,
            wrapTags
        } = this.props;
        return (
            <EquityScopeAutocompleteUI
                disabled={disabled}
                getTagLabel={this.getTagLabel}
                getTagStyle={this.getTagStyle}
                icon={icon}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={indicesLoading || searchLoading || sectorsLoading || watchlistsLoading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                onSearch={this.onSearch}
                options={
                    term
                        ? searchResultsState
                        : this.groupedOptions(
                              this.watchlistOptions(watchlists),
                              this.equityOptions(searchResults),
                              this.indexOptions(indices),
                              this.sectorOptions(sectors)
                          )
                }
                placeholder={placeholder}
                resultsPlaceholder={resultsPlaceholder}
                styles={styles}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const EquityScopeAutocompleteContainer = compose(
    withWatchlistsData(),
    withIndices(),
    withSectors({ variables: { withSubSectors: true } }),
    withProps(({ sectorsLoading }) => ({ sectorsLoading })),
    withStateHandlers(() => ({ term: '' }), {
        updateSearchTerm: () => term => ({ term })
    }),
    withProps({
        searchType: 'equities',
        minTermLength: 1
    }),
    withBaseSearch(({ term = '' }) => ({
        skip: !term.length,
        variables: {
            withEquities: true
        },
        context: { debounceKey: 'withEquityScopeAutocomplete', debounceTimeout: term.length ? 300 : 0 }
    })),
    withPropsOnChange(['searchLoading', 'searchResults'], ({ searchLoading, searchResults }) => ({
        searchResults: get(searchResults, 'hits', []),
        searchLoading
    }))
)(EquityScopeAutocomplete);
