export const styleSheet = theme => {
    const result = {
        cursor: 'pointer',
        paddingTop: 8,
        paddingBottom: 8,
        lineHeight: '1em',
        paddingRight: theme.margins.margin01,
        paddingLeft: theme.margins.margin00,
        '.localTicker': {
            lineHeight: '1em'
        }
    };
    return {
        formNumber: {
            float: 'right',
            marginLeft: theme.margins.margin01,
            marginTop: 7
        },
        loadButtonRow: {
            padding: 10
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            margin: '12px auto'
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        loadMoreButtonHighlighted: {
            borderRadius: 6,
            justifyContent: 'center',
            boxShadow: `0 0 0 1px ${theme.colors.blue08}`,
            p: {
                color: theme.colors.blue08
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        result: {
            ...result,
            ':nth-of-type(even)': {
                backgroundColor: theme.colors.gray10
            }
        },
        resultHighlighted: {
            ...result,
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01,
            '.identifierTag': {
                p: {
                    color: theme.colors.black01
                }
            },
            '.localTicker': {
                color: theme.colors.black01
            },
            em: {
                color: theme.colors.black01
            },
            p: {
                color: theme.colors.white01
            },
            div: {
                color: theme.colors.white01
            }
        },
        resultTop: {},
        results: {
            width: '100%'
        },
        subTitle: {
            marginTop: 2,
            color: theme.colors.gray06,
            lineHeight: '1.2em'
        },
        title: {
            fontSize: 14,
            lineHeight: '1.2em'
        }
    };
};
