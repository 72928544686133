import { matchPath } from 'react-router-dom';
import XDate from 'xdate';
import getIn from 'lodash/get';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import qs from 'qs';
import { config } from 'configuration';
import { ENVIRONMENTS, TAB_TYPES, HIGHLIGHT_COLOR_MAP, HIGHLIGHT_COLORS } from 'consts';

const MODAL_KEYS = [
    'dashboardGalleryId',
    'dashboardStatsId',
    'editBookmarkId',
    'editDashboardId',
    'editStreamId',
    'editWatchlistId',
    'equityFinancialsId',
    'exportHighlights',
    'submitEventDetailsId',
    'watchlistIcalId'
];
const NUMBER_WITH_COMMAS_REGEX = /\B(?=(\d{3})+(?!\d))/g;

/**
 * Utils used by other utils in this file are defined at the top
 * Everything else is alphabetical
 */
export const get = (object, path, def) => {
    const val = getIn(object, path, def);
    // If val is false or 0, we want to return val, but if
    // it's not defined or null then return the default value.
    return val === undefined || val === null ? def : val;
};

export function isBeta() {
    return window.location.host === 'beta.aiera.com';
}

export function isNumber(value) {
    return !Number.isNaN(parseFloat(value));
}

// The last argument is "format" and will override the other formatting options
export const toDateTimeString = (initialDate, full = false, includeTimeZone = false, format = null) => {
    let normalizedDate = initialDate;
    if (initialDate) {
        if (typeof initialDate === 'string' && initialDate.includes('.')) {
            normalizedDate = initialDate.replace('.', ' ');
        }
        const date = new XDate(normalizedDate);
        if (date.valid()) {
            let f = full ? 'MMM d, h:mm TT, yyyy' : 'MMM d, h:mm TT';
            if (includeTimeZone) {
                const timeZone = new Date()
                    .toLocaleString(navigator.language, { timeZoneName: 'short' })
                    .split(' ')
                    .pop();

                if (timeZone) {
                    f = full ? `MMM d, h:mm TT '${timeZone}', yyyy` : `MMM d, h:mm TT '${timeZone}'`;
                }
            }
            return date.toString(format || f);
        }
    }
    return 'N/A';
};

/**
 * BEGIN ALPHABETICAL LIST
 */
export function abbrevNum(num, fixed, forceDecimals, maxDenomination) {
    if (num === null) {
        return null;
    }
    if (num === 0) {
        return '0';
    }
    if (!isNumber(num)) {
        return 'NaN';
    }
    const denominations = ['', 'K', 'M', 'B', 'T'];
    let dec = !fixed || fixed < 0 ? 0 : fixed;
    const b = num.toPrecision(2).split('e');
    const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
    const c = k < 1 ? num.toFixed(0 + dec) : (num / 10 ** (k * 3)).toFixed(1 + dec);
    const d = c < 0 ? c : Math.abs(c);

    if (!forceDecimals && d % 1 === 0) {
        dec = 0;
    }

    let e;
    // maxDenomination is a string value representing the maximum denomination to use in the list
    // e.g. abbrevNum(1000000, 2, false, 'K') => $1,000K instead of $1M
    if (maxDenomination) {
        const denominationIdx = denominations.indexOf(maxDenomination);
        if (k > denominationIdx) {
            const diff = k - denominationIdx;
            let multiplier;
            if (diff === 1) multiplier = 1000;
            if (diff === 2) multiplier = 10000;
            if (diff === 3) multiplier = 100000;
            e =
                Number(d * multiplier)
                    .toFixed(0)
                    .replace(NUMBER_WITH_COMMAS_REGEX, ',') + maxDenomination;
        }
    } else {
        e = Number(d).toFixed(dec) + denominations[k];
    }

    return e;
}

export function anyChanged(fieldList, prev, next) {
    return fieldList.some(f => prev[f] !== next[f]);
}

export function areDatesSameDay(firstDate, secondDate) {
    try {
        const first = typeof firstDate === 'string' ? new Date(firstDate) : firstDate;
        const second = typeof secondDate === 'string' ? new Date(secondDate) : secondDate;
        return (
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        );
    } catch {
        return false;
    }
}

const arraySwapSplice = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
    return array;
};
export const arraySwap = (array, from, to) => {
    return arraySwapSplice([...array], from, to);
};

export function capitalize(string = '') {
    if (typeof string === 'string') {
        return (string || '').charAt(0).toUpperCase() + (string || '').slice(1);
    }

    return string || '';
}

/**
 * This takes a function that returns a promise, a delay integer value, and an optional leading flag
 * If leading is true, the function is run only after the delay, otherwise the function runs immediately
 * and gets resolved after the delay
 */
export function delayPromise(promiseFunc, delay, leading = false) {
    const delayed = new Promise(resolve => setTimeout(resolve, delay));
    return leading ? delayed.then(promiseFunc) : Promise.all([promiseFunc(), delayed]).then(([result]) => result);
}

// TODO replace with https://github.com/zenorocha/clipboard.js
function fallbackCopyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        return Promise.resolve();
    } catch {
        return Promise.resolve(); // swallow exception
    } finally {
        document.body.removeChild(textArea);
    }
}
// TODO replace with https://github.com/zenorocha/clipboard.js
export function copyToClipboard(text) {
    if (!window.navigator.clipboard) {
        return fallbackCopyToClipboard(text);
    }
    // Check if user granted permission before attempting to write to clipboard
    try {
        return navigator.permissions
            .query({ name: 'clipboard-write' })
            .then(permissionStatus => {
                if (permissionStatus.state === 'granted') {
                    return navigator.clipboard.writeText(text);
                }
                return fallbackCopyToClipboard(text);
            })
            .catch(() => {
                return fallbackCopyToClipboard(text);
            });
    } catch {
        return fallbackCopyToClipboard(text);
    }
}

// This is just like debounce, but instead of debouncing the function
// directly, it creates a debounced function that takes in a function
// and args to call. This is only useful for cases where you want to
// debounce at a higher level and don't have access to the actual
// function yet.  For example, if you know you want to only call a
// mutation once every seconds, no matter how many components have
// loaded the mutation and are firing it, then you can create a top
// level debounced function like this, and pass in the mutation to
// it in the components.
//
export function debounceCallback(timeout, options) {
    return debounce((func, ...args) => func(...args), timeout, options);
}

export function formatFinancial(valueType, value, decimals = 2) {
    if (typeof value !== 'number' || !valueType) {
        return value;
    }
    if (valueType === 'raw') {
        const formattedValue = parseFloat(value)
            .toFixed(decimals)
            .replace(NUMBER_WITH_COMMAS_REGEX, ',');
        return value > 0 ? formattedValue : `(${formattedValue})`;
    }
    if (valueType === 'currency') {
        return value > 0 ? `$${abbrevNum(value, decimals)}` : `($${abbrevNum(Math.abs(value), decimals)})`;
    }
    if (valueType === 'percentage') {
        return value > 0
            ? `${parseFloat(value * 100).toFixed(decimals || 0)}%`
            : `(${Math.abs(parseFloat(value * 100).toFixed(decimals || 0))}%)`;
    }
    if (valueType === 'multiple') {
        return value > 0 ? `${parseFloat(value).toFixed(1)}x` : `(${Math.abs(parseFloat(value).toFixed(1))}x)`;
    }
    if (valueType === 'number') {
        return value > 0 ? `${abbrevNum(value, decimals)}` : `(${abbrevNum(Math.abs(value), decimals)})`;
    }
    return null;
}

/**
 * Initials
 */
export function generateInitials(string) {
    if (string) {
        let initials = string.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }

    return undefined;
}

/**
 * Url-driven modal navigation
 */
export function generateModalId({ dashboardId, id, location, pathname, tabId, type }) {
    const path = get(location, 'pathname', pathname);
    const searchQuery = get(location, 'search', '');
    const query = omit(qs.parse(searchQuery.replace(/^\?/, '')), MODAL_KEYS);

    if (type === 'bookmark') query.editBookmarkId = id;
    if (type === 'dashboard') query.editDashboardId = id;
    if (type === 'dashboardGallery') query.dashboardGalleryId = id;
    if (type === 'dashboardStats') query.dashboardStatsId = id;
    if (type === 'equityFinancials') query.equityFinancialsId = id;
    if (type === 'exportEvents') query.exportEventsId = id;
    if (type === 'exportHighlights') query.exportHighlights = id;
    if (type === 'privateRecording') query.editPrivateRecordingId = id;
    if (type === 'stream') query.editStreamId = id;
    if (type === 'streamMatches') query.exportMatchesId = id;
    if (type === 'submitEventDetails') query.submitEventDetailsId = id;
    if (type === 'transcrippet') query.transcrippetId = id;
    if (type === 'upgrade') query.upgradeType = id;
    if (type === 'videoPlayer') query.videoType = id;
    if (type === 'watchlist') query.editWatchlistId = id;
    if (type === 'watchlistIcal') query.watchlistIcalId = id;

    if (dashboardId) {
        query.dashboardId = dashboardId;
    }

    if (tabId) {
        query.tabs = [tabId];
    }

    return `${path}?${qs.stringify(query, { encode: false })}`;
}

/**
 * Tab navigation
 * */
export function generateTabId({
    audioCallId,
    dataRecordId,
    documentId,
    eventId,
    id,
    initialSearchTerm,
    itemId,
    filingId,
    match,
    newsId,
    page,
    pageId,
    researchId,
    reportId,
    search,
    spotlightId,
    streetAccountId,
    subPage,
    subPageId,
    tabType,
    streamId
}) {
    const parts = [];

    // DETERMINE TAB TYPE
    if (id && tabType) {
        parts.push(tabType);
        parts.push(id);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }

        if (subPage) {
            parts.push(subPage);
            if (subPageId) {
                parts.push(subPageId);
            }
        }

        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (newsId) {
        parts.push(TAB_TYPES.news);
        parts.push(newsId);
        if (match && streamId) {
            parts.push('match');
            parts.push(streamId);
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (reportId) {
        parts.push(TAB_TYPES.report);
        parts.push(reportId);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (researchId) {
        parts.push(TAB_TYPES.research);
        parts.push(researchId);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (documentId) {
        parts.push(TAB_TYPES.document);
        parts.push(documentId);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (filingId) {
        parts.push(TAB_TYPES.filing);
        parts.push(filingId);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }
        if (match && streamId) {
            parts.push('match');
            parts.push(streamId);
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else if (spotlightId) {
        parts.push(TAB_TYPES.spotlight);
        parts.push(spotlightId);
        if (match && streamId) {
            parts.push('match');
            parts.push(streamId);
        }
    } else if (streetAccountId) {
        parts.push(TAB_TYPES.streetAccount);
        parts.push(streetAccountId);
        if (match && streamId) {
            parts.push('match');
            parts.push(streamId);
        }
    } else if (dataRecordId) {
        parts.push(TAB_TYPES.dataRecord);
        parts.push(dataRecordId);
        parts.push(streamId);
    } else if (eventId || audioCallId) {
        parts.push(TAB_TYPES.event);
        parts.push(audioCallId || eventId);
        if (page) {
            parts.push(page);
            if (pageId) {
                parts.push(pageId);
            }
        }
        if (match || search) {
            parts.push(match ? 'match' : 'search');
            parts.push(streamId);
            if (itemId) {
                parts.push(itemId);
            }
        }
        if (initialSearchTerm) {
            parts.push('initialSearch');
            parts.push(initialSearchTerm);
        }
    } else {
        return null;
    }

    return parts.join('|');
}

export function generateTabURL({
    audioCallId,
    dataRecordId,
    documentId,
    eventId,
    filingId,
    forPush,
    forecastId,
    itemId,
    initialSearchTerm,
    pathname,
    location,
    match,
    newsId,
    page,
    pageId,
    researchId,
    reportId,
    search,
    spotlightId,
    streamId,
    streetAccountId,
    subPage,
    subPageId,
    tabId: userTabId,
    tabIndex = 0
}) {
    let path = pathname;
    if (!pathname) {
        path = get(location, 'pathname');
    }
    const query = {};
    let tabId = userTabId;

    if (!tabId) {
        tabId = generateTabId({
            audioCallId,
            dataRecordId,
            documentId,
            eventId,
            filingId,
            forecastId,
            initialSearchTerm,
            itemId,
            match,
            newsId,
            page,
            pageId,
            researchId,
            reportId,
            search,
            spotlightId,
            streamId,
            streetAccountId,
            subPage,
            subPageId
        });
    } else if (typeof tabId === 'object') {
        tabId = generateTabId(tabId);
    }

    if (typeof tabIndex === 'number') {
        if (query.tabs) {
            query.tabs[tabIndex] = tabId;
        } else {
            query.tabs = [tabId];
        }
    } else if (query.tabs) {
        query.tabs.push(tabId);
    } else {
        query.tabs = [tabId];
    }

    query.tabs = uniq(query.tabs);

    if (forPush) {
        return { pathname: path, search: qs.stringify(query, { encode: false }) };
    }

    return `${path}?${qs.stringify(query, { encode: false })}`;
}

/**
 * Go through the company's instruments (sorted by primary first)
 * and find the US-based quote.
 * If no US-based quote is found,
 * use the first instrument's first quote (also sorted by primary first)
 */
export function getCompanyQuotes(instruments) {
    const quotes = [];
    let defaultQuote;
    let primaryQuote;
    (instruments || [])
        .sort((a, b) => b.isPrimary - a.isPrimary)
        .forEach(i => {
            get(i, 'quotes', [])
                .sort((a, b) => b.isPrimary - a.isPrimary)
                .forEach((quote, idx) => {
                    if (quote) {
                        const isQuotePrimary = get(quote, 'isPrimary');
                        // Set a default quote in case we don't find a primary one
                        if (!defaultQuote && idx === 0) {
                            defaultQuote = quote;
                        }
                        if (!primaryQuote && isQuotePrimary) {
                            primaryQuote = quote;
                        }
                        // Favor US-based quotes when they're also set as primary
                        if (isQuotePrimary && get(quote, 'exchange.country.countryCode') === 'US') {
                            primaryQuote = quote;
                        }
                        quotes.push(quote);
                    }
                });
        });
    // If no US-based quote is found, use the first instrument's first quote as primary
    if (!primaryQuote) {
        primaryQuote = defaultQuote;
    }
    return {
        primaryQuote,
        quotes
    };
}

// Return a date when hasUnknownTime is true, otherwise return a date & time
export function getEventDateTime(callDate, hasUnknownTime, condensed = false) {
    return hasUnknownTime
        ? new XDate(callDate).toString('MMM d, yyyy')
        : toDateTimeString(callDate, !condensed, !condensed);
}

export const getHighlightColor = memoize(color => {
    let selectedColor = HIGHLIGHT_COLOR_MAP[color];

    if (!selectedColor) {
        const colorKey = HIGHLIGHT_COLORS.find(c => HIGHLIGHT_COLOR_MAP[c].color === color);
        selectedColor = HIGHLIGHT_COLOR_MAP[colorKey];
    }

    if (color) {
        return selectedColor || HIGHLIGHT_COLOR_MAP.blue;
    }

    return {};
});

// Takes an equity's price, currency, and various formatting props, and returns a formatted price string
export function getNativePrice({
    currency: curr,
    decimals: dec,
    doNotAbbreviate,
    forceDecimals: forceDec,
    maxDenomination,
    postFix,
    price,
    raw,
    useParentheses
}) {
    if (price === undefined || typeof price !== 'number') {
        return price;
    }
    const currency = curr || {};
    const decimals = dec === undefined ? 2 : dec;
    const forceDecimals = forceDec === undefined ? true : forceDec;
    const { currencyCode, symbol, minorSymbol } = currency;
    // UK-based equities are the only ones using sub-units (minorSymbol)
    const currencySymbol = currencyCode === 'GBP' ? minorSymbol || symbol : symbol || '$';
    const currencyPrefix =
        currencyCode === 'GBP'
            ? get(currency, 'minorSymbolPrefix', get(currency, 'symbolPrefix', true))
            : get(currency, 'symbolPrefix', true);
    const value =
        currencyCode === 'GBP' || raw || doNotAbbreviate
            ? parseFloat(price)
                  .toFixed(decimals)
                  .replace(NUMBER_WITH_COMMAS_REGEX, ',')
            : abbrevNum(price, decimals, forceDecimals, maxDenomination);
    if (useParentheses && price < 0) {
        return (
            `(${currencyPrefix ? currencySymbol : ''}` +
            `${
                doNotAbbreviate
                    ? value.replace('-', '')
                    : abbrevNum(Math.abs(price), decimals, forceDecimals, maxDenomination)
            }` +
            `${!currencyPrefix ? currencySymbol : ''}${postFix || ''})`
        );
    }
    return `${currencyPrefix ? currencySymbol : ''}${value}${!currencyPrefix ? currencySymbol : ''}${postFix || ''}`;
}

export const getPathParams = (pathname, { exact = false, path, strict = false }) => {
    const matchProfile = matchPath(pathname, { exact, path, strict });
    return (matchProfile && matchProfile.params) || null;
};

export function getPreference(preferences, { type, name }, defaultValue) {
    const value = get(preferences, `${type}.${name}`);
    if (value !== null && value !== undefined) {
        // If the value is the string "true" or "false", return a real boolean
        return ['true', 'false'].includes(value) ? JSON.parse(value) : value;
    }
    // If we didn't find any matching preference, return the defaultValue
    return defaultValue;
}

export const getUrlParams = memoize(location => qs.parse(location.search.replace(/^\?/, '')));

// Returns offsets from viewport boundaries.
// positive values means that side is within the viewport,
// negative values means it's outside the viewport
export const getViewportOffset = ele => {
    const bounding = ele && ele.getBoundingClientRect ? ele.getBoundingClientRect() : false;
    if (bounding) {
        return {
            left: bounding.left,
            top: bounding.top,
            right: (window.innerWidth || document.documentElement.clientWidth) - bounding.right,
            bottom: (window.innerHeight || document.documentElement.clientHeight) - bounding.bottom
        };
    }
    return { top: 0, left: 0, right: 0, bottom: 0 };
};

export function hasClassInPath(node, className) {
    let currentNode = node;
    while (currentNode) {
        if (currentNode.classList && currentNode.classList.contains(className)) return true;
        currentNode = currentNode.parentNode;
    }
    return false;
}

export function hasPermission(user, perm, maxEnv) {
    let envRestricted = false;
    if (maxEnv) {
        let currentEnvIndex = ENVIRONMENTS.findIndex(e => config.AIERA_ENV === e);
        if (currentEnvIndex < 0) currentEnvIndex = ENVIRONMENTS.length - 1;
        let maxEnvIndex = ENVIRONMENTS.findIndex(e => e === maxEnv);
        if (maxEnvIndex < 0) maxEnvIndex = ENVIRONMENTS.length - 1;
        envRestricted = currentEnvIndex > maxEnvIndex;
    }
    return (get(user, 'permissions') || []).includes(perm) && !envRestricted;
}

export function hasPermissions(user, perms, maxEnv) {
    if (!user || !Array.isArray(perms)) return false;
    return !perms.some(perm => !hasPermission(user, perm, maxEnv));
}

export function hasPreference(preferences, { type, name, value }, defaultValue) {
    return getPreference(preferences, { type, name }, defaultValue) === value;
}

export function isEventComplete(status) {
    return ['finished', 'missed', 'archived'].includes(status);
}

export function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function makeCancelable(promise, shouldThrowOnCancel = false) {
    let isCanceled = false;
    const canceledError = new Error('Canceled');
    canceledError.isCanceled = true;

    const wrappedPromise = promise
        .then(
            val => {
                if (isCanceled) throw canceledError;
                else return val;
            },
            error => {
                throw isCanceled ? canceledError : error;
            }
        )
        .catch(error => {
            if (!error.isCanceled || shouldThrowOnCancel) {
                throw error;
            }
        });

    wrappedPromise.cancel = () => {
        isCanceled = true;
    };
    return wrappedPromise;
}

/**
 * When events have hasUnknownTime,
 * return "yesterday", "today", or "tomorrow" when the call date is in range.
 * Otherwise, return null
 */
export function maybeGetUnknownTimeAgo(callDate) {
    if (callDate) {
        const diff = Math.ceil(XDate.today().diffDays(new XDate(callDate)));
        if (diff < 0 && diff >= -1) return 'yesterday';
        if (diff <= 1 && diff >= 0) return 'today';
        if (diff <= 2 && diff > 1) return 'tomorrow';
    }
    return null;
}

// Utility for normalizing single line articles coming from MT NewsWires
export function mtNewsNormalize(str) {
    // First we'll convert any new lines to <p> tags
    const normalizedString = str
        .split('\n')
        .map(l => `<p>${l}</p>`)
        .join('');

    // Single line articles seem to be all p tags with 1 character, or \n
    // so lets find those
    const regex = /<p>[a-zA-Z0-9]{1}<\/p>/g;

    // Use the match() function to find all occurrences of the regex in the input string
    const matches = normalizedString.match(regex);

    // If there are more than 15 p tags with 1 character
    // lets assume it's a 1 line article and strip out the html
    if (matches && matches.length > 15) {
        // Strip HTML out of a string;
        const htmlReg = /(<([^>]+)>)/gi;
        return normalizedString.replace(htmlReg, '');
    }

    return str;
}

export const numberWithCommas = x => {
    return x.toString().replace(NUMBER_WITH_COMMAS_REGEX, ',');
};

export function parseTabId(tabId) {
    const tabObject = {};

    if (!tabId) {
        return tabObject;
    }

    const tabInfo = tabId.split('|');
    tabObject.tabType = get(tabInfo, '[0]');
    tabObject.id = get(tabInfo, '[1]', '');
    tabObject.page = get(tabInfo, '[2]');
    tabObject.pageId = get(tabInfo, '[3]');
    tabObject.subPage = get(tabInfo, '[4]');
    tabObject.subPageId = get(tabInfo, '[5]');
    tabObject.tabInfo = tabInfo;
    tabObject.tabKey = tabInfo.slice(0, 2).join('|');

    const initialSearchIndex = tabInfo.findIndex(v => v === 'initialSearch');
    if (initialSearchIndex > 0) {
        tabObject.initialSearch = get(tabInfo, `[${initialSearchIndex + 1}]`);
        // We don't want the initialSearch being confused for other location based variables
        if (tabObject.page === 'initialSearch') {
            tabObject.page = undefined;
            tabObject.pageId = undefined;
        }
        if (tabObject.pageId === 'initialSearch') {
            tabObject.pageId = undefined;
            tabObject.subPage = undefined;
        }
        if (tabObject.subPage === 'initialSearch') {
            tabObject.subPage = undefined;
            tabObject.subPageId = undefined;
        }
        if (tabObject.subPageId === 'initialSearch') {
            tabObject.subPageId = undefined;
        }
    }

    const { tabType, page, pageId, subPage, id } = tabObject;

    if (tabType === TAB_TYPES.event || tabType === TAB_TYPES.audioCall) {
        tabObject.eventId = id;
        tabObject.audioCallId = id;
        tabObject.match = page === 'match';
        tabObject.search = page === 'search';
        tabObject.streamId = pageId;
        tabObject.itemId = subPage;
    }

    if (tabType === TAB_TYPES.news) {
        tabObject.newsId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.research) {
        tabObject.researchId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.report) {
        tabObject.reportId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.document) {
        tabObject.documentId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.filing) {
        tabObject.filingId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.spotlight) {
        tabObject.spotlightId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.streetAccount) {
        tabObject.streetAccountId = id;
        tabObject.match = page === 'match';
        tabObject.streamId = pageId;
    }

    if (tabType === TAB_TYPES.dataRecord) {
        tabObject.dataRecordId = id;
        tabObject.streamId = page;
    }

    return tabObject;
}

/**
 * This util will replace the spaces in the last part of the string with
 * nbsp;'s and this will prevent those words from being orphaned
 *
 * @param line - the string to prettify
 * @param percentage - the amount of string to replace spaces
 * @param lineLength - the avg line length incase multiple breaks are needed
 *
 */
export function prettyLineBreak(line, percentage = 0.6, lineLength) {
    const lines = line.length / lineLength;
    if (lines > 2) {
        const startLength = line.length - lineLength;
        const centerIndex = lineLength * percentage + startLength;
        const start = line.slice(0, centerIndex);
        const end = line.slice(centerIndex).replace(/ /g, '\u00a0');
        return start + end;
    }
    const centerIndex = line.length * percentage;
    const start = line.slice(0, centerIndex);
    const end = line.slice(centerIndex).replace(/ /g, '\u00a0');
    return start + end;
}

export function prettyEventType(type) {
    const removeUnderscore = type.replace(/_/g, ' ');
    const capFirst = removeUnderscore[0].toUpperCase() + removeUnderscore.slice(1);
    return capFirst;
}

// Takes min and max integer values and returns a random number in that range
export function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Returns a new Regular Expression
 * If creating a RegExp from the original string raises an exception, escape special characters and try again
 * Source for escaping: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 */
export const safeRegExp = str => {
    if (!str || typeof str !== 'string') return str;

    const safeString = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return new RegExp(`(${safeString})`, 'gi');
};

export function titleize(string = '') {
    const titleized = string
        .replace(/([a-z])([A-Z])/g, (allMatches, firstMatch, secondMatch) => {
            return `${firstMatch} ${secondMatch}`;
        })
        .toLowerCase()
        .replace(/([ -_]|^)(.)/g, (allMatches, firstMatch, secondMatch) => {
            return (firstMatch ? ' ' : '') + secondMatch.toUpperCase();
        });
    return titleized.trim();
}

export const toDateString = (initialDate, full = false) => {
    let string = 'N/A';

    if (initialDate) {
        const date = new XDate(initialDate);
        const format = full ? 'MMMM dd, yyyy' : 'MMM d';
        if (date) {
            string = date.toString(format);
        }
    }

    return string;
};

// For 00:00:00 styles duration strings,
// max of 24 hours
export function toDurationString(seconds) {
    if (seconds === undefined) return '00:00:00';
    const d = new XDate('1/1/1970');
    d.addSeconds(seconds);
    return d.toString('HH:mm:ss');
}

export const toTimeString = initialDate => {
    if (!initialDate) {
        return 'N/A';
    }
    const date = new XDate(initialDate);
    const format = 'h:mm TT';
    return date.toString(format);
};

export function toUpperSnakeCase(str) {
    return str
        .replace(/\.?([A-Z]+)/g, (x, y) => `_${y}`)
        .replace(/^_/, '')
        .toUpperCase();
}

export const truncateString = (string, max) => {
    if (string && max) {
        if (string.length > max) {
            return `${string.slice(0, max)}...`;
        }
    }

    return string || '';
};

export const validateEmail = email => {
    const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const formattedEmail = email.trim();

    if (!formattedEmail) return false;
    if (formattedEmail.length > 254) return false;

    const valid = tester.test(formattedEmail);
    if (!valid) return false;

    // Further checking of some things regex can't handle
    const parts = formattedEmail.split('@');
    if (parts[0].length > 64) return false;

    const domainParts = parts[1].split('.');
    return !domainParts.some(part => part.length > 63);
};

/**
 * This utility function accepts two arguments:
 *     1) values is expected to be an object of input names and their respective values
 *     2) rules is expected to be an array of objects with the following props:
 *        "name" - the input name (required, must match the name in the supplied values object)
 *        "matchingInput" - the name of an input whose value will be used for an exact match comparison (optional)
 *        "minLength" - integer value for the minimum character length of the input value (optional)
 *        "maxLength" - integer value for the maximum character length of the input value (optional)
 *        "validator" - function that will be called to perform the validation (optional, must return a boolean)
 *        "message" - the error message that will be used for the input (optional)
 *        "required" - boolean value for whether or not the input is required (optional, default is true)
 *
 * Usage:
 *     validateInputs(
 *         { email: '', firstName: 'Morty', phone: '(555) 555-55', password: 'qwert', passwordConfirmation: 'qwerty' },
 *         [
 *             { name: 'email', validator: validateEmail },
 *             { name: 'firstName' },
 *             { name: 'phone', minLength: 10, maxLength: 10, validator: validatePhone },
 *             { name: 'password', minLength: 6, maxLength: 32 },
 *             { name: 'passwordConfirmation', matchingInput: password, message: 'Passwords must match' }
 *         ]
 *     )
 *
 * Result:
 *     {
 *         email: 'Required',
 *         phone: 'Invalid',
 *         password: 'Too short',
 *         passwordConfirmation: 'Passwords must match'
 *     }
 * */
export const validateInputs = (values = {}, rules = []) => {
    const errors = {};
    const hasValue = value => value && value.trim().length > 0;

    if (Object.keys(values).length && rules.length) {
        rules.forEach(({ name, matchingInput, minLength, maxLength, validator, message, required = true }) => {
            // If required (default is true), check if the value is missing
            if (required && (!values[name] || values[name].trim().length === 0)) {
                errors[name] = 'Required';
            }

            // Call the custom validator function, if supplied
            if (
                !errors[name] &&
                validator &&
                typeof validator === 'function' &&
                hasValue(values[name]) &&
                !validator(values[name])
            ) {
                errors[name] = message || 'Invalid';
            }

            // If exactMatch is supplied, compare the input value against this input name's value for an exact match
            if (!errors[name] && matchingInput && hasValue(values[name]) && values[name] !== values[matchingInput]) {
                errors[name] = message || `Must match ${matchingInput}`;
            }

            // If minLength is supplied, check if the value is too short
            if (!errors[name] && minLength && hasValue(values[name]) && values[name].trim().length < minLength) {
                errors[name] = message || 'Too short';
            }

            // If maxLength is supplied, check if the value is too long
            if (!errors[name] && maxLength && hasValue(values[name]) && values[name].trim().length > maxLength) {
                errors[name] = message || 'Too long';
            }
        });
    }

    return errors;
};

// Slightly modified version of https://stackoverflow.com/a/3809435/461294
export const validateUrl = url => {
    if (!url || typeof url !== 'string' || url.trim().length === 0) return false;
    return url.match(
        /^(https?:\/\/(www\.)?)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    );
};
