export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    scrollContainer: {
        padding: `0 0 ${theme.margins.margin01}`,
        overflowY: 'auto',
        '::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }
    },
    header: {
        alignItems: 'center',
        paddingTop: 8,
        paddingRight: theme.margins.margin01,
        marginBottom: theme.margins.margin00,
        position: 'sticky',
        top: 0,
        display: 'flex',
        backgroundColor: theme.colors.gray10,
        zIndex: 2,
        '.dropdown': {
            background: 'transparent',
            boxShadow: 'unset',
            color: theme.colors.gray06,
            padding: '0 0 0 12px',
            justifyContent: 'flex-start',
            'svg g': {
                fill: theme.colors.gray06
            },
            ':hover': {
                boxShadow: 'unset',
                color: theme.colors.black01,
                'svg g': {
                    fill: theme.colors.black01
                }
            }
        },
        '.dropdownArrow': {
            marginLeft: theme.margins.margin00
        }
    },
    settingsButton: {
        position: 'relative',
        marginLeft: theme.margins.margin00,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    settingsButtonDisabled: {
        position: 'relative',
        marginLeft: theme.margins.margin00,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        opacity: 0.3
    },
    settings: {
        ...theme.content.hoverMenu,
        backgroundColor: theme.colors.white01,
        width: 279
    },
    options: {
        margin: 15,
        svg: {
            flexShrink: 0,
            marginRight: 14
        }
    },
    option: {
        padding: `${theme.margins.margin00} ${theme.margins.margin01} ${theme.margins.margin00} 16px`
    },
    optionText: {
        marginRight: theme.margins.margin01,
        lineHeight: '1em'
    },
    optionTitle: {
        marginBottom: 2
    },
    settingsHeader: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15
    },
    headerSubtext: {
        color: theme.colors.gray04,
        marginTop: 6
    },
    filterDropdown: {
        width: 130,
        marginLeft: theme.margins.margin00
    },
    sortDropdown: {
        width: 121
    },
    highlight: {
        ...theme.content.box,
        position: 'relative',
        margin: `0 ${theme.margins.margin01} ${theme.margins.margin01}`,
        ':last-of-type': {
            marginBottom: 0
        },
        ':hover': {
            '.actionMenu-hover-btn': {
                visibility: 'visible'
            }
        }
    },
    highlightSpeaker: {
        marginRight: theme.margins.margin01,
        marginBottom: 8
    },
    actionMenu: {
        opacity: 0.7,
        visibility: 'hidden',
        position: 'absolute',
        top: 6,
        right: 6,
        zIndex: 1,
        ':hover': {
            opacity: 1,
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 8
    },
    tag: {
        margin: '6px 6px 0 0',
        padding: '4px 6px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        p: {
            textTransform: 'capitalize'
        }
    },
    highlightedText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '14px 16px 16px',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '5px 5px 0 0'
    },
    highlightText: {
        marginTop: 10,
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.blue08
        },
        '.highlightText': {
            fontSize: theme.fonts.fontSizes.size03,
            fontStyle: 'italic',
            lineHeight: '1.3em'
        }
    },
    highlightTime: {
        marginTop: 4,
        color: theme.colors.gray04
    },
    note: {
        margin: '16px 16px',
        position: 'relative'
    },
    noteHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    noteCreated: {
        marginTop: 4,
        color: theme.colors.gray04
    },
    noteText: {
        marginTop: theme.margins.margin00,
        lineHeight: '1.3em'
    },
    sharedIndicator: {
        height: 16,
        width: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    emptyMessage: {
        marginTop: theme.margins.margin02,
        position: 'relative',
        textAlign: 'center',
        color: theme.colors.gray04,
        lineHeight: '1.3em',
        // Video player preview image
        '.react-player__preview': {
            borderRadius: 6,
            margin: 'auto',
            maxWidth: 213,
            minHeight: 120
        }
    },
    emptyIcon: {
        height: 40,
        width: 40
    },
    emptyGraphic: {
        position: 'absolute',
        top: 20,
        marginLeft: -43
    },
    listenButton: {
        ...theme.content.blueButton,
        display: 'flex',
        alignSelf: 'start',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 30,
        height: 26,
        cursor: 'pointer',
        padding: '0 10px 0 8px',
        margin: '14px 0 0 -2px',
        svg: {
            height: 8
        },
        p: {
            marginLeft: 3,
            color: theme.colors.white01
        }
    },
    loader: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    spacer: {
        flex: 1
    },
    videoDescription: {
        padding: '50px 30px 0'
    },
    videoPlayer: {
        padding: '10px 30px 0'
    }
});
