import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LoaderLogo } from 'components/LoaderLogo';
import { Notification } from 'components/Notification';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class AlertBanner extends PureComponent {
    static propTypes = {
        content: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.string,
        dashboard: PropTypes.objectOf(PropTypes.any),
        equity: PropTypes.objectOf(PropTypes.any),
        event: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool.isRequired,
        message: PropTypes.objectOf(PropTypes.any),
        notificationType: PropTypes.string,
        notificationsCount: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        transcriptId: PropTypes.string,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        content: null,
        created: null,
        dashboard: null,
        equity: null,
        event: null,
        message: null,
        notificationType: null,
        passedStyles: {},
        stream: null,
        transcriptId: null
    };

    render() {
        const {
            content,
            created,
            dashboard,
            equity,
            event,
            loading,
            message,
            notificationType,
            notificationsCount,
            onClick,
            passedStyles,
            stream,
            styles,
            transcriptId,
            visible
        } = this.props;

        if (!visible) {
            return null;
        }

        if (loading) {
            return (
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <LoaderLogo styles={styles.loading} />
                </Div>
            );
        }

        const footer =
            notificationsCount > 1 ? (
                <Text size={1} styles={styles.notificationsCount}>
                    {notificationsCount} more notifications
                </Text>
            ) : null;
        return (
            <ErrorBoundary>
                <Notification
                    content={content}
                    created={created}
                    dashboard={dashboard}
                    equity={equity}
                    event={event}
                    footer={footer}
                    isRead
                    message={message}
                    notificationType={notificationType}
                    onClick={onClick}
                    stream={stream}
                    styles={{ ...styles.container, ...passedStyles }}
                    transcriptId={transcriptId}
                />
            </ErrorBoundary>
        );
    }
}

export const AlertBannerUI = compose(withStyleSheet(styleSheet))(AlertBanner);
