import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { EventCountdown } from 'components/EventCountdown';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { PARTICIPATION_TYPES } from 'components/PrivateRecordingForm/consts';
import { EVENT_LOG_STATES } from 'consts';
import { capitalize, get, titleize, toDateTimeString } from 'utils';
import { styleSheet } from './stylesheet';
import { EventLogs } from '../EventLogs';

const FINISHED_STATES = [EVENT_LOG_STATES.completed, EVENT_LOG_STATES.connected];
const IN_PROGRESS_STATES = [EVENT_LOG_STATES.connecting, EVENT_LOG_STATES.receiving];
const CONNECTING_STATES = FINISHED_STATES.concat(IN_PROGRESS_STATES);

class PrivateRecordingGate extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string,
        currentState: PropTypes.objectOf(PropTypes.any),
        disconnect: PropTypes.func.isRequired,
        disconnected: PropTypes.bool.isRequired,
        disconnecting: PropTypes.bool.isRequired,
        eventId: PropTypes.string,
        hasTranscript: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        logsLoading: PropTypes.bool.isRequired,
        onEdit: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showLogs: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleLogs: PropTypes.func.isRequired
    };

    static defaultProps = {
        callDate: undefined,
        currentState: undefined,
        eventId: undefined,
        passedStyles: {}
    };

    renderContent() {
        const {
            callDate,
            currentState,
            disconnect,
            disconnected,
            disconnecting,
            eventId,
            isOwner,
            onEdit,
            showLogs,
            styles,
            theme,
            toggleLogs
        } = this.props;
        const missed = callDate ? new XDate(callDate).diffMinutes(new XDate()) > 5 : true;
        let body = (
            <Fragment>
                <Text size={3} styles={styles.description}>
                    {missed ? 'We did not connect to this Recording scheduled on' : 'This Recording is scheduled for'}
                    &nbsp;
                    <Span weight="medium">{toDateTimeString(callDate, true, false)}</Span>.
                    {isOwner && (
                        <Fragment>
                            <br />
                            <br />
                            Click&nbsp;
                            <Span onClick={onEdit} styles={styles.inlineLink}>
                                here
                            </Span>
                            &nbsp;or the pencil icon to edit the details.
                        </Fragment>
                    )}
                </Text>
                <EventCountdown
                    callDate={callDate}
                    labelStyles={styles.eventCountdownLabels}
                    showDays
                    styles={styles.eventCountdown}
                />
                <Text onClick={toggleLogs} size={1} styles={{ ...styles.inlineLink, ...styles.separator }}>
                    {showLogs ? 'Hide' : 'Show'} logs
                </Text>
                {showLogs && <EventLogs eventId={eventId} styles={styles.logs} />}
            </Fragment>
        );
        let heading = <Heading>{missed ? 'Missed' : 'Scheduled'}</Heading>;
        const footer = (
            <Fragment>
                <Div styles={styles.footer}>
                    <Text onClick={toggleLogs} size={1} styles={styles.inlineLink}>
                        {showLogs ? 'Hide' : 'Show'} logs
                    </Text>
                    <ActionButton onClick={onEdit} size={2} styles={styles.button}>
                        <Text size={3}>Confirm Details & Retry</Text>
                    </ActionButton>
                </Div>
                {showLogs && <EventLogs eventId={eventId} styles={styles.logs} />}
            </Fragment>
        );
        const state = get(currentState, 'state', '');
        const stateLabel = capitalize(titleize(state).toLowerCase());
        if (CONNECTING_STATES.includes(state)) {
            heading =
                state === EVENT_LOG_STATES.connecting ? (
                    <Heading>Waiting to connect...</Heading>
                ) : (
                    <Heading>Connected</Heading>
                );
            body = (
                <Fragment>
                    <Text size={3} styles={styles.description}>
                        After transcription begins, it will continue until the Recording ends. You can leave Aiera and
                        return at any time. You will be able to replay the audio from the recording after it has
                        completed.
                    </Text>
                    <LoaderLogo styles={styles.loading} />
                    <Text size={3} styles={styles.currentState}>
                        {stateLabel}
                        {IN_PROGRESS_STATES.includes(state) ? '...' : ''}
                    </Text>
                    {!disconnected && (
                        <ActionButton
                            disabled={disconnecting}
                            loaderColor={theme.colors.white01}
                            loading={disconnecting}
                            onClick={disconnect}
                            size={2}
                            styles={styles.buttonDisconnect}
                        >
                            <Text size={3}>End Call</Text>
                        </ActionButton>
                    )}
                </Fragment>
            );
        }
        if ([EVENT_LOG_STATES.completed, EVENT_LOG_STATES.error, EVENT_LOG_STATES.initial].includes(state)) {
            // Consider the private recording missed if it's been 2 minutes,
            // the latest state is "completed", and there is no transcript
            if (missed) {
                heading = <Heading>Failed to connect</Heading>;
                body = (
                    <Fragment>
                        <Text size={3} styles={styles.description}>
                            We could not connect to your Recording. Please click the&nbsp;
                            <Span onClick={onEdit} styles={styles.inlineLink}>
                                confirm details button
                            </Span>
                            &nbsp;below and make sure all the information is correct. Once everything looks good,
                            you&apos;ll be able to retry connecting to your recording.
                        </Text>
                        {footer}
                    </Fragment>
                );
            }
        }
        if (state === EVENT_LOG_STATES.dialingAgent) {
            heading = <Heading>Connecting you to the call...</Heading>;
            body = (
                <Fragment>
                    <Text size={3} styles={styles.description}>
                        {PARTICIPATION_TYPES.participating.description}
                    </Text>
                    <LoaderLogo styles={styles.loading} />
                    <Text size={3} styles={styles.currentState}>
                        {stateLabel}...
                    </Text>
                </Fragment>
            );
        }
        if (state === EVENT_LOG_STATES.stopped) {
            heading = <Heading>Stopped</Heading>;
            body = (
                <Fragment>
                    <Text size={3} styles={styles.description}>
                        The Recording has stopped. Please click the&nbsp;
                        <Span onClick={onEdit} styles={styles.inlineLink}>
                            confirm details button
                        </Span>
                        &nbsp;below and make sure all the information is correct. Once everything looks good,
                        you&apos;ll be able to retry connecting to your recording.
                    </Text>
                    {footer}
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Div styles={styles.headingContainer}>
                    {heading}
                    {isOwner && (
                        <Div onClick={onEdit} styles={styles.headingEditIcon} title="Edit Recording">
                            <Icon type="pencil02" color={theme.colors.black01} />
                        </Div>
                    )}
                </Div>
                {body}
            </Fragment>
        );
    }

    render() {
        const { currentState, hasTranscript, logsLoading, passedStyles, styles } = this.props;
        // Hide overlay while logs load or if we have a transcript
        if (hasTranscript || (logsLoading && !currentState)) return null;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.content}>{this.renderContent()}</Div>
            </Div>
        );
    }
}

export const PrivateRecordingGateUI = compose(withStyleSheet(styleSheet))(PrivateRecordingGate);
