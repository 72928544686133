import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withEventMediaPlayer } from 'graphql/audioCalls';

import { RecommendedEventUI } from './ui';

export class RecommendedEvent extends PureComponent {
    static displayName = 'RecommendedEventContainer';

    static propTypes = {
        callDate: PropTypes.string,
        equity: PropTypes.objectOf(PropTypes.any),
        firstTranscriptItemStartMs: PropTypes.number,
        hideTooltip: PropTypes.func.isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string
    };

    static defaultProps = {
        callDate: undefined,
        equity: undefined,
        firstTranscriptItemStartMs: undefined,
        mediaPlayer: null,
        styles: undefined,
        title: undefined
    };

    render() {
        const { callDate, equity, firstTranscriptItemStartMs, hideTooltip, mediaPlayer, styles, title } = this.props;
        return (
            <RecommendedEventUI
                callDate={callDate}
                exchange={get(equity, 'exchange.shortName')}
                firstTranscriptItemStartMs={firstTranscriptItemStartMs}
                hideTooltip={hideTooltip}
                mediaPlayer={mediaPlayer}
                styles={styles}
                ticker={get(equity, 'localTicker')}
                title={title}
            />
        );
    }
}

export const RecommendedEventContainer = compose(withEventMediaPlayer({ trackDetails: false }))(RecommendedEvent);
