import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { TrUI } from './ui';

export class Tr extends PureComponent {
    static displayName = 'TrContainer';

    static propTypes = {
        elementRef: PropTypes.func
    };

    static defaultProps = {
        elementRef: undefined
    };

    render() {
        const { elementRef, ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <TrUI {...rest} ref={elementRef} />;
    }
}

export const TrContainer = compose()(Tr);
