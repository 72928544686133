import React, { Component } from 'react';
import { compose } from 'recompose';
import { get } from 'utils';
import { InternalLayoutUI } from './ui';

export class InternalLayout extends Component {
    static displayName = 'InternalLayoutContainer';

    onDragDropEnd(result) {
        const onDragEnd = get(result, 'payload.onDragEnd');
        if (onDragEnd) onDragEnd(result);
    }

    onDragDropStart(result) {
        const onDragStart = get(result, 'payload.onDragStart');
        if (onDragStart) onDragStart(result);
    }

    render() {
        const { ...rest } = this.props;

        return <InternalLayoutUI {...rest} onDragDropEnd={this.onDragDropEnd} onDragDropStart={this.onDragDropStart} />;
    }
}

export const InternalLayoutContainer = compose()(InternalLayout);
