import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'events';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { routes, externalRoutes } from 'routes';
import { withGetUser } from 'graphql/user';
import { withMobileBridge } from 'provider/mobileBridge';
import { get } from 'utils';

export class MobileBridge extends PureComponent {
    static displayName = 'WebViewBridgeContainer';

    static propTypes = {
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        user: PropTypes.shape({
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        }).isRequired,
        bridge: PropTypes.instanceOf(EventEmitter).isRequired
    };

    constructor(props) {
        super(props);
        this.deepLink = this.deepLink.bind(this);
        this.nav = this.nav.bind(this);
    }

    componentDidMount() {
        const { bridge } = this.props;
        // If loaded in a RN WebView, window.ReactNativeWebView will exist,
        // and if it does we register the event listeners and emit the ready event.
        if (bridge.enabled()) {
            window.addEventListener('message', bridge.handler);
            bridge.captureLogs();
            bridge.on('deepLink', this.deepLink);
            bridge.on('nav', this.nav);
            bridge.ready();
            this.userChanged();
        }
    }

    componentDidUpdate(prevProps) {
        const currentUserId = get(this.props, 'user.userId');
        const prevUserId = get(prevProps, 'user.userId');

        if ((!prevUserId && currentUserId) || prevUserId !== currentUserId) {
            // Make sure the mobile app gets notified of any user changes to it can
            // subscribe/unsubscribe to push notifications.
            this.userChanged();
        }
    }

    componentWillUnmount() {
        const { bridge } = this.props;
        if (window.ReactNativeWebView) {
            window.removeEventlistener('message', this.bridge.handler);
            bridge.removeListener('deepLink', this.deepLink);
        }
    }

    userChanged() {
        const { bridge, user } = this.props;
        // Send the new user info over the bridge to the mobile app
        bridge.emit('userChange', { user });
    }

    deepLink({ url }) {
        const { history } = this.props;
        // When the app receives a deeplink url, it will ire this event over
        // the bridge so that we can navigate to the targeted page.
        const parts = new URL(url);
        history.push(`${parts.pathname}${parts.search}${parts.hash}`);
    }

    nav({ page }) {
        const { history } = this.props;
        const path = {
            'push-permission': routes.mobilePushPermission,
            welcome: externalRoutes.mobileWelcome
        }[page];

        if (path) {
            history.push(path);
        }
    }

    render() {
        // This doesn't render anything to the screen, it just needs to be mounted
        // somewhere need the root of the app to handle of the bridging logic with
        // the mobile app.
        return null;
    }
}

export const MobileBridgeContainer = compose(
    withGetUser({ fetchPolicy: 'cache-first', variables: { withDetails: true } }),
    withProps(({ user }) => ({
        user: {
            ...user,
            isLoggedIn: !!(user && user.id)
        }
    })),
    withRouter,
    withMobileBridge()
)(MobileBridge);
