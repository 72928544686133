import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';

import { get } from 'utils';
import { styleSheet } from './stylesheet';

class Footer extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        version: PropTypes.string,
        commit: PropTypes.string,
        commitLink: PropTypes.string,
        showCommit: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        version: null,
        commit: null,
        commitLink: null,
        showCommit: false
    };

    constructor(props) {
        super(props);

        this.disclaimer = React.createRef();
        this.state = {
            height: 'auto'
        };
    }

    componentDidMount() {
        if (this.disclaimer.current) {
            const height = get(this.disclaimer, `current.offsetHeight`, 'auto');
            this.setState({
                height
            });
        }
    }

    render() {
        const { version, commit, commitLink, showCommit, styles, passedStyles } = this.props;
        const { height } = this.state;

        const year = new Date().getFullYear();
        const disclaimerStyle = {
            ...styles.disclaimer,
            height: `${height}px`,
            marginBottom: `-${height}px`
        };

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.footerBar}>
                    <Text styles={styles.link} uppercase>
                        <a rel="noopener noreferrer" href="http://www.aiera.com/terms" target="_blank">
                            Terms of service
                        </a>
                    </Text>
                    <Text styles={styles.link} uppercase>
                        <a rel="noopener noreferrer" href="http://www.aiera.com/privacy" target="_blank">
                            privacy policy
                        </a>
                    </Text>
                    <Text styles={{ ...styles.link, ...styles.build }}>
                        {version && `v${version}`}
                        {showCommit && commit && commitLink && (
                            <Fragment>
                                :
                                <a rel="noopener noreferrer" href={commitLink} target="_blank">
                                    {commit.slice(0, 8)}
                                </a>
                            </Fragment>
                        )}
                    </Text>
                    <Text uppercase styles={styles.copyright}>
                        {`© ${year} AIERA, INC.`}
                    </Text>
                </Div>
                <Div styles={disclaimerStyle} ref={this.disclaimer}>
                    <Text size={1}>
                        AIERA regards information that it provides as data that readers may or may not use in evaluating
                        securities transactions that they may be contemplating. Readers should not construe the
                        information provided as investment advice or as recommendations to buy, sell, or hold any
                        securities. AIERA specifically disclaims having the information that it would need to tailor the
                        information that it provides to any particular person’s investment needs. AIERA advises readers
                        to consider the information carefully, and to consult with their advisors, as appropriate, prior
                        to making any investment decisions. AIERA is not registered as a securities broker-dealer or an
                        investment adviser with the U.S. Securities and Exchange Commission, the Financial Industry
                        Regulatory Authority or any state securities regulatory authority.
                    </Text>
                </Div>
            </Div>
        );
    }
}

export const FooterUI = compose(withStyleSheet(styleSheet))(Footer);
