import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { get } from 'utils';
import { formatMatchesByType } from 'utils/streams';
import { withMemo } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import { withStreamPaging } from 'hoc/streams';
import { streamFragment, equityMatchFragment } from 'graphql/fragments/streams';

export const withData = () =>
    compose(
        withMemo({
            formatMatchesByType
        }),
        withPropsOnChange(['rules', 'searchTerm'], ({ rules, searchTerm }) => {
            const rulesWithSearchTerm = [...rules];
            if (searchTerm) {
                rulesWithSearchTerm.push({ ruleType: 'search_term', condition: 'is_equal', value: searchTerm });
            }
            return {
                rules: rulesWithSearchTerm,
                searchTerm
            };
        }),
        graphql(
            gql`
                query previewWatchlist($rules: [StreamRuleInput], $size: Int = 50, $offset: Int = 0) {
                    streams(filter: { previewStreamTypes: [equities] }) {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: { rules: $rules }) {
                            total
                            results {
                                ...equityMatch
                            }
                        }
                    }
                }
                ${equityMatchFragment}
                ${streamFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                    const equities = formatMatches(get(data, 'streams[0].matches.results', []));
                    return {
                        loading: !equities.length && get(data, 'loading'),
                        equities,
                        fetchMore: data.fetchMore
                    };
                },
                options: ({ rules }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        rules
                    },
                    context: {
                        debounceKey: 'debounce:watchlistPreview',
                        debounceTimeout: 250
                    }
                })
            }
        ),
        withStreamPaging()
    );
