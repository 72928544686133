import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { ShareableLinkFieldUI } from './ui';

export class ShareableLinkField extends PureComponent {
    static displayName = 'ShareableLinkFieldContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        confirmText: PropTypes.string,
        label: PropTypes.string,
        link: PropTypes.string,
        onRefresh: PropTypes.func,
        tooltipText: PropTypes.string
    };

    static defaultProps = {
        styles: {},
        confirmText: null,
        label: 'share link',
        link: null,
        onRefresh: null,
        tooltipText: null
    };

    constructor(props) {
        super(props);

        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh() {
        const { confirmText, onRefresh } = this.props;
        let confirmRefresh = true;
        if (confirmText) confirmRefresh = window.confirm(confirmText);
        if (confirmRefresh && onRefresh) onRefresh();
    }

    render() {
        const { styles, label, link, tooltipText } = this.props;
        return (
            <ShareableLinkFieldUI
                styles={styles}
                label={label}
                link={link}
                onRefresh={this.handleRefresh}
                tooltipText={tooltipText}
            />
        );
    }
}

export const ShareableLinkFieldContainer = compose()(ShareableLinkField);
