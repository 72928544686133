export const styleSheet = theme => ({
    containerCompact: {
        borderRadius: 'unset',
        width: 'unset',
        margin: 'unset'
    },
    containerMinimal: {
        borderRadius: 0,
        width: 'unset',
        margin: 'unset'
    },
    compact: {
        borderRadius: 'unset',
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 13px'
    },
    dateTime: {
        display: 'flex',
        color: theme.colors.gray06,
        paddingTop: '4px',
        'p:first-of-type': {
            textTransform: 'capitalize',
            marginRight: 3
        }
    },
    minimal: {
        border: 'unset',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        backgroundColor: theme.colors.gray14,
        ':hover': {
            backgroundColor: theme.colors.gray11
        }
    },
    minimalContent: {
        padding: '12px 16px',
        borderTop: `1px solid ${theme.colors.gray11}`
    },
    minimalDate: {
        color: theme.colors.gray06
    },
    minimalTitle: {
        lineHeight: '1.3em',
        margin: '4px 0 4px',
        color: theme.colors.white01
    },
    minimalDetails: {
        color: theme.colors.gray04
    }
});
