import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { NewsResultsUI } from './ui';

export class NewsResults extends PureComponent {
    static displayName = 'NewsResultsContainer';

    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            enabled,
            hasMoreResults,
            highlightedResult,
            highlightResult,
            indexElement,
            loading,
            loadingMoreResults,
            loadMoreResults,
            onClickResult,
            results
        } = this.props;
        return (
            <NewsResultsUI
                enabled={enabled}
                hasMoreResults={hasMoreResults}
                highlightedResult={highlightedResult}
                highlightResult={highlightResult}
                indexElement={indexElement}
                loading={loading}
                loadingMoreResults={loadingMoreResults}
                loadMoreResults={loadMoreResults}
                onClickResult={onClickResult}
                results={results}
            />
        );
    }
}

export const NewsResultsContainer = compose()(NewsResults);
