import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { AttachmentCard } from 'components/AttachmentCard';
import { BookmarkCard } from 'components/BookmarkCard';
import { CorporateActionCard } from 'components/CorporateActionCard';
import { CustomCard } from 'components/CustomCard';
import { Div } from 'components/Div';
import { DocumentCard } from 'components/DocumentCard';
import { EquityCard } from 'components/EquityCard';
import { EventCard } from 'components/EventCard';
import { FilingCard } from 'components/FilingCard';
import { GSheetCard } from 'components/GSheetCard';
import { NewsCard } from 'components/NewsCard';
import { ResearchCard } from 'components/ResearchCard';
import { SpotlightCard } from 'components/SpotlightCard';
import { Stream } from 'components/Stream';
import { StreetAccountCard } from 'components/StreetAccountCard';
import { TranscriptCard } from 'components/TranscriptCard';
import { STREAM_DISPLAY_MODES } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    attachment: AttachmentCard,
    bookmark: BookmarkCard,
    corporate_action: CorporateActionCard,
    custom: CustomCard,
    document: DocumentCard,
    equity: EquityCard,
    event: EventCard,
    filing: FilingCard,
    gsheet: GSheetCard,
    news: NewsCard,
    research: ResearchCard,
    spotlight: SpotlightCard,
    streetaccount: StreetAccountCard,
    transcript: TranscriptCard
};

class PreviewStream extends PureComponent {
    static propTypes = {
        color: PropTypes.string,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        matches: PropTypes.arrayOf(PropTypes.object),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        streamTypes: PropTypes.arrayOf(PropTypes.string),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        streamDisplayMode: PropTypes.string,
        title: PropTypes.string,
        template: PropTypes.objectOf(PropTypes.any),
        templateType: PropTypes.string
    };

    static defaultProps = {
        color: null,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        passedStyles: {},
        streamDisplayMode: STREAM_DISPLAY_MODES.default,
        streamTypes: null,
        title: null,
        template: null,
        templateType: null
    };

    render() {
        const {
            color,
            loading,
            loadMoreMatches,
            matches,
            passedStyles,
            streamTypes,
            styles,
            streamDisplayMode,
            title,
            template,
            templateType
        } = this.props;
        const streamType = streamTypes.length > 0 ? streamTypes[0] : undefined;
        return (
            <Stream
                color={color}
                headerDisabled
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                offset={matches.length}
                streamType={streamType}
                styles={{ ...styles.container, ...passedStyles }}
                title={title || 'Preview'}
            >
                {matches
                    .map(m => {
                        const CardComponent = COMPONENT_MAP[m.type];
                        return CardComponent ? (
                            <Div key={`preview-card-${m.id}`} styles={styles.cardContainer}>
                                <CardComponent
                                    displayMode={streamDisplayMode}
                                    streamId="previewStream"
                                    template={template}
                                    templateType={templateType}
                                    {...m}
                                />
                            </Div>
                        ) : null;
                    })
                    .filter(c => c)}
            </Stream>
        );
    }
}

export const PreviewStreamUI = compose(withStyleSheet(styleSheet))(PreviewStream);
