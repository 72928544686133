import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { dataCollectionRecordFragment, streamMatchTemplateFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withCustomDataRecord($streamId: ID!, $dataRecordId: ID!) {
                    streams(filter: { streamIds: [$streamId] }) {
                        id
                        dataCollection {
                            id
                            records(dataRecordId: $dataRecordId) {
                                ...dataCollectionRecord
                            }
                        }
                        streamMatchTemplate {
                            ...streamMatchTemplate
                        }
                    }
                }
                ${dataCollectionRecordFragment}
                ${streamMatchTemplateFragment}
            `,
            {
                props: ({ data }) => {
                    const record = get(data, 'streams[0].dataCollection.records[0]');
                    const template = get(data, 'streams[0].streamMatchTemplate');
                    return {
                        loading: get(data, 'loading'),
                        record,
                        template
                    };
                },
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
