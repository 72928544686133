import gql from 'graphql-tag';

export const billingInvoiceFragment = gql`
    fragment billingInvoice on StripeInvoice {
        id
        periodEnd
        periodStart
        subtotal
        tax
        total
    }
`;

export const billingInvoiceLineItemFragment = gql`
    fragment billingInvoiceLineItem on StripeInvoiceLineItem {
        id
        amount
        description
        periodEnd
        periodStart
        quantity
    }
`;

export const billingProductFragment = gql`
    fragment billingProduct on BillingProduct {
        id
        description
        features
        msrp
        name
        unitLabel
    }
`;

export const billingProductPriceFragment = gql`
    fragment billingProductPrice on BillingProductPrice {
        id
        billingProductId
        billingProductPriceId
        currencyCode
        interval
        intervalCount
        isActive
        isPromoted
        trialPeriodDays
        unitAmount
        currency {
            id
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
    }
`;

export const billingSubscriptionFragment = gql`
    fragment billingSubscription on BillingSubscription {
        id
        currentPeriodEnd
        currentPeriodStart
        interval
        status
        items {
            id
            quantity
        }
    }
`;

export const paymentMethodFragment = gql`
    fragment paymentMethod on StripePaymentMethod {
        id
        brand
        expMonth
        expYear
        last4
        sourceId
    }
`;
