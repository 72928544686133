import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import { withUrlContext } from 'hoc/url';
import { withUserPreferences } from 'graphql/user';
import { tabMinimize, tabMaximize, tabRemove, tabTitleStore } from 'actions/tabs';
import { PREFERENCES } from 'consts';
import { get, generateTabURL, getPreference } from 'utils';
import { FloatingTabUI } from './ui';

export class FloatingTab extends PureComponent {
    static displayName = 'FloatingTabContainer';

    static propTypes = {
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        maximizedTabId: PropTypes.string,
        maximizeTab: PropTypes.func.isRequired,
        minimizeTab: PropTypes.func.isRequired,
        minimizedTabIds: PropTypes.arrayOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        removeTab: PropTypes.func.isRequired,
        storeTabTitle: PropTypes.func.isRequired,
        tabs: PropTypes.arrayOf(PropTypes.any),
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        maximizedTabId: null,
        minimizedTabIds: [],
        preferences: [],
        tabs: [],
        user: null
    };

    constructor(props) {
        super(props);

        this.beforeMinimize = this.beforeMinimize.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.generatePath = this.generatePath.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.connectScroll = this.connectScroll.bind(this);
        this.setScrolledHeader = this.setScrolledHeader.bind(this);
        this.setToolbarTitle = this.setToolbarTitle.bind(this);
        this.maximizeTab = this.maximizeTab.bind(this);
        this.removeTab = this.removeTab.bind(this);

        this.scrollingWindows = {};

        this.state = {
            visibleHeaders: [],
            scrolledHeaderContent: {},
            toolbarTitles: {},
            animateMinimize: false,
            showContent: false
        };
    }

    componentDidMount() {
        const { tabs } = this.props;
        document.addEventListener('keydown', this.handleKeyDown);
        if (tabs && tabs.length > 0) {
            this.maximizeTab(tabs[0]);
        }
    }

    componentDidUpdate(prevProps) {
        const { tabs: prevTabs, maximizedTabId: prevMaximizedTabId } = prevProps;
        const { history, maximizedTabId, minimizedTabIds, pathname, tabs } = this.props;
        const equalTabs = isEqual(prevTabs, tabs);
        let newQuery = {};
        let queryChanged = false;

        // TODO REMOVE OLD SCROLL HANDLERS FROM REMOVED NODES.

        // Tab Removed
        // Need to update history
        if (!!prevMaximizedTabId && !maximizedTabId) {
            queryChanged = true;
            newQuery = this.updateQuery(newQuery, prevMaximizedTabId, 'remove');
        }

        // Tab Maximized
        // Need to update history
        if (!!maximizedTabId && maximizedTabId !== prevMaximizedTabId) {
            queryChanged = true;
            newQuery = this.updateQuery(newQuery, maximizedTabId, 'add');
        }

        if (queryChanged) {
            // Retain existing query params when updating history with the new tab
            const params = qs.parse(history.location.search.replace(/^\?/, ''));
            // Remove existing tabs param since we're adding/removing with newQuery
            delete params.tabs;
            const query = { pathname, search: qs.stringify({ ...newQuery, ...params }, { encode: false }) };
            history.push(query);
        }

        // Changes based on URL
        // We know the tab query param has changed
        if (!equalTabs) {
            // There is a maximized tab, and since
            // we know the tabs changed, lets set it
            if (tabs.length > 0) {
                this.maximizeTab(tabs[0]);
            }

            // There was a previous tab, so if it wasn't
            // minimized, and if it isn't already set as
            // the maximized tab, lets remove it
            if (prevTabs.length > 0) {
                // An existing tab was removed
                const tabId = prevTabs[0];

                // Make sure it wasn't minimized
                const isNotMinimized = !minimizedTabIds.includes(tabId);

                // Make sure it isn't the current tab
                const isNotMaximized = maximizedTabId !== tabId;

                if (isNotMaximized && isNotMinimized) {
                    this.removeTab(tabId);
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        clearTimeout(this.endAnimationTimer);
        clearTimeout(this.showContentTimeout);
        clearTimeout(this.forceNextTick);
    }

    beforeMinimize(tabId) {
        const { minimizeTab } = this.props;
        this.setState(
            {
                animateMinimize: true,
                showContent: false
            },
            () => {
                this.endAnimationTimer = setTimeout(() => {
                    minimizeTab(tabId);

                    // This controls removing the CSS class
                    // which we want to be on the tick after the tab is removed.
                    this.forceNextTick = setTimeout(() => this.setState({ animateMinimize: false }));
                }, 800);
            }
        );
    }

    // TODO move this to Modal component when we start rendering full-screen tabs as modals
    handleKeyDown({ keyCode, code }) {
        const { history, pathname, preferences } = this.props;
        // Close tab when hitting Escape key
        // There's a known bug where if you have a modal open over the tab, this will close both
        // But we don't care since we're getting rid of double modals
        if (
            getPreference(preferences, PREFERENCES.floatingTabFullScreen, true) &&
            (code === 'Escape' || keyCode === 27)
        ) {
            history.push(pathname);
        }
    }

    handleScroll(tabId, target) {
        const { visibleHeaders } = this.state;
        const isVisible = visibleHeaders.includes(tabId);
        if (!isVisible && target.scrollTop >= 316) {
            this.setState({
                visibleHeaders: [...visibleHeaders, tabId]
            });
        }

        if (isVisible && target.scrollTop < 316) {
            const index = visibleHeaders.indexOf(tabId);
            this.setState({
                visibleHeaders: [...visibleHeaders.slice(0, index), ...visibleHeaders.slice(index + 1)]
            });
        }
    }

    connectScroll(tabId, node) {
        this.scrollingWindows[tabId] = node;
        if (node) {
            this.scrollingWindows[tabId].addEventListener('scroll', ({ target }) => this.handleScroll(tabId, target));
        }
    }

    maximizeTab(id) {
        const { maximizeTab } = this.props;
        maximizeTab(id);
        this.showContentTimeout = setTimeout(() => {
            this.setState({
                showContent: true
            });
        });
    }

    removeTab(id) {
        const { removeTab } = this.props;
        this.setState({ showContent: false }, () => removeTab(id));
    }

    setScrolledHeader(tabId, component) {
        const { scrolledHeaderContent } = this.state;
        const content = {
            ...scrolledHeaderContent,
            [tabId]: component
        };

        this.setState({
            scrolledHeaderContent: content
        });
    }

    setToolbarTitle(tabId, title) {
        const { storeTabTitle } = this.props;
        const { toolbarTitles } = this.state;
        const oldTitle = toolbarTitles[tabId];

        if (!oldTitle || title !== oldTitle) {
            this.setState(
                {
                    toolbarTitles: {
                        ...toolbarTitles,
                        [tabId]: title
                    }
                },
                () => {
                    storeTabTitle(tabId, title);
                }
            );
        }
    }

    updateQuery(newQuery, tabId, operator) {
        const query = { ...newQuery };
        if (operator === 'add') {
            query.tabs = [tabId];
        } else if (operator === 'remove' && query.tabs) {
            delete query.tabs;
        }

        return query;
    }

    generatePath(tabId, forPush = false, tabIndex) {
        const { pathname } = this.props;
        return generateTabURL({ pathname, tabId, forPush, tabIndex });
    }

    render() {
        const { user, preferences, tabs, minimizedTabIds, pathname, maximizedTabId } = this.props;
        const { animateMinimize, showContent, visibleHeaders, scrolledHeaderContent, toolbarTitles } = this.state;

        if (!get(user, 'userId')) {
            return null;
        }

        if (pathname.includes('/popout/')) {
            return null;
        }

        return (
            <FloatingTabUI
                animateMinimize={animateMinimize}
                beforeMinimize={this.beforeMinimize}
                connectScroll={this.connectScroll}
                generatePath={this.generatePath}
                maximizedTabId={maximizedTabId}
                minimizedTabIds={minimizedTabIds}
                preferences={preferences}
                removeTab={this.removeTab}
                scrolledHeaderContent={scrolledHeaderContent}
                scrollingWindows={this.scrollingWindows}
                setScrolledHeader={this.setScrolledHeader}
                setToolbarTitle={this.setToolbarTitle}
                showContent={showContent}
                tabs={tabs}
                toolbarTitles={toolbarTitles}
                visibleHeaders={visibleHeaders}
            />
        );
    }
}

const mapStateToProps = ({ Tabs }) => ({
    maximizedTabId: get(Tabs, 'maximizedTabId'),
    minimizedTabIds: get(Tabs, 'minimizedTabIds', [])
});

const mapDispatchToProps = {
    maximizeTab: tabMaximize,
    minimizeTab: tabMinimize,
    storeTabTitle: tabTitleStore,
    removeTab: tabRemove
};

export const FloatingTabContainer = compose(
    withUrlContext(['pathname', 'history', 'tabs']),
    withUserPreferences({ fetchPolicy: 'cache-first' }),
    connect(mapStateToProps, mapDispatchToProps)
)(FloatingTab);
