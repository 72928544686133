const PUBLIC_ASK_AIERA_ADD_QUESTION = 'PUBLIC_ASK_AIERA_ADD_QUESTIONS';
const PUBLIC_ASK_AIERA_EMAIL_QUESTION = 'PUBLIC_ASK_AIERA_EMAIL_QUESTIONS';
const PUBLIC_ASK_AIERA_HIDE_SPLASH = 'PUBLIC_ASK_AIERA_HIDE_SPLASH';

export const types = {
    PUBLIC_ASK_AIERA_ADD_QUESTION,
    PUBLIC_ASK_AIERA_EMAIL_QUESTION,
    PUBLIC_ASK_AIERA_HIDE_SPLASH
};

export function publicAskAieraHideSplash() {
    return {
        type: PUBLIC_ASK_AIERA_HIDE_SPLASH
    };
}

export function publicAskAieraAddQuestion(question) {
    return {
        type: PUBLIC_ASK_AIERA_ADD_QUESTION,
        payload: {
            question
        }
    };
}

export function publicAskAieraEmailQuestion(id) {
    return {
        type: PUBLIC_ASK_AIERA_EMAIL_QUESTION,
        payload: {
            id
        }
    };
}
