import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { EquityAutocomplete } from 'components/EquityAutocomplete';
import { EventGroupAutocomplete } from 'components/EventGroupAutocomplete';
import { FormRow } from 'components/FormRow';
import { Heading } from 'components/Heading';
import { LocaleAutocomplete } from 'components/LocaleAutocomplete';
import { TaggedInput } from 'components/TaggedInput';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

const DEFAULT_LOCALE_CODE = 'en-US';

class RecordingDetails extends PureComponent {
    static propTypes = {
        equities: PropTypes.arrayOf(PropTypes.object),
        equityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        eventGroupId: PropTypes.string,
        eventGroupTitle: PropTypes.string,
        localeCode: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onCompleteEmailCreator: PropTypes.bool.isRequired,
        onFocus: PropTypes.func.isRequired,
        onTaggedInputChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showLocaleAutocomplete: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        taggedInput: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        title: PropTypes.string,
        touched: PropTypes.objectOf(PropTypes.any).isRequired,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        equities: undefined,
        eventGroupId: undefined,
        eventGroupTitle: '',
        localeCode: undefined,
        passedStyles: {},
        taggedInput: '',
        title: ''
    };

    render() {
        const {
            equities,
            equityIds,
            errors,
            eventGroupId,
            eventGroupTitle,
            localeCode,
            onBlur,
            onChange,
            onCompleteEmailCreator,
            onFocus,
            onTaggedInputChange,
            passedStyles,
            showLocaleAutocomplete,
            styles,
            tags,
            taggedInput,
            title,
            touched,
            visible
        } = this.props;
        if (!visible) return null;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Heading size={2} styles={styles.heading}>
                    Recording Details
                </Heading>
                <FormRow description="What is the name of this recording?" label="Title*" styles={styles.formRowTitle}>
                    <TextInput
                        error={touched.title && errors.title}
                        name="title"
                        onBlur={event => onBlur({ event, name: 'title', value: title })}
                        onChange={onChange}
                        onFocus={event => onFocus({ event, name: 'title' })}
                        styles={styles.input}
                        value={title}
                    />
                </FormRow>
                <FormRow description="Associate with a specific ticker" label="Equity" styles={styles.formRow}>
                    <EquityAutocomplete
                        equities={equities}
                        icon="search03"
                        multi
                        name="equityIds"
                        onChange={onChange}
                        placeholder="Search by ticker or name..."
                        useTags
                        value={equityIds}
                        wrapTags
                    />
                </FormRow>
                <FormRow description="Associate with a specific conference" label="Conference" styles={styles.formRow}>
                    <EventGroupAutocomplete
                        initialSearchTerm={eventGroupTitle}
                        name="eventGroupId"
                        onChange={onChange}
                        placeholder="Enter conference..."
                        resultsPlacholder="Search by conference name..."
                        value={eventGroupId}
                    />
                </FormRow>
                <FormRow description="Organize your events into categories" label="Tags" styles={styles.formRow}>
                    <TaggedInput
                        name="tags"
                        onChange={onChange}
                        onInputChange={onTaggedInputChange}
                        placeholder="Press enter key to add tag"
                        tags={tags}
                        value={taggedInput}
                    />
                </FormRow>
                {showLocaleAutocomplete && (
                    <FormRow
                        description="Choose a supported language from the dropdown menu to transcribe this recording"
                        label="Language*"
                        styles={styles.formRow}
                    >
                        <LocaleAutocomplete
                            defaultValue={DEFAULT_LOCALE_CODE}
                            error={errors.localeCode}
                            gcpAutomaticPunctuation
                            gcpSupport
                            name="localeCode"
                            onChange={onChange}
                            styles={styles.localeAutocomplete}
                            value={localeCode}
                        />
                    </FormRow>
                )}
                <FormRow
                    description="After the recording ends, we will email you a copy of the transcript"
                    label="Email transcript"
                    styles={styles.formRow}
                >
                    <Checkbox
                        checked={onCompleteEmailCreator}
                        name="onCompleteEmailCreator"
                        onChange={onChange}
                        size={2}
                        styles={styles.checkbox}
                    >
                        Email me a copy of the transcript
                    </Checkbox>
                </FormRow>
            </Div>
        );
    }
}

export const RecordingDetailsUI = compose(withStyleSheet(styleSheet))(RecordingDetails);
