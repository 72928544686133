export const styleSheet = theme => ({
    autoAsk: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        margin: '8px -6px 0px',
        background: theme.colors.gray03,
        borderRadius: 6,
        padding: '8px 12px 8px'
    },
    autoAskOptions: { display: 'flex', marginTop: 2 },
    autoAskOption: {
        display: 'flex',
        alignItems: 'center',
        p: {
            cursor: 'pointer'
        },
        ':last-of-type': {
            marginLeft: 12
        }
    },
    citations: {
        height: 30,
        borderRadius: 6,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        background: theme.colors.gray03,
        ':hover': {
            background: theme.colors.gray01
        },
        ':active': {
            background: theme.colors.gray05
        }
    },
    container: {
        ...theme.content.box,
        lineHeight: '1.3em',
        padding: '0 14px 2px',
        marginTop: 20
    },
    copy: {
        cursor: 'pointer'
    },
    questionTitle: {
        backgroundColor: theme.colors.white01,
        paddingTop: 12,
        position: 'sticky',
        top: 0,
        paddingBottom: 10
    },
    questionAnswer: {
        paddingBottom: 10
    },
    questionFeedback: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
        svg: {
            marginRight: 6
        },
        '> div': {
            display: 'flex',
            alignItems: 'center',
            ':hover': {
                color: theme.colors.blue08,
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        }
    },
    questionTemplate: {
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.colors.gray06
    },
    questionTemplateOptions: {
        display: 'flex',
        p: {
            cursor: 'pointer'
        }
    },
    questionTemplateCheckbox: { '> div': { borderRadius: 20 }, marginRight: 6 }
});
