import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { SchedulingUI } from './ui';

function getScheduledFor(dateString) {
    let scheduledFor;
    const date = new XDate(dateString);
    const dateEST = new XDate(new Date(dateString).toLocaleString('en-US', { timeZone: 'America/New_York' }));
    if (date.getTime() !== dateEST.getTime()) {
        scheduledFor = `${dateEST.toString('h:mm TT')} EST`;
    }
    return scheduledFor;
}

export class Scheduling extends PureComponent {
    static displayName = 'SchedulingContainer';

    static propTypes = {
        connectionType: PropTypes.string,
        connectOffsetSeconds: PropTypes.number,
        date: PropTypes.objectOf(PropTypes.any),
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        meridiem: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        scheduledFor: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        time: PropTypes.string,
        type: PropTypes.string,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        connectionType: undefined,
        connectOffsetSeconds: undefined,
        date: undefined,
        scheduledFor: undefined,
        styles: undefined,
        time: undefined,
        type: undefined
    };

    constructor(props) {
        super(props);

        this.getScheduledFor = memoize(getScheduledFor);
    }

    render() {
        const {
            connectionType,
            connectOffsetSeconds,
            date,
            errors,
            meridiem,
            onBlur,
            onChange,
            onFocus,
            scheduledFor,
            styles,
            time,
            type,
            visible
        } = this.props;
        return (
            <SchedulingUI
                connectionType={connectionType}
                connectOffsetSeconds={connectOffsetSeconds}
                date={date}
                errors={errors}
                meridiem={meridiem}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                scheduledFor={this.getScheduledFor(scheduledFor)}
                styles={styles}
                time={time}
                type={type}
                visible={visible}
            />
        );
    }
}

export const SchedulingContainer = compose()(Scheduling);
