import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { OPERATORS, TYPES } from 'consts/filters';
import { eventMatchFragment, ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges,
    withEventUpdates
} from 'hoc/streams';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';

function mapTogglesToFilters({
    earningsOnlyStateChanged,
    hasTranscriptStateChanged,
    inStateEarningsOnly,
    inStateHasTranscript
}) {
    const filters = [];
    if (hasTranscriptStateChanged && inStateHasTranscript) {
        filters.push({
            operator: OPERATORS.is,
            type: TYPES.eventStatus,
            value: 'completed_with_transcripts'
        });
    }
    if (earningsOnlyStateChanged && inStateEarningsOnly) {
        filters.push({
            operator: OPERATORS.is,
            type: TYPES.eventType,
            value: [['earnings']]
        });
    }
    return filters;
}

const mapStateToProps = ({ User: userStore }) => ({
    userIsIdle: get(userStore, 'isIdle')
});

export const withData = () =>
    compose(
        connect(mapStateToProps),
        withMemo({
            formatMatches: formatMatchesByType,
            mapFilters: mapDashFiltersToRules,
            mapToggles: mapTogglesToFilters
        }),
        graphql(
            gql`
                mutation SetStreamLens($input: SetStreamRulesInput!) {
                    setStreamLens(input: $input) {
                        stream {
                            ...stream
                            lens {
                                rules {
                                    ...rule
                                }
                            }
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate }) => ({
                    setStreamLens: ({ streamId, hasEarningsOnly, hasTranscript }) => {
                        const rules = [];
                        if (hasTranscript) {
                            rules.push({
                                ruleType: 'status',
                                condition: 'is_equal',
                                value: 'completed_with_transcripts'
                            });
                        }
                        if (hasEarningsOnly) {
                            rules.push({
                                ruleType: 'event_type',
                                condition: 'is_equal',
                                value: 'earnings'
                            });
                        }
                        return mutate({
                            variables: {
                                input: {
                                    streamId,
                                    rules
                                }
                            }
                        });
                    }
                })
            }
        ),
        graphql(
            gql`
                query withEventStream($streamId: ID, $size: Int = 20, $offset: Int = 0, $filter: StreamMatchFilter) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        lens {
                            rules {
                                ...rule
                            }
                        }
                        rules {
                            ...rule
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...eventMatch
                            }
                        }
                    }
                }

                ${eventMatchFragment}
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({
                    data,
                    ownProps: {
                        earningsOnlyStateChanged,
                        formatMatches,
                        hasTranscriptStateChanged,
                        inStateEarningsOnly,
                        inStateHasTranscript
                    }
                }) => {
                    const {
                        fetchMore,
                        loading,
                        matches,
                        refetch,
                        rules,
                        stream,
                        subtitle,
                        lens
                    } = getDefaultStreamProps(data, formatMatches);
                    // RULES
                    let hasCompletedEventRule = false;
                    let hasCustomEventRule = false;
                    let hasEarningsOnlyRule = false;
                    let hasNotCompletedEventRule = false;
                    let hasScopeRule = false;
                    let hasTranscriptStreamRule = false;
                    (rules || []).forEach(({ condition, ruleType, value }) => {
                        if (ruleType === 'status' && condition === 'is_equal' && value === 'completed') {
                            hasCompletedEventRule = true;
                        }
                        if (ruleType === 'type' && condition === 'is_equal' && value === 'custom') {
                            hasCustomEventRule = true;
                        }
                        if (ruleType === 'status' && condition === 'is_not_equal' && value === 'completed') {
                            hasNotCompletedEventRule = true;
                        }
                        if (
                            (ruleType === 'watchlist_id' ||
                                ruleType === 'equity_id' ||
                                ruleType === 'gics_sector_id' ||
                                ruleType === 'gics_sub_sector_id') &&
                            condition === 'is_equal'
                        ) {
                            hasScopeRule = true;
                        }
                        if (
                            ruleType === 'status' &&
                            condition === 'is_equal' &&
                            value === 'completed_with_transcripts'
                        ) {
                            hasTranscriptStreamRule = true;
                        }
                        if (ruleType === 'event_type' && condition === 'is_equal' && value === 'earnings') {
                            hasEarningsOnlyRule = true;
                        }
                    });
                    const showHasTranscriptToggle =
                        hasTranscriptStateChanged ||
                        hasCompletedEventRule ||
                        hasCustomEventRule ||
                        (hasScopeRule && !hasNotCompletedEventRule);
                    // LENSES
                    let hasEarningsOnlyLens = false;
                    let hasTranscriptLens = false;
                    (lens || []).forEach(({ ruleType, condition, value }) => {
                        if (ruleType === 'event_type' && condition === 'is_equal' && value === 'earnings') {
                            hasEarningsOnlyLens = true;
                        }
                        if (
                            ruleType === 'status' &&
                            condition === 'is_equal' &&
                            value === 'completed_with_transcripts'
                        ) {
                            hasTranscriptLens = true;
                        }
                    });
                    const hasEarningsOnly = earningsOnlyStateChanged
                        ? inStateEarningsOnly
                        : hasEarningsOnlyLens || hasEarningsOnlyRule;
                    const hasTranscript = hasTranscriptStateChanged
                        ? inStateHasTranscript
                        : hasTranscriptLens || hasTranscriptStreamRule;
                    return {
                        fetchMore,
                        hasEarningsOnly,
                        hasTranscript,
                        loading,
                        matches,
                        refetch,
                        rules,
                        showHasTranscriptToggle,
                        stream,
                        subtitle
                    };
                },
                skip: ({ streamId, streamProps, userIsIdle }) =>
                    !get(streamProps, 'wasVisible', false) || !streamId || userIsIdle,
                options: ({
                    dashDateRange,
                    dashFilters,
                    dashSearchTerm,
                    dashSources,
                    dashEquityScope,
                    mapFilters,
                    mapToggles,
                    streamId,
                    earningsOnlyStateChanged,
                    hasTranscriptStateChanged,
                    inStateEarningsOnly,
                    inStateHasTranscript
                }) => {
                    return getDefaultStreamOptions({
                        applyLens: true,
                        dashDateRange,
                        dashFilters,
                        dashSearchTerm,
                        dashSources,
                        dashEquityScope,
                        lenses: mapToggles({
                            earningsOnlyStateChanged,
                            hasTranscriptStateChanged,
                            inStateEarningsOnly,
                            inStateHasTranscript
                        }),
                        mapFilters,
                        streamId,
                        streamType: 'events'
                    });
                }
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging(),
        withEventUpdates()
    );
