import { types } from 'actions/highlight';

const { HIGHLIGHT_ADD, HIGHLIGHT_EXPORT, HIGHLIGHT_REMOVE, HIGHLIGHT_SELECTION_START } = types;

const initialState = {
    bookmarkIds: null,
    contentId: null,
    eventId: null,
    highlight: null,
    highlightsFilterKey: undefined,
    highlightsSortKey: undefined,
    includeNewsHighlights: undefined,
    matchId: null,
    onSuccess: undefined,
    selectionStartId: null,
    streamId: null,
    targetEndId: undefined,
    targetEndOffset: undefined,
    targetId: null,
    targetOffset: undefined,
    targetStreamId: null,
    targetType: null
};

export function highlightReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === HIGHLIGHT_ADD) {
        return {
            ...state,
            selectionStartId: null,
            ...payload
        };
    }

    if (type === HIGHLIGHT_EXPORT) {
        return {
            ...state,
            ...payload
        };
    }

    if (type === HIGHLIGHT_REMOVE) {
        return {
            ...state,
            ...initialState
        };
    }

    if (type === HIGHLIGHT_SELECTION_START) {
        return {
            ...state,
            ...payload
        };
    }

    return state;
}
