import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { STREAM_DISPLAY_MODES } from 'consts';
import { withUrlContext } from 'hoc/url';
import { generateTabURL } from 'utils';
import { CorporateActionCardUI } from './ui';

export class CorporateActionCard extends PureComponent {
    static displayName = 'CorporateActionCardContainer';

    static propTypes = {
        actionSubtype: PropTypes.string,
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        eventId: PropTypes.string,
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        source: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        actionSubtype: undefined,
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        equities: [],
        eventId: undefined,
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        score: undefined,
        source: undefined,
        styles: {},
        streamId: null
    };

    render() {
        const {
            actionSubtype,
            company,
            displayMode,
            equities,
            eventId,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            pathname,
            publishedDate,
            score,
            source,
            styles,
            streamId,
            title
        } = this.props;
        return (
            <CorporateActionCardUI
                actionSubtype={actionSubtype}
                company={company}
                displayMode={displayMode}
                equities={equities}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                matchId={matchId}
                source={source}
                onClick={this.handleClick}
                publishedDate={publishedDate}
                score={score}
                streamId={streamId}
                styles={styles}
                title={title}
                to={
                    eventId
                        ? generateTabURL({
                              eventId,
                              pathname
                          })
                        : undefined
                }
            />
        );
    }
}

export const CorporateActionCardContainer = compose(withUrlContext(['pathname']))(CorporateActionCard);
