import { keyframes } from '@emotion/react';

export const styleSheet = theme => {
    const bgColor = keyframes`
        10%   { color: ${theme.colors.gray14}; background-color: ${theme.colors.yellow09}; }
        100%  { background-color: ${theme.colors.gray14}; color: ${theme.colors.white01}; }
    `;

    return {
        container: {
            color: theme.colors.white01,
            display: 'flex',
            alignItems: 'center',
            height: 40,
            fontSize: theme.fonts.fontSizes.size01,
            backgroundColor: theme.colors.gray14,
            justifyContent: 'space-between',
            boxShadow: `inset 0 1px 0 0 ${theme.colors.gray16}`,
            [`@media(max-width:${theme.breakpoints.internal.mobile})`]: {
                display: 'none'
            }
        },
        playlist: {
            height: '100%',
            borderRight: `1px solid ${theme.colors.gray16}`,
            borderTop: `1px solid ${theme.colors.gray16}`,
            display: 'flex',
            padding: '0 16px 0 14px',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray12
            },
            ':active': {
                backgroundColor: theme.colors.gray11
            }
        },
        playlistMenu: {
            overflow: 'auto',
            borderRadius: '0 6px 0 0',
            backgroundColor: theme.colors.gray14,
            boxShadow: '4px -4px 8px 0 rgba(0, 0, 0, 0.2)',
            maxHeight: 'calc(100% - 48px)'
        },
        chevron: {
            transform: 'rotate(-90deg)',
            marginLeft: 16
        },
        tabListAnimation: {
            WebkitAnimation: `${bgColor} 1s 1`,
            animation: `${bgColor} 1s 1`
        },
        tabList: {
            borderLeft: `1px solid ${theme.colors.gray16}`,
            borderTop: `1px solid ${theme.colors.gray16}`,
            height: '100%',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            p: {
                marginLeft: 8
            },
            ':hover': {
                backgroundColor: theme.colors.gray12
            },
            ':active': {
                backgroundColor: theme.colors.gray11
            }
        },
        tabListMenu: {
            overflow: 'hidden',
            borderRadius: '6px 0 0 0',
            backgroundColor: theme.colors.gray14,
            boxShadow: '4px -4px 8px 0 rgba(0, 0, 0, 0.2)'
        },
        tabListOption: {
            borderBottom: `1px solid ${theme.colors.gray16}`,
            maxWidth: 300,
            color: theme.colors.white01,
            height: 34,
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'space-between',
            svg: {
                height: 14
            },
            div: {
                height: 14,
                ':hover': {
                    'svg g': {
                        fill: theme.colors.blue13
                    }
                }
            },
            p: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: 10
            },
            ':hover': {
                backgroundColor: theme.colors.gray12
            },
            ':active': {
                backgroundColor: theme.colors.gray11
            },
            ':last-of-type': {
                borderBottom: 'none'
            }
        }
    };
};
