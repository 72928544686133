import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { LoaderLogo } from 'components/LoaderLogo';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Tr } from 'components/Tr';
import { Icon } from 'components/Icon';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'searchResults';

class MonitorResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            styles,
            enabled,
            results,
            hasMoreResults,
            loading,
            loadingMoreResults,
            loadMoreResults,
            highlightedResult,
            highlightResult,
            indexElement,
            onClickResult,
            theme
        } = this.props;
        if (!enabled) {
            return null;
        }

        const highlightedType = get(highlightedResult, 'type');

        return (
            <Table styles={styles.results}>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={3}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results.map((monitor, index) => {
                        const { id, name, recommended, streamCount, dashboardType, streams = [] } = monitor;
                        const iconType = dashboardType === 'equity' ? 'dashboardEquity' : 'columns02';
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;

                        return (
                            <Tr
                                elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                key={`monitor-${id}`}
                                styles={resultStyles}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                                onClick={onClickResult}
                            >
                                <Td styles={dashboardType === 'equity' ? styles.equityIcon : styles.monitorIcon}>
                                    <Div className="monitorType" styles={styles.iconContainer}>
                                        <Icon type={iconType} color={theme.colors.gray06} />
                                    </Div>
                                </Td>
                                <Td>
                                    <Text size={2} styles={styles.name}>
                                        {`${name} ${recommended ? '(recommended)' : ''}`}
                                    </Text>
                                    <Div styles={styles.subTextWrapper}>
                                        <Text size={0} styles={styles.streamCount}>
                                            {`(${streamCount > 0 ? streamCount : 'No Saved Searches'})`}
                                        </Text>
                                        <Div styles={styles.streamNamesContainer}>
                                            <Text size={0} styles={styles.streamNames}>
                                                {streams.map(
                                                    ({ name: streamName }, streamIndex) =>
                                                        `${streamName}${streamIndex === streams.length ? '' : ', '}`
                                                )}
                                            </Text>
                                        </Div>
                                    </Div>
                                </Td>
                                <Td styles={styles.tagCell}>
                                    <Div>
                                        <Text size={0} className="searchTag" styles={styles.searchTag}>
                                            Jump to
                                        </Text>
                                    </Div>
                                </Td>
                            </Tr>
                        );
                    })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr styles={styles.loadButtonRow} elementRef={node => indexElement('loadMoreButton', 0, node)}>
                        <Td colSpan={4}>
                            <ActionButton
                                onClick={loadMoreResults}
                                styles={
                                    highlightedType === 'loadMoreButton'
                                        ? styles.loadMoreButtonHighlighted
                                        : styles.loadMoreButton
                                }
                            >
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const MonitorResultsUI = compose(withStyleSheet(styleSheet))(MonitorResults);
