import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withData } from './data';
import { EventStreamUI } from './ui';

export class EventStream extends PureComponent {
    static displayName = 'EventStreamContainer';

    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        EmptyComponent: PropTypes.objectOf(PropTypes.any),
        hasEarningsOnly: PropTypes.bool,
        hasTranscript: PropTypes.bool,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        matches: PropTypes.arrayOf(PropTypes.object),
        setStreamLens: PropTypes.func.isRequired,
        showHasTranscriptToggle: PropTypes.bool,
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string,
        toggleEarningsOnly: PropTypes.func.isRequired,
        toggleHasTranscript: PropTypes.func.isRequired
    };

    static defaultProps = {
        EmptyComponent: undefined,
        hasEarningsOnly: false,
        hasTranscript: false,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        showHasTranscriptToggle: false,
        streamId: null,
        styles: undefined,
        subtitle: undefined
    };

    constructor(props) {
        super(props);

        this.toggleEarningsOnly = this.toggleEarningsOnly.bind(this);
        this.toggleHasTranscript = this.toggleHasTranscript.bind(this);
    }

    toggleEarningsOnly() {
        const { hasEarningsOnly, setStreamLens, streamId, toggleEarningsOnly } = this.props;
        toggleEarningsOnly(!hasEarningsOnly);
        setStreamLens({ streamId, hasEarningsOnly: !hasEarningsOnly });
    }

    toggleHasTranscript() {
        const { hasTranscript, setStreamLens, streamId, toggleHasTranscript } = this.props;
        toggleHasTranscript(!hasTranscript);
        setStreamLens({ streamId, hasTranscript: !hasTranscript });
    }

    render() {
        const {
            children,
            dashboardId,
            EmptyComponent,
            hasEarningsOnly,
            hasTranscript,
            loading,
            loadMoreMatches,
            matches,
            showHasTranscriptToggle,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;
        return (
            <EventStreamUI
                dashboardId={dashboardId}
                EmptyComponent={EmptyComponent}
                hasEarningsOnly={hasEarningsOnly}
                hasTranscript={hasTranscript}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                showHasTranscriptToggle={showHasTranscriptToggle}
                streamDisplayMode={streamDisplayMode}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
                toggleHasTranscript={this.toggleHasTranscript}
                toggleEarningsOnly={this.toggleEarningsOnly}
            >
                {children}
            </EventStreamUI>
        );
    }
}

export const EventStreamContainer = compose(
    withStateHandlers(
        () => ({
            earningsOnlyStateChanged: false,
            hasTranscriptStateChanged: false,
            inStateEarningsOnly: false,
            inStateHasTranscript: true
        }),
        {
            toggleEarningsOnly: () => inStateEarningsOnly => ({
                earningsOnlyStateChanged: true,
                inStateEarningsOnly
            }),
            toggleHasTranscript: () => inStateHasTranscript => ({
                hasTranscriptStateChanged: true,
                inStateHasTranscript
            })
        }
    ),
    withData()
)(EventStream);
