export const styleSheet = () => ({
    searchTermInput: {
        position: 'relative',
        width: 200
    },
    dropdown: {
        width: 200
    },
    autocomplete: {
        width: 200
    }
});
