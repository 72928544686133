import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';
import { ContentCompaniesUI } from './ui';

export class ContentCompanies extends PureComponent {
    static displayName = 'ContentCompaniesContainer';

    static propTypes = {
        companies: PropTypes.arrayOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        showTickers: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        companies: [],
        showTickers: true,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.viewCompany = this.viewCompany.bind(this);
    }

    viewCompany(id) {
        const { history } = this.props;
        history.push(generatePath(routes.company, { companyId: id }));
    }

    render() {
        const { styles, showTickers, companies } = this.props;
        return (
            <ContentCompaniesUI
                companies={companies}
                showTickers={showTickers}
                styles={styles}
                viewCompany={this.viewCompany}
            />
        );
    }
}

export const ContentCompaniesContainer = compose(withUrlContext(['history']), withData())(ContentCompanies);
