import React, { PureComponent, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { AttachmentCard } from 'components/AttachmentCard';
import { ContentStreamChart } from 'components/ContentStreamChart';
import { ContentStreamToolbar } from 'components/ContentStreamToolbar';
import { DateDivider } from 'components/DateDivider';
import { Div } from 'components/Div';
import { DocumentCard } from 'components/DocumentCard';
import { FilingCard } from 'components/FilingCard';
import { FilingStreamToolbar } from 'components/FilingStreamToolbar';
import { NewsCard } from 'components/NewsCard';
import { ResearchCard } from 'components/ResearchCard';
import { SpotlightCard } from 'components/SpotlightCard';
import { Stream } from 'components/Stream';
import { StreetAccountCard } from 'components/StreetAccountCard';
import { TranscriptCard } from 'components/TranscriptCard';
import { NO_OP, STREAM_DISPLAY_MODES } from 'consts';
import { get } from 'utils';
import { CorporateActionCard } from '../CorporateActionCard';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    attachment: AttachmentCard,
    corporate_action: CorporateActionCard,
    document: DocumentCard,
    filing: FilingCard,
    news: NewsCard,
    research: ResearchCard,
    spotlight: SpotlightCard,
    streetaccount: StreetAccountCard,
    transcript: TranscriptCard
};

class ContentStream extends PureComponent {
    static propTypes = {
        chartRef: PropTypes.objectOf(PropTypes.any),
        contentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        dashEquityScope: PropTypes.arrayOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        dashboardId: PropTypes.string.isRequired,
        dashboardType: PropTypes.string.isRequired,
        filingForms: PropTypes.arrayOf(PropTypes.string),
        hasFilters: PropTypes.bool.isRequired,
        isOnlyFilings: PropTypes.bool.isRequired,
        lenses: PropTypes.arrayOf(PropTypes.any),
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
        showFilingStreamToolbar: PropTypes.bool.isRequired,
        showTypeSelector: PropTypes.bool.isRequired,
        streamContentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleContentType: PropTypes.func.isRequired,
        toggleFilingForm: PropTypes.func.isRequired
    };

    static defaultProps = {
        dashDateRange: null,
        dashSearchTerm: null,
        dashEquityScope: null,
        chartRef: undefined,
        filingForms: [],
        lenses: [],
        loading: false,
        loadMoreMatches: null,
        matches: [],
        passedStyles: {},
        streamId: null,
        subtitle: undefined
    };

    constructor(props) {
        super(props);

        this.streamRef = createRef();

        this.renderHeaderElements = this.renderHeaderElements.bind(this);
        this.renderResultsHeader = this.renderResultsHeader.bind(this);
    }

    renderHeaderElements() {
        const {
            chartRef,
            contentTypes,
            dashDateRange,
            dashSearchTerm,
            dashEquityScope,
            isOnlyFilings,
            streamId,
            streamProps
        } = this.props;
        const wasVisible = get(streamProps, 'wasVisible', false);

        if (isOnlyFilings) {
            return null;
        }

        return (
            <ContentStreamChart
                chartRef={chartRef}
                streamId={streamId}
                contentTypes={contentTypes}
                dashDateRange={dashDateRange}
                dashSearchTerm={dashSearchTerm}
                dashEquityScope={dashEquityScope}
                wasVisible={wasVisible}
            />
        );
    }

    renderResultsHeader() {
        const {
            contentTypes,
            filingForms,
            isOnlyFilings,
            showFilingStreamToolbar,
            showTypeSelector,
            streamContentTypes,
            streamDisplayMode,
            streamId,
            styles,
            toggleContentType,
            toggleFilingForm
        } = this.props;
        return (
            <Fragment>
                {!isOnlyFilings && (
                    <Div
                        styles={
                            streamDisplayMode !== STREAM_DISPLAY_MODES.compact
                                ? styles.chartSpacer
                                : styles.chartSpacerHidden
                        }
                    />
                )}
                {showFilingStreamToolbar ? (
                    <FilingStreamToolbar
                        filingForms={filingForms}
                        streamId={streamId}
                        toggleFilingForm={toggleFilingForm}
                    />
                ) : (
                    <ContentStreamToolbar
                        streamContentTypes={streamContentTypes}
                        contentTypes={contentTypes}
                        show={showTypeSelector}
                        streamId={streamId}
                        toggleContentType={toggleContentType}
                    />
                )}
            </Fragment>
        );
    }

    render() {
        const {
            dashEquityScope,
            dashSearchTerm,
            dashboardId,
            dashboardType,
            dashDateRange,
            hasFilters,
            isOnlyFilings,
            lenses,
            loadMoreMatches,
            loading,
            matches,
            passedStyles,
            searchTerms,
            streamDisplayMode,
            streamId,
            streamProps,
            subtitle
        } = this.props;
        let lastDate;
        return (
            <Stream
                {...streamProps}
                dashSearchTerm={dashSearchTerm}
                dashEquityScope={dashEquityScope}
                scrollButtonDisabled
                streamRef={this.streamRef}
                dashboardId={dashboardId}
                dashDateRange={dashDateRange}
                hasFilters={hasFilters}
                lenses={lenses}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                offset={matches.length}
                renderHeader={this.renderHeaderElements}
                renderResultsHeader={this.renderResultsHeader}
                searchTerms={searchTerms}
                streamId={streamId}
                styles={passedStyles}
                subtitle={subtitle}
            >
                {matches.map((m, idx) => {
                    const CardComponent = COMPONENT_MAP[m.type];
                    if (!CardComponent) return null;
                    const date = get(m, 'publishDate', get(m, 'publishedDate', get(m, 'callDate')));
                    const cardDate = date ? new XDate(date) : false;
                    if (
                        streamDisplayMode === STREAM_DISPLAY_MODES.default &&
                        cardDate &&
                        idx !== 0 &&
                        (cardDate.diffHours(lastDate).toFixed(0) > 24 || !lastDate) &&
                        !isOnlyFilings
                    ) {
                        lastDate = cardDate;
                        return (
                            <Fragment key={`content-card-${m.id}`}>
                                <DateDivider
                                    date={cardDate}
                                    onScrollToTop={get(this.streamRef, 'current.onScrollToEnd', NO_OP)}
                                />
                                <CardComponent
                                    dashboardType={dashboardType}
                                    displayMode={streamDisplayMode}
                                    streamId={streamId}
                                    {...m}
                                />
                            </Fragment>
                        );
                    }
                    return (
                        <CardComponent
                            dashboardType={dashboardType}
                            displayMode={streamDisplayMode}
                            key={`content-card-${m.id}`}
                            streamId={streamId}
                            {...m}
                        />
                    );
                })}
            </Stream>
        );
    }
}

export const ContentStreamUI = compose(withStyleSheet(styleSheet))(ContentStream);
