export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    datePicker: {
        '.calendar': {
            left: 0,
            top: 2
        },
        '.react-calendar__tile--active': {
            borderRadius: '20px !important',
            backgroundColor: theme.colors.blue08,
            ':hover': {
                backgroundColor: theme.colors.blue08
            },
            ':focus': {
                backgroundColor: theme.colors.blue08
            }
        }
    }
});
