import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Droppable, Draggable } from 'lib/react-beautiful-dnd';
import { withStyleSheet } from 'hoc/styles';
import { PERMISSIONS } from 'consts';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';
import { Icon } from 'components/Icon';
import { ActionButton } from 'components/ActionButton';
import { WithPermission } from 'components/WithPermission';
import { DashboardMenuOption } from '../DashboardMenuOption';
import { styleSheet } from './stylesheet';

class Section extends PureComponent {
    static propTypes = {
        createNewDashboard: PropTypes.func.isRequired,
        dashboards: PropTypes.arrayOf(PropTypes.object).isRequired,
        disableDraggingDashboards: PropTypes.bool.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        index: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        hide: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        newSectionName: PropTypes.string,
        onChangeSectionName: PropTypes.func.isRequired,
        onCreateSection: PropTypes.func.isRequired,
        onDeleteSection: PropTypes.func.isRequired,
        onDragDashboard: PropTypes.func.isRequired,
        onDragSection: PropTypes.func.isRequired,
        onRenameSection: PropTypes.func.isRequired,
        onTooltipKeyDown: PropTypes.func.isRequired,
        toggle: PropTypes.func.isRequired,
        collapsed: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showNoDashMessage: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {},
        newSectionName: null
    };

    renderSectionTooltip(newSection, isFirst) {
        const {
            styles,
            loading,
            onCreateSection,
            onChangeSectionName,
            newSectionName,
            onRenameSection,
            onTooltipKeyDown
        } = this.props;

        return ({ hideTooltip }) => (
            <TooltipForm
                onKeyDown={e => onTooltipKeyDown(e, hideTooltip, newSection, isFirst)}
                hideTooltip={hideTooltip}
                styles={styles.sectionForm}
                onChange={onChangeSectionName}
                onSubmit={newSection ? () => onCreateSection(hideTooltip, isFirst) : () => onRenameSection(hideTooltip)}
                loading={loading}
                placeholder="Enter section name"
                submitButtonText={newSection ? 'Add section' : 'Update section'}
                value={newSectionName}
            />
        );
    }

    renderNewSectionButton(isFirst) {
        const { styles, onChangeSectionName } = this.props;

        return (
            <Tooltip
                onShow={() => onChangeSectionName({ value: null })}
                xOffset={14}
                content={this.renderSectionTooltip(true, isFirst)}
                isEnabled
                slideIn
                persistOnMouseExit
                useElementOffsetLeft
                useElementOffsetTop
                useOutsideClickHandler
            >
                {({ showTooltip }) => (
                    <Div styles={styles.newSection} onClick={showTooltip} data-tname="sidebar-new-section">
                        <Div className="line" styles={styles.newSectionLine} />
                        <Div className="button" styles={styles.newSectionButton}>
                            <Text size={0} uppercase>
                                new section
                            </Text>
                        </Div>
                    </Div>
                )}
            </Tooltip>
        );
    }

    renderSectionHeaderTooltip() {
        const { styles, theme, onDeleteSection, onChangeSectionName, createNewDashboard, name } = this.props;

        return ({ hideTooltip }) => (
            <Fragment>
                <Div
                    styles={styles.sectionHoverMenuOption}
                    onClick={createNewDashboard}
                    data-tname="add-monitor-to-section"
                >
                    <WithPermission permissions={[PERMISSIONS.unlockedCreateDashboard]}>
                        {({ restricted, isLoading }) => (
                            <Div styles={styles.sectionMenuIconContainer}>
                                <Icon
                                    type={restricted || isLoading ? 'lock02' : 'newDashboard'}
                                    color={theme.colors.black01}
                                />
                            </Div>
                        )}
                    </WithPermission>
                    <Text size={3}>New Monitor</Text>
                </Div>
                <Tooltip
                    onShow={() => onChangeSectionName({ value: name })}
                    yOffset={0}
                    content={this.renderSectionTooltip()}
                    onHide={hideTooltip}
                    isEnabled
                    slideIn
                    persistOnMouseExit
                    useElementOffsetLeft
                    useElementOffsetTop
                >
                    {({ showTooltip }) => (
                        <Div styles={styles.sectionHoverMenuOption} onClick={showTooltip} data-tname="rename section">
                            <Div styles={styles.sectionMenuIconContainer}>
                                <Icon type="editSection" color={theme.colors.black01} />
                            </Div>
                            <Text size={3}>Rename Section</Text>
                        </Div>
                    )}
                </Tooltip>
                <Div styles={styles.sectionHoverMenuOption} onClick={onDeleteSection} data-tname="delete section">
                    <Div styles={styles.sectionMenuIconContainer}>
                        <Icon type="deleteSection" color={theme.colors.black01} />
                    </Div>
                    <Text size={3}>Delete Section</Text>
                </Div>
            </Fragment>
        );
    }

    renderHeader(dragHandleProps) {
        const { styles, theme, name, toggle, collapsed, dashboards } = this.props;
        const lowerName = name.toLowerCase();
        const showMenu = !['default', 'recommended'].includes(lowerName);
        let sectionTitle = lowerName === 'default' ? 'monitors' : name;
        if (collapsed && dashboards.length > 0) {
            sectionTitle = `${sectionTitle} (${dashboards.length})`;
        }

        return (
            <Fragment>
                <Div styles={styles.dashboardMenuDivider} {...dragHandleProps}>
                    <Div styles={collapsed ? styles.collapsedTitle : styles.collapsableTitle} onClick={toggle}>
                        <Icon type="chevron02" color={theme.colors.gray06} />
                        <Text size={0} weight="medium" uppercase>
                            {sectionTitle}
                        </Text>
                    </Div>
                    {showMenu && (
                        <Tooltip
                            slideIn
                            isEnabled
                            useElementOffsetLeft
                            useElementOffsetBottom
                            useOutsideClickHandler
                            styles={styles.sectionHoverMenu}
                            content={this.renderSectionHeaderTooltip()}
                        >
                            {({ showTooltip }) => (
                                <Div styles={styles.sectionMenuIcon} onClick={showTooltip}>
                                    <Icon type="menuVertical" color={theme.colors.gray04} />
                                </Div>
                            )}
                        </Tooltip>
                    )}
                </Div>
            </Fragment>
        );
    }

    renderNoDashboardsMessage() {
        const { styles, createNewDashboard, theme, name } = this.props;

        return (
            <WithPermission permissions={[PERMISSIONS.unlockedCreateDashboard]}>
                {({ restricted, isLoading }) =>
                    restricted || isLoading ? (
                        <Div styles={styles.noDashMessage}>
                            {name === 'Default' ? (
                                <Text size={1}>
                                    Add an equity monitor
                                    <br />
                                    for easy access
                                </Text>
                            ) : (
                                <Text size={1}>
                                    Drag any equity monitor
                                    <br />
                                    into this section.
                                </Text>
                            )}
                        </Div>
                    ) : (
                        <Div styles={styles.noDashMessage}>
                            <Text size={1}>
                                Drag and drop a monitor
                                <br /> into this section or
                            </Text>
                            <ActionButton
                                styles={styles.newDashButton}
                                onClick={createNewDashboard}
                                name="sidebar-first-dash"
                            >
                                <Icon type="newDashboard" color={theme.colors.white01} />
                                <Text size={1}>Create a Monitor</Text>
                            </ActionButton>
                        </Div>
                    )
                }
            </WithPermission>
        );
    }

    render() {
        const {
            passedStyles,
            styles,
            dashboards,
            disableDraggingDashboards,
            id,
            index,
            name,
            onDragSection,
            onDragDashboard,
            showNoDashMessage,
            theme,
            hide,
            collapsed
        } = this.props;
        const lowerName = name.toLowerCase();
        const isDragDisabled = ['recommended'].includes(lowerName) || hide;
        const showNewMenuButton = !collapsed && !isDragDisabled && dashboards.length > 0;

        return (
            <Draggable
                key={`section-${id}`}
                draggableId={id}
                index={index}
                isDragDisabled={isDragDisabled}
                payload={{ onDragEnd: onDragSection }}
            >
                {provided => (
                    <Div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        styles={{ ...styles.draggableContainer, ...passedStyles }}
                    >
                        {index === 0 && this.renderNewSectionButton(true)}
                        {!hide && this.renderHeader(provided.dragHandleProps)}
                        {!hide && (
                            <Droppable droppableId={id} type="sidebarMenuOptions">
                                {(dropProvided, snapshot) => (
                                    <Div
                                        styles={{
                                            ...styles.container,
                                            minHeight: !collapsed && dashboards.length > 0 ? undefined : 1,
                                            backgroundColor: snapshot.isDraggingOver
                                                ? theme.colors.gray11
                                                : 'transparent',
                                            paddingBottom: snapshot.isDraggingOver ? 20 : 0
                                        }}
                                        ref={dropProvided.innerRef}
                                        {...dropProvided.droppableProps}
                                    >
                                        {!collapsed &&
                                            dashboards.map((dashboard, optionIndex) => (
                                                <DashboardMenuOption
                                                    {...dashboard}
                                                    index={optionIndex}
                                                    sectionId={id}
                                                    key={`${id}-${dashboard.id}`}
                                                    onDragDashboard={onDragDashboard}
                                                    draggable={!disableDraggingDashboards}
                                                />
                                            ))}
                                        {showNoDashMessage && this.renderNoDashboardsMessage()}
                                        {dropProvided.placeholder}
                                        {!snapshot.isDraggingOver && showNewMenuButton && this.renderNewSectionButton()}
                                    </Div>
                                )}
                            </Droppable>
                        )}
                    </Div>
                )}
            </Draggable>
        );
    }
}

export const SectionUI = compose(withStyleSheet(styleSheet))(Section);
