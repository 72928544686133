import React, { forwardRef, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { Icon } from 'components/Icon';
import { LoaderDots } from 'components/LoaderDots';
import { Notice } from 'components/Notice';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class CustomStreamForm extends PureComponent {
    static propTypes = {
        customDataIsEditable: PropTypes.bool,
        templateConfig: PropTypes.objectOf(PropTypes.any),
        dataCollectionId: PropTypes.string,
        dataCollectionConfig: PropTypes.objectOf(PropTypes.any),
        dataCollectionType: PropTypes.string,
        dataCollectionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
        dataCollectionOptions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        reprocessDataCollection: PropTypes.func,
        sampleError: PropTypes.string,
        sampleLoading: PropTypes.bool,
        sampleStatus: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        templateOptions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
        templateType: PropTypes.string,
        templateTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        customDataIsEditable: true,
        dataCollectionId: null,
        dataCollectionConfig: null,
        dataCollectionType: null,
        dataCollectionOptions: [],
        passedStyles: {},
        reprocessDataCollection: null,
        sampleError: null,
        sampleLoading: false,
        sampleStatus: null,
        templateConfig: null,
        templateOptions: null,
        templateType: null
    };

    constructor(props) {
        super(props);
        this.renderTemplateOption = forwardRef(this.renderTemplateOption.bind(this));
    }

    renderTemplateOption({ isSelected, option, styles: optionStyles, ...optionProps }, ref) {
        const { styles, theme } = this.props;
        return (
            <Div ref={ref} styles={{ ...optionStyles, ...styles.option }} {...optionProps}>
                <Div styles={styles.optionBlock}>
                    <Text weight="medium" size={3}>
                        {option.label}
                    </Text>
                    <Div styles={styles.optionValues}>
                        {option.sampleValues.map((v, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Text key={`${option.name}-${v}-${i}`} size={2} styles={styles.optionText}>
                                {v}
                                {i < option.sampleValues.length - 1 ? ', ' : ''}
                            </Text>
                        ))}
                    </Div>
                </Div>
                {isSelected && <Icon type="checkMarkSmall" color={theme.colors.black01} />}
            </Div>
        );
    }

    renderCustomGSheetForm() {
        const { customDataIsEditable, styles, dataCollectionConfig, onChange } = this.props;
        return (
            <Fragment>
                <Div styles={styles.gSheetInfo}>
                    <Notice type="info" richContent styles={styles.gSheetInstruction}>
                        <Div>
                            <Text size={3}>To connect your Google Sheet, first share it with</Text>
                            <Text size={3} styles={styles.gSheetEmail}>
                                aiera-api-access@aiera-256022.iam.gserviceaccount.com
                            </Text>
                            <Text size={3}>Next paste the URL to the Google Sheet below.</Text>
                        </Div>
                    </Notice>
                </Div>
                <Div styles={styles.configContainer}>
                    <TextInput
                        disabled={!customDataIsEditable}
                        styles={styles.urlInput}
                        label="Google Sheet URL"
                        name="gsheet.url"
                        onChange={onChange}
                        placeholder="e.g. https://docs.google.com/spreadsheets/d/1dbXqBuO_uYfBepFkTQPbkQco_me4nffVlyb5P9gCp84/edit#gid=1205503499"
                        value={get(dataCollectionConfig, 'url')}
                    />
                    {this.renderSampleInfo()}
                </Div>
            </Fragment>
        );
    }

    renderCustomCsvForm() {
        const { customDataIsEditable, styles, dataCollectionConfig, onChange } = this.props;

        return (
            <Div styles={styles.configContainer}>
                <TextInput
                    disabled={!customDataIsEditable}
                    styles={styles.urlInput}
                    label="CSV URL"
                    name="csv.url"
                    onChange={onChange}
                    placeholder="e.g. https://people.sc.fsu.edu/~jburkardt/data/csv/zillow.csv"
                    value={get(dataCollectionConfig, 'url')}
                />

                {this.renderSampleInfo()}
            </Div>
        );
    }

    renderCustomApiForm() {
        const { customDataIsEditable, styles, dataCollectionConfig, onChange } = this.props;

        return (
            <Div styles={styles.configContainer}>
                <TextInput
                    disabled={!customDataIsEditable}
                    styles={styles.urlInput}
                    label="API URL"
                    name="api.url"
                    onChange={onChange}
                    placeholder="Enter api url..."
                    value={get(dataCollectionConfig, 'url')}
                />

                {this.renderSampleInfo()}
            </Div>
        );
    }

    renderCustomExternalForm() {
        const { customDataIsEditable, dataCollectionId, dataCollectionOptions, styles, onChange } = this.props;

        return (
            <Div styles={styles.configContainer}>
                <Dropdown
                    disableHoverOpen
                    disabled={!customDataIsEditable}
                    styles={styles.urlInput}
                    name="dataCollectionId"
                    placeholder="External source"
                    options={dataCollectionOptions}
                    onChange={onChange}
                    value={dataCollectionId}
                />
            </Div>
        );
    }

    renderSampleInfo() {
        const {
            dataCollectionConfig,
            reprocessDataCollection,
            sampleError,
            sampleLoading,
            sampleStatus,
            styles,
            theme
        } = this.props;
        const sampleTooltip = ({ hideTooltip }) => (
            <Div onMouseLeave={hideTooltip}>
                <Text size={3} styles={styles.sampleError}>
                    {sampleError}
                </Text>
            </Div>
        );
        return (
            <Div styles={styles.sampleControls}>
                {sampleLoading && get(dataCollectionConfig, 'url') && <LoaderDots gray />}
                {!sampleLoading && get(dataCollectionConfig, 'url') && (
                    <Div styles={styles.sampleStatus}>
                        {sampleStatus === 'complete' ? (
                            <Icon type="checkMark" color={theme.colors.green01} />
                        ) : (
                            <Tooltip
                                isEnabled={sampleError}
                                useElementOffsetLeft
                                useElementOffsetTop
                                xOffset={0}
                                content={sampleTooltip}
                            >
                                {({ showTooltip }) => (
                                    <Div onMouseEnter={showTooltip}>
                                        <Icon type="xMark02" color={theme.colors.red01} />
                                    </Div>
                                )}
                            </Tooltip>
                        )}
                    </Div>
                )}
                {['complete', 'error'].includes(sampleStatus) && (
                    <Div styles={styles.sampleReprocess} onClick={reprocessDataCollection}>
                        <Icon type="refresh" color={theme.colors.black01} />
                    </Div>
                )}
            </Div>
        );
    }

    renderCardTemplateForm() {
        const { customDataIsEditable, onChange, templateConfig, sampleLoading, styles, templateOptions } = this.props;
        const disabled = !customDataIsEditable || sampleLoading || !templateOptions;
        return (
            <Div styles={styles.templateFields}>
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Ticker"
                    placeholder="Ticker"
                    name="basic_card.ticker"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'ticker')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="URL"
                    placeholder="URL"
                    name="basic_card.url"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'url')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Title"
                    placeholder="Title"
                    name="basic_card.title"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'title')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Subtitle"
                    placeholder="Subtitle"
                    name="basic_card.subtitle"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'subtitle')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Top Right"
                    placeholder="Top Right"
                    name="basic_card.topRight"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'topRight')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Bottom Right"
                    placeholder="Bottom Right"
                    name="basic_card.bottomRight"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'bottomRight')}
                />
                <Div styles={[styles.templateField, styles.bodyContainer]}>
                    <Dropdown
                        disableHoverOpen
                        disabled={disabled}
                        styles={styles.body}
                        label="Body"
                        placeholder="Body"
                        name="basic_card.body"
                        onChange={onChange}
                        options={templateOptions}
                        OptionComponent={this.renderTemplateOption}
                        value={get(templateConfig, 'body')}
                    />
                    <Div>
                        <Text styles={styles.bodyRawLabel}>HTML?</Text>
                        <Checkbox
                            styles={styles.bodyRaw}
                            name="basic_card.bodyRaw"
                            checked={get(templateConfig, 'bodyRaw')}
                            onChange={onChange}
                        />
                    </Div>
                </Div>
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Full Page Body"
                    placeholder="Full Page Body"
                    name="basic_card.fullPageBody"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'fullPageBody')}
                />
            </Div>
        );
    }

    renderRowTemplateForm() {
        const { customDataIsEditable, onChange, templateConfig, sampleLoading, styles, templateOptions } = this.props;
        const disabled = !customDataIsEditable || sampleLoading || !templateOptions;
        return (
            <Div styles={styles.templateFields}>
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="URL"
                    placeholder="URL"
                    name="basic_row.url"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'url')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Title"
                    placeholder="Title"
                    name="basic_row.title"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'title')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Subtitle"
                    placeholder="Subtitle"
                    name="basic_row.subtitle"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'subtitle')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Top Right"
                    placeholder="Top Right"
                    name="basic_row.topRight"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'topRight')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Bottom Right"
                    placeholder="Bottom Right"
                    name="basic_row.bottomRight"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'bottomRight')}
                />
                <Dropdown
                    disableHoverOpen
                    disabled={disabled}
                    styles={styles.templateField}
                    label="Body"
                    placeholder="Body"
                    name="basic_row.body"
                    onChange={onChange}
                    options={templateOptions}
                    OptionComponent={this.renderTemplateOption}
                    value={get(templateConfig, 'body')}
                />
            </Div>
        );
    }

    render() {
        const {
            customDataIsEditable,
            dataCollectionType,
            dataCollectionTypes,
            templateType,
            templateTypes,
            onChange,
            passedStyles,
            styles
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.sourceAndTemplateContainer}>
                    <Dropdown
                        disableHoverOpen
                        disabled={!customDataIsEditable}
                        styles={styles.sourceType}
                        name="dataCollectionType"
                        placeholder="Custom source type"
                        options={dataCollectionTypes}
                        onChange={onChange}
                        value={dataCollectionType}
                    />
                    <Dropdown
                        disableHoverOpen
                        disabled={!customDataIsEditable}
                        styles={styles.templateType}
                        name="templateType"
                        placeholder="Custom template type"
                        options={templateTypes}
                        onChange={onChange}
                        value={templateType}
                    />
                </Div>
                {dataCollectionType === 'gsheet' && this.renderCustomGSheetForm()}
                {dataCollectionType === 'csv' && this.renderCustomCsvForm()}
                {dataCollectionType === 'api' && this.renderCustomApiForm()}
                {dataCollectionType === 'external' && this.renderCustomExternalForm()}
                {templateType === 'basic_card' && this.renderCardTemplateForm()}
                {templateType === 'basic_row' && this.renderRowTemplateForm()}
            </Div>
        );
    }
}

export const CustomStreamFormUI = compose(withStyleSheet(styleSheet))(CustomStreamForm);
