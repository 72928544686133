import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { LoaderLogo } from 'components/LoaderLogo';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Span } from 'components/Span';
import { Tr } from 'components/Tr';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'searchResults';

class EventResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            styles,
            enabled,
            results,
            loading,
            loadingMoreResults,
            loadMoreResults,
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) {
            return null;
        }

        const highlightedType = get(highlightedResult, 'type');

        return (
            <Table styles={styles.results}>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={2}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results.map((event, index) => {
                        const { equityIcon, equityInitials, date, company, ticker, id, isLive, title } = event;
                        const dateString = date.toString('MM/dd/yyyy');
                        const timeString = date.toString('h:mmTT');
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        return (
                            <Tr
                                elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                key={`event-${id}-${ticker}`}
                                styles={resultStyles}
                                onClick={onClickResult}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                            >
                                <Td>
                                    {equityIcon ? (
                                        <Div styles={styles.companyIcon}>
                                            <Img src={equityIcon} />
                                        </Div>
                                    ) : (
                                        <Div styles={styles.equityInitials}>
                                            <Text uppercase className="equityInitials" size={0}>
                                                {equityInitials}
                                            </Text>
                                        </Div>
                                    )}
                                </Td>
                                <Td>
                                    <Text size={0} styles={styles.equityName} uppercase>
                                        <Span>{ticker}</Span>
                                        {company}
                                    </Text>
                                    <Text size={2} styles={styles.eventTitle}>
                                        {title}
                                    </Text>
                                    <Text size={0} styles={styles.eventDateTime}>
                                        {isLive ? (
                                            <Span styles={styles.liveStatus} uppercase>
                                                {`live from ${timeString}`}
                                            </Span>
                                        ) : (
                                            <Span styles={styles.eventTime}>{timeString}</Span>
                                        )}
                                        {dateString}
                                    </Text>
                                </Td>
                            </Tr>
                        );
                    })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={2}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr styles={styles.loadButtonRow} elementRef={node => indexElement('loadMoreButton', 0, node)}>
                        <Td colSpan={2}>
                            <ActionButton
                                onClick={loadMoreResults}
                                styles={
                                    highlightedType === 'loadMoreButton'
                                        ? styles.loadMoreButtonHighlighted
                                        : styles.loadMoreButton
                                }
                            >
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const EventResultsUI = compose(withStyleSheet(styleSheet))(EventResults);
