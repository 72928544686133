export const styleSheet = theme => ({
    errorCode: {
        position: 'absolute',
        bottom: theme.margins.margin03,
        right: theme.margins.margin03,
        color: theme.colors.gray02
    },
    notice: {
        marginTop: theme.margins.margin02
    }
});
