export const styleSheet = theme => ({
    audioControl: {
        padding: '0 4px 0 2px',
        height: 30,
        width: 30,
        borderRadius: 15,
        background: theme.colors.gradient03,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.playIcon': {
            height: 12,
            marginLeft: 4
        },
        '.pauseIcon': {
            height: 12,
            marginLeft: 2
        },
        ':active': {
            background: theme.colors.gradient03c
        },
        ':hover': {
            background: theme.colors.gradient03b
        }
    },
    callType: {
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        color: theme.colors.gray04,
        marginLeft: 6
    },
    container: {
        position: 'fixed',
        bottom: 12,
        right: 14,
        zIndex: 10,
        width: 'calc(100% - 28px)',
        backgroundColor: '#1E2031',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px',
        borderRadius: 12,
        boxShadow: '5px 7px 14px 0 rgba(0, 0, 0, 0.3)'
    },
    closeButton: {
        marginLeft: 14,
        opacity: 0.6,
        svg: {
            height: 12,
            width: 12
        }
    },
    cursor: {
        cursor: 'pointer'
    },
    date: {
        marginTop: 3,
        color: theme.colors.gray04,
        opacity: 0.6
    },
    endTime: {
        width: 44,
        textAlign: 'left',
        color: 'rgba(255, 255, 255, 0.6)'
    },
    event: {
        flex: 1,
        marginLeft: 10,
        a: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            textDecoration: 'none'
        }
    },
    eventHeader: {
        display: 'flex',
        alignItems: 'baseline'
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    knob: {
        cursor: 'grab',
        top: -8,
        borderRadius: 4,
        position: 'absolute',
        height: 20,
        width: 10,
        backgroundColor: theme.colors.white01,
        ':hover': {
            backgroundColor: theme.colors.blue08
        },
        ':active': {
            backgroundColor: theme.colors.blue08,
            cursor: 'grabbing'
        }
    },
    live: {
        flexShrink: 0,
        boxShadow: '0px 0px 0 1px rgba(255, 255, 255, 0.2)',
        borderRadius: 6,
        marginLeft: 10,
        color: theme.colors.red10,
        alignItems: 'center',
        justifyContent: 'center',
        height: 36,
        display: 'flex',
        padding: '0 14px',
        ':active': {
            color: theme.colors.white01
        }
    },
    player: {
        flex: 1,
        position: 'relative',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '18px 4px 8px'
    },
    progressBar: {
        height: 4,
        background: theme.colors.yellow02,
        borderRadius: '4px 0 0 4px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    seekBack: {
        marginRight: 12
    },
    seekForward: {
        marginRight: 20
    },
    speed: {
        boxShadow: '0px 0px 0 1px rgba(255, 255, 255, 0.2)',
        borderRadius: 6,
        marginLeft: 10,
        color: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
        height: 36,
        display: 'flex',
        padding: '0 14px',
        ':active': {
            color: theme.colors.red10
        }
    },
    speedColor: {
        color: theme.colors.red10
    },
    startTime: {
        width: 44,
        textAlign: 'left',
        color: '#FFFFFF'
    },
    timelineWrapper: {
        position: 'relative',
        flex: 1,
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    timeline: {
        position: 'relative',
        flex: 1,
        margin: '0 15px 0 10px',
        height: 4,
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: 4
    },
    ticker: {
        color: theme.colors.white01
    }
});
