import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withEditTranscripts } from 'graphql/audioCalls';
import { highlightSelectionStart } from 'actions/highlight';
import { TranscriptEventUI } from './ui';

export class TranscriptEvent extends PureComponent {
    static displayName = 'TranscriptEventContainer';

    static propTypes = {
        audioCallId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        audioPlayed: PropTypes.bool.isRequired,
        bookmarkId: PropTypes.string,
        canAutoSelect: PropTypes.bool.isRequired,
        deleteEventItem: PropTypes.func.isRequired,
        eventId: PropTypes.string.isRequired,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isLive: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        listening: PropTypes.bool.isRequired,
        lockAudioScroll: PropTypes.bool.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        onStartSelection: PropTypes.func.isRequired,
        reverse: PropTypes.bool.isRequired,
        selectionStartId: PropTypes.string,
        transcript: PropTypes.string,
        transcriptEvents: PropTypes.arrayOf(PropTypes.any),
        updateEventItem: PropTypes.func.isRequired,
        userId: PropTypes.string
    };

    static defaultProps = {
        audioCallId: null,
        bookmarkId: undefined,
        selectionStartId: null,
        transcript: null,
        transcriptEvents: [],
        userId: undefined
    };

    constructor(props) {
        super(props);

        this.handleEventClick = this.handleEventClick.bind(this);
        this.onTranscriptChange = this.onTranscriptChange.bind(this);
        this.onEditTranscript = this.onEditTranscript.bind(this);
        this.onSubmitTranscript = this.onSubmitTranscript.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onDeleteTranscript = this.onDeleteTranscript.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onOpenActionMenu = this.onOpenActionMenu.bind(this);
        this.onCloseActionMenu = this.onCloseActionMenu.bind(this);
        this.selectHighlightId = this.selectHighlightId.bind(this);

        this.editableRef = React.createRef();

        this.state = {
            actionMenuOpen: false,
            editing: false,
            saving: false,
            showActionMenu: false,
            transcript: null,
            selectedHighlightId: undefined
        };
    }

    componentDidUpdate(prevProps) {
        const {
            canAutoSelect,
            bookmarkId,
            audioPlayed,
            listening,
            eventId,
            onNodeSelect,
            lockAudioScroll,
            isLive,
            isSelected
        } = this.props;
        const { bookmarkId: prevBookmarkId, audioPlayed: prevAudioPlayed } = prevProps;
        const { selectedHighlightId } = this.state;

        // Clear bookmarkId if previously selected highlight
        // is now deleted
        if (!!selectedHighlightId && !!prevBookmarkId && !bookmarkId) {
            this.setState({ selectedHighlightId: undefined });
        }

        // Scroll to playing audio
        if (canAutoSelect && !prevAudioPlayed && !isSelected && audioPlayed && listening) {
            onNodeSelect({
                id: eventId,
                withScroll: isLive ? false : lockAudioScroll,
                seekToAudio: false,
                isTermMatch: false
            });
        }
    }

    handleEventClick() {
        const { eventId, onNodeSelect } = this.props;
        onNodeSelect({ id: eventId, withScroll: false, isTermMatch: false, seekToAudio: true });
    }

    onTranscriptChange({ target }) {
        this.setState({ transcript: target.value }, () => this.resetHeight());
    }

    onMouseEnter() {
        this.setState({
            showActionMenu: true
        });
    }

    onMouseLeave() {
        this.setState({
            showActionMenu: false
        });
    }

    onOpenActionMenu() {
        this.setState({
            actionMenuOpen: true
        });
    }

    onCloseActionMenu() {
        this.setState({
            actionMenuOpen: false
        });
    }

    resetHeight() {
        // Textarea's don't expand with their content, this just makes it expand
        // like a regular div would
        const el = this.editableRef.current;
        el.style.height = `${el.scrollHeight}px`;
    }

    onEditTranscript() {
        const { transcript } = this.props;
        this.setState(
            {
                transcript,
                editing: true
            },
            () => {
                this.resetHeight();
            }
        );
    }

    onSubmitTranscript() {
        const { eventId, updateEventItem } = this.props;
        const { transcript } = this.state;
        this.setState(
            {
                saving: true
            },
            () => {
                updateEventItem({ itemId: eventId, transcript }).then(
                    () => {
                        this.setState({ editing: false, saving: false, transcript: null });
                    },
                    () => {
                        this.setState({ saving: false });
                    }
                );
            }
        );
    }

    onCancelEdit() {
        this.setState({
            transcript: null,
            editing: false
        });
    }

    onDeleteTranscript() {
        const { eventId, deleteEventItem } = this.props;
        const isConfirmed = window.confirm('Are you sure you want to delete this transcript item?');
        if (isConfirmed) {
            this.setState({ saving: true }, () => {
                deleteEventItem(eventId).catch(() => this.setState({ saving: false }));
            });
        }
    }

    selectHighlightId(e) {
        const { userId, onStartSelection, eventId } = this.props;
        const selectedHighlightId = e.target?.closest('[data-highlightid]')?.getAttribute('data-highlightid');
        const creatorId = e.target?.closest('[data-userid]')?.getAttribute('data-userid');

        this.setState(
            {
                selectedHighlightId: creatorId === userId ? selectedHighlightId : undefined
            },
            () => {
                onStartSelection(eventId);
            }
        );
    }

    render() {
        const {
            bookmarkId,
            highlightsFilterKey,
            highlightsSortKey,
            reverse,
            selectionStartId,
            transcript,
            transcriptEvents,
            userId,
            ...rest
        } = this.props;
        const {
            actionMenuOpen,
            editing,
            saving,
            transcript: editTranscript,
            showActionMenu,
            selectedHighlightId
        } = this.state;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <TranscriptEventUI
                {...rest}
                actionMenuOpen={actionMenuOpen}
                bookmarkId={bookmarkId}
                editableRef={this.editableRef}
                editing={editing}
                handleEventClick={this.handleEventClick}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                onCancelEdit={this.onCancelEdit}
                onDeleteTranscript={this.onDeleteTranscript}
                onEditTranscript={this.onEditTranscript}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onOpenActionMenu={this.onOpenActionMenu}
                onCloseActionMenu={this.onCloseActionMenu}
                onSubmitTranscript={this.onSubmitTranscript}
                onTranscriptChange={this.onTranscriptChange}
                reverse={reverse}
                saving={saving}
                selectedHighlightId={selectedHighlightId}
                selectHighlightId={this.selectHighlightId}
                showActionMenu={showActionMenu}
                transcript={editing ? editTranscript : transcript}
                transcriptEvents={transcriptEvents}
                userId={userId}
            />
        );
    }
}

const mapDispatchToProps = {
    onStartSelection: highlightSelectionStart
};

export const TranscriptEventContainer = compose(
    connect(undefined, mapDispatchToProps),
    withEditTranscripts()
)(TranscriptEvent);
