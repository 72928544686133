import { DASHBOARD_ENDPOINT_PROD } from 'configuration';
import { PERMISSIONS } from 'consts';
import { withGetUser } from 'graphql/user';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { compose } from 'recompose';
import { hasPermission, isBeta } from 'utils';

export class UserGate extends PureComponent {
    static displayName = 'UserGateContainer';

    static propTypes = {
        user: PropTypes.objectOf(PropTypes.any),
        userLoading: PropTypes.bool
    };

    static defaultProps = {
        user: null,
        userLoading: false
    };

    render() {
        const { children, user, userLoading } = this.props;

        if (isBeta() && !userLoading && !hasPermission(user, PERMISSIONS.featureBeta)) {
            window.location = DASHBOARD_ENDPOINT_PROD;
        }

        return children;
    }
}

export const UserGateContainer = compose(
    withGetUser({
        fetchPolicy: 'network-only',
        variables: { withDetails: true }
    })
)(UserGate);
