import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { constants } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { ExternalLayout } from 'layouts';
import { Heading } from 'components/Heading';
import { Notice } from 'components/Notice';
import { ExternalLink } from 'components/ExternalLink';
import { styleSheet } from './stylesheet';

class BrowserGate extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    render() {
        const { styles } = this.props;
        const { PHONE_SUPPORT, EMAIL_SUPPORT } = constants;
        return (
            <ExternalLayout>
                <Div styles={styles.container}>
                    <Heading size={1}>Unsupported browser</Heading>
                    <Notice type="info" styles={styles.notice}>
                        Please open this page in Chrome, Firefox, Safari or Edge.
                        <br />
                        <br />
                        If you need immediate assistance,
                        <br />
                        {`please call ${PHONE_SUPPORT},`}
                        <br />
                        or email&nbsp;
                        <ExternalLink href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</ExternalLink>
                    </Notice>
                </Div>
            </ExternalLayout>
        );
    }
}

export const BrowserGateUI = compose(withStyleSheet(styleSheet))(BrowserGate);
