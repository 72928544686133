import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { SpinnerUI } from './ui';

export class Spinner extends PureComponent {
    static displayName = 'SpinnerContainer';

    static propTypes = {
        color: PropTypes.oneOf(['white', 'orange', 'darkGray', 'lightGray']),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        color: 'white',
        styles: undefined
    };

    render() {
        const { color, styles } = this.props;
        return <SpinnerUI color={color} styles={styles} />;
    }
}

export const SpinnerContainer = compose()(Spinner);
