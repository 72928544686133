import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { ExternalLayout } from 'layouts';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { TextInput } from 'components/TextInput';
import { Icon } from 'components/Icon';
import { ButtonForm } from 'components/ButtonForm';
import { Notice } from 'components/Notice';
import { ExternalLink } from 'components/ExternalLink';

import { routes, externalRoutes } from 'routes';
import { styleSheet } from './stylesheet';

class Auth extends Component {
    static propTypes = {
        deactivated: PropTypes.bool.isRequired,
        emailInput: PropTypes.string,
        errorEmail: PropTypes.string,
        errorPassword: PropTypes.string,
        formDisabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        loginFailed: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool,
        onBlurEmail: PropTypes.func.isRequired,
        onBlurPassword: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        passwordInput: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        updateEmail: PropTypes.func.isRequired,
        updatePassword: PropTypes.func.isRequired
    };

    static defaultProps = {
        errorEmail: null,
        errorPassword: null,
        emailInput: '',
        isMobile: false,
        passwordInput: ''
    };

    render() {
        const {
            deactivated,
            emailInput,
            errorEmail,
            errorPassword,
            formDisabled,
            isMobile,
            loading,
            loginFailed,
            onBlurEmail,
            onBlurPassword,
            onSubmit,
            passwordInput,
            styles,
            theme,
            updateEmail,
            updatePassword
        } = this.props;

        return (
            <ExternalLayout>
                <Form onSubmit={onSubmit} styles={styles.formContainer}>
                    <Heading size={1}>Sign In</Heading>
                    {loginFailed && !deactivated && (
                        <Notice styles={styles.notice} type="error" heading="Hmm, we couldn't sign you in.">
                            Please check your credentials & try again.
                        </Notice>
                    )}
                    {loginFailed && deactivated && (
                        <Notice
                            styles={styles.notice}
                            type="error"
                            heading="Sorry! Your account credentials are no longer valid or your trial has ended."
                        >
                            But we can easily fix that... Contact{' '}
                            <ExternalLink href="mailto:sales@aiera.com?subject=Help! I've been deactivated.">
                                sales@aiera.com
                            </ExternalLink>{' '}
                            to choose which subscription plan is best for you.
                        </Notice>
                    )}
                    <TextInput
                        label="email / username"
                        placeholder="You@Example.com"
                        icon="email03"
                        containerStyle={styles.emailInput}
                        onChange={updateEmail}
                        onBlur={onBlurEmail}
                        error={errorEmail}
                        name="email"
                        value={emailInput}
                    />
                    <Div styles={styles.resetContainer}>
                        <TextInput
                            label="password"
                            placeholder="Password"
                            icon="lock02"
                            type="password"
                            containerStyle={styles.passwordInput}
                            onChange={updatePassword}
                            onBlur={onBlurPassword}
                            error={errorPassword}
                            name="password"
                            value={passwordInput}
                        />
                        <Link to={routes.resetPassword}>
                            <Icon type="key" color={theme.colors.iconGray} />
                            <Text size={1} styles={styles.textReset}>
                                Forgot Password?
                            </Text>
                        </Link>
                    </Div>
                    <Div styles={styles.formButtons}>
                        <ButtonForm
                            size={2}
                            styles={styles.submitBtn}
                            onClick={onSubmit}
                            loading={loading}
                            disabled={loading || formDisabled}
                            icon="arrowRight"
                        >
                            Sign In
                        </ButtonForm>
                        {!isMobile && <Link to={externalRoutes.pricing}>No Account? Start Your Trial Now</Link>}
                    </Div>
                </Form>
            </ExternalLayout>
        );
    }
}

export const AuthUI = compose(withStyleSheet(styleSheet))(Auth);
