export const styleSheet = theme => ({
    container: {
        width: '100%',
        tr: {
            td: {
                ':first-of-type': {
                    borderRadius: '6px 0 0 6px'
                },
                ':last-of-type': {
                    borderRadius: '0 6px 6px 0'
                }
            },
            ':nth-of-type(even)': {
                td: {
                    backgroundColor: theme.colors.gray10,
                    ':first-of-type': {
                        borderRadius: '6px 0 0 6px'
                    },
                    ':last-of-type': {
                        borderRadius: '0 6px 6px 0'
                    }
                }
            }
        }
    },
    containerAlt: {
        width: '100%',
        tr: {
            td: {
                ':first-of-type': {
                    borderRadius: '6px 0 0 6px'
                },
                ':last-of-type': {
                    borderRadius: '0 6px 6px 0'
                }
            },
            ':nth-of-type(odd)': {
                td: {
                    backgroundColor: theme.colors.gray10,
                    ':first-of-type': {
                        borderRadius: '6px 0 0 6px'
                    },
                    ':last-of-type': {
                        borderRadius: '0 6px 6px 0'
                    }
                }
            }
        }
    },
    tableWrapper: {
        height: '100%',
        overflowY: 'auto',
        padding: `0 ${theme.margins.margin00}`
    }
});
