import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import { compose, withProps, withPropsOnChange } from 'recompose';
import { withRequestEquities } from 'graphql/equities';
import { withSectors, withSubSectors } from 'graphql/sectors';
import { withResolve } from 'graphql/resolve';
import { get } from 'utils';
import { normalizeEquities, normalizeSectorEquities, sortEquities } from './selectors';
import { BulkFollowIdentifiersResultsUI } from './ui';

export class BulkFollowIdentifiersResults extends PureComponent {
    static displayName = 'BulkFollowIdentifiersResultsContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        error: PropTypes.oneOfType([PropTypes.any]),
        equities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        identifiers: PropTypes.arrayOf(PropTypes.string),
        loading: PropTypes.bool,
        onToggleEquity: PropTypes.func.isRequired,
        requestEquities: PropTypes.func.isRequired,
        searchEquities: PropTypes.func.isRequired,
        sectorEquities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        sectorsLoading: PropTypes.bool,
        subSectorEquities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        subSectorsLoading: PropTypes.bool,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        styles: {},
        error: undefined,
        equities: [],
        identifiers: null,
        loading: false,
        sectorEquities: [],
        sectorsLoading: false,
        subSectorEquities: [],
        subSectorsLoading: false
    };

    constructor(props) {
        super(props);

        this.handleResultSelect = this.handleResultSelect.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchInputKeyDown = this.handleSearchInputKeyDown.bind(this);
        this.handleUnmatchedIdentifierChange = this.handleUnmatchedIdentifierChange.bind(this);
        this.requestIdentifier = this.requestIdentifier.bind(this);
        this.searchUnmatchedIdentifier = this.searchUnmatchedIdentifier.bind(this);
        this.setMatchedEquityNode = this.setMatchedEquityNode.bind(this);
        this.toggleEquity = this.toggleEquity.bind(this);

        this.matchedEquityNodes = {};
        this.resultsContainer = React.createRef();
        this.searchInput = React.createRef();

        this.state = {
            checkedEquityIds: [],
            equities: null,
            failedIdentifiers: [],
            isSearching: false,
            requestedIdentifiers: [],
            searchInput: '',
            searchResults: null,
            searchedUnmatchedIdentifier: null,
            sectorEquities: null,
            subSectorEquities: null,
            unmatchedInputs: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {
            checkedEquityIds: initialCheckedEquityIds,
            equities: initialEquities,
            isSearching,
            searchedUnmatchedIdentifier,
            sectorEquities: initialSectorEquities,
            subSectorEquities: initialSubSectorEquities,
            unmatchedInputs
        } = state;
        const { equities, onToggleEquity, sectorEquities, subSectorEquities } = props;
        let newState = {};
        const checkedEquityIds = [...initialCheckedEquityIds];

        if (!isSearching && (!initialEquities || initialEquities.length === 0) && equities && equities.length > 0) {
            const normalizedEquities = normalizeEquities(equities);

            // If equities are from initial resolve query, check off the first equity id in each matching result set
            normalizedEquities.forEach(({ matches }) => {
                if (matches.length > 0) {
                    matches.forEach((match, idx) => {
                        const { equityId } = match;
                        if (idx === 0) checkedEquityIds.push(equityId);
                    });
                }
            });

            newState = {
                ...newState,
                checkedEquityIds,
                equities: normalizedEquities
            };
        } else if (isSearching) {
            // If searching, only update searchResults
            newState = {
                ...newState,
                searchResults: normalizeEquities(equities)
            };
        } else if (searchedUnmatchedIdentifier) {
            // If equities are from a searched unmatched input, replace the unmatched equity row with the new result
            const unmatchedIdentifier = unmatchedInputs[searchedUnmatchedIdentifier];

            if ((equities || []).some(equity => equity.identifier === unmatchedIdentifier)) {
                const matchedIndex = initialEquities.findIndex(e => e.identifier === searchedUnmatchedIdentifier);
                // Check if the newly searched identifier already exists in initial equities
                const exists = initialEquities.findIndex(e => e.identifier === unmatchedIdentifier) >= 0;
                // Save the unique equities and remove the newly searched identifier from unmatchedInputs
                newState = {
                    ...newState,
                    equities: uniq([
                        ...[
                            ...initialEquities.slice(0, matchedIndex),
                            ...(exists ? [] : normalizeEquities(equities)),
                            ...initialEquities.slice(matchedIndex + 1)
                        ]
                    ]),
                    searchedUnmatchedIdentifier: null,
                    unmatchedInputs: pick(
                        unmatchedInputs,
                        Object.keys(unmatchedInputs).filter(k => k !== searchedUnmatchedIdentifier)
                    )
                };
            }
        }

        // If sectorEquities prop is set, check off each equity and update state
        if ((!initialSectorEquities || initialSectorEquities.length === 0) && sectorEquities && sectorEquities.length) {
            // Filter out equities already loaded from resolve and subSector queries
            const existingIdentifiers = uniq((equities || []).concat(subSectorEquities || []).map(e => e.identifier));
            const uniqueSectorEquities = sectorEquities.filter(e => !existingIdentifiers.includes(e.identifier));

            uniqueSectorEquities.forEach(({ matches }) => {
                if (matches.length > 0) {
                    matches.forEach(({ equityId }) => checkedEquityIds.push(equityId));
                }
            });
            newState = {
                ...newState,
                checkedEquityIds,
                sectorEquities: uniqueSectorEquities
            };
        }

        // If subSectorEquities prop is set, check off each equity and update state
        if (
            (!initialSubSectorEquities || initialSubSectorEquities.length === 0) &&
            subSectorEquities &&
            subSectorEquities.length
        ) {
            // Filter out equities already loaded from resolve and subSector queries
            const existingIdentifiers = uniq((equities || []).concat(sectorEquities || []).map(e => e.identifier));
            const uniqueSubSectorEquities = subSectorEquities.filter(e => !existingIdentifiers.includes(e.identifier));

            subSectorEquities.forEach(({ matches }) => {
                if (matches.length > 0) {
                    matches.forEach(({ equityId }) => checkedEquityIds.push(equityId));
                }
            });
            newState = {
                ...newState,
                checkedEquityIds,
                subSectorEquities: uniqueSubSectorEquities
            };
        }

        // Pass checkedEquityIds to parent component
        if (onToggleEquity && checkedEquityIds.length > 0) onToggleEquity(checkedEquityIds);

        return newState;
    }

    focusSearchInput() {
        if (this.searchInput && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleResultSelect(equity) {
        const { equities: existingEquities, sectorEquities, subSectorEquities } = this.state;
        const equities = existingEquities || [];
        const identifier = get(equity, 'identifier', '');
        const exists = equities
            .concat(sectorEquities, subSectorEquities)
            .some(e => get(e, 'identifier', '').toLowerCase() === identifier.toLowerCase());
        // If the selected result is not yet in the equities list, append it to the bottom, then scroll to it
        this.setState(
            {
                equities: exists ? [...equities] : [...equities, equity],
                isSearching: false,
                searchInput: ''
            },
            () => {
                this.scrollToMatchedEquity(identifier);
                this.focusSearchInput();
            }
        );
    }

    handleSearchChange({ value }) {
        const { searchEquities } = this.props;

        this.setState(
            {
                isSearching: true,
                searchInput: value
            },
            () => {
                if (value.trim().length > 0) searchEquities([value]);
            }
        );
    }

    handleSearchInputKeyDown(event) {
        const { key, target } = event;
        if (key === 'Escape') {
            this.setState({
                isSearching: false,
                searchInput: ''
            });
            target.focus();
        }
    }

    handleUnmatchedIdentifierChange({ name, value }) {
        const { unmatchedInputs } = this.state;
        this.setState({
            unmatchedInputs: { ...unmatchedInputs, [name]: value }
        });
    }

    requestIdentifier(equity, isSearchResult = false) {
        const { requestEquities } = this.props;
        const { equities: equitiesState, failedIdentifiers, requestedIdentifiers } = this.state;
        const equities = equitiesState || [];
        const identifier = get(equity, 'identifier', '');

        requestEquities(identifier).then(success => {
            let newState = {
                failedIdentifiers: success ? [...failedIdentifiers] : [...failedIdentifiers, identifier],
                requestedIdentifiers:
                    requestedIdentifiers.indexOf(identifier) >= 0
                        ? [...requestedIdentifiers]
                        : [...requestedIdentifiers, identifier]
            };

            // If the request is for a search result, add the identifier to top of equities list and reset search state
            if (isSearchResult) {
                newState = {
                    ...newState,
                    isSearching: false,
                    searchInput: '',
                    equities: equities.some(e => get(e, 'identifier', null) === identifier)
                        ? [...equities]
                        : [equity, ...equities]
                };
            }

            this.setState({ ...newState });
        });
    }

    scrollToMatchedEquity(id) {
        const node = this.matchedEquityNodes[id];
        if (node) {
            this.resultsContainer.current.scrollTo({ behavior: 'smooth', top: node.offsetTop - 105 });
        }
    }

    searchUnmatchedIdentifier(identifier, value) {
        const { searchEquities } = this.props;
        this.setState({ searchedUnmatchedIdentifier: identifier }, () => {
            searchEquities(value);
        });
    }

    setMatchedEquityNode(id, node) {
        this.matchedEquityNodes[id] = node;
    }

    toggleEquity(id) {
        const { onToggleEquity } = this.props;
        const { checkedEquityIds: equityIds } = this.state;
        const index = equityIds.indexOf(id);
        const checkedEquityIds =
            index >= 0 ? [...equityIds.slice(0, index), ...equityIds.slice(index + 1)] : [...equityIds, id];

        this.setState({ checkedEquityIds }, () => {
            if (onToggleEquity) onToggleEquity(checkedEquityIds);
        });
    }

    render() {
        const {
            styles,
            error,
            identifiers,
            loading,
            requestEquities,
            sectorsLoading,
            subSectorsLoading,
            title
        } = this.props;
        const {
            checkedEquityIds,
            equities,
            failedIdentifiers,
            isSearching,
            requestedIdentifiers,
            searchInput,
            searchResults,
            searchedUnmatchedIdentifier,
            sectorEquities,
            subSectorEquities,
            unmatchedInputs
        } = this.state;

        return (
            <BulkFollowIdentifiersResultsUI
                styles={styles}
                buttonDisabled={loading || !!error}
                checkedEquityIds={checkedEquityIds}
                equities={equities}
                error={error}
                failedIdentifiers={failedIdentifiers}
                identifiers={identifiers}
                loading={loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                onSearchInputKeyDown={this.handleSearchInputKeyDown}
                onUnmatchedIdentifierChange={this.handleUnmatchedIdentifierChange}
                requestEquities={requestEquities}
                requestedIdentifiers={requestedIdentifiers}
                requestIdentifier={this.requestIdentifier}
                resultsContainerRef={this.resultsContainer}
                searchInput={searchInput}
                searchInputRef={this.searchInput}
                searchResults={searchResults}
                searchedUnmatchedIdentifier={searchedUnmatchedIdentifier}
                searchUnmatchedIdentifier={this.searchUnmatchedIdentifier}
                sectorEquities={sectorEquities}
                sectorsLoading={sectorsLoading}
                setMatchedEquityNode={this.setMatchedEquityNode}
                showSearchResults={isSearching && searchInput.trim().length > 0}
                subSectorEquities={subSectorEquities}
                subSectorsLoading={subSectorsLoading}
                title={title}
                toggleEquity={this.toggleEquity}
                unmatchedInputs={unmatchedInputs}
            />
        );
    }
}

export const BulkFollowIdentifiersResultsContainer = compose(
    withRequestEquities(),
    withResolve(({ identifiers }) => ({
        variables: { identifiers: identifiers || [] }
    })),
    withProps(({ resolveError, resolveLoading, resolveRefresh }) => ({
        error: resolveError,
        loading: resolveLoading,
        searchEquities: identifiers => {
            resolveRefresh({ identifiers });
        }
    })),
    withSectors(({ sectorIds }) => ({
        skip: !sectorIds || sectorIds.length === 0,
        variables: {
            withEquities: true,
            ids: sectorIds && sectorIds.length ? sectorIds : null
        }
    })),
    withSubSectors(({ subSectorIds }) => ({
        skip: !subSectorIds || subSectorIds.length === 0,
        variables: {
            withEquities: true,
            ids: subSectorIds && subSectorIds.length ? subSectorIds : null
        }
    })),
    withPropsOnChange(['sectors'], props => ({
        sectorEquities: sortEquities([...normalizeSectorEquities(props.sectors || [])])
    })),
    withPropsOnChange(['subSectors'], props => ({
        subSectorEquities: sortEquities([...normalizeSectorEquities(props.subSectors || [])])
    }))
)(BulkFollowIdentifiersResults);
