import { get } from 'utils';

export const generateWordDocument = ({ events, title: docTitle, onComplete, showEstimatedSpeakers }) => {
    import('docx').then(({ Document, Packer, Paragraph, TextRun, SectionType }) => {
        const fileName = docTitle
            .replace(/\./g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();

        const titleObj = [
            new Paragraph({
                children: [
                    new TextRun({
                        text: docTitle,
                        bold: true
                    })
                ]
            })
        ];

        // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
        // This simple example will only contain one section
        let currentSpeakerId = null;
        const doc = new Document({
            sections: [
                {
                    properties: {
                        type: SectionType.CONTINUOUS
                    },
                    children: titleObj
                },
                ...events.map(({ transcript, speaker: speakerPublished, estimatedSpeaker }) => {
                    const speaker =
                        speakerPublished || (showEstimatedSpeakers ? get(estimatedSpeaker, 'speaker') : null);
                    const children = [];

                    // Speaker changed
                    if (speaker && speaker.id && speaker.id !== currentSpeakerId) {
                        currentSpeakerId = speaker.id;
                        const hasSpeakerInfo = !!speaker.title || !!speaker.affiliation;
                        const speakerBlock = {
                            children: [new TextRun({ text: speaker.name, bold: true })]
                        };
                        if (!hasSpeakerInfo) {
                            speakerBlock.spacing = { after: 150 };
                        }
                        children.push(new Paragraph(speakerBlock));
                        if (hasSpeakerInfo) {
                            let speakerTitle = speaker.title || '';
                            if (speaker.affiliation) {
                                speakerTitle += `, ${speaker.affiliation}`;
                            }
                            children.push(
                                new Paragraph({
                                    children: [new TextRun({ text: speakerTitle })],
                                    spacing: {
                                        after: 150
                                    }
                                })
                            );
                        }
                    }
                    children.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: (transcript || '').replace(/(<([^>]+)>)/gi, '')
                                })
                            ],
                            spacing: {
                                after: 150
                            }
                        })
                    );
                    return {
                        properties: {
                            type: SectionType.CONTINUOUS
                        },
                        children
                    };
                })
            ]
        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then(blob => {
                import('file-saver').then(({ saveAs }) => saveAs(blob, fileName));
            })
            .then(onComplete)
            // eslint-disable-next-line no-console
            .catch(() => console.log('Could not save file'));
    });
};
