import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class InternalLink extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        passedStyles: {},
        onClick: undefined,
        to: '#'
    };

    render() {
        const { onClick, styles, passedStyles, to, children } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Link onClick={onClick} to={to}>
                    {children}
                </Link>
            </Div>
        );
    }
}

export const InternalLinkUI = compose(withStyleSheet(styleSheet))(InternalLink);
