import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withSpotlightTypeAutocomplete {
                    spotlightTypes: spotlightTypeHierarchy {
                        id
                        displayName
                        children {
                            id
                            displayName
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    spotlightTypes: get(data, 'spotlightTypes', []),
                    loading: get(data, 'loading')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
