import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TonalGraphUI } from './ui';

export class TonalGraph extends PureComponent {
    static displayName = 'TonalGraphContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        sentimentValue: PropTypes.number.isRequired,
        sentimentStrength: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
        divergentValue: PropTypes.number.isRequired
    };

    static defaultProps = {
        styles: undefined,
        passedStyles: undefined
    };

    render() {
        const { styles, sentimentStrength, sentimentValue, passedStyles, divergentValue } = this.props;
        return (
            <TonalGraphUI
                styles={styles}
                sentimentStrength={sentimentStrength}
                sentimentValue={sentimentValue}
                passedStyles={passedStyles}
                divergentValue={divergentValue}
            />
        );
    }
}

export const TonalGraphContainer = compose()(TonalGraph);
