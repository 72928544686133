import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class BookmarkTagsAutocomplete extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        clearOnSelect: PropTypes.bool.isRequired,
        icon: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSuggestedTagClick: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        suggestedTags: PropTypes.arrayOf(PropTypes.any),
        useTags: PropTypes.bool.isRequired,
        value: PropTypes.arrayOf(PropTypes.any),
        wrapTags: PropTypes.bool.isRequired
    };

    static defaultProps = {
        icon: undefined,
        initialSearchTerm: '',
        label: undefined,
        passedStyles: {},
        suggestedTags: undefined,
        value: undefined
    };

    render() {
        const {
            autoFocus,
            clearOnSelect,
            icon,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onChange,
            onSearch,
            onSuggestedTagClick,
            options,
            passedStyles,
            placeholder,
            styles,
            suggestedTags,
            useTags,
            value,
            wrapTags
        } = this.props;
        return (
            <Fragment>
                <Autocomplete
                    autoFocus={autoFocus}
                    changeOnEnter={false}
                    clearOnSelect={clearOnSelect}
                    icon={icon}
                    initialSearchTerm={initialSearchTerm}
                    keepInViewport
                    label={label}
                    loading={loading}
                    menuStyles={styles.menu}
                    multi={multi}
                    name={name}
                    onChange={onChange}
                    onSearch={onSearch}
                    options={options}
                    placeholder={placeholder}
                    strictOptions={false}
                    styles={{ ...styles.container, ...passedStyles }}
                    useTags={useTags}
                    value={value}
                    wrapTags={wrapTags}
                />
                {get(suggestedTags, 'length', 0) > 0 && (
                    <Div styles={styles.suggestedTags}>
                        <Text size={1}>Suggested:&nbsp;</Text>
                        {suggestedTags.map(({ tag }) => (
                            <Text
                                key={tag}
                                onClick={() => onSuggestedTagClick(tag)}
                                size={1}
                                styles={styles.suggestedTag}
                            >
                                {tag}
                            </Text>
                        ))}
                    </Div>
                )}
            </Fragment>
        );
    }
}

export const BookmarkTagsAutocompleteUI = compose(withStyleSheet(styleSheet))(BookmarkTagsAutocomplete);
