import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { get } from 'utils';
import { withIndices } from 'graphql/indices';
import { IndexAutocompleteUI } from './ui';

const formatOptions = indices => indices.map(({ displayName, id }) => ({ label: displayName, value: id }));

export class IndexAutocomplete extends PureComponent {
    static displayName = 'IndexAutocompleteContainer';

    static propTypes = {
        indices: PropTypes.arrayOf(PropTypes.any),
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
    };

    static defaultProps = {
        indices: [],
        initialSearchTerm: null,
        label: undefined,
        loading: false,
        multi: false,
        name: 'indexAutocomplete',
        onChange: null,
        placeholder: 'Filter by Index',
        styles: {},
        useTags: false,
        value: null
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getTagLabel = this.getTagLabel.bind(this);
        this.formatOptions = memoize(formatOptions);
    }

    onChange({ event, value }) {
        const { onChange, name, indices } = this.props;
        const label = get(
            this.formatOptions(indices).find(({ value: id }) => id === value),
            'label'
        );

        if (onChange) {
            onChange({ event, name, value, label });
        }
    }

    getTagLabel(tag) {
        const { indices } = this.props;
        const index = (indices || []).find(f => f.id === tag);
        return get(index, 'displayName');
    }

    render() {
        const {
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            placeholder,
            styles,
            indices,
            useTags,
            value
        } = this.props;
        return (
            <IndexAutocompleteUI
                getTagLabel={this.getTagLabel}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                options={this.formatOptions(indices)}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const IndexAutocompleteContainer = compose(
    withIndices(),
    withProps(({ indicesLoading }) => ({ loading: indicesLoading }))
)(IndexAutocomplete);
