import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get, generateTabURL } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { A } from 'components/A';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { LoaderDots } from 'components/LoaderDots';
import { RawHTML } from 'components/RawHTML';
import { StreamRow } from 'components/StreamRow';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class CustomCard extends PureComponent {
    static propTypes = {
        dataRecordId: PropTypes.string,
        equity: PropTypes.objectOf(PropTypes.any),
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        record: PropTypes.objectOf(PropTypes.any),
        showSubContent: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        streamId: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        template: PropTypes.objectOf(PropTypes.any),
        templateLoading: PropTypes.bool,
        templateType: PropTypes.string
    };

    static defaultProps = {
        dataRecordId: null,
        equity: null,
        onClick: null,
        passedStyles: {},
        record: null,
        showSubContent: false,
        streamId: null,
        template: null,
        templateLoading: false,
        templateType: null
    };

    getTemplateField(field, defaultValue) {
        const { record, template } = this.props;
        return get(record, get(template, field), defaultValue);
    }

    generateStyle(defaultStyle, value) {
        const { styles } = this.props;

        if (value && String(value).includes('+')) {
            return { ...defaultStyle, ...styles.greenText };
        }
        if (value && String(value).includes('-')) {
            return { ...defaultStyle, ...styles.redText };
        }

        return defaultStyle;
    }

    renderBasicCard() {
        const {
            dataRecordId,
            equity,
            onClick,
            passedStyles,
            pathname,
            showSubContent,
            streamId,
            styles,
            template
        } = this.props;
        const equityId = get(equity, 'equityId');
        const equityLink = equityId ? `/equity/${equityId}` : null;
        const url = this.getTemplateField('url');
        const body = this.getTemplateField('body');
        const topRight = this.getTemplateField('topRight');
        const bottomRight = this.getTemplateField('bottomRight');
        const rawHTMLBody = get(template, 'bodyRaw');
        const fullPageBody = get(template, 'fullPageBody');
        const content = showSubContent || get(body, 'length', 0) < 250 ? body : `${body.slice(0, 250)}...`;
        const to = fullPageBody ? generateTabURL({ pathname, dataRecordId, streamId }) : url ? null : equityLink;
        return (
            <Card styles={{ ...styles.container, ...passedStyles }} name="custom-data-card" to={to} externalTo={url}>
                <Div styles={styles.topContent}>
                    <CardHeader
                        context={this.getTemplateField('ticker', get(equity, 'localTicker'))}
                        title={this.getTemplateField('title')}
                        subtitle={this.getTemplateField('subtitle')}
                    />
                    <Div styles={styles.rightText}>
                        {topRight && (
                            <Text size={3} styles={this.generateStyle(styles.rightText01, topRight)}>
                                {topRight}
                            </Text>
                        )}
                        {bottomRight && (
                            <Text size={1} styles={this.generateStyle(styles.rightText02, bottomRight)}>
                                {bottomRight}
                            </Text>
                        )}
                    </Div>
                </Div>
                {body && (
                    <Fragment>
                        <Text span styles={styles.content} size={3}>
                            {rawHTMLBody ? <RawHTML html={content} /> : content}
                        </Text>
                        {get(body, 'length') > 250 && (
                            <Text styles={styles.readMore} onClick={onClick}>
                                {showSubContent ? 'Read less...' : 'Read more...'}
                            </Text>
                        )}
                    </Fragment>
                )}
            </Card>
        );
    }

    renderBasicRow() {
        const { onClick, passedStyles, showSubContent, styles, theme } = this.props;
        const url = this.getTemplateField('url');
        const title = this.getTemplateField('title');
        const subtitle = this.getTemplateField('subtitle');
        const body = this.getTemplateField('body');
        const topRight = this.getTemplateField('topRight');
        const bottomRight = this.getTemplateField('bottomRight');
        return (
            <StreamRow
                styles={{ cursor: body ? 'pointer' : undefined, ...styles.rowContainer, ...passedStyles }}
                onClick={body ? onClick : undefined}
                name="custom-row"
            >
                <Div styles={styles.rowContent}>
                    <Div styles={styles.rowInfo}>
                        {body ? (
                            <Fragment>
                                <Icon type={showSubContent ? 'boxMinus' : 'boxPlus'} color={theme.colors.gray04} />
                                <Div styles={styles.expandableTitleWrapper}>
                                    <Text className="contentTitle" styles={styles.rowTitle} size={3}>
                                        <A onClick={e => e.stopPropagation()} target="_blank" href={url}>
                                            {title}
                                        </A>
                                    </Text>
                                    <Text uppercase styles={styles.rowSubtitle} size={0}>
                                        {subtitle}
                                    </Text>
                                </Div>
                            </Fragment>
                        ) : (
                            <Div styles={styles.rowTitleWrapper}>
                                <Text className="contentTitle" styles={styles.rowTitle} size={3}>
                                    {title}
                                </Text>
                                {subtitle && (
                                    <Text uppercase styles={styles.rowSubtitle} size={0}>
                                        {subtitle}
                                    </Text>
                                )}
                            </Div>
                        )}
                    </Div>
                    <Div styles={styles.rowRightSide}>
                        <Text size={3} styles={this.generateStyle({}, topRight)}>
                            {topRight}
                        </Text>
                        <Text size={1} styles={this.generateStyle({}, bottomRight)}>
                            {bottomRight}
                        </Text>
                    </Div>
                </Div>
                {showSubContent && (
                    <Div styles={styles.rowSubContent}>
                        <Text size={1} styles={styles.rowSubContentBody}>
                            {body}
                        </Text>
                    </Div>
                )}
            </StreamRow>
        );
    }

    renderTemplate() {
        const { templateType } = this.props;
        return templateType === 'basic_card'
            ? this.renderBasicCard()
            : templateType === 'basic_row'
            ? this.renderBasicRow()
            : null;
    }

    render() {
        const { passedStyles, styles, templateLoading } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {templateLoading ? <LoaderDots /> : this.renderTemplate()}
            </Div>
        );
    }
}

export const CustomCardUI = compose(withStyleSheet(styleSheet))(CustomCard);
