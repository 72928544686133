export const styleSheet = theme => {
    const button = {
        backgroundColor: theme.colors.white01,
        svg: {
            marginRight: 7,
            marginBottom: 1
        }
    };
    return {
        container: {
            display: 'flex',
            position: 'relative',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.1)',
            transition: 'all 0.2s ease-in-out',
            borderRadius: 6.5,
            overflow: 'hidden'
        },
        bulkIdentifierButtonsContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        bulkIdentifiers: {
            marginTop: theme.margins.margin02,
            textarea: {
                maxHeight: 200
            }
        },
        bulkIdentifiersButton: {
            ...theme.content.blueButton,
            borderRadius: 6,
            boxShadow: 'unset',
            display: 'inline-flex',
            marginTop: theme.margins.margin00,
            p: {
                color: theme.colors.white01
            },
            ':last-of-type': {
                marginLeft: 8
            }
        },
        button: {
            ...button
        },
        buttonClone: {
            ...button,
            marginLeft: 12
        },
        buttonDisabled: {
            cursor: 'not-allowed',
            opacity: 0.6
        },
        buttonRemove: {
            ...button,
            backgroundColor: theme.colors.red02,
            border: 'unset',
            boxShadow: 'unset',
            color: theme.colors.white01,
            ':active': {
                backgroundColor: theme.colors.red09
            },
            ':hover': {
                backgroundColor: theme.colors.red01
            }
        },
        buttons: {
            ...theme.content.modalButtonsContainer,
            '> div:first-of-type': {
                marginRight: 12
            },
            '> div:last-of-type': {
                marginLeft: 12
            }
        },
        buttonSave: {
            ...theme.content.blueButton,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        cancelButton: {
            ...theme.content.cancelButton
        },
        closeModal: {
            cursor: 'pointer'
        },
        content: {
            padding: '30px 35px 0'
        },
        dynamicDescription: {
            lineHeight: '1.3em'
        },
        dynamicTitle: {
            marginTop: theme.margins.margin02,
            paddingTop: theme.margins.margin02,
            borderTop: `1px solid ${theme.colors.gray03}`,
            marginBottom: 6
        },
        filters: {
            flex: 1
        },
        filtersContainer: {
            marginTop: theme.margins.margin02,
            display: 'flex',
            alignItems: 'flex-start'
        },
        formContainer: {
            width: 750,
            backgroundColor: theme.colors.white01,
            borderRadius: 6.5
        },
        formRow: {
            marginTop: theme.margins.margin02,
            display: 'flex',
            alignItems: 'flex-start'
        },
        marketCapButtons: {
            marginLeft: 12,
            display: 'flex',
            paddingTop: 22,
            '> div': {
                padding: '0 12px',
                cursor: 'pointer',
                marginRight: 7,
                height: 38,
                borderRadius: 3.5,
                ':last-of-type': {
                    marginRight: 0
                }
            }
        },
        marketCapLabel: {
            color: theme.colors.gray06,
            letterSpacing: '1px',
            marginBottom: 5
        },
        minMax: {
            '> div': {
                width: 184
            }
        },
        notice: {
            backgroundColor: theme.colors.red05,
            borderRadius: 6,
            marginTop: theme.margins.margin01,
            padding: 15
        },
        previewContainer: {
            backgroundColor: theme.colors.white01,
            borderRadius: 6.5,
            marginLeft: 10
        },
        sectorAutocomplete: {
            flex: 1
        },
        shareLink: {
            width: 150,
            p: { marginTop: 0, marginBottom: 6 }
        },
        singleEquity: {
            marginTop: theme.margins.margin02
        },
        spacer: { flex: 1 },
        titleClose: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        titleInput: {
            flex: 1
        },
        toggle: {
            marginTop: 16,
            height: 40
        },
        toggleAdvanced: {
            fontSize: theme.fonts.fontSizes.size02,
            cursor: 'pointer',
            marginTop: -20,
            color: theme.colors.gray06,
            ':hover': {
                color: theme.colors.black01
            }
        }
    };
};
