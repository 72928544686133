import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { generatePath } from 'react-router-dom';
import XDate from 'xdate';
import { routes } from 'routes';
import { withReporting } from 'provider/reporting';
import { withUrlContext } from 'hoc/url';
import { generateTabURL, get, generateModalId } from 'utils';
import { STREAM_DISPLAY_MODES } from 'consts';
import { popoutManager } from 'utils/popout';
import { withData } from './data';
import { EventCardUI } from './ui';

export class EventCard extends PureComponent {
    static displayName = 'EventCardContainer';

    static propTypes = {
        alertEnabled: PropTypes.bool,
        company: PropTypes.string,
        conference: PropTypes.objectOf(PropTypes.any),
        creator: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]).isRequired,
        displayMode: PropTypes.string,
        eventId: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
        exchangeName: PropTypes.string,
        firstTranscriptItemStartMs: PropTypes.number,
        hasTonalSentiment: PropTypes.bool,
        hasTranscript: PropTypes.bool,
        hasUnknownTime: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isLive: PropTypes.bool,
        noLiveAccess: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        organizationName: PropTypes.string,
        overThreshold: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        price: PropTypes.string,
        priceMovementAbsolute: PropTypes.number,
        priceMovementPercent: PropTypes.number,
        processingAudio: PropTypes.bool.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        summaryText: PropTypes.arrayOf(PropTypes.any),
        summaryTitle: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        alertEnabled: false,
        company: null,
        conference: null,
        creator: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        exchangeName: null,
        firstTranscriptItemStartMs: undefined,
        hasTonalSentiment: false,
        hasTranscript: false,
        hasUnknownTime: false,
        isLive: false,
        noLiveAccess: false,
        organizationName: null,
        overThreshold: false,
        price: undefined,
        priceMovementAbsolute: undefined,
        priceMovementPercent: undefined,
        summaryText: undefined,
        summaryTitle: undefined,
        styles: {},
        ticker: null,
        title: null
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handlePopout = this.handlePopout.bind(this);
        this.openUpgradeModal = this.openUpgradeModal.bind(this);
        this.togglePlayEvent = this.togglePlayEvent.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        this.eventId = null;

        this.state = {
            loadingAudio: false,
            summaryExpanded: false
        };
    }

    handleClick(e) {
        if (e.shiftKey) {
            this.handlePopout(e);
        }
    }

    handlePopout(e) {
        const { eventId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generatePath(routes.popoutEvent, { type: 'event', audioCallId: eventId }),
            name: `popout-event-${eventId}`,
            width: 950
        });
    }

    openUpgradeModal(e) {
        const { history, pathname } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        history.push(generateModalId({ pathname, type: 'upgrade', id: 'liveEvents' }));
    }

    togglePlayEvent(event) {
        const { eventId, firstTranscriptItemStartMs, mediaPlayer, reporter } = this.props;
        event.preventDefault();
        event.stopPropagation();

        // clicking twice on the play button triggers the load of stream and webrtc
        // add logic to resolve that.
        if (this.eventId && mediaPlayer.isActive() && !mediaPlayer.listening) {
            this.eventId = null;
            return;
        }
        if (!mediaPlayer.isActive() && !mediaPlayer.listening && !this.eventId) {
            this.eventId = mediaPlayer.id;
        } else {
            this.eventId = null;
        }

        const isLive = !!get(mediaPlayer, 'isLive');
        if (mediaPlayer.listening) {
            if (mediaPlayer.seekable) {
                reporter.track(reporter.actions.click, reporter.objects.audioPause, {
                    live: isLive,
                    component: 'EventCard',
                    eventId
                });
                mediaPlayer.pause();
            } else {
                reporter.track(reporter.actions.click, reporter.objects.audioStop, {
                    live: isLive,
                    component: 'EventCard',
                    eventId
                });
                mediaPlayer.stop();
            }
        } else if (mediaPlayer.canListen) {
            reporter.track(reporter.actions.click, reporter.objects.audioPlay, {
                live: isLive,
                component: 'EventCard',
                eventId
            });

            const { loadingAudio } = this.state;
            if (!loadingAudio) {
                this.setState({ loadingAudio: true }, () => {
                    mediaPlayer
                        .listen()
                        .then(() => {
                            this.setState({ loadingAudio: false });
                        })
                        .catch(() => {
                            this.setState({ loadingAudio: false });
                        });
                    // If playing audio for the first time, seek to the first transcript item's startMs
                    mediaPlayer.maybeSeekToFirstTranscriptItem(
                        isLive,
                        mediaPlayer.currentTime,
                        firstTranscriptItemStartMs
                    );
                });
            }
        }
    }

    toggleSummary() {
        const { summaryExpanded } = this.state;
        this.setState({ summaryExpanded: !summaryExpanded });
    }

    render() {
        const {
            alertEnabled,
            company,
            conference,
            creator,
            date,
            displayMode,
            eventId,
            eventType,
            exchangeName,
            hasTonalSentiment,
            hasTranscript,
            hasUnknownTime,
            isLive,
            noLiveAccess,
            pathname,
            mediaPlayer,
            organizationName,
            overThreshold,
            price,
            priceMovementAbsolute,
            priceMovementPercent,
            processingAudio,
            summaryText,
            summaryTitle,
            styles,
            ticker,
            title
        } = this.props;
        const { loadingAudio, summaryExpanded } = this.state;
        const isUpcoming = new XDate(date).getTime() > new XDate().getTime();
        const canListen = get(mediaPlayer, 'canListen', false);
        return (
            <EventCardUI
                alertEnabled={alertEnabled}
                canListen={canListen}
                company={company}
                conference={conference}
                creator={creator}
                date={date}
                displayMode={displayMode}
                eventId={eventId}
                eventType={eventType}
                exchangeName={exchangeName}
                hasTonalSentiment={hasTonalSentiment}
                hasTranscript={hasTranscript}
                hasUnknownTime={hasUnknownTime}
                isLive={isLive}
                isUpcoming={isUpcoming}
                mediaPlayer={mediaPlayer}
                noLiveAccess={noLiveAccess}
                loadingAudio={loadingAudio}
                onClick={this.handleClick}
                onPopout={this.handlePopout}
                openUpgradeModal={this.openUpgradeModal}
                organizationName={organizationName}
                overThreshold={overThreshold}
                price={price}
                priceMovementAbsolute={priceMovementAbsolute}
                priceMovementPercent={priceMovementPercent}
                processingAudio={processingAudio}
                summaryText={summaryText}
                summaryTitle={summaryTitle}
                summaryExpanded={summaryExpanded}
                styles={styles}
                ticker={ticker}
                title={title}
                to={eventType !== 'earnings_release' ? generateTabURL({ pathname, eventId }) : null}
                togglePlayEvent={this.togglePlayEvent}
                toggleSummary={this.toggleSummary}
            />
        );
    }
}

export const EventCardContainer = compose(
    withUrlContext(['pathname', 'history']),
    withReporting(),
    withData(),
    withPropsOnChange(['audioCall'], ({ audioCall }) => ({
        firstTranscriptItemStartMs: get(audioCall, 'firstTranscriptItemStartMs'),
        processingAudio: get(audioCall, 'processingAudio', false)
    }))
)(EventCard);
