import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Toggle } from 'components/Toggle';
import { Checkbox } from 'components/Checkbox';
import { styleSheet } from './stylesheet';

class WatchlistPreview extends PureComponent {
    static propTypes = {
        equities: PropTypes.arrayOf(PropTypes.any),
        hiddenEquities: PropTypes.arrayOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        loadingMore: PropTypes.bool,
        onRemove: PropTypes.func.isRequired,
        onRemoveAll: PropTypes.func.isRequired,
        onRemoveSelected: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSelectEquity: PropTypes.func.isRequired,
        onUndoRemove: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        rules: PropTypes.arrayOf(PropTypes.any).isRequired,
        scrollRef: PropTypes.objectOf(PropTypes.any),
        searchTerm: PropTypes.string.isRequired,
        selectedEquities: PropTypes.arrayOf(PropTypes.string).isRequired,
        showHiddenEquities: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleHiddenEquities: PropTypes.func.isRequired
    };

    static defaultProps = {
        equities: [],
        loading: false,
        loadingMore: false,
        passedStyles: {},
        scrollRef: undefined
    };

    renderEmpty() {
        const { loading, equities, styles } = this.props;
        return loading && (!equities || equities.length === 0) ? (
            <Div styles={{ flex: 1 }}>
                <LoaderLogo styles={styles.loader} height={60} />
            </Div>
        ) : (
            <Div styles={styles.empty}>
                <Text size={3}>No companies in Watchlist</Text>
            </Div>
        );
    }

    render() {
        const {
            equities,
            hiddenEquities,
            loading,
            loadingMore,
            onRemove,
            onRemoveAll,
            passedStyles,
            styles,
            theme,
            onRemoveSelected,
            onSearch,
            onSelectEquity,
            onUndoRemove,
            rules,
            scrollRef,
            searchTerm,
            selectedEquities,
            showHiddenEquities,
            toggleHiddenEquities
        } = this.props;
        const hasSelected = selectedEquities.length > 0;
        const numHiddenEquities = hiddenEquities.length;
        const restoreAllDescription =
            'Companies are hidden only when added from a dynamic rule, such as the S&P 500 index, and manually ' +
            'removed from the list.';
        const showRestoreAll = numHiddenEquities > 1;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.header}>
                    <Text size={3} weight="medium">
                        Watchlist Preview
                    </Text>
                    <Text size={1} styles={styles.description}>
                        Add companies using the controls <br />
                        on the left, or select companies below to remove them from your watchlist.
                    </Text>
                    <TextInput
                        clearable
                        styles={styles.search}
                        name="watchlistSearchTerm"
                        onChange={onSearch}
                        placeholder="Search watchlist by ticker..."
                        value={searchTerm}
                        icon="search03"
                        disabled={rules.length === 0}
                    />
                    {(equities.length > 0 || (loading && numHiddenEquities > 0)) && (
                        <Div styles={styles.bulkControls}>
                            {hasSelected && <Text styles={styles.numSelected}>{selectedEquities.length} selected</Text>}
                            <Div styles={styles.spacer} />
                            <Div styles={styles.removeSelected} onClick={hasSelected ? onRemoveSelected : onRemoveAll}>
                                <Icon type="trash02" color={theme.colors.gray04} />
                                <Text>{hasSelected ? 'Remove' : 'Remove All'}</Text>
                            </Div>
                        </Div>
                    )}
                </Div>
                {numHiddenEquities > 0 && (
                    <Div
                        styles={
                            showHiddenEquities ? styles.hiddenEquitiesContainer : styles.hiddenEquitiesContainerHidden
                        }
                    >
                        {showHiddenEquities && (
                            <Fragment>
                                {showRestoreAll && (
                                    <Hint
                                        description={restoreAllDescription}
                                        growLeft
                                        growUp
                                        hideOnScroll
                                        onClick={() => onUndoRemove(hiddenEquities.map(({ id }) => id))}
                                        styles={styles.restoreAll}
                                        text="Restore visibility to all hidden companies"
                                        width={400}
                                        yOffset={-22}
                                    >
                                        <Icon type="reset02" color={theme.colors.gray04} />
                                        <Text>Restore All</Text>
                                    </Hint>
                                )}
                                <Div styles={styles.hiddenEquitiesWrapper}>
                                    {hiddenEquities.map(e => (
                                        <Hint
                                            hideOnScroll
                                            text="Restore Visibility"
                                            description={`${e.localTicker} — ${e.commonName}`}
                                            key={`watchlist-preview-hidden-match-${e.id}`}
                                            styles={styles.hiddenEquityRow}
                                            onClick={() => onUndoRemove(e.id)}
                                            growUp
                                            yOffset={-30}
                                        >
                                            <Text className="ticker" size={1} weight="medium" uppercase>
                                                {e.localTicker}
                                            </Text>
                                            <Text className="name">{e.commonName}</Text>
                                            <Icon type="eyeStrike" color={theme.colors.red09} />
                                        </Hint>
                                    ))}
                                </Div>
                            </Fragment>
                        )}
                        {numHiddenEquities > 0 && (
                            <Div
                                onClick={toggleHiddenEquities}
                                styles={
                                    showHiddenEquities
                                        ? styles.showHiddenEquitiesContainer
                                        : styles.showHiddenEquitiesContainerHidden
                                }
                            >
                                <Toggle
                                    on={showHiddenEquities}
                                    styles={
                                        showHiddenEquities
                                            ? styles.showHiddenEquitiesToggle
                                            : styles.showHiddenEquitiesToggleHidden
                                    }
                                />
                                <Text styles={styles.showHiddenEquitiesLink}>{`${
                                    showHiddenEquities ? 'Hide' : 'Show'
                                } ${numHiddenEquities} hidden companies`}</Text>
                            </Div>
                        )}
                    </Div>
                )}
                <Div styles={styles.listWrapper}>
                    <Div ref={scrollRef} styles={[styles.listContainer, loading && styles.loadingContainer]}>
                        {equities.length > 0
                            ? equities.map(e => (
                                  <Div
                                      key={`watchlist-preview-match-${e.id}`}
                                      styles={styles.equityRow}
                                      onClick={() => onSelectEquity(e.id)}
                                  >
                                      <Checkbox size={3} checked={selectedEquities.includes(e.id)} />
                                      <Div styles={styles.equityInfo}>
                                          <Div styles={styles.ticker}>
                                              <Text size={0} weight="medium">
                                                  {e.ticker}
                                              </Text>
                                              <Text size={0} uppercase styles={styles.exchange}>
                                                  {e.exchangeName}
                                              </Text>
                                          </Div>
                                          <Text size={1} styles={styles.company}>
                                              {e.company}
                                          </Text>
                                      </Div>
                                      <Div
                                          className="closeIcon"
                                          styles={styles.remove}
                                          onClick={event => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              onRemove(e.id, {
                                                  id: e.id,
                                                  localTicker: e.ticker,
                                                  commonName: e.company
                                              });
                                          }}
                                      >
                                          <Icon type="circleX" color={theme.colors.red01} />
                                      </Div>
                                  </Div>
                              ))
                            : this.renderEmpty()}
                        {!loading && loadingMore && <LoaderLogo styles={styles.loader} height={40} />}
                    </Div>
                </Div>
            </Div>
        );
    }
}

export const WatchlistPreviewUI = compose(withStyleSheet(styleSheet))(WatchlistPreview);
