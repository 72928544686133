export const styleSheet = theme => ({
    container: {},
    inputContainer: {
        position: 'relative'
    },
    input: {
        input: {
            height: '40px',
            fontSize: theme.fonts.fontSizes.size03,
            margin: 0,
            width: '100%',
            paddingLeft: theme.margins.margin00,
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                fontSize: theme.fonts.fontSizes.size04
            }
        },
        'div:last-of-type': {
            right: theme.margins.margin00,
            top: theme.margins.margin00
        }
    },
    tagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        borderTop: `1px solid ${theme.colors.gray01}`,
        padding: `8px ${theme.margins.margin00} 4px`,
        borderRadius: '0 0 6px 6px',
        backgroundColor: theme.colors.gray10,
        flexShrink: 0
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.gray01,
        borderRadius: '2px',
        height: '20px',
        padding: '4px 8px',
        margin: '0 6px 6px 0',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.gray09
        },
        p: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    removeIcon: {
        cursor: 'pointer',
        marginLeft: `calc(${theme.margins.margin00} - 5px)`,
        svg: {
            height: '10px'
        }
    },
    resultsContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white01,
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: '0 0 2px 2px',
        maxHeight: '100px',
        top: `calc(${theme.margins.margin04} + 5px)`,
        left: 0,
        right: 0,
        overflowY: 'scroll',
        margin: `0 ${theme.margins.margin01}`,
        padding: `calc(${theme.margins.margin00} - 1px)`,
        zIndex: 1
    },
    resultsRow: {
        display: 'flex',
        cursor: 'pointer',
        fontSize: theme.fonts.fontSizes.size02,
        marginBottom: theme.margins.margin00
    }
});
