import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { styleSheet } from './stylesheet';

const colorMap = {
    white: 'white',
    orange: 'orange',
    darkGray: 'dark-gray',
    lightGray: 'light-gray'
};

class Spinner extends PureComponent {
    static propTypes = {
        color: PropTypes.oneOf(['white', 'orange', 'darkGray', 'lightGray']),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        color: 'white',
        passedStyles: {}
    };

    render() {
        const { color, passedStyles, styles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Img styles={styles.img} src={`/static/nohash/spinner-${colorMap[color]}.gif`} />
            </Div>
        );
    }
}

export const SpinnerUI = compose(withStyleSheet(styleSheet))(Spinner);
