export const styleSheet = theme => ({
    container: {
        position: 'relative',
        ':hover': {
            'input:not(:focus)': {
                boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
                backgroundColor: theme.colors.white02
            }
        }
    },
    containerClearable: {
        position: 'relative',
        ':hover': {
            'input:not(:focus)': {
                boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
                backgroundColor: theme.colors.white02
            },
            '.iconRightWrapper': {
                display: 'none'
            },
            '.clearInput': {
                display: 'flex'
            }
        }
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        ...theme.content.formLabel
    },
    textError: {
        ...theme.content.formLabel,
        color: theme.colors.inputError
    },
    textRequired: {
        marginBottom: 'unset',
        marginTop: 4,
        letterSpacing: 1,
        color: theme.colors.inputError
    },
    input: {
        display: 'block',
        width: '100%',
        backgroundColor: theme.colors.white03,
        height: '40px',
        border: 'none',
        borderRadius: '6px',
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size04,
        paddingLeft: 12,
        transition: 'all 0.2s ease-in-out',
        '::placeholder': {
            color: theme.colors.gray04
        },
        ':focus': {
            backgroundColor: theme.colors.white01,
            boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 50px ${theme.colors.input} inset`
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            WebkitAppearance: 'none'
        }
    },
    inputWithIcon: {
        paddingLeft: 36
    },
    inputWithText: {
        backgroundColor: theme.colors.white01,
        boxShadow: `0 0 0 1px ${theme.colors.gray01}`
    },
    inputError: {
        boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`,
        '::placeholder': {
            color: theme.colors.inputError
        },
        ':focus': {
            boxShadow: `0 0 0 1px ${theme.colors.red07}, 0 0 0 3px ${theme.colors.red05}`
        }
    },
    iconWrapper: {
        width: '16px',
        justifyContent: 'center',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        left: 12,
        bottom: 0,
        height: '40px',
        pointerEvents: 'none'
    },
    iconRightWrapper: {
        width: '16px',
        justifyContent: 'center',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        right: 12,
        bottom: 0,
        height: '40px',
        pointerEvents: 'none'
    },
    clearInput: {
        display: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 12,
        alignItems: 'center',
        opacity: 0.4,
        ':hover': {
            opacity: 1
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'flex'
        }
    },
    clearInputLabel: {
        marginTop: 21
    },
    disabled: {
        cursor: 'not-allowed'
    }
});
