import React from 'react';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import { PERMISSIONS, TRACKING_ACTIONS, TRACKING_OBJECTS } from 'consts';
import { getUserQuery } from 'graphql/user';
import { get } from 'utils';

window.lr = LogRocket;

export function configureReporting(config, { bugsnagClient, graphqlClient }) {
    const { AIERA_ENV, BUGSNAG_VERSION, HEAP_ENABLED, LOGROCKET_API } = config;

    function initLogrocket() {
        if (config.LOGROCKET_ENABLED && !config.actingAsUser()) {
            LogRocket.init(LOGROCKET_API, {
                release: BUGSNAG_VERSION,
                network: {
                    requestSanitizer: request => {
                        if (
                            request.url.toLowerCase().includes('op=login') ||
                            request.url.toLowerCase().includes('op=register')
                        ) {
                            try {
                                const parsed = JSON.parse(request.body);
                                parsed.forEach(({ variables }) => {
                                    // eslint-disable-next-line
                                    variables.password = null;
                                    if (variables.input) {
                                        // eslint-disable-next-line
                                        variables.input.password = null;
                                        // eslint-disable-next-line
                                        variables.input.code = null;
                                    }
                                });
                                request.body = JSON.stringify(parsed);
                                // eslint-disable-next-line
                            } catch (e) {}
                        }
                        return request;
                    }
                }
            });
        }
    }
    initLogrocket();

    // set logrocket true since we default to enabled
    let lastUser = { logrocketEnabled: true };
    const updateUserInfo = data => {
        const user = get(data, 'data.currentUser');
        const userId = get(user, 'userId', get(user, 'id'));
        const status = get(user, 'status');
        const email = get(user, 'email');
        const name = get(user, 'name');
        const identityProvider = get(user, 'identityProvider');
        const isAnalyst = get(user, 'isAnalyst');
        const logrocketEnabled =
            !user || !user.permissions || get(user, 'permissions', []).includes(PERMISSIONS.featureLogrocket);
        const organizationId = get(user, 'organizationId');
        const organizationAdmin = get(user, 'organizationAdmin');
        const organizationName = get(user, 'organization.name');
        const phone = get(user, 'phone');
        const customerType = get(user, 'customerType');
        const isCustomer = get(user, 'organization.isCustomer');
        const createDate = get(user, 'createDate');
        const lastActive = get(user, 'lastActive');

        /* eslint-disable no-param-reassign */
        bugsnagClient.user = {
            email,
            id: userId,
            name,
            isAnalyst,
            organizationId,
            organizationAdmin,
            phone
        };

        if (lastUser.logrocketEnabled && !logrocketEnabled) {
            LogRocket.uninstall();
        } else if (!lastUser.logrocketEnabled && logrocketEnabled) {
            // This doesn't end up working correctly but would only mean we miss sessions
            // from someone who logs in the same browser session of a user who had this disabled
            // and logged out (without refresh).¯\_(ツ)_/¯
            initLogrocket();
            LogRocket.startNewSession();
        }

        if (lastUser.userId !== userId || lastUser.email !== email) {
            LogRocket.identify(userId, {
                email
            });

            if (HEAP_ENABLED && window.heap) {
                window.heap.identify(userId);
                window.heap.addUserProperties({
                    env: AIERA_ENV,
                    email,
                    name,
                    customerType,
                    isCustomer,
                    idpUser: !!identityProvider,
                    idpProvider: get(identityProvider, 'providerName'),
                    createDate,
                    lastActive,
                    organizationName,
                    organizationId
                });
                window.heap.addEventProperties({
                    customerType,
                    status
                });
            }
        }

        if (!userId) {
            if (HEAP_ENABLED && window.heap) {
                window.heap.clearEventProperties();
            }
        }

        lastUser = {
            logrocketEnabled,
            userId,
            email
        };
    };

    const queryOpts = {
        query: getUserQuery(),
        fetchPolicy: 'cache-only',
        variables: { withDetails: true }
    };
    graphqlClient.query(queryOpts).then(updateUserInfo);
    graphqlClient.watchQuery(queryOpts).subscribe(updateUserInfo);

    const track = (action, object, metadata /* , options = {} */) => {
        if (window.heap) {
            window.heap.track(`${action} | ${object}`, metadata);
        }
    };

    return { bugsnagClient, track, actions: TRACKING_ACTIONS, objects: TRACKING_OBJECTS };
}

const ReportingContext = React.createContext();
export const ReportingProvider = ({ children, reporter }) => (
    <ReportingContext.Provider value={reporter}>{React.Children.only(children)}</ReportingContext.Provider>
);
ReportingProvider.propTypes = { reporter: PropTypes.objectOf(PropTypes.any).isRequired };

export const withReporting = () => BaseComponent => props => (
    <ReportingContext.Consumer>
        {reporter => <BaseComponent {...props} reporter={reporter} />}
    </ReportingContext.Consumer>
);
