import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Filter } from 'components/Filter';
import { TextInput } from 'components/TextInput';
import { CountryAutocomplete } from 'components/CountryAutocomplete';
import { EventGroupAutocomplete } from 'components/EventGroupAutocomplete';
import { EquityAutocomplete } from 'components/EquityAutocomplete';
import { FilingFormAutocomplete } from 'components/FilingFormAutocomplete';
import { NewsSourceAutocomplete } from 'components/NewsSourceAutocomplete';
import { NewsSourceTagAutocomplete } from 'components/NewsSourceTagAutocomplete';
import { SearchCommandLegend } from 'components/SearchCommandLegend';
import { SourcesAutocomplete } from 'components/SourcesAutocomplete';
import { WatchlistAutocomplete } from 'components/WatchlistAutocomplete';
import { SectorAutocomplete } from 'components/SectorAutocomplete';
import { SpeakerAutocomplete } from 'components/SpeakerAutocomplete';
import { Dropdown } from 'components/Dropdown';
import { get } from 'utils';
import { getValueProps, INPUT_TYPES, SOURCE_TYPES, TYPES } from 'consts/filters';
import { styleSheet } from './stylesheet';

class StreamFilter extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        types: PropTypes.arrayOf(PropTypes.any).isRequired,
        typeValue: PropTypes.string,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        passedStyles: {},
        typeValue: null,
        value: null
    };

    renderValueComponent() {
        const { styles, typeValue, value } = this.props;

        // Each of these is given an onChange
        // and a value prop, supplied by the
        // Filter component
        const operator = get(value, 'operator');
        const valueProps = getValueProps(typeValue, operator);
        const inputType = get(valueProps, 'type');

        if (inputType === INPUT_TYPES.dropdown) {
            const { options } = valueProps;
            return (
                <Dropdown
                    disableHoverOpen
                    name={`${typeValue}-dropdown`}
                    options={options}
                    placeholder="Select option"
                    styles={styles.dropdown}
                />
            );
        }

        if (inputType === INPUT_TYPES.eventGroupAutocomplete) {
            return (
                <EventGroupAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="eventGroupAutocomplete"
                    placeholder="Enter conference..."
                    resultsPlacholder="Search by conference name..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (inputType === INPUT_TYPES.filingAutocomplete) {
            return (
                <FilingFormAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="filingFormAutocomplete"
                    placeholder="Enter filing form..."
                    resultsPlacholder="Search by filing form..."
                    styles={styles.autocomplete}
                />
            );
        }

        if ([TYPES.country, TYPES.exchangeCountry].includes(typeValue)) {
            return (
                <CountryAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="countryAutocomplete"
                    placeholder="Enter country..."
                    resultsPlacholder="Search by country name..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.equity) {
            return (
                <EquityAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="equityAutocomplete"
                    placeholder="Enter equity..."
                    resultsPlaceholder="Search by ticker, name..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.eventType) {
            return (
                <SourcesAutocomplete
                    name="eventTypeAutocomplete"
                    placeholder="Enter event type..."
                    resultsPlaceholder="Search by event type..."
                    sourceTypes={[SOURCE_TYPES[TYPES.eventType]]}
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.newsSource) {
            return (
                <NewsSourceAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="newsSourceAutocomplete"
                    placeholder="Enter news source..."
                    resultsPlaceholder="Search news sources..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.newsSourceTag) {
            return (
                <NewsSourceTagAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="newsSourceTagAutocomplete"
                    placeholder="Enter source tag..."
                    resultsPlaceholder="Search source tags..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.sector) {
            return (
                <SectorAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="sectorAutocomplete"
                    placeholder="Enter sector..."
                    resultsPlaceholder="Search by sector..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.source) {
            return (
                <SourcesAutocomplete
                    name="sourcesAutocomplete"
                    placeholder="Enter source..."
                    resultsPlaceholder="Search sources..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.person) {
            return (
                <SpeakerAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="speakerAutocomplete"
                    placeholder="Speaker name..."
                    resultsPlaceholder="Search speakers..."
                    styles={styles.autocomplete}
                />
            );
        }

        if (typeValue === TYPES.watchlist) {
            return (
                <WatchlistAutocomplete
                    initialSearchTerm={get(value, 'label')}
                    name="watchlistAutocomplete"
                    placeholder="Watchlists"
                    resultsPlaceholder="Search watchlists..."
                    styles={styles.autocomplete}
                />
            );
        }

        return (
            <TextInput
                autoComplete="off"
                disabled={!typeValue}
                icon={typeValue === 'searchTerm' ? 'pencil02' : undefined}
                name="searchTermInput"
                placeholder={typeValue === 'searchTerm' ? 'Enter search term...' : 'Enter value...'}
                styles={styles.searchTermInput}
                tooltipComponent={typeValue === 'searchTerm' ? <SearchCommandLegend /> : undefined}
                tooltipOptions={SearchCommandLegend.tooltipOptions}
                type={inputType === 'number' ? 'number' : 'text'}
            />
        );
    }

    render() {
        const { styles, passedStyles, types, name, onChange, value } = this.props;
        return (
            <Filter
                styles={{ ...styles.container, ...passedStyles }}
                types={types}
                name={name}
                onChange={onChange}
                value={value}
                valueComponent={this.renderValueComponent()}
            />
        );
    }
}

export const StreamFilterUI = compose(withStyleSheet(styleSheet))(StreamFilter);
