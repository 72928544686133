import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EventStreamToolbarUI } from './ui';

export class EventStreamToolbar extends PureComponent {
    static displayName = 'EventStreamToolbarContainer';

    static propTypes = {
        hasEarningsOnly: PropTypes.bool,
        hasTranscript: PropTypes.bool,
        showHasTranscriptToggle: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        toggleEarningsOnly: PropTypes.func.isRequired,
        toggleHasTranscript: PropTypes.func.isRequired
    };

    static defaultProps = {
        hasEarningsOnly: false,
        hasTranscript: false,
        showHasTranscriptToggle: false,
        styles: undefined
    };

    render() {
        const {
            hasEarningsOnly,
            hasTranscript,
            showHasTranscriptToggle,
            styles,
            toggleEarningsOnly,
            toggleHasTranscript
        } = this.props;
        return (
            <EventStreamToolbarUI
                hasEarningsOnly={hasEarningsOnly}
                hasTranscript={hasTranscript}
                showHasTranscriptToggle={showHasTranscriptToggle}
                styles={styles}
                toggleEarningsOnly={toggleEarningsOnly}
                toggleHasTranscript={toggleHasTranscript}
            />
        );
    }
}

export const EventStreamToolbarContainer = compose()(EventStreamToolbar);
