export const styleSheet = theme => ({
    container: {
        alignItems: 'center',
        borderRadius: 6,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: 30,
        svg: {
            height: 16,
            width: 16
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.gray04
            }
        },
        ':active': {
            'svg g': {
                fill: theme.colors.gray06
            }
        }
    },
    realTime: {
        padding: '0 10px 10px',
        marginTop: 14
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.margins.margin01
    },
    alertsContainer: {
        a: {
            textDecoration: 'none'
        }
    },
    averageDailyVolume: {
        margin: '10px 10px 0',
        backgroundColor: theme.colors.gray10,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6
    },
    averageDailyVolumeWarning: {
        margin: '10px 10px 0',
        backgroundColor: theme.colors.gray10,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        span: {
            color: theme.colors.red02
        }
    },
    manageAlerts: {
        marginBottom: 10,
        color: theme.colors.gray06,
        textAlign: 'center',
        ':hover': {
            color: theme.colors.gray08,
            textDecoration: 'underline'
        }
    },
    realTimeToggles: {
        alignItems: 'center',
        backgroundColor: theme.colors.white02,
        borderBottom: `1px solid ${theme.colors.gray01}`,
        borderLeft: `1px solid ${theme.colors.gray01}`,
        borderRadius: '0 0 6px 6px',
        borderRight: `1px solid ${theme.colors.gray01}`,
        display: 'flex',
        'div:nth-of-type(2)': {
            borderLeft: `1px solid ${theme.colors.gray01}`,
            width: 100
        }
    },
    tooltip: {
        ...theme.content.hoverMenu,
        borderRadius: '0 0 0 6px',
        backgroundColor: theme.colors.white01,
        width: 220
    },
    warning: {
        display: 'flex',
        backgroundColor: theme.colors.yellow07,
        borderRadius: 6,
        margin: '0 10px 10px',
        padding: '10px 12px',
        alignItems: 'center',
        svg: {
            flexShrink: 0,
            marginBottom: 1
        },
        p: {
            lineHeight: '1.2em',
            marginLeft: 10
        }
    },
    options: {
        marginTop: 6,
        '> div': {
            height: 37,
            padding: '10px 10px 10px 12px',
            p: {
                marginLeft: 10
            }
        },
        '.optionSelectContainer': {
            height: 15,
            width: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            svg: {
                height: 'unset',
                width: 'unset',
                margin: 'unset'
            }
        }
    },
    optionsTitle: {
        marginBottom: 4
    },
    optionsDescription: {
        color: theme.colors.gray06,
        lineHeight: '1.2em'
    },
    spinner: {
        hight: 15,
        width: 15
    }
});
