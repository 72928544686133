import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';

import { styleSheet } from './stylesheet';

class TextHint extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        text: PropTypes.string.isRequired,
        title: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        title: undefined
    };

    renderTooltip() {
        const { styles, theme, title, text } = this.props;

        return (
            <Div styles={styles.tooltip}>
                {title && (
                    <Div styles={styles.toolTipHeader}>
                        <Text size={3} weight="medium">
                            {title}
                        </Text>
                        <Icon type="circleInfo02" color={theme.colors.white01} />
                    </Div>
                )}
                <Text styles={styles.toolTipText} size={1}>
                    {text}
                </Text>
            </Div>
        );
    }

    render() {
        const { styles, passedStyles, children } = this.props;

        return (
            <Tooltip
                isEnabled
                useElementOffsetBottom
                useElementOffsetLeft
                yOffset={6}
                xOffset={-12}
                styles={styles.tooltip}
                content={this.renderTooltip()}
            >
                {({ showTooltip, hideTooltip }) => (
                    <Div
                        styles={{ ...styles.container, ...passedStyles }}
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                    >
                        {children}
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const TextHintUI = compose(withStyleSheet(styleSheet))(TextHint);
