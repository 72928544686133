import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { PERMISSIONS } from 'consts';
import { generateTabURL, toDateTimeString } from 'utils';
import { Div } from 'components/Div';
import { InternalLink } from 'components/InternalLink';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { WithPermission } from 'components/WithPermission';
import { styleSheet } from './stylesheet';

class EventFilings extends PureComponent {
    static propTypes = {
        filings: PropTypes.arrayOf(PropTypes.object),
        openUpgradeModal: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        filings: [],
        passedStyles: {}
    };

    renderFilings() {
        const { filings, pathname, styles } = this.props;
        return filings.map(({ publishedDate, title, id }) => (
            <Div styles={styles.filing} to={generateTabURL({ pathname, filingId: id })}>
                <InternalLink styles={styles.link} to={generateTabURL({ pathname, filingId: id })}>
                    <Text size={3}>{title}</Text>
                </InternalLink>
                <Div styles={styles.actionDetails}>
                    <Text size={1} styles={styles.publishDate}>
                        {toDateTimeString(publishedDate)}
                    </Text>
                </Div>
            </Div>
        ));
    }

    renderRestricted() {
        const { styles, openUpgradeModal, theme } = this.props;

        return (
            <Div styles={styles.upsellContent} onClick={openUpgradeModal}>
                <Div styles={styles.upsellTitle}>
                    <Text size={5} weight="medium">
                        Unlock Filings
                    </Text>
                    <Icon type="lock02" color={theme.colors.black01} />
                </Div>
                <Text size={3}>Filings associated with this event are locked.</Text>
            </Div>
        );
    }

    render() {
        const { passedStyles, styles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <WithPermission permissions={[PERMISSIONS.unlockedFilingsContent]} value={true} defaultValue={false}>
                    {({ restricted, isLoading }) => (
                        <Fragment>{restricted && !isLoading ? this.renderRestricted() : this.renderFilings()}</Fragment>
                    )}
                </WithPermission>
            </Div>
        );
    }
}

export const EventFilingsUI = compose(withStyleSheet(styleSheet))(EventFilings);
