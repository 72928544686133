export const styleSheet = theme => {
    const baseButton = {
        ...theme.content.blueButton,
        display: 'inline-flex',
        alignSelf: 'center',
        color: theme.colors.white01,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: theme.fonts.fontWeights.medium,
        fontSize: theme.fonts.fontSizes.size03,
        padding: '0 14px',
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        transition: 'all 0.1s ease-in-out',
        cursor: 'pointer',
        boxShadow: `0 0 0 0 ${theme.colors.white01}`,
        minWidth: '120px'
    };

    return {
        container: {
            textAlign: 'center',
            height: 40
        },
        button: {
            ...baseButton,
            ':hover': {
                transform: 'scale(1.02)',
                boxShadow: `0 3px 3px 0 ${theme.colors.gray01}`
            },
            ':active': {
                transform: 'scale(0.98)',
                boxShadow: `0 0 3px 0 ${theme.colors.gray01}`
            }
        },
        disabledButton: {
            ...baseButton,
            cursor: 'not-allowed'
        },
        loadingButton: {
            ...baseButton,
            padding: `0 ${theme.margins.margin01}`
        },
        iconContainer: {
            transition: 'all 0.1s ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        disabledIconContainer: {
            transition: 'all 0.1s ease-in-out',
            opacity: 0.5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        disabledText: {
            transition: 'all 0.1s ease-in-out',
            opacity: 0.5
        },
        text: {
            transition: 'all 0.1s ease-in-out'
        },
        hiddenButton: {
            border: 'none',
            height: '0px',
            width: '0px',
            display: 'none'
        }
    };
};
