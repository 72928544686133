import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class DropdownOptionWrap extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        option: PropTypes.objectOf(PropTypes.any).isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        className: undefined
    };

    render() {
        const {
            className,
            option: { label, value },
            onMouseDown,
            onMouseEnter,
            passedStyles
        } = this.props;
        return (
            <Text
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                className={className}
                size={3}
                key={value}
                styles={{
                    ...passedStyles,
                    padding: '10px 12px',
                    lineHeight: '1.3em'
                }}
            >
                {label}
            </Text>
        );
    }
}

export const DropdownOptionWrapUI = compose(withStyleSheet(styleSheet))(DropdownOptionWrap);
