import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import XDate from 'xdate';
import { theme } from 'styles';
import { get } from 'utils';
import { withData } from './data';

import { ContentStreamChartUI } from './ui';

export class ContentStreamChart extends PureComponent {
    static displayName = 'ContentStreamChartContainer';

    static propTypes = {
        chartRef: PropTypes.objectOf(PropTypes.any),
        rangeTotal: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        data: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        chartRef: undefined,
        rangeTotal: undefined,
        styles: undefined,
        data: []
    };

    constructor(props) {
        super(props);

        this.state = {
            options: this.generateOptions()
        };
    }

    componentDidUpdate({ data: prevData }) {
        const { data } = this.props;
        const series = get(data, '[0].data', []);
        const prevSeries = get(prevData, '[0].data', []);

        if (series.length !== prevSeries.length) {
            this.setState({ options: this.generateOptions() });
        }
    }

    generateOptions() {
        const { data } = this.props;
        const series = get(data, '[0]', {});
        const days = get(series, 'data', []).length;
        return {
            exporting: { enabled: false },
            chart: {
                type: 'spline',
                height: 90,
                backgroundColor: theme.colors.white01,
                spacing: [6, 6, 2, 6]
            },
            credits: {
                enabled: false
            },
            yAxis: {
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                tickAmount: 5,
                startOnTick: true,
                endOnTick: true,
                labels: {
                    style: {
                        color: theme.colors.gray04
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: null
                },
                tickPixelInterval: days > 3 ? 180 : 100,
                tickLength: 0,
                startOnTick: days > 3,
                endOnTick: days > 3,
                labels: {
                    style: {
                        color: theme.colors.gray04
                    }
                },
                lineWidth: 0,
                top: 2,
                max: new XDate().getTime()
            },
            legend: {
                enabled: false
            },
            title: {
                text: null
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            tooltip: {
                backgroundColor: theme.colors.gray11,
                borderColor: theme.colors.gray01,
                borderRadius: 6,
                borderWidth: 1,
                valueDecimals: 0,
                shadow: false,
                shared: true,
                split: false,
                style: {
                    color: theme.colors.white01,
                    fontFamily: theme.fonts.fontFamily,
                    fontSize: theme.fonts.fontSizes.size01,
                    textTransform: 'capitalize'
                },
                formatter() {
                    const dateString = `${new XDate(this.x).toString('MMM dd, yyyy')} <br/>`;
                    const items = this.points.sort((a, b) => (a.y < b.y ? -1 : a.y > b.y ? 1 : 0)).reverse();
                    const tipText = items.map(
                        item =>
                            `<span style="color:${item.color}" />●</span> ${item.series.name}: ${item.y.toFixed(
                                0
                            )} <br/>`
                    );
                    return dateString + tipText.join('');
                }
            },
            series: data
        };
    }

    render() {
        const { styles, chartRef, data, rangeTotal } = this.props;
        const { options } = this.state;

        // Not useful to chart less than 2 items
        const shouldLoad = data.find(({ data: d }) => d.length > 1);
        if (shouldLoad) {
            return (
                <ContentStreamChartUI chartRef={chartRef} rangeTotal={rangeTotal} styles={styles} options={options} />
            );
        }

        return null;
    }
}

export const ContentStreamChartContainer = compose(withData())(ContentStreamChart);
