import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class EventUploadProgress extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        percentComplete: PropTypes.number,
        status: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {},
        percentComplete: 0,
        status: undefined
    };

    render() {
        const { passedStyles, percentComplete, status, styles } = this.props;
        const progress = percentComplete * 100;
        if (percentComplete <= 0) {
            return null;
        }
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Text size={2} styles={styles.percentComplete}>
                    {`${Math.round(progress)}% Complete`}
                </Text>
                <Div styles={styles.progressBarContainer}>
                    <Div
                        className="progressBar"
                        styles={{
                            ...styles.progressBar,
                            borderRadius: `${percentComplete >= 0.99 ? '6px' : '6px 0 0 6px'}`,
                            width: `${progress}%`
                        }}
                    />
                </Div>
                <Div style={styles.status}>{!!status && <Text size={2}>{status}</Text>}</Div>
            </Div>
        );
    }
}

export const EventUploadProgressUI = compose(withStyleSheet(styleSheet))(EventUploadProgress);
