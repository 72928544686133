import gql from 'graphql-tag';

export const equityFragment = gql`
    fragment equity on Equity {
        id
        commonName
        equityId
        last
        lastClose
        localTicker
        marketcap
        name
        nextEarnings: nextEarningsDate
        status
        currency {
            id
            currencyCode
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
        country {
            id
            shortName
        }
        exchange {
            id
            shortName
        }
        subSector: gicsSubSector {
            id
            name
        }
        sector: gicsSector {
            id
            name
        }
    }
`;

export const smallEquityFragment = gql`
    fragment smallEquity on Equity {
        __typename
        id
        equityId
        last
        lastClose
        commonName
        localTicker
        marketcap
        nextEarnings: nextEarningsDate
        currency {
            __typename
            id
            currencyCode
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
        country {
            __typename
            id
            shortName
        }
        exchange {
            __typename
            id
            shortName
        }
        subSector: gicsSubSector {
            __typename
            id
            name
        }
        sector: gicsSector {
            __typename
            id
            name
        }
        valuation {
            __typename
            metric
            nextTwelveMonths
        }
    }
`;

export const equityAboutFragment = gql`
    fragment equityAbout on Equity {
        id
        equityId
        averageDailyVolume
        commonDescription
        commonName
        description
        fiftyTwoWeekHigh
        fiftyTwoWeekLow
        last
        lastClose
        lastOpen
        localTicker
        marketcap
        pricetoearnings
        volume
        country {
            id
            shortName
        }
        currency {
            id
            currencyCode
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
        exchange {
            id
            shortName
        }
        lastEarnings {
            id
            callDate
        }
        nextEarnings {
            id
            callDate
            callType
        }
        sector: gicsSector {
            id
            gicsCode
            name
        }
        subSector: gicsSubSector {
            id
            name
        }
    }
`;

export const equityFinancialFragment = gql`
    fragment equityFinancial on Financial {
        category
        displayUnits
        equityId
        format
        key
        ordering
        section
        subsection
        title
    }
`;

export const equityOhlcFragment = gql`
    fragment equityOhlc on EquityOhlc {
        __typename
        date
        open: adjOpen
        close: adjClose
        high: adjHigh
        low: adjLow
        volume: adjVolume
    }
`;

export const equityValuationFragment = gql`
    fragment equityValuation on EquityValuation {
        equityId
        format
        label
        metric
        nextTwelveMonths
        ordering
        section
        subsection
        trailingTwelveMonths
    }
`;
