import EventEmitter from 'events';
import autoBind from 'auto-bind';
import { get } from 'utils';
import { MediaPlayer } from 'utils/media';

export class AieraIntegration extends EventEmitter {
    constructor(options = {}) {
        super();
        autoBind(this);
        this.cache = {};
        this.options = options;
        window.addEventListener('message', this.onMessage, false);
        MediaPlayer.activeEvents.on('activeChange', this.onMediaPlayerChange);
    }

    onMediaPlayerChange() {
        if (this.mediaPlayer) {
            this.mediaPlayer.off('statusChange', this.onMediaPlayerStatusChange);
        }
        this.mediaPlayer = MediaPlayer.activePlayer;
        if (this.mediaPlayer) {
            this.mediaPlayer.on('statusChange', this.onMediaPlayerStatusChange);
        }
    }

    onMediaPlayerStatusChange() {
        this.emit('mediaStatus', this.mediaPlayer.status);
    }

    localEmit(name, args) {
        super.emit(name, ...args);
    }

    emit(type, ...args) {
        this.sendRequest('emit', { ignoreResponse: true, args: [type, ...args] });
    }

    isIFramed() {
        return window !== window.parent;
    }

    onMessage({ data }) {
        let parsed = {};
        try {
            parsed = JSON.parse(data) || {};
        } catch (e) {} // eslint-disable-line no-empty

        const { ns, type, args, request } = parsed;
        if (ns === 'aieraDesktop') {
            if (request && this[type]) {
                this[type](...args);
            } else {
                this.localEmit(type, args);
            }
        }
    }

    sendMessage(direction, type, ...args) {
        if (this.isIFramed()) {
            window.parent.postMessage(
                JSON.stringify({
                    ns: 'aieraDesktop',
                    request: direction === 'request',
                    response: direction === 'response',
                    type,
                    args
                }),
                // Since we don't know the domain loading the iframe, we have to
                // set this to '*'. This means we can never send sensitive data
                // because it can be sniffed by any js code running in any window
                // on the page. Generally, anything sensitive, such as apiKey, is
                // sent the other direction, from the parent to us, which is secured
                // so this is not a problem.
                '*'
            );
        }
    }

    sendRequest(request, { timeout = 1000, cache = true, sync = false, ignoreResponse = false, args = [] }) {
        const start = Date.now();
        if (cache && this.cache[request] !== undefined) {
            this.log(`${request} request returned from cache in ${Date.now() - start}ms`, this.cache[request]);
            return sync ? this.cache[request] : Promise.resolve(this.cache[request]);
        }

        if (sync) {
            return undefined;
        }

        if (ignoreResponse) {
            return this.sendMessage('request', request, ...args);
        }

        return new Promise((resolve, reject) => {
            this.sendMessage('request', request, ...args);
            const toHandle = setTimeout(() => reject(new Error(`Integration request timed out: ${request}`)), timeout);
            this.once(request, response => {
                clearTimeout(toHandle);
                this.cache[request] = response;
                this.log(`${request} request returned in ${Date.now() - start}ms`, response);
                resolve(response);
            });
        });
    }

    enabled({ timeout = 500, sync = false } = {}) {
        if (!this.isIFramed()) {
            return sync ? false : Promise.resolve(false);
        }
        return this.sendRequest('enabled', { timeout, sync }).catch(() => false);
    }

    getApiKey({ timeout = 1000, sync = false } = {}) {
        return this.sendRequest('apiKey', { timeout, sync });
    }

    getIntegrationInfo({ timeout = 1000, sync = false } = {}) {
        return this.sendRequest('integrationInfo', { timeout, sync });
    }

    addStyles(type, { timeout = 1000, sync = false } = {}) {
        return this.getIntegrationInfo({ timeout, sync }).then(info => {
            const { links = [], styles = [] } = get(info, `styles.${type}`, {});
            if (Array.isArray(links)) {
                links.forEach(link => {
                    const el = document.createElement('link');
                    el.setAttribute('rel', 'stylesheet');
                    el.setAttribute('href', link);
                    document.body.appendChild(el);
                });
            }

            if (Array.isArray(styles)) {
                styles.forEach(style => {
                    const el = document.createElement('style');
                    el.textContent = style;
                    document.body.appendChild(el);
                });
            }
        });
    }

    stopMedia() {
        if (this.mediaPlayer) {
            const { status } = this.mediaPlayer;
            if (['playing', 'listening', 'recording'].includes(status)) {
                this.mediaPlayer.stop();
            }
        } else {
            this.emit('error', 'No media player currently open');
        }
    }

    log(...args) {
        if (this.options.debug) {
            // eslint-disable-next-line no-console
            console.debug(Date.now(), 'AieraIntegrationChild', ...args);
        }
    }
}
