import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { mapPropsToOptions } from 'hoc/utils';
import { get } from 'utils';
import { resolveEquityFragment } from 'graphql/fragments/resolve';

export const getResolveQuery = alias => gql`
    query ${alias || 'withResolve'}($identifiers: [String]!) {
        resolve {
            equities(identifiers: $identifiers) {
                identifier
                equities {
                    ...resolveEquity
                }
            }
        }
    }
    ${resolveEquityFragment}
`;

export const withResolve = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withResolve';
    return graphql(getResolveQuery(alias), {
        props: ({ data }) => {
            return {
                resolveError: data.error,
                resolveLoading: !!data.loading,
                resolveRefresh: data.refetch,
                equities: get(data, 'resolve.equities', [])
            };
        },
        alias,
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-first',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};
