import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { BoxThUI } from './ui';

export class BoxTh extends PureComponent {
    static displayName = 'BoxThContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <BoxThUI {...rest} />;
    }
}

export const BoxThContainer = compose()(BoxTh);
