import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { TimeAgo } from 'components/TimeAgo';
import { Hint } from 'components/Hint';
import { STREAM_DISPLAY_MODES } from 'consts';
import { toDurationString, getHighlightColor, get } from 'utils';
import { styleSheet } from './stylesheet';

class TranscriptCard extends PureComponent {
    static propTypes = {
        bookmarkId: PropTypes.string,
        company: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        equityIcon: PropTypes.string,
        equityInitials: PropTypes.string,
        eventId: PropTypes.string.isRequired,
        eventType: PropTypes.string,
        exchangeName: PropTypes.string,
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        itemId: PropTypes.string,
        matchId: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        score: PropTypes.number,
        sentiment: PropTypes.string,
        speaker: PropTypes.objectOf(PropTypes.any),
        startTimestamp: PropTypes.instanceOf(XDate).isRequired,
        streamId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string,
        to: PropTypes.string,
        transcript: PropTypes.string.isRequired
    };

    static defaultProps = {
        bookmarkId: undefined,
        company: undefined,
        eventType: undefined,
        equityIcon: undefined,
        equityInitials: undefined,
        exchangeName: null,
        grouped: [],
        highlightColor: undefined,
        itemId: undefined,
        onClick: null,
        passedStyles: {},
        score: undefined,
        sentiment: null,
        speaker: null,
        ticker: null,
        title: null,
        to: null
    };

    renderGroupedRows() {
        const { styles, grouped, onClick } = this.props;
        if (!grouped.length) {
            return null;
        }

        return grouped.map((g, index) => {
            const { itemId, transcript, startMs, startTimestamp, to } = g;
            const seconds = startMs / 1000;
            const time = toDurationString(seconds);
            return (
                <InternalLink styles={styles.groupedContent} to={to} onClick={e => onClick(e, itemId)} key={itemId}>
                    <Div styles={styles.groupedContentDetails}>
                        <Text size={0} styles={styles.groupedContentDate}>
                            <TimeAgo date={startTimestamp} />
                        </Text>
                        <Text size={0} styles={styles.groupedContentTime}>{`@ ${time}`}</Text>
                        <Div styles={styles.spacer} />
                        <Text size={0} styles={styles.groupedContentIndex}>{`${index + 1} of ${grouped.length}`}</Text>
                    </Div>
                    <RawHTML html={transcript} />
                </InternalLink>
            );
        });
    }

    render() {
        const {
            bookmarkId,
            company,
            displayMode,
            equityIcon,
            equityInitials,
            eventType,
            eventId,
            exchangeName,
            highlightColor,
            itemId,
            matchId,
            onClick,
            passedStyles,
            score,
            sentiment,
            speaker,
            startTimestamp,
            streamId,
            styles,
            ticker,
            title,
            to,
            transcript
        } = this.props;
        const isCompact = displayMode === STREAM_DISPLAY_MODES.compact;
        const isDefault = displayMode === STREAM_DISPLAY_MODES.default;
        const contentStyle = {
            ...styles.content,
            backgroundColor: getHighlightColor(highlightColor)?.color
        };
        const speakerName = get(speaker, 'name');
        return (
            <Card
                containerStyles={isCompact ? styles.containerCompact : undefined}
                name="transcript-card"
                onClick={onClick}
                sentiment={sentiment}
                styles={isCompact ? { ...styles.compact, ...passedStyles } : passedStyles}
                subContentRows={isDefault ? this.renderGroupedRows() : undefined}
                to={to}
            >
                {({ isHovering }) => (
                    <Fragment>
                        {isHovering && (
                            <StreamActionMenu
                                bookmarkId={bookmarkId}
                                eventId={eventId}
                                highlight={transcript}
                                matchId={matchId}
                                score={score}
                                streamId={streamId}
                                styles={styles.actionMenu}
                                targetId={itemId}
                                targetType="transcript"
                                url={to}
                            />
                        )}
                        {isCompact ? (
                            <Div styles={styles.compactHeader}>
                                <Hint width={240} growUp yOffset={-40} text={company} description={title}>
                                    <Text weight="medium" size={3}>
                                        {ticker}
                                    </Text>
                                    <Text styles={styles.compactType} capitalize>
                                        {eventType.replace(/_/g, ' ')}
                                    </Text>
                                </Hint>
                                <Text size={0} className="subtext" styles={styles.compactTime}>
                                    {typeof startTimestamp.getMonth === 'function' ? (
                                        <TimeAgo date={startTimestamp} />
                                    ) : (
                                        startTimestamp
                                    )}
                                </Text>
                            </Div>
                        ) : (
                            <CardHeader
                                context={isCompact ? undefined : ticker}
                                secondaryContext={isCompact ? undefined : exchangeName}
                                iconUrl={isCompact ? undefined : equityIcon}
                                iconText={isCompact ? undefined : equityInitials}
                                subtext={startTimestamp}
                                subtitle={isCompact ? eventType : title}
                                title={isCompact ? ticker : company}
                                truncateTitle
                            />
                        )}
                        {!isCompact && speakerName && (
                            <Div styles={styles.speaker}>
                                <Icon type="chat" color="black" />
                                <Text weight="medium">{speakerName}</Text>
                            </Div>
                        )}
                        <Div styles={contentStyle}>
                            <RawHTML html={transcript} />
                        </Div>
                    </Fragment>
                )}
            </Card>
        );
    }
}

export const TranscriptCardUI = compose(withStyleSheet(styleSheet))(TranscriptCard);
