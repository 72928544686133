const TAB_REMOVE = 'TAB_REMOVE';
const TAB_REPLACE = 'TAB_REPLACE';
const TAB_MINIMIZE = 'TAB_MINIMIZE';
const TAB_MAXIMIZE = 'TAB_MAXIMIZE';
const TAB_TITLE_STORE = 'TAB_TITLE_STORE';

export const types = {
    TAB_MINIMIZE,
    TAB_MAXIMIZE,
    TAB_REMOVE,
    TAB_REPLACE,
    TAB_TITLE_STORE
};

/*
 *
 * These actions are used for add and removing
 * tabs from the floating tab architecture
 *
 */

export function tabMaximize(id) {
    return {
        type: TAB_MAXIMIZE,
        payload: {
            id
        }
    };
}

export function tabMinimize(id) {
    return {
        type: TAB_MINIMIZE,
        payload: {
            id
        }
    };
}

export function tabRemove(id) {
    return {
        type: TAB_REMOVE,
        payload: {
            id
        }
    };
}

export function tabReplace(originalTabId, newTabId) {
    return {
        type: TAB_REPLACE,
        payload: {
            originalTabId,
            newTabId
        }
    };
}

export function tabTitleStore(tabId, title) {
    return {
        type: TAB_TITLE_STORE,
        payload: {
            tabId,
            title
        }
    };
}
