import { keyframes } from '@emotion/react';

const fader = keyframes`
    0%, 50% { opacity: 0 }
    100% { opacity: 1 }
`;

const topRightTextContainer = {
    flex: 1,
    marginTop: 4,
    position: 'relative',
    textAlign: 'right'
};

export const styleSheet = theme => {
    const tagsContainer = {
        alignItems: 'center',
        borderRadius: 6,
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        transition: 'all 0.2s ease-in-out',
        paddingLeft: 2,
        paddingBottom: 5,
        paddingRight: 6
    };

    return {
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        tagsContainer: {
            ...tagsContainer,
            backgroundColor: theme.colors.white03,
            '.tagged-icon': {
                margin: '6px 2px 0 8px'
            },
            ':hover': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray05}`,
                backgroundColor: theme.colors.white02
            }
        },
        tagsContainerValues: {
            ...tagsContainer,
            backgroundColor: theme.colors.white01,
            boxShadow: `inset 0 0 0 1px ${theme.colors.gray05}`,
            '.tagged-icon': {
                margin: '6px 2px 0 8px',
                'svg g': {
                    fill: theme.colors.orange01
                }
            },
            ':hover': {
                backgroundColor: theme.colors.white03
            }
        },
        tagsContainerFocused: {
            ...tagsContainer,
            backgroundColor: theme.colors.white01,
            boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`,
            '.tagged-icon': {
                margin: '6px 2px 0 8px',
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        tag: {
            alignItems: 'center',
            backgroundColor: theme.colors.gray05,
            borderRadius: 15,
            color: theme.colors.black01,
            cursor: 'pointer',
            display: 'flex',
            height: 30,
            justifyContent: 'space-between',
            marginLeft: 4,
            marginTop: 5,
            overflowY: 'auto',
            padding: '0 4px 0 10px',
            ':hover': {
                backgroundColor: theme.colors.gray07
            },
            ':active': {
                backgroundColor: theme.colors.gray04
            },
            p: {
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }
        },
        tagPlus: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.colors.gray05,
            borderRadius: 2,
            color: theme.colors.black01,
            height: 30,
            padding: '0 4px',
            cursor: 'pointer',
            marginLeft: 4,
            marginTop: 5,
            ':hover': {
                backgroundColor: theme.colors.gray07
            },
            ':active': {
                backgroundColor: theme.colors.gray04
            },
            p: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        },
        tagInput: {
            flex: 1,
            minWidth: 105,
            marginTop: 5,
            input: {
                boxShadow: 'unset',
                paddingLeft: 8,
                backgroundColor: 'transparent',
                height: 30,
                ':focus': {
                    backgroundColor: 'transparent',
                    borderRadius: 6,
                    boxShadow: 'unset'
                },
                ':hover': {
                    backgroundColor: 'transparent',
                    borderRadius: 6,
                    boxShadow: 'unset'
                }
            },
            ':hover': {
                borderRadius: 6,
                boxShadow: 'unset'
            }
        },
        removeTagIcon: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginLeft: 2,
            svg: {
                opacity: 0.8,
                height: 8
            }
        },
        labelContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        label: {
            ...theme.content.formLabel
        },
        textError: {
            ...theme.content.formLabel,
            color: theme.colors.inputError
        },
        topRightText: {
            color: theme.colors.gray08,
            letterSpacing: 1,
            position: 'absolute',
            right: 0
        },
        topRightTextContainer: {
            ...topRightTextContainer
        },
        topRightTextContainerToggle: {
            ...topRightTextContainer,
            '.topRightText:nth-child(1)': {
                WebkitAnimation: `${fader} 5s infinite both alternate-reverse`,
                animation: `${fader} 5s infinite both alternate-reverse`
            },
            '.topRightText:nth-child(2)': {
                WebkitAnimation: `${fader} 5s infinite both alternate`,
                animation: `${fader} 5s infinite both alternate`
            }
        }
    };
};
