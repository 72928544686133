import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { get } from 'utils';
import { withMemo } from 'hoc/utils';
import { TYPES, OPERATORS } from 'consts/filters';
import { formatMatchesByType, mapFiltersToRules } from 'utils/streams';
import {
    companyMatchFragment,
    dashboardFragment,
    contentMatchFragment,
    eventMatchFragment,
    eventGroupMatchFragment,
    streamFragment,
    transcriptMatchFragment,
    dashboardMatchFragment
} from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withSearchDash($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        ...dashboard
                    }
                }
                ${dashboardFragment}
            `,
            {
                props: ({ data }) => ({
                    monitorCapabilities: get(data, 'dashboards[0].capabilities'),
                    monitorName: get(data, 'dashboards[0].name'),
                    loading: get(data, 'loading')
                }),
                skip: ({ dashboardId }) => !dashboardId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withMemo({
            formatMatchesByType
        }),
        graphql(
            gql`
                query withGlobalSearch(
                    $streamTypes: [StreamType]!
                    $rules: [StreamRuleInput]
                    $size: Int = 10
                    $offset: Int = 0
                    $sort: String
                ) {
                    streams(filter: { previewStreamTypes: $streamTypes }) {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: { rules: $rules }, sort: $sort) {
                            total
                            results {
                                ...companyMatch
                                ...eventMatch
                                ...eventGroupMatch
                                ...contentMatch
                                ...transcriptMatch
                                ...dashboardMatch
                            }
                        }
                    }
                }
                ${companyMatchFragment}
                ${eventGroupMatchFragment}
                ${contentMatchFragment}
                ${eventMatchFragment}
                ${streamFragment}
                ${transcriptMatchFragment}
                ${dashboardMatchFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                    let queryData = data;

                    // Network Status for setting variables is 2
                    if (data.networkStatus === 2) {
                        queryData = undefined;
                    }

                    return {
                        fetchMore: get(queryData, 'fetchMore'),
                        loadingResults: get(data, 'loading'),
                        searchResults: formatMatches(get(queryData, 'streams[0].matches.results', [])),
                        totalResultCount: get(queryData, 'streams[0].matches.total', 0)
                    };
                },
                options: ({ searchTerm, selectedResultType }) => {
                    const filters = [];
                    const dataMap = {
                        companies: {
                            streamType: 'companies'
                        },
                        conferences: {
                            streamType: 'event_groups'
                        },
                        events: {
                            streamType: 'events'
                        },
                        filing: {
                            streamType: 'content',
                            contentType: 'filing'
                        },
                        monitors: {
                            streamType: 'dashboards'
                        },
                        news: {
                            streamType: 'content',
                            contentType: 'news'
                        },
                        spotlight: {
                            streamType: 'content',
                            contentType: 'spotlight'
                        },
                        transcripts: {
                            streamType: 'content',
                            contentType: 'transcript'
                        }
                    };
                    const contentType = get(dataMap, `${selectedResultType}.contentType`);
                    const streamType = get(dataMap, `${selectedResultType}.streamType`, 'dashboards');
                    const sortKey = streamType === 'event_groups' ? 'date desc' : undefined;

                    if (searchTerm) {
                        filters.push({
                            type: TYPES.searchTerm,
                            operator: OPERATORS.is,
                            value: [searchTerm]
                        });
                    }

                    if (contentType) {
                        filters.push({
                            type: TYPES.type,
                            operator: OPERATORS.is,
                            value: contentType
                        });
                    }

                    return {
                        fetchPolicy: 'no-cache',
                        variables: {
                            streamTypes: [streamType],
                            rules: mapFiltersToRules(filters),
                            sort: sortKey
                        },
                        context: {
                            debounceKey: `withGlobalSearch${selectedResultType}`,
                            debounceTimeout: searchTerm && searchTerm.length > 0 ? 300 : 0
                        }
                    };
                },
                skip: ({ selectedResultType }) => !selectedResultType
            }
        ),
        withPropsOnChange(['fetchMore'], ({ fetchMore }) => ({
            loadMoreResults: offset => {
                return fetchMore({
                    variables: { offset },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        const prevStream = prev.streams[0];
                        const newStream = fetchMoreResult.streams[0];
                        const newMatches = newStream.matches;
                        return {
                            ...prev,
                            streams: [
                                {
                                    ...prevStream,
                                    matches: {
                                        ...prevStream.matches,
                                        results: [...prevStream.matches.results, ...newMatches.results]
                                    }
                                }
                            ]
                        };
                    }
                }).then(resp => ({
                    newResults: formatMatchesByType(get(resp, 'data.streams[0].matches.results', []))
                }));
            }
        }))
    );
