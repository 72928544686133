import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Notice } from 'components/Notice';
import { Heading } from 'components/Heading';

import { styleSheet } from './stylesheet';

class EmptyContent extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
        noIcon: PropTypes.bool,
        richContent: PropTypes.bool
    };

    static defaultProps = {
        noIcon: null,
        richContent: false,
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, heading, children, noIcon, richContent } = this.props;
        const headingStyles = noIcon ? styles.headingNoIcon : styles.heading;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Heading styles={headingStyles}>{heading}</Heading>
                <Notice type="info" noIcon={noIcon} richContent={richContent}>
                    {children}
                </Notice>
            </Div>
        );
    }
}

export const EmptyContentUI = compose(withStyleSheet(styleSheet))(EmptyContent);
