import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { FormRow } from 'components/FormRow';
import { Heading } from 'components/Heading';
import { Hint } from 'components/Hint';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { PhoneNumberInput } from 'components/PhoneNumberInput';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { Textarea } from 'components/Textarea';
import { PERMISSIONS } from 'consts';
import { hasPermission } from 'utils';
import { styleSheet } from './stylesheet';
import {
    AIERA_INTERVENTION_END_TIME,
    AIERA_INTERVENTION_START_TIME,
    PARTICIPATION_TYPES,
    TROUBLESHOOTING_OPTIONS,
    TROUBLESHOOTING_OPTIONS_WEBCAST,
    TROUBLESHOOTING_TYPES
} from '../consts';

class Troubleshooting extends PureComponent {
    static propTypes = {
        confirmPermission: PropTypes.bool.isRequired,
        disableConfirmPermission: PropTypes.bool.isRequired,
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        isInterventionEnabled: PropTypes.bool.isRequired,
        isWebcast: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onFailure: PropTypes.string,
        onFailureInstructions: PropTypes.string,
        onFailurePhoneNumber: PropTypes.string,
        participationType: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        useOnConnectDialNumber: PropTypes.bool.isRequired,
        user: PropTypes.objectOf(PropTypes.any),
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        onFailure: undefined,
        onFailureInstructions: '',
        onFailurePhoneNumber: '',
        participationType: undefined,
        passedStyles: {},
        user: undefined
    };

    renderOptions() {
        const { isInterventionEnabled, isWebcast, onFailure, styles, user } = this.props;
        const options =
            isWebcast && hasPermission(user, PERMISSIONS.customEventsIntervention)
                ? TROUBLESHOOTING_OPTIONS_WEBCAST
                : TROUBLESHOOTING_OPTIONS;
        return options.map(({ description, label, value }) => {
            const disabled = value === TROUBLESHOOTING_TYPES.aieraIntervention.value && !isInterventionEnabled;
            let Wrapper = Div;
            let wrapperProps = null;
            if (disabled) {
                Wrapper = Hint;
                wrapperProps = {
                    text: [
                        <Span key="troubleshooting-intervention-tooltip" size={1}>
                            Only available Monday - Friday, {AIERA_INTERVENTION_START_TIME} -&nbsp;
                            {AIERA_INTERVENTION_END_TIME} EST.
                            <br />
                            Change the schedule date & time to enable this option.
                        </Span>
                    ],
                    xOffset: -15,
                    yOffset: -110
                };
            }
            return (
                <Option disabled={disabled} key={value} type="radio" value={value}>
                    <Wrapper {...wrapperProps}>
                        <Text size={3} weight={onFailure === value ? 'medium' : 'normal'}>
                            {label}
                        </Text>
                        <Text size={1} styles={styles.optionDescription}>
                            {description}
                        </Text>
                    </Wrapper>
                </Option>
            );
        });
    }

    render() {
        const {
            confirmPermission,
            disableConfirmPermission,
            errors,
            onChange,
            onFailure,
            onFailureInstructions,
            onFailurePhoneNumber,
            participationType,
            passedStyles,
            styles,
            useOnConnectDialNumber,
            visible
        } = this.props;
        if (!visible) return null;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Heading size={2} styles={styles.heading}>
                    Troubleshooting
                </Heading>
                <FormRow
                    description="If we can't connect to your event, we have some options"
                    label="What should we do if we can't connect?"
                    styles={styles.formRow}
                >
                    <MultiSelect name="onFailure" onChange={onChange} required selected={[onFailure]}>
                        {this.renderOptions()}
                    </MultiSelect>
                </FormRow>
                {[TROUBLESHOOTING_TYPES.call.value, TROUBLESHOOTING_TYPES.sms.value].includes(onFailure) && (
                    <FormRow
                        description="What is the best number to reach you?"
                        label="Phone number*"
                        styles={styles.formRow}
                    >
                        <PhoneNumberInput
                            autoComplete="off"
                            defaultCountry="US"
                            error={errors.onFailurePhoneNumber}
                            localStorageKey="aiera:privateRecording:onFailurePhoneNumber"
                            name="onFailurePhoneNumber"
                            onChange={onChange}
                            placeholder="(888)-123-4567"
                            value={onFailurePhoneNumber}
                        />
                        {participationType === PARTICIPATION_TYPES.participating.value && (
                            <Checkbox
                                checked={useOnConnectDialNumber}
                                name="useOnConnectDialNumber"
                                onChange={onChange}
                                size={3}
                                styles={styles.checkbox}
                            >
                                Same as personal line (above)
                            </Checkbox>
                        )}
                    </FormRow>
                )}
                {onFailure === TROUBLESHOOTING_TYPES.aieraIntervention.value && (
                    <FormRow
                        description="Any information our agents might need to help connect your call"
                        label="Instructions"
                        styles={styles.formRow}
                    >
                        <Fragment>
                            <Textarea
                                name="onFailureInstructions"
                                onChange={onChange}
                                placeholder="Passwords or other useful information"
                                value={onFailureInstructions}
                            />
                            <Checkbox
                                checked={confirmPermission}
                                disabled={disableConfirmPermission}
                                name="confirmPermission"
                                onChange={onChange}
                                size={2}
                                styles={
                                    errors.confirmPermission
                                        ? { ...styles.checkbox02, ...styles.checkbox02Error }
                                        : styles.checkbox02
                                }
                            >
                                Confirm that Aiera agents have permission to attempt to resolve any connection issues
                                for this event
                            </Checkbox>
                        </Fragment>
                    </FormRow>
                )}
            </Div>
        );
    }
}

export const TroubleshootingUI = compose(withStyleSheet(styleSheet))(Troubleshooting);
