import EventEmitter from 'events';

export class Scroller extends EventEmitter {
    constructor(options = {}) {
        super();
        this.onScroll = this.onScroll.bind(this);
        this.scrollContainer = null;
        this.wasAtBottom = false;
        this.observing = false;
        this.options = {
            autoScroll: !!options.autoScroll,
            scrollEndThreshold: options.scrollEndThreshold || 5
        };

        // This defines the observer for auto-scrolling on new content,
        // but you have to call this.observer.observe(node) for it to
        // actually do anything.
        this.observer = new MutationObserver(() => {
            if (this.wasAtBottom) {
                this.scrollToBottom();
            }
        });
    }

    setup() {
        if (this.scrollContainer) {
            this.scrollContainer.addEventListener('scroll', this.onScroll);
            if (this.options.autoScroll) {
                this.startAutoscroll();
            }
        }
    }

    cleanup(full = true) {
        if (this.scrollContainer) {
            this.stopAutoScroll();
            this.scrollContainer.removeEventListener('scroll', this.onScroll);
        }
        if (full) {
            this.removeAllListeners();
        }
    }

    onScroll() {
        this.wasAtBottom = this.isAtBottom();
        this.wasAtTop = this.isAtTop();
        this.emit('scroll');
        if (this.wasAtBottom) {
            this.emit('scrollEnd');
            this.emit('scrollBottom');
        }
        if (this.wasAtTop) {
            this.emit('scrollTop');
        }
    }

    setScrollContainer(scrollContainer) {
        // Partial cleanup, don't remove listeners on this scroller,
        // just on the scrollContainer element.
        this.cleanup(false);
        this.scrollContainer = scrollContainer;
        this.setup();
    }

    get scrollHeight() {
        return this.scrollContainer ? this.scrollContainer.scrollHeight : 0;
    }

    get scrollTop() {
        return this.scrollContainer ? this.scrollContainer.scrollTop : 0;
    }

    get clientHeight() {
        return this.scrollContainer ? this.scrollContainer.clientHeight : 0;
    }

    scrollTo(opts) {
        if (this.scrollContainer) {
            this.scrollContainer.scrollTo(opts);
            this.onScroll();
        }
    }

    scrollToTop(opts = {}) {
        this.scrollTo({ top: 0, ...opts });
    }

    scrollToBottom(opts = {}) {
        this.scrollTo({ top: this.scrollHeight, ...opts });
    }

    isAtBottom() {
        return Math.ceil(this.scrollTop + this.clientHeight + this.options.scrollEndThreshold) >= this.scrollHeight;
    }

    isAtTop() {
        return this.scrollTop < this.options.scrollEndThreshold;
    }

    startAutoScroll() {
        if (!this.observing && this.scrollContainer) {
            this.observer.observe(this.scrollContainer, { childList: true, subtree: true, characterData: true });
            this.observing = true;
        }
    }

    stopAutoScroll() {
        if (this.observing) {
            this.observer.disconnect();
            this.observing = false;
        }
    }
}
