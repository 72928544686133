import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Droppable } from 'lib/react-beautiful-dnd';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Logo } from 'components/Logo';
import { Icon } from 'components/Icon';
import { Hint } from 'components/Hint';
import { SidebarClosed } from 'components/SidebarClosed';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { routes } from 'routes';
import { isInIframe } from 'utils';
import { Section } from './Section';
import { DashboardMenuOption } from './DashboardMenuOption';
import { styleSheet } from './stylesheet';

class Sidebar extends PureComponent {
    static propTypes = {
        collapsedSections: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashboardSections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        defaultDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        isClosed: PropTypes.bool,
        onDragDashboard: PropTypes.func.isRequired,
        onDragSection: PropTypes.func.isRequired,
        openGallery: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        recommendedDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        showNewEventModal: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleSection: PropTypes.func.isRequired,
        toggleSidebar: PropTypes.func.isRequired
    };

    static defaultProps = {
        isClosed: false,
        passedStyles: {}
    };

    renderHeaderButton({ label, onClick, icon }) {
        const { styles, theme } = this.props;
        return (
            <Hint text={label} onClick={onClick} styles={styles.headerButton} data-tname={`sidebar-header-${label}`}>
                <Icon color={theme.colors.gray04} type={icon} />
            </Hint>
        );
    }

    renderHeader() {
        const { styles, toggleSidebar } = this.props;

        if (isInIframe()) {
            return <Div styles={{ marginTop: 8 }} />;
        }

        return (
            <Div styles={styles.header}>
                <Logo small link={routes.root} />
                <Div styles={styles.headerButtons}>
                    {this.renderHeaderButton({ label: 'Collapse Sidebar', onClick: toggleSidebar, icon: 'chevron03' })}
                </Div>
            </Div>
        );
    }

    renderBrowseGalleryButton() {
        const { styles, theme, openGallery } = this.props;
        return (
            <Div styles={styles.browseGallery} onClick={openGallery} data-tname="sidebar-browse-gallery">
                <Icon type="gallery" color={theme.colors.gray04} />
                <Text size={1}>Browse Gallery</Text>
            </Div>
        );
    }

    renderDashboardsMenu() {
        const {
            styles,
            onDragSection,
            onDragDashboard,
            recommendedDashboards,
            defaultDashboards,
            dashboardSections,
            collapsedSections,
            toggleSection
        } = this.props;
        const hasDashboards = dashboardSections.some(section => section.dashboards.length > 0);

        return (
            <Div styles={styles.menuContainer}>
                <Div styles={styles.menuOptions}>
                    {(defaultDashboards || []).map((dashboard, index) =>
                        dashboard.permissionProps ? (
                            <WithPermission key={dashboard.id} {...dashboard.permissionProps}>
                                <DashboardMenuOption {...dashboard} index={index} sectionId="default" />
                            </WithPermission>
                        ) : (
                            <DashboardMenuOption {...dashboard} index={index} key={dashboard.id} sectionId="default" />
                        )
                    )}
                    <Droppable droppableId="sidebarSections" type="sidebarSections">
                        {provided => (
                            <Div styles={styles.sections} ref={provided.innerRef} {...provided.droppableProps}>
                                {dashboardSections.map(({ sectionId, dashboards, name }, index) => (
                                    <Section
                                        toggle={toggleSection}
                                        hide={
                                            name.toLowerCase() === 'default' &&
                                            dashboards.length === 0 &&
                                            (hasDashboards || dashboardSections.length > 1)
                                        }
                                        collapsed={collapsedSections.includes(sectionId)}
                                        showNoDashMessage={dashboards.length === 0}
                                        onDragDashboard={onDragDashboard}
                                        onDragSection={onDragSection}
                                        key={sectionId}
                                        name={name}
                                        id={sectionId}
                                        dashboards={dashboards}
                                        index={index}
                                    />
                                ))}
                                <WithPermission
                                    key="sectionPermissions"
                                    permissions={[PERMISSIONS.unlockedCreateDashboard]}
                                >
                                    {({ restricted, isLoading }) => (
                                        <Fragment>
                                            {recommendedDashboards.length > 0 && (
                                                <Section
                                                    styles={
                                                        restricted && !hasDashboards ? { marginTop: 20 } : undefined
                                                    }
                                                    disableDraggingDashboards={restricted || isLoading}
                                                    max={restricted ? 5 : 3}
                                                    toggle={toggleSection}
                                                    collapsed={collapsedSections.includes('recommended')}
                                                    onDragDashboard={onDragDashboard}
                                                    onDragSection={onDragSection}
                                                    name="recommended"
                                                    id="recommended"
                                                    dashboards={recommendedDashboards}
                                                    index={dashboardSections.length}
                                                />
                                            )}
                                            {!collapsedSections.includes('recommended') &&
                                                recommendedDashboards.length > 1 &&
                                                this.renderBrowseGalleryButton()}
                                            {provided.placeholder}
                                        </Fragment>
                                    )}
                                </WithPermission>
                            </Div>
                        )}
                    </Droppable>
                </Div>
            </Div>
        );
    }

    render() {
        const {
            isClosed,
            styles,
            passedStyles,
            toggleSidebar,
            openModal,
            recommendedDashboards,
            defaultDashboards,
            dashboardSections,
            showNewEventModal,
            theme
        } = this.props;

        return (
            <Fragment>
                {isClosed ? (
                    <SidebarClosed
                        dashboardSections={dashboardSections}
                        defaultDashboards={defaultDashboards}
                        openModal={openModal}
                        recommendedDashboards={recommendedDashboards}
                        showNewEventModal={showNewEventModal}
                        toggleSidebar={toggleSidebar}
                    />
                ) : (
                    <Div styles={{ ...styles.container, ...passedStyles }}>
                        {this.renderHeader()}
                        {this.renderDashboardsMenu()}
                        <WithPermission permissions={[PERMISSIONS.featureCreateEvent]}>
                            <WithPermission permissions={[PERMISSIONS.unlockedCreatePrivateRecording]}>
                                {({ restricted, isLoading }) => (
                                    <ActionButton
                                        styles={restricted || isLoading ? styles.newButtonLocked : styles.newButton}
                                        onClick={showNewEventModal}
                                        name="new-event-from-dash"
                                    >
                                        <Icon
                                            type={restricted || isLoading ? 'lock02' : 'newEvent'}
                                            color={
                                                restricted || isLoading ? theme.colors.black01 : theme.colors.white01
                                            }
                                        />
                                        <Text size={1}>Schedule Recording</Text>
                                    </ActionButton>
                                )}
                            </WithPermission>
                        </WithPermission>
                        <WithPermission permissions={[PERMISSIONS.unlockedCreateDashboard]}>
                            {({ restricted, isLoading }) =>
                                restricted || isLoading ? (
                                    <ActionButton
                                        styles={styles.newButtonLocked}
                                        onClick={() => (isLoading ? {} : openModal({ value: 'dashboard' }))}
                                        name="new-monitor-locked-from-sidebar"
                                    >
                                        <Icon type="lock02" color={theme.colors.black01} />
                                        <Text size={1}>Create New Monitor</Text>
                                    </ActionButton>
                                ) : (
                                    <ActionButton
                                        styles={styles.newButton}
                                        onClick={() => openModal({ value: 'dashboard' })}
                                        name="new-monitor-from-sidebar"
                                    >
                                        <Icon type="newDashboard" color={theme.colors.white01} />
                                        <Text size={1}>Create New Monitor</Text>
                                    </ActionButton>
                                )
                            }
                        </WithPermission>
                    </Div>
                )}
            </Fragment>
        );
    }
}

export const SidebarUI = compose(withStyleSheet(styleSheet))(Sidebar);
