import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { toDateTimeString } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class PartialTranscript extends PureComponent {
    static propTypes = {
        showTimestamps: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        partialTranscript: PropTypes.string,
        partialTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        passedStyles: {},
        partialTranscript: null,
        partialTimestamp: null
    };

    render() {
        const { passedStyles, partialTranscript, partialTimestamp, showTimestamps } = this.props;
        return (
            <Div styles={{ ...passedStyles.transcriptLine }} key="partialTranscript">
                {showTimestamps && (
                    <Div styles={passedStyles.timeStampContainer}>
                        <Text size={1} styles={passedStyles.timeStamp}>
                            {toDateTimeString(partialTimestamp, false, false, 'h:mm:ssTT')}
                        </Text>
                    </Div>
                )}
                <Text size={3} styles={passedStyles.transcriptTextContainer} span>
                    <Text
                        key="partialTranscriptText" // eslint-disable-line react/no-array-index-key
                        span
                        size={3}
                        styles={{ ...passedStyles.transcriptText, ...passedStyles.partialTranscript }}
                    >
                        {partialTranscript}
                    </Text>
                </Text>
            </Div>
        );
    }
}

export const PartialTranscriptUI = compose(withStyleSheet(styleSheet))(PartialTranscript);
