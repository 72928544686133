import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation BulkUpload($files: [FileInput]!) {
                    bulkUpload(files: $files) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        bulkUpload: ({ files }) =>
                            mutate({
                                variables: { files }
                            }).catch(error => {
                                setStatusBanner(`Error uploading documents: ${error}`, 'error', 'circleX');
                            })
                    };
                }
            }
        )
    );
