import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { BookmarkCard } from 'components/BookmarkCard';
import { Div } from 'components/Div';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Hint } from 'components/Hint';
import { Option } from 'components/Option';
import { Stream } from 'components/Stream';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class BookmarkStream extends PureComponent {
    static propTypes = {
        bookmarks: PropTypes.arrayOf(PropTypes.object),
        currentUserId: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        loadMoreMatches: PropTypes.func,
        onScopeChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        scopeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedScope: PropTypes.string,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        updatingScope: PropTypes.bool.isRequired
    };

    static defaultProps = {
        bookmarks: undefined,
        currentUserId: undefined,
        loadMoreMatches: undefined,
        passedStyles: {},
        selectedScope: undefined,
        streamId: undefined,
        streamProps: undefined
    };

    constructor(props) {
        super(props);

        this.renderResultsHeader = this.renderResultsHeader.bind(this);
    }

    renderResultsHeader() {
        const { loading, onScopeChange, scopeOptions, selectedScope, streamProps, styles, updatingScope } = this.props;
        if (get(streamProps, 'headerDisabled') || loading) return null;
        return (
            <Div styles={styles.optionsContainer}>
                {scopeOptions.map(({ hint, label, value }) => (
                    <Hint
                        key={value}
                        onClick={() => onScopeChange(value)}
                        styles={styles.hint}
                        text={hint}
                        width={200}
                        yOffset={0}
                    >
                        <Option
                            disabled={updatingScope}
                            key={value}
                            selected={selectedScope === value}
                            styles={styles.option}
                            type="radio"
                            value={value}
                        >
                            <Text size={1}>{label}</Text>
                        </Option>
                    </Hint>
                ))}
            </Div>
        );
    }

    render() {
        const {
            bookmarks,
            currentUserId,
            displayMode,
            loading,
            loadMoreMatches,
            passedStyles,
            streamId,
            streamProps
        } = this.props;
        return (
            <Stream
                {...streamProps}
                emptyText="No highlights yet..."
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                offset={bookmarks.length}
                renderResultsHeader={this.renderResultsHeader}
                streamId={streamId}
                styles={passedStyles}
            >
                {bookmarks.map(
                    ({
                        id,
                        bookmarkUrlType,
                        collapsed,
                        created,
                        creatorName,
                        creatorId,
                        date,
                        equity,
                        event,
                        highlight,
                        highlightColor,
                        matchId,
                        note,
                        shared,
                        source,
                        tags,
                        target,
                        targetId,
                        targetType,
                        transcript,
                        title,
                        url,
                        userId
                    }) => (
                        <ErrorBoundary key={`bookmark-card-error-boundary-${id}`}>
                            <BookmarkCard
                                bookmarkUrlType={bookmarkUrlType}
                                collapsed={collapsed}
                                created={created}
                                creatorName={creatorName}
                                creatorId={creatorId}
                                date={date}
                                displayMode={displayMode}
                                equity={equity}
                                eventId={get(event, 'id', get(transcript, 'event.id'))}
                                highlight={highlight}
                                highlightColor={highlightColor}
                                id={id}
                                isCreator={currentUserId === userId}
                                matchId={matchId}
                                note={note}
                                shared={shared}
                                source={source}
                                streamId={streamId}
                                tags={tags}
                                target={target}
                                targetId={targetId}
                                targetType={targetType}
                                title={title}
                                url={url}
                            />
                        </ErrorBoundary>
                    )
                )}
            </Stream>
        );
    }
}

export const BookmarkStreamUI = compose(withStyleSheet(styleSheet))(BookmarkStream);
