import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class Attachments extends PureComponent {
    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        openAttachment: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        setPopover: PropTypes.func.isRequired,
        showAttachments: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleAttachments: PropTypes.func.isRequired
    };

    static defaultProps = {
        attachments: [],
        passedStyles: {}
    };

    render() {
        const {
            toggleAttachments,
            showAttachments,
            styles,
            passedStyles,
            attachments,
            openAttachment,
            theme,
            setPopover
        } = this.props;
        if (!attachments || attachments.length === 0) {
            return null;
        }

        return (
            <Div styles={styles.attachmentsRow} className="print-hide">
                <Div styles={styles.attachmentsHeader} onClick={toggleAttachments}>
                    <Icon
                        type="chevron02"
                        color="black"
                        styles={
                            showAttachments
                                ? { transform: 'rotate(90deg)', transition: 'all 0.2s ease-in-out' }
                                : { transition: 'all 0.2s ease-in-out' }
                        }
                    />
                    <Div styles={styles.attachmentsHeaderText}>
                        <Text span size={3} weight="medium" styles={styles.attachmentsHeaderLabel}>
                            Event Attachments
                        </Text>
                        <Text styles={styles.attachmentsHeaderTitle} span size={3}>
                            Slides and/or Press Releases
                        </Text>
                    </Div>
                </Div>
                {showAttachments && (
                    <Div styles={{ ...styles.container, ...passedStyles }}>
                        <Div styles={styles.attachments}>
                            {attachments
                                .filter(({ archivedUrl }) => !!archivedUrl)
                                .map((attachment, idx) => {
                                    const { url: l, title, archivedUrl } = attachment;
                                    const filename = (title || l).substring((title || l).lastIndexOf('/') + 1);
                                    return (
                                        <MediaQuery maxWidth={theme.breakpoints.external.mobile} key={filename}>
                                            {m =>
                                                m ? (
                                                    <ExternalLink
                                                        href={l}
                                                        key={filename}
                                                        styles={styles.attachmentLink}
                                                    >
                                                        <Div styles={styles.attachmentTag}>
                                                            <Text size={3}>{filename}</Text>
                                                            <Icon type="pdf" color="#000" />
                                                        </Div>
                                                    </ExternalLink>
                                                ) : (
                                                    <Div styles={styles.attachmentBlock}>
                                                        <Text uppercase size={0} styles={styles.attachmentLabel}>
                                                            {filename}
                                                        </Text>
                                                        <Div styles={{ display: 'flex', alignItems: 'center' }}>
                                                            <Div styles={{ marginRight: 20 }}>
                                                                <Div
                                                                    styles={styles.attachmentOpenOption}
                                                                    onClick={() => openAttachment(idx)}
                                                                >
                                                                    <Icon type="pdf" color="#000" />
                                                                    <Text size={3}>Go to {filename}</Text>
                                                                </Div>
                                                                <Div
                                                                    styles={styles.attachmentOpenOption}
                                                                    onClick={() => setPopover(archivedUrl)}
                                                                >
                                                                    <Icon type="windowPopout" color="#000" />
                                                                    <Text size={3}>Open in Popover</Text>
                                                                </Div>
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                )
                                            }
                                        </MediaQuery>
                                    );
                                })}
                        </Div>
                    </Div>
                )}
            </Div>
        );
    }
}

export const AttachmentsUI = compose(withStyleSheet(styleSheet))(Attachments);
