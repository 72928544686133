import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withEventFilings($eventId: ID!) {
                    events(eventIds: [$eventId]) {
                        id
                        filingContent: content(filter: { contentTypes: [filing] }) {
                            id
                            contentType
                            displayType
                            title
                            publishedDate
                            ... on FilingContent {
                                filing {
                                    id
                                    form {
                                        id
                                        formName
                                        formNameShort
                                        formNumber
                                    }
                                    periodEndDate
                                    releaseDate
                                    arrivalDate
                                    isAmendment
                                    officialUrl
                                }
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    loading: get(data, 'loading'),
                    filings: get(data, 'events[0].filingContent')
                }),
                skip: ({ eventId }) => !eventId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
