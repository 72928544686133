import React, { PureComponent } from 'react';
import { BackButton } from 'components/BackButton';
import { Div } from 'components/Div';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { Text } from 'components/Text';
import { PDFViewer } from 'components/PDFViewer';
import { LoaderLogo } from 'components/LoaderLogo';
import { styleSheet } from './stylesheet';

class Document extends PureComponent {
    static propTypes = {
        pdfUrl: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        initialSearchTerm: PropTypes.string,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        documentId: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        documentLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        initialSearchTerm: undefined,
        pdfUrl: undefined,
        minimized: false,
        passedStyles: {},
        documentId: undefined,
        setToolbarTitle: null
    };

    componentDidMount() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    componentDidUpdate({ title: prevTitle }) {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title !== prevTitle) {
            setToolbarTitle(title);
        }
    }

    render() {
        const {
            initialSearchTerm,
            documentLoading,
            pdfUrl,
            documentId,
            styles,
            theme,
            passedStyles,
            containerRef,
            minimized
        } = this.props;

        if (minimized) {
            return null;
        }

        if (documentLoading) {
            return (
                <Div styles={styles.wrapperCentered}>
                    <LoaderLogo height={80} />
                    <Text size={3}>Loading Document...</Text>
                </Div>
            );
        }

        return (
            <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                <PDFViewer
                    targetId={documentId}
                    targetType="content_document"
                    initialSearchTerm={initialSearchTerm}
                    pdfUrl={pdfUrl}
                />
            </Div>
        );
    }
}

export const DocumentUI = compose(withStyleSheet(styleSheet))(Document);
