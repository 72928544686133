import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { TaggedInput } from 'components/TaggedInput';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class Autocomplete extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        changeOnEnter: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        error: PropTypes.string,
        getTagLabel: PropTypes.func,
        getTagStyle: PropTypes.func,
        handleInputRef: PropTypes.objectOf(PropTypes.any).isRequired,
        handleOptionNode: PropTypes.func.isRequired,
        handleTooltipRef: PropTypes.objectOf(PropTypes.any).isRequired,
        highlightedIndex: PropTypes.number.isRequired,
        hideTooltipOnScroll: PropTypes.bool,
        icon: PropTypes.string,
        id: PropTypes.string,
        inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        keepInViewport: PropTypes.bool,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        menuStyles: PropTypes.objectOf(PropTypes.any).isRequired,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onClear: PropTypes.func,
        onFocus: PropTypes.func.isRequired,
        onHighlight: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onTagChange: PropTypes.func.isRequired,
        onTooltipHide: PropTypes.func.isRequired,
        onTooltipShow: PropTypes.func.isRequired,
        OptionComponent: PropTypes.elementType,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        resultsPlaceholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        useTags: PropTypes.bool.isRequired,
        value: PropTypes.arrayOf(PropTypes.any),
        wrapTags: PropTypes.bool.isRequired
    };

    static defaultProps = {
        error: undefined,
        getTagLabel: undefined,
        getTagStyle: undefined,
        hideTooltipOnScroll: undefined,
        icon: undefined,
        id: undefined,
        inputValue: '',
        keepInViewport: undefined,
        label: undefined,
        multi: false,
        name: undefined,
        onClear: null,
        OptionComponent: null,
        passedStyles: {},
        placeholder: undefined,
        resultsPlaceholder: undefined,
        value: null
    };

    renderMenu() {
        const {
            handleOptionNode,
            highlightedIndex,
            loading,
            multi,
            onHighlight,
            onSelect,
            OptionComponent,
            options,
            resultsPlaceholder,
            styles,
            theme,
            value
        } = this.props;

        if (loading) {
            return <LoaderLogo styles={styles.loader} />;
        }

        if (options.length === 0 && resultsPlaceholder) {
            return (
                <Div styles={styles.menuPlaceholder}>
                    <Text size={3}>{resultsPlaceholder}</Text>
                </Div>
            );
        }

        return ({ hideTooltip }) =>
            options.map((option, idx) => {
                const {
                    label,
                    value: optionValue,
                    parent,
                    options: childOptions,
                    disabled,
                    styles: optionComponentStyles = {}
                } = option;
                const key =
                    typeof optionValue === 'string'
                        ? `autocomplete-${optionValue}-${label}`
                        : `autocomplete-${idx}-${label}`;
                const isHighlighted = idx === highlightedIndex;
                const optionStyles = isHighlighted ? styles.optionSelected : styles.option;
                let isSelected = false;
                let optionDecoratorStyles = childOptions ? styles.optionHeader : {};
                if (typeof optionValue === 'string') {
                    isSelected = value.includes(optionValue) || value.includes(get(option, 'parent.value'));
                } else if (typeof optionValue === 'object' && Array.isArray(value)) {
                    isSelected = value.some(val => isEqual(val, optionValue));
                }
                if (parent) {
                    optionDecoratorStyles = styles.optionNested;
                }
                return (
                    <Div
                        className="autocomplete-option"
                        styles={{ ...optionStyles, ...optionDecoratorStyles, ...optionComponentStyles }}
                        key={key}
                        onMouseEnter={() => (disabled ? {} : onHighlight(idx))}
                        onMouseDown={event => {
                            if (!disabled) {
                                onSelect({ event, option });
                                if (!multi) hideTooltip(event);
                            }
                        }}
                        ref={node => handleOptionNode(node, idx)}
                    >
                        {parent && (
                            <Div styles={styles.nestedIcon}>
                                <Icon type="arrowNested" color={theme.colors.gray04} />
                            </Div>
                        )}
                        {OptionComponent ? <OptionComponent {...option} /> : <Text>{label}</Text>}
                        <Div styles={styles.spacer} />
                        {isSelected && <Icon type="checkMarkSmall" color={theme.colors.blue08} />}
                    </Div>
                );
            });
    }

    render() {
        const {
            autoFocus,
            changeOnEnter,
            disabled,
            error,
            getTagLabel,
            getTagStyle,
            handleInputRef,
            handleTooltipRef,
            hideTooltipOnScroll,
            icon,
            id,
            inputValue,
            keepInViewport,
            label,
            menuStyles,
            name,
            onBlur,
            onClear,
            onFocus,
            onSearch,
            onTagChange,
            onTooltipHide,
            onTooltipShow,
            passedStyles,
            placeholder,
            styles,
            theme,
            useTags,
            value,
            wrapTags
        } = this.props;
        const cancelClickClass = `ac-${name}-cancelOutsideClick autocompleteTooltip`;
        return (
            <Tooltip
                ref={handleTooltipRef}
                styles={{ ...styles.autocompleteMenu, ...menuStyles }}
                xOffset={0}
                yOffset={10}
                useMinHoverWidth
                useMaxHoverWidth
                useElementOffsetLeft
                useElementOffsetBottom
                cancelClassName={cancelClickClass}
                blockBackground
                persistOnMouseExit
                isEnabled
                keepInViewport={keepInViewport}
                hideOnScroll={hideTooltipOnScroll}
                onShow={onTooltipShow}
                onHide={onTooltipHide}
                content={this.renderMenu()}
            >
                {({ showTooltip, hideTooltip }) =>
                    useTags ? (
                        <TaggedInput
                            changeOnEnter={changeOnEnter}
                            error={error}
                            getTagLabel={getTagLabel}
                            getTagStyle={getTagStyle}
                            inputRef={handleInputRef}
                            icon={icon}
                            id={id}
                            label={label}
                            name={name}
                            onBlur={e => {
                                hideTooltip(e);
                                onBlur(e);
                            }}
                            onChange={onTagChange}
                            onFocus={e => {
                                showTooltip(e);
                                onFocus(e);
                            }}
                            onInputChange={onSearch}
                            placeholder={placeholder}
                            styles={{ ...styles.container, ...passedStyles }}
                            tags={value}
                            value={inputValue}
                            wrapTags={wrapTags}
                        />
                    ) : (
                        <TextInput
                            autoComplete="off"
                            autoFocus={autoFocus}
                            className={cancelClickClass}
                            clearable
                            disabled={disabled}
                            error={error}
                            icon={icon || 'search03'}
                            iconColor={theme.colors.gray04}
                            iconRight={value.length ? 'checkMarkSmall' : undefined}
                            iconRightColor={value.length ? theme.colors.blue08 : undefined}
                            id={id}
                            inputRef={handleInputRef}
                            label={label}
                            name={name}
                            placeholder={placeholder}
                            onBlur={e => {
                                hideTooltip(e);
                                onBlur(e);
                            }}
                            onChange={onSearch}
                            onClear={onClear}
                            onFocus={e => {
                                showTooltip(e);
                                onFocus(e);
                            }}
                            styles={{ ...styles.container, ...passedStyles }}
                            type="text"
                            value={inputValue}
                        />
                    )
                }
            </Tooltip>
        );
    }
}

export const AutocompleteUI = compose(withStyleSheet(styleSheet))(Autocomplete);
