import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { styleSheet } from './stylesheet';

class SourcesAutocomplete extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {},
        value: null
    };

    render() {
        const { styles, passedStyles, name, options, placeholder, onChange, value } = this.props;
        return (
            <Autocomplete
                clearOnSelect
                multi
                name={name}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                menuStyles={styles.menu}
                value={value}
                icon="globe"
            />
        );
    }
}

export const SourcesAutocompleteUI = compose(withStyleSheet(styleSheet))(SourcesAutocomplete);
