import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { withRouter, matchPath } from 'react-router-dom';
import { externalRoutes } from 'routes';
import { withIntegration } from 'provider/integration';
import { withActiveMediaPlayer } from 'hoc/media';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { get } from 'utils';
import { MediaControlsUI } from './ui';

const RATES = [1, 1.1, 1.25, 1.5, 1.75, 2];

export class MediaControls extends PureComponent {
    static displayName = 'MediaControlsContainer';

    static propTypes = {
        integration: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        activeId: PropTypes.string,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        mediaPlayer: null,
        activeId: null
    };

    constructor(props) {
        super(props);

        this.togglePlaybackRate = this.togglePlaybackRate.bind(this);
        this.timelineRef = createRef();
        this.knobDrag = this.knobDrag.bind(this);
        this.knobRelease = this.knobRelease.bind(this);
        this.onDragPlayerKnob = this.onDragPlayerKnob.bind(this);
        this.seekBack = this.seekBack.bind(this);
        this.seekForward = this.seekForward.bind(this);
        this.seekToClick = this.seekToClick.bind(this);
        this.stopAudio = this.stopAudio.bind(this);
        this.state = {
            dragTime: null,
            hide: false
        };
    }

    componentDidMount() {
        const { integration } = this.props;
        integration.enabled().then(enabled => this.setState({ hide: enabled }));
    }

    togglePlaybackRate() {
        const { mediaPlayer } = this.props;
        const index = RATES.findIndex(r => r === mediaPlayer.playbackRate);
        mediaPlayer.setPlaybackRate(RATES[(index + 1) % RATES.length]);
    }

    seekToClick(e) {
        const { activeId, mediaPlayer, reporter } = this.props;
        const { duration } = mediaPlayer;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const xMovement = e.nativeEvent.pageX || e.nativeEvent.clientX;

        if (xMovement < timelineLeft + timelineWidth && xMovement > timelineLeft - 2) {
            const difference = xMovement - timelineLeft - 2;
            const time = duration * (difference / timelineWidth);
            reporter.track(reporter.actions.click, reporter.objects.audioSeek, {
                type: 'sync',
                live: !!get(mediaPlayer, 'isLive'),
                component: 'Playbar',
                activeId
            });
            mediaPlayer.seek(time);
        }
    }

    seekBack() {
        const { activeId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioRewind, {
            type: 'rewind',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId: activeId
        });
        if (mediaPlayer.currentTime === 0 && !mediaPlayer.listening) {
            mediaPlayer.seek(Math.max(mediaPlayer.duration - 15, 0));
        } else {
            mediaPlayer.seek(Math.max(mediaPlayer.currentTime - 15, 0));
        }
    }

    seekForward() {
        const { activeId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioFF, {
            type: 'fast_forward',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId: activeId
        });
        mediaPlayer.seek(Math.max(mediaPlayer.currentTime + 15, 0));
    }

    knobDrag(moveEvent) {
        const { mediaPlayer } = this.props;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const knobElem = timeline.querySelector('.knob');
        const xMovement = moveEvent.pageX || moveEvent.clientX || moveEvent.touches[0].clientX;
        if (xMovement < timelineLeft + timelineWidth && xMovement > timelineLeft - 2) {
            const difference = xMovement - timelineLeft - 2;
            const completion = difference / timelineWidth;
            const { duration } = mediaPlayer;
            const dragTime = duration * completion;
            knobElem.style.left = `${difference}px`;
            this.setState({ dragTime });
        }
    }

    knobRelease(moveEvent) {
        const { activeId, mediaPlayer, reporter } = this.props;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const knobElem = timeline.querySelector('.knob');
        const xMovement = moveEvent.pageX || moveEvent.clientX || moveEvent.changedTouches[0].clientX;
        const difference = xMovement - timelineLeft - 2;
        const completion = difference / timelineWidth;
        const { duration } = mediaPlayer;
        const time = duration * completion;

        if (!time) {
            return;
        }
        document.removeEventListener('touchmove', this.knobDrag);
        document.removeEventListener('touchend', this.knobRelease);
        reporter.track(reporter.actions.click, reporter.objects.audioSeek, {
            type: 'drag',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            activeId
        });
        mediaPlayer.seek(time);
        this.resetDragTimeout = setTimeout(() => {
            // Keeping this in a timeout makes sure the seek is complete
            // before removing the style, which will "jump" otherwise
            knobElem.removeAttribute('style');
            this.setState({ dragTime: null });
        }, 400);
    }

    onDragPlayerKnob(e) {
        e.preventDefault();
        e.stopPropagation();
        document.addEventListener('touchmove', this.knobDrag);
        document.addEventListener('touchend', this.knobRelease);
    }

    stopAudio() {
        const { activeId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioStop, {
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            activeId
        });
        mediaPlayer.stop();
    }

    render() {
        const { mediaPlayer, location, activeId, ...rest } = this.props;
        const { hide, dragTime } = this.state;
        const onExternalPage = Object.values(externalRoutes).some(path => matchPath(location.pathname, { path }));

        return activeId && !hide && !onExternalPage && mediaPlayer.canListen && mediaPlayer ? (
            <MediaControlsUI
                {...rest}
                location={location}
                mediaPlayer={mediaPlayer}
                togglePlaybackRate={this.togglePlaybackRate}
                onDragPlayerKnob={this.onDragPlayerKnob}
                seekBack={this.seekBack}
                seekForward={this.seekForward}
                seekToClick={this.seekToClick}
                timelineRef={this.timelineRef}
                dragTime={dragTime}
                stopAudio={this.stopAudio}
            />
        ) : null;
    }
}

export const MediaControlsContainer = compose(
    withRouter,
    withIntegration(),
    withActiveMediaPlayer(undefined, 'activeMediaPlayer'),
    withPropsOnChange(['audioCallId', 'activeMediaPlayer'], ({ audioCallId, activeMediaPlayer }) => {
        const activeId = get(activeMediaPlayer, 'metaData.id') || audioCallId;
        return {
            activeId,
            audioCallId: activeId
        };
    }),
    withEventMediaPlayer({ allowLiveStream: true, loadAudio: true })
)(MediaControls);
