export const styleSheet = theme => ({
    container: {
        position: 'fixed',
        zIndex: 17
    },
    slideIn: {
        ...theme.content.slideInAnimation
    },
    backgroundBlocker: {
        cursor: 'default',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 16
    }
});
