import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { alertToastShow } from 'actions/alertToast';
import { userIsIdle as userIsIdleAction } from 'actions/user';
import { bugsnagClient } from 'provider';
import { get } from 'utils';

const INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds
const USER_IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

export class VersionCheck extends PureComponent {
    static displayName = 'VersionCheckContainer';

    static propTypes = {
        localVersion: PropTypes.string.isRequired,
        setUserIsIdle: PropTypes.func.isRequired,
        showAlert: PropTypes.func.isRequired,
        userIsIdle: PropTypes.bool
    };

    static defaultProps = {
        userIsIdle: false
    };

    constructor(props) {
        super(props);

        this.check = this.check.bind(this);
        this.checkUserIsIdle = this.checkUserIsIdle.bind(this);
        this.checkVersion = this.checkVersion.bind(this);
        this.reload = this.reload.bind(this);
        this.setLastActiveTime = this.setLastActiveTime.bind(this);
    }

    componentDidMount() {
        // Every 5 minutes, check for a new version and if the user has gone idle
        this.checkInterval = setInterval(this.check, INTERVAL);

        window.addEventListener('mousemove', this.setLastActiveTime);
        this.setLastActiveTime();
        this.check();
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.setLastActiveTime);
        clearInterval(this.checkInterval);
        clearTimeout(this.reloadTimer);
    }

    check() {
        this.checkVersion();
        this.checkUserIsIdle();
    }

    checkUserIsIdle() {
        const { setUserIsIdle } = this.props;
        if (Date.now() - this.lastActive >= USER_IDLE_TIMEOUT) {
            setUserIsIdle(true);
        }
    }

    checkVersion() {
        const { showAlert, localVersion } = this.props;

        fetch('/version.json', { headers: { Accept: 'application/json, text/plain, */*' } })
            .then(resp => resp.json())
            .then(({ version }) => {
                const remoteParts = version.split('.');
                const localParts = localVersion.split('.');

                if (parseFloat(remoteParts[0]) > localParts[0]) {
                    // FORCE REFRESH
                    // window.location.reload(true);
                } else if (parseFloat(remoteParts[1]) > localParts[1]) {
                    // SHOW TOAST UI ASKING TO REFRESH
                    // AND REFRESH IN 1 MIN
                    showAlert('refresh');
                    this.reload(1);
                } else if (
                    (parseFloat(remoteParts[2]) > localParts[2] && this.wasIdleFor(5)) ||
                    this.wasIdleFor(2880)
                ) {
                    // RELOAD WHEN MINOR VERSION IS GREATER AND IDLE FOR 5 MIN
                    // OR AFTER BEING IDLE FOR 2 DAYS
                    this.reload();
                }
            })
            .catch(error => {
                bugsnagClient.notify(error);
            });
    }

    reload(minutes = 0) {
        if (!this.reloadTimer) {
            setTimeout(() => {
                this.reloadTimer = null;
                window.location.reload(true);
            }, minutes * 60 * 1000);
        }
    }

    setLastActiveTime() {
        const { setUserIsIdle, userIsIdle } = this.props;
        this.lastActive = Date.now();
        if (userIsIdle) {
            setUserIsIdle(false);
        }
    }

    wasIdleFor(minutes) {
        return (Date.now() - this.lastActive) / 60000 > minutes;
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ User: userStore }) => ({
    userIsIdle: get(userStore, 'isIdle')
});

const mapDispatchToProps = {
    setUserIsIdle: userIsIdleAction,
    showAlert: alertToastShow
};

export const VersionCheckContainer = compose(connect(mapStateToProps, mapDispatchToProps))(VersionCheck);
