export const styleSheet = () => ({
    container: {
        maxWidth: 1000,
        width: 'calc(100% - 80px)',
        '.vimeo-player': {
            iframe: {
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%'
            },
            padding: 35
        },
        '.vimeo-player > div': {
            height: 0,
            overflow: 'hidden',
            paddingTop: '56.25%',
            position: 'relative',
            width: '100%'
        }
    }
});
