import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId } from 'utils';
import { CardLockButtonUI } from './ui';

export class CardLockButton extends PureComponent {
    static displayName = 'CardLockButtonContainer';

    static propTypes = {
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        upgradeModalId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.showUpgradeModal = this.showUpgradeModal.bind(this);
    }

    showUpgradeModal(e) {
        const { history, location, upgradeModalId } = this.props;
        if (e) {
            e.preventDefault();
        }
        history.push(generateModalId({ location, id: upgradeModalId, type: 'upgrade' }));
    }

    render() {
        const { styles } = this.props;
        return <CardLockButtonUI styles={styles} onClick={this.showUpgradeModal} />;
    }
}

export const CardLockButtonContainer = compose(withUrlContext(['history', 'location']))(CardLockButton);
