import gql from 'graphql-tag';
import { compose } from 'recompose';
import { TYPES } from 'consts/filters';
import { ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { mapDashFiltersToRules } from 'utils/streams';

export const DOWNLOAD_MATCH_LIMIT = 250;

export const withData = () =>
    compose(
        withMemo({ mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query ExportEventsStreamDashboard($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        name
                        rules {
                            ...rule
                        }
                    }
                }
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    const dashRules = get(data, 'dashboards[0].rules');
                    let dashDateRule;
                    let hasDashDateRule = false;
                    let rules;
                    if (dashRules !== undefined) {
                        rules = [];
                        dashRules.forEach(({ condition, ruleType, value }) => {
                            if (ruleType === TYPES.date) {
                                dashDateRule = value.map(d => new Date(d));
                                hasDashDateRule = true;
                            }
                            rules.push({ condition, ruleType, value });
                        });
                    }
                    return {
                        dashDateRule,
                        dashName: get(data, 'dashboards[0].name'),
                        dashRules: rules,
                        dashLoading: get(data, 'loading'),
                        hasDashDateRule
                    };
                },
                skip: ({ dashboardId }) => !dashboardId,
                options: ({ dashboardId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: { dashboardId }
                })
            }
        ),
        graphql(
            gql`
                query ExportEventsStream($streamId: ID, $size: Int = 20, $offset: Int = 0, $filter: StreamMatchFilter) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ data }) => {
                    const stream = get(data, 'streams[0]', {});
                    return {
                        loading: get(data, 'loading'),
                        streamName: get(stream, 'name'),
                        totalMatches: get(stream, 'matches.total', 0)
                    };
                },
                skip: ({ dashLoading, streamId }) => !streamId || dashLoading,
                options: ({ dashRules, dateRange, mapFilters, streamId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        filter: {
                            rules: mapFilters ? dashRules.concat(mapFilters({ dateRange })) : undefined
                        },
                        streamId,
                        size: DOWNLOAD_MATCH_LIMIT
                    },
                    context: {
                        debounceKey: `debounce:content:${streamId}`,
                        debounceTimeout: dateRange ? 300 : 0
                    }
                })
            }
        )
    );
