import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Div } from 'components/Div';
import { DatePicker } from 'components/DatePicker';
import { ActionButton } from 'components/ActionButton';
import { Tooltip } from 'components/Tooltip';
import { LoaderLogo } from 'components/LoaderLogo';
import XDate from 'xdate';
import { Tr } from 'components/Tr';
import { Td } from 'components/Td';
import { Table } from 'components/Table';
import { Icon } from 'components/Icon';
import { Hint } from 'components/Hint';
import { styleSheet } from './stylesheet';

class ExportMatchesModal extends PureComponent {
    static propTypes = {
        dateRange: PropTypes.arrayOf(PropTypes.any),
        downloadSize: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingWord: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onDownloadWord: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        setDateRange: PropTypes.func.isRequired,
        streamName: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        totalMatches: PropTypes.number.isRequired
    };

    static defaultProps = {
        dateRange: null,
        passedStyles: {},
        streamName: undefined
    };

    render() {
        const {
            dateRange,
            downloadSize,
            loading,
            loadingWord,
            onClose,
            onDownloadWord,
            passedStyles,
            setDateRange,
            streamName,
            styles,
            theme,
            totalMatches
        } = this.props;

        const dateString = dateRange
            ? `${new XDate(dateRange[0]).toString('MMM d, yyyy')} — ${new XDate(dateRange[1]).toString('MMM d, yyyy')}`
            : 'All Time';
        return (
            <Modal
                isOpen
                onModalClose={onClose}
                styles={{ ...styles.container, ...passedStyles }}
                title="Export Matches"
            >
                {loading ? (
                    <Div styles={styles.loading}>
                        <Text size={3}>
                            Downloading Matches &<br />
                            Preparing Word Document
                        </Text>
                        <LoaderLogo />
                    </Div>
                ) : (
                    <Div styles={styles.content}>
                        <Tooltip
                            isEnabled
                            useOutsideClickHandler
                            content={<DatePicker name="dashDateRange" range onChange={setDateRange} />}
                        >
                            {({ showTooltip }) => (
                                <Table styles={styles.downloadInfo}>
                                    <Tr>
                                        <Td>Saved Search</Td>
                                        <Td>
                                            <Text size={3} styles={styles.truncate}>
                                                {streamName}
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Date Range</Td>
                                        <Td>
                                            <Div styles={styles.editRow} onClick={showTooltip}>
                                                {dateString}
                                                <Icon type="pencil02" color={theme.colors.gray04} />
                                            </Div>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Total Matches</Td>
                                        <Td>{totalMatches}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Download Limit</Td>
                                        <Td>
                                            <Hint
                                                growUp
                                                yOffset={-26}
                                                text="Download Limit"
                                                description="To access unlimited matches using our API, please reach out to our team."
                                                width={240}
                                                styles={styles.infoRow}
                                            >
                                                <Text size={3}>{downloadSize} matches</Text>
                                                <Icon type="circleInfo02" color={theme.colors.gray04} />
                                            </Hint>
                                        </Td>
                                    </Tr>
                                </Table>
                            )}
                        </Tooltip>
                        <ActionButton size={2} styles={styles.downloadBtn} onClick={onDownloadWord}>
                            {loadingWord ? <LoaderLogo /> : 'Download Word Document'}
                        </ActionButton>
                    </Div>
                )}
            </Modal>
        );
    }
}

export const ExportMatchesModalUI = compose(withStyleSheet(styleSheet))(ExportMatchesModal);
