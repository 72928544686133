import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { dashboardFragment, streamFragment } from 'graphql/fragments/streams';
import { withDeleteStream } from 'graphql/streams';
import { graphql } from 'graphql/utils';

// Todo filter to specific dashboard id when server supports it
export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation updateStreamOrder($dashboardId: ID!, $sortOrder: [ID]!) {
                    updateDashboardStreamSortOrder(dashboardId: $dashboardId, sortOrder: $sortOrder) {
                        dashboard {
                            ...dashboard
                            streams {
                                ...stream
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate, ownProps: { dashboardId } }) => ({
                    onSort: streamIds => {
                        return mutate({
                            variables: {
                                dashboardId,
                                sortOrder: streamIds
                            }
                        });
                    }
                })
            }
        ),
        withDeleteStream()
    );
