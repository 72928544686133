import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { HIGHLIGHT_COLORS } from 'consts';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { getHighlightColor } from 'utils';
import { withData } from './data';
import { BookmarkFormUI } from './ui';

const REMINDER_OPTIONS = [
    {
        label: 'No reminder',
        value: 'no_reminder'
    },
    {
        label: 'End of day',
        value: 'today'
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow'
    },
    {
        label: 'Next week',
        value: 'next_week'
    }
];

function mapReminderToOption(reminder) {
    if (reminder) {
        const today = new XDate();
        const diffDays = today.diffDays(new XDate(reminder));
        if (diffDays < 1 && diffDays > 0) return 'today';
        if (diffDays < 2 && diffDays > 1) return 'tomorrow';
        if (diffDays >= 2) return 'next_week';
    }
    return 'no_reminder';
}

const KEY_MAP = {
    '38': 'ArrowUp',
    '40': 'ArrowDown',
    '37': 'ArrowLeft',
    '39': 'ArrowRight'
};

export class BookmarkForm extends PureComponent {
    static displayName = 'BookmarkFormContainer';

    static propTypes = {
        bookmark: PropTypes.objectOf(PropTypes.any),
        bookmarkId: PropTypes.string,
        contentId: PropTypes.string,
        createBookmark: PropTypes.func.isRequired,
        dashboardId: PropTypes.string,
        deleteBookmark: PropTypes.func.isRequired,
        equities: PropTypes.arrayOf(PropTypes.object),
        eventId: PropTypes.string,
        highlight: PropTypes.string,
        highlightsFilterKey: PropTypes.string,
        highlightsSortKey: PropTypes.string,
        includeNewsHighlights: PropTypes.bool,
        loading: PropTypes.bool,
        matchId: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        target: PropTypes.objectOf(PropTypes.any),
        targetEndId: PropTypes.string,
        targetEndOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        targetId: PropTypes.string.isRequired,
        targetOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        targetType: PropTypes.string.isRequired,
        updateBookmark: PropTypes.func.isRequired
    };

    static defaultProps = {
        bookmark: null,
        bookmarkId: null,
        contentId: undefined,
        dashboardId: undefined,
        equities: undefined,
        eventId: undefined,
        highlight: undefined,
        highlightsFilterKey: undefined,
        highlightsSortKey: undefined,
        includeNewsHighlights: undefined,
        loading: false,
        matchId: undefined,
        onSuccess: undefined,
        streamId: undefined,
        styles: undefined,
        target: undefined,
        targetEndId: undefined,
        targetEndOffset: undefined,
        targetOffset: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onTaggedInputChange = this.onTaggedInputChange.bind(this);

        this.state = {
            deleting: false,
            hasChanges: false,
            submitting: false,
            taggedInput: '',
            ...this.mapBookmarkPropsToState()
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    componentDidUpdate({ bookmark: prevBookmark }) {
        const { bookmark } = this.props;
        if (prevBookmark !== bookmark && bookmark) {
            this.setState({
                ...this.mapBookmarkPropsToState()
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(event) {
        const { which } = event;
        const key = KEY_MAP[which];
        const ignore = ['ArrowUp', 'ArrowDown'];

        if (ignore.includes(key)) {
            event.stopPropagation();
        }
    }

    mapBookmarkPropsToState() {
        const { bookmark } = this.props;
        const { equityIds, highlightColor, note, reminder, tags } = bookmark || {};
        const date = mapReminderToOption(reminder);
        return {
            values: {
                equityIds: (equityIds || []).map(id => `${id}`),
                highlightColor: highlightColor || HIGHLIGHT_COLORS[0],
                note: note || '',
                reminder: date || REMINDER_OPTIONS[0].value,
                tags: tags || []
            }
        };
    }

    onChange({ name, value }) {
        let normalizedValue = value;
        if (name === 'highlightColor') {
            // The picker uses the client hex color,
            // but the server needs the color's key value
            normalizedValue = getHighlightColor(value)?.key || value;
        }
        this.setState(({ taggedInput, values }) => ({
            hasChanges: true,
            taggedInput: name === 'tags' ? '' : taggedInput, // clear the tagged input text when the tags change
            values: {
                ...values,
                [name]: normalizedValue
            }
        }));
    }

    onClose() {
        const { hasChanges } = this.state;
        const { onClose } = this.props;
        if (
            !hasChanges ||
            // eslint-disable-next-line no-alert
            (hasChanges && window.confirm('You have unsaved changes. Are you sure you want to cancel?'))
        ) {
            onClose();
        }
    }

    onDelete() {
        const {
            bookmarkId,
            deleteBookmark,
            contentId,
            eventId,
            matchId,
            onClose,
            reporter,
            targetId,
            targetType
        } = this.props;
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to delete this highlight?')) {
            this.setState({ deleting: true }, () => {
                deleteBookmark({ bookmarkId, contentId, eventId, matchId, targetId, targetType })
                    .then(() => {
                        this.setState({ deleting: false }, () => {
                            onClose();

                            // Track Highlight Delete
                            reporter.track(reporter.actions.submit, reporter.objects.highlightDelete, {
                                component: 'BookmarkForm',
                                bookmarkId,
                                targetType,
                                targetId,
                                eventId,
                                contentId
                            });
                        });
                    })
                    .catch(() => {
                        this.setState({ deleting: false });
                    });
            });
        }
    }

    onSubmit() {
        const { taggedInput, values } = this.state;
        const {
            bookmark,
            bookmarkId,
            contentId,
            createBookmark,
            eventId,
            highlight,
            highlightsFilterKey,
            highlightsSortKey,
            includeNewsHighlights,
            matchId,
            onClose,
            onSuccess,
            reporter,
            streamId,
            targetEndId,
            targetEndOffset,
            targetId,
            targetOffset,
            targetType,
            updateBookmark
        } = this.props;
        const { equityIds, highlightColor, note, reminder, tags } = values;
        const input = {
            contentId,
            equityIds,
            eventId,
            highlight,
            highlightColor,
            includeNewsHighlights,
            streamId,
            targetEndId,
            targetEndOffset,
            targetId,
            targetOffset,
            targetType
        };
        if (matchId) input.matchId = matchId;
        if (note.length) input.note = note;
        if (reminder !== 'no_reminder') {
            let date = new XDate();
            if (reminder === 'tomorrow') date = date.addDays(1);
            if (reminder === 'next_week') date = date.addWeeks(1);
            input.reminder = date.toISOString();
        }
        if (tags.length || taggedInput.length) {
            input.tags = tags;
            if (taggedInput.length) {
                input.tags = [...new Set([...input.tags, taggedInput])];
            }
        }
        this.setState({ submitting: true }, () => {
            (bookmark
                ? updateBookmark({ ...input, bookmarkId })
                : createBookmark({
                      ...input,
                      filterKey: highlightsFilterKey,
                      sortKey: highlightsSortKey
                  })
            )
                .then(() => {
                    this.setState({ submitting: false }, () => {
                        onClose();
                        if (onSuccess) onSuccess();

                        // Track Highlight Create / Update
                        reporter.track(
                            reporter.actions.submit,
                            bookmark ? reporter.objects.highlightUpdate : reporter.objects.highlightCreate,
                            {
                                component: 'BookmarkForm',
                                bookmarkId,
                                targetType,
                                targetId,
                                eventId,
                                contentId,
                                highlightColor
                            }
                        );
                    });
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        });
    }

    onTaggedInputChange({ value }) {
        this.setState({
            hasChanges: true,
            taggedInput: value
        });
    }

    render() {
        const { deleting, submitting, taggedInput, values } = this.state;
        const { bookmark, dashboardId, equities, eventId, highlight, loading, styles, target } = this.props;
        const { highlightColor } = values;
        const selectedColor = getHighlightColor(highlightColor);
        const borderColor = selectedColor?.dark;
        return (
            <BookmarkFormUI
                borderColor={borderColor}
                colorOptions={HIGHLIGHT_COLORS.map(color => getHighlightColor(color)?.color)}
                dashboardId={dashboardId}
                deleting={deleting}
                editing={!!bookmark}
                equities={equities}
                eventId={eventId}
                highlight={highlight}
                highlightColor={selectedColor?.color}
                loading={loading}
                onChange={this.onChange}
                onClose={this.onClose}
                onDelete={this.onDelete}
                onSubmit={this.onSubmit}
                onTaggedInputChange={this.onTaggedInputChange}
                reminderOptions={REMINDER_OPTIONS}
                styles={styles}
                submitting={submitting}
                taggedInput={taggedInput}
                target={target}
                values={values}
            />
        );
    }
}

const mapStateToProps = ({ Highlight }) => {
    const {
        contentId,
        eventId,
        highlight,
        highlightsFilterKey,
        highlightsSortKey,
        includeNewsHighlights,
        matchId,
        onSuccess,
        streamId,
        targetEndId,
        targetEndOffset,
        targetId,
        targetOffset,
        targetType
    } = Highlight;
    return {
        contentId,
        eventId,
        highlight,
        highlightsFilterKey,
        highlightsSortKey,
        includeNewsHighlights,
        matchId,
        onSuccess,
        streamId,
        targetEndId,
        targetEndOffset,
        targetId,
        targetOffset,
        targetType
    };
};

export const BookmarkFormContainer = compose(
    withUrlContext(['dashboardId']),
    connect(mapStateToProps, undefined),
    withData(),
    withReporting()
)(BookmarkForm);
