import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { EmptyContentUI } from './ui';

export class EmptyContent extends PureComponent {
    static displayName = 'EmptyContentContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <EmptyContentUI {...rest} />;
    }
}

const connectedComponent = connect()(EmptyContent);

export const EmptyContentContainer = compose()(connectedComponent);
