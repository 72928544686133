import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation RaiseEventIssue($input: EventIssueInput!) {
                    raiseEventIssue(input: $input) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    flag: input =>
                        mutate({
                            variables: { input }
                        })
                            .then(() => {
                                setStatusBanner('Transcript Flagged For Review');
                            })
                            .catch(error => {
                                setStatusBanner(`Error Flagging: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        )
    );
