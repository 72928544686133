import { compose } from 'recompose';
import { withEventMediaPlayer } from 'graphql/audioCalls';

export const withData = () =>
    compose(
        withEventMediaPlayer({
            allowLiveStream: true,
            trackDetails: false
        })
    );
