import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FilingStreamToolbarUI } from './ui';

export class FilingStreamToolbar extends PureComponent {
    static displayName = 'FilingStreamToolbarContainer';

    static propTypes = {
        filingForms: PropTypes.arrayOf(PropTypes.string),
        styles: PropTypes.objectOf(PropTypes.any),
        toggleFilingForm: PropTypes.func.isRequired
    };

    static defaultProps = {
        filingForms: [],
        styles: undefined
    };

    render() {
        const { filingForms, styles, toggleFilingForm } = this.props;
        return <FilingStreamToolbarUI filingForms={filingForms} styles={styles} toggleFilingForm={toggleFilingForm} />;
    }
}

export const FilingStreamToolbarContainer = compose()(FilingStreamToolbar);
