import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { withStyleSheet } from 'hoc/styles';
import { mapStylesProp } from 'utils/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

const TextareaEle = styled('textarea')(mapStylesProp);

class TextareaOld extends Component {
    static propTypes = {
        error: PropTypes.string,
        initTextarea: PropTypes.func.isRequired,
        label: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.string
    };

    static defaultProps = {
        error: '',
        label: null,
        onKeyDown: undefined,
        passedStyles: {},
        placeholder: '',
        value: ''
    };

    render() {
        const {
            styles,
            passedStyles,
            label,
            placeholder,
            onBlur,
            onFocus,
            onChange,
            onKeyDown,
            initTextarea,
            error,
            value,
            children
        } = this.props;

        const textareaStyles =
            error && error.length > 1 ? { ...styles.textarea, ...styles.textareaError } : styles.textarea;

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div style={styles.labelContainer}>
                    {label && (
                        <Text label uppercase styles={styles.label}>
                            {label}
                        </Text>
                    )}
                    {error && error.length > 0 && (
                        <Text label uppercase styles={styles.textError}>
                            {error}
                        </Text>
                    )}
                </Div>
                <TextareaEle
                    ref={t => {
                        initTextarea(t);
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    styles={textareaStyles}
                    id={label}
                    placeholder={placeholder}
                    value={value}
                />
                {children}
            </Div>
        );
    }
}

export const TextareaOldUI = compose(withStyleSheet(styleSheet))(TextareaOld);
