import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withTrackUserActivity } from 'graphql/user';
import { withData } from './data';
import { StreetAccountUI } from './ui';

export class StreetAccount extends PureComponent {
    static displayName = 'StreetAccountContainer';

    static propTypes = {
        body: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        company: PropTypes.string,
        publishedDate: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        streamId: PropTypes.string,
        streetAccountId: PropTypes.string,
        streetAccountError: PropTypes.bool,
        streetAccountLoading: PropTypes.bool,
        ticker: PropTypes.string,
        title: PropTypes.string,
        trackContentActivity: PropTypes.func.isRequired
    };

    static defaultProps = {
        body: undefined,
        company: undefined,
        equities: [],
        publishedDate: undefined,
        setToolbarTitle: null,
        streamId: null,
        streetAccountId: null,
        streetAccountError: false,
        streetAccountLoading: false,
        ticker: undefined,
        title: 'Loading...'
    };

    constructor(props) {
        super(props);

        this.containerRef = createRef();
        this.documentTitle = document.title;
        this.scrolled = false;
    }

    componentDidMount() {
        const { trackContentActivity, streetAccountId } = this.props;
        if (streetAccountId) {
            trackContentActivity(streetAccountId);
        }
        this.maybeScrollToMatch();
    }

    componentDidUpdate({
        streetAccountId: prevStreetAccountId,
        streamId: prevStreamId,
        streetAccountLoading: prevLoading,
        title: prevTitle
    }) {
        const { streetAccountId, streamId, streetAccountLoading, title, trackContentActivity } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | StreetAccount | ${title}`;
        }

        if (
            streetAccountId !== prevStreetAccountId ||
            streamId !== prevStreamId ||
            streetAccountLoading !== prevLoading
        ) {
            this.scrolled = false;
            trackContentActivity(streetAccountId);
            this.maybeScrollToMatch();
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    maybeScrollToMatch() {
        const { body, streamId } = this.props;
        if (streamId && body && this.containerRef.current) {
            const highlights = this.containerRef.current.getElementsByTagName('em');
            this.scrolled = true;
            if (highlights.length) {
                const firstHighlight = highlights[0];
                firstHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    render() {
        const {
            body,
            categories,
            company,
            equities,
            publishedDate,
            setToolbarTitle,
            streetAccountError,
            streetAccountLoading,
            ticker,
            title
        } = this.props;

        return (
            <StreetAccountUI
                body={body}
                containerRef={this.containerRef}
                categories={categories}
                company={company}
                equities={equities}
                errorLoadingDetails={streetAccountError}
                loadingDetails={streetAccountLoading}
                publishedDate={publishedDate}
                setToolbarTitle={setToolbarTitle}
                ticker={ticker}
                title={title}
            />
        );
    }
}

export const StreetAccountContainer = compose(
    withUrlContext(['streamId']),
    withTrackUserActivity(),
    withData()
)(StreetAccount);
