import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withFilingFormAutocomplete {
                    filingForms {
                        id
                        formNumber
                        formShortName: formNameShort
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    forms: get(data, 'filingForms', []),
                    loading: get(data, 'loading')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
