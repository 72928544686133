import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { TextHintUI } from './ui';

export class TextHint extends PureComponent {
    static displayName = 'TextHintContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        text: PropTypes.string.isRequired,
        title: PropTypes.string
    };

    static defaultProps = {
        styles: {},
        title: undefined
    };

    render() {
        const { children, styles, text, title } = this.props;
        return (
            <TextHintUI styles={styles} text={text} title={title}>
                {children}
            </TextHintUI>
        );
    }
}

export const TextHintContainer = compose()(TextHint);
