import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class FormRow extends PureComponent {
    static propTypes = {
        description: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.string]),
        forId: PropTypes.string,
        label: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        description: undefined,
        forId: undefined,
        label: undefined,
        passedStyles: {}
    };

    renderDescription() {
        const { description, styles, forId } = this.props;
        if (!description) return null;
        if (typeof description === 'string') {
            return (
                <Text label htmlFor={forId} size={1} styles={styles.description}>
                    {description}
                </Text>
            );
        }
        return description;
    }

    render() {
        const { children, label, passedStyles, styles, forId } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div className="formRow-leftSide" styles={styles.leftSide}>
                    {label && (
                        <Text label htmlFor={forId} className="form-row-label" size={3} styles={styles.label}>
                            {label}
                        </Text>
                    )}
                    {this.renderDescription()}
                </Div>
                {children && (
                    <Div className="formRow-rightSide" styles={styles.rightSide}>
                        {children}
                    </Div>
                )}
            </Div>
        );
    }
}

export const FormRowUI = compose(withStyleSheet(styleSheet))(FormRow);
