export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white02,
        a: {
            textDecoration: 'none',
            color: theme.colors.black01
        },
        ':hover': {
            a: {
                textDecoration: 'underline'
            }
        }
    }
});
