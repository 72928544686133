import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Hint } from 'components/Hint';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class FeaturePreview extends PureComponent {
    static propTypes = {
        growLeft: PropTypes.bool.isRequired,
        growUp: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        xOffset: PropTypes.number.isRequired,
        yOffset: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { growLeft, growUp, passedStyles, styles, xOffset, yOffset } = this.props;
        return (
            <Hint
                growLeft={growLeft}
                growUp={growUp}
                slideIn
                styles={{ ...styles.container, ...passedStyles }}
                text="This is a feature preview"
                xOffset={xOffset}
                yOffset={yOffset}
            >
                <Text>preview</Text>
            </Hint>
        );
    }
}

export const FeaturePreviewUI = compose(withStyleSheet(styleSheet))(FeaturePreview);
