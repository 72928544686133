import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { BrowserGateUI } from './ui';

export class BrowserGate extends PureComponent {
    static displayName = 'BrowserGateContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    render() {
        const { children, styles } = this.props;
        const isIE = navigator.userAgent.indexOf('MSIE') >= 0 || navigator.appVersion.indexOf('Trident/') >= 0;
        return isIE ? <BrowserGateUI styles={styles} /> : children;
    }
}

export const BrowserGateContainer = compose()(BrowserGate);
