/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import inputmask from 'inputmask';
import { TextInputUI } from './ui';

class Formik extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => {}
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange({ event }) {
        const { onChange } = this.props;
        onChange(event);
    }

    render() {
        return <TextInput {...this.props} onChange={this.onChange} />;
    }
}

export class TextInput extends PureComponent {
    static displayName = 'TextInputContainer';

    static propTypes = {
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        clearable: PropTypes.bool,
        clearOnEscape: PropTypes.bool,
        containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        disabled: PropTypes.bool,
        error: PropTypes.string,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        iconRight: PropTypes.string,
        iconRightColor: PropTypes.string,
        id: PropTypes.string,
        initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        inputRef: PropTypes.objectOf(PropTypes.object),
        label: PropTypes.string,
        mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        maxLength: PropTypes.string,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onClear: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tabIndex: PropTypes.number,
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    static defaultProps = {
        autoComplete: 'off',
        autoFocus: false,
        className: '',
        clearable: false,
        clearOnEscape: false,
        containerStyle: {},
        disabled: false,
        error: '',
        icon: null,
        iconColor: null,
        iconRight: null,
        iconRightColor: null,
        id: null,
        initialValue: undefined,
        inputRef: null,
        label: null,
        mask: null,
        maxLength: undefined,
        onBlur: null,
        onChange: null,
        onClear: null,
        onFocus: null,
        onKeyDown: () => {},
        onKeyUp: () => {},
        placeholder: '',
        styles: {},
        tabIndex: undefined,
        tooltipComponent: undefined,
        tooltipOptions: {},
        type: 'text',
        value: undefined
    };

    static Formik = Formik;

    constructor(props) {
        super(props);

        const { initialValue } = props;

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClear = this.handleClear.bind(this);

        this.inputRef = props.inputRef || React.createRef();

        this.state = {
            isFocused: false,
            inputValue: initialValue || ''
        };
    }

    componentDidMount() {
        const { mask } = this.props;
        if (this.inputRef.current && mask) {
            const maskObj = typeof mask === 'string' ? { mask } : mask;
            inputmask({
                placeholder: ' ',
                showMaskOnHover: false,
                ...maskObj
            }).mask(this.inputRef.current);
        }
    }

    componentDidUpdate(prevProps) {
        const { initialValue, value, mask, name, onChange } = this.props;

        if (initialValue && !prevProps.initialValue && this.inputRef.current && mask) {
            this.inputRef.current.inputmask.setValue(initialValue);
        }

        if (
            value &&
            mask &&
            this.inputRef.current &&
            String(this.inputRef.current.inputmask.unmaskedvalue()) !== String(value)
        ) {
            if (onChange) {
                onChange({ event: null, name, value: this.inputRef.current.inputmask.unmaskedvalue() });
            }
        }
    }

    handleBlur(e) {
        const { onBlur } = this.props;

        this.setState({
            isFocused: false
        });

        if (onBlur) {
            onBlur(e);
        }
    }

    handleClear(e) {
        const { onChange, onClear, name } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (onChange) {
            onChange({ event: e, name, value: '' });
        }
        if (onClear) {
            onClear({ event: e });
        }
    }

    handleChange(e) {
        const { onChange, name, value, mask } = this.props;

        let {
            target: { value: inputValue }
        } = e;

        if (mask && this.inputRef.current) {
            inputValue = this.inputRef.current.inputmask.unmaskedvalue();
        }

        // Only update the state input value when the provided value prop is undefined
        // This should only happen when the component is uncontrolled (initialValue is passed in instead of value)
        if (value === undefined) {
            this.setState({
                inputValue
            });
        }

        if (onChange) {
            onChange({ event: e, name, value: inputValue });
        }
    }

    handleFocus(e) {
        const { onFocus } = this.props;

        this.setState({
            isFocused: true
        });

        if (onFocus) {
            onFocus(e);
        }
    }

    handleKeyDown(event) {
        const { clearOnEscape, name, onChange, onKeyDown, value } = this.props;
        const { key, target } = event;

        if (clearOnEscape && key === 'Escape') {
            // If uncontrolled, clear out the input state value
            if (value === undefined) {
                this.setState({ inputValue: '' });
            } else if (onChange) {
                // If controlled, call onChange to clear out the input
                onChange({ event, name, value: '' });
            }
        }

        if (onKeyDown) onKeyDown(event);
        target.focus();
    }

    render() {
        const {
            autoComplete,
            autoFocus,
            className,
            clearable,
            containerStyle,
            disabled,
            error,
            icon,
            iconColor,
            iconRight,
            iconRightColor,
            id,
            label,
            maxLength,
            name,
            onKeyUp,
            placeholder,
            styles,
            tabIndex,
            tooltipComponent,
            tooltipOptions,
            type,
            value
        } = this.props;
        const { isFocused, inputValue } = this.state;
        return (
            <TextInputUI
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className={className}
                clearable={clearable}
                containerStyle={containerStyle}
                disabled={disabled}
                error={error}
                icon={icon}
                iconColor={iconColor}
                iconRight={iconRight}
                iconRightColor={iconRightColor}
                id={id}
                inputRef={this.inputRef}
                isFocused={isFocused}
                label={label}
                maxLength={maxLength}
                name={name}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onClear={this.handleClear}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyDown}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                styles={styles}
                tabIndex={tabIndex}
                tooltipComponent={tooltipComponent}
                tooltipOptions={tooltipOptions}
                type={type}
                value={value || inputValue}
            />
        );
    }
}

export const TextInputContainer = compose()(TextInput);
