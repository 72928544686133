import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'events';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { matchPath, Redirect } from 'react-router-dom';
import { externalRoutes, routes } from 'routes';
import { validateEmail, get } from 'utils';
import { withMobileBridge } from 'provider/mobileBridge';
import { withUrlContext } from 'hoc/url';
import { withLogin, withLogout, withGetUser } from 'graphql/user';
import { AuthUI } from './ui';

export class Auth extends PureComponent {
    static displayName = 'AuthContainer';

    static propTypes = {
        bridge: PropTypes.instanceOf(EventEmitter).isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        login: PropTypes.func.isRequired,
        loginAs: PropTypes.string,
        loginRoute: PropTypes.string,
        loginUrl: PropTypes.string,
        logout: PropTypes.func.isRequired,
        redirectTo: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any),
        userRefresh: PropTypes.func.isRequired
    };

    static defaultProps = {
        loginAs: null,
        loginRoute: null,
        loginUrl: null,
        redirectTo: null,
        user: null
    };

    constructor(props) {
        super(props);

        this.updatePassword = this.updatePassword.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.checkReady = this.checkReady.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            deactivated: false,
            loginFailed: false,
            loading: false,
            emailInput: '',
            passwordInput: '',
            formDisabled: true,
            errorEmail: null,
            errorPassword: null
        };
    }

    componentDidMount() {
        const { loginAs, userRefresh } = this.props;
        if (loginAs) {
            window.sessionStorage.asAieraUser = loginAs;
            userRefresh();
        }
        this.checkLogout();
    }

    componentDidUpdate() {
        this.checkLogout();
    }

    checkLogout() {
        const { bridge, logout, history, location, loginUrl, redirectTo } = this.props;
        if (matchPath(location.pathname, { path: routes.signOut, exact: true })) {
            logout().then(() => {
                if (loginUrl) {
                    let loginRedirect = loginUrl;
                    if (redirectTo && window.URLSearchParams) {
                        const url = new URL(loginUrl);
                        url.searchParams.set('next', redirectTo);
                        loginRedirect = url.toString();
                    }
                    window.location = loginRedirect;
                } else {
                    history.push(bridge.enabled() ? externalRoutes.mobileWelcome : externalRoutes.signIn);
                }
            });
        }
    }

    onSubmit(e) {
        const { login } = this.props;
        const { formDisabled, emailInput, passwordInput } = this.state;

        // DON'T REFRESH PAGE ON ENTER
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.checkErrors() && !formDisabled) {
            this.setState({
                loading: true,
                loginFailed: false
            });
            login(emailInput, passwordInput)
                .catch(err => {
                    this.setState({
                        deactivated: get(err, 'graphQLErrors[0].code') === 403,
                        formDisabled: true,
                        loginFailed: true
                    });
                })
                .finally(() => this.setState({ loading: false }));
        } else {
            this.setState({
                formDisabled: true,
                loginFailed: true
            });
        }
    }

    checkEmail() {
        const { emailInput, errorEmail } = this.state;

        // NO EMAIL
        if (!emailInput || (emailInput && emailInput.length === 0)) {
            this.setState({
                errorEmail: 'Email or username required',
                formDisabled: true
            });

            return false;
        }

        // Disable format validation to support non-email usernames (e.g. Eze)
        // INVALID EMAIL
        // if (!validateEmail(emailInput)) {
        //     this.setState({
        //         errorEmail: 'Invalid format',
        //         formDisabled: true
        //     });
        //
        //     return false;
        // }

        // CLEAR EMAIL ERROR
        if (errorEmail && errorEmail.length > 1 && validateEmail(emailInput)) {
            this.setState({
                errorEmail: null,
                formDisabled: false
            });
        }

        return true;
    }

    checkPassword() {
        const { passwordInput, errorPassword } = this.state;

        // NO PASSWORD
        if (!passwordInput || (passwordInput && passwordInput.length === 0)) {
            this.setState({
                errorPassword: 'Password required',
                formDisabled: true
            });

            return false;
        }

        // CLEAR PASSWORD ERROR
        if (errorPassword) {
            this.setState({
                errorPassword: null
            });
        }

        return true;
    }

    checkErrors(type) {
        if (type === 'email') {
            return this.checkEmail();
        }

        if (type === 'password') {
            return this.checkPassword();
        }

        return this.checkEmail() && this.checkPassword();
    }

    checkReady() {
        const { emailInput, passwordInput, formDisabled, errorEmail, errorPassword } = this.state;
        let formDisabledNewVal = false;

        // REMOVE EXISTING EMAIL ERROR IF FIXED
        if (errorEmail && errorEmail.length > 1) {
            formDisabledNewVal = !this.checkErrors('email');
        }

        // REMOVE EXISTING PASSWORD ERROR IF FIXED
        if (errorPassword && errorPassword.length > 1) {
            formDisabledNewVal = !this.checkErrors('password');
        }

        // EMAIL || PASSWORD EMPTY?
        if (emailInput.length === 0 || passwordInput.length === 0) {
            formDisabledNewVal = true;
        }

        // SAVE
        if (formDisabledNewVal !== formDisabled) {
            this.setState({
                formDisabled: formDisabledNewVal
            });
        }
    }

    updatePassword({ value: passwordInput }) {
        this.setState({ passwordInput }, this.checkReady);
    }

    updateEmail({ value: emailInput }) {
        this.setState({ emailInput }, this.checkReady);
    }

    render() {
        const { bridge, user, loginRoute, location, redirectTo, ...rest } = this.props;
        const {
            deactivated,
            loading,
            formDisabled,
            errorEmail,
            errorPassword,
            loginFailed,
            emailInput,
            passwordInput
        } = this.state;

        if (user && user.userId && !matchPath(location.pathname, { path: routes.signOut, exact: true })) {
            if (redirectTo) {
                window.location = redirectTo;
            } else {
                return <Redirect to={loginRoute || routes.root} />;
            }
        }

        return (
            <AuthUI
                {...rest}
                deactivated={deactivated}
                emailInput={emailInput}
                errorEmail={errorEmail}
                errorPassword={errorPassword}
                formDisabled={formDisabled}
                isMobile={bridge.enabled()}
                loading={loading}
                loginFailed={loginFailed}
                onBlurEmail={() => this.checkErrors('email')}
                onBlurPassword={() => this.checkErrors('password')}
                onSubmit={this.onSubmit}
                passwordInput={passwordInput}
                updateEmail={this.updateEmail}
                updatePassword={this.updatePassword}
            />
        );
    }
}

function mapStateToProps({ App }) {
    const { loginRoute } = App;
    return {
        loginRoute
    };
}

export const AuthContainer = compose(
    withUrlContext(['history', 'location', 'loginAs', 'redirectTo']),
    connect(mapStateToProps),
    withLogin(),
    withLogout(),
    withGetUser({
        fetchPolicy: 'cache-first',
        variables: { withLoginUrl: true }
    }),
    withMobileBridge()
)(Auth);
