import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { getQuestionsQuery } from 'graphql/askAiera';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withGetTemplateQuestions($eventId: ID) {
                    events(eventIds: [$eventId]) {
                        id
                        questionTemplates {
                            id
                            question
                            templateType
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const questionTemplates = get(data, 'events.[0].questionTemplates', []);
                    return { questionTemplates };
                },
                options: ({ eventId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        eventId
                    }
                })
            }
        ),
        graphql(getQuestionsQuery, {
            props: ({ data }) => {
                const questions = get(data, 'getQuestions', []);
                return {
                    questions
                };
            },
            options: ({ eventId }) => ({
                fetchPolicy: 'cache-and-network',
                variables: {
                    startDate: undefined,
                    endDate: undefined,
                    eventId
                }
            })
        }),
        withPropsOnChange(['questionTemplates', 'questions'], ({ questionTemplates, questions }) => {
            const rawQuestionBank = questionTemplates.map(({ question }) => question.toLowerCase());
            const questionBank = new Set(rawQuestionBank);
            questions.forEach(({ question }) => {
                if (questionBank.has(question.toLowerCase())) {
                    questionBank.delete(question.toLowerCase());
                }
            });

            return {
                questionBank: [...questionBank]
            };
        }),
        graphql(
            gql`
                mutation AskQuestion($eventId: ID!, $question: String!, $isTemplate: MakeTemplate) {
                    askQuestion(eventId: $eventId, question: $question, isTemplate: $isTemplate) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, eventId } = ownProps;
                    return {
                        askQuestion: (question, template) =>
                            mutate({
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        variables: {
                                            eventId,
                                            startDate: undefined,
                                            endDate: undefined
                                        },
                                        query: getQuestionsQuery
                                    }
                                ],
                                variables: {
                                    eventId,
                                    question,
                                    isTemplate: template
                                }
                            })
                                .then(() => {
                                    setStatusBanner('Question asked successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error asking question: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
