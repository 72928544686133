import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { withStyleSheet } from 'hoc/styles';
import { mapStylesProp } from 'utils/styles';
import { styleSheet } from './stylesheet';

export const H1 = styled('h1')(mapStylesProp);
export const H2 = styled('h2')(mapStylesProp);
export const H3 = styled('h3')(mapStylesProp);

class Heading extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.number,
        className: PropTypes.string
    };

    static defaultProps = {
        size: 1,
        passedStyles: {},
        className: undefined
    };

    render() {
        const { styles, passedStyles, size, children, className } = this.props;

        if (size === 2) {
            return (
                <H2 className={className} styles={{ ...styles.h2, ...passedStyles }}>
                    {children}
                </H2>
            );
        }

        if (size === 3) {
            return (
                <H3 className={className} styles={{ ...styles.h3, ...passedStyles }}>
                    {children}
                </H3>
            );
        }

        return (
            <H1 className={className} styles={{ ...styles.h1, ...passedStyles }}>
                {children}
            </H1>
        );
    }
}

export const HeadingUI = compose(withStyleSheet(styleSheet))(Heading);
