import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Dropdown } from 'components/Dropdown';
import { Textarea } from 'components/Textarea';
import { ActionButton } from 'components/ActionButton';
import { DropdownOptionWrap } from 'components/DropdownOptionWrap';
import { capitalize } from 'utils';
import { styleSheet } from './stylesheet';

class AskAieraForm extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        question: PropTypes.string.isRequired,
        questionBank: PropTypes.arrayOf(PropTypes.any).isRequired,
        onChangeQuestion: PropTypes.func.isRequired,
        onAskQuestion: PropTypes.func.isRequired,
        textareaRef: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const {
            passedStyles,
            styles,
            questionBank,
            onChangeQuestion,
            textareaRef,
            onAskQuestion,
            question
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {questionBank && questionBank.length > 0 && (
                    <Dropdown
                        placeholder="Browse Questions"
                        OptionComponent={DropdownOptionWrap}
                        styles={styles.previousQuestions}
                        disableHoverOpen
                        label="Question Templates"
                        options={questionBank.map(q => ({ label: capitalize(q), value: q }))}
                        onChange={a => onChangeQuestion(a, true)}
                    />
                )}
                <Div styles={styles.askFormFocusCheck}>
                    <Textarea
                        label="Ask about this transcript"
                        ref={textareaRef}
                        onKeyDown={e => {
                            if (e.nativeEvent.code === 'Enter' || e.nativeEvent.code === 'NumpadEnter') {
                                e.preventDefault();
                                e.stopPropagation();
                                onAskQuestion();
                            }
                        }}
                        onChange={onChangeQuestion}
                        value={question}
                        name="Ask Aiera"
                    />
                    <Div styles={styles.askFormFooter}>
                        {question && question.length > 0 && (
                            <Text className="askFormEnter">Press &apos;Enter&apos; or</Text>
                        )}
                        <ActionButton
                            name="ask-aiera-submit"
                            onClick={onAskQuestion}
                            styles={styles.askButton}
                            disabled={!question || question.length === 0}
                        >
                            <Text size={1} weight="medium">
                                Ask Aiera
                            </Text>
                        </ActionButton>
                    </Div>
                </Div>
            </Div>
        );
    }
}

export const AskAieraFormUI = compose(withStyleSheet(styleSheet))(AskAieraForm);
