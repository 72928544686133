export const styleSheet = theme => ({
    container: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 4,
        background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 70%)'
    },
    notes: {
        maxWidth: 350,
        color: theme.colors.white01,
        marginLeft: theme.margins.margin01,
        '> p': {
            lineHeight: '1.3em',
            fontSize: theme.fonts.fontSizes.size03,
            textShadow: '0 0 14px #000'
        },
        '> span': {
            display: 'block',
            lineHeight: '1.3em',
            fontSize: theme.fonts.fontSizes.size03,
            textShadow: '0 0 14px #000'
        }
    },
    dashAnalysis: {
        backgroundColor: theme.colors.gray12,
        borderRadius: 6,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30
    },
    continueBtn: {
        backgroundColor: theme.colors.blue08,
        color: theme.colors.white01,
        display: 'inline-flex',
        padding: theme.margins.margin01,
        marginTop: theme.margins.margin01,
        marginRight: theme.margins.margin00,
        boxShadow: 'unset',
        p: {
            fontSize: theme.fonts.fontSizes.size03,
            textShadow: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.blue10
        },
        ':active': {
            backgroundColor: theme.colors.blue11
        }
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        '> p': {
            marginTop: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size01,
            opacity: 0.7,
            marginLeft: theme.margins.margin00
        }
    },
    homeTipStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 40,
        left: -30,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    homeTipSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 230,
        width: 230,
        marginLeft: -30,
        marginTop: -80,
        marginRight: 20,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.7)',
        borderRadius: 200
    },
    createTipStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 255,
        left: 10,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    createTipSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 48,
        width: 124,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 12
    },
    streamsTipStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 40,
        left: 235,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    streamsTipSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 300,
        width: 320,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 16
    },
    dashFilterStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 0,
        left: 242,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '> div': {
            '> span': {
                textShadow: '2px 2px 2px rgb(0, 0, 0)'
            },
            '> p': {
                textShadow: '2px 2px 2px rgb(0, 0, 0)'
            }
        }
    },
    dashFilterSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 46,
        width: 416,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 12
    },
    contextStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    contextSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 46,
        width: 352,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 16
    },
    galleryStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 220,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    gallerySpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 40,
        width: 110,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 20
    },
    aieracastSpotLight: {
        transition: 'all 0.2s ease-in-out',
        height: 40,
        width: 250,
        boxShadow: '0 0 0px 4000px rgba(0, 0, 0, 0.65)',
        borderRadius: 20
    },
    aieracastStyle: {
        transition: 'all 0.2s ease-in-out',
        position: 'fixed',
        top: 101,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    iconBlock: {
        marginRight: 10,
        height: 30,
        width: 30,
        borderRadius: 6,
        background: theme.colors.gray11,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconContent: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.2em'
    },
    setupWatchlistBtn: {
        ...theme.content.blueButton,
        color: theme.colors.white01,
        marginBottom: 10,
        boxShadow: 'unset',
        borderRadius: 8
    },
    welcomeModal: {
        padding: 20,
        backgroundColor: theme.colors.white01,
        maxWidth: '600px',
        width: '100%',
        borderRadius: 12,
        textAlign: 'center'
    },
    welcomeTitle: {
        marginTop: 10,
        marginBottom: 6
    },
    welcomeVideo: {
        borderRadius: 8,
        margin: '20px 10px 30px',
        height: 360,
        overflow: 'hidden',
        display: 'flex',
        flex: 1,
        backgroundColor: theme.colors.gray12,
        padding: 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    welcomeButtons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        margin: 20
    },
    welcomeDescription: {
        marginTop: 6,
        lineHeight: '1.3em'
    },
    savingDefault: {
        marginTop: 10
    },
    defaultBtn: {
        boxShadow: 'unset',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    defaultBtnNote: {
        marginTop: 4,
        opacity: 0.6
    }
});
