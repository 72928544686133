import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import isArray from 'lodash/isArray';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { BarChart } from 'components/BarChart';
import { Td } from 'components/Td';
import { Tr } from 'components/Tr';

import { get, toDurationString } from 'utils';
import { styleSheet } from './stylesheet';

class ExpandedDifferentialRow extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        chartHeight: PropTypes.number,
        labels: PropTypes.arrayOf(PropTypes.any),
        data: PropTypes.arrayOf(PropTypes.any),
        highlights: PropTypes.arrayOf(PropTypes.any),
        onChartDataPointClick: PropTypes.func,
        onNodeSelect: PropTypes.func,
        toggleMore: PropTypes.func.isRequired,
        showMore: PropTypes.bool.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        labels: [],
        highlights: [],
        data: [],
        chartHeight: undefined,
        onChartDataPointClick: null,
        onNodeSelect: undefined
    };

    renderHighlights() {
        const { styles, highlights, onNodeSelect, showMore, toggleMore } = this.props;

        if (isArray(highlights) && highlights.length > 0) {
            const visibleHighlights = showMore ? [...highlights] : [...highlights].slice(0, 5);
            const howManyMore = highlights.length - 5;
            return visibleHighlights.map((h, index) => {
                const { item, text, itemId, id, highlights: textHighlights = [], transcript } = h;
                const seconds = get(item, 'startMs', get(transcript, 'startMs', 0)) / 1000;
                const time = toDurationString(seconds);
                const highlightId = itemId || id;
                const onClick =
                    onNodeSelect && highlightId ? () => onNodeSelect({ id: highlightId, withScroll: true }) : undefined;
                const highlight = text || textHighlights.join(' ');
                return (
                    <Fragment key={`${time}-${highlightId}`}>
                        <Div styles={styles.highlight}>
                            <Div styles={styles.highlightInfo}>
                                {time && (
                                    <Text weight="medium" styles={styles.dateText} size={0} uppercase onClick={onClick}>
                                        {`@ ${time}`}
                                    </Text>
                                )}
                                {onClick && (
                                    <Text styles={styles.jumpText} size={0} uppercase onClick={onClick}>
                                        jump to
                                    </Text>
                                )}
                                <Div styles={styles.spacer} />
                                <Text styles={styles.matchNumberText} size={0} uppercase>
                                    {index + 1} of {highlights.length}
                                </Text>
                            </Div>
                            {
                                // eslint-disable-next-line react/no-danger
                                <div dangerouslySetInnerHTML={{ __html: unescape(highlight) }} />
                            }
                        </Div>
                        {index === 4 && (
                            <Div styles={styles.showMore} onClick={toggleMore}>
                                <Div styles={styles.showMoreButton}>
                                    <Text size={0} uppercase>
                                        {showMore ? 'show less' : `show ${howManyMore} more`}
                                    </Text>
                                </Div>
                            </Div>
                        )}
                    </Fragment>
                );
            });
        }

        return null;
    }

    render() {
        const { styles, passedStyles, labels, data, chartHeight, onChartDataPointClick } = this.props;
        return (
            <Tr styles={{ ...styles.container, ...passedStyles }}>
                <Td colSpan={6}>
                    <BarChart
                        labels={labels}
                        data={data}
                        height={chartHeight}
                        onSeriesPointClick={onChartDataPointClick}
                    />
                    {this.renderHighlights()}
                </Td>
            </Tr>
        );
    }
}

export const ExpandedDifferentialRowUI = compose(withStyleSheet(styleSheet))(ExpandedDifferentialRow);
