export const styleSheet = theme => {
    const resultTd = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: theme.fonts.fontSizes.size03,
        paddingTop: 12,
        paddingBottom: 12,
        lineHeight: '1.3em',
        paddingRight: theme.margins.margin01,
        ':first-of-type': {
            verticalAlign: 'top',
            width: 44,
            paddingLeft: theme.margins.margin00,
            paddingRight: theme.margins.margin00
        },
        ':last-of-type': {
            paddingRight: 14
        }
    };

    return {
        results: {
            width: '100%'
        },
        result: {
            td: {
                ...resultTd
            },
            ':nth-of-type(even)': {
                td: {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        resultHighlighted: {
            td: {
                ...resultTd,
                backgroundColor: theme.colors.blue08,
                color: theme.colors.white01,
                div: {
                    color: theme.colors.white01
                },
                em: {
                    color: theme.colors.black01
                },
                p: {
                    color: theme.colors.white01
                },
                '.equityInitials': {
                    color: theme.colors.gray06
                }
            }
        },
        preview: {
            backgroundColor: theme.colors.white01,
            marginLeft: 1
        },
        previewBlock: {
            padding: theme.margins.margin00,
            borderBottom: `1px solid ${theme.colors.gray03}`,
            ':last-of-type': {
                borderBottom: 'unset'
            }
        },
        previewHeader: {
            marginTop: 'unset'
        },
        previewLabel: {
            color: theme.colors.gray06,
            letterSpacing: 1,
            marginBottom: 4
        },
        previewSubtitle: {
            lineHeight: '1.3em'
        },
        equityInitials: {
            backgroundColor: theme.colors.gray03,
            color: theme.colors.gray06,
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        companyIcon: {
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            img: {
                maxHeight: 24
            }
        },
        resultHeaders: {
            th: {
                position: 'sticky',
                top: 0,
                backgroundColor: theme.colors.gray05,
                height: 30,
                verticalAlign: 'middle',
                textTransform: 'uppercase',
                fontSize: theme.fonts.fontSizes.size01,
                textAlign: 'left',
                letterSpacing: 1,
                color: theme.colors.gray06,
                paddingRight: theme.margins.margin01,
                ':first-of-type': {
                    paddingLeft: theme.margins.margin00
                },
                ':last-of-type': {
                    paddingRight: 14,
                    textAlign: 'right'
                }
            }
        },
        resultTop: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        transcript: {
            ...theme.content.annotated,
            color: theme.colors.gray08,
            marginTop: theme.margins.margin00,
            fontSize: theme.fonts.fontSizes.size01,
            lineHeight: '1.3em'
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            padding: '12px 0',
            div: {
                margin: 'auto'
            }
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            margin: 10,
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        eventDateTime: {
            textAlign: 'right',
            p: {
                lineHeight: '1.2em'
            }
        },
        eventTime: {
            color: theme.colors.gray06
        },
        subTitle: {
            color: theme.colors.gray06,
            lineHeight: '1.2em'
        }
    };
};
