import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EventPriceAlertUI } from './ui';

const DING = 'http://dmpblu9l4ptlr.cloudfront.net/91926__tim-kahn__ding.wav';

export class EventPriceAlert extends PureComponent {
    static displayName = 'EventPriceAlertContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        price: PropTypes.string,
        priceMovementPercent: PropTypes.number,
        priceMovementAbsolute: PropTypes.number,
        dismissPriceAlert: PropTypes.func.isRequired
    };

    static defaultProps = {
        styles: undefined,
        price: undefined,
        priceMovementPercent: undefined,
        priceMovementAbsolute: undefined
    };

    componentDidMount() {
        const audio = new Audio();
        audio.src = DING;
        audio.play();
    }

    render() {
        const { styles, price, priceMovementAbsolute, priceMovementPercent, dismissPriceAlert } = this.props;
        return (
            <EventPriceAlertUI
                dismissPriceAlert={dismissPriceAlert}
                styles={styles}
                price={price}
                priceMovementAbsolute={priceMovementAbsolute}
                priceMovementPercent={priceMovementPercent}
            />
        );
    }
}

export const EventPriceAlertContainer = compose()(EventPriceAlert);
