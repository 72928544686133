import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId } from 'utils';
import { OptionUI } from './ui';

export class Option extends PureComponent {
    static displayName = 'OptionContainer';

    static propTypes = {
        disabled: PropTypes.bool,
        env: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        lockedPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        lockedModalId: PropTypes.string,
        onClick: PropTypes.func,
        permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        preferences: PropTypes.objectOf(PropTypes.any),
        prefixIcon: PropTypes.bool,
        selected: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        tabId: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        disabled: false,
        env: undefined,
        lockedModalId: undefined,
        lockedPermission: undefined,
        onClick: null,
        permission: undefined,
        preferences: undefined,
        prefixIcon: false,
        selected: false,
        styles: {},
        tabId: undefined,
        type: undefined
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleLockedClick = this.handleLockedClick.bind(this);
    }

    // Let the parent know we've been clicked
    handleClick(event) {
        const { onClick, value } = this.props;
        if (onClick) onClick({ value, event });
    }

    handleLockedClick() {
        const { tabId, lockedModalId, history, location } = this.props;
        if (lockedModalId) {
            history.push(generateModalId({ location, tabId, id: lockedModalId, type: 'upgrade' }));
        }
    }

    render() {
        const {
            children,
            disabled,
            env,
            lockedPermission,
            permission,
            preferences,
            prefixIcon,
            selected,
            styles,
            type
        } = this.props;
        return (
            <OptionUI
                disabled={disabled}
                env={env}
                lockedPermission={lockedPermission}
                onClick={this.handleClick}
                onLockedClick={this.handleLockedClick}
                permission={permission}
                preferences={preferences}
                prefixIcon={prefixIcon}
                selected={selected}
                styles={styles}
                type={type}
            >
                {children}
            </OptionUI>
        );
    }
}

export const OptionContainer = compose(withUrlContext(['history', 'location', 'tabId']))(Option);
