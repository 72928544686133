import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { FilingHeaderUI } from './ui';

export class FilingHeader extends PureComponent {
    static displayName = 'FilingHeaderContainer';

    static propTypes = {
        arrivalDate: PropTypes.string,
        company: PropTypes.string,
        companyId: PropTypes.string,
        exchange: PropTypes.string,
        formNumber: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        periodEndDate: PropTypes.string,
        ticker: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        arrivalDate: null,
        company: null,
        companyId: null,
        exchange: null,
        formNumber: null,
        loading: false,
        periodEndDate: null,
        ticker: null,
        title: null
    };

    constructor(props) {
        super(props);

        this.onTickerClick = this.onTickerClick.bind(this);
    }

    onTickerClick() {
        const { companyId, history } = this.props;
        if (companyId) {
            history.push(generatePath(routes.company, { companyId }));
        }
    }

    render() {
        const {
            arrivalDate,
            company,
            companyId,
            exchange,
            formNumber,
            history,
            loading,
            periodEndDate,
            ticker,
            title
        } = this.props;
        return (
            <FilingHeaderUI
                arrivalDate={arrivalDate}
                company={company}
                companyId={companyId}
                exchange={exchange}
                formNumber={formNumber}
                history={history}
                loading={loading}
                onTickerClick={this.onTickerClick}
                periodEndDate={periodEndDate}
                ticker={ticker}
                title={title}
            />
        );
    }
}

export const FilingHeaderContainer = compose(withUrlContext(['history']))(FilingHeader);
