export const breakpoints = {
    external: {
        mobile: '480px',
        mobileEdge: '481px',
        tablet: '800px',
        tabletEdge: '801px',
        desktop: '1024px',
        desktopEdge: '1025px',
        xLarge: '1440px',
        xLargeEdge: '1441px'
    },
    internal: {
        mobile: '480px',
        mobileEdge: '481px',
        tablet: '1000px',
        tabletEdge: '1001px',
        desktop: '1024px',
        desktopEdge: '1025px',
        xLarge: '1440px',
        xLargeEdge: '1441px',
        maxContentWidth: '1000px'
    }
};
