import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PERMISSIONS } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Checkbox } from 'components/Checkbox';
import { CloneStreamTooltip } from 'components/CloneStreamTooltip';
import { ColorMenu } from 'components/ColorMenu';
import { CustomStreamForm } from 'components/CustomStreamForm';
import { DashboardDropdown } from 'components/DashboardDropdown';
import { Div } from 'components/Div';
import { EquityScopeAutocomplete } from 'components/EquityScopeAutocomplete';
import { ExternalLink } from 'components/ExternalLink';
import { FilingFormAutocomplete } from 'components/FilingFormAutocomplete';
import { FiltersOld } from 'components/FiltersOld';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { PreviewStream } from 'components/PreviewStream';
import { SearchCommandLegend } from 'components/SearchCommandLegend';
import { ShareableLinkField } from 'components/ShareableLinkField';
import { SpotlightTypeAutocomplete } from 'components/SpotlightTypeAutocomplete';
import { TaggedInput } from 'components/TaggedInput';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Notice } from 'components/Notice';
import { WithPermission } from 'components/WithPermission';
import { get } from 'utils';

import { styleSheet } from './stylesheet';

const GSHEET_TEMPLATE =
    'https://docs.google.com/spreadsheets/d/1Gu6-xJmw499moMzUzF8i53WX2-ovmoPzk5RY1IFkBGM/edit?usp=sharing';

class StreamForm extends PureComponent {
    static propTypes = {
        bookmarkTags: PropTypes.arrayOf(PropTypes.string),
        bookmarkTypeOptions: PropTypes.objectOf(PropTypes.any).isRequired,
        buttonsDisabled: PropTypes.bool.isRequired,
        clearTags: PropTypes.func.isRequired,
        contentTypeLabelMap: PropTypes.objectOf(PropTypes.any).isRequired,
        contentTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
        copySearchTerms: PropTypes.func.isRequired,
        customData: PropTypes.objectOf(PropTypes.any),
        dashboard: PropTypes.objectOf(PropTypes.any),
        dashboardId: PropTypes.string,
        dashboardType: PropTypes.string.isRequired,
        deleting: PropTypes.bool.isRequired,
        equityScope: PropTypes.arrayOf(PropTypes.any),
        errors: PropTypes.objectOf(PropTypes.any),
        filingForms: PropTypes.arrayOf(PropTypes.any).isRequired,
        filters: PropTypes.arrayOf(PropTypes.object).isRequired,
        gSheetSource: PropTypes.string,
        ignoreDashFilters: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isSubmitButtonDisabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onBack: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onCustomChange: PropTypes.func.isRequired,
        onChangeGSheet: PropTypes.func.isRequired,
        onChangeFilters: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onEquityScopeChange: PropTypes.func.isRequired,
        onFilingFormChange: PropTypes.func.isRequired,
        onSelectBookmarkType: PropTypes.func.isRequired,
        onSelectColor: PropTypes.func.isRequired,
        onSelectContentTypes: PropTypes.func.isRequired,
        onSelectDashboard: PropTypes.func.isRequired,
        onSelectType: PropTypes.func.isRequired,
        onSetSearchTerms: PropTypes.func.isRequired,
        onSpotlightTypeChange: PropTypes.func.isRequired,
        onTagChange: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        page: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        previewFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
        searchTerms: PropTypes.arrayOf(PropTypes.string),
        searchTermsCopied: PropTypes.bool.isRequired,
        selectedBookmarkType: PropTypes.string.isRequired,
        selectedColor: PropTypes.string,
        selectedDashboard: PropTypes.string,
        setBookmarkTags: PropTypes.func.isRequired,
        shareableLink: PropTypes.string,
        showSearchTerms: PropTypes.bool.isRequired,
        spotlightTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
        stream: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
            rules: PropTypes.arrayOf(
                PropTypes.shape({
                    ruleType: PropTypes.string.isRequired,
                    condition: PropTypes.string.isRequired,
                    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired
                })
            ),
            streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            streamType: PropTypes.string.isRequired,
            uxPreferences: PropTypes.shape({
                color: PropTypes.string
            })
        }),
        streamId: PropTypes.string,
        streamType: PropTypes.string.isRequired,
        streamTypeLabelMap: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        submitting: PropTypes.bool.isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        toggleFilterMode: PropTypes.func.isRequired,
        touched: PropTypes.objectOf(PropTypes.any),
        values: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        bookmarkTags: [],
        customData: {},
        dashboard: undefined,
        dashboardId: null,
        equityScope: undefined,
        errors: {},
        gSheetSource: undefined,
        page: null,
        passedStyles: {},
        searchTerms: [],
        selectedColor: undefined,
        selectedDashboard: undefined,
        shareableLink: null,
        stream: null,
        streamId: null,
        touched: {},
        values: {}
    };

    renderModalButtons({ onBack, onCancel, onContinue, onCreate, onDelete, onUpdate }) {
        const {
            styles,
            theme,
            buttonsDisabled,
            deleting,
            loading,
            submitting,
            isSubmitButtonDisabled,
            dashboard,
            dashboardId,
            page,
            stream
        } = this.props;
        return (
            <Div styles={styles.modalButtons}>
                {onDelete && (
                    <ActionButton
                        disabled={buttonsDisabled}
                        loading={deleting}
                        onClick={onDelete}
                        size={2}
                        styles={styles.button}
                    >
                        <Icon type="trash02" color={theme.colors.gray04} />
                        <Text size={3}>Remove</Text>
                    </ActionButton>
                )}
                {dashboardId && stream && page !== 'shareStream' && (
                    <CloneStreamTooltip
                        currentDashboard={dashboard}
                        dashboardId={dashboardId}
                        onSubmit={onCancel}
                        streamId={get(stream, 'streamId')}
                    >
                        {({ showTooltip }) => (
                            <ActionButton
                                disabled={buttonsDisabled}
                                onClick={showTooltip}
                                size={2}
                                styles={styles.button}
                            >
                                <Icon type="copy" color={theme.colors.gray04} />
                                <Text size={3}>Clone</Text>
                            </ActionButton>
                        )}
                    </CloneStreamTooltip>
                )}
                {onBack && (
                    <ActionButton disabled={buttonsDisabled} onClick={onBack} size={2} styles={styles.backButton}>
                        <Icon type="chevron02" color={theme.colors.black01} />
                        <Text size={3}>Back</Text>
                    </ActionButton>
                )}
                <Div styles={styles.spacer} />
                {onCancel && (
                    <ActionButton disabled={buttonsDisabled} onClick={onCancel} size={2} styles={styles.cancelButton}>
                        <Text size={3}>Cancel</Text>
                    </ActionButton>
                )}
                {onContinue && (
                    <ActionButton styles={styles.button} size={2} loading={loading} onClick={onContinue}>
                        <Text size={3}>Configure</Text>
                    </ActionButton>
                )}
                {onCreate && (
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSave,
                            ...(isSubmitButtonDisabled ? styles.buttonDisabled : {})
                        }}
                        size={2}
                        disabled={isSubmitButtonDisabled}
                        loading={submitting}
                        onClick={onCreate}
                    >
                        <Text size={3}>Create</Text>
                    </ActionButton>
                )}
                {onUpdate && (
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSave,
                            ...(isSubmitButtonDisabled ? styles.buttonDisabled : {})
                        }}
                        size={2}
                        disabled={isSubmitButtonDisabled}
                        loading={submitting}
                        onClick={onUpdate}
                    >
                        <Text size={3}>Save Changes</Text>
                    </ActionButton>
                )}
            </Div>
        );
    }

    renderModalHeader({ title }) {
        const { styles, theme, onCancel } = this.props;
        return (
            <Div styles={styles.modalHeader}>
                <Div styles={styles.titleClose}>
                    <Heading size={1}>{title}</Heading>
                    <Div styles={styles.closeModal} onClick={onCancel}>
                        <Icon type="xMark" color={theme.colors.black01} />
                    </Div>
                </Div>
            </Div>
        );
    }

    renderBookmarkTypeSelector() {
        const { bookmarkTypeOptions, onSelectBookmarkType, selectedBookmarkType, styles } = this.props;
        const options = Object.keys(bookmarkTypeOptions).map(type => (
            <Option
                styles={type === selectedBookmarkType ? styles.optionSelected : styles.option}
                value={type}
                permission={bookmarkTypeOptions[type].permission}
                key={`bookmarkType-${type}`}
                type="radio"
            >
                <Div styles={styles.optionBookmarkContainer}>
                    <Text size={3}>{bookmarkTypeOptions[type].label}</Text>
                    <Text styles={styles.optionDescriptionBookmark} size={1}>
                        {bookmarkTypeOptions[type].description}
                    </Text>
                </Div>
            </Option>
        ));

        return (
            <Div styles={styles.selectContentType}>
                <MultiSelect
                    name="selectedBookmarkType"
                    onChange={onSelectBookmarkType}
                    selected={[selectedBookmarkType]}
                    required
                >
                    {options}
                </MultiSelect>
            </Div>
        );
    }

    renderContentTypeSelector() {
        const { styles, onSelectContentTypes, contentTypes, contentTypeLabelMap } = this.props;

        // We only want to include news if the stream already has news
        const optionTypes = Object.keys(contentTypeLabelMap).filter(
            type => type !== 'news' || contentTypes.includes('news')
        );

        const options = optionTypes.map(type => (
            <Option
                key={`contentType-${type}`}
                styles={contentTypes.includes(type) ? styles.optionSelected : styles.option}
                value={type}
                type="checkbox"
                lockedPermission={contentTypeLabelMap[type].lockedPermission}
                lockedModalId={type}
            >
                <Div>
                    <Text size={3}>{contentTypeLabelMap[type].label}</Text>
                    <Text styles={styles.optionDescription} size={1}>
                        {contentTypeLabelMap[type].description}
                    </Text>
                </Div>
            </Option>
        ));

        return (
            <Div styles={styles.selectContentType}>
                <MultiSelect
                    label="content types"
                    multi
                    name="selectContentType"
                    onChange={onSelectContentTypes}
                    selected={contentTypes}
                >
                    {options}
                </MultiSelect>
            </Div>
        );
    }

    renderStreamTypeSelector() {
        const { styles, streamType, onSelectType, streamTypeLabelMap } = this.props;
        const options = Object.keys(streamTypeLabelMap).map(type => (
            <Option
                styles={type === streamType ? styles.streamOptionSelected : styles.streamOption}
                value={type}
                permission={streamTypeLabelMap[type].permission}
                lockedPermission={streamTypeLabelMap[type].lockedPermission}
                lockedModalId={type}
                key={`streamType-${type}`}
                type="radio"
            >
                <Div>
                    <Text size={3}>{streamTypeLabelMap[type].label}</Text>
                    <Text styles={styles.streamOptionDescription} size={1}>
                        {streamTypeLabelMap[type].description}
                    </Text>
                </Div>
            </Option>
        ));

        return (
            <Div styles={styles.selectType}>
                <MultiSelect name="selectStreamType" onChange={onSelectType} selected={[streamType]} required>
                    {options}
                </MultiSelect>
            </Div>
        );
    }

    renderGSheetForm() {
        const { styles, onChangeGSheet, theme, gSheetSource } = this.props;

        return (
            <Div styles={styles.gSheetForm}>
                <Notice type="info" richContent styles={styles.gSheetInstruction}>
                    <Div>
                        <Text size={3}>To connect your Google Sheet, first share it with</Text>
                        <Text size={3} styles={styles.gSheetEmail}>
                            aiera-api-access@aiera-256022.iam.gserviceaccount.com
                        </Text>
                        <Text size={3}>Next paste the URL to the Google Sheet below.</Text>
                    </Div>
                    <Div styles={styles.gSheetLink}>
                        <Icon type="spreadsheet" color={theme.colors.black01} />
                        <ExternalLink href={GSHEET_TEMPLATE}>
                            <Text size={1}>
                                View
                                <br /> Template
                            </Text>
                        </ExternalLink>
                    </Div>
                </Notice>
                <TextInput
                    styles={styles.gSheetSourceInput}
                    label="Google Sheet URL"
                    name="gsheetSource"
                    onChange={onChangeGSheet}
                    placeholder="e.g. https://docs.google.com/spreadsheets/d/1dbXqBuO_uYfBepFkTQPbkQco_me4nffVlyb5P9gCp84/edit#gid=1205503499"
                    value={gSheetSource}
                />
            </Div>
        );
    }

    renderFilingForm() {
        const { onFilingFormChange, filingForms, styles } = this.props;
        return (
            <FilingFormAutocomplete
                multi
                useTags
                label="Filing Form"
                onChange={onFilingFormChange}
                styles={styles.spotlightForm}
                value={filingForms}
            />
        );
    }

    renderSpotlightForm() {
        const { onSpotlightTypeChange, spotlightTypes, styles } = this.props;
        return (
            <SpotlightTypeAutocomplete
                multi
                useTags
                label="Corporate Activity Type"
                onChange={onSpotlightTypeChange}
                styles={styles.spotlightForm}
                value={spotlightTypes}
            />
        );
    }

    renderStreamConfiguration() {
        const {
            bookmarkTags,
            clearTags,
            copySearchTerms,
            customData,
            dashboardType,
            equityScope,
            errors,
            filters,
            ignoreDashFilters,
            onBlur,
            onChange,
            onChangeFilters,
            onCustomChange,
            onEquityScopeChange,
            onSelectColor,
            onSelectDashboard,
            onSetSearchTerms,
            onTagChange,
            searchTerms,
            searchTermsCopied,
            selectedColor,
            selectedDashboard,
            setBookmarkTags,
            shareableLink,
            showSearchTerms,
            stream,
            streamId,
            streamType,
            styles,
            toggleFilterMode,
            touched,
            values
        } = this.props;
        return (
            <Div styles={styles.streamConfiguration}>
                {streamType === 'gsheet' && this.renderGSheetForm()}
                {streamType === 'custom_data' && (
                    <CustomStreamForm
                        {...customData}
                        customDataIsEditable={!get(stream, 'clonedFrom')}
                        streamId={streamId}
                        onChange={onCustomChange}
                    />
                )}
                {streamType === 'filings' && this.renderFilingForm()}
                {streamType === 'spotlight' && this.renderSpotlightForm()}
                {showSearchTerms && (
                    <Fragment>
                        <TaggedInput
                            autoFocus={!['filings', 'spotlight'].includes(streamType)}
                            canEditTags
                            label={
                                ['filings', 'spotlight'].includes(streamType)
                                    ? 'Search terms (optional)'
                                    : 'Search terms'
                            }
                            maxTags={50}
                            name="streamSearchTerm"
                            onChange={onSetSearchTerms}
                            onInputChange={({ event }) => onChange(event)}
                            onTagChange={onTagChange}
                            placeholder={searchTerms.length >= 50 ? 'Limit reached' : 'Add keywords...'}
                            styles={styles.taggedInput}
                            tags={searchTerms}
                            topRightText='press the "enter&ldquo; key to add term'
                            topRightTextAlt='press the "delete&ldquo; key to remove term'
                            tooltipComponent={<SearchCommandLegend />}
                            tooltipOptions={SearchCommandLegend.tooltipOptions}
                            value={values.streamSearchTerm}
                        />
                        {searchTerms.length > 0 && (
                            <Fragment>
                                {searchTerms.length > 50 ? (
                                    <Div styles={styles.searchTermLimitError}>
                                        <Text>
                                            Limit of 50 search terms exceeded, please remove {searchTerms.length - 50}
                                            &nbsp;tags
                                        </Text>
                                        &nbsp;•&nbsp;
                                        <Text onClick={clearTags}>Clear tags</Text>
                                    </Div>
                                ) : (
                                    <Div styles={styles.copySearchTerms}>
                                        <Text onClick={copySearchTerms}>
                                            {searchTermsCopied
                                                ? 'Search Terms Copied Successfully'
                                                : 'Copy search terms to clipboard'}
                                        </Text>
                                        <Text>&nbsp;•&nbsp;</Text>
                                        <Text onClick={clearTags}>Clear tags</Text>
                                    </Div>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
                {streamType === 'content' && this.renderContentTypeSelector()}
                <Div styles={styles.titleColorRow}>
                    <TextInput.Formik
                        autoFocus={!showSearchTerms}
                        styles={styles.modalTitleInput}
                        label="title *"
                        name="name"
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Enter title..."
                        error={touched.name && errors.name}
                        value={values.name}
                    />
                    <ColorMenu
                        value={selectedColor}
                        styles={styles.colorMenu}
                        label="corner color"
                        onChange={onSelectColor}
                    />
                </Div>
                {streamType === 'bookmarks' && (
                    <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                        {this.renderBookmarkTypeSelector()}
                        <TaggedInput
                            canEditTags
                            label="Filter Highlights by Tags"
                            name="bookmarkTags"
                            onChange={setBookmarkTags}
                            onInputChange={({ event }) => onChange(event)}
                            onTagChange={onTagChange}
                            placeholder="Add tags..."
                            styles={styles.taggedInput}
                            tags={bookmarkTags}
                            topRightText='press the "enter&ldquo; key to add tag'
                            topRightTextAlt='press the "delete&ldquo; key to remove term'
                            value={values.bookmarkTags}
                        />
                    </WithPermission>
                )}
                <EquityScopeAutocomplete
                    wrapTags
                    label="Filter Results by Equity, Index, Sector, or Watchlist"
                    name="equityScope"
                    onChange={onEquityScopeChange}
                    placeholder="Filter by..."
                    styles={styles.equityScope}
                    value={equityScope}
                />
                {dashboardType === 'default' && (
                    <DashboardDropdown
                        styles={styles.dashboardDropdown}
                        label="In Monitor"
                        name="dashboardId"
                        onChange={onSelectDashboard}
                        value={selectedDashboard}
                        disableHoverOpen
                    />
                )}
                <FiltersOld
                    styles={styles.filters}
                    label="advanced filters"
                    filterType={streamType}
                    name="streamFilters"
                    onChange={onChangeFilters}
                    value={filters}
                />
                <Checkbox
                    checked={ignoreDashFilters}
                    onChange={toggleFilterMode}
                    size={2}
                    styles={styles.filterModeCheckbox}
                >
                    Ignore filters from parent monitor
                </Checkbox>
                {shareableLink && (
                    <ShareableLinkField
                        confirmText={
                            'This will invalidate any previously shared links to this stream. Are you sure you want ' +
                            'to generate a new share link?'
                        }
                        label="share stream"
                        link={shareableLink}
                        onRefresh={() => {}}
                        styles={styles.shareLink}
                        tooltipText={
                            'Any user with this URL will have access to clone this stream. Click the refresh button ' +
                            'to generate a new shareable link. This will invalidate any previously shared links to ' +
                            'this stream.'
                        }
                    />
                )}
            </Div>
        );
    }

    renderStreamPreview() {
        const { customData, previewFilters, selectedColor, selectedDashboard, streamType, styles, values } = this.props;
        return (
            <Div styles={styles.streamPreview}>
                <PreviewStream
                    dashboardId={selectedDashboard}
                    dataCollectionId={get(customData, 'dataCollectionId')}
                    color={selectedColor}
                    filters={previewFilters}
                    streamMatchTemplateId={get(customData, 'streamMatchTemplateId')}
                    streamTypes={[streamType]}
                    styles={styles.preview}
                    title={values.name}
                />
            </Div>
        );
    }

    renderNewStreamPage() {
        const { styles, onCancel, onContinue } = this.props;
        return (
            <Div styles={styles.columns}>
                <Div styles={{ ...styles.content, ...styles.newStreamPage }}>
                    {this.renderModalHeader({ title: 'Select Type' })}
                    {this.renderStreamTypeSelector()}
                    {this.renderModalButtons({ onCancel, onContinue })}
                </Div>
            </Div>
        );
    }

    renderNewStreamConfigurePage() {
        const { styles, onCancel, onCreate, onBack, streamType, streamTypeLabelMap } = this.props;
        return (
            <Div styles={styles.columns}>
                <Div styles={styles.content}>
                    {this.renderModalHeader({
                        title: streamTypeLabelMap[streamType].label
                    })}
                    {this.renderStreamConfiguration()}
                    {this.renderModalButtons({ onBack, onCancel, onCreate })}
                </Div>
                {this.renderStreamPreview()}
            </Div>
        );
    }

    renderEditStreamPage() {
        const { styles, onCancel, onDelete, onUpdate, title } = this.props;
        return (
            <Div styles={styles.columns}>
                <Div styles={styles.content}>
                    {this.renderModalHeader({ title })}
                    {this.renderStreamConfiguration()}
                    {this.renderModalButtons({ onCancel, onDelete, onUpdate })}
                </Div>
                {this.renderStreamPreview()}
            </Div>
        );
    }

    renderShareStream() {
        const { styles, onCancel, onCreate, title } = this.props;
        return (
            <Div styles={styles.columns}>
                <Div styles={styles.content}>
                    {this.renderModalHeader({ title })}
                    {this.renderStreamConfiguration()}
                    {this.renderModalButtons({ onCancel, onCreate })}
                </Div>
                {this.renderStreamPreview()}
            </Div>
        );
    }

    render() {
        const { styles, passedStyles, isOpen, onCancel, page } = this.props;
        return (
            <Modal isOpen={isOpen} onModalClose={onCancel} useShell>
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    {page === 'newStream' && this.renderNewStreamPage()}
                    {page === 'shareStream' && this.renderShareStream()}
                    {page === 'newStreamConfigure' && this.renderNewStreamConfigurePage()}
                    {page === 'editStream' && this.renderEditStreamPage()}
                </Div>
            </Modal>
        );
    }
}

export const StreamFormUI = compose(withStyleSheet(styleSheet))(StreamForm);
