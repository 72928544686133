import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { get, generateTabURL, generateTabId } from 'utils';

import { STREAM_DISPLAY_MODES } from 'consts';
import { withGetUser } from 'graphql/user';
import { DocumentCardUI } from './ui';

export class DocumentCard extends PureComponent {
    static displayName = 'DocumentCardContainer';

    static propTypes = {
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightsMatches: PropTypes.arrayOf(PropTypes.any),
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        uploadingUserEmail: PropTypes.string,
        uploadingUserId: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        equities: [],
        highlightColor: undefined,
        highlights: null,
        highlightsMatches: [],
        highlightTitle: null,
        score: undefined,
        styles: {},
        streamId: null,
        user: undefined,
        uploadingUserEmail: undefined,
        uploadingUserId: undefined
    };

    render() {
        const {
            company,
            displayMode,
            equities,
            highlightColor,
            highlights,
            highlightsMatches,
            highlightTitle,
            id,
            matchId,
            pathname,
            publishedDate,
            score,
            styles,
            streamId,
            user,
            uploadingUserEmail,
            uploadingUserId,
            title
        } = this.props;
        const firstMatch = highlightsMatches.find(m => m.length > 0);
        const page = firstMatch ? firstMatch.join(',') : '';
        const tabId = generateTabId({ documentId: id, page });
        const isDocumentOwner = uploadingUserId === get(user, 'id');
        return (
            <DocumentCardUI
                company={company}
                displayMode={displayMode}
                equities={equities}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                isDocumentOwner={isDocumentOwner}
                matchId={matchId}
                onClick={this.handleClick}
                publishedDate={publishedDate}
                score={score}
                streamId={streamId}
                styles={styles}
                title={title}
                to={generateTabURL({ tabId, pathname })}
                uploadingUserEmail={uploadingUserEmail}
            />
        );
    }
}

export const DocumentCardContainer = compose(
    withGetUser({ fetchPolicy: 'cache-only' }),
    withUrlContext(['pathname'])
)(DocumentCard);
