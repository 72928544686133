import { keyframes } from '@emotion/react';

const fadeInFrames = keyframes`
  0%   { opacity:0; }
  50%   { opacity:0; }
  100% { opacity:1; }
`;

const fadeInAnimation = {
    animation: `${fadeInFrames} 0.6s 1`,
    WebkitAnimation: `${fadeInFrames} 0.6s 1`
};

const manualResultRow = {
    textAlign: 'center',
    cursor: 'pointer',
    height: 30,
    padding: '0 8px 0 10px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    zIndex: 1,
    svg: {
        marginRight: 8
    }
};

export const styleSheet = theme => ({
    searchContainer: {
        width: '100%',
        maxWidth: 400,
        marginRight: 6,
        borderRadius: 6.5,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.white02,
        paddingRight: 8,
        transition: 'all 0.2s ease-in-out',
        zIndex: 2,
        position: 'relative',
        svg: {
            g: {
                fill: theme.colors.gray04
            }
        },
        ':focus-within': {
            backgroundColor: theme.colors.white01,
            maxWidth: 550,
            svg: {
                g: {
                    fill: theme.colors.blue08
                }
            },
            '.slashBox': {
                backgroundColor: theme.colors.blue08,
                border: `1px solid ${theme.colors.blue08}`,
                color: theme.colors.white01
            }
        },
        ':hover': {
            backgroundColor: theme.colors.white01,
            boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.2)',
            svg: {
                g: {
                    fill: theme.colors.blue08
                }
            },
            '.clearInput': {
                right: 6,
                svg: {
                    g: {
                        fill: theme.colors.gray04
                    }
                }
            },
            '.slashBox': {
                backgroundColor: theme.colors.blue08,
                border: `1px solid ${theme.colors.blue08}`,
                color: theme.colors.white01
            }
        },
        ':active': {
            p: {
                color: theme.colors.gray08
            }
        }
    },
    searchResultsAreVisible: {
        backgroundColor: theme.colors.white01,
        maxWidth: 550,
        svg: {
            g: {
                fill: theme.colors.blue08
            }
        },
        '.slashBox': {
            backgroundColor: theme.colors.blue08,
            border: `1px solid ${theme.colors.blue08}`,
            color: theme.colors.white01
        },
        input: {
            backgroundColor: theme.colors.white01
        }
    },
    searchInput: {
        width: '100%',
        '.iconWrapper': {
            height: 30,
            left: 8
        },
        ':hover input:not(:focus)': {
            boxShadow: 'unset',
            backgroundColor: theme.colors.white01
        },
        input: {
            paddingLeft: 32,
            height: 30,
            fontSize: 14,
            backgroundColor: theme.colors.white02,
            borderRadius: 6.5,
            boxShadow: 'unset',
            ':hover': {
                backgroundColor: theme.colors.white01,
                boxShadow: 'unset',
                ':not(:focus)': {
                    backgroundColor: theme.colors.white01,
                    boxShadow: 'unset'
                }
            },
            ':focus': {
                backgroundColor: theme.colors.white01,
                boxShadow: 'unset'
            }
        }
    },
    slashBox: {
        color: theme.colors.gray04,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.gray04}`,
        borderRadius: 4,
        height: 16,
        width: 16,
        fontSize: 9
    },
    searchResults: {
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            borderRadius: 6.5,
            WebkitOverflowScrolling: 'touch',
            overflowY: 'scroll'
        }
    },
    verticalResults: {
        ...fadeInAnimation,
        paddingTop: 4,
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '0 0 6.5px 6.5px',
        width: '100%',
        maxHeight: 'calc(100% - 60px)',
        backgroundColor: theme.colors.white01,
        alignSelf: 'center'
    },
    manualResultRow: {
        ...manualResultRow,
        '> p': {
            fontSize: 14
        }
    },
    manualResultRowHighlighted: {
        ...manualResultRow,
        backgroundColor: theme.colors.blue08,
        '> div': {
            border: 'unset',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: theme.colors.white01
        },
        '> p': {
            fontSize: 14,
            color: theme.colors.white01
        },
        'svg g': {
            fill: theme.colors.white01
        }
    },
    searchTag: {
        border: `1px solid ${theme.colors.gray05}`,
        backgroundColor: theme.colors.gray10,
        borderRadius: 6,
        padding: '4px 6px',
        color: theme.colors.gray06,
        p: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 170,
            textOverflow: 'ellipsis'
        }
    },
    noResults: {
        padding: theme.margins.margin02,
        textAlign: 'center',
        color: theme.colors.gray04,
        lineHeight: '1.3em'
    },
    hasSearchTerm: {
        '.clearInput': {
            display: 'flex',
            right: 6
        }
    },
    tabContainer: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.white01,
        paddingLeft: theme.margins.margin00,
        boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        '.contentTab-selected': {
            color: theme.colors.black01
        },
        '.contentTab': {
            ':hover': {
                color: theme.colors.gray06
            }
        }
    },
    tabContainerHighlighted: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.white01,
        paddingLeft: theme.margins.margin00,
        boxShadow: `0 1px 0 0 ${theme.colors.blue08}`,
        zIndex: 1,
        '.contentTab-selected': {
            color: theme.colors.black01
        },
        '.contentTab': {
            ':hover': {
                color: theme.colors.gray06
            }
        }
    },
    clearHistory: {
        flexShrink: 0,
        height: 16,
        paddingRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        color: theme.colors.gray04,
        ':hover': {
            color: theme.colors.gray06
        }
    },
    spacer: {
        flex: 1
    },
    buttonContainer: {
        alignItems: 'center',
        backgroundColor: theme.colors.gray10,
        borderRadius: '0 0 6.5px 6.5px',
        boxShadow: 'inset 0 1px 0 0 rgba(0, 0, 0, 0.04)',
        display: 'flex',
        bottom: 0,
        justifyContent: 'space-between',
        padding: 10,
        position: 'sticky',
        'div:last-of-type': {
            marginLeft: theme.margins.margin00
        }
    },
    iconButton: {
        backgroundColor: theme.colors.white01,
        borderRadius: 6,
        boxShadow: 'unset',
        height: 30,
        padding: `0 ${theme.margins.margin00}`,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray01}`,
        p: {
            marginLeft: 6,
            whiteSpace: 'nowrap'
        },
        svg: {
            height: 13,
            marginBottom: 1
        },
        ':hover': {
            backgroundColor: theme.colors.white03
        },
        ':active': {
            backgroundColor: theme.colors.gray01
        }
    },
    addSearch: {
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
});
