import React, { PureComponent } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import { EventLocalMonitorsUI } from './ui';
import { withLocalStreams } from '../localStreamsData';

export class EventLocalMonitors extends PureComponent {
    static displayName = 'EventLocalMonitorsContainer';

    static propTypes = {
        streamMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        onTermSelect: PropTypes.func.isRequired,
        selectedNode: PropTypes.string,
        equityId: PropTypes.string,
        createStream: PropTypes.func.isRequired,
        deleteStream: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedNode: null,
        equityId: null,
        streamMatches: []
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);

        this.state = {
            term: '',
            loadingNew: false
        };
    }

    onSubmit() {
        const { createStream, equityId } = this.props;
        const { term } = this.state;

        if (term && equityId) {
            this.setState(
                {
                    loadingNew: true
                },
                () => {
                    createStream({ searchTerm: term, equityId })
                        .then(() => {
                            this.setState({
                                term: '',
                                loadingNew: false
                            });
                        })
                        .catch(() => {
                            this.setState({
                                loadingNew: false
                            });
                        });
                }
            );
        }
    }

    onRemove(streamId) {
        const { deleteStream } = this.props;
        if (window.confirm('Removing this monitor will remove it from all events, do you still want to remove it?')) {
            return deleteStream(streamId);
        }
        return Promise.resolve();
    }

    onChange({ value: term }) {
        this.setState({
            term
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSubmit();
        }
    }

    render() {
        const { streamMatches, onTermSelect, selectedNode } = this.props;
        const { loadingNew, term } = this.state;

        return (
            <EventLocalMonitorsUI
                onTermSelect={onTermSelect}
                selectedNode={selectedNode}
                streamMatches={streamMatches}
                newTerm={term}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                onKeyPress={this.onKeyPress}
                onRemove={this.onRemove}
                loadingNewTerm={loadingNew}
            />
        );
    }
}

export const EventLocalMonitorsContainer = compose(
    withPropsOnChange(['eventId'], ({ eventId }) => ({
        eventIds: [eventId],
        filter: {
            rules: [{ ruleType: 'event_id', condition: 'is_equal', value: eventId }]
        }
    })),
    withLocalStreams()
)(EventLocalMonitors);
