export const styleSheet = theme => ({
    citations: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: theme.colors.white02
    },
    citationsAnswer: {
        ...theme.content.box,
        padding: '12px 14px',
        margin: '20px 20px 0',
        fontSize: theme.fonts.fontSizes.size03,
        lineHeight: '1.2em',
        fontStyle: 'italic',
        span: {
            marginBottom: 4,
            color: theme.colors.blue08,
            fontStyle: 'normal',
            letterSpacing: 1,
            display: 'block'
        }
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: theme.colors.white02
    },
    selectedTermWrapper: {
        backgroundColor: theme.colors.white02,
        position: 'sticky',
        top: 0,
        padding: '20px 20px 0',
        zIndex: 1
    },
    selectedTerm: {
        backgroundColor: theme.colors.gray03,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        borderRadius: 6,
        cursor: 'pointer',
        '.selectedTermParent': {
            color: theme.colors.gray06,
            marginTop: 4
        },
        ':hover': {
            '.selectedTermBack': {
                backgroundColor: theme.colors.blue08
            },
            '.selectedTermParent': {
                color: theme.colors.blue08
            },
            'svg g': {
                fill: theme.colors.white01
            }
        },
        ':active': {
            '.selectedTermBack': {
                backgroundColor: theme.colors.blue11
            },
            '.selectedTermParent': {
                color: theme.colors.blue11
            }
        }
    },
    selectedTermBack: {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.black01,
        backgroundColor: theme.colors.white02,
        borderRadius: 6,
        width: 28,
        height: 26,
        justifyContent: 'center',
        flexShrink: 0
    },
    selectedTermName: {
        lineHeight: '1.1em'
    }
});
