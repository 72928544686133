export const styleSheet = theme => ({
    container: {
        span: {
            fontSize: theme.fonts.fontSizes.size06,
            fontWeight: theme.fonts.fontWeights.bold
        }
    },
    labels: {
        color: theme.colors.gray04,
        display: 'flex',
        justifyContent: 'space-between',
        margin: `${theme.margins.margin00} 0 ${theme.margins.margin01} 0`,
        textTransform: 'uppercase',
        span: {
            fontSize: theme.fonts.fontSizes.size00,
            fontWeight: theme.fonts.fontWeights.regular
        }
    }
});
