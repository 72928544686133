export const styleSheet = theme => ({
    container: {
        borderTop: `1px solid ${theme.colors.gray01}`,
        margin: '25px 35px'
    },
    dateInput: {
        flex: '0 1 30%'
    },
    dateTimeContainer: {
        alignItems: 'flex-end',
        display: 'flex'
    },
    dropdown: {
        marginLeft: theme.margins.margin00
    },
    formRow: {
        paddingTop: 30
    },
    heading: {
        padding: '25px 0 5px'
    },
    optionDescription: {
        color: theme.colors.gray04,
        lineHeight: '1.3em',
        paddingTop: 6
    },
    scheduledFor: {
        alignSelf: 'center',
        color: theme.colors.gray04,
        marginLeft: theme.margins.margin00
    },
    textInput: {
        ...theme.content.inputErrorSmall,
        marginLeft: 20,
        maxWidth: 90
    }
});
