import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { ActionButton } from 'components/ActionButton';
import { DatePicker } from 'components/DatePicker';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { Tr } from 'components/Tr';
import { config } from 'configuration';
import { withStyleSheet } from 'hoc/styles';
import { get, toDateTimeString } from 'utils';
import { styleSheet } from './stylesheet';

class ExportEventsModal extends PureComponent {
    static propTypes = {
        dashboardId: PropTypes.string,
        dashName: PropTypes.string,
        dateRange: PropTypes.arrayOf(PropTypes.any),
        downloadLimit: PropTypes.number.isRequired,
        hasDashDateRule: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        setDateRange: PropTypes.func.isRequired,
        streamId: PropTypes.string,
        streamName: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        totalMatches: PropTypes.number.isRequired
    };

    static defaultProps = {
        dashboardId: undefined,
        dashName: undefined,
        dateRange: null,
        passedStyles: {},
        streamId: undefined,
        streamName: undefined
    };

    render() {
        const {
            dashboardId,
            dashName = '',
            dateRange,
            downloadLimit,
            hasDashDateRule,
            loading,
            onClose,
            passedStyles,
            setDateRange,
            streamId,
            streamName,
            styles,
            theme,
            totalMatches
        } = this.props;
        const startDate = toDateTimeString(dateRange[0], false, false, 'yyyy-MM-dd');
        const endDate = toDateTimeString(dateRange[1], false, false, 'yyyy-MM-dd');
        const dateString = dateRange
            ? `${new XDate(dateRange[0]).toString('MMM d, yyyy')} — ${new XDate(dateRange[1]).toString('MMM d, yyyy')}`
            : 'All Time';
        const dashDateRuleWarning = hasDashDateRule
            ? `Remove the date filter in the ${dashName} monitor to edit this date range.`
            : undefined;
        return (
            <Modal
                isOpen
                onModalClose={onClose}
                styles={{ ...styles.container, ...passedStyles }}
                title="Export Events"
            >
                {loading ? (
                    <Div styles={styles.loading}>
                        <Text size={3}>
                            Downloading Events &<br />
                            Preparing CSV File...
                        </Text>
                        <LoaderLogo />
                    </Div>
                ) : (
                    <Div styles={styles.content}>
                        <Tooltip
                            isEnabled
                            useOutsideClickHandler
                            content={<DatePicker name="dashDateRange" range onChange={setDateRange} />}
                        >
                            {({ showTooltip }) => (
                                <Table styles={styles.downloadInfo}>
                                    <Tr>
                                        <Td>Saved Search</Td>
                                        <Td>
                                            <Text size={3} styles={styles.truncate} title={streamName}>
                                                {streamName}
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Date Range</Td>
                                        <Td>
                                            {hasDashDateRule ? (
                                                <Hint
                                                    growUp
                                                    yOffset={-26}
                                                    text="Locked"
                                                    description={dashDateRuleWarning}
                                                    width={240}
                                                    styles={styles.infoRow}
                                                >
                                                    <Text size={3}>{dateString}</Text>
                                                    <Icon type="warning" color={theme.colors.red01} />
                                                </Hint>
                                            ) : (
                                                <Div styles={styles.editRow} onClick={showTooltip}>
                                                    {dateString}
                                                    <Icon type="pencil02" color={theme.colors.gray04} />
                                                </Div>
                                            )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Total Matches</Td>
                                        <Td>
                                            <Text size={3}>{totalMatches >= 10000 ? '10000+' : totalMatches}</Text>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Download Limit</Td>
                                        <Td>
                                            <Hint
                                                growUp
                                                yOffset={-26}
                                                text="Download Limit"
                                                description="To access unlimited matches using our API, please reach out to our team."
                                                width={240}
                                                styles={styles.infoRow}
                                            >
                                                <Text size={3}>{downloadLimit} matches</Text>
                                                <Icon type="circleInfo02" color={theme.colors.gray04} />
                                            </Hint>
                                        </Td>
                                    </Tr>
                                </Table>
                            )}
                        </Tooltip>
                        <ExternalLink
                            href={`${get(
                                config,
                                'API_ENDPOINT'
                            )}/dashboards/${dashboardId}/streams/${streamId}/events/export/${startDate}/${endDate}`}
                            data-tname="stream-csv-export"
                        >
                            <ActionButton size={2} styles={styles.downloadBtn}>
                                Download CSV
                            </ActionButton>
                        </ExternalLink>
                    </Div>
                )}
            </Modal>
        );
    }
}

export const ExportEventsModalUI = compose(withStyleSheet(styleSheet))(ExportEventsModal);
