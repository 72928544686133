import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TooltipFormUI } from './ui';

export class TooltipForm extends PureComponent {
    static displayName = 'TooltipFormContainer';

    static propTypes = {
        className: PropTypes.string,
        buttonDisabled: PropTypes.bool,
        error: PropTypes.string,
        formFooterComponent: PropTypes.element,
        formHeaderComponent: PropTypes.element,
        hideTooltip: PropTypes.func,
        initialValue: PropTypes.string,
        inputName: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        onBlur: PropTypes.func,
        onCancel: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onSubmit: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        submitButtonText: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        buttonDisabled: false,
        error: null,
        formFooterComponent: undefined,
        formHeaderComponent: undefined,
        hideTooltip: undefined,
        initialValue: '',
        inputName: '',
        label: undefined,
        loading: false,
        onBlur: undefined,
        onCancel: undefined,
        onChange: undefined,
        onFocus: undefined,
        onKeyDown: undefined,
        onSubmit: undefined,
        placeholder: '',
        styles: undefined,
        submitButtonText: undefined,
        type: undefined,
        value: ''
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            inputValue: props.initialValue
        };
    }

    handleChange({ event, value }) {
        const { inputName, onChange } = this.props;

        // Only update the state input value when the provided value prop is undefined
        // This should only happen when the component is uncontrolled (initialValue is passed in instead of value)
        if (value === undefined) {
            this.setState({ inputValue: value });
        }

        if (onChange) onChange({ event, name: inputName, value });
    }

    render() {
        const {
            className,
            buttonDisabled,
            error,
            formFooterComponent,
            formHeaderComponent,
            hideTooltip,
            inputName,
            label,
            loading,
            onBlur,
            onCancel,
            onFocus,
            onKeyDown,
            onSubmit,
            placeholder,
            styles,
            type,
            submitButtonText,
            value
        } = this.props;
        const { inputValue } = this.state;
        return (
            <TooltipFormUI
                className={className}
                buttonDisabled={buttonDisabled}
                error={error}
                formFooterComponent={formFooterComponent}
                formHeaderComponent={formHeaderComponent}
                inputName={inputName}
                label={label}
                loading={loading}
                onBlur={onBlur}
                onCancel={onCancel || hideTooltip}
                onChange={this.handleChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onSubmit={onSubmit}
                placeholder={placeholder}
                styles={styles}
                submitButtonText={submitButtonText}
                type={type}
                value={value || inputValue}
            />
        );
    }
}

export const TooltipFormContainer = compose()(TooltipForm);
