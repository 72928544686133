import get from 'lodash/get';
// Can't grab 'get' from 'utils' as it creates
// a dependency cycle, as utils also imports
// from consts

export const DEFAULT_WATCHLIST_FILTER = {
    equity: [],
    equityBlacklist: [],
    marketCap: undefined,
    sector: undefined,
    filterList: [{ type: 'index', value: ['2'] }]
};

export const FILING_FORM_FILTERS = {
    '10-K': '1',
    '10-Q': '2',
    '8-K': '6',
    Other: null
};

export const OPERATORS = {
    between: 'between',
    is: 'is',
    isNot: 'isNot',
    lessThan: 'lessThan',
    lessThanOrEqual: 'lessThanOrEqual',
    greaterThan: 'greaterThan',
    greaterThanOrEqual: 'greaterThanOrEqual',
    withinDays: 'withinDays',
    withinWeeks: 'withinWeeks',
    withinMonths: 'withinMonths'
};

export const OPERATOR_VALUES = {
    [OPERATORS.between]: { label: 'between', value: OPERATORS.between },
    [OPERATORS.greaterThanOrEqual]: { label: '>=', value: OPERATORS.greaterThanOrEqual },
    [OPERATORS.greaterThan]: { label: '>', value: OPERATORS.greaterThan },
    [OPERATORS.isNot]: { label: 'is not', value: OPERATORS.isNot },
    [OPERATORS.is]: { label: 'is', value: OPERATORS.is },
    [OPERATORS.lessThanOrEqual]: { label: '<=', value: OPERATORS.lessThanOrEqual },
    [OPERATORS.lessThan]: { label: '<', value: OPERATORS.lessThan },
    [OPERATORS.withinDays]: { label: 'within days', value: OPERATORS.withinDays },
    [OPERATORS.withinMonths]: { label: 'within months', value: OPERATORS.withinMonths },
    [OPERATORS.withinWeeks]: { label: 'within weeks', value: OPERATORS.withinWeeks }
};

export const TYPES = {
    alertType: 'alertType',
    // analystName: 'analystName',
    collapse: 'collapse',
    company: 'company',
    companyType: 'company_type',
    content: 'content',
    country: 'country',
    date: 'date',
    domain: 'domain',
    equity: 'equity',
    event: 'event',
    evEbitda: 'evEbitda',
    eventDate: 'eventDate',
    eventGroup: 'eventGroup',
    eventStatus: 'eventStatus',
    eventTitle: 'eventTitle',
    eventTonalSentiment: 'tonal_sentiment',
    eventType: 'eventType',
    evFcf: 'evFcf',
    evSales: 'evSales',
    exchangeCountry: 'exchangeCountry',
    filingForm: 'filingForm',
    filingFormCategory: 'filingFormCategory',
    index: 'index',
    isArchived: 'isArchived',
    isRead: 'isRead',
    isStarred: 'isStarred',
    mcap: 'marketcap',
    mentioningCompany: 'mentioningCompany',
    newsSource: 'newsSource',
    newsSourceTag: 'newsSourceTag',
    // nextEvent: 'nextEvent',
    offeringType: 'offering_type',
    // pageCount: 'pageCount',
    pe: 'pe',
    person: 'person',
    pGaapEps: 'pGaapEps',
    // priceChg24: 'priceChg24',
    // primaryIdentifiers: 'primaryIdentifiers',
    publishDate: 'publishDate',
    publishedTranscriptSource: 'publishedTranscriptSource',
    rating: 'rating',
    recordDate: 'recordDate',
    // relatedIdentifiers: 'relatedIdentifiers',
    // reportType: 'reportType',
    revenue: 'revenue',
    scope: 'scope',
    searchTerm: 'searchTerm',
    sector: 'sector',
    // similarCount: 'similarCount',
    source: 'source',
    sourceCategory: 'sourceCategory',
    spotlightType: 'spotlightType',
    subSector: 'subSector',
    tag: 'tag',
    transcriptStatus: 'transcriptStatus',
    type: 'type',
    watchlist: 'watchlist'
    // wordCount: 'wordCount',
    // valuation: 'valuation',
    // volChg24H: 'volChg24H'
};

export const TYPE_VALUES = {
    [TYPES.alertType]: {
        label: 'Subjects (Alert type)',
        value: TYPES.alertType
    },
    [TYPES.analystName]: {
        label: 'Analyst Name',
        value: TYPES.analystName
    },
    [TYPES.companyType]: {
        label: 'Company Type',
        value: TYPES.companyType
    },
    [TYPES.eventGroup]: {
        label: 'Conf. Name',
        value: TYPES.eventGroup
    },
    [TYPES.country]: {
        label: 'Country (HQ)',
        value: TYPES.country
    },
    [TYPES.date]: {
        label: 'Date',
        value: TYPES.date
    },
    [TYPES.exchangeCountry]: {
        label: 'Country (Exchange)',
        value: TYPES.exchangeCountry
    },
    [TYPES.equity]: {
        label: 'Equity',
        value: TYPES.equity
    },
    [TYPES.eventDate]: {
        label: 'Event Date',
        value: TYPES.eventDate
    },
    [TYPES.eventStatus]: {
        label: 'Event Status',
        value: TYPES.eventStatus
    },
    [TYPES.eventTitle]: {
        label: 'Event Title',
        value: TYPES.eventTitle
    },
    [TYPES.eventTonalSentiment]: {
        label: 'Tonal Sentiment',
        value: TYPES.eventTonalSentiment
    },
    [TYPES.eventType]: {
        label: 'Event Type',
        value: TYPES.eventType
    },
    [TYPES.filingForm]: {
        label: 'Filing Form',
        value: TYPES.filingForm
    },
    [TYPES.followList]: {
        label: 'Follow List',
        value: TYPES.followList
    },
    [TYPES.index]: {
        label: 'Index',
        value: TYPES.index
    },
    [TYPES.recordDate]: {
        label: 'Record Date',
        value: TYPES.recordDate
    },
    [TYPES.mcap]: {
        label: 'Market Cap.',
        value: TYPES.mcap
    },
    [TYPES.nextEvent]: {
        label: 'Next Event',
        value: TYPES.nextEvent
    },
    [TYPES.newsSource]: {
        label: 'News Source',
        value: TYPES.newsSource
    },
    [TYPES.newsSourceTag]: {
        label: 'News Source Tag',
        value: TYPES.newsSourceTag
    },
    [TYPES.offeringType]: {
        label: 'Offering Type',
        value: TYPES.offeringType
    },
    [TYPES.pageCount]: {
        label: 'Length (pages)',
        value: TYPES.pageCount
    },
    [TYPES.pe]: {
        label: 'P/E',
        value: TYPES.pe
    },
    [TYPES.person]: {
        label: 'Speaker',
        value: TYPES.person
    },
    [TYPES.priceChg24H]: {
        label: '24h Price Change',
        value: TYPES.priceChg24
    },
    [TYPES.primaryIdentifiers]: {
        label: 'Primary Identifiers',
        value: TYPES.primaryIdentifiers
    },
    [TYPES.publishDate]: {
        label: 'Publish Date',
        value: TYPES.publishDate
    },
    [TYPES.publishedTranscriptSource]: {
        label: 'Transcript Source',
        value: TYPES.publishedTranscriptSource
    },
    [TYPES.rating]: {
        label: 'Rating',
        value: TYPES.rating
    },
    [TYPES.relatedIdentifiers]: {
        label: 'Related Identifiers',
        value: TYPES.relatedIdentifiers
    },
    [TYPES.reportType]: {
        label: 'Report Type',
        value: TYPES.reportType
    },
    [TYPES.revenue]: {
        label: 'Revenue',
        value: TYPES.revenue
    },
    [TYPES.searchTerm]: {
        label: 'Search Term',
        value: TYPES.searchTerm
    },
    [TYPES.spotlightType]: {
        label: 'Spotlight Type',
        value: TYPES.spotlightType
    },
    [TYPES.sector]: {
        label: 'Sector',
        value: TYPES.sector
    },
    [TYPES.subSector]: {
        label: 'SubSector',
        value: TYPES.subSector
    },
    [TYPES.similarCount]: {
        label: 'Similar articles',
        value: TYPES.similarCount
    },
    [TYPES.source]: {
        label: 'Source',
        value: TYPES.source
    },
    [TYPES.transcriptStatus]: {
        label: 'Transcript Status',
        value: TYPES.transcriptStatus
    },
    [TYPES.valuation]: {
        label: 'Valuation',
        value: TYPES.valuation
    },
    [TYPES.volChg24H]: {
        label: '24h Volume Change',
        value: TYPES.volChg24H
    },
    [TYPES.watchlist]: {
        label: 'Watchlist',
        value: TYPES.watchlist
    },
    [TYPES.wordCount]: {
        label: 'Word Count',
        value: TYPES.wordCount
    },
    [TYPES.pGaapEps]: {
        label: 'P/E (GAAP)',
        value: 'pGaapEps'
    },
    [TYPES.evSales]: {
        label: 'EV / Sales',
        value: 'evSales'
    },
    [TYPES.evEbitda]: {
        label: 'EV / EBITDA',
        value: 'evEbitda'
    },
    [TYPES.evFcf]: {
        label: 'EV / FCF',
        value: 'evFcf'
    }
};

export const SOURCE_TYPES = {
    [TYPES.domain]: 'DomainSource',
    [TYPES.eventType]: 'EventSource'
};

export const operatorMap = {
    [TYPES.alertType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.analystName]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.eventGroup]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.companyType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.country]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.date]: [OPERATORS.is, OPERATORS.isNot, OPERATORS.withinDays, OPERATORS.withinWeeks, OPERATORS.withinMonths],
    [TYPES.exchangeCountry]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.equity]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.eventDate]: [
        OPERATORS.is,
        OPERATORS.isNot,
        OPERATORS.withinDays,
        OPERATORS.withinWeeks,
        OPERATORS.withinMonths
    ],
    [TYPES.eventStatus]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.eventTitle]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.eventTonalSentiment]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.eventType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.index]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.filingForm]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.followList]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.recordDate]: [
        OPERATORS.is,
        OPERATORS.isNot,
        OPERATORS.withinDays,
        OPERATORS.withinWeeks,
        OPERATORS.withinMonths
    ],
    [TYPES.mcap]: [OPERATORS.greaterThan, OPERATORS.lessThan, OPERATORS.greaterThanOrEqual, OPERATORS.lessThanOrEqual],
    [TYPES.nextEvent]: [OPERATORS.withinDays, OPERATORS.withinWeeks, OPERATORS.withinMonths],
    [TYPES.newsSource]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.newsSourceTag]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.offeringType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.pageCount]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.pe]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.person]: [OPERATORS.is],
    [TYPES.priceChg24H]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.primaryIdentifiers]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.publishDate]: [OPERATORS.withinDays, OPERATORS.withinWeeks, OPERATORS.withinMonths],
    [TYPES.publishedTranscriptSource]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.rating]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.relatedIdentifiers]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.reportType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.revenue]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.spotlightType]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.sector]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.subSector]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.similarCount]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.source]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.transcriptStatus]: [OPERATORS.is, OPERATORS.isNot],
    [TYPES.valuation]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.volChg24H]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ],
    [TYPES.watchlist]: [OPERATORS.is],
    [TYPES.wordCount]: [
        OPERATORS.is,
        OPERATORS.greaterThan,
        OPERATORS.lessThan,
        OPERATORS.greaterThanOrEqual,
        OPERATORS.lessThanOrEqual
    ]
};

export const INPUT_TYPES = {
    countryAutocomplete: 'countryAutocomplete',
    datepicker: 'datepicker',
    dropdown: 'dropdown',
    equityAutocomplete: 'equityAutocomplete',
    eventGroupAutocomplete: 'eventGroupAutocomplete',
    filingAutocomplete: 'filingAutocomplete',
    followList: 'followListAutocomplete',
    indexAutocomplete: 'indexAutocomplete',
    minMax: 'minMax',
    multiSelect: 'multiSelect',
    number: 'number',
    sectorAutocomplete: 'sectorAutocomplete',
    speakerAutocomplete: 'speakerAutocomplete',
    spotlightAutocomplete: 'spotlightAutocomplete',
    textInput: 'textInput'
};

export const valueMap = {
    [TYPES.alertType]: {
        type: INPUT_TYPES.dropdown,
        options: [
            { label: 'US Market Summary', value: 'usmkts' },
            { label: 'EU Market Summary', value: 'eumkts' },
            { label: 'Asia Market Summary', value: 'amkts' },
            { label: 'Sector Highlights', value: 'scthtl' },
            { label: 'US Economic and World News', value: 'uscnwld' },
            { label: 'EU Economic and World News', value: 'eucnwld' },
            { label: 'Asia Economic and World News', value: 'asconwld' },
            { label: 'Week Ahead	Street Takeaways', value: 'wkhst' },
            { label: 'Analyst Upgrades and Downgrades', value: 'alupdg' },
            { label: 'Earnings Scorecard', value: 'erscrd' },
            { label: 'Regulatory News', value: 'regnws' },
            { label: 'Newspaper Summaries', value: 'nwpsm' }
        ]
    },
    [TYPES.analystName]: { type: INPUT_TYPES.textInput },
    [TYPES.companyType]: {
        type: INPUT_TYPES.multiSelect,
        options: [
            { label: 'Government', value: 'government' },
            { label: 'Regulatory', value: 'regulatory' }
        ]
    },
    [TYPES.eventGroup]: { type: INPUT_TYPES.eventGroupAutocomplete },
    [TYPES.country]: { type: INPUT_TYPES.countryAutocomplete },
    [TYPES.date]: {
        [OPERATORS.is]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        [OPERATORS.isNot]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        type: INPUT_TYPES.number
    },
    [TYPES.exchangeCountry]: { type: INPUT_TYPES.countryAutocomplete },
    [TYPES.equity]: { type: INPUT_TYPES.equityAutocomplete },
    [TYPES.eventDate]: {
        [OPERATORS.is]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        [OPERATORS.isNot]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        type: INPUT_TYPES.number
    },
    [TYPES.eventStatus]: {
        type: INPUT_TYPES.dropdown,
        options: [
            { label: 'Live', value: 'live' },
            { label: 'Completed', value: 'completed' },
            { label: 'Complete w/ Transcript', value: 'completed_with_transcripts' }
        ]
    },
    [TYPES.eventTitle]: { type: INPUT_TYPES.textInput },
    [TYPES.eventTonalSentiment]: {
        type: INPUT_TYPES.dropdown,
        [OPERATORS.is]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Available', value: 'available' },
                { label: 'Divergent', value: 'divergent' }
            ]
        },
        [OPERATORS.isNot]: {
            type: INPUT_TYPES.dropdown,
            options: [{ label: 'Divergent', value: 'divergent' }]
        }
    },
    [TYPES.eventType]: { type: INPUT_TYPES.sourceAutocomplete },
    [TYPES.filingForm]: { type: INPUT_TYPES.filingAutocomplete },
    [TYPES.followList]: { type: INPUT_TYPES.followListAutocomplete },
    [TYPES.index]: { type: INPUT_TYPES.indexAutocomplete },
    [TYPES.recordDate]: {
        [OPERATORS.is]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Latest period', value: 'latest_period' },
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        [OPERATORS.isNot]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Latest period', value: 'latest_period' },
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        type: INPUT_TYPES.number
    },
    [TYPES.mcap]: { type: INPUT_TYPES.number },
    [TYPES.nextEvent]: { type: INPUT_TYPES.datepicker },
    [TYPES.newsSource]: { type: INPUT_TYPES.newsSourceAutocomplete },
    [TYPES.newsSourceTag]: { type: INPUT_TYPES.newsSourceTagAutocomplete },
    [TYPES.offeringType]: {
        type: INPUT_TYPES.dropdown,
        options: [{ label: 'SPAC', value: 'spac' }]
    },
    [TYPES.pageCount]: { type: INPUT_TYPES.number },
    [TYPES.person]: { type: INPUT_TYPES.speakerAutocomplete },
    [TYPES.pe]: { type: INPUT_TYPES.number },
    [TYPES.priceChg24H]: { type: INPUT_TYPES.number },
    [TYPES.primaryIdentifiers]: { type: INPUT_TYPES.equityAutocomplete },
    [TYPES.publishDate]: {
        [OPERATORS.is]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        [OPERATORS.isNot]: {
            type: INPUT_TYPES.dropdown,
            options: [
                { label: 'Today', value: 'today' },
                { label: 'Tomorrow', value: 'tomorrow' },
                { label: 'Yesterday', value: 'yesterday' }
            ]
        },
        type: INPUT_TYPES.number
    },
    [TYPES.publishedTranscriptSource]: {
        type: INPUT_TYPES.dropdown,
        options: [{ label: 'SCRIPTS Asia', value: 'scripts_asia' }]
    },
    [TYPES.rating]: {
        type: INPUT_TYPES.dropdown,
        options: [
            { label: 'Buy', value: 'buy' },
            { label: 'Sell', value: 'sell' },
            { label: 'Hold', value: 'hold' }
        ]
    },
    [TYPES.relatedIdentifiers]: { type: INPUT_TYPES.textInput },
    [TYPES.reportType]: {
        type: INPUT_TYPES.dropdown,
        options: [
            {
                label: 'Industry/Sector Report',
                value: 'indsct'
            },
            {
                label: 'Initiation',
                value: 'initiation'
            },
            {
                label: 'Rating Change',
                value: 'rtngchg'
            },
            {
                label: 'Price Target Change',
                value: 'prctgt'
            },
            {
                label: 'Estimate Revision',
                value: 'estrvs'
            },
            {
                label: 'Company Report',
                value: 'cmpyrpt'
            },
            {
                label: 'Earnings Preview',
                value: 'earningsprev'
            },
            {
                label: 'Earnings Review',
                value: 'earningsrev'
            },
            {
                label: 'Daily',
                value: 'daily'
            },
            {
                label: 'Macro',
                value: 'macro'
            },
            {
                label: 'Credit/Fixed Income Research',
                value: 'crdfxincrs'
            },
            {
                label: 'Other Reports',
                value: 'othrprts'
            }
        ]
    },
    [TYPES.revenue]: { type: INPUT_TYPES.number },
    [TYPES.spotlightType]: { type: INPUT_TYPES.spotlightAutocomplete },
    [TYPES.sector]: { type: INPUT_TYPES.sectorAutocomplete },
    [TYPES.similarCount]: { type: INPUT_TYPES.number },
    [TYPES.source]: { type: INPUT_TYPES.sourceAutocomplete },
    [TYPES.transcriptStatus]: {
        type: INPUT_TYPES.dropdown,
        options: [
            { label: 'Live', value: 'live' },
            { label: 'Main Presentation', value: 'presentation' },
            { label: 'Q&A', value: 'q_and_a' }
        ]
    },
    [TYPES.valuation]: { type: INPUT_TYPES.number },
    [TYPES.volChg24H]: { type: INPUT_TYPES.number },
    [TYPES.wordCount]: { type: INPUT_TYPES.number },
    [TYPES.pGaapEps]: {
        type: INPUT_TYPES.minMax
    },
    [TYPES.evSales]: {
        type: INPUT_TYPES.minMax
    },
    [TYPES.evEbitda]: {
        type: INPUT_TYPES.minMax
    },
    [TYPES.evFcf]: {
        type: INPUT_TYPES.minMax
    }
};

export const getValueProps = (type, operator) => {
    return get(valueMap, `${type}.${operator}`, get(valueMap, type));
};

export const generateOperators = type => {
    return (operatorMap[type] || []).map(k => OPERATOR_VALUES[k]);
};

export const generateTypes = types => {
    return types.map(t => get(TYPE_VALUES, `[${t}]`, null)).filter(t => t);
};
