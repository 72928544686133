import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { RawHTML } from 'components/RawHTML';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Text } from 'components/Text';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, HIGHLIGHT_COLORS, PERMISSIONS } from 'consts';
import { get, toDateTimeString, generateInitials, getHighlightColor, getEventDateTime } from 'utils';
import { generateBookmarkUrl } from 'utils/streams';
import { styleSheet } from './stylesheet';

class BookmarkCard extends PureComponent {
    static propTypes = {
        bookmarkIcon: PropTypes.string.isRequired,
        bookmarkType: PropTypes.string.isRequired,
        bookmarkUrlType: PropTypes.string,
        collapsed: PropTypes.arrayOf(PropTypes.any).isRequired,
        containerStyles: PropTypes.objectOf(PropTypes.any),
        creatorName: PropTypes.string,
        created: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        creatorId: PropTypes.string,
        currentUserId: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        displayMode: PropTypes.string.isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        eventId: PropTypes.string,
        highlight: PropTypes.string,
        highlightColor: PropTypes.string,
        id: PropTypes.string,
        matchId: PropTypes.string,
        note: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        reportCards: PropTypes.arrayOf(PropTypes.string).isRequired,
        setVisibility: PropTypes.func.isRequired,
        shared: PropTypes.bool.isRequired,
        speakerName: PropTypes.string,
        startTimestamp: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
        target: PropTypes.objectOf(PropTypes.any),
        targetId: PropTypes.string,
        targetType: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
        toggleExport: PropTypes.func,
        url: PropTypes.string
    };

    static defaultProps = {
        bookmarkUrlType: undefined,
        containerStyles: {},
        created: undefined,
        creatorName: undefined,
        creatorId: undefined,
        currentUserId: undefined,
        date: undefined,
        equity: undefined,
        eventId: undefined,
        highlight: undefined,
        highlightColor: undefined,
        id: undefined,
        matchId: undefined,
        note: undefined,
        passedStyles: {},
        speakerName: undefined,
        startTimestamp: undefined,
        tags: [],
        target: undefined,
        targetId: undefined,
        targetType: undefined,
        title: undefined,
        toggleExport: undefined,
        url: undefined
    };

    constructor(props) {
        super(props);

        this.renderHighlight = this.renderHighlight.bind(this);
    }

    renderHighlight(bookmark, index) {
        const {
            collapsed,
            currentUserId,
            pathname,
            reportCards,
            setVisibility,
            styles,
            theme,
            toggleExport
        } = this.props;
        const {
            id,
            created,
            creatorName,
            creatorId,
            eventId,
            highlight,
            highlightColor,
            matchId,
            note,
            shared,
            speakerName,
            startTimestamp,
            tags,
            target,
            targetId,
            targetType
        } = bookmark;
        const hasUnknownTime = get(target, 'hasUnknownTime', get(target, 'event.hasUnknownTime', false));
        const isCreator = currentUserId === creatorId;
        const isExported = reportCards.includes(id);
        const containerStyle = {};
        const to = generateBookmarkUrl(bookmark, pathname);
        let Wrapper = to ? Link : Fragment;
        let wrapperProps = {};
        if (to) {
            Wrapper = Link;
            wrapperProps = { to };
        }
        if (index === collapsed.length - 1 || index === 'first') {
            containerStyle.borderRadius = '0 0 6px 6px';
        }
        return (
            <Div className="individualHighlight" styles={{ ...containerStyle, ...styles.highlightIndividual }} key={id}>
                {isCreator && index !== 'first' && (
                    <StreamActionMenu
                        bookmarkId={id}
                        enableScoring={false}
                        eventId={eventId}
                        highlight={highlight}
                        matchId={matchId}
                        matchType="bookmark"
                        styles={{
                            ...styles.actionMenuHighlight,
                            boxShadow: `inset 0 0 0 1px ${getHighlightColor(highlightColor)?.dark}`,
                            backgroundColor: getHighlightColor(highlightColor)?.color,
                            ':hover': {
                                backgroundColor: getHighlightColor(highlightColor)?.dark,
                                boxShadow: 'unset'
                            }
                        }}
                        targetId={targetId}
                        targetType={targetType}
                        url={to}
                    />
                )}
                <Wrapper {...wrapperProps}>
                    <Div
                        styles={{
                            ...styles.highlightText,
                            backgroundColor: getHighlightColor(highlightColor)?.color,
                            border: `1px solid ${getHighlightColor(highlightColor)?.dark}`
                        }}
                    >
                        <Icon type="quote" color={getHighlightColor(highlightColor)?.dark02} />
                        {speakerName && (
                            <Text styles={{ color: theme.colors.black01 }} weight="medium">
                                {speakerName}
                            </Text>
                        )}
                        {startTimestamp && (
                            <Text
                                styles={{
                                    ...styles.highlightSpeakerTime,
                                    color: getHighlightColor(highlightColor)?.dark02
                                }}
                            >
                                {/* Render null here instead of omitting the block to keep bottom padding */}
                                {hasUnknownTime ? null : toDateTimeString(startTimestamp)}
                            </Text>
                        )}
                        <RawHTML className="highlightText" html={highlight} />
                    </Div>
                </Wrapper>
                <Div styles={styles.highlightNote}>
                    <Hint
                        hideOnScroll
                        growLeft
                        fromLeft
                        text={creatorName}
                        yOffset={-26}
                        xOffset={-4}
                        styles={{
                            ...styles.highlightCreatorLarge,
                            color: getHighlightColor(highlightColor)?.dark02,
                            backgroundColor: getHighlightColor(highlightColor)?.color,
                            border: `1px solid ${getHighlightColor(highlightColor)?.dark}`
                        }}
                    >
                        <Text size={1} weight="medium">
                            {generateInitials(creatorName)}
                        </Text>
                    </Hint>
                    <Div styles={styles.highlightNoteContent}>
                        <Text weight="medium">{creatorName}</Text>
                        {created && (
                            <Text
                                styles={{
                                    ...styles.highlightCreated,
                                    color: getHighlightColor(highlightColor)?.dark02
                                }}
                            >
                                {toDateTimeString(created)}
                            </Text>
                        )}
                        {isCreator && (
                            <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                                <Hint
                                    hideOnScroll
                                    text={shared ? 'Visible to your team' : 'Hidden from your team'}
                                    styles={styles.sharedIndicator}
                                    growLeft
                                    fromLeft
                                    yOffset={-18}
                                    xOffset={-4}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setVisibility({ bookmarkId: id, shared: !shared });
                                    }}
                                >
                                    <Icon type={shared ? 'eye' : 'eyeStrike'} color={theme.colors.gray04} />
                                </Hint>
                            </WithPermission>
                        )}
                        {note && <Text styles={styles.highlightNoteText}>{note}</Text>}
                        {tags && tags.length > 0 && (
                            <Div styles={styles.highlightTags}>
                                {tags.map(tag => (
                                    <Text
                                        key={tag}
                                        size={0}
                                        styles={{
                                            ...styles.highlightTag,
                                            backgroundColor: getHighlightColor(highlightColor)?.dark,
                                            color: getHighlightColor(highlightColor)?.dark02
                                        }}
                                    >
                                        {tag}
                                    </Text>
                                ))}
                            </Div>
                        )}
                    </Div>
                </Div>
                {!!toggleExport && (
                    <Div onClick={event => toggleExport({ event, id })} styles={styles.footer}>
                        <Div styles={styles.checkboxContainer}>
                            <Checkbox checked={isExported} size={3} styles={styles.checkbox} />
                            <Text size={1}>{isExported ? 'Selected' : 'Select to Export'}</Text>
                        </Div>
                    </Div>
                )}
            </Div>
        );
    }

    renderHighlightsCreators(creators) {
        const { styles } = this.props;

        return (
            <Div styles={styles.highlightCreators}>
                {([...creators].slice(0, 5) || [])?.map((creatorName, idx) => (
                    <Hint
                        hideOnScroll
                        growLeft
                        fromLeft
                        key={creatorName}
                        text={creatorName}
                        yOffset={-22}
                        xOffset={-6}
                        styles={{
                            ...styles.highlightCreator,
                            color: getHighlightColor(HIGHLIGHT_COLORS[idx])?.dark02,
                            backgroundColor: getHighlightColor(HIGHLIGHT_COLORS[idx])?.color,
                            boxShadow: `0 0 0 1px ${getHighlightColor(HIGHLIGHT_COLORS[idx])?.dark}`
                        }}
                    >
                        <Text size={0} weight="medium">
                            {generateInitials(creatorName)}
                        </Text>
                    </Hint>
                ))}
            </Div>
        );
    }

    render() {
        const {
            bookmarkIcon,
            bookmarkType,
            bookmarkUrlType,
            collapsed,
            containerStyles,
            created,
            creatorName,
            creatorId,
            currentUserId,
            date,
            displayMode,
            equity,
            eventId,
            highlight,
            highlightColor,
            id,
            matchId,
            note,
            passedStyles,
            shared,
            speakerName,
            startTimestamp,
            styles,
            tags,
            target,
            targetId,
            targetType,
            title,
            url
        } = this.props;
        const hasUnknownTime = get(target, 'hasUnknownTime', get(target, 'event.hasUnknownTime', false));
        const isCompact = displayMode === STREAM_DISPLAY_MODES.compact;
        const localTicker = get(equity, 'localTicker');
        const exchange = get(equity, 'exchange.shortName');
        const creators = [...new Set([...collapsed.map(({ creatorName: name }) => name)])];
        const isCreator = currentUserId === creatorId;
        return (
            <Card
                key={id}
                containerStyles={{ ...(isCompact ? styles.containerCompact : undefined), ...containerStyles }}
                styles={{ ...(isCompact ? styles.cardCompact : styles.highlight), ...passedStyles }}
                name="bookmark-card"
                to={url}
                subContentRows={collapsed.map(this.renderHighlight)}
                subContentButtonText={pluralize('additional highlights', collapsed.length, true)}
                subContentButtonContent={this.renderHighlightsCreators(creators)}
                subContentTitle="Additional Highlights"
                subContentSubtitle={`For ${title}`}
            >
                {({ isHovering }) => (
                    <Fragment>
                        {isCreator && isHovering && (
                            <StreamActionMenu
                                bookmarkId={id}
                                enableScoring={false}
                                eventId={eventId}
                                highlight={highlight}
                                matchId={matchId}
                                matchType="bookmark"
                                styles={styles.actionMenu}
                                targetId={targetId}
                                targetType={targetType}
                                url={url}
                            />
                        )}
                        <CardHeader
                            styles={styles.highlightHeader}
                            context={localTicker}
                            secondaryContext={exchange}
                            title={title}
                            subtitle={getEventDateTime(date, hasUnknownTime, true)}
                            truncateTitle
                        />
                        <Hint
                            hideOnScroll
                            growLeft
                            xOffset={-20}
                            yOffset={-20}
                            text={bookmarkType}
                            styles={styles.icon}
                        >
                            <Icon
                                type={bookmarkIcon}
                                color={getHighlightColor(highlightColor)?.dark02}
                                styles={styles.bookmarkIcon}
                            />
                        </Hint>
                        {this.renderHighlight(
                            {
                                id,
                                bookmarkUrlType,
                                created,
                                creatorId,
                                creatorName,
                                eventId,
                                highlight,
                                highlightColor,
                                matchId,
                                note,
                                shared,
                                speakerName,
                                startTimestamp,
                                tags,
                                targetId,
                                targetType,
                                target
                            },
                            'first'
                        )}
                    </Fragment>
                )}
            </Card>
        );
    }
}

export const BookmarkCardUI = compose(withStyleSheet(styleSheet))(BookmarkCard);
