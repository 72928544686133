import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId, get } from 'utils';
import { withData } from './data';
import { SectionUI } from './ui';

export class Section extends PureComponent {
    static displayName = 'SectionContainer';

    static propTypes = {
        collapsed: PropTypes.bool.isRequired,
        createDashboardSection: PropTypes.func.isRequired,
        dashboards: PropTypes.arrayOf(PropTypes.object),
        deleteDashboardSection: PropTypes.func.isRequired,
        disableDraggingDashboards: PropTypes.bool,
        hide: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        index: PropTypes.number.isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        max: PropTypes.number,
        name: PropTypes.string.isRequired,
        onDragDashboard: PropTypes.func.isRequired,
        onDragSection: PropTypes.func.isRequired,
        showNoDashMessage: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        toggle: PropTypes.func.isRequired,
        updateDashboardSection: PropTypes.func.isRequired
    };

    static defaultProps = {
        dashboards: [],
        disableDraggingDashboards: false,
        hide: false,
        max: undefined,
        showNoDashMessage: false,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.createNewDashboard = this.createNewDashboard.bind(this);
        this.onChangeSectionName = this.onChangeSectionName.bind(this);
        this.handleNewSection = this.handleNewSection.bind(this);
        this.handleRenameSection = this.handleRenameSection.bind(this);
        this.handleDeleteSection = this.handleDeleteSection.bind(this);
        this.handleTooltipKeyDown = this.handleTooltipKeyDown.bind(this);
        this.toggle = this.toggle.bind(this);

        this.state = {
            loading: false,
            newSectionName: null
        };
    }

    onChangeSectionName({ value: newSectionName }) {
        this.setState({
            newSectionName
        });
    }

    handleTooltipKeyDown(e, hideTooltip, newSection, isFirst) {
        const keyCode = get(e, 'nativeEvent.code');
        if (keyCode === 'Enter') {
            if (newSection) {
                this.handleNewSection(hideTooltip, isFirst);
            } else {
                this.handleRenameSection(hideTooltip);
            }
        } else if (keyCode === 'Escape') {
            this.setState(
                {
                    newSectionName: null
                },
                hideTooltip
            );
        }
    }

    handleNewSection(hideTooltip, isFirst) {
        const { createDashboardSection, index } = this.props;
        const { newSectionName } = this.state;
        if (newSectionName) {
            const newIndex = isFirst ? index : index + 1;
            this.setState(
                {
                    loading: true
                },
                () => {
                    createDashboardSection(newSectionName, newIndex).finally(() => {
                        this.setState(
                            {
                                loading: false,
                                newSectionName: null
                            },
                            hideTooltip
                        );
                    });
                }
            );
        }
    }

    handleRenameSection(hideTooltip) {
        const { updateDashboardSection, index, id } = this.props;
        const { newSectionName } = this.state;
        if (newSectionName) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    updateDashboardSection(newSectionName, index, id).finally(() => {
                        this.setState(
                            {
                                loading: false,
                                newSectionName: null
                            },
                            hideTooltip
                        );
                    });
                }
            );
        }
    }

    handleDeleteSection() {
        const { deleteDashboardSection, id } = this.props;
        const confirm = window.confirm(
            'Are you sure you want to remove this section? Any child monitors will move to the default "Monitors" section.'
        );

        if (confirm) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    deleteDashboardSection(id).finally(() => {
                        this.setState({
                            loading: false,
                            newSectionName: null
                        });
                    });
                }
            );
        }
    }

    createNewDashboard() {
        const { history, location, id } = this.props;
        history.push(`${generateModalId({ location, id: 'new', type: 'dashboard' })}&sectionId=${id}`);
    }

    toggle() {
        const { toggle, id } = this.props;
        toggle(id);
    }

    render() {
        const { loading, newSectionName } = this.state;
        const {
            styles,
            name,
            id,
            max,
            dashboards,
            disableDraggingDashboards,
            hide,
            index,
            onDragSection,
            onDragDashboard,
            showNoDashMessage,
            collapsed
        } = this.props;
        return (
            <SectionUI
                createNewDashboard={this.createNewDashboard}
                disableDraggingDashboards={disableDraggingDashboards}
                onChangeSectionName={this.onChangeSectionName}
                onRenameSection={this.handleRenameSection}
                onDeleteSection={this.handleDeleteSection}
                onCreateSection={this.handleNewSection}
                onDragSection={onDragSection}
                onTooltipKeyDown={this.handleTooltipKeyDown}
                onDragDashboard={onDragDashboard}
                loading={loading}
                hide={hide}
                showNoDashMessage={showNoDashMessage}
                styles={styles}
                name={name}
                newSectionName={newSectionName}
                index={index}
                id={id}
                dashboards={dashboards.slice(0, max)}
                toggle={this.toggle}
                collapsed={collapsed}
            />
        );
    }
}

export const SectionContainer = compose(withData(), withUrlContext(['history', 'location']))(Section);
