export const styleSheet = theme => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${theme.colors.gray09}`,
            maxWidth: '320px',
            width: '100%',
            flex: '1',
            height: '100%',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                height: 'unset',
                padding: `0 0 ${theme.margins.margin01} 0`,
                maxWidth: 'unset',
                overflowY: 'unset',
                borderLeft: 'unset'
            }
        },
        divergentSentimentColumn: {
            width: '27%',
            paddingBottom: '2px',
            paddingLeft: '5px'
        },
        divergentSentimentTitle: {
            width: '60%',
            paddingBottom: '2px'
        },
        divergentTonalGraph: {
            width: '25%',
            marginBottom: '9px',
            paddingLeft: '10px'
        },
        divergenceBlurb: {
            margin: '20px 20px 0',
            borderRadius: 6,
            p: {
                lineHeight: '1.3em'
            }
        },
        featurePreview: {
            alignSelf: 'flex-start'
        },
        gptBlock: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 20px 0',
            backgroundColor: theme.colors.gray01,
            borderRadius: 6,
            padding: '10px 12px',
            lineHeight: '1em'
        },
        gptLink: {
            marginTop: -8,
            color: theme.colors.blue08,
            textAlign: 'right',
            fontSize: 12,
            fontWeight: theme.fonts.fontWeights.medium,
            ':hover': {
                textDecoration: 'underline',
                color: theme.colors.blue11
            }
        },
        gptTitle: { marginBottom: 2 },
        header: {
            flexShrink: 0,
            padding: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
            borderBottom: `1px solid ${theme.colors.gray01}`,
            height: 90,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '.contentTab-selected': {
                color: theme.colors.black01
            }
        },
        scrollContainer: {
            flex: 1,
            padding: `0 0 ${theme.margins.margin01}`,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
                width: '0px',
                background: 'transparent'
            }
        },
        searchBox: {
            input: {
                height: 30,
                fontSize: theme.fonts.fontSizes.size01,
                backgroundColor: theme.colors.gray01,
                borderRadius: 15,
                paddingLeft: 30
            },
            '.iconWrapper': {
                height: 30,
                left: 8
            },
            '.clearInput': {
                height: 30,
                right: 8
            }
        },
        selectedTermWrapper: {
            backgroundColor: theme.colors.white02,
            position: 'sticky',
            top: 0,
            padding: '20px 20px 0',
            zIndex: 1
        },
        selectedTerm: {
            backgroundColor: theme.colors.gray03,
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
            borderRadius: 6,
            cursor: 'pointer',
            '.selectedTermParent': {
                color: theme.colors.gray06,
                marginTop: 4
            },
            ':hover': {
                '.selectedTermBack': {
                    backgroundColor: theme.colors.blue08
                },
                '.selectedTermParent': {
                    color: theme.colors.blue08
                },
                'svg g': {
                    fill: theme.colors.white01
                }
            },
            ':active': {
                '.selectedTermBack': {
                    backgroundColor: theme.colors.blue11
                },
                '.selectedTermParent': {
                    color: theme.colors.blue11
                }
            }
        },
        selectedTermBack: {
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.black01,
            backgroundColor: theme.colors.white02,
            borderRadius: 6,
            width: 28,
            justifyContent: 'center'
        },
        selectedTermName: {
            lineHeight: '12px'
        },
        sentimentBarNeg: {
            backgroundColor: theme.colors.pink01,
            borderRadius: '6px 0 0 6px',
            height: 7,
            position: 'absolute',
            top: 0
        },
        sentimentBarPos: {
            backgroundColor: theme.colors.green01,
            borderRadius: '0 6px 6px 0',
            height: 7,
            left: 61,
            position: 'absolute',
            top: 0
        },
        sentimentContainer: {
            ...theme.content.box,
            ...theme.content.fadeInAnimation,
            backgroundColor: theme.colors.white01,
            margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
            padding: '14px 0 10px',
            table: {
                padding: 0
            },
            [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
                borderLeft: 'none',
                borderRadius: 0,
                borderRight: 'none'
            }
        },
        sentimentCount: {
            color: theme.colors.gray04,
            letterSpacing: '1px'
        },
        sentimentHeader: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px',
            padding: '0 13px 0 14px'
        },
        sentimentRow: {
            cursor: 'pointer',
            ':hover': {
                p: {
                    color: theme.colors.black01
                },
                '.sentiment-type': {
                    textDecoration: 'underline'
                }
            }
        },
        sentimentScore: {
            borderTop: `1px solid ${theme.colors.gray09}`,
            margin: '14px -13px 0',
            padding: '12px 14px 0'
        },
        sentimentTitle: {
            width: '20%',
            marginRight: '15px',
            marginLeft: '3px'
        },
        sentimentScoreRow: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        sentimentColumn: {
            marginRight: '12px',
            width: '25%'
        },
        sentimentTonalGraph: {
            width: '25%',
            marginBottom: '9px'
        },
        sentimentScoreSelected: {
            borderTop: `1px solid ${theme.colors.gray09}`,
            margin: '14px -13px 0',
            padding: '12px 14px 0'
        },
        tabs: {
            flex: 'unset',
            marginBottom: -1,
            '.contentTab': {
                ':hover': {
                    color: theme.colors.gray06
                },
                ':active': {
                    color: theme.colors.black01
                }
            }
        },
        loader: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        empty: {
            padding: theme.margins.margin01
        },
        emptyText: {
            color: theme.colors.gray04,
            lineHeight: '1.3em',
            paddingTop: 10,
            textAlign: 'center'
        }
    };
};
