export const styleSheet = theme => {
    const dashboard = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 30,
        paddingLeft: 30,
        svg: {
            flexShrink: 0,
            margin: '0 15px 0 auto'
        }
    };
    const sectionHeader = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        padding: '0 0 5px 16px',
        svg: {
            marginRight: 10,
            transition: 'all 0.2s ease-in-out'
        }
    };

    return {
        container: {
            position: 'relative',
            padding: '0 10px',
            width: 250,
            flexShrink: 0
        },
        header: {
            display: 'flex'
        },
        searchContainer: {
            backgroundColor: theme.colors.white01,
            padding: `3px 12px 0`,
            marginBottom: theme.margins.margin01,
            position: 'sticky',
            zIndex: 1,
            top: 0,
            display: 'flex',
            alignItems: 'center'
        },
        search: {
            flex: 1,
            input: {
                borderRadius: 15,
                height: 30,
                paddingLeft: 30
            },
            '.clearInput': {
                right: 8
            },
            '.iconWrapper': {
                height: 30,
                left: 8
            }
        },
        scrollContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: 6.5,
            overflowY: 'auto',
            paddingBottom: 14
        },
        section: {
            margin: `${theme.margins.margin01} 0`
        },
        sectionCollapsed: {
            marginTop: 6
        },
        sectionHeader: {
            ...sectionHeader
        },
        sectionHeaderExpanded: {
            ...sectionHeader,
            svg: {
                transition: 'all 0.2s ease-in-out',
                marginRight: 10,
                transform: 'rotate(90deg)'
            }
        },
        sectionName: {
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.gray04,
            letterSpacing: '2px',
            textTransform: 'uppercase'
        },
        sectionDashCount: {
            fontSize: theme.fonts.fontSizes.size00
        },
        dashboard: {
            ...dashboard,
            p: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginRight: 12
            },
            ':hover': {
                backgroundColor: theme.colors.blue07
            }
        },
        dashboardCurrent: {
            ...dashboard,
            backgroundColor: theme.colors.blue07,
            cursor: 'default',
            p: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginRight: 12,
                fontWeight: theme.fonts.fontWeights.medium
            }
        },
        dashNameTooltip: {
            ...theme.content.tooltipText
        },
        loading: {
            margin: '100px auto'
        },
        noResults: {
            lineHeight: '1.3em',
            padding: 20
        }
    };
};
