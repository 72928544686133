export const generateWordDocument = ({ questions, eventDate, title: docTitle, onComplete }) => {
    import('docx').then(({ Document, Packer, Paragraph, TextRun, SectionType }) => {
        const fileName = docTitle
            .replace(/\./g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();

        const titleObj = [];

        titleObj.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: docTitle,
                        bold: false,
                        size: 50
                    })
                ]
            })
        );
        titleObj.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: eventDate,
                        color: '717181'
                    })
                ],
                spacing: {
                    after: 200
                }
            })
        );

        const doc = new Document({
            sections: [
                {
                    properties: {
                        type: SectionType.CONTINUOUS
                    },
                    children: titleObj
                },
                ...questions.map(({ question, answer }) => {
                    const textSet = [];

                    // Title for this block
                    textSet.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: question,
                                    bold: true
                                })
                            ],
                            spacing: {
                                after: 50
                            }
                        })
                    );

                    answer.forEach(txt => {
                        textSet.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: txt
                                    })
                                ],
                                spacing: {
                                    after: 260
                                }
                            })
                        );
                    });

                    return {
                        properties: {
                            type: SectionType.CONTINUOUS
                        },
                        children: textSet
                    };
                })
            ]
        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then(blob => {
                import('file-saver').then(({ saveAs }) => saveAs(blob, `ask-aiera-${fileName}`));
            })
            .then(onComplete)
            // eslint-disable-next-line no-console
            .catch(() => console.log('Could not save file'));
    });
};
