import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { styleSheet } from './stylesheet';

class LoadingBlock extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        size: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        height: undefined
    };

    render() {
        const { passedStyles, styles, height, width, size } = this.props;
        let containerStyle = size === 'small' ? styles.loadingSmallText : styles.loadingMediumText;
        if (height) {
            containerStyle = {
                ...containerStyle,
                height
            };
        }
        return <Div styles={{ ...containerStyle, width, ...passedStyles }} />;
    }
}

export const LoadingBlockUI = compose(withStyleSheet(styleSheet))(LoadingBlock);
