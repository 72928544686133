import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';

function mapTargetType(targetType) {
    if (targetType === 'filing') return 'content';
    if (targetType === 'transcript') return 'event_item';
    return targetType;
}

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation UpdateUserObjectStreamSettings(
                    $score: Int
                    $streamId: ID!
                    $targetId: ID!
                    $targetType: UserObjectSettingsTargetType!
                ) {
                    updateUserObjectStreamSettings(
                        input: { score: $score, streamId: $streamId, targetId: $targetId, targetType: $targetType }
                    ) {
                        settings {
                            id
                            score
                        }
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    updateSettings: ({ score, streamId, targetId, targetType }) =>
                        mutate({
                            variables: {
                                score,
                                streamId,
                                targetId,
                                targetType: mapTargetType(targetType)
                            }
                        }).catch(error => {
                            setStatusBanner(`Error scoring content: ${error}`, 'error', 'circleX');
                            throw error;
                        })
                })
            }
        ),
        graphql(
            gql`
                mutation DeleteDoc($contentId: ID!) {
                    deleteDoc(contentId: $contentId) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner, targetId } }) => ({
                    deleteDoc: () =>
                        mutate({
                            variables: {
                                contentId: targetId
                            }
                        }).catch(error => {
                            setStatusBanner(`Error deleting document: ${error}`, 'error', 'circleX');
                        })
                })
            }
        )
    );
