import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { TextInput } from 'components/TextInput';

import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class DialPad extends PureComponent {
    static propTypes = {
        allowInternational: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        onChangeInput: PropTypes.func,
        onPressCall: PropTypes.func,
        onPressKeypad: PropTypes.func,
        onPressBackspace: PropTypes.func,
        pressed: PropTypes.string,
        tones: PropTypes.string,
        connected: PropTypes.bool,
        connecting: PropTypes.bool
    };

    static defaultProps = {
        allowInternational: false,
        passedStyles: {},
        onChangeInput: () => {},
        onPressCall: () => {},
        onPressKeypad: () => {},
        onPressBackspace: () => {},
        pressed: null,
        tones: null,
        connected: false,
        connecting: false
    };

    render() {
        const {
            allowInternational,
            styles,
            passedStyles,
            onChangeInput,
            onPressCall,
            onPressKeypad,
            onPressBackspace,
            pressed,
            tones,
            connecting,
            connected,
            theme
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {matches => (
                        <TextInput
                            disabled={matches}
                            name="phoneNumber"
                            mask={{
                                regex: allowInternational ? '[+]?[0-9*#]*' : '\\+1 \\(\\d{3}\\) \\d{3}-\\d{4}[0-9*#]*'
                            }}
                            className="dial-input"
                            styles={styles.tones}
                            size={2}
                            value={tones}
                            onChange={onChangeInput}
                        />
                    )}
                </MediaQuery>
                <Div styles={styles.dialPad}>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map(key => (
                        <Div
                            key={key}
                            onPointerDown={() => onPressKeypad(key)}
                            onPointerUp={() => onPressKeypad(null)}
                            onPointerLeave={() => onPressKeypad(null)}
                            styles={[styles.button, styles.key, pressed === key ? styles.pressed : null]}
                        >
                            {key}
                        </Div>
                    ))}
                    {[
                        <Div styles={styles.otherButton} key="phone-empty-1" />,
                        <Div
                            key="phone-call-button"
                            styles={[
                                styles.button,
                                styles.phoneBg,
                                connecting && { ...theme.content.flickerAnimation },
                                connected ? styles.phoneBgConnected : styles.phoneBgDisconnected
                            ]}
                            onClick={onPressCall}
                        >
                            <Icon type="phoneCall" color={theme.colors.white01} />
                        </Div>,
                        <Div
                            onClick={tones && !connected && !connecting ? onPressBackspace : undefined}
                            styles={[styles.otherButton, styles.backspaceContainer]}
                            key="phone-empty-2"
                        >
                            {tones && !connected && !connecting && (
                                <Fragment>
                                    <Div styles={styles.backspaceLeft} />
                                    <Div styles={styles.backspace}>
                                        <Icon type="xBold" color={theme.colors.white01} />
                                    </Div>
                                </Fragment>
                            )}
                        </Div>
                    ]}
                </Div>
            </Div>
        );
    }
}

export const DialPadUI = compose(withStyleSheet(styleSheet))(DialPad);
