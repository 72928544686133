import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { prettyLineBreak } from 'utils';

const ReportPDF = ({ Document, Link, Page, PDFText, styles, View, Image, title, eventDate, questions }) => (
    <Document>
        <Page size="A4" style={styles.pdfPage}>
            <View style={styles.pdfDisclaimer}>
                <PDFText style={styles.pdfDisclaimerText}>
                    Answers are generated using artifical intelligence, and may not be perfect.
                </PDFText>
            </View>
            <View style={styles.pdfHeader}>
                <PDFText style={styles.pdfTitle}>{prettyLineBreak(title, 0.6, 68)}</PDFText>
                {eventDate && <PDFText style={styles.pdfSubTitle}>{eventDate}</PDFText>}
            </View>
            {questions.map(({ id, answer, question }) => (
                <View wrap={false} style={styles.pdfBlock} key={id}>
                    <PDFText style={styles.pdfBlockSummaryTitle}>{question}</PDFText>
                    {answer.map(txt => (
                        <PDFText key={txt.slice(0, 10)} style={styles.pdfBlockText}>
                            {txt}
                        </PDFText>
                    ))}
                </View>
            ))}
            <View style={styles.pdfFooter}>
                <Link href="http://www.aiera.com" style={{ textDecoration: 'none' }}>
                    <Image src="/static/nohash/aiera-logo.jpg" style={{ width: 90 }} />
                </Link>
                <Link href="http://www.aiera.com" style={styles.pdfWatermarkText}>
                    Questions and answers generated on Aiera.com
                </Link>
            </View>
        </Page>
    </Document>
);

ReportPDF.propTypes = {
    Document: PropTypes.elementType.isRequired,
    Link: PropTypes.elementType.isRequired,
    Page: PropTypes.elementType.isRequired,
    PDFText: PropTypes.elementType.isRequired,
    View: PropTypes.elementType.isRequired,
    Image: PropTypes.elementType.isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.string,
    eventDate: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.any)
};

ReportPDF.defaultProps = {
    title: '',
    eventDate: '',
    questions: []
};

export const generatePdfDocument = async ({ onComplete, styles, eventDate, questions, title }) => {
    await import('@react-pdf/renderer').then(async ({ Document, Page, Text, View, Link, Image, pdf }) => {
        const blob = await pdf(
            <ReportPDF
                Document={Document}
                Link={Link}
                Page={Page}
                PDFText={Text}
                styles={styles}
                View={View}
                Image={Image}
                title={title}
                eventDate={eventDate}
                questions={questions}
            />
        ).toBlob();
        onComplete();
        const fileName = `ask-aiera-questions--${title.replace(/\s+/g, '-').toLowerCase()}.pdf`;
        saveAs(blob, fileName);
    });
};
