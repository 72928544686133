import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Logo } from 'components/Logo';
import { BackButton } from 'components/BackButton';

import { styleSheet } from './stylesheet';

class ExternalLayout extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        backText: PropTypes.string,
        backRoute: PropTypes.string,
        back: PropTypes.bool
    };

    static defaultProps = {
        backText: null,
        backRoute: null,
        back: false
    };

    renderBackButton() {
        const { backText, backRoute, back, styles } = this.props;
        if (backText && backText.length > 0 && backRoute && backRoute.length > 0) {
            return <BackButton styles={styles.back} text={backText} route={backRoute} />;
        }

        if (back) {
            return <BackButton styles={styles.back} back />;
        }

        return null;
    }

    render() {
        const { styles, children, theme } = this.props;
        return (
            <Div styles={styles.container}>
                <MediaQuery minWidth={theme.breakpoints.external.tabletEdge}>
                    <Div styles={styles.brandBox}>
                        <Logo />
                    </Div>
                </MediaQuery>
                <Div styles={styles.content}>
                    <MediaQuery maxWidth={theme.breakpoints.external.tablet}>
                        <Logo
                            height={40}
                            width={144}
                            styles={styles.logoMobile}
                            color={theme.colors.orange02}
                            markColor={theme.colors.orange02}
                        />
                    </MediaQuery>
                    {this.renderBackButton()}
                    <Div styles={styles.wrapper}>{children}</Div>
                </Div>
            </Div>
        );
    }
}

export const ExternalLayoutUI = compose(withStyleSheet(styleSheet))(ExternalLayout);
