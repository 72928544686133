import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { Span } from 'components/Span';
import { Text } from 'components/Text';

import { CONNECTION_TYPES, PARTICIPATION_TYPES, ZOOM_MEETING_TYPES } from './consts';
import { ConnectionDetails } from './ConnectionDetails';
import { RecordingDetails } from './RecordingDetails';
import { Scheduling } from './Scheduling';
import { Troubleshooting } from './Troubleshooting';
import { styleSheet } from './stylesheet';

class PrivateRecordingForm extends PureComponent {
    static propTypes = {
        confirmPermission: PropTypes.bool.isRequired,
        connectAccessId: PropTypes.string,
        connectCallerId: PropTypes.string,
        connectionType: PropTypes.string,
        connectOffsetSeconds: PropTypes.number,
        connectPhoneNumber: PropTypes.string,
        connectPin: PropTypes.string,
        connectUrl: PropTypes.string,
        deleting: PropTypes.bool.isRequired,
        domainScore: PropTypes.number,
        editing: PropTypes.bool.isRequired,
        equities: PropTypes.arrayOf(PropTypes.object),
        equityIds: PropTypes.arrayOf(PropTypes.string),
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        eventGroupId: PropTypes.string,
        eventGroupTitle: PropTypes.string,
        externalAudioStreamUrl: PropTypes.string,
        filename: PropTypes.string,
        getComponentVisibility: PropTypes.func.isRequired,
        getErrorHints: PropTypes.func.isRequired,
        hasFutureDatetime: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        localeCode: PropTypes.string,
        meetingType: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onCompleteEmailCreator: PropTypes.bool.isRequired,
        onConnectDialNumber: PropTypes.string,
        onDelete: PropTypes.func.isRequired,
        onFailure: PropTypes.string,
        onFailureInstructions: PropTypes.string,
        onFailurePhoneNumber: PropTypes.string,
        onFileDelete: PropTypes.func.isRequired,
        onFileUpload: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        organizationName: PropTypes.string,
        participationType: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        reconnecting: PropTypes.bool.isRequired,
        retryable: PropTypes.bool.isRequired,
        scheduleDate: PropTypes.objectOf(PropTypes.any),
        scheduledFor: PropTypes.string,
        scheduleMeridiem: PropTypes.string.isRequired,
        scheduleTime: PropTypes.string,
        scheduleType: PropTypes.string,
        smsAlertBeforeCall: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submittable: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        taggedInput: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        touched: PropTypes.objectOf(PropTypes.any).isRequired,
        useOnConnectDialNumber: PropTypes.bool.isRequired
    };

    static defaultProps = {
        connectAccessId: undefined,
        connectCallerId: undefined,
        connectionType: undefined,
        connectOffsetSeconds: undefined,
        connectPhoneNumber: undefined,
        connectPin: undefined,
        connectUrl: undefined,
        domainScore: undefined,
        equities: undefined,
        equityIds: undefined,
        eventGroupId: undefined,
        eventGroupTitle: undefined,
        externalAudioStreamUrl: undefined,
        filename: undefined,
        localeCode: undefined,
        onConnectDialNumber: undefined,
        onFailure: undefined,
        onFailureInstructions: undefined,
        onFailurePhoneNumber: undefined,
        organizationName: undefined,
        participationType: undefined,
        passedStyles: {},
        scheduleDate: undefined,
        scheduledFor: undefined,
        scheduleTime: undefined,
        scheduleType: undefined,
        taggedInput: undefined,
        tags: undefined,
        title: undefined
    };

    renderButtons() {
        const {
            deleting,
            editing,
            errors,
            getErrorHints,
            hasFutureDatetime,
            onClose,
            onDelete,
            onSubmit,
            reconnecting,
            retryable,
            scheduleType,
            styles,
            submittable,
            submitting,
            theme
        } = this.props;
        const retryableHint =
            'Aiera will disconnect from this recording and attempt to reconnect. Audio will not be ' +
            'transcribed during the reconnection process. The existing transcript will not be lost.';
        const submitHintText = [];
        let SubmitWrapper = Fragment;
        let submitWrapperProps = null;
        let hasHint;
        // Show the future time hint when scheduleType is set,
        // the recording is scheduled for a past time,
        // and there aren't any errors (we show those if they exist)
        if (!!scheduleType && !hasFutureDatetime && Object.keys(errors).length === 0) {
            hasHint = true;
            submitHintText.push(
                <Span key="submitHintText" styles={styles.buttonSubmitHintText}>
                    {`Schedule for a future time to ${editing ? 'connect' : 'create'}`}
                </Span>
            );
        }
        // Show the errors in a hint
        if (Object.keys(errors).length) {
            hasHint = true;
            const hints = getErrorHints(errors);
            submitHintText.push(
                Object.keys(hints).map(key => (
                    <Span key={key} styles={styles.buttonSubmitHintText}>
                        {`${key}: ${hints[key].join(', ')}`}
                        <br />
                    </Span>
                ))
            );
        }
        if (hasHint) {
            SubmitWrapper = Hint;
            submitWrapperProps = {
                growUp: true,
                text: submitHintText,
                xOffset: 15,
                yOffset: -45
            };
        }
        return (
            <Div styles={styles.buttonsContainer}>
                {editing && (
                    <ActionButton loading={deleting} onClick={onDelete} size={2} styles={styles.buttonDelete}>
                        <Icon type="trash02" color={theme.colors.gray04} />
                        <Text size={3}>Remove</Text>
                    </ActionButton>
                )}
                <ActionButton size={2} styles={styles.buttonCancel} onClick={onClose}>
                    <Text size={3}>Cancel</Text>
                </ActionButton>
                {retryable && (
                    <Hint growUp text={retryableHint} width={380} xOffset={-50} yOffset={-45}>
                        <ActionButton
                            disabled={!submittable}
                            loading={reconnecting}
                            loaderColor={theme.colors.white01}
                            onClick={() => onSubmit(true)}
                            size={2}
                            styles={styles.buttonSubmitRetry}
                        >
                            <Text size={3}>Save & Reconnect</Text>
                        </ActionButton>
                    </Hint>
                )}
                <SubmitWrapper {...submitWrapperProps}>
                    <ActionButton
                        disabled={!submittable}
                        loading={submitting}
                        loaderColor={theme.colors.white01}
                        onClick={() => onSubmit(false)}
                        size={2}
                        styles={styles.buttonSubmit}
                    >
                        <Text size={3}>{`${editing ? 'Update' : 'Create'} Recording`}</Text>
                    </ActionButton>
                </SubmitWrapper>
            </Div>
        );
    }

    render() {
        const {
            confirmPermission,
            connectAccessId,
            connectCallerId,
            connectionType,
            connectOffsetSeconds,
            connectPhoneNumber,
            connectPin,
            connectUrl,
            domainScore,
            editing,
            equities,
            equityIds,
            errors,
            eventGroupId,
            eventGroupTitle,
            externalAudioStreamUrl,
            filename,
            getComponentVisibility,
            loading,
            localeCode,
            meetingType,
            onBlur,
            onChange,
            onClose,
            onCompleteEmailCreator,
            onConnectDialNumber,
            onFailure,
            onFailureInstructions,
            onFailurePhoneNumber,
            onFileDelete,
            onFileUpload,
            onFocus,
            organizationName,
            participationType,
            passedStyles,
            scheduleDate,
            scheduledFor,
            scheduleMeridiem,
            scheduleTime,
            scheduleType,
            smsAlertBeforeCall,
            styles,
            taggedInput,
            tags,
            title,
            touched,
            useOnConnectDialNumber
        } = this.props;
        return (
            <Modal isOpen onModalClose={onClose} styles={{ ...styles.container, ...passedStyles }} title="Recording">
                {loading ? (
                    <LoaderLogo styles={styles.loading} />
                ) : (
                    <Fragment>
                        <ConnectionDetails
                            connectAccessId={connectAccessId}
                            connectCallerId={connectCallerId}
                            connectionType={connectionType}
                            connectPhoneNumber={connectPhoneNumber}
                            connectPin={connectPin}
                            connectUrl={connectUrl}
                            domainScore={domainScore}
                            errors={errors}
                            externalAudioStreamUrl={externalAudioStreamUrl}
                            filename={filename}
                            meetingType={meetingType}
                            onBlur={onBlur}
                            onChange={onChange}
                            onConnectDialNumber={onConnectDialNumber}
                            onFocus={onFocus}
                            onFileDelete={onFileDelete}
                            onFileUpload={onFileUpload}
                            organizationName={organizationName}
                            participationType={participationType}
                            smsAlertBeforeCall={smsAlertBeforeCall}
                            touched={touched}
                        />
                        <Scheduling
                            connectionType={connectionType}
                            connectOffsetSeconds={connectOffsetSeconds}
                            date={scheduleDate}
                            errors={errors}
                            meridiem={scheduleMeridiem}
                            onBlur={onBlur}
                            onChange={onChange}
                            onFocus={onFocus}
                            scheduledFor={scheduledFor}
                            time={scheduleTime}
                            type={scheduleType}
                            visible={getComponentVisibility('scheduling')}
                        />
                        {participationType !== PARTICIPATION_TYPES.participating.value && (
                            <Troubleshooting
                                confirmPermission={confirmPermission}
                                connectionType={connectionType}
                                disableConfirmPermission={editing}
                                errors={errors}
                                isWebcast={
                                    connectionType === CONNECTION_TYPES.webcast.value ||
                                    (connectionType === CONNECTION_TYPES.zoom.value &&
                                        meetingType === ZOOM_MEETING_TYPES.web.value)
                                }
                                onChange={onChange}
                                onConnectDialNumber={onConnectDialNumber}
                                onFailure={onFailure}
                                onFailureInstructions={onFailureInstructions}
                                onFailurePhoneNumber={onFailurePhoneNumber}
                                participationType={participationType}
                                scheduleDate={scheduleDate}
                                scheduleMeridiem={scheduleMeridiem}
                                scheduleTime={scheduleTime}
                                scheduleType={scheduleType}
                                useOnConnectDialNumber={useOnConnectDialNumber}
                                visible={getComponentVisibility('troubleshooting')}
                            />
                        )}
                        <RecordingDetails
                            editing={editing}
                            equities={equities}
                            equityIds={equityIds}
                            errors={errors}
                            eventGroupId={eventGroupId}
                            eventGroupTitle={eventGroupTitle}
                            localeCode={localeCode}
                            onBlur={onBlur}
                            onChange={onChange}
                            onCompleteEmailCreator={onCompleteEmailCreator}
                            onFocus={onFocus}
                            taggedInput={taggedInput}
                            tags={tags}
                            title={title}
                            touched={touched}
                            visible={getComponentVisibility('recordingDetails')}
                        />
                        {this.renderButtons()}
                    </Fragment>
                )}
            </Modal>
        );
    }
}

export const PrivateRecordingFormUI = compose(withStyleSheet(styleSheet))(PrivateRecordingForm);
