import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import { withUser } from 'graphql/user';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { searchShow } from 'actions/search';
import { withData } from './data';
import { MobileTabBarUI } from './ui';

export class MobileTabBar extends PureComponent {
    static displayName = 'MobileTabBarContainer';

    static propTypes = {
        unreadCount: PropTypes.number
    };

    static defaultProps = {
        unreadCount: 0
    };

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <MobileTabBarUI {...rest} />;
    }
}

export const MobileTabBarContainer = compose(
    withRouter,
    connect(undefined, {
        showSearch: searchShow
    }),
    withUser({ fetchPolicy: 'cache-first' }),
    withProps(({ user }) => {
        return { ...user };
    }),
    withData()
)(MobileTabBar);
