import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import flatten from 'lodash/flatten';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { SpotlightTypeAutocompleteUI } from './ui';

function formatSpotlightType(type) {
    return {
        label: get(type, 'displayName'),
        value: get(type, 'id'),
        options: get(type, 'children', []).map(formatSpotlightType)
    };
}

function formatSpotlightTypes(types) {
    return (types || []).map(formatSpotlightType);
}

export class SpotlightTypeAutocomplete extends PureComponent {
    static propTypes = {
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        spotlightTypes: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)])
    };

    static defaultProps = {
        initialSearchTerm: null,
        label: null,
        loading: false,
        multi: false,
        name: 'spotlightTypeAutocomplete',
        onChange: null,
        placeholder: 'Filter by corporate activity type',
        spotlightTypes: [],
        styles: {},
        useTags: false,
        value: null
    };

    constructor(props) {
        super(props);

        this.formatOptions = memoize(formatSpotlightTypes);
        this.getTagLabel = this.getTagLabel.bind(this);
    }

    getTagLabel(tag) {
        const { spotlightTypes } = this.props;
        return get(
            flatten(spotlightTypes.map(t => [t, ...(t.children || [])])).find(t => t.id === tag),
            'displayName'
        );
    }

    render() {
        const {
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onChange,
            placeholder,
            spotlightTypes,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <SpotlightTypeAutocompleteUI
                getTagLabel={this.getTagLabel}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={onChange}
                options={this.formatOptions(spotlightTypes)}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const SpotlightTypeAutocompleteContainer = compose(withData())(SpotlightTypeAutocomplete);
