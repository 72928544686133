import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PREFERENCES, STREAM_DISPLAY_MODES } from 'consts';
import { withUserPreferences } from 'graphql/user';
import { getPreference } from 'utils';
import { withData } from './data';
import { PreviewStreamUI } from './ui';

export class PreviewStream extends PureComponent {
    static displayName = 'PreviewStreamContainer';

    static propTypes = {
        color: PropTypes.string,
        dataCollectionStatus: PropTypes.string,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        matches: PropTypes.arrayOf(PropTypes.object),
        preferences: PropTypes.objectOf(PropTypes.any),
        refreshPreview: PropTypes.func.isRequired,
        streamTypes: PropTypes.arrayOf(PropTypes.string),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
        template: PropTypes.objectOf(PropTypes.any),
        templateType: PropTypes.string
    };

    static defaultProps = {
        color: null,
        dataCollectionStatus: null,
        loadMoreMatches: null,
        loading: false,
        matches: [],
        preferences: {},
        streamTypes: null,
        title: null,
        template: null,
        templateType: null
    };

    componentDidUpdate(prevProps) {
        const { dataCollectionStatus: prevStatus } = prevProps;
        const { dataCollectionStatus, refreshPreview } = this.props;
        if (dataCollectionStatus === 'complete' && prevStatus !== 'complete') {
            refreshPreview();
        }
    }

    render() {
        const {
            color,
            loading,
            loadMoreMatches,
            matches,
            preferences,
            streamTypes,
            styles,
            title,
            template,
            templateType
        } = this.props;
        return (
            <PreviewStreamUI
                color={color}
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                matches={matches}
                offset={matches.length}
                streamTypes={streamTypes}
                streamDisplayMode={getPreference(
                    preferences,
                    PREFERENCES.streamDisplayMode,
                    STREAM_DISPLAY_MODES.default
                )}
                styles={styles}
                title={title}
                template={template}
                templateType={templateType}
            />
        );
    }
}

export const PreviewStreamContainer = compose(withUserPreferences(), withData())(PreviewStream);
