import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { VIDEO_TYPES } from 'consts';
import { withUrlContext } from 'hoc/url';
import { withGetUser } from 'graphql/user';
import { generateModalId, get } from 'utils';
import { withData } from './data';
import { NewsHighlightsUI } from './ui';

export class NewsHighlights extends PureComponent {
    static displayName = 'NewsHighlightsContainer';

    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        filterKey: PropTypes.string.isRequired,
        highlightsCount: PropTypes.number,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        manageShareSettings: PropTypes.func.isRequired,
        matches: PropTypes.arrayOf(PropTypes.any),
        pageId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        selectBlock: PropTypes.func.isRequired,
        setFilter: PropTypes.func.isRequired,
        setSort: PropTypes.func.isRequired,
        setVisibility: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        sortKey: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tabs: PropTypes.arrayOf(PropTypes.any),
        userId: PropTypes.string
    };

    static defaultProps = {
        highlightsCount: undefined,
        loading: false,
        matches: [],
        styles: undefined,
        pageId: undefined,
        tabs: [],
        userId: undefined
    };

    constructor(props) {
        super(props);

        this.manageShareSettings = this.manageShareSettings.bind(this);
        this.onSelectHighlight = this.onSelectHighlight.bind(this);
        this.openExport = this.openExport.bind(this);
        this.openVideoModal = this.openVideoModal.bind(this);

        this.videoPlayer = createRef();
    }

    manageShareSettings({ value }) {
        const { manageShareSettings } = this.props;
        const shareOn = value[0] === 'share';
        manageShareSettings(shareOn);
    }

    onSelectHighlight(highlightId) {
        const { containerRef, selectBlock } = this.props;
        const highlightNode = containerRef.current.querySelector(`[data-highlightid="${highlightId}"]`);
        selectBlock(highlightNode?.parentNode);
    }

    openExport() {
        const { history, tabs, location, sortKey, filterKey } = this.props;
        history.push(
            generateModalId({ type: 'exportHighlights', tabId: tabs[0], location, id: `${sortKey}|${filterKey}` })
        );
    }

    openVideoModal() {
        const { history, pathname, tabs } = this.props;
        // Clicking the thumbnail will remove the preview overlay and open the modal that plays the video
        // Because the preview overlay is removed, we need to put it back so the user can re-open the modal
        if (this.videoPlayer && this.videoPlayer.current) this.videoPlayer.current.showPreview();
        history.push(
            generateModalId({ pathname, id: VIDEO_TYPES.workspaceHighlights, tabId: tabs[0], type: 'videoPlayer' })
        );
    }

    render() {
        const {
            containerRef,
            filterKey,
            highlightsCount,
            loading,
            matches,
            pageId,
            setFilter,
            setSort,
            setVisibility,
            shareBookmarks,
            sortKey,
            styles,
            userId
        } = this.props;
        return (
            <NewsHighlightsUI
                containerRef={containerRef}
                filterKey={filterKey}
                hasHighlights={!!highlightsCount && highlightsCount > 0}
                loading={loading}
                manageShareSettings={this.manageShareSettings}
                matches={matches}
                onSelectHighlight={this.onSelectHighlight}
                openExport={this.openExport}
                openVideoModal={this.openVideoModal}
                pageId={pageId}
                setFilter={setFilter}
                setSort={setSort}
                setVisibility={setVisibility}
                shareBookmarks={shareBookmarks}
                sortKey={sortKey}
                styles={styles}
                userId={userId}
                videoPlayerRef={this.videoPlayer}
            />
        );
    }
}

export const NewsHighlightsContainer = compose(
    withUrlContext(['tabs', 'location', 'newsId', 'pathname']),
    withData(),
    withGetUser({ fetchPolicy: 'cache-first' }),
    withPropsOnChange(['user'], ({ user }) => ({
        userId: get(user, 'id')
    }))
)(NewsHighlights);
