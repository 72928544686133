export const styleSheet = theme => ({
    container: {
        position: 'relative',
        height: '50px',
        verticalAlign: 'middle',
        paddingRight: theme.margins.margin02,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingRight: theme.margins.margin01
        }
    },
    alignRight: {
        textAlign: 'right'
    },
    alignLeft: {
        textAlign: 'left'
    },
    centered: {
        textAlign: 'center'
    },
    rightEdge: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.colors.white01,
        width: '20px'
    },
    rightCurves: {
        position: 'absolute',
        right: 10,
        bottom: 0,
        top: 0,
        width: '10px',
        borderRadius: '0 6px 6px 0'
    },
    leftEdge: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.colors.white01,
        width: '20px'
    },
    leftCurves: {
        position: 'absolute',
        left: 10,
        bottom: 0,
        top: 0,
        width: '10px',
        borderRadius: '6px 0 0 6px'
    }
});
