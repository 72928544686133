import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';

function normalizeWatchlists(watchlists = []) {
    const options = watchlists.map(({ name, watchlistId }) => ({
        label: name,
        value: watchlistId
    }));

    return {
        options
    };
}

export const withData = () =>
    compose(
        graphql(
            gql`
                query withUserWatchlists {
                    currentUser {
                        id
                        watchlists {
                            id
                            watchlistId
                            name
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    watchlists: get(data, 'currentUser.watchlists', [])
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withMemo({ normalizeWatchlists }),
        withProps(({ normalizeWatchlists: normalize, watchlists }) => ({
            ...normalize(watchlists)
        }))
    );
