import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import { compose, withStateHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { withUrlContext } from 'hoc/url';
import { routes } from 'routes';
import { get, generateTabURL } from 'utils';
import { withReporting } from 'provider/reporting';
import { searchTermSet, searchTermClear, searchHistoryAddTerm, searchHistoryClear } from 'actions/search';
import { PERMISSIONS } from 'consts';
import { Scroller } from 'utils/scroll';
import { withData } from './data';
import { SearchUI } from './ui';

const RESULT_TYPES = [
    {
        value: 'companies',
        label: 'Companies'
    },
    {
        value: 'events',
        label: 'Events'
    },
    {
        value: 'transcripts',
        label: 'Transcripts'
    },
    {
        value: 'spotlight',
        label: 'Corp. Activity',
        permissions: [PERMISSIONS.featureStreamsSpotlight, PERMISSIONS.unlockedSpotlightContent]
    },
    {
        value: 'monitors',
        label: 'Monitors'
    },
    {
        value: 'conferences',
        label: 'Conferences'
    },
    {
        value: 'news',
        label: 'News',
        permissions: [PERMISSIONS.unlockedSearchNews]
    },
    {
        value: 'filing',
        label: 'Filings'
    }
];
const NO_TERM_RESULT_TYPES = [
    {
        value: 'monitors',
        label: 'Recently Viewed'
    },
    {
        value: 'events',
        label: 'Events'
    },
    {
        value: 'spotlight',
        label: 'Corp. Activity',
        permissions: [PERMISSIONS.featureStreamsSpotlight, PERMISSIONS.unlockedSpotlightContent]
    },
    {
        value: 'conferences',
        label: 'Conferences'
    }
];

const KEY_MAP = {
    '38': 'ArrowUp',
    '40': 'ArrowDown',
    '37': 'ArrowLeft',
    '39': 'ArrowRight',
    '13': 'Enter',
    '27': 'Escape',
    '191': 'Slash',
    '9': 'Tab',
    '16': 'Shift'
};

export class Search extends PureComponent {
    static displayName = 'SearchContainer';

    static propTypes = {
        addToHistory: PropTypes.func.isRequired,
        clearHistory: PropTypes.func.isRequired,
        clearSearchTerm: PropTypes.func.isRequired,
        dashSearch: PropTypes.string,
        highlightResult: PropTypes.func.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        loadingResults: PropTypes.bool,
        loadMoreResults: PropTypes.func,
        monitorCapabilities: PropTypes.arrayOf(PropTypes.string),
        monitorName: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        searchHistory: PropTypes.arrayOf(PropTypes.string).isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.any),
        searchTerm: PropTypes.string,
        selectedResultType: PropTypes.string.isRequired,
        selectResultType: PropTypes.func.isRequired,
        setSearchTerm: PropTypes.func.isRequired,
        storedSearchTerm: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        totalResultCount: PropTypes.number,
        viewingSearchPage: PropTypes.bool.isRequired
    };

    static defaultProps = {
        dashSearch: null,
        highlightedResult: null,
        loading: false,
        loadingResults: false,
        loadMoreResults: undefined,
        monitorCapabilities: [],
        monitorName: undefined,
        searchResults: [],
        searchTerm: '',
        storedSearchTerm: '',
        styles: undefined,
        totalResultCount: 0
    };

    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleScrollRef = this.handleScrollRef.bind(this);
        this.highlightFirstResult = this.highlightFirstResult.bind(this);
        this.highlightNextResult = this.highlightNextResult.bind(this);
        this.highlightPreviousResult = this.highlightPreviousResult.bind(this);
        this.indexElement = this.indexElement.bind(this);
        this.loadMoreResults = this.loadMoreResults.bind(this);
        this.navToResult = this.navToResult.bind(this);
        this.searchInMonitor = this.searchInMonitor.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onShowAutocomplete = this.onShowAutocomplete.bind(this);
        this.onHideAutocomplete = this.onHideAutocomplete.bind(this);
        this.generateOrderedResultTypes = this.generateOrderedResultTypes.bind(this);
        this.selectNextResultType = this.selectNextResultType.bind(this);
        this.selectPreviousResultType = this.selectPreviousResultType.bind(this);
        this.selectResultType = this.selectResultType.bind(this);
        this.toggleAllResults = this.toggleAllResults.bind(this);

        this.scroller = new Scroller();
        this.inputRef = React.createRef();

        this.resultRowElements = {
            history: [],
            resultTabs: [],
            searchMonitor: [],
            searchAll: [],
            searchResults: [],
            loadMoreButton: []
        };

        this.autocompleteVisible = false;
        this.hasSearched = false;

        // For loading more results
        this.hasMoreSearchResults = false;

        this.state = {
            loadingMoreResults: false,
            searchResults: props.searchResults
        };
    }

    componentDidMount() {
        const { dashSearch, searchTerm, storedSearchTerm, clearSearchTerm, pathname } = this.props;
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
        if (dashSearch) {
            this.onSearch({ value: dashSearch }).then(this.searchInMonitor);
        } else if (!searchTerm && storedSearchTerm && pathname !== routes.search) {
            // No search term in UI, but one saved in redux
            // Don't clear for Search page since it relies on the searchTerm in redux
            clearSearchTerm();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            dashSearch: prevDashSearch,
            monitorName: prevMonitorName,
            pathname: prevPath,
            searchResults: prevSearchResults,
            selectedResultType: prevSelectedResultType
        } = prevProps;
        const {
            clearSearchTerm,
            dashSearch,
            highlightResult,
            monitorName,
            onSearch,
            pathname,
            searchResults,
            selectedResultType,
            totalResultCount,
            viewingSearchPage
        } = this.props;

        if (pathname !== prevPath && !viewingSearchPage) {
            // Use this so we don't open
            // the autocomplete
            onSearch({ value: '' });
            clearSearchTerm();
        }

        // Grab URL param for search
        if (dashSearch && dashSearch !== prevDashSearch && !viewingSearchPage) {
            this.onSearch({ value: dashSearch }).then(this.searchInMonitor);
        }

        // Set monitor as initial highlighted result
        // if on a monitor
        if (monitorName && prevMonitorName !== monitorName) {
            highlightResult('searchMonitor', 0);
        }

        // Update matches in state whenever results change
        if (!isEqual(prevSearchResults, searchResults) || prevSelectedResultType !== selectedResultType) {
            this.hasMoreSearchResults = searchResults.length < totalResultCount;
            this.setState({ searchResults });
            if (searchResults && searchResults.length === 0) {
                // If no results, highlight the monitor, if no monitor
                // highlight search all
                highlightResult(monitorName ? 'searchMonitor' : 'searchAll', 0);
            } else if (
                (!prevSearchResults || prevSearchResults.length === 0) &&
                searchResults &&
                searchResults.length > 0
            ) {
                // if there were no results, but there are results now
                // highlight the first of the results
                highlightResult('searchResults', 0);
            }
        }
    }

    componentWillUnmount() {
        this.scroller.cleanup();
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    indexElement(type, index, node) {
        this.resultRowElements[type][index] = node;
    }

    handleScrollRef(node) {
        this.scroller.setScrollContainer(node);
    }

    generateOrderedResultTypes() {
        const { searchResults } = this.state;
        const { monitorName, searchTerm, searchHistory } = this.props;
        const hasSearchTerm = searchTerm && searchTerm.length > 0;

        // Start building ordered list
        // of all visible result types
        const resultTypes = [];

        if (hasSearchTerm && monitorName) {
            resultTypes.unshift({ value: 'searchMonitor' });
        }
        if (hasSearchTerm) {
            resultTypes.push({ value: 'searchAll' });
        }
        if (!hasSearchTerm && searchHistory && searchHistory.length > 0) {
            resultTypes.push({ value: 'history' });
        }
        resultTypes.push({ value: 'resultTabs' });
        if (searchResults && searchResults.length > 0) {
            resultTypes.push({ value: 'searchResults' });
        }
        if (this.hasMoreSearchResults) {
            resultTypes.push({ value: 'loadMoreButton' });
        }

        return resultTypes;
    }

    highlightFirstResult() {
        const { highlightResult } = this.props;
        const { searchResults } = this.state;
        const orderedResultTypes = this.generateOrderedResultTypes();
        let resultType = orderedResultTypes[0].value;

        // We don't want to highlight the tabs by default if there are results
        if (resultType === 'resultTabs' && searchResults && searchResults.length > 0) {
            resultType = 'searchResults';
        }

        highlightResult(resultType, 0);
    }

    highlightNextResult() {
        const { searchResults } = this.state;
        const { highlightResult, highlightedResult, searchHistory } = this.props;
        const resultMap = {
            history: searchHistory,
            searchAll: [1],
            searchMonitor: [1],
            searchResults,
            resultTabs: [1],
            loadMoreButton: [1]
        };

        // Currently highlighted result type and index
        const type = get(highlightedResult, 'type');
        const index = get(highlightedResult, 'index');

        // Ordered list
        // of all visible result types
        const orderedResultTypes = this.generateOrderedResultTypes();

        // Find the index of the current type,
        // in the order list of types
        const currentTypeIndex = orderedResultTypes.findIndex(({ value }) => value === type);

        // Is the type the last type? If yes, keep the current type.
        // If no, set the next type to the next in the list..
        // This will only be used if the result is the last of the type too.
        const nextType =
            currentTypeIndex < orderedResultTypes.length - 1 ? orderedResultTypes[currentTypeIndex + 1].value : type;

        // What is the last result index, of the current type?
        const currentResultsMaxIndex = resultMap[type].length - 1;

        // If the current index is less than the last result index
        // we can go next, otherwise we need to first index of the next type.
        // If its the last of the last type, do nothing.
        let newType = type;
        let newIndex = index;
        if (index < currentResultsMaxIndex) {
            newIndex = index + 1;
        } else if (nextType !== type) {
            newType = nextType;
            newIndex = 0;
        }

        // Highlight the next result if the type or index has changed.
        // and scroll it into view.
        if (newType !== type || newIndex !== index) {
            highlightResult(newType, newIndex);
            if (this.resultRowElements[newType][newIndex]) {
                this.resultRowElements[newType][newIndex].scrollIntoView({ block: 'nearest' });
            }
        }
    }

    highlightPreviousResult() {
        const { searchResults } = this.state;
        const { highlightResult, highlightedResult, searchHistory } = this.props;
        const resultMap = {
            history: searchHistory,
            searchMonitor: [1],
            searchAll: [1],
            searchResults,
            resultTabs: [1],
            loadMoreButton: [1]
        };

        // Currently highlighted result type and index
        const type = get(highlightedResult, 'type');
        const index = get(highlightedResult, 'index');

        // Ordered list
        // of all visible result types
        const orderedResultTypes = this.generateOrderedResultTypes();

        // Find the index of the current type,
        // in the order list of types
        const currentTypeIndex = orderedResultTypes.findIndex(({ value }) => value === type);

        // Find the index of the type before the currently selected type, if
        // there is one. This will only be used if the selected result, is the
        // first of it's type.
        const previousType = currentTypeIndex > 0 ? orderedResultTypes[currentTypeIndex - 1].value : type;

        // If one less than the current index is less than zero
        // we can go to the last result of the previous type, otherwise we need
        // to go to the previous result of this type.
        let newType = type;
        let newIndex = index;
        if (index > 0) {
            newIndex = index - 1;
        } else if (previousType !== type) {
            newType = previousType;
            newIndex = resultMap[previousType].length - 1;
        }

        // Highlight the next result if the type or index has changed.
        // and scroll it into view.
        if (newType !== type || newIndex !== index) {
            highlightResult(newType, newIndex);
            const node = this.resultRowElements[newType][newIndex];
            if (node && newType !== 'resultTabs') {
                node.scrollIntoView({
                    block: newType === 'searchMonitor' ? 'start' : 'nearest'
                });
                if (newType !== 'searchMonitor' && newType !== 'searchAll') {
                    const offsetParentTop = this.resultRowElements[newType][newIndex].offsetParent.offsetTop;
                    const offsetTop = offsetParentTop + this.resultRowElements[newType][newIndex].offsetTop - 34;

                    // The bounding rect top offset, measures the result offset from
                    // the top of the viewport. When it is less than 130, it means the
                    // result is being cut-off by the sticky header, and we should
                    // scroll the results container to the proper position..
                    //
                    // the proper position is calculated using the parent (results type
                    // container) top offset from the scroll container, plus the offset
                    // of the result within the parent.. We can't use this every time,
                    // because we don't want to keep the result at the top of the
                    // scroll container
                    if (node.getBoundingClientRect().top < 130) {
                        this.scroller.scrollTo({ top: offsetTop });
                    }
                }
            }
        } else if (this.inputRef.current && this.inputRef.current !== document.activeElement) {
            this.inputRef.current.focus();
        }
    }

    selectResultType(resultType) {
        const { selectResultType, selectedResultType } = this.props;
        if (selectedResultType !== resultType) {
            this.setState(
                {
                    searchResults: []
                },
                () => {
                    selectResultType(resultType);
                }
            );
        }
    }

    selectPreviousResultType() {
        const { selectedResultType } = this.props;
        const types = this.hasSearched ? RESULT_TYPES : NO_TERM_RESULT_TYPES;
        const currentIndex = types.findIndex(({ value }) => value === selectedResultType);
        const nextResultType = types[currentIndex - 1] ? types[currentIndex - 1].value : types[types.length - 1].value;
        this.selectResultType(nextResultType);
    }

    selectNextResultType() {
        const { selectedResultType } = this.props;
        const types = this.hasSearched ? RESULT_TYPES : NO_TERM_RESULT_TYPES;
        const currentIndex = types.findIndex(({ value }) => value === selectedResultType);
        const nextResultType = types[currentIndex + 1] ? types[currentIndex + 1].value : types[0].value;
        this.selectResultType(nextResultType);
    }

    toggleAllResults() {
        const { addToHistory, history, searchTerm, setSearchTerm } = this.props;
        // Update searchTerm in redux
        setSearchTerm(searchTerm);
        // Save to search history
        addToHistory(searchTerm);
        // Close the autocomplete
        if (this.hideAutocomplete) this.hideAutocomplete();
        history.push({
            pathname: routes.search,
            search: qs.stringify({ dashSearch: searchTerm })
        });
    }

    handleKeyUp(event) {
        const { which } = event;
        const key = KEY_MAP[which];

        if (this.autocompleteVisible && key === 'Shift') {
            this.holdingShift = false;
        }
    }

    handleKeyDown(event) {
        const { which } = event;
        const key = KEY_MAP[which];
        let hasFocus = false;

        // Check input is in focus
        if (this.inputRef.current) {
            hasFocus = this.inputRef.current === document.activeElement;
        }

        // Detect arrow left/right
        if (
            hasFocus &&
            !this.autocompleteVisible &&
            this.showAutocomplete &&
            (key === 'ArrowLeft' || key === 'ArrowRight')
        ) {
            this.showAutocomplete();
        }

        if (this.autocompleteVisible && key === 'ArrowUp') {
            this.highlightPreviousResult();
        }

        if (this.autocompleteVisible && key === 'ArrowDown') {
            this.highlightNextResult();
            if (hasFocus && this.inputRef.current) {
                this.inputRef.current.blur();
            }
        }

        if (this.autocompleteVisible && key === 'Enter') {
            this.navToResult();
        }

        if (this.autocompleteVisible && key === 'Shift') {
            this.holdingShift = true;
        }

        if (this.autocompleteVisible && key === 'ArrowRight' && (!hasFocus || this.holdingShift)) {
            this.selectNextResultType();
        }

        if (this.autocompleteVisible && key === 'ArrowLeft' && (!hasFocus || this.holdingShift)) {
            this.selectPreviousResultType();
        }

        if (this.autocompleteVisible && key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            if (this.holdingShift) {
                this.selectPreviousResultType();
            } else {
                this.selectNextResultType();
            }
        }

        if (key === 'Slash') {
            // Check if the focused element is an input, textarea, or has the "editable" className before focusing
            if (
                !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName) &&
                !(document.activeElement.classList && document.activeElement.classList.contains('editable')) &&
                this.inputRef.current
            ) {
                event.preventDefault();
                this.inputRef.current.focus();
            }
        }

        if (this.autocompleteVisible && key === 'Escape') {
            event.preventDefault();
            if (this.inputRef.current) {
                this.inputRef.current.blur();
            }
            if (this.hideAutocomplete) {
                this.hideAutocomplete();
            }
        }
    }

    loadMoreResults() {
        const { loadMoreResults, totalResultCount } = this.props;
        const { searchResults, loadingMoreResults } = this.state;

        if (this.hasMoreSearchResults && loadMoreResults && !loadingMoreResults) {
            this.setState({ loadingMoreResults: true }, () =>
                loadMoreResults(searchResults.length)
                    .then(({ newResults }) => {
                        this.hasMoreSearchResults = searchResults.length + newResults.length < totalResultCount;
                        this.setState({
                            searchResults: [...searchResults, ...newResults],
                            loadingMoreResults: false
                        });
                    })
                    .catch(() => this.setState({ loadingMoreResults: false }))
            );
        }
    }

    navToResult() {
        const { searchResults } = this.state;
        const {
            history,
            pathname,
            highlightedResult,
            highlightResult,
            addToHistory,
            searchHistory,
            searchTerm,
            selectedResultType,
            reporter
        } = this.props;
        const resultMap = {
            history: [],
            searchMonitor: [this.searchInMonitor],
            searchAll: [this.toggleAllResults],
            searchResults,
            resultTabs: [() => {}],
            loadMoreButton: [this.loadMoreResults]
        };
        const { index, type } = highlightedResult;
        const result = resultMap[type][index];

        if (type === 'history') {
            this.onSearch({ value: searchHistory[index] });
        } else if (typeof result === 'function') {
            if (type === 'loadMoreButton') {
                this.highlightPreviousResult();
            } else if (type === 'searchAll') {
                // Highlight next result when search all disappears
                // highlighting
                const firstResultType = this.generateOrderedResultTypes();
                if (firstResultType && firstResultType[0] && firstResultType[0].value) {
                    highlightResult(firstResultType[0].value, 0);
                }
                result();
                return; // return to avoid calling addToHistory below (called in toggleAllResults)
            }
            result();
        } else if (result) {
            const {
                companyId,
                contentId,
                dashboardId,
                dashboardType,
                equityId,
                eventGroupId,
                eventId,
                type: resultType
            } = result;
            let navPath;

            if (this.hideAutocomplete) {
                this.hideAutocomplete();
            }

            if (resultType === 'company') {
                navPath = generatePath(routes.company, { companyId });
            } else if (resultType === 'event') {
                navPath = generateTabURL({ pathname, eventId });
            } else if (resultType === 'filing') {
                navPath = generateTabURL({ pathname, filingId: contentId });
            } else if (resultType === 'news') {
                navPath = generateTabURL({ pathname, newsId: contentId });
            } else if (resultType === 'transcript') {
                navPath = generateTabURL({ pathname, eventId });
            } else if (resultType === 'dashboard') {
                if (dashboardType === 'equity') {
                    navPath = generatePath(routes.equity, { equityId });
                } else {
                    navPath = generatePath(routes.dashboard, { dashboardId });
                }
            } else if (resultType === 'event_group') {
                navPath = generatePath(routes.conference, { eventGroupId });
            }

            if (navPath) {
                history.push(navPath);
            }
        }

        // Track Search Result Click Event
        reporter.track(reporter.actions.click, reporter.objects.globalSearchResult, {
            component: 'Search',
            type: selectedResultType,
            searchTerm
        });

        // Save to search history
        addToHistory(searchTerm);
    }

    searchInMonitor() {
        const { searchTerm, monitorName, setSearchTerm, dashSearch, history, pathname, addToHistory } = this.props;

        if (pathname !== routes.search && (monitorName || dashSearch)) {
            setSearchTerm(searchTerm);
            if (this.hideAutocomplete) {
                this.hideAutocomplete();
            }

            // Save to search history
            addToHistory(searchTerm);

            if (dashSearch) {
                history.push(pathname);
            }
        }
    }

    onBlur() {
        const { clearSearchTerm, searchTerm, storedSearchTerm } = this.props;

        if (storedSearchTerm && searchTerm === '') {
            clearSearchTerm();
        }
    }

    onSearch(searchTerm) {
        const { onSearch, selectedResultType, reporter } = this.props;
        onSearch(searchTerm);

        return new Promise(resolve => {
            if (!this.autocompleteVisible && this.showAutocomplete) {
                this.showAutocomplete();
            }
            if (!this.hasSearched) {
                this.hasSearched = true;

                // Only switch to companies when the default
                // type hasn't been changed before searching
                if (selectedResultType === 'monitors') {
                    this.selectResultType('companies');
                }
            }

            // Track Search Event
            reporter.track(reporter.actions.submit, reporter.objects.globalSearch, {
                component: 'Search',
                type: selectedResultType,
                searchTerm
            });

            resolve();
        });
    }

    onSearchClear() {
        const { clearSearchTerm, dashSearch, history, pathname, storedSearchTerm } = this.props;
        if (storedSearchTerm && storedSearchTerm.length) {
            clearSearchTerm();
            if (dashSearch) history.push(pathname);
        }
    }

    onShowAutocomplete({ hideTooltip, showTooltip }) {
        this.highlightFirstResult();
        this.hideAutocomplete = hideTooltip;
        this.showAutocomplete = showTooltip;
        this.autocompleteVisible = true;
    }

    onHideAutocomplete() {
        const { searchTerm } = this.props;
        this.autocompleteVisible = false;
        if (!searchTerm || searchTerm.length === 0) {
            this.selectResultType('monitors');
            this.hasSearched = false;
        }
    }

    render() {
        const { searchResults, loadingMoreResults } = this.state;
        const {
            clearHistory,
            highlightResult,
            highlightedResult,
            loading,
            loadingResults,
            monitorCapabilities,
            monitorName,
            searchHistory,
            searchTerm,
            selectedResultType,
            styles
        } = this.props;
        return (
            <SearchUI
                autocompleteVisible={this.autocompleteVisible}
                clearHistory={clearHistory}
                scrollRef={this.handleScrollRef}
                hasMoreResults={this.hasMoreSearchResults}
                highlightResult={highlightResult}
                highlightedResult={highlightedResult}
                indexElement={this.indexElement}
                inputRef={this.inputRef}
                loadingResults={loadingResults}
                loading={loading}
                loadingMoreResults={loadingMoreResults}
                loadMoreResults={this.loadMoreResults}
                monitorCapabilities={monitorCapabilities}
                monitorName={monitorName}
                onBlur={this.onBlur}
                onClickResult={this.navToResult}
                onHideAutocomplete={this.onHideAutocomplete}
                onSearch={this.onSearch}
                onSearchClear={this.onSearchClear}
                onShowAutocomplete={this.onShowAutocomplete}
                resultTypes={this.hasSearched ? RESULT_TYPES : NO_TERM_RESULT_TYPES}
                searchInMonitor={this.searchInMonitor}
                searchHistory={searchHistory}
                searchTerm={searchTerm}
                searchResults={searchResults}
                selectedResultType={selectedResultType}
                selectResultType={this.selectResultType}
                styles={styles}
                toggleAllResults={this.toggleAllResults}
            />
        );
    }
}

const mapStateToProps = ({ Search: storeSearch }) => {
    const searchTerm = get(storeSearch, 'searchTerm', '');
    return {
        searchTerm,
        searchHistory: get(storeSearch, 'searchHistory', []),
        storedSearchTerm: searchTerm
    };
};

const mapDispatchToProps = {
    setSearchTerm: searchTermSet,
    clearSearchTerm: searchTermClear,
    addToHistory: searchHistoryAddTerm,
    clearHistory: searchHistoryClear
};

export const SearchContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withUrlContext(['globalSearch', 'history', 'pathname', 'dashboardId', 'dashSearch']),
    withStateHandlers(
        ({ dashSearch, pathname, searchTerm }) => ({
            highlightedResult: {
                type: 'searchMonitor',
                index: 0
            },
            searchTerm: pathname === routes.search ? dashSearch || searchTerm : dashSearch || '',
            selectedResultType: !dashSearch ? 'monitors' : 'companies'
        }),
        {
            highlightResult: () => (type, index) => ({
                highlightedResult: { type, index }
            }),
            selectResultType: () => selectedResultType => ({
                selectedResultType
            }),
            onSearch: () => ({ value: searchTerm }) => ({
                searchTerm
            })
        }
    ),
    withPropsOnChange(
        ['pathname', 'searchHistory', 'searchTerm', 'dashboardId'],
        ({ pathname, searchHistory, searchTerm, dashboardId }) => ({
            searchHistory: searchTerm ? [] : searchHistory.filter(term => term !== searchTerm),
            viewingSearchPage: pathname === routes.search,
            dashboardId:
                pathname === routes.research
                    ? 'broker_research'
                    : pathname === routes.documents
                    ? 'documents'
                    : dashboardId
        })
    ),
    withData(),
    withReporting()
)(Search);
