const POPOVER_PDF_HIDE = 'POPOVER_PDF_HIDE';
const POPOVER_PDF_SET = 'POPOVER_PDF_SET';

export const types = {
    POPOVER_PDF_HIDE,
    POPOVER_PDF_SET
};

export function popoverPDFSet(url) {
    return {
        type: POPOVER_PDF_SET,
        payload: {
            url
        }
    };
}

export function popoverPDFHide() {
    return {
        type: POPOVER_PDF_HIDE
    };
}
