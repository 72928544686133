const SEARCH_SHOW = 'SEARCH_SHOW';
const SEARCH_HIDE = 'SEARCH_HIDE';
const SEARCH_HISTORY_ADD_TERM = 'SEARCH_HISTORY_ADD_TERM';
const SEARCH_HISTORY_CLEAR = 'SEARCH_HISTORY_CLEAR';
const SEARCH_TERM_SET = 'SEARCH_TERM_SET';
const SEARCH_TERM_CLEAR = 'SEARCH_TERM_CLEAR';

export const types = {
    SEARCH_HIDE,
    SEARCH_HISTORY_ADD_TERM,
    SEARCH_HISTORY_CLEAR,
    SEARCH_SHOW,
    SEARCH_TERM_CLEAR,
    SEARCH_TERM_SET
};

/**
 *
 * These actions are for showing an hiding the search UI.
 * These values persist, so a user can refresh and still
 * see the search UI open if they are not yet finished.
 *
 */

export function searchHide() {
    return {
        type: SEARCH_HIDE
    };
}

export function searchShow() {
    return {
        type: SEARCH_SHOW,
        track: true
    };
}

export function searchTermSet(searchTerm) {
    return {
        type: SEARCH_TERM_SET,
        payload: {
            searchTerm
        }
    };
}

export function searchTermClear() {
    return {
        type: SEARCH_TERM_CLEAR
    };
}

export function searchHistoryAddTerm(searchTerm) {
    return {
        type: SEARCH_HISTORY_ADD_TERM,
        payload: {
            searchTerm
        }
    };
}

export function searchHistoryClear() {
    return {
        type: SEARCH_HISTORY_CLEAR
    };
}
