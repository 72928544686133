import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { EventGroupStreamUI } from './ui';

export class EventGroupStream extends PureComponent {
    static displayName = 'EventGroupStreamContainer';

    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        EmptyComponent: PropTypes.objectOf(PropTypes.any),
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string
    };

    static defaultProps = {
        EmptyComponent: undefined,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        streamId: null,
        styles: undefined,
        subtitle: undefined
    };

    render() {
        const {
            dashboardId,
            EmptyComponent,
            loadMoreMatches,
            loading,
            matches,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;
        return (
            <EventGroupStreamUI
                dashboardId={dashboardId}
                EmptyComponent={EmptyComponent}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                streamDisplayMode={streamDisplayMode}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
            />
        );
    }
}

export const EventGroupStreamContainer = compose(withData())(EventGroupStream);
