import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DatePickerUI } from './ui';

export class DatePicker extends PureComponent {
    static displayName = 'DatePickerContainer';

    static propTypes = {
        maxDetail: PropTypes.string,
        minDate: PropTypes.objectOf(PropTypes.any),
        minDetail: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        range: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.instanceOf(Date)
    };

    static defaultProps = {
        maxDetail: 'month',
        minDate: undefined,
        minDetail: 'year',
        onChange: undefined,
        range: false,
        styles: undefined,
        value: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            visible: true
        };
    }

    onChange(value) {
        const { name, onChange } = this.props;
        this.setState(
            {
                visible: false
            },
            () => {
                if (onChange) {
                    onChange({ name, value });
                }
            }
        );
    }

    render() {
        const { visible } = this.state;
        const { maxDetail, minDate, minDetail, name, range, styles, value } = this.props;
        return (
            <DatePickerUI
                maxDetail={maxDetail}
                minDate={minDate}
                minDetail={minDetail}
                name={name}
                onChange={this.onChange}
                range={range}
                styles={styles}
                value={value}
                visible={visible}
            />
        );
    }
}

export const DatePickerContainer = compose()(DatePicker);
