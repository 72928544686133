import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { get } from 'utils';
import { popoutManager } from 'utils/popout';
import { withData } from './data';
import { withLocalStreams } from '../localStreamsData';
import { EventStreamMatchDifferentialsUI } from './ui';

export class EventStreamMatchDifferentials extends PureComponent {
    static displayName = 'EventStreamMatchDifferentialsContainer';

    static propTypes = {
        createStream: PropTypes.func.isRequired,
        deleteStream: PropTypes.func.isRequired,
        differentialEvents: PropTypes.arrayOf(PropTypes.any),
        aieraDifferentials: PropTypes.arrayOf(PropTypes.any),
        dashboardDifferentials: PropTypes.arrayOf(PropTypes.any),
        equityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        loading: PropTypes.bool,
        localDifferentials: PropTypes.arrayOf(PropTypes.any),
        onNodeSelect: PropTypes.func.isRequired
    };

    static defaultProps = {
        differentialEvents: [],
        aieraDifferentials: [],
        dashboardDifferentials: [],
        equityId: null,
        eventId: null,
        localDifferentials: [],
        loading: false
    };

    constructor(props) {
        super(props);

        this.expandRow = this.expandRow.bind(this);
        this.collapseRow = this.collapseRow.bind(this);
        this.handleDifferentialsChartDataPointClick = this.handleDifferentialsChartDataPointClick.bind(this);
        this.onChangeNewMonitor = this.onChangeNewMonitor.bind(this);
        this.onCreateNewMonitor = this.onCreateNewMonitor.bind(this);
        this.onRemoveMonitor = this.onRemoveMonitor.bind(this);
        this.toggleDash = this.toggleDash.bind(this);
        this.toggleStreamMatches = this.toggleStreamMatches.bind(this);

        this.state = {
            hideStreamMatches: false,
            collapsedDashboards: [],
            expandedRowTitle: null,
            newMonitor: '',
            newMonitorLoading: false,
            deletingStreamId: ''
        };
    }

    onCreateNewMonitor(hideTooltip) {
        const { createStream, equityId } = this.props;
        const { newMonitor } = this.state;

        return () => {
            if (newMonitor && equityId) {
                this.setState(
                    {
                        newMonitorLoading: true
                    },
                    () => {
                        createStream({ searchTerm: newMonitor, equityId })
                            .then(() => {
                                hideTooltip();
                                this.setState({
                                    newMonitor: '',
                                    newMonitorLoading: false
                                });
                            })
                            .catch(() => {
                                this.setState({
                                    newMonitorLoading: false
                                });
                            });
                    }
                );
            }
        };
    }

    onRemoveMonitor(monitor) {
        const { deleteStream } = this.props;
        const { streamId } = monitor;

        if (
            streamId &&
            window.confirm(
                'Removing this monitor will remove it from all events for this equity, do you still want to remove it?'
            )
        ) {
            this.setState(
                {
                    deletingStreamId: streamId
                },
                () => {
                    deleteStream(streamId).finally(() => {
                        this.setState({
                            deletingStreamId: ''
                        });
                    });
                }
            );
        }
    }

    onChangeNewMonitor({ value: newMonitor }) {
        this.setState({
            newMonitor
        });
    }

    handleDifferentialsChartDataPointClick(event) {
        const audioCallId = get(event, 'point.id');
        if (!audioCallId) return;
        popoutManager.open({
            url: generatePath(routes.popoutEvent, { type: 'event', audioCallId }),
            name: `popout-event-${audioCallId}`,
            width: 950
        });
    }

    expandRow(expandedRowTitle) {
        this.setState({
            expandedRowTitle
        });
    }

    collapseRow() {
        this.setState({ expandedRowTitle: null });
    }

    toggleStreamMatches() {
        this.setState(({ hideStreamMatches }) => ({ hideStreamMatches: !hideStreamMatches }));
    }

    toggleDash(dashId) {
        this.setState(({ collapsedDashboards }) => {
            const dashIds = new Set(collapsedDashboards);
            if (dashIds.has(dashId)) {
                dashIds.delete(dashId);
            } else {
                dashIds.add(dashId);
            }

            return {
                collapsedDashboards: [...dashIds]
            };
        });
    }

    render() {
        const {
            expandedRowTitle,
            newMonitorLoading,
            newMonitor,
            deletingStreamId,
            collapsedDashboards,
            hideStreamMatches
        } = this.state;
        const {
            aieraDifferentials,
            dashboardDifferentials,
            differentialEvents,
            onNodeSelect,
            eventId,
            loading,
            localDifferentials
        } = this.props;

        return (
            <EventStreamMatchDifferentialsUI
                toggleStreamMatches={this.toggleStreamMatches}
                hideStreamMatches={hideStreamMatches}
                aieraDifferentials={aieraDifferentials}
                collapseRow={this.collapseRow}
                collapsedDashboards={collapsedDashboards}
                dashboardDifferentials={dashboardDifferentials}
                deletingStreamId={deletingStreamId}
                differentialEvents={differentialEvents}
                eventId={eventId}
                expandRow={this.expandRow}
                expandedRowTitle={expandedRowTitle}
                loading={loading}
                localDifferentials={localDifferentials}
                newMonitor={newMonitor}
                newMonitorLoading={newMonitorLoading}
                onChangeNewMonitor={this.onChangeNewMonitor}
                onCreateNewMonitor={this.onCreateNewMonitor}
                onDifferentialsChartDataPointClick={this.handleDifferentialsChartDataPointClick}
                onNodeSelect={onNodeSelect}
                onRemoveMonitor={this.onRemoveMonitor}
                toggleDash={this.toggleDash}
            />
        );
    }
}

export const EventStreamMatchDifferentialsContainer = compose(
    withLocalStreams(),
    withData()
)(EventStreamMatchDifferentials);
