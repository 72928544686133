import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { statusBannerFire } from 'actions/statusBanner';
import { dashboardFragment, dashboardSectionFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

const mapStateToProps = ({ User: userStore }) => ({
    userIsIdle: get(userStore, 'isIdle')
});

export const withData = () =>
    compose(
        connect(mapStateToProps, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withDashboardMenuData($dashboardId: ID!) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        numLiveEvents
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    numUnread: get(data, 'dashboards[0].numLiveEvents')
                }),
                skip: ({ id, showNumRead = false, userIsIdle }) => !showNumRead || !id || userIsIdle,
                options: ({ id }) => ({
                    fetchPolicy: 'cache-and-network',
                    pollInterval: 30000,
                    variables: { dashboardId: id },
                    context: { batchKey: 'sidebar' }
                })
            }
        ),
        graphql(
            gql`
                mutation DeleteDashboard($dashboardId: ID!, $searchTerm: String) {
                    deleteDashboard(dashboardId: $dashboardId) {
                        success
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                            galleryDashboards(filter: { search: $searchTerm }) {
                                tag
                                dashboards {
                                    ...dashboard
                                    clonedToId
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        deleteDashboard: dashboardId =>
                            mutate({
                                variables: { dashboardId }
                            })
                                .then(() => {
                                    setStatusBanner('Monitor deleted successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error deleting monitor: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation unfollowMonitorSidebar($dashboardId: ID!, $followed: Boolean!) {
                    starDashboard(dashboardId: $dashboardId, starred: $followed) {
                        success
                        dashboard {
                            ...dashboard
                        }
                        query {
                            recommendedDashboards: dashboards(filter: { recommended: true, size: 5 }) {
                                ...dashboard
                                creatingUser {
                                    id
                                    username
                                }
                            }
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        unfollowMonitor: dashboardId =>
                            mutate({
                                variables: { dashboardId, followed: false }
                            }).catch(error => {
                                setStatusBanner(`Error following monitor: ${error}`, 'error', 'circleX');
                            })
                    };
                }
            }
        )
    );
