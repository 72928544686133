import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DashboardGalleryMenuUI } from './ui';

export class DashboardGalleryMenu extends PureComponent {
    static displayName = 'DashboardGalleryMenuContainer';

    static propTypes = {
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool,
        onClickDashboard: PropTypes.func,
        onKeyDown: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.object),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        currentDashboard: null,
        loading: false,
        onClickDashboard: undefined,
        sections: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onToggleSection = this.onToggleSection.bind(this);

        this.state = {
            collapsed: []
        };
    }

    onToggleSection(id) {
        this.setState(({ collapsed }) => ({
            collapsed: collapsed.includes(id) ? collapsed.filter(i => i !== id) : [...collapsed, id]
        }));
    }

    render() {
        const { collapsed } = this.state;
        const {
            currentDashboard,
            loading,
            onClickDashboard,
            onKeyDown,
            onSearch,
            searchTerm,
            sections,
            styles
        } = this.props;
        return (
            <DashboardGalleryMenuUI
                collapsed={collapsed}
                currentDashboard={currentDashboard}
                loading={loading}
                onClickDashboard={onClickDashboard}
                onKeyDown={onKeyDown}
                onSearch={onSearch}
                onToggleSection={this.onToggleSection}
                searchTerm={searchTerm}
                sections={sections}
                styles={styles}
            />
        );
    }
}

export const DashboardGalleryMenuContainer = compose()(DashboardGalleryMenu);
