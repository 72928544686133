import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { STREAM_DISPLAY_MODES } from 'consts';
import { styleSheet } from './stylesheet';

class StreetAccountCard extends PureComponent {
    static propTypes = {
        bookmarkId: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        displayMode: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any).isRequired,
        highlights: PropTypes.string,
        id: PropTypes.string.isRequired,
        matchId: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.objectOf(PropTypes.any).isRequired,
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        to: PropTypes.string
    };

    static defaultProps = {
        bookmarkId: undefined,
        highlights: undefined,
        passedStyles: {},
        score: undefined,
        streamId: undefined,
        to: null
    };

    renderCategories() {
        const { categories, styles } = this.props;

        const mappedCategories = categories.map(({ displayName, categoryId }) => (
            <Div key={categoryId} className="categoryTag" styles={styles.category}>
                <Text size={0} uppercase>
                    {displayName}
                </Text>
            </Div>
        ));

        if (categories && categories.length) {
            return (
                <Div className="categories" styles={styles.categoryContainer}>
                    <Text styles={styles.catLabel} size={0} uppercase>
                        streetaccount
                    </Text>
                    <Div styles={styles.categories}>{mappedCategories}</Div>
                </Div>
            );
        }

        return null;
    }

    render() {
        const {
            bookmarkId,
            displayMode,
            equities,
            highlights,
            id,
            matchId,
            onClick,
            passedStyles,
            publishedDate,
            score,
            streamId,
            styles,
            title,
            to
        } = this.props;
        if (STREAM_DISPLAY_MODES.compact === displayMode) {
            return (
                <Card
                    containerStyles={styles.containerCompact}
                    name="streetaccount-card-compact"
                    onClick={onClick}
                    styles={{ ...styles.compact, ...passedStyles }}
                    to={to}
                >
                    {({ isHovering }) => (
                        <Fragment>
                            {isHovering && (
                                <StreamActionMenu
                                    bookmarkId={bookmarkId}
                                    highlight={highlights}
                                    matchId={matchId}
                                    score={score}
                                    streamId={streamId}
                                    styles={styles.actionMenu}
                                    targetId={id}
                                    targetType="content"
                                    url={to}
                                />
                            )}
                            <CardHeader title={title} subtext={publishedDate} />
                        </Fragment>
                    )}
                </Card>
            );
        }
        const containerStyles =
            displayMode === STREAM_DISPLAY_MODES.default ? styles.container : styles.containerComfortable;
        return (
            <Card name="streetaccount-card" onClick={onClick} styles={{ ...containerStyles, ...passedStyles }} to={to}>
                {({ isHovering }) => (
                    <Fragment>
                        {isHovering && (
                            <StreamActionMenu
                                bookmarkId={bookmarkId}
                                highlight={highlights}
                                matchId={matchId}
                                score={score}
                                streamId={streamId}
                                styles={styles.actionMenu}
                                targetId={id}
                                targetType="content"
                                url={to}
                            />
                        )}
                        <CardEquities equities={equities} />
                        <CardHeader title={title} subtext={publishedDate} />
                        {displayMode === STREAM_DISPLAY_MODES.default && highlights && (
                            <Div styles={styles.content}>
                                <RawHTML html={highlights} />
                            </Div>
                        )}
                        {displayMode === STREAM_DISPLAY_MODES.default && this.renderCategories()}
                    </Fragment>
                )}
            </Card>
        );
    }
}

export const StreetAccountCardUI = compose(withStyleSheet(styleSheet))(StreetAccountCard);
