import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withUserPreferences } from 'graphql/user';
import { withUrlContext } from 'hoc/url';
import { copyToClipboard, generateTabURL, hasPreference } from 'utils';
import { PREFERENCES } from 'consts';
import { withData } from './data';
import { LiveTranscriptRowUI } from './ui';

export class LiveTranscriptRow extends PureComponent {
    static displayName = 'LiveTranscriptRowContainer';

    static propTypes = {
        dashSearchTerm: PropTypes.string,
        eventId: PropTypes.string.isRequired,
        itemId: PropTypes.string,
        partialTranscript: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        transcript: PropTypes.string,
        transcriptTime: PropTypes.instanceOf(XDate)
    };

    static defaultProps = {
        dashSearchTerm: undefined,
        itemId: null,
        partialTranscript: null,
        preferences: {},
        styles: undefined,
        transcript: '',
        transcriptTime: null
    };

    constructor(props) {
        super(props);

        this.onCopy = this.onCopy.bind(this);

        this.state = {
            copied: false
        };
    }

    onCopy(e) {
        const { transcript } = this.props;
        e.preventDefault();
        e.stopPropagation();
        copyToClipboard(transcript);
        this.setState(
            {
                copied: true
            },
            () => {
                setTimeout(() => {
                    this.setState({ copied: false });
                }, 5000);
            }
        );
    }

    render() {
        const {
            dashSearchTerm,
            eventId,
            itemId,
            partialTranscript,
            pathname,
            preferences,
            styles,
            transcript,
            transcriptTime
        } = this.props;
        const { copied } = this.state;

        return (
            <LiveTranscriptRowUI
                dashSearchTerm={dashSearchTerm}
                eventHref={generateTabURL({
                    eventId,
                    pathname,
                    page: itemId ? 'text' : null,
                    pageId: itemId
                })}
                partialTranscript={partialTranscript}
                showAnnotations={hasPreference(
                    preferences,
                    { ...PREFERENCES.showTranscriptAnnotations, value: true },
                    true
                )}
                onCopy={this.onCopy}
                copied={copied}
                styles={styles}
                transcript={transcript}
                transcriptTime={transcriptTime}
            />
        );
    }
}

export const LiveTranscriptRowContainer = compose(
    withUrlContext(['pathname']),
    withData(),
    withUserPreferences()
)(LiveTranscriptRow);
