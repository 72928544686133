import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { ExpandedDifferentialRowUI } from './ui';

export class ExpandedDifferentialRow extends PureComponent {
    static displayName = 'ExpandedDifferentialRowContainer';

    static propTypes = {
        chartHeight: PropTypes.number,
        data: PropTypes.arrayOf(PropTypes.any),
        highlights: PropTypes.arrayOf(PropTypes.any),
        labels: PropTypes.arrayOf(PropTypes.any),
        onChartDataPointClick: PropTypes.func,
        onNodeSelect: PropTypes.func
    };

    static defaultProps = {
        chartHeight: undefined,
        data: [],
        highlights: [],
        labels: [],
        onChartDataPointClick: null,
        onNodeSelect: undefined
    };

    constructor(props) {
        super(props);

        this.toggleMore = this.toggleMore.bind(this);

        this.state = {
            showMore: false
        };
    }

    toggleMore() {
        const { showMore } = this.state;
        this.setState({
            showMore: !showMore
        });
    }

    render() {
        const { labels, data, chartHeight, highlights, onChartDataPointClick, onNodeSelect } = this.props;
        const { showMore } = this.state;
        return (
            <ExpandedDifferentialRowUI
                showMore={showMore}
                toggleMore={this.toggleMore}
                labels={labels}
                data={data}
                onChartDataPointClick={onChartDataPointClick}
                onNodeSelect={onNodeSelect}
                chartHeight={chartHeight}
                highlights={highlights}
            />
        );
    }
}

export const ExpandedDifferentialRowContainer = compose()(ExpandedDifferentialRow);
