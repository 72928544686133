import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { routes } from 'routes';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Logo } from 'components/Logo';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class PublicLayout extends PureComponent {
    static propTypes = {
        extraContent: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {},
        extraContent: null
    };

    render() {
        const { extraContent, styles, passedStyles, theme, children } = this.props;

        const centerContent = (
            <Fragment>
                <Div styles={styles.logo}>
                    <Link to={routes.home} target="_blank">
                        <Logo />
                        <Text size={0} uppercase>
                            sign in
                        </Text>
                    </Link>
                    {extraContent}
                </Div>
                {children}
                <Div styles={styles.sales}>
                    <a href="mailto:sales@aiera.com">
                        <Text size={0} uppercase>
                            contact sales
                        </Text>
                    </a>
                </Div>
            </Fragment>
        );

        const leftPaneContent = (
            <Fragment>
                <Div styles={styles.logo}>
                    <Link to={routes.home} target="_blank">
                        <Logo />
                        <Text size={0} uppercase>
                            sign in
                        </Text>
                    </Link>
                    {extraContent}
                    <Div styles={styles.sales}>
                        <a href="mailto:sales@aiera.com">
                            <Text size={0} uppercase>
                                contact sales
                            </Text>
                        </a>
                    </Div>
                </Div>
                {children}
            </Fragment>
        );

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m =>
                        m ? (
                            children
                        ) : (
                            <MediaQuery maxWidth={extraContent ? '1600px' : '1300px'}>
                                {mq => (mq ? leftPaneContent : centerContent)}
                            </MediaQuery>
                        )
                    }
                </MediaQuery>
            </Div>
        );
    }
}

export const PublicLayoutUI = compose(withStyleSheet(styleSheet))(PublicLayout);
