import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import XDate from 'xdate';
import { PREFERENCES } from 'consts';
import { withReporting } from 'provider/reporting';
import { withActiveMediaPlayer } from 'hoc/media';
import { withUrlContext } from 'hoc/url';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { withUserPreferences } from 'graphql/user';
import { get, parseTabId, hasPreference, generateModalId, generateTabId } from 'utils';

import { PlayerUI } from './ui';

export class Player extends PureComponent {
    static displayName = 'PlayerContainer';

    static propTypes = {
        eventId: PropTypes.string,
        firstTranscriptItemStartMs: PropTypes.number,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isPublic: PropTypes.bool,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        processingAudio: PropTypes.bool.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        saveUXPreference: PropTypes.func.isRequired,
        shareId: PropTypes.string,
        showEventInfo: PropTypes.bool,
        showVolume: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        tabId: PropTypes.string,
        timelineOnly: PropTypes.bool,
        viewingActiveEvent: PropTypes.bool.isRequired,
        viewingNoEvent: PropTypes.bool.isRequired
    };

    static defaultProps = {
        eventId: undefined,
        firstTranscriptItemStartMs: undefined,
        isPublic: false,
        mediaPlayer: null,
        preferences: {},
        shareId: null,
        showEventInfo: false,
        showVolume: true,
        styles: undefined,
        tabId: undefined,
        timelineOnly: false
    };

    constructor(props) {
        super(props);

        this.timelineRef = createRef();

        this.toggleAutoPlay = this.toggleAutoPlay.bind(this);
        this.knobDrag = this.knobDrag.bind(this);
        this.knobRelease = this.knobRelease.bind(this);
        this.onDragPlayerKnob = this.onDragPlayerKnob.bind(this);
        this.openUpgradeModal = this.openUpgradeModal.bind(this);
        this.togglePlaybackRate = this.togglePlaybackRate.bind(this);
        this.seekBack = this.seekBack.bind(this);
        this.seekForward = this.seekForward.bind(this);
        this.seekStart = this.seekStart.bind(this);
        this.seekToClick = this.seekToClick.bind(this);
        this.startAudio = this.startAudio.bind(this);
        this.stopAudio = this.stopAudio.bind(this);

        this.state = {
            dragTime: null,
            loadingAudio: false
        };
    }

    componentDidUpdate(prevProps) {
        const { eventId, mediaPlayer, preferences, reporter } = this.props;
        const { eventId: prevEventId, mediaPlayer: prevMediaPlayer } = prevProps;
        const shouldAutoPlay = hasPreference(preferences, { ...PREFERENCES.autoPlayAudio, value: true }, true);

        // Only autoplay live starting events
        if (
            shouldAutoPlay &&
            eventId === prevEventId &&
            mediaPlayer.canListen &&
            mediaPlayer.hasLoadedAudio &&
            !prevMediaPlayer.hasLoadedAudio
        ) {
            const callDate = get(mediaPlayer, 'metaData.callDate');

            // This check is because if you reload the page, the conditions
            // above will be true.. So we don't auto play the event unless
            // it only started a few min ago... we may want to scrap this
            // check entirely
            if (callDate && new XDate(callDate).diffMinutes(new XDate()) < 4) {
                reporter.track(reporter.actions.click, reporter.objects.autoPlayAudio, {
                    type: 'autoplay',
                    live: !!get(mediaPlayer, 'isLive'),
                    component: 'Playbar',
                    eventId
                });
                this.startAudio();
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.resetDragTimeout);
    }

    onDragPlayerKnob(e) {
        e.preventDefault();
        e.stopPropagation();
        document.addEventListener('mousemove', this.knobDrag);
        document.addEventListener('mouseup', this.knobRelease);
    }

    openUpgradeModal() {
        const { history, pathname, tabId } = this.props;
        history.push(generateModalId({ pathname, id: 'liveEvents', type: 'upgrade', tabId }));
    }

    knobDrag(moveEvent) {
        const { mediaPlayer } = this.props;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const knobElem = timeline.querySelector('.knob');
        const xMovement = moveEvent.pageX || moveEvent.clientX;
        if (xMovement < timelineLeft + timelineWidth && xMovement > timelineLeft - 2) {
            const difference = xMovement - timelineLeft - 2;
            const completion = difference / timelineWidth;
            const { duration } = mediaPlayer;
            const dragTime = duration * completion;
            knobElem.style.left = `${difference}px`;
            this.setState({ dragTime });
        }
    }

    knobRelease(moveEvent) {
        const { eventId, mediaPlayer, reporter } = this.props;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const knobElem = timeline.querySelector('.knob');
        const xMovement = moveEvent.pageX || moveEvent.clientX;
        const difference = xMovement - timelineLeft - 2;
        const completion = difference / timelineWidth;
        const { duration } = mediaPlayer;
        const time = duration * completion;
        document.removeEventListener('mousemove', this.knobDrag);
        document.removeEventListener('mouseup', this.knobRelease);

        reporter.track(reporter.actions.click, reporter.objects.audioSeek, {
            type: 'drag',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId
        });
        mediaPlayer.seek(time);
        this.resetDragTimeout = setTimeout(() => {
            // Keeping this in a timeout makes sure the seek is complete
            // before removing the style, which will "jump" otherwise
            knobElem.removeAttribute('style');
            this.setState({ dragTime: null });
        }, 400);
    }

    seekBack() {
        const { eventId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioRewind, {
            type: 'rewind',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId
        });
        if (mediaPlayer.currentTime === 0 && !mediaPlayer.listening) {
            mediaPlayer.seek(Math.max(mediaPlayer.duration - 15, 0));
        } else {
            mediaPlayer.seek(Math.max(mediaPlayer.currentTime - 15, 0));
        }
    }

    seekForward() {
        const { eventId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioFF, {
            type: 'fast_forward',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId
        });
        mediaPlayer.seek(Math.max(mediaPlayer.currentTime + 15, 0));
    }

    seekStart() {
        const { eventId, mediaPlayer, reporter } = this.props;
        reporter.track(reporter.actions.click, reporter.objects.audioStartOver, {
            type: 'from_start',
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId
        });
        mediaPlayer.seek(0);
    }

    seekToClick(e) {
        const { eventId, mediaPlayer, reporter } = this.props;
        const { duration } = mediaPlayer;
        const timeline = this.timelineRef.current;
        const timelineLeft = timeline?.getBoundingClientRect()?.left;
        const timelineWidth = timeline?.getBoundingClientRect()?.width;
        const xMovement = e.nativeEvent.pageX || e.nativeEvent.clientX;

        if (mediaPlayer.seek && xMovement < timelineLeft + timelineWidth && xMovement > timelineLeft - 2) {
            const difference = xMovement - timelineLeft - 2;
            const time = duration * (difference / timelineWidth);
            reporter.track(reporter.actions.click, reporter.objects.audioSeek, {
                type: 'sync',
                live: !!get(mediaPlayer, 'isLive'),
                component: 'Playbar',
                eventId
            });
            mediaPlayer.seek(time);
        }
    }

    toggleAutoPlay() {
        const { saveUXPreference, preferences } = this.props;
        const shouldAutoPlay = hasPreference(preferences, { ...PREFERENCES.autoPlayAudio, value: true }, true);

        saveUXPreference({ name: 'autoPlayAudio', value: !shouldAutoPlay });
    }

    togglePlaybackRate(rateIndex) {
        const { eventId, mediaPlayer, reporter } = this.props;
        const rates = [1, 1.1, 1.25, 1.5, 1.75, 2, 2.5];
        const rate = rates[rateIndex];
        reporter.track(reporter.actions.click, reporter.objects.audioPlaybackRate, {
            rate,
            live: !!get(mediaPlayer, 'isLive'),
            component: 'Playbar',
            eventId
        });
        mediaPlayer.setPlaybackRate(rate);
    }

    startAudio() {
        const { eventId, firstTranscriptItemStartMs, mediaPlayer, reporter } = this.props;
        const isLive = !!get(mediaPlayer, 'isLive');
        reporter.track(reporter.actions.click, reporter.objects.audioPlay, {
            live: isLive,
            component: 'Playbar',
            eventId
        });

        this.setState({ loadingAudio: true }, () => {
            mediaPlayer
                .listen()
                .then(() => {
                    this.setState({ loadingAudio: false });
                })
                .catch(() => {
                    this.setState({ loadingAudio: false });
                });
            // If playing audio for the first time, seek to the first transcript item's startMs
            mediaPlayer.maybeSeekToFirstTranscriptItem(isLive, mediaPlayer.currentTime, firstTranscriptItemStartMs);
        });
    }

    stopAudio() {
        const { eventId, mediaPlayer, reporter } = this.props;
        if (mediaPlayer.seekable) {
            reporter.track(reporter.actions.click, reporter.objects.audioPause, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'Playbar',
                eventId
            });
            mediaPlayer.pause();
        } else {
            reporter.track(reporter.actions.click, reporter.objects.audioStop, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'Playbar',
                eventId
            });
            mediaPlayer.stop();
        }
    }

    render() {
        const {
            eventId,
            isPublic,
            mediaPlayer,
            pathname,
            preferences,
            processingAudio,
            shareId,
            showEventInfo,
            showVolume,
            styles,
            timelineOnly,
            viewingActiveEvent,
            viewingNoEvent
        } = this.props;
        const willAutoPlay = hasPreference(preferences, { ...PREFERENCES.autoPlayAudio, value: true }, true);
        const { dragTime, loadingAudio } = this.state;
        return eventId || shareId ? (
            <PlayerUI
                dragTime={dragTime}
                isPublic={isPublic}
                loadingAudio={loadingAudio}
                mediaPlayer={mediaPlayer}
                onDragPlayerKnob={this.onDragPlayerKnob}
                openUpgradeModal={this.openUpgradeModal}
                pathname={pathname}
                processingAudio={processingAudio}
                seekBack={this.seekBack}
                seekForward={this.seekForward}
                seekStart={this.seekStart}
                seekToClick={this.seekToClick}
                showEventInfo={showEventInfo}
                showVolume={showVolume}
                startAudio={this.startAudio}
                stopAudio={this.stopAudio}
                styles={styles}
                timelineRef={this.timelineRef}
                timelineOnly={timelineOnly}
                togglePlaybackRate={this.togglePlaybackRate}
                toggleAutoPlay={this.toggleAutoPlay}
                willAutoPlay={willAutoPlay}
                viewingActiveEvent={viewingActiveEvent}
                viewingNoEvent={viewingNoEvent}
            />
        ) : null;
    }
}

export const PlayerContainer = compose(
    withUserPreferences(),
    withUrlContext(['audioCallId', 'history', 'pathname', 'shareId', 'tabs']),
    withActiveMediaPlayer(undefined, 'activeMediaPlayer'),
    withPropsOnChange(['audioCallId', 'tabs', 'activeMediaPlayer'], ({ audioCallId, tabs = [], activeMediaPlayer }) => {
        const activeId = get(activeMediaPlayer, 'metaData.id');
        const eventId = audioCallId || get(parseTabId(tabs[0]), 'eventId');
        return {
            activeId,
            audioCallId: activeId || eventId,
            eventId: activeId || eventId,
            tabId: generateTabId({ eventId }),
            viewingActiveEvent: eventId === activeId,
            viewingNoEvent: !eventId
        };
    }),
    withEventMediaPlayer({ allowLiveStream: true, loadAudio: true }),
    withReporting(),
    withPropsOnChange(['audioCall'], ({ audioCall }) => ({
        firstTranscriptItemStartMs: get(audioCall, 'firstTranscriptItemStartMs'),
        processingAudio: get(audioCall, 'processingAudio', false)
    }))
)(Player);
