import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { withData } from './data';
import { EventPriceChartUI } from './ui';

export class EventPriceChart extends PureComponent {
    static displayName = 'EventPriceChartContainer';

    static propTypes = {
        callEndTime: PropTypes.string,
        callStartTime: PropTypes.string,
        chartRef: PropTypes.objectOf(PropTypes.any),
        endPrice: PropTypes.number,
        equity: PropTypes.objectOf(PropTypes.any),
        equityId: PropTypes.string,
        eventType: PropTypes.string,
        inProgress: PropTypes.bool,
        lastClose: PropTypes.number,
        onTimeSelect: PropTypes.func,
        priceData: PropTypes.arrayOf(PropTypes.any),
        realtimePrices: PropTypes.arrayOf(PropTypes.object),
        refreshPrices: PropTypes.func,
        renderChartHeader: PropTypes.func,
        selectedTime: PropTypes.string,
        startPrice: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string,
        volumeData: PropTypes.arrayOf(PropTypes.any),
        showChart: PropTypes.bool
    };

    static defaultProps = {
        callEndTime: null,
        callStartTime: null,
        chartRef: null,
        endPrice: null,
        equity: null,
        equityId: null,
        eventType: null,
        inProgress: false,
        lastClose: null,
        onTimeSelect: null,
        priceData: null,
        realtimePrices: null,
        refreshPrices: null,
        renderChartHeader: null,
        selectedTime: null,
        startPrice: null,
        styles: undefined,
        title: undefined,
        volumeData: null,
        showChart: true
    };

    constructor(props) {
        super(props);

        this.closeChartModal = this.closeChartModal.bind(this);
        this.openChartModal = this.openChartModal.bind(this);

        this.state = { isChartModalOpen: false };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const { inProgress, refreshPrices } = this.props;
            if (inProgress && refreshPrices) {
                refreshPrices();
            }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    closeChartModal() {
        this.setState({ isChartModalOpen: false });
    }

    openChartModal() {
        this.setState({ isChartModalOpen: true });
    }

    render() {
        const {
            callEndTime,
            callStartTime,
            chartRef,
            endPrice,
            equity,
            equityId,
            eventType,
            lastClose,
            onTimeSelect,
            priceData,
            realtimePrices,
            renderChartHeader,
            selectedTime,
            startPrice,
            styles,
            title,
            volumeData,
            showChart
        } = this.props;
        const { isChartModalOpen } = this.state;
        const equityHref = equityId ? generatePath(routes.equity, { equityId }) : null;
        return (
            <EventPriceChartUI
                callEndTime={callEndTime}
                callStartTime={callStartTime}
                chartRef={chartRef}
                closeChartModal={this.closeChartModal}
                endPrice={endPrice}
                equity={equity}
                equityHref={equityHref}
                eventType={eventType}
                isChartModalOpen={isChartModalOpen}
                lastClose={lastClose}
                onTimeSelect={onTimeSelect}
                openChartModal={this.openChartModal}
                priceData={priceData}
                realtimePrices={realtimePrices}
                renderChartHeader={renderChartHeader}
                selectedTime={selectedTime}
                startPrice={startPrice}
                styles={styles}
                title={title}
                volumeData={volumeData}
                showChart={showChart}
            />
        );
    }
}

export const EventPriceChartContainer = compose(withData())(EventPriceChart);
