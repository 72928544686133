import { DASHBOARD_ENDPOINT_PROD } from 'configuration';
import { toDateTimeString, get, generateTabId } from 'utils';

export const generateWordDocument = ({ matches, title: docTitle, searchTerms, onComplete }) => {
    import('docx').then(
        ({ Document, Packer, Paragraph, TextRun, SectionType, ExternalHyperlink, HeadingLevel, AlignmentType }) => {
            const fileName = docTitle
                .replace(/\./g, '')
                .replace(/,/g, '')
                .replace(/\s+/g, '-')
                .toLowerCase();

            const titleObj = [
                new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: docTitle,
                            bold: true,
                            color: '#000000'
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: searchTerms.join(', ')
                        })
                    ],
                    spacing: {
                        after: 400
                    }
                })
            ];

            function newsMatch({ title, id, publishedDate, ticker, newsSource, highlights, grouped }) {
                const textSet = [];

                // Title for this Match
                textSet.push(
                    new Paragraph({
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: title,
                                        bold: true,
                                        style: 'Hyperlink'
                                    })
                                ],
                                link: `${DASHBOARD_ENDPOINT_PROD}companies?tabs[0]=${generateTabId({ newsId: id })}`
                            })
                        ]
                    })
                );

                textSet.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: ticker
                                    ? `${ticker} | ${newsSource} — ${toDateTimeString(publishedDate)}`
                                    : `${newsSource} - ${toDateTimeString(publishedDate)}`,
                                bold: false
                            })
                        ]
                    })
                );

                // Match Text
                textSet.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: highlights ? highlights.replace(/<[^>]*>?/gm, '') : ''
                            })
                        ]
                    })
                );

                (grouped || []).forEach(({ highlights: groupedHighlights = '' }) => {
                    // Match Text
                    textSet.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: groupedHighlights ? groupedHighlights.replace(/<[^>]*>?/gm, '') : ''
                                })
                            ]
                        })
                    );
                });

                textSet.push(
                    new Paragraph({
                        spacing: {
                            after: 100
                        },
                        children: [
                            new TextRun({
                                text: '_____________________________________________'
                            })
                        ]
                    })
                );

                return textSet;
            }

            function transcriptMatch({
                callDate,
                eventId,
                eventType,
                grouped,
                startTimestamp,
                ticker,
                title,
                transcriptRaw: transcript,
                speaker: speakerMatch
            }) {
                const speakerAffiliation = get(speakerMatch, 'affiliation');
                const speakerName = get(speakerMatch, 'name');
                const speakerTitle = get(speakerMatch, 'title');
                const textSet = [];
                // Title for this Match
                textSet.push(
                    new Paragraph({
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: title,
                                        bold: true,
                                        style: 'Hyperlink'
                                    })
                                ],
                                link: `${DASHBOARD_ENDPOINT_PROD}dashboard/events?tabs[0]=${generateTabId({ eventId })}`
                            })
                        ]
                    })
                );

                textSet.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: ticker
                                    ? `${ticker} | ${eventType} — ${toDateTimeString(callDate)}`
                                    : `${eventType} - ${toDateTimeString(callDate)}`,
                                bold: false
                            })
                        ]
                    })
                );

                // Transcript Speaker
                const speaker = [
                    new TextRun({
                        text: speakerName || '',
                        bold: true
                    })
                ];

                if (speakerTitle) {
                    speaker.push(new TextRun(`, ${speakerTitle}`));
                }

                if (speakerAffiliation) {
                    speaker.push(new TextRun(`, ${speakerAffiliation}`));
                }

                textSet.push(
                    new Paragraph({
                        children: speaker,
                        spacing: {
                            before: speakerName ? 200 : 100
                        }
                    })
                );

                // Time of spoken text
                textSet.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: toDateTimeString(startTimestamp, false, false, 'h:mm TT MMM dS, yyyy')
                            })
                        ]
                    })
                );

                // Match Text
                textSet.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: transcript ? transcript.replace(/<[^>]*>?/gm, '') : ''
                            })
                        ]
                    })
                );

                (grouped || []).forEach(
                    ({
                        startTimestamp: groupedTimestamp,
                        transcriptRaw: groupedTranscript,
                        speaker: groupedSpeakerMatch
                    }) => {
                        const groupedSpeakerAffiliation = get(groupedSpeakerMatch, 'affiliation');
                        const groupedSpeakerName = get(groupedSpeakerMatch, 'name');
                        const groupedSpeakerTitle = get(groupedSpeakerMatch, 'title');
                        // Transcript Speaker
                        const groupedSpeaker = [
                            new TextRun({
                                text: groupedSpeakerName || '',
                                bold: true
                            })
                        ];

                        if (groupedSpeakerTitle) {
                            groupedSpeaker.push(new TextRun(`, ${groupedSpeakerTitle}`));
                        }

                        if (groupedSpeakerAffiliation) {
                            groupedSpeaker.push(new TextRun(`, ${groupedSpeakerAffiliation}`));
                        }

                        textSet.push(
                            new Paragraph({
                                children: groupedSpeaker,
                                spacing: {
                                    before: groupedSpeakerName ? 200 : 100
                                }
                            })
                        );

                        // Time of spoken text
                        textSet.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: toDateTimeString(groupedTimestamp, false, false, 'h:mm TT MMM dS, yyyy')
                                    })
                                ]
                            })
                        );

                        // Match Text
                        textSet.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: groupedTranscript ? groupedTranscript.replace(/<[^>]*>?/gm, '') : ''
                                    })
                                ]
                            })
                        );
                    }
                );

                textSet.push(
                    new Paragraph({
                        spacing: {
                            after: 100
                        },
                        children: [
                            new TextRun({
                                text: '_____________________________________________'
                            })
                        ]
                    })
                );

                return textSet;
            }

            const doc = new Document({
                sections: [
                    {
                        properties: {
                            type: SectionType.CONTINUOUS
                        },
                        children: titleObj
                    },
                    ...matches.map(match => {
                        const { contentType } = match;
                        if (contentType === 'transcript') {
                            // handle transcript match
                            return {
                                properties: {
                                    type: SectionType.CONTINUOUS
                                },
                                children: transcriptMatch(match)
                            };
                        }

                        // handle news match
                        return {
                            properties: {
                                type: SectionType.CONTINUOUS
                            },
                            children: newsMatch(match)
                        };
                    })
                ]
            });

            // Used to export the file into a .docx file
            Packer.toBlob(doc)
                .then(blob => {
                    import('file-saver').then(({ saveAs }) => saveAs(blob, fileName));
                })
                .then(onComplete)
                // eslint-disable-next-line no-console
                .catch(() => console.log('Could not save file'));
        }
    );
};
