export const styleSheet = theme => ({
    container: {
        zIndex: 5,
        position: 'absolute',
        top: '-4px',
        left: 40,
        minWidth: '200px',
        maxWidth: '240px',
        backgroundColor: theme.colors.gray08,
        padding: '2px 0 0',
        boxShadow: `0 2px 8px 0 ${theme.colors.gray02}`,
        borderRadius: 6
    },
    dropDownMenuRemoveRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '35px',
        padding: '0 10px 0 16px',
        letterSpacing: '1px',
        color: theme.colors.gray04,
        borderRadius: '0 0 6px 6px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            color: theme.colors.white01,
            'svg g': {
                fill: theme.colors.white01
            }
        }
    },
    dropDownMenuOption: {
        padding: '8px 16px',
        cursor: 'pointer',
        color: theme.colors.white03,
        whiteSpace: 'normal',
        lineHeight: '1.3em',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        ':last-of-type': {
            borderBottom: 'unset'
        },
        ':hover': {
            whiteSpace: 'normal',
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
    dropDownMenuCategory: {
        padding: '8px 16px',
        color: theme.colors.yellow02,
        whiteSpace: 'normal',
        backgroundColor: theme.colors.gray08,
        position: 'sticky',
        top: 0,
        letterSpacing: '1px',
        zIndex: 1
    },
    dropDownMenuOptionLoading: {
        padding: '8px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
    },
    menuSearchInput: {
        margin: '6px 0 5px 8px',
        flex: 1,
        input: {
            height: '30px',
            borderRadius: '6px',
            fontSize: theme.fonts.fontSizes.size03,
            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                fontSize: '16px'
            }
        },
        div: {
            height: '30px',
            right: 10
        }
    },
    searchHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '3px'
    },
    closeMenu: {
        padding: '3px 8px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ':hover': {
            'svg g': {
                fill: theme.colors.gray04
            }
        }
    },
    optionsContainer: {
        maxHeight: '168px',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch'
    },
    optionsContainerWithCategories: {
        maxHeight: '156px',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch'
    }
});
