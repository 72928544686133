import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class DateDivider extends PureComponent {
    static propTypes = {
        onScrollToTop: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(XDate).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { onScrollToTop, passedStyles, styles, date } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Text size={0} uppercase>
                    {date.toString('ddd, MMM dd, yyyy')}
                </Text>
                <Text onClick={onScrollToTop} className="dateScrollTop" size={0} uppercase>
                    Top
                </Text>
            </Div>
        );
    }
}

export const DateDividerUI = compose(withStyleSheet(styleSheet))(DateDivider);
