import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { VIDEO_URLS } from 'consts';
import { get } from 'utils';
import { VideoModalUI } from './ui';

export class VideoModal extends PureComponent {
    static displayName = 'VideoModalContainer';

    static propTypes = {
        onClose: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        videoType: PropTypes.string.isRequired
    };

    static defaultProps = {
        styles: undefined
    };

    render() {
        const { onClose, styles, videoType } = this.props;
        return (
            <VideoModalUI
                onClose={onClose}
                styles={styles}
                title={get(VIDEO_URLS, `${videoType}.title`)}
                videoType={videoType}
            />
        );
    }
}

export const VideoModalContainer = compose()(VideoModal);
