import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { ModuleTdUI } from './ui';

export class ModuleTd extends PureComponent {
    static displayName = 'ModuleTdContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ModuleTdUI {...rest} />;
    }
}

export const ModuleTdContainer = compose()(ModuleTd);
