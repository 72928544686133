import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';
import { dashboardFragment } from 'graphql/fragments/streams';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withDashboardDropdownData($filter: DashboardsFilter) {
                    dashboards(filter: $filter) {
                        ...dashboard
                    }
                }
                ${dashboardFragment}
            `,
            {
                props: ({ data }) => ({
                    dashboards: get(data, 'dashboards', [])
                }),
                options: ({ hasCapabilities: capabilities }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: capabilities ? { filter: { capabilities } } : { filter: { capabilities: [] } }
                })
            }
        )
    );
