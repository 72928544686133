import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ConnectionDetailsUI } from './ui';

export class ConnectionDetails extends PureComponent {
    static displayName = 'ConnectionDetailsContainer';

    static propTypes = {
        connectAccessId: PropTypes.string,
        connectCallerId: PropTypes.string,
        connectionType: PropTypes.string,
        connectPhoneNumber: PropTypes.string,
        connectPin: PropTypes.string,
        connectUrl: PropTypes.string,
        domainScore: PropTypes.number,
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        externalAudioStreamUrl: PropTypes.string,
        filename: PropTypes.string,
        meetingType: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onConnectDialNumber: PropTypes.string,
        onFileDelete: PropTypes.func.isRequired,
        onFileUpload: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        organizationName: PropTypes.string,
        participationType: PropTypes.string,
        smsAlertBeforeCall: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        touched: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        connectAccessId: undefined,
        connectCallerId: undefined,
        connectionType: undefined,
        connectPhoneNumber: undefined,
        connectPin: undefined,
        connectUrl: undefined,
        domainScore: undefined,
        externalAudioStreamUrl: undefined,
        filename: undefined,
        onConnectDialNumber: undefined,
        organizationName: undefined,
        participationType: undefined,
        styles: undefined
    };

    render() {
        const {
            connectAccessId,
            connectCallerId,
            connectionType,
            connectPhoneNumber,
            connectPin,
            connectUrl,
            domainScore,
            errors,
            externalAudioStreamUrl,
            filename,
            meetingType,
            onBlur,
            onChange,
            onConnectDialNumber,
            onFileDelete,
            onFileUpload,
            onFocus,
            organizationName,
            participationType,
            smsAlertBeforeCall,
            styles,
            touched
        } = this.props;
        return (
            <ConnectionDetailsUI
                connectAccessId={connectAccessId}
                connectCallerId={connectCallerId}
                connectionType={connectionType}
                connectPhoneNumber={connectPhoneNumber}
                connectPin={connectPin}
                connectUrl={connectUrl}
                domainScore={domainScore}
                errors={errors}
                externalAudioStreamUrl={externalAudioStreamUrl}
                filename={filename}
                lockConnectionTypeSelect={!!filename}
                meetingType={meetingType}
                onBlur={onBlur}
                onChange={onChange}
                onConnectDialNumber={onConnectDialNumber}
                onFileDelete={onFileDelete}
                onFileUpload={onFileUpload}
                onFocus={onFocus}
                organizationName={organizationName}
                participationType={participationType}
                smsAlertBeforeCall={smsAlertBeforeCall}
                styles={styles}
                touched={touched}
            />
        );
    }
}

export const ConnectionDetailsContainer = compose()(ConnectionDetails);
