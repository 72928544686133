export const styleSheet = theme => ({
    container: {
        ...theme.content.box,
        backgroundColor: theme.colors.white01,
        margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`
    },
    mentions: {
        border: 'unset',
        boxShadow: 'unset',
        marginTop: 'unset',
        paddingBottom: 0,
        margin: '20px 0 0'
    },
    trackTerm: {
        padding: theme.margins.margin00,
        display: 'flex'
    },
    input: {
        width: '100%',
        input: {
            fontSize: theme.fonts.fontSizes.size01,
            height: 30,
            lineHeight: '30px'
        }
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        cursor: 'pointer',
        marginLeft: 6,
        padding: '0 10px',
        lineHeight: '30px',
        height: 30,
        backgroundColor: theme.colors.gray08,
        color: theme.colors.white01,
        ':hover': {
            backgroundColor: theme.colors.gray11
        }
    }
});
