export const styleSheet = theme => ({
    container: {
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'space-between',
        ':hover': {
            '.form-row-label': {
                color: theme.colors.black01
            }
        }
    },
    description: {
        display: 'block',
        color: theme.colors.gray04,
        lineHeight: '1.3em'
    },
    label: {
        ...theme.content.formLabel,
        display: 'block',
        lineHeight: '1.3em',
        textTransform: 'none'
    },
    leftSide: {
        maxWidth: 180,
        width: '100%'
    },
    rightSide: {
        flex: 1,
        paddingLeft: 30
    }
});
