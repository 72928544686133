export const styleSheet = () => ({
    container: {
        minWidth: 200,
        input: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            paddingRight: 32,
            borderRadius: 6
        }
    }
});
