import gql from 'graphql-tag';

export const userFragment = gql`
    fragment user on User {
        id
        apiKey
        apiKeyPublic
        billingProductPriceId
        createDate
        customerType
        email: username
        firstName
        hasApp
        icalToken
        isAdmin
        isAnalyst
        isEditor
        lastActive
        lastName
        numFollowedEquities
        organizationAdmin
        organizationId
        passwordStatus
        permissions
        phone
        primaryEmail
        pusherToken
        status
        tier
        timezone
        lastRequestTimezone
        usedIcal
        userId
        username
        organization {
            id
            apiKey
            pusherToken
        }
        identityProvider {
            id
            providerName
        }
    }
`;

export const preferenceFragment = gql`
    fragment preference on UserPreference {
        preferenceType
        preferenceName
        preferenceValue
        preferenceData
    }
`;

export const organizationFragment = gql`
    fragment organization on Organization {
        id
        isSelfServeBilling
        billingSource
        isCustomer
        privateRecordingCount
        name
        seatAllotment
    }
`;

export const domainFragment = gql`
    fragment domain on DomainConfiguration {
        domain
        weight
        blacklisted
    }
`;

export const codeFragment = gql`
    fragment code on Code {
        code
    }
`;

export const eventPreferencesFragment = gql`
    fragment eventPreferences on GlobalRealtimeEventNotificationPreferences {
        id
        inAppEnabled
        emailEnabled
        eventQuestionAnsweredEnabled
        eventSummariesEnabled
        upcomingEventEnabled
        quicklinkMatchesEnabled
        streamMatchesEnabled
        spotlightMatchesEnabled
        priceReactionsEnabled
        weeklyDigestDaysAhead
        equityScopeRules {
            condition
            ruleType
            value
            equity: targetEquity {
                id
                localTicker
                exchange {
                    id
                    shortName
                }
            }
            index: targetIndex {
                id
                displayName
            }
            sector: targetGicsSector {
                id
                name
            }
            subSector: targetGicsSubSector {
                id
                name
            }
            watchlist: targetWatchlist {
                id
                name
            }
        }
    }
`;

export const streamPreferencesFragment = gql`
    fragment streamPreferences on GlobalRealtimeStreamNotificationPreferences {
        id
        inAppEnabled
        emailEnabled
    }
`;
