import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { WithPermission } from 'components/WithPermission';
import { Icon } from 'components/Icon';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class UpsellGate extends PureComponent {
    static propTypes = {
        companyName: PropTypes.string,
        description: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        locked: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        pageTitle: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        permission: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        companyName: undefined,
        description: undefined,
        onClick: undefined,
        pageTitle: undefined,
        passedStyles: {},
        subtitle: undefined
    };

    render() {
        const {
            companyName,
            description,
            locked,
            loading,
            onClick,
            pageTitle,
            passedStyles,
            permission,
            styles,
            subtitle,
            theme,
            title
        } = this.props;
        return (
            <WithPermission permissions={permission}>
                {({ restricted, isLoading }) =>
                    restricted && locked && !loading && !isLoading ? (
                        <Div styles={{ ...styles.container, ...passedStyles }}>
                            <Div styles={styles.upsellContent} onClick={onClick}>
                                <Div styles={styles.upsellTitle}>
                                    <Text size={5} weight="medium">
                                        {title}
                                    </Text>
                                    <Icon type="lock02" color={theme.colors.black01} />
                                </Div>
                                <Text size={3}>{description}</Text>
                                {companyName && (
                                    <Fragment>
                                        <Text weight="medium" size={1} styles={styles.eventTitle}>
                                            {companyName}
                                        </Text>
                                        <Text size={1}>{pageTitle}</Text>
                                        {subtitle && (
                                            <Text styles={styles.subtitle} size={1}>
                                                {subtitle}
                                            </Text>
                                        )}
                                    </Fragment>
                                )}
                            </Div>
                        </Div>
                    ) : null
                }
            </WithPermission>
        );
    }
}

export const UpsellGateUI = compose(withStyleSheet(styleSheet))(UpsellGate);
