import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { LoaderLogo } from 'components/LoaderLogo';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Th } from 'components/Th';
import { Tr } from 'components/Tr';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'companies';

class CompanyResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            styles,
            enabled,
            results,
            hasMoreResults,
            loading,
            loadingMoreResults,
            loadMoreResults,
            highlightedResult,
            highlightResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) {
            return null;
        }

        return (
            <Table styles={styles.results}>
                <Tr styles={styles.resultHeaders}>
                    <Th colSpan={2}>Companies</Th>
                    <Th>Ticker</Th>
                    <Th>Exchange</Th>
                </Tr>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results.map((c, index) => {
                        const { companyId, companyName, exchangeName, iconUrl, initials, ticker } = c;
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        return (
                            <Tr
                                elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                key={`company-${companyId}-${ticker}`}
                                styles={resultStyles}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                                onClick={onClickResult}
                            >
                                <Td>
                                    {iconUrl ? (
                                        <Div styles={styles.companyIcon}>
                                            <Img src={iconUrl} />
                                        </Div>
                                    ) : (
                                        <Div styles={styles.companyInitials}>
                                            <Text className="companyInitials" uppercase size={0}>
                                                {initials}
                                            </Text>
                                        </Div>
                                    )}
                                </Td>
                                <Td>{companyName}</Td>
                                <Td>{ticker}</Td>
                                <Td>{exchangeName}</Td>
                            </Tr>
                        );
                    })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr>
                        <Td colSpan={4}>
                            <ActionButton onClick={() => loadMoreResults('company')} styles={styles.loadMoreButton}>
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const CompanyResultsUI = compose(withStyleSheet(styleSheet))(CompanyResults);
