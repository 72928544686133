import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { get, toDateString, toDateTimeString, generateTabURL } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { BackButton } from 'components/BackButton';
import { ContentCompanies } from 'components/ContentCompanies';
import { Div } from 'components/Div';
import { Heading } from 'components/Heading';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { ContentActionBlock } from 'components/ContentActionBlock';
import { styleSheet } from './stylesheet';

class Spotlight extends PureComponent {
    static propTypes = {
        body: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        companies: PropTypes.arrayOf(PropTypes.any).isRequired,
        company: PropTypes.string,
        companyId: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        contentType: PropTypes.string.isRequired,
        displayType: PropTypes.string,
        eventDate: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.any).isRequired,
        exchangeName: PropTypes.string,
        isArchived: PropTypes.bool.isRequired,
        isPopout: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        loadingDetails: PropTypes.bool.isRequired,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        spotlightId: PropTypes.string,
        spotlightSubtype: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tags: PropTypes.arrayOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string.isRequired,
        userTags: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        body: undefined,
        company: undefined,
        companyId: undefined,
        displayType: undefined,
        eventDate: undefined,
        exchangeName: undefined,
        minimized: false,
        passedStyles: {},
        publishedDate: undefined,
        setToolbarTitle: null,
        spotlightId: null,
        spotlightSubtype: undefined,
        ticker: undefined
    };

    componentDidUpdate() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    renderCategories() {
        const { categories, styles } = this.props;

        const mappedCategories = categories.map(({ displayName, categoryId }) => (
            <Div key={`sa-page-cat-${categoryId}`} styles={styles.category}>
                <Text size={0} uppercase>
                    {displayName}
                </Text>
            </Div>
        ));

        if (categories && categories.length) {
            return (
                <Div styles={styles.categoryContainer}>
                    <Text styles={styles.catLabel} size={0} uppercase>
                        News categories
                    </Text>
                    <Div styles={styles.categories}>{mappedCategories}</Div>
                </Div>
            );
        }

        return null;
    }

    renderCompany() {
        const { company, companyId, styles, ticker, exchangeName } = this.props;

        if (!company && !ticker) {
            return null;
        }

        const content = (
            <Fragment>
                <Text styles={styles.ticker} size={1} uppercase weight="medium">
                    {`${ticker}:${exchangeName}`}
                </Text>
                <Text size={1} styles={styles.company}>
                    {company}
                </Text>
            </Fragment>
        );

        if (companyId) {
            return (
                <Div styles={styles.companyInfo} title="View company">
                    <InternalLink to={generatePath(routes.company, { companyId })} styles={styles.companyLink}>
                        {content}
                    </InternalLink>
                </Div>
            );
        }

        return (
            <Div styles={styles.companyInfo} title="View company">
                {content}
            </Div>
        );
    }

    renderFullText() {
        const {
            body,
            companies,
            contentType,
            displayType,
            eventDate,
            isArchived,
            isRead,
            isStarred,
            publishedDate,
            spotlightId,
            spotlightSubtype,
            styles,
            tags,
            title,
            userTags
        } = this.props;
        if (body) {
            return (
                <Div styles={styles.fullTextContainer} className="enable-quick-context">
                    {companies.length > 1 ? <ContentCompanies companies={companies} /> : this.renderCompany()}
                    <Heading size={1} styles={styles.fullTextHeading}>
                        {title || 'No document title'}
                    </Heading>
                    <Div styles={styles.sourceDate}>
                        <Text span styles={styles.spotlightTag} size={0}>
                            {displayType}
                            {spotlightSubtype && (
                                <Text span styles={styles.spotlightSubTag} size={0}>
                                    {spotlightSubtype}
                                </Text>
                            )}
                        </Text>
                        <Text size={1} styles={styles.publishDate}>
                            &nbsp;• {toDateString(eventDate, true)}
                            &nbsp;• Updated: {toDateTimeString(publishedDate, true)}
                        </Text>
                    </Div>
                    <ContentActionBlock
                        contentType={contentType}
                        isArchived={isArchived}
                        isRead={isRead}
                        isStarred={isStarred}
                        tags={tags}
                        userTags={userTags}
                        targetId={spotlightId}
                        targetType="content"
                        styles={styles.actionBlock}
                    />
                    <Div styles={styles.documentContent}>
                        <RawHTML html={body} />
                    </Div>
                    {this.renderCategories()}
                </Div>
            );
        }

        return null;
    }

    renderEvents(restricted) {
        const { events, pathname, styles, isPopout } = this.props;
        if (!events || !events.length) return null;

        if (isPopout) {
            // TODO instead, navigate to popout URL, not tabURL
            return null;
        }

        return (
            <Div styles={styles.eventsContainer}>
                <Text weight="medium" styles={styles.eventsTitle}>
                    Related Events
                </Text>
                {events.map(event => (
                    <InternalLink
                        key={get(event, 'id')}
                        to={restricted ? undefined : generateTabURL({ pathname, eventId: get(event, 'id') })}
                    >
                        <Div styles={styles.event}>
                            <Text styles={styles.eventTitle}>{get(event, 'title')}</Text>
                            <Text styles={styles.eventDate}>{toDateString(get(event, 'callDate'))}</Text>
                        </Div>
                    </InternalLink>
                ))}
            </Div>
        );
    }

    render() {
        const { styles, theme, passedStyles, loadingDetails, body, containerRef, minimized } = this.props;

        if (minimized) {
            return null;
        }

        if (loadingDetails && !body) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {m => m && <BackButton back styles={styles.backButton} />}
                    </MediaQuery>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        return (
            <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => m && <BackButton back styles={styles.backButton} />}
                </MediaQuery>
                {this.renderFullText()}
                {this.renderEvents()}
            </Div>
        );
    }
}

export const SpotlightUI = compose(withStyleSheet(styleSheet))(Spotlight);
