export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white02,
        borderBottom: `solid 1px ${theme.colors.gray01}`,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.margins.margin00,
        position: 'relative',
        ':hover': {
            backgroundColor: theme.colors.white03
        }
    },
    containerUnread: {
        backgroundColor: 'rgb(230, 242, 255)'
    },
    bold: {
        fontWeight: theme.fonts.fontWeights.medium
    },
    notificationBody: {
        lineHeight: '1.2em',
        paddingBottom: 10
    },
    notificationContainer: {
        flex: 1
    },
    notificationEmoji: {
        marginRight: 3
    },
    notificationFooter: {
        color: theme.colors.gray06
    },
    notificationHeader: {
        paddingBottom: 10
    }
});
