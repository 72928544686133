import gql from 'graphql-tag';
import flatten from 'lodash/flatten';
import { compose } from 'recompose';
import { dashboardFragment, streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withDashboardGallery($searchTerm: String) {
                    galleryDashboards(filter: { search: $searchTerm }) {
                        tag
                        dashboards {
                            ...dashboard
                            clonedToId
                        }
                    }
                }
                ${dashboardFragment}
            `,
            {
                props: ({ data }) => {
                    const sections = get(data, 'galleryDashboards');
                    return {
                        dashboards: flatten((sections || []).map(s => s.dashboards)),
                        loading: get(data, 'loading'),
                        sections
                    };
                },
                options: {
                    context: {
                        debounceKey: 'dashboardGalleryData',
                        debounceTimeout: 300
                    },
                    pollInterval: 3600000
                }
            }
        ),
        graphql(
            gql`
                query withDashboardGalleryStreams($dashboardId: ID) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        id
                        streams {
                            ...stream
                        }
                    }
                }
                ${streamFragment}
            `,
            {
                props: ({ data }) => ({
                    streams: get(data, 'dashboards[0].streams'),
                    streamsLoading: get(data, 'loading')
                }),
                skip: ({ currentDashboardId }) => !currentDashboardId,
                options: ({ currentDashboardId }) => ({
                    context: {
                        debounceKey: 'dashboardGalleryStreamsData',
                        debounceTimeout: 300
                    },
                    pollInterval: 3600000,
                    variables: {
                        dashboardId: currentDashboardId
                    }
                })
            }
        )
    );
