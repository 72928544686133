import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { styleSheet } from './stylesheet';

class SpotlightTypeAutocomplete extends PureComponent {
    static propTypes = {
        getTagLabel: PropTypes.func.isRequired,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        useTags: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
    };

    static defaultProps = {
        initialSearchTerm: null,
        label: null,
        passedStyles: {},
        value: null
    };

    render() {
        const {
            getTagLabel,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            onChange,
            options,
            passedStyles,
            placeholder,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <Autocomplete
                getTagLabel={getTagLabel}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                menuStyles={styles.menu}
                multi={multi}
                name={name}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                useTags={useTags}
                value={value}
            />
        );
    }
}

export const SpotlightTypeAutocompleteUI = compose(withStyleSheet(styleSheet))(SpotlightTypeAutocomplete);
