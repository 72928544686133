export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01,
        position: 'relative',
        borderRadius: 6.5,
        overflow: 'hidden',
        width: 'calc(100% - 80px)',
        maxWidth: 1000,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            position: 'absolute',
            top: 'env(safe-area-inset-top)',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            overflowY: 'auto',
            width: 'unset'
        }
    },
    content: {
        position: 'relative'
    },
    chart: {},
    noStreamsContainer: {
        backgroundColor: theme.colors.white01,
        textAlign: 'center',
        lineHeight: '1.2em',
        color: theme.colors.gray06,
        borderRadius: '0 0 6px 6px',
        padding: `${theme.margins.margin01} 0 ${theme.margins.margin02} 0`
    },
    mobileSearchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 17px 3px'
    },
    searchMobile: {
        display: 'inline-block',
        backgroundColor: theme.colors.white01,
        height: 30,
        input: {
            paddingLeft: 30,
            borderRadius: 15,
            height: 30,
            width: 240
        },
        '.iconWrapper': {
            left: 8,
            height: 30,
            svg: {
                height: 14
            }
        }
    },
    search: {
        display: 'inline-block',
        backgroundColor: theme.colors.white01,
        height: 30,
        margin: '0 17px 3px',
        input: {
            paddingLeft: 30,
            borderRadius: 15,
            height: 30,
            width: 240
        },
        '.iconWrapper': {
            left: 8,
            height: 30,
            svg: {
                height: 14
            }
        }
    },
    exportButton: {
        position: 'absolute',
        right: 30,
        top: 7,
        zIndex: 1,
        display: 'flex',
        marginRight: 6,
        height: 30,
        padding: `0 10px`,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3.5,
        color: theme.colors.gray06,
        svg: {
            marginLeft: theme.margins.margin00
        },
        ':hover': {
            textDecoration: 'none',
            color: theme.colors.black01,
            backgroundColor: theme.colors.gray10,
            'svg g': {
                fill: theme.colors.black01
            }
        }
    },
    dot: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginRight: theme.margins.margin00
    },
    streams: {
        '.dots': {
            height: 10
        },
        th: {
            color: theme.colors.gray06,
            fontSize: theme.fonts.fontSizes.size00,
            letterSpacing: 1,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            borderBottom: `1px solid ${theme.colors.gray03}`,
            paddingRight: theme.margins.margin01
        },
        td: {
            paddingTop: theme.margins.margin00,
            paddingBottom: theme.margins.margin00,
            paddingRight: theme.margins.margin01
        }
    },
    streamRow: {
        ':nth-of-type(odd)': {
            backgroundColor: theme.colors.gray10
        }
    },
    streamName: {
        display: 'flex',
        alignItems: 'center',
        p: {
            textTransform: 'capitalize',
            whiteSpace: 'nowrap'
        }
    },
    streamRulesText: {
        lineHeight: '1.3em',
        textTransform: 'capitalize',
        color: theme.colors.gray06,
        marginTop: 4
    },
    rangeSelection: {
        display: 'flex',
        justifyContent: 'space-between',
        letterSpacing: 1
    },
    ranges: {
        display: 'flex'
    },
    range: {
        marginLeft: theme.margins.margin00,
        cursor: 'pointer'
    },
    spacer: {
        flex: 1
    },
    streamTotal: {
        marginLeft: theme.margins.margin01
    },
    loadingHeader: {
        marginLeft: theme.margins.margin01
    },
    loading: {
        padding: `${theme.margins.margin02} 0 ${theme.margins.margin03} 0`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        padding: '12px 20px 0',
        display: 'flex',
        alignItems: 'center'
    },
    filteredBy: {
        marginTop: 8,
        marginLeft: theme.margins.margin00,
        color: theme.colors.gray06
    },
    closeModal: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        height: 32,
        marginLeft: 'auto',
        svg: {
            margin: '2px 0 0 auto'
        },
        ':hover': {
            transform: 'scale(1.1)'
        }
    },
    statsLoading: {
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 350,
        p: {
            marginBottom: theme.margins.margin01
        }
    }
});
