import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Span } from 'components/Span';
import { Spinner } from 'components/Spinner';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { routes } from 'routes';
import { formatFinancial } from 'utils';
import { styleSheet } from './stylesheet';

class StreamAlertsTooltip extends PureComponent {
    static propTypes = {
        averageDailyVolume: PropTypes.number,
        averageDailyVolumeLoading: PropTypes.bool,
        enableRealTime: PropTypes.bool.isRequired,
        hasAlerts: PropTypes.bool.isRequired,
        hasWarning: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        realTimeDelivery: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        updateAverageDailyVolume: PropTypes.func.isRequired
    };

    static defaultProps = {
        averageDailyVolume: null,
        averageDailyVolumeLoading: false,
        name: 'toggle-streamAlertsTooltip',
        passedStyles: {}
    };

    renderTooltip() {
        const {
            averageDailyVolume,
            averageDailyVolumeLoading,
            enableRealTime,
            hasWarning,
            loading,
            name,
            onChange,
            realTimeDelivery,
            styles,
            theme
        } = this.props;
        const alerts = [];

        if (loading) {
            return (
                <Div styles={styles.loaderContainer}>
                    <LoaderLogo />
                </Div>
            );
        }

        if (enableRealTime) {
            alerts.push(
                <Div data-tname={name} key="streamAlertsTooltip-realTime" styles={styles.realTime}>
                    <Text styles={styles.optionsTitle} size={3} weight="medium">
                        Real-Time Alerts
                    </Text>
                    <Text size={1} styles={styles.optionsDescription}>
                        Stay up to date on new matches for this search with real-time alerts.
                    </Text>
                    <MultiSelect
                        styles={styles.options}
                        multi
                        name="realTimeDelivery"
                        onChange={onChange}
                        selected={realTimeDelivery}
                    >
                        <Option value="realTimeEmail" type="checkbox">
                            <Icon type="email03" color={theme.colors.gray04} />
                            <Text size={1} weight="medium">
                                By Email
                            </Text>
                        </Option>
                        <Option value="realTimeInApp" type="checkbox">
                            <Icon type="notification" color={theme.colors.gray04} />
                            <Text size={1} weight="medium">
                                Notifications in Aiera
                            </Text>
                        </Option>
                    </MultiSelect>
                </Div>
            );
        }

        return (
            <Div styles={styles.alertsContainer}>
                <Text
                    size={1}
                    styles={averageDailyVolume > 100 ? styles.averageDailyVolumeWarning : styles.averageDailyVolume}
                >
                    Average Daily Volume:&nbsp;
                    {averageDailyVolumeLoading ? (
                        <Spinner styles={styles.spinner} color="darkGray" />
                    ) : (
                        <Span>{averageDailyVolume ? formatFinancial('raw', averageDailyVolume, 0) : 'Very Low'}</Span>
                    )}
                </Text>
                {alerts}
                {hasWarning && (
                    <Div styles={styles.warning}>
                        <Icon type="warning" color={theme.colors.black01} />
                        <Text size={1}>Alerts will be grouped when volume is too high.</Text>
                    </Div>
                )}
                <Link to={routes.alerts}>
                    <Text size={1} styles={styles.manageAlerts}>
                        Manage Alert Settings
                    </Text>
                </Link>
            </Div>
        );
    }

    render() {
        const { hasAlerts, passedStyles, styles, theme, updateAverageDailyVolume } = this.props;
        return (
            <Tooltip
                content={this.renderTooltip()}
                isEnabled
                persistOnMouseExit
                styles={styles.tooltip}
                useElementOffsetLeft
                useElementOffsetTop
                useOutsideClickHandler
                xOffset={-166}
                yOffset={35}
            >
                {({ showTooltip }) => (
                    <Div
                        onClick={e => {
                            updateAverageDailyVolume();
                            showTooltip(e);
                        }}
                        styles={{ ...styles.container, ...passedStyles }}
                    >
                        <Icon
                            type={hasAlerts ? 'bellRinging' : 'bellDisabled'}
                            color={hasAlerts ? theme.colors.blue08 : theme.colors.gray02}
                        />
                    </Div>
                )}
            </Tooltip>
        );
    }
}

export const StreamAlertsTooltipUI = compose(withStyleSheet(styleSheet))(StreamAlertsTooltip);
