import gql from 'graphql-tag';

export const getQuestionsQuery = gql`
    query withGetQuestions($eventId: ID, $companyId: ID, $startDate: DateTime, $endDate: DateTime) {
        getQuestions(eventId: $eventId, companyId: $companyId, startDate: $startDate, endDate: $endDate) {
            id
            scheduledAudioCallId
            question
            answer
            autoQuestion
            feedback
            isTemplate
            citations {
                id
                transcript
                startMs
                speaker {
                    id
                    name
                    title
                    affiliation
                }
            }
        }
    }
`;
