import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EventUploadProgressUI } from './ui';

export class EventUploadProgress extends PureComponent {
    static displayName = 'EventUploadProgressContainer';

    static propTypes = {
        percentComplete: PropTypes.number,
        status: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        percentComplete: undefined,
        status: undefined,
        styles: undefined
    };

    render() {
        const { percentComplete, status, styles } = this.props;
        return <EventUploadProgressUI percentComplete={percentComplete} status={status} styles={styles} />;
    }
}

export const EventUploadProgressContainer = compose()(EventUploadProgress);
