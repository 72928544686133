export const styleSheet = theme => ({
    beta: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.red08,
        borderRadius: 10,
        height: 19,
        width: 46,
        marginTop: 5,
        marginRight: theme.margins.margin00,
        cursor: 'pointer',
        paddingLeft: 1
    },
    betaText: {
        color: theme.colors.white01,
        letterSpacing: '1px'
    },
    betaTooltip: {
        borderRadius: 6,
        backgroundColor: theme.colors.white01,
        padding: theme.margins.margin00
    },
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.gray12,
        zIndex: 4,
        padding: '8px 6px 8px 0'
    },
    spacer: {
        flex: 1
    },
    headerButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: 6.5,
        marginRight: 6,
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        backgroundColor: theme.colors.gray11,
        flexShrink: 0,
        svg: {
            height: 14,
            width: 14
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.white01
            }
        },
        ':active': {
            backgroundColor: theme.colors.gray13
        }
    },
    newEventButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        borderRadius: 6.5,
        marginRight: 6,
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        flexShrink: 0,
        boxShadow: 'none',
        color: theme.colors.white01,
        ...theme.content.blueButton,
        svg: {
            marginRight: 6,
            height: 14,
            width: 14
        }
    },
    newEventButtonLocked: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        borderRadius: 6.5,
        marginRight: 6,
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        flexShrink: 0,
        boxShadow: 'none',
        backgroundColor: theme.colors.yellow05,
        p: {
            color: theme.colors.black01,
            marginLeft: 4
        },
        ':hover': {
            backgroundColor: theme.colors.yellow04
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    }
});
