export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01,
        td: {
            ':first-of-type': {
                paddingLeft: theme.margins.margin00
            },
            ':last-of-type': {
                textAlign: 'right',
                paddingLeft: theme.margins.margin01,
                paddingRight: theme.margins.margin00
            }
        },
        th: {
            ':first-of-type': {
                paddingLeft: theme.margins.margin00
            },
            ':last-of-type': {
                textAlign: 'right',
                paddingLeft: theme.margins.margin01,
                paddingRight: '8px'
            }
        }
    }
});
