export const styleSheet = theme => {
    const resultTd = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        lineHeight: '1.3em',
        paddingRight: theme.margins.margin01,
        ':first-of-type': {
            width: 44,
            paddingLeft: theme.margins.margin00,
            paddingRight: theme.margins.margin00
        },
        ':last-of-type': {
            paddingRight: 14,
            textAlign: 'right'
        }
    };
    return {
        results: {
            width: '100%'
        },
        result: {
            td: {
                ...resultTd
            },
            ':nth-of-type(even)': {
                td: {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        resultHighlighted: {
            td: {
                ...resultTd,
                backgroundColor: theme.colors.blue08,
                color: theme.colors.white01,
                p: {
                    color: theme.colors.white01
                },
                '.companyInitials': {
                    color: theme.colors.gray06
                }
            }
        },
        companyPreview: {
            backgroundColor: theme.colors.white01,
            marginLeft: 1
        },
        companyHeader: {
            marginTop: 'unset'
        },
        resultHeaders: {
            th: {
                position: 'sticky',
                top: 0,
                backgroundColor: theme.colors.gray05,
                height: 30,
                verticalAlign: 'middle',
                textTransform: 'uppercase',
                fontSize: theme.fonts.fontSizes.size01,
                textAlign: 'left',
                letterSpacing: 1,
                color: theme.colors.gray06,
                paddingRight: theme.margins.margin01,
                ':first-of-type': {
                    paddingLeft: theme.margins.margin00
                },
                ':last-of-type': {
                    paddingRight: 14,
                    textAlign: 'right'
                }
            }
        },
        previewBlock: {
            padding: theme.margins.margin00,
            borderBottom: `1px solid ${theme.colors.gray03}`,
            ':last-of-type': {
                borderBottom: 'unset'
            }
        },
        previewLabel: {
            color: theme.colors.gray06,
            letterSpacing: 1,
            marginBottom: 4
        },
        companyInitials: {
            backgroundColor: theme.colors.gray03,
            color: theme.colors.gray06,
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        companyIcon: {
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            img: {
                maxHeight: 24
            }
        },
        priceContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
            svg: {
                margin: '0 0 1px 5px'
            }
        },
        priceChange: {
            marginLeft: '5px'
        },
        textGreen: {
            color: theme.colors.green03
        },
        textRed: {
            color: theme.colors.red01
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            padding: '12px 0',
            div: {
                margin: 'auto'
            }
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            margin: 10,
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        }
    };
};
