export const styleSheet = theme => {
    const button = {
        backgroundColor: theme.colors.white01,
        borderRadius: 3,
        height: 40,
        marginLeft: 15,
        svg: {
            marginRight: 7,
            marginBottom: 1
        }
    };
    return {
        container: {
            maxWidth: 700,
            width: 'calc(100% - 80px)'
        },
        button: {
            ...button
        },
        buttonCancel: {
            ...theme.content.cancelButton
        },
        buttonDelete: {
            ...button,
            marginLeft: 0,
            marginRight: 'auto'
        },
        buttonsContainer: {
            ...theme.content.modalButtonsContainer,
            justifyContent: 'flex-end'
        },
        buttonSubmit: {
            ...button,
            ...theme.content.blueButton,
            borderRadius: 3,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        buttonSubmitHintText: {
            fontSize: theme.fonts.fontSizes.size02,
            lineHeight: '1.3em'
        },
        buttonSubmitRetry: {
            ...button,
            ...theme.content.orangeButton,
            borderRadius: 3,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        loading: {
            height: 100,
            margin: 'auto'
        }
    };
};
