export const styleSheet = theme => ({
    container: {
        position: 'absolute',
        top: 86,
        bottom: 0,
        left: 0,
        right: 0,
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    upsellContent: {
        maxWidth: 510,
        cursor: 'pointer',
        padding: '14px 16px',
        backgroundColor: theme.colors.yellow09,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.06)',
        margin: `0 ${theme.margins.margin01}`,
        p: {
            lineHeight: '1.3em'
        },
        ':hover': {
            backgroundColor: theme.colors.yellow07
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    },
    upsellTitle: {
        marginBottom: theme.margins.margin00,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        svg: {
            marginBottom: 2,
            marginRight: 8
        }
    },
    subtitle: {
        opacity: 0.3
    },
    eventTitle: {
        marginTop: theme.margins.margin00
    }
});
