import React from 'react';
import PropTypes from 'prop-types';
import { AieraIntegration } from 'utils/integration';

export const integration = new AieraIntegration({ debug: false });
export function configureIntegration() {
    return integration;
}

const IntegrationContext = React.createContext();
// eslint-disable-next-line no-shadow
export const IntegrationProvider = ({ children, integration }) => (
    <IntegrationContext.Provider value={integration}>{React.Children.only(children)}</IntegrationContext.Provider>
);
IntegrationProvider.propTypes = { integration: PropTypes.objectOf(PropTypes.any).isRequired };

export const withIntegration = () => BaseComponent => props => (
    <IntegrationContext.Consumer>
        {// eslint-disable-next-line no-shadow
        integration => <BaseComponent {...props} integration={integration} />}
    </IntegrationContext.Consumer>
);
