import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';
import { compose, lifecycle, withProps } from 'recompose';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';
import { eventStreamDifferentialsQuery } from '../localStreamsData';

function normalizeDifferential(stream) {
    const title = get(stream, 'stream.name');
    const streamEvents = get(stream, 'events', []);
    const highlights = get(stream, 'stream.matches.results', [])
        .flatMap(h => [h, ...get(h, 'collapsed', [])])
        .reverse();
    const numMatches = highlights.length;
    const columnData = new Array(5)
        .fill(0)
        .map((_, i) => ({
            numMatches: get(streamEvents, `[${i}].numMatches`, 0),
            scheduledAudioCallId: get(streamEvents, `[${i}].event.scheduledAudioCallId`)
        }))
        .reverse();
    return {
        streamId: get(stream, 'stream.id'),
        title,
        columnData,
        numMatches,
        highlights
    };
}

function normalizeDifferentials(differentials) {
    const events = get(differentials, '[0].events', []);
    const differentialEvents = new Array(5)
        .fill(0)
        .map((_, i) => get(events, `[${i}].event`, {}))
        .reverse();
    const dashboardMap = {};
    const aieraDifferentials = [];
    differentials
        .filter(s => get(s, 'stream.dashboards', []).length > 0 && get(s, 'stream.matches.results.length', 0) > 0)
        .forEach(s => {
            const stream = get(s, 'stream');
            const dashboardId = get(stream, 'dashboards[0].id');
            const dashboard = dashboardMap[dashboardId];
            const dashboardName = get(stream, 'dashboards[0].name');
            const differential = normalizeDifferential(s);
            if (dashboardName.toLowerCase().includes('quick links')) {
                aieraDifferentials.push(differential);
            } else if (dashboard) {
                dashboard.streams.push(differential);
            } else {
                dashboardMap[dashboardId] = {
                    id: dashboardId,
                    name: dashboardName,
                    streams: [differential]
                };
            }
        });
    const dashboardDifferentials = Object.values(dashboardMap)
        .map(dash => ({
            ...dash,
            streams: sortBy(dash.streams, s => get(s, 'numMatches', 0)).reverse()
        }))
        .reverse();

    return {
        differentialEvents,
        aieraDifferentials,
        dashboardDifferentials,
        localDifferentials: differentials
            .filter(s => get(s, 'stream.dashboards', []).length === 0)
            .map(normalizeDifferential)
    };
}

export const withData = () =>
    compose(
        graphql(eventStreamDifferentialsQuery, {
            props: ({ data }) => ({
                differentials: get(data, 'events[0].differentials.streams', []),
                loading: get(data, 'loading', false),
                refreshDifferentials: data.refetch
            }),
            skip: ({ collapsed }) => collapsed,
            options: ({ eventId, shareId }) => ({
                fetchPolicy: 'cache-first',
                variables: {
                    eventId,
                    // Because the rule value is a different type, we have to pass as sep variable
                    eventIdScalar: eventId || shareId,
                    shareId
                }
            })
        }),
        graphql(
            gql`
                query withUserToken {
                    currentUser {
                        id
                        pusherToken
                    }
                }
            `,
            {
                props: ({ data }) => ({ pusherToken: get(data, 'currentUser.pusherToken') }),
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                // We may not have the pusher token on mount, so save a function
                // onto the component that we can either call here or in componentDidUpdate
                // to subscribe as soon as we have the token.
                this.trySubscribe = () => {
                    if (!this.subscriptions) {
                        const { pusherToken, eventId, realtime } = this.props;
                        if (pusherToken && eventId) {
                            this.subscriptions = [
                                realtime.subscribe(`user_${pusherToken}`, 'stream_matches', () => {
                                    const { refreshDifferentials } = this.props;
                                    if (refreshDifferentials) {
                                        refreshDifferentials();
                                    }
                                }),
                                realtime.subscribe(`scheduled_audio_call_${eventId}_changes`, 'stream_matches', () => {
                                    const { refreshDifferentials } = this.props;
                                    if (refreshDifferentials) {
                                        refreshDifferentials();
                                    }
                                })
                            ];
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        }),
        withMemo({ normalizeDifferentials }),
        withProps(({ normalizeDifferentials: normalize, differentials }) => ({
            ...normalize(differentials || [])
        }))
    );
