import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { get, capitalize } from 'utils';
import { withMemo } from 'hoc/utils';
import { TYPES, OPERATORS } from 'consts/filters';
import { formatMatchesByType, mapFiltersToRules } from 'utils/streams';
import {
    companyMatchFragment,
    contentMatchFragment,
    eventMatchFragment,
    streamFragment,
    transcriptMatchFragment
} from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';

function generateQuery({ singularType, streamType, selectedType, contentType }) {
    const capType = capitalize(selectedType);
    return graphql(
        gql`
            query withGlobal${capType}Search(
                $streamTypes: [StreamType]!
                $rules: [StreamRuleInput]
                $size: Int = 10
                $offset: Int = 0
            ) {
                streams(filter: { previewStreamTypes: $streamTypes }) {
                    ...stream
                    matches(size: $size, fromIndex: $offset, filter: { rules: $rules }) {
                        total
                        results {
                            ...companyMatch
                            ...eventMatch
                            ...contentMatch
                            ...transcriptMatch
                        }
                    }
                }
            }
            ${companyMatchFragment}
            ${contentMatchFragment}
            ${eventMatchFragment}
            ${streamFragment}
            ${transcriptMatchFragment}
        `,
        {
            props: ({ data, ownProps: { formatMatchesByType: formatMatches } }) => {
                const matches = formatMatches(get(data, 'streams[0].matches.results', []));
                return {
                    [`loading${capType}`]: !matches.length && get(data, 'loading'),
                    [`${singularType}Matches`]: matches,
                    [`fetchMore${capType}`]: data.fetchMore
                };
            },
            options: ({ searchTerm }) => {
                const filters = [
                    {
                        type: TYPES.searchTerm,
                        operator: OPERATORS.is,
                        value: [searchTerm]
                    }
                ];

                if (contentType) {
                    filters.push({
                        type: TYPES.type,
                        operator: OPERATORS.is,
                        value: contentType
                    });
                }

                return {
                    fetchPolicy: 'no-cache',
                    variables: {
                        streamTypes: [streamType],
                        rules: mapFiltersToRules(filters)
                    },
                    context: {
                        debounceKey: `withGlobal${capType}SearchTerm`,
                        debounceTimeout: searchTerm && searchTerm.length > 0 ? 300 : 0
                    }
                };
            },
            skip: ({ selectedResultTypes, searchTerm }) =>
                !searchTerm || !selectedResultTypes || !selectedResultTypes.filter(t => t === selectedType).length
        }
    );
}

function fetchMoreMatches(functionName, fetchMore) {
    return {
        [functionName]: offset => {
            let hasMore = true;
            return fetchMore({
                variables: { offset },
                updateQuery: (prev, { fetchMoreResult }) => {
                    const prevStream = prev.streams[0];
                    const newStream = fetchMoreResult.streams[0];
                    const newMatches = newStream.matches;
                    hasMore = newMatches.results.length >= 10;
                    return {
                        ...prev,
                        streams: [
                            {
                                ...prevStream,
                                matches: {
                                    ...prevStream.matches,
                                    results: [...prevStream.matches.results, ...newMatches.results]
                                }
                            }
                        ]
                    };
                }
            }).then(resp => ({
                hasMore,
                newResults: formatMatchesByType(get(resp, 'data.streams[0].matches.results', []))
            }));
        }
    };
}

export const withData = () =>
    compose(
        withMemo({
            formatMatchesByType
        }),
        generateQuery({ streamType: 'companies', selectedType: 'companies', singularType: 'company' }),
        generateQuery({ streamType: 'events', selectedType: 'events', singularType: 'event' }),
        generateQuery({ streamType: 'content', selectedType: 'news', contentType: 'news', singularType: 'news' }),
        generateQuery({
            streamType: 'content',
            selectedType: 'transcripts',
            contentType: 'transcript',
            singularType: 'transcript'
        }),
        // generateQuery({
        //     streamType: 'content',
        //     selectedType: 'streetaccount',
        //     contentType: 'streetaccount',
        //     singularType: 'streetAccount'
        // }),
        withPropsOnChange(
            ['fetchMoreCompanies', 'fetchMoreEvents', 'fetchMoreNews', 'fetchMoreTranscripts'],
            ({ fetchMoreCompanies, fetchMoreEvents, fetchMoreNews, fetchMoreTranscripts }) => ({
                ...fetchMoreMatches('loadMoreCompanies', fetchMoreCompanies),
                ...fetchMoreMatches('loadMoreEvents', fetchMoreEvents),
                ...fetchMoreMatches('loadMoreNews', fetchMoreNews),
                ...fetchMoreMatches('loadMoreTranscripts', fetchMoreTranscripts)
            })
        )
    );
