import { compose, withPropsOnChange } from 'recompose';
import gql from 'graphql-tag';
import { withMemo } from 'hoc/utils';
import { bookmarkTargetFragment, bookmarkFragment } from 'graphql/fragments/bookmarks';
import { streamFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges
} from 'hoc/streams';
import { formatMatchesByType, normalizeBookmarks } from 'utils/streams';

export const withData = () =>
    compose(
        withMemo({ formatMatches: formatMatchesByType, normalize: normalizeBookmarks }),
        graphql(
            gql`
                query withBookmarkList($filter: StreamMatchFilter, $offset: Int = 0, $size: Int = 20) {
                    streams(filter: { previewStreamTypes: [bookmarks] }) {
                        ...stream
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            results {
                                id
                                bookmark {
                                    ...bookmark
                                    ...bookmarkTarget
                                }
                            }
                        }
                    }
                }
                ${streamFragment}
                ${bookmarkFragment}
                ${bookmarkTargetFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { fetchMore, loading, matches, refetch } = getDefaultStreamProps(data, formatMatches);
                    return {
                        bookmarks: matches,
                        fetchMore,
                        loading,
                        refetch
                    };
                },
                options: () => {
                    const defaultStreamOptions = getDefaultStreamOptions({ streamType: 'bookmarks' });
                    return {
                        ...defaultStreamOptions,
                        variables: {
                            ...defaultStreamOptions.variables
                        }
                    };
                },
                skip: ({ isTooltipVisible }) => !isTooltipVisible
            }
        ),
        graphql(
            gql`
                mutation ArchiveBookmarks($bookmarkIds: [ID]!) {
                    archiveBookmarks(bookmarkIds: $bookmarkIds) {
                        archived {
                            ...bookmark
                        }
                    }
                }
                ${bookmarkFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    archiveBookmarks: bookmarkIds =>
                        mutate({
                            variables: { bookmarkIds }
                        }).catch(error => {
                            setStatusBanner(`Error archiving bookmarks: ${error}`, 'error', 'circleX');
                        })
                })
            }
        ),
        graphql(
            gql`
                mutation DeleteBookmarks($bookmarkIds: [ID]!) {
                    deleteBookmarks(bookmarkIds: $bookmarkIds) {
                        deleted {
                            ...bookmark
                        }
                    }
                }
                ${bookmarkFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    deleteBookmarks: bookmarkIds =>
                        mutate({
                            variables: { bookmarkIds }
                        }).catch(error => {
                            setStatusBanner(`Error removing bookmarks: ${error}`, 'error', 'circleX');
                        })
                })
            }
        ),
        withPropsOnChange(['bookmarks', 'normalize'], ({ bookmarks, normalize, pathname }) => ({
            bookmarks: normalize(bookmarks || [], pathname)
        })),
        withUpdateStreamOnChanges(),
        withStreamPaging()
    );
