import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Heading } from 'components/Heading';
import { ActionButton } from 'components/ActionButton';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { WatchlistForm } from 'components/WatchlistForm';
import { VIDEO_TYPES } from 'consts';
import { VideoPlayer } from 'components/VideoPlayer';
import { styleSheet } from './stylesheet';

class WelcomeMessage extends PureComponent {
    static propTypes = {
        changeSetupPage: PropTypes.func.isRequired,
        finishOnboarding: PropTypes.func.isRequired,
        firstName: PropTypes.string,
        openGallery: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        primaryWatchlistId: PropTypes.string,
        savingDefault: PropTypes.bool.isRequired,
        setupPage: PropTypes.number.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        step: PropTypes.number.isRequired,
        stepForward: PropTypes.func.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        useDefaultWatchlist: PropTypes.func.isRequired
    };

    static defaultProps = {
        firstName: undefined,
        passedStyles: {},
        primaryWatchlistId: null
    };

    constructor(props) {
        super(props);

        this.homeTip = this.homeTip.bind(this);
        this.streamsTip = this.streamsTip.bind(this);
        this.contextTip = this.contextTip.bind(this);
        this.dashFilterTip = this.dashFilterTip.bind(this);
        this.createTip = this.createTip.bind(this);
        this.galleryTip = this.galleryTip.bind(this);
        this.aieracastTip = this.aieracastTip.bind(this);
    }

    galleryTip() {
        const { styles, openGallery } = this.props;

        return (
            <Div styles={styles.notes}>
                <Text>
                    The Gallery has pre-created
                    <br />
                    Monitors, with custom saved searches handbuilt by our experts.
                </Text>
                <ActionButton size={2} onClick={openGallery} styles={styles.continueBtn}>
                    <Text>End tour and view Gallery</Text>
                </ActionButton>
            </Div>
        );
    }

    aieracastTip() {
        const { styles, stepForward } = this.props;

        return (
            <Div styles={styles.notes}>
                <Text>
                    Aieracast lets you view unlimited
                    <br />
                    live events, transcribing in parallel.
                </Text>
                <br />
                <Text>
                    Listen live in 1 click,
                    <br />
                    See real time price highlights and changes.
                </Text>
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>6 of 7</Text>
                </Div>
            </Div>
        );
    }

    createTip() {
        const { styles, stepForward } = this.props;

        return (
            <Div styles={styles.notes}>
                <Text>
                    Create a personalized monitor,
                    <br />
                    with multiple saved searches,
                    <br />
                    from any page.
                </Text>
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>2 of 7</Text>
                </Div>
            </Div>
        );
    }

    dashFilterTip() {
        const { styles, stepForward } = this.props;

        return (
            <Div styles={styles.notes}>
                <br />
                <Text>This is your universal search bar.</Text>
                <br />
                <Text>
                    You can use it to jump to a specific company, <br />
                    or find a keyword across transcripts,
                    <br /> news, and filings.
                </Text>
                <br />
                <Text>
                    You can also search within the results <br />
                    of all saved searches in a monitor you&apos;re viewing,
                    <br /> or across all of Aiera.
                </Text>
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>4 of 7</Text>
                </Div>
            </Div>
        );
    }

    contextTip() {
        const { styles, stepForward } = this.props;

        return (
            <Div styles={{ ...styles.notes, marginLeft: 0 }}>
                <br />
                <Div styles={styles.iconContent}>
                    <Div styles={styles.iconBlock}>
                        <Icon type="headset" color="white" />
                    </Div>
                    <Text size={3}>
                        Access live customer support & <br />
                        our knowledge base.
                    </Text>
                </Div>
                <Div styles={styles.iconContent}>
                    <Div styles={styles.iconBlock}>
                        <Icon type="bell03" color="white" />
                    </Div>
                    <Text size={3}>Your saved monitor & event alerts.</Text>
                </Div>
                <Div styles={styles.iconContent}>
                    <Div styles={styles.iconBlock}>
                        <Icon type="highlighter" color="white" />
                    </Div>
                    <Text size={3}>Your news & transcript highlights.</Text>
                </Div>
                <Div styles={styles.iconContent}>
                    <Div styles={styles.iconBlock}>
                        <Icon type="account02" color="white" />
                    </Div>
                    <Text size={3}>Your profile and account settings.</Text>
                </Div>
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>5 of 7</Text>
                </Div>
            </Div>
        );
    }

    streamsTip() {
        const { styles, stepForward } = this.props;

        return (
            <Div styles={{ ...styles.notes, maxWidth: 600 }}>
                <br />
                <Text>
                    Your company list can be filtered to
                    <br /> multiple watchlists, sectors, indices, <br />
                    and individual equities.
                </Text>
                <br />
                <Text>
                    Sort by absolute movement, <br />
                    to see which companies are making headlines.
                </Text>
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>3 of 7</Text>
                </Div>
            </Div>
        );
    }

    homeTip() {
        const { styles, stepForward, firstName } = this.props;

        return (
            <Div styles={styles.notes}>
                <Heading size={1}>
                    {firstName ? `Hi ${firstName},` : ''}
                    <br />
                    Welcome to Aiera
                </Heading>
                <br />
                <Text>
                    Start every day with your watchlist, sorted by the day&apos;s price movement. Jump directly to the
                    biggest events driving the headlines.
                </Text>
                <br />
                <Text>Create monitors to track your thesis across events and news, for the companies you follow.</Text>
                <br />
                <Text>
                    Monitors are made up of parallel saved searches, organized in columns. Turn on alerts for daily
                    mentions of topics and themes.
                </Text>
                <br />
                <Div styles={styles.btnContainer}>
                    <ActionButton size={2} onClick={stepForward} styles={styles.continueBtn}>
                        <Text>Continue</Text>
                    </ActionButton>
                    <Text size={1}>1 of 7</Text>
                </Div>
            </Div>
        );
    }

    welcomeToAiera() {
        const {
            styles,
            changeSetupPage,
            setupPage,
            savingDefault,
            primaryWatchlistId,
            finishOnboarding,
            useDefaultWatchlist
        } = this.props;
        return (
            <Modal isOpen useShell onModalClose={() => {}} styles={styles.welcomeModal}>
                {setupPage === 0 ? (
                    <Fragment>
                        <Text size={6} weight="medium" styles={styles.welcomeTitle}>
                            Welcome to Aiera
                        </Text>
                        <Text size={3} styles={{ lineHeight: '1.2em' }}>
                            Access Wall Street&apos;s events instantly and in real-time, with tools to help you cut
                            through the noise and identify themes to drive stronger research.
                        </Text>
                        <Div styles={styles.welcomeVideo}>
                            <VideoPlayer autoplay videoType={VIDEO_TYPES.welcomeToAiera} />
                        </Div>
                        <Div>
                            <Text size={5} weight="medium">
                                Create Your Primary Watchlist
                            </Text>
                            <Text size={3} styles={styles.welcomeDescription}>
                                You&apos;ll have the best experience when you have a watchlist. <br /> We use your
                                watchlist in the default experience
                                <br /> to show you content you care about the most.
                            </Text>
                        </Div>
                        <Div styles={styles.welcomeButtons}>
                            <ActionButton
                                disabled={!primaryWatchlistId}
                                size={2}
                                onClick={() => changeSetupPage(1)}
                                styles={styles.setupWatchlistBtn}
                            >
                                Setup Your Watchlist Now
                            </ActionButton>
                            {savingDefault ? (
                                <Div styles={styles.savingDefault}>Saving...</Div>
                            ) : (
                                <ActionButton size={2} onClick={useDefaultWatchlist} styles={styles.defaultBtn}>
                                    <Text size={3}>Skip for now</Text>
                                    <Text size={0} styles={styles.defaultBtnNote}>
                                        Defaults to S&P500
                                    </Text>
                                </ActionButton>
                            )}
                        </Div>
                    </Fragment>
                ) : (
                    <WatchlistForm isOpen isOnboarding onClose={finishOnboarding} watchlistId={primaryWatchlistId} />
                )}
            </Modal>
        );
    }

    render() {
        const { styles, passedStyles, step, theme } = this.props;
        const stepMap = {
            1: {
                render: this.homeTip,
                style: styles.homeTipStyle,
                spotLight: styles.homeTipSpotLight
            },
            2: {
                render: this.createTip,
                style: styles.createTipStyle,
                spotLight: styles.createTipSpotLight
            },
            3: {
                render: this.streamsTip,
                style: styles.streamsTipStyle,
                spotLight: styles.streamsTipSpotLight
            },
            4: {
                render: this.dashFilterTip,
                style: styles.dashFilterStyle,
                spotLight: styles.dashFilterSpotLight
            },
            5: {
                render: this.contextTip,
                style: styles.contextStyle,
                spotLight: styles.contextSpotLight
            },
            6: {
                render: this.aieracastTip,
                style: styles.aieracastStyle,
                spotLight: styles.aieracastSpotLight
            },
            7: {
                render: this.galleryTip,
                style: styles.galleryStyle,
                spotLight: styles.gallerySpotLight
            }
        };

        if (step > 7) {
            return null;
        }

        if (step === 0) {
            return this.welcomeToAiera();
        }

        const stepContent = (
            <Div styles={stepMap[step].style}>
                <Div styles={stepMap[step].spotLight} />
                {stepMap[step].render()}
            </Div>
        );

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m =>
                    !m && (
                        <Div
                            styles={{
                                ...styles.container,
                                ...passedStyles
                            }}
                        >
                            {stepContent}
                        </Div>
                    )
                }
            </MediaQuery>
        );
    }
}

export const WelcomeMessageUI = compose(withStyleSheet(styleSheet))(WelcomeMessage);
