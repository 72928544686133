import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withGetUser } from 'graphql/user';
import { SCHEDULE_TYPES, TROUBLESHOOTING_TYPES } from '../consts';
import { TroubleshootingUI } from './ui';

function getStartAndEndTimesForDate(date) {
    return {
        startTime: new XDate(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0, 0),
        endTime: new XDate(date.getFullYear(), date.getMonth(), date.getDate(), 17, 30, 0, 0)
    };
}

function isInterventionEnabled(date, meridiem, time, type) {
    let enabled = false;
    if (type === SCHEDULE_TYPES.now.value) {
        const now = new XDate(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const { endTime, startTime } = getStartAndEndTimesForDate(now);
        enabled =
            !['Sat', 'Sun'].includes(now.toString('ddd')) &&
            now.diffMinutes(startTime) <= 0 &&
            now.diffMinutes(endTime) > 0;
    } else if (date && time && meridiem) {
        // Convert time to 24-hour clock
        const minutes = time.slice(-2);
        let hours = parseInt(time.slice(0, time.length > 3 ? 2 : 1), 0);
        if (hours < 12 && meridiem === 'PM') {
            hours += 12;
        }
        if (hours === 12 && meridiem === 'AM') {
            hours = 0;
        }
        // Get a date object using the date, time, and meridiem state values
        const scheduledNativeDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0);
        const scheduledDate = new XDate(scheduledNativeDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const { endTime, startTime } = getStartAndEndTimesForDate(scheduledDate);
        enabled =
            !['Sat', 'Sun'].includes(scheduledDate.toString('ddd')) &&
            scheduledDate.diffMinutes(startTime) <= 0 &&
            scheduledDate.diffMinutes(endTime) > 0;
    }
    return enabled;
}

export class Troubleshooting extends PureComponent {
    static displayName = 'TroubleshootingContainer';

    static propTypes = {
        confirmPermission: PropTypes.bool,
        disableConfirmPermission: PropTypes.bool,
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        isWebcast: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onConnectDialNumber: PropTypes.string,
        onFailure: PropTypes.string,
        onFailureInstructions: PropTypes.string,
        onFailurePhoneNumber: PropTypes.string,
        participationType: PropTypes.string,
        scheduleDate: PropTypes.objectOf(PropTypes.any),
        scheduleMeridiem: PropTypes.string.isRequired,
        scheduleTime: PropTypes.string,
        scheduleType: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        useOnConnectDialNumber: PropTypes.bool,
        user: PropTypes.objectOf(PropTypes.any),
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        confirmPermission: false,
        disableConfirmPermission: false,
        onConnectDialNumber: undefined,
        onFailure: undefined,
        onFailureInstructions: undefined,
        onFailurePhoneNumber: undefined,
        participationType: undefined,
        scheduleDate: undefined,
        scheduleTime: undefined,
        scheduleType: undefined,
        styles: undefined,
        useOnConnectDialNumber: false,
        user: undefined
    };

    constructor(props) {
        super(props);
        this.isInterventionEnabled = memoize(isInterventionEnabled);
    }

    componentDidUpdate({
        scheduleDate: prevScheduleDate,
        scheduleMeridiem: prevScheduleMeridiem,
        scheduleTime: prevScheduleTime,
        scheduleType: prevScheduleType
    }) {
        const { onChange, onFailure, scheduleDate, scheduleMeridiem, scheduleTime, scheduleType } = this.props;
        // If any of the schedule inputs change,
        // check if intervention is enabled,
        // and if intervention is currently selected but not enabled,
        // then call onChange to set onFailure to "do nothing"
        if (
            onFailure === TROUBLESHOOTING_TYPES.aieraIntervention.value &&
            (prevScheduleDate !== scheduleDate ||
                prevScheduleMeridiem !== scheduleMeridiem ||
                prevScheduleTime !== scheduleTime ||
                prevScheduleType !== scheduleType) &&
            !this.isInterventionEnabled(scheduleDate, scheduleMeridiem, scheduleTime, scheduleType)
        ) {
            onChange({ name: 'onFailure', value: TROUBLESHOOTING_TYPES.none.value });
        }
    }

    render() {
        const {
            confirmPermission,
            disableConfirmPermission,
            errors,
            isWebcast,
            onChange,
            onConnectDialNumber,
            onFailure,
            onFailureInstructions,
            onFailurePhoneNumber,
            participationType,
            scheduleDate,
            scheduleMeridiem,
            scheduleTime,
            scheduleType,
            styles,
            useOnConnectDialNumber,
            user,
            visible
        } = this.props;
        return (
            <TroubleshootingUI
                confirmPermission={confirmPermission}
                disableConfirmPermission={disableConfirmPermission}
                errors={errors}
                isInterventionEnabled={this.isInterventionEnabled(
                    scheduleDate,
                    scheduleMeridiem,
                    scheduleTime,
                    scheduleType
                )}
                isWebcast={isWebcast}
                onChange={onChange}
                onFailure={onFailure}
                onFailureInstructions={onFailureInstructions}
                onFailurePhoneNumber={useOnConnectDialNumber ? onConnectDialNumber : onFailurePhoneNumber}
                participationType={participationType}
                styles={styles}
                useOnConnectDialNumber={useOnConnectDialNumber}
                user={user}
                visible={visible}
            />
        );
    }
}

export const TroubleshootingContainer = compose(
    withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } })
)(Troubleshooting);
