import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withPartial } from 'graphql/audioCalls';
import { PartialTranscriptUI } from './ui';

export class PartialTranscript extends PureComponent {
    static displayName = 'PartialTranscriptContainer';

    static propTypes = {
        partialTranscript: PropTypes.string
    };

    static defaultProps = {
        partialTranscript: null
    };

    render() {
        const { partialTranscript, ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return partialTranscript ? <PartialTranscriptUI {...rest} partialTranscript={partialTranscript} /> : null;
    }
}

export const PartialTranscriptContainer = compose(withPartial({ subscribe: true }))(PartialTranscript);
