export const styleSheet = theme => ({
    container: {
        transition: 'all 0.2s ease-in-out',
        position: 'relative'
    },
    calendar: {
        position: 'absolute',
        zIndex: 1,
        '.react-calendar': {
            border: 'unset',
            boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.1)',
            borderRadius: 6,
            overflow: 'hidden'
        },
        '.react-calendar__year-view': {
            '.react-calendar__tile': {
                padding: '21px 0',
                borderRadius: 6,
                ':hover': {
                    backgroundColor: theme.colors.gray03
                },
                ':focus': {
                    backgroundColor: theme.colors.gray03
                }
            }
        },
        '.react-calendar__navigation': {
            marginBottom: '5px',
            paddingTop: '5px',
            '.react-calendar__navigation__label': {
                backgroundColor: theme.colors.white01,
                fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
                fontFamily: theme.fonts.fontFamily,
                color: theme.colors.gray06,
                borderRadius: 6,
                ':hover': {
                    backgroundColor: theme.colors.gray03
                },
                ':focus': {
                    backgroundColor: theme.colors.gray03
                }
            },
            '.react-calendar__navigation__prev-button': {
                marginLeft: 6,
                borderRadius: 6,
                ':hover': {
                    backgroundColor: theme.colors.gray03
                },
                ':focus': {
                    backgroundColor: theme.colors.gray03
                }
            },
            '.react-calendar__navigation__next-button': {
                marginRight: 6,
                borderRadius: 6,
                ':hover': {
                    backgroundColor: theme.colors.gray03
                },
                ':focus': {
                    backgroundColor: theme.colors.gray03
                }
            },
            // hide the previous and next year buttons
            '.react-calendar__navigation__prev2-button': {
                display: 'none'
            },
            '.react-calendar__navigation__next2-button': {
                display: 'none'
            }
        },
        '.react-calendar__month-view': {
            backgroundColor: theme.colors.white01,
            border: `1px solid ${theme.colors.gray01}`,
            borderBottom: 'none',
            margin: '0 -1px 0px -1px',
            padding: theme.margins.margin00,
            '.react-calendar__tile': {
                ':hover': {
                    backgroundColor: theme.colors.gray03
                },
                ':focus': {
                    backgroundColor: theme.colors.gray03
                }
            },
            '.react-calendar__tile--hover': {
                backgroundColor: theme.colors.gray03
            },
            '.react-calendar--selectRange': {
                backgroundColor: theme.colors.gray03
            },
            '.react-calendar__tile--active': {
                borderRadius: '20px 0 0 20px',
                backgroundColor: theme.colors.blue08,
                ':hover': {
                    backgroundColor: theme.colors.blue08
                },
                ':focus': {
                    backgroundColor: theme.colors.blue08
                }
            }
        }
    }
});
