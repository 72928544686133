import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class Checkbox extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        disabled: false,
        name: undefined,
        passedStyles: {},
        size: 1
    };

    render() {
        const { name, theme, styles, passedStyles, children, checked, disabled, onChange, size, ...rest } = this.props;
        let boxStyle = styles.box;
        let boxClass = 'checkbox';
        let iconType = 'checkMark';
        if (size === 2) {
            boxStyle = checked ? styles.rowBoxChecked : styles.rowBox;
            boxClass = checked ? 'rowBoxChecked' : 'rowBox';
        }
        if (size === 3) {
            boxStyle = checked ? styles.rowBoxChecked03 : styles.rowBox03;
            boxClass = checked ? 'checkboxChecked03' : 'checkbox03';
            iconType = 'checkMarkSmall';
        }
        if (size === 4) {
            boxStyle = checked ? styles.rowBoxChecked04 : styles.rowBox04;
            boxClass = checked ? 'checkboxChecked04' : 'checkbox04';
            iconType = 'checkMarkSmall';
        }
        return (
            <Div
                data-tname={`checkbox-${name}`}
                styles={{ ...styles.container, ...(disabled ? styles.disabled : {}), ...passedStyles }}
                onClick={disabled ? undefined : onChange}
                {...rest}
            >
                <Div styles={boxStyle} className={boxClass}>
                    <Icon type={iconType} color={checked ? theme.colors.purple01 : theme.colors.white01} />
                </Div>
                {children && children.length > 0 && (
                    <Text size={1} styles={styles.label} uppercase>
                        {children}
                    </Text>
                )}
            </Div>
        );
    }
}

export const CheckboxUI = compose(withStyleSheet(styleSheet))(Checkbox);
