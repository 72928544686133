import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NavLink } from 'react-router-dom';
import { Draggable } from 'lib/react-beautiful-dnd';
import { withStyleSheet } from 'hoc/styles';
import { Icon } from 'components/Icon';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { Hint } from 'components/Hint';
import { styleSheet } from './stylesheet';

class DashboardMenuOption extends PureComponent {
    static propTypes = {
        dashboardType: PropTypes.string,
        deleting: PropTypes.bool.isRequired,
        description: PropTypes.string,
        draggable: PropTypes.bool.isRequired,
        editable: PropTypes.bool.isRequired,
        icon: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        index: PropTypes.number.isRequired,
        isDefault: PropTypes.bool.isRequired,
        isFollowed: PropTypes.bool.isRequired,
        isRecommended: PropTypes.bool.isRequired,
        isShared: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        numUnread: PropTypes.number,
        onDeleteDashboard: PropTypes.func.isRequired,
        onDragDashboard: PropTypes.func,
        onEditDashboard: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        route: PropTypes.string.isRequired,
        sectionId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        unselectable: PropTypes.bool.isRequired
    };

    static defaultProps = {
        dashboardType: null,
        description: null,
        icon: null,
        numUnread: 2,
        passedStyles: {},
        onDragDashboard: undefined
    };

    renderDashboardTooltip() {
        const { styles, theme, onDeleteDashboard, onEditDashboard, isShared, dashboardType } = this.props;

        return ({ hideTooltip }) => (
            <Fragment>
                {!isShared && dashboardType !== 'equity' && dashboardType !== 'event_group' && (
                    <Div
                        styles={styles.hoverMenuOption}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            onEditDashboard();
                            hideTooltip();
                        }}
                    >
                        <Div styles={styles.menuIconContainer}>
                            <Icon type="pencil02" color={theme.colors.black01} />
                        </Div>
                        <Text size={3}>Edit</Text>
                    </Div>
                )}
                <Div
                    styles={styles.hoverMenuOption}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDeleteDashboard();
                        hideTooltip();
                    }}
                >
                    <Div styles={styles.menuIconContainer}>
                        <Icon
                            type={isShared || dashboardType === 'equity' ? 'circleX' : 'trash02'}
                            color={theme.colors.black01}
                        />
                    </Div>
                    <Text size={3}>{isShared || dashboardType === 'equity' ? 'Remove' : 'Delete'}</Text>
                </Div>
            </Fragment>
        );
    }

    render() {
        const {
            dashboardType,
            deleting,
            description,
            draggable,
            editable,
            icon,
            id,
            index,
            isDefault,
            isFollowed,
            isRecommended,
            isShared,
            label,
            numUnread,
            onDragDashboard,
            passedStyles,
            route,
            sectionId,
            styles,
            theme,
            unselectable
        } = this.props;
        if (!route) {
            return null;
        }
        const deleteLabel = isShared || dashboardType === 'equity' ? 'Removing...' : 'Deleting...';
        let iconType = isShared ? 'dashboardFollowed' : 'columns02';
        if (isRecommended) {
            iconType = 'dashboardUnfollowed';
        }
        if (dashboardType === 'equity') {
            iconType = 'dashboardEquityUnfollowed';
            if (isFollowed) {
                iconType = 'dashboardEquity';
            }
        }

        const link = (
            <NavLink to={route} activeClassName={!unselectable ? 'selected' : undefined}>
                <Div styles={styles.dashboardMenuOptionLabel}>
                    {isDefault ? (
                        <Div className="optionIcon" styles={styles.defaultIconContainer}>
                            <Icon type={icon} color={theme.colors.gray04} />
                        </Div>
                    ) : (
                        <Div className="optionIcon" styles={styles.dashboardIconContainer}>
                            <Icon type={iconType} color={theme.colors.gray04} />
                        </Div>
                    )}
                    <Hint
                        xOffset={210}
                        yOffset={-19}
                        text={label}
                        description={description}
                        styles={styles.dashboardMenuOptionLabel}
                    >
                        <Text size={3}>{deleting ? deleteLabel : label}</Text>
                    </Hint>
                </Div>
                {editable ? (
                    <Tooltip
                        slideIn
                        isEnabled
                        useElementOffsetLeft
                        useElementOffsetTop
                        xOffset={0}
                        yOffset={-4}
                        useOutsideClickHandler
                        styles={styles.hoverMenu}
                        content={this.renderDashboardTooltip()}
                    >
                        {({ showTooltip }) => (
                            <Fragment>
                                {numUnread > 0 && (
                                    <Div className="unread" styles={[styles.unread, styles.unreadMenuIcon]}>
                                        <Text size={1} weight="bold">
                                            {numUnread}
                                        </Text>
                                    </Div>
                                )}
                                <Div
                                    className="menuIcon"
                                    data-dashboardid={`${id}`}
                                    styles={styles.menuIcon}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showTooltip(e);
                                    }}
                                >
                                    <Icon type="menuVertical" color={theme.colors.gray04} />
                                </Div>
                            </Fragment>
                        )}
                    </Tooltip>
                ) : (
                    <Fragment>
                        {numUnread > 0 && (
                            <Div className="unread" styles={styles.unread}>
                                <Text size={2}>{numUnread}</Text>
                            </Div>
                        )}
                    </Fragment>
                )}
            </NavLink>
        );

        if (draggable) {
            return (
                <Draggable
                    key={`${sectionId}-${id || ''}-${label}`}
                    draggableId={`${sectionId}:${id}` || label}
                    index={index}
                    payload={{ onDragEnd: onDragDashboard }}
                >
                    {provided => (
                        <Div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            styles={{ ...styles.container, ...passedStyles }}
                            data-tname="sidebar-dashboard-nav"
                        >
                            {link}
                        </Div>
                    )}
                </Draggable>
            );
        }

        return (
            <Div
                key={`${id || ''}-${label}`}
                styles={{ ...styles.container, ...passedStyles }}
                data-tname="sidebar-dashboard-nav"
            >
                {link}
            </Div>
        );
    }
}

export const DashboardMenuOptionUI = compose(withStyleSheet(styleSheet))(DashboardMenuOption);
