import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import memoize from 'memoize-one';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { routes } from 'routes';
import { withUrlContext } from 'hoc/url';
import { generateTabURL } from 'utils';
import { popoutManager } from 'utils/popout';
import { SENTIMENT_TYPES, STREAM_DISPLAY_MODES } from 'consts';
import { TranscriptCardUI } from './ui';

function groupedWithLinks(eventId, pathname, streamId, grouped) {
    return (grouped || []).map(g => {
        return {
            ...g,
            to: generateTabURL({
                eventId,
                pathname,
                match: 'match',
                streamId,
                itemId: g.itemId
            })
        };
    });
}

export class TranscriptCard extends PureComponent {
    static displayName = 'TranscriptCardContainer';

    static propTypes = {
        bookmarkId: PropTypes.string,
        callDate: PropTypes.string.isRequired,
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equityIcon: PropTypes.string,
        equityInitials: PropTypes.string,
        eventId: PropTypes.string.isRequired,
        eventType: PropTypes.string,
        exchangeName: PropTypes.string,
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        itemId: PropTypes.string,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        score: PropTypes.number,
        sentiment: PropTypes.oneOf(SENTIMENT_TYPES),
        speaker: PropTypes.objectOf(PropTypes.any),
        startTimestamp: PropTypes.string.isRequired,
        streamId: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string,
        transcript: PropTypes.string.isRequired
    };

    static defaultProps = {
        eventType: undefined,
        bookmarkId: undefined,
        company: undefined,
        displayMode: STREAM_DISPLAY_MODES.default,
        equityIcon: undefined,
        equityInitials: undefined,
        exchangeName: null,
        grouped: [],
        highlightColor: undefined,
        initialSearchTerm: undefined,
        itemId: null,
        score: undefined,
        sentiment: null,
        speaker: null,
        styles: {},
        ticker: null,
        title: null
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.groupedWithLinks = memoize(groupedWithLinks);
    }

    handleClick(e, clickedItemId) {
        const { itemId } = this.props;
        if (e.shiftKey) {
            this.handlePopout(e, clickedItemId || itemId);
        }
    }

    handlePopout(e, itemId) {
        const { eventId, streamId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generateTabURL({
                pathname: generatePath(routes.popoutEvent, { type: 'event', audioCallId: eventId }),
                eventId,
                match: 'match',
                itemId,
                streamId
            }),
            name: `popout-event-${eventId}`,
            width: 950
        });
    }

    render() {
        const {
            bookmarkId,
            callDate,
            company,
            displayMode,
            grouped,
            itemId,
            equityIcon,
            equityInitials,
            eventId,
            eventType,
            exchangeName,
            highlightColor,
            initialSearchTerm,
            matchId,
            pathname,
            score,
            sentiment,
            speaker,
            startTimestamp,
            styles,
            streamId,
            ticker,
            title,
            transcript
        } = this.props;
        return (
            <TranscriptCardUI
                bookmarkId={bookmarkId}
                callDate={callDate}
                company={company}
                displayMode={displayMode}
                equityIcon={equityIcon}
                equityInitials={equityInitials}
                eventId={eventId}
                eventType={eventType}
                exchangeName={exchangeName}
                grouped={this.groupedWithLinks(eventId, pathname, streamId, grouped)}
                highlightColor={highlightColor}
                itemId={itemId}
                matchId={matchId}
                onClick={this.handleClick}
                score={score}
                sentiment={sentiment}
                speaker={speaker}
                startTimestamp={startTimestamp ? new XDate(startTimestamp) : null}
                streamId={streamId}
                styles={styles}
                ticker={ticker}
                title={title}
                to={generateTabURL({
                    eventId,
                    pathname,
                    match: 'match',
                    streamId,
                    itemId,
                    initialSearchTerm
                })}
                transcript={transcript}
            />
        );
    }
}

export const TranscriptCardContainer = compose(withUrlContext(['pathname']))(TranscriptCard);
