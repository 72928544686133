import gql from 'graphql-tag';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withGetSummaryReport($summaryReportId: ID!) {
                    getSummaryReport(summaryReportId: $summaryReportId) {
                        id
                        summaryReportId
                        endDate
                        startDate
                        created
                        title
                        topSummary
                        blocks {
                            quarter
                            year
                            eventTitle
                            localTicker
                            companyName
                            eventType
                            eventId
                            eventDate
                            title
                            content
                            url
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const report = get(data, 'getSummaryReport');
                    const title = get(report, 'title');
                    const created = get(report, 'created');
                    const blocks = get(report, 'blocks', []);
                    const endDate = get(report, 'endDate');
                    const startDate = get(report, 'startDate');
                    const topSummary = get(report, 'topSummary', []);
                    const loading = get(data, 'loading', false);
                    return {
                        title,
                        created,
                        blocks,
                        endDate,
                        startDate,
                        topSummary,
                        loading
                    };
                },
                options: ({ reportId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        summaryReportId: reportId
                    }
                })
            }
        )
    );
