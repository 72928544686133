import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { styleSheet } from './stylesheet';

class TonalGraph extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        sentimentValue: PropTypes.number.isRequired,
        sentimentStrength: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
        divergentValue: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    determineColor() {
        const { sentimentValue, divergentValue, theme } = this.props;
        if (sentimentValue < 0) {
            return theme.colors.red09;
        }
        if (divergentValue >= 1) {
            return theme.colors.orange03;
        }
        if (sentimentValue > 0) {
            return theme.colors.green03;
        }
        return theme.colors.gray02;
    }

    render() {
        const { passedStyles, styles, sentimentStrength } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {['Low', 'Medium', 'High'].includes(sentimentStrength) ? (
                    <Div styles={{ ...styles.bar1container, backgroundColor: this.determineColor() }} />
                ) : (
                    <Div styles={{ ...styles.bar1container }} />
                )}

                {['Medium', 'High'].includes(sentimentStrength) ? (
                    <Div styles={{ ...styles.bar2container, backgroundColor: this.determineColor() }} />
                ) : (
                    <Div styles={{ ...styles.bar2container }} />
                )}

                {sentimentStrength === 'High' ? (
                    <Div styles={{ ...styles.bar3container, backgroundColor: this.determineColor() }} />
                ) : (
                    <Div styles={{ ...styles.bar3container }} />
                )}
            </Div>
        );
    }
}

export const TonalGraphUI = compose(withStyleSheet(styleSheet))(TonalGraph);
