import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionMenu } from 'components/ActionMenu';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { PERMISSIONS, HIGHLIGHT_COLORS } from 'consts';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class EventActionMenu extends PureComponent {
    static propTypes = {
        bookmarkId: PropTypes.string,
        copiedLink: PropTypes.bool.isRequired,
        copiedText: PropTypes.bool.isRequired,
        creatingTranscrippet: PropTypes.bool.isRequired,
        copyTextToClipboard: PropTypes.func.isRequired,
        copyLinkToClipboard: PropTypes.func.isRequired,
        eventType: PropTypes.string,
        flagEnabled: PropTypes.bool.isRequired,
        flagged: PropTypes.bool.isRequired,
        hideTooltip: PropTypes.func,
        isEventOwner: PropTypes.bool.isRequired,
        isPublished: PropTypes.bool.isRequired,
        onBookmark: PropTypes.func.isRequired,
        onTranscrippet: PropTypes.func.isRequired,
        onFlag: PropTypes.func.isRequired,
        onDeleteTranscript: PropTypes.func,
        onEditTranscript: PropTypes.func,
        onHide: PropTypes.func,
        onShow: PropTypes.func,
        openBookmarkForm: PropTypes.func.isRequired,
        openByDefault: PropTypes.bool.isRequired,
        openEditor: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        bookmarkId: null,
        eventType: undefined,
        hideTooltip: undefined,
        onHide: undefined,
        onDeleteTranscript: undefined,
        onEditTranscript: undefined,
        onShow: undefined,
        passedStyles: {}
    };

    render() {
        const {
            bookmarkId,
            copiedLink,
            copiedText,
            copyLinkToClipboard,
            copyTextToClipboard,
            creatingTranscrippet,
            eventType,
            flagEnabled,
            flagged,
            hideTooltip,
            isEventOwner,
            isPublished,
            onBookmark,
            onTranscrippet,
            onFlag,
            onDeleteTranscript,
            onEditTranscript,
            onHide,
            onShow,
            openBookmarkForm,
            openByDefault,
            openEditor,
            passedStyles,
            styles,
            submitting,
            theme
        } = this.props;

        const options = [
            {
                iconType: 'highlighter',
                iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                label: bookmarkId ? 'Remove highlight' : 'Highlight match',
                onClick: bookmarkId ? onBookmark : undefined,
                loading: submitting,
                name: 'toggleHighlight',
                closeOnClick: true,
                permissions: [PERMISSIONS.featureBookmarks],
                content: bookmarkId ? (
                    undefined
                ) : (
                    <Fragment>
                        <Icon type="highlighter" color={theme.colors.gray04} />
                        <Div styles={styles.colorOptions}>
                            {HIGHLIGHT_COLORS.map(color => (
                                <Div
                                    key={color}
                                    onMouseDown={e => onBookmark(e, color)}
                                    onTouchStart={e => onBookmark(e, color)}
                                    styles={{
                                        ...styles.colorOption,
                                        backgroundColor: getHighlightColor(color)?.dark,
                                        border: `1px solid ${getHighlightColor(color)?.dark02}`
                                    }}
                                />
                            ))}
                        </Div>
                    </Fragment>
                )
            },
            {
                iconType: 'bookmarkWithNote',
                iconColor: bookmarkId ? theme.colors.pink01 : theme.colors.gray04,
                label: bookmarkId ? 'Edit highlight' : 'Highlight with note',
                onClick: openBookmarkForm,
                name: 'openBookmarkForm',
                closeOnClick: true,
                permissions: [PERMISSIONS.featureBookmarks],
                hideMobile: true
            },
            {
                iconType: 'code',
                iconColor: theme.colors.gray04,
                label: 'Share Transcrippet™',
                onClick: e => {
                    onTranscrippet(e, hideTooltip);
                },
                loading: creatingTranscrippet,
                name: 'toggleTranscrippet',
                permissions: [PERMISSIONS.featureTranscrippets],
                hideMobile: true
            }
        ];

        if (eventType === 'custom' && !isPublished && isEventOwner && onEditTranscript) {
            options.push({
                iconType: 'pencil02',
                iconColor: theme.colors.gray04,
                label: 'Edit Text',
                onClick: onEditTranscript,
                closeOnClick: true,
                name: 'editTranscript'
            });
        }

        if (eventType === 'custom' && !isPublished && isEventOwner && onDeleteTranscript) {
            options.push({
                iconType: 'trash02',
                iconColor: theme.colors.gray04,
                label: 'Delete Text',
                onClick: onDeleteTranscript,
                closeOnClick: true,
                name: 'deleteTranscript'
            });
        }

        options.push({
            iconType: 'copyToClipboard',
            iconColor: copiedText ? theme.colors.green02 : theme.colors.gray04,
            label: copiedText ? 'Text Copied!' : 'Copy Text to Clipboard',
            onClick: copyTextToClipboard,
            closeOnClick: true,
            name: 'copyTextToClipboard'
        });
        options.push({
            iconType: 'share',
            iconColor: theme.colors.gray04,
            label: copiedLink ? 'Link copied!' : 'Copy Link to Clipboard',
            onClick: copyLinkToClipboard,
            closeOnClick: true,
            name: 'copyLinkToClipboard'
        });

        if (!isPublished) {
            options.push({
                iconType: 'pencil02',
                iconColor: theme.colors.gray04,
                label: 'Open in Editor',
                onClick: openEditor,
                closeOnClick: true,
                name: 'editTranscript',
                permissions: [PERMISSIONS.editTranscripts]
            });
        }

        if (flagEnabled && !isEventOwner && !isPublished) {
            options.push({
                iconType: 'flag',
                iconColor: flagged ? theme.colors.pink01 : theme.colors.gray04,
                label: flagged ? 'Flagged for review' : 'Flag Transcription',
                onClick: onFlag,
                name: 'flagTranscript'
            });
        }

        return (
            <ActionMenu
                className="tab-tooltip"
                hideTooltip={hideTooltip}
                menuStyles={styles.menu}
                onHide={onHide}
                onShow={onShow}
                openByDefault={openByDefault}
                options={options}
                styles={passedStyles}
            />
        );
    }
}

export const EventActionMenuUI = compose(withStyleSheet(styleSheet))(EventActionMenu);
