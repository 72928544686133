import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import XDate from 'xdate';

import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { styleSheet } from './stylesheet';

class DashboardStatsChart extends PureComponent {
    static propTypes = {
        dashDateFilter: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        data: PropTypes.arrayOf(PropTypes.any),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectRange: PropTypes.func.isRequired,
        chartElement: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        dashDateFilter: undefined,
        passedStyles: {},
        data: []
    };

    render() {
        const { styles, passedStyles, data, theme, selectRange, chartElement, dashDateFilter } = this.props;

        const options = {
            exporting: {
                enabled: true,
                sourceWidth: 990,
                sourceHeight: 400,
                buttons: { contextButton: { symbolFill: theme.colors.gray04, symbolStroke: theme.colors.gray04 } }
            },
            chart: {
                type: 'spline',
                height: '400px',
                backgroundColor: theme.colors.white01
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        anchor: 'end',
                        enabled: true,
                        forced: true,
                        approximation: 'sum',
                        units: [['week', [1]]]
                    },
                    events: {
                        legendItemClick(e) {
                            // Upon cmd-click of a legend item, rather than toggling visibility, we want to hide all other items.
                            const hideAllOthers = e.browserEvent.metaKey;
                            if (hideAllOthers) {
                                const seriesIndex = this.index;
                                const { series } = this.chart;
                                let i;

                                for (i = 0; i < series.length; i += 1) {
                                    // rather than calling 'show()' and 'hide()' on the series', we use setVisible and then
                                    // call chart.redraw --- this is significantly faster since it involves fewer chart redraws
                                    if (series[i].index === seriesIndex) {
                                        if (!series[i].visible) series[i].setVisible(true, false);
                                    } else if (series[i].visible) {
                                        series[i].setVisible(false, false);
                                    }
                                }
                                this.chart.redraw();
                                return false;
                            }
                            return true;
                        }
                    }
                }
            },
            credits: {
                enabled: true,
                text: 'Source: Aiera, Inc.',
                href: '/'
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: null
                }
            },
            legend: {
                enabled: true,
                align: 'left',
                x: 2,
                style: {
                    textTransform: 'capitalize'
                }
            },
            title: {
                text: null
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: !dashDateFilter,
                selected: 3,
                allButtonsEnabled: true,
                inputEnabled: false,
                buttonPosition: {
                    x: 6
                },
                buttons: [
                    {
                        type: 'month',
                        count: 1,
                        text: '1M',
                        events: {
                            click() {
                                selectRange(1);
                            }
                        }
                    },
                    {
                        type: 'month',
                        count: 3,
                        text: '3M',
                        events: {
                            click() {
                                selectRange(3);
                            }
                        }
                    },
                    {
                        type: 'month',
                        count: 6,
                        text: '6M',
                        events: {
                            click() {
                                selectRange(6);
                            }
                        }
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1YR',
                        events: {
                            click() {
                                selectRange(13);
                            }
                        }
                    }
                ]
            },
            tooltip: {
                backgroundColor: theme.colors.gray11,
                borderColor: theme.colors.gray01,
                borderRadius: 6,
                borderWidth: 1,
                valueDecimals: 0,
                shadow: false,
                shared: true,
                split: false,
                style: {
                    color: theme.colors.white01,
                    fontFamily: theme.fonts.fontFamily,
                    fontSize: theme.fonts.fontSizes.size01,
                    textTransform: 'capitalize'
                },
                formatter() {
                    const dateString = `Week ending ${new XDate(this.x).toString('MMM dd, yyyy')} <br/>`;
                    const items = this.points.sort((a, b) => (a.y < b.y ? -1 : a.y > b.y ? 1 : 0)).reverse();
                    const tipText = items.map(
                        item => `<span style="color:${item.color}" />●</span> ${item.series.name}: ${item.y} <br/>`
                    );
                    return dateString + tipText.join('');
                }
            },
            series: data
        };

        if (data && data.length > 0) {
            return (
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="stockChart"
                        options={options}
                        ref={chartElement}
                    />
                </Div>
            );
        }

        return null;
    }
}

export const DashboardStatsChartUI = compose(withStyleSheet(styleSheet))(DashboardStatsChart);
