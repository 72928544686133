import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { get } from 'utils';
import { withSectors } from 'graphql/sectors';
import { SectorAutocompleteUI } from './ui';

function formatValue({ gicsSectorId, gicsSubSectorId }) {
    if (gicsSectorId) {
        return `sector:${gicsSectorId}`;
    }
    if (gicsSubSectorId) {
        return `subsector:${gicsSubSectorId}`;
    }
    return null;
}

const groupOptions = sectors =>
    (sectors || []).map(({ name, id: gicsSectorId, subSectors }) => ({
        label: name,
        value: formatValue({ gicsSectorId }),
        options: (subSectors || []).map(({ name: subSectorName, id: gicsSubSectorId }) => ({
            label: subSectorName,
            value: formatValue({ gicsSubSectorId })
        }))
    }));

export class SectorAutocomplete extends PureComponent {
    static displayName = 'SectorAutocompleteContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        sectors: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        useTags: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)])
    };

    static defaultProps = {
        autoFocus: false,
        initialSearchTerm: null,
        label: null,
        loading: false,
        multi: false,
        name: 'sectorAutocomplete',
        onChange: null,
        placeholder: 'Filter by Sector',
        sectors: [],
        styles: {},
        useTags: false,
        value: null
    };

    constructor(props) {
        super(props);

        this.getTagLabel = this.getTagLabel.bind(this);
        this.onChange = this.onChange.bind(this);

        this.formatValue = memoize(formatValue);
        this.groupOptions = memoize(groupOptions);
    }

    getTagLabel(tag) {
        const { sectors } = this.props;
        const groupedSectors = this.groupOptions(sectors);
        const type = tag.split(':')[0];

        if (type === 'sector') {
            return get(
                groupedSectors.find(s => s.value === tag),
                'label'
            );
        }

        if (type === 'subsector') {
            let subSectorLabel = tag;
            groupedSectors.some(({ options }) => {
                return options.some(subSector => {
                    const match = subSector.value === tag;
                    if (match) subSectorLabel = subSector.label;
                    return match;
                });
            });
            return subSectorLabel;
        }

        return tag;
    }

    onChange({ event, value: selectedValue, parent }) {
        const { onChange, name } = this.props;
        let value;

        // When this component is used with tags, TaggedInput sends an array of selected tags as the value
        // and parent is never sent. So, we need to check each value item to see if it's a sector or sub-sector
        if (Array.isArray(selectedValue)) {
            value = selectedValue.map(v => {
                const valueData = v.split(':');
                return valueData[0] === 'sector' ? { gicsSectorId: valueData[1] } : { gicsSubSectorId: valueData[1] };
            });
        } else {
            const gicsSectorId = selectedValue && !parent ? selectedValue.split(':')[1] : undefined;
            const gicsSubSectorId = selectedValue && parent ? selectedValue.split(':')[1] : undefined;
            value = { gicsSectorId, gicsSubSectorId };
        }

        if (onChange) {
            onChange({
                event,
                name,
                value,
                label: typeof selectedValue === 'string' ? this.getTagLabel(selectedValue) : undefined
            });
        }
    }

    render() {
        const {
            autoFocus,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            placeholder,
            sectors,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <SectorAutocompleteUI
                autoFocus={autoFocus}
                getTagLabel={this.getTagLabel}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                options={this.groupOptions(sectors)}
                placeholder={placeholder}
                styles={styles}
                useTags={useTags}
                value={Array.isArray(value) ? value.map(this.formatValue) : this.formatValue(value || {})}
            />
        );
    }
}

export const SectorAutocompleteContainer = compose(
    withSectors({ variables: { withSubSectors: true } }),
    withProps(({ sectorsLoading }) => ({ loading: sectorsLoading }))
)(SectorAutocomplete);
