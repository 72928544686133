import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { TagAutocomplete } from 'components/TagAutocomplete';
import { styleSheet } from './stylesheet';

class TooltipTagForm extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        buttonDisabled: PropTypes.bool.isRequired,
        error: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        onCancel: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onPlainText: PropTypes.func,
        onSubmit: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        error: null,
        onCancel: undefined,
        onSubmit: undefined,
        onPlainText: undefined,
        passedStyles: {},
        value: ''
    };

    render() {
        const {
            className,
            styles,
            passedStyles,
            buttonDisabled,
            loading,
            onCancel,
            onChange,
            onSubmit,
            onPlainText,
            error,
            theme,
            value
        } = this.props;
        return (
            <Div className={className} styles={{ ...styles.container, ...passedStyles }}>
                <TagAutocomplete
                    autoFocus
                    styles={styles.input}
                    name="new-tag-form"
                    value={value}
                    onChange={onChange}
                    onPlainText={onPlainText}
                    placeholder="Enter Tag... "
                />
                {error && (
                    <Text size={2} styles={styles.error} uppercase weight="medium">
                        {error}
                    </Text>
                )}
                <Div styles={styles.buttonsContainer}>
                    <ActionButton styles={{ ...styles.button, ...styles.buttonCancel }} onClick={onCancel}>
                        <Text size={1}>Cancel</Text>
                    </ActionButton>
                    <ActionButton
                        styles={{
                            ...styles.button,
                            ...styles.buttonSubmit,
                            ...(buttonDisabled ? styles.buttonDisabled : {})
                        }}
                        disabled={buttonDisabled}
                        loading={loading}
                        loaderColor={theme.colors.white01}
                        onClick={onSubmit}
                    >
                        <Text size={1}>Add Tag</Text>
                    </ActionButton>
                </Div>
            </Div>
        );
    }
}

export const TooltipTagFormUI = compose(withStyleSheet(styleSheet))(TooltipTagForm);
