import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ButtonFormUI } from './ui';

export class ButtonForm extends PureComponent {
    static displayName = 'ButtonFormContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ButtonFormUI {...rest} />;
    }
}

const connectedComponent = connect()(ButtonForm);

export const ButtonFormContainer = compose()(connectedComponent);
