import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath, withRouter } from 'react-router-dom';
import { withCloneDashboard } from 'graphql/dashboards';
import { routes } from 'routes';
import { generateModalId, get } from 'utils';
import { withData } from './data';
import { AddMonitorButtonUI } from './ui';

export class AddMonitorButton extends PureComponent {
    static displayName = 'AddMonitorButtonContainer';

    static propTypes = {
        cloneDashboard: PropTypes.func.isRequired,
        dashboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        follow: PropTypes.bool,
        followMonitor: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isStarred: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        navOnCreate: PropTypes.bool,
        restricted: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        dashboardId: undefined,
        follow: false,
        isStarred: false,
        navOnCreate: true,
        restricted: false,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onAddMonitor = this.onAddMonitor.bind(this);

        this.state = {
            loading: false
        };
    }

    componentDidUpdate({ dashboardId: newDashboardId }) {
        const { dashboardId } = this.props;

        if (dashboardId !== newDashboardId) {
            this.setState({
                loading: false
            });
        }
    }

    onAddMonitor(options) {
        const {
            cloneDashboard,
            restricted,
            dashboardId,
            history,
            location,
            navOnCreate,
            follow,
            followMonitor,
            isStarred
        } = this.props;
        const shouldFollow = get(options, 'shouldFollow', false);
        if (restricted) {
            history.push(generateModalId({ location, id: 'addMonitor', type: 'upgrade' }));
        } else if (dashboardId) {
            if (follow || shouldFollow) {
                this.setState({ loading: true }, () => {
                    followMonitor(dashboardId, !isStarred)
                        .then(() => {
                            this.setState({ loading: false });
                        })
                        .catch(() => {
                            this.setState({ loading: false });
                        });
                });
            } else {
                this.setState({ loading: true }, () => {
                    cloneDashboard(dashboardId)
                        .then(({ id }) => {
                            this.setState({ loading: false }, () => {
                                if (navOnCreate) {
                                    history.push(generatePath(routes.dashboard, { dashboardId: id }));
                                }
                            });
                        })
                        .catch(() => {
                            this.setState({ loading: false });
                        });
                });
            }
        }
    }

    render() {
        const { styles, restricted, isStarred } = this.props;
        const { loading } = this.state;
        return (
            <AddMonitorButtonUI
                restricted={restricted}
                isStarred={isStarred}
                onAddMonitor={this.onAddMonitor}
                loading={loading}
                styles={styles}
            />
        );
    }
}

export const AddMonitorButtonContainer = compose(withData(), withRouter, withCloneDashboard())(AddMonitorButton);
