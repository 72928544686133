export const styleSheet = theme => ({
    container: {
        borderRadius: 0,
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.gray11,
        height: 30,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.margins.margin00,
        cursor: 'pointer',
        zIndex: 4,
        ':hover': {
            backgroundColor: theme.colors.gray13
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            opacity: 0
        }
    },
    toolbarButton: {
        cursor: 'pointer',
        alignItems: 'center',
        marginRight: theme.margins.margin00,
        svg: {
            height: 14,
            width: 14
        }
    },
    spacer: {
        display: 'flex',
        height: '34px',
        flex: 1
    },
    title: {
        cursor: 'pointer',
        color: theme.colors.gray04,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize'
    },
    titleContainer: {
        cursor: 'pointer',
        width: 'calc(100% - 100px)',
        lineHeight: '38px'
    },
    toolbarControls: {
        display: 'flex',
        alignItems: 'center',
        padding: '0',
        cursor: 'pointer',
        div: {
            height: '38px',
            display: 'flex',
            ':hover': {
                g: {
                    fill: theme.colors.blue01
                }
            }
        }
    }
});
