import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { LoaderLogo } from 'components/LoaderLogo';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'news';

class NewsResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        setPreview: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    componentDidUpdate(prevProps) {
        const { highlightedResult: prevHighlightedResult } = prevProps;
        const { highlightedResult, setPreview, results } = this.props;
        const { type, index } = highlightedResult;
        const { type: prevType, index: prevIndex } = prevHighlightedResult;

        if (type === RESULT_TYPE && (type !== prevType || index !== prevIndex)) {
            const result = results[index];
            if (result) {
                clearTimeout(this.previewTimeout);
                this.previewTimeout = setTimeout(() => {
                    setPreview(this.generatePreview(result));
                }, 50);
            }
        }
    }

    /* eslint-disable react/no-array-index-key */
    generatePreview(result) {
        const { title, equities, highlights, additionalHighlights, publishedDate, newsSource } = result;
        const { styles, onClickResult } = this.props;
        return (
            <Div styles={styles.equityPreview} onClick={onClickResult}>
                <Div styles={styles.previewBlock}>
                    {get(equities, 'length') === 1 && (
                        <CardEquities
                            equities={equities}
                            showTickers={false}
                            styles={styles.singleEquity}
                            maxIcons={9}
                        />
                    )}
                    <CardHeader styles={styles.previewHeader} title={title} />
                    {get(equities, 'length') > 1 && (
                        <CardEquities equities={equities} showTickers={false} styles={styles.equities} maxIcons={9} />
                    )}
                </Div>
                {newsSource && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            source
                        </Text>
                        <Text size={3}>{newsSource}</Text>
                    </Div>
                )}
                {publishedDate && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            Publish date
                        </Text>
                        <Text size={3}>{new XDate(publishedDate).toString('MMM d, yyyy, h:ssTT')}</Text>
                    </Div>
                )}
                {highlights && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            match
                        </Text>
                        <Div styles={styles.summary}>
                            <RawHTML html={highlights} />
                        </Div>
                    </Div>
                )}
                {additionalHighlights.map((highlight, index) => (
                    <Div key={`${highlight.slice(1, 10)}-${index}-news-search`} styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            {`match ${index + 2}`}
                        </Text>
                        <Div styles={styles.summary}>
                            <RawHTML html={highlight} />
                        </Div>
                    </Div>
                ))}
            </Div>
        );
    }

    render() {
        const {
            styles,
            enabled,
            results,
            loading,
            loadingMoreResults,
            loadMoreResults,
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) {
            return null;
        }

        return (
            <Div styles={styles.results}>
                <Div styles={styles.resultHeaders}>News & Media</Div>
                {loading ? (
                    <Div styles={styles.loaderCell} colSpan={4}>
                        <LoaderLogo />
                    </Div>
                ) : (
                    results.map((news, index) => {
                        const { id, title, newsSource, publishedDate, equities } = news;
                        const date = publishedDate ? new XDate(publishedDate) : null;
                        const dateString = date ? date.toString('MM/dd/yyyy') : null;
                        const timeString = date ? date.toString('h:mmTT') : null;
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        return (
                            <Div
                                ref={node => indexElement(RESULT_TYPE, index, node)}
                                key={`news-${id}`}
                                styles={resultStyles}
                                onClick={onClickResult}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                            >
                                {get(equities, 'length') === 1 && (
                                    <CardEquities
                                        equities={equities}
                                        showTickers={false}
                                        styles={styles.singleEquity}
                                        maxIcons={10}
                                    />
                                )}
                                <Div styles={styles.resultTop}>
                                    <Text styles={styles.title}>{title}</Text>
                                    <Text styles={styles.subTitle}>
                                        {`${newsSource} • ${dateString}, ${timeString}`}
                                    </Text>
                                </Div>
                            </Div>
                        );
                    })
                )}
                {!loading && loadingMoreResults && <LoaderLogo styles={styles.loadingMore} />}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <ActionButton onClick={() => loadMoreResults('news')} size={1} styles={styles.loadMoreButton}>
                        <Text size={1}>Load More</Text>
                    </ActionButton>
                )}
            </Div>
        );
    }
}

export const NewsResultsUI = compose(withStyleSheet(styleSheet))(NewsResults);
