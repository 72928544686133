import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { withMemo } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

function normalizeTags(maxSuggested, tags, withSuggested, value) {
    const existingTagsCount = get(value, 'length', 0);
    const tagOptions = (tags || []).map(({ tag }) => ({
        label: tag,
        value: tag
    }));
    return {
        suggestedTags:
            withSuggested && tags
                ? tags
                      .slice(0, (maxSuggested || tags.length) + existingTagsCount)
                      .filter(t => !(value || []).includes(t.tag))
                : null,
        tags: tagOptions
    };
}

export const withData = () =>
    compose(
        withMemo({ normalize: normalizeTags }),
        graphql(
            gql`
                query withBookmarkTagsAutocomplete(
                    # we're not passing dashboardId because right now
                    # the results from the server are too limited
                    $equityId: ID
                    $eventId: ID
                    $scopes: [String]
                    $searchTerm: String
                ) {
                    bookmarkTags(
                        filter: { equityId: $equityId, eventId: $eventId, scopes: $scopes, search: $searchTerm }
                    ) {
                        count
                        tag
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    loading: get(data, 'loading'),
                    tags: get(data, 'bookmarkTags')
                }),
                options: ({ searchTerm }) => ({
                    context: {
                        debounceKey: 'withBookmarkTagsAutocomplete',
                        debounceTimeout: get(searchTerm, 'length') ? 300 : 0
                    },
                    fetchPolicy: 'cache-first'
                }),
                skip: ({ searchTerm, withSuggested }) => (withSuggested ? false : !get(searchTerm, 'length'))
            }
        ),
        withPropsOnChange(['tags', 'value'], ({ maxSuggested, normalize, tags, withSuggested, value }) => ({
            ...normalize(maxSuggested, tags, withSuggested, value)
        }))
    );
