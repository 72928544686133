import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ModalUI } from './ui';

/**
 * This modal component can be used by wrapping any component or DOM element.
 *
 * It requires three props: 1) isOpen - bool for modal visibility
 *                          2) onModalClose - func that gets called whenever a "close modal" click event occurs
 *                          3) title - title of the modal
 *
 * It also takes an optional styles prop which is used to style the modal's content wrapper div
 *
 * Usage:
 *
 *   <Modal isOpen={false} onModalClose={closeModal} styles={someStyles} title="My special modal">
 *       <Text size={1}>Some text here!</Text>
 *   </Modal>
 *
 * Note: when a user clicks outside the content of the modal, the onModalClose function will fire
 */
export class Modal extends PureComponent {
    static displayName = 'ModalContainer';

    static propTypes = {
        cancelClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        isOpen: PropTypes.bool.isRequired,
        onModalClose: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
        useShell: PropTypes.bool
    };

    static defaultProps = {
        cancelClassName: null,
        styles: {},
        title: null,
        useShell: false
    };

    constructor(props) {
        super(props);

        this.modalRoot = window.document.getElementById('modal-root');
        this.el = document.createElement('div');

        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        this.documentTitle = document.title;
        this.modalRoot.appendChild(this.el);
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
        this.modalRoot.removeChild(this.el);
    }

    render() {
        const { mounted } = this.state;
        const { styles, isOpen, onModalClose, title, children, useShell, cancelClassName } = this.props;
        return (
            <ModalUI
                cancelClassName={cancelClassName}
                isOpen={isOpen}
                modalContainer={this.el}
                mounted={mounted}
                onModalClose={onModalClose}
                styles={styles}
                title={title}
                useShell={useShell}
            >
                {children}
            </ModalUI>
        );
    }
}

export const ModalContainer = compose()(Modal);
