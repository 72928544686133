import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { dashboardFragment, dashboardSectionFragment } from 'graphql/fragments/streams';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CreateDashboardSection($name: String!, $position: Int) {
                    createDashboardSection(input: { name: $name, position: $position }) {
                        success
                        currentUser {
                            id
                            dashboardSections {
                                ...dashboardSection
                                dashboards {
                                    ...dashboard
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        createDashboardSection: (name, position) =>
                            mutate({
                                variables: { name, position }
                            })
                                .then(() => {
                                    setStatusBanner('Section created successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error creating section: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation DeleteDashboardSection($sectionId: ID!) {
                    deleteDashboardSection(sectionId: $sectionId) {
                        success
                        currentUser {
                            id
                            dashboardSections {
                                ...dashboardSection
                                dashboards {
                                    ...dashboard
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        deleteDashboardSection: sectionId =>
                            mutate({
                                variables: { sectionId }
                            })
                                .then(() => {
                                    setStatusBanner('Section successfully removed!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error removing section: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation UpdateDashboardSection($name: String!, $position: Int, $sectionId: ID!) {
                    updateDashboardSection(input: { name: $name, position: $position, sectionId: $sectionId }) {
                        success
                        currentUser {
                            id
                            dashboardSections {
                                ...dashboardSection
                                dashboards {
                                    ...dashboard
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        updateDashboardSection: (name, position, sectionId) =>
                            mutate({
                                variables: { name, position, sectionId }
                            })
                                .then(() => {
                                    setStatusBanner('Section updated successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error updating section: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        )
    );
