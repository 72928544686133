import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { get, toDateString, generateTabURL } from 'utils';
import { Div } from 'components/Div';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { ContentActionBlock } from 'components/ContentActionBlock';
import { PDFViewer } from 'components/PDFViewer';
import { FilingSidebar } from './FilingSidebar';
import { FilingHeader } from './FilingHeader';
import { styleSheet } from './stylesheet';

class Filing extends PureComponent {
    static propTypes = {
        arrivalDate: PropTypes.string,
        body: PropTypes.string,
        company: PropTypes.string,
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        contentType: PropTypes.string.isRequired,
        companyId: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.any).isRequired,
        exchangeName: PropTypes.string,
        filingId: PropTypes.string,
        formNumber: PropTypes.string,
        formShortName: PropTypes.string,
        handleContainerRef: PropTypes.func.isRequired,
        initialSearchTerm: PropTypes.string,
        isArchived: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        loadingDetails: PropTypes.bool,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        pdfUrl: PropTypes.string,
        periodEndDate: PropTypes.string,
        selectBlock: PropTypes.func.isRequired,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tags: PropTypes.arrayOf(PropTypes.any).isRequired,
        ticker: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        arrivalDate: null,
        body: undefined,
        company: null,
        companyId: undefined,
        exchangeName: undefined,
        filingId: null,
        formNumber: null,
        formShortName: null,
        initialSearchTerm: undefined,
        loadingDetails: false,
        minimized: false,
        passedStyles: {},
        pdfUrl: undefined,
        periodEndDate: null,
        setToolbarTitle: null,
        ticker: undefined
    };

    componentDidUpdate() {
        const { company, formShortName, setToolbarTitle } = this.props;

        if (setToolbarTitle && company && formShortName) {
            setToolbarTitle(`${company} - ${formShortName}`);
        }
    }

    renderFullText() {
        const {
            handleContainerRef,
            loadingDetails,
            body,
            formNumber,
            styles,
            passedStyles,
            contentType,
            isArchived,
            isRead,
            isStarred,
            filingId,
            tags,
            userTags
        } = this.props;
        if (loadingDetails && !body) {
            return (
                <Div styles={{ ...styles.containerLoading, ...passedStyles }}>
                    <Div styles={styles.loading}>
                        <Text size={3}>Loading...</Text>
                        <Div styles={styles.loadingBlocks}>
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                            <Div styles={styles.loadingBlock} />
                        </Div>
                    </Div>
                </Div>
            );
        }

        if (body) {
            return (
                <Div styles={styles.fullTextContainer} className="enable-quick-context" ref={handleContainerRef}>
                    <ContentActionBlock
                        contentType={contentType}
                        isArchived={isArchived}
                        isRead={isRead}
                        isStarred={isStarred}
                        tags={tags}
                        userTags={userTags}
                        targetId={filingId}
                        targetType="content"
                        styles={styles.actionBlock}
                    />
                    <RawHTML
                        className={`filing-form filing-form-${formNumber.replace(' ', '-')} raw-text`}
                        html={body}
                    />
                    {this.renderEvents()}
                </Div>
            );
        }

        return null;
    }

    renderEvents(restricted) {
        const { events, pathname, styles } = this.props;
        if (!events || !events.length) return null;

        return (
            <Div styles={styles.eventsContainer}>
                <Text weight="medium" styles={styles.eventsTitle}>
                    Related Events
                </Text>
                {events.map(event => (
                    <InternalLink
                        key={get(event, 'id')}
                        to={restricted ? undefined : generateTabURL({ pathname, eventId: get(event, 'id') })}
                    >
                        <Div styles={styles.event}>
                            <Text styles={styles.eventTitle}>{get(event, 'title')}</Text>
                            <Text styles={styles.eventDate}>{toDateString(get(event, 'callDate'))}</Text>
                        </Div>
                    </InternalLink>
                ))}
            </Div>
        );
    }

    render() {
        const {
            company,
            companyId,
            loadingDetails,
            initialSearchTerm,
            styles,
            body,
            passedStyles,
            containerRef,
            minimized,
            selectBlock,
            ticker,
            exchangeName,
            formShortName,
            periodEndDate,
            pdfUrl,
            filingId,
            formNumber,
            arrivalDate
        } = this.props;

        if (minimized) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <FilingHeader
                    arrivalDate={arrivalDate}
                    company={company}
                    companyId={companyId}
                    exchange={exchangeName}
                    formNumber={formNumber}
                    loading={loadingDetails}
                    periodEndDate={periodEndDate}
                    ticker={ticker}
                    title={formShortName}
                />
                {pdfUrl ? (
                    <PDFViewer
                        initialSearchTerm={initialSearchTerm}
                        pdfUrl={pdfUrl}
                        targetId={filingId}
                        targetType="content_filing"
                    />
                ) : (
                    <Div styles={styles.columns}>
                        <FilingSidebar selectBlock={selectBlock} body={body} containerRef={containerRef} />
                        {this.renderFullText()}
                    </Div>
                )}
            </Div>
        );
    }
}

export const FilingUI = compose(withStyleSheet(styleSheet))(Filing);
