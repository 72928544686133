import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { BookmarkTagsAutocomplete } from 'components/BookmarkTagsAutocomplete';
import { ColorMenu } from 'components/ColorMenu';
import { Div } from 'components/Div';
// import { Dropdown } from 'components/Dropdown';
import { EquityAutocomplete } from 'components/EquityAutocomplete';
import { FormRow } from 'components/FormRow';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Textarea } from 'components/Textarea';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class BookmarkForm extends PureComponent {
    static propTypes = {
        borderColor: PropTypes.string,
        colorOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        dashboardId: PropTypes.string,
        deleting: PropTypes.bool.isRequired,
        editing: PropTypes.bool.isRequired,
        equities: PropTypes.arrayOf(PropTypes.object),
        eventId: PropTypes.string,
        highlight: PropTypes.string,
        highlightColor: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onTaggedInputChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        /* reminderOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired, */
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        target: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        values: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        borderColor: undefined,
        dashboardId: undefined,
        equities: undefined,
        eventId: undefined,
        highlight: null,
        passedStyles: {},
        target: null
    };

    renderButtons() {
        const { deleting, editing, onClose, onDelete, onSubmit, styles, submitting, theme } = this.props;
        return (
            <Div styles={styles.buttonsContainer}>
                {editing && (
                    <ActionButton loading={deleting} onClick={onDelete} size={2} styles={styles.buttonDelete}>
                        <Icon type="trash02" color={theme.colors.gray04} />
                        <Text size={3}>Remove</Text>
                    </ActionButton>
                )}
                <ActionButton size={2} styles={styles.buttonCancel} onClick={onClose}>
                    <Text size={3}>Cancel</Text>
                </ActionButton>
                <ActionButton
                    styles={styles.buttonSubmit}
                    loading={submitting}
                    loaderColor={theme.colors.white01}
                    onClick={onSubmit}
                    size={2}
                >
                    <Text size={3}>{editing ? 'Update Highlight' : 'Create Highlight'}</Text>
                </ActionButton>
            </Div>
        );
    }

    renderForm() {
        const {
            borderColor,
            colorOptions,
            dashboardId,
            equities,
            eventId,
            highlight,
            highlightColor,
            onChange,
            onTaggedInputChange,
            // reminderOptions,
            styles,
            values
        } = this.props;
        const colorMenu = (
            <ColorMenu
                useHighlightColors
                colors={colorOptions}
                label="Highlight Color"
                name="highlightColor"
                onChange={onChange}
                styles={styles.colorMenu}
                value={highlightColor}
            />
        );
        return (
            <Div styles={styles.formContainer}>
                <FormRow description={colorMenu} styles={styles.formRow}>
                    <Div
                        styles={{
                            ...styles.blockQuote,
                            backgroundColor: highlightColor,
                            borderLeft: `3px solid ${borderColor}`
                        }}
                    >
                        <RawHTML html={highlight} />
                    </Div>
                </FormRow>
                <FormRow
                    description="Optional text providing context or opinion regarding the highlighted content"
                    label="Add note"
                    styles={styles.formRow}
                    forId="highlightNote"
                >
                    <Textarea
                        id="highlightNote"
                        styles={styles.textarea}
                        name="note"
                        placeholder="Enter note..."
                        onChange={onChange}
                        value={values.note}
                    />
                </FormRow>
                {/*
                <FormRow
                    description="Get an email reminder about this highlight"
                    label="Remind me"
                    styles={styles.formRow}
                >
                    <Dropdown
                        disableHoverOpen
                        name="reminder"
                        onChange={onChange}
                        options={reminderOptions}
                        styles={styles.dropdown}
                        value={values.reminder}
                    />
                </FormRow>
                */}
                <FormRow
                    forId="highlightEquityIds"
                    description="Associate with specific tickers"
                    label="Equities"
                    styles={styles.formRow}
                >
                    <EquityAutocomplete
                        equities={equities}
                        icon="search03"
                        multi
                        name="equityIds"
                        id="highlightEquityIds"
                        onChange={onChange}
                        placeholder="Search by ticker or name..."
                        useTags
                        value={values.equityIds}
                        wrapTags
                    />
                </FormRow>
                <FormRow
                    description="Organize your highlights into categories"
                    forId="highlightTags"
                    label="Tags"
                    styles={styles.formRowScrollable}
                >
                    <BookmarkTagsAutocomplete
                        dashboardId={dashboardId}
                        equityId={get(equities, '[0].value')}
                        eventId={eventId}
                        id="highlightTags"
                        maxSuggested={10}
                        multi
                        name="tags"
                        onChange={onChange}
                        onSearch={onTaggedInputChange}
                        placeholder="Press enter key to add tag"
                        styles={styles.bookmarkTags}
                        useTags
                        value={values.tags}
                        withSuggested
                        wrapTags
                    />
                </FormRow>
            </Div>
        );
    }

    renderModalTitle() {
        const { loading, styles, target } = this.props;
        if (loading || !target) return null;
        const { date, hasUnknownTime, title } = target;
        return (
            <Div styles={styles.modalHeader}>
                <Heading size={2}>{title}</Heading>
                <Text size={1} styles={styles.headerDate}>
                    {date.toString(hasUnknownTime ? 'MMM d, yyyy' : 'MMM d, yyyy, h:mmTT')}
                </Text>
            </Div>
        );
    }

    render() {
        const { highlight, loading, onClose, passedStyles, styles, target } = this.props;
        if (!loading && (!highlight || !target)) return null;
        return (
            <Modal isOpen onModalClose={onClose} styles={styles.modal} title={this.renderModalTitle()}>
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    {loading || !target ? (
                        <LoaderLogo styles={styles.loading} />
                    ) : (
                        <Fragment>
                            {this.renderForm()}
                            {this.renderButtons()}
                        </Fragment>
                    )}
                </Div>
            </Modal>
        );
    }
}

export const BookmarkFormUI = compose(withStyleSheet(styleSheet))(BookmarkForm);
