export const styleSheet = theme => ({
    actionBlock: {
        margin: `${theme.margins.margin01} ${theme.margins.margin02} 0`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`
        }
    },
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: theme.colors.white01,
        height: '100%',
        overflow: 'hidden'
    },
    containerLoading: {
        backgroundColor: theme.colors.white01,
        paddingBottom: theme.margins.margin03,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    content: {
        overflow: 'auto',
        height: '100%'
    },
    fullTextHeading: {
        padding: `0 ${theme.margins.margin02}`,
        lineHeight: '1.2em',
        fontFamily: theme.fonts.fontFamilyContent,
        fontWeight: theme.fonts.fontWeights.bold,
        marginBottom: theme.margins.margin00,
        span: {
            display: 'block',
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    backButton: {
        margin: `${theme.margins.margin02} 0 0 ${theme.margins.margin03}`,
        [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
            margin: `${theme.margins.margin01} 0 0 ${theme.margins.margin01}`
        }
    },
    documentContent: {
        padding: `0 ${theme.margins.margin02}`,
        marginTop: 24,
        h1: {
            fontWeight: theme.fonts.fontWeights.medium,
            fontSize: theme.fonts.fontSizes.size05
        },
        ul: {
            display: 'block',
            listStyleType: 'disc',
            marginTop: '1em',
            marginBottom: '1em',
            paddingLeft: theme.margins.margin02
        },
        li: {
            display: 'list-item'
        },
        strong: {
            fontWeight: theme.fonts.fontWeights.medium
        },
        p: {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        },
        '> *': {
            marginBottom: theme.margins.margin01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.5em'
        },
        span: {
            marginBottom: theme.margins.margin01,
            lineHeight: '1.5em',
            ':last-child': {
                marginBottom: 0
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    fullTextContainer: {
        padding: '28px 0 8px',
        wordBreak: 'break-word',
        '> *': {
            overflowWrap: 'break-word'
        },
        ...theme.content.annotated,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        },
        '.selectedBlock': {
            position: 'relative',
            '&:before': {
                content: '" "',
                display: 'block',
                whiteSpace: 'pre',
                position: 'absolute',
                left: -30,
                width: 2,
                backgroundColor: theme.colors.blue08,
                height: '100%',
                zIndex: 1
            }
        },
        '.searchTerm': {
            backgroundColor: theme.colors.blue08,
            color: theme.colors.white01
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 0
        }
    },
    loading: {
        padding: theme.margins.margin03,
        color: theme.colors.gray02
    },
    loadingBlock: {
        height: '24px',
        marginBottom: '10px',
        backgroundColor: theme.colors.gray03
    },
    loadingBlocks: {
        width: '80%',
        paddingTop: theme.margins.margin01,
        '> div:last-child': {
            marginRight: theme.margins.margin03
        }
    },
    categories: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    category: {
        padding: '4px 6px',
        marginRight: 4,
        marginTop: 6,
        borderRadius: 6,
        letterSpacing: 1,
        backgroundColor: theme.colors.gray01
    },
    categoryContainer: {
        marginTop: theme.margins.margin01,
        marginBottom: 5
    },
    catLabel: {
        color: theme.colors.gray04,
        letterSpacing: 2
    },
    ticker: {
        color: theme.colors.orange04,
        letterSpacing: '1px'
    },
    sourceDate: {
        padding: `0 ${theme.margins.margin02}`,
        marginTop: 3,
        display: 'flex',
        a: {
            fontSize: theme.fonts.fontSizes.size01,
            marginRight: 3
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    source: {
        marginRight: 3
    },
    publishDate: {
        color: theme.colors.gray06
    },
    companyInfo: {
        lineHeight: '12px',
        padding: `0 ${theme.margins.margin02}`,
        a: {
            display: 'inline-flex'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    companies: {
        padding: `0 ${theme.margins.margin02}`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            padding: `0 ${theme.margins.margin01}`
        }
    },
    companyLink: {
        backgroundColor: theme.colors.white01,
        a: {
            textDecoration: 'none',
            color: theme.colors.black01
        },
        ':hover': {
            a: {
                textDecoration: 'none'
            }
        }
    },
    company: {
        color: theme.colors.gray06,
        marginLeft: 8,
        marginBottom: 18
    },
    spacer: {
        flex: 1
    }
});
