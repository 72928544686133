import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTrackUserActivity } from 'graphql/user';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';
import { ResearchUI } from './ui';

export class Research extends PureComponent {
    static displayName = 'ResearchContainer';

    static propTypes = {
        page: PropTypes.string,
        pdfUrl: PropTypes.string,
        researchId: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string,
        researchLoading: PropTypes.bool
    };

    static defaultProps = {
        page: undefined,
        pdfUrl: undefined,
        researchId: undefined,
        setToolbarTitle: null,
        styles: undefined,
        title: 'Loading...',
        researchLoading: false
    };

    constructor(props) {
        super(props);

        this.containerRef = createRef();
        this.documentTitle = document.title;
    }

    componentDidUpdate({ title: prevTitle }) {
        const { title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | PDF | ${title}`;
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    render() {
        const { researchLoading, researchId, pdfUrl, setToolbarTitle, styles, title, page } = this.props;
        const initialSearchTerm = typeof page === 'string' ? page.split(',').join('|') : undefined;

        return (
            <ResearchUI
                initialSearchTerm={initialSearchTerm}
                pdfUrl={pdfUrl}
                containerRef={this.containerRef}
                researchId={researchId}
                setToolbarTitle={setToolbarTitle}
                styles={styles}
                title={title}
                researchLoading={researchLoading}
            />
        );
    }
}

export const ResearchContainer = compose(withUrlContext(['page']), withTrackUserActivity(), withData())(Research);
