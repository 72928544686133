import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose } from 'recompose';
import { get } from 'utils';
import { withUrlContext } from 'hoc/url';
import { CustomCardUI } from './ui';

function formatRecord(fields) {
    return fields.reduce(
        (record, field) => ({
            ...record,
            [field.name]: field.formatted || field.value
        }),
        {}
    );
}

export class CustomCard extends PureComponent {
    static displayName = 'CustomCardContainer';

    static propTypes = {
        dataRecordId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        record: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        template: PropTypes.objectOf(PropTypes.any),
        templateLoading: PropTypes.bool,
        templateType: PropTypes.string
    };

    static defaultProps = {
        dataRecordId: null,
        record: null,
        streamId: null,
        styles: undefined,
        template: null,
        templateType: null,
        templateLoading: null
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.formatRecord = memoize(formatRecord);

        this.state = {
            showSubContent: false
        };
    }

    handleClick(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState(({ showSubContent }) => ({
            showSubContent: !showSubContent
        }));
    }

    render() {
        const {
            dataRecordId,
            pathname,
            record,
            streamId,
            styles,
            template,
            templateLoading,
            templateType
        } = this.props;
        const { showSubContent } = this.state;
        return (
            <CustomCardUI
                onClick={this.handleClick}
                equity={get(record, 'equity')}
                pathname={pathname}
                record={this.formatRecord(get(record, 'record'))}
                dataRecordId={dataRecordId}
                showSubContent={showSubContent}
                streamId={streamId}
                styles={styles}
                template={template}
                templateType={templateType}
                templateLoading={templateLoading}
            />
        );
    }
}

export const CustomCardContainer = compose(withUrlContext(['pathname']))(CustomCard);
