import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTrackUserActivity } from 'graphql/user';
import { withUrlContext } from 'hoc/url';
import { withData } from './data';
import { DocumentUI } from './ui';

export class Document extends PureComponent {
    static displayName = 'DocumentContainer';

    static propTypes = {
        page: PropTypes.string,
        pdfUrl: PropTypes.string,
        documentId: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string,
        documentLoading: PropTypes.bool
    };

    static defaultProps = {
        page: undefined,
        pdfUrl: undefined,
        documentId: undefined,
        setToolbarTitle: null,
        styles: undefined,
        title: 'Loading...',
        documentLoading: false
    };

    constructor(props) {
        super(props);

        this.containerRef = createRef();
        this.documentTitle = document.title;
    }

    componentDidUpdate({ title: prevTitle }) {
        const { title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | PDF | ${title}`;
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    render() {
        const { documentLoading, documentId, pdfUrl, setToolbarTitle, styles, title, page } = this.props;
        const initialSearchTerm = typeof page === 'string' ? page.split(',').join('|') : undefined;

        return (
            <DocumentUI
                initialSearchTerm={initialSearchTerm}
                pdfUrl={pdfUrl}
                containerRef={this.containerRef}
                documentId={documentId}
                setToolbarTitle={setToolbarTitle}
                styles={styles}
                title={title}
                documentLoading={documentLoading}
            />
        );
    }
}

export const DocumentContainer = compose(withUrlContext(['page']), withTrackUserActivity(), withData())(Document);
