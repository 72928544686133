import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import pick from 'lodash/pick';
import XDate from 'xdate';
import { compose, withPropsOnChange, withStateHandlers } from 'recompose';
import { withGetUser } from 'graphql/user';
import { withDomainQuality } from 'graphql/utils';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { generateTabURL, get, validateUrl } from 'utils';
import {
    CONNECTION_TYPES,
    OPTION_INPUT_NAMES_MAP,
    PARTICIPATION_TYPES,
    SCHEDULE_TYPES,
    TROUBLESHOOTING_TYPES,
    ZOOM_MEETING_TYPES
} from './consts';
import { withData } from './data';
import { PrivateRecordingFormUI } from './ui';

// Map validated input names to error hints displayed above the submit button
const INPUT_FIELD_LABELS = {
    audioUpload: 'File',
    confirmPermission: 'Agent intervention permission checkbox',
    connectAccessId: 'Meeting ID',
    connectionType: 'Type of connection',
    connectPhoneNumber: 'Dial-in number',
    connectPin: 'PIN',
    connectUrl: 'URL',
    localeCode: 'Language',
    onConnectDialNumber: 'Your phone number',
    onFailurePhoneNumber: 'Best number to reach you',
    participationType: 'How we should connect',
    scheduleTime: 'Schedule time',
    title: 'Title'
};

// These fields are always included with the mutations regardless of the selected options
const PERSISTED_PRIVATE_RECORDING_FIELDS = [
    'connectionType',
    'connectOffsetSeconds',
    'equityIds',
    'eventGroupId',
    'localeCode',
    'onCompleteEmailCreator',
    'scheduledFor',
    'tags',
    'title'
];

// Converts time to 24 hours
function convertTime(time, meridiem) {
    let converted = null;
    if (time && meridiem) {
        const minutes = time.slice(-2);
        let hour = parseInt(time.slice(0, time.length > 3 ? 2 : 1), 0);
        if (hour < 12 && meridiem === 'PM') {
            hour += 12;
        } else if (hour === 12 && meridiem === 'AM') {
            hour = 0;
        }
        converted = `${hour < 10 ? `0${hour}` : hour}:${minutes}:00`;
    }
    return converted;
}

function getConnectCallerId(connectCallerId, orgPrefs) {
    let callerId = connectCallerId;
    if (orgPrefs) {
        const privateRecordingPrefs = orgPrefs.find(
            ({ preferenceName, preferenceType }) =>
                preferenceName === 'settings' && preferenceType === 'private_recording'
        );
        if (privateRecordingPrefs) {
            const callerIdOverride = get(privateRecordingPrefs, 'preferenceData.caller_id');
            if (callerIdOverride && callerIdOverride !== callerId) {
                callerId = callerIdOverride;
            }
        }
    }
    return callerId;
}

function getErrorHints(errors) {
    const hints = {};
    if (Object.keys(errors).length) {
        Object.keys(errors).forEach(key => {
            const value = errors[key];
            const label = INPUT_FIELD_LABELS[key];
            if (hints[value]) {
                hints[value].push(label);
            } else {
                hints[value] = [label];
            }
        });
    }
    return hints;
}

function hasFutureDatetime(scheduleType, scheduleDate, scheduleTime, scheduleMeridiem) {
    let isFuture = false;
    if (scheduleType === SCHEDULE_TYPES.now.value) {
        isFuture = true;
    } else if (scheduleDate && scheduleTime && scheduleMeridiem) {
        const date = new XDate(scheduleDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const time = convertTime(scheduleTime, scheduleMeridiem);
        const timeParts = time.split(':');
        const hours = timeParts[0];
        const minutes = timeParts[1];
        isFuture = new XDate(year, month, day, hours, minutes, 0, 0).getTime() > new XDate().getTime();
    }
    return isFuture;
}

function isFormSubmittable(errors, hasChanges, reconnecting, submitting, title) {
    return !reconnecting && !submitting && hasChanges && Object.keys(errors).length === 0 && !!title;
}

function isRetryable(privateRecording, connectionType, scheduleType) {
    return (
        !!privateRecording &&
        connectionType !== CONNECTION_TYPES.audioStream.value &&
        scheduleType === SCHEDULE_TYPES.now.value
    );
}

function mapOnFailureToState(onFailureProp, onFailureDialNumber, onFailureSmsNumber) {
    let onFailure = onFailureProp || TROUBLESHOOTING_TYPES.none.value;
    let onFailurePhoneNumber;
    if (onFailure === 'manual_intervention') {
        if (onFailureDialNumber) {
            onFailure = TROUBLESHOOTING_TYPES.call.value;
            onFailurePhoneNumber = onFailureDialNumber;
        }
        if (onFailureSmsNumber) {
            onFailure = TROUBLESHOOTING_TYPES.sms.value;
            onFailurePhoneNumber = onFailureSmsNumber;
        }
    }
    return {
        onFailure,
        onFailurePhoneNumber
    };
}

function mapScheduledForToState(scheduledFor) {
    const scheduleDate = scheduledFor ? new Date(scheduledFor) : new Date();
    return {
        scheduleDate,
        scheduleMeridiem: scheduledFor ? new XDate(scheduleDate).toString('TT') : 'AM',
        scheduleTime: scheduledFor ? new XDate(scheduleDate).toString('hh:mm') : '',
        scheduleType: scheduledFor ? SCHEDULE_TYPES.future.value : ''
    };
}

function mapStateToScheduledFor({ scheduleDate, scheduleMeridiem, scheduleTime, scheduleType }) {
    let dateTime = new XDate(scheduleType === SCHEDULE_TYPES.now.value ? new Date() : scheduleDate || new Date());
    if (scheduleType === SCHEDULE_TYPES.future.value && scheduleTime) {
        const convertedTime = convertTime(scheduleTime, scheduleMeridiem);
        // Combine the date and time
        dateTime = new XDate(`${dateTime.toString('yyyy-MM-dd')}T${convertedTime}`);
    }
    return dateTime.toISOString();
}

export class PrivateRecordingForm extends PureComponent {
    static displayName = 'PrivateRecordingFormContainer';

    static propTypes = {
        createPrivateRecording: PropTypes.func.isRequired,
        deletePrivateRecording: PropTypes.func.isRequired,
        domainScore: PropTypes.number,
        equities: PropTypes.arrayOf(PropTypes.object),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        organizationName: PropTypes.string,
        organizationPreferences: PropTypes.arrayOf(PropTypes.object),
        pathname: PropTypes.string.isRequired,
        privateRecording: PropTypes.objectOf(PropTypes.any),
        privateRecordingId: PropTypes.string,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        setUrl: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        updatePrivateRecording: PropTypes.func.isRequired
    };

    static defaultProps = {
        domainScore: undefined,
        equities: undefined,
        loading: false,
        organizationName: undefined,
        organizationPreferences: undefined,
        privateRecording: undefined,
        privateRecordingId: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.getComponentVisibility = this.getComponentVisibility.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.getConnectCallerId = memoize(getConnectCallerId);
        this.getErrorHints = memoize(getErrorHints);
        this.hasFutureDatetime = memoize(hasFutureDatetime);
        this.isFormSubmittable = memoize(isFormSubmittable);
        this.isRetryable = memoize(isRetryable);
        this.mapOnFailureToState = memoize(mapOnFailureToState);
        this.mapScheduledForToState = memoize(mapScheduledForToState);
        this.mapStateToScheduledFor = memoize(mapStateToScheduledFor);

        this.state = {
            deleting: false,
            errors: {},
            file: null,
            hasChanges: false,
            reconnecting: false,
            submitting: false,
            taggedInput: '',
            touched: {},
            ...this.mapPrivateRecordingToState()
        };
    }

    componentDidMount() {
        const { connectUrl } = this.state;
        const { setUrl } = this.props;
        // Set url in state handlers to trigger domain quality check
        if (connectUrl && setUrl) {
            setUrl(connectUrl);
        }
    }

    componentDidUpdate({ organizationPreferences: prevOrgPrefs, privateRecording: prevRecording }) {
        const { loading, organizationPreferences, privateRecording } = this.props;
        if (!loading && ((!prevRecording && privateRecording) || (!prevOrgPrefs && organizationPreferences))) {
            this.setState(
                {
                    ...this.mapPrivateRecordingToState()
                },
                () => {
                    const { connectUrl } = this.state;
                    const { setUrl } = this.props;
                    // Set url in state handlers to trigger domain quality check
                    if (connectUrl && setUrl) {
                        setUrl(connectUrl);
                    }
                }
            );
        }
    }

    getComponentVisibility(name) {
        const { connectionType, file, meetingType, onFailure, participationType, scheduleType } = this.state;
        const canSchedule =
            connectionType !== CONNECTION_TYPES.audioUpload.value &&
            (!!participationType ||
                connectionType === CONNECTION_TYPES.webcast.value ||
                connectionType === CONNECTION_TYPES.audioStream.value ||
                (connectionType === CONNECTION_TYPES.zoom.value && meetingType === ZOOM_MEETING_TYPES.web.value));
        if (name === 'scheduling') {
            return !!connectionType && canSchedule;
        }
        if (name === 'troubleshooting') {
            return (
                !!connectionType &&
                connectionType !== CONNECTION_TYPES.audioStream.value &&
                canSchedule &&
                !!scheduleType
            );
        }
        if (name === 'recordingDetails') {
            return (
                (connectionType === CONNECTION_TYPES.audioUpload.value && !!file) ||
                (!!connectionType &&
                    canSchedule &&
                    !!scheduleType &&
                    (!!onFailure || participationType === PARTICIPATION_TYPES.participating.value))
            );
        }
        return false;
    }

    mapPrivateRecordingToState() {
        const { organizationPreferences, privateRecording, privateRecordingId } = this.props;
        const connectUrl = get(privateRecording, 'connectUrl', '');
        const eventGroup = get(privateRecording, 'eventGroups[0]');
        const onConnectDialNumber = get(privateRecording, 'onConnectDialNumber', '');
        const onFailure = get(privateRecording, 'onFailure');
        const onFailureDialNumber = get(privateRecording, 'onFailureDialNumber');
        const onFailureSmsNumber = get(privateRecording, 'onFailureSmsNumber');
        const scheduledFor = get(privateRecording, 'scheduledFor');
        const fileUpload = get(privateRecording, 'fileUpload');
        let file = null;
        let participationType = '';
        if (privateRecording) {
            participationType = onConnectDialNumber
                ? PARTICIPATION_TYPES.participating.value
                : PARTICIPATION_TYPES.notParticipating.value;
        }
        if (fileUpload) {
            file = {
                id: fileUpload.id,
                name: fileUpload.rawFilename
            };
        }
        return {
            confirmPermission: !!privateRecording,
            connectAccessId: get(privateRecording, 'connectAccessId', ''),
            connectCallerId: this.getConnectCallerId(
                get(privateRecording, 'connectCallerId', ''),
                organizationPreferences
            ),
            connectionType: get(privateRecording, 'connectionType'),
            connectOffsetSeconds: get(privateRecording, 'connectOffsetSeconds', 0),
            connectPhoneNumber: get(privateRecording, 'connectPhoneNumber', ''),
            connectPin: get(privateRecording, 'connectPin', ''),
            connectUrl,
            equityIds: get(privateRecording, 'equityIds', []).map(id => `${id}`),
            eventGroupId: get(eventGroup, 'id'),
            eventGroupTitle: get(eventGroup, 'title'),
            externalAudioStreamUrl: get(privateRecording, 'externalAudioStreamUrl', ''),
            file,
            localeCode: get(privateRecording, 'localeCode'),
            meetingType:
                privateRecordingId !== 'new' && connectUrl.length
                    ? ZOOM_MEETING_TYPES.web.value
                    : ZOOM_MEETING_TYPES.phone.value,
            onCompleteEmailCreator: get(privateRecording, 'onCompleteEmailCreator', true),
            onConnectDialNumber,
            onFailureInstructions: get(privateRecording, 'onFailureInstructions', ''),
            participationType,
            scheduledFor,
            smsAlertBeforeCall: get(privateRecording, 'smsAlertBeforeCall', false),
            tags: get(privateRecording, 'tags', []),
            title: get(privateRecording, 'title', ''),
            useOnConnectDialNumber: [onFailureDialNumber, onFailureSmsNumber].includes(onConnectDialNumber),
            ...this.mapOnFailureToState(onFailure, onFailureDialNumber, onFailureSmsNumber),
            ...this.mapScheduledForToState(scheduledFor)
        };
    }

    onBlur({ name, value }) {
        this.setState({
            ...this.validateInput({ name, value })
        });
    }

    onChange({ name, value: newValue }) {
        this.setState(
            state => {
                const newState = {
                    ...state,
                    hasChanges: true
                };
                const value = Array.isArray(newValue) ? newValue[0] : newValue;
                switch (name) {
                    case 'confirmPermission':
                        newState.confirmPermission = !state.confirmPermission;
                        break;
                    case 'connectionType':
                        // Reset onFailure if switching connection types
                        if (state.connectionType !== value) {
                            newState.onFailure = TROUBLESHOOTING_TYPES.none.value;
                        }
                        newState.connectionType = value;
                        break;
                    case 'equityIds':
                        newState.equityIds = newValue; // equityIds is an array
                        break;
                    case 'meetingType':
                        // Reset onFailure if switching to phone meeting type
                        if (value === ZOOM_MEETING_TYPES.phone.value) {
                            newState.onFailure = TROUBLESHOOTING_TYPES.none.value;
                        }
                        newState.meetingType = value;
                        break;
                    case 'onCompleteEmailCreator':
                        newState.onCompleteEmailCreator = !state.onCompleteEmailCreator;
                        break;
                    case 'onConnectDialNumber':
                        newState.onConnectDialNumber = value;
                        // Also update onFailurePhoneNumber if useOnConnectDialNumber is checked off
                        if (state.useOnConnectDialNumber) {
                            newState.onFailurePhoneNumber = value;
                        }
                        break;
                    case 'onFailurePhoneNumber':
                        newState.onFailurePhoneNumber = value;
                        newState.useOnConnectDialNumber = false;
                        break;
                    case 'scheduleDate':
                        newState.scheduleDate = new Date(value);
                        break;
                    case 'tags':
                        newState.tags = newValue; // tags is an array
                        newState.taggedInput = ''; // clear the tagged input text when the tags change
                        break;
                    case 'useOnConnectDialNumber':
                        // If useOnConnectDialNumber is checked off, use the onConnectDialNumber value
                        // otherwise, use onFailurePhoneNumber
                        newState.onFailurePhoneNumber = value ? state.onConnectDialNumber : state.onFailurePhoneNumber;
                        newState.useOnConnectDialNumber = value;
                        break;
                    default:
                        newState[name] = value;
                }
                return {
                    ...newState,
                    ...this.validateInput({ name, value })
                };
            },
            () => {
                // If connectUrl is set and valid, update url prop in state handlers to trigger withDomainQuality query
                const { connectUrl, errors } = this.state;
                const { setUrl } = this.props;
                if (name === 'connectUrl' && connectUrl && !errors.connectUrl) {
                    setUrl(connectUrl);
                }
            }
        );
    }

    onClose() {
        const { hasChanges } = this.state;
        const { onClose } = this.props;
        if (
            !hasChanges ||
            // eslint-disable-next-line no-alert
            (hasChanges && window.confirm('You have unsaved changes. Are you sure you want to cancel?'))
        ) {
            onClose();
        }
    }

    onDelete() {
        const { deletePrivateRecording, history, pathname, privateRecordingId } = this.props;
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to delete this recording?')) {
            this.setState({ deleting: true }, () => {
                deletePrivateRecording(privateRecordingId)
                    .then(() => {
                        this.setState({ deleting: false }, () => {
                            history.push({ pathname });
                        });
                    })
                    .catch(() => {
                        this.setState({ deleting: false });
                    });
            });
        }
    }

    onFileDelete() {
        this.setState({
            file: null,
            ...this.validateInput({ name: 'audioUpload', value: null })
        });
    }

    onFileUpload({ error, fileId, fileName, fileUrl }) {
        if (fileId && fileName && fileUrl) {
            const file = {
                id: fileId,
                name: fileName,
                url: fileUrl
            };
            this.setState({
                file,
                ...this.validateInput({ name: 'audioUpload', value: file })
            });
        }
        if (error) {
            const { errors } = this.state;
            this.setState({
                errors: {
                    ...errors,
                    audioUpload: error
                }
            });
        }
    }

    onFocus({ name }) {
        this.setState(({ touched }) => ({
            touched: {
                ...touched,
                [name]: true
            }
        }));
    }

    onSubmit(retry = false) {
        const {
            connectCallerId,
            connectionType,
            errors,
            file,
            meetingType,
            onConnectDialNumber,
            onFailure,
            onFailureInstructions,
            onFailurePhoneNumber: onFailurePhoneNumberState,
            participationType,
            taggedInput,
            useOnConnectDialNumber
        } = this.state;
        const {
            createPrivateRecording,
            history,
            onClose,
            organizationName,
            pathname,
            privateRecording,
            reporter,
            updatePrivateRecording
        } = this.props;
        const onFailurePhoneNumber = useOnConnectDialNumber ? onConnectDialNumber : onFailurePhoneNumberState;
        if (Object.keys(errors).length === 0) {
            const input = pick(this.state, [
                ...PERSISTED_PRIVATE_RECORDING_FIELDS,
                ...(OPTION_INPUT_NAMES_MAP[connectionType] || []),
                ...(OPTION_INPUT_NAMES_MAP[participationType] || [])
            ]);
            // Pass eventGroupId as an array
            input.eventGroupIds = input.eventGroupId ? [input.eventGroupId] : [];
            if (connectCallerId) input.connectCallerId = connectCallerId;
            // Remove non-digits from connectAccessId & connectPin
            if (input.connectAccessId) input.connectAccessId = input.connectAccessId.replace(/\D/g, '');
            if (input.connectPin) input.connectPin = input.connectPin.replace(/\D/g, '');
            // Sanitize connectUrl
            if (input.connectUrl) {
                const urlMatches = validateUrl(input.connectUrl);
                input.connectUrl = urlMatches ? urlMatches[0] : input.connectUrl;
            }
            // Map the Schedule date and time state to the scheduledFor field
            input.scheduledFor = this.mapStateToScheduledFor(this.state);
            // Map onFailurePhoneNumber to either onFailureDialNumber or onFailureSmsNumber based on selected onFailure
            if (onFailure === TROUBLESHOOTING_TYPES.aieraIntervention.value) {
                input.onFailure = 'aiera_intervention';
                input.onFailureInstructions = onFailureInstructions;
            }
            if (onFailure === TROUBLESHOOTING_TYPES.sms.value) {
                input.onFailure = 'manual_intervention';
                input.onFailureSmsNumber = onFailurePhoneNumber;
            }
            if (onFailure === TROUBLESHOOTING_TYPES.call.value) {
                input.onFailure = 'manual_intervention';
                input.onFailureDialNumber = onFailurePhoneNumber;
            }
            if (onFailure === TROUBLESHOOTING_TYPES.none.value) {
                input.onFailure = 'none';
            }
            // Append text in the RecordingDetails tagged input to tags
            if (taggedInput && taggedInput.length > 0) {
                input.tags = [...new Set([...input.tags, taggedInput])];
            }
            // Set connect input values based on selected Zoom meeting type
            if (connectionType === CONNECTION_TYPES.zoom.value) {
                if (meetingType === ZOOM_MEETING_TYPES.web.value) {
                    delete input.connectAccessId;
                    delete input.connectPhoneNumber;
                    // Default to user's org's name if caller ID input is blank
                    input.connectCallerId = input.connectCallerId || organizationName;
                } else if (meetingType === ZOOM_MEETING_TYPES.phone.value) {
                    delete input.connectUrl;
                }
            }
            // Remove other connection fields when the type is audio stream
            if (connectionType === CONNECTION_TYPES.audioStream.value) {
                delete input.connectAccessId;
                delete input.connectPhoneNumber;
                delete input.connectUrl;
            }
            // Send the url and file uuid if uploading an audio file
            if (file) {
                input.audioUrl = file.url;
                input.fileUploadUuid = file.id;
            }
            const submittingKey = retry ? 'reconnecting' : 'submitting';
            this.setState({ [submittingKey]: true }, () => {
                (privateRecording ? updatePrivateRecording(input, retry) : createPrivateRecording(input))
                    .then(newPrivateRecording => {
                        this.setState({ [submittingKey]: false }, () => {
                            // Store phone numbers in local storage to allow users to reuse
                            if (input.connectPhoneNumber) {
                                this.setLocalStorage('connectPhoneNumber', input.connectPhoneNumber);
                            }
                            if (input.onConnectDialNumber) {
                                this.setLocalStorage('onConnectDialNumber', input.onConnectDialNumber);
                            }
                            if (input.onFailureDialNumber) {
                                // Use onFailurePhoneNumber for the storage key because that's the input name in the UI
                                this.setLocalStorage('onFailurePhoneNumber', input.onFailureDialNumber);
                            }
                            if (input.onFailureSmsNumber) {
                                // Use onFailurePhoneNumber for the storage key because that's the input name in the UI
                                this.setLocalStorage('onFailurePhoneNumber', input.onFailureSmsNumber);
                            }
                            if (newPrivateRecording) {
                                history.push(generateTabURL({ pathname, eventId: newPrivateRecording.id }));
                            } else {
                                onClose();
                            }

                            // Track new / updated private recording
                            reporter.track(
                                reporter.actions.submit,
                                privateRecording
                                    ? reporter.objects.privateRecordingUpdate
                                    : reporter.objects.privateRecordingCreate,
                                {
                                    component: 'PrivateRecordingForm'
                                }
                            );
                        });
                    })
                    .catch(() => {
                        this.setState({ [submittingKey]: false });
                    });
            });
        }
    }

    setLocalStorage(name, value) {
        localStorage.setItem(`aiera:privateRecording:${name}`, value);
    }

    validateInput({ name, value }) {
        const {
            confirmPermission,
            connectAccessId,
            connectionType,
            connectPhoneNumber,
            connectPin,
            connectUrl,
            errors: errorsState,
            externalAudioStreamUrl,
            file,
            meetingType,
            onConnectDialNumber,
            onFailure,
            onFailurePhoneNumber,
            participationType,
            scheduleTime,
            scheduleType,
            useOnConnectDialNumber
        } = this.state;
        const { privateRecordingId } = this.props;
        const errors = { ...errorsState };
        const hasValue = typeof value === 'string' ? value.trim().length > 0 : !!value;
        const trimmedValue = typeof value === 'string' ? value.replace(/\s/g, '') : value;
        if (['connectPhoneNumber', 'localeCode', 'title'].includes(name)) {
            if (!hasValue) {
                errors[name] = 'Required';
            } else if (errors[name]) {
                delete errors[name];
            }
        }
        if (name === 'audioUpload') {
            if (value && errors.audioUpload) {
                delete errors.audioUpload;
            } else {
                errors.audioUpload = 'Required';
            }
        }
        if (name === 'confirmPermission') {
            if (value && errors.confirmPermission) {
                delete errors.confirmPermission;
            } else {
                errors.confirmPermission = 'Required';
            }
        }
        if (name === 'connectAccessId') {
            if (!hasValue && connectionType === CONNECTION_TYPES.zoom.value) {
                errors.connectAccessId = 'Required';
            } else if (hasValue && !/^[\d#]+$/.test(trimmedValue)) {
                errors.connectAccessId = 'Must only contain numbers or #';
            } else if (errors.connectAccessId) {
                delete errors.connectAccessId;
            }
        }
        // Add errors for onConnectDialNumber and onFailurePhoneNumber
        // if either of them are the same as connectPhoneNumber
        if (name === 'connectPhoneNumber') {
            if (hasValue && value === onConnectDialNumber) {
                errors.onConnectDialNumber = 'Cannot be the same as the "Dial-in number"';
            } else if (errors.onConnectDialNumber === 'Cannot be the same as the "Dial-in number"') {
                delete errors.onConnectDialNumber;
            }
            if (hasValue && value === onFailurePhoneNumber) {
                errors.onFailurePhoneNumber = 'Cannot be the same as the "Dial-in number"';
            } else if (errors.onFailurePhoneNumber === 'Cannot be the same as the "Dial-in number"') {
                delete errors.onFailurePhoneNumber;
            }
        }
        // Allow digit-only PINs for phone-based connection types
        // Exclude Zoom web urls
        if (name === 'connectPin') {
            if (
                !errors.connectPin &&
                hasValue &&
                meetingType !== ZOOM_MEETING_TYPES.web.value &&
                !/^[\d#]+$/.test(trimmedValue)
            ) {
                errors.connectPin = 'Must only contain numbers or #';
            } else if (errors.connectPin && (!hasValue || (hasValue && /^\d+$/.test(trimmedValue)))) {
                delete errors.connectPin;
            }
        }
        if (name === 'connectionType') {
            // connectPhoneNumber is only required for Google Meet and phone number types
            if (
                [CONNECTION_TYPES.googleMeet.value, CONNECTION_TYPES.phoneNumber.value].includes(value) &&
                !connectPhoneNumber &&
                !errors.connectPhoneNumber
            ) {
                errors.connectPhoneNumber = 'Required';
            }
            if (value === CONNECTION_TYPES.webcast.value) {
                if (!connectUrl) {
                    errors.connectUrl = 'Required';
                } else {
                    const validUrl = validateUrl(connectUrl);
                    if (!validUrl) {
                        errors.connectUrl = 'Must be a valid url starting with http or https';
                    }
                    if (validUrl && errors.connectUrl) {
                        delete errors.connectUrl;
                    }
                }
                // We don't collect connectPhoneNumber for webcasts
                if (errors.connectPhoneNumber) {
                    delete errors.connectPhoneNumber;
                }
                // No phone intervention for webcasts
                if (errors.onFailurePhoneNumber) {
                    delete errors.onFailurePhoneNumber;
                }
            }
            if (value === CONNECTION_TYPES.audioStream.value) {
                if (!externalAudioStreamUrl) {
                    errors.externalAudioStreamUrl = 'Required';
                } else {
                    const validUrl = validateUrl(externalAudioStreamUrl);
                    if (!validUrl) {
                        errors.externalAudioStreamUrl = 'Must be a valid url starting with http or https';
                    }
                    if (validUrl && errors.externalAudioStreamUrl) {
                        delete errors.externalAudioStreamUrl;
                    }
                }
                // We don't collect connectPhoneNumber for audio streams
                if (errors.connectPhoneNumber) {
                    delete errors.connectPhoneNumber;
                }
                // No phone intervention for audio streams
                if (errors.onFailurePhoneNumber) {
                    delete errors.onFailurePhoneNumber;
                }
            } else if (errors.externalAudioStreamUrl) {
                delete errors.externalAudioStreamUrl;
            }
            if (value === CONNECTION_TYPES.zoom.value) {
                if (!connectAccessId && !errors.connectAccessId) {
                    errors.connectAccessId = 'Required';
                }
                // We require connectUrl for "web" Zoom meeting type
                // We don't require connectAccessId & connectPhoneNumber
                if (meetingType === ZOOM_MEETING_TYPES.web.value) {
                    if (!connectUrl && !errors.connectUrl) {
                        errors.connectUrl = 'Required';
                    }
                    if (errors.connectAccessId) {
                        delete errors.connectAccessId;
                    }
                    if (errors.connectPhoneNumber) {
                        delete errors.connectPhoneNumber;
                    }
                }
                // We require connectAccessId & connectPhoneNumber for "phone" Zoom meeting type
                // We don't require connectUrl
                if (meetingType === ZOOM_MEETING_TYPES.phone.value) {
                    if (!connectAccessId && !errors.connectAccessId) {
                        errors.connectAccessId = 'Required';
                    }
                    if (!connectPhoneNumber && !errors.connectPhoneNumber) {
                        errors.connectPhoneNumber = 'Required';
                    }
                    if (errors.connectUrl) {
                        delete errors.connectUrl;
                    }
                }
            }
            // Only Zoom requires a connectAccessId, so remove the error if the connection type changes
            if (value !== CONNECTION_TYPES.zoom.value && errors.connectAccessId) {
                delete errors.connectAccessId;
            }
            // Only Zoom (web meeting type only) and Webcast require a connectUrl,
            // so remove the error if the connection type changes to something else
            if (![CONNECTION_TYPES.webcast.value, CONNECTION_TYPES.zoom.value].includes(value) && errors.connectUrl) {
                delete errors.connectUrl;
            }
            // Add onConnectDialNumber error if selecting Google Meet, Zoom phone, or phone number connection types,
            // "Call me" option is selected,
            // and onConnectDialNumber isn't set.
            // Otherwise, delete the onConnectDialNumber error if it's set and the above conditions aren't met
            if (
                [CONNECTION_TYPES.googleMeet.value, CONNECTION_TYPES.phoneNumber.value].includes(value) ||
                (value === CONNECTION_TYPES.zoom.value && meetingType === ZOOM_MEETING_TYPES.phone)
            ) {
                if (participationType === PARTICIPATION_TYPES.participating.value && !onConnectDialNumber) {
                    errors.onConnectDialNumber = 'Required when selecting "Call me" option';
                } else if (errors.onConnectDialNumber) {
                    delete errors.onConnectDialNumber;
                }
            } else if (errors.onConnectDialNumber) {
                delete errors.onConnectDialNumber;
            }
            // Remove "Aiera Intervention" permission checkbox error if switching connection types
            if (value !== connectionType && errors.confirmPermission) {
                delete errors.confirmPermission;
            }
            // For uploading audio files, add required error if no file is in state
            if (value === CONNECTION_TYPES.audioUpload.value) {
                if (!file && !errors.audioUpload) {
                    errors.audioUpload = 'Required';
                }
                // Remove any other errors because the audio upload
                // only validates the file and recording title
                const nonAudioFileErrorKeys = Object.keys(errors);
                if (nonAudioFileErrorKeys.length) {
                    nonAudioFileErrorKeys
                        .filter(eK => !['audioUpload', 'title'].includes(eK))
                        .forEach(errorKey => {
                            delete errors[errorKey];
                        });
                }
            } else if (errors.audioUpload) {
                delete errors.audioUpload;
            }
        }
        if (name === 'connectUrl') {
            if (!hasValue) {
                errors.connectUrl = 'Required';
            } else {
                const validUrl = validateUrl(value);
                if (!validUrl) {
                    errors.connectUrl = 'Must be a valid url starting with http or https';
                }
                if (validUrl && errors.connectUrl) {
                    delete errors.connectUrl;
                }
            }
        }
        if (name === 'externalAudioStreamUrl') {
            if (!hasValue) {
                errors.externalAudioStreamUrl = 'Required';
            } else {
                const validUrl = validateUrl(value);
                if (!validUrl) {
                    errors.externalAudioStreamUrl = 'Must be a valid url starting with http or https';
                }
                if (validUrl && errors.externalAudioStreamUrl) {
                    delete errors.externalAudioStreamUrl;
                }
            }
        }
        // Update required fields based on Zoom meeting type (web or phone)
        if (name === 'meetingType') {
            if (value === 'web') {
                if (!connectUrl) {
                    errors.connectUrl = 'Required';
                } else {
                    const validUrl = validateUrl(connectUrl);
                    if (!validUrl) {
                        errors.connectUrl = 'Must be a valid url starting with http or https';
                    }
                    if (validUrl && errors.connectUrl) {
                        delete errors.connectUrl;
                    }
                }
                // Remove connectAccessId and connectPhoneNumber errors when meetingType is web
                if (errors.connectAccessId) {
                    delete errors.connectAccessId;
                }
                if (errors.connectPhoneNumber) {
                    delete errors.connectPhoneNumber;
                }
                // Remove connectPin validation error for web
                if (errors.connectPin) {
                    delete errors.connectPin;
                }
            }
            if (value === 'phone') {
                if (!connectAccessId) {
                    errors.connectAccessId = 'Required';
                }
                if (!connectPhoneNumber) {
                    errors.connectPhoneNumber = 'Required';
                }
                // Remove connectUrl error when meetingType is phone
                if (errors.connectUrl) {
                    delete errors.connectUrl;
                }
                // Only allow digits for PIN
                if (!errors.connectPin && !!connectPin && !/^[\d#]+$/.test(connectPin)) {
                    errors.connectPin = 'Must only contain numbers or #';
                } else if (errors.connectPin && (!connectPin || (hasValue && /^\d+$/.test(connectPin)))) {
                    delete errors.connectPin;
                }
                // Remove "Aiera Intervention" permission checkbox error if switching to phone meetingType
                if (errors.confirmPermission) {
                    delete errors.confirmPermission;
                }
            }
        }
        if (name === 'onConnectDialNumber') {
            if (participationType === PARTICIPATION_TYPES.participating.value) {
                if (!hasValue) {
                    errors.onConnectDialNumber = 'Required when selecting "Call me" option';
                    // Add error for onFailurePhoneNumber if useOnConnectDialNumber is checked off
                    if (useOnConnectDialNumber && !errors.onFailurePhoneNumber) {
                        errors.onFailurePhoneNumber = 'Required';
                    }
                } else {
                    if (errors.onConnectDialNumber) {
                        delete errors.onConnectDialNumber;
                    }
                    // Delete the onFailurePhoneNumber error if it exists and useOnConnectDialNumber is checked off
                    if (useOnConnectDialNumber && errors.onFailurePhoneNumber) {
                        delete errors.onFailurePhoneNumber;
                    }
                }
            } else if (errors.onConnectDialNumber) {
                delete errors.onConnectDialNumber;
            }
            // Add error if the same value as connectPhoneNumber
            if (hasValue && value === connectPhoneNumber) {
                errors.onConnectDialNumber = 'Cannot be the same as the "Dial-in number"';
            }
        }
        if (name === 'onFailure') {
            if ([TROUBLESHOOTING_TYPES.sms.value, TROUBLESHOOTING_TYPES.call.value].includes(value)) {
                if (!onFailurePhoneNumber && !useOnConnectDialNumber) {
                    errors.onFailurePhoneNumber = 'Required';
                } else if (errors.onFailurePhoneNumber) {
                    delete errors.onFailurePhoneNumber;
                }
            }
            // For new private recordings, add error to confirm permission checkbox when selecting
            // "Aiera intervention" troubleshooting option
            if (
                value === TROUBLESHOOTING_TYPES.aieraIntervention.value &&
                privateRecordingId === 'new' &&
                !confirmPermission &&
                !errors.confirmPermission
            ) {
                errors.confirmPermission = 'Required';
            }
            if (value === TROUBLESHOOTING_TYPES.none.value) {
                if (errors.onFailurePhoneNumber) {
                    delete errors.onFailurePhoneNumber;
                }
                if (errors.confirmPermission) {
                    delete errors.confirmPermission;
                }
            }
        }
        // onFailurePhoneNumber isn't a Private Recording field, but is used in the Troubleshooting component
        // as the input name and value.
        // When the form is submitted, its value is mapped to either onFailureSmsNumber or onFailureDialNumber
        // depending on the current onFailure value.
        if (name === 'onFailurePhoneNumber') {
            if ([TROUBLESHOOTING_TYPES.sms.value, TROUBLESHOOTING_TYPES.call.value].includes(onFailure)) {
                if (!hasValue) {
                    errors.onFailurePhoneNumber = 'Required';
                } else if (errors.onFailurePhoneNumber) {
                    delete errors.onFailurePhoneNumber;
                }
            } else if (errors.onFailurePhoneNumber) {
                delete errors.onFailurePhoneNumber;
            }
            // Add error if the same value as connectPhoneNumber
            if (hasValue && value === connectPhoneNumber) {
                errors.onFailurePhoneNumber = 'Cannot be the same as the "Dial-in number"';
            }
        }
        if (name === 'participationType') {
            if (value === PARTICIPATION_TYPES.participating.value) {
                if (!onConnectDialNumber) {
                    errors.onConnectDialNumber = 'Required when selecting "Call me" option';
                } else if (errors.onConnectDialNumber) {
                    delete errors.onConnectDialNumber;
                }
            }
            if (value === PARTICIPATION_TYPES.notParticipating.value && errors.onConnectDialNumber) {
                delete errors.onConnectDialNumber;
            }
        }
        if (name === 'scheduleTime') {
            if (scheduleType === SCHEDULE_TYPES.future.value) {
                if (!hasValue) {
                    errors.scheduleTime = 'Required';
                } else {
                    const validTime = [3, 4].includes(value.length);
                    if (!validTime) {
                        errors.scheduleTime = 'Invalid';
                    }
                    if (validTime && errors.scheduleTime) {
                        delete errors.scheduleTime;
                    }
                }
            } else if (errors.scheduleTime) {
                delete errors.scheduleTime;
            }
        }
        if (name === 'scheduleType') {
            if (value === SCHEDULE_TYPES.future.value && !scheduleTime) {
                errors.scheduleTime = 'Required';
            }
            if (value === SCHEDULE_TYPES.now.value && errors.scheduleTime) {
                delete errors.scheduleTime;
            }
        }
        // Add or remove the onFailurePhoneNumber error depending on if useOnConnectDialNumber is checked off
        // and whether or not onConnectDialNumber or onFailurePhoneNumber is set
        if (name === 'useOnConnectDialNumber') {
            if (errors.onFailurePhoneNumber && ((value && onConnectDialNumber) || (!value && onFailurePhoneNumber))) {
                delete errors.onFailurePhoneNumber;
            }
            if (
                !errors.onFailurePhoneNumber &&
                ((value && !onConnectDialNumber) || (!value && !onFailurePhoneNumber))
            ) {
                errors.onFailurePhoneNumber = 'Required';
            }
        }
        return { errors };
    }

    render() {
        const {
            confirmPermission,
            connectAccessId,
            connectCallerId,
            connectionType,
            connectOffsetSeconds,
            connectPhoneNumber,
            connectPin,
            connectUrl,
            deleting,
            equityIds,
            errors,
            eventGroupId,
            eventGroupTitle,
            externalAudioStreamUrl,
            file,
            hasChanges,
            localeCode,
            meetingType,
            onCompleteEmailCreator,
            onConnectDialNumber,
            onFailure,
            onFailureInstructions,
            onFailurePhoneNumber,
            participationType,
            reconnecting,
            scheduleDate,
            scheduleMeridiem,
            scheduleTime,
            scheduleType,
            smsAlertBeforeCall,
            submitting,
            taggedInput,
            tags,
            title,
            touched,
            useOnConnectDialNumber
        } = this.state;
        const { domainScore, equities, loading, organizationName, privateRecording, styles } = this.props;
        return (
            <PrivateRecordingFormUI
                confirmPermission={confirmPermission}
                connectAccessId={connectAccessId}
                connectCallerId={connectCallerId}
                connectionType={connectionType}
                connectOffsetSeconds={connectOffsetSeconds}
                connectPhoneNumber={connectPhoneNumber}
                connectPin={connectPin}
                connectUrl={connectUrl}
                deleting={deleting}
                domainScore={domainScore}
                editing={!!privateRecording}
                equities={equities}
                equityIds={equityIds}
                errors={errors}
                eventGroupId={eventGroupId}
                eventGroupTitle={eventGroupTitle}
                externalAudioStreamUrl={externalAudioStreamUrl}
                filename={get(file, 'name')}
                getComponentVisibility={this.getComponentVisibility}
                getErrorHints={this.getErrorHints}
                hasChanges={hasChanges}
                hasFutureDatetime={this.hasFutureDatetime(scheduleType, scheduleDate, scheduleTime, scheduleMeridiem)}
                loading={loading}
                localeCode={localeCode}
                meetingType={meetingType}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onClose={this.onClose}
                onCompleteEmailCreator={onCompleteEmailCreator}
                onConnectDialNumber={onConnectDialNumber}
                onDelete={this.onDelete}
                onFailure={onFailure}
                onFailureInstructions={onFailureInstructions}
                onFailurePhoneNumber={onFailurePhoneNumber}
                onFileDelete={this.onFileDelete}
                onFileUpload={this.onFileUpload}
                onFocus={this.onFocus}
                onSubmit={this.onSubmit}
                organizationName={organizationName}
                participationType={participationType}
                reconnecting={reconnecting}
                retryable={this.isRetryable(privateRecording, connectionType, scheduleType)}
                scheduleDate={scheduleDate}
                scheduledFor={this.mapStateToScheduledFor(this.state)}
                scheduleMeridiem={scheduleMeridiem}
                scheduleTime={scheduleTime}
                scheduleType={scheduleType}
                smsAlertBeforeCall={smsAlertBeforeCall}
                styles={styles}
                submittable={this.isFormSubmittable(errors, hasChanges, reconnecting, submitting, title)}
                submitting={submitting}
                taggedInput={taggedInput}
                tags={tags}
                title={title}
                touched={touched}
                useOnConnectDialNumber={useOnConnectDialNumber}
            />
        );
    }
}

export const PrivateRecordingFormContainer = compose(
    withUrlContext(['history', 'pathname']),
    withStateHandlers(
        () => ({
            url: null
        }),
        {
            setUrl: () => url => ({ url })
        }
    ),
    withGetUser({ variables: { withOrganization: true, withOrganizationDetails: true } }),
    withPropsOnChange(['user'], ({ user }) => ({
        organizationName: get(user, 'organization.name'),
        organizationPreferences: get(user, 'organization.preferences')
    })),
    withData(),
    withDomainQuality(),
    withReporting()
)(PrivateRecordingForm);
