export const styleSheet = theme => {
    const dashHeader = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.colors.white01,
        padding: `${theme.margins.margin00} 18px 0 14px`,
        marginBottom: 8,
        marginTop: theme.margins.margin00,
        borderTop: `1px solid ${theme.colors.gray03}`,
        '> svg': {
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            transform: 'rotate(0deg)',
            flexShrink: 0
        },
        p: {
            cursor: 'pointer'
        },
        '> div:first-of-type': {
            backgroundColor: theme.colors.white01
        },
        a: {
            color: theme.colors.black01,
            textDecoration: 'none',
            svg: {
                height: 12,
                marginBottom: -1
            },
            ':hover': {
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        ':first-of-type': {
            paddingTop: 0,
            borderTop: 'unset',
            marginTop: 'unset'
        },
        ':hover': {
            '.chevron02 g': {
                fill: theme.colors.blue08
            }
        }
    };

    return {
        container: {
            ...theme.content.box,
            ...theme.content.fadeInAnimation,
            padding: '14px 0 10px',
            backgroundColor: theme.colors.white01,
            margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
            table: {
                padding: 0
            },
            [`@media(max-width: ${theme.breakpoints.external.mobile})`]: {
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: 0
            }
        },
        dashHeader: {
            ...dashHeader,
            '> svg': {
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                transform: 'rotate(90deg)',
                flexShrink: 0
            }
        },
        dashHeaderCollapsed: {
            ...dashHeader
        },
        dashIconLink: {
            backgroundColor: theme.colors.white01
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
            padding: '0 13px 0 14px'
        },
        dashTitle: {
            color: theme.colors.black01,
            marginLeft: 6,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: theme.margins.margin01
        },
        dataRow: {},
        dataRowClickable: {
            cursor: 'pointer',
            ':hover': {
                color: theme.colors.black01,
                '.dataLabel': {
                    textDecoration: 'underline'
                }
            }
        },
        dataRowIndex: {
            cursor: 'pointer',
            color: theme.colors.gray04,
            fontSize: theme.fonts.fontSizes.size01,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            ':hover': {
                color: theme.colors.black01
            }
        },
        dataRowIndexActive: {
            cursor: 'pointer',
            color: theme.colors.blue01,
            fontWeight: theme.fonts.fontWeights.medium,
            fontSize: theme.fonts.fontSizes.size01,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            ':hover': {
                color: theme.colors.black01
            }
        },
        dataRowValue: {
            paddingTop: '8px',
            paddingBottom: '8px',
            lineHeight: '1.1em',
            '.dataLabel': {
                textTransform: 'capitalize'
            },
            '.dataRow': {
                svg: {
                    height: '12px',
                    display: 'none'
                }
            },
            ':hover': {
                '.dataRow': {
                    svg: {
                        display: 'inline-block'
                    }
                }
            }
        },
        trash: {
            cursor: 'pointer',
            display: 'inline-block',
            marginLeft: '6px',
            ':hover': {
                'svg g': {
                    fill: theme.colors.blue01
                }
            }
        },
        table: {
            height: 'auto'
        },
        upgradeTopics: {
            cursor: 'pointer',
            padding: '14px 16px',
            backgroundColor: theme.colors.yellow09,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 6,
            boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.06)',
            margin: `12px ${theme.margins.margin01} ${theme.margins.margin01}`,
            svg: {
                flexShrink: 0,
                marginRight: 12
            },
            p: {
                lineHeight: '1.3em'
            },
            ':hover': {
                backgroundColor: theme.colors.yellow07
            },
            ':active': {
                backgroundColor: theme.colors.yellow08
            }
        },
        flexRow: {
            display: 'flex'
        },
        sentiment: {
            display: 'flex',
            marginTop: 3,
            svg: {
                height: 10,
                display: 'block'
            }
        },
        sentimentText: {
            lineHeight: '10px',
            marginRight: 4,
            marginLeft: 4,
            color: theme.colors.gray06
        },
        sentimentTextPositive: {
            lineHeight: '10px',
            marginRight: 4,
            marginLeft: 4,
            color: theme.colors.green03
        },
        sentimentTextNegative: {
            lineHeight: '10px',
            marginRight: 4,
            marginLeft: 4,
            color: theme.colors.red01
        },
        sentimentTooltip: {
            ...theme.content.tooltipTextSmall,
            width: 253,
            padding: '6px 8px',
            lineHeight: '1.2em',
            height: 'unset'
        },
        spacer: {
            flex: 1
        }
    };
};
