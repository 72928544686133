import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get, copyToClipboard } from 'utils';
import { withData } from './data';

import { AskAieraQuestionCardUI } from './ui';

export class AskAieraQuestionCard extends PureComponent {
    static displayName = 'AskAieraQuestionCardContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        answerFeedback: PropTypes.func.isRequired,
        onSelectAnswer: PropTypes.func,
        callType: PropTypes.string,
        id: PropTypes.string,
        feedback: PropTypes.string,
        answer: PropTypes.arrayOf(PropTypes.any),
        citations: PropTypes.arrayOf(PropTypes.any),
        autoQuestion: PropTypes.string,
        question: PropTypes.string.isRequired,
        template: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string
    };

    static defaultProps = {
        callType: undefined,
        styles: undefined,
        id: undefined,
        feedback: undefined,
        citations: [],
        answer: [],
        autoQuestion: undefined,
        onSelectAnswer: undefined,
        template: undefined,
        ticker: undefined
    };

    constructor(props) {
        super(props);

        this.onAnswerAuto = this.onAnswerAuto.bind(this);
        this.onAnswerFeedback = this.onAnswerFeedback.bind(this);
        this.onAnswerTemplate = this.onAnswerTemplate.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);

        this.state = { copied: false };
    }

    copyToClipboard() {
        const { answer } = this.props;
        copyToClipboard(answer.join(' ')).then(() => this.setState({ copied: true }));
    }

    onAnswerTemplate({ templateType: newTemplate }) {
        const { answerFeedback, template, id, feedback, autoQuestion } = this.props;
        const templateId = get(template, 'id');
        const templateType = get(template, 'templateType');
        if (newTemplate !== templateType) {
            // Update template
            if (template && templateId && templateType !== 'aiera') {
                answerFeedback({
                    id: templateId,
                    feedback: feedback || undefined,
                    isTemplate: newTemplate,
                    autoQuestion: autoQuestion || undefined
                });
            } else if (!template) {
                answerFeedback({
                    id,
                    feedback: feedback || undefined,
                    isTemplate: newTemplate,
                    autoQuestion: autoQuestion || undefined
                });
            }
        }
    }

    onAnswerFeedback({ id, feedback: newFeedback }) {
        const { answerFeedback, feedback, template, autoQuestion } = this.props;
        const templateType = get(template, 'templateType');

        if (newFeedback !== feedback) {
            // Update feedback
            if (id) {
                answerFeedback({
                    id,
                    feedback: newFeedback,
                    isTemplate: templateType || undefined,
                    autoQuestion: autoQuestion || undefined
                });
            }
        }
    }

    onAnswerAuto({ id, autoQuestion: newAutoQuestion }) {
        const { answerFeedback, feedback, template, autoQuestion } = this.props;
        const templateType = get(template, 'templateType');

        if (newAutoQuestion !== autoQuestion) {
            // Update autoquestion
            if (id) {
                answerFeedback({
                    id,
                    feedback: feedback || undefined,
                    isTemplate: templateType || undefined,
                    autoQuestion: newAutoQuestion
                });
            }
        }
    }

    render() {
        const {
            callType,
            styles,
            onSelectAnswer,
            citations,
            question,
            template,
            ticker,
            id,
            feedback,
            answer,
            autoQuestion
        } = this.props;
        const { copied } = this.state;
        return (
            <AskAieraQuestionCardUI
                styles={styles}
                id={id}
                feedback={feedback}
                answer={answer}
                autoQuestion={autoQuestion}
                onAnswerFeedback={this.onAnswerFeedback}
                onAnswerAuto={this.onAnswerAuto}
                onAnswerTemplate={this.onAnswerTemplate}
                onSelectAnswer={onSelectAnswer}
                callType={callType}
                citations={citations}
                copyToClipboard={this.copyToClipboard}
                copied={copied}
                question={question}
                template={template}
                ticker={ticker}
            />
        );
    }
}

export const AskAieraQuestionCardContainer = compose(withData())(AskAieraQuestionCard);
