export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.blue09,
        td: {
            paddingTop: theme.margins.margin00,
            paddingBottom: theme.margins.margin00,
            borderBottom: `1px solid ${theme.colors.gray03}`,
            position: 'relative',
            zIndex: 1,
            backgroundColor: theme.colors.blue09
        },
        'td:first-of-type': {
            paddingLeft: theme.margins.margin00
        },
        'td:last-of-type': {
            paddingRight: theme.margins.margin00
        }
    },
    highlight: {
        ...theme.content.annotated,
        color: theme.colors.gray08,
        fontSize: theme.fonts.fontSizes.size03,
        padding: `24px ${theme.margins.margin00} 0`,
        lineHeight: '1.3em'
    },
    highlightInfo: {
        display: 'flex',
        marginBottom: '4px'
    },
    matchNumberText: {
        marginRight: theme.margins.margin00,
        color: theme.colors.gray04,
        letterSpacing: '1px'
    },
    dateText: {
        marginRight: theme.margins.margin00,
        color: theme.colors.black01,
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.blue03,
            textDecoration: 'underline'
        }
    },
    jumpText: {
        color: theme.colors.gray04,
        letterSpacing: '1px',
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.blue03,
            textDecoration: 'underline'
        }
    },
    showMore: {
        margin: '20px 10px 25px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px dashed ${theme.colors.gray01}`,
        position: 'relative'
    },
    showMoreButton: {
        position: 'absolute',
        height: '20px',
        borderRadius: '0 0 2px 2px',
        padding: '0 6px',
        top: 0,
        backgroundColor: theme.colors.gray08,
        color: theme.colors.white01,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        letterSpacing: '1px',
        ':hover': {
            backgroundColor: theme.colors.gray11
        }
    },
    spacer: {
        flex: 1
    }
});
