import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { TickerTagUI } from './ui';

export class TickerTag extends PureComponent {
    static displayName = 'TickerTagContainer';

    render() {
        const { ...rest } = this.props;
        return <TickerTagUI {...rest} />;
    }
}

export const TickerTagContainer = compose()(TickerTag);
