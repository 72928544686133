import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withData } from './data';
import { NotificationListUI } from './ui';

export class NotificationList extends PureComponent {
    static displayName = 'NotificationListContainer';

    static propTypes = {
        isTooltipOpen: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        markNotificationsRead: PropTypes.func.isRequired,
        matches: PropTypes.arrayOf(PropTypes.object),
        onTooltipHide: PropTypes.func.isRequired,
        onTooltipShow: PropTypes.func.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string,
        unreadCount: PropTypes.number
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        streamId: null,
        streamProps: undefined,
        styles: undefined,
        subtitle: undefined,
        unreadCount: 0
    };

    constructor(props) {
        super(props);

        this.onTooltipHide = this.onTooltipHide.bind(this);
    }

    onTooltipHide() {
        const { markNotificationsRead, onTooltipHide } = this.props;
        markNotificationsRead().finally(() => {
            onTooltipHide();
        });
    }

    render() {
        const {
            isTooltipOpen,
            loadMoreMatches,
            loading,
            matches,
            onTooltipShow,
            streamId,
            streamProps,
            styles,
            subtitle,
            unreadCount
        } = this.props;
        return (
            <NotificationListUI
                isTooltipOpen={isTooltipOpen}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                onTooltipHide={this.onTooltipHide}
                onTooltipShow={onTooltipShow}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
                unreadCount={unreadCount}
            />
        );
    }
}

export const NotificationListContainer = compose(
    withStateHandlers(
        {
            isTooltipOpen: false
        },
        {
            onTooltipHide: () => () => ({ isTooltipOpen: false }),
            onTooltipShow: () => () => ({ isTooltipOpen: true })
        }
    ),
    withData()
)(NotificationList);
