import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { watchlistFragment } from 'graphql/fragments/watchlists';

export const withDeleteWatchlist = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation DeleteWatchlist($watchlistId: ID!) {
                    deleteWatchlist(watchlistId: $watchlistId) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { watchlistId, setStatusBanner } }) => ({
                    deleteWatchlist: () =>
                        mutate({
                            variables: { watchlistId },
                            update: proxy => {
                                const query = gql`
                                    query withDeleteWatchlist {
                                        currentUser {
                                            id
                                            watchlists {
                                                id
                                            }
                                        }
                                    }
                                `;
                                const { currentUser } = proxy.readQuery({ query });
                                proxy.writeQuery({
                                    query,
                                    data: {
                                        currentUser: {
                                            ...currentUser,
                                            watchlists: currentUser.watchlists.filter(({ id }) => id !== watchlistId)
                                        }
                                    }
                                });
                            }
                        })
                            .then(() => {
                                setStatusBanner('Watchlist deleted successfully!');
                            })
                            .catch(error => {
                                setStatusBanner(`Error deleting watchlist: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        )
    );

export const withCloneWatchlist = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CloneWatchlist($watchlistId: ID!) {
                    cloneWatchlist(watchlistId: $watchlistId) {
                        success
                        watchlist {
                            ...watchlist
                        }
                    }
                }
                ${watchlistFragment}
            `,
            {
                props: ({ mutate, ownProps: { watchlistId, setStatusBanner } }) => ({
                    cloneWatchlist: () =>
                        mutate({
                            variables: { watchlistId },
                            update: (
                                proxy,
                                {
                                    data: {
                                        cloneWatchlist: { watchlist }
                                    }
                                }
                            ) => {
                                const query = gql`
                                    query withCloneWatchlist {
                                        currentUser {
                                            id
                                            watchlists {
                                                id
                                                watchlistId
                                                name
                                            }
                                        }
                                    }
                                `;
                                const { currentUser } = proxy.readQuery({ query });
                                proxy.writeQuery({
                                    query,
                                    data: {
                                        currentUser: {
                                            ...currentUser,
                                            watchlists: [...currentUser.watchlists, watchlist]
                                        }
                                    }
                                });
                            }
                        })
                            .then(
                                ({
                                    data: {
                                        cloneWatchlist: { watchlist }
                                    }
                                }) => {
                                    setStatusBanner('Watchlist cloned successfully!');
                                    return watchlist;
                                }
                            )
                            .catch(error => {
                                setStatusBanner(`Error cloning watchlist: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        )
    );
