import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { constants } from 'consts';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class UpgradeModal extends PureComponent {
    static propTypes = {
        adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
        calculating: PropTypes.bool.isRequired,
        currentPaymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        goBack: PropTypes.func.isRequired,
        hasBillingError: PropTypes.bool.isRequired,
        isSelfServe: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        membershipType: PropTypes.string,
        nextBillingDate: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onUpgrade: PropTypes.func.isRequired,
        organizationAdmin: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        paymentInterval: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleUpgradeOption: PropTypes.func.isRequired,
        upgradeOption: PropTypes.string.isRequired,
        upgradePlan: PropTypes.objectOf(PropTypes.any),
        upgradePriceMap: PropTypes.objectOf(PropTypes.any),
        userCount: PropTypes.number.isRequired
    };

    static defaultProps = {
        currentPaymentAmount: undefined,
        membershipType: undefined,
        nextBillingDate: undefined,
        passedStyles: {},
        paymentInterval: undefined,
        upgradePlan: null,
        upgradePriceMap: null
    };

    renderBillingError() {
        const { styles } = this.props;
        return (
            <Div styles={styles.billingError}>
                <Text size={3} styles={styles.billingErrorText} weight="medium">
                    Oops! Something went wrong
                </Text>
                <Text size={1} styles={styles.billingErrorText}>
                    There is an issue with your membership.
                </Text>
                <Text size={1} styles={styles.billingErrorText}>
                    {`Please call us at ${constants.PHONE_SUPPORT} or email us at`}&nbsp;
                    <ExternalLink href={`mailto:${constants.EMAIL_SUPPORT}`}>{constants.EMAIL_SUPPORT}</ExternalLink>.
                </Text>
            </Div>
        );
    }

    renderFooter() {
        const {
            styles,
            organizationAdmin,
            goBack,
            hasBillingError,
            isSelfServe,
            theme,
            onClose,
            onUpgrade,
            calculating,
            loading,
            submitting,
            upgradePriceMap,
            upgradePlan
        } = this.props;
        const submitDisabled = calculating || hasBillingError || loading || !upgradePriceMap;
        const name = get(upgradePlan, 'title');
        return (
            <Div styles={styles.footer}>
                <Div styles={styles.buttonsContainer}>
                    <ActionButton onClick={goBack} size={2} styles={styles.backButton}>
                        <Icon type="chevron02" color={theme.colors.black01} />
                        <Text size={3}>Back</Text>
                    </ActionButton>
                    <Div styles={styles.spacer} />
                    <ActionButton styles={styles.cancelButton} size={2} onClick={onClose}>
                        <Text size={3}>{organizationAdmin ? 'Cancel' : 'Close'}</Text>
                    </ActionButton>
                    {organizationAdmin && isSelfServe && (
                        <ActionButton
                            styles={submitDisabled ? styles.upgradeButtonDisabled : styles.upgradeButton}
                            size={2}
                            disabled={submitDisabled}
                            loading={submitting}
                            onClick={onUpgrade}
                            name={name}
                        >
                            <Text size={3}>Upgrade Account</Text>
                        </ActionButton>
                    )}
                </Div>
            </Div>
        );
    }

    renderManageUpgrade() {
        const {
            styles,
            calculating,
            membershipType,
            userCount,
            upgradeOption,
            toggleUpgradeOption,
            currentPaymentAmount,
            paymentInterval,
            nextBillingDate,
            upgradePlan,
            upgradePriceMap
        } = this.props;
        const membershipNeeded = get(upgradePlan, 'membershipNeeded');
        const newPrice = get(upgradePriceMap, `${upgradeOption}.newPrice`, '');
        const proratedAmount = get(upgradePriceMap, `${upgradeOption}.proratedAmount`);
        const interval = paymentInterval === 'year' ? 'yearly' : 'monthly';
        return (
            <Div styles={styles.manageUpgrade}>
                <Text size={3} weight="medium" styles={styles.upgradeTitle}>
                    {`Upgrade${membershipType ? ` from ${membershipType}` : ''} to ${membershipNeeded}`}
                </Text>
                <Text size={1} styles={styles.availableFunctionality}>
                    {`All ${membershipNeeded} functionality will be available immediately after upgrading.`}
                </Text>
                {userCount > 1 && (
                    <MultiSelect
                        required
                        onChange={toggleUpgradeOption}
                        selected={[upgradeOption]}
                        styles={styles.upgradeMultiselect}
                    >
                        <Option value="currentUser" type="radio">
                            <Div styles={styles.upgradeOption}>
                                <Text
                                    size={3}
                                    weight={upgradeOption === 'currentUser' ? 'medium' : undefined}
                                    styles={styles.optionLabel}
                                >
                                    Your Account Only
                                </Text>
                                {calculating ? (
                                    <Text size={1} styles={styles.optionDescription}>
                                        Calculating price...
                                    </Text>
                                ) : (
                                    <Text size={1} styles={styles.optionDescription}>
                                        Increases your {interval}
                                        <br /> payment by {get(upgradePriceMap, 'currentUser.priceIncrease', '')}
                                    </Text>
                                )}
                            </Div>
                        </Option>
                        <Option value="team" type="radio">
                            <Div styles={styles.upgradeOption}>
                                <Text
                                    size={3}
                                    weight={upgradeOption === 'team' ? 'medium' : undefined}
                                    styles={styles.optionLabel}
                                >
                                    Your Entire Team ({pluralize('seat', userCount, true)})
                                </Text>
                                {calculating ? (
                                    <Text size={1} styles={styles.optionDescription}>
                                        Calculating price...
                                    </Text>
                                ) : (
                                    <Text size={1} styles={styles.optionDescription}>
                                        Increases your {interval}
                                        <br /> payment by {get(upgradePriceMap, 'team.priceIncrease', '')}
                                    </Text>
                                )}
                            </Div>
                        </Option>
                    </MultiSelect>
                )}
                {calculating ? (
                    <Text size={1} styles={styles.priceChange}>
                        Calculating price...
                    </Text>
                ) : (
                    <Fragment>
                        <Text size={1} styles={styles.priceChange}>
                            Your {interval} charge will change from&nbsp;
                            <Span styles={styles.bold}>{currentPaymentAmount}</Span>
                            &nbsp;to&nbsp;
                            <Span styles={styles.bold}>{newPrice}</Span>.
                        </Text>
                        {proratedAmount && (
                            <Text styles={styles.billingDetails}>
                                You will be charged a prorated amount of {proratedAmount} immediately upon confirmation.
                            </Text>
                        )}
                        <Text styles={styles.billingDetails}>
                            At the next billing date of {nextBillingDate}, you will be charged {newPrice}.
                        </Text>
                    </Fragment>
                )}
            </Div>
        );
    }

    renderUpgradeRequest() {
        const { adminUsers, styles, membershipType, upgradePlan } = this.props;
        const membershipNeeded = get(upgradePlan, 'membershipNeeded');
        const wantedAction = get(upgradePlan, 'wantedAction');
        const generateMailTo = email =>
            `mailto:${email}?subject=Aiera membership change?&body=Can you please change my membership` +
            `${membershipType ? ` from ${membershipType}` : ''} to ${membershipNeeded} (in the Billing page), so I ` +
            `have the ability to ${wantedAction}?`;
        return (
            <Div styles={styles.upgradeRequest}>
                <Text size={1} styles={styles.administratorRequest}>
                    Only an administrator can modify your membership. Reach out to one of the administrators on your
                    team, to request your account be changed from&nbsp;
                    {membershipType ? (
                        <Text span weight="medium">
                            {membershipType}
                        </Text>
                    ) : (
                        'your current membership'
                    )}
                    {' to '}
                    <Text span weight="medium">
                        {membershipNeeded}
                    </Text>
                    .
                </Text>
                {adminUsers && adminUsers.length > 0 && (
                    <Text size={1} weight="medium" styles={styles.adminsHeader}>
                        Your Administrators
                    </Text>
                )}
                <ul>
                    {adminUsers.map(({ email }, idx) => (
                        <li key={email}>
                            <ExternalLink href={generateMailTo(email)}>
                                <Text size={1}>{idx > 0 ? `, ${email}` : email}</Text>
                            </ExternalLink>
                        </li>
                    ))}
                </ul>
            </Div>
        );
    }

    renderNotSelfServe() {
        const { adminUsers, styles, membershipType, upgradePlan, organizationAdmin } = this.props;
        const membershipNeeded = get(upgradePlan, 'membershipNeeded');
        const wantedAction = get(upgradePlan, 'wantedAction');
        const adminText = "Please contact us to change you, or your team's memberships";
        const adminMailTo =
            'mailto:sales@aiera.com?subject=How can I change my Aiera membership?&body=What is required to change my ' +
            `membership${membershipType ? ` from ${membershipType}` : ''} to ${membershipNeeded}, so I have the ` +
            `ability to ${wantedAction}`;
        const generateMailTo = email =>
            `mailto:${email}?subject=Aiera membership change?&body=Can you please change my membership` +
            `${membershipType ? ` from ${membershipType}` : ''} to ${membershipNeeded}, so I have the ability to ` +
            `${wantedAction}?`;
        return (
            <Div styles={styles.upgradeRequest}>
                {organizationAdmin ? (
                    <Fragment>
                        <Text size={1} styles={styles.administratorRequest}>
                            {membershipType
                                ? `${adminText} from ${membershipType} to ${membershipNeeded}.`
                                : `${adminText} to ${membershipNeeded}`}
                        </Text>
                        <ExternalLink styles={styles.salesEmail} href={adminMailTo}>
                            <Text size={1}>Email sales@aiera.com</Text>
                        </ExternalLink>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Text size={1} styles={styles.administratorRequest}>
                            Only an administrator can modify your membership. Reach out to one of the administrators on
                            your team, to request your account be changed from&nbsp;
                            {membershipType ? (
                                <Text span weight="medium">
                                    {membershipType}
                                </Text>
                            ) : (
                                'your current membership'
                            )}
                            {' to '}
                            <Text span weight="medium">
                                {membershipNeeded}
                            </Text>
                            .
                        </Text>
                        {adminUsers && adminUsers.length > 0 && (
                            <Text size={1} weight="medium" styles={styles.adminsHeader}>
                                Your Administrators
                            </Text>
                        )}
                        <ul>
                            {adminUsers.map(({ email }, idx) => (
                                <li key={email}>
                                    <ExternalLink href={generateMailTo(email)}>
                                        <Text size={1}>{idx > 0 ? `, ${email}` : email}</Text>
                                    </ExternalLink>
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}
            </Div>
        );
    }

    render() {
        const {
            calculating,
            hasBillingError,
            isSelfServe,
            membershipType,
            onClose,
            organizationAdmin,
            passedStyles,
            styles,
            upgradePlan
        } = this.props;
        const content = get(upgradePlan, 'content');
        const title = get(upgradePlan, 'title');
        const membershipNeeded = get(upgradePlan, 'membershipNeeded');
        return (
            <Modal isOpen onModalClose={onClose} title={title}>
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div styles={styles.tags}>
                        {membershipType && (
                            <Div styles={styles.tag}>
                                <Text weight="medium" size={1}>
                                    Your Membership
                                </Text>
                                <Text size={1}>{membershipType}</Text>
                            </Div>
                        )}
                        {membershipNeeded && (
                            <Div styles={styles.tag}>
                                <Text weight="medium" size={1}>
                                    Membership Required
                                </Text>
                                <Text size={1}>{membershipNeeded}</Text>
                            </Div>
                        )}
                    </Div>
                    <Text className="upgradeModalContent" size={3} styles={styles.content}>
                        {content}
                    </Text>
                    {hasBillingError ? (
                        this.renderBillingError()
                    ) : (
                        <Fragment>
                            {!isSelfServe
                                ? this.renderNotSelfServe()
                                : organizationAdmin || calculating
                                ? this.renderManageUpgrade()
                                : this.renderUpgradeRequest()}
                        </Fragment>
                    )}
                    {this.renderFooter()}
                </Div>
            </Modal>
        );
    }
}

export const UpgradeModalUI = compose(withStyleSheet(styleSheet))(UpgradeModal);
