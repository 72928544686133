import { types } from 'actions/popoverPDF';

const { POPOVER_PDF_HIDE, POPOVER_PDF_SET } = types;

const initialState = {
    url: null
};

export function popoverPDFReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === POPOVER_PDF_SET) {
        return {
            url: payload.url
        };
    }

    if (type === POPOVER_PDF_HIDE) {
        return {
            url: null
        };
    }

    return state;
}
