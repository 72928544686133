import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Checkbox } from 'components/Checkbox';

import { styleSheet } from './stylesheet';

class ContentStreamToolbar extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleContentType: PropTypes.func.isRequired,
        contentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        streamContentTypes: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { streamContentTypes, passedStyles, styles, toggleContentType, contentTypes } = this.props;
        return (
            <Div className="contentStreamToolbar" styles={{ ...styles.container, ...passedStyles }}>
                {streamContentTypes.includes('transcript') && (
                    <Div styles={styles.checkbox} onClick={() => toggleContentType('transcript')}>
                        <Checkbox size={3} checked={contentTypes.includes('transcript')} />
                        <Text size={1}>Transcripts</Text>
                    </Div>
                )}
                {streamContentTypes.includes('news') && (
                    <Div styles={styles.checkbox} onClick={() => toggleContentType('news')}>
                        <Checkbox size={3} checked={contentTypes.includes('news')} />
                        <Text size={1}>News</Text>
                    </Div>
                )}
                {streamContentTypes.includes('attachment') && (
                    <Div styles={styles.checkbox} onClick={() => toggleContentType('attachment')}>
                        <Checkbox size={3} checked={contentTypes.includes('attachment')} />
                        <Text size={1}>Event Docs</Text>
                    </Div>
                )}
            </Div>
        );
    }
}

export const ContentStreamToolbarUI = compose(withStyleSheet(styleSheet))(ContentStreamToolbar);
