import gql from 'graphql-tag';
import { compose } from 'recompose';
import { dashboardFragment, ruleFragment } from 'graphql/fragments/streams';
import { mapDashFiltersToRules, mapDashRulesToFilters } from 'utils/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';

const DASHBOARD_ID = 'companies';
export const dashboardQuery = `
    dashboards(filter: { dashboardIds: [$dashboardId] }) {
        ...dashboard
        rules {
            ...rule
        }
        streams {
            id
            streamKey
            streamType
            uxPreferences
        }
    }
`;

export const withUpdateCompaniesDashboard = () =>
    graphql(
        gql`
            mutation updateDashboard($rules: [StreamRuleInput]!, $dashboardId: ID!) {
                updateDashboard(input: { rules: $rules, dashboardId: $dashboardId }) {
                    success
                    dashboard {
                        ...dashboard
                        rules {
                            ...rule
                        }
                    }
                }
            }
            ${dashboardFragment}
            ${ruleFragment}
        `,
        {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                updateDashboard: ({ equityScope }) => {
                    return mutate({
                        variables: {
                            dashboardId: DASHBOARD_ID,
                            rules: mapDashFiltersToRules({
                                dateRange: [],
                                filters: [],
                                sources: [],
                                equityScope
                            })
                        },
                        context: {
                            debounceKey: `updateDashboard`,
                            debounceTimeout: 300
                        }
                    })
                        .then(() => setStatusBanner('Filters saved successfully!'))
                        .catch(() => setStatusBanner('Error saving filters', 'error', 'circleX'));
                }
            })
        }
    );

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withCompaniesDashboard($dashboardId: ID) {
                    ${dashboardQuery}
                }
                ${dashboardFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    const dashboard = get(data, 'dashboards[0]', {});
                    const streams = get(dashboard, 'streams', []);
                    const companiesStream = streams.find(({ streamKey }) => streamKey === 'companies');
                    const liveEventsStream = streams.find(
                        ({ streamKey }) => streamKey === 'companies_empty_live_events'
                    );
                    const upcomingEventsStream = streams.find(
                        ({ streamKey }) => streamKey === 'companies_empty_upcoming_events'
                    );
                    const recentEventsStream = streams.find(
                        ({ streamKey }) => streamKey === 'companies_empty_recent_events'
                    );
                    const conferencesStream = streams.find(
                        ({ streamKey }) => streamKey === 'companies_empty_conferences'
                    );

                    // EquityScope needs to be set to undefined, so when it does have a value the company stream will
                    // update on the prop change
                    const equityFilters = get(mapDashRulesToFilters(get(dashboard, 'rules')), 'equityScope', []);
                    const equityScope = !equityFilters || equityFilters.length === 0 ? undefined : equityFilters;

                    return {
                        dashboard,
                        dashboardId: DASHBOARD_ID,
                        loading: get(data, 'loading', false),
                        uxPreferences: get(companiesStream, 'uxPreferences'),
                        companiesStreamId: get(companiesStream, 'id'),
                        liveEventsStreamId: get(liveEventsStream, 'id'),
                        upcomingEventsStreamId: get(upcomingEventsStream, 'id'),
                        recentEventsStreamId: get(recentEventsStream, 'id'),
                        conferencesStreamId: get(conferencesStream, 'id'),
                        equityScope
                    };
                },
                options: {
                    fetchPolicy: 'cache-first',
                    variables: { dashboardId: DASHBOARD_ID }
                }
            }
        ),
        withUpdateCompaniesDashboard()
    );
