import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { withEventDetails } from 'graphql/audioCalls';
import { get } from 'utils';
import { EventAskAieraUI } from './ui';

export class EventAskAiera extends PureComponent {
    static displayName = 'EventAskAieraContainer';

    static propTypes = {
        canAskQuestions: PropTypes.bool.isRequired,
        eventId: PropTypes.string.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        selectedNode: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined,
        selectedNode: undefined
    };

    constructor(props) {
        super(props);

        this.selectAnswer = this.selectAnswer.bind(this);

        this.state = {
            selectedAnswer: null
        };
    }

    selectAnswer(selectedAnswer) {
        this.setState({
            selectedAnswer
        });
    }

    render() {
        const { eventId, canAskQuestions, styles, onNodeSelect, selectedNode } = this.props;
        const { selectedAnswer } = this.state;
        return (
            <EventAskAieraUI
                eventId={eventId}
                canAskQuestions={canAskQuestions}
                onNodeSelect={onNodeSelect}
                selectedNode={selectedNode}
                selectAnswer={this.selectAnswer}
                selectedAnswer={selectedAnswer}
                styles={styles}
            />
        );
    }
}

export const EventAskAieraContainer = compose(
    withEventDetails({
        fetchPolicy: 'cache-only'
    }),
    withPropsOnChange(['audioCall'], ({ audioCall }) => ({
        canAskQuestions:
            !get(audioCall, 'isLive', false) &&
            (get(audioCall, 'hasLiveTranscript') || get(audioCall, 'hasPublishedTranscript'))
    }))
)(EventAskAiera);
