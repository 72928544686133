import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { constants } from 'consts';
import { withReporting } from 'provider/reporting';
import { withUrlContext } from 'hoc/url';
import { ErrorBoundaryUI } from './ui';

export class ErrorBoundary extends PureComponent {
    static displayName = 'ErrorBoundaryContainer';

    static propTypes = {
        reporter: PropTypes.shape({ bugsnagClient: PropTypes.oneOfType([PropTypes.any]) }).isRequired,
        pathname: PropTypes.string.isRequired,
        showErrorPage: PropTypes.bool
    };

    static defaultProps = {
        showErrorPage: false
    };

    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    componentDidUpdate(prevProps) {
        const { pathname } = this.props;
        const { hasError } = this.state;

        if (hasError && prevProps.pathname !== pathname) {
            this.setState({
                hasError: false
            });
        }
    }

    componentDidCatch(error, info) {
        const {
            reporter: { bugsnagClient }
        } = this.props;
        this.setState({ hasError: true });
        bugsnagClient.notify(error, { metaData: { react: { info } } });
    }

    render() {
        const { hasError } = this.state;
        const { children, showErrorPage, ...rest } = this.props;

        if (hasError && showErrorPage) {
            // PUT OTHER PROPS AFTER {...rest}
            return (
                <ErrorBoundaryUI
                    {...rest}
                    phoneSupport={constants.PHONE_SUPPORT}
                    emailSupport={constants.EMAIL_SUPPORT}
                />
            );
        }

        return !hasError ? children : null;
    }
}

export const ErrorBoundaryContainer = compose(withUrlContext(['pathname']), withReporting())(ErrorBoundary);
