import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { BackButtonUI } from './ui';

export class BackButton extends PureComponent {
    static displayName = 'BackButtonContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <BackButtonUI {...rest} />;
    }
}

export const BackButtonContainer = compose(withRouter)(BackButton);
