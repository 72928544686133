export const styleSheet = theme => ({
    containerCompact: {
        borderRadius: 'unset',
        width: 'unset',
        margin: 'unset'
    },
    actionMenu: {
        ...theme.content.cardActionMenu
    },
    compact: {
        borderRadius: 'unset',
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        padding: '7px 13px 15px',
        '.title': {
            ...theme.content.annotated,
            fontWeight: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.blue09
        }
    },
    containerComfortable: {
        padding: '10px 0 6px',
        '.title': {
            ...theme.content.annotated,
            fontWeight: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.blue09
        }
    },
    container: {
        padding: '10px 0 0 0',
        '.title': {
            ...theme.content.annotated
        },
        ':hover': {
            backgroundColor: theme.colors.blue09
        }
    },
    containerRestricted: {
        a: {
            cursor: 'default'
        },
        ':hover': {
            backgroundColor: theme.colors.white01
        }
    },
    cardLink: {
        padding: '0 13px',
        marginBottom: 10,
        position: 'relative',
        ':hover': {
            textDecoration: 'none'
        },
        backgroundColor: 'inherit'
    },
    content: {
        ...theme.content.annotated,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        margin: '12px -5px -3px',
        padding: '3px 5px',
        borderRadius: 3.5
    },
    title: {
        marginTop: '3px'
    },
    subTextCompact: {
        display: 'flex',
        marginTop: 3,
        alignItems: 'center'
    },
    subText: {
        display: 'flex',
        marginTop: 3,
        alignItems: 'center'
    },
    source: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.colors.gray04
    },
    date: {
        whiteSpace: 'nowrap',
        color: theme.colors.gray04
    },
    textDarkerGray: {
        color: theme.colors.gray06
    },
    groupedContent: {
        ...theme.content.annotated,
        borderLeft: `1px solid ${theme.colors.gray02}`,
        borderRight: `1px solid ${theme.colors.gray02}`,
        backgroundColor: theme.colors.white02,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        padding: '8px 13px',
        ':hover': {
            backgroundColor: theme.colors.blue09,
            cursor: 'pointer'
        },
        ':last-of-type': {
            borderBottom: `1px solid ${theme.colors.gray02}`,
            borderRadius: '0 0 6px 6px',
            paddingBottom: '10px'
        }
    },
    groupedContentDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    groupedContentIndex: {
        marginLeft: 'auto'
    },
    spacer: {
        flex: 1
    },
    locked: {
        position: 'absolute',
        right: 5,
        bottom: 6,
        padding: '2px 5px',
        borderRadius: 6,
        cursor: 'pointer',
        backgroundColor: theme.colors.white01,
        svg: {
            height: 10
        },
        ':hover': {
            backgroundColor: theme.colors.yellow07,
            'svg g': {
                fill: theme.colors.black01
            }
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    },
    spotlightTag: {
        padding: '3px 6px 2px',
        borderRadius: 20,
        overflow: 'hidden',
        color: theme.colors.gray08,
        backgroundColor: theme.colors.gray01
    },
    spotlightSubTag: {
        marginLeft: 4,
        marginRight: -6,
        padding: '3px 6px 2px 4px',
        borderLeft: 'solid 1px white',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        backgroundColor: theme.colors.gray03,
        color: theme.colors.gray08
    },
    eventsContainer: {
        backgroundColor: theme.colors.gray03,
        borderRadius: 6,
        margin: '0 5px 5px',
        a: {
            color: theme.colors.gray08,
            textDecoration: 'none',
            height: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
            svg: {
                height: 12,
                flexShrink: 0
            }
        },
        ':hover': {
            backgroundColor: theme.colors.gray05,
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    eventsContainerRestricted: {
        ':hover': {
            backgroundColor: theme.colors.gray03
        }
    },
    eventTitle: {
        marginLeft: 6,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    eventDate: {
        marginLeft: theme.margins.margin00,
        whiteSpace: 'nowrap'
    },
    lockIcon: {
        marginBottom: -10
    }
});
