import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import { PDFViewerMinimal } from 'components/PDFViewerMinimal';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class PopoverPDF extends PureComponent {
    static propTypes = {
        resizing: PropTypes.bool.isRequired,
        setResizing: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        hidePopover: PropTypes.func.isRequired,
        url: PropTypes.string
    };

    static defaultProps = {
        url: null
    };

    render() {
        const { styles, url, hidePopover, resizing, setResizing } = this.props;

        if (!url) {
            return null;
        }

        return (
            <Draggable onDrag={() => !resizing} bounds=".App" defaultClassName="popoverPDF" disabled={resizing}>
                <Div styles={styles.container} className="popoverPDF">
                    <Resizable
                        onResizeStart={() => setResizing(true)}
                        onResizeStop={() => setResizing(false)}
                        defaultSize={{
                            width: 540,
                            height: 430
                        }}
                    >
                        <Div styles={styles.padding}>
                            <Div styles={styles.header}>
                                <Text styles={styles.popoverTitle}>Drag and Resize</Text>
                                <Text styles={styles.popoverClose} onClick={hidePopover}>
                                    Close
                                </Text>
                            </Div>
                            <PDFViewerMinimal pdfUrl={url} styles={styles.viewer} />
                        </Div>
                    </Resizable>
                </Div>
            </Draggable>
        );
    }
}

export const PopoverPDFUI = compose(withStyleSheet(styleSheet))(PopoverPDF);
