export const styleSheet = theme => ({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#353546',
        display: 'flex'
    },
    logo: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
        flex: 1,
        a: {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            flexDirection: 'column',
            flex: 1,
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.colors.white01,
            p: {
                opacity: 0.3,
                letterSpacing: '1px',
                marginTop: theme.margins.margin00,
                textAlign: 'center',
                transition: 'all 0.2s ease-in-out'
            },
            svg: {
                opacity: 0.9,
                maxWidth: '100px',
                maxHeight: '34px',
                margin: 'auto'
            },
            ':hover': {
                svg: {
                    opacity: 1
                },
                p: {
                    opacity: 1
                }
            }
        }
    },
    sales: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
        flex: 1,
        marginTop: theme.margins.margin02,
        a: {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            flexDirection: 'column',
            flex: 1,
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.colors.white01,
            p: {
                opacity: 0.3,
                letterSpacing: '1px',
                marginTop: theme.margins.margin00,
                textAlign: 'center',
                transition: 'all 0.2s ease-in-out'
            },
            ':hover': {
                p: {
                    opacity: 1
                }
            }
        }
    },
    spacer: {
        flex: 1
    }
});
