import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { RecordingDetailsUI } from './ui';

export class RecordingDetails extends PureComponent {
    static displayName = 'RecordingDetailsContainer';

    static propTypes = {
        editing: PropTypes.bool.isRequired,
        equities: PropTypes.arrayOf(PropTypes.object),
        equityIds: PropTypes.arrayOf(PropTypes.string),
        errors: PropTypes.objectOf(PropTypes.any).isRequired,
        eventGroupId: PropTypes.string,
        eventGroupTitle: PropTypes.string,
        localeCode: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onCompleteEmailCreator: PropTypes.bool.isRequired,
        onFocus: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        taggedInput: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        touched: PropTypes.objectOf(PropTypes.any).isRequired,
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        equities: undefined,
        equityIds: [],
        eventGroupId: undefined,
        eventGroupTitle: undefined,
        localeCode: undefined,
        styles: undefined,
        taggedInput: undefined,
        tags: [],
        title: undefined
    };

    constructor(props) {
        super(props);

        this.onTaggedInputChange = this.onTaggedInputChange.bind(this);
    }

    // For new recordings, call the onBlur and onFocus callbacks
    // immediately to show a "Required" error message above the input.
    // This should prevent users from being confused why the submit button is disabled
    // if they scroll past the title input and try to submit.
    componentDidMount() {
        const { editing, onBlur, onFocus, title } = this.props;
        if (!editing && !title) {
            if (onBlur) {
                onBlur({
                    name: 'title',
                    value: title
                });
            }
            if (onFocus) onFocus({ name: 'title' });
        }
    }

    onTaggedInputChange({ event }) {
        const { onChange } = this.props;
        onChange({
            name: 'taggedInput',
            value: event.target?.value
        });
    }

    render() {
        const {
            equities,
            equityIds,
            errors,
            eventGroupId,
            eventGroupTitle,
            localeCode,
            onBlur,
            onChange,
            onCompleteEmailCreator,
            onFocus,
            styles,
            taggedInput,
            tags,
            title,
            touched,
            visible
        } = this.props;
        return (
            <RecordingDetailsUI
                equities={equities}
                equityIds={equityIds}
                errors={errors}
                eventGroupId={eventGroupId}
                eventGroupTitle={eventGroupTitle}
                localeCode={localeCode}
                onBlur={onBlur}
                onChange={onChange}
                onCompleteEmailCreator={onCompleteEmailCreator}
                onFocus={onFocus}
                onTaggedInputChange={this.onTaggedInputChange}
                showLocaleAutocomplete={false}
                styles={styles}
                taggedInput={taggedInput}
                tags={tags}
                title={title}
                touched={touched}
                visible={visible}
            />
        );
    }
}

export const RecordingDetailsContainer = compose()(RecordingDetails);
