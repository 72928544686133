export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: `${theme.margins.margin02} 0`
    },
    noEventsCountdown: {
        span: {
            fontSize: theme.fonts.fontSizes.size06,
            fontWeight: theme.fonts.fontWeights.bold
        }
    },
    addToCalendar: {
        '.addToCalendar': {
            display: 'flex',
            flexDirection: 'column',
            margin: `${theme.margins.margin00} 0 ${theme.margins.margin01} 0`,
            border: `1px solid ${theme.colors.gray01}`,
            borderRadius: '2px',
            svg: {
                marginLeft: theme.margins.margin00,
                g: {
                    fill: theme.colors.gray04
                }
            }
        }
    },
    proTips: {
        margin: 20,
        maxWidth: 600,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'none'
        }
    },
    proTipNotice: {
        borderRadius: 6,
        marginTop: 10,
        display: 'flex',
        '.noticeContent': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    watchlistDropdown: {
        marginLeft: 20,
        '.dropdown': {
            backgroundColor: theme.colors.white01,
            boxShadow: `inset 0 0 0 1px ${theme.colors.gray05}`,
            ':hover': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray04}`
            }
        }
    }
});
