import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { routes } from 'routes';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { ModuleTable } from 'components/ModuleTable';
import { ModuleTr } from 'components/ModuleTr';
import { ModuleTd } from 'components/ModuleTd';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { InternalLink } from 'components/InternalLink';
import { Tooltip } from 'components/Tooltip';
import { get } from 'utils';

import orderBy from 'lodash/orderBy';
import { styleSheet } from './stylesheet';

class NewsMentions extends PureComponent {
    static propTypes = {
        collapsedDashboards: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashboardStreamMatches: PropTypes.arrayOf(PropTypes.any),
        matchCount: PropTypes.number.isRequired,
        mentionsTitle: PropTypes.string.isRequired,
        onTermSelect: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedIndex: PropTypes.number.isRequired,
        selectedNode: PropTypes.string,
        streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        streamMatches: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleDash: PropTypes.func
    };

    static defaultProps = {
        dashboardStreamMatches: [],
        passedStyles: {},
        selectedNode: null,
        streamId: null,
        streamMatches: [],
        toggleDash: () => {}
    };

    constructor(props) {
        super(props);

        this.renderMatch = this.renderMatch.bind(this);
    }

    renderMatch(match) {
        const {
            selectedIndex,
            matchCount,
            styles,
            onTermSelect,
            selectedNode,
            theme,
            streamId: currentStreamId
        } = this.props;
        const sentimentTooltip = (
            <Text size={1} styles={styles.sentimentTooltip}>
                Measures the sentiment score for this topic within this article and highlights any deviation from
                mentions of the same topic in previous articles
            </Text>
        );
        const selectedEventId = (selectedNode || '').toString();
        const { streamId, name: dataLabel } = match;
        const matches = get(match, 'matches', []);
        const contentIds = matches.map(m => m?.contentId?.toString()).sort();
        const totalInstances = contentIds.length;
        const currentIndex = contentIds.indexOf(selectedEventId.toString());
        const onClick = totalInstances > 0 ? () => onTermSelect(streamId) : undefined;
        const rowStyle = totalInstances > 0 ? styles.dataRowClickable : styles.dataRow;
        const indexStyle = currentIndex + 1 > 0 ? styles.dataRowIndexActive : styles.dataRowIndex;
        const sentimentMovement = get(match, 'sentimentMovement');
        const sentimentPercent = get(match, 'sentimentMovementPercent');
        const isSelected = streamId === currentStreamId;
        const isPositive = sentimentMovement && sentimentMovement > 0;
        let sentimentStyle = styles.sentimentText;
        if (isPositive) {
            sentimentStyle = styles.sentimentTextPositive;
        } else {
            sentimentStyle = styles.sentimentTextNegative;
        }

        return (
            <ModuleTr key={dataLabel} styles={rowStyle} onClick={onClick}>
                <ModuleTd
                    styles={{
                        ...styles.dataRowValue,
                        backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                    }}
                >
                    <Div styles={styles.flexRow} className="dataRow">
                        <Text size={3} className="dataLabel" weight="normal">
                            {dataLabel}
                        </Text>
                    </Div>
                    {sentimentMovement ? (
                        <Tooltip
                            slideIn
                            content={sentimentTooltip}
                            isEnabled
                            useElementOffsetLeft
                            useElementOffsetBottom
                            xOffset={-8}
                            yOffset={6}
                        >
                            {({ showTooltip, hideTooltip }) => (
                                <Div styles={styles.sentiment}>
                                    <Span onMouseLeave={hideTooltip} onMouseEnter={showTooltip}>
                                        <Icon type="circleInfo02" color={theme.colors.gray04} />
                                    </Span>
                                    <Text styles={sentimentStyle} size={1}>
                                        {`Sentiment ${isPositive ? 'up' : 'down'}`}
                                    </Text>
                                    {new Array(sentimentPercent).fill(sentimentPercent).map((percent, index) => {
                                        const iconKey = `${index}-${dataLabel}`;
                                        return (
                                            <Icon
                                                key={iconKey}
                                                type={isPositive ? 'triangleUp' : 'triangleDown'}
                                                color={isPositive ? theme.colors.green03 : theme.colors.red01}
                                            />
                                        );
                                    })}
                                </Div>
                            )}
                        </Tooltip>
                    ) : null}
                </ModuleTd>
                {totalInstances >= 0 ? (
                    <ModuleTd
                        styles={{
                            backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                        }}
                    >
                        {isSelected && (
                            <Text size={1} uppercase styles={indexStyle}>
                                {`${selectedIndex}/${matchCount}`}
                            </Text>
                        )}
                    </ModuleTd>
                ) : (
                    <ModuleTd
                        styles={{
                            backgroundColor: isSelected ? `${theme.colors.blue07} !important` : 'auto'
                        }}
                    />
                )}
            </ModuleTr>
        );
    }

    renderDataRows() {
        const { streamMatches } = this.props;

        return orderBy(
            streamMatches,
            row => {
                const matches = get(row, 'matches', []);
                return matches.length;
            },
            'desc'
        ).map(this.renderMatch);
    }

    renderDataRowsByDash() {
        const { dashboardStreamMatches, styles, theme, toggleDash, collapsedDashboards } = this.props;

        return orderBy(
            dashboardStreamMatches,
            dash => {
                const streams = get(dash, 'streams', []);
                return streams.length;
            },
            'desc'
        ).map(dash => {
            const dashName = get(dash, 'name');
            const dashId = get(dash, 'id');
            const streams = get(dash, 'streams', []);
            const collapsed = collapsedDashboards.includes(dashId);
            const headerStyles = !collapsed ? styles.dashHeader : styles.dashHeaderCollapsed;
            return (
                <Fragment key={dashId}>
                    <Div styles={headerStyles} onClick={() => toggleDash(dashId)}>
                        <Icon type="chevron02" color={theme.colors.black01} />
                        <Text weight="medium" size={3} styles={styles.dashTitle}>
                            {dashName}
                        </Text>
                        <Div styles={styles.spacer} />
                        <InternalLink to={generatePath(routes.dashboard, { dashboardId: dashId })}>
                            <Icon type="columns02" color={theme.colors.gray04} />
                        </InternalLink>
                    </Div>
                    {!collapsed && (
                        <ModuleTable wrapperStyles={styles.table} alternate>
                            {streams.map(this.renderMatch)}
                        </ModuleTable>
                    )}
                </Fragment>
            );
        });
    }

    render() {
        const { styles, passedStyles, mentionsTitle, streamMatches, dashboardStreamMatches } = this.props;

        if (
            (!streamMatches || streamMatches.length === 0) &&
            (!dashboardStreamMatches || dashboardStreamMatches.length === 0)
        ) {
            return null;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {dashboardStreamMatches.length > 0 ? (
                    this.renderDataRowsByDash()
                ) : (
                    <Fragment>
                        <Div styles={styles.header}>
                            <Text weight="medium" size={3} styles={styles.dataTitle}>
                                {mentionsTitle}
                            </Text>
                        </Div>
                        <ModuleTable wrapperStyles={styles.table} alternate>
                            {this.renderDataRows()}
                        </ModuleTable>
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const NewsMentionsUI = compose(withStyleSheet(styleSheet))(NewsMentions);
