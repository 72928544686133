import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import XDate from 'xdate';
import { getHighlightColor, toDateTimeString } from 'utils';

const HighlightsPDF = ({ Document, highlights, isMultiSource, Link, Page, PDFText, styles, subTitle, title, View }) => (
    <Document>
        <Page size="A4" style={styles.pdfPage}>
            <View style={styles.pdfHeader}>
                <PDFText style={styles.pdfTitle}>{title}</PDFText>
                {subTitle && <PDFText style={styles.pdfSubTitle}>{subTitle}</PDFText>}
            </View>
            {highlights.map(
                ({
                    created,
                    creatorName,
                    date,
                    highlight,
                    highlightColor,
                    id,
                    note,
                    noteRemoved,
                    speakerAffiliation,
                    speakerName,
                    speakerTitle,
                    startTimestamp,
                    title: targetTitle
                }) => (
                    <View wrap={false} style={styles.pdfHighlightContainer} key={id}>
                        <View
                            style={{
                                ...styles.pdfHighlight,
                                backgroundColor: getHighlightColor(highlightColor)?.color,
                                borderBottom: `1pt solid ${getHighlightColor(highlightColor)?.dark}`
                            }}
                        >
                            {isMultiSource && (
                                <Fragment>
                                    {targetTitle && <PDFText style={styles.pdfHighlightSpeaker}>{targetTitle}</PDFText>}
                                    {date && (
                                        <PDFText
                                            style={{
                                                ...styles.pdfHighlightDate,
                                                color: getHighlightColor(highlightColor)?.dark02
                                            }}
                                        >
                                            {toDateTimeString(date, false, false, 'h:mm TT MMM dS, yyyy')}
                                        </PDFText>
                                    )}
                                </Fragment>
                            )}
                            {speakerName && (
                                <PDFText
                                    style={{
                                        ...styles.pdfHighlightSpeaker,
                                        ...(isMultiSource ? styles.pdfHighlightSpeakerMulti : {})
                                    }}
                                >
                                    {speakerName}
                                </PDFText>
                            )}
                            {(speakerTitle || speakerAffiliation) && (
                                <PDFText style={styles.pdfHighlightSpeakerInfo}>
                                    {`${
                                        speakerTitle && speakerAffiliation ? `${speakerTitle}, ` : speakerTitle
                                    }${speakerAffiliation}`}
                                </PDFText>
                            )}
                            {!isMultiSource && startTimestamp && (
                                <PDFText
                                    style={{
                                        ...styles.pdfHighlightDate,
                                        color: getHighlightColor(highlightColor)?.dark02
                                    }}
                                >
                                    {toDateTimeString(startTimestamp, false, false, 'h:mm TT MMM dS, yyyy')}
                                </PDFText>
                            )}
                            <PDFText style={styles.pdfHighlightText}>{highlight.replace(/<[^>]*>?/gm, '')}</PDFText>
                        </View>
                        {!noteRemoved && (
                            <View style={styles.pdfNoteContainer}>
                                <PDFText style={styles.pdfNoteCreator}>{creatorName}</PDFText>
                                <PDFText style={styles.pdfNoteDate}>
                                    {new XDate(created).toString('h:mm TT MMM dS, yyyy')}
                                </PDFText>
                                {note && <PDFText style={styles.pdfNoteText}>{note}</PDFText>}
                            </View>
                        )}
                    </View>
                )
            )}
            <View style={styles.pdfWatermark}>
                <PDFText style={styles.pdfWatermarkText}>
                    <Link href="http://www.aiera.com">Highlights generated using Aiera.com</Link>
                </PDFText>
            </View>
        </Page>
    </Document>
);

HighlightsPDF.propTypes = {
    Document: PropTypes.elementType.isRequired,
    highlights: PropTypes.arrayOf(PropTypes.any),
    isMultiSource: PropTypes.bool,
    Link: PropTypes.elementType.isRequired,
    Page: PropTypes.elementType.isRequired,
    PDFText: PropTypes.elementType.isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    View: PropTypes.elementType.isRequired
};

HighlightsPDF.defaultProps = {
    highlights: [],
    isMultiSource: false,
    subTitle: '',
    title: ''
};

export const generatePdfDocument = async ({ highlights, isMultiSource, onComplete, styles, subTitle, title }) => {
    await import('@react-pdf/renderer').then(async ({ Document, Page, Text, View, Link, pdf }) => {
        const blob = await pdf(
            <HighlightsPDF
                Document={Document}
                highlights={highlights}
                isMultiSource={isMultiSource}
                Link={Link}
                Page={Page}
                PDFText={Text}
                styles={styles}
                subTitle={subTitle}
                title={title}
                View={View}
            />
        ).toBlob();
        onComplete();
        saveAs(blob, 'aiera-highlights.pdf');
    });
};
