export const styleSheet = theme => ({
    container: {
        flexShrink: 0,
        display: 'flex',
        borderRadius: '10px',
        backgroundColor: theme.colors.blue08,
        boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.10)`,
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            backgroundColor: theme.colors.blue04,
            '.circleHoverMenu': {}
        }
    },
    hoverMenu: {
        ...theme.content.hoverMenu
    },
    menuOption: {
        ...theme.content.hoverMenuOption
    },
    iconContainer: {
        width: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        marginBottom: 2,
        textAlign: 'center'
    }
});
