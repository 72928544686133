import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { routes } from 'routes';
import { PREFERENCES, TAB_TYPES } from 'consts';
import { withQueryParams } from 'hoc/url';
import { tabRemove, tabReplace } from 'actions/tabs';
import { withUserPreferences } from 'graphql/user';
import { parseTabId } from 'utils';
import { popoutManager } from 'utils/popout';
import { ToolbarUI } from './ui';

function popoutInfo(tabId, search) {
    const { tabType, id } = parseTabId(tabId);
    if ([TAB_TYPES.event, TAB_TYPES.audioCall].includes(tabType)) {
        return {
            url: `${generatePath(routes.popoutEvent, { type: 'event', audioCallId: id })}${search}`,
            name: `popout-event-${id}`,
            width: 950
        };
    }
    if (tabType === TAB_TYPES.news) {
        return {
            url: `${generatePath(routes.popoutNews, { type: 'news', newsId: id })}${search}`,
            name: `popout-news-${id}`,
            width: 750
        };
    }

    if (tabType === TAB_TYPES.streetAccount) {
        return {
            url: `${generatePath(routes.popoutStreetAccount, { type: 'streetaccount', streetAccountId: id })}${search}`,
            name: `popout-sa-${id}`,
            width: 750
        };
    }

    if (tabType === TAB_TYPES.spotlight) {
        return {
            url: `${generatePath(routes.popoutSpotlight, { type: 'spotlight', spotlightId: id })}${search}`,
            name: `popout-spotlight-${id}`,
            width: 750
        };
    }
    return undefined;
}

export class Toolbar extends PureComponent {
    static displayName = 'ToolbarContainer';

    static propTypes = {
        beforeMinimize: PropTypes.func.isRequired,
        isFullScreen: PropTypes.bool,
        location: PropTypes.shape({ search: PropTypes.string }).isRequired,
        replaceTab: PropTypes.func.isRequired,
        saveUXPreference: PropTypes.func.isRequired,
        tabId: PropTypes.string,
        tabs: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        isFullScreen: false,
        tabId: null,
        tabs: []
    };

    constructor(props) {
        super(props);

        this.onMinimize = this.onMinimize.bind(this);
        this.onReplace = this.onReplace.bind(this);
        this.onFullScreen = this.onFullScreen.bind(this);
        this.onPopout = this.onPopout.bind(this);
    }

    onMinimize() {
        const { beforeMinimize, tabId } = this.props;

        if (beforeMinimize) {
            beforeMinimize(tabId);
        }
    }

    onReplace() {
        const { replaceTab, tabs, tabId } = this.props;
        const originalTabId = tabs[0];
        if (originalTabId) {
            replaceTab(originalTabId, tabId);
        }
    }

    onFullScreen() {
        const { isFullScreen, saveUXPreference } = this.props;
        saveUXPreference({
            name: PREFERENCES.floatingTabFullScreen.name,
            value: !isFullScreen,
            hideSuccessBanner: true
        });
    }

    onPopout() {
        const {
            tabId,
            location: { search }
        } = this.props;
        const { url, name, width, height } = popoutInfo(tabId, search || '');
        popoutManager.open({ url, name, width, height });
    }

    render() {
        const { isFullScreen, tabId, ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <ToolbarUI
                {...rest}
                isFullScreen={isFullScreen}
                onMinimize={this.onMinimize}
                onPopout={popoutInfo(tabId) ? this.onPopout : null}
                onReplace={this.onReplace}
                onFullScreen={this.onFullScreen}
                tabId={tabId}
            />
        );
    }
}

const mapDispatchToProps = {
    removeTab: tabRemove,
    replaceTab: tabReplace
};

export const ToolbarContainer = compose(
    withQueryParams(),
    connect(undefined, mapDispatchToProps),
    withUserPreferences()
)(Toolbar);
