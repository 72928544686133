import React, { PureComponent } from 'react';
import { generateTabURL, generateTabId, get } from 'utils';
import { Hint } from 'components/Hint';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { Span } from 'components/Span';
import { withStyleSheet } from 'hoc/styles';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { PDFViewer } from 'components/PDFViewer';
import { generatePath, Link } from 'react-router-dom';
import { routes } from 'routes';
import { constants } from 'consts';
import { Notice } from 'components/Notice';
import { LoaderLogo } from 'components/LoaderLogo';
import { styleSheet } from './stylesheet';

class EventPDF extends PureComponent {
    static propTypes = {
        company: PropTypes.objectOf(PropTypes.any),
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        eventId: PropTypes.string,
        hasOtherAttachment: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        initialSearchTerm: PropTypes.string,
        minimized: PropTypes.bool,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pdfUrl: PropTypes.string.isRequired,
        pathname: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string,
        eventTitle: PropTypes.string.isRequired,
        dateString: PropTypes.string.isRequired
    };

    static defaultProps = {
        pathname: undefined,
        company: undefined,
        eventId: undefined,
        initialSearchTerm: undefined,
        minimized: false,
        passedStyles: {},
        setToolbarTitle: null,
        type: undefined
    };

    componentDidMount() {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title) {
            setToolbarTitle(title);
        }
    }

    componentDidUpdate({ title: prevTitle }) {
        const { setToolbarTitle, title } = this.props;

        if (setToolbarTitle && title !== prevTitle) {
            setToolbarTitle(title);
        }
    }

    renderCompany() {
        const { company, styles, theme } = this.props;
        const ticker = get(company, 'primaryInstrument.primaryQuote.localTicker', null);
        const exchange = get(company, 'primaryInstrument.primaryQuote.exchange.shortName', null);
        const name = get(company, 'commonName', 'No company');
        const companyId = get(company, 'id');
        const companyPath = companyId ? generatePath(routes.company, { companyId }) : '';

        if (!ticker) {
            return null;
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Hint
                        text="View Company"
                        disabled={m}
                        styles={styles.company}
                        onClick={() => {}}
                        xOffset={300}
                        yOffset={-37}
                    >
                        {!m && (
                            <Link to={companyPath}>
                                {name && (
                                    <Text size={3} className="companyName">
                                        {name}
                                    </Text>
                                )}
                                {ticker && (
                                    <Text size={0} styles={styles.companyTicker} uppercase>
                                        {ticker}
                                        <Span styles={styles.exchange}>{exchange}</Span>
                                    </Text>
                                )}
                            </Link>
                        )}
                        {ticker && <Icon type="company" color={theme.colors.gray04} />}
                    </Hint>
                )}
            </MediaQuery>
        );
    }

    renderEvent() {
        const { eventId, eventTitle, company, dateString, styles, theme, pathname } = this.props;
        const ticker = get(company, 'primaryInstrument.primaryQuote.localTicker', null);
        const exchange = get(company, 'primaryInstrument.primaryQuote.exchange.shortName', null);
        const eventPath = generateTabURL({ eventId, pathname });
        return (
            <Div styles={styles.event}>
                <Div styles={styles.eventText}>
                    <Link to={eventPath} size={3}>
                        {eventTitle}
                    </Link>
                    <Text styles={styles.eventTickerRow} uppercase size={0}>
                        {ticker}
                        <Text styles={styles.eventExchange} size={0} uppercase span>
                            {exchange}
                        </Text>
                        <Text styles={styles.eventDate} size={0} uppercase span>
                            {dateString}
                        </Text>
                    </Text>
                </Div>
                <Link to={eventPath} className="eventBack">
                    <Icon type="arrowLeft" color={theme.colors.gray04} />
                    <Text size={3}>View Event</Text>
                </Link>
            </Div>
        );
    }

    renderOtherAttachment() {
        const { type, eventId, pathname, theme } = this.props;
        const label = type === 'slides' ? 'View Press Release' : 'View Slides';
        const tabId = generateTabId({
            tabType: type === 'slides' ? constants.TAB_TYPES.pdfPresentation : constants.TAB_TYPES.pdfSlides,
            id: eventId
        });
        const attachmentPath = generateTabURL({
            tabId,
            pathname
        });
        return (
            <Link to={attachmentPath} className="otherAttachment">
                <Icon type="pdf" color={theme.colors.gray04} />
                <Text size={3}>{label}</Text>
            </Link>
        );
    }

    renderHeader() {
        const { styles, type, hasOtherAttachment } = this.props;
        return (
            <Div styles={styles.header}>
                {this.renderCompany()}
                {this.renderEvent()}
                {!!type && !!hasOtherAttachment && this.renderOtherAttachment()}
            </Div>
        );
    }

    render() {
        const {
            type,
            loading,
            eventId,
            pdfUrl,
            styles,
            passedStyles,
            containerRef,
            minimized,
            initialSearchTerm
        } = this.props;
        const targetType = type === 'slides' ? 'event_slides' : 'event_press_release';

        if (minimized) {
            return null;
        }

        return (
            <Div ref={containerRef} styles={{ ...styles.container, ...passedStyles }}>
                {this.renderHeader()}
                {pdfUrl ? (
                    <PDFViewer
                        pdfUrl={pdfUrl}
                        targetId={eventId}
                        targetType={targetType}
                        initialSearchTerm={initialSearchTerm}
                    />
                ) : (
                    <Div styles={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {loading ? (
                            <LoaderLogo height={80} />
                        ) : (
                            <Notice type="info" heading="Unable to load PDF" styles={{ borderRadius: 6 }}>
                                Sorry, we&apos;re still processing this PDF. Please check back later.
                            </Notice>
                        )}
                    </Div>
                )}
            </Div>
        );
    }
}

export const EventPDFUI = compose(withStyleSheet(styleSheet))(EventPDF);
