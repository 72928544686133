import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers, withPropsOnChange } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withTrackUserActivity, withGetUser } from 'graphql/user';
import { hasPermission, get, safeRegExp } from 'utils';
import { PERMISSIONS } from 'consts';
import { withData } from './data';
import { NewsUI } from './ui';

export class News extends PureComponent {
    static displayName = 'NewsContainer';

    static propTypes = {
        body: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        companies: PropTypes.arrayOf(PropTypes.any),
        company: PropTypes.string,
        companyId: PropTypes.string,
        contentType: PropTypes.string,
        exchange: PropTypes.string,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isArchived: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        newsId: PropTypes.string,
        newsError: PropTypes.bool,
        newsLoading: PropTypes.bool,
        newsSource: PropTypes.string,
        publishedDate: PropTypes.string,
        selectSidebarTab: PropTypes.func.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        setToolbarTitle: PropTypes.func,
        shareBookmarks: PropTypes.bool,
        sidebarTab: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string,
        trackContentActivity: PropTypes.func.isRequired,
        url: PropTypes.string,
        userId: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        body: undefined,
        companies: [],
        company: undefined,
        companyId: undefined,
        contentType: 'news',
        exchange: undefined,
        newsError: false,
        newsId: null,
        newsLoading: false,
        newsSource: undefined,
        publishedDate: undefined,
        setToolbarTitle: null,
        shareBookmarks: false,
        streamId: null,
        tags: [],
        ticker: undefined,
        title: 'Loading...',
        url: undefined,
        userId: undefined,
        userTags: []
    };

    constructor(props) {
        super(props);

        this.selectHighlightId = this.selectHighlightId.bind(this);
        this.containerRef = createRef();
        this.documentTitle = document.title;
        this.selectBlock = this.selectBlock.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.highlightSearch = this.highlightSearch.bind(this);

        this.state = {
            selectedHighlightId: undefined,
            searchTerm: '',
            matchCount: 0
        };
    }

    componentDidMount() {
        const { trackContentActivity, newsId } = this.props;
        if (newsId) {
            trackContentActivity(newsId);
        }
        this.maybeScrollToMatch();
    }

    componentDidUpdate({ newsId: prevNewsId, streamId: prevStreamId, newsLoading: prevLoading, title: prevTitle }) {
        const { newsId, streamId, trackContentActivity, newsLoading, title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | News | ${title}`;
        }

        if (newsId !== prevNewsId || streamId !== prevStreamId || newsLoading !== prevLoading) {
            trackContentActivity(newsId);
            this.maybeScrollToMatch();
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    // Split the text and insert styled spans when there are matches for the searchTerm
    highlightSearch() {
        const { body } = this.props;
        const { searchTerm } = this.state;
        let highlighted = [body];

        if (searchTerm) {
            const parts = body.split(safeRegExp(searchTerm));
            highlighted = parts.map(part => {
                if (part.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return `<span class="searchTerm">${part}</span>`;
                }
                return part;
            });
            return highlighted.join('');
        }

        return body;
    }

    maybeScrollToMatch() {
        const { body, streamId } = this.props;
        if (streamId && body && this.containerRef.current) {
            const highlights = this.containerRef.current.getElementsByTagName('em');
            if (highlights.length) {
                const firstHighlight = highlights[0];
                firstHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstHighlight.parentNode.className = 'selectedBlock';
            }
            this.setState({
                matchCount: highlights.length
            });
        }
    }

    onSearch(searchTerm) {
        this.setState({
            searchTerm
        });
    }

    selectBlock(newBlock) {
        const resetBlocks = new Promise(resolve => {
            const selectedBlocks = this.containerRef?.current?.querySelectorAll('.selectedBlock');
            selectedBlocks.forEach(node => {
                // eslint-disable-next-line
                node.className = '';
            });

            resolve();
        });

        resetBlocks.then(() => {
            if (newBlock) {
                newBlock.scrollIntoView({ block: 'center', behavior: 'smooth' });
                // eslint-disable-next-line
                newBlock.className = 'selectedBlock';
            }
        });
    }

    selectHighlightId(e) {
        const { userId } = this.props;
        const selectedHighlightId = e.target?.closest('[data-highlightid]')?.getAttribute('data-highlightid');
        const creatorId = e.target?.closest('[data-userid]')?.getAttribute('data-userid');
        if (creatorId === userId) {
            this.setState({
                selectedHighlightId
            });
        } else {
            this.setState({
                selectedHighlightId: undefined
            });
        }
    }

    render() {
        const {
            categories,
            companies,
            company,
            companyId,
            contentType,
            exchange,
            highlightsFilterKey,
            highlightsSortKey,
            isArchived,
            isRead,
            isStarred,
            newsId,
            publishedDate,
            selectSidebarTab,
            setHighlightsFilter,
            setHighlightsSort,
            setToolbarTitle,
            shareBookmarks,
            sidebarTab,
            newsError,
            newsLoading,
            newsSource,
            tags,
            ticker,
            title,
            userTags,
            url
        } = this.props;
        const { matchCount, selectedHighlightId } = this.state;
        return (
            <NewsUI
                body={this.highlightSearch()}
                categories={categories}
                companies={companies}
                company={company}
                companyId={companyId}
                containerRef={this.containerRef}
                contentType={contentType}
                errorLoadingDetails={newsError}
                exchange={exchange}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                isArchived={isArchived}
                isRead={isRead}
                isStarred={isStarred}
                loadingDetails={newsLoading}
                matchCount={matchCount}
                newsId={newsId}
                newsSource={newsSource}
                onSearch={this.onSearch}
                publishedDate={publishedDate}
                selectBlock={this.selectBlock}
                selectedHighlightId={selectedHighlightId}
                selectHighlightId={this.selectHighlightId}
                selectSidebarTab={selectSidebarTab}
                setHighlightsFilter={setHighlightsFilter}
                setHighlightsSort={setHighlightsSort}
                setToolbarTitle={setToolbarTitle}
                shareBookmarks={shareBookmarks}
                sidebarTab={sidebarTab}
                tags={tags}
                ticker={ticker}
                title={title}
                url={url}
                userTags={userTags}
            />
        );
    }
}

export const NewsContainer = compose(
    withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
    withPropsOnChange(['user'], ({ user }) => ({
        highlightsEnabled: hasPermission(user, PERMISSIONS.featureBookmarks),
        userId: get(user, 'id')
    })),
    withTrackUserActivity(),
    withUrlContext(['streamId']),
    withData(),
    withStateHandlers(
        ({ highlightsEnabled }) => ({
            sidebarTab: highlightsEnabled ? 'highlights' : 'keyMentions',
            highlightsFilterKey: 'all',
            highlightsSortKey: 'newest'
        }),
        {
            selectSidebarTab: () => sidebarTab => ({
                sidebarTab
            }),
            setHighlightsFilter: () => ({ value }) => ({
                highlightsFilterKey: value
            }),
            setHighlightsSort: () => ({ value }) => ({
                highlightsSortKey: value
            })
        }
    )
)(News);
