import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { compose } from 'recompose';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { get } from 'utils';

import { EventSidebarUI } from './ui';

export class EventSidebar extends PureComponent {
    static displayName = 'EventSidebarContainer';

    static propTypes = {
        equityId: PropTypes.string,
        eventId: PropTypes.string,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isPublic: PropTypes.bool.isRequired,
        initialSearch: PropTypes.string,
        itemId: PropTypes.string,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        loading: PropTypes.bool,
        localMonitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        onNodeSelect: PropTypes.func.isRequired,
        pageId: PropTypes.string,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        selectedNode: PropTypes.string,
        selectedTab: PropTypes.string.isRequired,
        selectTab: PropTypes.func.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        streamMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        styles: PropTypes.objectOf(PropTypes.any),
        tonalSentiment: PropTypes.objectOf(PropTypes.any),
        transcriptEvents: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        eventId: null,
        equityId: null,
        initialSearch: undefined,
        itemId: null,
        keyMentions: [],
        loading: false,
        localMonitorMatches: [],
        mediaPlayer: undefined,
        monitorMatches: [],
        pageId: null,
        selectedNode: null,
        streamMatches: [],
        styles: undefined,
        tonalSentiment: undefined,
        transcriptEvents: []
    };

    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.onSearchFocus = this.onSearchFocus.bind(this);
        this.onSentimentSelect = this.onSentimentSelect.bind(this);
        this.onTermSelect = this.onTermSelect.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.selectDefaultTab = this.selectDefaultTab.bind(this);
        this.toggleDash = this.toggleDash.bind(this);

        this.scrollRef = createRef();

        this.state = {
            collapsedDashboards: [],
            searchTerm: '',
            searchResults: [],
            selectedKeyMentionId: null,
            selectedSentiment: null
        };
    }

    componentDidMount() {
        const { initialSearch } = this.props;
        if (initialSearch) {
            this.onSearch({ value: initialSearch });
        }
    }

    componentDidUpdate(prevProps) {
        const prevMonitorMatches = get(prevProps, 'monitorMatches', []);
        const prevTranscriptEvents = get(prevProps, 'transcriptEvents', []);
        const { monitorMatches, transcriptEvents } = this.props;
        const { searchTerm, searchResults } = this.state;

        if (monitorMatches.length !== prevMonitorMatches.length) {
            const collapsedDashboards = [];
            monitorMatches.forEach(({ id }, index) => {
                if (index !== 0) {
                    collapsedDashboards.push(id);
                }
            });
            this.setState({ collapsedDashboards });
        }

        // If we have a search term (from initial search)
        // but no results, after transcript loads
        // rerun the search
        if (
            searchTerm &&
            prevTranscriptEvents.length === 0 &&
            transcriptEvents.length > 0 &&
            searchResults.length === 0
        ) {
            this.handleKeywordSearch(searchTerm);
        }
    }

    handleKeywordSearch(searchTerm) {
        const { transcriptEvents } = this.props;
        let searchResults = [];

        // Only do the keyword lookup if the search term is at least 2 chars
        if (searchTerm.length > 1 && transcriptEvents && transcriptEvents.length > 0) {
            searchResults = [];
            searchResults = transcriptEvents.filter(event => {
                const { transcript } = event;
                return (
                    transcript &&
                    transcript
                        .replace(/(<([^>]+)>)/gi, '')
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                );
            });
        }

        this.setState({
            searchResults
        });
    }

    onSearchFocus() {
        const { searchTerm } = this.state;
        const { selectedTab, selectTab } = this.props;

        if (searchTerm && searchTerm.length > 0 && selectedTab !== 'searchResults') {
            selectTab('searchResults');
        }
    }

    onSearch({ value }) {
        const { selectedTab, selectTab } = this.props;
        this.setState(
            {
                searchTerm: value
            },
            () => {
                if (selectedTab !== 'searchResults' && value && value.length > 0) {
                    selectTab('searchResults');
                } else if ((!value || value.length === 0) && selectedTab === 'searchResults') {
                    this.selectDefaultTab();
                }
                this.handleKeywordSearch(value);
            }
        );
    }

    onSentimentSelect(selectedSentiment) {
        this.setState({ selectedSentiment });
    }

    onTermSelect(streamId) {
        this.scrollRef.current.scrollTop = 0;
        this.setState({
            selectedKeyMentionId: streamId
        });
    }

    selectDefaultTab() {
        const { selectTab } = this.props;
        selectTab('keyMentions');
    }

    toggleDash(dashId) {
        this.setState(({ collapsedDashboards }) => {
            const dashIds = new Set(collapsedDashboards);
            if (dashIds.has(dashId)) {
                dashIds.delete(dashId);
            } else {
                dashIds.add(dashId);
            }

            return {
                collapsedDashboards: [...dashIds]
            };
        });
    }

    render() {
        const {
            equityId,
            eventId,
            highlightsFilterKey,
            highlightsSortKey,
            isPublic,
            itemId,
            keyMentions,
            loading,
            localMonitorMatches,
            mediaPlayer,
            monitorMatches,
            onNodeSelect,
            pageId,
            selectedNode,
            selectedTab,
            selectTab,
            setHighlightsFilter,
            setHighlightsSort,
            shareBookmarks,
            streamMatches,
            styles,
            tonalSentiment
        } = this.props;
        const { collapsedDashboards, searchTerm, searchResults, selectedKeyMentionId, selectedSentiment } = this.state;
        const selectedTerm = streamMatches.find(stream => get(stream, 'streamId') === selectedKeyMentionId);
        return (
            <EventSidebarUI
                collapsedDashboards={collapsedDashboards}
                eventId={eventId}
                equityId={equityId}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                isPublic={isPublic}
                itemId={itemId}
                keyMentions={keyMentions}
                loading={loading}
                localMonitorMatches={localMonitorMatches}
                mediaPlayer={mediaPlayer}
                monitorMatches={monitorMatches}
                onNodeSelect={onNodeSelect}
                onSearch={this.onSearch}
                onSearchFocus={this.onSearchFocus}
                onSentimentSelect={this.onSentimentSelect}
                onTermSelect={this.onTermSelect}
                pageId={pageId}
                scrollRef={this.scrollRef}
                searchResults={searchResults}
                searchTerm={searchTerm}
                selectTab={selectTab}
                selectedNode={selectedNode}
                selectedSentiment={selectedSentiment}
                selectedTab={selectedTab}
                selectedTerm={selectedTerm}
                setHighlightsFilter={setHighlightsFilter}
                setHighlightsSort={setHighlightsSort}
                shareBookmarks={shareBookmarks}
                styles={styles}
                toggleDash={this.toggleDash}
                tonalSentiment={tonalSentiment}
            />
        );
    }
}

export const EventSidebarContainer = compose(
    withUrlContext(['pageId', 'itemId', 'initialSearch']),
    withEventMediaPlayer({ allowLiveStream: true, fetchPolicy: 'cache-only' }),
    withReporting()
)(EventSidebar);
