import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { get } from 'utils';

import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { LoaderDots } from 'components/LoaderDots';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';

import { styleSheet } from './stylesheet';

class BulkFollowIdentifiersResults extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        checkedEquityIds: PropTypes.arrayOf(PropTypes.any),
        equities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        failedIdentifiers: PropTypes.arrayOf(PropTypes.string),
        loading: PropTypes.bool,
        onResultSelect: PropTypes.func.isRequired,
        onSearchChange: PropTypes.func.isRequired,
        onSearchInputKeyDown: PropTypes.func.isRequired,
        onUnmatchedIdentifierChange: PropTypes.func.isRequired,
        requestedIdentifiers: PropTypes.arrayOf(PropTypes.string),
        requestIdentifier: PropTypes.func.isRequired,
        resultsContainerRef: PropTypes.objectOf(PropTypes.object),
        searchInput: PropTypes.string.isRequired,
        searchInputRef: PropTypes.objectOf(PropTypes.object),
        searchResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        searchedUnmatchedIdentifier: PropTypes.string,
        searchUnmatchedIdentifier: PropTypes.func.isRequired,
        sectorEquities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        sectorsLoading: PropTypes.bool.isRequired,
        setMatchedEquityNode: PropTypes.func.isRequired,
        showSearchResults: PropTypes.bool.isRequired,
        subSectorEquities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        subSectorsLoading: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        toggleEquity: PropTypes.func.isRequired,
        unmatchedInputs: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        checkedEquityIds: [],
        equities: [],
        failedIdentifiers: [],
        loading: false,
        passedStyles: {},
        requestedIdentifiers: [],
        resultsContainerRef: null,
        searchInputRef: null,
        searchResults: null,
        searchedUnmatchedIdentifier: null,
        sectorEquities: [],
        subSectorEquities: []
    };

    renderResults() {
        const {
            styles,
            theme,
            checkedEquityIds,
            equities,
            loading,
            onSearchChange,
            onSearchInputKeyDown,
            resultsContainerRef,
            searchInput,
            searchInputRef,
            sectorEquities,
            sectorsLoading,
            showSearchResults,
            subSectorEquities,
            subSectorsLoading,
            title
        } = this.props;
        const rows = [];
        const numEquities = (equities || []).length + (sectorEquities || []).length + (subSectorEquities || []).length;

        // Add equities resolved from identifiers
        if (loading && equities && equities.length === 0) {
            rows.push(<LoaderDots key="loader-identifier-equities" styles={styles.resultsLoading} />);
        } else if (equities && equities.length > 0) {
            equities.forEach((equity, eqIndex) => {
                if (get(equity, 'matches', []).length > 0) {
                    rows.push(this.renderMatchRow(equity));
                } else {
                    rows.push(this.renderNoMatchRow(equity, eqIndex));
                }
            });
        }

        // Add equities from selected sectors
        if (sectorsLoading) {
            rows.push(<LoaderDots key="loader-sector-equities" styles={styles.resultsLoading} />);
        } else if (sectorEquities && sectorEquities.length > 0) {
            sectorEquities.forEach(eq => {
                rows.push(this.renderMatchRow(eq));
            });
        }

        // Add equities from selected sub-sectors
        if (subSectorsLoading) {
            rows.push(<LoaderDots key="loader-subSector-equities" styles={styles.resultsLoading} />);
        } else if (subSectorEquities && subSectorEquities.length > 0) {
            subSectorEquities.forEach(eq => {
                rows.push(this.renderMatchRow(eq));
            });
        }

        return (
            <Div styles={styles.searchResultsWrapper}>
                <Div styles={styles.resultsHeaderContainer}>
                    <Text styles={styles.resultsHeader} uppercase>
                        {`${title} (${numEquities})`}
                    </Text>
                    {checkedEquityIds && checkedEquityIds.length > 0 && (
                        <Text styles={styles.resultsHeader} uppercase>
                            Selected
                            <Span styles={styles.resultsHeaderSelected}>{checkedEquityIds.length}</Span>
                        </Text>
                    )}
                </Div>
                <Div ref={resultsContainerRef} styles={styles.resultsContainer}>
                    {loading && !rows.length && <LoaderDots styles={styles.loading} />}
                    {rows}
                </Div>
                <Div styles={styles.addIdentifierContainer}>
                    <TextInput
                        inputRef={searchInputRef}
                        containerStyle={styles.addIdentifierInputContainer}
                        styles={styles.addIdentifierInput}
                        name="search"
                        onChange={onSearchChange}
                        onKeyDown={onSearchInputKeyDown}
                        placeholder="Type any kind of identifier..."
                        value={searchInput}
                        autoComplete="off"
                    />
                    {!loading && <Icon type="plus" color={theme.colors.gray02} />}
                    {loading && searchInput && searchInput.length > 0 && <LoaderDots styles={styles.searchLoading} />}
                </Div>
                {showSearchResults && this.renderSearchResults()}
            </Div>
        );
    }

    renderMatchRow(equity, isSearchResult = false) {
        const { checkedEquityIds, onResultSelect, setMatchedEquityNode, styles, theme, toggleEquity } = this.props;
        const resultRows = [];
        const { identifier, matches } = equity;

        matches.forEach((match, index) => {
            const id = get(match, 'equityId', '');
            const exchange = get(match, 'exchangeShortName', get(match, 'exchangeName', ''));
            const name = get(match, 'commonName', get(match, 'name', ''));
            let rowStyles = index === 0 ? styles.resultRow : { ...styles.resultRow, ...styles.resultRowListItem };
            if (isSearchResult) rowStyles = { ...rowStyles, ...styles.resultRowSearch };
            const checked = checkedEquityIds.includes(id);
            const checkboxStyles = checked ? styles.checked : styles.unchecked;
            const resultRowIdentifierStyles =
                index === 0
                    ? { ...styles.resultRowIdentifier, ...styles.bold }
                    : { ...styles.resultRowIdentifier, ...styles.faded };

            resultRows.push(
                <Div
                    key={`${identifier}-${exchange}`}
                    styles={rowStyles}
                    onClick={() => {
                        toggleEquity(id);
                        if (isSearchResult) onResultSelect(equity);
                    }}
                >
                    <Text size={1} styles={resultRowIdentifierStyles} uppercase>
                        {identifier}
                    </Text>
                    <Text size={1} styles={styles.resultRowExchange} uppercase>
                        {exchange}
                    </Text>
                    <Text size={1} styles={styles.resultRowName} uppercase>
                        {name}
                    </Text>
                    <Div styles={{ ...styles.checkbox, ...checkboxStyles }}>
                        <Icon type="checkMarkSmall" color={theme.colors.white01} />
                    </Div>
                </Div>
            );
        });

        return (
            <Div ref={node => setMatchedEquityNode(identifier, node)} key={identifier} styles={styles.result}>
                {resultRows}
            </Div>
        );
    }

    renderNoMatchRow(equity, eqIndex) {
        const {
            failedIdentifiers,
            onUnmatchedIdentifierChange,
            requestedIdentifiers,
            requestIdentifier,
            searchUnmatchedIdentifier,
            searchedUnmatchedIdentifier,
            styles,
            unmatchedInputs
        } = this.props;
        const identifier = get(equity, 'identifier', '');
        const edited = !!unmatchedInputs && unmatchedInputs[identifier] !== undefined;
        const value = edited ? unmatchedInputs[identifier] : identifier;
        const requested = requestedIdentifiers.includes(identifier);
        const invalid = failedIdentifiers.includes(identifier);
        const identifierStyles = requested ? styles.requestedIdentifier : styles.noMatchIdentifier;
        const containerStyles = requested
            ? { ...styles.resultRowNoMatch, ...styles.resultRowRequested }
            : styles.resultRowNoMatch;
        // eqIndex is needed for the key here because a user can change an unmatched input to have the same identifier
        // as other unmatched inputs
        return (
            <Div key={`${identifier}-${eqIndex}`} styles={containerStyles}>
                <Text size={1} styles={identifierStyles} uppercase>
                    {identifier}
                </Text>
                {requested ? (
                    <Text size={1} styles={invalid ? styles.invalidText : styles.requestedText} uppercase>
                        {invalid ? 'Invalid' : 'Requested'}
                    </Text>
                ) : (
                    <Div styles={styles.requestIdentifierContainer}>
                        <TextInput
                            id={identifier}
                            styles={styles.requestIdentifierInput}
                            iconRight="pencil"
                            name={identifier}
                            onChange={onUnmatchedIdentifierChange}
                            value={value}
                        />
                        <ActionButton
                            disabled={edited && value.length === 0}
                            loading={searchedUnmatchedIdentifier === identifier}
                            onClick={() =>
                                edited ? searchUnmatchedIdentifier(identifier, value) : requestIdentifier(equity)
                            }
                            styles={styles.requestIdentifierButton}
                        >
                            <Text size={1} styles={styles.requestIdentifierButtonText} uppercase>
                                {edited ? 'Search' : 'Request'}
                            </Text>
                        </ActionButton>
                    </Div>
                )}
            </Div>
        );
    }

    renderSearchResults() {
        const { failedIdentifiers, requestIdentifier, requestedIdentifiers, searchResults, styles } = this.props;
        const rows = [];

        searchResults.forEach(equity => {
            const hasMatch = get(equity, 'matches', []).length > 0;

            if (hasMatch) {
                rows.push(this.renderMatchRow(equity, true));
            } else {
                const identifier = get(equity, 'identifier', '');
                const requested = requestedIdentifiers.includes(identifier);
                const invalid = failedIdentifiers.includes(identifier);
                const noResultsContainerStyles = requested
                    ? { ...styles.noResultsContainer, ...styles.noResultsContainerRequested }
                    : styles.noResultsContainer;

                rows.push(
                    <Div key={`nomatch-${identifier}`} styles={noResultsContainerStyles}>
                        <Text styles={styles.noResults} size={3}>
                            No matching equities found for <Span styles={styles.bold}>{identifier}</Span>
                        </Text>
                        {requested ? (
                            <Text size={1} styles={invalid ? styles.invalidText : styles.requestedText} uppercase>
                                {invalid ? 'Invalid' : 'Requested'}
                            </Text>
                        ) : (
                            <ActionButton
                                onClick={() => requestIdentifier(equity, true)}
                                styles={styles.requestIdentifierButton}
                            >
                                <Text size={1} styles={styles.requestIdentifierButtonText} uppercase>
                                    Request
                                </Text>
                            </ActionButton>
                        )}
                    </Div>
                );
            }
        });

        return <Div styles={styles.searchResultsContainer}>{rows}</Div>;
    }

    render() {
        const { styles, passedStyles } = this.props;
        return <Div styles={{ ...styles.container, ...passedStyles }}>{this.renderResults()}</Div>;
    }
}

export const BulkFollowIdentifiersResultsUI = compose(withStyleSheet(styleSheet))(BulkFollowIdentifiersResults);
