import gql from 'graphql-tag';
import XDate from 'xdate';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { PERMISSIONS } from 'consts';
import { withUpdateSubscription } from 'graphql/billing';
import {
    billingProductFragment,
    billingProductPriceFragment,
    billingSubscriptionFragment
} from 'graphql/fragments/billing';
import { organizationFragment, userFragment } from 'graphql/fragments/user';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { withReporting } from 'provider/reporting';
import { get, getNativePrice } from 'utils';

const UPGRADE_MAP = {
    addMonitor: {
        content:
            'Add existing monitors to your sidebar, view cross topic comparisons, and edit or add your own searches.',
        title: 'Add Monitor',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedCreateDashboard,
        wantedAction: 'add already-made monitors'
    },
    attachment: {
        content: 'Create saved searches for event attachments.',
        title: 'Event Attachments',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedAttachmentContent,
        wantedAction: 'search event attachments'
    },
    createEvent: {
        content:
            'Transcribe a call live through your computer, or dial into an existing call, event, or podcast. ' +
            'Automatically transcribe and make available to you and your team immediately after. Search, and ' +
            'highlight your pre-defined keywords.',
        title: 'Create Your Own Recordings',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedCreatePrivateRecording,
        wantedAction: 'create a custom live recording'
    },
    createStream: {
        content:
            'Search across news, media, 3rd party data, and event transcripts for groups of keywords and synonyms.' +
            'Then filter by equities, sectors, and watchlists.',
        title: 'Create Custom Search',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedCreateStream,
        wantedAction: 'create custom searches'
    },
    editDashboard: {
        content: 'You are already able to create and edit monitors.',
        title: 'Edit Monitor',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedEditDashboard,
        wantedAction: 'create and edit monitors'
    },
    editStream: {
        content:
            'Search across news, media, 3rd party data, and event transcripts for groups of keywords and synonyms.' +
            'Then filter by equities, sectors, and watchlists.',
        title: 'Create and Edit Searches',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedEditStream,
        wantedAction: 'create and edit searches'
    },
    dashboard: {
        content:
            'Build monitors around related topics, view cross topic comparisons, and stay on top of everything ' +
            'that matters.',
        title: 'Create Monitor',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedCreateDashboard,
        wantedAction: 'create custom monitors'
    },
    dashboardAnalysis: {
        content:
            'The monitor analysis charts the number of mentions over time for each "transcript/news" search in a ' +
            'monitor, as a separate line series in the graph. The data can also be exported to CSV.',
        title: 'Monitor Analysis',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedDashboardAnalysis,
        wantedAction: 'view a cross search monitor analysis'
    },
    dashboardRequest: {
        content:
            "Send a topic to Aiera's team of experts, and they'll create a monitor customized for you - " +
            'leveraging searches, keywords, and data types that best suit your needs.',
        title: 'Request Custom Monitor',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedRequestCustomDashboard,
        wantedAction: 'request expert built monitors directly from Aiera'
    },
    filings: {
        content: 'Access the filings directly, as soon as Aiera has them.',
        title: 'Equity Filings',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedEquityFilings,
        wantedAction: 'view Equity Filings'
    },
    followDashboard: {
        content:
            "Following a monitor will add it to your sidebar to give you easy access. You'll also get all updates " +
            'to the monitor if the creator makes any changes to the searches or settings. When you follow a ' +
            "monitor, you won't be able to modify it, or add your own searches.",
        title: 'Following Monitors',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedFollowDashboard,
        wantedAction: 'follow monitors from Aiera and other users'
    },
    gsheet: {
        content: 'Connect any Google sheet you control, and automatically import the data into a search.',
        title: 'Google Sheet Searches',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedCreateStreamCustom,
        wantedAction: 'create custom searches driven from Google Sheets'
    },
    liveEvents: {
        content:
            'Connect to live earnings calls and other investor events with live transcription and event intelligence',
        title: 'Live Events',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedLiveEvents,
        wantedAction: 'access live event transcripts and audio in Aiera'
    },
    news: {
        content:
            'Search across and view all available news & media, imported documents, 3rd party data, and custom ' +
            'sources.',
        title: 'News & Media',
        membershipNeeded: 'Aiera Professional + News',
        permission: PERMISSIONS.unlockedNewsContent,
        wantedAction: 'view news & media content, plus creating custom news & media searches'
    },
    premiumSupport: {
        content:
            'Help when you need it, by phone or live chat. Expert created monitors and searches are only a request ' +
            'away.',
        title: 'Live Customer Support',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedLiveSupport,
        wantedAction: 'receive live support from the Aiera team'
    },
    research: {
        content: 'Search, view, and annotate broker research you are entitled to.',
        title: 'Broker Research',
        membershipNeeded: 'Professional',
        permission: PERMISSIONS.unlockedResearchContent,
        wantedAction: 'view entitled broker research, plus creating custom research searches and alerts'
    },
    topics: {
        content:
            "Access and explore topics related to events, companies, and more - all identified by Aiera's NLP engine.",
        title: 'AI Detected Topics',
        membershipNeeded: 'Professional',
        permissions: PERMISSIONS.unlockedTopics,
        wantedAction: 'view topics identified by Aiera NLP'
    },
    sentiment: {
        content: "Layer in Aiera's audio & linguistic sentiment markup on real-time and published event transcripts.",
        title: 'Transcript Sentiment',
        membershipNeeded: 'Professional',
        permissions: PERMISSIONS.unlockedSentiment,
        wantedAction: 'view transcript sentiment markup'
    },
    spotlight: {
        content: 'Search guidance updates, mergers & acquisitions, corporate actions, IPOs and other business updates.',
        title: 'Corporate Activity',
        membershipNeeded: 'Basic',
        permissions: PERMISSIONS.unlockedSpotlightContent,
        wantedAction: 'search corporate activity'
    },
    stream: {
        content:
            'Search across news, media, 3rd party data, and event transcripts for groups of keywords and synonyms.' +
            'Then filter by equities, sectors, and watchlists.',
        title: 'Create Custom Search',
        membershipNeeded: 'Basic',
        permission: PERMISSIONS.unlockedCreateStream,
        wantedAction: 'create custom searches'
    }
};

// These need to match the membershipNeeded values in UPGRADE_MAP (except lowercase)
const PLAN_TIERS = {
    basic: 0,
    professional: 1
};

// Format input for previewSubscription mutation
function getUpdatedSeats(productPriceId, upgradeOption, currentUserId, users = []) {
    return {
        updatedSeats:
            upgradeOption === 'team'
                ? users.map(({ userId }) => ({ productPriceId, userId }))
                : [{ productPriceId, userId: currentUserId }]
    };
}

// Get prices for single-seat and entire-team upgrades
function getUpgradeInvoices(previewSubscription, productPriceId, currentUserId, users) {
    const currentUserPromise = new Promise(resolve => {
        previewSubscription(getUpdatedSeats(productPriceId, 'currentUser', currentUserId)).then(
            ({ immediateInvoice, nextInvoice }) => {
                resolve({
                    immediateInvoice,
                    nextInvoice
                });
            }
        );
    });
    const teamPromise = new Promise(resolve => {
        previewSubscription(getUpdatedSeats(productPriceId, 'team', currentUserId, users)).then(
            ({ immediateInvoice, nextInvoice }) => {
                resolve({
                    immediateInvoice,
                    nextInvoice
                });
            }
        );
    });
    return Promise.all([currentUserPromise, teamPromise]);
}

function normalizeData(previewSubscription, products = [], type, user, reporter) {
    const adminUsers = [];
    const users = [];
    const productMap = {};
    const activeSubscription = get(user, 'organization.activeSubscription');
    const currency = get(activeSubscription, 'upcomingInvoice.currency');
    const currentPaymentAmount = get(activeSubscription, 'upcomingInvoice.total', 0) / 100;
    const nextBillingDate = get(activeSubscription, 'currentPeriodEnd');
    const paymentInterval = get(activeSubscription, 'interval');
    const membershipType = get(user, 'billingProductPrice.product.name');
    const organizationAdmin = get(user, 'organizationAdmin', false);
    const upgradePlan = get(UPGRADE_MAP, type);
    const isSelfServe = get(user, 'organization.isSelfServeBilling', false);
    let hasBillingError = false;
    let newMembershipPriceId = null;

    if (user && isSelfServe) {
        const bugsnagClient = get(reporter, 'bugsnagClient');
        // Figure out if we should use the promoted prices or legacy ones
        // based on the current user's billingProductPriceId
        const userProductId = get(user, 'billingProductPrice.billingProductId');
        const userPriceId = get(user, 'billingProductPrice.billingProductPriceId');
        const existingProduct = products.find(product => product.id === userProductId);
        // Notify bugsnag if the user's existing product is not included in the billing products list
        if (!existingProduct) {
            hasBillingError = true;
            if (bugsnagClient) {
                bugsnagClient.notify(
                    `Billing Error: current user ${user.id} has an existing billing product ${userProductId} that is ` +
                        'not in the list of available billing products.'
                );
            }
        }
        const existingPrice = existingProduct.prices.find(p => p.billingProductPriceId === userPriceId);
        // Notify bugsnag if the user's existing price is not included in the matching billing products' prices
        if (!existingPrice) {
            hasBillingError = true;
            if (bugsnagClient) {
                bugsnagClient.notify(
                    `Billing Error: current user ${user.id} has an existing billing price ${userPriceId} that is not ` +
                        'in the list of billing product prices.'
                );
            }
        }
        // We show an error message when there's a billing error
        // so skip building the product and seat data
        if (!hasBillingError) {
            // Build a map of products to avoid multiple loops
            products.forEach(p => {
                const { name, prices: productPrices = [] } = p;
                const prices = productPrices.filter(pp => (existingPrice.isPromoted ? pp.isPromoted : !pp.isPromoted));
                prices.forEach(({ billingProductPriceId, interval }) => {
                    if (!productMap[billingProductPriceId]) {
                        productMap[billingProductPriceId] = name;
                    }
                    // Set the new membership product price id
                    if (
                        interval === paymentInterval &&
                        name.toLowerCase() === get(upgradePlan, 'membershipNeeded', '').toLowerCase()
                    ) {
                        newMembershipPriceId = billingProductPriceId;
                    }
                });
            });

            // Iterate over users once and filter for admins and upgradable seats
            get(user, 'organization.users', []).forEach(u => {
                const { billingProductPriceId, organizationAdmin: orgAdmin } = u;
                const planName = get(productMap, billingProductPriceId, '').toLowerCase();
                if (PLAN_TIERS[planName] < PLAN_TIERS[get(upgradePlan, 'membershipNeeded', '').toLowerCase()]) {
                    users.push(u);
                }
                if (orgAdmin) {
                    adminUsers.push(u);
                }
            });
        }
    }

    return {
        adminUsers,
        currency,
        currentPaymentAmount: getNativePrice({
            currency,
            price: currentPaymentAmount,
            raw: true
        }),
        getUpdatedSeats,
        hasBillingError,
        isSelfServe,
        membershipType,
        newMembershipPriceId,
        nextBillingDate: nextBillingDate ? new XDate(nextBillingDate).toString('MMM d, yyyy') : '',
        organizationAdmin,
        paymentInterval,
        upgradePlan,
        users
    };
}

function normalizeInvoices(currency, seatUpgradeInvoices = {}, teamUpgradeInvoices = {}, user) {
    // Build a price map to easily swap prices when toggling upgrade options
    const upgradePriceMap = {
        currentUser: {
            newPrice: 0,
            priceIncrease: 0,
            proratedAmount: null
        },
        team: {
            newPrice: 0,
            priceIncrease: 0,
            proratedAmount: null
        }
    };
    const currentPrice = get(user, 'organization.activeSubscription.upcomingInvoice.total', 0) / 100;
    // Current user upgrade pricing
    const { immediateInvoice: currentUserImmediateInvoice, nextInvoice: currentUserNextInvoice } = seatUpgradeInvoices;
    if (currentUserImmediateInvoice) {
        upgradePriceMap.currentUser.proratedAmount = getNativePrice({
            currency,
            price: get(currentUserImmediateInvoice, 'total', 0) / 100,
            raw: true
        });
    }
    if (currentUserNextInvoice) {
        const currentUserNextInvoicePrice = get(currentUserNextInvoice, 'total', 0) / 100;
        upgradePriceMap.currentUser.newPrice = getNativePrice({
            currency,
            price: currentUserNextInvoicePrice,
            raw: true
        });
        upgradePriceMap.currentUser.priceIncrease = getNativePrice({
            currency,
            price: currentUserNextInvoicePrice - currentPrice,
            raw: true
        });
    }
    // Team upgrade pricing
    const { immediateInvoice: teamImmediateInvoice, nextInvoice: teamNextInvoice } = teamUpgradeInvoices;
    if (teamImmediateInvoice) {
        upgradePriceMap.team.proratedAmount = getNativePrice({
            currency,
            price: get(teamImmediateInvoice, 'total', 0) / 100,
            raw: true
        });
    }
    if (teamNextInvoice) {
        const teamNextInvoicePrice = get(teamNextInvoice, 'total', 0) / 100;
        upgradePriceMap.team.newPrice = getNativePrice({
            currency,
            price: teamNextInvoicePrice,
            raw: true
        });
        upgradePriceMap.team.priceIncrease = getNativePrice({
            currency,
            price: teamNextInvoicePrice - currentPrice,
            raw: true
        });
    }
    return {
        calculating:
            !user ||
            (get(user, 'organizationAdmin') &&
                upgradePriceMap.currentUser.newPrice === 0 &&
                upgradePriceMap.team.newPrice === 0),
        upgradePriceMap
    };
}

export const withData = () =>
    compose(
        withMemo({ normalizeData, normalizeInvoices }),
        withReporting(),
        graphql(
            gql`
                query withUpgradeModalData {
                    currentUser {
                        id
                        ...user
                        billingProductPrice {
                            ...billingProductPrice
                            product {
                                ...billingProduct
                            }
                        }
                        organization {
                            ...organization
                            users(includeInvited: true) {
                                ...user
                            }
                            activeSubscription {
                                ...billingSubscription
                                upcomingInvoice {
                                    id
                                    total
                                    currency {
                                        id
                                        symbol
                                        symbolPrefix
                                        minorSymbol
                                        minorSymbolPrefix
                                    }
                                }
                            }
                        }
                    }
                    billingProducts {
                        ...billingProduct
                        prices {
                            ...billingProductPrice
                        }
                    }
                }
                ${userFragment}
                ${organizationFragment}
                ${billingProductFragment}
                ${billingProductPriceFragment}
                ${billingSubscriptionFragment}
            `,
            {
                props: ({ data }) => ({
                    billingProducts: get(data, 'billingProducts'),
                    loading: get(data, 'loading'),
                    user: get(data, 'currentUser')
                }),
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        withUpdateSubscription({ abortable: false }),
        withPropsOnChange(
            ['billingProducts', 'reporter', 'type', 'user'],
            ({ billingProducts, normalizeData: normalize, previewSubscription, reporter, type, user }) => ({
                ...normalize(previewSubscription, billingProducts, type, user, reporter)
            })
        ),
        lifecycle({
            componentDidMount() {
                const { newMembershipPriceId, previewSubscription, user, users } = this.props;
                if (newMembershipPriceId && get(user, 'organizationAdmin')) {
                    getUpgradeInvoices(previewSubscription, newMembershipPriceId, get(user, 'userId'), users).then(
                        invoices => {
                            this.setState({
                                calculating: true,
                                seatUpgradeInvoices: {
                                    ...get(invoices, '[0]', {})
                                },
                                teamUpgradeInvoices: {
                                    ...get(invoices, '[1]', {})
                                }
                            });
                        }
                    );
                }
            },
            componentDidUpdate({ newMembershipPriceId: prevMembershipPriceId }) {
                const { newMembershipPriceId, previewSubscription, user, users } = this.props;
                if (prevMembershipPriceId !== newMembershipPriceId && get(user, 'organizationAdmin')) {
                    getUpgradeInvoices(previewSubscription, newMembershipPriceId, get(user, 'userId'), users).then(
                        invoices => {
                            this.setState({
                                calculating: true,
                                seatUpgradeInvoices: {
                                    ...get(invoices, '[0]', {})
                                },
                                teamUpgradeInvoices: {
                                    ...get(invoices, '[1]', {})
                                }
                            });
                        }
                    );
                }
            }
        }),
        withPropsOnChange(
            ['seatUpgradeInvoices', 'teamUpgradeInvoices', 'user'],
            ({ currency, normalizeInvoices: normalize, seatUpgradeInvoices, teamUpgradeInvoices, user }) => ({
                ...normalize(currency, seatUpgradeInvoices, teamUpgradeInvoices, user)
            })
        )
    );
