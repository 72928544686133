import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { LoaderLogo } from 'components/LoaderLogo';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'searchResults';

class FilingResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            styles,
            enabled,
            results,
            loading,
            loadingMoreResults,
            loadMoreResults,
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) return null;
        const highlightedType = get(highlightedResult, 'type');
        return (
            <Div styles={styles.results}>
                {loading ? (
                    <Div styles={styles.loaderCell} colSpan={4}>
                        <LoaderLogo />
                    </Div>
                ) : (
                    results.map((filing, index) => {
                        const { id, arrivalDate, formNumber, formShortName, primaryEquity } = filing;
                        const date = arrivalDate ? new XDate(arrivalDate) : null;
                        const dateString = date ? date.toString('MM/dd/yyyy') : null;
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        return (
                            <Div
                                ref={node => indexElement(RESULT_TYPE, index, node)}
                                key={`filing-${id}`}
                                styles={resultStyles}
                                onClick={onClickResult}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                            >
                                <Text size={3} styles={styles.formNumber}>
                                    {formNumber}
                                </Text>
                                <Div styles={styles.resultTop}>
                                    <Text styles={styles.title} size={2}>
                                        {get(primaryEquity, 'commonName')}&nbsp; ({get(primaryEquity, 'localTicker')})
                                    </Text>
                                    <Text styles={styles.subTitle} size={0}>
                                        {`${formShortName} • ${dateString}`}
                                    </Text>
                                </Div>
                            </Div>
                        );
                    })
                )}
                {!loading && loadingMoreResults && <LoaderLogo styles={styles.loadingMore} />}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Div styles={styles.loadButtonRow} ref={node => indexElement('loadMoreButton', 0, node)}>
                        <ActionButton
                            onClick={loadMoreResults}
                            size={1}
                            styles={
                                highlightedType === 'loadMoreButton'
                                    ? styles.loadMoreButtonHighlighted
                                    : styles.loadMoreButton
                            }
                        >
                            <Text size={1}>Load More</Text>
                        </ActionButton>
                    </Div>
                )}
            </Div>
        );
    }
}

export const FilingResultsUI = compose(withStyleSheet(styleSheet))(FilingResults);
