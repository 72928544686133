import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Text } from 'components/Text';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { FileUpload } from 'components/FileUpload';
import { TextInput } from 'components/TextInput';
import { Notice } from 'components/Notice';
import { EquityAutocomplete } from 'components/EquityAutocomplete';
import { Hint } from 'components/Hint';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { styleSheet } from './stylesheet';

class UploadDocumentButton extends PureComponent {
    static propTypes = {
        beforeHide: PropTypes.func.isRequired,
        bulkFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
        loading: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
        onChangeTitle: PropTypes.func.isRequired,
        onUpload: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onDeleteFile: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        status: PropTypes.string.isRequired,
        documentTitle: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        tagCompany: PropTypes.func.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const {
            beforeHide,
            bulkFiles,
            loading,
            styles,
            documentTitle,
            onChangeTitle,
            onHide,
            onUpload,
            onSubmit,
            onDeleteFile,
            status,
            tagCompany,
            theme,
            passedStyles
        } = this.props;

        return (
            <Tooltip
                isEnabled
                useElementOffsetBottom
                useElementOffsetRight
                xOffset={0}
                yOffset={6}
                growLeft
                beforeHide={beforeHide}
                onHide={onHide}
                persistOnMouseExit
                useOutsideClickHandler
                styles={styles.tooltip}
                content={
                    <Div styles={styles.tooltipContent}>
                        <Text size={5} weight="medium" styles={styles.title}>
                            Upload Files
                        </Text>
                        <Notice type="info" styles={styles.notice} richContent>
                            <Text size={3} styles={styles.noticeSubtext}>
                                Supported File Types
                            </Text>
                            <WithPermission permissions={[PERMISSIONS.featureCreateEvent]}>
                                {({ restricted }) =>
                                    restricted ? (
                                        <Text size={3} styles={styles.noticeSubtext}>
                                            PDF, DOC, DOCX, PPT, PPTX
                                        </Text>
                                    ) : (
                                        <Fragment>
                                            <Text size={3} styles={styles.noticeSubtext}>
                                                PDF, DOC, DOCX, PPT, PPTX, MP3, MP4, M4A
                                            </Text>
                                            <Div styles={styles.noticeAudio}>
                                                <Icon type="mic" color={theme.colors.black01} />
                                                <Text size={3}>
                                                    Uploaded audio files will be transcribed, and can be found in the
                                                    recordings page.
                                                </Text>
                                            </Div>
                                        </Fragment>
                                    )
                                }
                            </WithPermission>
                            <Div styles={styles.emailDocuments}>
                                <Icon type="email03" color={theme.colors.black01} />
                                <Text size={3}>
                                    Email file attachments to{' '}
                                    <a href="mailto:upload@aiera.com" target="_blank" rel="noreferrer">
                                        upload@aiera.com
                                    </a>
                                    , and they will be uploaded automatically.
                                </Text>
                            </Div>
                        </Notice>
                        <Div styles={styles.titleWrapper}>
                            <TextInput
                                name="uploadDocTitle"
                                label="Title"
                                placeholder="Title for your upload..."
                                value={documentTitle}
                                onChange={onChangeTitle}
                            />
                            {bulkFiles.length > 1 && (
                                <Div styles={styles.disableTitle}>
                                    <Text size={3}>Filenames will be used as titles for bulk uploads</Text>
                                </Div>
                            )}
                        </Div>
                        {loading ? (
                            <Fragment>
                                <Div styles={styles.tagEquityLoading}>
                                    <Text size={3}>Tag Company (optional)</Text>
                                </Div>
                                <Div styles={styles.saving}>
                                    <Text uppercase>{`Saving ${pluralize(
                                        'File',
                                        bulkFiles.length,
                                        bulkFiles.length > 1
                                    )} To Your Organization...`}</Text>
                                </Div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <EquityAutocomplete
                                    label="Tag Company (optional)"
                                    onChange={tagCompany}
                                    placeholder="Search by ticker or name..."
                                    styles={styles.tagEquity}
                                />
                                <WithPermission permissions={[PERMISSIONS.featureCreateEvent]}>
                                    {({ restricted }) => (
                                        <FileUpload
                                            allowMultiple
                                            onDelete={onDeleteFile}
                                            onUpload={onUpload}
                                            type={restricted ? 'documentNoAudio' : 'document'}
                                            styles={styles.fileUpload}
                                        />
                                    )}
                                </WithPermission>
                            </Fragment>
                        )}
                        <ActionButton
                            disabled={bulkFiles.length < 1 || (bulkFiles.length === 1 && !documentTitle) || loading}
                            styles={styles.saveDocument}
                            onClick={onSubmit}
                            name="saveUploadedFiles"
                        >
                            <Text size={3}>
                                {loading
                                    ? bulkFiles.length > 1
                                        ? 'Saving Files...'
                                        : 'Saving File...'
                                    : status === 'success'
                                    ? 'Upload Success!'
                                    : bulkFiles.length > 1
                                    ? 'Save Files'
                                    : 'Save File'}
                            </Text>
                        </ActionButton>
                        {status === 'success' && (
                            <Text styles={styles.processing}>
                                Processing can take a few minutes... <br />
                                document will be available shortly.
                            </Text>
                        )}
                    </Div>
                }
            >
                {({ showTooltip }) => (
                    <Hint
                        text="Upload Files"
                        description="Documents & Audio"
                        styles={{ ...styles.container, ...passedStyles }}
                        onClick={showTooltip}
                    >
                        <Icon type="upload" color="white" />
                        Upload Files
                    </Hint>
                )}
            </Tooltip>
        );
    }
}

export const UploadDocumentButtonUI = compose(withStyleSheet(styleSheet))(UploadDocumentButton);
