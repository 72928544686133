import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'utils';
import { tabMinimize, tabMaximize, tabRemove } from 'actions/tabs';

import { withData } from './data';
import { AudioPlaybarUI } from './ui';

export class AudioPlaybar extends PureComponent {
    static displayName = 'AudioPlaybarContainer';

    static propTypes = {
        isPublic: PropTypes.bool,
        maximizeTab: PropTypes.func.isRequired,
        minimizedTabIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        recommendedEvents: PropTypes.arrayOf(PropTypes.any),
        removeTab: PropTypes.func.isRequired,
        showEventInfo: PropTypes.bool,
        showRecommendedEvents: PropTypes.bool,
        showSavedTabs: PropTypes.bool,
        showVolume: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        titleMap: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        isPublic: false,
        recommendedEvents: [],
        showEventInfo: false,
        showRecommendedEvents: false,
        showSavedTabs: false,
        showVolume: true,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.state = {
            animateTabList: false
        };
    }

    componentDidUpdate({ minimizedTabIds: prevIds }) {
        const { minimizedTabIds } = this.props;
        if (minimizedTabIds.length > prevIds.length) {
            this.setState({ animateTabList: true }, () => {
                this.endAnimationTimer = setTimeout(() => {
                    this.setState({ animateTabList: false });
                }, 800);
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.endAnimationTimer);
    }

    savedTabs() {
        const { minimizedTabIds, titleMap } = this.props;
        const tabs = [];

        minimizedTabIds.forEach(id => {
            if (id && titleMap[id]) {
                tabs.push({
                    id,
                    title: titleMap[id]
                });
            }
        });

        return tabs;
    }

    render() {
        const {
            isPublic,
            showEventInfo,
            showRecommendedEvents,
            showSavedTabs,
            showVolume,
            styles,
            recommendedEvents,
            removeTab,
            maximizeTab
        } = this.props;
        const { animateTabList } = this.state;

        // We only show the play bar when
        // #1 we have the user
        // #2 when the popout is an event
        return (
            <AudioPlaybarUI
                animateTabList={animateTabList}
                isPublic={isPublic}
                maximizeTab={maximizeTab}
                recommendedEvents={recommendedEvents}
                removeTab={removeTab}
                savedTabs={this.savedTabs()}
                showEventInfo={showEventInfo}
                showRecommendedEvents={showRecommendedEvents}
                showSavedTabs={showSavedTabs}
                showVolume={showVolume}
                styles={styles}
            />
        );
    }
}

const mapStateToProps = ({ Tabs }) => ({
    minimizedTabIds: get(Tabs, 'minimizedTabIds', []),
    titleMap: get(Tabs, 'titleMap', {})
});

const mapDispatchToProps = {
    maximizeTab: tabMaximize,
    minimizeTab: tabMinimize,
    removeTab: tabRemove
};

export const AudioPlaybarContainer = compose(withData(), connect(mapStateToProps, mapDispatchToProps))(AudioPlaybar);
