export const styleSheet = theme => ({
    container: {},
    topContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    content: {
        ...theme.content.annotated,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        marginTop: 15
    },
    rightText: {
        p: {
            textAlign: 'right'
        }
    },
    rightText01: {
        lineHeight: '1.2em'
    },
    rightText02: {
        lineHeight: '1.2em',
        marginTop: 3,
        color: theme.colors.gray06
    },
    greenText: {
        color: theme.colors.green02
    },
    redText: {
        color: theme.colors.red01
    },
    rowContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        ':hover': {
            '.contentTitle': {
                color: theme.colors.black01
            },
            'svg g': {
                fill: theme.colors.black01
            }
        }
    },
    rowInfo: {
        display: 'flex',
        minWidth: 0,
        svg: {
            marginTop: 8
        }
    },
    rowTitle: {
        lineHeight: '1.3em',
        color: theme.colors.black01,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: 12,
        textTransform: 'capitalize'
    },
    rowTitleWrapper: {
        minWidth: 0
    },
    rowRightSide: {
        color: theme.colors.gray06,
        lineHeight: '1em',
        p: {
            ':first-of-type': {
                lineHeight: '1.3em'
            },
            textAlign: 'right'
        }
    },
    rowSubtitle: {
        color: theme.colors.gray04,
        letterSpacing: 1
    },
    expandableTitleWrapper: {
        marginLeft: 8,
        minWidth: 0
    },
    rowSubContentBody: {
        lineHeight: '1.3em',
        color: theme.colors.gray08
    },
    rowSubContent: {
        marginTop: 8,
        marginRight: 7
    }
});
