export const styleSheet = theme => ({
    container: {
        position: 'relative',
        flex: 1,
        display: 'flex'
    },
    option: {
        flexShrink: 0,
        color: theme.colors.gray04,
        marginRight: 18,
        cursor: 'pointer',
        p: {
            textTransform: 'capitalize',
            pointerEvents: 'none'
        }
    },
    optionSelected: {
        flexShrink: 0,
        color: theme.colors.white01,
        marginRight: 18,
        p: {
            textTransform: 'capitalize',
            pointerEvents: 'none'
        }
    },
    options: {
        height: 34,
        alignItems: 'center',
        position: 'relative',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        ':nth-last-of-type(2)': {
            marginRight: 0
        }
    },
    selectedBar: {
        transition: '0.1s all ease-in-out',
        height: 2,
        backgroundColor: theme.colors.blue08,
        position: 'absolute',
        bottom: 0,
        marginRight: 0
    }
});
