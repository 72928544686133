import { toDateTimeString, get } from 'utils';
import XDate from 'xdate';

export const generateWordDocument = ({ highlights, title: docTitle, isMultiSource, onComplete }) => {
    import('docx').then(({ Document, Packer, Paragraph, TextRun, SectionType }) => {
        const fileName = docTitle
            .replace(/\./g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();

        const titleObj = [];

        const firstHighlight = get(highlights, '[0]');

        if (!isMultiSource && get(firstHighlight, 'target.contentType') === 'news') {
            titleObj.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${get(firstHighlight, 'source')}, ${toDateTimeString(get(firstHighlight, 'date'))}`,
                            bold: false
                        })
                    ]
                })
            );
        }

        // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
        // This simple example will only contain one section
        const doc = new Document({
            sections: [
                {
                    properties: {
                        type: SectionType.CONTINUOUS
                    },
                    children: titleObj
                },
                ...highlights.map(
                    ({
                        created,
                        creatorName,
                        date,
                        speakerName,
                        speakerTitle,
                        speakerAffiliation,
                        highlight,
                        note,
                        noteRemoved,
                        startTimestamp,
                        source,
                        title,
                        targetType
                    }) => {
                        const textSet = [];

                        // Title for this Highlight
                        textSet.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: title,
                                        bold: true
                                    })
                                ]
                            })
                        );

                        if (isMultiSource) {
                            if (targetType === 'content') {
                                // Source and Date for non-transcripts
                                textSet.push(
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: `Source: ${source}, ${toDateTimeString(date)}`,
                                                bold: false
                                            })
                                        ]
                                    })
                                );
                            }
                        }

                        // Highlight Spoken Time
                        if (targetType === 'transcript') {
                            // Time of spoken text
                            textSet.push(
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: toDateTimeString(startTimestamp, false, false, 'h:mm TT MMM dS, yyyy')
                                        })
                                    ]
                                })
                            );
                        }

                        // Highlighted Text
                        textSet.push(
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: highlight
                                    })
                                ]
                            })
                        );

                        // Transcript Speaker
                        if (targetType === 'transcript') {
                            const speaker = [
                                new TextRun({
                                    text: speakerName || '',
                                    bold: true
                                })
                            ];

                            if (speakerTitle) {
                                speaker.push(new TextRun(`, ${speakerTitle}`));
                            }

                            if (speakerAffiliation) {
                                speaker.push(new TextRun(`, ${speakerAffiliation}`));
                            }

                            textSet.push(
                                new Paragraph({
                                    children: speaker,
                                    spacing: {
                                        after: 200
                                    }
                                })
                            );
                        }

                        if (!noteRemoved && note) {
                            const creator = [];
                            creator.push(
                                new TextRun({
                                    text: creatorName,
                                    bold: true
                                })
                            );
                            creator.push(new TextRun(`, ${new XDate(created).toString('h:mm TT MMM dS, yyyy')}`));
                            textSet.push(
                                new Paragraph({
                                    children: creator
                                })
                            );
                            textSet.push(
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: note
                                        })
                                    ]
                                })
                            );
                        }

                        return {
                            properties: {
                                type: SectionType.CONTINUOUS
                            },
                            children: textSet
                        };
                    }
                )
            ]
        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then(blob => {
                import('file-saver').then(({ saveAs }) => saveAs(blob, fileName));
            })
            .then(onComplete)
            // eslint-disable-next-line no-console
            .catch(() => console.log('Could not save file'));
    });
};
