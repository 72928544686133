export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white03
    },
    footerBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: `0 ${theme.margins.margin03}`,
        // boxShadow: `0 -1px 0 0 ${theme.colors.gray03}`,
        borderTop: `1px solid ${theme.colors.gray03}`,
        height: '60px'
    },
    disclaimer: {
        padding: `${theme.margins.margin01} ${theme.margins.margin03} ${theme.margins.margin01}`,
        color: theme.colors.gray04,
        opacity: 0.6,
        lineHeight: '1.4em'
    },
    link: {
        opacity: 0.8,
        color: theme.colors.gray04,
        letterSpacing: '0.4px',
        marginRight: theme.margins.margin02,
        [`@media(max-width: ${theme.breakpoints.internal.tablet})`]: {
            marginRight: theme.margins.margin01
        },
        a: {
            color: theme.colors.gray04,
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    copyright: {
        opacity: 0.8,
        color: theme.colors.gray04,
        justifyContent: 'flex-end',
        letterSpacing: '1px',
        marginLeft: theme.margins.margin02,
        [`@media(max-width: ${theme.breakpoints.internal.tablet})`]: {
            marginLeft: theme.margins.margin01
        }
    },
    build: {
        flex: 1,
        textAlign: 'center'
    }
});
