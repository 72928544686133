import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ToggleUI } from './ui';

export class Toggle extends PureComponent {
    static displayName = 'ToggleContainer';

    static propTypes = {
        disabled: PropTypes.bool,
        formLabel: PropTypes.string,
        gray: PropTypes.bool,
        leftIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        leftLabel: PropTypes.string,
        name: PropTypes.string,
        on: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        responsive: PropTypes.string,
        rightIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        rightLabel: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        disabled: false,
        formLabel: null,
        gray: false,
        leftIcon: null,
        leftLabel: null,
        name: undefined,
        onClick: undefined,
        responsive: null,
        rightIcon: null,
        rightLabel: null,
        styles: {}
    };

    render() {
        const {
            disabled,
            formLabel,
            gray,
            leftIcon,
            leftLabel,
            name,
            on,
            onClick,
            responsive,
            rightIcon,
            rightLabel,
            styles
        } = this.props;
        return (
            <ToggleUI
                disabled={disabled}
                formLabel={formLabel}
                gray={gray}
                leftIcon={leftIcon}
                leftLabel={leftLabel}
                name={name}
                on={on}
                onClick={disabled ? undefined : onClick}
                responsive={responsive}
                rightIcon={rightIcon}
                rightLabel={rightLabel}
                styles={styles}
            />
        );
    }
}

export const ToggleContainer = compose()(Toggle);
