import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { config } from 'configuration';
import { get } from 'utils';

import { FileUploadUI } from './ui';

// These must map to the aiera-api uploads rest endpoints
// https://github.com/aiera-inc/aiera-api/blob/master/aiera_api/www/rest/file_uploads.py#L33
// example: /uploads/audio/
const ALLOWED_FILE_TYPES = ['audio', 'pdf', 'document', 'documentNoAudio'];
// 1 GB (in bytes, should match limit in aiera-api)
// https://github.com/aiera-inc/aiera-api/blob/master/aiera_api/www/server.py#L55
const MAX_FILE_SIZE = 1073741824;
const VALID_MIME_TYPES_MAP = {
    audio: {
        mimeTypes: ['audio/m4a', 'audio/mp4', 'audio/mpeg', 'audio/x-m4a', 'video/mp4', 'video/x-m4v'],
        extensions: ['mp3', 'mp4', 'm4a', 'm4v']
    },
    pdf: {
        mimeTypes: ['application/pdf'],
        extensions: ['pdf']
    },
    document: {
        mimeTypes: [
            'audio/m4a',
            'audio/mpeg',
            'audio/mp4',
            'audio/x-m4a',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'video/mp4',
            'video/x-m4v'
        ],
        extensions: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'mp3', 'mp4', 'm4a', 'm4v']
    },
    documentNoAudio: {
        mimeTypes: [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ],
        extensions: ['pdf', 'doc', 'docx', 'ppt', 'pptx']
    }
};

const normalizeType = type => {
    if (type === 'documentNoAudio') {
        return 'document';
    }

    return type;
};

export class FileUpload extends Component {
    static displayName = 'FileUploadContainer';

    static propTypes = {
        allowMultiple: PropTypes.bool,
        filename: PropTypes.string,
        onDelete: PropTypes.func,
        onUpload: PropTypes.func,
        styles: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.oneOf(ALLOWED_FILE_TYPES).isRequired
    };

    static defaultProps = {
        allowMultiple: false,
        filename: null,
        onDelete: undefined,
        onUpload: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.uploadFile = this.uploadFile.bind(this);

        // Keep file and filename separate because existing uploads
        // will just have a filename
        this.state = {
            files: [],
            uploadedFiles: []
        };
    }

    onChange(event) {
        const { type } = this.props;
        const { files: existingFiles, uploadedFiles: existingUploadedFiles } = this.state;
        const files = get(event, 'target.files', get(event, 'dataTransfer.files'));
        // Need to spread the files like this, as it is not an Array type
        // but this converts it an array
        const uploadedFiles = [
            ...existingUploadedFiles,
            ...[...files].map(idx => ({
                name: '',
                id: idx,
                progress: 0,
                uploaded: false,
                uploading: false,
                url: ''
            }))
        ];
        if (files) {
            // Validate files
            this.setState({ files: [...existingFiles, ...files], uploadedFiles }, () => {
                files.forEach((file, idx) => {
                    const index = idx + existingFiles.length;
                    const error = this.validate(file, type);
                    if (!error) {
                        this.uploadFile(index);
                    } else {
                        uploadedFiles[index].error = error;
                        this.setState({ uploadedFiles });
                    }
                });
            });
        }
    }

    onDelete(idx) {
        const { onDelete } = this.props;
        const { uploadedFiles, files } = this.state;
        const filteredUploadedFiles = uploadedFiles.filter((_, index) => index !== idx);
        const filteredFiles = files.filter((_, index) => index !== idx);

        this.setState(
            {
                uploadedFiles: filteredUploadedFiles,
                files: filteredFiles
            },
            () => {
                if (onDelete) onDelete({ bulkFilesRaw: filteredUploadedFiles });
            }
        );
    }

    uploadFile(index) {
        const { onUpload, type: typeRaw } = this.props;
        const { files, uploadedFiles } = this.state;
        const type = normalizeType(typeRaw);
        const file = files[index];
        const data = new FormData();
        data.append('file', file);
        uploadedFiles[index].uploading = true;
        this.setState({ uploadedFiles }, () => {
            axios
                .post(`${get(config, 'API_ENDPOINT')}/uploads/${type}`, data, {
                    method: 'post',
                    onUploadProgress: progressEvent => {
                        // Stagger the progress for smoother transitions
                        setTimeout(() => {
                            uploadedFiles[index].progress = (progressEvent.loaded / progressEvent.total) * 100;
                            this.setState({ uploadedFiles });
                        }, 500);
                    },
                    withCredentials: true
                })
                .then(resp => {
                    uploadedFiles[index].uploaded = true;
                    uploadedFiles[index].uploading = false;
                    uploadedFiles[index].url = get(resp, 'data.url');
                    uploadedFiles[index].name = get(resp, 'data.filename');
                    uploadedFiles[index].id = get(resp, 'data.fileId');
                    this.setState(
                        {
                            uploadedFiles
                        },
                        () => {
                            if (onUpload && index === files.length - 1) {
                                onUpload({
                                    bulkFiles: uploadedFiles,
                                    fileId: get(resp, 'data.fileId'),
                                    fileName: get(resp, 'data.filename'),
                                    fileUrl: get(resp, 'data.url')
                                });
                            }
                        }
                    );
                })
                .catch(e => {
                    const error = `Upload failed! ${get(
                        e,
                        'response.data.message',
                        'Please refresh the page and try again.'
                    )}`;
                    uploadedFiles[index].uploading = false;
                    uploadedFiles[index].error = error;
                    this.setState(
                        {
                            uploadedFiles
                        },
                        () => {
                            if (onUpload && files.length === 1) {
                                onUpload({ error });
                            }
                        }
                    );
                });
        });
    }

    validate(file, type) {
        let error = null;
        if (!file) {
            error = 'File is required';
        }
        if (!file.name || !file.name.trim()) {
            error = 'File must have a filename';
        }
        if (!get(VALID_MIME_TYPES_MAP, `${type}.mimeTypes`, []).includes(file.type)) {
            error = `Invalid file type. Must be one of ${get(VALID_MIME_TYPES_MAP, `${type}.extensions`, []).join(
                ', '
            )}`;
        }
        if (file.size > MAX_FILE_SIZE) {
            error = `Maximum file size is ${MAX_FILE_SIZE / 1000000} MB`;
        }
        return error;
    }

    render() {
        const { error, uploadedFiles } = this.state;
        const { allowMultiple, filename: existingFilename, styles } = this.props;
        return (
            <FileUploadUI
                allowMultiple={allowMultiple}
                error={error}
                existingFilename={existingFilename}
                onChange={this.onChange}
                onDelete={this.onDelete}
                styles={styles}
                uploadedFiles={uploadedFiles}
            />
        );
    }
}

export const FileUploadContainer = compose()(FileUpload);
