import { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { withRealtime } from 'provider/realtime';
import { withData } from './data';

const query = gql`
    query withDeletedObjects($modifiedSince: DateTime!) {
        changedObjects(modifiedSince: $modifiedSince) {
            ... on ScheduledAudioCall {
                id
                deleted
            }
        }
    }
`;

export class DeletedCheck extends Component {
    static displayName = 'DeletedCheckContainer';

    static propTypes = {
        client: PropTypes.shape({
            query: PropTypes.func
        }).isRequired,
        orgPusherToken: PropTypes.string,
        realtime: PropTypes.objectOf(PropTypes.any).isRequired,
        userId: PropTypes.string
    };

    static defaultProps = {
        userId: null,
        orgPusherToken: null
    };

    componentDidMount() {
        this.trySubscribe();
    }

    componentDidUpdate({ userId: prevUserId }) {
        const { userId } = this.props;
        if (prevUserId && userId && prevUserId !== userId) {
            this.unsubscribe();
        }
        this.trySubscribe();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    trySubscribe() {
        const { orgPusherToken, realtime } = this.props;
        if (!this.subscription && orgPusherToken) {
            this.lastChecked = new Date();
            this.subscription = realtime.subscribe(`organization_${orgPusherToken}`, 'changed_objects', () => {
                const { client } = this.props;
                const nextDate = new Date();
                client.query({ query, variables: { modifiedSince: this.lastChecked } }).then(() => {
                    this.lastChecked = nextDate;
                });
            });
        }
    }

    unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    render() {
        return null;
    }
}

export const DeletedCheckContainer = compose(withData(), withRealtime(), withApollo)(DeletedCheck);
