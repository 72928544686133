export const styleSheet = theme => ({
    hint: {
        marginLeft: 4,
        ':first-of-type': {
            marginLeft: 0
        }
    },
    optionsContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: 3
    },
    option: {
        alignItems: 'center',
        backgroundColor: theme.colors.gray10,
        borderRadius: 6,
        color: theme.colors.black01,
        cursor: 'pointer',
        display: 'flex',
        height: 24,
        justifyContent: 'space-between',
        margin: '2px 2px 4px',
        maxWidth: 100,
        padding: '0 5px',
        // Overwrite default Option container styles
        ':first-of-type': {
            borderRadius: 6
        },
        ':last-of-type': {
            borderRadius: 6
        },
        ':hover': {
            p: {
                color: theme.colors.black01,
                marginRight: 4
            }
        },
        p: {
            color: theme.colors.gray06,
            marginRight: 4
        }
    }
});
