import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { withStyleSheet } from 'hoc/styles';
import { Hint } from 'components/Hint';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { TooltipTagForm } from 'components/TooltipTagForm';
import { styleSheet } from './stylesheet';

const MAPPED_CONTENT_TYPES = {
    transcript: 'Event',
    spotlight: 'Corp. Activity',
    news: 'News',
    filing: 'Filing'
};

class ContentActionBlock extends PureComponent {
    static propTypes = {
        contentType: PropTypes.string,
        isArchived: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        onArchive: PropTypes.func.isRequired,
        onStar: PropTypes.func.isRequired,
        onTag: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        tags: PropTypes.arrayOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        userTags: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        contentType: 'content',
        passedStyles: {},
        tags: [],
        userTags: []
    };

    renderTagBlock() {
        const { contentType, styles, theme, onTag, tags, userTags } = this.props;
        const hasTags = userTags.length > 0 || tags.length > 0;
        const mappedContentType = MAPPED_CONTENT_TYPES[contentType] || contentType;
        const newTagForm = ({ hideTooltip }) => <TooltipTagForm onSubmit={onTag} hideTooltip={hideTooltip} />;

        const addTagCircle = (
            <Div styles={styles.addTagCircle}>
                <Icon type="plus" color={theme.colors.white01} />
            </Div>
        );

        return (
            <Div styles={styles.tagsBlock}>
                <Tooltip
                    cancelClassName="tab-tooltip"
                    isEnabled
                    content={newTagForm}
                    persistOnMouseExit
                    styles={styles.tooltipMenu}
                    useElementOffsetLeft
                    useElementOffsetTop
                    useOutsideClickHandler
                    keepInViewport
                >
                    {({ showTooltip }) =>
                        hasTags ? (
                            <Div onClick={showTooltip} styles={styles.hasTagsButton}>
                                {addTagCircle}
                            </Div>
                        ) : (
                            <Div onClick={showTooltip} styles={styles.addTagButton}>
                                {addTagCircle}
                                <Text size={1}>Tag {mappedContentType}</Text>
                            </Div>
                        )
                    }
                </Tooltip>
                <Hint disabled={!hasTags} styles={styles.tags} growUp yOffset={-27} text="Filter tagged content">
                    {userTags.map(tag => (
                        <Div key={tag} styles={styles.tag}>
                            <Link to={`${routes.search}?dashSearch=${tag}`}>
                                <Text size={1}>{tag}</Text>
                            </Link>
                            <Div
                                data-tname="remove-tag"
                                className="tagRemove"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    onTag({ remove: tag });
                                }}
                            >
                                <Icon type="xMark02" color={theme.colors.gray06} />
                            </Div>
                        </Div>
                    ))}
                    {tags.map(({ tag, users }) => (
                        <Hint key={tag} styles={styles.tag} text={users.map(({ username }) => username).join(', ')}>
                            <Link to={`${routes.search}?dashSearch=${tag}`}>
                                <Text size={1}>{tag}</Text>
                            </Link>
                        </Hint>
                    ))}
                </Hint>
            </Div>
        );
    }

    render() {
        const { contentType, passedStyles, onStar, onArchive, isArchived, isStarred, styles, theme } = this.props;

        return (
            <Div className="print-hide" styles={{ ...styles.container, ...passedStyles }}>
                <Hint
                    onClick={onStar}
                    yOffset={-36}
                    growUp
                    text="Find your starred content, by company"
                    description={'In the company activity, filter to "starred".'}
                    styles={styles.starBlock}
                >
                    <Icon
                        type={isStarred ? 'star' : 'starOutline'}
                        color={isStarred ? theme.colors.yellow05 : theme.colors.gray04}
                    />
                    <Text size={1}>{isStarred ? 'Unstar' : 'Star'}</Text>
                </Hint>
                {this.renderTagBlock()}
                <Hint
                    yOffset={-36}
                    text={`Archive ${MAPPED_CONTENT_TYPES[contentType] || contentType}`}
                    description="Hide from results"
                    growLeft
                    growUp
                    styles={styles.archiveBlock}
                    onClick={onArchive}
                >
                    <Icon type="archive" color={theme.colors.gray04} />
                    <Text size={1}>{isArchived ? 'Unarchive' : 'Archive'}</Text>
                </Hint>
            </Div>
        );
    }
}

export const ContentActionBlockUI = compose(withStyleSheet(styleSheet))(ContentActionBlock);
