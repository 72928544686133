import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { SubmitEventDetailsFormUI } from './ui';

export class SubmitEventDetailsForm extends PureComponent {
    static displayName = 'SubmitEventDetailsFormContainer';

    static propTypes = {
        onClose: PropTypes.func,
        submitDetails: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        onClose: null,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            form: {},
            submitting: false,
            submitted: false
        };
    }

    onChange({ name, value }) {
        this.setState(({ form }) => ({
            form: {
                ...form,
                [name]: value
            }
        }));
    }

    onSubmit() {
        const { submitDetails } = this.props;
        const { form } = this.state;
        this.setState({ submitting: true });
        submitDetails(form)
            .then(() => this.setState({ submitted: true }))
            .finally(() => {
                this.setState({ submitting: false });
            });
    }

    render() {
        const { onClose, styles } = this.props;
        const { form, submitting, submitted } = this.state;
        return (
            <SubmitEventDetailsFormUI
                form={form}
                onChange={this.onChange}
                onClose={onClose}
                onSubmit={this.onSubmit}
                submitting={submitting}
                submitDisabled={submitting || !Object.values(form).filter(v => v).length}
                submitted={submitted}
                styles={styles}
            />
        );
    }
}

export const SubmitEventDetailsFormContainer = compose(withData())(SubmitEventDetailsForm);
