import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TYPES } from 'consts/filters';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Filters } from 'components/Filters';
import { styleSheet } from './stylesheet';

class WatchlistFilters extends PureComponent {
    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        name: null,
        onChange: null,
        passedStyles: {},
        value: null
    };

    render() {
        const { name, onChange, passedStyles, styles, value } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Filters
                    name={name}
                    onChange={onChange}
                    types={[
                        TYPES.exchangeCountry,
                        TYPES.country,
                        TYPES.index,
                        TYPES.companyType,
                        TYPES.offeringType,
                        TYPES.pGaapEps,
                        TYPES.evSales,
                        TYPES.evEbitda,
                        TYPES.evFcf
                    ]}
                    value={value}
                />
            </Div>
        );
    }
}

export const WatchlistFiltersUI = compose(withStyleSheet(styleSheet))(WatchlistFilters);
