import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { LoaderLogo } from 'components/LoaderLogo';
import { RawHTML } from 'components/RawHTML';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Th } from 'components/Th';
import { Tr } from 'components/Tr';
import { toDurationString, get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'transcripts';

class TranscriptResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        setPreview: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    componentDidUpdate(prevProps) {
        const { highlightedResult: prevHighlightedResult } = prevProps;
        const { highlightedResult, setPreview, results } = this.props;
        const { type, index } = highlightedResult;
        const { type: prevType, index: prevIndex } = prevHighlightedResult;

        if (type === RESULT_TYPE && (type !== prevType || index !== prevIndex)) {
            const result = results[index];
            if (result) {
                clearTimeout(this.previewTimeout);
                this.previewTimeout = setTimeout(() => {
                    setPreview(this.generatePreview(result));
                }, 50);
            }
        }
    }

    /* eslint-disable react/no-array-index-key */
    generatePreview(t) {
        const { company, equityIcon, ticker, title, equityInitials, callDate, transcript, grouped, startMs } = t;
        const firstMatchTime = toDurationString(startMs / 1000);
        const { styles, onClickResult } = this.props;
        return (
            <Div styles={styles.preview} onClick={onClickResult}>
                <Div styles={styles.previewBlock}>
                    <CardHeader
                        styles={styles.previewHeader}
                        context={ticker}
                        iconUrl={equityIcon}
                        iconText={equityInitials}
                        title={company}
                    />
                    <Div styles={styles.previewSubtitle}>
                        <Text size={3}>{title}</Text>
                    </Div>
                </Div>
                {callDate && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            Event date
                        </Text>
                        <Text size={3}>{new XDate(callDate).toString('MMM d, yyyy, h:ssTT')}</Text>
                    </Div>
                )}
                {transcript && (
                    <Div styles={styles.previewBlock}>
                        <Text size={0} styles={styles.previewLabel} uppercase>
                            {`Transcript match @ ${firstMatchTime}`}
                        </Text>
                        <Div styles={styles.transcript}>
                            <RawHTML html={transcript} />
                        </Div>
                    </Div>
                )}
                {grouped.map((match, index) => {
                    const seconds = get(match, 'startMs', 0) / 1000;
                    const time = toDurationString(seconds);

                    return (
                        <Div key={`${time}-${index}-search-tsc`} styles={styles.previewBlock}>
                            <Text size={0} styles={styles.previewLabel} uppercase>
                                {`additional match @ ${time}`}
                            </Text>
                            <Div styles={styles.transcript}>
                                <RawHTML html={match.transcript} />
                            </Div>
                        </Div>
                    );
                })}
            </Div>
        );
    }

    render() {
        const {
            styles,
            enabled,
            results,
            loading,
            loadingMoreResults,
            loadMoreResults,
            hasMoreResults,
            highlightResult,
            highlightedResult,
            indexElement,
            onClickResult
        } = this.props;
        if (!enabled) {
            return null;
        }

        return (
            <Table styles={styles.results}>
                <Tr styles={styles.resultHeaders}>
                    <Th colSpan={2}>Transcript</Th>
                    <Th>Date</Th>
                </Tr>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results
                        .filter(({ type }) => type !== 'custom')
                        .map((content, index) => {
                            const {
                                equityIcon,
                                equityInitials,
                                title,
                                callDate,
                                transcript,
                                company,
                                ticker,
                                id,
                                isLive
                            } = content;
                            const date = callDate ? new XDate(callDate) : null;
                            const dateString = date ? date.toString('MM/dd/yyyy') : null;
                            const timeString = date ? date.toString('h:mmTT') : null;
                            const resultStyles =
                                RESULT_TYPE === get(highlightedResult, 'type') &&
                                index === get(highlightedResult, 'index')
                                    ? styles.resultHighlighted
                                    : styles.result;
                            return (
                                <Tr
                                    elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                    key={`transcript-${id}-${ticker}`}
                                    styles={resultStyles}
                                    onClick={onClickResult}
                                    onMouseEnter={() => {
                                        highlightResult(RESULT_TYPE, index);
                                    }}
                                >
                                    <Td>
                                        {equityIcon ? (
                                            <Div styles={styles.companyIcon}>
                                                <Img src={equityIcon} />
                                            </Div>
                                        ) : (
                                            <Div styles={styles.equityInitials}>
                                                <Text uppercase className="equityInitials" size={0}>
                                                    {equityInitials}
                                                </Text>
                                            </Div>
                                        )}
                                    </Td>
                                    <Td colSpan={2}>
                                        <Div styles={styles.resultTop}>
                                            {company ? (
                                                <Div>
                                                    {`${company} (${ticker})`}
                                                    <br />
                                                    <Text styles={styles.subTitle}>{title}</Text>
                                                </Div>
                                            ) : (
                                                title
                                            )}
                                            {callDate && (
                                                <Div styles={styles.eventDateTime}>
                                                    {dateString}
                                                    {isLive ? (
                                                        <Text size={0} styles={styles.liveStatus} uppercase>
                                                            {`live from ${timeString}`}
                                                        </Text>
                                                    ) : (
                                                        <Text size={1} styles={styles.eventTime}>
                                                            {timeString}
                                                        </Text>
                                                    )}
                                                </Div>
                                            )}
                                        </Div>
                                        <Div styles={styles.transcript}>
                                            <RawHTML html={transcript} />
                                        </Div>
                                    </Td>
                                </Tr>
                            );
                        })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr>
                        <Td colSpan={4}>
                            <ActionButton
                                onClick={() => loadMoreResults('transcript')}
                                size={1}
                                styles={styles.loadMoreButton}
                            >
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const TranscriptResultsUI = compose(withStyleSheet(styleSheet))(TranscriptResults);
