import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Tr } from 'components/Tr';

import { styleSheet } from './stylesheet';

class ModuleTr extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, children, ...rest } = this.props;
        return (
            <Tr styles={{ ...styles.container, ...passedStyles }} {...rest}>
                {children}
            </Tr>
        );
    }
}

export const ModuleTrUI = compose(withStyleSheet(styleSheet))(ModuleTr);
