export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#1E2031',
        borderRadius: 10,
        position: 'fixed',
        top: 20,
        left: 20,
        zIndex: 990000,
        color: theme.colors.white01,
        boxShadow: '5px 7px 14px 0 rgba(0, 0, 0, 0.3)'
    },
    header: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    padding: {
        padding: '14px 14px',
        cursor: 'grab',
        height: '100%'
    },
    popoverTitle: {
        color: theme.colors.gray04
    },
    popoverClose: {
        cursor: 'pointer',
        ':hover': {
            color: theme.colors.red01
        }
    },
    viewer: {
        height: 'calc(100% - 22px)',
        borderRadius: 6,
        overflow: 'hidden',
        flex: 1
    }
});
