export const styleSheet = theme => ({
    container: {
        transition: 'all 0.1s ease-in-out',
        color: theme.colors.purple01,
        backgroundColor: theme.colors.white01,
        height: '23px',
        display: 'inline-flex',
        flexDirection: 'column',
        flexShrink: 1,
        justifyContent: 'center',
        padding: '1px 5px 0',
        borderRadius: theme.utils.borderRadius,
        boxShadow: `0 0 0 1px ${theme.colors.tickerTagBorder}`,
        whiteSpace: 'nowrap'
    },
    containerSmall: {
        transition: 'all 0.1s ease-in-out',
        backgroundColor: theme.colors.white02,
        border: `1px solid ${theme.colors.gray01}`,
        color: theme.colors.black01,
        lineHeight: '18px',
        display: 'inline-flex',
        flexDirection: 'column',
        flexShrink: 1,
        justifyContent: 'center',
        padding: '0 4px',
        borderRadius: theme.utils.borderRadius,
        span: {
            fontSize: '12px',
            letterSpacing: '1px',
            fontWeight: theme.fonts.fontWeights.medium
        }
    }
});
