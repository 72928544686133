export const styleSheet = theme => ({
    actionMenu: {
        ...theme.content.cardActionMenu
    },
    compact: {
        borderRadius: 'unset',
        border: 'unset',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        padding: '7px 13px 10px',
        '.title': {
            ...theme.content.annotated
        }
    },
    container: {
        '.title': {
            ...theme.content.annotated
        }
    },
    containerComfortable: {
        '.title': {
            ...theme.content.annotated
        }
    },
    containerCompact: {
        borderRadius: 'unset',
        width: 'unset',
        margin: 'unset'
    },
    content: {
        ...theme.content.annotated,
        fontSize: `calc(${theme.fonts.fontSizes.size03} - 1px)`,
        color: theme.colors.gray08,
        lineHeight: '1.3em',
        margin: '12px -5px -3px',
        padding: '3px 5px',
        borderRadius: 3.5
    },
    date: {
        whiteSpace: 'nowrap',
        color: theme.colors.gray04
    },
    source: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.colors.gray04
    },
    subText: {
        display: 'flex',
        marginTop: 3
    },
    subTextCompact: {
        display: 'flex',
        marginTop: 3
    }
});
