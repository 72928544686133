export const styleSheet = theme => ({
    allQuestions: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: '0 20px',
        paddingBottom: 20
    },
    export: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 4,
        color: theme.colors.gray06,
        '> div': {
            cursor: 'pointer',
            ':hover': {
                path: {
                    fill: theme.colors.blue08
                }
            }
        },
        p: {
            marginRight: 8
        }
    },
    questionsHeader: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    questionsEmpty: {
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.gray04,
        textAlign: 'center',
        lineHeight: '1.3em',
        flex: 1
    },
    yourQuestions: {
        color: theme.colors.gray04,
        letterSpacing: 1
    },
    pdfPage: {
        paddingTop: 26,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 26,
        fontFamily: 'Rubik'
    },
    pdfBlock: {
        marginBottom: 30
    },
    pdfBlockTitle: {
        fontWeight: 700,
        fontSize: 12
    },
    pdfBlockText: {
        fontSize: 12,
        marginBottom: 10,
        lineHeight: '1.3em'
    },
    pdfBlockDate: {
        color: '#717181',
        fontSize: 9,
        marginBottom: 12
    },
    pdfHeader: {
        marginBottom: 24
    },
    pdfTitle: {
        color: '#000000',
        fontSize: 24,
        fontFamily: 'CharisSIL',
        fontWeight: 700
    },
    pdfBlockSummaryTitle: {
        color: '#000000',
        fontSize: 14,
        fontWeight: 700,
        marginTop: 4,
        marginBottom: 4,
        lineHeight: '1.4em',
        letterSpacing: -0.2
    },
    pdfSubTitle: {
        color: '#717181',
        fontSize: 12,
        marginBottom: 4,
        textTransform: 'capitalize'
    },
    pdfFooter: {
        marginTop: 12
    },
    pdfWatermarkText: {
        marginTop: 4,
        fontSize: 12
    },
    pdfDisclaimer: {
        backgroundColor: '#f5fdff',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        paddingRight: 14,
        marginBottom: 10,
        borderRadius: 6
    },
    pdfDisclaimerText: {
        color: '#568f9b',
        fontSize: 12
    }
});
