import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { CirclePlusButtonUI } from './ui';

export class CirclePlusButton extends PureComponent {
    static displayName = 'CirclePlusButtonContainer';

    static propTypes = {
        hoverOptions: PropTypes.arrayOf(PropTypes.any),
        onClick: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tooltipOptions: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        hoverOptions: [],
        styles: {},
        tooltipOptions: {}
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(value, restricted) {
        const { onClick, hoverOptions } = this.props;
        if (onClick) {
            if (hoverOptions.length > 0) {
                onClick({ value, restricted });
            } else {
                onClick(value);
            }
        }
    }

    render() {
        const { styles, hoverOptions, tooltipOptions } = this.props;
        return (
            <CirclePlusButtonUI
                tooltipOptions={tooltipOptions}
                styles={styles}
                hoverOptions={hoverOptions}
                onClick={this.onClick}
            />
        );
    }
}

export const CirclePlusButtonContainer = compose()(CirclePlusButton);
