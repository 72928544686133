import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withTrackUserActivity } from 'graphql/user';
import { withUpdateUserObjectSettings } from 'graphql/utils';
import { withData } from './data';
import { SpotlightUI } from './ui';

export class Spotlight extends PureComponent {
    static displayName = 'SpotlightContainer';

    static propTypes = {
        body: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.any).isRequired,
        companies: PropTypes.arrayOf(PropTypes.any),
        company: PropTypes.string,
        companyId: PropTypes.string,
        contentType: PropTypes.string,
        copyright: PropTypes.string,
        displayType: PropTypes.string,
        eventDate: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.any),
        exchangeName: PropTypes.string,
        isPopout: PropTypes.bool,
        isArchived: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        license: PropTypes.string,
        markRead: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        spotlightError: PropTypes.bool,
        spotlightId: PropTypes.string,
        spotlightLoading: PropTypes.bool,
        spotlightSubtype: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string,
        trackContentActivity: PropTypes.func.isRequired,
        url: PropTypes.string,
        userTags: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        body: undefined,
        companies: [],
        company: undefined,
        companyId: undefined,
        contentType: 'spotlight',
        copyright: undefined,
        displayType: undefined,
        eventDate: undefined,
        events: [],
        exchangeName: undefined,
        isPopout: false,
        license: undefined,
        publishedDate: undefined,
        setToolbarTitle: null,
        spotlightError: false,
        spotlightId: null,
        spotlightLoading: false,
        spotlightSubtype: null,
        streamId: null,
        styles: undefined,
        tags: [],
        ticker: undefined,
        title: 'Loading...',
        url: undefined,
        userTags: []
    };

    constructor(props) {
        super(props);

        this.containerRef = createRef();
        this.documentTitle = document.title;
        this.scrolled = false;
    }

    componentDidMount() {
        const { isRead, markRead, trackContentActivity, spotlightId } = this.props;
        if (spotlightId) {
            trackContentActivity(spotlightId);
            if (!isRead) {
                markRead({ targetId: spotlightId, targetType: 'content', isRead: true });
            }
        }
        this.maybeScrollToMatch();
    }

    componentDidUpdate({
        spotlightId: prevNewsId,
        streamId: prevStreamId,
        spotlightLoading: prevLoading,
        title: prevTitle
    }) {
        const { spotlightId, streamId, trackContentActivity, spotlightLoading, title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | Corporate Activity | ${title}`;
        }

        if (spotlightId !== prevNewsId || streamId !== prevStreamId || spotlightLoading !== prevLoading) {
            this.scrolled = false;
            trackContentActivity(spotlightId);
            this.maybeScrollToMatch();
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    maybeScrollToMatch() {
        const { body, streamId } = this.props;
        if (streamId && body && this.containerRef.current) {
            const highlights = this.containerRef.current.getElementsByTagName('em');
            this.scrolled = true;
            if (highlights.length) {
                const firstHighlight = highlights[0];
                firstHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    render() {
        const {
            body,
            categories,
            companies,
            company,
            companyId,
            contentType,
            copyright,
            displayType,
            eventDate,
            events,
            exchangeName,
            isPopout,
            isRead,
            isArchived,
            isStarred,
            license,
            pathname,
            publishedDate,
            setToolbarTitle,
            spotlightError,
            spotlightLoading,
            spotlightSubtype,
            spotlightId,
            styles,
            tags,
            ticker,
            title,
            url,
            userTags
        } = this.props;
        return (
            <SpotlightUI
                body={body}
                categories={categories}
                companies={companies}
                company={company}
                companyId={companyId}
                containerRef={this.containerRef}
                contentType={contentType}
                copyright={copyright}
                displayType={displayType}
                errorLoadingDetails={spotlightError}
                eventDate={eventDate}
                events={events}
                exchangeName={exchangeName}
                isPopout={isPopout}
                isArchived={isArchived}
                isRead={isRead}
                isStarred={isStarred}
                license={license}
                loadingDetails={spotlightLoading}
                pathname={pathname}
                publishedDate={publishedDate}
                setToolbarTitle={setToolbarTitle}
                spotlightId={spotlightId}
                spotlightSubtype={spotlightSubtype}
                styles={styles}
                tags={tags}
                ticker={ticker}
                title={title}
                url={url}
                userTags={userTags}
            />
        );
    }
}

export const SpotlightContainer = compose(
    withTrackUserActivity(),
    withUrlContext(['streamId', 'pathname']),
    withUpdateUserObjectSettings(),
    withData()
)(Spotlight);
