import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';
import XDate from 'xdate';
import { compose, lifecycle, withProps } from 'recompose';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';
import { eventStreamMatchesQuery } from './localStreamsData';

const SENTIMENT_TYPES = {
    divergent: 'Divergence',
    negative: 'Negative',
    positive: 'Positive'
};

function normalizeMatch(match) {
    return {
        eventId: get(match, 'itemId'),
        startMs: get(match, 'transcript.startMs'),
        text: get(match, 'highlights', [])
            .filter((h, i) => get(match, `highlightFields[${i}]`, '').split('.')[0] === 'text')
            .join(' '),
        timestamp: get(match, 'transcript.startTimestamp'),
        speakerName: get(match, 'transcript.speaker.name'),
        speakerTitle: get(match, 'transcript.speaker.title'),
        speakerAffiliation: get(match, 'transcript.speaker.affiliation')
    };
}

function normalizeSentiment(sentiment, callDate) {
    const { tonal } = sentiment || {};
    const data = {};
    if (tonal) {
        const sentimentTypes = {};
        tonal.forEach(({ speakerTurn, tags, textSample, textualScore, tonalScore }) => {
            const tag = tags.includes('divergent') ? 'divergent' : tags[0];
            const key = SENTIMENT_TYPES[tag];
            const startMs = get(speakerTurn, 'startEventItem.startMs');
            const row = {
                itemId: get(speakerTurn, 'startEventItem.itemId'),
                speakerAffiliation: get(speakerTurn, 'speaker.affiliation'),
                speakerName: get(speakerTurn, 'speaker.name'),
                speakerTitle: get(speakerTurn, 'speaker.title'),
                startTime: startMs ? new XDate(callDate).addMilliseconds(startMs).toString('h:mm:ss') : null,
                startMs,
                textSample,
                transcript: textSample,
                textualScore,
                tonalScore
            };
            if (sentimentTypes[key]) {
                sentimentTypes[key].push(row);
            } else {
                sentimentTypes[key] = [row];
            }
        });
        data.tonalSentiment = sentimentTypes;
    }
    return data;
}

const normalizeStreamMatches = (streams = []) => {
    const sortedStreams = sortBy(streams, s => get(s, 'matches.results.length', 0));
    const sortedDashIds = [];
    streams.forEach(s => {
        const dashId = get(s, 'dashboards[0].id');
        if (!sortedDashIds.includes(dashId)) {
            sortedDashIds.push(dashId);
        }
    });
    const dashboardMap = {};
    const keyMentions = [];
    streams
        .filter(s => get(s, 'dashboards', []).length > 0 && get(s, 'matches.results.length', 0) > 0)
        .forEach(stream => {
            const dashboardId = get(stream, 'dashboards[0].id');
            const dashboard = dashboardMap[dashboardId];
            const dashboardName = get(stream, 'dashboards[0].name');
            const sentimentMovementPercent = get(stream, 'matches.averageSentimentScoreMovementPercent');
            const absMovement = Math.abs(sentimentMovementPercent);
            const match = {
                streamId: get(stream, 'id'),
                name: get(stream, 'name'),
                matches: get(stream, 'matches.results', []).flatMap(m => [
                    normalizeMatch(m),
                    ...get(m, 'collapsed', []).map(c => normalizeMatch(c))
                ])
            };

            if (sentimentMovementPercent && absMovement > 0.02) {
                if (absMovement > 1) {
                    match.sentimentMovementPercent = 3;
                } else if (absMovement > 0.25 && absMovement <= 1) {
                    match.sentimentMovementPercent = 2;
                } else if (absMovement >= 0 && absMovement <= 0.25) {
                    match.sentimentMovementPercent = 1;
                }
                match.sentimentMovement = sentimentMovementPercent;
            }
            if (dashboardName.toLowerCase().includes('quick links')) {
                keyMentions.push(match);
            } else if (dashboard) {
                dashboard.streams.push(match);
            } else {
                dashboardMap[dashboardId] = {
                    id: dashboardId,
                    name: dashboardName,
                    streams: [match]
                };
            }
        });

    const monitorMatches = sortBy(Object.values(dashboardMap), dash => sortedDashIds.indexOf(dash.id)).map(dash => ({
        ...dash,
        streams: sortBy(dash.streams, s => get(s, 'matches.length', 0)).reverse()
    }));

    return {
        keyMentions,
        monitorMatches,
        streamMatches: sortedStreams.map(stream => ({
            dashboardId: get(stream, 'dashboards[0].id'),
            dashboardName: get(stream, 'dashboards[0].name'),
            streamId: get(stream, 'id'),
            name: get(stream, 'name'),
            matches: get(stream, 'matches.results', []).flatMap(m => [
                normalizeMatch(m),
                ...get(m, 'collapsed', []).map(c => normalizeMatch(c))
            ])
        })),
        localMonitorMatches: sortedStreams
            .filter(s => get(s, 'dashboards', []).length === 0)
            .map(stream => ({
                streamId: get(stream, 'id'),
                name: get(stream, 'name'),
                matches: get(stream, 'matches.results', []).flatMap(m => [
                    normalizeMatch(m),
                    ...get(m, 'collapsed', []).map(c => normalizeMatch(c))
                ])
            }))
    };
};

export const withData = () =>
    compose(
        graphql(eventStreamMatchesQuery, {
            props: ({ data }) => ({
                streams: get(data, 'events[0].streams', []),
                refreshStreams: data.refetch,
                loadingSidebar: get(data, 'loading', false)
            }),
            options: ({ eventId, shareId, sidebarTab }) => ({
                fetchPolicy: 'cache-and-network',
                variables: {
                    eventId,
                    // Because the rule value is a different type, we have to pass as sep variable
                    eventIdScalar: eventId || shareId,
                    userStreams: sidebarTab === 'keyMentions',
                    quicklinkStreams: sidebarTab === 'keyMentions',
                    shareId
                }
            }),
            skip: ({ sidebarTab }) => sidebarTab !== 'keyMentions'
        }),
        graphql(
            gql`
                query withEventTonalSentiment($eventId: ID, $shareId: ID) {
                    events(eventIds: [$eventId], shareId: $shareId) {
                        id
                        callDate
                        sentiment {
                            tonal {
                                speakerTurn {
                                    id: speakerTurnId
                                    speaker {
                                        id
                                        affiliation
                                        name
                                        title
                                    }
                                    startEventItem {
                                        id
                                        itemId
                                        startMs
                                    }
                                }
                                tags
                                textSample
                                textualScore
                                tonalScore
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    callDate: get(data, 'events[0].callDate'),
                    loadingSentiment: get(data, 'loading'),
                    sentiment: get(data, 'events[0].sentiment')
                }),
                options: ({ eventId, shareId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        eventId,
                        shareId
                    }
                }),
                skip: ({ sidebarTab }) => sidebarTab !== 'keyMentions'
            }
        ),
        graphql(
            gql`
                query withUserToken {
                    currentUser {
                        id
                        pusherToken
                    }
                }
            `,
            {
                props: ({ data }) => ({ pusherToken: get(data, 'currentUser.pusherToken') }),
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                // We may not have the pusher token on mount, so save a function
                // onto the component that we can either call here or in componentDidUpdate
                // to subscribe as soon as we have the token.
                this.trySubscribe = () => {
                    if (!this.subscriptions) {
                        const { pusherToken, eventId, realtime } = this.props;
                        if (pusherToken && eventId) {
                            this.subscriptions = [
                                realtime.subscribe(`user_${pusherToken}`, 'stream_matches', () => {
                                    const { refreshStreams } = this.props;
                                    if (refreshStreams) refreshStreams();
                                }),
                                realtime.subscribe(`scheduled_audio_call_${eventId}_changes`, 'stream_matches', () => {
                                    const { refreshStreams } = this.props;
                                    if (refreshStreams) refreshStreams();
                                })
                            ];
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        }),
        withMemo({ normalizeSentiment, normalizeStreamMatches }),
        withProps(
            ({
                callDate,
                normalizeSentiment: normalizeSentiments,
                normalizeStreamMatches: normalize,
                sentiment,
                sidebarTab,
                streams
            }) => ({
                ...normalize(streams),
                ...(sidebarTab === 'keyMentions' && normalizeSentiments(sentiment, callDate))
            })
        )
    );
