import gql from 'graphql-tag';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { statusBannerFire } from 'actions/statusBanner';
import { TYPES } from 'consts/filters';
import { withMemo } from 'hoc/utils';
import { withRealtimeHighlights } from 'hoc/highlights';
import { bookmarkFragment, bookmarkTargetFragment } from 'graphql/fragments/bookmarks';
import { ruleFragment, streamFragment } from 'graphql/fragments/streams';
import { withUserPreferences } from 'graphql/user';
import { graphql } from 'graphql/utils';
import { getDefaultStreamOptions, withStreamPaging, withUpdateStreamOnChanges } from 'hoc/streams';
import { get } from 'utils';
import {
    formatMatchesByType,
    mapDashFiltersToRules,
    mapFiltersToRules,
    mapRulesToFilters,
    normalizeBookmarks
} from 'utils/streams';

const UPDATE_STREAM_PROPS = ['filterMode', 'name', 'rules', 'searchable', 'streamId', 'streamType', 'uxPreferences'];

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withMemo({
            formatMatches: formatMatchesByType,
            mapFilters: mapDashFiltersToRules,
            normalize: normalizeBookmarks
        }),
        withUserPreferences(),
        graphql(
            gql`
                query withBookmarkStream($filter: StreamMatchFilter, $offset: Int = 0, $size: Int = 20, $streamId: ID) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        matches(collapse: true, size: $size, fromIndex: $offset, filter: $filter) {
                            results {
                                id
                                bookmark {
                                    ...bookmark
                                    ...bookmarkTarget
                                }
                                collapsed {
                                    id
                                    bookmark {
                                        ...bookmark
                                        ...bookmarkTarget
                                    }
                                }
                            }
                        }
                        rules {
                            ...rule
                        }
                    }
                }
                ${streamFragment}
                ${bookmarkFragment}
                ${bookmarkTargetFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches, user } }) => {
                    const results = get(data, 'streams[0].matches.results', []);
                    const loading = data.loading && !results.length;
                    const rules = get(data, 'streams[0].rules', []);
                    const filters = [];
                    const scopes = [];
                    mapRulesToFilters(rules).forEach(f => {
                        if (f.type === TYPES.scope) {
                            scopes.push(f);
                        } else {
                            filters.push(f);
                        }
                    });
                    return {
                        bookmarks: formatMatches(results),
                        currentUserId: get(user, 'id'),
                        fetchMore: data.fetchMore,
                        filters,
                        loading: (!results || results.length === 0) && loading,
                        refetch: data.refetch,
                        refreshHighlights: data.refetch, // needed for realtime highlights
                        rules,
                        scopes,
                        stream: get(data, 'streams[0]')
                    };
                },
                options: ({
                    dashDateRange,
                    dashFilters,
                    dashSearchTerm,
                    dashSources,
                    dashEquityScope,
                    mapFilters,
                    streamId
                }) =>
                    getDefaultStreamOptions({
                        dashDateRange,
                        dashFilters,
                        dashSearchTerm,
                        dashSources,
                        dashEquityScope,
                        mapFilters,
                        streamId,
                        streamType: 'bookmarks'
                    }),
                skip: ({ streamId, streamProps }) => !get(streamProps, 'wasVisible', false) || !streamId
            }
        ),
        graphql(
            gql`
                mutation UpdateBookmarkStream($input: UpdateStreamInput!) {
                    updateStream(input: $input) {
                        stream {
                            ...stream
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate, ownProps: { filters, setStatusBanner, stream } }) => ({
                    updateStream: (streamId, scopes) => {
                        return mutate({
                            variables: {
                                input: {
                                    ...pick(stream, UPDATE_STREAM_PROPS),
                                    rules: mapFiltersToRules(filters.concat(scopes)),
                                    streamId
                                }
                            }
                        }).catch(error => {
                            setStatusBanner(`Error updating highlights search: ${error}`, 'error', 'circleX');
                        });
                    }
                })
            }
        ),
        withProps(({ bookmarks, normalize, pathname }) => ({
            bookmarks: normalize(bookmarks || [], pathname)
        })),
        withRealtimeHighlights(),
        withUpdateStreamOnChanges(),
        withStreamPaging()
    );
