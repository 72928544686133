import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Measure from 'react-measure';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { LoaderLogo } from 'components/LoaderLogo';
import { TAB_TYPES, PREFERENCES } from 'consts';
import { TabContext } from 'context';
import { CustomDataRecord, Event, Filing, News, Spotlight, StreetAccount, Report } from 'floatingTabs';
import { get, parseTabId, hasPreference, getPreference } from 'utils';
import { Research } from 'floatingTabs/Research';
import { EventPDF } from 'floatingTabs/EventPDF';
import { Document } from 'floatingTabs/Document';
import { styleSheet } from './stylesheet';
import { Toolbar } from './Toolbar';

class FloatingTab extends PureComponent {
    static propTypes = {
        animateMinimize: PropTypes.bool.isRequired,
        beforeMinimize: PropTypes.func.isRequired,
        connectScroll: PropTypes.func.isRequired,
        generatePath: PropTypes.func.isRequired,
        maximizedTabId: PropTypes.string,
        minimizedTabIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        preferences: PropTypes.objectOf(PropTypes.any).isRequired,
        removeTab: PropTypes.func.isRequired,
        scrolledHeaderContent: PropTypes.objectOf(PropTypes.any),
        scrollingWindows: PropTypes.objectOf(PropTypes.any).isRequired,
        setScrolledHeader: PropTypes.func.isRequired,
        setToolbarTitle: PropTypes.func.isRequired,
        showContent: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tabs: PropTypes.arrayOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toolbarTitles: PropTypes.objectOf(PropTypes.any),
        visibleHeaders: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        maximizedTabId: null,
        scrolledHeaderContent: {},
        tabs: null,
        toolbarTitles: {},
        visibleHeaders: []
    };

    constructor(props) {
        super(props);

        this.renderMaximizedTab = this.renderMaximizedTab.bind(this);

        this.state = {};
    }

    renderMaximizedTab(isFullScreen) {
        const {
            connectScroll,
            generatePath,
            beforeMinimize,
            maximizedTabId: tabId,
            preferences,
            removeTab,
            scrolledHeaderContent,
            scrollingWindows,
            setScrolledHeader,
            setToolbarTitle,
            showContent,
            styles,
            tabs,
            theme,
            toolbarTitles,
            visibleHeaders
        } = this.props;

        if (!tabs) {
            return [];
        }

        const tabDimensions = get(this.state, tabId, null);
        const containerEle = scrollingWindows[tabId];
        const { page: tabPage, pageId: tabPageId, id, tabType, tabKey } = parseTabId(tabId);
        const createTabPath = (newTabId, forPush) => generatePath(newTabId, forPush, 0);
        const contextObj = { tabId, tabIndex: 0, createTabPath, id, tabType, containerEle, tabDimensions };
        const showHeader = visibleHeaders.includes(tabId);
        const setHeader = component => setScrolledHeader(tabId, component);
        const setTitle = title => setToolbarTitle(tabId, title);
        const toolbarTitle = Object.keys(toolbarTitles).includes(tabId) ? toolbarTitles[tabId] : 'Loading...';
        const headerContent = Object.keys(scrolledHeaderContent).includes(tabId) ? scrolledHeaderContent[tabId] : null;
        let floatingTabStyles = isFullScreen
            ? { ...styles.floatingTab, ...styles.floatingTabFullScreen }
            : { ...styles.floatingTab };
        let content = null;
        let footerSpacerWidth = {};

        if (tabType === TAB_TYPES.news) {
            contextObj.newsId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabNews };
            footerSpacerWidth = { width: 960 };
            content = <News newsId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.report) {
            contextObj.reportId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabReport };
            footerSpacerWidth = { width: 760 };
            content = <Report reportId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.filing) {
            contextObj.filingId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabFiling };
            footerSpacerWidth = { width: 1060 };
            content = <Filing filingId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.spotlight) {
            contextObj.spotlightId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabSpotlight };
            footerSpacerWidth = { width: 740 };
            content = <Spotlight spotlightId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.pdfSlides) {
            contextObj.pdfSlidesId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabPDF };
            footerSpacerWidth = { width: 1200 };
            content = <EventPDF type="slides" eventId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.pdfPresentation) {
            contextObj.pdfPresentationId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabPDF };
            footerSpacerWidth = { width: 1200 };
            content = <EventPDF type="press release" eventId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.document) {
            contextObj.documentId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabPDF };
            footerSpacerWidth = { width: 1200 };
            content = <Document documentId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.research) {
            contextObj.researchId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabPDF };
            footerSpacerWidth = { width: 1200 };
            content = <Research researchId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.streetAccount) {
            contextObj.streetAccountId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabStreetAccount };
            footerSpacerWidth = { width: 740 };
            content = <StreetAccount streetAccountId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.dataRecord) {
            contextObj.dataRecordId = id;
            floatingTabStyles = { ...floatingTabStyles, ...styles.floatingTabCustomData };
            footerSpacerWidth = { width: 740 };
            content = <CustomDataRecord dataRecordId={id} setToolbarTitle={setTitle} />;
        }

        if (tabType === TAB_TYPES.event || tabType === TAB_TYPES.audioCall) {
            const isFocused = hasPreference(preferences, { ...PREFERENCES.eventFocusMode, value: true }, false);
            const eventTabStyle = isFocused ? styles.floatingTabEventFocused : styles.floatingTabEvent;
            contextObj.callId = id;
            contextObj.eventId = id;
            floatingTabStyles = { ...floatingTabStyles, ...eventTabStyle };
            footerSpacerWidth = isFocused ? { width: '650px' } : { width: '1000px' };
            content = (
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m => (
                        <Event
                            isFocused={!m && isFocused}
                            tabId={tabId}
                            audioCallId={id}
                            setScrolledHeader={setHeader}
                            setToolbarTitle={setTitle}
                            tabDimensions={tabDimensions}
                        />
                    )}
                </MediaQuery>
            );
        }

        if (tabPage && tabPage) {
            contextObj.tabPage = tabPage;
            contextObj.tabPageId = tabPageId;
            if ((tabPage === 'forecast' || tabPage === 'signal') && tabPageId) {
                contextObj.forecastId = tabPageId;
            }
            if (tabPage === 'pdf' && tabPageId) {
                contextObj.pdfIndex = tabPageId;
            }
        }

        if (content) {
            const tabContent = measureRef => (
                <Div className="tab-wrapper" styles={floatingTabStyles} ref={measureRef}>
                    {headerContent && (
                        <Div
                            className="print-hide"
                            styles={showHeader ? styles.scrolledHeader : styles.scrolledHeaderHidden}
                        >
                            {headerContent}
                        </Div>
                    )}
                    <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                        <Toolbar
                            beforeMinimize={beforeMinimize}
                            isFullScreen={isFullScreen}
                            title={toolbarTitle}
                            tabId={tabId}
                        />
                    </MediaQuery>
                    <Div className="tab-content" styles={styles.page} ref={node => connectScroll(tabId, node)}>
                        {showContent ? (
                            content
                        ) : (
                            <Div styles={styles.tabLoader}>
                                <LoaderLogo height={60} />
                            </Div>
                        )}
                    </Div>
                    <Div className="print-hide" styles={{ ...styles.footerSpacer, ...footerSpacerWidth }} />
                </Div>
            );
            return (
                // Currently, we have no way to support opening the same tab multiple
                // times with using the tab index as part of the key. Since we consider
                // these unique tabs, it makes sense to allow this here.
                //
                // eslint-disable-next-line react/no-array-index-key
                <TabContext.Provider value={contextObj} key={tabKey}>
                    <Measure
                        bounds
                        onResize={contentRect => {
                            this.setState({ [tabId]: contentRect.bounds });
                        }}
                    >
                        {({ measureRef }) =>
                            isFullScreen ? (
                                <Fragment>
                                    <Div
                                        className="tab-outside print-hide"
                                        style={styles.floatingTabFullScreenContainer}
                                    />
                                    <OutsideClickHandler
                                        cancelClassName={[
                                            'audio-playbar',
                                            'audio-playbar-tools',
                                            'autocomplete-option',
                                            'dropdown-option',
                                            'dropdown-placeholder',
                                            'dropdown-option--disabled',
                                            'media-controls',
                                            'modal-container',
                                            'popoverPDF',
                                            'tab-tooltip'
                                        ]}
                                        onClick={() => removeTab(tabId)}
                                    >
                                        {tabContent(measureRef)}
                                    </OutsideClickHandler>
                                </Fragment>
                            ) : (
                                tabContent(measureRef)
                            )
                        }
                    </Measure>
                </TabContext.Provider>
            );
        }

        return null;
    }

    render() {
        const { animateMinimize, styles, tabs, minimizedTabIds, preferences, theme } = this.props;

        if ((!tabs || tabs.length === 0) && (!minimizedTabIds || minimizedTabIds.length === 0)) {
            return null;
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {isMobile => {
                    const isFullScreen =
                        !isMobile && getPreference(preferences, PREFERENCES.floatingTabFullScreen, true);
                    let containerStyles = isFullScreen ? styles.containerFullScreen : styles.container;

                    if (animateMinimize) {
                        containerStyles = { ...containerStyles, ...styles.floatingTabMinimizeAnimation };
                    }

                    return (
                        tabs &&
                        tabs.length > 0 && (
                            <Div className="floating-tab" styles={containerStyles}>
                                {this.renderMaximizedTab(isFullScreen)}
                            </Div>
                        )
                    );
                }}
            </MediaQuery>
        );
    }
}

export const FloatingTabUI = compose(withStyleSheet(styleSheet))(FloatingTab);
