import gql from 'graphql-tag';
import XDate from 'xdate';
import { compose } from 'recompose';
import { graphql } from 'graphql/utils';
import { get, capitalize } from 'utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withEventPDFs($eventId: ID!) {
                    events(eventIds: [$eventId]) {
                        id
                        callType
                        callDate
                        title
                        companies {
                            id
                            commonName
                            primaryInstrument {
                                id
                                primaryQuote {
                                    id
                                    localTicker
                                    exchange {
                                        id
                                        shortName
                                    }
                                }
                            }
                        }
                        attachments {
                            title
                            mimeType
                            url
                            archivedUrl
                        }
                    }
                }
            `,
            {
                props: ({ data, ownProps: { type } }) => {
                    const event = get(data, 'events[0]');
                    const eventDate = get(event, 'callDate');
                    const eventType = get(event, 'callType', '');
                    const ticker = get(event, 'companies[0].primaryInstrument.primaryQuote.localTicker', '');
                    const year = eventDate ? new XDate(eventDate).toString('yyyy') : '';
                    const dateString = eventDate ? new XDate(eventDate).toString('MMM dd, h:mm TT') : '';
                    const title = `${ticker} ${eventType} ${year} - ${capitalize(type)}`;
                    const eventAttachments = get(event, 'attachments', []);
                    const company = get(event, 'companies[0]');
                    const pdf = eventAttachments.find(
                        ({ mimeType, title: t }) =>
                            mimeType === 'application/pdf' &&
                            (t.toLowerCase() === type || t.toLowerCase() === 'local language transcript')
                    );
                    const otherAttachment = type === 'slides' ? 'press release' : 'slides';
                    const hasOtherAttachment = eventAttachments.find(
                        ({ mimeType, title: t }) =>
                            mimeType === 'application/pdf' && t.toLowerCase() === otherAttachment
                    );
                    return {
                        loading: get(data, 'loading', false),
                        eventId: get(event, 'id'),
                        company,
                        dateString,
                        eventTitle: get(event, 'title'),
                        hasOtherAttachment: !!hasOtherAttachment,
                        pdfUrl: get(pdf, 'archivedUrl', ''),
                        title
                    };
                },
                options: ({ eventId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        eventId
                    }
                })
            }
        )
    );
