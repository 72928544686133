const USER_IS_IDLE = 'USER_IS_IDLE';
const USER_LOAD = 'USER_LOAD';
const USER_LOGOUT = 'USER_LOGOUT';

export const types = {
    USER_IS_IDLE,
    USER_LOAD,
    USER_LOGOUT
};

/**
 * Track when a user goes idle.
 * Used to pause polling queries.
 */
export function userIsIdle(isIdle) {
    return {
        payload: { isIdle },
        type: USER_IS_IDLE
    };
}

/**
 *
 * This is the hard logout - meaning the user
 * is intentionally logging out. Everything
 * will reset.
 *
 */

export function userLogout() {
    // USE THE SAME ACTION TYPE
    // WE ARE DESTROYING THE SESSION
    // ON THE CLIENT NO MATTER WHAT
    return {
        type: USER_LOGOUT,
        track: true
    };
}

/**
 *
 * This action loads the user into redux.
 *
 */

export function userLoad(payload) {
    return {
        type: USER_LOAD,
        payload
    };
}
