export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01,
        boxShadow: `0 1px 0 0 ${theme.colors.gray03}`,
        height: '34px',
        verticalAlign: 'middle',
        position: 'sticky',
        paddingRight: theme.margins.margin02,
        top: '0px',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingRight: theme.margins.margin01
        }
    },
    containerSortable: {
        zIndex: 1,
        backgroundColor: theme.colors.white01,
        boxShadow: `0 1px 0 0 ${theme.colors.gray03}`,
        height: '34px',
        verticalAlign: 'middle',
        position: 'sticky',
        paddingRight: theme.margins.margin02,
        top: '0px',
        cursor: 'pointer',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingRight: theme.margins.margin01
        },
        ':hover': {
            p: {
                color: theme.colors.black01
            }
        }
    },
    text: {
        letterSpacing: '2px',
        textAlign: 'left',
        color: theme.colors.gray06
    },
    columns: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sortContainer: {
        width: '6px',
        marginLeft: '6px'
    }
});
