import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { generateTabURL } from 'utils';
import { popoutManager } from 'utils/popout';
import { withUrlContext } from 'hoc/url';
import { FilingCardUI } from './ui';

export class FilingCard extends PureComponent {
    static displayName = 'FilingCardContainer';

    static propTypes = {
        allowInternalLink: PropTypes.bool,
        arrivalDate: PropTypes.string,
        bookmarkId: PropTypes.string,
        dashboardType: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
        formName: PropTypes.string,
        formNumber: PropTypes.string,
        formShortName: PropTypes.string,
        highlights: PropTypes.string,
        highlightsMatches: PropTypes.arrayOf(PropTypes.any),
        id: PropTypes.string,
        isAmendment: PropTypes.bool,
        matchId: PropTypes.string.isRequired,
        officialUrl: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        pdfUrl: PropTypes.string,
        primaryEquity: PropTypes.objectOf(PropTypes.any),
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string
    };

    static defaultProps = {
        allowInternalLink: true,
        arrivalDate: undefined,
        bookmarkId: undefined,
        dashboardType: 'default',
        events: [],
        formName: null,
        formNumber: null,
        formShortName: null,
        highlights: null,
        highlightsMatches: [],
        id: null,
        isAmendment: false,
        officialUrl: null,
        pdfUrl: undefined,
        primaryEquity: {},
        score: undefined,
        styles: {},
        streamId: null
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        if (e.shiftKey) {
            this.handlePopout(e);
        }
    }

    handlePopout(e) {
        const { id, streamId } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        popoutManager.open({
            url: generateTabURL({
                pathname: generatePath(routes.popoutFiling, { type: 'filing', filingId: id }),
                filingId: id,
                match: !!streamId,
                streamId
            }),
            name: `popout-filing-${id}`,
            width: 750
        });
    }

    render() {
        const {
            allowInternalLink,
            arrivalDate,
            bookmarkId,
            dashboardType,
            displayMode,
            events,
            formName,
            formNumber,
            formShortName,
            highlights,
            highlightsMatches,
            id,
            isAmendment,
            matchId,
            officialUrl,
            pathname,
            pdfUrl,
            primaryEquity,
            score,
            styles,
            streamId
        } = this.props;
        const tabURLParams = {
            pathname,
            filingId: id,
            streamId
        };
        if (pdfUrl) {
            const firstMatch = highlightsMatches.find(m => m.length > 0);
            tabURLParams.page = firstMatch ? firstMatch.join(',') : '';
        } else {
            tabURLParams.match = !!streamId;
        }
        return (
            <FilingCardUI
                arrivalDate={arrivalDate}
                bookmarkId={bookmarkId}
                dashboardType={dashboardType}
                displayMode={displayMode}
                events={events}
                formName={formName}
                formNumber={formNumber}
                formShortName={formShortName}
                highlights={highlights}
                id={id}
                isAmendment={isAmendment}
                matchId={matchId}
                officialUrl={officialUrl}
                onClick={allowInternalLink ? this.handleClick : undefined}
                pathname={pathname}
                primaryEquity={primaryEquity}
                score={score}
                streamId={streamId}
                styles={styles}
                to={allowInternalLink ? generateTabURL(tabURLParams) : undefined}
            />
        );
    }
}

export const FilingCardContainer = compose(withUrlContext(['pathname']))(FilingCard);
