import { types } from 'actions/zendesk';

const { WEB_WIDGET_HIDE, WEB_WIDGET_SHOW } = types;

const initialState = {
    visible: false
};

export function zendeskReducer(state = initialState, action) {
    const { type } = action;
    if (type === WEB_WIDGET_HIDE) {
        return {
            ...state,
            visible: false
        };
    }

    if (type === WEB_WIDGET_SHOW) {
        return {
            ...state,
            visible: true
        };
    }

    return state;
}
