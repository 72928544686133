import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import {
    dashboardFragment,
    ruleFragment,
    streamFragment,
    dataCollectionFragment,
    streamMatchTemplateFragment
} from 'graphql/fragments/streams';
import { withDeleteStream } from 'graphql/streams';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { mapStreamType, mapFiltersToRules } from 'utils/streams';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withStreamFormDashboard(
                    $dashboardId: ID
                    $streamId: ID
                    $clone: Boolean
                    $withStreams: Boolean = false
                ) {
                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                        ...dashboard
                    }
                    streams(filter: { streamIds: [$streamId] }, clone: $clone) @include(if: $withStreams) {
                        ...stream
                        lens {
                            rules {
                                ...rule
                            }
                        }
                        rules {
                            ...rule
                        }
                    }
                }
                ${dashboardFragment}
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: ({ data }) => {
                    const dashboard = get(data, 'dashboards[0]');
                    const stream = get(data, 'streams[0]');
                    return {
                        dashboard,
                        dashboardType: get(dashboard, 'dashboardType'),
                        dashboardCapabilities: get(dashboard, 'capabilities'),
                        loading: get(data, 'loading'),
                        stream,
                        title: `Edit ${get(stream, 'name', '')}`
                    };
                },
                skip: ({ dashboardId, streamId, sharedStreamId }) =>
                    (!streamId || streamId === 'new') && !sharedStreamId && !dashboardId,
                options: ({ dashboardId, streamId, sharedStreamId }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        clone: !!sharedStreamId,
                        dashboardId,
                        streamId: streamId || sharedStreamId,
                        withStreams: !!streamId || !!sharedStreamId
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation commitDataCollection($dataCollectionId: ID!) {
                    commitDataCollection(dataCollectionId: $dataCollectionId) {
                        dataCollection {
                            ...dataCollection
                        }
                    }
                }
                ${dataCollectionFragment}
            `,
            {
                props: ({ mutate }) => ({
                    commitDataCollection: dataCollectionId => mutate({ variables: { dataCollectionId } })
                })
            }
        ),
        graphql(
            gql`
                mutation commitStreamMatchTemplate($streamMatchTemplateId: ID!) {
                    commitStreamMatchTemplate(templateId: $streamMatchTemplateId) {
                        template {
                            ...streamMatchTemplate
                        }
                    }
                }
                ${streamMatchTemplateFragment}
            `,
            {
                props: ({ mutate }) => ({
                    commitStreamMatchTemplate: streamMatchTemplateId => mutate({ variables: { streamMatchTemplateId } })
                })
            }
        ),
        graphql(
            gql`
                mutation CreateStream($input: StreamInput!) {
                    createStream(input: $input) {
                        stream {
                            ...stream
                            lens {
                                rules {
                                    ...rule
                                }
                            }
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, commitDataCollection, commitStreamMatchTemplate } = ownProps;
                    return {
                        createStream: input =>
                            Promise.all([
                                input.dataCollectionId
                                    ? commitDataCollection(input.dataCollectionId)
                                    : Promise.resolve(),
                                input.streamMatchTemplateId
                                    ? commitStreamMatchTemplate(input.streamMatchTemplateId)
                                    : Promise.resolve()
                            ])
                                .then(() =>
                                    mutate({
                                        variables: { input },
                                        update: (proxy, response) => {
                                            const query = gql`
                                                query withStreamFormData($dashboardIds: [ID]!) {
                                                    dashboards(filter: { dashboardIds: $dashboardIds }) {
                                                        id
                                                        streams {
                                                            ...stream
                                                        }
                                                    }
                                                }
                                                ${streamFragment}
                                            `;
                                            try {
                                                const { dashboards } = proxy.readQuery({
                                                    query,
                                                    variables: {
                                                        dashboardIds: input.dashboardIds
                                                    }
                                                });
                                                const dashboard = get(dashboards, '[0]');
                                                const stream = get(response, 'data.createStream.stream');
                                                if (dashboard && stream) {
                                                    const streams = get(dashboard, 'streams', []);
                                                    proxy.writeQuery({
                                                        query,
                                                        data: {
                                                            dashboards: [
                                                                {
                                                                    ...dashboard,
                                                                    streams: [...streams, stream]
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                            } catch {
                                                // The stream's dashboard isn't in the cache yet, so do nothing
                                            }
                                        }
                                    })
                                )
                                .then(() => {
                                    setStatusBanner('Search saved successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error saving search: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation UpdateStream($input: UpdateStreamInput!) {
                    updateStream(input: $input) {
                        stream {
                            ...stream
                            lens {
                                rules {
                                    ...rule
                                }
                            }
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, commitDataCollection, commitStreamMatchTemplate } = ownProps;
                    return {
                        updateStream: input =>
                            Promise.all([
                                input.dataCollectionId
                                    ? commitDataCollection(input.dataCollectionId)
                                    : Promise.resolve(),
                                input.streamMatchTemplateId
                                    ? commitStreamMatchTemplate(input.streamMatchTemplateId)
                                    : Promise.resolve()
                            ])
                                .then(() =>
                                    mutate({
                                        variables: { input },
                                        update: (proxy, response) => {
                                            const query = gql`
                                                query withStreamFormData($dashboardId: ID) {
                                                    dashboards(filter: { dashboardIds: [$dashboardId] }) {
                                                        id
                                                        streams {
                                                            ...stream
                                                        }
                                                    }
                                                }
                                                ${streamFragment}
                                            `;

                                            // Stream's original dashboard
                                            const { dashboards: originalDashboards } = proxy.readQuery({
                                                query,
                                                variables: {
                                                    dashboardId: ownProps.dashboardId
                                                }
                                            });
                                            const originalDashboard = get(originalDashboards, '[0]');
                                            // The updated stream
                                            const stream = get(response, 'data.updateStream.stream');
                                            const currentDashboardId = input.dashboardIds[0];
                                            let moved = false;

                                            // If the stream's dashboard changes, add it to the new one
                                            if (ownProps.dashboardId !== currentDashboardId) {
                                                moved = true;
                                                try {
                                                    const { dashboards } = proxy.readQuery({
                                                        query,
                                                        variables: {
                                                            dashboardId: currentDashboardId
                                                        }
                                                    });
                                                    const currentDashboard = get(dashboards, '[0]');
                                                    if (currentDashboard && stream) {
                                                        proxy.writeQuery({
                                                            query,
                                                            data: {
                                                                dashboards: [
                                                                    {
                                                                        ...currentDashboard,
                                                                        streams: [
                                                                            ...get(currentDashboard, 'streams', []),
                                                                            stream
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        });
                                                    }
                                                } catch {
                                                    // Current dashboard isn't loaded in the cache, so do nothing
                                                }
                                            }

                                            if (originalDashboard && stream) {
                                                // If the stream is moved to another dashboard, remove it from the original
                                                // Otherwise, update the stream's dashboard's cached streams
                                                const streams = get(originalDashboard, 'streams', []);
                                                const newStreams = moved
                                                    ? streams.filter(s => s.id !== stream.streamId)
                                                    : streams.map(s => (s.id === stream.streamId ? stream : s));
                                                proxy.writeQuery({
                                                    query,
                                                    data: {
                                                        dashboards: [
                                                            {
                                                                ...originalDashboard,
                                                                streams: newStreams
                                                            }
                                                        ]
                                                    }
                                                });
                                            }
                                        }
                                    })
                                )
                                .then(() => {
                                    setStatusBanner('Saved Search updated successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error updating Saved Search: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation SetStreamLens($input: SetStreamRulesInput!) {
                    setStreamLens(input: $input) {
                        stream {
                            ...stream
                            lens {
                                rules {
                                    ...rule
                                }
                            }
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
            `,
            {
                props: ({ mutate }) => ({
                    removeFilingFormLens: (streamId, rules) => {
                        return mutate({
                            variables: {
                                input: {
                                    streamId,
                                    rules: rules.filter(({ ruleType }) => ruleType !== 'filing_form')
                                }
                            }
                        });
                    }
                })
            }
        ),
        withDeleteStream()
    );

export function formatInput({
    color,
    dashboardId,
    filters,
    ignoreDashFilters,
    name,
    streamId,
    streamType,
    dataCollectionId,
    streamMatchTemplateId,
    uxPreferences
}) {
    let input = {
        dataCollectionId,
        filterMode: ignoreDashFilters ? 'ignore_dashboard' : 'default',
        name,
        rules: mapFiltersToRules(filters),
        streamMatchTemplateId,
        streamType: mapStreamType(streamType)
    };

    if (streamId) {
        input = { ...input, streamId };
    }

    if (dashboardId) {
        input = {
            ...input,
            dashboardIds: Array.isArray(dashboardId) ? dashboardId : [dashboardId]
        };
    }

    if (color) {
        input = {
            ...input,
            uxPreferences: { ...uxPreferences, color }
        };
    }

    return input;
}
