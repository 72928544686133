export const styleSheet = theme => {
    const resultTd = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: 14,
        paddingTop: 5,
        paddingBottom: 5,
        lineHeight: '1em',
        paddingRight: theme.margins.margin01,
        ':first-of-type': {
            width: 44,
            paddingLeft: theme.margins.margin00,
            paddingRight: theme.margins.margin00
        }
    };
    return {
        results: {
            width: '100%'
        },
        result: {
            td: {
                ...resultTd
            },
            ':nth-of-type(even)': {
                td: {
                    backgroundColor: theme.colors.gray10
                }
            }
        },
        resultHighlighted: {
            td: {
                ...resultTd,
                backgroundColor: theme.colors.blue08,
                color: theme.colors.white01,
                span: {
                    color: theme.colors.white01
                },
                p: {
                    color: theme.colors.white01
                },
                '.equityInitials': {
                    color: theme.colors.gray06
                }
            }
        },
        equityInitials: {
            backgroundColor: theme.colors.gray03,
            color: theme.colors.gray06,
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        companyIcon: {
            width: 24,
            height: 24,
            borderRadius: 12,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            img: {
                maxHeight: 24
            }
        },
        eventDateTime: {
            display: 'flex',
            color: theme.colors.gray04,
            span: {
                color: theme.colors.gray06,
                marginRight: 6
            }
        },
        equityName: {
            color: theme.colors.gray04,
            letterSpacing: 1,
            span: {
                color: theme.colors.gray06,
                marginRight: 6
            }
        },
        eventTime: {
            color: theme.colors.gray06
        },
        eventTitle: {
            lineHeight: '1.2em'
        },
        loaderCell: {
            padding: `${theme.margins.margin03} 0`,
            div: {
                margin: 'auto'
            }
        },
        loadingMore: {
            padding: '12px 0',
            div: {
                margin: 'auto'
            }
        },
        loadButtonRow: {
            td: {
                padding: 10
            }
        },
        loadMoreButton: {
            borderRadius: 6,
            justifyContent: 'center',
            p: {
                color: theme.colors.gray04
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        },
        loadMoreButtonHighlighted: {
            borderRadius: 6,
            justifyContent: 'center',
            boxShadow: `0 0 0 1px ${theme.colors.blue08}`,
            p: {
                color: theme.colors.blue08
            },
            ':hover': {
                backgroundColor: theme.colors.white02,
                p: {
                    color: theme.colors.black01
                }
            }
        }
    };
};
