import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { Div } from 'components/Div';
import { Img } from 'components/Img';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

// Flag svg source: https://gitlab.com/catamphetamine/country-flag-icons#web
// eslint-disable-next-line react/prop-types
const LocaleAutocompleteOption = ({ label, metadata }) => {
    const countryCode = get(metadata, 'countryCode');
    return (
        <Div styles={{ display: 'flex', alignItems: 'center' }}>
            {!!countryCode && (
                <Img
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
                    styles={{ width: 20 }}
                />
            )}
            <Text size={3} styles={{ marginLeft: countryCode ? 8 : 28 }}>
                {label}
            </Text>
        </Div>
    );
};

class LocaleAutocomplete extends PureComponent {
    static propTypes = {
        defaultValue: PropTypes.string,
        error: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        locales: PropTypes.arrayOf(PropTypes.object).isRequired,
        loading: PropTypes.bool.isRequired,
        multi: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        useTags: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
    };

    static defaultProps = {
        defaultValue: undefined,
        error: undefined,
        initialSearchTerm: null,
        label: undefined,
        passedStyles: {},
        value: undefined
    };

    render() {
        const {
            defaultValue,
            error,
            initialSearchTerm,
            label,
            loading,
            locales,
            multi,
            name,
            onChange,
            passedStyles,
            placeholder,
            styles,
            useTags,
            value
        } = this.props;
        return (
            <Autocomplete
                error={error}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={onChange}
                OptionComponent={LocaleAutocompleteOption}
                options={locales}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                useTags={useTags}
                value={value === undefined ? defaultValue : value}
            />
        );
    }
}

export const LocaleAutocompleteUI = compose(withStyleSheet(styleSheet))(LocaleAutocomplete);
