import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class ColorMenu extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        colors: PropTypes.arrayOf(PropTypes.string).isRequired,
        hideLabel: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        selectedColor: PropTypes.string,
        useHighlightColors: PropTypes.bool.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        selectedColor: ''
    };

    render() {
        const {
            styles,
            passedStyles,
            label,
            colors,
            hideLabel,
            onSelect,
            selectedColor,
            useHighlightColors
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {!hideLabel && (
                    <Text size={0} styles={styles.label} uppercase>
                        {label}
                    </Text>
                )}
                <Div styles={styles.colors} className="colors">
                    {colors.map(color => {
                        const colorStyle = color === selectedColor ? styles.selectedColor : styles.color;
                        let backgroundColor = color === 'transparent' ? '#FAFAFC' : color;
                        let border;
                        if (useHighlightColors) {
                            backgroundColor = getHighlightColor(color)?.dark;
                            border = `1px solid ${getHighlightColor(color)?.dark02}`;
                        }
                        return (
                            <Div
                                key={color}
                                styles={{
                                    backgroundColor,
                                    border,
                                    ...colorStyle
                                }}
                                onClick={e => onSelect(e, color)}
                            />
                        );
                    })}
                </Div>
            </Div>
        );
    }
}

export const ColorMenuUI = compose(withStyleSheet(styleSheet))(ColorMenu);
