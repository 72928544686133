import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import ContentEditable from 'react-contenteditable';
import { Div } from 'components/Div';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { TextInput } from 'components/TextInput';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

class TaggedInput extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool.isRequired,
        canEditTags: PropTypes.bool,
        containerRef: PropTypes.func.isRequired,
        editingTag: PropTypes.string,
        editingValue: PropTypes.string,
        error: PropTypes.string,
        getTagLabel: PropTypes.func.isRequired,
        getTagStyle: PropTypes.func.isRequired,
        icon: PropTypes.string,
        id: PropTypes.string,
        inFocus: PropTypes.bool.isRequired,
        inputRef: PropTypes.objectOf(PropTypes.any),
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onEditBlur: PropTypes.func.isRequired,
        onEditChange: PropTypes.func.isRequired,
        onEditFocus: PropTypes.func.isRequired,
        onEditKeyDown: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onInputKeyDown: PropTypes.func.isRequired,
        onRemoveTag: PropTypes.func.isRequired,
        onUnfocus: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        tabIndex: PropTypes.number.isRequired,
        tags: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
        tagsVisible: PropTypes.number.isRequired,
        textInputStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any).isRequired,
        topRightText: PropTypes.string,
        topRightTextAlt: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        wrapTags: PropTypes.bool.isRequired
    };

    static defaultProps = {
        canEditTags: false,
        editingTag: null,
        editingValue: null,
        error: '',
        icon: undefined,
        id: undefined,
        inputRef: undefined,
        label: null,
        onBlur: undefined,
        passedStyles: {},
        textInputStyles: undefined,
        tooltipComponent: undefined,
        topRightText: undefined,
        topRightTextAlt: undefined,
        value: null
    };

    render() {
        const {
            autoFocus,
            canEditTags,
            containerRef,
            editingTag,
            editingValue,
            error,
            getTagLabel,
            getTagStyle,
            icon,
            id,
            inFocus,
            inputRef,
            label,
            name,
            onBlur,
            onChange,
            onEditBlur,
            onEditChange,
            onEditFocus,
            onEditKeyDown,
            onFocus,
            onInputKeyDown,
            onRemoveTag,
            onUnfocus,
            passedStyles,
            placeholder,
            styles,
            tabIndex,
            tags,
            tagsVisible,
            textInputStyles,
            theme,
            tooltipComponent,
            tooltipOptions,
            topRightText,
            topRightTextAlt,
            value,
            wrapTags
        } = this.props;
        const wrappedTags = tags.length - tagsVisible;
        let inputBoxClass = 'taggedInputBox';
        let tagsContainerStyle = inFocus ? styles.tagsContainerFocused : styles.tagsContainer;
        let renderTags;

        if (inFocus) {
            inputBoxClass = `${inputBoxClass} inFocus`;
        }

        if (tags.length) {
            tagsContainerStyle = inFocus ? styles.tagsContainerFocused : styles.tagsContainerValues;
            renderTags = tags.slice(0, tagsVisible).map(tag => (
                <Div
                    key={getTagLabel(tag)}
                    className="taggedInputTag"
                    styles={{ ...styles.tag, ...getTagStyle(tag) }}
                    // THIS IS MOUSE DOWN
                    // BECAUSE IT WILL PREVENT
                    // THE FOCUS EVENT FROM FIRING
                    // AND OPENING AUTOCOMPLETE
                    // TOOLTIPS
                    onMouseDown={!canEditTags ? event => onRemoveTag(event, tag) : null}
                >
                    <ContentEditable
                        disabled={!canEditTags}
                        name={`editable-tag-${tag}`}
                        html={editingTag === tag ? editingValue : getTagLabel(tag)}
                        onBlur={onEditBlur}
                        onChange={onEditChange}
                        onFocus={e => onEditFocus(e, tag)}
                        onKeyDown={onEditKeyDown}
                        tagName="p"
                        title={tag}
                    />
                    <Div styles={styles.removeTagIcon} onClick={canEditTags ? event => onRemoveTag(event, tag) : null}>
                        <Icon type="xMark" color={theme.colors.black01} />
                    </Div>
                </Div>
            ));
        }

        return (
            <Tooltip isEnabled={!!tooltipComponent} content={tooltipComponent} {...tooltipOptions}>
                {({ showTooltip, hideTooltip }) => (
                    <OutsideClickHandler onClick={onUnfocus} styles={passedStyles}>
                        <Div styles={styles.container} className="taggedInputContainer">
                            {label && label.length > 0 && (
                                <Div style={styles.labelContainer}>
                                    <Text label uppercase styles={styles.label}>
                                        {label}
                                    </Text>
                                    {error && error.length > 0 && (
                                        <Text label uppercase styles={styles.textError}>
                                            {error}
                                        </Text>
                                    )}
                                    {(!error || error.length === 0) && topRightText && (
                                        <Div
                                            styles={
                                                topRightTextAlt && tags.length > 0
                                                    ? styles.topRightTextContainerToggle
                                                    : styles.topRightTextContainer
                                            }
                                        >
                                            <Text
                                                className="topRightText"
                                                styles={styles.topRightText}
                                                size={0}
                                                uppercase
                                            >
                                                {topRightText}
                                            </Text>
                                            {!!topRightTextAlt && tags.length > 0 && (
                                                <Text
                                                    className="topRightText"
                                                    styles={styles.topRightText}
                                                    size={0}
                                                    uppercase
                                                >
                                                    {topRightTextAlt}
                                                </Text>
                                            )}
                                        </Div>
                                    )}
                                </Div>
                            )}
                            <Div
                                data-tname={`tagged-input-${name}`}
                                className={inputBoxClass}
                                ref={containerRef}
                                styles={tagsContainerStyle}
                                onClick={onFocus}
                                onMouseEnter={showTooltip}
                                onMouseLeave={hideTooltip}
                                tabIndex={tabIndex}
                                onFocus={onFocus}
                                onBlur={e => {
                                    // The TaggedInput specifically
                                    // uses this to manage the focus
                                    // of the div containing the input
                                    // for the tags.. it sets the
                                    // border and active styles.
                                    //
                                    // onBlur (used with the input)
                                    // is not handled by TaggedInput
                                    // and is simply a passthrough.
                                    if (wrapTags) onUnfocus(e);
                                }}
                            >
                                {icon && (
                                    <Span className="tagged-icon">
                                        <Icon type={icon} color={theme.colors.gray04} />
                                    </Span>
                                )}
                                {renderTags}
                                {wrappedTags > 0 && (
                                    <Div styles={styles.tagPlus} className="taggedInputPlus">
                                        <Text size={1}>+{wrappedTags}</Text>
                                    </Div>
                                )}
                                <TextInput
                                    id={id}
                                    autoComplete="off"
                                    autoFocus={autoFocus}
                                    className="draggableCancel taggedInput"
                                    containerStyle={textInputStyles}
                                    inputRef={inputRef}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    onKeyDown={onInputKeyDown}
                                    placeholder={placeholder}
                                    styles={styles.tagInput}
                                    value={value}
                                />
                            </Div>
                        </Div>
                    </OutsideClickHandler>
                )}
            </Tooltip>
        );
    }
}

export const TaggedInputUI = compose(withStyleSheet(styleSheet))(TaggedInput);
