export const styleSheet = theme => ({
    container: {
        background: theme.colors.gray12,
        borderRadius: 6,
        margin: '0 5px 6px',
        maxWidth: 400,
        color: theme.colors.white01,
        padding: '10px 12px 10px 10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        svg: {
            height: 12
        },
        p: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        ':hover': {
            backgroundColor: theme.colors.gray11
        },
        ':active': {
            backgroundColor: theme.colors.gray16
        },
        ':first-of-type': {
            marginTop: 6
        },
        ':last-of-type': {
            borderBottom: 'none'
        }
    },
    exchange: {
        color: theme.colors.orange02,
        opacity: 0.4
    },
    playBtn: {
        flexShrink: 0,
        paddingLeft: 2,
        background: theme.colors.gradient03,
        height: 26,
        width: 26,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    trackInfo: {
        marginLeft: 8,
        maxWidth: 308,
        overflow: 'hidden',
        '.trackTitle': {
            display: 'block',
            color: theme.colors.white01,
            fontSize: theme.fonts.fontSizes.size01,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginBottom: 4,
            textDecoration: 'none'
        },
        '.ticker': {
            fontSize: theme.fonts.fontSizes.size00,
            textTransform: 'uppercase',
            color: theme.colors.orange02,
            marginRight: 4,
            textDecoration: 'none'
        }
    },
    trackDate: {
        color: theme.colors.gray04,
        marginLeft: 6
    },
    trackDetails: {
        display: 'flex',
        letterSpacing: 1
    }
});
