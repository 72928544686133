import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { Scroller } from 'utils/scroll';
import { withData } from './data';
import { WatchlistPreviewUI } from './ui';

export class WatchlistPreview extends PureComponent {
    static displayName = 'WatchlistPreviewContainer';

    static propTypes = {
        equities: PropTypes.arrayOf(PropTypes.any),
        hiddenEquities: PropTypes.arrayOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onRemoveAll: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        onSelect: PropTypes.func,
        onUndoRemove: PropTypes.func.isRequired,
        rules: PropTypes.arrayOf(PropTypes.any),
        searchTerm: PropTypes.string.isRequired,
        selectedEquities: PropTypes.arrayOf(PropTypes.string),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        equities: [],
        loading: false,
        onRemoveAll: undefined,
        onSelect: undefined,
        rules: [],
        selectedEquities: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onScrollEnd = this.onScrollEnd.bind(this);
        this.hasMoreResults = true;
        this.scroller = new Scroller();
        this.scrollRef = createRef();
        this.onSelectEquity = this.onSelectEquity.bind(this);
        this.onToggleAll = this.onToggleAll.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.toggleHiddenEquities = this.toggleHiddenEquities.bind(this);

        this.state = {
            loadingMoreMatches: false,
            selectedEquities: props.selectedEquities,
            showHiddenEquities: false
        };
    }

    componentDidMount() {
        this.scroller.setScrollContainer(this.scrollRef.current);
        this.scroller.on('scrollEnd', this.onScrollEnd);
    }

    componentDidUpdate({ rules: prevRules, selectedEquities: prevSelectedEquities }) {
        const { rules, selectedEquities } = this.props;
        if (prevRules !== rules) {
            this.hasMoreResults = true;
        }
        if (prevSelectedEquities !== selectedEquities) {
            this.setState({ selectedEquities });
        }
    }

    componentWillUnmount() {
        this.scroller.cleanup();
    }

    loadMoreMatches() {
        const { equities, loadMoreMatches } = this.props;
        const { loadingMoreMatches } = this.state;
        if (equities.length && this.hasMoreResults && loadMoreMatches && !loadingMoreMatches) {
            this.setState({ loadingMoreMatches: true }, () =>
                loadMoreMatches(equities.length)
                    .then(hasMoreResults => {
                        this.hasMoreResults = hasMoreResults;
                    })
                    .finally(() => this.setState({ loadingMoreMatches: false }))
            );
        }
    }

    onScrollEnd() {
        this.loadMoreMatches();
    }

    onSelectEquity(equityId) {
        const { selectedEquities } = this.state;
        const { onSelect } = this.props;
        const includedEquities = new Set(selectedEquities);

        if (includedEquities.has(equityId)) {
            includedEquities.delete(equityId);
        } else {
            includedEquities.add(equityId);
        }

        this.setState(
            {
                selectedEquities: [...includedEquities]
            },
            () => {
                const { selectedEquities: newSelectedEquities } = this.state;
                if (onSelect) {
                    onSelect({ value: newSelectedEquities });
                }
            }
        );
    }

    onToggleAll() {
        const { equities, onSelect } = this.props;
        const { selectedEquities } = this.state;
        this.setState(
            {
                selectedEquities:
                    selectedEquities.length === 0 || selectedEquities.length < equities.length
                        ? equities.map(({ id }) => id)
                        : []
            },
            () => {
                const { selectedEquities: newSelectedEquities } = this.state;
                if (onSelect) {
                    onSelect({ value: newSelectedEquities });
                }
            }
        );
    }

    onRemoveSelected() {
        const { onRemove, equities } = this.props;
        const { selectedEquities } = this.state;
        const hiddenEquities = equities
            .filter(({ id }) => selectedEquities.includes(id))
            .map(({ company, id, ticker }) => ({ commonName: company, id, localTicker: ticker }));
        this.setState(
            {
                selectedEquities: []
            },
            () => {
                onRemove(selectedEquities, hiddenEquities);
            }
        );
    }

    toggleHiddenEquities() {
        this.setState(({ showHiddenEquities }) => ({ showHiddenEquities: !showHiddenEquities }));
    }

    render() {
        const {
            equities,
            hiddenEquities,
            loading,
            onRemove,
            onRemoveAll,
            onSearch,
            onUndoRemove,
            rules,
            searchTerm,
            styles
        } = this.props;
        const { loadingMoreMatches, selectedEquities, showHiddenEquities } = this.state;
        return (
            <WatchlistPreviewUI
                equities={equities}
                hiddenEquities={hiddenEquities}
                loading={loading}
                loadingMore={loadingMoreMatches}
                onSearch={onSearch}
                onSelectEquity={this.onSelectEquity}
                onToggleAll={this.onToggleAll}
                onRemove={onRemove}
                onRemoveAll={onRemoveAll}
                onRemoveSelected={this.onRemoveSelected}
                onUndoRemove={onUndoRemove}
                rules={rules}
                scrollRef={this.scrollRef}
                searchTerm={searchTerm}
                selectedEquities={selectedEquities}
                showHiddenEquities={showHiddenEquities}
                styles={styles}
                toggleHiddenEquities={this.toggleHiddenEquities}
            />
        );
    }
}

export const WatchlistPreviewContainer = compose(
    withStateHandlers(
        {
            searchTerm: ''
        },
        {
            onSearch: () => ({ value: searchTerm }) => ({ searchTerm })
        }
    ),
    withData()
)(WatchlistPreview);
