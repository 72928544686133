import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { DashboardStatsChartUI } from './ui';

export class DashboardStatsChart extends PureComponent {
    static displayName = 'DashboardStatsChartContainer';

    static propTypes = {
        dashDateFilter: PropTypes.arrayOf(PropTypes.any),
        filterMonths: PropTypes.number.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        streams: PropTypes.arrayOf(PropTypes.any),
        selectRange: PropTypes.func.isRequired
    };

    static defaultProps = {
        dashDateFilter: undefined,
        styles: undefined,
        streams: []
    };

    constructor(props) {
        super(props);

        this.chart = React.createRef();
    }

    componentDidUpdate({ streams: prevStreams }) {
        const { streams, filterMonths } = this.props;
        if (streams !== prevStreams) {
            const selectedIndex = [1, 3, 6, 13].indexOf(filterMonths);
            this.chart.current.chart.update({
                rangeSelector: {
                    selected: selectedIndex
                }
            });
        }
    }

    render() {
        const { dashDateFilter, styles, streams, selectRange, filterMonths } = this.props;
        return (
            <DashboardStatsChartUI
                dashDateFilter={dashDateFilter}
                filterMonths={filterMonths}
                data={streams}
                styles={styles}
                selectRange={selectRange}
                chartElement={this.chart}
            />
        );
    }
}

export const DashboardStatsChartContainer = compose()(DashboardStatsChart);
