import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { TextInput } from 'components/TextInput';
import { LiveEventRow } from 'components/LiveEventRow';
import { SortHandle } from 'components/SortHandle';
import { Stream } from 'components/Stream';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    event: LiveEventRow
};

class LiveEventsStream extends PureComponent {
    static propTypes = {
        addEvent: PropTypes.func.isRequired,
        dashboardId: PropTypes.string.isRequired,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        onSearch: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeEvent: PropTypes.func.isRequired,
        searchTerm: PropTypes.string,
        selectedEventIds: PropTypes.instanceOf(Set),
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        loading: false,
        loadMoreMatches: null,
        matches: [],
        onSearch: null,
        passedStyles: {},
        searchTerm: '',
        selectedEventIds: new Set(),
        streamId: null,
        subtitle: undefined
    };

    renderToolbar({ dragHandleProps }) {
        const { onSearch, styles, searchTerm, theme } = this.props;
        return (
            <Div styles={styles.header} {...dragHandleProps}>
                <SortHandle color={theme.colors.gray04} />
                <TextInput
                    clearable
                    icon="search03"
                    name="liveEventSearch"
                    placeholder="Search all events..."
                    onChange={onSearch}
                    styles={styles.search}
                    value={searchTerm}
                />
            </Div>
        );
    }

    render() {
        const {
            addEvent,
            dashboardId,
            loadMoreMatches,
            loading,
            matches,
            passedStyles,
            removeEvent,
            selectedEventIds,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle
        } = this.props;
        return (
            <Stream
                {...streamProps}
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                offset={matches.length}
                renderToolbar={props => this.renderToolbar(props)}
                streamId={streamId}
                styles={{ ...styles.container, ...passedStyles }}
                subtitle={subtitle}
            >
                {matches.map(m => {
                    const CardComponent = COMPONENT_MAP[m.type];
                    return (
                        <CardComponent
                            addEvent={addEvent}
                            displayMode={streamDisplayMode}
                            key={`media-card-${m.id}`}
                            removeEvent={removeEvent}
                            selected={selectedEventIds.has(get(m, 'eventId'))}
                            {...m}
                        />
                    );
                })}
            </Stream>
        );
    }
}

export const LiveEventsStreamUI = compose(withStyleSheet(styleSheet))(LiveEventsStream);
