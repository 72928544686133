import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { get } from 'utils';
import { generateTypes } from 'consts/filters';
import { StreamFilterUI } from './ui';

export class StreamFilter extends PureComponent {
    static displayName = 'StreamFilterContainer';

    static propTypes = {
        types: PropTypes.arrayOf(PropTypes.any),
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        name: 'streamFilter',
        onChange: undefined,
        types: [],
        value: null
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.generateTypes = memoize(generateTypes);
    }

    onChange({ event, value }) {
        const { onChange, name } = this.props;

        if (onChange) {
            onChange({
                name,
                event,
                value
            });
        }
    }

    render() {
        const { name, value, types } = this.props;
        const typeValue = get(value, 'type');

        return (
            <StreamFilterUI
                name={name}
                onChange={this.onChange}
                typeValue={typeValue}
                types={this.generateTypes(types)}
                value={value}
            />
        );
    }
}

export const StreamFilterContainer = compose()(StreamFilter);
