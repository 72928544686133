import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { EventCountdownUI } from './ui';

export class EventCountdown extends PureComponent {
    static displayName = 'EventCountdownContainer';

    static propTypes = {
        callDate: PropTypes.string.isRequired,
        hideLabels: PropTypes.bool,
        labelStyles: PropTypes.objectOf(PropTypes.any),
        showDays: PropTypes.bool,
        showSeconds: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        hideLabels: false,
        labelStyles: {},
        showDays: false,
        showSeconds: true,
        styles: {}
    };

    render() {
        const { callDate, hideLabels, labelStyles, showDays, showSeconds, styles } = this.props;
        return (
            <EventCountdownUI
                callDate={callDate}
                hideLabels={hideLabels}
                labelStyles={labelStyles}
                showDays={showDays}
                showSeconds={showSeconds}
                styles={styles}
            />
        );
    }
}

export const EventCountdownContainer = compose()(EventCountdown);
