import gql from 'graphql-tag';

export const privateRecordingFragment = gql`
    fragment privateRecording on PrivateRecording {
        id
        connectAccessId
        connectCallerId
        connectionType
        connectOffsetSeconds
        connectPhoneNumber
        connectPin
        connectUrl
        equityIds
        externalAudioStreamUrl
        fileUpload {
            id
            rawFilename
        }
        localeCode
        onCompleteEmailCreator
        onConnectDialNumber
        onFailure
        onFailureDialNumber
        onFailureInstructions
        onFailureSmsNumber
        organizationId
        privateRecordingId
        scheduledFor
        smsAlertBeforeCall
        status
        tags
        title
        userId
    }
`;
