export const styleSheet = theme => ({
    absolute: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 7
    },
    containerError: {
        borderRight: `3px solid ${theme.colors.statusBannerError}`,
        backgroundColor: theme.colors.statusBannerError,
        lineHeight: '44px'
    },
    container: {
        transition: 'all 0.2s ease-in-out',
        position: 'absolute',
        bottom: '-44px',
        right: 20,
        backgroundColor: theme.colors.statusBanner,
        height: '44px',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 15px',
        alignItems: 'center',
        borderRadius: '6.5px 0 0 6.5px',
        borderRight: `3px solid ${theme.colors.green04}`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            left: 10
        },
        '@supports (height: env(safe-area-inset-top))': {
            bottom: 'calc(-44px - env(safe-area-inset-top))'
        }
    },
    visible: {
        bottom: 20,
        boxShadow: `0 ${theme.margins.margin00} ${theme.margins.margin00} 0 rgba(0, 0, 0, 0.1)`,
        '@supports (height: env(safe-area-inset-top))': {
            bottom: 'calc(20px + env(safe-area-inset-top))'
        }
    },
    text: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '18px',
        letterSpacing: '1px',
        color: theme.colors.white01,
        marginRight: 14
    }
});
