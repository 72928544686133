import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateModalId } from 'utils';
import { withData } from './data';
import { EventFilingsUI } from './ui';

export class EventFilings extends PureComponent {
    static displayName = 'EventFilingsContainer';

    static propTypes = {
        filings: PropTypes.arrayOf(PropTypes.object),
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        pathname: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        filings: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.openUpgradeModal = this.openUpgradeModal.bind(this);
    }

    openUpgradeModal() {
        const { history, pathname } = this.props;
        history.push(generateModalId({ pathname, id: 'filings', type: 'upgrade' }));
    }

    render() {
        const { filings, pathname, styles } = this.props;
        return (
            <EventFilingsUI
                filings={filings}
                pathname={pathname}
                openUpgradeModal={this.openUpgradeModal}
                styles={styles}
            />
        );
    }
}

export const EventFilingsContainer = compose(withUrlContext(['history', 'pathname']), withData())(EventFilings);
