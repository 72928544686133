export const styleSheet = () => ({
    container: {
        paddingBottom: 0,
        width: 290,
        minWidth: 290,
        paddingLeft: 0,
        ':first-of-type': {
            width: 290,
            minWidth: 290,
            paddingLeft: 0
        }
    },
    cardContainer: {
        pointerEvents: 'none'
    }
});
