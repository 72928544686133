import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { PDFViewerUI } from './ui';
import { withData } from './data';

export class PDFViewer extends PureComponent {
    static displayName = 'PDFViewerContainer';

    static propTypes = {
        annotations: PropTypes.arrayOf(PropTypes.any),
        authorMap: PropTypes.objectOf(PropTypes.any),
        initialSearchTerm: PropTypes.string,
        createAnnotation: PropTypes.func.isRequired,
        deleteAnnotation: PropTypes.func.isRequired,
        updateAnnotation: PropTypes.func.isRequired,
        pdfUrl: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        annotations: [],
        authorMap: undefined,
        initialSearchTerm: undefined,
        styles: undefined,
        user: undefined
    };

    constructor(props) {
        super(props);

        this.viewerRef = createRef();
        this.handleViewerRef = this.handleViewerRef.bind(this);
        this.initializePdf = this.initializePdf.bind(this);
        this.postMessage = this.postMessage.bind(this);
        this.loadAnnotations = this.loadAnnotations.bind(this);

        this.onPdfEvent = this.onPdfEvent.bind(this);
    }

    componentDidMount() {
        const { pdfUrl } = this.props;
        window.addEventListener('message', this.onPdfEvent, true);
        if (pdfUrl) {
            this.initializePdf();
        }
    }

    componentDidUpdate({ pdfUrl: prevPdfUrl, annotations: prevAnnotations }) {
        const { pdfUrl, annotations } = this.props;
        if (pdfUrl && !prevPdfUrl) {
            this.initializePdf();
        }

        if (prevAnnotations.length !== annotations.length) {
            this.loadAnnotations();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onPdfEvent);
    }

    onPdfEvent(event) {
        const {
            initialSearchTerm,
            deleteAnnotation,
            updateAnnotation,
            createAnnotation,
            annotations,
            user
        } = this.props;
        if (get(event, 'data.channel') === 'annotate') {
            const action = get(event, 'data.action');
            const annotationId = get(event, 'data.id');
            const highlight = get(event, 'data.xfdf');
            if (action === 'add' && annotationId && highlight) {
                createAnnotation({ highlight, annotationId });
            } else if (action === 'modify' && annotationId && highlight) {
                updateAnnotation({ highlight, annotationId });
            } else if (action === 'delete') {
                deleteAnnotation({ annotationId });
            }
        }
        if (get(event, 'data.channel') === 'document' && get(event, 'data.payload') === 'documentReady') {
            if (initialSearchTerm && this.viewerRef.current) {
                this.postMessage({ searchTerm: initialSearchTerm, options: { regex: true }, channel: 'search' });
            }
            if (annotations.length > 0) {
                this.loadAnnotations();
                if (!initialSearchTerm) {
                    this.postMessage({ channel: 'showAnnotations' });
                }
            } else {
                this.postMessage({
                    channel: 'loadAuthorMap',
                    authorMap: {
                        [get(user, 'id')]: get(user, 'email')
                    }
                });
            }
        }
    }

    loadAnnotations() {
        const { annotations, authorMap } = this.props;
        annotations.forEach(({ highlight, userId }) => {
            try {
                this.postMessage({
                    channel: 'loadAnnotation',
                    annotation: {
                        xfdf: JSON.parse(highlight),
                        authorId: userId,
                        authorMap
                    }
                });
            } catch {
                // console.error('PDF Highlight failed to load');
            }
        });
    }

    postMessage(message) {
        if (this.viewerRef.current) {
            const iframe = get(this.viewerRef, 'current.children[0]');
            const nodeName = get(iframe, 'nodeName');
            if (nodeName === 'IFRAME') {
                iframe.contentWindow.postMessage(message, '*');
            }
        }
    }

    handleViewerRef(container) {
        this.viewerRef.current = container;
    }

    initializePdf() {
        const { user, pdfUrl } = this.props;
        import('@pdftron/pdfjs-express').then(({ default: WebViewer }) => {
            WebViewer(
                {
                    licenseKey: '9LWWe7mbwRDiYD1s4Oh8',
                    config: 'https://public.aiera.com/js/pdfconfig.js',
                    path: 'https://public.aiera.com/js/webviewer/lib',
                    initialDoc: pdfUrl,
                    annotationUser: get(user, 'id')
                },
                this.viewerRef.current
            );
        });
    }

    render() {
        const { styles } = this.props;
        return <PDFViewerUI handleViewerRef={this.handleViewerRef} styles={styles} />;
    }
}

export const PDFViewerContainer = compose(withData())(PDFViewer);
