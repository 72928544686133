import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generatePath } from 'react-router-dom';
import { STREAM_MENU_COLORS } from 'consts';
import { OPERATORS, TYPES } from 'consts/filters';
import { withCreateStream } from 'graphql/streams';
import { routes } from 'routes';
import { titleize } from 'utils';
import { mapContentTypeToFilter, mapFiltersToRules } from 'utils/streams';
import { CreateStreamFromTermTooltipUI } from './ui';

const CONTENT_TYPES = [
    { label: 'news', value: 'news' },
    { label: 'corporate activity', value: 'spotlight' },
    { label: 'transcript', value: 'transcript' },
    { label: 'filings', value: 'filing' }
];
const CONTENT_TYPE_OPTIONS = CONTENT_TYPES.map(({ label, value }) => ({
    label: titleize(label),
    value
}));

export class CreateStreamFromTermTooltip extends PureComponent {
    static displayName = 'CreateStreamFromTermTooltipContainer';

    static propTypes = {
        createStream: PropTypes.func.isRequired,
        dashboardId: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        onSubmit: PropTypes.func,
        searchTerm: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        xOffset: PropTypes.number,
        yOffset: PropTypes.number
    };

    static defaultProps = {
        dashboardId: null,
        onSubmit: null,
        searchTerm: '',
        styles: undefined,
        xOffset: undefined,
        yOffset: undefined
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSelectContentType = this.onSelectContentType.bind(this);
        this.onSelectDashboard = this.onSelectDashboard.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        const selectedContentType = CONTENT_TYPE_OPTIONS[0].value;
        this.state = {
            loading: false,
            selectedContentType,
            selectedDashboard: props.dashboardId,
            value: `${props.searchTerm} ${titleize(selectedContentType)}`
        };
    }

    componentDidUpdate({ searchTerm: prevSearchTerm }) {
        const { selectedContentType } = this.state;
        const { searchTerm } = this.props;
        if (prevSearchTerm !== searchTerm) {
            this.setState({
                value: `${searchTerm} ${titleize(selectedContentType)}`
            });
        }
    }

    isSubmitDisabled() {
        const { selectedContentType, selectedDashboard, value } = this.state;
        return !value.length || !selectedContentType || !selectedDashboard;
    }

    onChange({ value }) {
        this.setState({ value });
    }

    onSelectContentType({ value }) {
        this.setState({ selectedContentType: value });
    }

    onSelectDashboard({ value }) {
        this.setState({ selectedDashboard: value });
    }

    onSubmit() {
        const { selectedContentType, selectedDashboard, value } = this.state;
        const { createStream, history, onSubmit, searchTerm } = this.props;
        const searchTermFilter = {
            type: TYPES.searchTerm,
            operator: OPERATORS.is,
            value: [searchTerm]
        };
        this.setState({ loading: true }, () => {
            const input = {
                dashboardIds: [selectedDashboard],
                name: value,
                rules: mapFiltersToRules([searchTermFilter, mapContentTypeToFilter(selectedContentType)]),
                streamType: 'content',
                uxPreferences: {
                    color: STREAM_MENU_COLORS[Math.floor(Math.random() * 9) + 1] // random int between 1-9
                }
            };
            createStream(input)
                .then(() => {
                    this.setState({ loading: false }, () => {
                        if (onSubmit) onSubmit();
                        history.push(generatePath(routes.dashboard, { dashboardId: selectedDashboard }));
                    });
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        });
    }

    render() {
        const { loading, selectedContentType, selectedDashboard, value } = this.state;
        const { children, styles, xOffset, yOffset } = this.props;
        return (
            <CreateStreamFromTermTooltipUI
                contentTypeOptions={CONTENT_TYPE_OPTIONS}
                isSubmitDisabled={this.isSubmitDisabled()}
                loading={loading}
                onChange={this.onChange}
                onSelectContentType={this.onSelectContentType}
                onSelectDashboard={this.onSelectDashboard}
                onSubmit={this.onSubmit}
                selectedContentType={selectedContentType}
                selectedDashboard={selectedDashboard}
                styles={styles}
                value={value}
                xOffset={xOffset}
                yOffset={yOffset}
            >
                {children}
            </CreateStreamFromTermTooltipUI>
        );
    }
}

export const CreateStreamFromTermTooltipContainer = compose(
    withUrlContext(['dashboardId', 'history']),
    withCreateStream({ batchKey: 'createStreamFromTermTooltip' })
)(CreateStreamFromTermTooltip);
