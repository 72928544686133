export const styleSheet = theme => ({
    menu: {
        width: 170
    },
    colorOptions: {
        display: 'flex',
        backgroundColor: theme.colors.white01,
        padding: '3px 2px',
        borderRadius: 6,
        margin: '-4px -4px -5px 0'
    },
    colorOption: {
        cursor: 'pointer',
        height: 24,
        width: 18,
        borderRadius: 3.5,
        margin: '0 2px',
        ':hover': {
            border: `2px solid ${theme.colors.blue08}`
        }
    }
});
