export const styleSheet = theme => ({
    sortableWrapper: {
        backgroundColor: theme.colors.gray12,
        display: 'flex',
        flex: 1,
        position: 'relative',
        ':hover': {
            '.paging': {
                bottom: 'unset',
                display: 'flex'
            }
        }
    },
    container: {
        position: 'absolute',
        display: 'flex',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
        '.stream.sorting': {
            transition: 'none'
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            paddingBottom: 'calc(env(safe-area-inset-bottom) + 40px)'
        }
    },
    snap: {
        scrollSnapType: 'x mandatory'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        minWidth: 294,
        width: '100%',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'column-reverse',
            minWidth: 'calc(100vw - 50px)',
            width: 'calc(100vw - 50px)',
            scrollSnapAlign: 'start'
        }
    },
    newStream: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        width: 294,
        borderRadius: 6,
        backgroundColor: theme.colors.gray14,
        flexShrink: 0,
        marginBottom: 8
    },
    newStreamSpacer: {
        width: 6,
        flexShrink: 0
    },
    newSavedSearch: {
        width: 240,
        letterSpacing: 1,
        color: theme.colors.gray09,
        marginBottom: 10
    },
    newStreamButton: {
        margin: '2px 0',
        backgroundColor: theme.colors.gray11,
        width: 240,
        height: 40,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        scrollSnapAlign: 'start',
        padding: '0 14px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
        svg: {
            marginBottom: 2,
            width: 16
        },
        p: {
            color: theme.colors.gray09,
            marginLeft: 10
        },
        ':hover': {
            color: theme.colors.white01,
            backgroundColor: theme.colors.gray08
        },
        ':active': {
            backgroundColor: theme.colors.gray11
        }
    },
    newStreamButtonLocked: {
        margin: '2px 0',
        backgroundColor: theme.colors.gray11,
        width: 240,
        height: 40,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        scrollSnapAlign: 'start',
        padding: '0 14px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
        svg: {
            marginBottom: 2,
            g: {
                fill: theme.colors.yellow05
            }
        },
        p: {
            color: theme.colors.gray09,
            marginLeft: 10
        },
        ':hover': {
            color: theme.colors.white01,
            backgroundColor: theme.colors.gray08
        },
        ':active': {
            backgroundColor: theme.colors.gray11
        }
    },
    noticeLeftMargin: {
        marginLeft: 50,
        borderRadius: 6,
        border: 'unset',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        p: {
            color: theme.colors.white01
        }
    },
    notice: {
        borderRadius: 6,
        border: 'unset',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        p: {
            color: theme.colors.white01
        }
    },
    endOfStreams: {
        paddingRight: 10
    },
    emptyAieracast: {
        display: 'flex',
        svg: {
            marginTop: 62,
            marginRight: theme.margins.margin01
        },
        [`@media(max-width:${theme.breakpoints.internal.mobile})`]: {
            scrollSnapAlign: 'start',
            svg: {
                marginTop: 25
            }
        }
    },
    emptyAieracastContent: {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        borderRadius: 6,
        padding: '26px 30px',
        color: theme.colors.white01,
        alignSelf: 'flex-start',
        marginTop: 114,
        maxWidth: 340,
        marginRight: 50,
        [`@media(max-width:${theme.breakpoints.internal.mobile})`]: {
            marginTop: 2
        }
    },
    emptyContentRow: {
        display: 'flex',
        marginTop: theme.margins.margin01,
        lineHeight: '1.3em',
        alignItems: 'center',
        p: {
            ':first-of-type': {
                marginRight: 18
            }
        }
    },
    rightArrow: {
        marginTop: 40,
        cursor: 'pointer',
        zIndex: 2,
        position: 'absolute',
        alignSelf: 'center',
        right: 0,
        height: 60,
        width: 40,
        borderRadius: '30px 0 0 30px',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        svg: {
            height: 20,
            width: 'auto'
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
    leftArrow: {
        marginTop: 40,
        cursor: 'pointer',
        zIndex: 2,
        position: 'absolute',
        alignSelf: 'center',
        left: 0,
        height: 60,
        width: 40,
        borderRadius: '0 30px 30px 0',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 10,
        svg: {
            transform: 'rotate(180deg)',
            height: 20,
            width: 'auto'
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    }
});
