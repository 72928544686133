import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class Toggle extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        formLabel: PropTypes.string,
        gray: PropTypes.bool.isRequired,
        leftIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        leftLabel: PropTypes.string,
        name: PropTypes.string,
        on: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        responsive: PropTypes.string,
        rightIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        rightLabel: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        formLabel: null,
        leftIcon: null,
        leftLabel: null,
        name: undefined,
        onClick: undefined,
        passedStyles: {},
        responsive: null,
        rightIcon: null,
        rightLabel: null
    };

    render() {
        const {
            disabled,
            formLabel,
            gray,
            name,
            on,
            onClick,
            leftIcon,
            leftLabel,
            passedStyles,
            responsive,
            rightIcon,
            rightLabel,
            styles,
            theme
        } = this.props;
        const hasLeftSide = leftLabel || leftIcon;
        const hasRightSide = rightLabel || rightIcon;

        const leftLabelStyles =
            !disabled && ((!hasRightSide && on) || (hasRightSide && !on))
                ? { ...styles.label, ...styles.labelOn, ...styles.labelLeft }
                : { ...styles.label, ...styles.labelLeft };
        const leftIconColor =
            !disabled && ((!hasRightSide && on) || (hasRightSide && !on)) ? theme.colors.gray08 : theme.colors.gray04;

        const rightLabelStyles =
            !disabled && on
                ? { ...styles.label, ...styles.labelOn, ...styles.labelRight }
                : { ...styles.label, ...styles.labelRight };
        const rightIconColor = !disabled && on ? theme.colors.gray08 : theme.colors.gray04;

        const toggleContainerStyles = disabled
            ? { ...styles.toggleContainer, ...styles.toggleContainerDisabled }
            : styles.toggleContainer;
        let containerStyles = disabled ? { ...styles.container, ...styles.containerDisabled } : { ...styles.container };
        let toggleStyles = on ? styles.toggle : { ...styles.toggle, ...styles.toggleOff };

        // We only want the gray color if the component is off and either of the labels is missing
        if (!on && (!hasLeftSide || !hasRightSide)) {
            toggleStyles = { ...toggleStyles, ...styles.toggleOffGray };
        }

        if (gray) {
            containerStyles = { ...containerStyles, ...styles.containerGray };
        }

        if (formLabel) {
            containerStyles = { ...containerStyles, ...styles.formToggle };
        }

        containerStyles = { ...containerStyles, ...passedStyles };

        return (
            <MediaQuery maxWidth={responsive || '0px'}>
                {matches => {
                    const toggleEle = (
                        <Div styles={containerStyles} data-tname={name} onClick={onClick}>
                            {(!matches || !leftIcon) && leftLabel && (
                                <Text className="toggleLeftLabel" styles={leftLabelStyles} size={1}>
                                    {leftLabel}
                                </Text>
                            )}
                            {(matches || !leftLabel) && leftIcon && (
                                <Div styles={styles.labelLeft}>
                                    <Icon type={leftIcon} color={leftIconColor} />
                                </Div>
                            )}
                            <Div className="toggleContainer" styles={toggleContainerStyles}>
                                <Div className={`toggle ${on ? 'toggleOn' : 'toggleOff'}`} styles={toggleStyles} />
                            </Div>
                            {(matches || !rightLabel) && rightIcon && (
                                <Div styles={styles.labelRight}>
                                    <Icon type={rightIcon} color={rightIconColor} />
                                </Div>
                            )}
                            {(!matches || !rightIcon) && rightLabel && (
                                <Text styles={rightLabelStyles} size={1}>
                                    {rightLabel}
                                </Text>
                            )}
                        </Div>
                    );
                    return formLabel ? (
                        <Div>
                            {formLabel && <Text styles={styles.formLabel}>{formLabel}</Text>}
                            {toggleEle}
                        </Div>
                    ) : (
                        toggleEle
                    );
                }}
            </MediaQuery>
        );
    }
}

export const ToggleUI = compose(withStyleSheet(styleSheet))(Toggle);
