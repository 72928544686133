import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withUrlContext } from 'hoc/url';
import { get, generateTabId } from 'utils';
import { constants } from 'consts';
import { connect } from 'react-redux';
import { popoverPDFHide, popoverPDFSet } from 'actions/popoverPDF';
import { AttachmentsUI } from './ui';

export class Attachments extends PureComponent {
    static displayName = 'AttachmentsContainer';

    static propTypes = {
        fromEvent: PropTypes.bool,
        attachments: PropTypes.arrayOf(PropTypes.any),
        createTabPath: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        hidePopover: PropTypes.func.isRequired,
        id: PropTypes.string,
        setPopover: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tabType: PropTypes.string
    };

    static defaultProps = {
        fromEvent: false,
        attachments: [],
        id: null,
        styles: null,
        tabType: null
    };

    constructor(props) {
        super(props);

        this.openAttachment = this.openAttachment.bind(this);
        this.toggleAttachments = this.toggleAttachments.bind(this);

        this.state = {
            showAttachments: false
        };
    }

    componentWillUnmount() {
        const { hidePopover } = this.props;
        hidePopover();
    }

    openAttachment(idx) {
        const { fromEvent, attachments, createTabPath, history, id, tabType } = this.props;
        if (id && tabType) {
            let newTabId = generateTabId({ tabType, id: `${id}`, page: 'pdf', pageId: `${idx}` });
            if (fromEvent) {
                const att = attachments[idx];
                const eventTabType =
                    get(att, 'title') === 'Slides'
                        ? constants.TAB_TYPES.pdfSlides
                        : constants.TAB_TYPES.pdfPresentation;
                newTabId = generateTabId({ id, tabType: eventTabType });
            }
            const tabPath = createTabPath(newTabId);
            history.push(tabPath);
        }
    }

    toggleAttachments() {
        this.setState(({ showAttachments }) => ({ showAttachments: !showAttachments }));
    }

    render() {
        const { attachments, styles, tabType, setPopover } = this.props;
        const { showAttachments } = this.state;

        const pdfAttachments = attachments.filter(a => {
            if (get(a, 'mimeType', '').includes('pdf')) {
                return a;
            }

            return false;
        });

        return (
            tabType && (
                <AttachmentsUI
                    showAttachments={showAttachments}
                    setPopover={setPopover}
                    styles={styles}
                    toggleAttachments={this.toggleAttachments}
                    attachments={pdfAttachments}
                    openAttachment={this.openAttachment}
                />
            )
        );
    }
}

const mapDispatchToProps = {
    setPopover: popoverPDFSet,
    hidePopover: popoverPDFHide
};

export const AttachmentsContainer = compose(
    connect(undefined, mapDispatchToProps),
    withRouter,
    withUrlContext(['createTabPath', 'history', 'id', 'tabType'])
)(Attachments);
