import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { CardLockButton } from 'components/CardLockButton';
import { Div } from 'components/Div';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { getHighlightColor, titleize } from 'utils';
import { styleSheet } from './stylesheet';

class CorporateActionCard extends PureComponent {
    static propTypes = {
        actionSubtype: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        source: PropTypes.string,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        to: PropTypes.string
    };

    static defaultProps = {
        actionSubtype: undefined,
        equities: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        onClick: undefined,
        source: null,
        passedStyles: {},
        score: undefined,
        streamId: undefined,
        to: null
    };

    render() {
        const {
            actionSubtype,
            displayMode,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            source,
            onClick,
            passedStyles,
            publishedDate,
            score,
            styles,
            title,
            equities,
            streamId,
            to
        } = this.props;
        const containerStyles =
            displayMode === STREAM_DISPLAY_MODES.comfortable ? styles.containerComfortable : styles.container;

        const contentStyles = {
            ...styles.content,
            backgroundColor: getHighlightColor(highlightColor)?.color
        };

        if (STREAM_DISPLAY_MODES.compact === displayMode) {
            return (
                <WithPermission permissions={[PERMISSIONS.unlockedCorporateActionContent]}>
                    {({ restricted, isLoading }) => (
                        <Card
                            containerStyles={styles.containerCompact}
                            name="corporate-action-card-compact"
                            onClick={isLoading ? undefined : onClick}
                            styles={{ ...styles.compact, ...passedStyles }}
                            to={restricted || isLoading ? undefined : to}
                        >
                            {({ isHovering }) => (
                                <Fragment>
                                    {isHovering && (
                                        <StreamActionMenu
                                            noBookmark
                                            highlight={highlights}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="content"
                                            url={to}
                                        />
                                    )}
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subTextCompact}>
                                        <Text size={1} styles={styles.date}>
                                            <TimeAgo date={publishedDate} />
                                        </Text>
                                        {!!actionSubtype && (
                                            <Text size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                                {titleize(actionSubtype)}
                                            </Text>
                                        )}
                                        {!!source && (
                                            <Text size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                                {titleize(source)}
                                            </Text>
                                        )}
                                    </Div>
                                    <Div styles={contentStyles}>
                                        <RawHTML html={highlights} />
                                    </Div>
                                    {(restricted || isLoading) && <CardLockButton upgradeModalId="corporateAction" />}
                                </Fragment>
                            )}
                        </Card>
                    )}
                </WithPermission>
            );
        }

        return (
            <WithPermission permissions={[PERMISSIONS.unlockedCorporateActionContent]}>
                {({ restricted, isLoading }) => (
                    <Card
                        name={`corporate-action-card-${
                            displayMode === STREAM_DISPLAY_MODES.default ? 'default' : 'comfortable'
                        }`}
                        onClick={isLoading ? undefined : onClick}
                        styles={{ ...containerStyles, ...passedStyles }}
                        to={restricted || isLoading ? undefined : to}
                    >
                        {({ isHovering }) => {
                            return (
                                <Fragment>
                                    {isHovering && (
                                        <StreamActionMenu
                                            noBookmark
                                            highlight={highlights}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="content"
                                            url={to}
                                        />
                                    )}
                                    <CardEquities equities={equities} />
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subText}>
                                        <Text size={1} styles={styles.date}>
                                            <TimeAgo date={publishedDate} />
                                        </Text>
                                        {!!actionSubtype && (
                                            <Text size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                                {titleize(actionSubtype)}
                                            </Text>
                                        )}
                                        {!!source && (
                                            <Text size={1} styles={styles.source}>
                                                &nbsp; • &nbsp;
                                                {titleize(source)}
                                            </Text>
                                        )}
                                    </Div>
                                    <Div styles={contentStyles}>
                                        <RawHTML html={highlights} />
                                    </Div>
                                    {(restricted || isLoading) && <CardLockButton upgradeModalId="corporateAction" />}
                                </Fragment>
                            );
                        }}
                    </Card>
                )}
            </WithPermission>
        );
    }
}

export const CorporateActionCardUI = compose(withStyleSheet(styleSheet))(CorporateActionCard);
