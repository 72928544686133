import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';
import { compose, withPropsOnChange } from 'recompose';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';

function normalizeLocales(locales) {
    return (locales || []).map(locale => ({
        label: locale.displayName,
        metadata: { countryCode: locale.countryCode },
        value: locale.localeCode
    }));
}

export const withData = () =>
    compose(
        withMemo({ normalizeLocales }),
        graphql(
            gql`
                query withLocales($gcpAutomaticPunctuation: Boolean = false, $gcpSupport: Boolean = false) {
                    locales(filter: { gcpAutomaticPunctuation: $gcpAutomaticPunctuation, gcpSupport: $gcpSupport }) {
                        id
                        countryCode
                        displayName
                        localeCode
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    loading: get(data, 'loading'),
                    locales: get(data, 'locales')
                }),
                options: ({ gcpAutomaticPunctuation, gcpSupport }) => ({
                    fetchPolicy: 'cache-first',
                    variables: {
                        gcpAutomaticPunctuation: gcpAutomaticPunctuation || false,
                        gcpSupport: gcpSupport || false
                    }
                })
            }
        ),
        withPropsOnChange(['locales'], ({ locales, normalizeLocales: normalize }) => ({
            locales: normalize(locales)
        }))
    );
