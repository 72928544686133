import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Lottie from 'lottie-web';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import animation1 from './animation1';
import animation2 from './animation2';
import { styleSheet } from './stylesheet';

class LoaderLogo extends PureComponent {
    static propTypes = {
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        color: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    constructor(props) {
        super(props);

        this.loadAnimation = this.loadAnimation.bind(this);
    }

    componentWillUnmount() {
        if (this.animation) {
            this.animation.destroy();
        }
    }

    loadAnimation(container) {
        const { type } = this.props;
        const animationData = type === 1 ? animation1 : animation2;
        this.animation = Lottie.loadAnimation({
            container,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData
        });
    }

    render() {
        const { passedStyles, styles, color, height } = this.props;
        const explicitStyle = {
            height,
            width: height,
            svg: {
                path: {
                    stroke: color
                }
            }
        };
        return <Div ref={this.loadAnimation} styles={{ ...styles.container, ...explicitStyle, ...passedStyles }} />;
    }
}

export const LoaderLogoUI = compose(withStyleSheet(styleSheet))(LoaderLogo);
