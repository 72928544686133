import gql from 'graphql-tag';
import XDate from 'xdate';
import pick from 'lodash/pick';
import { compose, withPropsOnChange } from 'recompose';
import { get } from 'utils';
import { withMemo } from 'hoc/utils';
import { graphql } from 'graphql/utils';

const normalizePriceData = realtimePrices => {
    if (!realtimePrices) {
        return { priceData: null, volumeData: null };
    }

    const priceData = [];
    const volumeData = [];

    realtimePrices.forEach(p => {
        priceData.push({
            x: new XDate(p.date).getTime(),
            y: p.price,
            changeAmount: p.changeAmount,
            changePercent: p.changePercent,
            volume: p.volumeChangeFromLast
        });
        volumeData.push({
            x: new XDate(p.date).getTime(),
            y: p.volumeChangeFromLast
        });
    });

    return {
        priceData,
        volumeData
    };
};

export const withData = () =>
    compose(
        withMemo({ normalizePriceData }),
        graphql(
            gql`
                query withEventPriceChartEquity($equityId: ID!) {
                    equities(equityIds: [$equityId]) {
                        id
                        equityId
                        commonName
                        localTicker
                        lastClose
                        exchange {
                            id
                            shortName
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    equity: get(data, 'equities[0]')
                }),
                skip: ({ equityId }) => !equityId,
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        ),
        graphql(
            gql`
                query withEventPriceChart($eventId: ID, $shareId: ID, $after: DateTime) {
                    events(eventIds: [$eventId], shareId: $shareId) {
                        id
                        eventId
                        equity {
                            id
                            equityId
                            commonName
                            localTicker
                            lastClose
                            exchange {
                                id
                                shortName
                            }
                        }
                        afterMarketClose
                        startPrice: quoddPriceAtStart
                        endPrice: quoddPriceAtEnd
                        currentDayClosePrice
                        previousDayClosePrice
                        callStartTime: callDate
                        callEndTime: transcriptionDisconnectedDate
                        status: transcriptionStatus
                        isLive
                        realtimePrices(filter: { after: $after }) {
                            date
                            price
                            volumeChangeFromLast
                            changeAmount
                            changePercent
                        }
                    }
                }
            `,
            {
                props: ({ data, ownProps: { equity, normalizePriceData: formatPriceData } }) => {
                    const afterMarketClose = get(data, 'events[0].afterMarketClose', false);
                    return {
                        ...pick(get(data, 'events[0]'), ['title', 'callStartTime', 'callEndTime']),
                        ...formatPriceData(get(data, 'events[0].realtimePrices')),
                        inProgress: get(data, 'events[0].isLive'),
                        realtimePrices: get(data, 'events[0].realtimePrices'),
                        equity: get(data, 'events[0].equity', equity),
                        fetchMorePrices: data.fetchMore,
                        startPrice: parseInt(get(data, 'events[0].startPrice', 0), 10) / 10000,
                        endPrice: parseInt(get(data, 'events[0].endPrice', 0), 10) / 10000,
                        lastClose: parseFloat(
                            get(
                                data,
                                afterMarketClose ? 'events[0].currentDayClosePrice' : 'events[0].previousDayClosePrice',
                                0
                            ),
                            10
                        )
                    };
                },
                skip: ({ eventId, shareId }) => !eventId && !shareId,
                options: ({ eventId }) => ({
                    fetchPolicy: 'cache-first',
                    context: { batchKey: `eventPriceChart-${eventId}` }
                })
            }
        ),
        withPropsOnChange(
            ['fetchMorePrices', 'realtimePrices', 'eventId'],
            ({ eventId, fetchMorePrices, realtimePrices }) => ({
                refreshPrices: () => {
                    let lastPriceDate = null;
                    if (realtimePrices && realtimePrices.length) {
                        lastPriceDate = realtimePrices[realtimePrices.length - 1].date;
                    }
                    return (
                        fetchMorePrices &&
                        fetchMorePrices({
                            variables: {
                                after: lastPriceDate,
                                eventId
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                const prevPrices = get(prev, 'events[0].realtimePrices', []);
                                const event = get(fetchMoreResult, 'events[0]');
                                return get(event, 'realtimePrices.length', 0) > 0
                                    ? {
                                          ...prev,
                                          events: [
                                              { ...event, realtimePrices: [...prevPrices, ...event.realtimePrices] }
                                          ]
                                      }
                                    : prev;
                            }
                        })
                    );
                }
            })
        )
    );
