import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { SpeakerAutocompleteUI } from './ui';

export class SpeakerAutocomplete extends PureComponent {
    static displayName = 'SpeakerAutocompleteContainer';

    static propTypes = {
        initialSearchTerm: PropTypes.string,
        loading: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any),
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        initialSearchTerm: null,
        loading: false,
        name: 'speakerAutocomplete',
        onChange: null,
        options: [],
        placeholder: 'Filter by speaker name',
        styles: {},
        value: null
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange({ event, value }) {
        const { name, onChange, options } = this.props;
        if (onChange) {
            const label = get(
                options.find(({ value: val }) => val === value),
                'label'
            );
            onChange({
                event,
                name,
                value,
                label
            });
        }
    }

    render() {
        const { loading, name, options, placeholder, styles, value, onSearch, initialSearchTerm } = this.props;
        return (
            <SpeakerAutocompleteUI
                initialSearchTerm={initialSearchTerm}
                loading={loading}
                name={name}
                onChange={this.onChange}
                onSearch={onSearch}
                options={options}
                placeholder={placeholder}
                styles={styles}
                value={value}
            />
        );
    }
}

export const SpeakerAutocompleteContainer = compose(
    withStateHandlers(() => ({ searchTerm: '' }), {
        onSearch: () => ({ value }) => ({ searchTerm: value })
    }),
    withData()
)(SpeakerAutocomplete);
