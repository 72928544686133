import gql from 'graphql-tag';

export const scheduledAudioCallEventFragment = gql`
    fragment scheduledAudioCallEvent on ScheduledAudioCallEvent {
        id
        bookmark {
            id
        }
        eventId
        scheduledAudioCallId
        eventType
        status
        startTime
        endTime
        conversationId
        connectionId
        transcript(annotate: true)
        translated
        durationMs
        isEdited
        startMs
        startTimestamp
        speaker {
            id
            name
            affiliation
            title
        }
        transcriptSection
        estimatedSpeaker {
            speakerId
            eventId
            speaker {
                id
                name
                affiliation
                title
            }
        }
        createdDate
        talkTimes
        sentiment {
            primarySentiment
            primarySentimentScore
        }
    }
`;

export const differentialFinancialsFragment = gql`
    fragment differentialFinancials on FinancialDifferential {
        userFinancialKpiId
        kpiTerm
        kpiTitle
        kpiFormat
        searchResult {
            term {
                term
            }
            matches {
                itemId
                text
                item {
                    startMs
                }
            }
        }
        latestQuarter {
            year
            quarter
            date
            actual
            estimate
            beatMiss
            actualYoy
            estimateYoy
        }
        quarters {
            year
            quarter
            date
            actual
            estimate
        }
    }
`;

export const differentialFragment = gql`
    fragment differential on Differentials {
        financials {
            ...differentialFinancials
        }
    }
    ${differentialFinancialsFragment}
`;

export const scheduledAudioCallFragment = gql`
    fragment scheduledAudioCall on ScheduledAudioCall {
        archivedSlidesUrl
        audioProxy
        audioStreamUri
        authToken
        broadcastUrl
        callDate
        callExpires
        callProvider
        callType
        conferenceNumber
        conferencePin
        createdByUserId
        emailNotificationsEnabled
        equityId
        expectPublishedTranscript
        externalAudioStreamUrl
        failureCode
        hasLiveTranscript
        hasPublishedTranscript
        hasUnknownTime
        id
        isLive
        isUploading
        lastUpdated
        localeCode
        pressUrl
        priceHighlight {
            endOrLatestPrice
            movementAbsolute
            movementPercent
        }
        processingAudio
        publishedTranscriptSource
        replayUrl
        scheduledAudioCallId
        shareId
        siftTokens
        slidesUrl
        source
        startPrice
        streamProvider
        title
        transcriptCurrentVersion
        transcriptionAudioUrl
        transcriptionConnectedDate
        transcriptionConnectionId
        transcriptionDisconnectedDate
        transcriptionDownloadUrl
        transcriptionStatus
        verified
        webcastStatus
    }
`;

export const scheduledAudioCallStateFragment = gql`
    fragment scheduledAudioCallState on EventState {
        created
        eventId
        parameters
        state
        stateId
        stateType
        timestamp
    }
`;
