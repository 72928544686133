import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Hint } from 'components/Hint';

import { styleSheet } from './stylesheet';

class Toolbar extends PureComponent {
    static propTypes = {
        isFullScreen: PropTypes.bool.isRequired,
        onFullScreen: PropTypes.func.isRequired,
        onMinimize: PropTypes.func.isRequired,
        onPopout: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeTab: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        tabId: PropTypes.string.isRequired,
        tabs: PropTypes.arrayOf(PropTypes.string),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        tabs: [],
        onPopout: null,
        passedStyles: {}
    };

    renderIcons() {
        const { isFullScreen, onFullScreen, onMinimize, onPopout, removeTab, styles, tabId, theme } = this.props;
        return (
            <Div styles={styles.toolbarControls}>
                {onPopout && (
                    <Hint
                        text="Popout"
                        onClick={onPopout}
                        styles={styles.toolbarButton}
                        className="toolbarIcon"
                        yOffset={0}
                        growLeft
                    >
                        <Icon type="windowPopout" color={theme.colors.gray04} />
                    </Hint>
                )}
                <Fragment>
                    <Hint
                        text="Minimize"
                        onClick={onMinimize}
                        styles={styles.toolbarButton}
                        className="toolbarIcon"
                        growLeft
                        yOffset={0}
                    >
                        <Icon type="windowMinimize" color={theme.colors.gray04} />
                    </Hint>
                    {isFullScreen ? (
                        <Hint
                            text="Exit Full Screen"
                            onClick={onFullScreen}
                            styles={styles.toolbarButton}
                            className="toolbarIcon"
                            yOffset={0}
                            growLeft
                        >
                            <Icon type="windowExitFullScreen" color={theme.colors.gray04} />
                        </Hint>
                    ) : (
                        <Hint
                            text="Full Screen"
                            onClick={onFullScreen}
                            styles={styles.toolbarButton}
                            className="toolbarIcon"
                            yOffset={0}
                            growLeft
                        >
                            <Icon type="windowMaximize" color={theme.colors.gray04} />
                        </Hint>
                    )}
                </Fragment>
                <Hint
                    text="Close"
                    onClick={() => removeTab(tabId)}
                    styles={styles.toolbarButton}
                    className="toolbarIcon"
                    yOffset={0}
                    growLeft
                >
                    <Icon type="windowClose" color={theme.colors.gray04} />
                </Hint>
            </Div>
        );
    }

    renderSpacer() {
        const { styles, onMinimize, tabs } = this.props;

        const replaceBtn = <Div onClick={onMinimize} styles={styles.spacer} />;
        const maximizeBtn = <Div onClick={onMinimize} styles={styles.spacer} />;

        return (
            <Fragment>
                <MediaQuery minWidth="1900px">{tabs.length >= 3 ? replaceBtn : maximizeBtn}</MediaQuery>
                <MediaQuery maxWidth="1899px" minWidth="1250px">
                    {tabs.length >= 2 ? replaceBtn : maximizeBtn}
                </MediaQuery>
                <MediaQuery maxWidth="1249px">{tabs.length >= 1 ? replaceBtn : maximizeBtn}</MediaQuery>
            </Fragment>
        );
    }

    renderTitle() {
        const { styles, onMinimize, tabs, title } = this.props;

        const replaceBtn = (
            <Div styles={styles.titleContainer} onClick={onMinimize}>
                <Text styles={styles.title}>{title}</Text>
            </Div>
        );

        const maximizeBtn = (
            <Div styles={styles.titleContainer} onClick={onMinimize}>
                <Text styles={styles.title}>{title}</Text>
            </Div>
        );
        return (
            <Fragment>
                <MediaQuery minWidth="1900px">{tabs.length > 0 ? replaceBtn : maximizeBtn}</MediaQuery>
                <MediaQuery maxWidth="1899px" minWidth="1250px">
                    {tabs.length > 0 ? replaceBtn : maximizeBtn}
                </MediaQuery>
                <MediaQuery maxWidth="1249px">{tabs.length > 0 ? replaceBtn : maximizeBtn}</MediaQuery>
            </Fragment>
        );
    }

    render() {
        const { styles, passedStyles } = this.props;

        return (
            <Div className="print-hide" styles={{ ...styles.container, ...passedStyles }}>
                {this.renderTitle()}
                {this.renderSpacer()}
                {this.renderIcons()}
            </Div>
        );
    }
}

export const ToolbarUI = compose(withStyleSheet(styleSheet))(Toolbar);
