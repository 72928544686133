import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { BoxTableUI } from './ui';

export class BoxTable extends PureComponent {
    static displayName = 'BoxTableContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <BoxTableUI {...rest} />;
    }
}

export const BoxTableContainer = compose()(BoxTable);
