export const styleSheet = theme => ({
    container: {
        alignItems: 'center',
        backgroundColor: theme.colors.gray11,
        borderRadius: 6.5,
        cursor: 'pointer',
        display: 'flex',
        flexShrink: 0,
        height: 30,
        justifyContent: 'center',
        marginRight: 6,
        position: 'relative',
        svg: {
            height: 14,
            width: 14
        },
        transition: 'all 0.1s ease-in-out',
        width: 30,
        ':active': {
            backgroundColor: theme.colors.gray13
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.white01
            }
        }
    },
    bookmarksContainer: {
        ...theme.content.hoverMenu,
        background: theme.colors.gray03,
        borderRadius: 6,
        overflow: 'hidden'
    },
    bookmarkContent: {
        marginRight: 15,
        overflow: 'hidden',
        width: '100%'
    },
    bookmarkDate: {
        color: theme.colors.gray06
    },
    bookmarkHeader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    bookmarkQuote: {
        ...theme.content.blockQuoteRow,
        fontSize: theme.fonts.fontSizes.size01,
        marginTop: 6,
        div: {
            overflow: 'hidden',
            padding: 5,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    bookmarkRow: {
        a: {
            color: 'inherit',
            overflow: 'hidden',
            textDecoration: 'none',
            width: '100%'
        },
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.gray01}`,
        cursor: 'pointer',
        display: 'flex',
        height: 65,
        overflow: 'hidden',
        paddingRight: 15,
        '.checkboxChecked03': {
            boxShadow: 'unset'
        },
        ':hover': {
            backgroundColor: theme.colors.white02,
            '.checkbox03': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray06}`
            }
        }
    },
    bookmarkTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 280
    },
    buttonArchive: {
        marginRight: 15
    },
    buttonDelete: {
        margin: '0 10px 0 auto'
    },
    checkbox: {
        display: 'flex',
        height: '100%',
        padding: '0 15px',
        '.checkbox03': {
            boxShadow: `inset 0 0 0 1px ${theme.colors.gray04}`,
            margin: 0
        }
    },
    numSelected: {
        cursor: 'pointer',
        marginLeft: 15
    },
    stream: {
        borderRadius: 6,
        height: 500,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '.streamHitContainer': {
            backgroundColor: theme.colors.white01,
            borderRadius: 'unset',
            padding: 0
        },
        '.streamScrollTop': {
            display: 'none'
        }
    },
    toolbar: {
        alignItems: 'center',
        backgroundColor: theme.colors.white01,
        borderBottom: `1px solid ${theme.colors.gray01}`,
        display: 'flex',
        padding: '10px 0',
        position: 'sticky',
        top: 0
    },
    tooltip: {
        ...theme.content.hoverMenu,
        backgroundColor: theme.colors.white01,
        borderRadius: 6,
        maxHeight: 'calc(100% - 46px)',
        overflowY: 'auto',
        width: 400
    }
});
