export const styleSheet = theme => ({
    container: {
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        zIndex: 1,
        ':hover': {
            p: {
                color: theme.colors.linkHover
            },
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    },
    containerLink: {
        a: {
            cursor: 'pointer',
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            textDecoration: 'none',
            zIndex: 1,
            ':hover': {
                p: {
                    color: theme.colors.linkHover
                },
                'svg g': {
                    fill: theme.colors.blue01
                }
            }
        }
    },
    text: {
        marginLeft: `calc(${theme.margins.margin01} - 4px)`,
        color: theme.colors.backLink,
        textDecoration: 'none'
    }
});
