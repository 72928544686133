import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { webWidgetHide } from 'actions/zendesk';
import { PERMISSIONS } from 'consts';
import { withGetUser } from 'graphql/user';
import { get, hasPermissions } from 'utils';

const QUERY = gql`
    query withUserZendeskToken {
        currentUser {
            id
            zendeskChatToken
            zendeskToken
        }
    }
`;

export class Zendesk extends PureComponent {
    static displayName = 'ZendeskContainer';

    static propTypes = {
        client: PropTypes.shape({
            query: PropTypes.func
        }).isRequired,
        hideWidget: PropTypes.func.isRequired,
        user: PropTypes.objectOf(PropTypes.any),
        visible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        user: null
    };

    constructor(props) {
        super(props);

        this.configureWebWidget = this.configureWebWidget.bind(this);
    }

    componentDidMount() {
        const { visible } = this.props;
        if (visible) this.configureWebWidget();
    }

    componentDidUpdate({ visible: prevVisible }) {
        const { visible } = this.props;
        if (prevVisible && !visible) this.hideWidget();
        if (!prevVisible && visible) this.configureWebWidget();
    }

    componentWillUnmount() {
        this.hideWidget();
    }

    canUseDOM() {
        return !(typeof window === 'undefined' || !window.document || !window.document.createElement);
    }

    configureWebWidget() {
        if (this.canUseDOM() && window.zE) {
            const { client, user } = this.props;
            const isChatPermitted = hasPermissions(user, [
                PERMISSIONS.featureLiveSupport,
                PERMISSIONS.unlockedLiveSupport
            ]);
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    authenticate: {
                        chat: isChatPermitted
                            ? {
                                  jwtFn(callback) {
                                      client.query({ query: QUERY }).then(({ data }) => {
                                          callback(get(data, 'currentUser.zendeskChatToken'));
                                      });
                                  }
                              }
                            : {},
                        jwtFn(callback) {
                            client.query({ query: QUERY }).then(({ data }) => {
                                callback(get(data, 'currentUser.zendeskToken'));
                            });
                        }
                    },
                    chat: isChatPermitted
                        ? {
                              connectOnPageLoad: false,
                              hideWhenOffline: false
                          }
                        : {
                              suppress: true
                          },
                    contactOptions: {
                        enabled: isChatPermitted
                    }
                }
            });
            // Prefill contact form and live chat with user info
            if (user) {
                const { firstName, email } = user;
                window.zE('webWidget', 'prefill', {
                    email: {
                        readOnly: true,
                        value: email
                    },
                    name: {
                        readOnly: false,
                        value: firstName
                    }
                });
            }
            // Open the widget
            window.zE('webWidget', 'show');
            window.zE('webWidget', 'open');
            // Add listener to hide the widget launcher from the DOM when user closes it
            window.zE('webWidget:on', 'close', () => {
                this.hideWidget();
            });
        }
    }

    hideWidget() {
        if (!this.canUseDOM() || !window.zE) return;
        if (window.zE) window.zE('webWidget', 'hide');
        // Hide the widget in redux so it doesn't show up on refresh
        const { hideWidget } = this.props;
        hideWidget();
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ Zendesk: Z }) => ({
    visible: get(Z, 'visible', false)
});

const mapDispatchToProps = {
    hideWidget: webWidgetHide
};

export const ZendeskContainer = compose(
    withGetUser({ fetchPolicy: 'cache-only', variables: { withDetails: true } }),
    withApollo,
    connect(mapStateToProps, mapDispatchToProps)
)(Zendesk);
