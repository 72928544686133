export const styleSheet = theme => ({
    container: {
        borderRadius: 6
    },
    draggableContainer: {
        backgroundColor: theme.colors.gray12,
        borderRadius: 6
    },
    sectionForm: {
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)'
    },
    dashboardMenuDivider: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 8,
        height: 26,
        p: {
            letterSpacing: 2,
            color: theme.colors.gray06
        }
    },
    newSectionButton: {
        position: 'absolute',
        visibility: 'hidden',
        borderRadius: 6,
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px 5px',
        svg: {
            height: 7
        },
        p: {
            marginLeft: 2,
            marginTop: 1,
            letterSpacing: 1
        }
    },
    newSection: {
        position: 'relative',
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        '.line': {
            borderRadius: 2,
            backgroundColor: 'transparent',
            height: 2
        },
        ':hover': {
            '.button': {
                visibility: 'visible',
                backgroundColor: theme.colors.gray11,
                p: {
                    color: theme.colors.white01
                }
            },
            '.line': {
                backgroundColor: theme.colors.gray11
            }
        },
        ':active': {
            '.button': {
                backgroundColor: theme.colors.gray13,
                p: {
                    color: theme.colors.white01
                }
            },
            '.line': {
                backgroundColor: theme.colors.gray13
            },
            'svg g': {
                fill: theme.colors.white01
            }
        }
    },
    sectionDivider: {
        position: 'relative',
        height: 20
    },
    sectionMenuIcon: {
        height: 22,
        width: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 3,
        borderRadius: 6,
        cursor: 'pointer',
        ':hover': {
            boxShadow: 'unset',
            backgroundColor: theme.colors.gray11
        },
        ':active': {
            backgroundColor: theme.colors.gray13
        }
    },
    sectionHoverMenu: {
        ...theme.content.hoverMenu
    },
    sectionHoverMenuOption: {
        ...theme.content.hoverMenuOption
    },
    sectionMenuIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        marginBottom: 2,
        textAlign: 'center'
    },
    noDashMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '4px 8px 22px',
        padding: '24px 14px',
        border: `1px dashed ${theme.colors.gray11}`,
        borderRadius: 6,
        lineHeight: '1em',
        textAlign: 'center',
        color: theme.colors.gray04
    },
    newDashButton: {
        backgroundColor: theme.colors.blue08,
        borderRadius: 6,
        boxShadow: 'unset',
        margin: '12px 0 6px',
        height: 30,
        padding: `0 ${theme.margins.margin00}`,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        svg: {
            marginBottom: 1
        },
        p: {
            color: theme.colors.white01,
            marginLeft: 6
        },
        ':hover': {
            backgroundColor: theme.colors.blue10
        },
        ':active': {
            backgroundColor: theme.colors.blue11
        }
    },
    collapsableTitle: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        svg: {
            transition: 'all 0.2s ease-in-out',
            transform: 'rotate(90deg)'
        },
        p: {
            width: 180,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 8,
            marginLeft: 8
        }
    },
    collapsedTitle: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        svg: {
            transition: 'all 0.2s ease-in-out',
            transform: 'rotate(0deg)'
        },
        p: {
            width: 180,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 8,
            marginLeft: 8
        }
    }
});
