export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.gray12,
        height: '100%',
        minHeight: '500px',
        width: 250,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            width: 'calc(100% - 40px)'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 8px 8px 12px'
    },
    headerButtons: {
        display: 'flex'
    },
    headerButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: 6.5,
        marginLeft: 6,
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        svg: {
            height: 14,
            width: 14
        },
        ':hover': {
            backgroundColor: theme.colors.gray11,
            'svg g': {
                fill: theme.colors.white01
            }
        },
        ':active': {
            backgroundColor: theme.colors.gray13
        }
    },
    menuContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        maxHeight: 'calc(100vh - 38px)',
        padding: '0px 8px 14px'
    },
    dashboardColumns: {
        display: 'none',
        height: 18,
        borderRadius: 3.5,
        padding: '2px 4px',
        svg: {
            height: 14
        },
        ':hover': {
            backgroundColor: theme.colors.blue08,
            'svg g': {
                fill: theme.colors.blue05
            }
        }
    },
    streamTitleCell: {
        width: '100%',
        p: {
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    streamSearchContainer: {
        flex: 1,
        input: {
            fontSize: theme.fonts.fontSizes.size01,
            lineHeight: 30,
            marginTop: 0,
            height: 30,
            borderRadius: 15.5,
            backgroundColor: theme.colors.white01,
            border: `1px solid ${theme.colors.gray05}`,
            paddingLeft: theme.margins.margin02,
            ':focus': {
                border: `1px solid ${theme.colors.white01}`
            }
        },
        '> div:last-of-type': {
            height: 30,
            right: 'unset',
            left: 10
        }
    },
    toggle: {
        '.toggleContainer': {
            backgroundColor: 'rgba(164, 168, 184, 0.3)'
        }
    },
    spacer: {
        flex: 1
    },
    labelTooltipSmall: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.margins.margin00}`,
        backgroundColor: theme.colors.black01,
        opacity: 0.9,
        borderRadius: 6.5,
        color: theme.colors.white01
    },
    browseGallery: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.gray04,
        padding: 10,
        height: 30,
        backgroundColor: theme.colors.gray13,
        borderRadius: 6,
        cursor: 'pointer',
        margin: '4px 0 0 4px',
        p: {
            marginLeft: 8
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            p: {
                color: theme.colors.gray10
            },
            svg: {
                fill: theme.colors.white01
            }
        },
        ':active': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    },
    newButton: {
        ...theme.content.blueButton,
        justifyContent: 'center',
        display: 'inline-flex',
        borderRadius: 6,
        boxShadow: 'unset',
        margin: '0 8px 8px',
        svg: {
            marginBottom: 1
        },
        p: {
            marginLeft: 6,
            color: theme.colors.white01
        }
    },
    newButtonLocked: {
        backgroundColor: theme.colors.yellow05,
        display: 'inline-flex',
        justifyContent: 'center',
        borderRadius: 6,
        boxShadow: 'unset',
        margin: '0 8px 8px',
        svg: {
            marginBottom: 1
        },
        p: {
            marginLeft: 6,
            color: theme.colors.black01
        },
        ':hover': {
            backgroundColor: theme.colors.yellow04
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    },
    sections: {}
});
