export const styleSheet = theme => ({
    container: {
        background: theme.colors.white01,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 32px 20px',
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            margin: `5px ${theme.margins.margin01} 0`
        }
    },
    attachments: {
        display: 'flex',
        flex: 1,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'column'
        }
    },
    attachmentBlock: {
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: 6,
        padding: '10px 14px',
        marginRight: 10
    },
    attachmentLabel: {
        letterSpacing: 1,
        color: theme.colors.gray06,
        marginBottom: 10
    },
    attachmentLink: {
        color: theme.colors.black01,
        p: {
            color: theme.colors.black01
        },
        textDecoration: 'none',
        ':active': {
            textDecoration: 'none'
        },
        ':hover': {
            textDecoration: 'none'
        }
    },
    attachmentTag: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        cursor: 'pointer',
        justifyContent: 'space-between',
        ':hover': {
            textDecoration: 'underline',
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    },
    attachmentOpenOption: {
        lineHeight: '1.3em',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        svg: {
            marginRight: 6
        },
        ':hover': {
            color: theme.colors.blue08,
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    },
    heading: {
        letterSpacing: '2px',
        color: theme.colors.gray06
    },
    attachmentsRow: {
        position: 'relative',
        zIndex: 6,
        background: theme.colors.white01,
        borderBottom: `1px solid ${theme.colors.gray01}`
    },
    attachmentsHeader: {
        position: 'relative',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        height: 40,
        padding: '0 20px',
        svg: {
            marginRight: 8
        },
        ':hover': {
            backgroundColor: theme.colors.gray10
        }
    },
    attachmentsHeaderLabel: {
        display: 'inline-block',
        whiteSpace: 'nowrap'
    },
    attachmentsHeaderText: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        left: 33,
        right: 20,
        top: 0,
        bottom: 0
    },
    attachmentsHeaderTitle: {
        color: theme.colors.gray06,
        marginLeft: 6,
        fontStyle: 'italic',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
    }
});
