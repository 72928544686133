import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { PREFERENCES, STREAM_DISPLAY_MODES } from 'consts';
import { getPreference } from 'utils';
import { getBookmarkFilters, mapScopesToSelectedType } from 'utils/streams';
import { withData } from './data';
import { BookmarkStreamUI } from './ui';

const DEFAULT_SCOPE = 'my';
const SCOPE_OPTIONS = [
    {
        hint: 'Highlights created by you and members of your organization',
        label: 'All',
        value: 'all'
    },
    {
        hint: 'Highlights created by you only',
        label: 'Mine Only',
        value: 'my'
    },
    {
        hint: 'Highlights created by other members of your organization, excluding you',
        label: 'Team Only',
        value: 'team'
    }
];

export class BookmarkStream extends PureComponent {
    static displayName = 'BookmarkStreamContainer';

    static propTypes = {
        bookmarks: PropTypes.arrayOf(PropTypes.object),
        currentUserId: PropTypes.string,
        loading: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        preferences: PropTypes.objectOf(PropTypes.any),
        scopes: PropTypes.arrayOf(PropTypes.object),
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        updateStream: PropTypes.func.isRequired
    };

    static defaultProps = {
        bookmarks: undefined,
        currentUserId: undefined,
        loading: false,
        loadMoreMatches: undefined,
        preferences: undefined,
        scopes: [],
        streamId: undefined,
        streamProps: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onScopeChange = this.onScopeChange.bind(this);

        this.state = {
            selectedScope: mapScopesToSelectedType(props.scopes) || DEFAULT_SCOPE,
            updatingScope: false
        };
    }

    componentDidUpdate({ scopes: prevScopes }) {
        const { scopes } = this.props;
        if (!isEqual(prevScopes, scopes)) {
            this.setState({
                selectedScope: mapScopesToSelectedType(scopes) || DEFAULT_SCOPE
            });
        }
    }

    onScopeChange(scope) {
        const { selectedScope } = this.state;
        const { streamId, updateStream } = this.props;
        if (selectedScope !== scope) {
            this.setState(
                {
                    selectedScope: scope,
                    updatingScope: true
                },
                () => {
                    updateStream(streamId, getBookmarkFilters(scope)).finally(() => {
                        this.setState({ updatingScope: false });
                    });
                }
            );
        }
    }

    render() {
        const { selectedScope, updatingScope } = this.state;
        const {
            bookmarks,
            currentUserId,
            loading,
            loadMoreMatches,
            preferences,
            streamId,
            streamProps,
            styles
        } = this.props;
        return (
            <BookmarkStreamUI
                bookmarks={bookmarks}
                currentUserId={currentUserId}
                displayMode={getPreference(preferences, PREFERENCES.streamDisplayMode, STREAM_DISPLAY_MODES.default)}
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                onScopeChange={this.onScopeChange}
                scopeOptions={SCOPE_OPTIONS}
                selectedScope={selectedScope}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                updatingScope={updatingScope}
            />
        );
    }
}

export const BookmarkStreamContainer = compose(withUrlContext(['pathname']), withData())(BookmarkStream);
