import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { userSettingsFragment } from 'graphql/fragments/alerts';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withStreamAlertsTooltip($streamId: ID, $withStreams: Boolean = false) {
                    streams(filter: { streamIds: [$streamId] }) @include(if: $withStreams) {
                        userSettings {
                            ...userSettings
                        }
                    }
                }
                ${userSettingsFragment}
            `,
            {
                props: ({ data, ownProps: { targetType } }) => {
                    let newProps = {};
                    if (targetType === 'stream') {
                        const userSettings = get(data, 'streams[0].userSettings');
                        newProps = {
                            ...newProps,
                            toggleValueDaily: get(userSettings, 'dailyDigestEnabled'),
                            toggleValueRealTimeEmail: get(userSettings, 'realtimeNotificationsEmail'),
                            toggleValueRealTimeInApp: get(userSettings, 'realtimeNotificationsInApp'),
                            toggleValueWeekly: get(userSettings, 'weeklyDigestEnabled')
                        };
                    }
                    return {
                        loading: get(data, 'loading'),
                        ...newProps
                    };
                },
                skip: ({ targetId, targetType, wasVisible }) => !targetId || !targetType || !wasVisible,
                options: ({ targetId, targetType }) => {
                    let variables = {};
                    if (targetType === 'stream') {
                        variables = {
                            ...variables,
                            streamId: targetId,
                            withStreams: true
                        };
                    }
                    return {
                        fetchPolicy: 'cache-first',
                        variables,
                        context: { batchKey: 'StreamAlertsTooltip' }
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation UpdateAverageDailyVolume($streamId: ID!) {
                    updateStreamStatistics(streamId: $streamId) {
                        stream {
                            id
                            averageDailyVolume
                        }
                    }
                }
            `,
            {
                props: ({ mutate }) => ({
                    updateAverageDailyVolume: streamId => mutate({ variables: { streamId } })
                })
            }
        ),
        graphql(
            gql`
                mutation UpdateUserObjectSettings($input: UpdateUserObjectSettingsInput!) {
                    updateUserObjectSettings(input: $input) {
                        settings {
                            ...userSettings
                        }
                    }
                }
                ${userSettingsFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    updateAlertSettings: input =>
                        mutate({
                            variables: { input }
                        })
                            .then(() => {
                                setStatusBanner('Alert Preferences Saved');
                            })
                            .catch(error => {
                                setStatusBanner(`Error toggling alert: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                })
            }
        )
    );
