import bugsnag from 'bugsnag-js';
import LogRocket from 'logrocket';

// Currently this initializes and returns the bugsnag
// client. This allows the caller to pass the pre-configured
// client around in a flexible way.
export function configureBugsnag(config) {
    const {
        BUGSNAG_API,
        BUGSNAG_ENABLED,
        BUGSNAG_VERSION,
        LOGROCKET_ENABLED,
        AIERA_ENV,
        BUILD_COMMIT,
        GITHUB_BASE
    } = config;

    const bugsnagClient = bugsnag({
        apiKey: BUGSNAG_API,
        appVersion: BUGSNAG_VERSION,
        revision: BUILD_COMMIT,
        releaseStage: AIERA_ENV,
        beforeSend: report => {
            report.updateMetaData('logRocket', {
                sessionURL: LOGROCKET_ENABLED ? LogRocket.sessionURL : null
            });

            // Don't actually send the report if bugsnag isn't enabled
            return BUGSNAG_ENABLED;
        }
    });

    bugsnagClient.metaData = {
        ...(bugsnagClient.metaData || {}),
        github: { commit: BUILD_COMMIT, source: `${GITHUB_BASE}/tree/${BUILD_COMMIT}` }
    };

    return { bugsnagClient };
}
