import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { TextInput } from 'components/TextInput';
import { styleSheet } from './stylesheet';

class MinMaxInput extends PureComponent {
    static propTypes = {
        mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        maxLabel: PropTypes.string,
        minLabel: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    };

    static defaultProps = {
        mask: undefined,
        maxLabel: null,
        minLabel: null,
        name: undefined,
        onChange: null,
        passedStyles: {},
        value: null
    };

    render() {
        const { onChange, passedStyles, styles, value, mask, minLabel, maxLabel, name } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <TextInput
                    autoComplete="off"
                    mask={mask}
                    clearable
                    label={minLabel || 'min'}
                    name={`${name}:min`}
                    onChange={onChange}
                    styles={styles.input}
                    value={get(value, 'min')}
                    type={mask ? undefined : 'number'}
                />
                <TextInput
                    autoComplete="off"
                    mask={mask}
                    clearable
                    label={maxLabel || 'max'}
                    name={`${name}:max`}
                    onChange={onChange}
                    styles={styles.input}
                    value={get(value, 'max')}
                    type={mask ? undefined : 'number'}
                />
            </Div>
        );
    }
}

export const MinMaxInputUI = compose(withStyleSheet(styleSheet))(MinMaxInput);
