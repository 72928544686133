import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TYPES } from 'consts/filters';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { BulkFollowIdentifiers } from 'components/BulkFollowIdentifiers';
import { EquityAutocomplete } from 'components/EquityAutocomplete';
import { Div } from 'components/Div';
import { Heading } from 'components/Heading';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { MinMaxInput } from 'components/MinMaxInput';
import { Modal } from 'components/Modal';
import { Notice } from 'components/Notice';
import { SectorAutocomplete } from 'components/SectorAutocomplete';
import { ShareableLinkField } from 'components/ShareableLinkField';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Toggle } from 'components/Toggle';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';
import { WatchlistFilters } from 'components/WatchlistFilters';
import { WatchlistPreview } from 'components/WatchlistPreview';
import { styleSheet } from './stylesheet';

class WatchlistForm extends PureComponent {
    static propTypes = {
        bulkIdentifiersRef: PropTypes.objectOf(PropTypes.any).isRequired,
        cloneName: PropTypes.string,
        cloning: PropTypes.bool.isRequired,
        deleting: PropTypes.bool.isRequired,
        filters: PropTypes.objectOf(PropTypes.any),
        hiddenEquities: PropTypes.arrayOf(PropTypes.any).isRequired,
        isCancelDisabled: PropTypes.bool.isRequired,
        isModalOpen: PropTypes.bool.isRequired,
        isNew: PropTypes.bool.isRequired,
        isOnboarding: PropTypes.bool.isRequired,
        isSubmitDisabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        locked: PropTypes.bool.isRequired,
        marketCapButtons: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.object
            })
        ),
        name: PropTypes.string,
        onAddSelectedIdentifiers: PropTypes.func.isRequired,
        onBulkIdentifiersReset: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onChangeSelectedIdentifiers: PropTypes.func.isRequired,
        onClone: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onRemoveAllEquities: PropTypes.func.isRequired,
        onRemoveEquity: PropTypes.func.isRequired,
        onRestore: PropTypes.func.isRequired,
        onReviewIdentifiers: PropTypes.func.isRequired,
        onSelectEquity: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onUndoRemoveEquity: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        previewRules: PropTypes.arrayOf(PropTypes.any),
        reviewingIdentifiers: PropTypes.bool.isRequired,
        searchable: PropTypes.bool,
        selectedIdentifiers: PropTypes.arrayOf(PropTypes.string).isRequired,
        selectedPreviewEquities: PropTypes.arrayOf(PropTypes.string).isRequired,
        shareableLink: PropTypes.string,
        showAdvanced: PropTypes.bool.isRequired,
        showWarning: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        submitting: PropTypes.bool.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleAdvanced: PropTypes.func.isRequired,
        title: PropTypes.string,
        uuid: PropTypes.string.isRequired
    };

    static defaultProps = {
        cloneName: '',
        filters: {},
        marketCapButtons: [],
        name: null,
        passedStyles: {},
        previewRules: [],
        searchable: undefined,
        shareableLink: undefined,
        title: null
    };

    renderHeader() {
        const { onCancel, styles, theme, title, isOnboarding } = this.props;
        return (
            <Fragment>
                <Div styles={styles.titleClose}>
                    <Heading size={1}>{isOnboarding ? 'Create Primary Watchlist' : title}</Heading>
                    {!isOnboarding && (
                        <Div styles={styles.closeModal} onClick={onCancel}>
                            <Icon type="xMark" color={theme.colors.black01} />
                        </Div>
                    )}
                </Div>
                {isOnboarding && (
                    <Div>Your primary watchlist is used to create a better default experience in Aiera</Div>
                )}
            </Fragment>
        );
    }

    renderForm() {
        const {
            bulkIdentifiersRef,
            filters,
            isOnboarding,
            marketCapButtons,
            name,
            onAddSelectedIdentifiers,
            onBulkIdentifiersReset,
            onChange,
            onChangeSelectedIdentifiers,
            onReviewIdentifiers,
            reviewingIdentifiers,
            searchable,
            selectedIdentifiers,
            shareableLink,
            showAdvanced,
            styles,
            theme,
            toggleAdvanced,
            uuid
        } = this.props;
        return (
            <Div styles={styles.form}>
                {!isOnboarding && (
                    <Div styles={styles.formRow}>
                        <TextInput
                            autoFocus
                            styles={styles.titleInput}
                            label="Watchlist title"
                            name={`${uuid}:name`}
                            onChange={onChange}
                            placeholder="Enter watchlist title..."
                            value={name}
                        />
                    </Div>
                )}
                <EquityAutocomplete
                    label="Search For a Company"
                    name="equity"
                    onChange={onChange}
                    placeholder="ex. Twitter or TWTR..."
                    styles={styles.singleEquity}
                    clearOnSelect
                />
                <BulkFollowIdentifiers
                    bulkFollowIdentifiersRef={bulkIdentifiersRef}
                    hideSectors
                    isReviewingResults={reviewingIdentifiers}
                    label="Bulk Add Companies By Ticker"
                    onReviewResults={onReviewIdentifiers}
                    onToggleEquity={onChangeSelectedIdentifiers}
                    styles={styles.bulkIdentifiers}
                />
                {reviewingIdentifiers && (
                    <Div styles={styles.bulkIdentifierButtonsContainer}>
                        <ActionButton onClick={onBulkIdentifiersReset} size={1} styles={styles.bulkIdentifiersButton}>
                            <Text size={1}>Go Back</Text>
                        </ActionButton>
                        <ActionButton
                            disabled={selectedIdentifiers.length === 0}
                            onClick={onAddSelectedIdentifiers}
                            size={1}
                            styles={styles.bulkIdentifiersButton}
                        >
                            <Text size={1}>Add Companies</Text>
                        </ActionButton>
                    </Div>
                )}
                <Div onClick={toggleAdvanced} styles={styles.toggleAdvanced}>
                    <Icon
                        type="chevron02"
                        color={theme.colors.black01}
                        styles={{
                            marginRight: 10,
                            transform: showAdvanced ? `rotate(90deg)` : ''
                        }}
                    />
                    {showAdvanced ? 'Hide advanced settings' : 'Show advanced settings'}
                </Div>
                {showAdvanced && (
                    <Fragment>
                        <Text size={5} weight="medium" styles={styles.dynamicTitle}>
                            Advanced Dynamic Rules
                        </Text>
                        <Text size={3} styles={styles.dynamicDescription}>
                            The rules below will return all matching companies. Dynamically added companies will
                            automatically be added and removed from the watchlist over time as the companies matching
                            the rules change. Dynamic rules will not impact manually added or removed companies.
                        </Text>
                        <Div styles={styles.formRow}>
                            <SectorAutocomplete
                                label="Sectors"
                                name={`${uuid}:${TYPES.sector}`}
                                multi
                                onChange={onChange}
                                useTags
                                value={filters[TYPES.sector]}
                                styles={styles.sectorAutocomplete}
                            />
                        </Div>

                        <Div styles={styles.formRow}>
                            <MinMaxInput
                                minLabel="Min MktCap."
                                maxLabel="Max MktCap."
                                styles={styles.minMax}
                                mask={{
                                    alias: 'currency',
                                    digits: 0,
                                    numericInput: true,
                                    rightAlign: false
                                }}
                                name={`${uuid}:${TYPES.mcap}`}
                                onChange={onChange}
                                value={filters[TYPES.mcap]}
                            />
                            <Div styles={styles.marketCapButtons}>
                                {marketCapButtons.map(({ label, value }) => (
                                    <ActionButton
                                        key={label}
                                        onClick={event => onChange({ name: TYPES.mcap, value, event })}
                                    >
                                        <Text>{label}</Text>
                                    </ActionButton>
                                ))}
                            </Div>
                        </Div>

                        <Div styles={styles.filtersContainer}>
                            <WatchlistFilters
                                name="filterList"
                                onChange={onChange}
                                styles={styles.filters}
                                value={filters.filterList}
                            />
                        </Div>

                        {(searchable !== undefined || shareableLink) && (
                            <Div styles={styles.formRow}>
                                {searchable !== undefined && (
                                    <Toggle on={false} styles={styles.toggle} gray leftLabel="Searchable" />
                                )}
                                {shareableLink && (
                                    <ShareableLinkField
                                        confirmText={
                                            'This will invalidate any previously shared links to this watchlist. Are you sure you want ' +
                                            'to generate a new share link?'
                                        }
                                        label="share watchlist"
                                        link={shareableLink}
                                        onRefresh={() => {}}
                                        styles={styles.shareLink}
                                        tooltipText={
                                            'Any user with this URL will have access to clone this watchlist. Click the refresh button ' +
                                            'to generate a new shareable link. This will invalidate any previously shared links to ' +
                                            'this watchlist.'
                                        }
                                    />
                                )}
                            </Div>
                        )}
                    </Fragment>
                )}
            </Div>
        );
    }

    renderButtons() {
        const {
            cloneName,
            cloning,
            deleting,
            isCancelDisabled,
            isNew,
            isOnboarding,
            isSubmitDisabled,
            loading,
            locked,
            onCancel,
            onChange,
            onClone,
            onRemove,
            onRestore,
            onSubmit,
            styles,
            submitting,
            theme
        } = this.props;
        const cloneTooltip = (
            <TooltipForm
                buttonDisabled={!cloneName}
                inputName="cloneName"
                loading={cloning}
                onChange={onChange}
                onSubmit={onClone}
                placeholder="Enter new watchlist name..."
                submitButtonText="Clone watchlist"
                value={cloneName}
            />
        );
        return (
            <Div styles={styles.buttons}>
                {!isNew && !isOnboarding && (
                    <Fragment>
                        <ActionButton
                            disabled={locked}
                            loading={deleting}
                            size={2}
                            styles={{ ...styles.buttonRemove, ...(locked ? styles.buttonDisabled : {}) }}
                            onClick={onRemove}
                        >
                            <Icon type="trash02" color={theme.colors.white01} />
                            <Text size={3}>Remove</Text>
                        </ActionButton>
                        <Hint
                            growUp
                            onClick={onRestore}
                            text="Remove all companies and restore watchlist to S&P 500"
                            xOffset={0}
                            yOffset={-48}
                        >
                            <ActionButton size={2} styles={styles.button}>
                                <Icon type="reset02" color={theme.colors.gray04} />
                                <Text size={3}>Restore</Text>
                            </ActionButton>
                        </Hint>
                        <Tooltip
                            content={cloneTooltip}
                            isEnabled
                            onHide={event => onChange({ event, name: 'cloneName', value: '' })}
                            persistOnMouseExit
                            slideIn
                            useElementOffsetLeft
                            useElementOffsetTop
                            useOutsideClickHandler
                            xOffset={-1}
                            yOffset={-109}
                        >
                            {({ showTooltip }) => (
                                <ActionButton size={2} styles={styles.buttonClone} onClick={showTooltip}>
                                    <Icon type="copy" color={theme.colors.gray04} />
                                    <Text size={3}>Clone</Text>
                                </ActionButton>
                            )}
                        </Tooltip>
                    </Fragment>
                )}
                <Div styles={styles.spacer} />
                {!isOnboarding && (
                    <ActionButton disabled={isCancelDisabled} styles={styles.cancelButton} size={2} onClick={onCancel}>
                        <Text size={3}>Cancel</Text>
                    </ActionButton>
                )}
                <ActionButton
                    styles={{
                        ...styles.button,
                        ...styles.buttonSave,
                        ...(isSubmitDisabled ? styles.buttonDisabled : {})
                    }}
                    size={2}
                    disabled={isSubmitDisabled}
                    loading={loading || submitting}
                    onClick={onSubmit}
                >
                    <Text size={3}>
                        {isNew ? 'Create Watchlist' : isOnboarding ? 'Save & Start Using Aiera' : 'Save Watchlist'}
                    </Text>
                </ActionButton>
            </Div>
        );
    }

    renderWarning() {
        const { styles } = this.props;
        return (
            <Notice styles={styles.notice} type="error">
                We noticed that your Primary watchlist doesn&apos;t have any equities.
                <br />
                Please add an equity or sector and save the watchlist.
            </Notice>
        );
    }

    render() {
        const {
            hiddenEquities,
            isCancelDisabled,
            isModalOpen,
            isOnboarding,
            onCancel,
            onRemoveEquity,
            onRemoveAllEquities,
            onSelectEquity,
            onUndoRemoveEquity,
            passedStyles,
            previewRules,
            selectedPreviewEquities,
            showWarning,
            styles
        } = this.props;
        return (
            <Modal
                isOpen={isModalOpen}
                onModalClose={() => (isCancelDisabled || isOnboarding ? {} : onCancel())}
                useShell
            >
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div styles={styles.formContainer}>
                        <Div styles={styles.content}>
                            {this.renderHeader()}
                            {!isOnboarding && showWarning && this.renderWarning()}
                            {this.renderForm()}
                        </Div>
                        {this.renderButtons()}
                    </Div>
                    <WatchlistPreview
                        hiddenEquities={hiddenEquities}
                        onRemove={onRemoveEquity}
                        onRemoveAll={onRemoveAllEquities}
                        onSelect={onSelectEquity}
                        onUndoRemove={onUndoRemoveEquity}
                        rules={previewRules}
                        selectedEquities={selectedPreviewEquities}
                        styles={styles.previewContainer}
                    />
                </Div>
            </Modal>
        );
    }
}

export const WatchlistFormUI = compose(withStyleSheet(styleSheet))(WatchlistForm);
