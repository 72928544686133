import gql from 'graphql-tag';
import { compose, withPropsOnChange } from 'recompose';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { routes } from 'routes';
import { CAPABILITIES, PERMISSIONS } from 'consts';
import { get } from 'utils';
import { withUrlContext } from 'hoc/url';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { dashboardFragment, dashboardSectionFragment } from 'graphql/fragments/streams';
import { withMemo } from 'hoc/utils';

function generateRoute({ id: dashboardId, dashboardType, tabs }) {
    const tabString = tabs ? `?${qs.stringify({ tabs }, { encode: false })}` : '';
    const route = dashboardType === 'equity' ? routes.equityDashboard : routes.dashboard;

    return `${generatePath(route, { dashboardId })}${tabString}`;
}

function normalizeSections({ dashboardSections = [], userId, tabs }) {
    const sections = dashboardSections.map(({ sectionId, name, dashboards }) => ({
        sectionId,
        name,
        dashboards: dashboards.map(
            ({ id, name: label, capabilities, starred, creatingUserId, description, dashboardType, equity }) => ({
                dashboardType,
                draggable: true,
                description,
                editable:
                    dashboardType === 'equity' ||
                    dashboardType === 'event_group' ||
                    capabilities.includes(CAPABILITIES.edit),
                label: dashboardType === 'equity' ? get(equity, 'commonName') : label,
                id,
                isDefault: false,
                route: generateRoute({ id, dashboardType, tabs }),
                isFollowed: starred,
                isRecommended: false,
                isShared: userId !== creatingUserId
            })
        )
    }));

    return { dashboardSections: sections };
}

function normalize({ recommendedDashboards = [], userId, tabs /* , location */ }) {
    const defaultDashboards = [
        {
            label: 'Companies',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'companies',
            route: routes.companies,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'company'
        },
        {
            label: 'Events',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'events',
            route: generateRoute({ id: 'events', tabs }),
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'calendar'
        },
        {
            label: 'Aieracast',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'aieracast',
            route: generateRoute({ id: 'aieracast', tabs }),
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            showNumRead: true,
            icon: 'calendarBolt'
        },
        {
            label: 'Calendar',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'calendar',
            route: routes.calendar,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'event2',
            permissionProps: { permissions: [PERMISSIONS.featureCalendar] }
        },
        {
            label: 'Recordings',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'recordings',
            route: generateRoute({ id: 'recordings', tabs }),
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'mic',
            permissionProps: { permissions: [PERMISSIONS.featureCreateEvent] }
        },
        {
            label: 'Broker Research',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'research',
            route: routes.research,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'briefcase',
            permissionProps: { permissions: [PERMISSIONS.featureStreamsResearch] }
        },
        {
            label: 'Documents',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'documents',
            route: routes.documents,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'files',
            permissionProps: { permissions: [PERMISSIONS.featureUploads] }
        },
        {
            label: 'Workspace',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'workspace',
            route: routes.workspace,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'folder',
            permissionProps: { permissions: [PERMISSIONS.featureBookmarks] }
        },
        {
            label: 'Watchlists',
            editable: false,
            draggable: false,
            isDefault: true,
            id: 'watchlists',
            route: routes.watchlistRoot,
            isRecommended: false,
            isShared: false,
            isFollowed: false,
            icon: 'list02'
        }
        // {
        //     label: 'Gallery',
        //     editable: false,
        //     draggable: false,
        //     isDefault: true,
        //     id: 'dashboardGallery',
        //     route: generateModalId({ location, id: 'new', type: 'dashboardGallery' }),
        //     isRecommended: false,
        //     isShared: false,
        //     isFollowed: false,
        //     icon: 'gallery',
        //     unselectable: true
        // }
    ];

    const normalizeDashboard = () => ({
        id,
        name,
        starred,
        capabilities,
        creatingUserId,
        description,
        dashboardType,
        equity,
        recommended
    }) => ({
        editable: dashboardType === 'equity' || (!starred && !recommended && capabilities.includes(CAPABILITIES.edit)),
        dashboardType,
        draggable: true,
        description,
        label: dashboardType === 'equity' ? get(equity, 'commonName') : name,
        id,
        isDefault: false,
        route: generateRoute({ id, dashboardType, tabs }),
        isFollowed: starred,
        isRecommended: recommended,
        isShared: userId !== creatingUserId
    });

    const recommendedOptions = recommendedDashboards.map(normalizeDashboard()).filter(({ isFollowed }) => !isFollowed);

    return {
        defaultDashboards,
        recommendedDashboards: recommendedOptions
    };
}

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withUrlContext(['tabs', 'location']),
        graphql(
            gql`
                query withSidebarDashboardData {
                    currentUser {
                        id
                        dashboardSections {
                            ...dashboardSection
                            dashboards {
                                ...dashboard
                            }
                        }
                    }
                    recommendedDashboards: dashboards(filter: { recommended: true, size: 5 }) {
                        ...dashboard
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: ({ data }) => ({
                    dashboardSections: get(data, 'currentUser.dashboardSections'),
                    recommendedDashboards: get(data, 'recommendedDashboards'),
                    userId: get(data, 'currentUser.id')
                }),
                options: {
                    fetchPolicy: 'cache-and-network',
                    context: {
                        batchKey: 'sidebar'
                    }
                }
            }
        ),
        graphql(
            gql`
                mutation SetDashboardSectionPoisition($sectionId: ID!, $position: Int) {
                    setDashboardSectionPosition(sectionId: $sectionId, position: $position) {
                        success
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        setDashboardSectionPosition: (sectionId, position) =>
                            mutate({
                                variables: { sectionId, position }
                            }).catch(error => {
                                setStatusBanner(`Error moving section: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation AddDashboardToSection($sectionId: ID!, $position: Int!, $dashboardId: ID!) {
                    addDashboardToSection(sectionId: $sectionId, position: $position, dashboardId: $dashboardId) {
                        success
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        addDashboardToSection: (sectionId, position, dashboardId) =>
                            mutate({
                                variables: { sectionId, position, dashboardId }
                            }).catch(error => {
                                setStatusBanner(`Error moving monitor: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation RemoveDashboardFromSection($sectionId: ID!, $dashboardId: ID!) {
                    removeDashboardFromSection(sectionId: $sectionId, dashboardId: $dashboardId) {
                        success
                        query {
                            currentUser {
                                id
                                dashboardSections {
                                    ...dashboardSection
                                    dashboards {
                                        ...dashboard
                                    }
                                }
                            }
                            recommendedDashboards: dashboards(filter: { recommended: true, size: 5 }) {
                                ...dashboard
                            }
                        }
                    }
                }
                ${dashboardFragment}
                ${dashboardSectionFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        removeDashboardFromSection: (sectionId, dashboardId) =>
                            mutate({
                                variables: { sectionId, dashboardId }
                            }).catch(error => {
                                setStatusBanner(`Error removing monitor: ${error}`, 'error', 'circleX');
                                throw error;
                            })
                    };
                }
            }
        ),
        withMemo({ normalize, normalizeSections }),
        withPropsOnChange(
            ['dashboardSections', 'recommendedDashboards', 'userId', 'tabs', 'location'],
            ({
                normalize: normalizeDashboards,
                normalizeSections: normalizeDashboardSections,
                dashboardSections,
                recommendedDashboards,
                userId,
                tabs,
                location
            }) => ({
                ...normalizeDashboards({ recommendedDashboards, userId, tabs, location }),
                ...normalizeDashboardSections({ dashboardSections, userId, tabs })
            })
        )
    );
