export const styleSheet = theme => {
    const color = {
        height: 30,
        width: 20,
        borderRadius: 3.5,
        marginRight: 5,
        transition: 'all 0.1s ease-in-out'
    };
    return {
        label: {
            ...theme.content.formLabel
        },
        container: {},
        colors: {
            borderRadius: 6.5,
            display: 'flex',
            padding: '5px 6px',
            backgroundColor: theme.colors.gray03,
            '> div:last-of-type': {
                marginRight: 'unset'
            }
        },
        color: {
            ...color,
            cursor: 'pointer',
            ':hover': {
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                transform: 'scale(1.1)'
            },
            ':active': {
                transform: 'scale(1.1) translateY(1px)',
                boxShadow: `inset 0 0 0 12px rgba(0, 0, 0, 0.1)`
            }
        },
        selectedColor: {
            ...color,
            border: 'unset',
            boxShadow: `inset 0 0 0 1.5px ${theme.colors.white01}, 0 0 0 1.5px ${theme.colors.blue08}`
        }
    };
};
