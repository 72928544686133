import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';

import { styleSheet } from './stylesheet';

class TickerTag extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        ticker: PropTypes.string.isRequired,
        large: PropTypes.bool,
        small: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        large: false,
        small: false
    };

    render() {
        const { styles, ticker, passedStyles, large, small } = this.props;

        if (small) {
            return (
                <Div className="ticker-tag" styles={{ ...styles.containerSmall, ...passedStyles }}>
                    <Text span>{ticker}</Text>
                </Div>
            );
        }

        return (
            <Div className="ticker-tag" styles={{ ...styles.container, ...passedStyles }}>
                <Text size={large ? 3 : 2} weight="medium" uppercase>
                    {ticker}
                </Text>
            </Div>
        );
    }
}

export const TickerTagUI = compose(withStyleSheet(styleSheet))(TickerTag);
