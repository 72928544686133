import React, { PureComponent } from 'react';
import { webWidgetShow } from 'actions/zendesk';
import { DASHBOARD_ENDPOINT_PROD } from 'configuration';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { HeaderUI } from './ui';

export class Header extends PureComponent {
    static displayName = 'HeaderContainer';

    static propTypes = {
        openZendesk: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);
        this.onLeaveBeta = this.onLeaveBeta.bind(this);
    }

    onLeaveBeta() {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to leave Aiera Beta?')) {
            window.location = DASHBOARD_ENDPOINT_PROD;
        }
    }

    render() {
        const { openZendesk, styles } = this.props;
        return (
            <HeaderUI
                onLeaveBeta={this.onLeaveBeta}
                openZendesk={openZendesk}
                showNewEventModal={this.showNewEventModal}
                styles={styles}
            />
        );
    }
}

const mapDispatchToProps = {
    openZendesk: webWidgetShow
};

export const HeaderContainer = compose(connect(undefined, mapDispatchToProps))(Header);
