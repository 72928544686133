import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import memoize from 'memoize-one';
import XDate from 'xdate';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { highlightExport } from 'actions/highlight';
import { withGetUser } from 'graphql/user';
import { withUrlContext } from 'hoc/url';
import { get, parseTabId } from 'utils';

import regular from 'styles/fonts/Rubik-Regular.ttf';
import medium from 'styles/fonts/Rubik-Medium.ttf';
import bold from 'styles/fonts/Rubik-Bold.ttf';
import serif from 'styles/fonts/CharisSIL-B.ttf';

import { HighlightExportModalUI } from './ui';
import { withData } from './data';
import { generatePdfDocument } from './pdf';
import { generateWordDocument } from './doc';

function getSubtitle(highlights) {
    const { isMultiSource } = this.props;
    let subtitle;
    if (isMultiSource) {
        subtitle = 'Highlights';
    } else {
        const type = get(highlights, '[0].target.__typename');
        switch (type) {
            case 'FilingContent':
                subtitle = 'Filing Highlights';
                break;
            case 'NewsContent':
                subtitle = 'News Highlights';
                break;
            case 'ScheduledAudioCall':
            case 'ScheduledAudioCallEvent':
                subtitle = 'Event Highlights';
                break;
            default:
                subtitle = 'Highlights';
                break;
        }
    }
    return subtitle;
}

function getTitle(highlights) {
    const { isMultiSource } = this.props;
    return isMultiSource ? new XDate().toString('MMMM d, yyyy') : get(highlights, '[0].title');
}

export class HighlightExportModal extends PureComponent {
    static displayName = 'HighlightExportModalContainer';

    static propTypes = {
        highlights: PropTypes.arrayOf(PropTypes.any),
        isMultiSource: PropTypes.bool,
        loading: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        setExportHighlights: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        userId: PropTypes.string
    };

    static defaultProps = {
        highlights: [],
        isMultiSource: false,
        loading: false,
        styles: undefined,
        userId: undefined
    };

    constructor(props) {
        super(props);

        this.loadFonts = this.loadFonts.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDownloadPdf = this.onDownloadPdf.bind(this);
        this.onDownloadWord = this.onDownloadWord.bind(this);
        this.onTextEdit = this.onTextEdit.bind(this);
        this.removeHighlight = this.removeHighlight.bind(this);
        this.removeNote = this.removeNote.bind(this);
        this.resetHighlights = this.resetHighlights.bind(this);

        this.getSubtitle = memoize(getSubtitle);
        this.getTitle = memoize(getTitle);

        this.state = {
            canReset: false,
            defaultSubtitle: undefined,
            defaultTitle: undefined,
            hasChanges: false,
            highlights: [],
            loadingPdf: false,
            loadingWord: false,
            editedHighlights: [], // We handle the changes separately so they don't interrupt node editing,
            subTitle: undefined,
            title: undefined
        };
    }

    componentDidMount() {
        const { highlights } = this.props;
        const subtitle = this.getSubtitle(highlights);
        const title = this.getTitle(highlights);
        this.loadFonts();
        this.setState({
            defaultSubtitle: subtitle,
            defaultTitle: title,
            highlights,
            editedHighlights: highlights,
            subTitle: subtitle,
            title
        });
    }

    componentDidUpdate(prevProps) {
        const { highlights } = this.props;
        const { highlights: prevHighlights } = prevProps;
        if (highlights.length !== prevHighlights.length) {
            const subtitle = this.getSubtitle(highlights);
            const title = this.getTitle(highlights);
            this.setState({
                defaultSubtitle: subtitle,
                defaultTitle: title,
                highlights,
                editedHighlights: highlights,
                subTitle: subtitle,
                title
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.fontLoader);
    }

    loadFonts() {
        import('@react-pdf/renderer').then(({ Font }) => {
            let attempts = 0;
            return new Promise((resolve, reject) => {
                let hasSans = false;
                let hasSerif = false;
                Font.register({
                    family: 'Rubik',
                    format: 'truetype',
                    fonts: [{ src: regular }, { src: medium, fontWeight: 500 }, { src: bold, fontWeight: 700 }]
                });

                Font.register({
                    family: 'CharisSIL',
                    format: 'truetype',
                    src: serif,
                    fontWeight: 700
                });
                Font.load({ fontFamily: 'CharisSIL' }).then(() => {
                    hasSerif = true;
                });
                Font.load({ fontFamily: 'Rubik' }).then(() => {
                    hasSans = true;
                });

                const fontsLoaded = () => {
                    this.fontLoader = setTimeout(() => {
                        attempts += 1;
                        if (attempts < 5) {
                            if (hasSans && hasSerif) {
                                resolve();
                            } else {
                                fontsLoaded();
                            }
                        } else {
                            reject();
                        }
                    }, 1000);
                };

                fontsLoaded();
            });
        });
    }

    // Clear selected bookmark ids in redux when closing modal
    onClose() {
        const { hasChanges } = this.state;
        const { onClose, setExportHighlights } = this.props;
        if (
            !hasChanges ||
            (hasChanges &&
                window.confirm('You will lose changes if you close this editor. Are you sure you want to close?"'))
        ) {
            setExportHighlights(null);
            onClose();
        }
    }

    onDownloadPdf(styles) {
        const { editedHighlights, title, subTitle } = this.state;
        const { isMultiSource } = this.props;
        this.setState({ loadingPdf: true }, () => {
            generatePdfDocument({
                highlights: editedHighlights,
                isMultiSource,
                onComplete: () => this.setState({ hasChanges: false, loadingPdf: false }),
                styles,
                subTitle,
                title
            });
        });
    }

    onDownloadWord() {
        const { editedHighlights, title } = this.state;
        const { isMultiSource } = this.props;
        this.setState({ loadingWord: true }, () => {
            generateWordDocument({
                highlights: editedHighlights,
                isMultiSource,
                onComplete: () => this.setState({ hasChanges: false, loadingWord: false }),
                title
            });
        });
    }

    onTextEdit({ event, field, highlightIndex }) {
        const { highlights, editedHighlights } = this.state;
        const newHighlights = cloneDeep(editedHighlights.length === 0 ? highlights : editedHighlights);
        const textContent = get(event, 'nativeEvent.target.textContent');
        const newState = {
            canReset: true,
            hasChanges: true
        };

        // editedHighlights exists
        // because onTextEdit should not update
        // the highlights used in the UI component,
        // but it must update the state for the PDF.
        // removing nodes, must update the UI
        // and the PDF, so there is a need for both
        if (textContent && typeof highlightIndex === 'number') {
            newHighlights[highlightIndex] = {
                ...newHighlights[highlightIndex],
                [field]: textContent
            };
            newState.editedHighlights = newHighlights;
        } else if (textContent && field) {
            newState[field] = textContent;
        }

        this.setState(newState);
    }

    removeHighlight(index) {
        const { highlights, editedHighlights } = this.state;
        const newHighlights = cloneDeep(highlights);
        const newChanges = editedHighlights.length === 0 ? [] : cloneDeep(editedHighlights);
        newHighlights.splice(index, 1);
        if (newChanges.length) {
            newChanges.splice(index, 1);
        }

        if (newHighlights.length === 0) {
            this.resetHighlights();
        } else {
            this.setState({
                canReset: true,
                hasChanges: true,
                highlights: newHighlights,
                editedHighlights: newChanges
            });
        }
    }

    removeNote(index) {
        const { highlights, editedHighlights } = this.state;
        const newHighlights = cloneDeep(highlights);
        const newChanges = editedHighlights.length === 0 ? [] : cloneDeep(editedHighlights);
        newHighlights[index] = {
            ...highlights[index],
            noteRemoved: true
        };
        if (newChanges.length) {
            newChanges[index] = {
                ...editedHighlights[index],
                noteRemoved: true
            };
        }
        this.setState({
            canReset: true,
            hasChanges: true,
            highlights: newHighlights,
            editedHighlights: newChanges
        });
    }

    resetHighlights() {
        const { highlights } = this.props;
        this.setState(
            {
                highlights: [],
                editedHighlights: [],
                defaultSubtitle: undefined,
                defaultTitle: undefined
            },
            () => {
                const subtitle = this.getSubtitle(highlights);
                const title = this.getTitle(highlights);
                this.setState({
                    canReset: false,
                    defaultSubtitle: subtitle,
                    defaultTitle: title,
                    hasChanges: false,
                    highlights,
                    editedHighlights: highlights,
                    subTitle: subtitle,
                    title
                });
            }
        );
    }

    render() {
        const { isMultiSource, loading, styles, userId } = this.props;
        const { canReset, defaultSubtitle, defaultTitle, highlights, loadingPdf, loadingWord } = this.state;
        return (
            <HighlightExportModalUI
                canReset={canReset}
                highlights={highlights}
                isMultiSource={isMultiSource}
                loading={loading}
                loadingPdf={loadingPdf}
                loadingWord={loadingWord}
                onClose={this.onClose}
                onDownloadPdf={this.onDownloadPdf}
                onDownloadWord={this.onDownloadWord}
                onTextEdit={this.onTextEdit}
                removeHighlight={this.removeHighlight}
                removeNote={this.removeNote}
                resetHighlights={this.resetHighlights}
                styles={styles}
                subTitle={defaultSubtitle}
                title={defaultTitle}
                userId={userId}
            />
        );
    }
}

const mapStateToProps = ({ Highlight }) => ({
    bookmarkIds: get(Highlight, 'bookmarkIds')
});

const mapDispatchToProps = {
    setExportHighlights: highlightExport
};

export const HighlightExportModalContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withUrlContext(['tabs']),
    withPropsOnChange(['tabs'], ({ tabs }) => ({
        ...(tabs ? parseTabId(tabs[0]) : {})
    })),
    withData(),
    withGetUser({ fetchPolicy: 'cache-first' }),
    withPropsOnChange(['user'], ({ user }) => ({
        userId: get(user, 'id')
    }))
)(HighlightExportModal);
