export const styleSheet = theme => {
    const eventAlertToggle = {
        flex: 'unset',
        cursor: 'pointer',
        height: 34,
        width: 34,
        padding: 'unset',
        marginRight: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        boxShadow: 'unset'
    };
    const company = {
        flexShrink: 0,
        width: '320px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        borderRight: '1px solid rgba(0,0,0,0.15)',
        height: '100%',
        '.companyName': {
            whiteSpace: 'nowrap',
            width: 260,
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            overflow: 'hidden'
        },
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    };
    return {
        container: {
            backgroundColor: theme.colors.gray08,
            boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.1)',
            height: '52px',
            padding: 0,
            zIndex: 2,
            transition: 'all 0.2s ease-in-out',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.white01
        },
        eventAlertToggle: {
            ...eventAlertToggle,
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.15)',
                boxShadow: 'unset'
            },
            ':active': {
                'svg g': {
                    fill: theme.colors.blue08
                }
            }
        },
        eventAlertToggleEnabled: {
            ...eventAlertToggle,
            padding: 10,
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.15)',
                boxShadow: 'unset'
            },
            ':active': {
                'svg g': {
                    fill: theme.colors.gray06
                }
            }
        },
        headerInfo: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0 12px',
            flex: 1
        },
        headerTitle: {
            display: 'flex',
            position: 'relative'
        },
        headerTitlePlaceholder: {
            opacity: 0,
            maxWidth: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        headerTitleTextWrapper: {
            display: 'flex',
            position: 'absolute',
            left: 0,
            right: theme.margins.margin00,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        headerTitleText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize'
        },
        headerSmallText: {
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical'
        },
        backIcon: {
            height: '30px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            borderRadius: '6px',
            cursor: 'pointer',
            marginLeft: '12px',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                'svg g': {
                    fill: theme.colors.blue01
                }
            },
            svg: {
                height: '12px',
                width: '12px',
                transform: 'rotate(180deg)'
            }
        },
        eventStatus: {
            padding: '3px 2px 0px 3px',
            borderRadius: '2px',
            marginRight: '6px',
            letterSpacing: '1px',
            backgroundColor: theme.colors.red01,
            color: theme.colors.white01
        },
        detailItem: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        copyLink: {
            color: theme.colors.yellow07,
            ':hover': {
                color: theme.colors.orange01
            }
        },
        copyClickMask: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            opacity: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        textLabel: {
            color: theme.colors.gray04,
            letterSpacing: '1px'
        },
        eventDate: {
            color: theme.colors.gray04,
            marginRight: '6px'
        },
        eventType: {
            color: theme.colors.gray03,
            marginRight: '6px'
        },
        ticker: {
            color: theme.colors.orange02,
            marginRight: '6px',
            cursor: 'pointer',
            ':hover': {
                color: theme.colors.white01
            }
        },
        company: {
            ...company,
            justifyContent: 'space-between',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                flexShrink: 1,
                width: 'unset'
            }
        },
        companyFocus: {
            ...company,
            width: 194,
            justifyContent: 'space-between',
            borderLeft: '1px solid rgba(0,0,0,0.15)',
            '.companyName': {
                whiteSpace: 'nowrap',
                width: 140,
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                overflow: 'hidden'
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                flexShrink: 1,
                width: 'unset'
            }
        },
        companyPublic: {
            ...company,
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                flexShrink: 1,
                width: 'unset'
            }
        },
        companyNone: {
            ...company,
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                borderLeft: '1px solid rgba(0,0,0,0.15)',
                flexShrink: 1,
                width: 'unset'
            }
        },
        companySummary: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: theme.margins.margin00
        },
        companyTicker: {
            color: theme.colors.gray04,
            letterSpacing: '1px',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                color: theme.colors.white01
            }
        },
        exchange: {
            opacity: 0.5,
            marginLeft: 4
        },
        eventMenuButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            cursor: 'pointer',
            borderLeft: '1px solid rgba(0,0,0,0.15)',
            height: '100%',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                'svg g': {
                    fill: theme.colors.blue01
                }
            },
            ':active': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
            }
        },
        manageMenu: {
            ...theme.content.hoverMenu,
            borderRadius: '0 0 0 6px'
        },
        manageMenuOption: {
            ...theme.content.hoverMenuOption,
            svg: {
                height: 14
            },
            p: {
                marginLeft: 8
            },
            ':first-of-type': {
                borderRadius: 0
            },
            ':last-of-type': {
                borderRadius: '0 0 0 6px'
            }
        },
        downloadLink: {
            flexShrink: 0,
            width: 176,
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 12px',
            borderLeft: '1px solid rgba(0,0,0,0.15)',
            height: '100%',
            svg: {
                width: '12px',
                height: '16px'
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                flexShrink: 1,
                width: 'unset'
            }
        },
        downloadBtns: {
            display: 'flex',
            '.pdfDownloadBtn': {
                cursor: 'pointer',
                marginLeft: 4,
                height: 30,
                width: 30,
                borderRadius: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.colors.gray16,
                ':hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    'svg g': {
                        fill: theme.colors.blue01
                    },
                    'svg path': {
                        fill: theme.colors.blue01
                    }
                },
                ':active': {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)'
                }
            }
        },
        downloadBtn: {
            cursor: 'pointer',
            marginLeft: 4,
            height: 30,
            width: 30,
            borderRadius: 6,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.gray16,
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                'svg g': {
                    fill: theme.colors.blue01
                },
                'svg path': {
                    fill: theme.colors.blue01
                }
            },
            ':active': {
                backgroundColor: 'rgba(0, 0, 0, 0.4)'
            }
        },
        shareLink: {
            flexShrink: 0,
            width: '106px',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 12px',
            borderLeft: '1px solid rgba(0,0,0,0.15)',
            height: '100%',
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                'svg g': {
                    fill: theme.colors.blue01
                }
            },
            ':active': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
            },
            svg: {
                width: '12px',
                height: '16px'
            },
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                flexShrink: 1,
                width: 'unset'
            }
        },
        columns: {
            display: 'flex'
        },
        loadingPlaceholders: {
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '12px'
        },
        loadingSmallText: {
            height: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
            borderRadius: '6px',
            width: '60px',
            marginRight: theme.margins.margin00,
            ...theme.content.flickerAnimation
        },
        loadingMediumText: {
            height: '15px',
            backgroundColor: 'rgba(255, 255, 255, 0.09)',
            borderRadius: '6px',
            width: '250px',
            ...theme.content.flickerAnimation
        }
    };
};
