import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { TableUI } from './ui';

export class Table extends PureComponent {
    static displayName = 'TableContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <TableUI {...rest} />;
    }
}

export const TableContainer = compose()(Table);
