import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { getQuestionsQuery } from 'graphql/askAiera';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(getQuestionsQuery, {
            props: ({ data }) => {
                const questions = get(data, 'getQuestions', []);
                const startPolling = get(data, 'startPolling');
                const stopPolling = get(data, 'stopPolling');
                const loading = get(data, 'loading', false);
                const waitingForAnswers = questions.some(({ answer }) => !answer);
                if (waitingForAnswers) {
                    startPolling(30000);
                } else {
                    stopPolling();
                }
                return {
                    loading,
                    questions
                };
            },
            options: ({ eventId }) => ({
                fetchPolicy: 'cache-and-network',
                variables: {
                    startDate: undefined,
                    endDate: undefined,
                    eventId
                }
            })
        })
    );
