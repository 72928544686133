import memoize from 'lodash/memoize';
import gql from 'graphql-tag';
import pick from 'lodash/pick';
import { withApollo } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle, withPropsOnChange } from 'recompose';
import { config } from 'configuration';
import { get, debounceCallback } from 'utils';
import { userLoad, userLogout } from 'actions/user';
import { statusBannerFire } from 'actions/statusBanner';
import { smallEquityFragment } from 'graphql/fragments/equities';
import {
    userFragment,
    preferenceFragment,
    organizationFragment,
    domainFragment,
    codeFragment
} from 'graphql/fragments/user';
import { differentialFinancialsFragment } from 'graphql/fragments/audioCalls';
import { withWindowVisibility } from 'hoc/window';
import { mergeOptions, mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import { withIntegration } from 'provider/integration';
import { constants } from 'consts';

export const getUserQuery = alias => gql`
    query ${alias || 'GetCurrentUser'}(
        $withDetails: Boolean = false,
        $withPreferences: Boolean = false,
        $withOrganization: Boolean = false,
        $withOrganizationDetails: Boolean = false,
        $withFollowedEquities: Boolean = false,
        $withEventHighlights: Boolean = false,
        $withNextMonitoredEvent: Boolean = false,
        $withLoginUrl: Boolean = false,
        $withDev: Boolean = false
    ) {
        loginUrl @include(if: $withLoginUrl)
        currentUser {
            id
            userId
            status
            isDev @include(if: $withDev)
            ...user @include(if: $withDetails)
            eventPriceHighlights @include(if: $withEventHighlights) {
                event {
					eventId
                    title
                    callDate
                    equity {
                        id
                        localTicker
                        commonName
                        name
                    }
                }
                startPrice
                endOrLatestPrice
                movementPercent
                movementAbsolute
            }
            numMonitoredLiveEvents @include(if: $withEventHighlights)
            preferences @include(if: $withPreferences) {
                ...preference
            }
            organization @include(if: $withOrganization) {
                ...organization
                configuration @include(if: $withOrganizationDetails) {
                    domains {
                        domain
                        weight
                        blacklisted
                    }
                }
                preferences @include(if: $withOrganizationDetails) {
                    preferenceData
                    preferenceName
                    preferenceType
                    preferenceValue
                }
                users @include(if: $withOrganizationDetails) {
                    ...user
                }
                inviteCode @include(if: $withOrganizationDetails)
            }
            followedEquities @include(if: $withFollowedEquities) {
                ...smallEquity
            }
            nextMonitoredEvent @include(if: $withNextMonitoredEvent) {
                scheduledAudioCallId
                callDate
            }
        }
    }
    ${userFragment}
    ${preferenceFragment}
    ${organizationFragment}
    ${smallEquityFragment}
`;

export const getUserFromCode = alias => gql`
    query ${alias || 'GetUserFromCode'}($code: String!, $codeType: String) {
        code(code: $code, codeType: $codeType) {
            email
            firstName
            lastName
            organizationId
            organizationName
            type
        }
    }
`;

export const registerUserMutation = gql`
    mutation Register($input: RegisterInput!) {
        register(input: $input) {
            success
            inviteCode {
                ...code
            }
            user {
                ...user
            }
        }
    }
    ${codeFragment}
    ${userFragment}
`;

export const updateUserMutation = gql`
    mutation UpdateUser($firstName: String, $lastName: String, $phone: String, $timezone: String) {
        updateUser(input: { firstName: $firstName, lastName: $lastName, phone: $phone, timezone: $timezone }) {
            success
            user {
                ...user
            }
        }
    }
    ${userFragment}
`;

export const loginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            success
            user {
                ...user
                organization {
                    ...organization
                }
            }
        }
    }
    ${userFragment}
    ${organizationFragment}
`;

export const logoutMutation = gql`
    mutation Logout {
        logout {
            success
        }
    }
`;

export const setPasswordMutation = gql`
    mutation SetPassword($newPassword: String!, $oldPassword: String) {
        setPassword(newPassword: $newPassword, oldPassword: $oldPassword) {
            success
        }
    }
`;

export const forgotPasswordMutation = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(email: $email) {
            success
        }
    }
`;

export const resetPasswordMutation = gql`
    mutation ResetPassword($code: String!, $password: String!) {
        resetPassword(code: $code, password: $password) {
            success
        }
    }
`;

export const verifyEmailMutation = gql`
    mutation VerifyEmail($code: String!) {
        verifyEmail(code: $code) {
            success
            user {
                id
                status
            }
        }
    }
`;

export const followEquitiesMutation = gql`
    mutation FollowEquities($equityIds: [ID]!, $follow: Boolean = true, $userId: ID) {
        followEquities(equityIds: $equityIds, follow: $follow, userId: $userId) {
            success
        }
    }
`;

export const addUserFinancialKpiMutation = gql`
    mutation AddUserFinancialKpi(
        $equityId: ID!
        $financialTermKey: ID!
        $userId: ID
        $eventId: ID
        $includeEvent: Boolean = false
    ) {
        addUserFinancialKpi(
            equityId: $equityId
            financialTermKey: $financialTermKey
            userId: $userId
            eventId: $eventId
        ) {
            success
            event @include(if: $includeEvent) {
                id
                scheduledAudioCallId
                differentials {
                    financials {
                        ...differentialFinancials
                    }
                }
            }
        }
    }
    ${differentialFinancialsFragment}
`;

export const removeUserFinancialKpiMutation = gql`
    mutation RemoveUserFinancialKpi($userFinancialKpiId: ID, $eventId: ID, $includeEvent: Boolean = false) {
        removeUserFinancialKpi(userFinancialKpiId: $userFinancialKpiId, eventId: $eventId) {
            success
            event @include(if: $includeEvent) {
                id
                scheduledAudioCallId
                differentials {
                    financials {
                        ...differentialFinancials
                    }
                }
            }
        }
    }
    ${differentialFinancialsFragment}
`;

export const replaceUserFinancialKpiMutation = gql`
    mutation ReplaceUserFinancialKpi(
        $userFinancialKpiId: ID
        $financialTermKey: ID!
        $eventId: ID
        $includeEvent: Boolean = false
    ) {
        replaceUserFinancialKpi(
            userFinancialKpiId: $userFinancialKpiId
            financialTermKey: $financialTermKey
            eventId: $eventId
        ) {
            success
            event @include(if: $includeEvent) {
                id
                scheduledAudioCallId
                differentials {
                    financials {
                        ...differentialFinancials
                    }
                }
            }
        }
    }
    ${differentialFinancialsFragment}
`;

export const inviteUserMutation = gql`
    mutation InviteUser($email: String!) {
        inviteUser(email: $email) {
            success
        }
    }
`;

export const setPreferenceMutation = gql`
    mutation SetPreferences($input: [PreferenceInput]!) {
        setPreferences(input: $input) {
            user {
                id
                userId
                status
                preferences {
                    ...preference
                }
            }
        }
    }
    ${preferenceFragment}
`;

export const addDomainMutation = gql`
    mutation SaveDomainConfiguration($input: DomainConfigurationInput!) {
        saveDomainConfiguration(input: $input) {
            success
            domainConfiguration {
                ...domain
            }
        }
    }
    ${domainFragment}
`;

export const deleteDomainMutation = gql`
    mutation DeleteDomainConfiguration($input: DomainConfigurationInput!) {
        deleteDomainConfiguration(input: $input) {
            success
        }
    }
`;

export const trackActivityMutation = gql`
    mutation TrackActivity(
        $companyId: ID
        $contentId: ID
        $dataRecordId: ID
        $dashboardId: ID
        $environment: TrackingEnvironment
        $eventId: ID
        $eventType: TrackingEventType = access
    ) {
        trackActivity(
            companyId: $companyId
            contentId: $contentId
            dashboardId: $dashboardId
            dataRecordId: $dataRecordId
            environment: $environment
            eventId: $eventId
            eventType: $eventType
        ) {
            success
        }
    }
`;

const normalizeUser = memoize(user => ({
    ...user,
    firstName: get(user, 'firstName', get(user, 'email', '')),
    lastName: get(user, 'lastName', ''),
    timezone: user.timezone || user.lastRequestTimezone,
    organizationAdmin: !!user.organizationAdmin,
    phone: user.phone ? user.phone.replace(/^\+1/, '') : user.phone,
    name: `${get(user, 'firstName', get(user, 'email', ''))} ${get(user, 'lastName', '')}`
}));

export const withGetUser = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withUser';
    return graphql(getUserQuery(alias), {
        props: ({ data }) => ({
            userLoading: data.loading,
            userError: data.error,
            userFetchMore: data.fetchMore,
            userRefresh: data.refetch,
            user: data.currentUser ? normalizeUser(data.currentUser) : undefined,
            loginUrl: get(data, 'loginUrl')
        }),
        alias,
        skip: props => mapPropsToOptions(options, props).skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            const variables = {
                withDetails: false,
                withDev: false,
                withPreferences: false,
                withOrganization: false,
                withOrganizationDetails: false,
                withFollowedEquities: false,
                withSuggestedEquities: false,
                withEventHighlights: false,
                ...(opts.variables || {})
            };
            const expensiveVars = ['withFollowedEquities', 'withSuggestedEquities', 'withOrganizationDetails'];
            return {
                returnPreviousData: opts.returnPreviousData,
                fetchPolicy: opts.fetchPolicy || 'cache-first',
                pollInterval: opts.pollInterval,
                context: {
                    batchKey: !expensiveVars.some(v => variables[v]) && 'withUser'
                },
                variables
            };
        }
    });
};

export const withRegisterUser = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(registerUserMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                register: registerInput =>
                    mutate({
                        variables: { input: { ...registerInput } }
                    })
                        .then(({ data }) => {
                            const {
                                register: { success, user, inviteCode }
                            } = data;

                            if (success) {
                                setStatusBanner('Account created successfully!');
                            } else {
                                setStatusBanner(
                                    'Something went wrong. We apologize for the inconvenience. Please try again.',
                                    'error',
                                    'circleX'
                                );
                            }

                            return { success, user, inviteCode };
                        })
                        .catch(error => {
                            const errorMessage =
                                'Something went wrong. We apologize for the inconvenience. Our ' +
                                'engineers have been notified.';
                            setStatusBanner(errorMessage, 'error', 'circleX');
                            throw error;
                        })
            })
        })
    );

export const withUpdateUser = () =>
    compose(
        connect(undefined, { loadUser: userLoad, setStatusBanner: statusBannerFire }),
        graphql(updateUserMutation, {
            props: ({ mutate, ownProps: { loadUser, setStatusBanner } }) => ({
                updateUser: ({ name, phone, timezone }) => {
                    const names = name.split(' ');
                    let firstName = name;
                    let lastName;
                    if (names.length > 1) {
                        lastName = names.pop();
                        firstName = names.join(' ');
                    }
                    return mutate({
                        variables: {
                            firstName,
                            lastName,
                            phone,
                            timezone
                        }
                    })
                        .then(
                            ({
                                data: {
                                    updateUser: { user }
                                }
                            }) => {
                                loadUser(normalizeUser(user));
                            }
                        )
                        .then(() => {
                            setStatusBanner('Account saved successfully!');
                        })
                        .catch(() => {
                            setStatusBanner('There was an error saving your account', 'error', 'circleX');
                        });
                }
            })
        })
    );

export const withLogin = () =>
    compose(
        connect(undefined, { loadUser: userLoad }),
        withApollo,
        graphql(loginMutation, {
            props: ({ mutate, ownProps: { loadUser, client: apolloClient } }) => ({
                login: (email, password) => {
                    apolloClient.cache.reset();
                    return mutate({
                        variables: {
                            email,
                            password
                        },
                        update: (
                            proxy,
                            {
                                data: {
                                    login: { user }
                                }
                            }
                        ) => {
                            const query = getUserQuery();
                            proxy.writeQuery({
                                query,
                                data: { loginUrl: null, currentUser: user },
                                variables: { withLoginUrl: true }
                            });
                        }
                    }).then(
                        ({
                            data: {
                                login: { user }
                            }
                        }) => {
                            loadUser({ ...normalizeUser(user) });
                        }
                    );
                }
            })
        })
    );

export const withLogout = () =>
    compose(
        connect(undefined, { logoutUser: userLogout }),
        withApollo,
        graphql(logoutMutation, {
            props: ({ mutate, ownProps: { client: apolloClient, logoutUser } }) => ({
                logout: () => {
                    logoutUser();
                    return mutate({
                        update: proxy => {
                            const query = getUserQuery();
                            proxy.writeQuery({
                                query,
                                data: { loginUrl: null, currentUser: null },
                                variables: { withLoginUrl: true }
                            });
                        }
                    }).then(() => apolloClient.cache.reset());
                }
            })
        })
    );

export const withSetPassword = () =>
    compose(
        graphql(setPasswordMutation, {
            props: ({ mutate }) => ({
                setPassword: (newPassword, oldPassword) => mutate({ variables: { newPassword, oldPassword } })
            })
        })
    );

export const withForgotPassword = () =>
    compose(
        graphql(forgotPasswordMutation, {
            props: ({ mutate }) => ({
                forgotPassword: email => mutate({ variables: { email } })
            })
        })
    );

export const withResetPassword = () =>
    compose(
        graphql(resetPasswordMutation, {
            props: ({ mutate }) => ({
                resetPassword: (code, password) => mutate({ variables: { code, password } })
            })
        })
    );

export const withGetUserFromCode = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withGetUserFromCode';
    return graphql(getUserFromCode(alias), {
        props: ({ data }) => {
            return {
                loading: data.loading,
                organizationId: get(data, 'code.organizationId'),
                organizationName: get(data, 'code.organizationName'),
                codeType: get(data, 'code.type'),
                email: get(data, 'code.email'),
                firstName: get(data, 'code.firstName'),
                lastName: get(data, 'code.lastName')
            };
        },
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-first',
                variables: {
                    ...(opts.variables || {})
                }
            };
        }
    });
};

export const withInviteUser = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(inviteUserMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                inviteUser: email =>
                    mutate({ variables: { email } })
                        .then(() => setStatusBanner(`An invitation has been sent to ${email}!`))
                        .catch(e => {
                            setStatusBanner("We weren't able to send the invitation.", 'error', 'circleX');
                            throw e;
                        })
            })
        })
    );

export const withPassword = () => compose(withSetPassword(), withForgotPassword(), withResetPassword());

export const withUser = (options = {}) =>
    compose(
        withGetUser(
            mergeOptions(
                {
                    variables: { withDetails: true }
                },
                options
            )
        ),
        withLogin(),
        withLogout(),
        withUpdateUser()
    );

// Translate
// [{ preferenceType: 'type', preferenceName: 'name', preferenceValue: 'value'}]
// into
// { type: { name: value }}
const normalizePreferences = memoize(preferences =>
    preferences.reduce(
        (prefs, pref) => ({
            ...prefs,
            [pref.preferenceType]: {
                ...(prefs[pref.preferenceType] || {}),
                [pref.preferenceName]:
                    pref.preferenceValue !== undefined && pref.preferenceValue !== null
                        ? pref.preferenceValue
                        : pref.preferenceData
            }
        }),
        {}
    )
);

export const withUserPreferences = (options = {}) =>
    compose(
        withUser(
            mergeOptions(
                {
                    alias: 'withUserPreferences',
                    variables: { withPreferences: true }
                },
                options
            )
        ),
        withIntegration(),
        // We try to grab the preference info synchronously so that we can use it to conditionally
        // render components on the first render. If we haven't retrieved them yet, we'll load
        // them from the integration asynchronously.
        withProps(props => {
            const { integration } = props;
            const info = integration.getIntegrationInfo({ sync: true });
            return { preferencesLoading: !info, preferences: info ? info.preferences : undefined };
        }),
        lifecycle({
            componentDidMount() {
                const { integration, preferencesLoading } = this.props;
                // preferenceLoading is set to true above if we couldn't grab them synchronously
                // In that case, we'll load them here and then override preferencesLoading
                if (preferencesLoading) {
                    integration
                        .enabled()
                        .then(
                            enabled =>
                                enabled &&
                                integration.getIntegrationInfo().then(({ preferences }) => {
                                    this.setState({ preferences });
                                })
                        )
                        .finally(() => this.setState({ preferencesLoading: false }));
                }
            },
            componentDidUpdate({ user: prevUser }) {
                const prevUserPreferences = get(prevUser, 'preferences');
                const userPreferences = get(this.props, 'user.preferences');
                if (userPreferences && prevUserPreferences !== userPreferences) {
                    try {
                        localStorage.setItem('aiera:userPreferences', JSON.stringify(userPreferences));
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                }
            }
        }),
        withPropsOnChange(['preferencesLoading', 'preferences', 'userLoading', 'user'], props => {
            const preferencesLoading = get(props, 'preferencesLoading', true);
            const preferences = get(props, 'preferences', []);
            const userLoading = get(props, 'userLoading', true);
            let userPreferences = get(props, 'user.preferences');
            if (!userPreferences) {
                try {
                    userPreferences = JSON.parse(localStorage.getItem('aiera:userPreferences'));
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            }
            return {
                preferencesLoading: userLoading || preferencesLoading,
                preferences: normalizePreferences([...preferences, ...(userPreferences || [])])
            };
        }),
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(setPreferenceMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => {
                const savePreference = (preferenceType, values) => {
                    const { name, value, data, hideSuccessBanner } = values;
                    return mutate({
                        context: {
                            batchKey: 'userPrefs'
                        },
                        variables: {
                            input: [
                                {
                                    preferenceType,
                                    preferenceName: name,
                                    preferenceValue: value,
                                    preferenceData: data
                                }
                            ]
                        }
                    })
                        .then(() => {
                            // Don't show banner when updating dashboard layout
                            if (!hideSuccessBanner) {
                                setStatusBanner('Preference saved successfully!');
                            }
                        })
                        .catch(e => {
                            setStatusBanner(`We weren't able to save this preference`, 'error', 'circleX');
                            throw e;
                        });
                };
                return {
                    savePreference,
                    saveNotificationPreference: savePreference.bind(null, 'notification'),
                    saveUXPreference: savePreference.bind(null, 'ux')
                };
            }
        })
    );

export const withVerifyEmail = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(verifyEmailMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                verifyEmail: code =>
                    mutate({ variables: { code } })
                        .then(({ data }) => {
                            setStatusBanner('Thank you for confirming your email. Welcome to Aiera!');
                            return get(data, 'verifyEmail.user');
                        })
                        .catch(error => {
                            if (get(error, 'graphQLErrors[0].type') === 'UserAlreadyExists') {
                                setStatusBanner(
                                    'Your email is already confirmed. Please sign in.',
                                    'error',
                                    'circleX',
                                    3000
                                );
                            } else {
                                setStatusBanner(
                                    "We weren't able to verify your email. Please email us at " +
                                        `${constants.EMAIL_SUPPORT}, or call us at ${constants.PHONE_SUPPORT}.`,
                                    'error',
                                    'circleX'
                                );
                            }

                            throw error;
                        })
            })
        })
    );

export const withDomains = (options = {}) =>
    compose(
        withGetUser(
            mergeOptions(
                {
                    variables: { withOrganization: true, withOrganizationDetails: true }
                },
                options
            )
        ),
        withProps(({ user }) => ({ domains: get(user, 'organization.configuration.domains', []) })),
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(addDomainMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                addDomain: domainConfig =>
                    mutate({
                        refetchQueries: [
                            {
                                query: getUserQuery(),
                                variables: { withOrganization: true, withOrganizationDetails: true }
                            }
                        ],
                        awaitRefetchQueries: true,
                        variables: { input: pick(domainConfig, ['domain', 'weight', 'blacklisted']) }
                    })
                        .then(response => {
                            const { success, domainConfiguration } = response.data.saveDomainConfiguration;

                            if (success) {
                                setStatusBanner(`Domain ${domainConfig.isUpdate ? 'updated' : 'added'} successfully.`);
                            } else {
                                setStatusBanner(
                                    `Could not add domain ${domainConfiguration.domain}`,
                                    'error',
                                    'circleX'
                                );
                            }

                            return success;
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(`Failure from addDomainMutation: ${error}`);
                            setStatusBanner(
                                'Something went wrong. Please check the domain and try again.',
                                'error',
                                'circleX'
                            );
                        })
            })
        }),
        graphql(deleteDomainMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                deleteDomain: domainConfiguration =>
                    mutate({
                        refetchQueries: [
                            {
                                query: getUserQuery(),
                                variables: { withOrganization: true, withOrganizationDetails: true }
                            }
                        ],
                        awaitRefetchQueries: true,
                        variables: { input: domainConfiguration }
                    })
                        .then(response => {
                            const { success } = response.data.deleteDomainConfiguration;

                            if (success) {
                                setStatusBanner(`Domain deleted successfully.`);
                            } else {
                                setStatusBanner(`Could not delete domain. Please try again later.`, 'error', 'circleX');
                            }
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(`Failure from deleteDomainMutation: ${error}`);
                            setStatusBanner('Something went wrong. Please try again later.', 'error', 'circleX');
                        })
            })
        })
    );

export const withFollowEquities = (options = {}) =>
    compose(
        connect(undefined, {
            setStatusBanner: statusBannerFire
        }),
        graphql(followEquitiesMutation, {
            props: props => {
                const { mutate, ownProps } = props;
                const { setStatusBanner } = ownProps;
                const opts = mapPropsToOptions(options, ownProps);
                return {
                    followEquities: (equityIds, follow = true, userId) =>
                        mutate({
                            variables: { equityIds, follow, userId }
                        })
                            .then(({ data }) => {
                                if (opts.withStatusBanner) {
                                    const equities = equityIds.length;
                                    setStatusBanner(
                                        `Followed ${equities} ${equities === 1 ? 'equity' : 'equities'} successfully!`
                                    );
                                }
                                return get(data, 'followEquities.success', false);
                            })
                            .catch(() => {
                                setStatusBanner(
                                    'Something went wrong. We apologize for the inconvenience. Please refresh the page ' +
                                        'and try again.',
                                    'error',
                                    'circleX'
                                );
                            })
                };
            }
        })
    );

// No matter how many components use this, only track every thirty
const maxUserEveryThirty = debounceCallback(30000, { maxWait: 30000, leading: true });
export const withTrackUserActivity = () =>
    compose(
        graphql(
            gql`
                query taUser {
                    currentUser {
                        userId
                    }
                }
            `,
            {
                props: ({ data }) => ({ trackActivityUser: data.currentUser }),
                options: { fetchPolicy: 'cache-only' }
            }
        ),
        withWindowVisibility(),
        graphql(trackActivityMutation, {
            props: ({ mutate, ownProps: { windowVisible, trackActivityUser } }) => {
                const emptyVars = {
                    companyId: null,
                    contentId: null,
                    dashboardId: null,
                    dataRecordId: null,
                    environment: config.AIERA_ENV,
                    eventId: null,
                    eventType: null
                };
                return {
                    trackActivity: (force = false) => {
                        if (windowVisible && trackActivityUser && !config.actingAsUser()) {
                            if (force) {
                                mutate({ variables: emptyVars });
                            } else {
                                maxUserEveryThirty(() => mutate({ variables: emptyVars }));
                            }
                        }
                    },
                    trackEventActivity: eventId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({ alias: 'trackEventActivity', variables: { ...emptyVars, eventId } });
                        }
                    },
                    trackDashboardActivity: dashboardId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({ alias: 'trackDashboardActivity', variables: { ...emptyVars, dashboardId } });
                        }
                    },
                    trackContentActivity: contentId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({ alias: 'trackContentActivity', variables: { ...emptyVars, contentId } });
                        }
                    },
                    trackDataRecordActivity: dataRecordId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({ alias: 'trackContentActivity', variables: { ...emptyVars, dataRecordId } });
                        }
                    },
                    trackAudioStartActivity: eventId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({
                                alias: 'trackAudioStartActivity',
                                variables: { ...emptyVars, eventId, eventType: 'audio_start' }
                            });
                        }
                    },
                    trackAudioStopActivity: eventId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({
                                alias: 'trackAudioStopActivity',
                                variables: { ...emptyVars, eventId, eventType: 'audio_end' }
                            });
                        }
                    },
                    trackCompanyActivity: companyId => {
                        if (windowVisible && !config.actingAsUser()) {
                            mutate({
                                alias: 'trackCompanyActivity',
                                variables: { ...emptyVars, companyId }
                            });
                        }
                    }
                };
            }
        })
    );
