import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';

import { styleSheet } from './stylesheet';

class ButtonForm extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        icon: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        disabled: false,
        loading: false,
        icon: null
    };

    render() {
        const { styles, icon, theme, onClick, disabled, children, passedStyles, loading } = this.props;
        const iconStyles = disabled ? styles.disabledIconContainer : styles.iconContainer;
        let textStyles = disabled ? styles.disabledText : styles.text;
        let buttonStyles = disabled ? styles.disabledButton : styles.button;
        if (icon) {
            buttonStyles = { ...buttonStyles, padding: '0 14px 0 20px' };
            textStyles = { ...textStyles, paddingRight: `calc(${theme.margins.margin01} - 10px)` };
        }

        if (loading) {
            buttonStyles = styles.loadingButton;
            return (
                <Div styles={{ ...styles.container, ...passedStyles }}>
                    <Div styles={buttonStyles}>
                        <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                            <LoaderLogo type={2} color={theme.colors.white01} />
                        </MediaQuery>
                        <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                            <LoaderLogo type={2} color={theme.colors.white01} />
                        </MediaQuery>
                    </Div>
                </Div>
            );
        }

        /* eslint-disable jsx-a11y/control-has-associated-label */
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={buttonStyles} onClick={disabled ? () => {} : onClick}>
                    <Text size={3} styles={textStyles}>
                        {children}
                    </Text>
                    {icon && (
                        <Div style={iconStyles}>
                            <Icon type={icon} color={theme.colors.white01} />
                        </Div>
                    )}
                </Div>
                <button type="submit" style={styles.hiddenButton} />
            </Div>
        );
        /* eslint-enable jsx-a11y/control-has-associated-label */
    }
}

export const ButtonFormUI = compose(withStyleSheet(styleSheet))(ButtonForm);
