import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { styleSheet } from './stylesheet';

class Hint extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        disabled: PropTypes.bool.isRequired,
        fromLeft: PropTypes.bool.isRequired,
        growLeft: PropTypes.bool.isRequired,
        growUp: PropTypes.bool,
        hideOnScroll: PropTypes.bool.isRequired,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        slideIn: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        xOffset: PropTypes.number.isRequired,
        yOffset: PropTypes.number.isRequired
    };

    static defaultProps = {
        className: 'hint',
        description: undefined,
        growUp: undefined,
        name: undefined,
        onClick: undefined,
        onMouseEnter: undefined,
        onMouseLeave: undefined,
        passedStyles: {}
    };

    renderTooltip() {
        const { styles, text, description } = this.props;
        return (
            <Fragment>
                <Text styles={styles.tooltipText} size={1} weight="medium">
                    {text}
                </Text>
                {description && (
                    <Text styles={styles.tooltipTextDescription} size={1}>
                        {description}
                    </Text>
                )}
            </Fragment>
        );
    }

    render() {
        const {
            children,
            className,
            disabled,
            fromLeft,
            growLeft,
            growUp,
            hideOnScroll,
            name,
            onClick,
            onMouseEnter,
            onMouseLeave,
            passedStyles,
            slideIn,
            styles,
            theme,
            width,
            xOffset,
            yOffset
        } = this.props;
        const tooltipStyles = {
            ...styles.tooltip,
            width
        };
        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Tooltip
                        cancelClassName={className}
                        content={this.renderTooltip()}
                        growLeft={growLeft}
                        growUp={growUp}
                        hideOnScroll={hideOnScroll}
                        isEnabled={!m && !disabled}
                        keepInViewport
                        slideIn={slideIn}
                        styles={tooltipStyles}
                        useElementOffsetBottom
                        useElementOffsetLeft={!growLeft || fromLeft}
                        useElementOffsetRight={growLeft && !fromLeft}
                        useOutsideClickHandler
                        xOffset={xOffset}
                        yOffset={yOffset}
                    >
                        {({ showTooltip, hideTooltip }) => (
                            <Div
                                onClick={onClick}
                                styles={{ ...styles.container, ...passedStyles }}
                                onMouseEnter={
                                    onMouseEnter
                                        ? e => {
                                              onMouseEnter(e);
                                              showTooltip(e);
                                          }
                                        : showTooltip
                                }
                                onMouseLeave={
                                    onMouseLeave
                                        ? e => {
                                              onMouseLeave(e);
                                              hideTooltip(e);
                                          }
                                        : hideTooltip
                                }
                                data-tname={name}
                                className={className}
                            >
                                {children}
                            </Div>
                        )}
                    </Tooltip>
                )}
            </MediaQuery>
        );
    }
}

export const HintUI = compose(withStyleSheet(styleSheet))(Hint);
