import gql from 'graphql-tag';
import { compose, lifecycle, withProps } from 'recompose';
import { get } from 'utils';
import { withRealtime } from 'provider/realtime';
import { withMemo } from 'hoc/utils';
import { formatMatchesByType, mapDashFiltersToRules } from 'utils/streams';
import {
    getDefaultStreamOptions,
    getDefaultStreamProps,
    withStreamPaging,
    withUpdateStreamOnChanges
} from 'hoc/streams';
import { ruleFragment, streamFragment, transcriptMatchFragment } from 'graphql/fragments/streams';
import { graphql } from 'graphql/utils';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { withTrackUserActivity } from 'graphql/user';

export const withData = () =>
    compose(
        withTrackUserActivity(),
        withMemo({ formatMatches: formatMatchesByType, mapFilters: mapDashFiltersToRules }),
        graphql(
            gql`
                query withTranscriptStream(
                    $streamId: ID
                    $size: Int = 30
                    $offset: Int = 0
                    $filter: StreamMatchFilter
                ) {
                    streams(filter: { streamIds: [$streamId] }) {
                        ...stream
                        rules {
                            ...rule
                        }
                        ... on TranscriptStream {
                            event {
                                id
                                broadcastUrl
                                callDate
                                callType
                                conferenceNumber
                                equityId
                                expectPublishedTranscript
                                firstTranscriptItemStartMs
                                hasPublishedTranscript
                                isLive
                                priceHighlight {
                                    overThreshold
                                    movementAbsolute
                                    movementPercent
                                    endOrLatestPrice
                                }
                                title
                                transcriptionStatus
                            }
                        }
                        matches(size: $size, fromIndex: $offset, filter: $filter) {
                            total
                            results {
                                ...transcriptMatch
                                ... on TranscriptStreamMatch {
                                    transcript {
                                        transcript
                                    }
                                }
                            }
                        }
                    }
                }
                ${ruleFragment}
                ${streamFragment}
                ${transcriptMatchFragment}
            `,
            {
                props: ({ data, ownProps: { formatMatches } }) => {
                    const { fetchMore, loading, matches, refetch, rules, stream, subtitle } = getDefaultStreamProps(
                        data,
                        formatMatches
                    );
                    const lastTranscriptId = get(matches, '[0].id');
                    return {
                        eventId: get(stream, 'event.id'),
                        fetchMore,
                        lastTranscriptId,
                        loading,
                        matches,
                        refetch,
                        rules,
                        stream,
                        subtitle,
                        eventDate: get(stream, 'event.callDate'),
                        eventType: get(stream, 'event.callType'),
                        eventTitle: get(stream, 'event.title'),
                        firstTranscriptItemStartMs: get(stream, 'event.firstTranscriptItemStartMs'),
                        hasPublishedTranscript: get(stream, 'event.hasPublishedTranscript'),
                        expectPublishedTranscript: get(stream, 'event.expectPublishedTranscript'),
                        hasDetails: !!(get(stream, 'event.conferenceNumber') || get(stream, 'event.broadcastUrl')),
                        isLive: get(stream, 'event.isLive'),
                        equityId: get(stream, 'event.equityId')
                    };
                },
                skip: ({ streamId, streamProps }) => !get(streamProps, 'wasVisible', false) || !streamId,
                options: ({ dashSearchTerm, mapFilters, streamId }) => {
                    return getDefaultStreamOptions({
                        dashSearchTerm,
                        mapFilters,
                        streamId,
                        streamType: 'content'
                    });
                }
            }
        ),
        withUpdateStreamOnChanges(),
        withStreamPaging(),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.hasLiveTranscript = false;
                this.trySubscribe = () => {
                    const { eventId, getUpdates, realtime, refetch } = this.props;
                    if (!this.subscriptions && eventId) {
                        this.subscriptions = [
                            realtime.subscribe(
                                `scheduled_audio_call_${eventId}_events_changes`,
                                'partial_transcript',
                                () => {
                                    if (!get(this.state, 'hasPartial')) {
                                        this.setState(
                                            {
                                                hasPartial: true
                                            },
                                            () => {
                                                this.hasLiveTranscript = true;
                                            }
                                        );
                                    }
                                }
                            ),
                            realtime.subscribe(`scheduled_audio_call_${eventId}_events_changes`, 'modified', () =>
                                getUpdates()
                            ),
                            realtime.subscribe(`scheduled_audio_call_${eventId}_changes`, 'modified', () => {
                                // We only care about updating the SAC details until the call is live
                                // Once we have a partial transcript, don't refetch
                                if (!this.hasLiveTranscript) {
                                    refetch();
                                }
                            })
                        ];
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },

            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        }),
        withProps(({ eventId }) => ({ audioCallId: eventId })),
        withEventMediaPlayer({ allowLiveStream: true, trackDetails: false })
    );
