import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import sortBy from 'lodash/sortBy';
import { compose } from 'recompose';
import { get } from 'utils';
import { withData } from './data';
import { DashboardDropdownUI } from './ui';

function getDashboardOptions(dashboards, currentDashboard = {}) {
    return sortBy(
        dashboards
            .filter(d => d.id !== currentDashboard.id)
            .concat(currentDashboard)
            .map(d => ({
                label: d.name,
                value: d.id
            }))
            .filter(d => d.value),
        'label'
    );
}

export class DashboardDropdown extends PureComponent {
    static displayName = 'DashboardDropdownContainer';

    static propTypes = {
        cancelClassName: PropTypes.string,
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        dashboards: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                name: PropTypes.string
            })
        ),
        disableHoverOpen: PropTypes.bool,
        initialLabel: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        cancelClassName: undefined,
        currentDashboard: undefined,
        dashboards: [],
        disableHoverOpen: undefined,
        initialLabel: undefined,
        label: undefined,
        onChange: undefined,
        size: undefined,
        styles: {},
        value: undefined
    };

    constructor(props) {
        super(props);

        this.getDashboardOptions = memoize(getDashboardOptions);
    }

    componentDidMount() {
        this.maybeSendDefaultValue();
    }

    // Once the dashboards initially load, maybe send the first dashboard to the parent to use as a default value
    componentDidUpdate(prevProps) {
        if (!get(prevProps, 'dashboards.length')) {
            this.maybeSendDefaultValue();
        }
    }

    // When the value prop is omitted, pass the first dashboard to the parent via onChange
    // This let's the parent use a default value for a dashboard without needing to query for the dashboards itself
    maybeSendDefaultValue() {
        const { dashboards, name, onChange, value } = this.props;
        if (get(dashboards, 'length') && onChange && !value) {
            onChange({ name, value: get(dashboards, '[0].id') });
        }
    }

    render() {
        const {
            initialLabel,
            styles,
            cancelClassName,
            currentDashboard,
            dashboards,
            disableHoverOpen,
            label,
            name,
            onChange,
            size,
            value
        } = this.props;
        const options = this.getDashboardOptions(dashboards, currentDashboard);
        return (
            <DashboardDropdownUI
                initialLabel={initialLabel}
                styles={styles}
                cancelClassName={cancelClassName}
                disableHoverOpen={disableHoverOpen}
                label={label}
                name={name}
                onChange={onChange}
                options={options}
                size={size}
                value={value || get(options, '[0].value')}
            />
        );
    }
}

export const DashboardDropdownContainer = compose(withData())(DashboardDropdown);
