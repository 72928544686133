export const styleSheet = theme => {
    const menuItem = {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: 10,
        borderBottom: `1px solid ${theme.colors.gray03}`,
        svg: {
            flexShrink: 0,
            marginRight: 8,
            height: 14,
            width: 14
        },
        ':first-of-type': {
            borderRadius: '6px 6px 0 0'
        },
        ':last-of-type': {
            borderBottom: 'unset',
            borderRadius: '0 0 6px 6px'
        },
        ':hover': {
            backgroundColor: theme.colors.gray03,
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    };
    return {
        container: {
            alignItems: 'center',
            backgroundColor: theme.colors.white01,
            borderRadius: 6,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0,0,0,0.05)',
            cursor: 'pointer',
            display: 'flex',
            height: 30,
            width: 30,
            justifyContent: 'center'
        },
        loading: {
            margin: '0 auto'
        },
        menu: {
            backgroundColor: theme.colors.white01,
            borderRadius: 6,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0,0,0,0.05)',
            overflow: 'hidden',
            width: 150
        },
        menuItem: {
            ...menuItem
        },
        menuItemFilled: {
            'svg g': {
                fill: theme.colors.pink01
            }
        },
        menuItemSmall: {
            ...menuItem,
            svg: {
                height: 13,
                width: 13
            }
        },

        thumbFilled: {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    };
};
