export const styleSheet = theme => {
    const option = {
        width: '100%',
        padding: '0 12px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.black01,
        boxShadow: `inset 0 -1px 0 0 ${theme.colors.white03}`,
        ':last-of-type': {
            boxShadow: 'unset'
        }
    };
    const containerStyle = {
        display: 'flex',
        padding: '0 12px',
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        p: {
            minWidth: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    };
    return {
        containerSelected: {
            ...containerStyle,
            backgroundColor: theme.colors.white01,
            boxShadow: `inset 0 0 0 1px ${theme.colors.gray03}`,
            cursor: 'pointer',
            ':hover': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray05}`,
                backgroundColor: theme.colors.white02
            },
            ':focus': {
                boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
            }
        },
        container: {
            ...containerStyle,
            backgroundColor: theme.colors.white03,
            color: theme.colors.gray04,
            cursor: 'pointer',
            ':hover': {
                boxShadow: `inset 0 0 0 1px ${theme.colors.gray05}`,
                backgroundColor: theme.colors.white02
            },
            'svg g': {
                fill: theme.colors.gray04
            },
            ':focus': {
                boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
            }
        },
        containerDisabled: {
            ...containerStyle,
            backgroundColor: theme.colors.white03,
            color: theme.colors.gray04,
            'svg g': {
                fill: theme.colors.gray04
            }
        },
        chevron: {
            minWidth: 12,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: theme.margins.margin01,
            svg: {
                transform: 'rotate(90deg)'
            }
        },
        menuContainer: {
            // Tooltip uses 17 by default, but we want this to show over `Hint`s so inc by 1
            zIndex: 18,
            width: '100%',
            minHeight: 40,
            borderRadius: 6,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.colors.white01,
            boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
            '.dropdown-option--disabled': {
                color: theme.colors.gray04
            }
        },
        option: {
            ...option
        },
        optionHighlighted: {
            ...option,
            color: theme.colors.white01,
            backgroundColor: theme.colors.blue08,
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        spacer: {
            flex: 1
        },
        labelContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        label: {
            ...theme.content.formLabel
        }
    };
};
