import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { Logo } from 'components/Logo';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { getHighlightColor, toDateTimeString } from 'utils';
import { styleSheet } from './stylesheet';

class HighlightExportModal extends PureComponent {
    static propTypes = {
        canReset: PropTypes.bool.isRequired,
        highlights: PropTypes.arrayOf(PropTypes.any).isRequired,
        isMultiSource: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingPdf: PropTypes.bool.isRequired,
        loadingWord: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onDownloadPdf: PropTypes.func.isRequired,
        onDownloadWord: PropTypes.func.isRequired,
        onTextEdit: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        removeHighlight: PropTypes.func.isRequired,
        removeNote: PropTypes.func.isRequired,
        resetHighlights: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subTitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
        userId: PropTypes.string
    };

    static defaultProps = {
        passedStyles: {},
        title: undefined,
        subTitle: undefined,
        userId: undefined
    };

    renderHeader() {
        const {
            canReset,
            loadingPdf,
            loadingWord,
            onClose,
            onDownloadPdf,
            onDownloadWord,
            resetHighlights,
            styles,
            theme
        } = this.props;
        return (
            <Div styles={styles.header}>
                {/* 
                    <ActionButton onClick={() => {}}>
                        <Icon type="report" color={theme.colors.gray04} />
                        <Text>Create Report</Text>
                    </ActionButton>
                    <ActionButton onClick={() => {}}>
                        <Icon type="printer" color={theme.colors.gray04} />
                        <Text>Print</Text>
                    </ActionButton>
                */}
                <ActionButton styles={styles.resetBtn} onClick={resetHighlights} disabled={!canReset}>
                    <Icon type="refresh" color={canReset ? theme.colors.red01 : theme.colors.gray04} />
                    <Text>Reset</Text>
                </ActionButton>
                <Div styles={styles.spacer} />
                <Text styles={styles.cancel} onClick={onClose}>
                    Cancel
                </Text>
                <ActionButton styles={styles.downloadBtn} onClick={onDownloadWord} name="download-word-highlights">
                    <Icon type="download" color={theme.colors.gray04} />
                    <Text>{loadingWord ? 'Loading...' : 'Download Word Doc'}</Text>
                </ActionButton>
                <ActionButton
                    styles={styles.downloadBtn}
                    onClick={() => onDownloadPdf(styles)}
                    name="download-pdf-highlights"
                >
                    <Icon type="download" color={theme.colors.gray04} />
                    <Text>{loadingPdf ? 'Loading...' : 'Download PDF'}</Text>
                </ActionButton>
            </Div>
        );
    }

    renderTitle() {
        const { onTextEdit, styles, subTitle, theme, title } = this.props;
        return (
            <Div styles={styles.title}>
                <Div styles={styles.editWrapper}>
                    <Hint
                        yOffset={-20}
                        xOffset={20}
                        styles={{ ...styles.textEdit, left: -31, marginTop: 6 }}
                        text="Text can be edited for export"
                    >
                        <Icon type="textEdit" color={theme.colors.gray04} />
                    </Hint>
                    <Div style={styles.titleText}>
                        <Text
                            className="editable"
                            contentEditable
                            onInput={event => onTextEdit({ event, field: 'title' })}
                            serif
                            size={6}
                            styles={styles.titleHeadline}
                            weight="bold"
                        >
                            {title}
                        </Text>
                        {subTitle && (
                            <Text
                                className="editable"
                                contentEditable
                                onInput={event => onTextEdit({ event, field: 'subTitle' })}
                                size={1}
                                styles={styles.titleType}
                            >
                                {subTitle}
                            </Text>
                        )}
                    </Div>
                </Div>
            </Div>
        );
    }

    renderWatermark() {
        const { styles, theme } = this.props;
        return (
            <Div styles={styles.watermark}>
                <Text>Powered by</Text>
                <Logo height={20} width={74} oldmarkColor={theme.colors.gray04} color={theme.colors.gray04} />
            </Div>
        );
    }

    renderHighlights() {
        const {
            onTextEdit,
            highlights,
            isMultiSource,
            removeHighlight,
            removeNote,
            styles,
            theme,
            userId
        } = this.props;

        if (highlights.length === 0) {
            return (
                <Div styles={styles.emptyHighlights}>
                    <Text size={3}>No highlights</Text>
                </Div>
            );
        }

        return highlights.map(
            (
                {
                    created,
                    creatorName,
                    date,
                    highlight,
                    highlightColor,
                    id,
                    note,
                    noteRemoved,
                    speakerAffiliation,
                    speakerName,
                    speakerTitle,
                    startTimestamp,
                    title,
                    userId: creatorId
                },
                index
            ) => (
                <Div styles={styles.highlightContainer} key={id}>
                    <Div
                        styles={{
                            ...styles.highlightText,
                            backgroundColor: getHighlightColor(highlightColor)?.color,
                            borderRadius: noteRemoved ? 5 : '5px 5px 0 0'
                        }}
                    >
                        <Div
                            styles={styles.removeHighlight}
                            className="removeHighlight"
                            onClick={() => removeHighlight(index)}
                        >
                            <Icon type="circleX" color={theme.colors.black01} />
                        </Div>
                        {isMultiSource && (
                            <Fragment>
                                {title && (
                                    <Text size={3} weight="medium" styles={styles.highlightTargetTitle}>
                                        {title}
                                    </Text>
                                )}
                                {date && (
                                    <Text
                                        size={1}
                                        styles={{
                                            ...styles.highlightTime,
                                            color: getHighlightColor(highlightColor)?.dark02
                                        }}
                                    >
                                        {toDateTimeString(date, false, false, 'h:mm TT MMM dS, yyyy')}
                                    </Text>
                                )}
                            </Fragment>
                        )}
                        {speakerName && (
                            <Text
                                size={3}
                                weight="medium"
                                styles={
                                    isMultiSource && (title || date)
                                        ? styles.highlightSpeakerMulti
                                        : styles.highlightSpeaker
                                }
                            >
                                {speakerName}
                                {(speakerTitle || speakerAffiliation) && <br />}
                                {speakerTitle && (
                                    <Text span size={1}>
                                        {speakerTitle}
                                    </Text>
                                )}
                                {speakerAffiliation && (
                                    <Text span size={1}>
                                        {`, ${speakerAffiliation}`}
                                    </Text>
                                )}
                            </Text>
                        )}
                        {!isMultiSource && startTimestamp && (
                            <Text
                                size={1}
                                styles={{ ...styles.highlightTime, color: getHighlightColor(highlightColor)?.dark02 }}
                            >
                                {toDateTimeString(startTimestamp, false, false, 'h:mm TT MMM dS, yyyy')}
                            </Text>
                        )}
                        <Div styles={styles.editWrapper}>
                            <Hint
                                yOffset={-20}
                                xOffset={20}
                                styles={styles.textEdit}
                                text="Text can be edited for export"
                            >
                                <Icon type="textEdit" color={theme.colors.gray04} />
                            </Hint>
                            <Text
                                className="editable"
                                contentEditable
                                onInput={event => onTextEdit({ event, field: 'highlight', highlightIndex: index })}
                                size={3}
                                styles={styles.highlightSelection}
                            >
                                {highlight ? highlight.replace(/<[^>]*>?/gm, '') : ''}
                            </Text>
                        </Div>
                    </Div>
                    {!noteRemoved && (
                        <Div
                            styles={{
                                ...styles.note,
                                borderTop: `1px solid ${getHighlightColor(highlightColor)?.dark}`
                            }}
                        >
                            <Div styles={styles.removeNote} className="removeNote" onClick={() => removeNote(index)}>
                                <Icon type="circleX" color={theme.colors.black01} />
                            </Div>
                            <Div styles={styles.noteHeader}>
                                <Text size={3} weight="medium">
                                    {creatorName}
                                </Text>
                                <Text size={1} styles={styles.noteCreated}>
                                    {new XDate(created).toString('h:mm TT MMM dS, yyyy')}
                                </Text>
                            </Div>
                            {note && (
                                <Div styles={styles.editWrapper}>
                                    {userId === creatorId && (
                                        <Hint
                                            yOffset={-20}
                                            xOffset={20}
                                            styles={styles.textEdit}
                                            text="Text can be edited for export"
                                        >
                                            <Icon type="textEdit" color={theme.colors.gray04} />
                                        </Hint>
                                    )}
                                    <Text
                                        className={userId === creatorId ? 'editable' : undefined}
                                        contentEditable={userId === creatorId}
                                        onInput={event => onTextEdit({ event, field: 'note', highlightIndex: index })}
                                        size={3}
                                        styles={styles.noteText}
                                    >
                                        {note}
                                    </Text>
                                </Div>
                            )}
                        </Div>
                    )}
                </Div>
            )
        );
    }

    render() {
        const { onClose, passedStyles, styles, loading } = this.props;
        const containerStyle = loading ? styles.containerLoading : styles.container;
        return (
            <Modal useShell isOpen onModalClose={onClose} styles={{ ...containerStyle, ...passedStyles }}>
                {loading ? (
                    <Fragment>
                        <Text size={3}>Loading highlights...</Text>
                        <LoaderLogo height={60} styles={styles.loading} />
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.renderHeader()}
                        {this.renderTitle()}
                        {this.renderHighlights()}
                        {this.renderWatermark()}
                    </Fragment>
                )}
            </Modal>
        );
    }
}

export const HighlightExportModalUI = compose(withStyleSheet(styleSheet))(HighlightExportModal);
