import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { highlightAdd } from 'actions/highlight';
import { config } from 'configuration';
import { HIGHLIGHT_COLORS } from 'consts';
import { withCreateBookmark, withDeleteBookmark } from 'graphql/bookmarks';
import { withUrlContext } from 'hoc/url';
import { withReporting } from 'provider/reporting';
import { copyToClipboard, generateModalId, get } from 'utils';
import { withData } from './data';
import { StreamActionMenuUI } from './ui';

export class StreamActionMenu extends PureComponent {
    static displayName = 'StreamActionMenuContainer';

    static propTypes = {
        addHighlight: PropTypes.func.isRequired,
        bookmarkId: PropTypes.string,
        createBookmark: PropTypes.func.isRequired,
        deleteBookmark: PropTypes.func.isRequired,
        deleteDoc: PropTypes.func.isRequired,
        enableScoring: PropTypes.bool,
        eventId: PropTypes.string,
        highlight: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isDocumentOwner: PropTypes.bool,
        matchId: PropTypes.string,
        noBookmark: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        targetId: PropTypes.string,
        targetType: PropTypes.string,
        url: PropTypes.string,
        updateSettings: PropTypes.func.isRequired
    };

    static defaultProps = {
        bookmarkId: undefined,
        enableScoring: true,
        eventId: undefined,
        highlight: undefined,
        isDocumentOwner: false,
        matchId: undefined,
        noBookmark: false,
        score: undefined,
        streamId: undefined,
        styles: undefined,
        targetId: undefined,
        targetType: undefined,
        url: undefined
    };

    constructor(props) {
        super(props);

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.onBookmark = this.onBookmark.bind(this);
        this.onScore = this.onScore.bind(this);
        this.openBookmarkForm = this.openBookmarkForm.bind(this);

        this.state = {
            copied: false,
            score: get(props, 'score', null),
            submitting: false
        };
    }

    componentDidUpdate({ score: prevScore }) {
        const { score } = this.props;
        if (prevScore !== score) {
            this.setState({ score });
        }
    }

    copyToClipboard(event) {
        event.preventDefault();
        event.stopPropagation();
        const { url } = this.props;
        if (url) {
            const link = url[0] === '/' ? url.slice(1) : url;
            this.setState({ copied: true });
            copyToClipboard(`${config.DASHBOARD_ENDPOINT}${link}`);
        }
    }

    onBookmark(event, color) {
        event.preventDefault();
        event.stopPropagation();
        const {
            bookmarkId,
            createBookmark,
            deleteBookmark,
            highlight,
            matchId,
            reporter,
            streamId,
            targetId,
            targetType
        } = this.props;
        this.setState({ submitting: true }, () => {
            (bookmarkId
                ? deleteBookmark({ bookmarkId, matchId, targetType })
                : createBookmark({
                      highlight,
                      highlightColor: color || HIGHLIGHT_COLORS[0],
                      matchId,
                      targetStreamId: streamId,
                      targetId,
                      targetType
                  })
            )
                .then(() => {
                    // Only reset submitting if creating a bookmark
                    // Deleting a bookmark could cause the component to unmount before the state updates
                    if (!bookmarkId) {
                        this.setState({ submitting: false });
                    }

                    // Track Highlight Create / Delete
                    reporter.track(
                        reporter.actions.submit,
                        bookmarkId ? reporter.objects.highlightDelete : reporter.objects.highlightCreate,
                        {
                            component: 'StreamActionMenu',
                            matchId,
                            bookmarkId,
                            targetId,
                            targetType,
                            streamId
                        }
                    );
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        });
    }

    onScore(event, score) {
        event.preventDefault();
        event.stopPropagation();
        const { score: existingScore } = this.state;
        const { streamId, targetId, targetType, updateSettings } = this.props;
        let newScore = score;
        // Reset score if clicking same button
        if (score === existingScore) newScore = 0;
        this.setState({ score: newScore }, () => {
            updateSettings({
                score: newScore,
                streamId,
                targetId,
                targetType
            }).catch(() => {
                this.setState({ score: existingScore });
            });
        });
    }

    openBookmarkForm(event) {
        event.preventDefault();
        event.stopPropagation();
        const {
            addHighlight,
            bookmarkId,
            eventId,
            highlight,
            history,
            matchId,
            pathname,
            streamId,
            targetId,
            targetType
        } = this.props;
        addHighlight({ highlight, targetId, targetType, eventId, matchId, targetStreamId: streamId });
        history.push(generateModalId({ pathname, id: bookmarkId || 'new', type: 'bookmark' }));
    }

    render() {
        const { copied, score, submitting } = this.state;
        const {
            bookmarkId,
            deleteDoc,
            enableScoring,
            isDocumentOwner,
            noBookmark,
            styles,
            targetType,
            url
        } = this.props;
        return (
            <StreamActionMenuUI
                bookmarkId={bookmarkId}
                copied={copied}
                copyToClipboard={url ? this.copyToClipboard : undefined}
                deleteDoc={deleteDoc}
                enableScoring={enableScoring}
                isDocumentOwner={isDocumentOwner}
                noBookmark={noBookmark}
                onBookmark={this.onBookmark}
                onScore={this.onScore}
                openBookmarkForm={this.openBookmarkForm}
                score={score}
                styles={styles}
                submitting={submitting}
                targetType={targetType}
            />
        );
    }
}

const mapDispatchToProps = {
    addHighlight: highlightAdd
};

export const StreamActionMenuContainer = compose(
    connect(undefined, mapDispatchToProps),
    withUrlContext(['history', 'pathname']),
    withData(),
    withCreateBookmark(),
    withDeleteBookmark(),
    withReporting()
)(StreamActionMenu);
