import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ContentTabs } from 'components/ContentTabs';
import { Div } from 'components/Div';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { FeaturePreview } from 'components/FeaturePreview';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { ModuleTable } from 'components/ModuleTable';
import { ModuleTd } from 'components/ModuleTd';
import { ModuleTr } from 'components/ModuleTr';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { get, titleize } from 'utils';
import { TonalGraph } from 'components/TonalGraph';
import { Notice } from 'components/Notice';
import { ExternalLink } from 'components/ExternalLink';
import { EventHighlights } from '../EventHighlights';
import { EventLocalMonitors } from '../EventLocalMonitors';
import { EventMentions } from '../EventMentions';
import { styleSheet } from './stylesheet';
import { EventAskAiera } from '../EventAskAiera';
import { EventMatch } from '../EventMatch';

const ORDERED_SENTIMENT_TYPES = ['Positive', 'Negative', 'Divergence'];

class EventSidebar extends PureComponent {
    static propTypes = {
        collapsedDashboards: PropTypes.arrayOf(PropTypes.any).isRequired,
        eventId: PropTypes.string,
        equityId: PropTypes.string,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isPublic: PropTypes.bool.isRequired,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        loading: PropTypes.bool.isRequired,
        localMonitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        onNodeSelect: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSearchFocus: PropTypes.func.isRequired,
        onSentimentSelect: PropTypes.func.isRequired,
        onTermSelect: PropTypes.func.isRequired,
        pageId: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        scrollRef: PropTypes.objectOf(PropTypes.any).isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.any),
        searchTerm: PropTypes.string,
        selectTab: PropTypes.func.isRequired,
        selectedNode: PropTypes.string,
        selectedSentiment: PropTypes.string,
        selectedTab: PropTypes.string.isRequired,
        selectedTerm: PropTypes.objectOf(PropTypes.any),
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        tonalSentiment: PropTypes.objectOf(PropTypes.any),
        toggleDash: PropTypes.func.isRequired,
        sentimentType: PropTypes.string
    };

    static defaultProps = {
        eventId: null,
        equityId: null,
        keyMentions: [],
        localMonitorMatches: [],
        monitorMatches: [],
        pageId: null,
        passedStyles: {},
        searchResults: [],
        searchTerm: '',
        selectedNode: null,
        selectedSentiment: null,
        selectedTerm: null,
        tonalSentiment: null,
        sentimentType: null
    };

    renderHeader() {
        const { onSearch, onSearchFocus, searchTerm, styles, selectTab, selectedTab } = this.props;

        return (
            <Div styles={styles.header}>
                <TextInput
                    name="eventTranscriptSearch"
                    onChange={onSearch}
                    value={searchTerm}
                    styles={styles.searchBox}
                    icon="search03"
                    placeholder="Search transcript..."
                    clearable
                    onFocus={onSearchFocus}
                />
                <ContentTabs
                    styles={styles.tabs}
                    onSelect={selectTab}
                    options={[
                        { label: 'Key Insights', value: 'keyMentions' },
                        {
                            label: 'Highlights',
                            value: 'highlights',
                            permissions: [PERMISSIONS.featureBookmarks]
                        }
                        // { label: 'Ask Aiera', value: 'ask', permissions: [PERMISSIONS.featureEventsAskAiera] }
                    ]}
                    selectedOption={selectedTab}
                />
            </Div>
        );
    }

    renderSelectedTerm() {
        const { selectedTerm, styles, onTermSelect, theme } = this.props;
        const dashboardName = get(selectedTerm, 'dashboardName');
        const name = get(selectedTerm, 'name');
        const matches = get(selectedTerm, 'matches', []);
        const streamId = get(selectedTerm, 'streamId');

        return (
            <ErrorBoundary>
                <Div styles={styles.selectedTermWrapper}>
                    <Div styles={styles.selectedTerm} onClick={() => onTermSelect(undefined, null)}>
                        <Div styles={styles.selectedTermTitle}>
                            <Text styles={styles.selectedTermName} size={3} weight="medium">
                                {name}
                            </Text>
                            <Text className="selectedTermParent" size={0}>
                                {dashboardName}
                            </Text>
                        </Div>
                        <Div className="selectedTermBack" styles={styles.selectedTermBack}>
                            <Icon type="arrowLeft" color={theme.colors.black01} />
                        </Div>
                    </Div>
                </Div>
                {this.renderMatches(matches, streamId)}
            </ErrorBoundary>
        );
    }

    renderMatches(matches, streamId) {
        const { onNodeSelect, searchTerm, selectedNode } = this.props;
        // Matches can be from search results
        // or key mentions / local monitors etc
        if (matches && matches.length > 0) {
            return matches.map((data, index) => {
                const eventId = get(data, 'eventId', get(data, 'id')).toString();
                return (
                    <EventMatch
                        selectedNode={selectedNode}
                        streamId={streamId}
                        searchTerm={searchTerm}
                        onNodeSelect={onNodeSelect}
                        data={data}
                        total={matches.length}
                        index={index + 1}
                        key={eventId}
                    />
                );
            });
        }

        return null;
    }

    renderKeyMentions() {
        const {
            collapsedDashboards,
            equityId,
            eventId,
            isPublic,
            keyMentions,
            localMonitorMatches,
            monitorMatches,
            onTermSelect,
            selectedNode,
            selectedTerm,
            selectedSentiment,
            styles,
            toggleDash
        } = this.props;

        if (selectedTerm) {
            return this.renderSelectedTerm();
        }

        return (
            <Fragment>
                <WithPermission permissions={[PERMISSIONS.featureEventsTonalSentiment]}>
                    {this.renderSentiment()}
                </WithPermission>
                {!selectedSentiment && (
                    <ErrorBoundary>
                        {!isPublic && !!equityId && (
                            <ErrorBoundary>
                                <EventLocalMonitors
                                    eventId={eventId}
                                    equityId={equityId}
                                    onTermSelect={onTermSelect}
                                    selectedNode={selectedNode}
                                    streamMatches={localMonitorMatches}
                                />
                            </ErrorBoundary>
                        )}
                        {!isPublic && (
                            <ErrorBoundary>
                                <EventMentions
                                    collapsedDashboards={collapsedDashboards}
                                    dashboardStreamMatches={monitorMatches}
                                    mentionsTitle="Monitor Matches"
                                    onTermSelect={onTermSelect}
                                    selectedNode={selectedNode}
                                    toggleDash={toggleDash}
                                />
                            </ErrorBoundary>
                        )}
                        <EventMentions
                            onTermSelect={onTermSelect}
                            selectedNode={selectedNode}
                            mentionsTitle="Suggested"
                            streamMatches={keyMentions}
                        />
                        {(!keyMentions || keyMentions.length === 0) &&
                            (!monitorMatches || monitorMatches.length === 0) &&
                            (!localMonitorMatches || localMonitorMatches.length === 0) && (
                                <Div styles={styles.empty}>
                                    <Text size={3} styles={styles.emptyText}>
                                        No Key Mentions Found.
                                    </Text>
                                    <Text size={1} styles={styles.emptyText}>
                                        Key terms and phrases are surfaced <br />
                                        through your monitors, and determined <br />
                                        by our experts and NLP tooling.
                                    </Text>
                                </Div>
                            )}
                    </ErrorBoundary>
                )}
            </Fragment>
        );
    }

    renderSearchResults() {
        const { searchResults, searchTerm, styles } = this.props;

        if (searchResults && searchResults.length > 0) {
            return this.renderMatches(searchResults);
        }

        return (
            <Div styles={styles.empty}>
                <Text size={3} styles={styles.emptyText}>
                    {`No results for ${searchTerm}`}
                </Text>
            </Div>
        );
    }

    renderSentiment() {
        const {
            onNodeSelect,
            onSentimentSelect,
            selectedSentiment,
            selectedNode,
            styles,
            theme,
            tonalSentiment
        } = this.props;
        if (!Object.keys(tonalSentiment || {}).length) {
            return null;
        }
        if (selectedSentiment) {
            const matches = orderBy(
                tonalSentiment[selectedSentiment],
                m => (m.tonalScore ? Math.abs(m.tonalScore) : Math.abs(m.textualScore)),
                ['desc', 'desc']
            );

            return (
                <ErrorBoundary>
                    <Div styles={styles.selectedTermWrapper}>
                        <Div onClick={() => onSentimentSelect(null)} styles={styles.selectedTerm}>
                            <Div>
                                <Text size={3} styles={styles.selectedTermName} weight="medium">
                                    {selectedSentiment}
                                </Text>
                                <Text className="selectedTermParent" size={0}>
                                    Audio Sentiment
                                </Text>
                            </Div>
                            <Div className="selectedTermBack" styles={styles.selectedTermBack}>
                                <Icon type="arrowLeft" color={theme.colors.black01} />
                            </Div>
                        </Div>
                    </Div>
                    {selectedSentiment === 'Divergence' && (
                        <Notice
                            type="info"
                            richContent
                            onClick={() => onSentimentSelect(null)}
                            styles={styles.divergenceBlurb}
                        >
                            <Text size={3}>
                                The difference between text sentiment & audio sentiment, is greater than the standard
                                deviation for the speaker.
                            </Text>
                        </Notice>
                    )}

                    {matches.map((match, idx) => {
                        const { itemId, textualScore, tonalScore } = match;
                        const isSelected = selectedNode;
                        let tonalSentimentStrength;
                        let textSentimentStrength;

                        if (Math.abs(tonalScore) >= 0 && Math.abs(tonalScore) <= 1.5) {
                            tonalSentimentStrength = 'Low';
                        } else if (Math.abs(tonalScore) > 1.5 && Math.abs(tonalScore) <= 3.5) {
                            tonalSentimentStrength = 'Medium';
                        } else if (Math.abs(tonalScore) > 3.5) {
                            tonalSentimentStrength = 'High';
                        }

                        if (Math.abs(textualScore) >= 0 && Math.abs(textualScore) <= 1.5) {
                            textSentimentStrength = 'Low';
                        } else if (Math.abs(textualScore) > 1.5 && Math.abs(textualScore) <= 3.5) {
                            textSentimentStrength = 'Medium';
                        } else if (Math.abs(textualScore) > 3.5) {
                            textSentimentStrength = 'High';
                        }

                        let sentimentDisplay;
                        let sentimentDisplay2;

                        if (tonalScore) {
                            sentimentDisplay = (
                                <Div styles={styles.sentimentScoreRow}>
                                    <Text size={1} weight="medium" styles={styles.sentimentTitle}>
                                        Audio
                                    </Text>
                                    <Div styles={styles.sentimentColumn}>
                                        {tonalScore > 0 && (
                                            <Text size={1} weight="medium" styles={{ color: theme.colors.green06 }}>
                                                {' '}
                                                Positive
                                            </Text>
                                        )}
                                        {tonalScore < 0 && (
                                            <Text size={1} weight="medium" styles={{ color: theme.colors.red09 }}>
                                                {' '}
                                                Negative
                                            </Text>
                                        )}
                                    </Div>

                                    <Div styles={styles.sentimentColumn}>
                                        <Text size={1}>{tonalSentimentStrength}</Text>
                                    </Div>

                                    <TonalGraph
                                        sentimentValue={tonalScore}
                                        sentimentStrength={tonalSentimentStrength}
                                        styles={styles.sentimentTonalGraph}
                                    />
                                </Div>
                            );
                        }

                        if (textualScore) {
                            sentimentDisplay2 = (
                                <Div styles={styles.sentimentScoreRow}>
                                    <Text size={1} weight="medium" styles={styles.sentimentTitle}>
                                        Textual
                                    </Text>
                                    <Div styles={styles.sentimentColumn}>
                                        {/** Setting text based on tonalScore */}
                                        {textualScore > 0 && (
                                            <Text size={1} weight="medium" style={{ color: theme.colors.green06 }}>
                                                {' '}
                                                Positive
                                            </Text>
                                        )}
                                        {textualScore < 0 && (
                                            <Text size={1} weight="medium" style={{ color: theme.colors.red09 }}>
                                                {' '}
                                                Negative
                                            </Text>
                                        )}
                                    </Div>

                                    {/** Outputting sentiment strength text */}
                                    <Div styles={styles.sentimentColumn}>
                                        <Text size={1}>{textSentimentStrength}</Text>
                                    </Div>
                                    <TonalGraph
                                        sentimentValue={textualScore}
                                        sentimentStrength={textSentimentStrength}
                                        styles={styles.sentimentTonalGraph}
                                    />
                                </Div>
                            );
                        }

                        return (
                            <ErrorBoundary key={itemId}>
                                <EventMatch
                                    selectedNode={selectedNode}
                                    name="tonal-event-match"
                                    data={match}
                                    total={matches.length}
                                    index={idx + 1}
                                    onNodeSelect={onNodeSelect}
                                >
                                    <Div styles={isSelected ? styles.sentimentScoreSelected : styles.sentimentScore}>
                                        {sentimentDisplay}
                                        {sentimentDisplay2}
                                    </Div>
                                </EventMatch>
                            </ErrorBoundary>
                        );
                    })}
                </ErrorBoundary>
            );
        }

        return (
            <ErrorBoundary>
                <Div styles={styles.sentimentContainer}>
                    <Div styles={styles.sentimentHeader}>
                        <Text size={3} weight="medium">
                            Speaker Sentiment
                        </Text>
                        <FeaturePreview growLeft styles={styles.featurePreview} xOffset={-46} yOffset={-17} />
                    </Div>
                    <ModuleTable alternate wrapperStyles={styles.table}>
                        {ORDERED_SENTIMENT_TYPES.map(sentimentType =>
                            tonalSentiment[sentimentType] ? (
                                <ModuleTr
                                    data-tname="tonal-category"
                                    key={sentimentType}
                                    onClick={() => onSentimentSelect(sentimentType)}
                                    styles={styles.sentimentRow}
                                >
                                    <ModuleTd className="sentiment-type" styles={styles.sentimentType}>
                                        <Text size={3}>{titleize(sentimentType)}</Text>
                                    </ModuleTd>
                                    <ModuleTd styles={styles.sentimentCount}>
                                        <Text size={1}>{tonalSentiment[sentimentType].length}</Text>
                                    </ModuleTd>
                                </ModuleTr>
                            ) : null
                        )}
                    </ModuleTable>
                </Div>
            </ErrorBoundary>
        );
    }

    render() {
        const {
            eventId,
            highlightsFilterKey,
            highlightsSortKey,
            keyMentions,
            loading,
            localMonitorMatches,
            monitorMatches,
            onNodeSelect,
            pageId,
            passedStyles,
            scrollRef,
            searchResults,
            selectedTab,
            selectedNode,
            setHighlightsFilter,
            setHighlightsSort,
            shareBookmarks,
            styles
        } = this.props;
        const matchDict = {
            keyMentions: [...keyMentions, ...monitorMatches, ...localMonitorMatches],
            searchResults
        };
        // We don't care about matches in sentiment tab,
        // so show the logo while the data is loading
        const hasNoMatches =
            ((selectedTab === 'keyMentions' || selectedTab === 'searchResults') &&
                (!matchDict[selectedTab] || matchDict[selectedTab].length === 0)) ||
            selectedTab === 'sentiment';
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} className="print-hide">
                {this.renderHeader()}
                <WithPermission permissions={[PERMISSIONS.featureEventsAskAiera]}>
                    <Div styles={styles.gptBlock}>
                        <Text weight="medium" styles={styles.gptTitle}>
                            AieraGPT now available in beta!
                        </Text>
                        <Text>If you have ChatGPT+, you can ask about this event or company, & much more.</Text>
                        <ExternalLink
                            data-tname="AieraGPT Event Link"
                            href={`https://chat.openai.com/g/g-FTB0Gd6uU-aiera?q=summarize+event+${eventId}`}
                            styles={styles.gptLink}
                        >
                            Try Now
                        </ExternalLink>
                    </Div>
                </WithPermission>
                {loading && hasNoMatches ? (
                    <Div styles={styles.loader}>
                        <LoaderLogo height={60} />
                    </Div>
                ) : (
                    <Fragment>
                        {selectedTab === 'highlights' ? (
                            <EventHighlights
                                pageId={pageId}
                                audioCallId={eventId}
                                eventId={eventId}
                                filterKey={highlightsFilterKey}
                                onNodeSelect={onNodeSelect}
                                setFilter={setHighlightsFilter}
                                setSort={setHighlightsSort}
                                shareBookmarks={shareBookmarks}
                                sortKey={highlightsSortKey}
                            />
                        ) : (
                            <Div styles={styles.scrollContainer} ref={scrollRef}>
                                {selectedTab === 'ask' && (
                                    <EventAskAiera
                                        eventId={eventId}
                                        onNodeSelect={onNodeSelect}
                                        selectedNode={selectedNode}
                                    />
                                )}
                                {selectedTab === 'keyMentions' && this.renderKeyMentions()}
                                {selectedTab === 'searchResults' && this.renderSearchResults()}
                                {selectedTab === 'sentiment' && (
                                    <WithPermission permissions={[PERMISSIONS.featureEventsTonalSentiment]}>
                                        {this.renderSentiment()}
                                    </WithPermission>
                                )}
                            </Div>
                        )}
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const EventSidebarUI = compose(withStyleSheet(styleSheet))(EventSidebar);
