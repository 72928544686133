import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { CardHeaderUI } from './ui';

export class CardHeader extends PureComponent {
    static displayName = 'CardHeaderContainer';

    /*
     *
     * We discussed these names a lot.
     * They describe the card structure,
     * not the content being passed in.
     * <context><secondaryContext>
     * <title>
     * <subtitle>
     * <subtext>
     *
     */
    static propTypes = {
        context: PropTypes.string,
        iconUrl: PropTypes.string,
        iconText: PropTypes.string,
        secondaryContext: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        subtitle: PropTypes.string,
        title: PropTypes.string,
        truncateTitle: PropTypes.bool
    };

    static defaultProps = {
        context: '',
        iconUrl: undefined,
        iconText: undefined,
        secondaryContext: '',
        styles: {},
        subtext: '',
        subtitle: '',
        title: '',
        truncateTitle: false
    };

    render() {
        const {
            styles,
            title,
            context,
            iconUrl,
            iconText,
            secondaryContext,
            subtitle,
            subtext,
            truncateTitle
        } = this.props;
        return (
            <CardHeaderUI
                context={context}
                iconUrl={iconUrl}
                iconText={iconText}
                secondaryContext={secondaryContext}
                styles={styles}
                subtext={subtext}
                subtitle={subtitle}
                title={title}
                truncateTitle={truncateTitle}
            />
        );
    }
}

export const CardHeaderContainer = compose()(CardHeader);
