export const styleSheet = theme => ({
    container: {
        borderTop: `1px solid ${theme.colors.gray01}`,
        margin: '25px 35px'
    },
    checkbox: {
        marginTop: theme.margins.margin00,
        p: {
            color: 'inherit',
            fontSize: theme.fonts.fontSizes.size01,
            letterSpacing: 'normal',
            textTransform: 'unset'
        },
        '.checkbox03': {
            marginRight: 5
        },
        '.checkboxChecked03': {
            marginRight: 5
        }
    },
    checkbox02: {
        marginTop: theme.margins.margin01,
        p: {
            color: 'inherit',
            flex: '0 1 90%',
            fontSize: theme.fonts.fontSizes.size03,
            letterSpacing: 'normal',
            textTransform: 'unset'
        },
        '.rowBox': {
            border: `1px solid ${theme.colors.gray04}`,
            marginRight: theme.margins.margin00
        },
        '.rowBoxChecked': {
            marginRight: theme.margins.margin00
        },
        ':hover': {
            '.rowBox': {
                border: `1px solid ${theme.colors.gray06}`
            }
        }
    },
    checkbox02Error: {
        '> p': {
            color: theme.colors.red01
        },
        '.rowBox': {
            border: `1px solid ${theme.colors.red01}`,
            boxShadow: `0 0 5px 1px ${theme.colors.red07}`,
            marginRight: theme.margins.margin00
        },
        '.rowBoxChecked': {
            marginRight: theme.margins.margin00
        },
        ':hover': {
            '.rowBox': {
                boxShadow: 'unset',
                marginRight: theme.margins.margin00
            },
            '.rowBoxChecked': {
                marginRight: theme.margins.margin00
            }
        }
    },
    formRow: {
        paddingTop: 30
    },
    heading: {
        padding: '25px 0 5px'
    },
    input: {
        ...theme.content.inputErrorSmall
    },
    optionDescription: {
        color: theme.colors.gray04,
        lineHeight: '1.3em',
        paddingTop: 6
    }
});
