import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { get } from 'utils';

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                query withEventSummary($eventId: ID) {
                    events(eventIds: [$eventId]) {
                        id
                        summarizations {
                            audioClip
                            videoClip
                            id
                            modelType
                            summary
                            summaryType
                            title
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    const summarizations = get(data, 'events[0].summarizations', []);
                    // Prioritize "constrained" model type, default to "zeroshot"
                    const constrained = summarizations.filter(({ modelType }) => modelType === 'constrained');
                    const summaries =
                        constrained.length > 0
                            ? constrained
                            : summarizations.filter(({ modelType }) => modelType === 'zeroshot');
                    const everything = summaries.find(({ summaryType }) => summaryType === 'everything');
                    const pres = summaries.find(({ summaryType }) => summaryType === 'presentation');
                    const qa = summaries.find(({ summaryType }) => summaryType === 'q_and_a');
                    const summary = everything || pres || qa;
                    const summaryAudioUrl = get(summary, 'audioClip');
                    const summaryTitle = get(summary, 'title', '');
                    const summaryText = get(summary, 'summary', []);
                    const loadingSummary = get(data, 'loading');
                    const hasSummary = !loadingSummary && summaryText.length > 0;

                    return {
                        hasSummary,
                        summaryAudioUrl,
                        summaryTitle,
                        summaryText
                    };
                },
                options: ({ eventId, audioCallId }) => ({
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        eventId: eventId || audioCallId
                    }
                })
            }
        ),
        graphql(
            gql`
                mutation ReconnectEventTranscription($eventId: ID!) {
                    reconnectEventTranscription(eventId: $eventId) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, audioCallId } = ownProps;
                    return {
                        reconnectTranscription: onSuccess =>
                            mutate({
                                variables: { eventId: audioCallId }
                            })
                                .then(() => {
                                    onSuccess();
                                    setStatusBanner('Reconnecting Transcription...');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error reconnecting: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation ReportEventIssue($eventId: ID!, $issue: String!) {
                    reportEventIssue(eventId: $eventId, issue: $issue) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, audioCallId } = ownProps;
                    return {
                        reportIssue: (issue, onSuccess) =>
                            mutate({
                                variables: { eventId: audioCallId, issue }
                            })
                                .then(() => {
                                    onSuccess();
                                    setStatusBanner('Reporting Issue...');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error reporting issue: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation RetryEventConnection($eventId: ID!) {
                    retryEventConnection(eventId: $eventId) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, audioCallId } = ownProps;
                    return {
                        retryConnection: onSuccess =>
                            mutate({
                                variables: { eventId: audioCallId }
                            })
                                .then(() => {
                                    onSuccess();
                                    setStatusBanner('Retrying connection...');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error retrying connection: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
