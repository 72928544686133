import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { withUrlContext } from 'hoc/url';
import { withEventDetails } from 'graphql/audioCalls';
import { get, generateModalId } from 'utils';
import { EventNoTranscriptUI } from './ui';
import { withData } from './data';

export class EventNoTranscript extends PureComponent {
    static displayName = 'EventNoTranscriptContainer';

    static propTypes = {
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        pathname: PropTypes.string.isRequired,
        tabId: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.openIcalModal = this.openIcalModal.bind(this);
    }

    openIcalModal({ value }) {
        const { history, pathname, tabId } = this.props;
        history.push(generateModalId({ pathname, tabId, id: value, type: 'watchlistIcal' }));
    }

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <EventNoTranscriptUI {...rest} openIcalModal={this.openIcalModal} />;
    }
}

export const EventNoTranscriptContainer = compose(
    withUrlContext(['audioCallId', 'history', 'pathname', 'tabId']),
    withEventDetails({ fetchPolicy: 'cache-only' }),
    withData(),
    withProps(({ audioCall }) => {
        const events = get(audioCall, 'events');
        const inProgress = get(audioCall, 'isLive');
        return {
            ...audioCall,
            inProgress,
            waiting: inProgress && !events.length
        };
    })
)(EventNoTranscript);
