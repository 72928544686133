import gql from 'graphql-tag';
import { compose } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withDeletedCheck {
                    currentUser {
                        id
                        userId
                        organization {
                            id
                            pusherToken
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    userId: get(data, 'currentUser.userId'),
                    orgPusherToken: get(data, 'currentUser.organization.pusherToken')
                }),
                options: {
                    fetchPolicy: 'cache-only'
                }
            }
        )
    );
