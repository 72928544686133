/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import MediaQuery from 'react-responsive';
import XDate from 'xdate';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { WithPreference } from 'components/WithPreference';
import { PREFERENCES } from 'consts';
import { get, generateTabURL, toDurationString } from 'utils';

import { styleSheet } from './stylesheet';

class MediaControls extends PureComponent {
    static propTypes = {
        audioCall: PropTypes.objectOf(PropTypes.any),
        dragTime: PropTypes.string,
        location: PropTypes.objectOf(PropTypes.any),
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        onDragPlayerKnob: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        seekBack: PropTypes.func.isRequired,
        seekForward: PropTypes.func.isRequired,
        seekToClick: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        stopAudio: PropTypes.func.isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        timelineRef: PropTypes.objectOf(PropTypes.any).isRequired,
        togglePlaybackRate: PropTypes.func.isRequired
    };

    static defaultProps = {
        audioCall: {},
        passedStyles: {},
        mediaPlayer: null,
        location: null,
        dragTime: null
    };

    renderTimeline() {
        const {
            mediaPlayer,
            dragTime,
            seekBack,
            seekForward,
            styles,
            theme,
            timelineRef,
            onDragPlayerKnob,
            seekToClick
        } = this.props;
        const { currentTime } = mediaPlayer;
        const { duration } = mediaPlayer;
        let completion = parseInt((currentTime * 100) / duration, 10);
        completion = (completion > 100 ? 100 : completion).toFixed(2);
        const progressBarStyle = {
            ...styles.progressBar,
            width: `calc(${completion}%)`
        };

        const timelineStyles = { ...styles.timelineWrapper };

        if (mediaPlayer.isActive) {
            progressBarStyle.borderRadius = 4;
        } else {
            progressBarStyle.width = 0;
            timelineStyles.cursor = 'default';
        }

        return (
            <Div styles={styles.player}>
                {mediaPlayer.seekable && (
                    <Fragment>
                        <Div onClick={seekBack} styles={styles.seekBack}>
                            <Icon type="back15" color={theme.colors.gray04} />
                        </Div>
                        <Div onClick={seekForward} styles={styles.seekForward}>
                            <Icon type="forward15" color={theme.colors.gray04} />
                        </Div>
                    </Fragment>
                )}
                <Div
                    onClick={mediaPlayer.seekable ? () => mediaPlayer.seek(0) : null}
                    styles={mediaPlayer.seekable && styles.cursor}
                >
                    <Text size={0} styles={styles.startTime}>
                        {toDurationString(dragTime || mediaPlayer.currentTime)}
                    </Text>
                </Div>
                <Div onClick={seekToClick} styles={timelineStyles}>
                    <Div ref={timelineRef} className="player-timeline" styles={styles.timeline}>
                        <Div styles={progressBarStyle} />
                        {mediaPlayer.seekable ? (
                            <Div
                                onTouchStart={onDragPlayerKnob}
                                className="knob"
                                styles={{ ...styles.knob, left: `${completion}%` }}
                            />
                        ) : null}
                    </Div>
                </Div>
                <Div>
                    <Text size={0} styles={styles.endTime}>
                        {toDurationString(mediaPlayer.duration)}
                    </Text>
                </Div>
            </Div>
        );
    }

    render() {
        const {
            styles,
            passedStyles,
            location,
            theme,
            mediaPlayer,
            togglePlaybackRate,
            stopAudio,
            audioCall
        } = this.props;

        const tabId = get(mediaPlayer, 'metaData.tabId');
        const isAtEnd = mediaPlayer.duration - mediaPlayer.currentTime < 5;
        const ticker = get(audioCall, 'equity.localTicker', '');
        const callType = get(audioCall, 'callType', '').replace(/_/g, ' ');
        const callDate = get(audioCall, 'callDate');
        const formattedDate = callDate ? new XDate(callDate).toString('MMM dd, yyyy') : '';
        const canStream =
            mediaPlayer.canListen && mediaPlayer.canPlay && mediaPlayer && !mediaPlayer.audio.failedToLoad;

        return (
            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                {hasPref => (
                    <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                        {matches =>
                            hasPref || matches ? (
                                <Draggable bounds=".App">
                                    <Div styles={{ ...styles.container, ...passedStyles }} className="media-controls">
                                        <Div styles={styles.header}>
                                            {canStream && (
                                                <Div
                                                    styles={styles.audioControl}
                                                    onClick={
                                                        mediaPlayer.listening ? mediaPlayer.pause : mediaPlayer.listen
                                                    }
                                                >
                                                    <Icon
                                                        className={mediaPlayer.listening ? 'pauseIcon' : 'playIcon'}
                                                        color={theme.colors.white01}
                                                        type={mediaPlayer.listening ? 'pause' : 'play'}
                                                    />
                                                </Div>
                                            )}
                                            <Div styles={styles.event}>
                                                <Link
                                                    to={generateTabURL({
                                                        location,
                                                        tabId,
                                                        tabIndex: matches ? 0 : null
                                                    })}
                                                >
                                                    <Div styles={styles.eventHeader}>
                                                        <Text size={3} styles={styles.ticker} weight="medium">
                                                            {ticker}
                                                        </Text>
                                                        <Text size={1} capitalize styles={styles.callType}>
                                                            {callType ? callType.replace(/_/g, ' ') : 'Loading...'}
                                                        </Text>
                                                    </Div>
                                                    <Text styles={styles.date}>{formattedDate}</Text>
                                                </Link>
                                            </Div>
                                            {canStream && (
                                                <Div
                                                    styles={styles.speed}
                                                    onClick={isAtEnd ? undefined : togglePlaybackRate}
                                                >
                                                    <Text
                                                        size={1}
                                                        weight="medium"
                                                        styles={mediaPlayer.playbackRate > 1 ? styles.speedColor : ``}
                                                    >
                                                        {mediaPlayer.playbackRate > 0
                                                            ? `${parseFloat(mediaPlayer.playbackRate).toFixed(2)}x`
                                                            : `...`}
                                                    </Text>
                                                </Div>
                                            )}
                                            {canStream && mediaPlayer.isLive && (
                                                <Div
                                                    styles={styles.live}
                                                    className="go-live-button"
                                                    data-tname={isAtEnd ? 'player-is-live' : 'player-go-live'}
                                                    onClick={mediaPlayer.goLive}
                                                >
                                                    <Text weight="medium" size={1}>
                                                        {!isAtEnd ? 'Go Live' : 'Live'}
                                                    </Text>
                                                </Div>
                                            )}
                                            <Div styles={styles.closeButton} onClick={stopAudio}>
                                                <Icon type="xBold" color={theme.colors.gray04} />
                                            </Div>
                                        </Div>
                                        {canStream && this.renderTimeline()}
                                    </Div>
                                </Draggable>
                            ) : null
                        }
                    </MediaQuery>
                )}
            </WithPreference>
        );
    }
}

export const MediaControlsUI = compose(withStyleSheet(styleSheet))(MediaControls);
