import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { BoxTable } from 'components/BoxTable';
import { BoxTd } from 'components/BoxTd';
import { BoxTh } from 'components/BoxTh';
import { BoxTr } from 'components/BoxTr';
import { ContentTabs } from 'components/ContentTabs';
import { Div } from 'components/Div';
import { EmptyContent } from 'components/EmptyContent';
import { LoaderLogo } from 'components/LoaderLogo';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class EquityFinancialsModal extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
        currency: PropTypes.objectOf(PropTypes.any),
        currentTab: PropTypes.string.isRequired,
        equity: PropTypes.objectOf(PropTypes.any),
        financials: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSelectTab: PropTypes.func.isRequired,
        quarters: PropTypes.arrayOf(PropTypes.string),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        currency: null,
        equity: null,
        financials: null,
        quarters: [],
        passedStyles: {}
    };

    renderTabs() {
        const { categories, currentTab, onSelectTab, styles } = this.props;
        return (
            <ContentTabs options={categories} onSelect={onSelectTab} selectedOption={currentTab} styles={styles.tabs} />
        );
    }

    renderTable() {
        const { currency, currentTab, financials, quarters, styles } = this.props;
        const firstAnnual = quarters.find(q => !q.includes('Q'));
        const separatorIndex = quarters.indexOf(firstAnnual);
        const viewingKPIs = currentTab === 'KPIs';
        return (
            <Fragment>
                <BoxTable styles={viewingKPIs ? styles.table : styles.tableWithNote}>
                    <BoxTr styles={styles.tableHeaderRow}>
                        <BoxTh>
                            {!viewingKPIs && (
                                <Text size={0} styles={styles.displayUnits}>
                                    (in millions of {get(currency, 'currencyCode', 'USD')}, except per share items)
                                </Text>
                            )}
                        </BoxTh>
                        {quarters.map((quarter, qIdx) => (
                            <BoxTh
                                alignRight
                                key={quarter}
                                styles={separatorIndex === qIdx ? styles.tableHeaderBorder : styles.tableHeader}
                            >
                                {quarter}
                            </BoxTh>
                        ))}
                    </BoxTr>
                    {Object.keys(financials).map(section => (
                        <Fragment key={section}>
                            <BoxTr>
                                <BoxTd
                                    colSpan={separatorIndex > 0 ? separatorIndex + 1 : quarters.length + 1}
                                    styles={styles.sectionHeader}
                                >
                                    {section}
                                </BoxTd>
                                <BoxTd
                                    colSpan={separatorIndex > 0 ? quarters.length - separatorIndex : 0}
                                    styles={separatorIndex > 0 ? styles.sectionHeaderBorder : styles.sectionHeader}
                                />
                            </BoxTr>
                            {financials[section].map(({ key, actuals, title }) => (
                                <BoxTr key={key}>
                                    <BoxTd>{title}</BoxTd>
                                    {quarters.map((q, vIdx) => {
                                        const a = actuals.find(({ label }) => label === q);
                                        return a ? (
                                            <BoxTd
                                                alignRight
                                                key={`${section}-${a.key}-${a.quarter}-${a.year}`}
                                                styles={separatorIndex === vIdx ? styles.cellBorder : undefined}
                                            >
                                                {a.value}
                                            </BoxTd>
                                        ) : (
                                            <BoxTd
                                                alignRight
                                                key={`${section}-${q}-not-found`}
                                                styles={separatorIndex === vIdx ? styles.cellBorder : undefined}
                                            >
                                                -
                                            </BoxTd>
                                        );
                                    })}
                                </BoxTr>
                            ))}
                        </Fragment>
                    ))}
                </BoxTable>
            </Fragment>
        );
    }

    render() {
        const { equity, financials, loading, onClose, passedStyles, styles } = this.props;
        return (
            <Modal
                isOpen
                onModalClose={onClose}
                styles={{ ...styles.container, ...passedStyles }}
                title={`${get(equity, 'commonName', 'Equity')} Financials`}
            >
                {loading && <LoaderLogo styles={styles.loading} />}
                {!loading && financials && (
                    <Div styles={styles.financialsContainer}>
                        {this.renderTabs()}
                        {this.renderTable()}
                        <Text size={0} styles={styles.disclaimer}>
                            *Data provided by FactSet
                        </Text>
                    </Div>
                )}
                {!loading && !financials && (
                    <EmptyContent heading="We're still gathering information about this equity" styles={styles.empty}>
                        Please check back soon...
                    </EmptyContent>
                )}
            </Modal>
        );
    }
}

export const EquityFinancialsModalUI = compose(withStyleSheet(styleSheet))(EquityFinancialsModal);
