const WEB_WIDGET_HIDE = 'WEB_WIDGET_HIDE';
const WEB_WIDGET_SHOW = 'WEB_WIDGET_SHOW';

export const types = {
    WEB_WIDGET_HIDE,
    WEB_WIDGET_SHOW
};

export function webWidgetHide() {
    return {
        type: WEB_WIDGET_HIDE
    };
}

export function webWidgetShow() {
    return {
        type: WEB_WIDGET_SHOW
    };
}
