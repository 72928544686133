import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { compose, withStateHandlers } from 'recompose';
import { withData } from './data';
import { BookmarkTagsAutocompleteUI } from './ui';

export class BookmarkTagsAutocomplete extends PureComponent {
    static displayName = 'BookmarkTagsAutocompleteContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        clearOnSelect: PropTypes.bool,
        icon: PropTypes.string,
        id: PropTypes.string,
        initialSearchTerm: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        multi: PropTypes.bool,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onSearch: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        suggestedTags: PropTypes.arrayOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.any),
        updateSearchTerm: PropTypes.func.isRequired,
        useTags: PropTypes.bool,
        value: PropTypes.arrayOf(PropTypes.any),
        wrapTags: PropTypes.bool
    };

    static defaultProps = {
        autoFocus: false,
        clearOnSelect: false,
        icon: undefined,
        id: undefined,
        initialSearchTerm: undefined,
        label: undefined,
        loading: false,
        multi: false,
        name: 'bookmarkTagsAutocomplete',
        onChange: undefined,
        onSearch: undefined,
        placeholder: 'Search tags by name, equity, or event...',
        styles: {},
        suggestedTags: undefined,
        tags: [],
        useTags: false,
        value: undefined,
        wrapTags: false
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSuggestedTagClick = this.onSuggestedTagClick.bind(this);

        this.state = {
            suggestedTags: props.suggestedTags
        };
    }

    componentDidUpdate({ suggestedTags: prevTags }) {
        const { suggestedTags: suggestedTagsState } = this.state;
        const { loading, suggestedTags } = this.props;
        if (!loading && suggestedTags && (!suggestedTagsState || !isEqual(prevTags, suggestedTags))) {
            this.setState({ suggestedTags });
        }
    }

    onChange({ event, value }) {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange({ event, name, value });
        }
    }

    onSearch({ value }) {
        const { onSearch, updateSearchTerm } = this.props;
        if (onSearch) {
            onSearch({ value });
        }
        updateSearchTerm(value);
    }

    onSuggestedTagClick(tag) {
        const { name, onChange, value } = this.props;
        if (onChange) {
            const tagsSet = new Set(value || []);
            if (!tagsSet.has(tag)) {
                tagsSet.add(tag);
            }
            onChange({
                name,
                value: [...tagsSet]
            });
        }
    }

    render() {
        const { suggestedTags } = this.state;
        const {
            autoFocus,
            clearOnSelect,
            icon,
            id,
            initialSearchTerm,
            label,
            loading,
            multi,
            name,
            placeholder,
            styles,
            tags,
            useTags,
            value,
            wrapTags
        } = this.props;
        return (
            <BookmarkTagsAutocompleteUI
                autoFocus={autoFocus}
                clearOnSelect={clearOnSelect}
                icon={icon}
                id={id}
                initialSearchTerm={initialSearchTerm}
                label={label}
                loading={loading}
                multi={multi}
                name={name}
                onChange={this.onChange}
                onSearch={this.onSearch}
                onSuggestedTagClick={this.onSuggestedTagClick}
                options={tags}
                placeholder={placeholder}
                styles={styles}
                suggestedTags={suggestedTags}
                useTags={useTags}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const BookmarkTagsAutocompleteContainer = compose(
    withStateHandlers(() => ({ searchTerm: '' }), {
        updateSearchTerm: () => searchTerm => ({ searchTerm })
    }),
    withData()
)(BookmarkTagsAutocomplete);
