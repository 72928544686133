/* eslint-disable no-underscore-dangle */
class PopoutManager {
    constructor() {
        this.windows = [];
    }

    checkWindows() {
        this.windows = this.windows.filter(w => !w.window.closed);
    }

    windowCount() {
        this.checkWindows();
        return this.windows.length;
    }

    open(options) {
        const { url, name, height, width } = options;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.width;
        const top = 0;
        const stackAdjustment = this.windowCount() * 50;
        const left = screenWidth - width - stackAdjustment;

        this.windows.push({
            ...options,
            top,
            left,
            window: window.open(url, name, `height=${height || screenHeight},width=${width},left=${left},top=${top}`)
        });

        // Generally this isn't going to actually work because browsers block it so ads can't
        // do annoying things, but if the rare case it's allowed or the user overrode those settings
        // we can try to bring all the popouts back to the front, with th emost recent one focused.
        window.blur();
        this.windows.forEach(w => {
            w.window.focus();
        });
    }
}

export const popoutManager = new PopoutManager();
window.popoutManager = popoutManager;
