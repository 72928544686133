import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { theme } from 'styles';

export function withStyleSheet(componentStyleSheet) {
    // Return a function that accepts a component
    // and then returns that component with
    // some decorated props ya herd
    return BaseComponent =>
        class StyledComponent extends Component {
            static propTypes = {
                styles: PropTypes.objectOf(PropTypes.any)
            };

            static defaultProps = {
                styles: {}
            };

            constructor(props) {
                super(props);
                this.getStyles = memoize(componentStyleSheet || (() => null));
            }

            render() {
                const { styles } = this.props;

                const numArgs = componentStyleSheet ? componentStyleSheet.length : 0;
                return (
                    <BaseComponent
                        theme={theme}
                        {...this.props}
                        passedStyles={styles}
                        styles={numArgs > 1 ? this.getStyles(theme, this.props) : this.getStyles(theme)}
                    />
                );
            }
        };
}
