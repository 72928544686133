export const styleSheet = theme => ({
    addTagButton: {
        flexShrink: 0,
        backgroundColor: theme.colors.white03,
        height: 30,
        padding: '0 8px',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        p: {
            marginLeft: 6,
            textTransform: 'capitalize'
        },
        ':hover': {
            backgroundColor: theme.colors.gray03
        },
        ':active': {
            backgroundColor: theme.colors.gray01
        }
    },
    addTagCircle: {
        flexShrink: 0,
        ...theme.content.blueButton,
        height: 18,
        width: 18,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    archiveBlock: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        height: 30,
        padding: '0 8px',
        borderRadius: 6,
        backgroundColor: theme.colors.white03,
        cursor: 'pointer',
        svg: {
            height: 16,
            marginRight: 6
        },
        ':hover': {
            backgroundColor: theme.colors.gray03
        },
        ':active': {
            backgroundColor: theme.colors.gray01
        }
    },
    container: {
        display: 'flex'
    },
    hasTagsButton: {
        height: 30,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start'
    },
    tag: {
        display: 'flex',
        cursor: 'pointer',
        color: theme.colors.gray06,
        marginRight: 4,
        marginBottom: 2,
        marginTop: 2,
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: 15,
        a: {
            height: 18,
            display: 'flex',
            alignItems: 'center',
            padding: '0 6px',
            textDecoration: 'none',
            color: theme.colors.gray06,
            ':hover': {
                color: theme.colors.blue08
            }
        },
        '.tagRemove': {
            height: 10,
            width: 10,
            marginRight: 4,
            position: 'relative',
            svg: {
                cursor: 'pointer',
                height: 8,
                width: 8
            }
        },
        ':hover': {
            svg: {
                ':hover': {
                    g: {
                        fill: theme.colors.blue08
                    }
                }
            }
        }
    },
    tags: {
        flexWrap: 'wrap',
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center'
    },
    tagsBlock: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'none'
        }
    },
    starBlock: {
        flexShrink: 0,
        height: 30,
        padding: '0 8px',
        borderRadius: 6,
        backgroundColor: theme.colors.white03,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        svg: {
            marginRight: 6,
            height: 16
        },
        ':hover': {
            backgroundColor: theme.colors.gray03
        },
        ':active': {
            backgroundColor: theme.colors.gray01
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            marginRight: 'auto'
        }
    }
});
