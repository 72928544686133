import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class LoaderDots extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        light: PropTypes.bool.isRequired,
        gray: PropTypes.bool.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, light, gray } = this.props;
        let containerStyles = styles.container;

        if (light) {
            containerStyles = styles.containerLight;
        }

        if (gray) {
            containerStyles = styles.containerGray;
        }

        return (
            <Div styles={{ ...containerStyles, ...passedStyles }}>
                <Div className="bounce1 dot" />
                <Div className="bounce2 dot" />
                <Div className="bounce3 dot" />
                <Div className="bounce4 dot" />
            </Div>
        );
    }
}

export const LoaderDotsUI = compose(withStyleSheet(styleSheet))(LoaderDots);
