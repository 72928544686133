import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import flatten from 'lodash/flatten';
import { compose } from 'recompose';
import { withData } from './data';
import { SourcesAutocompleteUI } from './ui';

function formatValue(value) {
    return value ? flatten(Object.keys(value).map(type => value[type].map(v => `${type}:${v}`))) : null;
}

export class SourcesAutocomplete extends PureComponent {
    static displayName = 'SourcesAutocompleteContainer';

    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        value: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        name: 'sectorAutocomplete',
        onChange: null,
        placeholder: 'Filter by Sector',
        options: [],
        styles: {},
        value: null
    };

    constructor(props) {
        super(props);

        this.formatValue = memoize(formatValue);
        this.onChange = this.onChange.bind(this);
    }

    onChange({ event, value }) {
        const { name, onChange } = this.props;
        if (onChange) {
            let newValue = null;
            if (value) {
                newValue = {};
                value.forEach(v => {
                    const [type, id] = v.split(':');
                    newValue[type] = newValue[type] || [];
                    newValue[type].push(id);
                });
            }
            onChange({
                event,
                name,
                value: newValue
            });
        }
    }

    render() {
        const { name, options, placeholder, styles, value } = this.props;
        return (
            <SourcesAutocompleteUI
                name={name}
                onChange={this.onChange}
                options={options}
                placeholder={placeholder}
                styles={styles}
                value={this.formatValue(value)}
            />
        );
    }
}

export const SourcesAutocompleteContainer = compose(withData())(SourcesAutocomplete);
