import gql from 'graphql-tag';
import { compose } from 'recompose';
import { withMemo } from 'hoc/utils';
import { get } from 'utils';
import { TYPES } from 'consts/filters';
import { graphql } from 'graphql/utils';

const TYPE_MAP = {
    DomainSource: TYPES.domain,
    EventSource: TYPES.type
};

function formatSource(source) {
    return {
        label: source.domain || source.name,
        value: `${TYPE_MAP[source.type]}:${source.id}`
    };
}

function formatSources(sources, sourceTypes) {
    return (sourceTypes.length ? sources.filter(s => sourceTypes.includes(s.type)) : sources).map(formatSource);
}

function formatCategory(category, sourceTypes) {
    return {
        label: category.name,
        value: `${TYPES.sourceCategory}:${category.name}`,
        options: formatSources(category.sources, sourceTypes)
    };
}

function formatCategories(categories, sourceTypes) {
    return categories.map(c => formatCategory(c, sourceTypes)).filter(c => c.options.length > 0);
}

export const withData = () =>
    compose(
        withMemo({ formatCategories }),
        graphql(
            gql`
                query withSourcesAutocomplete {
                    streamSourceCategories {
                        name
                        sources {
                            __typename
                            type: __typename
                            id
                            name
                            ... on DomainSource {
                                domain
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data, ownProps: { formatCategories: formatOptions, sourceTypes = [] } }) => {
                    return {
                        options: formatOptions(get(data, 'streamSourceCategories', []), sourceTypes)
                    };
                },
                options: {
                    fetchPolicy: 'cache-first'
                }
            }
        )
    );
