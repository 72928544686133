import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { get } from 'utils';
import { generateAlertURL } from 'utils/alerts';
import { NotificationUI } from './ui';

export class Notification extends PureComponent {
    static displayName = 'NotificationContainer';

    static propTypes = {
        content: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.string.isRequired,
        dashboard: PropTypes.objectOf(PropTypes.any),
        equity: PropTypes.objectOf(PropTypes.any),
        event: PropTypes.objectOf(PropTypes.any),
        footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        hideTooltip: PropTypes.func,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isRead: PropTypes.bool,
        message: PropTypes.objectOf(PropTypes.any),
        notificationType: PropTypes.string,
        onClick: PropTypes.func,
        pathname: PropTypes.string.isRequired,
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        transcriptId: PropTypes.string
    };

    static defaultProps = {
        content: undefined,
        dashboard: undefined,
        equity: null,
        event: null,
        footer: undefined,
        hideTooltip: null,
        isRead: false,
        message: undefined,
        notificationType: undefined,
        onClick: undefined,
        stream: undefined,
        styles: undefined,
        transcriptId: undefined
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {
            content,
            dashboard,
            equity,
            event,
            hideTooltip,
            history,
            notificationType,
            onClick,
            pathname,
            stream,
            transcriptId
        } = this.props;
        if (onClick) onClick();
        const url = generateAlertURL({
            contentId: get(content, 'id'),
            dashboardId: get(dashboard, 'id'),
            dashboardType: get(dashboard, 'dashboardType'),
            equityId: get(equity, 'id'),
            eventId: get(event, 'id'),
            notificationType,
            pathname,
            streamId: get(stream, 'id'),
            transcriptId
        });
        if (url) {
            if (hideTooltip) hideTooltip();
            history.push(url);
        }
    }

    render() {
        const {
            content,
            created,
            dashboard,
            equity,
            event,
            footer,
            isRead,
            message,
            notificationType,
            stream,
            styles
        } = this.props;
        return (
            <NotificationUI
                content={content}
                created={created}
                dashboard={dashboard}
                equity={equity}
                event={event}
                footer={footer}
                isRead={isRead}
                message={message}
                notificationType={notificationType}
                onClick={this.onClick}
                stream={stream}
                styles={styles}
            />
        );
    }
}

export const NotificationContainer = compose(withUrlContext(['history', 'pathname']))(Notification);
