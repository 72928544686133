import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NavLink } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { Hint } from 'components/Hint';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { styleSheet } from './stylesheet';

class SidebarClosed extends PureComponent {
    static propTypes = {
        defaultDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        dashboardSections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        openModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        recommendedDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleSidebar: PropTypes.func.isRequired,
        showNewEventModal: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    constructor(props) {
        super(props);

        this.renderDashboardMenuOption = this.renderDashboardMenuOption.bind(this);
    }

    renderClosedMenuButton({ label, onClick, route, icon, permissionProps, buttonStyles = {} }) {
        const { styles, theme } = this.props;
        const menuButton = route ? (
            <Hint xOffset={40} yOffset={-28} text={label} styles={styles.closedBtnLink} key={label}>
                <NavLink to={route} activeClassName={label !== 'Gallery' ? 'selected' : 'notSelected'}>
                    <Icon color={theme.colors.gray04} type={icon} />
                </NavLink>
            </Hint>
        ) : null;
        return route ? (
            permissionProps ? (
                <WithPermission key={label} {...permissionProps}>
                    {menuButton}
                </WithPermission>
            ) : (
                menuButton
            )
        ) : (
            <Hint
                key={label}
                text={label}
                onClick={onClick}
                styles={{ ...styles.closedBtn, ...buttonStyles }}
                xOffset={40}
                yOffset={-28}
            >
                <Icon color={theme.colors.gray04} type={icon} />
            </Hint>
        );
    }

    renderDashboardMenuOption({ id, label, route }, hideTooltip) {
        const { styles } = this.props;
        if (!route) {
            return null;
        }

        return (
            <Div key={`${id || ''}-${label}`} styles={styles.dashboardMenuOption} onClick={hideTooltip}>
                <NavLink to={route} activeClassName="selected">
                    <Text size={2}>{label}</Text>
                </NavLink>
            </Div>
        );
    }

    renderDashboardsHoverMenu() {
        const { styles, theme, recommendedDashboards, dashboardSections } = this.props;
        return ({ hideTooltip }) => (
            <Fragment>
                <Div styles={styles.hoverMenuBtn}>
                    <Icon color={theme.colors.white01} type="columns02" />
                </Div>
                <Div styles={styles.menuScrollContainer}>
                    {dashboardSections.map(({ name, id, dashboards }) => (
                        <Fragment>
                            {dashboards.length > 0 && (
                                <Div styles={styles.divider} key={id}>
                                    <Text size={0} weight="medium" uppercase>
                                        {name}
                                    </Text>
                                </Div>
                            )}
                            {dashboards.map(d => this.renderDashboardMenuOption(d, hideTooltip))}
                        </Fragment>
                    ))}
                    {recommendedDashboards.length > 0 && (
                        <Fragment>
                            <Div styles={styles.divider}>
                                <Text size={0} weight="medium" uppercase>
                                    Recommended
                                </Text>
                            </Div>
                            {recommendedDashboards.map(d => this.renderDashboardMenuOption(d, hideTooltip))}
                        </Fragment>
                    )}
                </Div>
            </Fragment>
        );
    }

    render() {
        const {
            defaultDashboards,
            styles,
            passedStyles,
            theme,
            toggleSidebar,
            openModal,
            showNewEventModal
        } = this.props;

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {this.renderClosedMenuButton({
                    label: 'Open sidebar',
                    onClick: toggleSidebar,
                    icon: 'chevron03',
                    buttonStyles: styles.openPane
                })}
                {defaultDashboards.map(({ label, route, icon, permissionProps }) =>
                    this.renderClosedMenuButton({
                        label,
                        route,
                        icon,
                        permissionProps
                    })
                )}
                <WithPermission permissions={[PERMISSIONS.featureCreateEvent]}>
                    <WithPermission permissions={[PERMISSIONS.unlockedCreatePrivateRecording]}>
                        <Hint
                            text="Schedule Recording"
                            styles={styles.newBtn}
                            xOffset={32}
                            yOffset={-24}
                            onClick={showNewEventModal}
                        >
                            <Icon type="newEvent" color={theme.colors.white01} />
                        </Hint>
                    </WithPermission>
                </WithPermission>
                <Hint
                    text="New Monitor"
                    styles={styles.newBtn}
                    xOffset={32}
                    yOffset={-24}
                    onClick={() => openModal({ value: 'dashboard' })}
                >
                    <Icon type="newDashboard" color={theme.colors.white01} />
                </Hint>
                <Tooltip
                    yOffset={-1}
                    xOffset={40}
                    isEnabled
                    useElementOffsetTop
                    styles={styles.hoverMenu}
                    content={this.renderDashboardsHoverMenu()}
                >
                    {({ showTooltip }) => (
                        <Div styles={styles.closedBtn} onMouseEnter={showTooltip}>
                            <Icon color={theme.colors.gray04} type="columns02" />
                        </Div>
                    )}
                </Tooltip>
                <Div styles={styles.spacer} />
            </Div>
        );
    }
}

export const SidebarClosedUI = compose(withStyleSheet(styleSheet))(SidebarClosed);
