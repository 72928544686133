export const styleSheet = theme => ({
    container: {
        padding: '30px 35px 35px'
    },
    input: {
        marginTop: theme.margins.margin01
    },
    submitButton: {
        marginTop: theme.margins.margin02
    },
    thanks: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    thanksTitle: {
        color: theme.colors.gray12,
        marginBottom: theme.margins.margin01
    },
    thanksText: {
        textAlign: 'center',
        maxWidth: 400,
        lineHeight: '1.2em'
    },
    legal: {
        marginTop: theme.margins.margin02,
        lineHeight: '1.2em'
    }
});
