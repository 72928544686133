import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { EventAlertToggleUI } from './ui';

export class EventAlertToggle extends PureComponent {
    static displayName = 'EventAlertToggleContainer';

    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        toggleAlert: PropTypes.func.isRequired
    };

    static defaultProps = {
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.toggleAlert = this.toggleAlert.bind(this);

        this.state = {
            loading: false
        };
    }

    toggleAlert(event) {
        const { loading } = this.state;
        const { enabled, toggleAlert } = this.props;
        event.preventDefault();
        event.stopPropagation();
        if (!loading) {
            this.setState({ loading: true }, () => {
                toggleAlert(!enabled).finally(() => {
                    this.setState({ loading: false });
                });
            });
        }
    }

    render() {
        const { enabled, styles } = this.props;
        return <EventAlertToggleUI enabled={enabled} styles={styles} toggleAlert={this.toggleAlert} />;
    }
}

export const EventAlertToggleContainer = compose(withData())(EventAlertToggle);
