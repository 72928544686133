import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { CardLockButton } from 'components/CardLockButton';
import { Div } from 'components/Div';
import { InternalLink } from 'components/InternalLink';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { WithPermission } from 'components/WithPermission';
import { STREAM_DISPLAY_MODES, PERMISSIONS } from 'consts';
import { getHighlightColor, mtNewsNormalize } from 'utils';
import { styleSheet } from './stylesheet';

class NewsCard extends PureComponent {
    static propTypes = {
        additionalHighlights: PropTypes.arrayOf(PropTypes.any).isRequired,
        bookmarkId: PropTypes.string,
        displayMode: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        grouped: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        newsSource: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        to: PropTypes.string
    };

    static defaultProps = {
        bookmarkId: undefined,
        equities: [],
        grouped: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        newsSource: null,
        passedStyles: {},
        score: undefined,
        streamId: undefined,
        to: null
    };

    renderGroupedRows() {
        const { styles, grouped, onClick } = this.props;

        if (!grouped.length) {
            return null;
        }

        return grouped.map((g, index) => {
            const { company, newsSource, mediaId, publishedDate, title } = g;
            return (
                <Div key={mediaId} styles={styles.groupedContent} onClick={() => onClick(mediaId)}>
                    <Div styles={styles.groupedContentDetails}>
                        <Text size={1} styles={styles.textDarkerGray}>
                            <TimeAgo date={publishedDate} />
                        </Text>
                        <Text size={1} styles={styles.textDarkerGray}>
                            &nbsp; • &nbsp;
                            {newsSource}
                        </Text>
                        <Div styles={styles.spacer} />
                        <Text size={0} styles={styles.groupedContentIndex}>{`${index + 1} of ${grouped.length}`}</Text>
                    </Div>
                    <Text size={3} weight="medium">
                        {company}
                    </Text>
                    <Text size={1} styles={styles.title}>
                        {title}
                    </Text>
                </Div>
            );
        });
    }

    renderAdditionalHighlights() {
        const { styles, additionalHighlights, to, onClick } = this.props;

        if (!additionalHighlights.length) {
            return null;
        }

        return additionalHighlights.map((h, index) => {
            const key = `news-highlight-${index}-${h.slice(10)}`;
            return (
                <WithPermission permissions={[PERMISSIONS.unlockedNewsContent]}>
                    {({ restricted, isLoading }) =>
                        restricted || isLoading ? (
                            <Div styles={styles.groupedContentLocked}>
                                <RawHTML html={h} />
                            </Div>
                        ) : (
                            <InternalLink key={key} onClick={onClick} styles={styles.groupedContent} to={to}>
                                <RawHTML html={h} />
                            </InternalLink>
                        )
                    }
                </WithPermission>
            );
        });
    }

    render() {
        const {
            additionalHighlights,
            bookmarkId,
            displayMode,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            matchId,
            newsSource,
            onClick,
            passedStyles,
            publishedDate,
            score,
            styles,
            title,
            equities,
            streamId,
            to
        } = this.props;
        if (STREAM_DISPLAY_MODES.compact === displayMode) {
            return (
                <WithPermission permissions={[PERMISSIONS.unlockedNewsContent]}>
                    {({ restricted, isLoading }) => (
                        <Card
                            containerStyles={styles.containerCompact}
                            name="news-card-compact"
                            onClick={isLoading ? undefined : onClick}
                            styles={{ ...styles.compact, ...passedStyles }}
                            to={restricted || isLoading ? undefined : to}
                        >
                            {({ isHovering }) => (
                                <Fragment>
                                    {isHovering && (
                                        <StreamActionMenu
                                            bookmarkId={bookmarkId}
                                            highlight={highlights}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="content"
                                            url={to}
                                        />
                                    )}
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subTextCompact}>
                                        <Text size={1} styles={styles.date}>
                                            <TimeAgo date={publishedDate} />
                                        </Text>
                                        <Text size={1} styles={styles.source}>
                                            &nbsp; • &nbsp;
                                            {newsSource}
                                        </Text>
                                    </Div>
                                    {(restricted || isLoading) && <CardLockButton upgradeModalId="news" />}
                                </Fragment>
                            )}
                        </Card>
                    )}
                </WithPermission>
            );
        }
        const containerStyles =
            displayMode === STREAM_DISPLAY_MODES.comfortable ? styles.containerComfortable : styles.container;

        const contentStyles = {
            ...styles.content,
            backgroundColor: getHighlightColor(highlightColor)?.color
        };

        return (
            <WithPermission permissions={[PERMISSIONS.unlockedNewsContent]}>
                {({ restricted, isLoading }) => (
                    <Card
                        name={`news-card-${displayMode === STREAM_DISPLAY_MODES.default ? 'default' : 'comfortable'}`}
                        onClick={isLoading ? undefined : onClick}
                        styles={{ ...containerStyles, ...passedStyles }}
                        subContentButtonText={
                            additionalHighlights && additionalHighlights.length > 0
                                ? `${pluralize('additional match', additionalHighlights.length, true)}`
                                : ''
                        }
                        subContentRows={
                            displayMode === STREAM_DISPLAY_MODES.default ? this.renderAdditionalHighlights() : undefined
                        }
                        subContentTitle={title}
                        to={restricted || isLoading ? undefined : to}
                    >
                        {({ isHovering }) => {
                            return (
                                <Fragment>
                                    {isHovering && (
                                        <StreamActionMenu
                                            bookmarkId={bookmarkId}
                                            highlight={highlights}
                                            matchId={matchId}
                                            score={score}
                                            streamId={streamId}
                                            styles={styles.actionMenu}
                                            targetId={id}
                                            targetType="content"
                                            url={to}
                                        />
                                    )}
                                    <CardEquities equities={equities} />
                                    <CardHeader title={highlightTitle || title} />
                                    <Div styles={styles.subText}>
                                        <Text size={1} styles={styles.date}>
                                            <TimeAgo date={publishedDate} />
                                        </Text>
                                        <Text size={1} styles={styles.source}>
                                            &nbsp; • &nbsp;
                                            {newsSource}
                                        </Text>
                                    </Div>
                                    {displayMode === STREAM_DISPLAY_MODES.default && (
                                        <Div styles={contentStyles}>
                                            <RawHTML html={mtNewsNormalize(highlights)} />
                                        </Div>
                                    )}
                                    {(restricted || isLoading) && <CardLockButton upgradeModalId="news" />}
                                </Fragment>
                            );
                        }}
                    </Card>
                )}
            </WithPermission>
        );
    }
}

export const NewsCardUI = compose(withStyleSheet(styleSheet))(NewsCard);
