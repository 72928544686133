export const styleSheet = theme => {
    const containerBase = {
        textDecoration: 'none',
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
        height: '30px'
    };
    return {
        container: {
            ...containerBase,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray01,
                boxShadow: `0 0 0 0 rgba(0,0,0,0)`
            },
            ':active': {
                backgroundColor: theme.colors.gray05,
                boxShadow: `0 0 0 0 rgba(0,0,0,0)`
            }
        },
        containerSize02: {
            height: 40,
            padding: '0 12px',
            borderRadius: 3,
            ':hover': {
                boxShadow: `0 0 0 1px ${theme.colors.gray07}`,
                backgroundColor: theme.colors.white02
            },
            ':active': {
                backgroundColor: theme.colors.white03
            }
        },
        disabled: {
            cursor: 'not-allowed',
            p: {
                opacity: 0.5
            },
            ':hover': {
                backgroundColor: 'auto'
            },
            ':active': {
                backgroundColor: 'auto'
            }
        }
    };
};
