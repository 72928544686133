import gql from 'graphql-tag';
import { capitalize, get } from 'utils';
import { mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import { equitySearchFragment } from 'graphql/fragments/search';

export const getSearchQuery = alias => gql`
    query ${alias || 'withSearch'}(
        $term: String
        $withEquities: Boolean = false
    ) {
        search {
            equities(term: $term) @include(if: $withEquities) {
                ...equitySearch
            }
        }
    }
    ${equitySearchFragment}

`;

export const withBaseSearch = (options = {}) => {
    const alias = mapPropsToOptions(options).alias || 'withSearch';
    return graphql(getSearchQuery(alias), {
        props: ({ data, ownProps }) => ({
            searchError: data.error,
            searchLoading: !!data.loading,
            searchResults: get(data, `search.${ownProps.searchType}`)
        }),
        alias,
        skip: props => {
            const { minTermLength = 0, term = '' } = props;
            return mapPropsToOptions(options, props).skip || term.trim().length < minTermLength;
        },
        options: props => {
            const opts = mapPropsToOptions(options, props);
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                // One of the few examples where we want to return existing cache data even
                // when variables don't match.
                returnPreviousData: opts.returnPreviousData === undefined ? true : opts.returnPreviousData,
                variables: {
                    term: props.term,
                    // Defaulting all to false here because apollo ends up making
                    // 2 requests; 1 with just your variables and then one with
                    // the defaults filled in. Annoying, but being explicit here
                    // fixes the issue.
                    //
                    // Can be removed when https://github.com/apollographql/react-apollo/issues/2715
                    // is addressed.
                    withEquities: false,
                    [`with${capitalize(props.searchType)}`]: true,
                    ...(opts.variables || {})
                },
                context: opts.context
            };
        }
    });
};
