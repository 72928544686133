export const styleSheet = theme => {
    const streamTaggedInputSvg = {
        display: 'none',
        position: 'absolute',
        top: '-5px',
        right: 0,
        bottom: 0,
        left: 249,
        zIndex: 1
    };
    const toggleAdvanced = {
        display: 'flex',
        marginTop: 20,
        cursor: 'pointer',
        alignItems: 'center',
        color: theme.colors.gray06
    };
    return {
        container: {},
        content: {
            padding: `${theme.margins.margin02} 35px 0`
        },
        dashId: {
            marginTop: theme.margins.margin01,
            marginBottom: theme.margins.margin02,
            display: 'flex',
            borderRadius: 6
        },
        input: {
            width: '100%',
            input: {
                height: '40px',
                fontSize: theme.fonts.fontSizes.size03,
                margin: 0,
                [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                    fontSize: theme.fonts.fontSizes.size04
                }
            }
        },
        textarea: {
            marginTop: theme.margins.margin01
        },
        visibilityContainer: {
            marginTop: theme.margins.margin02,
            display: 'inline-flex',
            alignItems: 'flex-start'
        },
        tags: {
            marginTop: theme.margins.margin02
        },
        equityScope: {
            marginTop: theme.margins.margin02
        },
        bulkStreamsContainer: {
            marginTop: theme.margins.margin02
        },
        bulkStreamsLabel: {
            ...theme.content.formLabel
        },
        taggedInputsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 6,
            '> div:nth-of-type(even)': {
                marginLeft: 'auto'
            }
        },
        streamTaggedInputContainer: {
            position: 'relative',
            '.streamTaggedInputIcon': {
                svg: {
                    ...streamTaggedInputSvg
                }
            },
            ':hover': {
                '.streamTaggedInputIcon': {
                    svg: {
                        ...streamTaggedInputSvg,
                        cursor: 'pointer',
                        display: 'block'
                    }
                }
            }
        },
        streamTaggedInput: {
            borderRadius: 6,
            height: 140,
            marginBottom: 10,
            position: 'relative',
            width: 260,
            backgroundColor: theme.colors.white03,
            '.taggedInputBox': {
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                borderRadius: '6px 6px 0 0',
                boxShadow: 'unset',
                height: 110,
                overflow: 'auto',
                position: 'unset',
                backgroundColor: theme.colors.white03,
                ':focus-within': {
                    backgroundColor: theme.colors.white03
                },
                ':hover': {
                    boxShadow: 'unset'
                }
            },
            '.taggedInputTag': {
                flexShrink: 0,
                marginLeft: 4
            }
        },
        streamTextInput: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 'calc(100% - 12px)',
            margin: 6,
            '.taggedInput': {
                borderRadius: 6,
                fontSize: theme.fonts.fontSizes.size01,
                backgroundColor: theme.colors.white01,
                ':hover': {
                    backgroundColor: theme.colors.white01,
                    boxShadow: `0 0 0 1px ${theme.colors.gray01}`
                },
                ':focus': {
                    backgroundColor: theme.colors.white01,
                    boxShadow: `0 0 0 1px ${theme.colors.blue13}, 0 0 0 3px ${theme.colors.blue14}`
                }
            }
        },
        addStreamLine: {
            display: 'flex',
            alignItems: 'center'
        },
        addStreamButton: {
            justifyContent: 'center',
            backgroundColor: theme.colors.blue09,
            boxShadow: 'unset',
            borderRadius: 6,
            flex: 1,
            p: {
                marginLeft: 6,
                color: theme.colors.blue04
            },
            ':hover': {
                backgroundColor: theme.colors.blue07
            },
            ':active': {
                backgroundColor: '#e5eef7'
            }
        },
        addStreamTooltip: {
            ...theme.content.tooltipText,
            maxWidth: 540
        },
        addStreamTooltipText: {
            marginTop: 10
        },
        buttonsContainer: {
            ...theme.content.modalButtonsContainer
        },
        cancelButton: {
            ...theme.content.cancelButton
        },
        button: {
            backgroundColor: theme.colors.white01,
            borderRadius: 3,
            height: 40,
            marginLeft: 15,
            svg: {
                marginRight: 7,
                marginBottom: 1
            }
        },
        buttonSubmit: {
            ...theme.content.blueButton,
            borderRadius: 3,
            color: theme.colors.white01,
            border: 'unset',
            boxShadow: 'unset',
            'svg g': {
                fill: theme.colors.white01
            }
        },
        buttonDisabled: {
            borderRadius: 3,
            cursor: 'not-allowed',
            opacity: 0.6
        },
        buttonDelete: {
            borderRadius: 3,
            margin: '0 15px 0 0'
        },
        buttonClone: {
            borderRadius: 3,
            margin: '0 auto 0 0'
        },
        tooltip: {
            ...theme.content.tooltipText,
            maxWidth: 270
        },
        tooltipClone: {
            maxWidth: 290,
            opacity: 1
        },
        tooltipShareDash: {
            ...theme.content.tooltipText,
            maxWidth: 460
        },
        spacer: {
            display: 'flex',
            flex: 1
        },
        requestButton: {
            cursor: 'pointer',
            backgroundColor: theme.colors.white01,
            boxShadow: `0 0 0 1px ${theme.colors.gray01}`,
            borderRadius: 3,
            padding: ' 0 12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            p: {
                color: theme.colors.gray06
            },
            ':hover': {
                boxShadow: `0 0 0 1px ${theme.colors.gray07}`,
                svg: {
                    g: {
                        fill: theme.colors.blue08
                    }
                },
                p: {
                    color: theme.colors.black01
                }
            },
            ':active': {
                backgroundColor: 'unset'
            },
            svg: {
                marginRight: 7,
                marginBottom: 1
            }
        },
        noQuickTerms: {
            position: 'absolute',
            zIndex: 1,
            textAlign: 'center',
            margin: '40px 28px 0',
            lineHeight: '1.3em',
            color: theme.colors.gray04
        },
        galleryCategories: {
            marginTop: theme.margins.margin02
        },
        galleryNote: {
            ...theme.content.box,
            marginTop: 35,
            padding: theme.margins.margin01
        },
        toggleAdvanced: {
            ...toggleAdvanced,
            svg: {
                marginRight: 6,
                transform: 'rotate(90deg)'
            },
            ':hover': {
                color: theme.colors.black01,
                'svg g': {
                    fill: theme.colors.black01
                }
            }
        },
        toggleAdvancedClosed: {
            ...toggleAdvanced,
            svg: {
                marginRight: 6
            },
            ':hover': {
                color: theme.colors.black01,
                'svg g': {
                    fill: theme.colors.black01
                }
            }
        }
    };
};
