import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Autocomplete } from 'components/Autocomplete';
import { styleSheet } from './stylesheet';

class SpeakerAutocomplete extends PureComponent {
    static propTypes = {
        initialSearchTerm: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        value: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        initialSearchTerm: null,
        passedStyles: {},
        value: null
    };

    render() {
        const {
            initialSearchTerm,
            loading,
            name,
            onChange,
            onSearch,
            options,
            passedStyles,
            placeholder,
            styles,
            value
        } = this.props;
        return (
            <Autocomplete
                initialSearchTerm={initialSearchTerm}
                loading={loading}
                menuStyles={styles.menu}
                name={name}
                onChange={onChange}
                onSearch={onSearch}
                options={options}
                placeholder={placeholder}
                styles={{ ...styles.container, ...passedStyles }}
                value={value}
            />
        );
    }
}

export const SpeakerAutocompleteUI = compose(withStyleSheet(styleSheet))(SpeakerAutocomplete);
