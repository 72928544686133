import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { NoRouteMatchUI } from './ui';

export class NoRouteMatch extends PureComponent {
    static displayName = 'NoRouteMatchContainer';

    render() {
        return <NoRouteMatchUI />;
    }
}

export const NoRouteMatchContainer = compose()(NoRouteMatch);
