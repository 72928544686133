import { colors } from 'styles/colors';

const toggleCheckboxes = {
    display: 'flex',
    marginTop: 20,
    cursor: 'pointer',
    alignItems: 'center',
    color: colors.gray06
};

export const styleSheet = theme => ({
    container: {
        padding: '20px 35px 35px',
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            padding: 20
        }
    },
    actionButton: {
        ...theme.content.blueButton,
        borderRadius: 6,
        boxShadow: 'unset',
        display: 'inline-flex',
        height: 40,
        marginLeft: 'auto',
        marginTop: theme.margins.margin02,
        p: {
            color: theme.colors.white01
        }
    },
    button: {
        a: {
            ...theme.content.blueButton,
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: '6px',
            color: theme.colors.white01,
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '30px',
            height: 40,
            margin: '20px 0 5px 0',
            padding: '0 15px',
            textDecoration: 'none'
        },
        ':hover': {
            a: {
                textDecoration: 'none'
            }
        },
        svg: {
            marginLeft: theme.margins.margin00
        },
        textAlign: 'center'
    },
    checkbox: {
        '.rowBox': {
            marginRight: theme.margins.margin00
        },
        '.rowBoxChecked': {
            marginRight: theme.margins.margin00
        }
    },
    checkboxContainer: {
        alignItems: 'center',
        columnGap: 50,
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.margins.margin02,
        rowGap: 20
    },
    checkboxRow: {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        whiteSpace: 'nowrap'
    },
    content: {
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '355px'
        }
    },
    contentManual: {
        flex: 1,
        marginLeft: 30
    },
    contentRow: {
        display: 'flex'
    },
    contentAuto: {
        flex: 1,
        background: theme.colors.gray10,
        border: `1px solid ${theme.colors.gray03}`,
        borderRadius: 6,
        padding: 20
    },
    copyLink: {
        border: `1px solid ${theme.colors.white03}`,
        borderRadius: '6px',
        margin: `${theme.margins.margin01} 0`,
        padding: '12px'
    },
    dateRangeDropdown: {
        '.dropdownLabel': {
            label: {
                textTransform: 'none'
            },
            paddingBottom: 6
        }
    },
    description: {
        display: 'block',
        fontSize: theme.fonts.fontSizes.size04,
        lineHeight: '1.3em',
        marginTop: theme.margins.margin01,
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            maxWidth: '375px'
        }
    },
    footer: {
        alignItems: 'center',
        display: 'flex'
    },
    footnoteDagger: {
        color: theme.colors.gray06,
        marginLeft: 'auto',
        marginTop: 6
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    formFooter: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    formFooterNote: {
        alignSelf: 'flex-end',
        color: theme.colors.gray06,
        lineHeight: '1.2em'
    },
    heading: {
        lineHeight: '1.2em'
    },
    loading: {
        marginTop: theme.margins.margin04
    },
    modal: {
        maxWidth: 800,
        width: 'calc(100% - 80px)',
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            width: '100%',
            '.modalHeader': {
                padding: '16px 20px 0',
                h1: {
                    fontSize: theme.fonts.fontSizes.size05
                }
            }
        }
    },
    moreInfo: {
        marginTop: theme.margins.margin02,
        ul: {
            listStyle: 'disc',
            paddingLeft: theme.margins.margin04,
            lineHeight: '1.5em'
        }
    },
    moreInfoText: {
        fontSize: theme.fonts.fontSizes.size04,
        marginBottom: theme.margins.margin01,
        lineHeight: '1.3em'
    },
    note: {
        marginTop: theme.margins.margin00,
        textAlign: 'center',
        color: theme.colors.gray04,
        letterSpacing: '1px'
    },
    notice: {
        borderRadius: 6,
        marginBottom: 20,
        [`@media(max-width: ${theme.breakpoints.external.mobileEdge})`]: {
            '> div': {
                lineHeight: 'normal'
            }
        }
    },
    strong: {
        fontWeight: theme.fonts.fontWeights.medium
    },
    toggleCheckboxes: {
        ...toggleCheckboxes,
        svg: {
            marginRight: 6,
            transform: 'rotate(90deg)'
        },
        ':hover': {
            color: theme.colors.black01,
            'svg g': {
                fill: theme.colors.black01
            }
        }
    },
    toggleCheckboxesClosed: {
        ...toggleCheckboxes,
        svg: {
            marginRight: 6
        },
        ':hover': {
            color: theme.colors.black01,
            'svg g': {
                fill: theme.colors.black01
            }
        }
    }
});
