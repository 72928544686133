import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import XDate from 'xdate';
import { withStyleSheet } from 'hoc/styles';
import { routes } from 'routes';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tr } from 'components/Tr';
import { Th } from 'components/Th';
import { Td } from 'components/Td';
import { CirclePlusButton } from 'components/CirclePlusButton';
import { LoaderLogo } from 'components/LoaderLogo';
import { Hint } from 'components/Hint';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';
import { InternalLink } from 'components/InternalLink';

import { truncateString } from 'utils';
import { ExpandedDifferentialRow } from '../ExpandedDifferentialRow';
import { styleSheet } from './stylesheet';

function formatDate(callDate, includeYear) {
    if (callDate) {
        if (includeYear) {
            return new XDate(callDate).toString("MMM dd, 'yy");
        }
        return new XDate(callDate).toString('MMM dd');
    }

    return 'N/A';
}

function highlightsText(highlights) {
    let highlightString = 'highlights';
    if (highlights.length === 1) {
        highlightString = 'highlight';
    }
    return highlightString;
}

class EventStreamMatchDifferentials extends PureComponent {
    static propTypes = {
        collapseRow: PropTypes.func.isRequired,
        collapsedDashboards: PropTypes.arrayOf(PropTypes.any).isRequired,
        deletingStreamId: PropTypes.string,
        differentialEvents: PropTypes.arrayOf(PropTypes.any),
        aieraDifferentials: PropTypes.arrayOf(PropTypes.any),
        dashboardDifferentials: PropTypes.arrayOf(PropTypes.any),
        expandRow: PropTypes.func.isRequired,
        expandedRowTitle: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        hideStreamMatches: PropTypes.bool.isRequired,
        localDifferentials: PropTypes.arrayOf(PropTypes.any),
        newMonitor: PropTypes.string,
        newMonitorLoading: PropTypes.bool.isRequired,
        onChangeNewMonitor: PropTypes.func.isRequired,
        onCreateNewMonitor: PropTypes.func.isRequired,
        onDifferentialsChartDataPointClick: PropTypes.func.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        onRemoveMonitor: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleDash: PropTypes.func,
        toggleStreamMatches: PropTypes.func.isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        deletingStreamId: null,
        differentialEvents: [],
        aieraDifferentials: [],
        dashboardDifferentials: [],
        expandedRowTitle: null,
        localDifferentials: [],
        newMonitor: null,
        toggleDash: () => {}
    };

    constructor(props) {
        super(props);

        this.renderNewMonitorTooltip = this.renderNewMonitorTooltip.bind(this);
    }

    renderCell(value, styleOverride, className) {
        const { styles } = this.props;

        return (
            <Td className={className} styles={styleOverride || styles.tableRowValue}>
                <Text size={2}>{value}</Text>
            </Td>
        );
    }

    renderHeaderCell(value, styleOverride) {
        const { styles } = this.props;

        return (
            <Th styles={styleOverride || styles.tableHeaderColumnLabel}>
                <Text size={0} uppercase>
                    {value}
                </Text>
            </Th>
        );
    }

    renderHeaderTitleCell({ title, onClick, toolTip, onToggle, hidden }) {
        const { styles, theme } = this.props;
        const titleStyles = hidden ? styles.titleToggleHidden : styles.titleToggle;

        const titleCell = toolTip ? (
            <Hint text="How it works" description={toolTip}>
                <Text size={3} weight="medium">
                    {title}
                </Text>
            </Hint>
        ) : (
            <Text size={3} weight="medium">
                {title}
            </Text>
        );
        return (
            <Th rowSpan={2} styles={styles.tableHeaderTitle}>
                <Div styles={styles.headerTitleWrapper}>
                    {onToggle ? (
                        <Div styles={titleStyles} onClick={onToggle}>
                            {titleCell}
                            {!!onToggle && <Icon type="chevron02" color={theme.colors.black01} />}
                        </Div>
                    ) : (
                        titleCell
                    )}
                    {onClick && <CirclePlusButton styles={styles.plusButton} onClick={onClick} />}
                </Div>
            </Th>
        );
    }

    renderNewMonitorTooltip({ hideTooltip }) {
        const { newMonitor, newMonitorLoading, onChangeNewMonitor, onCreateNewMonitor } = this.props;
        return (
            <TooltipForm
                className="tab-tooltip"
                buttonDisabled={!newMonitor.length}
                inputName="newLocalMonitor"
                loading={newMonitorLoading}
                onCancel={hideTooltip}
                onChange={onChangeNewMonitor}
                onSubmit={onCreateNewMonitor(hideTooltip)}
                placeholder="Search term"
                submitButtonText="Monitor"
                value={newMonitor}
            />
        );
    }

    renderHeaderRow({ isMobile, title, toolTip, onClick, onToggle, hidden }) {
        const { styles, differentialEvents } = this.props;

        return (
            <Fragment>
                <Tr styles={styles.tableHeaderRow}>
                    {this.renderHeaderTitleCell({
                        title,
                        onClick,
                        toolTip,
                        onToggle,
                        hidden
                    })}
                    <Th colSpan={isMobile ? 2 : 5} styles={styles.tableHeaderGroupLabel}>
                        <Text size={0} uppercase weight="medium">
                            event mentions
                        </Text>
                    </Th>
                </Tr>
                <Tr styles={styles.tableHeaderRowShadow}>
                    {!isMobile &&
                        this.renderHeaderCell(formatDate(differentialEvents[0] && differentialEvents[0].callDate))}
                    {!isMobile &&
                        this.renderHeaderCell(formatDate(differentialEvents[1] && differentialEvents[1].callDate))}
                    {!isMobile &&
                        this.renderHeaderCell(formatDate(differentialEvents[2] && differentialEvents[2].callDate))}
                    {this.renderHeaderCell(formatDate(differentialEvents[3] && differentialEvents[3].callDate))}
                    {this.renderHeaderCell(formatDate(differentialEvents[4] && differentialEvents[4].callDate))}
                </Tr>
            </Fragment>
        );
    }

    renderDifferentialRow(differential, isLast, removable) {
        const {
            styles,
            theme,
            differentialEvents,
            expandedRowTitle,
            collapseRow,
            expandRow,
            onNodeSelect,
            onRemoveMonitor,
            onDifferentialsChartDataPointClick,
            deletingStreamId
        } = this.props;

        const { columnData, streamId, title, highlights } = differential;
        const isExpanded = expandedRowTitle && expandedRowTitle.toLowerCase() === title.toLowerCase();
        const boxType = isExpanded ? 'boxMinus' : 'boxPlus';
        const boxColor = isExpanded ? theme.colors.black01 : theme.colors.gray04;
        const toggleExpand = isExpanded ? () => collapseRow(title) : () => expandRow(title);
        let tableStyles = isExpanded ? styles.tableRowExpanded : styles.tableRow;
        let chartData = [];

        if (isLast) {
            tableStyles = styles.tableRowLast;
        }

        if (columnData && columnData.length > 0) {
            chartData = columnData.map(({ numMatches, scheduledAudioCallId }) => ({
                y: numMatches,
                id: scheduledAudioCallId
            }));
            const lastPosition = columnData.length - 1;
            chartData[lastPosition] = {
                y: columnData[lastPosition].numMatches,
                color: theme.colors.blue08,
                id: columnData[lastPosition].scheduledAudioCallId
            };
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile} key={`differential-${title}`}>
                {mobile => (
                    <Fragment>
                        <Tr styles={tableStyles}>
                            <Td styles={styles.tableRowTitle}>
                                <Div styles={styles.rowTitleWrapper}>
                                    <Div styles={styles.titlePlus} onClick={toggleExpand}>
                                        <Icon type={boxType} color={boxColor} />
                                        <Div>
                                            <Text
                                                size={3}
                                                styles={styles.rowTitleText}
                                                weight={isExpanded ? 'medium' : 'normal'}
                                            >
                                                {title}
                                            </Text>
                                            <Text size={0} uppercase styles={styles.rowSubtext}>
                                                {`${highlights.length} ${highlightsText(highlights)}`}
                                            </Text>
                                        </Div>
                                    </Div>
                                    {deletingStreamId === streamId && (
                                        <Div styles={styles.removeMonitorLoader}>
                                            <LoaderLogo type={2} height={30} />
                                        </Div>
                                    )}
                                    {deletingStreamId !== streamId && removable && (
                                        <Div
                                            styles={styles.removeMonitorButton}
                                            className="removeMonitorButton"
                                            onClick={() => onRemoveMonitor(differential)}
                                        >
                                            <Icon type="trash02" color={theme.colors.gray04} />
                                        </Div>
                                    )}
                                </Div>
                            </Td>
                            {!mobile && this.renderCell(columnData[0].numMatches)}
                            {!mobile && this.renderCell(columnData[1].numMatches)}
                            {!mobile && this.renderCell(columnData[2].numMatches)}
                            {this.renderCell(columnData[3].numMatches)}
                            {this.renderCell(columnData[4].numMatches)}
                        </Tr>
                        {isExpanded && (
                            <ExpandedDifferentialRow
                                colSpan={6}
                                labels={differentialEvents.map(
                                    e => `${formatDate(e.callDate, true)} ${truncateString(e.title, 36)}`
                                )}
                                data={[{ name: 'Mentions', color: '#AEC2D1', data: chartData }]}
                                chartHeight={mobile ? 180 : 196}
                                highlights={highlights}
                                onChartDataPointClick={onDifferentialsChartDataPointClick}
                                onNodeSelect={onNodeSelect}
                            />
                        )}
                    </Fragment>
                )}
            </MediaQuery>
        );
    }

    renderDifferentials({ differentials, title, toolTip, isLocal, isRemovable }) {
        const { styles, theme, expandedRowTitle, loading } = this.props;
        const differentialRows = [];
        differentials.forEach((differential, idx) => {
            const isExpanded = expandedRowTitle && expandedRowTitle.toLowerCase() === title.toLowerCase();
            const isLast = !isExpanded && differentials.length - 1 === idx;
            differentialRows.push(this.renderDifferentialRow(differential, isLast, isRemovable));
        });

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {isMobile => (
                    <Tooltip
                        styles={styles.tooltipNewMonitor}
                        isEnabled
                        persistOnMouseExit
                        useElementOffsetLeft
                        useElementOffsetTop
                        useOutsideClickHandler
                        hideOnScroll={!isMobile}
                        xOffset={-140}
                        yOffset={-60}
                        content={this.renderNewMonitorTooltip}
                    >
                        {({ showTooltip }) => (
                            <Fragment>
                                {this.renderHeaderRow({
                                    isMobile,
                                    title,
                                    onClick: isLocal ? showTooltip : undefined,
                                    toolTip
                                })}
                                {loading && differentials.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} styles={styles.loadingCell}>
                                            <Div styles={styles.loadingContent}>
                                                <LoaderLogo />
                                            </Div>
                                        </Td>
                                    </Tr>
                                )}
                                {!loading && differentials.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} styles={styles.noMatchesCell}>
                                            <Div styles={styles.noDifferentials}>
                                                <Text size={3} styles={styles.noDifferentials}>
                                                    No matches found, add a new local monitor
                                                </Text>
                                                <CirclePlusButton onClick={showTooltip} />
                                            </Div>
                                        </Td>
                                    </Tr>
                                )}
                                {differentialRows}
                            </Fragment>
                        )}
                    </Tooltip>
                )}
            </MediaQuery>
        );
    }

    renderDashboardDifferentials(dashboardDifferentials) {
        const {
            styles,
            theme,
            expandedRowTitle,
            loading,
            collapsedDashboards,
            toggleDash,
            hideStreamMatches,
            toggleStreamMatches
        } = this.props;

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {isMobile => (
                    <Tooltip
                        styles={styles.tooltipNewMonitor}
                        isEnabled
                        persistOnMouseExit
                        useElementOffsetLeft
                        useElementOffsetTop
                        useOutsideClickHandler
                        hideOnScroll={!isMobile}
                        xOffset={-140}
                        yOffset={-60}
                        content={this.renderNewMonitorTooltip}
                    >
                        {({ showTooltip }) => (
                            <Fragment>
                                {this.renderHeaderRow({
                                    onToggle: toggleStreamMatches,
                                    hidden: hideStreamMatches,
                                    isMobile,
                                    title: 'Saved Search Matches',
                                    toolTip: 'Matches populate based on the "Transcript" searches you have created.'
                                })}
                                {loading && dashboardDifferentials.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} styles={styles.loadingCell}>
                                            <Div styles={styles.loadingContent}>
                                                <LoaderLogo />
                                            </Div>
                                        </Td>
                                    </Tr>
                                )}
                                {!loading && dashboardDifferentials.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} styles={styles.noMatchesCell}>
                                            <Div styles={styles.noDifferentials}>
                                                <Text size={3} styles={styles.noDifferentials}>
                                                    No matches found, add a new local monitor
                                                </Text>
                                                <CirclePlusButton onClick={showTooltip} />
                                            </Div>
                                        </Td>
                                    </Tr>
                                )}
                                {!hideStreamMatches &&
                                    dashboardDifferentials.map(({ id, name, streams }, dashIndex) => {
                                        const collapsed = collapsedDashboards.includes(id);
                                        const rowStyle = collapsed ? styles.dashboardRowCollapsed : styles.dashboardRow;
                                        return (
                                            <Fragment key={id}>
                                                <Tr>
                                                    <Td colSpan={6}>
                                                        <Div styles={rowStyle} onClick={() => toggleDash(id)}>
                                                            <Div>
                                                                <InternalLink
                                                                    to={generatePath(routes.dashboard, {
                                                                        dashboardId: id
                                                                    })}
                                                                >
                                                                    <Icon
                                                                        type="columns02"
                                                                        color={theme.colors.gray04}
                                                                    />
                                                                </InternalLink>
                                                                <Text size={0} uppercase>
                                                                    {name}
                                                                </Text>
                                                            </Div>
                                                            <Icon type="chevron02" color={theme.colors.black01} />
                                                        </Div>
                                                    </Td>
                                                </Tr>
                                                {!collapsed &&
                                                    streams.map((differential, idx) => {
                                                        const { title } = differential;
                                                        const isExpanded =
                                                            expandedRowTitle &&
                                                            expandedRowTitle.toLowerCase() === title.toLowerCase();
                                                        const isLast =
                                                            !isExpanded &&
                                                            streams.length - 1 === idx &&
                                                            dashboardDifferentials.length - 1 === dashIndex;
                                                        return this.renderDifferentialRow(differential, isLast);
                                                    })}
                                            </Fragment>
                                        );
                                    })}
                            </Fragment>
                        )}
                    </Tooltip>
                )}
            </MediaQuery>
        );
    }

    render() {
        const { localDifferentials, aieraDifferentials, dashboardDifferentials } = this.props;
        return (
            <Fragment>
                {aieraDifferentials.length > 0 &&
                    this.renderDifferentials({
                        differentials: aieraDifferentials,
                        title: 'Key Mentions',
                        toolTip: 'Key Mentions are generated by Aiera, uniquely for each equity.'
                    })}
                {dashboardDifferentials.length > 0 && this.renderDashboardDifferentials(dashboardDifferentials)}
                {this.renderDifferentials({
                    differentials: localDifferentials,
                    title: 'Local Monitors',
                    toolTip: 'Local monitors will be displayed on all events for the equity you add them to.',
                    isLocal: true,
                    isRemovable: true
                })}
            </Fragment>
        );
    }
}

export const EventStreamMatchDifferentialsUI = compose(withStyleSheet(styleSheet))(EventStreamMatchDifferentials);
