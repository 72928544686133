import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';

import { styleSheet } from './stylesheet';

class CopyLink extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        copied: PropTypes.bool.isRequired,
        copyToClipboard: PropTypes.func.isRequired,
        hideText: PropTypes.bool,
        textSize: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        hideText: false
    };

    render() {
        const { styles, passedStyles, theme, copied, copyToClipboard, hideText, textSize, url } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} onClick={copyToClipboard}>
                <Icon type="copyToClipboard" color={theme.colors.gray04} />
                {!hideText && (
                    <Div styles={styles.urlContainer}>
                        <Text size={textSize} styles={styles.inviteUrl}>
                            {url}
                        </Text>
                    </Div>
                )}
                {copied && (
                    <Div styles={styles.copiedContainer}>
                        <Text size={1} styles={styles.copiedText}>
                            Copied!
                        </Text>
                        <Icon type="checkMark" color={theme.colors.green01} />
                    </Div>
                )}
            </Div>
        );
    }
}

export const CopyLinkUI = compose(withStyleSheet(styleSheet))(CopyLink);
