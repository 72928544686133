import { types as userTypes } from 'actions/user';
import { types as appTypes } from 'actions/app';

const { APP_SET_LOGIN_ROUTE, APP_SET_DEBUG_MODE } = appTypes;
const { USER_IS_LOGGED_OUT } = userTypes;

const initialState = {
    loginRoute: null,
    debugMode: false
};

export function appReducer(state = initialState, action) {
    const { type, payload } = action;
    if (type === APP_SET_LOGIN_ROUTE) {
        const { loginRoute = null } = payload;
        return {
            ...state,
            loginRoute
        };
    }

    if (type === APP_SET_DEBUG_MODE) {
        const { debugMode } = payload;
        return {
            ...state,
            debugMode
        };
    }

    if (type === USER_IS_LOGGED_OUT) {
        return {
            ...initialState
        };
    }

    return state;
}
