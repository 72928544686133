import memoize from 'memoize-one';
import sortBy from 'lodash/sortBy';
import { get } from 'utils';

const normalizeEquity = (identifier, equity) => {
    const {
        equityId,
        commonName,
        name,
        exchange: { name: exchangeName, shortName: exchangeShortName }
    } = equity;
    return {
        identifier,
        equityId,
        commonName,
        name,
        exchangeName,
        exchangeShortName
    };
};

function getEquityMatches(equities) {
    const matched = [];
    const unmatched = [];

    if (equities && equities.length > 0) {
        equities.forEach(e => {
            const { equities: equityMatches = [], identifier } = e;

            if (equityMatches.length > 0) {
                const matches = equityMatches.map(equity => normalizeEquity(identifier, equity));
                matched.push({ identifier, matches });
            } else {
                unmatched.push({ identifier, matches: [] });
            }
        });
    }

    return [...unmatched, ...matched].sort((a, b) => a.identifier.localeCompare(b.identifier));
}

function getSectorEquities(sectors = []) {
    const equities = [];

    if (sectors.length) {
        sectors.forEach(sector => {
            const { equities: sectorEquities = [] } = sector;
            if (sectorEquities.length) {
                sectorEquities.forEach(equity => {
                    const identifier = get(equity, 'identifier', get(equity, 'localTicker', ''));
                    // Must match the normalized equities format because we're combining the results from the
                    // identifiers search and the selected sectors
                    equities.push({ identifier, matches: [normalizeEquity(identifier, equity)] });
                });
            }
        });
    }

    return equities;
}

export const normalizeEquities = memoize(equities => getEquityMatches(equities));
export const normalizeSectorEquities = memoize(sectors => getSectorEquities(sectors));
export const sortEquities = memoize(equities => sortBy(equities, e => e.identifier));
