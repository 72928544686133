import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { colors } from 'styles/colors';

import { LoaderLogoUI } from './ui';

export class LoaderLogo extends PureComponent {
    static displayName = 'LoaderLogoContainer';

    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.any),
        height: PropTypes.number,
        type: PropTypes.number,
        color: PropTypes.string
    };

    static defaultProps = {
        styles: undefined,
        color: colors.orange02,
        height: 40,
        type: 1
    };

    render() {
        const { styles, color, height, type } = this.props;
        return <LoaderLogoUI styles={styles} color={color} height={height} type={type} />;
    }
}

export const LoaderLogoContainer = compose()(LoaderLogo);
