import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Img } from 'components/Img';
import { Hint } from 'components/Hint';
import { generateInitials } from 'utils';
import { styleSheet } from './stylesheet';

class CardEquities extends PureComponent {
    static propTypes = {
        equities: PropTypes.arrayOf(PropTypes.any).isRequired,
        expanded: PropTypes.bool.isRequired,
        keepOpen: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showTickers: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        toggle: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { keepOpen, toggle, expanded, equities, styles, showTickers, passedStyles } = this.props;
        const equityRange = expanded || keepOpen ? equities : equities.slice(0, 1);
        const containerStyles = showTickers ? styles.container : styles.containerMinimal;

        if (equities && equities.length) {
            return (
                <Div styles={{ ...containerStyles, ...passedStyles }}>
                    {equityRange.map(({ id, icon, commonName, localTicker, exchange: { shortName: exchangeName } }) => (
                        <Hint
                            yOffset={-60}
                            styles={showTickers ? styles.equity : styles.equityMinimal}
                            text={commonName}
                            key={`ticker-${id}`}
                        >
                            <Div
                                className="identifierTag"
                                styles={icon ? styles.identifierIcon : styles.identifierInitials}
                            >
                                {icon ? (
                                    <Img src={icon} />
                                ) : (
                                    <Text uppercase size={0}>
                                        {generateInitials(commonName)}
                                    </Text>
                                )}
                            </Div>
                            {showTickers && (
                                <Div styles={styles.text}>
                                    <Text
                                        styles={styles.ticker}
                                        weight="medium"
                                        className="localTicker"
                                        uppercase
                                        size={0}
                                    >
                                        {localTicker}
                                    </Text>
                                    {exchangeName && (
                                        <Text styles={styles.exchange} uppercase size={0}>
                                            {exchangeName}
                                        </Text>
                                    )}
                                </Div>
                            )}
                        </Hint>
                    ))}
                    {!keepOpen && equities.length > 1 && (
                        <Div styles={styles.toggle} onClick={toggle}>
                            <Text size={0}>{expanded ? '—' : `+ ${equities.length - 1}`}</Text>
                        </Div>
                    )}
                </Div>
            );
        }

        return null;
    }
}

export const CardEquitiesUI = compose(withStyleSheet(styleSheet))(CardEquities);
