import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import sortBy from 'lodash/sortBy';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';
import { withUrlContext } from 'hoc/url';
import { streamFragment, dashboardFragment, contentMatchFragment } from 'graphql/fragments/streams';
import { get } from 'utils';

export const streamMatchesQuery = gql`
    query withNewsKeyMentions(
        $contentId: ID
        $contentIdScalar: GenericScalar
        $userStreams: Boolean = true
        $quicklinkStreams: Boolean = true
    ) {
        content(filter: { contentIds: [$contentId] }) {
            id
            streams(filter: { userStreams: $userStreams, quicklinkStreams: $quicklinkStreams }) {
                ...stream
                dashboards {
                    ...dashboard
                }
                matches(
                    highlightSize: 0
                    filter: { rules: [{ ruleType: content_id, condition: is_equal, value: $contentIdScalar }] }
                ) {
                    averageSentimentScoreMovementPercent
                    results {
                        ...contentMatch
                    }
                }
            }
        }
    }
    ${dashboardFragment}
    ${streamFragment}
    ${contentMatchFragment}
`;

function normalizeMatch(match) {
    return {
        contentId: get(match, 'id'),
        text: get(match, 'highlights', [])
            .filter((h, i) => get(match, `highlightFields[${i}]`, '').split('.')[0] === 'text')
            .join(' ')
    };
}

const normalizeStreamMatches = (streams = []) => {
    const dashboardMap = {};
    const keyMentions = [];
    streams
        .filter(s => get(s, 'dashboards', []).length > 0 && get(s, 'matches.results.length', 0) > 0)
        .forEach(stream => {
            const dashboardId = get(stream, 'dashboards[0].id');
            const dashboard = dashboardMap[dashboardId];
            const dashboardName = get(stream, 'dashboards[0].name');
            const sentimentMovementPercent = get(stream, 'matches.averageSentimentScoreMovementPercent');
            const absMovement = Math.abs(sentimentMovementPercent);
            const match = {
                streamId: get(stream, 'id'),
                name: get(stream, 'name'),
                matches: get(stream, 'matches.results', []).flatMap(m => [
                    normalizeMatch(m),
                    ...get(m, 'collapsed', []).map(c => normalizeMatch(c))
                ])
            };

            if (sentimentMovementPercent && absMovement > 0.02) {
                if (absMovement > 1) {
                    match.sentimentMovementPercent = 3;
                } else if (absMovement > 0.25 && absMovement <= 1) {
                    match.sentimentMovementPercent = 2;
                } else if (absMovement >= 0 && absMovement <= 0.25) {
                    match.sentimentMovementPercent = 1;
                }
                match.sentimentMovement = sentimentMovementPercent;
            }
            if (dashboardName.toLowerCase().includes('quick links')) {
                keyMentions.push(match);
            } else if (dashboard) {
                dashboard.streams.push(match);
            } else {
                dashboardMap[dashboardId] = {
                    id: dashboardId,
                    name: dashboardName,
                    streams: [match]
                };
            }
        });

    const monitorMatches = Object.values(dashboardMap).map(dash => ({
        ...dash,
        streams: sortBy(dash.streams, s => get(s, 'matches.length', 0)).reverse()
    }));

    return {
        keyMentions,
        monitorMatches
    };
};

export const withData = () =>
    compose(
        withUrlContext(['newsId']),
        graphql(streamMatchesQuery, {
            props: ({ data }) => ({
                streams: get(data, 'content[0].streams', []),
                refreshStreams: data.refetch,
                loading: get(data, 'loading', false)
            }),
            options: ({ newsId, selectedTab }) => ({
                fetchPolicy: 'cache-and-network',
                variables: {
                    contentId: newsId,
                    // Because the rule value is a different type, we have to pass as sep variable
                    contentIdScalar: newsId,
                    userStreams: selectedTab === 'keyMentions',
                    quicklinkStreams: selectedTab === 'keyMentions'
                }
            }),
            skip: ({ selectedTab }) => selectedTab !== 'keyMentions'
        }),
        withMemo({ normalizeStreamMatches }),
        withProps(({ normalizeStreamMatches: normalize, streams }) => ({
            ...normalize(streams)
        }))
    );
