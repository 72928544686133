import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { WithPermission } from 'components/WithPermission';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class ContentTabs extends PureComponent {
    static propTypes = {
        left: PropTypes.number.isRequired,
        onSelect: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        passedRef: PropTypes.func,
        selectedOption: PropTypes.string.isRequired,
        setOptionRef: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        width: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {},
        passedRef: undefined
    };

    renderOptions() {
        const { onSelect, options, selectedOption, setOptionRef, styles } = this.props;
        return options.map(option => {
            const label = typeof option === 'object' ? get(option, 'label') : option;
            const value = typeof option === 'object' ? get(option, 'value') : option;
            const env = get(option, 'env');
            const permissions = get(option, 'permissions', []);
            const isSelected = value === selectedOption;
            const optionStyles = isSelected ? styles.optionSelected : styles.option;
            return (
                <WithPermission env={env} permissions={permissions} key={value}>
                    <Div
                        className={isSelected ? 'contentTab-selected' : 'contentTab'}
                        ref={node => setOptionRef(node, value)}
                        styles={optionStyles}
                        onClick={() => onSelect(value)}
                    >
                        <Text size={1}>{label}</Text>
                    </Div>
                </WithPermission>
            );
        });
    }

    render() {
        const { styles, passedStyles, left, width, passedRef } = this.props;
        return (
            <Div className="contentTabs print-hide" styles={{ ...styles.container, ...passedStyles }} ref={passedRef}>
                <Div className="contentTabs-options" styles={styles.options}>
                    {this.renderOptions()}
                    <Div styles={{ ...styles.selectedBar, left, width }} />
                </Div>
            </Div>
        );
    }
}

export const ContentTabsUI = compose(withStyleSheet(styleSheet))(ContentTabs);
