export const styleSheet = theme => ({
    container: {
        '.file-upload': {
            border: `2px dotted ${theme.colors.gray09}`,
            borderRadius: 6,
            color: theme.colors.gray08,
            cursor: 'pointer',
            input: {
                display: 'none'
            },
            fontSize: theme.fonts.fontSizes.size02,
            letterSpacing: '1px',
            lineHeight: '1.5em',
            opacity: 0.8,
            padding: 15,
            textAlign: 'center',
            textTransform: 'uppercase',
            ':hover': {
                color: theme.colors.black01,
                border: `2px dotted ${theme.colors.gray04}`,
                opacity: 1
            }
        },
        '.file-upload-more': {
            display: 'inline',
            marginLeft: 'calc(50% - 63px)',
            textDecoration: 'underline',
            color: theme.colors.gray08,
            cursor: 'pointer',
            input: {
                display: 'none'
            },
            fontSize: theme.fonts.fontSizes.size02,
            lineHeight: '1.5em',
            textAlign: 'center',
            ':hover': {
                color: theme.colors.black01
            }
        }
    },
    blueText: {
        color: theme.colors.blue11,
        fontWeight: theme.fonts.fontWeights.medium
    },
    bulkHint: {
        textAlign: 'center',
        color: theme.colors.gray08,
        marginTop: 10
    },
    buttonDelete: {
        position: 'relative',
        zIndex: 2,
        boxShadow: 'unset',
        height: 16,
        padding: 0,
        marginLeft: 10
    },
    error: {
        color: theme.colors.inputError
    },
    existingFile: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        ':hover': {
            '.progressBar': {
                backgroundColor: theme.colors.green09
            }
        }
    },
    existingFileContainerDrop: {
        height: 73,
        position: 'relative'
    },
    fileContainer: {
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.colors.blue05,
        borderRadius: 6,
        padding: '10px 10px 10px 14px',
        marginBottom: 10
    },
    fileText: {
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        alignItems: 'baseline',
        overflow: 'hidden',
        flex: 1
    },
    filename: {
        position: 'relative',
        zIndex: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginRight: 20
    },
    locked: {
        cursor: 'not-allowed',
        opacity: 0.8
    },
    progressBar: {
        backgroundColor: theme.colors.green11,
        top: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        transition: 'width 0.2s ease-in-out',
        zIndex: 1
    },
    spacer: {
        flex: 1
    },
    success: {
        color: theme.colors.green03
    },
    uploading: {}
});
