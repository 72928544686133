import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { withTrackUserActivity } from 'graphql/user';
import { withUpdateUserObjectSettings } from 'graphql/utils';
import { withData } from './data';
import { FilingUI } from './ui';

export class Filing extends PureComponent {
    static displayName = 'FilingContainer';

    static propTypes = {
        arrivalDate: PropTypes.string,
        body: PropTypes.string,
        company: PropTypes.string,
        companyId: PropTypes.string,
        contentType: PropTypes.string,
        copyright: PropTypes.string,
        displayType: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.any),
        exchangeName: PropTypes.string,
        filingError: PropTypes.bool,
        filingId: PropTypes.string,
        filingLoading: PropTypes.bool,
        formName: PropTypes.string,
        formNumber: PropTypes.string,
        formShortName: PropTypes.string,
        isAmendment: PropTypes.bool,
        isArchived: PropTypes.bool.isRequired,
        isStarred: PropTypes.bool.isRequired,
        isRead: PropTypes.bool.isRequired,
        license: PropTypes.string,
        markRead: PropTypes.func.isRequired,
        minimized: PropTypes.bool,
        officialUrl: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        page: PropTypes.string,
        pdfUrl: PropTypes.string,
        periodEndDate: PropTypes.string,
        publishedDate: PropTypes.string,
        setToolbarTitle: PropTypes.func,
        streamId: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.any),
        ticker: PropTypes.string,
        title: PropTypes.string,
        trackContentActivity: PropTypes.func.isRequired,
        userTags: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        arrivalDate: null,
        body: undefined,
        company: undefined,
        companyId: undefined,
        contentType: 'filing',
        copyright: undefined,
        displayType: undefined,
        events: [],
        exchangeName: undefined,
        filingError: false,
        filingId: null,
        filingLoading: false,
        formName: null,
        formNumber: null,
        formShortName: null,
        isAmendment: false,
        license: undefined,
        minimized: false,
        officialUrl: undefined,
        page: undefined,
        pdfUrl: undefined,
        periodEndDate: null,
        publishedDate: undefined,
        setToolbarTitle: null,
        streamId: null,
        tags: [],
        ticker: undefined,
        title: 'Loading...',
        userTags: []
    };

    constructor(props) {
        super(props);

        this.selectBlock = this.selectBlock.bind(this);
        this.containerRef = { current: null };
        this.documentTitle = document.title;
        this.scrolled = false;
        this.handleContainerRef = this.handleContainerRef.bind(this);
        this.blockExternalLinks = this.blockExternalLinks.bind(this);
    }

    componentDidMount() {
        const { isRead, markRead, trackContentActivity, filingId } = this.props;
        this.documentTitle = document.title;
        if (filingId) {
            trackContentActivity(filingId);
            if (!isRead) {
                markRead({ targetId: filingId, targetType: 'content', isRead: true });
            }
        }
        this.maybeScrollToMatch();
    }

    componentDidUpdate({ filingId: prevNewsId, filingLoading: prevLoading, title: prevTitle }) {
        const { filingId, trackContentActivity, filingLoading, title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | Filing | ${title}`;
        }

        if (filingId !== prevNewsId || filingLoading !== prevLoading) {
            this.scrolled = false;
            trackContentActivity(filingId);
            this.maybeScrollToMatch();
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle;
        if (this.containerRef && this.containerRef.current) {
            this.containerRef.current.removeEventListener('click', this.blockExternalLinks);
        }
    }

    blockExternalLinks(event) {
        if (event.target.tagName.toLowerCase() === 'a' && !event.target.href.includes('#')) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    handleContainerRef(current) {
        this.containerRef = { current };
        if (this.containerRef.current) {
            this.containerRef.current.addEventListener('click', this.blockExternalLinks);
        }
    }

    maybeScrollToMatch() {
        const { body, streamId } = this.props;
        if (streamId && body && this.containerRef.current) {
            const highlights = this.containerRef.current.getElementsByTagName('em');
            this.scrolled = true;
            if (highlights.length) {
                const firstHighlight = highlights[0];
                firstHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    selectBlock(newBlock) {
        const resetBlocks = new Promise(resolve => {
            const selectedBlocks = this.containerRef?.current?.querySelectorAll('.selectedBlock');
            selectedBlocks.forEach(node => {
                // eslint-disable-next-line
                node.className = '';
            });

            resolve();
        });

        resetBlocks.then(() => {
            if (newBlock) {
                newBlock.scrollIntoView({ block: 'center', behavior: 'smooth' });
                // eslint-disable-next-line
                newBlock.className = 'selectedBlock';
            }
        });
    }

    render() {
        const {
            arrivalDate,
            body,
            company,
            companyId,
            contentType,
            copyright,
            displayType,
            events,
            exchangeName,
            filingError,
            filingId,
            filingLoading,
            formName,
            formNumber,
            formShortName,
            isAmendment,
            isRead,
            isArchived,
            isStarred,
            license,
            minimized,
            officialUrl,
            page,
            pathname,
            pdfUrl,
            periodEndDate,
            publishedDate,
            setToolbarTitle,
            tags,
            ticker,
            title,
            userTags
        } = this.props;
        const initialSearchTerm = typeof page === 'string' ? page.split(',').join('|') : undefined;

        return (
            <FilingUI
                arrivalDate={arrivalDate}
                body={body}
                company={company}
                companyId={companyId}
                containerRef={this.containerRef}
                contentType={contentType}
                copyright={copyright}
                displayType={displayType}
                errorLoadingDetails={filingError}
                events={events}
                exchangeName={exchangeName}
                filingId={filingId}
                formName={formName}
                formNumber={formNumber}
                formShortName={formShortName}
                handleContainerRef={this.handleContainerRef}
                initialSearchTerm={initialSearchTerm}
                isAmendment={isAmendment}
                isArchived={isArchived}
                isRead={isRead}
                isStarred={isStarred}
                license={license}
                loadingDetails={filingLoading}
                minimized={minimized}
                officialUrl={officialUrl}
                pdfUrl={pdfUrl}
                pathname={pathname}
                periodEndDate={periodEndDate}
                publishedDate={publishedDate}
                selectBlock={this.selectBlock}
                setToolbarTitle={setToolbarTitle}
                tags={tags}
                ticker={ticker}
                title={title}
                userTags={userTags}
            />
        );
    }
}

export const FilingContainer = compose(
    withTrackUserActivity(),
    withUrlContext(['streamId', 'pathname', 'page']),
    withUpdateUserObjectSettings(),
    withData()
)(Filing);
