import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';
import { EventGroupCardUI } from './ui';

export class EventGroupCard extends PureComponent {
    static displayName = 'EventGroupCardContainer';

    static propTypes = {
        displayMode: PropTypes.string.isRequired,
        eventGroupId: PropTypes.string,
        eventGroupTitle: PropTypes.string,
        isPromoted: PropTypes.bool,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hostEquityId: PropTypes.string,
        hostEquityLocalTicker: PropTypes.string,
        hostEquityName: PropTypes.string,
        hostEquityExchangeName: PropTypes.string,
        numEvents: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        history: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        eventGroupId: null,
        eventGroupTitle: null,
        isPromoted: false,
        startDate: null,
        endDate: null,
        hostEquityId: null,
        hostEquityLocalTicker: null,
        hostEquityName: null,
        hostEquityExchangeName: null,
        numEvents: 0,
        styles: {}
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { eventGroupId, history } = this.props;
        if (eventGroupId) {
            history.push(generatePath(routes.conference, { eventGroupId }));
        }
    }

    render() {
        const {
            displayMode,
            eventGroupId,
            eventGroupTitle,
            isPromoted,
            startDate,
            endDate,
            hostEquityId,
            hostEquityLocalTicker,
            hostEquityName,
            hostEquityExchangeName,
            numEvents,
            styles
        } = this.props;

        return (
            <EventGroupCardUI
                displayMode={displayMode}
                styles={styles}
                eventGroupId={eventGroupId}
                eventGroupTitle={eventGroupTitle}
                isPromoted={isPromoted}
                startDate={startDate}
                endDate={endDate}
                hostEquityId={hostEquityId}
                hostEquityLocalTicker={hostEquityLocalTicker}
                hostEquityName={hostEquityName}
                hostEquityExchangeName={hostEquityExchangeName}
                numEvents={numEvents}
                onClick={this.handleClick}
                to={generatePath(routes.conference, { eventGroupId })}
            />
        );
    }
}

export const EventGroupCardContainer = compose(withUrlContext(['history']))(EventGroupCard);
