import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withEventDetails, withEventMediaPlayer } from 'graphql/audioCalls';
import { withUrlContext } from 'hoc/url';
import { EventMatchUI } from './ui';

export class EventMatch extends PureComponent {
    static displayName = 'EventMatchContainer';

    static propTypes = {
        data: PropTypes.objectOf(PropTypes.any),
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        index: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        searchTerm: PropTypes.string,
        selectedNode: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        onNodeSelect: PropTypes.func.isRequired
    };

    static defaultProps = {
        data: null,
        searchTerm: '',
        selectedNode: undefined,
        streamId: undefined,
        styles: undefined
    };

    render() {
        const {
            children,
            styles,
            onNodeSelect,
            index,
            total,
            data,
            mediaPlayer,
            searchTerm,
            selectedNode,
            streamId
        } = this.props;
        if (!data) {
            return null;
        }
        return (
            <EventMatchUI
                index={index}
                match={data}
                mediaPlayer={mediaPlayer}
                onNodeSelect={onNodeSelect}
                styles={styles}
                searchTerm={searchTerm}
                selectedNode={selectedNode}
                streamId={streamId}
                total={total}
            >
                {children}
            </EventMatchUI>
        );
    }
}

export const EventMatchContainer = compose(
    withUrlContext(['pageId', 'itemId']),
    withEventMediaPlayer({ fetchPolicy: 'cache-only' }),
    withEventDetails({
        fetchPolicy: 'cache-only'
    })
)(EventMatch);
