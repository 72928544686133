import sortBy from 'lodash/sortBy';
import { compose, withProps } from 'recompose';
import { EVENT_LOG_STATE_TYPES } from 'consts';
import { withEventLogs } from 'graphql/audioCalls';

const IGNORED_STATE_TYPES = [EVENT_LOG_STATE_TYPES.conference];

export const withData = () =>
    compose(
        withEventLogs(),
        withProps(({ logs, logsLoading }) => ({
            loading: logsLoading,
            logs: sortBy(
                (logs || []).filter(l => !IGNORED_STATE_TYPES.includes(l.state)),
                'timestamp'
            )
        }))
    );
