import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { BulkFollowIdentifiersResults } from 'components/BulkFollowIdentifiersResults';
import { Div } from 'components/Div';
import { ExternalLink } from 'components/ExternalLink';
import { Icon } from 'components/Icon';
import { LoaderDots } from 'components/LoaderDots';
import { TaggedInputOld } from 'components/TaggedInputOld';
import { Text } from 'components/Text';
import { Textarea } from 'components/Textarea';
import { styleSheet } from './stylesheet';

class BulkFollowIdentifiers extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        hideSectors: PropTypes.bool.isRequired,
        label: PropTypes.string,
        identifiers: PropTypes.arrayOf(PropTypes.string),
        identifiersInput: PropTypes.string.isRequired,
        isButtonDisabled: PropTypes.bool.isRequired,
        isReviewingResults: PropTypes.bool.isRequired,
        isSearchingSectors: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        maxTickersExceeded: PropTypes.bool.isRequired,
        onReviewResults: PropTypes.func.isRequired,
        onSectorsAdd: PropTypes.func.isRequired,
        onSectorsInputChange: PropTypes.func.isRequired,
        onSectorsInputKeyDown: PropTypes.func.isRequired,
        onSectorsRemove: PropTypes.func.isRequired,
        onTextAreaChange: PropTypes.func.isRequired,
        onToggleEquity: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchResultsSectors: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                numEquities: PropTypes.number
            })
        ),
        sectorIds: PropTypes.arrayOf(PropTypes.string),
        sectorsInput: PropTypes.string.isRequired,
        selectedSectors: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        ).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        subSectorIds: PropTypes.arrayOf(PropTypes.string),
        taggedInputRef: PropTypes.objectOf(PropTypes.object),
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        error: null,
        identifiers: null,
        label: null,
        loading: false,
        onToggleEquity: null,
        passedStyles: {},
        searchResultsSectors: null,
        sectorIds: null,
        subSectorIds: null,
        taggedInputRef: null
    };

    renderSearchResultsSectors() {
        const { styles, isSearchingSectors, onSectorsAdd, searchResultsSectors } = this.props;

        if (isSearchingSectors && searchResultsSectors && searchResultsSectors.length === 0) {
            return (
                <Div styles={styles.searchResultsSectorsContainer}>
                    <Text size={2}>No results found!</Text>
                </Div>
            );
        }

        if (isSearchingSectors && searchResultsSectors && searchResultsSectors.length) {
            const searchResults = searchResultsSectors.map((sector, idx) => {
                const { id, label, numEquities, subSectors } = sector;
                let subs;

                if (subSectors.length) {
                    subs = subSectors.map(subSector => {
                        const { id: subSectorId, label: subSectorLabel, numEquities: subSectorEquities } = subSector;
                        return (
                            <Div
                                key={`${subSectorId}-${subSectorLabel}`}
                                styles={styles.searchResultsSubSectorsRow}
                                onClick={() => onSectorsAdd(subSector)}
                            >
                                <Text size={1}>
                                    {subSectorEquities ? `${subSectorLabel} (${subSectorEquities})` : subSectorLabel}
                                </Text>
                            </Div>
                        );
                    });
                }

                return (
                    <Fragment key={`${id}-${label}`}>
                        {idx === 0 && (
                            <Text key="results-sectors-header" size={1} styles={styles.searchResultsSectorsRowHeader}>
                                Sectors / Sub-sectors (# of equities)
                            </Text>
                        )}
                        <Div styles={styles.searchResultsSectorsRow} onClick={() => onSectorsAdd(sector)}>
                            <Text size={1}>{numEquities ? `${label} (${numEquities})` : label}</Text>
                        </Div>
                        {subs}
                    </Fragment>
                );
            });

            return <Div styles={styles.searchResultsSectorsContainer}>{searchResults}</Div>;
        }

        return null;
    }

    render() {
        const {
            styles,
            passedStyles,
            error,
            hideSectors,
            identifiers,
            identifiersInput,
            isButtonDisabled,
            isReviewingResults,
            label,
            loading,
            maxTickersExceeded,
            onReviewResults,
            onSectorsInputChange,
            onSectorsInputKeyDown,
            onSectorsRemove,
            onTextAreaChange,
            onToggleEquity,
            sectorIds,
            sectorsInput,
            selectedSectors,
            subSectorIds,
            taggedInputRef,
            theme
        } = this.props;

        if (loading && !isReviewingResults) {
            return <LoaderDots />;
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {!!label && (
                    <Text label styles={styles.label}>
                        {label}
                    </Text>
                )}
                {isReviewingResults ? (
                    <BulkFollowIdentifiersResults
                        identifiers={identifiers}
                        onToggleEquity={onToggleEquity}
                        sectorIds={sectorIds}
                        subSectorIds={subSectorIds}
                        title="Review matched tickers"
                    />
                ) : (
                    <Fragment>
                        <Div styles={styles.textArea}>
                            <Textarea
                                autoFocus
                                error={maxTickersExceeded ? 'Too many tickers' : undefined}
                                name="description"
                                onChange={({ value }) => onTextAreaChange(value)}
                                placeholder="Enter a list of tickers separated by a comma or new line..."
                                value={identifiersInput}
                            />
                            {maxTickersExceeded && (
                                <Text size={1} styles={styles.maxTickersExceeded} uppercase>
                                    max limit of 1000 tickers reached
                                </Text>
                            )}
                        </Div>
                        {!hideSectors && (
                            <TaggedInputOld
                                styles={styles.taggedInput}
                                disabled={loading}
                                inputLabel="Search by sector*"
                                inputName="sectors"
                                inputRef={taggedInputRef}
                                inputValue={sectorsInput}
                                onInputChange={onSectorsInputChange}
                                onInputKeyDown={onSectorsInputKeyDown}
                                onTagRemove={onSectorsRemove}
                                tags={selectedSectors}
                            >
                                {this.renderSearchResultsSectors()}
                            </TaggedInputOld>
                        )}
                    </Fragment>
                )}
                {error && (
                    <Text size={3} styles={styles.error}>
                        {error}
                    </Text>
                )}
                {!isReviewingResults && (
                    <Div styles={styles.footer}>
                        <ExternalLink
                            href="https://aiera.notion.site/Creating-Importing-Watchlists-0ac782ec4e8e4dfdaa9f057aac57b43b#d0208441b53a42049ba91470d0b4fe19"
                            styles={styles.infoLinkText}
                        >
                            <Icon color={theme.colors.gray06} type="circleInfo02" />
                            <Text size={1}>Accepted Formats</Text>
                        </ExternalLink>
                        <ActionButton
                            disabled={isButtonDisabled}
                            loading={loading}
                            onClick={onReviewResults}
                            size={1}
                            styles={styles.button}
                        >
                            <Text size={1}>Find tickers</Text>
                        </ActionButton>
                    </Div>
                )}
            </Div>
        );
    }
}

export const BulkFollowIdentifiersUI = compose(withStyleSheet(styleSheet))(BulkFollowIdentifiers);
