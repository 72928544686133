import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { FILING_FORM_FILTERS } from 'consts/filters';
import { styleSheet } from './stylesheet';

class FilingStreamToolbar extends PureComponent {
    static propTypes = {
        filingForms: PropTypes.arrayOf(PropTypes.string).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleFilingForm: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { filingForms, passedStyles, styles, toggleFilingForm } = this.props;
        return (
            <Div className="filingStreamToolbar" styles={{ ...styles.container, ...passedStyles }}>
                {Object.keys(FILING_FORM_FILTERS).map(form => (
                    <Div key={form} styles={styles.checkbox} onClick={() => toggleFilingForm(form)}>
                        <Checkbox size={3} checked={filingForms.includes(form)} />
                        <Text size={1}>{form}</Text>
                    </Div>
                ))}
            </Div>
        );
    }
}

export const FilingStreamToolbarUI = compose(withStyleSheet(styleSheet))(FilingStreamToolbar);
