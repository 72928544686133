import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ResizeObserver from 'resize-observer-polyfill';
import { get } from 'utils';
import { TaggedInputUI } from './ui';

export class TaggedInput extends PureComponent {
    static displayName = 'TaggedInputContainer';

    static propTypes = {
        autoFocus: PropTypes.bool,
        canEditTags: PropTypes.bool,
        changeOnEnter: PropTypes.bool,
        error: PropTypes.string,
        getTagLabel: PropTypes.func,
        getTagStyle: PropTypes.func,
        icon: PropTypes.string,
        id: PropTypes.string,
        inputRef: PropTypes.objectOf(PropTypes.any),
        label: PropTypes.string,
        maxTags: PropTypes.number,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onInputChange: PropTypes.func.isRequired,
        onTagChange: PropTypes.func,
        placeholder: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tabIndex: PropTypes.number,
        tags: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
        textInputStyles: PropTypes.objectOf(PropTypes.any),
        tooltipComponent: PropTypes.element,
        tooltipOptions: PropTypes.objectOf(PropTypes.any),
        topRightText: PropTypes.string,
        topRightTextAlt: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        wrapTags: PropTypes.bool
    };

    static defaultProps = {
        autoFocus: false,
        canEditTags: false,
        changeOnEnter: false,
        error: '',
        getTagLabel: t => t,
        getTagStyle: () => {},
        icon: undefined,
        id: undefined,
        inputRef: undefined,
        label: null,
        maxTags: undefined,
        name: 'aieraTaggedInput',
        onBlur: undefined,
        onChange: undefined,
        onFocus: undefined,
        onTagChange: undefined,
        placeholder: 'Add term...',
        styles: undefined,
        tabIndex: 0,
        tags: [],
        textInputStyles: undefined,
        tooltipComponent: undefined,
        tooltipOptions: {},
        topRightText: undefined,
        topRightTextAlt: undefined,
        value: null,
        wrapTags: true
    };

    constructor(props) {
        super(props);

        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleEditBlur = this.handleEditBlur.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditFocus = this.handleEditFocus.bind(this);
        this.handleEditKeyDown = this.handleEditKeyDown.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.handleUnfocus = this.handleUnfocus.bind(this);
        this.handleContainerRef = this.handleContainerRef.bind(this);
        this.calculateVisibleTags = this.calculateVisibleTags.bind(this);

        this.showAllTags = this.showAllTags.bind(this);
        this.containerResizeObserver = new ResizeObserver(this.calculateVisibleTags);

        this.inputRef = React.createRef();

        this.state = {
            inFocus: false,
            tags: get(props, 'tags'),
            tagsVisible: get(props, 'tags', []).length,
            wrapTags: get(props, 'wrapTags'),
            editingTag: null,
            editingValue: null
        };
    }

    componentDidUpdate(prevProps) {
        const { tags: prevTags } = prevProps;
        const { tags: propTags } = this.props;
        const { tags } = this.state;

        if (propTags !== prevTags && propTags !== tags) {
            this.setState({
                tags: propTags,
                tagsVisible: (propTags || []).length
            });
        }
    }

    componentWillUnmount() {
        this.containerResizeObserver.disconnect();
    }

    showAllTags() {
        this.setState(({ tags }) => ({
            wrapTags: true,
            tagsVisible: tags.length
        }));
    }

    calculateVisibleTags(e) {
        const { wrapTags } = this.state;
        if (wrapTags) {
            return;
        }

        const resizedHeight = Math.floor(get(e, '[0].contentRect.height'));
        const containerHeight = this.containerRef ? Math.floor(this.containerRef.getBoundingClientRect().height) : 0;

        if (resizedHeight > 40 || containerHeight > 40) {
            this.setState(
                ({ tagsVisible }) => ({
                    tagsVisible: tagsVisible - 1
                }),
                this.calculateVisibleTags
            );
        }
    }

    focusInputRef() {
        const { inputRef } = this.props;
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        } else if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleContainerRef(node) {
        this.containerRef = node;
        if (node && this.containerRef) {
            this.containerResizeObserver.observe(this.containerRef);
        }
    }

    handleFocus(e) {
        const { onFocus } = this.props;
        const { editingTag } = this.state;
        if (!editingTag) {
            this.setState({ inFocus: true }, this.showAllTags);
            if (onFocus) onFocus(e);
            this.focusInputRef();
        }
    }

    handleUnfocus() {
        const { wrapTags } = this.props;
        const { editingTag } = this.state;
        if (!editingTag) {
            this.setState({ inFocus: false, wrapTags }, () => {
                if (!wrapTags) this.calculateVisibleTags();
            });
        }
    }

    handleAddTag(event) {
        const { name, onChange, onInputChange, value, maxTags } = this.props;
        event.persist();
        this.setState(
            ({ tags }) => {
                const withAddedTags = [...tags, value.trim()];
                const newTags = tags.includes(value) || withAddedTags.length > maxTags ? tags : withAddedTags;
                return {
                    tags: newTags,
                    tagsVisible: newTags.length
                };
            },
            () => {
                onInputChange({ event });
                const { tags } = this.state;
                if (onChange) {
                    onChange({
                        name,
                        event,
                        value: tags
                    });
                }
            }
        );
    }

    handleRemoveTag(event, tag) {
        event.persist();
        event.stopPropagation();
        this.setState(
            ({ tags }) => {
                const newTags = tags.filter(t => t !== tag);
                return {
                    inFocus: false,
                    tags: newTags,
                    tagsVisible: newTags.length
                };
            },
            () => {
                const { onChange, name, inputRef } = this.props;
                const { tags } = this.state;
                // Blur input and set focus to false
                // on clicking a tag to remove it
                (inputRef || this.inputRef).current.blur();
                if (onChange) {
                    onChange({
                        name,
                        event,
                        value: tags
                    });
                }
            }
        );
    }

    handleEditBlur(event) {
        event.stopPropagation();
        this.handleEditSave(event);
    }

    handleEditFocus(event, tag) {
        const { getTagLabel } = this.props;
        event.stopPropagation();
        this.setState({
            editingTag: tag,
            editingValue: getTagLabel(tag)
        });
    }

    handleEditChange(event) {
        this.setState(
            {
                editingValue: event.target.value
            },
            () => {
                const { onTagChange, name } = this.props;
                if (onTagChange) {
                    onTagChange({ event, value: event.target.value, name });
                }
            }
        );
    }

    handleEditSave(event) {
        this.setState(
            ({ tags, editingTag, editingValue }) => ({
                editingTag: null,
                editingValue: null,
                tags: tags.map(t => (t === editingTag ? editingValue : t)).filter(t => t)
            }),
            () => {
                const { onChange, name } = this.props;
                const { tags } = this.state;
                if (onChange) {
                    onChange({
                        name,
                        event,
                        value: tags
                    });
                }
            }
        );
    }

    handleEditKeyDown(event) {
        const key = get(event, 'key');
        const { editingTag } = this.state;
        if (key === 'Enter' && editingTag) {
            this.handleEditSave(event);
            this.focusInputRef();
        }
    }

    handleChange(e) {
        const { onInputChange, onChange, name } = this.props;

        if (onInputChange && !e.value.includes(',')) {
            onInputChange(e);
        } else if (e.value.includes(',')) {
            // Intercept pasted value to split multiple tags
            const addTags = e.value.split(',').map(t => t.trim());
            this.setState(
                ({ tags }) => {
                    const newTags = new Set([...tags, ...addTags]);
                    return {
                        tags: [...newTags],
                        tagsVisible: newTags.size
                    };
                },
                () => {
                    if (onChange) {
                        const { tags } = this.state;
                        onChange({ name, event: e, value: tags });
                    }
                }
            );
        }
    }

    handleInputKeyDown(event) {
        const key = get(event, 'key');
        const { tags: currentTags } = this.state;
        const { changeOnEnter, onInputChange, value } = this.props;
        if ([',', 'Enter', 'Tab'].includes(key) && value && !changeOnEnter) {
            if (key === ',') {
                event.preventDefault();
                event.stopPropagation();
            }
            this.handleAddTag(event);
        } else if (key === 'Backspace' && !value && currentTags.length) {
            this.setState(
                ({ tags }) => ({
                    tags: tags.slice(0, -1)
                }),
                () => {
                    const { onChange, name } = this.props;
                    const { tags } = this.state;
                    if (onChange) {
                        onChange({
                            name,
                            event,
                            value: tags
                        });
                    }
                }
            );
        } else if (key === 'Escape') {
            onInputChange({ event });
        }
    }

    render() {
        const {
            autoFocus,
            canEditTags,
            error,
            getTagLabel,
            getTagStyle,
            icon,
            id,
            inputRef,
            label,
            name,
            onBlur,
            placeholder,
            styles,
            tabIndex,
            textInputStyles,
            tooltipComponent,
            tooltipOptions,
            topRightText,
            topRightTextAlt,
            value,
            wrapTags
        } = this.props;
        const { inFocus, tags, tagsVisible, editingTag, editingValue } = this.state;
        return (
            <TaggedInputUI
                autoFocus={autoFocus}
                canEditTags={canEditTags}
                containerRef={this.handleContainerRef}
                editingTag={editingTag}
                editingValue={editingValue}
                error={error}
                getTagLabel={getTagLabel}
                getTagStyle={getTagStyle}
                icon={icon}
                id={id}
                inFocus={inFocus}
                inputRef={inputRef || this.inputRef}
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={this.handleChange}
                onEditBlur={this.handleEditBlur}
                onEditChange={this.handleEditChange}
                onEditFocus={this.handleEditFocus}
                onEditKeyDown={this.handleEditKeyDown}
                onFocus={this.handleFocus}
                onInputKeyDown={this.handleInputKeyDown}
                onRemoveTag={this.handleRemoveTag}
                onUnfocus={this.handleUnfocus}
                placeholder={placeholder}
                styles={styles}
                tabIndex={tabIndex}
                tags={tags}
                tagsVisible={tagsVisible}
                textInputStyles={textInputStyles}
                tooltipComponent={tooltipComponent}
                tooltipOptions={tooltipOptions}
                topRightText={topRightText}
                topRightTextAlt={topRightTextAlt}
                value={value}
                wrapTags={wrapTags}
            />
        );
    }
}

export const TaggedInputContainer = compose()(TaggedInput);
