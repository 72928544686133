import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import { generateTabURL, generateTabId } from 'utils';

import { STREAM_DISPLAY_MODES, TAB_TYPES } from 'consts';
import { AttachmentCardUI } from './ui';

export class AttachmentCard extends PureComponent {
    static displayName = 'AttachmentCardContainer';

    static propTypes = {
        attachmentType: PropTypes.string,
        company: PropTypes.string,
        displayMode: PropTypes.string,
        equities: PropTypes.arrayOf(PropTypes.any),
        eventId: PropTypes.string,
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightsMatches: PropTypes.arrayOf(PropTypes.any),
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        matchId: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        streamId: PropTypes.string,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        attachmentType: undefined,
        company: null,
        displayMode: STREAM_DISPLAY_MODES.default,
        equities: [],
        eventId: undefined,
        highlightColor: undefined,
        highlights: null,
        highlightsMatches: [],
        highlightTitle: null,
        score: undefined,
        styles: {},
        streamId: null
    };

    render() {
        const {
            attachmentType,
            company,
            displayMode,
            equities,
            eventId,
            highlightColor,
            highlights,
            highlightsMatches,
            highlightTitle,
            id,
            matchId,
            pathname,
            publishedDate,
            score,
            styles,
            streamId,
            title
        } = this.props;
        const firstMatch = highlightsMatches.find(m => m.length > 0);
        const page = firstMatch ? firstMatch.join(',') : '';
        const eventTabType = attachmentType === 'presentation' ? TAB_TYPES.pdfSlides : TAB_TYPES.pdfPresentation;
        const tabId = generateTabId({ id: eventId, tabType: eventTabType, page });
        return (
            <AttachmentCardUI
                company={company}
                displayMode={displayMode}
                equities={equities}
                highlightColor={highlightColor}
                highlights={highlights}
                highlightTitle={highlightTitle}
                id={id}
                matchId={matchId}
                onClick={this.handleClick}
                publishedDate={publishedDate}
                score={score}
                streamId={streamId}
                styles={styles}
                title={title}
                to={generateTabURL({ tabId, pathname })}
            />
        );
    }
}

export const AttachmentCardContainer = compose(withUrlContext(['pathname']))(AttachmentCard);
