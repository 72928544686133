import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { DASHBOARD_ENDPOINT_BETA, DASHBOARD_ENDPOINT_PROD } from 'configuration';
import { PERMISSIONS } from 'consts';
import { isBeta } from 'utils';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { ExternalLink } from 'components/ExternalLink';
import { WithPermission } from 'components/WithPermission';
import { routes } from 'routes';
import { styleSheet } from './stylesheet';

class UserMenu extends PureComponent {
    static propTypes = {
        billingSource: PropTypes.string,
        organizationAdmin: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        userName: PropTypes.string.isRequired
    };

    static defaultProps = {
        billingSource: null
    };

    renderMenuOption({ label, icon, route, externalRoute, permission }) {
        const { styles, theme } = this.props;
        return (
            <WithPermission permissions={permission}>
                <Div styles={styles.hoverMenuOption} data-tname={`user-menu-${label}`}>
                    {externalRoute ? (
                        <ExternalLink href={externalRoute} styles={styles.externalLink}>
                            <Div styles={styles.iconWrapper}>
                                <Icon type={icon} color={theme.colors.gray04} />
                            </Div>
                            <Text size={2}>{label}</Text>
                            <Div styles={styles.optionHoverIndicator} className="optionHoverIndicator" />
                        </ExternalLink>
                    ) : (
                        <Link to={route}>
                            <Div styles={styles.iconWrapper}>
                                <Icon type={icon} color={theme.colors.gray04} />
                            </Div>
                            <Text size={2}>{label}</Text>
                            <Div styles={styles.optionHoverIndicator} className="optionHoverIndicator" />
                        </Link>
                    )}
                </Div>
            </WithPermission>
        );
    }

    renderMenuButton(showTooltip) {
        const { styles, theme, userName } = this.props;

        return (
            <Div
                styles={styles.menuButton}
                onClick={showTooltip === false ? undefined : showTooltip}
                className="menuButton"
            >
                <Div className="userInfoIconWrapper" styles={styles.iconWrapper}>
                    <Icon type="account02" color={theme.colors.gray04} />
                </Div>
                <Div styles={styles.userInfoText}>
                    {userName && (
                        <Text className="userNameText" size={2} styles={styles.name}>
                            {userName}
                        </Text>
                    )}
                </Div>
                <Div className="chevron" styles={styles.chevron}>
                    <Icon type="chevron02" color={theme.colors.gray08} />
                </Div>
            </Div>
        );
    }

    renderTooltip() {
        const { styles, billingSource, organizationAdmin } = this.props;
        return (
            <Fragment>
                {this.renderMenuButton(false)}
                {isBeta() &&
                    this.renderMenuOption({
                        label: 'Leave Beta',
                        icon: 'beta',
                        externalRoute: `${DASHBOARD_ENDPOINT_PROD}${routes.home.slice(1)}`
                    })}
                {!isBeta() &&
                    this.renderMenuOption({
                        label: 'Aiera Beta',
                        icon: 'beta',
                        externalRoute: `${DASHBOARD_ENDPOINT_BETA}${routes.home.slice(1)}`,
                        permission: PERMISSIONS.featureBeta
                    })}
                {this.renderMenuOption({ label: 'Account', icon: 'account', route: routes.account })}
                {this.renderMenuOption({ label: 'Alerts', icon: 'bell', route: routes.alerts })}
                {organizationAdmin &&
                    (!billingSource || billingSource === 'manual') &&
                    this.renderMenuOption({ label: 'Manage Team', icon: 'people', route: routes.manageTeam })}
                {organizationAdmin &&
                    billingSource &&
                    billingSource !== 'manual' &&
                    this.renderMenuOption({ label: 'Billing', icon: 'billing', route: routes.billing })}
                {this.renderMenuOption({ label: 'Support', icon: 'help', route: routes.support })}
                {this.renderMenuOption({
                    label: 'Help Center',
                    icon: 'circleInfo02',
                    externalRoute: 'https://support.aiera.com'
                })}
                <Div styles={styles.signOutButton}>
                    <Link to={routes.signOut}>
                        <Text size={0} uppercase>
                            Sign out
                        </Text>
                    </Link>
                </Div>
            </Fragment>
        );
    }

    render() {
        const { styles } = this.props;
        return (
            <Tooltip
                isEnabled
                styles={styles.tooltip}
                useElementOffsetLeft
                useElementOffsetBottom
                useMinHoverWidth
                useMaxHoverWidth
                xOffset={0}
                useOutsideClickHandler
                content={this.renderTooltip()}
            >
                {({ showTooltip }) => this.renderMenuButton(showTooltip)}
            </Tooltip>
        );
    }
}

export const UserMenuUI = compose(withStyleSheet(styleSheet))(UserMenu);
