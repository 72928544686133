import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Svg = styled('svg')(props => ({
    ...props.style,
    ...props.styles,
    path: {
        transition: 'all 0.1s ease-in-out'
    }
}));

const arrowLeftCurved = ({ styles, color, opacity, height = 146, width = 152 }) => () => (
    <Svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} style={styles}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={opacity}
        >
            <g id="Artboard-arrowLeftCurved" stroke={color} strokeWidth="3">
                <g id="ArrowLeftCurved" transform="translate(2.000000, 1.000000)">
                    <polyline
                        id="Path"
                        transform="translate(21.642578, 24.403742) rotate(-19.000000) translate(-21.642578, -24.403742) "
                        points="23.9226679 43.807484 6 13.6757812 37.2851562 5"
                    />
                    <path
                        d="M6.40314137,19.7560627 C117.453933,19.7560627 117.453933,143.422078 147.660954,143.422078"
                        id="Path-2"
                    />
                </g>
            </g>
        </g>
    </Svg>
);

const backdrop = ({ styles, color, opacity, height = 87, width = 87 }) => () => (
    <Svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} styles={styles}>
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={opacity}>
            <g id="Artboard-Copy-93" fill={color}>
                <path
                    d="M56.101228,87 C82.1382585,87 87,64.3158386 87,41.1108281 C87,17.9058175 74.0007956,0.0882332539 47.9637651,0.0882332539 C21.9267345,0.0882332539 0,-2.52416951 0,20.6808411 C0,32.3875699 19.8364478,31.8595132 34.1064914,47.6638428 C48.3765349,63.4681724 25.8216107,87 56.101228,87 Z"
                    id="backdrop"
                />
            </g>
        </g>
    </Svg>
);

const doubleArrowLeft = ({ styles, color, opacity, height = 402, width = 91 }) => () => (
    <Svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} styles={styles}>
        <g
            id="Companies"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={opacity}
        >
            <g id="Artboard-Copy-3" stroke={color} strokeWidth="4">
                <g id="Group-16" transform="translate(3.000000, 3.000000)">
                    <polyline
                        id="Path-6"
                        transform="translate(11.000000, 18.000000) rotate(-7.000000) translate(-11.000000, -18.000000) "
                        points="13.1303933 35 2 14.3770492 20 1"
                    />
                    <path
                        d="M85.9101563,169 C27.7401969,148.357975 45.7254445,95.9047514 46.75,56 C47.7401969,17.433458 4,15.3770492 4,15.3770492"
                        id="Path-7"
                    />
                    <path
                        d="M85.9101563,397 C-7.6953125,356 45.7254445,95.9047514 46.75,56 C47.7401969,17.433458 4,15.3770492 4,15.3770492"
                        id="Path-7"
                    />
                </g>
            </g>
        </g>
    </Svg>
);

const featuredBoxBg = ({ styles, height = 153, width = 782 }) => () => (
    <Svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} styles={styles}>
        <defs>
            <filter x="-15.1%" y="-64.1%" width="130.3%" height="228.3%" filterUnits="objectBoundingBox" id="filter-1">
                <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
            </filter>
            <filter x="-15.1%" y="-64.1%" width="130.3%" height="228.3%" filterUnits="objectBoundingBox" id="filter-2">
                <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
            </filter>
            <filter x="-15.1%" y="-64.1%" width="130.3%" height="228.3%" filterUnits="objectBoundingBox" id="filter-3">
                <feGaussianBlur stdDeviation="10" in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard-Copy-4" transform="translate(30.000000, 34.000000)">
                <g id="Group" transform="translate(0.000000, -4.000000)">
                    <path
                        d="M606.541586,82 C639.954814,82 667.041586,71.9264069 667.041586,59.5 C667.041586,47.0735931 639.954814,37 606.541586,37 C573.128359,37 560.041586,62.5 514.541586,54 C469.041586,45.5 466.002474,76.8460228 470.529867,80 C480.710758,87.0924462 583.413368,82 606.541586,82 Z"
                        id="Oval"
                        fillOpacity="0.054496285"
                        fill="#9B25C3"
                        filter="url(#filter-1)"
                    />
                    <path
                        d="M376.541586,45 C409.954814,45 437.041586,34.9264069 437.041586,22.5 C437.041586,10.0735931 409.954814,-1.17239551e-13 376.541586,-1.17239551e-13 C343.128359,-1.17239551e-13 330.041586,25.5 284.541586,17 C239.041586,8.5 236.002474,39.8460228 240.529867,43 C250.710758,50.0924462 353.413368,45 376.541586,45 Z"
                        id="Oval"
                        fillOpacity="0.0613527098"
                        fill="#2FFF64"
                        filter="url(#filter-2)"
                        transform="translate(338.020793, 23.384821) scale(1, -1) translate(-338.020793, -23.384821) "
                    />
                    <path
                        d="M137.541586,91 C170.954814,91 198.041586,80.9264069 198.041586,68.5 C198.041586,56.0735931 170.954814,46 137.541586,46 C104.128359,46 91.0415865,71.5 45.5415865,63 C0.041586496,54.5 -2.99752639,85.8460228 1.5298675,89 C11.7107583,96.0924462 114.413368,91 137.541586,91 Z"
                        id="Oval"
                        fillOpacity="0.0878496503"
                        fill="#2FD0FF"
                        filter="url(#filter-3)"
                    />
                </g>
            </g>
        </g>
    </Svg>
);

const newReport = ({ styles, color, opacity, height = 124, width = 114 }) => () => (
    <Svg width={`${width}px`} height={`${height}px`} viewBox="0 0 114 124" style={styles}>
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={opacity}>
            <g id="new-report" fill={color}>
                <path
                    d="M73.1844952,123.023438 C107.150007,123.023438 113.492188,93.5995189 113.492188,63.5 C113.492188,33.4004811 96.5346227,10.2890625 62.5691106,10.2890625 C28.6035985,10.2890625 7.10542736e-15,6.90048114 7.10542736e-15,37 C7.10542736e-15,52.1849492 25.8768029,51.5 44.4921875,72 C63.1075721,92.5 33.6844952,123.023438 73.1844952,123.023438 Z"
                    id="Oval"
                    fill="#5E6185"
                    opacity="0.116954985"
                />
                <path
                    d="M43.1921875,0 C40.0601553,0 37.4921875,2.53417875 37.4921875,5.625 L37.4921875,39.375 C37.4921875,42.4658212 40.0601553,45 43.1921875,45 L69.7921875,45 C72.9242197,45 75.4921875,42.4658212 75.4921875,39.375 L75.4921875,12.3486337 L62.9789053,0 L43.1921875,0 Z M43.3671875,4 L60.2421875,4 L60.2421875,15.4 L71.4921875,15.4 L71.4921875,40.1 C71.4921875,41.16875 70.671875,42 69.6171875,42 L43.3671875,42 C42.3125,42 41.4921875,41.16875 41.4921875,40.1 L41.4921875,5.9 C41.4921875,4.83125 42.3125,4 43.3671875,4 Z M64.4921875,6 L69.4921875,11 L64.4921875,11 L64.4921875,6 Z M49.4921875,30 L49.4921875,34 L60.4921875,34 L60.4921875,30 L49.4921875,30 Z"
                    id="Shape"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                />
                <polygon
                    id="Path"
                    fill="#FFEC00"
                    fillRule="nonzero"
                    points="49.4921875 22 49.4921875 26 64.4921875 26 64.4921875 22"
                />
            </g>
        </g>
    </Svg>
);

class Graphic extends PureComponent {
    static propTypes = {
        color: PropTypes.string,
        height: PropTypes.number,
        opacity: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.any),
        type: PropTypes.string.isRequired,
        width: PropTypes.number
    };

    static defaultProps = {
        color: 'url(#linearGradient-1)',
        height: undefined,
        opacity: 1,
        styles: {},
        width: undefined
    };

    render() {
        const { type, color, opacity, styles, height, width } = this.props;
        const attributes = { styles, color, height, width, opacity };
        const graphics = {
            arrowLeftCurved: arrowLeftCurved(attributes),
            backdrop: backdrop(attributes),
            doubleArrowLeft: doubleArrowLeft(attributes),
            featuredBoxBg: featuredBoxBg(attributes),
            newReport: newReport(attributes)
        };

        return typeof graphics[type] === 'function' ? graphics[type]() : null;
    }
}

export const GraphicUI = Graphic;
