import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { BarChartUI } from './ui';

export class BarChart extends PureComponent {
    static displayName = 'BarChartContainer';

    static propTypes = {
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        data: PropTypes.arrayOf(PropTypes.any),
        height: PropTypes.number,
        onSeriesPointClick: PropTypes.func
    };

    static defaultProps = {
        data: [],
        height: undefined,
        onSeriesPointClick: null
    };

    constructor(props) {
        super(props);

        this.handleSeriesPointClick = this.handleSeriesPointClick.bind(this);
    }

    handleSeriesPointClick(event) {
        const { onSeriesPointClick } = this.props;
        if (onSeriesPointClick) onSeriesPointClick(event);
    }

    render() {
        const { labels, data, height } = this.props;
        return (
            <BarChartUI labels={labels} height={height} data={data} onSeriesPointClick={this.handleSeriesPointClick} />
        );
    }
}

export const BarChartContainer = compose()(BarChart);
