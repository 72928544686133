import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { DialPad } from 'components/DialPad';
import { styleSheet } from './stylesheet';

class EventDialer extends PureComponent {
    static propTypes = {
        focusMode: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleDialPad: PropTypes.func,
        onTone: PropTypes.func,
        onCall: PropTypes.func,
        onHangUp: PropTypes.func,
        number: PropTypes.string,
        pin: PropTypes.string,
        connected: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        toggleDialPad: () => {},
        onTone: () => {},
        onCall: () => {},
        onHangUp: () => {},
        number: '',
        pin: '',
        connected: false
    };

    render() {
        const {
            focusMode,
            number,
            pin,
            connected,
            toggleDialPad,
            onTone,
            onCall,
            onHangUp,
            styles,
            passedStyles,
            theme
        } = this.props;
        const bottom = focusMode ? 'calc(50% - 273px)' : 'calc(50% - 315px)';
        return (
            <Div styles={{ ...styles.container, bottom, ...passedStyles }}>
                <Div styles={styles.close} onClick={toggleDialPad}>
                    <Icon type="xMark" color={theme.colors.gray04} />
                </Div>
                <Div styles={[styles.info]} size={3}>
                    <Span styles={styles.noWrap}>
                        <Text span size={2} weight="medium">
                            Dial in:&nbsp;
                        </Text>
                        <Text span size={2} styles={styles.number}>
                            +1 {number}
                        </Text>
                    </Span>
                    <Span styles={styles.noWrap}>
                        <Text span size={2} weight="medium">
                            PIN:&nbsp;
                        </Text>
                        <Text span size={2}>
                            {pin}
                        </Text>
                    </Span>
                </Div>
                <DialPad
                    connected={connected}
                    onTone={onTone}
                    onCall={onCall}
                    onHangUp={onHangUp}
                    styles={styles.dialpad}
                />
            </Div>
        );
    }
}

export const EventDialerUI = compose(withStyleSheet(styleSheet))(EventDialer);
