export const styleSheet = theme => ({
    container: {
        position: 'absolute',
        right: 5,
        bottom: 6,
        padding: '2px 5px',
        borderRadius: 6,
        cursor: 'pointer',
        backgroundColor: theme.colors.yellow09,
        svg: {
            height: 10
        },
        ':hover': {
            backgroundColor: theme.colors.yellow07
        },
        ':active': {
            backgroundColor: theme.colors.yellow08,
            'svg g': {
                fill: theme.colors.white01
            }
        }
    }
});
