import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/react-hoc';
import storage from 'localforage';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import LogRocket from 'logrocket';

import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@emotion/react';

import { theme } from 'styles';
import { reducers } from 'reducers';
import { aieraReporting } from 'middleware';
import { configureGraphqlClient } from 'provider/graphql';
import { ReportingProvider, configureReporting } from 'provider/reporting';
import { configureBugsnag } from 'provider/bugsnag';
import { configureRealtimeClient, RealtimeProvider } from 'provider/realtime';
import { configureMobileBridge, MobileBridgeProvider } from 'provider/mobileBridge';
import { configureIntegration, IntegrationProvider } from 'provider/integration';
import { config } from 'configuration';

const persistConfig = {
    key: 'root',
    blacklist: ['StatusBanner', 'App', 'Media', 'AlertToast'],
    storage
};

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));

const middlewares = [aieraReporting, thunk];

if (config.SHOW_REDUX_LOGGER) {
    middlewares.push(logger);
}

// MUST BE LAST MIDDLEWARE
if (config.LOGROCKET_ENABLED) {
    middlewares.push(
        LogRocket.reduxMiddleware({
            actionSanitizer: action => {
                if (action.type === 'USER_LOGIN') {
                    return null;
                }
                return action;
            }
        })
    );
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

const persistor = persistStore(store);
export const history = createBrowserHistory();

export const { bugsnagClient } = configureBugsnag(config);
const mobileBridge = configureMobileBridge();
const integration = configureIntegration();

export const graphqlClient = configureGraphqlClient(config, {
    store,
    bugsnagClient,
    mobileBridge,
    integration,
    history
});

// Kickoff identify reporting
const reporter = configureReporting(config, { bugsnagClient, graphqlClient });

if (config.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__APOLLO_CLIENT__ = graphqlClient;
}

export const realtime = configureRealtimeClient(config);

export class AieraProvider extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    render() {
        const { children } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ReportingProvider reporter={reporter}>
                            <ApolloProvider client={graphqlClient}>
                                <MobileBridgeProvider bridge={mobileBridge}>
                                    <IntegrationProvider integration={integration}>
                                        <RealtimeProvider realtime={realtime}>
                                            <Router history={history}>{children}</Router>
                                        </RealtimeProvider>
                                    </IntegrationProvider>
                                </MobileBridgeProvider>
                            </ApolloProvider>
                        </ReportingProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        );
    }
}
