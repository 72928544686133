import { types } from 'actions/publicAskAiera';

const { PUBLIC_ASK_AIERA_ADD_QUESTION, PUBLIC_ASK_AIERA_EMAIL_QUESTION, PUBLIC_ASK_AIERA_HIDE_SPLASH } = types;

const initialState = {
    questionIds: [],
    providedEmailIds: [],
    hideSplash: false
};

export function publicAskAiera(state = initialState, action) {
    const { type, payload } = action;

    if (type === PUBLIC_ASK_AIERA_ADD_QUESTION) {
        return {
            ...state,
            questionIds: [...state.questionIds, payload.question]
        };
    }

    if (type === PUBLIC_ASK_AIERA_EMAIL_QUESTION) {
        return {
            ...state,
            providedEmailIds: [...state.providedEmailIds, payload.id]
        };
    }

    if (type === PUBLIC_ASK_AIERA_HIDE_SPLASH) {
        return {
            ...state,
            hideSplash: true
        };
    }

    return state;
}
