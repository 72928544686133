import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { ModuleTrUI } from './ui';

export class ModuleTr extends PureComponent {
    static displayName = 'ModuleTrContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ModuleTrUI {...rest} />;
    }
}

export const ModuleTrContainer = compose()(ModuleTr);
