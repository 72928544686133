export const styleSheet = theme => {
    const helpContainer = {
        cursor: 'pointer',
        display: 'inline-block',
        paddingTop: 8,
        p: {
            color: theme.colors.blue01
        }
    };
    const helpContentSpanHighlighted = {
        backgroundColor: theme.colors.yellow04,
        borderRadius: 2,
        color: theme.colors.black01,
        padding: 2
    };
    return {
        container: {
            borderTop: `1px solid ${theme.colors.gray01}`,
            margin: '25px 35px'
        },
        callerIdFootnote: {
            color: theme.colors.gray04,
            paddingTop: 8
        },
        checkbox: {
            p: {
                color: 'inherit',
                fontSize: theme.fonts.fontSizes.size03,
                letterSpacing: 'normal',
                textTransform: 'unset'
            },
            '.rowBox': {
                border: `1px solid ${theme.colors.gray04}`,
                marginRight: theme.margins.margin00
            },
            '.rowBoxChecked': {
                marginRight: theme.margins.margin00
            },
            ':hover': {
                '.rowBox': {
                    border: `1px solid ${theme.colors.gray06}`
                }
            }
        },
        connectUrl: {
            display: 'flex',
            flexDirection: 'column'
        },
        formRow: {
            paddingTop: 40
        },
        formRowFirst: {
            paddingTop: 30
        },
        heading: {
            padding: '25px 0 5px'
        },
        helpContainer: {
            ...helpContainer
        },
        helpContainerPadded: {
            ...helpContainer,
            marginLeft: 44
        },
        helpContentContainer: {
            backgroundColor: theme.colors.gray12,
            borderRadius: 2,
            color: theme.colors.white01,
            lineHeight: '1.2em',
            padding: 15
        },
        helpContentSpan: {
            marginLeft: 40
        },
        helpContentSpanHighlighted: {
            ...helpContentSpanHighlighted
        },
        helpContentSpanHighlightedPadded: {
            ...helpContentSpanHighlighted,
            marginLeft: 40
        },
        input: {
            ...theme.content.inputErrorSmall
        },
        inputExample: {
            color: theme.colors.gray04,
            marginLeft: 'auto',
            paddingTop: 8
        },
        optionDescription: {
            color: theme.colors.gray04,
            lineHeight: '1.3em',
            paddingTop: 6
        },
        optionPadded: {
            'div:first-of-type': {
                paddingRight: 10
            }
        },
        upload: {
            maxWidth: 420,
            display: 'flex',
            flexDirection: 'column'
        },
        zoomMeetingTypeToggle: {
            height: 'unset',
            p: {
                fontSize: theme.fonts.fontSizes.size03,
                textTransform: 'none'
            }
        }
    };
};
