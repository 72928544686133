import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import {
    billingInvoiceFragment,
    billingInvoiceLineItemFragment,
    billingProductFragment,
    billingProductPriceFragment,
    billingSubscriptionFragment
} from 'graphql/fragments/billing';
import { userFragment } from 'graphql/fragments/user';
import { graphql } from 'graphql/utils';
import { get } from 'utils';
import { mapPropsToOptions } from '../hoc/utils';

export const withUpdateSubscription = (options = {}) =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation UpdateSubscription(
                    $newSeats: [SubscriptionSeatInput]
                    $removedSeats: [SubscriptionSeatInput]
                    $updatedSeats: [SubscriptionSeatInput]
                    $preview: Boolean = false
                ) {
                    updateSubscription(
                        newSeats: $newSeats
                        removedSeats: $removedSeats
                        updatedSeats: $updatedSeats
                        preview: $preview
                    ) {
                        query {
                            currentUser {
                                id
                                ...user
                                billingProductPrice {
                                    ...billingProductPrice
                                    product {
                                        ...billingProduct
                                    }
                                }
                                organization {
                                    id
                                    activeSubscription {
                                        ...billingSubscription
                                        upcomingInvoice {
                                            id
                                            total
                                            currency {
                                                id
                                                symbol
                                                symbolPrefix
                                                minorSymbol
                                                minorSymbolPrefix
                                            }
                                        }
                                    }
                                    invoices {
                                        ...billingInvoice
                                        lines {
                                            ...billingInvoiceLineItem
                                            price {
                                                ...billingProductPrice
                                            }
                                        }
                                    }
                                    users(includeInvited: true) {
                                        id
                                        billingProductPriceId
                                        email: username
                                        firstName
                                        lastName
                                        organizationAdmin
                                        status
                                    }
                                }
                            }
                        }
                        immediateInvoice @include(if: $preview) {
                            ...billingInvoice
                            currency {
                                id
                                symbol
                                symbolPrefix
                                minorSymbol
                                minorSymbolPrefix
                            }
                            lines {
                                ...billingInvoiceLineItem
                                price {
                                    ...billingProductPrice
                                }
                            }
                        }
                        nextInvoice @include(if: $preview) {
                            ...billingInvoice
                            currency {
                                id
                                symbol
                                symbolPrefix
                                minorSymbol
                                minorSymbolPrefix
                            }
                            lines {
                                ...billingInvoiceLineItem
                                price {
                                    ...billingProductPrice
                                }
                            }
                        }
                    }
                }
                ${userFragment}
                ${billingSubscriptionFragment}
                ${billingProductFragment}
                ${billingProductPriceFragment}
                ${billingInvoiceFragment}
                ${billingInvoiceLineItemFragment}
            `,
            {
                props: ({ mutate, ownProps }) => {
                    const { apolloAbort, setApolloAbort, setStatusBanner } = ownProps;
                    const { abortable = true } = mapPropsToOptions(options, ownProps);
                    return {
                        previewSubscription: input => {
                            if (abortable) apolloAbort();
                            return mutate({
                                context: {
                                    abortable,
                                    setAbort: abortable ? setApolloAbort : undefined
                                },
                                variables: {
                                    ...input,
                                    preview: true
                                }
                            })
                                .then(({ data }) => get(data, 'updateSubscription'))
                                .catch(error => {
                                    setStatusBanner(`${error}`, 'error', 'circleX', 5000);
                                    throw error;
                                });
                        },
                        updateSubscription: input =>
                            mutate({
                                context: { abortable: false },
                                variables: {
                                    ...input,
                                    preview: false
                                }
                            })
                                .then(() => {
                                    setStatusBanner('Subscription updated successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error updating subscription: ${error}`, 'error', 'circleX');
                                    throw error;
                                })
                    };
                }
            }
        )
    );
