import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { get } from 'utils';
import { graphql } from 'graphql/utils';
import { withMemo } from 'hoc/utils';

function normalizeEventGroups(eventGroups = []) {
    const options = eventGroups.map(({ id, title }) => ({
        label: title,
        value: id
    }));

    return {
        options
    };
}

export const withData = () =>
    compose(
        graphql(
            gql`
                query withEventGroups($term: String) {
                    eventGroups(filter: { titlePrefix: $term }) {
                        id
                        title
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    eventGroups: get(data, 'eventGroups', [])
                }),
                skip: ({ term }) => !term,
                options: ({ term }) => ({
                    fetchPolicy: 'cache-first',
                    context: {
                        debounceKey: 'withEventGroupAutocomplete',
                        debounceTimeout: term && term.length ? 300 : 0
                    }
                })
            }
        ),
        withMemo({ normalizeEventGroups }),
        withProps(({ normalizeEventGroups: normalize, eventGroups }) => ({
            ...normalize(eventGroups)
        }))
    );
