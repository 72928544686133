import gql from 'graphql-tag';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { notificationFragment } from 'graphql/fragments/alerts';
import { scheduledAudioCallFragment } from 'graphql/fragments/audioCalls';
import { dashboardFragment, streamFragment } from 'graphql/fragments/streams';
import { withMarkAlertsRead } from 'graphql/notifications';
import { graphql } from 'graphql/utils';
import { withRealtime } from 'provider/realtime';
import { get } from 'utils';

export const withData = () =>
    compose(
        withRealtime(),
        lifecycle({
            componentDidMount() {
                // Subscribe to new notifications once we have the user pusherToken
                this.trySubscribe = () => {
                    if (!this.subscription) {
                        const { pusherToken, realtime } = this.props;
                        if (pusherToken) {
                            this.subscription = realtime.subscribe(
                                `user_${pusherToken}`,
                                'new_notifications',
                                ({ notification_ids: notificationIds }) => {
                                    this.setState({ notificationIds });
                                }
                            );
                        }
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },
            componentWillUnmount() {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.subscription = null;
                }
            }
        }),
        withPropsOnChange(['notificationIds'], ({ notificationIds }) => ({
            notificationId: (notificationIds || [])[0],
            notificationsCount: (notificationIds || []).length
        })),
        graphql(
            gql`
                query withAlertBanner($notificationId: ID, $withNotification: Boolean = false) {
                    currentUser {
                        id
                        pusherToken
                    }
                    notifications(notificationIds: [$notificationId]) @include(if: $withNotification) {
                        ...notification
                        dashboard {
                            ...dashboard
                        }
                        equity {
                            id
                            commonName
                        }
                        event {
                            ...scheduledAudioCall
                        }
                        stream {
                            ...stream
                        }
                    }
                }
                ${notificationFragment}
                ${dashboardFragment}
                ${scheduledAudioCallFragment}
                ${streamFragment}
            `,
            {
                props: ({ data }) => {
                    const notification = get(data, 'notifications[0]');
                    return {
                        alert: notification,
                        content: get(notification, 'content'),
                        created: get(notification, 'created'),
                        dashboard: get(notification, 'dashboard'),
                        deleted: get(notification, 'deleted'),
                        equity: get(notification, 'equity'),
                        event: get(notification, 'event'),
                        loading: get(data, 'loading'),
                        message: get(notification, 'message'),
                        notificationType: get(notification, 'notificationType'),
                        pusherToken: get(data, 'currentUser.pusherToken'),
                        stream: get(notification, 'stream'),
                        transcriptId: get(notification, 'transcriptId')
                    };
                },
                options: ({ notificationId }) => ({
                    fetchPolicy: notificationId ? 'cache-first' : 'cache-only',
                    variables: {
                        notificationId,
                        withNotification: !!notificationId
                    }
                })
            }
        ),
        withMarkAlertsRead()
    );
