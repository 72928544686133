export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01
    },
    spacer: {
        flex: 1
    },
    relativeContainer: {
        position: 'relative',
        height: 104
    },
    absoluteContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    chartModal: {
        width: 'calc(100% - 80px)',
        maxWidth: 1000,
        overflow: 'hidden',
        '.eventPricingChart': {
            marginTop: 30
        }
    }
});
