import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { generateTabURL } from 'utils';
import { withUrlContext } from 'hoc/url';
import { NewsMentionsUI } from './ui';

export class NewsMentions extends PureComponent {
    static displayName = 'NewsMentionsContainer';

    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        matchCount: PropTypes.number.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        streamId: PropTypes.string,
        newsId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        selectBlock: PropTypes.func.isRequired
    };

    static defaultProps = {
        newsId: undefined,
        streamId: undefined
    };

    constructor(props) {
        super(props);

        this.toggleDash = this.toggleDash.bind(this);
        this.onTermSelect = this.onTermSelect.bind(this);

        this.state = {
            collapsedDashboards: [],
            selectedIndex: 1
        };
    }

    onTermSelect(selectedStreamId) {
        const { containerRef, newsId, history, pathname, streamId, selectBlock } = this.props;
        let prevSelected = false;

        if (streamId !== selectedStreamId) {
            history.push(generateTabURL({ pathname, newsId, match: true, streamId: selectedStreamId }));
        } else {
            const nodes = containerRef.current.querySelectorAll('.annotate_term_match');
            [...nodes].find((node, index) => {
                if (node.parentNode.className === 'selectedBlock') {
                    prevSelected = true;
                    if (nodes.length === index + 1) {
                        this.setState({ selectedIndex: 1 }, () => {
                            selectBlock(nodes[0].parentNode);
                        });
                    }
                } else if (prevSelected) {
                    this.setState({ selectedIndex: index + 1 }, () => {
                        selectBlock(node.parentNode);
                    });
                    return true;
                }

                return false;
            });
        }
    }

    toggleDash(dashId) {
        this.setState(({ collapsedDashboards }) => {
            const dashIds = new Set(collapsedDashboards);
            if (dashIds.has(dashId)) {
                dashIds.delete(dashId);
            } else {
                dashIds.add(dashId);
            }

            return {
                collapsedDashboards: [...dashIds]
            };
        });
    }

    render() {
        const { streamId, matchCount, ...rest } = this.props;
        const { collapsedDashboards, selectedIndex } = this.state;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <NewsMentionsUI
                {...rest}
                matchCount={matchCount}
                onTermSelect={this.onTermSelect}
                selectedIndex={selectedIndex}
                streamId={streamId}
                toggleDash={this.toggleDash}
                collapsedDashboards={collapsedDashboards}
            />
        );
    }
}

export const NewsMentionsContainer = compose(withUrlContext(['pathname', 'history', 'streamId', 'newsId']))(
    NewsMentions
);
