import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';
import { getQuestionsQuery } from 'graphql/askAiera';
import { get } from 'utils';

const getTemplatesQuery = gql`
    query withGetTemplateQuestionsCard($eventId: ID) {
        events(eventIds: [$eventId]) {
            id
            callType
            equity {
                id
                localTicker
            }
            questionTemplates {
                id
                question
                templateType
            }
        }
    }
`;

export const withData = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(getTemplatesQuery, {
            props: ({ data, ownProps }) => {
                const ticker = get(data, 'events[0].equity.localTicker');
                const callType = get(data, 'events[0].callType');
                const questionText = get(ownProps, 'question');
                const questionTemplates = get(data, 'events[0].questionTemplates', []);
                let template;
                if (questionText && questionTemplates.length > 0) {
                    template = questionTemplates.find(
                        ({ question }) => question.toLowerCase() === questionText.toLowerCase()
                    );
                }
                return { template, ticker, callType };
            },
            options: ({ eventId }) => ({
                fetchPolicy: 'cache-only',
                variables: {
                    eventId
                }
            })
        }),
        graphql(
            gql`
                mutation AskQuestionFeedback(
                    $sacQuestionId: ID!
                    $feedback: AnswerFeedback
                    $isTemplate: ConvertTemplate
                    $autoQuestion: ConvertAutoQuestion
                ) {
                    askQuestionFeedback(
                        sacQuestionId: $sacQuestionId
                        feedback: $feedback
                        isTemplate: $isTemplate
                        autoQuestion: $autoQuestion
                    ) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, eventId, companyId, byCompany } = ownProps;
                    return {
                        answerFeedback: ({ id, feedback, isTemplate, autoQuestion }) =>
                            mutate({
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        variables: {
                                            companyId: byCompany ? companyId : undefined,
                                            eventId: byCompany ? undefined : eventId,
                                            startDate: undefined,
                                            endDate: undefined
                                        },
                                        query: getQuestionsQuery
                                    },
                                    {
                                        variables: {
                                            eventId
                                        },
                                        query: getTemplatesQuery
                                    }
                                ],
                                variables: {
                                    sacQuestionId: id,
                                    feedback,
                                    isTemplate,
                                    autoQuestion
                                }
                            })
                                .then(() => {
                                    setStatusBanner('Feedback provided successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error providing feedback: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
