export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.gray10,
        paddingBottom: theme.margins.margin00,
        maxWidth: 640,
        width: '100%',
        borderRadius: 6,
        overflow: 'hidden'
    },
    containerLoading: {
        backgroundColor: theme.colors.gray10,
        borderRadius: 6,
        padding: 30,
        color: theme.colors.gray04,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: {
            marginBottom: theme.margins.margin01
        }
    },
    downloadBtn: {
        ...theme.content.blueButton,
        color: theme.colors.white01,
        marginLeft: 10,
        borderRadius: 6,
        svg: {
            marginRight: 6,
            g: {
                fill: theme.colors.white01
            }
        }
    },
    resetBtn: {
        backgroundColor: theme.colors.white01,
        borderRadius: 6,
        svg: {
            marginRight: 6
        }
    },
    cancel: {
        display: 'flex',
        cursor: 'pointer',
        color: theme.colors.gray06,
        alignItems: 'center',
        ':hover': {
            color: theme.colors.black01
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.margins.margin00,
        backgroundColor: theme.colors.gray03,
        borderBottom: `1px solid ${theme.colors.gray05}`,
        a: {
            textDecoration: 'none'
        }
    },
    highlightContainer: {
        boxShadow: `0 1px 0 0 ${theme.colors.gray03}`,
        margin: '10px 30px 20px 50px',
        border: `1px solid ${theme.colors.gray05}`,
        borderRadius: 6,
        ':hover': {
            border: `1px solid ${theme.colors.gray04}`
        }
    },
    highlightText: {
        padding: '14px 16px',
        position: 'relative',
        ':hover': {
            '.removeHighlight': {
                display: 'block'
            }
        }
    },
    highlightSpeaker: {
        marginRight: theme.margins.margin01,
        marginBottom: 8
    },
    highlightSpeakerMulti: {
        margin: '15px 20px 8px 0'
    },
    highlightTargetTitle: {
        margin: '0 20px 5px 0'
    },
    highlightTime: {
        marginTop: 4,
        color: theme.colors.gray04
    },
    highlightSelection: {
        lineHeight: '1.3em',
        marginTop: 10
    },
    removeHighlight: {
        height: 16,
        width: 16,
        borderRadius: 8,
        backgroundColor: theme.colors.white01,
        cursor: 'pointer',
        position: 'absolute',
        top: -6,
        right: -6,
        display: 'none',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    note: {
        backgroundColor: theme.colors.white01,
        borderRadius: '0 0 6px 6px',
        position: 'relative',
        padding: '14px 16px',
        ':hover': {
            '.removeNote': {
                display: 'block'
            }
        }
    },
    removeNote: {
        height: 16,
        width: 16,
        borderRadius: 8,
        backgroundColor: theme.colors.white01,
        cursor: 'pointer',
        position: 'absolute',
        top: -6,
        right: -6,
        display: 'none',
        ':hover': {
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    noteCreated: {
        marginTop: 4,
        color: theme.colors.gray04
    },
    noteText: {
        marginTop: theme.margins.margin00,
        lineHeight: '1.3em'
    },
    textEdit: {
        position: 'absolute',
        left: -48,
        marginTop: 2
    },
    editWrapper: {
        position: 'relative',
        '.editable': {
            ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.03)'
            }
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.black01
            }
        }
    },
    title: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: `20px ${theme.margins.margin02} 20px  50px`
    },
    titleText: {
        textAlign: 'left'
    },
    titleHeadline: {
        lineHeight: '1.2em'
    },
    titleType: {
        color: theme.colors.gray04,
        marginTop: 4
    },
    watermark: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: theme.colors.gray04,
        padding: '0 32px 10px 0',
        p: {
            marginRight: 10
        }
    },
    spacer: {
        flex: 1
    },
    pdfPage: {
        backgroundColor: 'white'
    },
    pdfHeader: {
        marginTop: 8,
        marginLeft: 10,
        marginRight: 10
    },
    pdfTitle: {
        color: '#000000',
        fontSize: 15,
        fontFamily: 'CharisSIL',
        fontWeight: 700
    },
    pdfSubTitle: {
        color: theme.colors.gray04,
        fontSize: 10,
        marginTop: 2
    },
    pdfHighlightContainer: {
        border: `1pt solid ${theme.colors.gray05}`,
        borderRadius: '6pt',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10
    },
    pdfHighlight: {
        padding: '8 10'
    },
    pdfHighlightSpeaker: {
        fontSize: 12,
        fontFamily: 'Rubik',
        fontWeight: 500
    },
    pdfHighlightSpeakerMulti: {
        marginTop: 15
    },
    pdfHighlightSpeakerInfo: {
        fontSize: 10,
        fontFamily: 'Rubik',
        fontWeight: 400,
        marginBottom: 8
    },
    pdfHighlightDate: {
        color: theme.colors.gray04,
        fontSize: 10,
        marginTop: 2
    },
    pdfHighlightText: {
        color: '#000000',
        marginTop: 10,
        fontSize: 12,
        lineHeight: 1.3
    },
    pdfNoteContainer: {
        padding: 10
    },
    pdfNoteCreator: {
        color: '#000000',
        fontSize: 12,
        fontFamily: 'Rubik',
        fontWeight: 500
    },
    pdfNoteDate: {
        color: theme.colors.gray04,
        fontSize: 10,
        marginTop: 2
    },
    pdfNoteText: {
        color: '#000000',
        marginTop: 10,
        fontSize: 12,
        lineHeight: 1.3
    },
    pdfWatermark: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
    },
    pdfWatermarkText: {
        color: theme.colors.blue08,
        textDecoration: 'underline',
        fontSize: 12,
        fontWeight: 500
    }
});
