export const styleSheet = theme => ({
    container: {
        ...theme.content.slideInAnimation,
        backgroundColor: theme.colors.blue08,
        alignItems: 'center',
        height: 40,
        padding: '0 12px',
        display: 'flex',
        position: 'absolute',
        top: 6,
        right: 6,
        left: 6,
        borderRadius: 6,
        zIndex: 9,
        cursor: 'pointer',
        ':hover': {
            '> svg': {
                ':last-of-type': {
                    g: {
                        fill: theme.colors.blue08
                    }
                }
            }
        }
    },
    label: {
        margin: '0 8px',
        letterSpacing: 1,
        opacity: 0.8
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 6,
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        padding: '6px 8px',
        marginRight: 10,
        span: {
            marginLeft: 2
        },
        svg: {
            marginLeft: 4
        }
    },
    spacer: {
        flex: 1
    }
});
