import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { StreamActionMenu } from 'components/StreamActionMenu';
import { Card } from 'components/Card';
import { CardEquities } from 'components/CardEquities';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { TimeAgo } from 'components/TimeAgo';
import { STREAM_DISPLAY_MODES } from 'consts';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class DocumentCard extends PureComponent {
    static propTypes = {
        displayMode: PropTypes.string.isRequired,
        equities: PropTypes.arrayOf(PropTypes.any),
        highlightColor: PropTypes.string,
        highlights: PropTypes.string,
        highlightTitle: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isDocumentOwner: PropTypes.bool.isRequired,
        matchId: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedDate: PropTypes.string.isRequired,
        score: PropTypes.number,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        to: PropTypes.string,
        uploadingUserEmail: PropTypes.string
    };

    static defaultProps = {
        equities: [],
        highlightColor: undefined,
        highlights: null,
        highlightTitle: null,
        onClick: undefined,
        passedStyles: {},
        score: undefined,
        streamId: undefined,
        to: null,
        uploadingUserEmail: undefined
    };

    render() {
        const {
            displayMode,
            highlightColor,
            highlights,
            highlightTitle,
            id,
            isDocumentOwner,
            matchId,
            onClick,
            passedStyles,
            publishedDate,
            styles,
            score,
            title,
            equities,
            streamId,
            to,
            uploadingUserEmail
        } = this.props;
        if (STREAM_DISPLAY_MODES.compact === displayMode) {
            return (
                <Card
                    containerStyles={styles.containerCompact}
                    name="attachment-card-compact"
                    onClick={onClick}
                    styles={{ ...styles.compact, ...passedStyles }}
                    to={to}
                >
                    {({ isHovering }) => (
                        <Fragment>
                            {isHovering && (
                                <StreamActionMenu
                                    enableScoring={false}
                                    noBookmark
                                    isDocumentOwner={isDocumentOwner}
                                    score={score}
                                    highlight={highlights}
                                    matchId={matchId}
                                    streamId={streamId}
                                    styles={styles.actionMenu}
                                    targetId={id}
                                    targetType="document"
                                    url={to}
                                />
                            )}
                            <CardHeader title={highlightTitle || title} />
                            <Div styles={styles.subTextCompact}>
                                <Text size={1} styles={styles.date}>
                                    <TimeAgo date={publishedDate} />
                                </Text>
                                •
                                <Text size={1} styles={styles.email}>
                                    {uploadingUserEmail}
                                </Text>
                            </Div>
                        </Fragment>
                    )}
                </Card>
            );
        }
        const containerStyles =
            displayMode === STREAM_DISPLAY_MODES.comfortable ? styles.containerComfortable : styles.container;

        const contentStyles = {
            ...styles.content,
            backgroundColor: getHighlightColor(highlightColor)?.color
        };

        return (
            <Card
                name={`attachment-card-${displayMode === STREAM_DISPLAY_MODES.default ? 'default' : 'comfortable'}`}
                onClick={onClick}
                styles={{ ...containerStyles, ...passedStyles }}
                to={to}
            >
                {({ isHovering }) => {
                    return (
                        <Fragment>
                            {isHovering && (
                                <StreamActionMenu
                                    enableScoring={false}
                                    noBookmark
                                    isDocumentOwner={isDocumentOwner}
                                    score={score}
                                    highlight={highlights}
                                    matchId={matchId}
                                    streamId={streamId}
                                    styles={styles.actionMenu}
                                    targetId={id}
                                    targetType="document"
                                    url={to}
                                />
                            )}
                            <CardEquities equities={equities} />
                            <CardHeader title={highlightTitle || title} />
                            <Div styles={styles.subText}>
                                <Text size={1} styles={styles.date}>
                                    <TimeAgo date={publishedDate} />
                                </Text>
                                •
                                <Text size={1} styles={styles.email}>
                                    {uploadingUserEmail}
                                </Text>
                            </Div>
                            {displayMode === STREAM_DISPLAY_MODES.default && (
                                <Div styles={contentStyles}>
                                    <RawHTML html={highlights} />
                                </Div>
                            )}
                        </Fragment>
                    );
                }}
            </Card>
        );
    }
}

export const DocumentCardUI = compose(withStyleSheet(styleSheet))(DocumentCard);
