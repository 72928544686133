import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { notificationFragment } from 'graphql/fragments/alerts';
import { eventPreferencesFragment, streamPreferencesFragment } from 'graphql/fragments/user';
import { graphql } from 'graphql/utils';

export const withMarkAlertsRead = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation MarkNotificationsRead($notificationIds: [ID]) {
                    markNotificationsRead(notificationIds: $notificationIds) {
                        notifications {
                            ...notification
                        }
                    }
                }
                ${notificationFragment}
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    markNotificationsRead: notificationIds =>
                        mutate({
                            variables: { notificationIds }
                        }).catch(error => {
                            setStatusBanner(`Error marking alerts read: ${error}`, 'error', 'circleX');
                        })
                })
            }
        )
    );

export const withUpdateGlobalAlertPrefs = () =>
    compose(
        graphql(
            gql`
                mutation UpdateGlobalAlertPrefs($input: GlobalRealtimeNotificationPreferencesInput!) {
                    setGlobalRealtimeNotificationPreferences(input: $input) {
                        success
                        preferences {
                            id
                            eventPreferences {
                                ...eventPreferences
                            }
                            streamPreferences {
                                ...streamPreferences
                            }
                        }
                    }
                }
                ${eventPreferencesFragment}
                ${streamPreferencesFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner } = ownProps;
                    return {
                        updateGlobalPrefs: input =>
                            mutate({ variables: { input } })
                                .then(() => {
                                    setStatusBanner('Alert Preferences Saved');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error saving preferences: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
