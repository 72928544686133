import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { withReporting } from 'provider/reporting';
import { withUpdateUserObjectSettings } from 'graphql/utils';

import { ContentActionBlockUI } from './ui';

export class ContentActionBlock extends PureComponent {
    static displayName = 'ContentActionBlockContainer';

    static propTypes = {
        bulkUpdateItems: PropTypes.func.isRequired,
        contentType: PropTypes.string.isRequired,
        isArchived: PropTypes.bool,
        isRead: PropTypes.bool,
        isStarred: PropTypes.bool,
        markRead: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.any),
        targetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        targetType: PropTypes.string.isRequired,
        userTags: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        isArchived: false,
        isRead: false,
        isStarred: false,
        styles: undefined,
        tags: [],
        userTags: []
    };

    constructor(props) {
        super(props);

        this.onArchive = this.onArchive.bind(this);
        this.onStar = this.onStar.bind(this);
        this.onTag = this.onTag.bind(this);
    }

    componentDidMount() {
        const { isRead, markRead, targetId, targetType } = this.props;

        if (!isRead) {
            markRead({ targetId, targetType, isRead: true });
        }
    }

    onArchive() {
        const { bulkUpdateItems, contentType, isArchived, targetType, targetId, reporter } = this.props;
        const items = [
            {
                archived: !isArchived,
                targetType,
                targetId
            }
        ];

        bulkUpdateItems(items).then(() => {
            /*
             **************************
             *                        *
             *  Track Archived Items  *
             *                        *
             **************************
             */

            const contentTrackingMap = {
                news: reporter.objects.archiveNews,
                transcript: reporter.objects.archiveEvent,
                spotlight: reporter.objects.archiveSpotlight,
                filing: reporter.objects.archiveFiling
            };

            reporter.track(reporter.actions.click, contentTrackingMap[contentType], {
                component: 'ContentActionBlock',
                archiveItems: items
            });
        });
    }

    onStar() {
        const { bulkUpdateItems, isStarred, targetType, targetId, reporter, contentType } = this.props;
        const items = [
            {
                starred: !isStarred,
                targetType,
                targetId
            }
        ];

        bulkUpdateItems(items).then(() => {
            /*
             *************************
             *                       *
             *  Track Starred Items  *
             *                       *
             *************************
             */

            const contentTrackingMap = {
                news: reporter.objects.starNews,
                transcript: reporter.objects.starEvent,
                spotlight: reporter.objects.starSpotlight,
                filing: reporter.objects.starFiling
            };

            reporter.track(reporter.actions.click, contentTrackingMap[contentType], {
                component: 'ContentActionBlock',
                starredItems: items
            });
        });
    }

    onTag({ tag, remove }) {
        const { bulkUpdateItems, reporter, targetId, targetType, userTags, contentType } = this.props;
        const uniqueTags = new Set([...userTags]);
        if (tag && !uniqueTags.has(tag)) {
            uniqueTags.add(tag);
        }
        if (remove && uniqueTags.has(remove)) {
            uniqueTags.delete(remove);
        }
        const items = [
            {
                tags: [...uniqueTags],
                targetType,
                targetId
            }
        ];

        bulkUpdateItems(items).then(() => {
            /*
             ************************
             *                      *
             *  Track Tagged Items  *
             *                      *
             ************************
             */

            const contentTrackingMap = {
                news: reporter.objects.tagNews,
                transcript: reporter.objects.tagEvent,
                spotlight: reporter.objects.tagSpotlight,
                filing: reporter.objects.tagFiling
            };

            reporter.track(reporter.actions.submit, contentTrackingMap[contentType], {
                component: 'ContentActionBlock',
                taggedItems: items
            });
        });
    }

    render() {
        const { contentType, styles, userTags, tags, isStarred, isArchived } = this.props;

        return (
            <ContentActionBlockUI
                contentType={contentType}
                isArchived={isArchived}
                isStarred={isStarred}
                onArchive={this.onArchive}
                onStar={this.onStar}
                onTag={this.onTag}
                styles={styles}
                tags={tags}
                userTags={userTags}
            />
        );
    }
}

export const ContentActionBlockContainer = compose(
    withReporting(),
    withUpdateUserObjectSettings(),
    withPropsOnChange(['userTags', 'tags'], ({ userTags = [], tags = [] }) => ({
        // Remove dupes from team tags
        tags: tags.filter(({ tag }) => !userTags.includes(tag))
    }))
)(ContentActionBlock);
