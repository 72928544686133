import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Card } from 'components/Card';
import { CardHeader } from 'components/CardHeader';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { abbrevNum, toDateString } from 'utils';
import { styleSheet } from './stylesheet';

class EquityCard extends PureComponent {
    static propTypes = {
        company: PropTypes.string,
        marketCap: PropTypes.number,
        nextEarnings: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pe: PropTypes.number,
        price: PropTypes.string,
        priceChange: PropTypes.string,
        priceChangePercent: PropTypes.string,
        sector: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        ticker: PropTypes.string
    };

    static defaultProps = {
        company: '',
        marketCap: null,
        nextEarnings: null,
        passedStyles: {},
        pe: null,
        price: null,
        priceChange: null,
        priceChangePercent: null,
        sector: null,
        ticker: ''
    };

    render() {
        const {
            styles,
            passedStyles,
            theme,
            company,
            onClick,
            price,
            priceChange,
            priceChangePercent,
            ticker,
            sector,
            pe,
            marketCap,
            nextEarnings
        } = this.props;
        const isPositive = priceChange && priceChange > 0;
        return (
            <Card styles={{ ...styles.container, ...passedStyles }} onClick={onClick} name="equity-card">
                <CardHeader title={company} context={ticker} truncateTitle subtitle={sector} />
                {price && (
                    <Div styles={styles.priceContainer}>
                        <Text size={1}>{price}</Text>
                        {priceChange && (
                            <Text
                                size={1}
                                styles={
                                    isPositive
                                        ? { ...styles.priceChange, ...styles.textGreen }
                                        : { ...styles.priceChange, ...styles.textRed }
                                }
                            >
                                {priceChange}
                            </Text>
                        )}
                        {priceChangePercent && (
                            <Text
                                size={1}
                                styles={
                                    isPositive
                                        ? { ...styles.priceChange, ...styles.textGreen }
                                        : { ...styles.priceChange, ...styles.textRed }
                                }
                            >
                                ({priceChangePercent})
                            </Text>
                        )}
                        {priceChange && (
                            <Icon
                                type={isPositive ? 'triangleUp' : 'triangleDown'}
                                color={isPositive ? theme.colors.green03 : theme.colors.red01}
                            />
                        )}
                    </Div>
                )}
                {(marketCap || pe || nextEarnings) && (
                    <Div styles={styles.metrics}>
                        {marketCap && (
                            <Div>
                                <Text>{abbrevNum(marketCap)}</Text>
                                <Text styles={styles.label} size={0} uppercase>
                                    Market cap.
                                </Text>
                            </Div>
                        )}
                        {pe ? (
                            <Div>
                                <Text>{pe.toFixed(2)}</Text>
                                <Text styles={styles.label} size={0} uppercase>
                                    PE
                                </Text>
                            </Div>
                        ) : null}
                        {nextEarnings && (
                            <Div>
                                <Text>{toDateString(nextEarnings)}</Text>
                                <Text styles={styles.label} size={0} uppercase>
                                    Next earnings
                                </Text>
                            </Div>
                        )}
                    </Div>
                )}
            </Card>
        );
    }
}

export const EquityCardUI = compose(withStyleSheet(styleSheet))(EquityCard);
