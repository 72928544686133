import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DefaultPlayerUI } from './ui';

export class DefaultPlayer extends PureComponent {
    static displayName = 'DefaultPlayerContainer';

    static propTypes = {
        autoplay: PropTypes.bool,
        onClickPreview: PropTypes.func,
        playerRef: PropTypes.objectOf(PropTypes.any),
        showControls: PropTypes.bool,
        showPreview: PropTypes.bool,
        styles: PropTypes.objectOf(PropTypes.any),
        url: PropTypes.string.isRequired
    };

    static defaultProps = {
        autoplay: false,
        onClickPreview: undefined,
        playerRef: undefined,
        showControls: true,
        showPreview: false,
        styles: undefined
    };

    render() {
        const { autoplay, onClickPreview, playerRef, showControls, showPreview, styles, url } = this.props;
        return (
            <DefaultPlayerUI
                autoplay={autoplay}
                onClickPreview={onClickPreview}
                playerRef={playerRef}
                showControls={showControls}
                showPreview={showPreview}
                styles={styles}
                url={url}
            />
        );
    }
}

export const DefaultPlayerContainer = compose()(DefaultPlayer);
