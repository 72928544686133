import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Img } from 'components/Img';
import { Hint } from 'components/Hint';
import { generateInitials } from 'utils';
import { styleSheet } from './stylesheet';

class ContentCompanies extends PureComponent {
    static propTypes = {
        companies: PropTypes.arrayOf(PropTypes.any).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showTickers: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        viewCompany: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { companies, styles, showTickers, passedStyles, viewCompany } = this.props;
        const containerStyles = showTickers ? styles.container : styles.containerMinimal;
        if (companies && companies.length) {
            return (
                <Div styles={{ ...containerStyles, ...passedStyles }}>
                    {companies.map(({ commonName, exchangeName, icon, id, localTicker }) => (
                        <Hint
                            yOffset={-60}
                            styles={showTickers ? styles.company : styles.companyMinimal}
                            text={commonName}
                            key={`ticker-${id}`}
                            onClick={() => viewCompany(id)}
                        >
                            <Div
                                className="identifierTag"
                                styles={icon ? styles.identifierIcon : styles.identifierInitials}
                            >
                                {icon ? (
                                    <Img src={icon} />
                                ) : (
                                    <Text uppercase size={0}>
                                        {generateInitials(commonName)}
                                    </Text>
                                )}
                            </Div>
                            {showTickers && (
                                <Div styles={styles.text}>
                                    <Text
                                        styles={styles.ticker}
                                        weight="medium"
                                        className="localTicker"
                                        uppercase
                                        size={0}
                                    >
                                        {localTicker}
                                    </Text>
                                    {exchangeName && (
                                        <Text styles={styles.exchange} uppercase size={0}>
                                            {exchangeName}
                                        </Text>
                                    )}
                                </Div>
                            )}
                        </Hint>
                    ))}
                </Div>
            );
        }
        return null;
    }
}

export const ContentCompaniesUI = compose(withStyleSheet(styleSheet))(ContentCompanies);
