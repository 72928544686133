import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { withUrlContext } from 'hoc/url';
import { compose, withPropsOnChange } from 'recompose';
import { STREAM_DISPLAY_MODES } from 'consts';
import { withGetUser } from 'graphql/user';
import { getBookmarkType } from 'utils/bookmarks';
import { get } from 'utils';
import { BookmarkCardUI } from './ui';
import { withData } from './data';

const ICON_MAP = {
    attachment: 'files',
    document: 'files',
    event: 'calendar',
    filing: 'filings',
    news: 'newspaper',
    research: 'briefcase',
    spotlight: 'briefcase',
    streetaccount: 'briefcase'
};
const TYPE_MAP = {
    attachment: 'Event Attachment',
    document: 'Uploaded Document',
    event: 'Event',
    filing: 'Filing',
    news: 'News',
    research: 'Broker Research',
    spotlight: 'Corp. Activity',
    streetaccount: 'Corp. Activity'
};

export class BookmarkCard extends PureComponent {
    static displayName = 'BookmarkCardContainer';

    static propTypes = {
        bookmarkUrlType: PropTypes.string,
        collapsed: PropTypes.arrayOf(PropTypes.any),
        containerStyles: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        creatorName: PropTypes.string,
        creatorId: PropTypes.string,
        currentUserId: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(XDate)]),
        displayMode: PropTypes.string,
        equity: PropTypes.objectOf(PropTypes.any),
        eventId: PropTypes.string,
        highlight: PropTypes.string,
        highlightColor: PropTypes.string,
        id: PropTypes.string,
        matchId: PropTypes.string,
        note: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        reportCards: PropTypes.arrayOf(PropTypes.string),
        setVisibility: PropTypes.func.isRequired,
        shared: PropTypes.bool,
        source: PropTypes.string,
        speakerName: PropTypes.string,
        startTimestamp: PropTypes.string,
        streamId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tags: PropTypes.arrayOf(PropTypes.string),
        target: PropTypes.objectOf(PropTypes.any),
        targetId: PropTypes.string,
        targetType: PropTypes.string,
        title: PropTypes.string,
        toggleExport: PropTypes.func,
        url: PropTypes.string
    };

    static defaultProps = {
        bookmarkUrlType: undefined,
        collapsed: [],
        containerStyles: undefined,
        created: undefined,
        creatorName: undefined,
        creatorId: undefined,
        currentUserId: undefined,
        date: undefined,
        displayMode: STREAM_DISPLAY_MODES.default,
        equity: undefined,
        eventId: undefined,
        highlight: undefined,
        highlightColor: undefined,
        id: undefined,
        matchId: undefined,
        note: undefined,
        reportCards: [],
        shared: false,
        source: undefined,
        speakerName: undefined,
        startTimestamp: undefined,
        streamId: undefined,
        styles: undefined,
        tags: undefined,
        target: undefined,
        targetId: undefined,
        targetType: undefined,
        title: undefined,
        toggleExport: undefined,
        url: undefined
    };

    render() {
        const {
            bookmarkUrlType,
            collapsed,
            containerStyles,
            created,
            creatorId,
            creatorName,
            currentUserId,
            date,
            displayMode,
            equity,
            eventId,
            highlight,
            highlightColor,
            id,
            matchId,
            note,
            pathname,
            reportCards,
            setVisibility,
            shared,
            source,
            speakerName,
            startTimestamp,
            streamId,
            styles,
            tags,
            target,
            targetId,
            targetType,
            title,
            toggleExport,
            url
        } = this.props;
        const bookmarkType = getBookmarkType({ target });
        return (
            <BookmarkCardUI
                bookmarkIcon={ICON_MAP[bookmarkType]}
                bookmarkType={TYPE_MAP[bookmarkType]}
                bookmarkUrlType={bookmarkUrlType}
                collapsed={collapsed}
                containerStyles={containerStyles}
                created={created}
                creatorId={creatorId}
                creatorName={creatorName}
                currentUserId={currentUserId}
                date={date}
                displayMode={displayMode}
                equity={equity}
                eventId={eventId}
                highlight={highlight}
                highlightColor={highlightColor}
                id={id}
                matchId={matchId}
                note={note}
                pathname={pathname}
                reportCards={reportCards}
                setVisibility={setVisibility}
                shared={shared}
                source={source}
                speakerName={speakerName}
                startTimestamp={startTimestamp}
                streamId={streamId}
                styles={styles}
                tags={tags}
                target={target}
                targetId={targetId}
                targetType={targetType}
                title={title}
                toggleExport={toggleExport}
                url={url}
            />
        );
    }
}

export const BookmarkCardContainer = compose(
    withUrlContext(['pathname']),
    withGetUser({ fetchPolicy: 'cache-only' }),
    withPropsOnChange(['user'], ({ user }) => ({
        currentUserId: get(user, 'id')
    })),
    withData()
)(BookmarkCard);
