import { createRef } from 'react';
import { compose, lifecycle, withPropsOnChange } from 'recompose';

export const withWindowVisibility = () =>
    compose(
        // Set initial values since they won't get set until mounting in the lifecycle component
        withPropsOnChange([], () => ({ windowActive: true, windowVisible: !document.hidden })),
        lifecycle({
            componentDidMount() {
                // Whether the tab is visible or not
                this.handleVisibilityChange = () => {
                    this.setState({ windowVisibile: !document.hidden });
                };
                document.addEventListener('visibilitychange', this.handleVisibilityChange);

                // Whether the tab is focused or not
                this.handleFocus = () => {
                    this.setState({ windowActive: true });
                };
                this.handleBlur = () => {
                    this.setState({ windowActive: false });
                };
                window.addEventListener('focus', this.handleFocus);
                window.addEventListener('blur', this.handleBlur);
            },
            componentWillUnmount() {
                document.removeEventListener('visibilitychange', this.handleVisibilityChange);
                window.removeEventListener('focus', this.handleFocus);
                window.removeEventListener('blur', this.handleBlur);
            }
        })
    );

export const withComponentVisibility = () =>
    compose(
        withPropsOnChange([], () => ({
            componentVisibilityRef: createRef()
        })),
        lifecycle({
            componentDidMount() {
                const { componentVisibilityRef } = this.props;
                if (window.IntersectionObserver && componentVisibilityRef.current) {
                    this.observer = new IntersectionObserver(entries => {
                        entries.forEach(entry => {
                            this.setState(prevState => {
                                const { wasVisible = false } = prevState || {};
                                if (entry.isIntersecting && !wasVisible) {
                                    return { wasVisible: true };
                                }
                                return null;
                            });
                        });
                    });
                    this.observer.observe(componentVisibilityRef.current);
                } else {
                    // If there's no observer, default to true
                    this.setState({ wasVisible: true });
                }
            },
            componentWillUnmount() {
                if (this.observer) {
                    this.observer.disconnect();
                }
            }
        })
    );
