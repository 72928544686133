import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { TextareaOldUI } from './ui';

export class TextareaOld extends Component {
    static displayName = 'TextareaOldContainer';

    static propTypes = {
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        onBlur: null,
        onFocus: null
    };

    constructor(props) {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInit = this.onInit.bind(this);

        // PUBLIC API
        this.clear = this.clear.bind(this);

        this.state = {
            isFocused: false
        };
    }

    // PUBLIC API
    clear() {
        if (this.textarea) {
            this.textarea.value = '';
        }
    }

    // PRIVATE API
    onInit(t) {
        this.textarea = t;
    }

    onChange(e) {
        const { onChange } = this.props;
        const {
            target: { value }
        } = e;

        if (onChange) {
            onChange(value);
        }
    }

    onFocus() {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus();
        }
        this.setState({
            isFocused: true
        });
    }

    onBlur() {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur();
        }
        this.setState({
            isFocused: false
        });
    }

    render() {
        const { ...rest } = this.props;
        const { isFocused } = this.state;

        return (
            <TextareaOldUI
                {...rest}
                initTextarea={this.onInit}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                isFocused={isFocused}
            />
        );
    }
}

export const TextareaOldContainer = compose()(TextareaOld);
