import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import { withUrlContext } from 'hoc/url';
import XDate from 'xdate';
import { ExportMatchesModalUI } from './ui';
import { withData } from './data';
import { generateWordDocument } from './doc';

export class ExportMatchesModal extends PureComponent {
    static displayName = 'ExportMatchesModalContainer';

    static propTypes = {
        dateRange: PropTypes.arrayOf(PropTypes.any),
        downloadSize: PropTypes.number.isRequired,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.any),
        searchTerms: PropTypes.arrayOf(PropTypes.string),
        setDateRange: PropTypes.func.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        streamName: PropTypes.string,
        totalMatches: PropTypes.number,
        onClose: PropTypes.func.isRequired
    };

    static defaultProps = {
        dateRange: null,
        loading: false,
        matches: [],
        searchTerms: [],
        streamName: undefined,
        styles: undefined,
        totalMatches: null
    };

    constructor(props) {
        super(props);

        this.onDownloadWord = this.onDownloadWord.bind(this);

        this.state = {
            loadingWord: false
        };
    }

    onDownloadWord() {
        const { streamName, matches, dateRange, searchTerms } = this.props;
        this.setState({ loadingWord: true }, () => {
            generateWordDocument({
                matches,
                onComplete: () => this.setState({ loadingWord: false }),
                searchTerms,
                title: `${streamName} Matches ${new XDate(dateRange[0]).toString('MMM d')} - ${new XDate(
                    dateRange[1]
                ).toString('MMM d, yyyy')}`
            });
        });
    }

    render() {
        const {
            downloadSize,
            dateRange,
            loading,
            onClose,
            setDateRange,
            styles,
            streamName,
            totalMatches
        } = this.props;
        const { loadingWord } = this.state;
        return (
            <ExportMatchesModalUI
                dateRange={dateRange}
                downloadSize={downloadSize}
                loading={loading}
                loadingWord={loadingWord}
                onClose={onClose}
                onDownloadWord={this.onDownloadWord}
                setDateRange={setDateRange}
                streamName={streamName}
                styles={styles}
                totalMatches={totalMatches}
            />
        );
    }
}

export const ExportMatchesModalContainer = compose(
    withStateHandlers(
        {
            downloadSize: 250,
            dateRange: [new XDate().addMonths(-3).toDate(), new Date()]
        },
        {
            setDateRange: () => ({ value }) => ({
                dateRange: value
            })
        }
    ),
    withUrlContext(['exportMatchesId', 'location']),
    withData()
)(ExportMatchesModal);
