export const styleSheet = theme => {
    const sectionHeader = {
        p: {
            fontWeight: theme.fonts.fontWeights.semibold
        },
        padding: '0 !important'
    };
    const table = {
        marginTop: theme.margins.margin01,
        p: {
            fontFamily: 'sans-serif',
            fontSize: theme.fonts.fontSizes.size03
        }
    };
    const tableHeader = {
        div: {
            justifyContent: 'flex-end'
        }
    };
    return {
        container: {
            maxWidth: 1000,
            width: 'calc(100% - 80px)'
        },
        cellBorder: {
            boxShadow: `0 1px 0 1px ${theme.colors.gray01}`,
            paddingLeft: 10
        },
        disclaimer: {
            color: theme.colors.gray06,
            marginTop: theme.margins.margin02
        },
        displayUnits: {
            color: theme.colors.gray06,
            fontSize: `${theme.fonts.fontSizes.size01} !important`,
            fontStyle: 'italic'
        },
        empty: {
            h1: {
                fontSize: theme.fonts.fontSizes.size05,
                marginBottom: 0
            },
            margin: 'auto',
            maxWidth: 'unset',
            padding: 35
        },
        financialsContainer: {
            height: '100%',
            padding: 35
        },
        loading: {
            margin: '40px auto'
        },
        sectionHeader: {
            ...sectionHeader
        },
        sectionHeaderBorder: {
            ...sectionHeader,
            boxShadow: `0 1px 0 1px ${theme.colors.gray01}`
        },
        table: {
            ...table,
            marginTop: 20
        },
        tableHeader: {
            ...tableHeader
        },
        tableHeaderBorder: {
            ...tableHeader,
            boxShadow: `0 1px 0 1px ${theme.colors.gray01}`,
            paddingLeft: 10
        },
        tableHeaderRow: {
            th: {
                backgroundColor: theme.colors.gray10,
                ':last-of-type': {
                    paddingRight: theme.margins.margin01
                }
            }
        },
        tableWithNote: {
            ...table
        },
        tabs: {
            '.contentTab': {
                p: {
                    color: theme.colors.gray06,
                    fontSize: theme.fonts.fontSizes.size03
                }
            },
            '.contentTab-selected': {
                p: {
                    color: theme.colors.black01,
                    fontSize: theme.fonts.fontSizes.size03
                }
            }
        }
    };
};
