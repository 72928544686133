import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { DragDropContext } from 'lib/react-beautiful-dnd';
import { withStyleSheet } from 'hoc/styles';
import { AlertBanner } from 'components/AlertBanner';
import { Div } from 'components/Div';
import { Modals } from 'components/Modals';
import { Sidebar } from 'components/Sidebar';
import { WelcomeMessage } from 'components/WelcomeMessage';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS } from 'consts';
import { AlertToast } from './AlertToast';
import { MobileSearch } from './MobileSearch';
import { Footer } from './Footer';
import { Header } from './Header';
import { MobileTabBar } from './MobileTabBar';
import { styleSheet } from './stylesheet';
import { PopoverPDF } from './PopoverPDF';

class InternalLayout extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        onDragDropEnd: PropTypes.func.isRequired,
        onDragDropStart: PropTypes.func.isRequired,
        showFooter: PropTypes.bool,
        allowScroll: PropTypes.bool
    };

    static defaultProps = {
        showFooter: false,
        allowScroll: false
    };

    render() {
        const { styles, children, showFooter, theme, allowScroll, onDragDropEnd, onDragDropStart } = this.props;
        const pageContainerStyles = allowScroll ? styles.pageContainer : styles.pageContainerLocked;
        return (
            <DragDropContext onDragEnd={onDragDropEnd} onBeforeDragStart={onDragDropStart}>
                <Div styles={styles.container} className="print-hide">
                    <AlertToast />
                    <PopoverPDF />
                    <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                        <Div styles={styles.sidebarStreams}>
                            <Sidebar />
                        </Div>
                    </MediaQuery>
                    <Div styles={styles.contentWrapper}>
                        <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                            <Header />
                        </MediaQuery>
                        <Div styles={styles.activeContent}>
                            <Div styles={styles.subContentWrapper}>
                                <Div styles={pageContainerStyles}>
                                    <Div styles={showFooter ? styles.page : styles.pageNoFooter}>{children}</Div>
                                    {showFooter && (
                                        <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                                            <Div styles={styles.footer}>
                                                <Footer />
                                            </Div>
                                        </MediaQuery>
                                    )}
                                </Div>
                            </Div>
                        </Div>
                        <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                            <Div styles={styles.mobileStatusBarBg} />
                            <MobileTabBar />
                        </MediaQuery>
                    </Div>
                    <WithPermission permissions={[PERMISSIONS.featureAlerts]}>
                        <AlertBanner />
                    </WithPermission>
                    <MobileSearch />
                    <Modals />
                    <WelcomeMessage />
                </Div>
            </DragDropContext>
        );
    }
}

export const InternalLayoutUI = compose(withStyleSheet(styleSheet))(InternalLayout);
