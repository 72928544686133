import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { Img } from 'components/Img';
import { LoaderLogo } from 'components/LoaderLogo';
import { Span } from 'components/Span';
import { Table } from 'components/Table';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Tr } from 'components/Tr';
import { getNativePrice, get } from 'utils';
import { styleSheet } from './stylesheet';

const RESULT_TYPE = 'searchResults';

class CompanyResults extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        hasMoreResults: PropTypes.bool.isRequired,
        highlightedResult: PropTypes.objectOf(PropTypes.any),
        highlightResult: PropTypes.func.isRequired,
        indexElement: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        loadingMoreResults: PropTypes.bool.isRequired,
        loadMoreResults: PropTypes.func.isRequired,
        onClickResult: PropTypes.func.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        highlightedResult: null
    };

    render() {
        const {
            styles,
            enabled,
            results,
            hasMoreResults,
            loading,
            loadingMoreResults,
            loadMoreResults,
            highlightedResult,
            highlightResult,
            indexElement,
            onClickResult,
            theme
        } = this.props;
        if (!enabled) return null;
        const highlightedType = get(highlightedResult, 'type');
        return (
            <Table styles={styles.results}>
                {loading ? (
                    <Tr>
                        <Td styles={styles.loaderCell} colSpan={3}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                ) : (
                    results.map((company, index) => {
                        const {
                            companyId,
                            companyName,
                            currency,
                            exchangeName,
                            iconUrl,
                            initials,
                            latestOhlc,
                            prevClose,
                            quotes,
                            ticker
                        } = company;
                        const resultStyles =
                            RESULT_TYPE === get(highlightedResult, 'type') && index === get(highlightedResult, 'index')
                                ? styles.resultHighlighted
                                : styles.result;
                        const { close: price } = latestOhlc || {};
                        const priceChange = (price || 0) - (prevClose || 0);
                        const priceChangePercent =
                            priceChange && priceChange !== 0
                                ? `${((priceChange * 100) / prevClose).toFixed(2)}%`
                                : null;
                        let color = theme.colors.gray04;
                        let iconType;
                        if (priceChange < 0) {
                            color = theme.colors.red01;
                            iconType = 'triangleDown';
                        }
                        if (priceChange > 0) {
                            color = theme.colors.green03;
                            iconType = 'triangleUp';
                        }
                        // Show all quotes in a hint if there's more than one
                        let NameWrapper = Fragment;
                        let wrapperProps = null;
                        if (quotes && quotes.length > 1) {
                            NameWrapper = Hint;
                            wrapperProps = {
                                growUp: true,
                                text: quotes.map(q => (
                                    <Text
                                        key={`company-results-quote-${q.id}`}
                                        styles={styles.quoteHint}
                                        weight="medium"
                                    >
                                        <Span>{get(q, 'localTicker', '')}</Span>
                                        <Span>{get(q, 'exchange.shortName')}</Span>
                                    </Text>
                                )),
                                yOffset: -35
                            };
                        }
                        return (
                            <Tr
                                elementRef={node => indexElement(RESULT_TYPE, index, node)}
                                key={`company-${companyId}-${ticker}`}
                                styles={resultStyles}
                                onMouseEnter={() => {
                                    highlightResult(RESULT_TYPE, index);
                                }}
                                onClick={onClickResult}
                            >
                                <Td>
                                    {iconUrl ? (
                                        <Div styles={styles.companyIcon}>
                                            <Img src={iconUrl} />
                                        </Div>
                                    ) : (
                                        <Div styles={styles.companyInitials}>
                                            <Text className="companyInitials" uppercase size={0}>
                                                {initials}
                                            </Text>
                                        </Div>
                                    )}
                                </Td>
                                <Td>
                                    <NameWrapper {...wrapperProps}>
                                        <Text size={0}>
                                            <Span weight="medium" styles={styles.ticker}>
                                                {ticker}
                                            </Span>
                                            <Span styles={styles.exchange}>{exchangeName}</Span>
                                        </Text>
                                        <Text size={2} styles={styles.company}>
                                            {companyName}
                                        </Text>
                                    </NameWrapper>
                                </Td>
                                <Td>
                                    {price && (
                                        <Div styles={styles.priceContainer}>
                                            <Div styles={styles.price}>
                                                <Text>{getNativePrice({ price, currency })}</Text>
                                                {!!iconType && <Icon type={iconType} color={color} />}
                                            </Div>
                                            <Text size={0} styles={styles.priceChangePercent}>
                                                {!!priceChange && (
                                                    <Span styles={{ ...styles.priceChange, color }}>
                                                        {priceChange.toFixed(2)}
                                                    </Span>
                                                )}
                                                {!!priceChangePercent && (
                                                    <Span styles={{ ...styles.priceChange, color }}>
                                                        ({priceChangePercent})
                                                    </Span>
                                                )}
                                            </Text>
                                        </Div>
                                    )}
                                </Td>
                            </Tr>
                        );
                    })
                )}
                {!loading && loadingMoreResults && (
                    <Tr>
                        <Td styles={styles.loadingMore} colSpan={4}>
                            <LoaderLogo />
                        </Td>
                    </Tr>
                )}
                {!loading && !loadingMoreResults && hasMoreResults && (
                    <Tr styles={styles.loadButtonRow} elementRef={node => indexElement('loadMoreButton', 0, node)}>
                        <Td colSpan={4}>
                            <ActionButton
                                onClick={loadMoreResults}
                                styles={
                                    highlightedType === 'loadMoreButton'
                                        ? styles.loadMoreButtonHighlighted
                                        : styles.loadMoreButton
                                }
                            >
                                <Text size={1}>Load More</Text>
                            </ActionButton>
                        </Td>
                    </Tr>
                )}
            </Table>
        );
    }
}

export const CompanyResultsUI = compose(withStyleSheet(styleSheet))(CompanyResults);
