import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { WithPermission } from 'components/WithPermission';
import { WithPreference } from 'components/WithPreference';
import { styleSheet } from './stylesheet';

class Option extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        env: PropTypes.string,
        lockedPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        onClick: PropTypes.func.isRequired,
        onLockedClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        preferences: PropTypes.objectOf(PropTypes.any),
        prefixIcon: PropTypes.bool.isRequired,
        selected: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        type: PropTypes.string
    };

    static defaultProps = {
        env: undefined,
        lockedPermission: undefined,
        passedStyles: {},
        permission: undefined,
        preferences: undefined,
        type: null
    };

    render() {
        const {
            children,
            disabled,
            env,
            lockedPermission,
            onClick,
            onLockedClick,
            passedStyles,
            permission,
            preferences,
            prefixIcon,
            selected,
            styles,
            theme,
            type
        } = this.props;
        let containerStyles = selected
            ? { ...styles.container, ...styles.selected, ...passedStyles }
            : { ...styles.container, ...passedStyles };
        const containerLockedStyles = { ...styles.containerLocked, ...passedStyles };
        let selectStyles = prefixIcon ? {} : styles.justifiedLeft;
        // Disabled styles
        if (disabled) {
            containerStyles = { ...containerStyles, ...styles.containerDisabled };
            selectStyles = { ...selectStyles, ...styles.selectDisabled };
        }
        if (type === 'checkbox') {
            selectStyles = selected
                ? { ...styles.checkboxSelected, ...selectStyles }
                : { ...styles.checkbox, ...selectStyles };
        } else if (type === 'radio') {
            selectStyles = selected
                ? { ...styles.radioSelected, ...selectStyles }
                : { ...styles.radio, ...selectStyles };
        }
        const lockElement = (
            <Div
                className="optionLockedContainer"
                styles={prefixIcon ? styles.lock : { ...styles.lock, ...styles.justifiedLeft }}
                onClick={onLockedClick}
            >
                <Icon type="lock02" color={theme.colors.gray04} />
            </Div>
        );
        const iconElement = (
            <Div styles={selectStyles} className="optionSelectContainer">
                {selected && <Icon type="checkMarkSmall" color={theme.colors.white01} />}
            </Div>
        );
        let Wrapper = Fragment;
        let wrapperProps = null;
        if (preferences) {
            Wrapper = WithPreference;
            wrapperProps = preferences;
        }
        return (
            <WithPermission env={env} permissions={permission}>
                <WithPermission permissions={lockedPermission}>
                    {({ restricted, isLoading }) => (
                        <Wrapper {...wrapperProps}>
                            <Div
                                styles={restricted || isLoading ? containerLockedStyles : containerStyles}
                                onClick={disabled || restricted || isLoading ? undefined : onClick}
                            >
                                {prefixIcon && !restricted && !isLoading ? iconElement : prefixIcon && lockElement}
                                {typeof children === 'function' ? children({ selected }) : children}
                                {!prefixIcon && !restricted && !isLoading ? iconElement : !prefixIcon && lockElement}
                            </Div>
                        </Wrapper>
                    )}
                </WithPermission>
            </WithPermission>
        );
    }
}

export const OptionUI = compose(withStyleSheet(styleSheet))(Option);
