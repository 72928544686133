export const styleSheet = theme => ({
    container: {
        margin: `5px ${theme.margins.margin01}`
    },
    detailItem: {
        display: 'flex',
        height: 40,
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.gray03}`,
        justifyContent: 'space-between'
    },
    textLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.colors.gray06,
        width: 110,
        flexShrink: 0
    },
    detailValue: {
        overflow: 'hidden',
        p: {
            lineHeight: '1.3em'
        },
        a: {
            color: theme.colors.blue01,
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    rowLink: {
        color: theme.colors.externalLink,
        cursor: 'pointer',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline'
        }
    }
});
