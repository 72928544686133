import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { PERMISSIONS } from 'consts';
import { TYPES } from 'consts/filters';
import { StreamFilter } from 'components/StreamFilter';
import { WithPermission } from 'components/WithPermission';
import { styleSheet } from './stylesheet';

class EventFilter extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.shape({
            type: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.any
        })
    };

    static defaultProps = {
        passedStyles: {},
        value: null
    };

    render() {
        const { styles, passedStyles, onChange, name, value } = this.props;
        return (
            <WithPermission permissions={[PERMISSIONS.featureEventsTonalSentiment]}>
                {({ isLoading, restricted }) => (
                    <StreamFilter
                        value={value}
                        name={name}
                        styles={{ ...styles.container, ...passedStyles }}
                        types={[
                            TYPES.eventTitle,
                            TYPES.eventType,
                            TYPES.eventDate,
                            TYPES.eventGroup,
                            ...(isLoading || restricted ? [] : [TYPES.eventTonalSentiment]),
                            TYPES.eventStatus,
                            TYPES.publishedTranscriptSource,
                            TYPES.mcap,
                            TYPES.country,
                            TYPES.exchangeCountry,
                            TYPES.offeringType,
                            TYPES.pGaapEps,
                            TYPES.evSales,
                            TYPES.evEbitda,
                            TYPES.evFcf,
                            TYPES.equity,
                            TYPES.sector
                        ]}
                        onChange={onChange}
                    />
                )}
            </WithPermission>
        );
    }
}

export const EventFilterUI = compose(withStyleSheet(styleSheet))(EventFilter);
