import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NavLink, generatePath } from 'react-router-dom';
import { withStyleSheet } from 'hoc/styles';
import { PERMISSIONS } from 'consts';
import { WithPermission } from 'components/WithPermission';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';

import { routes } from 'routes';
import { styleSheet } from './stylesheet';

class MobileTabBar extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showSearch: PropTypes.func.isRequired,
        unreadCount: PropTypes.number.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { styles, passedStyles, showSearch, unreadCount } = this.props;

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.tab}>
                    <NavLink to={generatePath(routes.mobileCompanies)} activeClassName="selected" replace>
                        <Icon type="company" color="#FFF" />
                    </NavLink>
                </Div>
                <Div styles={styles.tab}>
                    <NavLink to={generatePath(routes.mobileEvents)} activeClassName="selected" replace>
                        <Icon type="calendar" color="#FFF" />
                    </NavLink>
                </Div>
                <Div styles={styles.tabSearch} onClick={showSearch}>
                    <Icon type="search03" color="#FFF" />
                </Div>
                <WithPermission permissions={[PERMISSIONS.featureAlerts]}>
                    <Div styles={styles.tabAlerts}>
                        <NavLink to={routes.mobileAlerts} activeClassName="selected" replace>
                            <Icon type="bell03" color="#FFF" />
                            {unreadCount > 0 && (
                                <Div styles={styles.unreadCount}>
                                    <Text size={1}>{unreadCount}</Text>
                                </Div>
                            )}
                        </NavLink>
                    </Div>
                </WithPermission>
                <Div styles={styles.tabMenu}>
                    <NavLink to={routes.mobileMenu} activeClassName="selected" replace>
                        <Icon type="menu" color="#FFF" />
                    </NavLink>
                </Div>
            </Div>
        );
    }
}

export const MobileTabBarUI = compose(withStyleSheet(styleSheet))(MobileTabBar);
