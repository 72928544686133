import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { TextInput } from 'components/TextInput';
import { Tooltip } from 'components/Tooltip';
import { get } from 'utils';
import { styleSheet } from './stylesheet';
import { LoaderLogo } from '../../LoaderLogo';

class DashboardGalleryMenu extends PureComponent {
    static propTypes = {
        collapsed: PropTypes.arrayOf(PropTypes.string).isRequired,
        currentDashboard: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool.isRequired,
        onClickDashboard: PropTypes.func,
        onKeyDown: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onToggleSection: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        searchTerm: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.object).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        currentDashboard: null,
        onClickDashboard: undefined,
        passedStyles: {}
    };

    renderSections() {
        const {
            collapsed,
            currentDashboard,
            loading,
            onClickDashboard,
            onToggleSection,
            sections,
            styles,
            theme
        } = this.props;
        let content;

        if (loading) {
            content = <LoaderLogo height={60} styles={styles.loading} />;
        }

        if (!loading && !sections.length) {
            content = (
                <Text size={2} styles={styles.noResults}>
                    No dashboards found.
                    <br />
                    Try searching for something else.
                </Text>
            );
        }

        if (!loading && sections.length) {
            content = sections.map(({ tag, dashboards }) => {
                const isCollapsed = collapsed.includes(tag);
                return (
                    <Div key={tag} styles={isCollapsed ? styles.sectionCollapsed : styles.section}>
                        <Div
                            styles={isCollapsed ? styles.sectionHeader : styles.sectionHeaderExpanded}
                            onClick={() => onToggleSection(tag)}
                        >
                            <Icon type="chevron02" color={theme.colors.gray02} />
                            <Text size={0} styles={styles.sectionName} uppercase weight="medium">
                                {tag}
                                {dashboards.length && (
                                    <Span styles={styles.sectionDashCount}>&nbsp;({dashboards.length})</Span>
                                )}
                            </Text>
                        </Div>
                        {!isCollapsed &&
                            dashboards.map(dashboard => {
                                const { id, name: dashboardName, clonedToId, starred } = dashboard;
                                const added = clonedToId || starred;
                                const isCurrent = get(currentDashboard, 'id') === id;
                                const tooltipText = <Text size={2}>{dashboardName}</Text>;
                                return (
                                    <Tooltip
                                        key={id}
                                        content={tooltipText}
                                        isEnabled={dashboardName.length > (isCurrent && added ? 24 : 26)}
                                        slideIn
                                        styles={styles.dashNameTooltip}
                                        useElementOffsetLeft
                                        useElementOffsetTop
                                        xOffset={235}
                                        yOffset={-5}
                                    >
                                        {({ showTooltip, hideTooltip }) => (
                                            <Div
                                                onClick={() =>
                                                    isCurrent || !onClickDashboard ? {} : onClickDashboard(id)
                                                }
                                                onMouseEnter={showTooltip}
                                                onMouseLeave={hideTooltip}
                                                styles={isCurrent ? styles.dashboardCurrent : styles.dashboard}
                                            >
                                                <Text size={3}>{dashboardName}</Text>
                                                {added && <Icon type="checkMarkSmall" color={theme.colors.green02} />}
                                            </Div>
                                        )}
                                    </Tooltip>
                                );
                            })}
                    </Div>
                );
            });
        }

        return content;
    }

    render() {
        const { onKeyDown, onSearch, passedStyles, searchTerm, styles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Div styles={styles.scrollContainer}>
                    <Div styles={styles.searchContainer}>
                        <TextInput
                            autoComplete="off"
                            clearable
                            icon="search03"
                            name="dashGallerySearch"
                            onChange={onSearch}
                            onKeyDown={onKeyDown}
                            placeholder="Search..."
                            styles={styles.search}
                            value={searchTerm}
                        />
                    </Div>
                    {this.renderSections()}
                </Div>
            </Div>
        );
    }
}

export const DashboardGalleryMenuUI = compose(withStyleSheet(styleSheet))(DashboardGalleryMenu);
