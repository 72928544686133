import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withData } from './data';
import { AlertBannerUI } from './ui';

export class AlertBanner extends PureComponent {
    static displayName = 'AlertBannerContainer';

    static propTypes = {
        alert: PropTypes.objectOf(PropTypes.any),
        content: PropTypes.objectOf(PropTypes.any),
        created: PropTypes.string,
        dashboard: PropTypes.objectOf(PropTypes.any),
        equity: PropTypes.objectOf(PropTypes.any),
        event: PropTypes.objectOf(PropTypes.any),
        loading: PropTypes.bool,
        markNotificationsRead: PropTypes.func.isRequired,
        message: PropTypes.objectOf(PropTypes.any),
        notificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        notificationType: PropTypes.string,
        notificationsCount: PropTypes.number.isRequired,
        stream: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        alert: undefined,
        content: undefined,
        created: undefined,
        dashboard: undefined,
        equity: undefined,
        event: undefined,
        loading: false,
        message: undefined,
        notificationId: undefined,
        notificationType: undefined,
        stream: undefined,
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);

        this.state = {
            visible: false
        };
    }

    componentDidUpdate({ alert: prevAlert, loading: prevLoading }) {
        const { alert, loading } = this.props;
        if ((!prevLoading && loading) || (!prevAlert && alert)) {
            this.setState({ visible: true }, () => {
                setTimeout(() => {
                    this.setState({ visible: false });
                }, 3000);
            });
        }
    }

    onClick() {
        const { markNotificationsRead, notificationId } = this.props;
        this.setState({ visible: false }, () => {
            markNotificationsRead(notificationId);
        });
    }

    render() {
        const { visible } = this.state;
        const {
            alert,
            content,
            created,
            dashboard,
            equity,
            event,
            loading,
            message,
            notificationType,
            notificationsCount,
            stream,
            styles
        } = this.props;
        return (
            <AlertBannerUI
                alert={alert}
                content={content}
                created={created}
                dashboard={dashboard}
                equity={equity}
                event={event}
                loading={loading}
                message={message}
                notificationType={notificationType}
                notificationsCount={notificationsCount}
                onClick={this.onClick}
                stream={stream}
                styles={styles}
                visible={visible}
            />
        );
    }
}

export const AlertBannerContainer = compose(withData())(AlertBanner);
