import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ActionButtonUI } from './ui';

export class ActionButton extends PureComponent {
    static displayName = 'ActionButtonContainer';

    static propTypes = {
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func
    };

    static defaultProps = {
        onMouseEnter: undefined,
        onMouseLeave: undefined
    };

    constructor(props) {
        super(props);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.state = {
            isHovering: false
        };
    }

    onMouseEnter() {
        const { onMouseEnter } = this.props;
        this.setState({ isHovering: true }, () => {
            if (onMouseEnter) onMouseEnter();
        });
    }

    onMouseLeave() {
        const { onMouseLeave } = this.props;
        this.setState({ isHovering: false }, () => {
            if (onMouseLeave) onMouseLeave();
        });
    }

    render() {
        const { isHovering } = this.state;
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return (
            <ActionButtonUI
                {...rest}
                isHovering={isHovering}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }
}

export const ActionButtonContainer = compose()(ActionButton);
