import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Checkbox } from 'components/Checkbox';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon';
import { RawHTML } from 'components/RawHTML';
import { Stream } from 'components/Stream';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { getHighlightColor } from 'utils';
import { styleSheet } from './stylesheet';

class BookmarkList extends PureComponent {
    static propTypes = {
        archiving: PropTypes.bool.isRequired,
        bookmarks: PropTypes.arrayOf(PropTypes.object),
        deleting: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        loadMoreMatches: PropTypes.func,
        onArchive: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onSelectBookmark: PropTypes.func.isRequired,
        onTooltipHide: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedBookmarks: PropTypes.arrayOf(PropTypes.string).isRequired,
        setTooltipVisibility: PropTypes.func.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        bookmarks: [],
        loadMoreMatches: undefined,
        passedStyles: {},
        streamId: undefined,
        streamProps: undefined
    };

    constructor(props) {
        super(props);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
    }

    renderBookmark(bookmark, hideTooltip) {
        const { onSelectBookmark, selectedBookmarks, styles } = this.props;
        const { id, date, highlight, highlightColor, title, url } = bookmark;
        const Wrapper = url ? Link : Fragment;
        const wrapperProps = url ? { to: url } : null;
        const backgroundColor = getHighlightColor(highlightColor)?.color;
        const borderLeft = `3px solid ${getHighlightColor(highlightColor)?.dark}`;
        return (
            <Div key={id} onClick={() => onSelectBookmark(id)} styles={styles.bookmarkRow}>
                <Hint styles={styles.checkbox} text="Select Highlight" fromLeft growLeft yOffset={-45} xOffset={-10}>
                    <Checkbox checked={selectedBookmarks.includes(id)} size={3} />
                </Hint>
                <Wrapper {...wrapperProps}>
                    <Div styles={styles.bookmarkContent} onClick={url ? hideTooltip : undefined}>
                        <Div styles={styles.bookmarkHeader}>
                            <Text size={1} styles={styles.bookmarkTitle} weight="medium">
                                {title}
                            </Text>
                            <Text size={1} styles={styles.bookmarkDate}>
                                {date.toString('MMM d')}
                            </Text>
                        </Div>
                        <Div styles={{ ...styles.bookmarkQuote, backgroundColor, borderLeft }}>
                            <RawHTML html={highlight} />
                        </Div>
                    </Div>
                </Wrapper>
            </Div>
        );
    }

    renderToolbar() {
        const { archiving, deleting, onArchive, onDelete, selectedBookmarks, styles } = this.props;
        return (
            <Div styles={styles.toolbar}>
                {selectedBookmarks.length > 0 && (
                    <Text size={1} styles={styles.numSelected}>
                        {selectedBookmarks.length} selected
                    </Text>
                )}
                <ActionButton
                    disabled={!selectedBookmarks.length}
                    loading={deleting}
                    onClick={onDelete}
                    styles={styles.buttonDelete}
                >
                    <Text size={1}>Remove</Text>
                </ActionButton>
                <ActionButton
                    disabled={!selectedBookmarks.length}
                    loading={archiving}
                    onClick={onArchive}
                    styles={styles.buttonArchive}
                >
                    <Text size={1}>Archive</Text>
                </ActionButton>
            </Div>
        );
    }

    renderTooltipContent({ hideTooltip }) {
        const { bookmarks, loading, loadMoreMatches, streamId, streamProps, styles } = this.props;
        return (
            <Stream
                {...streamProps}
                emptyText="No highlights yet..."
                loading={loading}
                loadMoreMatches={loadMoreMatches}
                offset={bookmarks.length}
                renderToolbar={() => this.renderToolbar()}
                streamId={streamId}
                styles={styles.stream}
                width="100%"
            >
                {bookmarks.map(b => (
                    <ErrorBoundary key={`bookmark-row-error-boundary-${b.id}`}>
                        {this.renderBookmark(b, hideTooltip)}
                    </ErrorBoundary>
                ))}
            </Stream>
        );
    }

    render() {
        const { onTooltipHide, passedStyles, setTooltipVisibility, styles, theme } = this.props;
        return (
            <Tooltip
                content={this.renderTooltipContent}
                isEnabled
                keepInViewport
                onHide={onTooltipHide}
                onShow={() => setTooltipVisibility(true)}
                persistOnMouseExit
                styles={styles.tooltip}
                useElementOffsetLeft
                useElementOffsetTop
                useOutsideClickHandler
                xOffset={-188}
                yOffset={38}
            >
                {({ showTooltip }) => (
                    <Hint
                        growLeft
                        text="My Highlights"
                        data-tname="bookmarkList"
                        onClick={showTooltip}
                        styles={{ ...styles.container, ...passedStyles }}
                    >
                        <Icon color={theme.colors.gray04} type="highlighter" />
                    </Hint>
                )}
            </Tooltip>
        );
    }
}

export const BookmarkListUI = compose(withStyleSheet(styleSheet))(BookmarkList);
