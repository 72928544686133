export const styleSheet = theme => ({
    container: {
        width: 480
    },
    content: {
        margin: '20px 35px 10px',
        height: 280
    },
    downloadBtn: {
        ...theme.content.blueButton,
        marginTop: 20,
        justifyContent: 'center',
        color: theme.colors.white01,
        borderRadius: 6,
        boxShadow: 'unset'
    },
    downloadInfo: {
        width: '100%',
        tr: {
            borderBottom: `1px solid ${theme.colors.gray03}`,
            td: {
                padding: '16px 0',
                ':first-of-type': {
                    fontWeight: theme.fonts.fontWeights.medium,
                    paddingRight: 20
                }
            },
            ':last-of-type': {
                borderBottom: 'unset'
            }
        }
    },
    editRow: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    loading: {
        height: 310,
        width: 480,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: {
            textAlign: 'center',
            lineHeight: '1.3em',
            marginBottom: 20
        }
    },
    truncate: {
        width: 180,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});
