import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withUrlContext } from 'hoc/url';
import regular from 'styles/fonts/Rubik-Regular.ttf';
import medium from 'styles/fonts/Rubik-Medium.ttf';
import bold from 'styles/fonts/Rubik-Bold.ttf';
import serif from 'styles/fonts/CharisSIL-B.ttf';
import { ReportUI } from './ui';
import { withData } from './data';
import { generatePdfDocument } from './pdf';
import { generateWordDocument } from './doc';

export class Report extends PureComponent {
    static displayName = 'ReportContainer';

    static propTypes = {
        setToolbarTitle: PropTypes.func,
        title: PropTypes.string,
        loading: PropTypes.bool,
        created: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        endDate: PropTypes.string,
        startDate: PropTypes.string,
        blocks: PropTypes.arrayOf(PropTypes.any),
        topSummary: PropTypes.arrayOf(PropTypes.any)
    };

    static defaultProps = {
        setToolbarTitle: null,
        title: 'Loading...',
        startDate: undefined,
        endDate: undefined,
        blocks: [],
        topSummary: [],
        loading: false,
        created: undefined
    };

    constructor(props) {
        super(props);

        this.loadFonts = this.loadFonts.bind(this);
        this.containerRef = createRef();
        this.documentTitle = document.title;
        this.toggleSummary = this.toggleSummary.bind(this);
        this.onDownloadPdf = this.onDownloadPdf.bind(this);
        this.onDownloadWord = this.onDownloadWord.bind(this);

        this.state = {
            showSummary: false,
            loadingPdf: false,
            loadingWord: false
        };
    }

    componentDidMount() {
        this.loadFonts();
    }

    componentDidUpdate({ title: prevTitle }) {
        const { title } = this.props;

        if (prevTitle !== title) {
            document.title = `Aiera | Report | ${title}`;
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
    }

    loadFonts() {
        import('@react-pdf/renderer').then(({ Font }) => {
            let attempts = 0;
            return new Promise((resolve, reject) => {
                let hasSans = false;
                let hasSerif = false;
                Font.register({
                    family: 'Rubik',
                    format: 'truetype',
                    fonts: [{ src: regular }, { src: medium, fontWeight: 500 }, { src: bold, fontWeight: 700 }]
                });

                Font.register({
                    family: 'CharisSIL',
                    format: 'truetype',
                    src: serif,
                    fontWeight: 700
                });
                Font.load({ fontFamily: 'CharisSIL' }).then(() => {
                    hasSerif = true;
                });
                Font.load({ fontFamily: 'Rubik' }).then(() => {
                    hasSans = true;
                });

                const fontsLoaded = () => {
                    this.fontLoader = setTimeout(() => {
                        attempts += 1;
                        if (attempts < 5) {
                            if (hasSans && hasSerif) {
                                resolve();
                            } else {
                                fontsLoaded();
                            }
                        } else {
                            reject();
                        }
                    }, 1000);
                };

                fontsLoaded();
            });
        });
    }

    onDownloadPdf(styles) {
        const { title, created, blocks } = this.props;
        this.setState({ loadingPdf: true }, () => {
            generatePdfDocument({
                onComplete: () => this.setState({ loadingPdf: false }),
                styles,
                created,
                blocks,
                title
            });
        });
    }

    onDownloadWord() {
        const { title, created, blocks } = this.props;
        this.setState({ loadingWord: true }, () => {
            generateWordDocument({
                onComplete: () => this.setState({ loadingWord: false }),
                created,
                blocks,
                title
            });
        });
    }

    toggleSummary() {
        const { showSummary } = this.state;
        this.setState({
            showSummary: !showSummary
        });
    }

    render() {
        const {
            setToolbarTitle,
            title,
            created,
            blocks,
            endDate,
            startDate,
            topSummary,
            pathname,
            loading
        } = this.props;
        const { showSummary, loadingPdf, loadingWord } = this.state;

        return (
            <ReportUI
                title={title}
                created={created}
                blocks={blocks}
                endDate={endDate}
                startDate={startDate}
                topSummary={topSummary}
                pathname={pathname}
                loading={loading}
                loadingPdf={loadingPdf}
                loadingWord={loadingWord}
                containerRef={this.containerRef}
                onDownloadPdf={this.onDownloadPdf}
                onDownloadWord={this.onDownloadWord}
                setToolbarTitle={setToolbarTitle}
                showSummary={showSummary}
                toggleSummary={this.toggleSummary}
            />
        );
    }
}

export const ReportContainer = compose(withUrlContext(['pathname']), withData())(Report);
