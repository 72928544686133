import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { ContentTabs } from 'components/ContentTabs';
import { TextInput } from 'components/TextInput';
import { Text } from 'components/Text';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LoaderLogo } from 'components/LoaderLogo';
import { RawHTML } from 'components/RawHTML';
import { safeRegExp } from 'utils';
import { PERMISSIONS } from 'consts';
import { NewsHighlights } from '../NewsHighlights';
import { NewsMentions } from '../NewsMentions';
import { styleSheet } from './stylesheet';

class NewsSidebar extends PureComponent {
    static propTypes = {
        containerRef: PropTypes.objectOf(PropTypes.any).isRequired,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        loading: PropTypes.bool.isRequired,
        matchCount: PropTypes.number.isRequired,
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        onSearch: PropTypes.func.isRequired,
        onSearchFocus: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectBlock: PropTypes.func.isRequired,
        scrollToSearchResult: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.any),
        searchTerm: PropTypes.string,
        selectTab: PropTypes.func.isRequired,
        selectedTab: PropTypes.string.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        keyMentions: [],
        monitorMatches: [],
        passedStyles: {},
        searchResults: [],
        searchTerm: ''
    };

    // Split the text and insert styled spans when there are matches for the searchTerm
    getHighlightedText(result) {
        const { searchTerm, theme } = this.props;
        let highlighted = [result];

        if (result) {
            const parts = result.split(safeRegExp(searchTerm));
            highlighted = parts.map(part => {
                if (part.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return `<span style="${`background-color: ${theme.colors.blue08};color: ${theme.colors.white01}`}">${part}</span>`;
                }
                return part;
            });
        }

        return highlighted.join('');
    }

    renderHeader() {
        const { onSearch, onSearchFocus, searchTerm, styles, selectTab, selectedTab } = this.props;

        return (
            <Div styles={styles.header}>
                <TextInput
                    name="articleSearch"
                    onChange={onSearch}
                    value={searchTerm}
                    styles={styles.searchBox}
                    icon="search03"
                    placeholder="Search article..."
                    clearable
                    onFocus={onSearchFocus}
                />
                <ContentTabs
                    styles={styles.tabs}
                    onSelect={selectTab}
                    options={[
                        {
                            label: 'Highlights',
                            value: 'highlights',
                            permissions: [PERMISSIONS.featureBookmarks]
                        },
                        { label: 'Key Mentions', value: 'keyMentions' }
                    ]}
                    selectedOption={selectedTab}
                />
            </Div>
        );
    }

    renderKeyMentions() {
        const { selectBlock, containerRef, matchCount, keyMentions, monitorMatches, styles } = this.props;

        return (keyMentions && keyMentions.length > 0) || (monitorMatches && monitorMatches.length > 0) ? (
            <ErrorBoundary>
                <NewsMentions
                    selectBlock={selectBlock}
                    containerRef={containerRef}
                    matchCount={matchCount}
                    mentionsTitle="Suggested"
                    streamMatches={keyMentions}
                />
                <NewsMentions
                    selectBlock={selectBlock}
                    containerRef={containerRef}
                    matchCount={matchCount}
                    mentionsTitle="Monitor Matches"
                    dashboardStreamMatches={monitorMatches}
                />
            </ErrorBoundary>
        ) : (
            <Div styles={styles.empty}>
                <Text size={3} styles={styles.emptyText}>
                    No Key Mentions Found.
                </Text>
                <Text size={1} styles={styles.emptyText}>
                    Suggested key terms and phrases are determined by our experts and NLP tooling.
                </Text>
            </Div>
        );
    }

    renderSearchResults() {
        const { searchResults, searchTerm, styles, scrollToSearchResult } = this.props;

        if (searchResults && searchResults.length > 0) {
            return searchResults.map(({ text }, index) => {
                const key = `result-${text.slice(0, 5)}-${index}`;
                return (
                    <Div styles={styles.searchResult} key={key} onClick={() => scrollToSearchResult(index)}>
                        <Text size={0} styles={styles.searchResultCount}>
                            {`${index + 1} of ${searchResults.length}`}
                        </Text>
                        <Div styles={styles.searchResultText}>
                            <RawHTML html={this.getHighlightedText(text)} />
                        </Div>
                    </Div>
                );
            });
        }

        return (
            <Div styles={styles.empty}>
                <Text size={3} styles={styles.emptyText}>
                    {`No results for ${searchTerm}`}
                </Text>
            </Div>
        );
    }

    render() {
        const {
            containerRef,
            highlightsFilterKey,
            highlightsSortKey,
            keyMentions,
            loading,
            monitorMatches,
            passedStyles,
            selectBlock,
            searchResults,
            selectedTab,
            setHighlightsFilter,
            setHighlightsSort,
            shareBookmarks,
            styles
        } = this.props;
        const matchDict = {
            keyMentions: [...(keyMentions || []), ...(monitorMatches || [])],
            highlights: [],
            searchResults
        };
        const hasNoMatches = !matchDict[selectedTab] || matchDict[selectedTab].length === 0;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} className="print-hide">
                {this.renderHeader()}
                {selectedTab !== 'highlights' && loading && hasNoMatches ? (
                    <Div styles={styles.loader}>
                        <LoaderLogo height={60} />
                    </Div>
                ) : (
                    <Fragment>
                        {selectedTab === 'highlights' ? (
                            <NewsHighlights
                                selectBlock={selectBlock}
                                containerRef={containerRef}
                                filterKey={highlightsFilterKey}
                                setFilter={setHighlightsFilter}
                                setSort={setHighlightsSort}
                                shareBookmarks={shareBookmarks}
                                sortKey={highlightsSortKey}
                            />
                        ) : (
                            <Div styles={styles.scrollContainer}>
                                {selectedTab === 'keyMentions' && this.renderKeyMentions()}
                                {selectedTab === 'searchResults' && this.renderSearchResults()}
                            </Div>
                        )}
                    </Fragment>
                )}
            </Div>
        );
    }
}

export const NewsSidebarUI = compose(withStyleSheet(styleSheet))(NewsSidebar);
