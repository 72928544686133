export const styleSheet = theme => ({
    formToggle: {
        height: 40,
        padding: '0 15px',
        p: {
            fontSize: theme.fonts.fontSizes.size03,
            letterSpacing: 0,
            textTransform: 'unset'
        }
    },
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: '16px',
        ':hover': {
            '> p': {
                color: theme.colors.gray08
            },
            '.toggleOn': {
                backgroundColor: theme.colors.blue04
            },
            '.toggleOff': {
                backgroundColor: theme.colors.blue08
            }
        }
    },
    containerDisabled: {
        position: 'relative',
        cursor: 'not-allowed',
        opacity: 0.6,
        ':hover': {
            '> p': {
                color: theme.colors.gray04
            }
        }
    },
    containerGray: {
        backgroundColor: theme.colors.white03,
        padding: '0 12px',
        borderRadius: '6px',
        height: '30px'
    },
    label: {
        textTransform: 'capitalize',
        color: theme.colors.gray04
    },
    labelOn: {
        color: theme.colors.gray08
    },
    labelLeft: {
        marginLeft: -2,
        marginRight: 6
    },
    labelRight: {
        marginRight: -2,
        marginLeft: 6
    },
    toggleContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: '6px',
        width: '30px',
        borderRadius: '10px',
        backgroundColor: theme.colors.gray05,
        boxShadow: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.04)'
    },
    toggleContainerDisabled: {
        cursor: 'not-allowed'
    },
    toggle: {
        position: 'absolute',
        left: 14,
        boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.10)`,
        backgroundColor: theme.colors.blue08,
        borderRadius: '12px',
        height: '16px',
        width: '16px',
        transition: 'all 0.1s ease-in-out'
    },
    toggleOff: {
        transition: 'all 0.1s ease-in-out',
        left: 0
    },
    toggleOffGray: {
        backgroundColor: theme.colors.gray04
    },
    formLabel: {
        ...theme.content.formLabel
    }
});
