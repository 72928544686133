export const styleSheet = theme => ({
    container: {
        position: 'relative'
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        letterSpacing: '1px',
        color: theme.colors.inputLabel
    },
    textError: {
        letterSpacing: '1px',
        color: theme.colors.inputError
    },
    textarea: {
        display: 'block',
        borderRadius: '2px',
        marginTop: '6px',
        width: '100%',
        backgroundColor: theme.colors.input,
        minHeight: '160px',
        border: 'none',
        fontFamily: theme.fonts.fontFamily,
        fontSize: theme.fonts.fontSizes.size04,
        padding: `calc(${theme.margins.margin01} - 3px) ${theme.margins.margin01}`,
        resize: 'vertical',
        transition: 'border 0.2s ease-in-out',
        '::placeholder': {
            color: theme.colors.inputPlaceholder
        },
        ':focus': {
            boxShadow: `inset 0 0 0 1px ${theme.colors.gray02}`
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 50px ${theme.colors.input} inset`
        }
    },
    textareaError: {
        boxShadow: `0 1px 0 0 ${theme.colors.inputError}`
    }
});
