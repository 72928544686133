export const styleSheet = theme => ({
    shareDashLabel: {
        letterSpacing: '1px',
        color: theme.colors.gray06,
        fontSize: theme.fonts.fontSizes.size00,
        margin: `${theme.margins.margin01} 0 ${theme.margins.margin00} 0`
    },
    shareDash: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.colors.white03}`,
        borderRadius: '6px',
        padding: '12px',
        ':hover': {
            backgroundColor: theme.colors.white03
        }
    },
    copyLink: {
        alignItems: 'flex-start',
        maxWidth: '350px',
        width: '100%',
        'div > p': {
            position: 'relative'
        }
    },
    shareDashUrlRefresh: {
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    tooltipShareDash: {
        ...theme.content.tooltipText,
        maxWidth: 460
    }
});
