import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { ModuleTableUI } from './ui';

export class ModuleTable extends PureComponent {
    static displayName = 'ModuleTableContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <ModuleTableUI {...rest} />;
    }
}

export const ModuleTableContainer = compose()(ModuleTable);
