export const styleSheet = theme => {
    const match = {
        ...theme.content.annotated,
        ...theme.content.box,
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 14px',
        margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.blue09
        }
    };
    return {
        listenButton: {
            ...theme.content.blueButton,
            display: 'flex',
            alignSelf: 'start',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 30,
            height: 26,
            cursor: 'pointer',
            padding: '0 10px 0 8px',
            margin: '14px 0 0 -2px',
            svg: {
                height: 8
            },
            p: {
                marginLeft: 3,
                color: theme.colors.white01
            }
        },
        match: {
            ...match
        },
        matchSelected: {
            ...match,
            border: `1px solid ${theme.colors.blue08}`,
            boxShadow: `inset 0 0 0 1px ${theme.colors.blue08}, 0 2px 4px 0 rgba(66,65,82,0.06)`
        },
        matchSpeakerBlock: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10
        },
        matchSpeakerName: {
            color: theme.colors.black01,
            marginBottom: 4,
            maxWidth: 200,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        matchSpeakerInfo: {
            color: theme.colors.gray06,
            lineHeight: '1.2em'
        },
        matchCount: {
            color: theme.colors.gray04,
            marginBottom: 6,
            whiteSpace: 'nowrap'
        },
        matchTranscript: {
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.3em',
            marginTop: 6
        }
    };
};
