import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Icon } from 'components/Icon';
import { styleSheet } from './stylesheet';

class CardLockButton extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const { passedStyles, styles, theme, onClick } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }} onClick={onClick}>
                <Icon type="lock02" color={theme.colors.black01} />
            </Div>
        );
    }
}

export const CardLockButtonUI = compose(withStyleSheet(styleSheet))(CardLockButton);
