import 'react-phone-number-input/style.css';
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Hint } from 'components/Hint';
import { LoaderLogo } from 'components/LoaderLogo';
import { Text } from 'components/Text';
import { styleSheet } from './stylesheet';

class PhoneNumberInput extends PureComponent {
    static propTypes = {
        autoComplete: PropTypes.string,
        defaultCountry: PropTypes.string,
        error: PropTypes.string,
        formatNumber: PropTypes.func,
        helpElement: PropTypes.objectOf(PropTypes.any),
        label: PropTypes.string,
        localStorageKey: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        ReactPhoneInput: PropTypes.elementType,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        autoComplete: undefined,
        defaultCountry: undefined,
        error: undefined,
        formatNumber: null,
        helpElement: undefined,
        label: undefined,
        localStorageKey: undefined,
        name: undefined,
        passedStyles: {},
        placeholder: undefined,
        ReactPhoneInput: undefined,
        value: ''
    };

    renderLocalStorageHint() {
        const { formatNumber, localStorageKey, name, onChange, styles } = this.props;
        const localStoragePhoneNumber = localStorage.getItem(localStorageKey);
        if (localStoragePhoneNumber && formatNumber) {
            const formattedNumber = formatNumber(localStoragePhoneNumber);
            if (formattedNumber) {
                return (
                    <Hint
                        onClick={() => onChange({ name, value: localStoragePhoneNumber })}
                        text="This is the last phone number used for this field in this browser."
                        xOffset={45}
                    >
                        <Text size={1} styles={styles.localStorageHint}>
                            {`Use ${formattedNumber}?`}
                        </Text>
                    </Hint>
                );
            }
        }
        return null;
    }

    render() {
        const {
            autoComplete,
            defaultCountry,
            error,
            helpElement,
            label,
            onChange,
            name,
            passedStyles,
            placeholder,
            ReactPhoneInput,
            styles,
            value: initialValue
        } = this.props;
        // Nest input styles under the container because passing them to react-phone-number-input doesn't work properly
        let inputStyles = error ? { ...styles.input, ...styles.inputError } : styles.input;
        if (initialValue) inputStyles = { ...inputStyles, ...styles.inputWithText };
        return (
            <Div styles={{ ...styles.container, ...{ input: inputStyles }, ...passedStyles }}>
                {ReactPhoneInput ? (
                    <Fragment>
                        {(label || error) && (
                            <Div style={styles.labelContainer}>
                                {label && (
                                    <Text label size={1} styles={styles.label}>
                                        {label}
                                    </Text>
                                )}
                                {error && (
                                    <Text label size={1} styles={styles.error}>
                                        {error}
                                    </Text>
                                )}
                            </Div>
                        )}
                        <ReactPhoneInput
                            autoComplete={autoComplete}
                            defaultCountry={defaultCountry}
                            initialValueFormat="national"
                            onChange={value => onChange({ name, value })}
                            placeholder={placeholder}
                            value={initialValue}
                        />
                        <Div styles={styles.subContent}>
                            {this.renderLocalStorageHint()}
                            {helpElement}
                        </Div>
                    </Fragment>
                ) : (
                    <LoaderLogo />
                )}
            </Div>
        );
    }
}

export const PhoneNumberInputUI = compose(withStyleSheet(styleSheet))(PhoneNumberInput);
