import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Checkbox } from 'components/Checkbox';
import { Hint } from 'components/Hint';

import { styleSheet } from './stylesheet';

class EventStreamToolbar extends PureComponent {
    static propTypes = {
        hasEarningsOnly: PropTypes.bool.isRequired,
        hasTranscript: PropTypes.bool.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showHasTranscriptToggle: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        toggleEarningsOnly: PropTypes.func.isRequired,
        toggleHasTranscript: PropTypes.func.isRequired
    };

    static defaultProps = {
        passedStyles: {}
    };

    render() {
        const {
            hasTranscript,
            hasEarningsOnly,
            passedStyles,
            showHasTranscriptToggle,
            styles,
            toggleEarningsOnly,
            toggleHasTranscript
        } = this.props;
        return (
            <Div className="eventStreamToolbar" styles={{ ...styles.container, ...passedStyles }}>
                {showHasTranscriptToggle && (
                    <Hint
                        text={hasTranscript ? 'Show Events Without Transcript' : 'Only Show Events With Transcripts'}
                        styles={styles.checkbox}
                        onClick={toggleHasTranscript}
                    >
                        <Checkbox size={3} checked={hasTranscript} />
                        <Text size={1}>Has Transcript</Text>
                    </Hint>
                )}
                <Hint
                    onClick={toggleEarningsOnly}
                    styles={styles.checkbox}
                    text={hasEarningsOnly ? 'Show Non-Earnings Events' : 'Only Show Earnings Events'}
                >
                    <Checkbox checked={hasEarningsOnly} size={3}>
                        Earnings only
                    </Checkbox>
                </Hint>
            </Div>
        );
    }
}

export const EventStreamToolbarUI = compose(withStyleSheet(styleSheet))(EventStreamToolbar);
