import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { AlertToastUI } from './ui';

export class AlertToast extends PureComponent {
    static displayName = 'AlertToastContainer';

    render() {
        const { ...rest } = this.props;

        // PUT OTHER PROPS AFTER {...rest}
        return <AlertToastUI {...rest} />;
    }
}

const mapStateToProps = ({ AlertToast: AToast }) => {
    const { visible, type } = AToast;
    return {
        visible,
        type
    };
};

const connectedComponent = connect(mapStateToProps)(AlertToast);

export const AlertToastContainer = compose()(connectedComponent);
