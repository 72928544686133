import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { NotFound } from 'components/NotFound';
import { styleSheet } from './stylesheet';

class NoRouteMatch extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired
    };

    render() {
        const { styles } = this.props;
        return <NotFound syles={styles} />;
    }
}

export const NoRouteMatchUI = compose(withStyleSheet(styleSheet))(NoRouteMatch);
