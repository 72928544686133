import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

class Filter extends PureComponent {
    static propTypes = {
        filterValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.bool]),
        handleSelectOperator: PropTypes.func.isRequired,
        handleSelectType: PropTypes.func.isRequired,
        handleSelectValue: PropTypes.func.isRequired,
        operatorName: PropTypes.string,
        operatorPlaceholder: PropTypes.string,
        operators: PropTypes.arrayOf(PropTypes.any).isRequired,
        operatorValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        typeName: PropTypes.string,
        typePlaceholder: PropTypes.string,
        types: PropTypes.arrayOf(PropTypes.any).isRequired,
        typeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        valueComponent: PropTypes.element
    };

    static defaultProps = {
        filterValue: null,
        operatorName: 'operatorSelect',
        operatorPlaceholder: 'Operator',
        operatorValue: '',
        passedStyles: {},
        typeName: 'typeSelect',
        typePlaceholder: 'Filter type',
        typeValue: '',
        valueComponent: null
    };

    render() {
        const {
            filterValue,
            handleSelectOperator,
            handleSelectType,
            handleSelectValue,
            operatorName,
            operatorPlaceholder,
            operators,
            operatorValue,
            passedStyles,
            styles,
            typeName,
            typePlaceholder,
            types,
            typeValue,
            valueComponent
        } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                <Dropdown
                    name={typeName}
                    placeholder={typePlaceholder}
                    options={types}
                    value={typeValue}
                    onChange={handleSelectType}
                    styles={styles.filterType}
                    disableHoverOpen
                />
                <Dropdown
                    name={operatorName}
                    placeholder={operatorPlaceholder}
                    options={operators}
                    value={operatorValue}
                    onChange={handleSelectOperator}
                    disabled={operators.length === 0}
                    styles={styles.operator}
                    disableHoverOpen
                />
                {React.cloneElement(valueComponent, {
                    onChange: (arg1, arg2) => {
                        if (get(arg1, 'constructor.name') === 'SyntheticEvent') {
                            // TODO This check is for the TextInput component,
                            // whose onChange signature is (event, value).
                            // The filter is looking for an object
                            // with { event, name, value }
                            handleSelectValue({ event: arg1, name: 'textInput', value: arg2 });
                        } else {
                            handleSelectValue(arg1);
                        }
                    },
                    value: filterValue
                })}
            </Div>
        );
    }
}

export const FilterUI = compose(withStyleSheet(styleSheet))(Filter);
