import { PERMISSIONS } from 'consts';

export const AIERA_INTERVENTION_START_TIME = '8:00 AM';
export const AIERA_INTERVENTION_END_TIME = '5:30 PM';

export const CONNECTION_TYPES = {
    audioStream: {
        label: 'Audio Stream URL',
        value: 'audio_stream',
        description: 'Transcribe audio stream from a url',
        permission: PERMISSIONS.customEventsAudioStreamUrl
    },
    audioUpload: {
        label: 'MP3 File Upload',
        value: 'audio_upload',
        description: 'Upload an audio file for Aiera to transcribe'
    },
    citrix: {
        label: 'Citrix',
        value: 'citrix',
        description: 'Connect to a Citrix dial-in number'
    },
    googleMeet: {
        label: 'Google Meet',
        value: 'google_meet',
        description: 'Connect to a Google Meet dial-in number'
    },
    microsoftTeams: {
        label: 'Microsoft Teams',
        value: 'microsoft_teams',
        description: 'Connect to a Microsoft Teams dial-in number'
    },
    phoneNumber: {
        label: 'Phone number (other)',
        value: 'phone',
        description: 'Connect to a plain phone number, including an optional pin'
    },
    skype: {
        label: 'Skype',
        value: 'skype',
        description: 'Connect to a Skype dial-in number'
    },
    webcast: {
        label: 'Webcast',
        value: 'webcast',
        description: 'Connect to a webcast url'
    },
    webex: {
        label: 'Webex',
        value: 'webex',
        description: 'Connect to a Webex dial-in number'
    },
    zoom: {
        label: 'Zoom',
        value: 'zoom',
        description: 'Connect to a Zoom dial-in number or web url'
    }
};
export const PARTICIPATION_TYPES = {
    notParticipating: {
        label: 'Set it & forget it',
        value: 'not_participating',
        description:
            "We'll automatically connect, then transcribe and record the call for you. You can join later if you " +
            'change your mind.'
    },
    participating: {
        label: 'Call me',
        value: 'participating',
        description:
            "We'll call you, and then connect you to the call. Please enter any required pins, or speak to an " +
            'operator, if needed. The call will continue to record & transcribe even after you disconnect. You may ' +
            'end the recording manually from the transcript in Aiera.'
    }
};
export const SCHEDULE_TYPES = {
    future: {
        label: 'In the future',
        value: 'future',
        description: 'Schedule a time for Aiera to attempt connecting to the event'
    },
    now: {
        label: 'Now',
        value: 'now',
        description: 'Aiera will attempt connection when you click "Create Event"'
    }
};
export const TROUBLESHOOTING_TYPES = {
    aieraIntervention: {
        label: 'Attempt Aiera intervention',
        value: 'aiera_intervention',
        description:
            'Give permission to the Aiera team to intervene. Only available Monday - Friday, ' +
            `${AIERA_INTERVENTION_START_TIME} - ${AIERA_INTERVENTION_END_TIME} EST`
    },
    call: {
        label: 'Call me',
        value: 'call',
        description: "We'll call you and then connect you to the number you provided"
    },
    none: {
        label: 'Do nothing',
        value: 'none',
        description: 'Let the event transcription fail silently'
    },
    sms: {
        label: 'Alert me by SMS',
        value: 'sms',
        description: "We'll alert you by SMS if we can't connect to your event"
    }
};
export const ZOOM_MEETING_TYPES = {
    web: {
        label: 'Web URL',
        value: 'web',
        description: 'Connect to Zoom meeting via web url (DISABLED)',
        disabled: true
    },
    phone: {
        label: 'Dial-in number',
        value: 'phone',
        description: 'Connect to Zoom meeting via dial-in number'
    }
};

export const CONNECT_OFFSET_SECONDS_OPTIONS = [
    { label: 'When the call starts', value: 0 },
    { label: '1 minute before', value: -60 },
    { label: '2 minutes before', value: -120 },
    { label: '3 minutes before', value: -180 },
    { label: '4 minutes before', value: -240 },
    { label: '5 minutes before', value: -300 }
];
export const CONNECTION_OPTIONS = [
    CONNECTION_TYPES.zoom,
    CONNECTION_TYPES.googleMeet,
    CONNECTION_TYPES.webcast,
    CONNECTION_TYPES.audioStream,
    CONNECTION_TYPES.phoneNumber,
    CONNECTION_TYPES.audioUpload
];
export const PARTICIPATION_OPTIONS = [PARTICIPATION_TYPES.notParticipating, PARTICIPATION_TYPES.participating];
export const SCHEDULE_OPTIONS = [SCHEDULE_TYPES.now, SCHEDULE_TYPES.future];
export const TROUBLESHOOTING_OPTIONS = [
    TROUBLESHOOTING_TYPES.sms,
    TROUBLESHOOTING_TYPES.call,
    TROUBLESHOOTING_TYPES.none
];
export const TROUBLESHOOTING_OPTIONS_WEBCAST = [TROUBLESHOOTING_TYPES.aieraIntervention, TROUBLESHOOTING_TYPES.none];
export const ZOOM_MEETING_TYPE_OPTIONS = [ZOOM_MEETING_TYPES.web, ZOOM_MEETING_TYPES.phone];

// Map option values to input names for the create/update mutations
export const OPTION_INPUT_NAMES_MAP = {
    [CONNECTION_TYPES.audioStream.value]: ['externalAudioStreamUrl'],
    [CONNECTION_TYPES.googleMeet.value]: ['connectPhoneNumber', 'connectPin'],
    [CONNECTION_TYPES.phoneNumber.value]: ['connectPhoneNumber', 'connectAccessId', 'connectPin'],
    [CONNECTION_TYPES.webcast.value]: ['connectUrl'],
    [CONNECTION_TYPES.zoom.value]: ['connectPhoneNumber', 'connectAccessId', 'connectPin', 'connectUrl'],
    [PARTICIPATION_TYPES.participating.value]: ['onConnectDialNumber', 'smsAlertBeforeCall']
};
