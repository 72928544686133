import React, { PureComponent } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { PREFERENCES, PERMISSIONS } from 'consts';
import { withUrlContext } from 'hoc/url';
import { withUserPreferences } from 'graphql/user';
import { generateModalId, get, getPreference, hasPermission } from 'utils';
import { withData } from './data';
import { SidebarUI } from './ui';

const UPGRADE_PERMISSIONS = {
    event: PERMISSIONS.unlockedCreatePrivateRecording,
    dashboard: PERMISSIONS.unlockedCreateDashboard,
    stream: PERMISSIONS.unlockedCreateStream
};

export class Sidebar extends PureComponent {
    static displayName = 'SidebarContainer';

    static propTypes = {
        addDashboardToSection: PropTypes.func.isRequired,
        dashboardSections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        defaultDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        recommendedDashboards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
        removeDashboardFromSection: PropTypes.func.isRequired,
        saveUXPreference: PropTypes.func.isRequired,
        setDashboardSectionPosition: PropTypes.func.isRequired,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        preferences: {},
        user: undefined
    };

    static getDerivedStateFromProps(props, state) {
        const isClosedInProps = get(props, 'isClosed');
        const isClosed = get(state, 'isClosed', isClosedInProps);
        return {
            isClosed
        };
    }

    constructor(props) {
        super(props);

        const dashboardSections = get(props, 'dashboardSections', []);
        const preferences = get(props, 'preferences');

        this.onNavigateToDashboard = this.onNavigateToDashboard.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.saveUserPreference = this.saveUserPreference.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.toggleSection = this.toggleSection.bind(this);
        this.showNewEventModal = this.showNewEventModal.bind(this);
        this.onDragSection = this.onDragSection.bind(this);
        this.onDragDashboard = this.onDragDashboard.bind(this);

        this.state = {
            isClosed: undefined,
            dashboardSections,
            collapsedSections: getPreference(preferences, PREFERENCES.sidebarCollapsedSections, {}).sectionIds || []
        };
    }

    componentDidUpdate(prevProps) {
        const prevDashboardSections = get(prevProps, 'dashboardSections', []);
        const { dashboardSections } = this.props;

        if (dashboardSections !== prevDashboardSections) {
            this.setState({
                dashboardSections
            });
        }
    }

    onDragSection(result) {
        const { setDashboardSectionPosition } = this.props;
        this.setState(
            ({ dashboardSections }) => {
                const newSections = cloneDeep(dashboardSections);
                const source = get(result, 'source');
                const destination = get(result, 'destination');
                if (!!destination && !!source) {
                    newSections.splice(source.index, 1);
                    newSections.splice(destination.index, 0, dashboardSections[source.index]);
                }

                return {
                    dashboardSections: newSections
                };
            },
            () => {
                const position = get(result, 'destination.index');
                const sectionId = get(result, 'draggableId');
                if (!!sectionId && typeof position === 'number') setDashboardSectionPosition(sectionId, position);
            }
        );
    }

    onDragDashboard(result) {
        const { addDashboardToSection, removeDashboardFromSection, recommendedDashboards } = this.props;
        const sourceMap = {
            recommended: recommendedDashboards
        };
        const source = get(result, 'source');
        const destination = get(result, 'destination');
        const sourceDroppableId = get(source, 'droppableId');
        const destinationDroppableId = get(destination, 'droppableId');

        // Must come from some section (static or dynamic)
        if (sourceDroppableId) {
            this.setState(
                ({ dashboardSections }) => {
                    const newSections = cloneDeep(dashboardSections);
                    const sourceIndex = dashboardSections.findIndex(
                        section => get(section, 'sectionId') === sourceDroppableId
                    );
                    const destinationIndex = dashboardSections.findIndex(
                        section => get(section, 'sectionId') === destinationDroppableId
                    );
                    let staticSectionDashboards = [];

                    if (sourceMap[sourceDroppableId] && sourceMap[sourceDroppableId].length > 0) {
                        staticSectionDashboards = sourceMap[sourceDroppableId];
                    }

                    // Has destination section
                    if (destinationDroppableId) {
                        // Moving from one section to another
                        if (sourceIndex !== destinationIndex) {
                            if (dashboardSections[sourceIndex] && dashboardSections[destinationIndex]) {
                                // Are a part of the dynamic dashboard sections
                                const sourceDashboards = Array.from(dashboardSections[sourceIndex].dashboards);
                                const destinationDashboards = Array.from(
                                    dashboardSections[destinationIndex].dashboards
                                );
                                destinationDashboards.splice(destination.index, 0, sourceDashboards[source.index]);
                                sourceDashboards.splice(source.index, 1);
                                newSections[sourceIndex].dashboards = sourceDashboards;
                                newSections[destinationIndex].dashboards = destinationDashboards;
                            } else if (!dashboardSections[sourceIndex]) {
                                // Moving dashboard from a static-section to dynamic section
                                const destinationDashboards = Array.from(
                                    dashboardSections[destinationIndex].dashboards
                                );
                                destinationDashboards.splice(
                                    destination.index,
                                    0,
                                    staticSectionDashboards[source.index]
                                );
                                newSections[destinationIndex].dashboards = destinationDashboards;
                            }
                        } else if (dashboardSections[sourceIndex]) {
                            // Moving dashboards in same section
                            const sourceDashboards = Array.from(dashboardSections[sourceIndex].dashboards);
                            sourceDashboards.splice(source.index, 1);
                            sourceDashboards.splice(
                                destination.index,
                                0,
                                dashboardSections[sourceIndex].dashboards[source.index]
                            );
                            newSections[sourceIndex].dashboards = sourceDashboards;
                        }
                    } else if (!destinationDroppableId && sourceIndex >= 0) {
                        // Has no destination section
                        // we will remove from section
                        const sourceDashboards = Array.from(dashboardSections[sourceIndex].dashboards);
                        sourceDashboards.splice(source.index, 1);
                        newSections[sourceIndex].dashboards = sourceDashboards;
                    }

                    return {
                        dashboardSections: newSections
                    };
                },
                () => {
                    const sectionId = destinationDroppableId;
                    const position = get(result, 'destination.index');
                    let dashboardId = get(result, 'draggableId');

                    // We prefix the dashboards
                    // with their sectionId as they can be duplicated
                    // and need unique draggable IDs
                    if (dashboardId.includes(':')) {
                        [, dashboardId] = dashboardId.split(':');
                    }

                    if (sectionId && !['recommended'].includes(sectionId)) {
                        addDashboardToSection(sectionId, position, dashboardId);
                    } else if (sourceDroppableId && !['recommended'].includes(sourceDroppableId)) {
                        removeDashboardFromSection(sourceDroppableId, dashboardId);
                    }
                }
            );
        }
    }

    toggleSection(sectionId) {
        if (sectionId) {
            const { saveUXPreference } = this.props;
            const { collapsedSections } = this.state;
            const newCollapsedSections = new Set(collapsedSections);
            if (newCollapsedSections.has(sectionId)) {
                newCollapsedSections.delete(sectionId);
            } else {
                newCollapsedSections.add(sectionId);
            }

            this.setState(
                {
                    collapsedSections: [...newCollapsedSections]
                },
                () => {
                    saveUXPreference({
                        name: PREFERENCES.sidebarCollapsedSections.name,
                        data: { sectionIds: [...newCollapsedSections] },
                        hideSuccessBanner: true
                    });
                }
            );
        }
    }

    toggleSidebar() {
        const { isClosed: isClosedInState } = this.state;
        const isClosed = !isClosedInState;
        this.setState(
            {
                isClosed
            },
            () => this.saveUserPreference(isClosed)
        );
    }

    saveUserPreference(isClosed) {
        const { preferences, saveUXPreference } = this.props;
        return saveUXPreference({
            name: PREFERENCES.sidebar.name,
            data: { ...getPreference(preferences, PREFERENCES.sidebar, {}), isClosed },
            hideSuccessBanner: true
        });
    }

    showNewEventModal() {
        const { history, location, user } = this.props;
        if (hasPermission(user, PERMISSIONS.unlockedCreatePrivateRecording)) {
            history.push(generateModalId({ location, id: 'new', type: 'privateRecording' }));
        } else {
            history.push(generateModalId({ location, id: 'createEvent', type: 'upgrade' }));
        }
    }

    onNavigateToDashboard(route) {
        const { history } = this.props;
        history.push(route);
    }

    openGallery() {
        const { history, location } = this.props;
        history.push(generateModalId({ location, id: 'new', type: 'dashboardGallery' }));
    }

    openModal({ value }) {
        const { history, location, user } = this.props;
        const permission = get(UPGRADE_PERMISSIONS, value);
        if (!permission || hasPermission(user, permission)) {
            if (value === 'dashboard') {
                history.push(generateModalId({ location, id: 'new', type: 'dashboard' }));
            }
        } else {
            history.push(generateModalId({ location, id: value, type: 'upgrade' }));
        }
    }

    render() {
        const { collapsedSections, dashboardSections, isClosed } = this.state;
        const { defaultDashboards, recommendedDashboards } = this.props;
        return (
            <SidebarUI
                collapsedSections={collapsedSections}
                dashboardSections={dashboardSections}
                defaultDashboards={defaultDashboards}
                isClosed={isClosed}
                onDragDashboard={this.onDragDashboard}
                onDragSection={this.onDragSection}
                onNavigateToDashboard={this.onNavigateToDashboard}
                openGallery={this.openGallery}
                openModal={this.openModal}
                recommendedDashboards={recommendedDashboards}
                showNewEventModal={this.showNewEventModal}
                toggleSection={this.toggleSection}
                toggleSidebar={this.toggleSidebar}
            />
        );
    }
}

export const SidebarContainer = compose(
    withData(),
    withUserPreferences({
        fetchPolicy: 'cache-first',
        alias: 'withUserPreferencesSidebar'
    }),
    withUrlContext(['history', 'location']),
    withPropsOnChange(['preferences'], ({ preferences }) => ({
        isClosed: get(getPreference(preferences, PREFERENCES.sidebar, {}), 'isClosed')
    }))
)(Sidebar);
