import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { sortableHandle } from 'react-sortable-hoc';
import { compose } from 'recompose';
import { SortHandleUI } from './ui';

export class SortHandle extends PureComponent {
    static displayName = 'SortHandleContainer';

    static propTypes = {
        color: PropTypes.string
    };

    static defaultProps = {
        color: undefined
    };

    render() {
        const { color } = this.props;
        return <SortHandleUI color={color} />;
    }
}

export const SortHandleContainer = compose(sortableHandle)(SortHandle);
