import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { mapFiltersToRules } from 'utils/streams';
import { graphql } from 'graphql/utils';
import {
    ruleFragment,
    streamFragment,
    dashboardFragment,
    transcriptMatchFragment,
    transcriptMatchNoCollapsedFragment
} from 'graphql/fragments/streams';
import { TYPES, OPERATORS } from 'consts/filters';

function formatLocalStreamsInput({ searchTerm, equityId }) {
    const input = {
        name: searchTerm,
        streamType: 'content',
        pinToEquityId: equityId,
        rules: mapFiltersToRules([
            {
                type: TYPES.searchTerm,
                operator: OPERATORS.is,
                value: [searchTerm]
            },
            {
                type: TYPES.type,
                operator: OPERATORS.is,
                value: 'transcript'
            }
        ])
    };

    return input;
}

export const eventStreamMatchesQuery = gql`
    query withEventStreamMatches(
        $eventId: ID
        $eventIdScalar: GenericScalar
        $shareId: ID
        $userStreams: Boolean = true
        $quicklinkStreams: Boolean = true
        $topicStreams: Boolean = true
    ) {
        events(eventIds: [$eventId], shareId: $shareId) {
            id
            streams(
                filter: { userStreams: $userStreams, quicklinkStreams: $quicklinkStreams, topicStreams: $topicStreams }
            ) {
                ...stream
                dashboards {
                    ...dashboard
                }
                matches(
                    highlightSize: 0
                    filter: { rules: [{ ruleType: event_id, condition: is_equal, value: $eventIdScalar }] }
                    sort: "date asc"
                    collapse: false
                ) {
                    averageSentimentScoreMovementPercent
                    results {
                        ...transcriptMatch
                    }
                }
            }
        }
    }
    ${dashboardFragment}
    ${streamFragment}
    ${transcriptMatchNoCollapsedFragment}
`;

export const eventStreamDifferentialsQuery = gql`
    query withEventStreamDifferentials($eventId: ID, $eventIdScalar: GenericScalar, $shareId: ID) {
        events(eventIds: [$eventId], shareId: $shareId) {
            id
            differentials {
                streams {
                    stream {
                        ...stream
                        dashboards {
                            ...dashboard
                        }
                        matches(
                            filter: { rules: [{ ruleType: event_id, condition: is_equal, value: $eventIdScalar }] }
                        ) {
                            results {
                                ...transcriptMatch
                            }
                        }
                    }
                    events {
                        numMatches
                        event {
                            id
                            scheduledAudioCallId
                            title
                            callDate
                        }
                    }
                }
            }
        }
    }
    ${dashboardFragment}
    ${streamFragment}
    ${transcriptMatchFragment}
`;

export const withLocalStreams = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CreateStream($input: StreamInput!) {
                    createStream(input: $input) {
                        success
                        stream {
                            ...stream
                            rules {
                                ...rule
                            }
                        }
                    }
                }
                ${streamFragment}
                ${ruleFragment}
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, eventId } = ownProps;
                    return {
                        createStream: input =>
                            mutate({
                                variables: { input: formatLocalStreamsInput(input) },
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        variables: {
                                            eventId,
                                            eventIdScalar: eventId,
                                            quicklinkStreams: true,
                                            userStreams: true
                                        },
                                        query: eventStreamMatchesQuery
                                    },
                                    {
                                        variables: { eventId, eventIdScalar: eventId },
                                        query: eventStreamDifferentialsQuery
                                    }
                                ]
                            })
                                .then(() => {
                                    setStatusBanner('Local monitor created successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error creating local monitor: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        ),
        graphql(
            gql`
                mutation DeleteStream($streamId: ID!) {
                    deleteStream(streamId: $streamId) {
                        success
                    }
                }
            `,
            {
                props: props => {
                    const { mutate, ownProps } = props;
                    const { setStatusBanner, eventId } = ownProps;
                    return {
                        deleteStream: streamId =>
                            mutate({
                                variables: { streamId },
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        variables: {
                                            eventId,
                                            eventIdScalar: eventId,
                                            quicklinkStreams: true,
                                            userStreams: true
                                        },
                                        query: eventStreamMatchesQuery
                                    },
                                    {
                                        variables: { eventId, eventIdScalar: eventId },
                                        query: eventStreamDifferentialsQuery
                                    }
                                ]
                            })
                                .then(() => {
                                    setStatusBanner('Local monitor deleted successfully!');
                                })
                                .catch(error => {
                                    setStatusBanner(`Error deleting local monitor: ${error}`, 'error', 'circleX');
                                })
                    };
                }
            }
        )
    );
