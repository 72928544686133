import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import { compose } from 'recompose';
import { CAPABILITIES, PREFERENCES, STREAM_DISPLAY_MODES } from 'consts';
import { withUrlContext } from 'hoc/url';
import { arraySwap, generateModalId, get, getPreference } from 'utils';
import { withData } from './data';
import { StreamsUI } from './ui';

function sortStreams(streams, streamSortOrder) {
    return sortBy(streams, ({ id }) => streamSortOrder.indexOf(id));
}

export class Streams extends PureComponent {
    static displayName = 'StreamsContainer';

    static propTypes = {
        allowNewStreams: PropTypes.bool,
        capabilities: PropTypes.arrayOf(PropTypes.string),
        dashboardId: PropTypes.string.isRequired,
        dashboardGuid: PropTypes.string,
        dashboardType: PropTypes.string,
        dashDateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])),
        dashFilters: PropTypes.arrayOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        dashSources: PropTypes.objectOf(PropTypes.any),
        dashEquityScope: PropTypes.arrayOf(PropTypes.any),
        deleteStream: PropTypes.func.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isCreator: PropTypes.bool.isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        onSort: PropTypes.func,
        preferences: PropTypes.objectOf(PropTypes.any),
        showNewStreamModal: PropTypes.func,
        streams: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                name: PropTypes.string.isRequired,
                streamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                streamType: PropTypes.string.isRequired,
                uxPreferences: PropTypes.shape({
                    color: PropTypes.string
                })
            })
        ),
        styles: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        allowNewStreams: true,
        capabilities: [],
        dashboardGuid: null,
        dashboardType: 'default',
        dashDateRange: null,
        dashFilters: null,
        dashSearchTerm: null,
        dashSources: null,
        dashEquityScope: null,
        onSort: null,
        preferences: {},
        showNewStreamModal: undefined,
        streams: [],
        styles: undefined
    };

    constructor(props) {
        super(props);

        this.checkPaging = this.checkPaging.bind(this);
        this.handleContainerRef = this.handleContainerRef.bind(this);
        this.handleDeleteStream = this.handleDeleteStream.bind(this);
        this.handleEditStream = this.handleEditStream.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onResizeStart = this.onResizeStart.bind(this);
        this.onResizeEnd = this.onResizeEnd.bind(this);
        this.pageLeft = this.pageLeft.bind(this);
        this.pageRight = this.pageRight.bind(this);

        this.sortStreams = memoize(sortStreams);

        this.state = {
            dragging: false,
            resizing: false,
            editingStream: null,
            sorting: false,
            streamSortOrder: get(props, 'streams', []).map(({ id }) => id),
            canPageLeft: false,
            canPageRight: false
        };
    }

    componentDidUpdate({ dashboardId: prevDashboardId, streams: prevStreams }) {
        const { dashboardId, streams } = this.props;
        // The second condition here is to set it back to 0 on initial load since scroll-snap seems
        // to cause the browser to scroll to the end when the content first renders
        if (dashboardId !== prevDashboardId || (!get(prevStreams, 'length') && get(streams, 'length'))) {
            if (this.containerRef) {
                this.containerRef.scrollLeft = 0;
                this.checkPaging();
            }
        }

        if (prevStreams !== streams) {
            this.setState({
                streamSortOrder: streams.map(s => s.id)
            });
        }
    }

    checkPaging() {
        if (this.containerRef) {
            const pageWidth = this.containerRef.offsetWidth;
            const scrollableWidth = this.containerRef.scrollWidth;
            const currentPosition = this.containerRef.scrollLeft;
            let canPageLeft = false;
            let canPageRight = false;
            if (currentPosition > 0) {
                canPageLeft = true;
            }
            if (scrollableWidth - (currentPosition + pageWidth) > 0) {
                canPageRight = true;
            }
            if (canPageRight || canPageLeft) {
                this.setState({
                    canPageLeft,
                    canPageRight
                });
            }
        }
    }

    handleContainerRef(node) {
        if (node) {
            this.containerRef = node;
            this.containerRef.addEventListener('scroll', debounce(this.checkPaging, 50, { trailing: true }));
        }
    }

    handleDeleteStream(streamId, confirm = true) {
        const { deleteStream } = this.props;
        let confirmDelete;
        if (confirm) {
            confirmDelete = window.confirm('Are you sure you want to delete this saved search?');
        } else {
            confirmDelete = true;
        }
        if (confirmDelete) deleteStream(streamId);
    }

    handleEditStream(streamId) {
        const { history, location } = this.props;
        history.push(generateModalId({ location, id: streamId, type: 'stream' }));
    }

    onDragStart() {
        this.setState({ dragging: true });
    }

    onSort(result) {
        const { onSort } = this.props;
        const { streamSortOrder } = this.state;
        const newIndex = get(result, 'destination.index');
        const oldIndex = get(result, 'source.index');
        if (onSort) {
            const newStreamSortOrder = arraySwap(streamSortOrder, oldIndex, newIndex);
            // Setting sorting to true to remove the transtion class when we finish sorting
            // and then immediately set it back. Kind of hack, but react-sortable seems
            // to be using `transorm` which we also use and we only want to animate it
            // for zooming, not for sorting.
            this.setState({ streamSortOrder: newStreamSortOrder, dragging: false, sorting: true }, () => {
                setTimeout(() => this.setState({ sorting: false }));
            });
            onSort(newStreamSortOrder);
        } else {
            this.setState({ dragging: false });
        }
    }

    onResizeStart() {
        this.setState({ resizing: true });
    }

    onResizeEnd() {
        this.setState({ resizing: false });
    }

    pageLeft() {
        if (this.containerRef) {
            const pageWidth = this.containerRef.offsetWidth;
            const currentPosition = this.containerRef.scrollLeft;
            if (currentPosition - pageWidth > 0) {
                this.containerRef.scrollTo({ left: currentPosition - pageWidth, behavior: 'smooth' });
            } else {
                this.containerRef.scrollTo({ left: 0, behavior: 'smooth' });
            }
        }
    }

    pageRight() {
        if (this.containerRef) {
            const pageWidth = this.containerRef.offsetWidth;
            const scrollableWidth = this.containerRef.scrollWidth;
            const currentPosition = this.containerRef.scrollLeft;
            if (currentPosition + pageWidth < scrollableWidth) {
                this.containerRef.scrollTo({ left: currentPosition + pageWidth, behavior: 'smooth' });
            } else {
                this.containerRef.scrollTo({ left: scrollableWidth, behavior: 'smooth' });
            }
        }
    }

    render() {
        const {
            allowNewStreams,
            capabilities,
            dashDateRange,
            dashFilters,
            dashSearchTerm,
            dashSources,
            dashEquityScope,
            dashboardId,
            dashboardGuid,
            dashboardType,
            isCreator,
            preferences,
            showNewStreamModal,
            streams,
            styles
        } = this.props;
        const { canPageRight, canPageLeft, dragging, editingStream, sorting, streamSortOrder, resizing } = this.state;
        return (
            <StreamsUI
                allowNewStreams={allowNewStreams && capabilities.includes(CAPABILITIES.addStream)}
                canPageLeft={canPageLeft}
                canPageRight={canPageRight}
                dashboardId={dashboardId}
                dashboardGuid={dashboardGuid}
                dashboardType={dashboardType}
                dashDateRange={dashDateRange}
                dashFilters={dashFilters}
                dashSearchTerm={dashSearchTerm}
                dashSources={dashSources}
                dashEquityScope={dashEquityScope}
                dragging={dragging}
                editingStream={editingStream}
                isCreator={isCreator}
                handleContainerRef={this.handleContainerRef}
                onDeleteStream={this.handleDeleteStream}
                onDragStart={this.onDragStart}
                onEditStream={this.handleEditStream}
                onResizeStart={this.onResizeStart}
                onResizeEnd={this.onResizeEnd}
                onSort={this.onSort}
                pageLeft={this.pageLeft}
                pageRight={this.pageRight}
                resizing={resizing}
                showNewStreamModal={showNewStreamModal}
                sorting={sorting}
                streamDisplayMode={getPreference(
                    preferences,
                    PREFERENCES.streamDisplayMode,
                    STREAM_DISPLAY_MODES.default
                )}
                streams={this.sortStreams(streams, streamSortOrder)}
                styles={styles}
            />
        );
    }
}

export const StreamsContainer = compose(withUrlContext(['history', 'location']), withData())(Streams);
