import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Tooltip } from 'components/Tooltip';
import { TooltipForm } from 'components/TooltipForm';

import { styleSheet } from './stylesheet';

class CloneDashboardTooltip extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onClone: PropTypes.func.isRequired,
        value: PropTypes.string,
        tooltipOptions: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        value: undefined
    };

    render() {
        const { styles, theme, loading, onChange, onClone, value, children, tooltipOptions } = this.props;
        const dashboardCloneForm = (
            <TooltipForm
                buttonDisabled={!value.length}
                inputName="cloneName"
                loading={loading}
                onChange={onChange}
                onSubmit={onClone}
                placeholder="Enter new monitor name"
                submitButtonText="Clone Monitor"
                value={value}
            />
        );
        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m =>
                    !m && (
                        <Tooltip
                            styles={styles.tooltipClone}
                            isEnabled
                            persistOnMouseExit
                            useElementOffsetLeft
                            useElementOffsetTop
                            useOutsideClickHandler
                            xOffset={-1}
                            yOffset={-61}
                            content={dashboardCloneForm}
                            {...tooltipOptions}
                        >
                            {children}
                        </Tooltip>
                    )
                }
            </MediaQuery>
        );
    }
}

export const CloneDashboardTooltipUI = compose(withStyleSheet(styleSheet))(CloneDashboardTooltip);
