import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Td } from 'components/Td';
import { Text } from 'components/Text';
import { Div } from 'components/Div';

import { styleSheet } from './stylesheet';

class BoxTd extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        centered: PropTypes.bool,
        alignLeft: PropTypes.bool,
        alignRight: PropTypes.bool,
        leftEdge: PropTypes.bool,
        rightEdge: PropTypes.bool
    };

    static defaultProps = {
        passedStyles: {},
        centered: false,
        alignLeft: false,
        alignRight: false,
        leftEdge: false,
        rightEdge: false
    };

    render() {
        const {
            styles,
            passedStyles,
            children,
            centered,
            alignLeft,
            alignRight,
            leftEdge,
            rightEdge,
            ...rest
        } = this.props;
        const centeredStyles = centered ? styles.centered : {};
        const leftStyles = alignLeft ? styles.alignLeft : {};
        const rightStyles = alignRight ? styles.alignRight : {};
        return (
            <Td
                styles={{ ...styles.container, ...centeredStyles, ...leftStyles, ...rightStyles, ...passedStyles }}
                {...rest}
            >
                {leftEdge && (
                    <Div styles={styles.leftEdge}>
                        <Div styles={styles.leftCurves} className="curves" />
                    </Div>
                )}
                {typeof children === 'string' ? <Text>{children}</Text> : children}
                {rightEdge && (
                    <Div styles={styles.rightEdge}>
                        <Div styles={styles.rightCurves} className="curves" />
                    </Div>
                )}
            </Td>
        );
    }
}

export const BoxTdUI = compose(withStyleSheet(styleSheet))(BoxTd);
