export const styleSheet = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: 6.5,
        marginRight: 6,
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        backgroundColor: theme.colors.gray11,
        flexShrink: 0,
        svg: {
            height: 14,
            width: 14
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.white01
            }
        },
        ':active': {
            backgroundColor: theme.colors.gray13
        }
    },
    stream: {
        borderRadius: 6,
        height: 500,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '.streamHitContainer': {
            borderRadius: 'unset',
            padding: 0
        },
        '.streamScrollTop': {
            display: 'none'
        }
    },
    unreadCount: {
        alignItems: 'center',
        backgroundColor: theme.colors.red08,
        borderRadius: 6,
        boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        left: 15,
        minWidth: 15,
        padding: '2px 4px',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        p: {
            color: theme.colors.white01,
            fontSize: theme.fonts.fontSizes.size00
        }
    },
    notificationList: {
        ...theme.content.hoverMenu,
        background: theme.colors.gray03,
        borderRadius: 6,
        overflow: 'hidden',
        'a.manageAlerts': {
            display: 'flex',
            color: theme.colors.gray06,
            textDecoration: 'none',
            padding: 10,
            zIndex: 1,
            position: 'relative',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': {
                textDecoration: 'underline'
            }
        }
    }
});
