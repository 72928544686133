export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexShrink: 0
    },
    iconContainer: {
        width: '30px',
        display: 'flex',
        flexShrink: 0
    },
    textContent: {
        color: theme.colors.text,
        lineHeight: '1.3em'
    },
    heading: {
        lineHeight: '16px',
        paddingBottom: '8px'
    },
    info: {
        backgroundColor: '#f5fdff',
        border: '2px solid #d7edf7',
        borderRadius: '4px',
        padding: '18px'
    }
});
