export const styleSheet = theme => ({
    container: {
        cursor: 'pointer',
        backgroundColor: theme.colors.white01,
        marginBottom: 1,
        padding: `${theme.margins.margin00} 0`,
        ':hover': {
            backgroundColor: theme.colors.blue09,
            '.toggleOff': {
                backgroundColor: theme.colors.blue08
            }
        }
    },
    mainContent: {
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.margins.margin00}`
    },
    row: {
        display: 'flex'
    },
    left: {
        flex: 1,
        paddingRight: 4
    },
    dateTime: {
        display: 'flex',
        marginTop: 4,
        color: theme.colors.gray06
    },
    cardHeader: {
        paddingBottom: 3
    },
    liveStatus: {
        display: 'flex',
        letterSpacing: 1,
        color: theme.colors.orange01,
        marginTop: 4
    },
    title: {
        lineHeight: '1.3em',
        marginRight: 10
    },
    liveDot: {
        height: '7px',
        width: '7px',
        borderRadius: '3.5px',
        margin: '1px 5px 0 0',
        backgroundColor: theme.colors.orange01
    },
    spacer: {
        flex: 1
    },
    priceReaction: {
        backgroundColor: theme.colors.gray10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 10px',
        borderRadius: 6,
        margin: '5px 5px -5px',
        svg: {
            marginLeft: 2
        }
    },
    reactionLabel: {
        opacity: 0.5,
        letterSpacing: 1
    }
});
