import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { get } from 'utils';
import { styleSheet } from './stylesheet';

const FONT_SIZE_MAP = {
    1: 1,
    2: 3
};

const HEIGHT_MAP = {
    1: 30,
    2: 40
};

const ICON_MAP = {
    1: 'chevron02',
    2: 'chevron'
};

class Dropdown extends PureComponent {
    static propTypes = {
        cancelClassName: PropTypes.string,
        disableHoverOpen: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        handleDropdownRef: PropTypes.objectOf(PropTypes.any).isRequired,
        handleOptionNode: PropTypes.func.isRequired,
        hideOnScroll: PropTypes.bool,
        highlightedIndex: PropTypes.number.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onTooltipHide: PropTypes.func.isRequired,
        onTooltipShow: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        OptionComponent: PropTypes.elementType,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        placeholder: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(PropTypes.any),
        size: PropTypes.number,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        hideOnScroll: undefined,
        cancelClassName: undefined,
        label: null,
        name: undefined,
        passedStyles: {},
        selectedOptions: null,
        OptionComponent: null,
        placeholder: null,
        size: 2
    };

    renderDropDownMenu() {
        const {
            styles,
            theme,
            options,
            selectedOptions,
            onSelect,
            onHighlight,
            highlightedIndex,
            OptionComponent,
            placeholder,
            handleOptionNode,
            size
        } = this.props;
        const fontSize = FONT_SIZE_MAP[size];
        const height = HEIGHT_MAP[size];
        const iconType = ICON_MAP[size];

        return ({ hideTooltip }) => {
            const placeholderElement = (
                <Div
                    className="dropdown-placeholder"
                    styles={
                        highlightedIndex === 0 ? { ...styles.optionHighlighted, height } : { ...styles.option, height }
                    }
                    onMouseEnter={() => onHighlight(0)}
                    onMouseDown={e => {
                        onSelect(e, null);
                        hideTooltip(e);
                    }}
                >
                    <Text size={fontSize}>{placeholder}</Text>
                    <Div styles={styles.spacer} />
                    <Div styles={styles.chevron}>
                        <Icon type={iconType} color={theme.colors.black01} />
                    </Div>
                </Div>
            );

            const menuOptions = options.map((option, idx) => {
                const { disabled, label, icon, value } = option;
                const isSelected = selectedOptions.find(o => get(o, 'value', null) === value);
                const optionIdx = idx + 1;
                const optionStyles = optionIdx === highlightedIndex ? styles.optionHighlighted : styles.option;
                return OptionComponent ? (
                    <OptionComponent
                        key={`dd-${value}`}
                        className={`dropdown-option${disabled ? '--disabled' : ''}`}
                        isSelected={isSelected}
                        onMouseEnter={() => (disabled ? {} : onHighlight(optionIdx))}
                        onMouseDown={e => (disabled ? {} : onSelect(e, value))}
                        ref={node => handleOptionNode(node, idx)}
                        styles={optionStyles}
                        option={option}
                    />
                ) : (
                    <Div
                        key={`dd-${value}`}
                        className={`dropdown-option${disabled ? '--disabled' : ''}`}
                        styles={{ ...optionStyles, height }}
                        onMouseEnter={() => (disabled ? {} : onHighlight(optionIdx))}
                        onMouseDown={e => (disabled ? {} : onSelect(e, value))}
                        ref={node => handleOptionNode(node, idx)}
                    >
                        {icon && <Icon type={icon} color={theme.colors.black01} />}
                        <Text size={fontSize}>{label}</Text>
                        <Div styles={styles.spacer} />
                        {isSelected && <Icon type="checkMarkSmall" color={theme.colors.black01} />}
                    </Div>
                );
            });

            return (
                <Fragment>
                    {placeholder && placeholderElement}
                    {menuOptions}
                </Fragment>
            );
        };
    }

    renderDropDown() {
        const {
            styles,
            theme,
            label,
            name,
            passedStyles,
            selectedOptions,
            placeholder,
            disabled,
            disableHoverOpen,
            handleDropdownRef,
            size
        } = this.props;
        const fontSize = FONT_SIZE_MAP[size];
        const height = HEIGHT_MAP[size];
        const iconType = ICON_MAP[size];
        let placeholderText = placeholder;
        if (selectedOptions.length) {
            placeholderText =
                selectedOptions.length > 1 ? `${selectedOptions.length} selected` : get(selectedOptions, '[0].label');
        }
        let containerStyle = selectedOptions.length ? styles.containerSelected : styles.container;
        if (disabled) {
            containerStyle = styles.containerDisabled;
        }

        return ({ showTooltip, hideTooltip }) => (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Div styles={passedStyles}>
                        {label && label.length > 0 && (
                            <Div className="dropdownLabel" style={styles.labelContainer}>
                                <Text label uppercase styles={styles.label}>
                                    {label}
                                </Text>
                            </Div>
                        )}
                        <Div
                            styles={{ ...containerStyle, height }}
                            className="dropdown"
                            onMouseEnter={!m && !disableHoverOpen ? showTooltip : undefined}
                            onBlur={!m ? hideTooltip : undefined}
                            onFocus={!m ? showTooltip : undefined}
                            tabIndex={0}
                            onClick={showTooltip}
                            ref={handleDropdownRef}
                            data-tname={`dropdown-${name}`}
                        >
                            <Text size={fontSize}>{placeholderText}</Text>
                            <Div styles={styles.chevron} className="dropdownArrow">
                                <Icon type={iconType} color={theme.colors.black01} />
                            </Div>
                        </Div>
                    </Div>
                )}
            </MediaQuery>
        );
    }

    render() {
        const { cancelClassName, styles, disabled, onTooltipShow, onTooltipHide, hideOnScroll } = this.props;
        return (
            <Tooltip
                cancelClassName={cancelClassName}
                isEnabled={!disabled}
                xOffset={0}
                yOffset={0}
                useElementOffsetLeft
                useElementOffsetTop
                useMaxHoverWidth
                useOutsideClickHandler
                onShow={onTooltipShow}
                onHide={onTooltipHide}
                styles={styles.menuContainer}
                content={this.renderDropDownMenu()}
                hideOnScroll={hideOnScroll}
            >
                {this.renderDropDown()}
            </Tooltip>
        );
    }
}

export const DropdownUI = compose(withStyleSheet(styleSheet))(Dropdown);
