import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import XDate from 'xdate';
import { compose } from 'recompose';
import { PERMISSIONS } from 'consts';
import { routes } from 'routes';
import { Div } from 'components/Div';
import { EventAlertToggle } from 'components/EventAlertToggle';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { Span } from 'components/Span';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { withStyleSheet } from 'hoc/styles';
import { get, getEventDateTime } from 'utils';
import { styleSheet } from './stylesheet';

const DATE_FORMAT = 'MMM dS, yyyy';
class EventHeader extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string,
        callType: PropTypes.string,
        company: PropTypes.objectOf(PropTypes.any),
        copyToClipboard: PropTypes.func.isRequired,
        disableCompany: PropTypes.bool.isRequired,
        disableNav: PropTypes.bool,
        downloadingWord: PropTypes.bool.isRequired,
        emailNotificationsEnabled: PropTypes.bool,
        eventId: PropTypes.string,
        focusMode: PropTypes.bool.isRequired,
        hasUnknownTime: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        inProgress: PropTypes.bool,
        isEventOwner: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        isUpcoming: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        openEditEventModal: PropTypes.func.isRequired,
        onDownloadWord: PropTypes.func.isRequired,
        onTickerClick: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        primaryQuote: PropTypes.objectOf(PropTypes.any),
        removeEvent: PropTypes.func.isRequired,
        shareId: PropTypes.string,
        showWordDownload: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        title: PropTypes.string,
        transcriptionDownloadUrl: PropTypes.string,
        urlCopied: PropTypes.bool.isRequired,
        waiting: PropTypes.bool
    };

    static defaultProps = {
        callDate: null,
        callType: null,
        company: undefined,
        disableNav: false,
        emailNotificationsEnabled: false,
        eventId: null,
        hasUnknownTime: false,
        inProgress: false,
        loading: false,
        passedStyles: {},
        primaryQuote: undefined,
        shareId: null,
        title: null,
        transcriptionDownloadUrl: null,
        waiting: false
    };

    renderCompany() {
        const {
            company,
            disableCompany,
            disableNav,
            focusMode,
            onTickerClick,
            primaryQuote,
            styles,
            theme
        } = this.props;
        const ticker = get(primaryQuote, 'localTicker', null);
        const exchange = get(primaryQuote, 'exchange.shortName', null);
        const name = get(company, 'commonName', 'No company');

        if (!ticker || disableCompany) {
            return null;
        }

        let companyStyle = styles.company;
        if (disableNav) companyStyle = styles.companyPublic;
        if (focusMode) companyStyle = styles.companyFocus;

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Hint
                        text="View Company"
                        disabled={disableNav || m}
                        styles={companyStyle}
                        onClick={onTickerClick}
                        xOffset={-105}
                        yOffset={-37}
                    >
                        {!m && (
                            <Div styles={styles.companySummary}>
                                {name && (
                                    <Text size={3} className="companyName">
                                        {name}
                                    </Text>
                                )}
                                {ticker && (
                                    <Text size={0} styles={styles.companyTicker} uppercase>
                                        {ticker}
                                        <Span styles={styles.exchange}>{exchange}</Span>
                                    </Text>
                                )}
                            </Div>
                        )}
                        {!disableNav && ticker && <Icon type="company" color={theme.colors.gray04} />}
                    </Hint>
                )}
            </MediaQuery>
        );
    }

    renderDownload() {
        const { inProgress, styles, theme, downloadingWord, onDownloadWord, transcriptionDownloadUrl } = this.props;
        return (
            <MediaQuery minWidth={theme.breakpoints.internal.tablet}>
                <Div styles={styles.downloadLink}>
                    <Div styles={styles.detailItem}>
                        <Text styles={styles.download} size={3}>
                            Download
                        </Text>
                        <Text styles={styles.textLabel} size={0} uppercase>
                            transcript
                        </Text>
                    </Div>
                    <Div styles={styles.downloadBtns}>
                        <Div styles={styles.downloadBtn} data-tname="downloadWordDoc" onClick={onDownloadWord}>
                            <Icon
                                type={downloadingWord ? 'checkMark' : 'doc'}
                                color={downloadingWord ? theme.colors.green01 : theme.colors.white01}
                            />
                        </Div>
                        {!inProgress && (
                            <a className="pdfDownloadBtn" href={transcriptionDownloadUrl}>
                                <Icon type="pdf" color={theme.colors.white01} />
                            </a>
                        )}
                    </Div>
                </Div>
            </MediaQuery>
        );
    }

    renderShare() {
        const { styles, theme, urlCopied, copyToClipboard, shareId } = this.props;

        if (!shareId) {
            return null;
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                {m => (
                    <Hint text="Copy Public Shareable Link" styles={styles.shareLink} xOffset={-180} yOffset={-37}>
                        {!m && (
                            <Div styles={styles.detailItem}>
                                <Text styles={styles.copyLink} size={3}>
                                    Share
                                </Text>
                                <Text styles={styles.textLabel} size={0} uppercase>
                                    copy link
                                </Text>
                            </Div>
                        )}
                        <Div styles={styles.share}>
                            <Icon
                                type={urlCopied ? 'checkMark' : 'share'}
                                color={urlCopied ? theme.colors.green01 : theme.colors.yellow07}
                            />
                        </Div>
                        <Div styles={styles.copyClickMask} onClick={copyToClipboard} data-tname="shareEventLinkClick" />
                    </Hint>
                )}
            </MediaQuery>
        );
    }

    renderEventMenu() {
        const { removeEvent, styles, theme, openEditEventModal } = this.props;

        const manageMenu = ({ hideTooltip }) => (
            <Div styles={styles.manageMenu}>
                <Div
                    styles={styles.manageMenuOption}
                    onClick={() => {
                        hideTooltip();
                        openEditEventModal();
                    }}
                >
                    <Icon type="pencil02" color={theme.colors.black01} />
                    <Text size={3}>Edit Details</Text>
                </Div>
                <Div styles={styles.manageMenuOption} onClick={removeEvent}>
                    <Icon type="trash02" color={theme.colors.black01} />
                    <Text size={3}>Remove</Text>
                </Div>
            </Div>
        );

        return (
            <Tooltip
                cancelClassName="modal-container"
                content={manageMenu}
                isEnabled
                useElementOffsetTop
                useElementOffsetLeft
                xOffset={-86}
                yOffset={52}
                useOutsideClickHandler
            >
                {({ showTooltip }) => (
                    <Hint
                        text="Manage Event"
                        styles={styles.eventMenuButton}
                        xOffset={-108}
                        yOffset={-37}
                        onClick={showTooltip}
                    >
                        <Icon type="menuVertical" color={theme.colors.gray04} />
                    </Hint>
                )}
            </Tooltip>
        );
    }

    render() {
        const {
            loading,
            showWordDownload,
            callDate,
            callType,
            company,
            disableNav,
            emailNotificationsEnabled,
            eventId,
            focusMode,
            hasUnknownTime,
            history,
            inProgress,
            isEventOwner,
            isUpcoming,
            isPublic,
            onTickerClick,
            passedStyles,
            primaryQuote,
            styles,
            theme,
            title,
            waiting
        } = this.props;
        const name = get(company, 'commonName');
        const ticker = get(primaryQuote, 'localTicker');
        const exchange = get(primaryQuote, 'exchange.shortName', null);
        let eventStatus;
        let pageTitle = title || 'Loading...';

        if (inProgress) {
            eventStatus = waiting ? 'waiting ' : 'live ';
        }

        if (name && callType && callDate) {
            const date = `${new XDate(callDate).toString(DATE_FORMAT)}`;
            pageTitle = title || `${callType} call - ${date}`;
        }

        if (loading && !name && !callType && !callDate) {
            return (
                <MediaQuery maxWidth={theme.breakpoints.internal.tablet}>
                    {m => (
                        <Div styles={{ ...styles.container, ...passedStyles }}>
                            {m && !disableNav && (
                                <Div styles={styles.backIcon} onClick={() => history.goBack()}>
                                    <Icon type="chevron" color={theme.colors.gray04} />
                                </Div>
                            )}
                            <Div styles={styles.loadingPlaceholders}>
                                <Div styles={styles.loadingMediumText} />
                                <Div styles={styles.columns}>
                                    <Div styles={styles.loadingSmallText} />
                                    <Div styles={styles.loadingSmallText} />
                                </Div>
                            </Div>
                        </Div>
                    )}
                </MediaQuery>
            );
        }

        return (
            <MediaQuery maxWidth={theme.breakpoints.internal.mobileEdge}>
                {m => (
                    <React.Fragment>
                        <Div styles={{ ...styles.container, ...passedStyles }} className="print-hide">
                            {m && !disableNav && (
                                <Div styles={styles.backIcon} onClick={() => history.push(routes.mobileEvents)}>
                                    <Icon type="chevron" color={theme.colors.gray04} />
                                </Div>
                            )}
                            {!m && !focusMode && this.renderCompany()}
                            <Div styles={styles.headerInfo}>
                                <Div styles={styles.headerTitle}>
                                    {pageTitle && (
                                        <Text styles={styles.headerTitlePlaceholder} size={3} weight="medium">
                                            {pageTitle}
                                        </Text>
                                    )}
                                    <Div styles={styles.headerTitleTextWrapper}>
                                        {eventStatus && (
                                            <Text styles={styles.eventStatus} uppercase size={0} weight="medium">
                                                {eventStatus}
                                            </Text>
                                        )}
                                        {pageTitle && (
                                            <Text styles={styles.headerTitleText} size={3} weight="medium">
                                                {pageTitle}
                                            </Text>
                                        )}
                                    </Div>
                                </Div>
                                <Text styles={styles.headerSmallText}>
                                    {ticker && (
                                        <Text span styles={styles.ticker} size={0} uppercase onClick={onTickerClick}>
                                            {ticker}
                                            <Span styles={styles.exchange}>{exchange}</Span>
                                        </Text>
                                    )}
                                    {callDate && (
                                        <Text span styles={styles.eventDate} size={0} uppercase>
                                            {getEventDateTime(callDate, hasUnknownTime, !m)}
                                        </Text>
                                    )}
                                </Text>
                            </Div>
                            {isUpcoming && eventId && (
                                <EventAlertToggle
                                    enabled={emailNotificationsEnabled}
                                    eventId={eventId}
                                    styles={
                                        emailNotificationsEnabled
                                            ? styles.eventAlertToggleEnabled
                                            : styles.eventAlertToggle
                                    }
                                />
                            )}
                            {!isPublic && showWordDownload && this.renderDownload()}
                            <WithPermission permissions={[PERMISSIONS.sharing]}>{this.renderShare()}</WithPermission>
                            {(m || focusMode) && this.renderCompany()}
                            {!m && !!isEventOwner && this.renderEventMenu()}
                        </Div>
                    </React.Fragment>
                )}
            </MediaQuery>
        );
    }
}

export const EventHeaderUI = compose(withStyleSheet(styleSheet))(EventHeader);
