export const styleSheet = theme => {
    const searchResult = {
        ...theme.content.annotated,
        ...theme.content.box,
        padding: '12px 14px',
        margin: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.blue09
        }
    };
    return {
        container: {
            position: 'relative',
            zIndex: 1,
            backgroundColor: theme.colors.gray10,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: `1px 0 0 0 ${theme.colors.gray09}`,
            minWidth: '320px',
            maxWidth: 320,
            flexShrink: 0,
            height: '100%',
            [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
                display: 'none'
            }
        },
        header: {
            flexShrink: 0,
            padding: `${theme.margins.margin01} ${theme.margins.margin01} 0`,
            borderBottom: `1px solid ${theme.colors.gray01}`,
            height: 90,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '.contentTab-selected': {
                color: theme.colors.black01
            }
        },
        scrollContainer: {
            padding: `0 0 ${theme.margins.margin01}`,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
                width: '0px',
                background: 'transparent'
            }
        },
        searchBox: {
            input: {
                height: 30,
                fontSize: theme.fonts.fontSizes.size01,
                backgroundColor: theme.colors.gray01,
                borderRadius: 15,
                paddingLeft: 30
            },
            '.iconWrapper': {
                height: 30,
                left: 8
            },
            '.clearInput': {
                height: 30,
                right: 8
            }
        },
        searchResult: {
            ...searchResult
        },
        searchResultSelected: {
            ...searchResult,
            border: `1px solid ${theme.colors.blue08}`,
            boxShadow: `inset 0 0 0 1px ${theme.colors.blue08}, 0 2px 4px 0 rgba(66,65,82,0.06)`
        },
        searchResultCount: {
            color: theme.colors.gray04
        },
        searchResultText: {
            fontSize: theme.fonts.fontSizes.size03,
            lineHeight: '1.3em',
            marginTop: 6
        },
        tabs: {
            flex: 'unset',
            marginBottom: -1,
            '.contentTab': {
                ':hover': {
                    color: theme.colors.gray06
                },
                ':active': {
                    color: theme.colors.black01
                }
            }
        },
        loader: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        empty: {
            padding: theme.margins.margin01
        },
        emptyText: {
            color: theme.colors.gray04,
            lineHeight: '1.3em',
            paddingTop: 10,
            textAlign: 'center'
        }
    };
};
