import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { constants } from 'consts';
import { Div } from 'components/Div';
import { EmptyContent } from 'components/EmptyContent';
import { ExternalLink } from 'components/ExternalLink';
import { Text } from 'components/Text';
import { BackButton } from 'components/BackButton';
import { styleSheet } from './stylesheet';

const { PHONE_SUPPORT, EMAIL_SUPPORT } = constants;

class NotFound extends PureComponent {
    static propTypes = {
        backButtonStyles: PropTypes.objectOf(PropTypes.any),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        showBack: PropTypes.bool.isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired
    };

    static defaultProps = {
        backButtonStyles: {},
        passedStyles: {}
    };

    render() {
        const { backButtonStyles, showBack, styles, passedStyles } = this.props;
        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {showBack && <BackButton back styles={{ ...styles.backButton, ...backButtonStyles }} />}
                <EmptyContent heading="Page not found">
                    <Text size={4} weight="light" styles={styles.errorCode} span>
                        404
                    </Text>
                    Our team has been notified.
                    <br />
                    This will be fixed soon!
                    <br />
                    <br />
                    {`If you need immediate assistance please call ${PHONE_SUPPORT},`}
                    <br />
                    or email&nbsp;
                    <ExternalLink href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</ExternalLink>
                </EmptyContent>
            </Div>
        );
    }
}

export const NotFoundUI = compose(withStyleSheet(styleSheet))(NotFound);
