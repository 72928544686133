import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { ExternalLayout } from 'layouts';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Heading } from 'components/Heading';
import { Notice } from 'components/Notice';
import { ExternalLink } from 'components/ExternalLink';

import { styleSheet } from './stylesheet';

class ErrorBoundary extends PureComponent {
    static propTypes = {
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        phoneSupport: PropTypes.string.isRequired,
        emailSupport: PropTypes.string.isRequired
    };

    render() {
        const { styles, phoneSupport, emailSupport } = this.props;
        return (
            <ExternalLayout back>
                <Div styles={styles.container}>
                    <Text size={4} weight="light" styles={styles.errorCode}>
                        Client
                    </Text>
                    <Heading size={1}>Client crashed</Heading>
                    <Notice type="info" styles={styles.notice}>
                        Our team has been notified.
                        <br />
                        We&apos;re working our fastest to fix this as quickly as possible.
                        <br />
                        <br />
                        If you need immediate assistance,
                        <br />
                        {`please call ${phoneSupport},`}
                        <br />
                        or email&nbsp;
                        <ExternalLink href={`mailto:${emailSupport}`}>{emailSupport}</ExternalLink>
                    </Notice>
                </Div>
            </ExternalLayout>
        );
    }
}

export const ErrorBoundaryUI = compose(withStyleSheet(styleSheet))(ErrorBoundary);
